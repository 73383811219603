import { updateDeeplink } from 'actions/deeplink';
import { createOrRemoveDeeplinkPersistedStorage } from './';
import type { AppDispatch } from 'store';

const RERENDER_PARAMS = { isDeeplink: true, isDeeplinkComplete: true };

interface RunDeeplinkParams {
  shouldRerender?: boolean;
  deeplink: string;
  shouldIgnoreFurtherNavigation?: boolean;
  dispatch: AppDispatch;
}

export const createSessionAndUpdateStore = async ({
  deeplink,
  shouldRerender = true,
  shouldIgnoreFurtherNavigation,
  dispatch,
}: RunDeeplinkParams) => {
  await createOrRemoveDeeplinkPersistedStorage({ flow: deeplink });

  if (shouldRerender) {
    dispatch(
      updateDeeplink({
        deeplinkFlow: deeplink,
        shouldIgnoreFurtherNavigation,
        ...RERENDER_PARAMS,
      })
    );
  }
};
