import { updateNakedDashboardErrors } from 'actions/dashboard';
import { selectDataStatus, selectError, selectIgnoredDashboardErrors } from 'selectors/dashboard';
import { getErrorsByHighestPriority } from '../../areas/dashboard/dashboards/naked/ErrorScenarios';

export default (store, area) => {
  let error;
  let errorsByHighestPriority;
  let nakedDashboardErrors;
  const state = store.getState();
  const { dispatch } = store;
  const dataStatus = selectDataStatus(state, area, {});
  const nakedDashboardErrorsToIgnore = selectIgnoredDashboardErrors(state);

  if (dataStatus) {
    error = selectError(state, area);
    errorsByHighestPriority = getErrorsByHighestPriority(dataStatus, error);
  }

  if (nakedDashboardErrorsToIgnore && nakedDashboardErrorsToIgnore.length > 0) {
    nakedDashboardErrors = errorsByHighestPriority
      ? errorsByHighestPriority.filter(
          (item) => !nakedDashboardErrorsToIgnore.includes(item.errorId)
        )
      : [];
  } else {
    nakedDashboardErrors = errorsByHighestPriority || [];
  }

  return dispatch(updateNakedDashboardErrors(nakedDashboardErrors, area));
};
