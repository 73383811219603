import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import withComponentContainer from 'decorators/withComponentContainer';
import Modal from 'containers/v2/layout/Modal';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import { accessibilityPrettifier } from 'helpers/string';
import Icon from '@sp/ui/v2/base/Icon';
import Margin from '@sp/ui/base/Margin';
import { RadioButton } from '@sp/ui/base/inputs';
import Row, { TWO_LINES_HEIGHT } from '@sp/ui/v2/base/rows';
import { Body2 } from '@sp/ui/v2/typography';
import SalaryInfo from './SalaryInfo';
import AnnualStateInfo from './AnnualStateInfo';
import { getSalaryPeriod } from '../../calculations/helpers/income';

const NEGATIVE_MARGIN_LEFT = -6;
const DEFAULT_RADIO_BUTTON_DIMENSIONS = 24;
const ICON_DIMENSIONS = 20;

const TouchableOpacity = styled.TouchableOpacity``;

const EmptyIconPlaceholder = styled.View`
  width: ${({ theme }) => ICON_DIMENSIONS + theme.SPACINGS.s}px;
`;

const RowTextRightWrapper = styled.View`
  flex-direction: column;
  height: ${TWO_LINES_HEIGHT}px;
  justify-content: center;
`;

const RowTextLeftWrapper = styled.View`
  justify-content: center;
  margin-left: ${NEGATIVE_MARGIN_LEFT}px;
  height: ${TWO_LINES_HEIGHT}px;
  flex: 1;
`;

const ExtraRowPaddingWrapper = styled.View`
  padding: ${({ theme }) => `${theme.SPACINGS.s}px 0px`};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const SalaryRow = ({
  onPress,
  calculatedIndex,
  userEnteredIncome,
  radioButtonDimensions,
  isHighlighted,
  i18n,
  incomeOption,
  theme,
  index,
  amount,
  grossAmount,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isIncomeOptionTypeAnnualState = incomeOption.type === 'AnnualStatement';

  const period = getSalaryPeriod(incomeOption, i18n, true);

  const description = isIncomeOptionTypeAnnualState
    ? i18n.t('dashboard|annualStatement')
    : i18n.t('dashboard|income');
  const infoLabel =
    incomeOption.source === 'Skatteforvaltningen'
      ? i18n.t('dashboard|annualStatementInfo')
      : i18n.t('dashboard|incomeInfo');
  const incomeSource = isIncomeOptionTypeAnnualState
    ? i18n.t('dashboard|incomeSourceAnnualStatement')
    : i18n.t('dashboard|incomeSourceSalary');

  const yearlyLabel = accessibilityPrettifier(
    i18n.t('dashboard|incomeSourceAnnualStatementA11Y'),
    i18n
  );
  const monthlyLabel = accessibilityPrettifier(i18n.t('dashboard|incomeSourceSelectSalary'), i18n);

  const backgroundColor =
    index % 2 === 0 ? theme.COLORS.PRIMARY_VARIANT1 : theme.COLORS.V2_SECONDARY_BACKGROUND;
  const fontWeight = isHighlighted ? 'bold' : 'regular';

  return (
    <ExtraRowPaddingWrapper backgroundColor={backgroundColor}>
      <Row
        hasBorderRadius={false}
        accessibilityLabel={
          isHighlighted
            ? accessibilityPrettifier(
                i18n.t('dashboard|isHighlightedSalaryRow', { amount, incomeSource, period }),
                i18n
              )
            : accessibilityPrettifier(
                i18n.t('dashboard|isNotHighlightedSalaryRow', {
                  amount,
                  incomeSource,
                  period,
                }),
                i18n
              )
        }
        accessibilityActions={[
          { name: 'rightComponent', label: infoLabel },
          {
            name: 'leftComponent',
            label: isIncomeOptionTypeAnnualState ? yearlyLabel : monthlyLabel,
          },
        ]}
        onAccessibilityAction={(event) => {
          switch (event.nativeEvent.actionName) {
            case 'rightComponent':
              setIsModalVisible(true);
              break;
            case 'leftComponent':
              onPress(calculatedIndex);
              break;
          }
        }}
        onPress={() => onPress(calculatedIndex)}
        textLeft={
          <RowTextLeftWrapper>
            <Body2 fontWeight={fontWeight} secondaryColor>
              {userEnteredIncome
                ? i18n.t('dashboard|selfEnteredSalary')
                : `${description} ${period}`}
            </Body2>
          </RowTextLeftWrapper>
        }
        textRight={
          <RowTextRightWrapper>
            <Body2 textAlign="right" fontWeight={fontWeight} secondaryColor>
              {i18n.t('dashboard|monthlyAmount', { amount })}
            </Body2>
            <Body2 textAlign="right" fontWeight="regular" secondaryColor>
              {i18n.t('dashboard|monthlyAmountWithGross', { amount: grossAmount })}
            </Body2>
          </RowTextRightWrapper>
        }
        componentLeft={
          <RadioButton
            checkmarkSelector
            accessibilityHide
            isSelected={isHighlighted}
            onPress={() => onPress(calculatedIndex)}
            border={false}
            dimensions={radioButtonDimensions}
            color={theme.COLORS.PRIMARY_SUCCESS}
          />
        }
        componentRight={
          userEnteredIncome ? (
            <EmptyIconPlaceholder />
          ) : (
            <TouchableOpacity
              testID={
                isIncomeOptionTypeAnnualState
                  ? testIds.DISPOSABLE_INCOME_ANNUAL_STATEMENT_INFO_BUTTON
                  : testIds.DISPOSABLE_INCOME_MONTHLY_STATEMENT_INFO_BUTTON
              }
              hitSlop={theme.HIT_SLOP}
              accessibilityRole="button"
              onPress={() => setIsModalVisible(true)}
            >
              <Margin marginLeft="s">
                <Icon fill={theme.COLORS.PRIMARY} name="info" />
              </Margin>
            </TouchableOpacity>
          )
        }
        backgroundColor={backgroundColor}
      />
      <Modal
        testID={
          isIncomeOptionTypeAnnualState
            ? testIds.DISPOSABLE_INCOME_ANNUAL_INCOME_MODAL
            : testIds.DISPOSABLE_INCOME_MONTHLY_INCOME_MODAL
        }
        closeButtonTestID={testIds.DISPOSABLE_INCOME_CLOSE_MODAL}
        ignorePadding
        visible={isModalVisible}
        onRequestClose={() => setIsModalVisible(false)}
      >
        <Margin marginBottom="xxl">
          {isIncomeOptionTypeAnnualState ? (
            <AnnualStateInfo index={calculatedIndex} />
          ) : (
            <SalaryInfo index={calculatedIndex} />
          )}
        </Margin>
      </Modal>
    </ExtraRowPaddingWrapper>
  );
};

SalaryRow.propTypes = {
  userEnteredIncome: PropTypes.bool,
  radioButtonDimensions: PropTypes.number,
  onPress: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  incomeOption: PropTypes.object.isRequired,
  calculatedIndex: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  grossAmount: PropTypes.string.isRequired,
};

SalaryRow.defaultProps = {
  userEnteredIncome: false,
  goBackRouteName: undefined,
  radioButtonDimensions: DEFAULT_RADIO_BUTTON_DIMENSIONS,
};

export default withTheme(withComponentContainer()(SalaryRow));
