import { START_CHANGE_INVESTMENT_PROFILE_FLOW } from 'constants/actionTypes';
import * as routeNames from 'constants/routeNames';
import { getPendingChanges, getCurrentInvestmentProfiles } from 'actions/changeInvestmentProfile';

import { selectAllSavingsData } from 'selectors/savings';
import { selectPendingChanges, selectCurrent } from 'selectors/investmentProfile';

import { fetchSavingsOverview } from 'actions/savings';

import { navigate } from 'helpers/navigation';
import { START_FLOW } from 'areas/changeInvestmentProfile/trackingIds';
import Tracking from 'services/tracking';

const middleware = (store) => {
  return (next) => async (action) => {
    if (action.type === START_CHANGE_INVESTMENT_PROFILE_FLOW) {
      Tracking.trackEvent(START_FLOW);

      const { dispatch } = store;
      let state = store.getState();

      const profiles = getCurrentInvestmentProfiles();
      const dispatchArray = [profiles];

      if (!selectAllSavingsData(state)) {
        dispatchArray.push(fetchSavingsOverview());
      }

      if (!selectPendingChanges(state)) {
        dispatchArray.push(getPendingChanges());
      }

      if (dispatchArray.length > 0) {
        await dispatch(dispatchArray);
      }

      state = store.getState();

      const { receipts } = selectPendingChanges(state);
      const canChangeInvestmentProfile =
        selectCurrent(state)?.currentPoliciesWithInvestmentProfile.length > 0;

      if (!canChangeInvestmentProfile) {
        return navigate(routeNames.CHANGE_INVESTMENT_PROFILE, {
          screen: routeNames.CHANGE_INVESTMENT_PROFILE_CONTACT_US,
        });
      }

      if (!receipts) {
        return navigate(routeNames.CHANGE_INVESTMENT_PROFILE, {
          screen: routeNames.CHANGE_INVESTMENT_PROFILE_CHOOSE_POLICY,
        });
      }

      if (receipts.length === 1) {
        return navigate(routeNames.CHANGE_INVESTMENT_PROFILE, {
          screen: routeNames.CHANGE_INVESTMENT_PROFILE_RECEIPT,
        });
      }

      if (receipts.length > 1) {
        return navigate(routeNames.CHANGE_INVESTMENT_PROFILE, {
          screen: routeNames.CHANGE_INVESTMENT_PROFILE_RECEIPT_OVERVIEW,
        });
      }
    }

    return next(action);
  };
};

export default middleware;
