const { default: Tracking } = require('services/tracking');

export const DASHBOARD_AREA = 'Dashboard';

export const RETIREMENT = 'Retirement';
export const DISABILITY = 'Disability';
export const DEATH = 'Death';

export const PAYOUTS_ALL = 'PayoutsAll';
export const PAYOUTS_OVER_TIME = 'PayoutsOverTime';
export const PAYOUTS_ONE_TIME = 'PayoutsOneTime';

export const DISPOSABLE_INCOME = 'DisposableIncome';
export const ADJUST_CALCULATION = 'AdjustCalculation';
export const ABOUT_CALCULATIONS = 'AboutCalculations';

export const CHILDREN = 'Children';
export const LINK_CHILDREN = 'LinkChildren';

export const KOMMUNESKAT = 'Kommuneskat';

export const INCOME = 'Income';
export const GROSS_AMOUNT = 'GrossAmount';

export const PUBLIC_SERVICES = 'PublicServices';

export const INCREASE_RETIREMENT_AGE = {
  area: DASHBOARD_AREA,
  action: 'ClickAgeIncrease',
};

export const DECREASE_RETIREMENT_AGE = {
  area: DASHBOARD_AREA,
  action: 'ClickAgeDecrease',
};

export const RECALCULATE_DASHBOARD = {
  area: ADJUST_CALCULATION,
  action: 'RecalculateDashboard',
};

export const ADJUST_INCOME = {
  area: INCOME,
  action: 'AdjustIncome',
};

export const CLICK_SUGGESTED_AGE_INFO = {
  area: DASHBOARD_AREA,
  action: 'ClickSuggestedAgeInfo',
};

export const CLICK_ADJUST_CALCULATIONS = {
  area: DASHBOARD_AREA,
  action: 'ClickAdjustCalculation',
};

export const CLICK_PAYOUTS = {
  area: DASHBOARD_AREA,
  action: 'ClickPayouts',
};

export const CLICK_CHILDREN_PAYOUTS = {
  area: DASHBOARD_AREA,
  action: 'ClickChildrenPayouts',
};

export const CLICK_PAYOUTS_ORIGIN = {
  area: DASHBOARD_AREA,
  actions: 'ClickPayoutsOrigin',
};

export const CLICK_PRECONDITIONS = {
  area: ABOUT_CALCULATIONS,
  action: 'ClickPreconditions',
};

export const CLICK_AWARD_RULES = {
  area: DASHBOARD_AREA,
  action: 'ClickAwardRules',
};

export const CLICK_LINK_CHILDREN = {
  area: LINK_CHILDREN,
  action: 'ClickLinkChildren',
};

export const CLICK_ADD_CHILD = {
  area: CHILDREN,
  action: 'ClickAddChild',
};

export const CLICK_UPDATE_CHILD = {
  area: CHILDREN,
  action: 'ClickUpdateChild',
};

export const CLICK_DELETE_CHILD = {
  area: CHILDREN,
  action: 'ClickDeleteChild',
};

export const CLICK_MUNICIPALITY = {
  area: KOMMUNESKAT,
  action: 'ClickMunicipality',
};

export const clickGrossAmount = (value: boolean) => ({
  area: GROSS_AMOUNT,
  action: value ? 'ClickGrossAmountYes' : 'ClickGrossAmountNo',
});

export const CLICK_UPDATE_CALCULATION = {
  area: DISPOSABLE_INCOME,
  action: 'ClickUpdateCalculation',
};

export const trackPublicServices = (action: string) =>
  Tracking.trackEvent({ area: PUBLIC_SERVICES, action });
