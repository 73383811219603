import { RootState } from 'store';

export const selectSpecificConsent = (state: RootState, key: string) =>
  state.services.consent.items.filter(
    ({ key: consentKey }) => consentKey.toLowerCase() === key.toLowerCase()
  );

export const selectGDPRConsent = (state: RootState) => ({
  ...state.services.consent,
  items: selectSpecificConsent(state, 'GDPR'),
});

export const selectPARTNERConsent = (state: RootState) => ({
  ...state.services.consent,
  items: selectSpecificConsent(state, 'PARTNER'),
});

export const selectGDPRAndPartnerConsents = (state: RootState) => ({
  ...state.services.consent,
  items: [...selectSpecificConsent(state, 'GDPR'), ...selectSpecificConsent(state, 'PARTNER')],
});

export const selectConsents = (state: RootState) => state.services.consent;
