import styled from 'styled-components/native';

export const MobileAndTabletTabs = styled.View`
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 0;
`;

const ICON_MARGIN_RIGHT = 12;

export const MobileAndDesktopIconWrapper = styled.View`
  margin-right: ${ICON_MARGIN_RIGHT}px;
`;
