import { formatDate } from 'helpers/string';

export const getSavingsDetails = (savingsData) => {
  if (!savingsData) return {};

  let pensionInfoLastUpdate;
  let hasInsurance;
  const policies = [];
  const {
    succeeded,
    internalSavings,
    externalSavings,
    blockedSavings,
    pensionInfoFetched,
    isSampensionEmployee,
    timestamp,
  } = savingsData;

  if (internalSavings?.length > 0) {
    policies.push(...internalSavings);

    hasInsurance = internalSavings.some((item) => item.isInsurance);
  }

  if (blockedSavings?.length > 0) {
    policies.push(...blockedSavings);
  }

  // NOTE: Pr. business specification don't show ATP
  if (externalSavings?.length > 0) {
    const externalSavingsWithoutATP = externalSavings.filter((item) => item.type !== 'ATP');

    if (externalSavingsWithoutATP.length > 0) {
      policies.push(...externalSavingsWithoutATP);
    }
  }

  if (pensionInfoFetched && timestamp) pensionInfoLastUpdate = formatDate(timestamp);

  /* TODO: Backend has been notified about using mixed attribute
  naming and content:
    (policyId / coverageNumber),
    (value / balance),
    (title / name)
  For now this fix aligns the policy attribute naming
  until a solid fix is in place. */
  const synchronizedPolicies = policies.map((item, i) => ({
    index: i,
    key: item.policyId || item.coverageNumber,
    title: item.title || item.name,
    policyId: item.policyId || item.coverageNumber,
    value:
      item.value ||
      (item.balance && {
        amount: item?.balance?.amount,
        currency: { alphaCode: item?.balance?.currency },
      }),
    isInternal: internalSavings.some(
      (internal) => internal.coverageNumber === item.policyId || item.coverageNumber
    ),
    isExternal: externalSavings.some((external) => external.policyId === item.policyId),
    isBlocked: blockedSavings.some((blocked) => blocked.policyId === item.policyId),
    isBeingTransferred: ['Blocked', 'Unknown', 'Outdated'].includes(item.transferState),
    ...item,
  }));

  return {
    succeeded,
    timestamp,
    pensionInfoLastUpdate,
    policies: synchronizedPolicies,
    hasInsurance,
    isSampensionEmployee,
  };
};
