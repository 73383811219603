import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { initFunc } from 'helpers/props';
import withScreenContainer from 'decorators/withScreenContainer';
import GlobalNotificationContext from 'context/GlobalNotificationContext';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { selectContactInfo } from 'selectors/profile';
import { verifyContactInformation } from 'actions/profile';
import { actions as formActions } from 'react-formed';
import { Body } from '@sp/ui/typography';
import { getDisplayPhoneNumber } from 'areas/profile/helpers';
import Margin from '@sp/ui/base/Margin';
import * as routeNames from 'constants/routeNames';

const Content = styled.View`
  align-items: center;
  padding: 0px ${({ theme }) => theme.SPACINGS.md}px;
`;

const UpdateContactInformation = ({
  navigation,
  updatedEmail,
  updatedPhoneNumber,
  updateContactInfo,
  clearContactInfoForm,
  i18n,
}) => {
  const { showNotification } = useContext(GlobalNotificationContext);

  useEffect(
    () => () => {
      if (clearContactInfoForm) clearContactInfoForm();
    },
    [clearContactInfoForm]
  );

  return (
    <ModalProvider isModal onClose={() => navigation.navigate(routeNames.TABS)}>
      <ScrollPage
        heading={i18n.t('Opdater din profil')}
        hasBackButton={false}
        actions={[
          {
            text: i18n.t('Ja tak'),
            mobilePosition: 'bottom-sticky',
            onPress: async () => {
              navigation.navigate(routeNames.TABS);
              await updateContactInfo(showNotification);
            },
          },
          {
            text: i18n.t('Nej tak'),
            mobilePosition: 'bottom-sticky',
            type: 'secondary',
            onPress: () => navigation.navigate(routeNames.TABS),
          },
        ]}
      >
        <Content>
          <Body textAlign="center">
            {i18n.t(
              'Slip for at indtaste dine kontaktoplysninger igen fremover, ved at opdatere din profil nu. Vi opdaterer din profil med følgende:'
            )}
          </Body>
          {!!updatedPhoneNumber && (
            <Margin marginTop="sm">
              <Body fontWeight="bold" textAlign="center">
                {updatedPhoneNumber}
              </Body>
            </Margin>
          )}
          {!!updatedEmail && (
            <Margin marginTop="sm">
              <Body fontWeight="bold" textAlign="center">
                {updatedEmail}
              </Body>
            </Margin>
          )}
          <Margin marginTop="md" />
          <Body textAlign="center">
            {i18n.t(
              'Når du opdaterer telefonnummer og email, ændrer du ikke på dit samtykke for, hvordan vi må bruge dine kontaktoplysninger.\n\nPå de næste skærme vil du skulle verificere dine nye kontaktoplysninger. Herefter vil du få mulighed for at justere dine kontaktindstillinger.'
            )}
          </Body>
        </Content>
      </ScrollPage>
    </ModalProvider>
  );
};

UpdateContactInformation.propTypes = {
  navigation: PropTypes.object.isRequired,
  updatedEmail: PropTypes.string,
  updatedPhoneNumber: PropTypes.string,
  updateContactInfo: initFunc,
  clearContactInfoForm: initFunc,
  i18n: PropTypes.object.isRequired,
};

UpdateContactInformation.defaultProps = {
  updatedEmail: undefined,
  updatedPhoneNumber: undefined,
  updateContactInfo: undefined,
  clearContactInfoForm: undefined,
};

const mapStateToProps = (state, props) => {
  const { email, phoneNumber, countryCode } = selectContactInfo(state);

  return {
    form: state.forms[props.route?.params?.formName] || {},
    email,
    phoneNumber,
    countryCode,
  };
};

const startVerificationFlow = (email, phoneNumber, showNotification, navigation, i18n) => {
  const navigateToEmailVerification = () =>
    navigation.navigate(routeNames.PROFILE_VERIFY_EMAIL_MODAL, {
      email,
      hasBackButton: false,
      standaloneFlow: true,
    });

  const navigateToPhoneVerification = () =>
    navigation.navigate(routeNames.PROFILE_VERIFY_PHONE_NUMBER_MODAL, {
      phoneNumber,
      email,
      hasBackButton: false,
      standaloneFlow: true,
    });

  if (phoneNumber && email) {
    showNotification(
      i18n.t('Kode er sendt til {{phoneNumber}} og {{email}}.', { phoneNumber, email })
    );

    navigateToPhoneVerification();
  } else if (phoneNumber) {
    showNotification(
      i18n.t('Kode er sendt til {{phoneNumber}}. Den gælder i 20 min.', { phoneNumber })
    );

    navigateToPhoneVerification();
  } else if (email) {
    showNotification(i18n.t('Kode er sendt til {{email}}. Den gælder i 24 timer.', { email }));

    navigateToEmailVerification();
  }
};

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, getProps, setProps }) =>
    action(async () => {
      const { form, dispatch, navigation, route, i18n } = getProps();
      const formName = route?.params?.formName;

      const updatedEmail = form?.email;
      const updatedPhoneNumber = getDisplayPhoneNumber(form?.phone || {});

      setProps({
        updatedEmail,
        updatedPhoneNumber,
      });

      return {
        updateContactInfo: (showNotification) =>
          action(async () => {
            if (form?.email) {
              await dispatch(verifyContactInformation({ email: form?.email }));
            }

            if (form?.phone) {
              await dispatch(
                verifyContactInformation({
                  phone: {
                    countryCode: form?.phone?.countryCode,
                    number: form?.phone?.phoneNumber,
                  },
                })
              );
            }

            startVerificationFlow(
              updatedEmail,
              updatedPhoneNumber,
              showNotification,
              navigation,
              i18n
            );
          }),
        clearContactInfoForm: () => action(() => dispatch(formActions.clear(formName))),
      };
    }),
})(UpdateContactInformation);
