import React from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';
import Svg from 'react-native-svg';

const SvgIcon = ({
  name,
  svgs,
  viewBox,
  height,
  width,
  fill,
  strokeWidth,
  fillRule,
  stroke,
  style,
  testID,
}) => {
  if (!name) {
    return null;
  }

  const iconEl = svgs[`${name}.${Platform.OS}`] || svgs[name];

  if (!iconEl) {
    return null;
  }

  const heightStr = height && height.toString();
  const widthStr = width && width.toString();
  const strokeWidthStr = strokeWidth && strokeWidth.toString();

  const isSimple = React.isValidElement(iconEl);
  const svgEl = isSimple ? iconEl : iconEl.svg;

  return (
    <Svg
      height={heightStr}
      width={widthStr}
      viewBox={!isSimple && iconEl.viewBox ? iconEl.viewBox : viewBox}
      style={style}
      testID={testID}
    >
      {React.cloneElement(svgEl, {
        fill,
        fillRule,
        stroke,
        strokeWidthStr,
      })}
    </Svg>
  );
};

SvgIcon.defaultProps = {
  fill: '#000',
  fillRule: 'evenodd',
  viewBox: '0 0 100 100',
  strokeWidth: undefined,
  stroke: undefined,
  style: undefined,
  testID: undefined,
};

SvgIcon.propTypes = {
  fill: PropTypes.string,
  fillRule: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  svgs: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  viewBox: PropTypes.string,
  testID: PropTypes.string,
};

export default SvgIcon;
