import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import BasePage, {
  Card,
  NarrowContent,
  Actions,
  TopContent,
  ActionShape,
} from '@sp/ui/pages/BasePage';
import PageParts from '@sp/ui/PageParts';

const NO_BACK_BUTTON_OFFSET = 69;

const NoBackButtonOffset = styled.View`
  padding-top: ${NO_BACK_BUTTON_OFFSET}px;
`;

const FlexPage = ({
  heading,
  description,
  children,
  actions,
  backButton,
  hasBackButton,
  testID,
  disableTopPadding,
  disableBottomPadding,
  menu,
  accessibilitySummary,
}) => (
  <BasePage testID={testID} menu={menu} accessibilitySummary={accessibilitySummary}>
    <PageParts>
      {({ BackButton: GlobalBackButton }) => (
        <NarrowContent>
          {!hasBackButton && <NoBackButtonOffset />}
          {hasBackButton && (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />)}
        </NarrowContent>
      )}
    </PageParts>
    <NarrowContent>
      <Card disableBottomPadding={disableBottomPadding} disableTopPadding={disableTopPadding}>
        {(heading || description) && <TopContent heading={heading} description={description} />}
        {children}
        {actions && actions.length > 0 && <Actions actions={actions} />}
      </Card>
    </NarrowContent>
  </BasePage>
);

FlexPage.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  backButton: PropTypes.node,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  hasBackButton: PropTypes.bool,
  disableTopPadding: PropTypes.bool,
  disableBottomPadding: PropTypes.bool,
  menu: PropTypes.node,
};

FlexPage.defaultProps = {
  heading: undefined,
  description: undefined,
  children: undefined,
  actions: undefined,
  backButton: undefined,
  testID: undefined,
  accessibilitySummary: undefined,
  hasBackButton: true,
  disableTopPadding: false,
  disableBottomPadding: false,
  menu: undefined,
};

export default FlexPage;
