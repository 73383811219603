import {
  SET_HAS_FETCHED_TAX,
  SET_HAS_FETCHED_PENSIONSINFO,
  SET_RESET_FETCHING_STATUSES,
  SET_SKIP_TAX_PENSIONSINFO,
  PENSION_CREATE_SESSION,
  SKAT_CREATE_SESSION,
  AWAIT_PENSIONS_INFO_STATUS,
  AWAIT_SKAT_STATUS,
  UPDATE_FETCH_TAX_PENSIONSINFO,
  UPDATE_FETCH_FAIL_TAX_PENSIONSINFO,
  FETCH_TAX_PENSIONSINFO,
  DELETE_FETCH_TAX_PENSIONSINFO_STORE,
} from 'constants/actionTypes';

const { default: auth } = require('services/api/client');
const { post, get, poll } = require('./api');

type FetchTaxAndPensionsInfoParams = {
  isOnboarding?: boolean;
  onDoneCallback?: () => void;
  onDoneNavigation?: () => void;
  onSkipNavigation?: () => void;
};

type InitFetchTaxAndPensionsInfoParams = {
  onDoneRouteConfig: {
    key: string;
    name: string;
    params?: Record<string, string>;
  };
  fetchTaxOrPensionsInfo: {
    fetchTax?: boolean;
    fetchPensionsInfo?: boolean;
  };
  isOnboarding?: boolean;
};

type SkipFetchingProcessParams = {
  isSkippingTax?: boolean;
  isSkippingPensionsInfo?: boolean;
};

export const createSession = (area: string) => {
  const isPension = area === 'pension';

  const action = isPension ? PENSION_CREATE_SESSION : SKAT_CREATE_SESSION;
  const postObject = isPension ? { clientId: auth.clientId } : {};
  const endpoint = isPension ? '/pensions/v1/pensionsinfosessions' : '/taxes/v1/eskatdata';

  return post(action, postObject, 'api', endpoint);
};

const checkStatus = ({ sessionId, area }: { sessionId: string; area: string }) => {
  const isPension = area === 'pension';

  const action = isPension ? AWAIT_PENSIONS_INFO_STATUS : AWAIT_SKAT_STATUS;
  const endpoint = isPension
    ? `/pensions/v1/pensionsinfosessions/${sessionId}`
    : `/taxes/v1/eskatdata/sessionstatus/${sessionId}`;

  return get(action, 'api', endpoint);
};

export const waitForStatus = ({ sessionId, area }: { sessionId: string; area: string }) =>
  poll(checkStatus({ sessionId, area }), {
    status: (response: any) => response,
  });

export const fetchTaxAndPensionsInfo = (params?: FetchTaxAndPensionsInfoParams) => ({
  type: FETCH_TAX_PENSIONSINFO,
  payload: {
    ...params,
    fetchTax: true,
    fetchPensionsInfo: true,
  },
});

export const fetchTax = (params?: FetchTaxAndPensionsInfoParams) => ({
  type: FETCH_TAX_PENSIONSINFO,
  payload: {
    ...params,
    fetchTax: true,
  },
});

export const fetchPensionsInfo = (params?: FetchTaxAndPensionsInfoParams) => ({
  type: FETCH_TAX_PENSIONSINFO,
  payload: {
    ...params,
    fetchPensionsInfo: true,
  },
});

export const initFetchTaxAndPensionsInfo = (params: InitFetchTaxAndPensionsInfoParams) => ({
  type: UPDATE_FETCH_TAX_PENSIONSINFO,
  payload: {
    onDoneRouteConfig: params.onDoneRouteConfig,
    isOnboarding: params?.isOnboarding,
    ...params?.fetchTaxOrPensionsInfo,
  },
});

export const updateHasFetchedTax = (status: boolean) => ({
  type: SET_HAS_FETCHED_TAX,
  payload: status,
});

export const updateHasFetchedPensionsInfo = (status: boolean) => ({
  type: SET_HAS_FETCHED_PENSIONSINFO,
  payload: status,
});

export const skipFetchingProcess = (skippedAreas: SkipFetchingProcessParams) => ({
  type: SET_SKIP_TAX_PENSIONSINFO,
  payload: {
    hasSkippedTax: skippedAreas?.isSkippingTax,
    hasSkippedPensionsInfo: skippedAreas?.isSkippingPensionsInfo,
  },
});

export const resetFetchingStatuses = () => ({
  type: SET_RESET_FETCHING_STATUSES,
});

export const updateFetchFailTaxAndPensionsInfo = (failType: string) => ({
  type: UPDATE_FETCH_FAIL_TAX_PENSIONSINFO,
  payload: failType,
});

export const deleteFetchTaxAndPensionsInfoStore = () => ({
  type: DELETE_FETCH_TAX_PENSIONSINFO_STORE,
});
