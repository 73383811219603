import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';

const H5 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.H5.DESKTOP}px;
`;

H5.defaultProps = {
  fontWeight: 'bold',
};

export default H5;
