import { RootState } from 'store';
import type { VerificationsType } from 'actions/profile';

export const selectProfileData = (state: RootState) => state.areas.profile?.profileData;

const selectCustomerData = (state: RootState) => selectProfileData(state)?.customer;

const selectPersonalData = (state: RootState) => selectProfileData(state)?.person;

export const selectContactInfo = (state: RootState) => state.areas.profile.contactInfo;

export const selectUserName = (state: RootState) => selectPersonalData(state)?.name.full;

export const selectUserFirstName = (state: RootState) => selectPersonalData(state)?.name.first;

export const selectUserGender = (state: RootState) => selectPersonalData(state)?.gender;

export const selectFamilySituation = (state: RootState) =>
  selectPersonalData(state)?.familySituation;

export const selectCustomerType = (state: RootState) => selectCustomerData(state)?.type;

export const selectCompanyGroups = (state: RootState) => selectCustomerData(state)?.companyGroups;

export const selectSubjectId = (state: RootState) => selectCustomerData(state)?.id;

export const selectProfileCompletenessInfo = (state: RootState) =>
  state.areas.profile?.profileCompleteness?.info;

export const selectPendingVerifications = (state: RootState, type: VerificationsType) =>
  state.areas.profile?.verifications?.pending?.data[type];

export const selectHasPendingVerifications = (state: RootState) =>
  !!(selectPendingVerifications(state, 'phone') || selectPendingVerifications(state, 'email'));

export const selectCurrentAge = (state: RootState) => selectPersonalData(state)?.age || 0;

export const selectDataFromExternalSources = (state: RootState) => {
  let numberOfExternalFiles = 0;

  const { pensionInfo, pensionInfoUpdateDate, taxStatement, taxStatementUpdateDate } =
    state.areas.profile?.profileCompleteness?.info ?? {};

  if (pensionInfo && pensionInfoUpdateDate) {
    numberOfExternalFiles += 1;
  }

  if (taxStatement && taxStatementUpdateDate) {
    numberOfExternalFiles += 1;
  }

  return {
    pensionInfo: { pensionInfo, pensionInfoUpdateDate },
    taxStatementInfo: { taxStatement, taxStatementUpdateDate },
    numberOfExternalFiles,
  };
};
