import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { Body } from '@sp/ui/typography';
import Conditional from 'decorators/conditional';
import Icon from '@sp/ui/base/Icon';
import { isWeb } from '@sp/ui/helpers/device';

const HORIZONTAL_WRAPPER_PADDING = 16;
const VERTICAL_WRAPPER_PADDING = 24;
const VERTICAL_WRAPPER_NATIVE_PADDING = 16;
const RADIO_BUTTON_CIRCLE_SIZE = 24;
const RADIO_BUTTON_INNER_CIRCLE_SIZE = 8;
const RADIO_BUTTON_LABEL_LEFT_MARGIN = 8;
const RADIO_BUTTON_LABEL_RIGHT_MARGIN = 8;
const RADIO_BUTTON_MIN_WIDTH_TABLET = 60;

const RadioButtonWrapperDesktop = styled.TouchableOpacity`
  ${({ border, theme }) =>
    border
      ? `
  borderWidth: ${StyleSheet.hairlineWidth}px;
  borderColor: ${theme.COLORS.PRIMARY_BORDER};
  borderRadius: ${theme.DEFAULTS.BORDER_RADIUS}px;
  `
      : null}
  ${({ checkmarkSelector, marginRightEnabled }) =>
    checkmarkSelector
      ? `
  width: ${RADIO_BUTTON_CIRCLE_SIZE}px;
  margin-right: ${marginRightEnabled ? RADIO_BUTTON_LABEL_RIGHT_MARGIN : 0}px;
  `
      : 'flex: 1;'}
  padding: ${(props) => props.padding};
  flex-direction: row;
  align-items: center;
`;

const RadioButtonWrapperDefault = styled.TouchableOpacity`
  ${({ border, theme }) =>
    border
      ? `
  borderWidth: ${StyleSheet.hairlineWidth}px;
  borderColor: ${theme.COLORS.PRIMARY_BORDER};
  borderRadius: ${theme.DEFAULTS.BORDER_RADIUS}px;
  `
      : null}
  ${({ checkmarkSelector, marginRightEnabled }) =>
    checkmarkSelector
      ? `
  width: ${RADIO_BUTTON_CIRCLE_SIZE}px;
  margin-right: ${marginRightEnabled ? RADIO_BUTTON_LABEL_RIGHT_MARGIN : 0}px;
  `
      : 'flex: 1;'}
  padding: ${(props) => props.padding};
  flex-direction: row;
  align-items: center;
`;

const RadioButtonOuterCircle = styled.View`
  align-items: center;
  justify-content: center;
  width: ${({ dimensions }) => dimensions ?? RADIO_BUTTON_CIRCLE_SIZE}px;
  height: ${({ dimensions }) => dimensions ?? RADIO_BUTTON_CIRCLE_SIZE}px;
  border-width: 1px;
  border-color: ${({ isSelected, color, theme }) =>
    isSelected ? color || theme.COLORS.PRIMARY : theme.COLORS.PRIMARY};
  border-radius: ${RADIO_BUTTON_CIRCLE_SIZE / 2}px;
  background: ${({ isSelected, theme, color }) =>
    isSelected ? color || theme.COLORS.PRIMARY : theme.COLORS.SECONDARY_BACKGROUND};
`;

const RadioButtonInnerCircle = styled.View`
  width: ${RADIO_BUTTON_INNER_CIRCLE_SIZE}px;
  height: ${RADIO_BUTTON_INNER_CIRCLE_SIZE}px;
  border-radius: ${RADIO_BUTTON_INNER_CIRCLE_SIZE / 2}px;
  background: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
`;

const RadioButtonCheckMark = styled.View`
  align-items: center;
  justify-content: center;
  width: ${RADIO_BUTTON_CIRCLE_SIZE}px;
  height: ${RADIO_BUTTON_CIRCLE_SIZE}px;
  border-radius: ${RADIO_BUTTON_CIRCLE_SIZE / 2}px;
`;

const RadioButtonLabelWrapperMobile = styled.View`
  margin-left: ${RADIO_BUTTON_LABEL_LEFT_MARGIN}px;
`;

const RadioButtonLabelWrapperTablet = styled.View`
  margin-left: ${RADIO_BUTTON_LABEL_LEFT_MARGIN}px;
  min-width: ${RADIO_BUTTON_MIN_WIDTH_TABLET}px;
`;

const getPadding = (checkmarkSelector) => {
  if (isWeb) {
    return checkmarkSelector
      ? '0px'
      : `${VERTICAL_WRAPPER_PADDING}px ${HORIZONTAL_WRAPPER_PADDING}px`;
  }
  return checkmarkSelector
    ? '0px'
    : `${VERTICAL_WRAPPER_NATIVE_PADDING}px ${HORIZONTAL_WRAPPER_PADDING}px`;
};

const RadioButtonLabelWrapper = Conditional({
  default: RadioButtonLabelWrapperTablet,
  mobile: RadioButtonLabelWrapperMobile,
});

const Wrapper = Conditional({
  default: RadioButtonWrapperDefault,
  desktop: RadioButtonWrapperDesktop,
});

const RadioButton = ({
  theme,
  label,
  isSelected,
  onPress,
  border,
  checkmarkSelector,
  color,
  accessibilityHide,
  marginRightEnabled,
  dimensions,
  testID,
}) => (
  <Wrapper
    hitSlop={theme.HIT_SLOP}
    onPress={onPress}
    border={border}
    padding={getPadding(checkmarkSelector)}
    checkmarkSelector={checkmarkSelector}
    accessibilityRole="checkbox"
    accessibilityState={{
      checked: isSelected,
    }}
    accessibilityElementsHidden={accessibilityHide}
    importantForAccessibility={accessibilityHide ? 'no-hide-descendants' : undefined}
    marginRightEnabled={marginRightEnabled}
    testID={testID}
  >
    <RadioButtonOuterCircle
      dimensions={dimensions}
      isSelected={isSelected}
      checkmarkSelector={checkmarkSelector}
      color={color}
      testID={isSelected ? `${testID}-Checked` : undefined}
    >
      {checkmarkSelector ? (
        <RadioButtonCheckMark>
          <Icon
            name="checkmark"
            width={dimensions ? dimensions / 2 : HORIZONTAL_WRAPPER_PADDING}
            height={dimensions ? dimensions / 2 : VERTICAL_WRAPPER_NATIVE_PADDING}
            fill={theme.COLORS.SECONDARY_BACKGROUND}
          />
        </RadioButtonCheckMark>
      ) : (
        <RadioButtonInnerCircle />
      )}
    </RadioButtonOuterCircle>
    {label && (
      <RadioButtonLabelWrapper>
        <Body>{label}</Body>
      </RadioButtonLabelWrapper>
    )}
  </Wrapper>
);

RadioButton.propTypes = {
  dimensions: PropTypes.number,
  label: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
  border: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  checkmarkSelector: PropTypes.bool,
  color: PropTypes.string,
  accessibilityHide: PropTypes.bool,
  marginRightEnabled: PropTypes.bool,
  testID: PropTypes.string,
};

RadioButton.defaultProps = {
  onPress: () => {},
  dimensions: RADIO_BUTTON_CIRCLE_SIZE,
  border: true,
  checkmarkSelector: false,
  color: undefined,
  label: undefined,
  accessibilityHide: false,
  marginRightEnabled: true,
  testID: undefined,
};

export default withTheme(RadioButton);
