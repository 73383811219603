/* eslint-disable react/prop-types */
import React from 'react';
import { formatMoney, getGrossAmountOrAmount } from 'helpers/string';
import i18n from 'services/i18n';
import * as routeNames from 'constants/routeNames';
import Description from './DeathDescription';
import Content from 'areas/dashboard/Content';
import getIncomeBar from '../getIncomeBar';
import getMenuItems from '../getMenuItems';
import { DEATH } from '../../constants/dashboardTypes';

const BAR_PLACEHOLDER_HEIGHT = 0.66;

export default ({
  navigation,
  payoutDeath,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  theme,
  awardRules,
  hasChildrenPayouts,
  awardRulesSucceeded,
  showGrossAmount,
}) => {
  /* TODO: Reintroduce logic when link children is available
  const checkForLinkableChildren = useCheckForLinkableChildren();
  */

  const menuItems = getMenuItems({
    navigation,
    payouts: payoutDeath,
    area: DEATH,
    payoutsRoute: routeNames.DASHBOARD_PAYOUTS_DEATH,
    payoutsOriginRoute: routeNames.PAYOUTS_ORIGIN,
    awardRulesRoute: routeNames.AWARD_RULES_DEATH,
    checkForLinkableChildren: false, // TODO: Insert checkForLinkableChildren here
    childrenPayoutsRoute: hasChildrenPayouts ? routeNames.PAYOUTS_CHILDREN : undefined,
  });

  const payout = getGrossAmountOrAmount(payoutDeath, showGrossAmount);

  return [
    getIncomeBar({
      incomeToday,
      totalIncomeToday,
      childAndYouthBenefitToday,
      i18n,
      theme,
      testIDPrefix: DEATH,
      showGrossAmount,
      menuItems,
    }),
    {
      id: '1',
      testID: 'deathBar',
      bar: payoutDeath
        ? {
            amount: formatMoney(payout, true),
            value: payout.amount,
            description: i18n.t('dashboard|payoutDeath'),
            color: theme.COLORS.GRAPH_NEUTRAL,
            trackId: 'PayoutDeath',
          }
        : {
            centerLabel: '?',
            amount: '',
            value: incomeToday && incomeToday.amount * BAR_PLACEHOLDER_HEIGHT,
            description: i18n.t('dashboard|payoutDeath'),
            color: theme.COLORS.GRAPH_NEUTRAL,
          },
      content: {
        component: (
          <Content
            testID="deathContent"
            description={
              <Description awardRules={awardRules} awardRulesSucceeded={awardRulesSucceeded} />
            }
            menuItems={menuItems}
          />
        ),
      },
    },
  ];
};
