import React from 'react';
import Conditional from 'decorators/conditional';
import PropTypes from 'prop-types';
import Icon from '@sp/ui/base/Icon';

const CLOSE_BUTTON_MOBILE_SIZE = 14;

const CloseIconMobile = () => (
  <Icon name="close" width={CLOSE_BUTTON_MOBILE_SIZE} height={CLOSE_BUTTON_MOBILE_SIZE} />
);
const CloseIconDefault = ({ theme }) => (
  <Icon name="close" width={theme.SPACINGS.lg} height={theme.SPACINGS.lg} />
);

CloseIconDefault.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default Conditional({
  mobile: CloseIconMobile,
  default: CloseIconDefault,
});
