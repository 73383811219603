import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';

export const DROPDOWN_BODY_WIDTH = 288;

export const DropdownBodyMobile = styled.TouchableOpacity`
  width: 100%;
  margin: 0;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DropdownBody = Conditional({
  mobile: DropdownBodyMobile,
  default: styled(DropdownBodyMobile)`
    width: ${DROPDOWN_BODY_WIDTH}px;
  `,
});

export default DropdownBody;
