/*
This function creates a memorized selector
the first arguments are input selectors (or data) and the last 2 arguments
are the input selector fn and the memorization fn that does the custom equality check
*/
export const createMemoSelect = (...fns) => {
  let prev = [];
  let result;
  const eq = fns.pop();
  const acc = fns.pop();

  return (state) => {
    const next = fns.map((fn) => fn(state));
    if (eq(next, prev)) {
      return result;
    }

    prev = next;
    result = acc(...prev);
    return result;
  };
};
