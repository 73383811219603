import React, { useEffect } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

export const FADE_DURATION = 300;

type displayType = 'none' | 'flex';

const FadeInOut: React.FC<{ visible: boolean; zIndex?: number }> = ({
  visible = false,
  zIndex,
  children,
}) => {
  const opacity = useSharedValue(0);
  const display = useSharedValue('none');

  const fadeAnimation = useAnimatedStyle(() => ({
    opacity: opacity.value,
    display: display.value as displayType,
    zIndex,
  }));

  useEffect(() => {
    const opacityChange = visible ? 1 : 0;

    if (visible) {
      display.value = 'flex';
    }

    opacity.value = withTiming(
      opacityChange,
      {
        duration: FADE_DURATION,
        easing: Easing.bezier(0.42, 0, 0.58, 1),
      },
      (transitionEnded) => {
        if (transitionEnded && !visible) {
          display.value = 'none';
        }
      }
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible]);

  return <Animated.View style={[fadeAnimation]}>{children}</Animated.View>;
};

export default FadeInOut;
