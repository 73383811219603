import React from 'react';
import styled from 'styled-components/native';
import useI18n from 'hooks/useI18n';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Body, H3 } = require('@sp/ui/v2/typography');
const { default: Modal } = require('containers/v2/layout/Modal');

const ModalContent = styled.View`
  justify-content: center;
`;

const actions = (onPress: () => void, i18n: any) => [
  {
    text: i18n.t('dashboard|close'),
    mobilePosition: 'bottom',
    type: 'secondary',
    onPress,
  },
];

type Props = {
  visible?: boolean;
  onClose: () => void;
  title: string;
  body: string;
};

const DashboardModal: React.FC<Props> = ({ visible, onClose, title, body }) => {
  const { i18n } = useI18n();

  return (
    <Modal visible={visible} onRequestClose={onClose} actions={actions(onClose, i18n)}>
      <ModalContent>
        <H3 textAlign="center">{title}</H3>
        <Margin marginTop="md" />
        <Body textAlign="left">{body}</Body>
      </ModalContent>
    </Modal>
  );
};

DashboardModal.defaultProps = {
  visible: false,
};

export default DashboardModal;
