/*
 * Form is valid when:
 *
 * - No validation errors are found
 * - When form is pristine, and either phone or email is present on profile
 * - When form is dirty, and either phone or email was entered
 */
import { emailValidator, phoneNumberValidator } from '@sp/ui/helpers/validators';

export const isFormValid = (
  phoneNumber,
  email,
  form,
  isPhoneInputPristine,
  isEmailInputPristine,
  isPhoneValid,
  isEmailValid,
  areAllInputsMandatory
) => {
  const isFormPristine = isPhoneInputPristine && isEmailInputPristine;

  const isPristineWithPrefilledValues = areAllInputsMandatory
    ? isFormPristine && !!email && !!phoneNumber
    : isFormPristine && (!!email || !!phoneNumber);

  const hasNoValidationErrors = isPhoneValid && isEmailValid;

  const isFormPhoneValueValid = !isPhoneInputPristine && isPhoneValid && !!form?.phone?.phoneNumber;

  const isFormEmailValueValid = !isEmailInputPristine && isEmailValid && !!form.email;

  const hasValidEmail = (isEmailInputPristine && email) || isFormEmailValueValid;

  const hasValidPhone = (isPhoneInputPristine && !!phoneNumber) || isFormPhoneValueValid;

  return areAllInputsMandatory
    ? (isPristineWithPrefilledValues || (hasValidEmail && hasValidPhone)) && hasNoValidationErrors
    : (isPristineWithPrefilledValues || hasValidEmail || hasValidPhone) && hasNoValidationErrors;
};

export const checkEmailValidity = (areAllInputsMandatory, isEmailInputPristine, email, form) => {
  if (areAllInputsMandatory) {
    return isEmailInputPristine && email ? !!email : emailValidator(form?.email);
  }

  return !form.email ? true : emailValidator(form?.email);
};

export const checkPhoneValidity = (
  areAllInputsMandatory,
  isPhoneInputPristine,
  phoneNumber,
  form
) => {
  const { phoneNumber: number, countryCode: code } = form?.phone || {};

  if (areAllInputsMandatory) {
    return isPhoneInputPristine && phoneNumber ? !!phoneNumber : phoneNumberValidator(code, number);
  }

  return !number ? true : phoneNumberValidator(code, number);
};
