import React from 'react';
import PropTypes from 'prop-types';
import { CommonActions } from '@react-navigation/native';
import { dismiss } from 'helpers/navigation';
import features from 'features';
import withScreenContainer from 'decorators/withScreenContainer';
import NodeGenerator, { elementType } from '@sp/ui/cms/NodeGenerator';
import FlexPage from '@sp/ui/pages/FlexPage';
import Loader from '@sp/ui/base/Loader';
import { openMaps, phoneCall } from 'helpers/linking';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import ChildLoader from '@sp/ui/context/ChildLoader';
import { getPage } from 'actions/cms';

const Page = ({ page, goToPage, handleLink, i18n, isModal, navigation }) =>
  page ? (
    <ModalProvider isModal={isModal} onClose={dismiss(navigation)}>
      <NodeGenerator
        goToPage={goToPage}
        nodeDefinition={page}
        handleLink={handleLink}
        featureCheck={(feature) => features.isEnabled(feature)}
      />
    </ModalProvider>
  ) : (
    <FlexPage title={i18n.t('Henter')}>
      <ChildLoader>
        <Loader />
      </ChildLoader>
    </FlexPage>
  );

Page.propTypes = {
  navigation: PropTypes.object.isRequired,
  page: elementType,
  handleLink: PropTypes.func,
  goToPage: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  isModal: PropTypes.bool,
};

Page.defaultProps = {
  page: undefined,
  handleLink: undefined,
  goToPage: undefined,
  isModal: false,
};

const mapStateToProps = (state, props) => ({
  isModal: props.route.params?.isModal || false,
  routeName: props.route.name,
  id: props.route.params?.id,
});

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, props }) =>
    action(async () => {
      const { id, routeName, navigation } = props;
      const page = await props.dispatch(getPage(id, true));
      const goToPage = (pageId) => {
        navigation.push(routeName, {
          id: pageId,
        });
      };
      const handleLink = (type, target) => {
        switch (type) {
          case 'Phone': {
            phoneCall(target.replace(/\s/g, ''));
            break;
          }
          case 'Map':
            openMaps(target);
            break;
          case 'App':
            // TODO: this is temporary while we migrate the app versions
            navigation.dispatch(
              CommonActions.navigate({
                name: target === 'mailCreate' ? 'mail-create' : target,
              })
            );
            break;
          default:
            // eslint-disable-next-line no-console
            console.warn(`Unhandled link type ${type} for ${target}`);
        }
      };
      return {
        page,
        goToPage,
        handleLink,
      };
    }),
})(Page);
