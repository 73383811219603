import Conditional from 'decorators/conditional';
import Mobile from './index.mobile';
import Desktop from './index.desktop';

export { ActionShape } from './index.shared';

export default Conditional({
  default: Mobile,
  desktop: Desktop,
});
