import styled from 'styled-components/native';

const DOT_TEXT_WRAPPER_WIDTH = 25;
const TEXT_BOX_CONTAINER_PADDING = 12;
const TEXT_BOX_CONTAINER_PADDING_RIGHT = 30;

export const TextBoxWrapperWithPadding = styled.View`
  display: flex;
  flex-direction: row;
  padding-left: ${TEXT_BOX_CONTAINER_PADDING}px;
  padding-right: ${TEXT_BOX_CONTAINER_PADDING_RIGHT}px;
`;

export const Column = styled.View`
  flex: 1;
  flex-direction: column;
`;

export const DotTextWrapper = styled.View`
  width: ${DOT_TEXT_WRAPPER_WIDTH}px;
`;
