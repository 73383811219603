import React from 'react';
import PropTypes from 'prop-types';
import Tracking from 'services/tracking';
import { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import { storeSession } from 'actions/security';
import { doConsentsAndApiStatusFlow as doConsentsAndApiStatusFlowAction } from 'actions/onboarding';
import ScrollPage from '@sp/ui/v2/pages/ScrollPage';
import Content from 'areas/onboarding/components/Content';
import { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import * as routeNames from 'constants/routeNames';
import { isAndroid } from 'helpers/platform';

import { selectIsPersisted, selectSupportedPersistenceMechanism } from 'selectors/api';

const ICON_SIZE = 69;

const typesFactory = (i18n) => ({
  ['Face ID']: {
    name: i18n.t('onboarding|faceID'),
    icon: 'faceId',
    description: i18n.t('onboarding|faceIDDescription'),
    hintDescription: i18n.t('onboarding|faceIDHint'),
  },
  ['Touch ID']: {
    name: isAndroid ? i18n.t('onboarding|fingerprint') : i18n.t('onboarding|touchID'),
    icon: 'fingerprint',
    description: i18n.t('onboarding|touchIDDescription'),
    hintDescription: i18n.t('onboarding|touchIDHint'),
  },
  ['PassCode']: {
    name: i18n.t('onboarding|pinCode'),
    icon: 'password',
    description: i18n.t('onboarding|pinCodeDescription'),
    hintDescription: i18n.t('onboarding|pinCodeHint'),
  },
});

const Biometrics = ({ type, save, next, i18n, theme }) => {
  const types = typesFactory(i18n);
  const data = types[type];

  return (
    <SafeAreaWrapper>
      <ScrollPage
        hasBackButton={false}
        title={i18n.t('onboarding|biometricTitle')}
        testID="biometricScreen"
        actions={[
          {
            text: i18n.t('onboarding|useMitID'),
            type: 'secondary',
            testID: 'logInWithNemIDorMitIDBtn',
            mobilePosition: 'bottom-sticky',
            onPress: () => next(),
          },
          {
            text: i18n.t('onboarding|useBiometricType', { dataName: data.name }),
            testID: 'loginWithBiometricBtn',
            mobilePosition: 'bottom-sticky',
            onPress: async () => {
              await save(type);
            },
          },
        ]}
      >
        <Content
          title={i18n.t('onboarding|biometricHeading', { dataName: data.name })}
          body={`${data.description}\n\n${data.hintDescription}`}
          icon={{
            width: ICON_SIZE,
            height: ICON_SIZE,
            name: data.icon,
            fill: theme.COLORS.V2_PRIMARY_TEXT,
          }}
        />
      </ScrollPage>
    </SafeAreaWrapper>
  );
};

Biometrics.propTypes = {
  save: PropTypes.func,
  type: PropTypes.oneOf(['Touch ID', 'Face ID', 'PassCode']).isRequired,
  next: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

Biometrics.defaultProps = {
  save: undefined,
  next: undefined,
};

const mapStateToProps = (state, props) => ({
  ...props,
  type: selectSupportedPersistenceMechanism(state),
  isPersisted: selectIsPersisted(state),
});

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['onboarding'],
  init: ({ action, getProps }) => ({
    save: (type) =>
      action(
        async () => {
          const { dispatch } = getProps();
          Tracking.trackEvent(
            {
              area: routeNames.BIOMETRIC,
              action: 'Authentication',
            },
            { type }
          );
          await dispatch(storeSession());
          const { isPersisted } = getProps();
          if (isPersisted) {
            await dispatch(doConsentsAndApiStatusFlowAction());
          }
        },
        {
          loader: true,
        }
      ),
    next: () =>
      action(
        async () => {
          const { dispatch } = getProps();
          Tracking.trackEvent(
            {
              area: routeNames.BIOMETRIC,
              action: 'Authentication',
            },
            { choseNemID: true }
          );
          await dispatch(doConsentsAndApiStatusFlowAction());
        },
        {
          loader: true,
        }
      ),
  }),
})(withTheme(Biometrics));
