import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withForm } from 'react-formed';
import { TextInput as TextInputV1 } from '@sp/ui/base/inputs';
import { TextInput as TextInputV2 } from '@sp/ui/v2/base/inputs';
import { getValue } from './index.shared';

const Input = ({
  setValue,
  value,
  defaultValue,
  placeholder,
  onChangeText,
  getIsDirty,
  useV2,
  ...props
}) => {
  const [isDirty, setDirty] = useState(false);
  if (typeof getIsDirty === 'function') getIsDirty(isDirty);

  const TextInput = useV2 ? TextInputV2 : TextInputV1;

  return (
    <TextInput
      value={getValue(value, defaultValue, isDirty)}
      defaultValue={getValue(value, defaultValue, isDirty)}
      placeholder={placeholder}
      onChangeText={(newValue) => {
        setDirty(true);

        if (typeof onChangeText === 'function') {
          onChangeText(setValue, newValue);
        } else {
          setValue(newValue);
        }
      }}
      {...props}
    />
  );
};

Input.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
  getIsDirty: PropTypes.func,
  useV2: PropTypes.bool,
};

Input.defaultProps = {
  value: undefined,
  useV2: false,
  defaultValue: undefined,
  placeholder: '',
  onChangeText: undefined,
  getIsDirty: undefined,
};

export default withForm(Input);
