import { create } from '../helpers/reducers';
import {
  REQUEST_GET_BOOKING_OPTIONS,
  REQUEST_GET_BOOKING_APPOINTMENTS,
  RESET_BOOKING_SELECTION,
  RESPONSE_GET_BOOKING_OPTIONS,
  RESPONSE_GET_BOOKING_APPOINTMENTS,
  RESPONSE_POST_BOOKING_APPOINTMENT,
  FAILED_POST_BOOKING_APPOINTMENT,
  SET_APPOINTMENT_TYPE,
  SET_SELECTED_DATE,
  SET_SELECTED_ORGANIZER,
  SET_SELECTED_LOCATION,
  SET_SELECTED_APPOINTMENT,
  SET_SELECTED_TIME_SLOT,
  SET_SELECTED_BOOKING_TOPIC_INFO,
  SET_BOOKING_CONTACT_INFO,
} from 'constants/actionTypes';

const initialSelectionState = {
  bookingVariations: undefined,
  organizerVariations: undefined,
  selectedDate: undefined,
  selectedOrganizer: undefined,
  selectedLocation: undefined,
  selectedAppointment: undefined,
  selectedTimeSlot: undefined,
  selectedBookingTopicInfo: undefined,
  selectedAppointmentType: undefined,
  contactInfo: undefined,
  bookingConflict: false,
};

const createDefaults = (): any => ({
  options: undefined as any,
  ...initialSelectionState,
  bookedAppointments: [] as any[],
  isFetchingOptions: false,
  hasFetchedAppointments: false,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [REQUEST_GET_BOOKING_OPTIONS]: (state) => ({
      ...state,
      isFetchingOptions: false,
    }),
    [RESET_BOOKING_SELECTION]: (state) => ({
      ...state,
      ...initialSelectionState,
    }),
    [RESPONSE_GET_BOOKING_OPTIONS]: (state, payload) => {
      const options = payload.response;

      const { appointments, organizers, topicLists, personalAdvisor } = options;

      // NOTE: Wire up appointments to their respective lookup tables
      appointments.forEach((appointment: any) => {
        appointment.topics = topicLists.find(
          (topicList: any) => topicList.topicListId === appointment.topicListId
        ).topics;

        appointment.organizer = organizers.find(
          (organizer: any) => organizer.organizerId === appointment.organizerId
        );
      });

      // NOTE: We reset selections if the meeting the user chose is not available anymore
      const selectedAppointmentId = state.selectedAppointment?.appointment?.appointmentId;

      if (selectedAppointmentId) {
        const resetSelectionState = !appointments.some(
          ({ appointmentId }: any) => selectedAppointmentId === appointmentId
        );

        if (resetSelectionState) {
          state = {
            ...state,
            ...initialSelectionState,
          };
        }
      }

      return {
        ...state,
        isFetchingOptions: true,
        selectedOrganizer: personalAdvisor,
        options,
      };
    },
    [REQUEST_GET_BOOKING_APPOINTMENTS]: (state) => ({
      ...state,
      hasFetchedAppointments: false,
    }),
    [RESPONSE_GET_BOOKING_APPOINTMENTS]: (state, payload) => ({
      ...state,
      bookedAppointments: payload.response,
      hasFetchedAppointments: true,
    }),
    [RESPONSE_POST_BOOKING_APPOINTMENT]: (state) => ({
      ...state,
      hasFetchedAppointments: false,
    }),
    [FAILED_POST_BOOKING_APPOINTMENT]: (state, payload) => ({
      ...state,
      bookingConflict: payload.status === 409,
    }),
    [SET_APPOINTMENT_TYPE]: (state, payload) => {
      const { appointmentType } = payload;

      const bookingVariations = state.options.appointments.filter((appointment: any) =>
        appointment.appointmentTypes.includes(appointmentType)
      );

      const availableOrganizerIds = [
        ...new Set(bookingVariations.map(({ organizerId }: any) => organizerId)) as any,
      ];

      const organizerVariations = state.options.organizers.filter(({ organizerId }: any) =>
        availableOrganizerIds.includes(organizerId)
      );

      return {
        ...state,
        selectedAppointmentType: appointmentType,
        bookingVariations,
        organizerVariations,
      };
    },
    [SET_SELECTED_DATE]: (state, payload) => {
      const { selectedDate } = payload;
      return {
        ...state,
        selectedDate,
      };
    },
    [SET_SELECTED_ORGANIZER]: (state, payload) => {
      const { selectedOrganizer } = payload;
      return {
        ...state,
        selectedOrganizer: selectedOrganizer || state.options.personalAdvisor,
      };
    },
    [SET_SELECTED_LOCATION]: (state, payload) => {
      const { selectedLocation } = payload;

      if (!selectedLocation) {
        return {
          ...state,
          selectedLocation,
        };
      }

      const bookingVariations = state.bookingVariations.filter(
        (appointment: any) => appointment.location === selectedLocation
      );

      const organizerSet = bookingVariations.reduce(
        (acc: any, value: any) => acc.add(value.organizer),
        new Set()
      );

      const organizerVariations = Array.from(organizerSet);

      return {
        ...state,
        organizerVariations,
        selectedLocation,
      };
    },
    [SET_SELECTED_APPOINTMENT]: (state, payload) => {
      const { selectedAppointment } = payload;
      return {
        ...state,
        selectedAppointment,
      };
    },
    [SET_SELECTED_TIME_SLOT]: (state, payload) => {
      const { selectedTimeSlot } = payload;
      return {
        ...state,
        selectedTimeSlot,
      };
    },
    [SET_SELECTED_BOOKING_TOPIC_INFO]: (state, payload) => ({
      ...state,
      selectedBookingTopicInfo: payload,
    }),
    [SET_BOOKING_CONTACT_INFO]: (state, payload) => ({
      ...state,
      contactInfo: payload,
    }),
  },
  createDefaults
);

export type { State };
