import React from 'react';
import { Platform } from 'react-native';
import type { RootState } from 'store';
import { shallowEqual } from 'react-redux';
import {
  TAXATION_MUNICIPALITY_SELECTOR_BUTTON,
  TAXATION_MUNICIPALITY_SELECTOR_ITEM,
} from 'constants/testIds/dashboard/adjustCalculation';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import styled from 'styled-components/native';
import useI18n from 'hooks/useI18n';
import useAsyncInit from 'hooks/useAsyncInit';
import { CLICK_MUNICIPALITY } from 'areas/dashboard/trackingIds';
import { selectMunicipalCode } from 'selectors/dashboardForms';

const { Form, Group } = require('react-formed');
const { Body2 } = require('@sp/ui/v2/typography');
const { default: Tracking } = require('services/tracking');
const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Selector } = require('containers/form/Selector');
const { getMunicipalities } = require('actions/reference');
const { selectMunicipalities } = require('services/selectors/reference');

// Necessary not to overflow Selector
const OverflowHack = styled.View`
  ${Platform.OS === 'web' ? 'z-index: 2;' : ''}
`;

export const selectCommuneTax = (state: RootState) => ({
  municipalities: selectMunicipalities(state),
  municipalCode: selectMunicipalCode(state),
});

const CommuneTax: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const dispatch = useAppDispatch();

  const { municipalities, municipalCode } = useAppSelector(selectCommuneTax, shallowEqual);

  useAsyncInit(
    async () => {
      if (!municipalities) {
        await dispatch(getMunicipalities());
      }
    },
    [],
    {
      error: {
        type: 'blocking',
        retry: true,
      },
    }
  );

  return (
    <OverflowHack>
      <Margin marginTop="md">
        <Body2 secondaryColor>{i18n.t('dashboard|municipalTaxDescription')}</Body2>
        <Margin marginTop="md" />
        <Form name="adjustCalculation" resetOnMount>
          <Group name="tax">
            <Selector
              buttonTestID={TAXATION_MUNICIPALITY_SELECTOR_BUTTON}
              itemTestID={TAXATION_MUNICIPALITY_SELECTOR_ITEM}
              name="municipalCode"
              placeholder={i18n.t('dashboard|chooseMunicipal')}
              options={municipalities?.map(
                (municipalityInfo: { name: string; kommunekode: string }) => ({
                  name: municipalityInfo.name,
                  value: municipalityInfo.kommunekode,
                })
              )}
              defaultValue={municipalCode}
              search
              onSelect={(option: { value: string }) =>
                Tracking.trackEvent(CLICK_MUNICIPALITY, { skattekommuneKommunekode: option.value })
              }
            />
          </Group>
        </Form>
      </Margin>
    </OverflowHack>
  );
};

export default CommuneTax;
