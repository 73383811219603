import React from 'react';
import PropTypes from 'prop-types';
import { DashboardRow } from '@sp/ui/base/rows';

const DocumentRow = ({ document, onPress, index }) => (
  <DashboardRow
    arrow
    key={document.title}
    textLeft={document.title}
    iconLeft="pdf"
    testID={`documents${index}Link`}
    onPress={onPress}
  />
);

DocumentRow.propTypes = {
  document: PropTypes.shape({
    downloadUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onPress: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default DocumentRow;
