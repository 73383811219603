import * as routeNames from 'constants/routeNames';

export const VALID_DEEPLINK_ROUTES = [
  routeNames.PARTNER,
  routeNames.MEETING_PREPARATION,
  routeNames.DEPOSITS,
  routeNames.TRANSFER,
  routeNames.CHANGE_INVESTMENT_PROFILE,
];
export const DEEPLINK_SESSION = 'deeplink';
export const REMOVE_DEEPLINK_PARAMS = { flow: DEEPLINK_SESSION, remove: true };
