import React from 'react';
import PropTypes from 'prop-types';
import BasePage, {
  NarrowContent,
  WideContent,
  TopContent,
  Actions,
  ActionShape,
} from '@sp/ui/pages/BasePage';
import styled from 'styled-components/native';
import PageParts from '@sp/ui/PageParts';
import Margin from '@sp/ui/base/Margin';

const Wrapper = styled.View`
  background: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
`;

const CenterContentWrapper = styled.View`
  display: flex;
  align-items: center;
`;

const BackButtonWrapper = styled.View`
  background: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
`;

const GraphPage = ({
  heading,
  children,
  actions,
  wideContent,
  description,
  tabBar,
  backButton,
  hasBackButton,
  accessibilitySummary,
}) => (
  <BasePage accessibilitySummary={accessibilitySummary}>
    <Wrapper>
      <PageParts>
        {({ BackButton: GlobalBackButton }) => (
          <BackButtonWrapper>
            {hasBackButton &&
              (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />)}
          </BackButtonWrapper>
        )}
      </PageParts>
      {tabBar}
      <WideContent>
        {(heading || description) && <TopContent heading={heading} description={description} />}

        {wideContent}
      </WideContent>

      <CenterContentWrapper>
        <NarrowContent paddingTop={0}>
          {children}
          {actions && actions.length > 0 && (
            <Margin marginBottom="md">
              <Actions actions={actions} />
            </Margin>
          )}
        </NarrowContent>
      </CenterContentWrapper>
    </Wrapper>
  </BasePage>
);

GraphPage.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tabBar: PropTypes.node,
  children: PropTypes.node,
  wideContent: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  accessibilitySummary: PropTypes.string,
};

GraphPage.defaultProps = {
  tabBar: undefined,
  heading: undefined,
  description: undefined,
  children: undefined,
  wideContent: undefined,
  actions: undefined,
  backButton: undefined,
  hasBackButton: true,
  accessibilitySummary: undefined,
};

export default GraphPage;
