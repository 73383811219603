import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
const { default: Tracking } = require('services/tracking');

type TrackingEvent = {
  area: string;
  action: string;
  options?: any;
  sendInfo?: boolean;
};

export default (trackingEvent: TrackingEvent, params?: { [key: string]: string }) => {
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      Tracking.trackEvent(trackingEvent, params);
    }
  }, [trackingEvent, params, isFocused]);
};
