import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@sp/ui/base/Icon';
import styled, { withTheme } from 'styled-components/native';
import { Body, H6, Subtitle } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import withComponentContainer from 'decorators/withComponentContainer';
import { selectIsDeviceTypeMobile } from 'selectors/device';

import { IconContainer, ItemHeader } from '../styles';

const SubtitleWrapper = styled.View`
  flex-direction: row;
`;

const SubtitleTextPaddingLeft = styled.View`
  padding-left: ${(props) => props.theme.SPACINGS.md}px;
`;

const ReadMoreWrapper = styled.View`
  flex: 1;
`;

const getSubtitle = (subtitles) =>
  subtitles && (
    <Margin marginTop="xs">
      {subtitles.map((item) => (
        <SubtitleWrapper key={item}>
          <Subtitle>•</Subtitle>
          <SubtitleTextPaddingLeft>
            <Subtitle>{item}</Subtitle>
          </SubtitleTextPaddingLeft>
        </SubtitleWrapper>
      ))}
    </Margin>
  );

const CardItem = ({ description, iconType, subtitles, title, theme, withoutMarginBottom }) => (
  <>
    {title && (
      <Margin marginBottom="sm">
        <H6>{title}</H6>
      </Margin>
    )}
    <Margin marginBottom={withoutMarginBottom ? '' : 'md'}>
      <ItemHeader>
        <IconContainer>
          {iconType === 'warning' ? (
            <Icon name="exclamationMarkFilled" fill={theme.COLORS.PRIMARY_WARNING} />
          ) : (
            <Icon name="checkmarkSuccess" fill={theme.COLORS.PRIMARY_SUCCESS} />
          )}
        </IconContainer>
        <ReadMoreWrapper>
          <Body>{description}</Body>
          {getSubtitle(subtitles)}
        </ReadMoreWrapper>
      </ItemHeader>
    </Margin>
  </>
);

CardItem.propTypes = {
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  iconType: PropTypes.oneOf(['warning', 'success']),
  subtitles: PropTypes.array,
  withoutMarginBottom: PropTypes.bool,
};

CardItem.defaultProps = {
  iconType: 'warning',
  title: undefined,
  subtitles: undefined,
  withoutMarginBottom: false,
};

const mapStateToProps = (state) => ({
  isMobile: selectIsDeviceTypeMobile(state),
});

const WrappedCollapsibleItem = withComponentContainer({
  hasLocalNotifications: false,
  mapStateToProps,
})(CardItem);

export default withTheme(WrappedCollapsibleItem);
