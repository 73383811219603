import React from 'react';
import PropTypes from 'prop-types';

const MouseView = ({ onMouseEnter, onMouseLeave, children }) => (
  <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    {children}
  </div>
);

MouseView.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

MouseView.defaultProps = {};

export default MouseView;
