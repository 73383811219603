import 'core-js';
import 'regenerator-runtime/runtime';
import { AppRegistry } from 'react-native';
import App from 'containers/App';
import '../web/style/style.css';

const rootTag = global.document.getElementById('react-root');

/* eslint-disable no-console */
const orgError = console.error;
console.error = (err = '', ...args) => {
  const message = err.toString();
  if (
    !message.includes('prop on a DOM') &&
    !message.includes('Invalid DOM property') &&
    !message.includes('Invalid prop `zIndex`') // NOTE: Added as styled components set index as string instead of number
  ) {
    orgError(err, ...args);
  }
};
/* eslint-enable no-console */

const mount = (tag) => {
  global.__webappRootTag = tag;
  AppRegistry.registerComponent('Sampension', () => App);
  AppRegistry.runApplication('Sampension', {
    rootTag: tag,
  });
};

if (rootTag) {
  mount(rootTag);
}

export { mount };
