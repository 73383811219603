import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled from 'styled-components/native';
import fontStyles from '@sp/ui/typography/fontStyles';
import { isWeb } from '@sp/ui/helpers/device';

export const getColor = (theme, color, secondaryColor, linkColor, isDesktop) => {
  if (secondaryColor) {
    return theme.COLORS.V2_SECONDARY_TEXT;
  }

  if (linkColor) {
    return theme.COLORS.V2_SECONDARY_LINK;
  }

  if (isDesktop) {
    return color || theme.COLORS.V2_SECONDARY_TEXT;
  }

  return color || theme.COLORS.V2_PRIMARY_TEXT;
};

const withColor = Conditional({
  default: styled.Text`
    color: ${({ theme, color, secondaryColor, linkColor }) =>
      getColor(theme, color, secondaryColor, linkColor, false)};
  `,
  desktop: styled.Text`
    color: ${({ theme, color, secondaryColor, linkColor }) =>
      getColor(theme, color, secondaryColor, linkColor, true)};
  `,
});

export const withDefaultTypographyProps = styled(withColor)`
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
  ${(props) => fontStyles(props.fontWeight)};
  font-style: ${(props) => props.fontStyle};
`;

withDefaultTypographyProps.defaultProps = {
  color: undefined,
  textAlign: 'left',
  selectable: true,
  fontStyle: 'normal',
  fontWeight: isWeb ? 'normal' : 'semibold',
};

withDefaultTypographyProps.propTypes = {
  color: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'semibold', 'bold']),
};

export const withDefaultTypographyInputProps = styled.TextInput`
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
  ${(props) => fontStyles(props.fontWeight)};
  ${isWeb ? 'outline-style: none;' : ''}
  color: ${(props) => props.color || props.theme.DEFAULTS.TEXT_COLOR};
`;

withDefaultTypographyProps.propTypes = {
  color: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'semibold', 'bold']),
};

withDefaultTypographyInputProps.defaultProps = {
  color: undefined,
  textAlign: 'left',
};
