import { selectProfileData } from 'selectors/profile';
import type { FetchTaxAndPensionsInfoState } from 'reducers/fetchTaxAndPensionsInfo';

interface StoreSlice {
  areas: { fetchTaxAndPensionsInfo: FetchTaxAndPensionsInfoState };
}

export const selectHasFetchTaxAndPensionsInfoData = (state: StoreSlice) =>
  Object.keys(state.areas.fetchTaxAndPensionsInfo ?? {}).length > 0;
export const selectFetchTax = (state: StoreSlice) => state.areas.fetchTaxAndPensionsInfo?.fetchTax;
export const selectHasFetchedTax = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.hasFetchedTax;
export const selectFetchPensionsInfo = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.fetchPensionsInfo;
export const selectHasFetchedPensionsInfo = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.hasFetchedPensionsInfo;
export const selectFetchFailType = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.fetchFailType;
export const selectTaxFetchingStatus = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.taxFetchingStatus;
export const selectPensionsInfoFetchingStatus = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.pensionsInfoFetchingStatus;
export const selectOnDoneRouteConfig = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.onDoneRouteConfig;
export const selectHasSkippedTax = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.hasSkippedTax;
export const selectHasSkippedPensionsInfo = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.hasSkippedPensionsInfo;
export const selectIsOnboarding = (state: StoreSlice) =>
  state.areas.fetchTaxAndPensionsInfo?.isOnboarding;

export const selectIsFetchingTaxOrPensionsInfo = (state: StoreSlice) => {
  const isFetchingTax = selectFetchTax(state);
  const isFetchingPensionsInfo = selectFetchPensionsInfo(state);

  return isFetchingTax || isFetchingPensionsInfo;
};

export const selectIsTaxStatementValid = (state: StoreSlice) => {
  const { isTaxStatementValid }: any = selectProfileData(state as any) ?? {};

  return typeof isTaxStatementValid === 'undefined' ? true : isTaxStatementValid;
};

export const selectShouldFetchPensionsInfo = (state: StoreSlice) => {
  const fetchPensionsInfo = selectFetchPensionsInfo(state);
  const hasFetchedPensionsInfo = selectHasFetchedPensionsInfo(state);

  return fetchPensionsInfo && !hasFetchedPensionsInfo;
};

export const selectShouldFetchTax = (state: StoreSlice) => {
  const fetchTax = selectFetchTax(state);
  const hasFetchedTax = selectHasFetchedTax(state);

  return fetchTax && !hasFetchedTax;
};

export const selectShouldRetryToFetchTax = (state: StoreSlice) => {
  const hasFetchedPensionsInfo = selectHasFetchedPensionsInfo(state);
  const fetchFailType = selectFetchFailType(state);

  return fetchFailType && !hasFetchedPensionsInfo;
};

export const selectIsFlowTax = (state: StoreSlice) => {
  const shouldFetchTax = selectShouldFetchTax(state);
  const hasSkippedTax = selectHasSkippedTax(state);
  const shouldRetryToFetchTax = selectShouldRetryToFetchTax(state);

  return shouldRetryToFetchTax || (shouldFetchTax && !hasSkippedTax);
};
