import React from 'react';
import styled from 'styled-components/native';
import { useAppSelector } from 'hooks/redux';
import { selectIsDeviceTypeTablet } from 'selectors/device';

const Wrapper = styled.View<{ ignoreVerticalPadding: boolean | undefined }>`
  justify-content: center;
  padding: ${({ theme, ignoreVerticalPadding }: any) =>
    ignoreVerticalPadding ? `${theme.SPACINGS.lg}px 0px` : `${theme.SPACINGS.lg}px`}
  width: 100%;
`;

type PageContentWrapperProps = {
  children: React.ReactNode;
  ignoreDefaultVerticalPadding?: boolean;
};

const PageContentWrapper = ({
  children,
  ignoreDefaultVerticalPadding,
}: PageContentWrapperProps) => {
  const isTablet = useAppSelector(selectIsDeviceTypeTablet);

  return (
    <Wrapper ignoreVerticalPadding={ignoreDefaultVerticalPadding && !isTablet}>{children}</Wrapper>
  );
};

PageContentWrapper.defaultProps = {
  ignoreDefaultVerticalPadding: false,
};

export default PageContentWrapper;
