import React from 'react';
import RadioButtonGroup from '@sp/ui/v2/base/inputs/RadioButtonGroup';
import type { AddOrEditChildFormProps } from 'areas/dashboard/AdjustCalculation/Children/AddOrEditChildForm';

const { withForm } = require('react-formed');

type RadioButtonFormProps = AddOrEditChildFormProps & {
  name: string;
  onPress: (indexOrTracking: number | void) => void;
  setValue: (value: boolean) => void;
};

const RadioButtonForm: React.FC<RadioButtonFormProps> = ({ setValue, onPress, ...props }) => (
  <RadioButtonGroup
    onPress={(i: number) => {
      const { value } = props.radioProps[i];

      if (typeof onPress === 'function') {
        onPress(i);
      }

      setValue(value);
    }}
    {...props}
  />
);

export default withForm(RadioButtonForm);
