import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { layoutSpecs } from 'areas/dashboard/styles';
import GraphComp from '@sp/ui/base/Graph';
import { getGrossAmountOrAmount } from 'helpers/string';
import Margin from '@sp/ui/base/Margin';
import withComponentContainer from 'decorators/withComponentContainer';
import { Dimensions } from 'react-native';
import {
  PAYOUTS_DETAILS_ACCUMULATED_GRAPH_SCREEN,
  PAYOUTS_DETAILS_OVER_TIME_GRAPH_SCREEN,
} from 'constants/testIds/dashboard';
import { getGraphLabels } from 'areas/dashboard/payouts/payoutsOverTime/helpers';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { payoutTypes } from 'areas/dashboard/props';
import GraphLabels from 'areas/dashboard/GraphLabels';
import {
  getMaxGraphWidth,
  getCenterContent,
  getYearlyPayoutBar,
  getIncomeBar,
  getAveragePayoutBar,
  getBarColor,
} from 'areas/dashboard/helpers';
import PayoutsModal from 'areas/dashboard/PayoutsModal';
import {
  getOneTimePayoutBar,
  reCalculateAverageValue,
} from 'areas/dashboard/payouts/payoutsAll/helpers';
import {
  selectIncomeToday,
  selectTotalIncomeToday,
  selectChildAndYouthBenefitToday,
  selectMaxPayout,
  selectNoRecommendationType,
  selectShowGrossAmount,
  selectRetirementWealth,
} from 'selectors/dashboard';
import { DEATH, RETIREMENT } from '../constants/dashboardTypes';

const window = Dimensions.get('window');

const Container = styled.View`
  align-items: center;
`;

const Graph = ({
  i18n,
  theme,
  isDesktop,
  payouts,
  payoutType,
  wealthAtPensionAge,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  showGrossAmount,
  maxPayout,
  incomeWhenRetired,
  noRecommendationType,
  showAverageBar,
}) => {
  const [dimensions, setDimensions] = useState({ window });
  const [showAverageModal, setShowAverageModal] = useState(false);

  const onChange = ({ window: windowArg }) => {
    setDimensions({ window: windowArg });
  };

  useEffect(() => {
    const subcription = Dimensions.addEventListener('change', onChange);
    return () => {
      subcription.remove();
    };
  });

  const GRAPH_PADDING = theme.SPACINGS.xl;

  const maxGraphWidth = getMaxGraphWidth(
    isDesktop,
    payouts,
    layoutSpecs.labelWidth,
    GRAPH_PADDING,
    dimensions.window.width
  );

  const pageWidth = dimensions.window.width - GRAPH_PADDING;
  const centerContent = getCenterContent(
    payouts,
    layoutSpecs.labelWidth,
    pageWidth,
    maxGraphWidth,
    GRAPH_PADDING
  );

  const bars = [
    getIncomeBar({
      theme,
      i18n,
      incomeToday,
      totalIncomeToday,
      childAndYouthBenefitToday,
      showGrossAmount,
    }),
  ];
  let averageBarColor;
  let averageValue;
  let recalculatedMaxPayout = maxPayout;

  if (showAverageBar) {
    averageValue = getGrossAmountOrAmount(incomeWhenRetired, showGrossAmount);

    if (averageValue.amount > 0) {
      averageBarColor =
        payoutType === DEATH
          ? theme.COLORS.GRAPH_NEUTRAL
          : getBarColor(incomeWhenRetired?.pensionVsTodayRatio, payoutType, noRecommendationType);

      recalculatedMaxPayout = Math.max(maxPayout, averageValue.amount);

      bars.push(
        getAveragePayoutBar({
          theme,
          i18n,
          income: averageValue,
          onSelect: payoutType === RETIREMENT ? () => setShowAverageModal(true) : undefined,
          averageBarColor,
        })
      );
    }
  }

  bars.push(
    ...payouts?.map((i) =>
      i.oneTimePayout
        ? getOneTimePayoutBar(i, recalculatedMaxPayout, theme, payoutType, i18n, showGrossAmount)
        : getYearlyPayoutBar({ i, theme, payoutType, i18n, showGrossAmount })
    )
  );

  const hasOneTimePayouts = payouts?.some(({ oneTimePayout }) => oneTimePayout);

  return (
    <>
      <Container
        centerContent={centerContent}
        testID={
          hasOneTimePayouts
            ? PAYOUTS_DETAILS_ACCUMULATED_GRAPH_SCREEN
            : PAYOUTS_DETAILS_OVER_TIME_GRAPH_SCREEN
        }
      >
        <Margin marginTop="md" />
        <GraphComp
          maxValue={recalculatedMaxPayout}
          centerContent
          showControls
          withScroll
          labelWidth={layoutSpecs.labelWidth}
          labelHeight={layoutSpecs.labelHeight}
          height={layoutSpecs.graphHeight}
          maxWidth={maxGraphWidth}
          paddingLeftAndRight={centerContent ? GRAPH_PADDING : GRAPH_PADDING / 2}
          bars={bars}
          prevBarAccessibilityLabel={i18n.t('dashboard|prevBarA11y')}
          nextBarAccessibilityLabel={i18n.t('dashboard|nextBarA11y')}
          averageBorder={
            showAverageBar && {
              value: reCalculateAverageValue(averageValue?.amount, recalculatedMaxPayout),
              startFromBarNo: 1,
              color: averageBarColor,
            }
          }
        />
        <GraphLabels
          labels={getGraphLabels(
            wealthAtPensionAge,
            theme.COLORS,
            payoutType,
            i18n,
            payouts,
            showAverageBar,
            averageBarColor
          )}
        />
      </Container>
      {payoutType === RETIREMENT && (
        <PayoutsModal
          showModal={showAverageModal}
          onClose={() => setShowAverageModal(false)}
          title={i18n.t('dashboard|averagePayoutsModalTitle')}
          description={i18n.t('dashboard|averagePayoutsModalDescription')}
        />
      )}
    </>
  );
};

Graph.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  payoutType: PropTypes.oneOf(payoutTypes).isRequired,
  isDesktop: PropTypes.bool.isRequired,
  payouts: PropTypes.array,
  wealthAtPensionAge: PropTypes.number.isRequired,
  incomeWhenRetired: PropTypes.object,
  incomeToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  totalIncomeToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  childAndYouthBenefitToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }),
  showGrossAmount: PropTypes.bool.isRequired,
  maxPayout: PropTypes.number,
  noRecommendationType: PropTypes.string,
  showAverageBar: PropTypes.bool,
};

Graph.defaultProps = {
  payouts: undefined,
  incomeWhenRetired: undefined,
  incomeToday: {
    amount: 0,
    currencyCode: 'DKK',
  },
  totalIncomeToday: undefined,
  childAndYouthBenefitToday: undefined,
  maxPayout: undefined,
  noRecommendationType: undefined,
  showAverageBar: false,
};

const mapStateToProps = (state, props) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
  showGrossAmount: selectShowGrossAmount(state),
  incomeToday: selectIncomeToday(state),
  totalIncomeToday: selectTotalIncomeToday(state),
  childAndYouthBenefitToday: selectChildAndYouthBenefitToday(state),
  maxPayout: selectMaxPayout(state, props.payoutType),
  wealthAtPensionAge: selectRetirementWealth(state),
  noRecommendationType: selectNoRecommendationType(state, props.payoutType),
});

export default withComponentContainer({
  hasLocalNotifications: false,
  mapStateToProps,
})(withTheme(Graph));
