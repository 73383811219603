import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from 'decorators/i18n';
import Margin from '@sp/ui/base/Margin';
import { Subtitle } from '@sp/ui/typography';
import AllPaymentYears from './AllPaymentYears';
import DepositsBrowser from '../browsers/DepositsBrowser';

const DepositContent = ({
  showAllYears,
  savingsDeposits,
  deposits,
  goToSpecificPaymentYear,
  i18n,
}) => {
  if (showAllYears) {
    return (
      <AllPaymentYears
        savingsDeposits={savingsDeposits}
        goToSpecificPaymentYear={goToSpecificPaymentYear}
      />
    );
  }

  return deposits?.length > 0 ? (
    <DepositsBrowser data={deposits} />
  ) : (
    <Margin vertical="xxl">
      <Subtitle textAlign="center">{i18n.t('savings|Ingen indbetalinger for dette år.')}</Subtitle>
    </Margin>
  );
};

DepositContent.propTypes = {
  showAllYears: PropTypes.bool,
  savingsDeposits: PropTypes.array,
  deposits: PropTypes.array,
  goToSpecificPaymentYear: PropTypes.func,
  i18n: PropTypes.object.isRequired,
};

DepositContent.defaultProps = {
  showAllYears: false,
  savingsDeposits: undefined,
  deposits: undefined,
  goToSpecificPaymentYear: undefined,
};

export default withTranslation()(DepositContent);
