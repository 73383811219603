import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withForm } from 'react-formed';
import { TextMessage } from '@sp/ui/base/inputs';
import { getValue } from './index.shared';

const Message = ({
  setValue,
  value,
  defaultValue,
  placeholder,
  attachments,
  onChangeText,
  ...props
}) => {
  const [isDirty, setDirty] = useState(false);
  return (
    <TextMessage
      value={getValue(value, defaultValue, isDirty)}
      defaultValue={getValue(value, defaultValue, isDirty)}
      placeholder={placeholder}
      attachments={attachments}
      onChangeText={(newValue) => {
        setDirty(true);

        if (typeof onChangeText === 'function') {
          onChangeText(setValue, newValue);
        } else {
          setValue(newValue);
        }
      }}
      {...props}
    />
  );
};

Message.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
  attachments: PropTypes.array,
};

Message.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  placeholder: '',
  onChangeText: undefined,
  attachments: undefined,
};

export default withForm(Message);
