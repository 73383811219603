import { selectPayoutDeath } from 'selectors/dashboard';
import { fetchDeath } from 'actions/digital-advice';
import { createPage } from './Payouts';
import { DEATH } from '../constants/dashboardTypes';

export default createPage({
  getPayoutsAction: fetchDeath,
  selectPayouts: selectPayoutDeath,
  payoutType: DEATH,
});
