import React from 'react';
import useI18n from 'hooks/useI18n';
import FormSectionWithModal from '@sp/ui/v2/layout/Forms/FormSectionWithModal';
import RadioButtonForm from 'containers/form/RadioButtonForm';
import ModalContent from './ModalContent';
import { useAppSelector } from 'hooks/redux';
import { selectHasChildAndYouthBenefit } from 'selectors/dashboardForms';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Body2 } = require('@sp/ui/v2/typography');
const { Form, Group } = require('react-formed');

type Props = {
  title: string;
};

const ChildAndYouthBenefit: React.FC<Props> = ({ title }) => {
  const { i18n } = useI18n(['dashboard']);
  const hasChildAndYouthBenefit = useAppSelector(selectHasChildAndYouthBenefit);

  const radioProps = [
    {
      label: i18n.t('yes'),
      isSelected: hasChildAndYouthBenefit,
      value: true,
      tracking: 'ClickChildAndYouthBenefitIncludeYes',
    },
    {
      label: i18n.t('no'),
      isSelected: !hasChildAndYouthBenefit,
      value: false,
      tracking: 'ClickChildAndYouthBenefitIncludeNo',
    },
  ];

  return (
    <FormSectionWithModal
      disablePadding
      title={
        <Body2 secondaryColor textAlign="center">
          {title}
        </Body2>
      }
      description={
        <Body2 secondaryColor fontWeight="regular">
          {i18n.t('dashboard|childAndYouthBenefitDescription')}
        </Body2>
      }
      infoAccessibilityLabel={i18n.t('dashboard|childAndYouthBenefitInfoA11y')}
      modalContent={
        <ModalContent
          texts={[
            {
              h3: i18n.t('dashboard|childAndYouthBenefit'),
            },
            {
              body: i18n.t('dashboard|childAndYouthBenefitModalDescriptionIncluded'),
            },
            {
              h5: i18n.t('dashboard|childAndYouthBenefitModalTitleIncomeToday'),
            },
            {
              body: i18n.t('dashboard|childAndYouthBenefitModalDescriptionIncomeToday'),
            },
            {
              h5: i18n.t('dashboard|childAndYouthBenefitModalTitlePension'),
            },
            {
              body: i18n.t('dashboard|childAndYouthBenefitModalDescriptionPension'),
            },
            {
              h5: i18n.t('dashboard|childAndYouthBenefitModalTitleInvalidity'),
            },
            {
              body: i18n.t('dashboard|childAndYouthBenefitModalDescriptionInvalidity'),
            },
          ]}
        />
      }
    >
      <Margin marginTop="md" marginBottom="s">
        <Form name="adjustCalculation">
          <Group name="publicBenefits">
            <RadioButtonForm
              name="hasChildAndYouthBenefit"
              leftAlignRadioButton
              radioProps={radioProps}
            />
          </Group>
        </Form>
      </Margin>
    </FormSectionWithModal>
  );
};

export default ChildAndYouthBenefit;
