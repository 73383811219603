import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import Header, { Button } from '@sp/ui/layout/Header';
import { ActionShape } from '@sp/ui/pages/BasePage';
import { useAppSelector } from 'hooks/redux';
import { selectDeeplink } from 'selectors/deeplink';

const GlobalHeader = ({
  title,
  actions,
  backButton,
  accessibilitySummary,
  animation,
  animateTitle,
}) => {
  const navigation = useNavigation();
  const { isDeeplink } = useAppSelector(selectDeeplink, shallowEqual);
  const rightItems = actions.map((action) => {
    if (isValidElement(action.element)) return action.element;

    return (
      <Button
        key={action.text || action.icon}
        text={action.icon ? undefined : action.text}
        {...action}
      />
    );
  });

  return (
    <Header
      visible={!isDeeplink}
      onPressBack={navigation.goBack}
      leftContent={backButton}
      title={title}
      rightContent={rightItems}
      accessibilitySummary={accessibilitySummary}
      animation={animation}
      animateTitle={animateTitle}
    />
  );
};

GlobalHeader.propTypes = {
  title: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  actions: PropTypes.arrayOf(ActionShape),
  backButton: PropTypes.node,
  animation: PropTypes.object,
  animateTitle: PropTypes.bool,
};

GlobalHeader.defaultProps = {
  title: undefined,
  accessibilitySummary: undefined,
  actions: [],
  backButton: undefined,
  animation: undefined,
  animateTitle: false,
};

export default GlobalHeader;
