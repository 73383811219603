import React, { useMemo } from 'react';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store';
import { shallowEqual } from 'react-redux';
import useI18n from 'hooks/useI18n';
import FormSectionWithModal from '@sp/ui/v2/layout/Forms/FormSectionWithModal';
import RadioButtonForm from 'containers/form/RadioButtonForm';
import ModalContent from './ModalContent';
import { selectHasFolkPension, selectHasFolkPensionSupplement } from 'selectors/dashboardForms';
import { selectRetirementAge } from 'selectors/dashboard';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Body2 } = require('@sp/ui/v2/typography');
const { Form, Group } = require('react-formed');

type Props = {
  title: string;
  type: string;
};

const selectPensionBasicAmountOrSupplement = (state: RootState) => ({
  hasFolkPension: selectHasFolkPension(state),
  hasFolkPensionSupplement: selectHasFolkPensionSupplement(state),
  retirementAge: selectRetirementAge(state),
});

const PensionBasicAmountOrSupplement: React.FC<Props> = ({ title, type }) => {
  const { i18n } = useI18n(['dashboard']);
  const { retirementAge, hasFolkPension, hasFolkPensionSupplement } = useAppSelector(
    selectPensionBasicAmountOrSupplement,
    shallowEqual
  );

  const isTypePensionBasicAmount = type === 'pensionBasicAmount';

  const radioProps = useMemo(
    () => [
      {
        label: i18n.t('yes'),
        isSelected: isTypePensionBasicAmount ? hasFolkPension : hasFolkPensionSupplement,
        value: true,
      },
      {
        label: i18n.t('no'),
        isSelected: isTypePensionBasicAmount ? !hasFolkPension : !hasFolkPensionSupplement,
        value: false,
      },
    ],
    [i18n, isTypePensionBasicAmount, hasFolkPension, hasFolkPensionSupplement]
  );

  return (
    <FormSectionWithModal
      disablePadding
      title={
        <Body2 secondaryColor textAlign="center">
          {title}
        </Body2>
      }
      description={
        <Body2 secondaryColor fontWeight="regular">
          {i18n.t('dashboard|publicBenefitsModalTitle', { retirementAge })}
        </Body2>
      }
      infoAccessibilityLabel={i18n.t('dashboard|publicBenefitsModalA11y')}
      modalContent={
        <ModalContent
          texts={[
            {
              h3: i18n.t('dashboard|pension'),
            },
            {
              body: i18n.t('dashboard|publicBenefitsModalDescription'),
            },
            {
              h5: i18n.t('dashboard|publicBenefitsModalSupplementTitle'),
            },
            {
              body: i18n.t('dashboard|publicBenefitsModalSupplementDescription'),
            },
            {
              h5: i18n.t('dashboard|publicBenefitsModalIncomeEffectTitle'),
            },
            {
              body: i18n.t('dashboard|publicBenefitsModalIncomeEffectDescription'),
            },
          ]}
        />
      }
    >
      <Margin marginTop="md">
        <Body2 secondaryColor fontWeight="regular">
          {isTypePensionBasicAmount
            ? i18n.t('dashboard|publicBenefitsContent')
            : i18n.t('dashboard|publicBenefitsSupplementContent')}
        </Body2>
        <Margin marginTop="md" marginBottom="s">
          <Form name="adjustCalculation">
            <Group name="publicBenefits">
              <RadioButtonForm
                name={isTypePensionBasicAmount ? 'hasFolkPension' : 'hasFolkPensionSupplement'}
                leftAlignRadioButton
                radioProps={radioProps}
              />
            </Group>
          </Form>
        </Margin>
      </Margin>
    </FormSectionWithModal>
  );
};

export default PensionBasicAmountOrSupplement;
