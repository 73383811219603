import React, { Component } from 'react';
import Tracking from 'services/tracking';

const trackAsHoc = (trackAs) => (WrappedComponent) =>
  class TrackAs extends Component {
    componentDidMount() {
      if (typeof trackAs === 'function') {
        trackAs = trackAs(this.props);
      }
      Tracking.trackEvent(trackAs, trackAs.params);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default trackAsHoc;
