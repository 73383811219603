import React from 'react';
import PropTypes from 'prop-types';
import Margin from '@sp/ui/base/Margin';
import { Body } from '@sp/ui/typography';

const ModalRegularText = ({ text, textAlign, removeMarginTop, fontStyle }) => (
  <>
    {!removeMarginTop && <Margin marginTop="md" />}
    <Body fontStyle={fontStyle} textAlign={textAlign || 'left'}>
      {text}
    </Body>
  </>
);

ModalRegularText.propTypes = {
  removeMarginTop: PropTypes.bool,
  fontStyle: PropTypes.string,
  textAlign: PropTypes.string,
  text: PropTypes.string.isRequired,
};

ModalRegularText.defaultProps = {
  textAlign: undefined,
  removeMarginTop: false,
  fontStyle: 'normal',
};

export default ModalRegularText;
