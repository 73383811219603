import { Linking } from 'react-native';
import { isWeb } from 'helpers/platform';
import * as routeNames from 'constants/routeNames';

const QUERY_FLOW = 'flow';
const QUERY_ORDERID = 'orderid';
const QUERY_SCREEN = 'screen';
const QUERY_PLATFORM = 'platform';
const APP_SCHEME_PAYMENT_SOURCES_NATIVE = `sampension://${routeNames.DEPOSITS}/${routeNames.DEPOSITS_PAYMENT_SOURCES}`;
const APP_SCHEME_PAYMENT_RECEIPT_NATIVE = `sampension://${routeNames.DEPOSITS}/${routeNames.DEPOSITS_PAYMENT_COMPLETED}`;
const URL_PAYMENT_SOURCES_LOGIN = `https://login.sampension.dk/${routeNames.DEPOSITS}/${routeNames.DEPOSITS_PAYMENT_SOURCES}`;
const URL_PAYMENT_RECEIPT_LOGIN = `https://login.sampension.dk/${routeNames.DEPOSITS}/${routeNames.DEPOSITS_PAYMENT_COMPLETED}`;
const URL_PAYMENT_SOURCES_CLASSIC = `https://sampension.dk/${routeNames.DEPOSITS}/${routeNames.DEPOSITS_PAYMENT_SOURCES}`;
const URL_PAYMENT_RECEIPT_CLASSIC = `https://sampension.dk/${routeNames.DEPOSITS}/${routeNames.DEPOSITS_PAYMENT_COMPLETED}`;

export const redirectToAppDeposits = async ({ currentLocation }: { currentLocation: string }) => {
  const isRedirectUrl = global?.location?.pathname.startsWith('/redirect');
  const url = new URL(currentLocation);
  const flow = url.searchParams.get(QUERY_FLOW);
  if (
    !isRedirectUrl ||
    (flow !== routeNames.DEPOSITS.toLowerCase() && flow !== routeNames.SIGNING.toLowerCase())
  ) {
    return undefined;
  }

  let APP_SCHEME_DEEPLINK;
  const canOpenSampensionApp = await Linking.canOpenURL('sampension://');
  const screen = url.searchParams.get(QUERY_SCREEN);

  if (flow === routeNames.SIGNING.toLowerCase()) {
    if (screen === routeNames.MAILBOX.toLowerCase()) {
      if (canOpenSampensionApp) {
        const threadId = url.searchParams.get('threadId');
        const documentId = url.searchParams.get('documentId');
        const signingId = url.searchParams.get('signingId');
        const cancelled = url.searchParams.get('cancelled');
        const completed = url.searchParams.get('completed');
        const nativeCompleteUrl = `sampension://main/tabs/mailbox/${routeNames.MAIL_SIGN}?threadId=${threadId}&documentId=${documentId}&completed=true&signingId=${signingId}`;
        const nativeCancelUrl = `sampension://main/tabs/mailbox/${routeNames.MAIL_SIGN}?threadId=${threadId}&documentId=${documentId}&cancelled=true`;

        if (completed?.toLowerCase() === 'true') {
          return global.location.replace(nativeCompleteUrl);
        } else if (cancelled?.toLowerCase() === 'true') {
          return global.location.replace(nativeCancelUrl);
        }
      }
    } else if (screen === routeNames.CHANGE_INVESTMENT_PROFILE.toLowerCase()) {
      const cancelled = url.searchParams.get('cancelled');
      const completed = url.searchParams.get('completed');
      const signingId = url.searchParams.get('signingId');
      const nativeCancelUrl = `sampension://main/${routeNames.CHANGE_INVESTMENT_PROFILE}/${routeNames.CHANGE_INVESTMENT_PROFILE_SIGNING}?cancelled=true`;
      const nativeCompleteUrl = `sampension://main/${routeNames.CHANGE_INVESTMENT_PROFILE}/${routeNames.CHANGE_INVESTMENT_PROFILE_SIGNING}?completed=true&signingId=${signingId}`;

      if (completed?.toLowerCase() === 'true') {
        return global.location.replace(nativeCompleteUrl);
      } else if (cancelled?.toLowerCase() === 'true') {
        return global.location.replace(nativeCancelUrl);
      }
    }
  }

  const orderId = url.searchParams.get(QUERY_ORDERID);
  const platform = url.searchParams.get(QUERY_PLATFORM);
  const derivedOrderIdQuery = `?orderid=${orderId}`;

  if (platform === 'classic') {
    APP_SCHEME_DEEPLINK = screen
      ? URL_PAYMENT_SOURCES_CLASSIC
      : `${URL_PAYMENT_RECEIPT_CLASSIC}${derivedOrderIdQuery}`;
  } else if (isWeb) {
    if (canOpenSampensionApp) {
      APP_SCHEME_DEEPLINK = screen
        ? APP_SCHEME_PAYMENT_SOURCES_NATIVE
        : `${APP_SCHEME_PAYMENT_RECEIPT_NATIVE}${derivedOrderIdQuery}`;
    } else {
      APP_SCHEME_DEEPLINK = screen
        ? URL_PAYMENT_SOURCES_LOGIN
        : `${URL_PAYMENT_RECEIPT_LOGIN}${derivedOrderIdQuery}`;
    }
  }

  return APP_SCHEME_DEEPLINK ? global.location.replace(APP_SCHEME_DEEPLINK) : undefined;
};
