import { formatMoney, formatDate } from 'helpers/string';

export const getDepositBrowserContent = (deposits, showAllYears, savingsDeposits) => {
  const payments = [];

  if (showAllYears) {
    savingsDeposits.forEach((item) => payments.push(...item.deposits));
  } else if (deposits?.length > 0) {
    deposits.forEach((item) => payments.push(...item.payments));
  }

  return {
    accumulatedDepositAmount: formatMoney(
      {
        amount: payments.reduce((total, item) => {
          total += item.totalAmount.amount;
          return total;
        }, 0),
      },
      true
    ),
    monthlyInterval:
      deposits?.length > 0
        ? `${formatDate(deposits[0].periodStart)} - ${formatDate(
            deposits[deposits.length - 1].periodEnd
          )}`
        : '',
  };
};
