import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from '@sp/ui/layout/Swiper';

const Slide1 = ({ description, title, companyImage }) => {
  return <Slide heading={title} description={description} image={companyImage} />;
};

Slide1.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  companyImage: PropTypes.any,
};

Slide1.defaultProps = {
  backgroundImage: undefined,
  companyImage: undefined,
};

export default Slide1;
