import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';
import PersistedStore from 'services/stores/persisted';
import log from 'services/log';
import resources from './resources';

export const DEFAULT_NAMESPACE = 'common';

// these i18n namespaces will be loaded by default
export const NAME_SPACES = [
  DEFAULT_NAMESPACE,
  'onboarding',
  'fetchTaxAndPensionsInfo',
  'accessibility',
  'link',
];

export const FALLBACK = 'da';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (callback) => {
    const { locale } = (await PersistedStore.getItem('language')) || {};
    callback(locale || FALLBACK);
  },
  init: () => {},
  cacheUserLanguage: (lng) => PersistedStore.setItem('language', { locale: lng }),
};

export const changeLanguage = (locale) =>
  i18next.changeLanguage(locale, (err, t) => {
    if (err) return log.error('something went wrong loading', err);
    return t;
  });

const i18n = {
  /**
   * @returns {Promise}
   */
  init: () =>
    new Promise((resolve, reject) => {
      i18next
        .on('initialized', () => log.info('[TC] Translation loaded', ''))
        .on('missingKeyHandler', (lngs, namespace, key, res) => {
          log.warn('missing key lngs:', lngs, 'namespace:', namespace, 'res:', res);
        })
        .use(initReactI18next)
        .use(languageDetector)
        .init(
          {
            lng: i18next.language,
            debug: false,
            load: 'languageOnly',
            saveMissing: false,
            fallbackLng: FALLBACK,
            resources,
            partialBundledLanguages: true,
            ns: NAME_SPACES,
            defaultNS: DEFAULT_NAMESPACE,
            keySeparator: false,
            nsSeparator: '|',
            react: {
              useSuspense: true,
            },
            returnEmptyString: false,
            interpolation: {
              escapeValue: false,
              defaultVariables: {
                COMPANY_NAME: 'Sampension',
                COMPANY_NAME_GENITIVE: 'Sampensions',
                COMPANY_NAME_FULL: 'Sampension Administrationsselskab A/S',
              },
            },
          },
          (error) => {
            if (error) {
              return reject(error);
            }
            return resolve();
          }
        );
    }),

  t: (key, options) => {
    try {
      return i18next.t(key, options);
    } catch (e) {
      log.error(e, 'Could not initialize i18n');
      return key;
    }
  },

  addResourceBundle: (lng, namespace, keyValues) =>
    i18next.addResourceBundle(lng, namespace, keyValues, true, true),
};

export default i18n;
