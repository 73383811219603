import i18n from 'services/i18n';

export const getSavingsAccessibility = ({ components, props }) => {
  const {
    policyId,
    policyName,
    policyValue,
    policyReturnAmount,
    policyInterestRateAmount,
    policyLatestAdminCosts,
    policyStatementDate,
    policyLatestDepositPayment,
    hasAnnualCosts,
    policies,
  } = props ?? {};

  const accessibility = [
    {
      component: 'InternalPolicy',
      accessibilityLabel: i18n.t(
        'savings|Intern police {{ policyName }} med police nummer {{ policyId }}',
        { policyName, policyId }
      ),
      accessibilityHint: i18n.t(
        'savings|Mere information om intern police: {{ policyName }} med police nummer {{ policyId }}',
        { policyName, policyId }
      ),
      accessibilityRole: 'button',
    },
    {
      component: 'ExternalPolicy',
      accessibilityLabel: i18n.t(
        'savings|Ekstern police {{ policyName }} med police nummer {{ policyId }}',
        { policyName, policyId }
      ),
      accessibilityRole: 'tab',
    },
    {
      component: 'BlockedPolicy',
      accessibilityLabel: i18n.t(
        'savings|Blokeret police {{ policyName }} med police nummer {{ policyId }}',
        { policyName, policyId }
      ),
      accessibilityHint: i18n.t(
        'savings|Mere information om blokeret police: {{ policyName }} med police nummer {{ policyId }}',
        { policyName, policyId }
      ),
      accessibilityRole: 'button',
    },
    {
      component: 'TransferredPolicy',
      accessibilityLabel: i18n.t(
        'savings|Overført police {{ policyName }} med police nummer {{ policyId }}',
        { policyName, policyId }
      ),
      accessibilityHint: i18n.t(
        'savings|Mere information om overført police: {{ policyName }} med police nummer {{ policyId }}',
        { policyName, policyId }
      ),
      accessibilityRole: 'button',
    },
    {
      component: 'PolicyValue',
      accessibilityLabel: policyStatementDate
        ? i18n.t(
            'savings|Depotværdien er {{ policyValue }} sidst opgjort den {{ policyStatementDate }}',
            { policyValue, policyStatementDate }
          )
        : i18n.t('savings|Depotværdien er {{ policyValue }}', { policyValue }),
      accessibilityRole: 'tab',
    },
    {
      component: 'PolicyReturn',
      accessibilityLabel: policyReturnAmount
        ? i18n.t('savings|Depotafkastet er {{ policyReturnAmount }}', { policyReturnAmount })
        : i18n.t('savings|Depotrenten er {{ policyInterestRateAmount }}', {
            policyInterestRateAmount,
          }),
      accessibilityHint: policyReturnAmount
        ? i18n.t('savings|Mere information om dit depotafkastet')
        : undefined,
      accessibilityRole: 'button',
    },
    {
      component: 'PolicyCosts',
      accessibilityLabel: i18n.t(
        'savings|Den månedlige omkostning er {{ policyLatestAdminCosts }}',
        { policyLatestAdminCosts }
      ),
      accessibilityHint: i18n.t('savings|Mere information om dine omkostninger'),
      accessibilityRole: hasAnnualCosts ? 'button' : 'tab',
    },
    {
      component: 'PolicyPayments',
      accessibilityLabel: i18n.t(
        'savings|Den seneste indbetaling er på {{ policyLatestDepositPayment }}',
        { policyLatestDepositPayment }
      ),
      accessibilityHint: i18n.t('savings|Mere information om dine indbetalinger'),
      accessibilityRole: 'button',
    },
    {
      component: 'LinkToDepotAccount',
      accessibilityLabel: i18n.t('savings|Depotregnskab'),
      accessibilityHint: i18n.t(
        'savings|Se flere tal og læs mere om din pensionsordning. (PDF fil. Læsningshjælp er nødvendigt)'
      ),
      accessibilityRole: 'link',
    },
    {
      component: 'SavingsOverviewSummary',
      accessibilityLabel:
        policies?.length === 1
          ? i18n.t('savings|Police overblik: i alt {{ length }} police', {
              length: policies.length,
            })
          : i18n.t('savings|Police overblik: i alt {{ length }} policer', {
              length: policies?.length,
            }),
      accessibilityRole: 'summary',
    },
  ];

  return Object.assign(
    {},
    ...accessibility
      .filter((item) => components.includes(item.component))
      .map((item) => ({
        [item.component]: {
          focusable: true,
          accessibilityLabel: item.accessibilityLabel,
          accessibilityRole: item.accessibilityRole,
          accessibilityHint: item.accessibilityHint,
          accessibilityViewIsModal: item.accessibilityIsModal ?? false,
        },
      }))
  );
};
