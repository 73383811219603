import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { PickerIOSControls } from '@sp/ui/base/inputs/Picker';
import Modal from '@sp/ui/layout/Modal';
import { isIOS } from '@sp/ui/helpers/device';

const Container = styled.View`
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  background: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
`;

const ScrollView = styled.ScrollView``;

const CustomPicker = ({ children, onPrevPress, onNextPress, onSubmitPress, submitTitle, testID }) =>
  isIOS ? (
    <Container>
      <PickerIOSControls
        onPrevPress={onPrevPress}
        onNextPress={onNextPress}
        onSubmitPress={onSubmitPress}
        submitTitle={submitTitle}
      />
      <ScrollView testID={testID}>{children}</ScrollView>
    </Container>
  ) : (
    <Modal visible onRequestClose={onSubmitPress}>
      <ScrollView>{children}</ScrollView>
    </Modal>
  );

CustomPicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onPrevPress: PropTypes.func,
  onNextPress: PropTypes.func,
  onSubmitPress: PropTypes.func.isRequired,
  submitTitle: PropTypes.string,
  testID: PropTypes.string,
};

CustomPicker.defaultProps = {
  submitTitle: 'OK',
  testID: undefined,
  onPrevPress: undefined,
  onNextPress: undefined,
};

export default withTheme(CustomPicker);
