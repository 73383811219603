export const selectCurrent = (state) => state.areas.investmentProfile.current;

export const selectIntroduction = (state) => state.areas.investmentProfile.questions?.introduction;

export const selectPolicies = (state) => state.areas.investmentProfile.policies;

export const selectCompanyGroup = (state) => state.areas.investmentProfile.policies?.companyGroup;

export const selectQuestions = (state) => state.areas.investmentProfile.questions?.questions;

export const selectPendingChanges = (state) => state.areas.investmentProfile.pendingChanges;

export const selectRecommendation = (state) => state.areas.investmentProfile.recommendation;

export const selectAnswers = (state) => state.areas.investmentProfile.answers;

export const selectConsents = (state) => state.areas.investmentProfile.consents;

export const selectContactInfo = (state) => state.areas.investmentProfile.contactInfo;

export const selectSigningUrl = (state) => state.areas.investmentProfile.signingUrl;

export const selectRecommendedProfile = (state) =>
  state.areas.investmentProfile?.recommendation?.recommended?.recommendedProfile;

export const selectAllRiskProfiles = (state) =>
  state.areas.investmentProfile?.recommendation?.allRiskProfiles;

export const selectNewRiskProfiles = (state) => state.areas.investmentProfile?.newRiskProfiles;

export const selectReceipt = (state) => state.areas.investmentProfile?.receipt;

export const selectInternalSavings = (state) => state.areas.savings?.data?.savings?.internalSavings;

export const selectChangeablePolicies = (state) => {
  const { currentPoliciesWithInvestmentProfile } = state.areas.investmentProfile?.current ?? {};

  return state.areas.savings?.data?.savings?.internalSavings?.filter((item) =>
    currentPoliciesWithInvestmentProfile
      ?.filter((x) => !x.hasPendingRequest)
      ?.map((x) => x.coverageNumber)
      ?.includes(item.coverageNumber)
  );
};

export const selectIsPolicyChangeable = (state, coverageNumber) => {
  const { currentPoliciesWithInvestmentProfile } = state.areas.investmentProfile?.current ?? {};

  return currentPoliciesWithInvestmentProfile
    ?.filter(({ hasPendingRequest }) => !hasPendingRequest)
    ?.some((policy) => policy.coverageNumber === coverageNumber);
};

export const selectReasonsForUnchangeablePolicies = (state) => {
  const { currentPoliciesWithoutInvestmentProfile } = state.areas.investmentProfile?.current ?? {};

  return currentPoliciesWithoutInvestmentProfile
    ?.filter((reason) => reason.coverageNumbers?.length > 0)
    .map((reasons) => ({
      ...reasons,
      policies: reasons.coverageNumbers?.map((coverageNumber) =>
        state.areas.savings?.data?.savings?.internalSavings.find(
          (item) => item.coverageNumber === coverageNumber
        )
      ),
    }));
};

export const selectChosenPoliciesFromSavings = (state) =>
  selectInternalSavings(state).filter((item) =>
    selectPolicies(state)
      ?.policies?.map((x) => x.coverageNumber)
      ?.includes(item.coverageNumber)
  );
