import React from 'react';
import PropTypes from 'prop-types';
import { TwoLineRow, DashboardRow } from '@sp/ui/base/rows';
import { H5 } from '@sp/ui/typography';

const FolderRow = ({ folder, onPress, index, isInPoliceFolder }) => {
  if (isInPoliceFolder) {
    return (
      <DashboardRow
        arrow
        textLeft={folder.title}
        iconLeft="rowFolder"
        onPress={onPress}
        testID={`folder${index}Link`}
      />
    );
  }

  return (
    <TwoLineRow
      arrow
      textLeft={<H5>{folder.companyName}</H5>}
      subtitleLeft={folder.title}
      iconLeft="rowFolder"
      onPress={onPress}
      testID={`documentsViewer${index}Link`}
    />
  );
};

FolderRow.propTypes = {
  folder: PropTypes.shape({
    title: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    police: PropTypes.string.isRequired,
  }).isRequired,
  onPress: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isInPoliceFolder: PropTypes.bool.isRequired,
};

export default FolderRow;
