import React from 'react';
import { useTheme } from 'styled-components/native';
import svgs from '../../assets/svgs';
import SvgIcon, { Props as SvgIconTypeProps } from './SvgIcon';

const VIEW_BOX_DEFAULT = '0 0 100 100';

type Props = Pick<SvgIconTypeProps, 'fill' | 'name' | 'viewBox'> &
  Partial<Pick<SvgIconTypeProps, 'width' | 'height'>>;

const Icon: React.FC<Props> = (props: Props) => {
  const { COLORS, DEFAULTS } = useTheme();

  return (
    <SvgIcon
      {...props}
      viewBox={props.viewBox ?? VIEW_BOX_DEFAULT}
      fill={props.fill || COLORS.PRIMARY_TEXT}
      width={props.width || DEFAULTS.ICON_HEIGHT}
      height={props.height || DEFAULTS.ICON_HEIGHT}
      svgs={svgs}
    />
  );
};

export default Icon;
