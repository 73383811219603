import React from 'react';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { openLink } from 'helpers/linking';
import AdminCosts from './AdminCosts';
import AdminCostGroupLifeAndHealthInsurances from './AdminCostGroupLifeAndHealthInsurances';
import { ModalTitle, ModalRegularText } from '../modal/modalTextComponents';
import InvestmentCosts from './InvestmentCosts';

const getCompanyContentProps = (type, i18n) => {
  let companyName;
  let linkText;
  let url;

  switch (type) {
    case 'PJD':
      companyName = i18n.t('savings|Pensionskassen for Jordbrugsakademikere & Dyrlæger');
      linkText = i18n.t('savings|www.pjdpension.dk');
      url = i18n.t('link|PJDInvestment');
      break;
    case 'ISP':
      companyName = i18n.t('savings|ISP Pension');
      linkText = i18n.t('savings|www.isp.dk');
      url = i18n.t('link|ISP');
      break;
    default:
      companyName = i18n.t('savings|Pensionskassen Arkitekter & Designere');
      linkText = i18n.t('savings|www.arkitektpension.dk');
      url = i18n.t('link|APInvestment');
      break;
  }

  return {
    companyName,
    linkText,
    url,
  };
};

const AboutCostsForOtherPensions = ({ type, openLatestDepotAccount, i18n }) => {
  const { companyName, linkText, url } = getCompanyContentProps(type, i18n);

  return (
    <>
      <AdminCosts
        openLatestDepotAccount={openLatestDepotAccount}
        type={type}
        companyName={companyName}
      />
      {type === 'ISP' ? (
        <AdminCostGroupLifeAndHealthInsurances companyName={companyName} />
      ) : (
        <>
          <ModalTitle
            subtitle={i18n.t('savings|Administration af gruppelivs- og ulykkesforsikringerne')}
          />
          <ModalRegularText
            text={i18n.t(
              'savings|Gruppelivsforsikringerne administreres i Forenede Gruppeliv. Ulykkesforsikringen administreres i Codan.'
            )}
          />
          <ModalRegularText
            text={i18n.t(
              'savings|Administrationsomkostninger til Forenede Gruppeliv og Codan er indeholdt i de administrationsomkostninger du betaler til {{ companyName }}.',
              { companyName }
            )}
          />
        </>
      )}
      <InvestmentCosts
        companyName={companyName}
        linkText={linkText}
        onPress={() => openLink(url, '_blank')}
        type={type}
      />
      {type !== 'ISP' && (
        <>
          <ModalTitle subtitle={i18n.t('savings|Omkostninger fra 2017')} />
          <ModalRegularText
            text={i18n.t(
              'savings|På omkostningssiden kan du se dine samlede omkostninger inklusive investeringsomkostningerne fra og med 2017.'
            )}
          />
        </>
      )}
    </>
  );
};

AboutCostsForOtherPensions.propTypes = {
  openLatestDepotAccount: PropTypes.func,
  type: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
};

AboutCostsForOtherPensions.defaultProps = {
  openLatestDepotAccount: undefined,
};

export default withComponentContainer()(AboutCostsForOtherPensions);
