import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PageParts from '@sp/ui/PageParts';
import styled from 'styled-components/native';
import LoadingContext from '@sp/ui/context/LoadingContext';
import AccessibilitySummary from './AccessibilitySummary';
import { isWeb } from '@sp/ui/helpers/device';

const CONTENT_BOTTOM_MARGIN = 60;

const Wrapper = styled.View`
  background: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  flex: 1;
  min-height: 100%;
  ${isWeb ? 'overflow-y: auto;' : ''}
  ${isWeb ? 'user-select: text;' : ''}
`;

const LoaderWrapper = styled.View`
  position: relative;
  flex: 1;
  align-items: center;
`;

const Content = styled.View`
  align-items: center;
`;

const BottomPadding = styled.View`
  flex: 1;
  min-height: ${CONTENT_BOTTOM_MARGIN}px;
  z-index: -1;
`;

const BasePage = ({ menu, footer, children, testID, hasNotifications, accessibilitySummary }) => {
  const { loader } = useContext(LoadingContext);
  return (
    <PageParts>
      {({ menu: globalMenu, footer: globalFooter, notifications }) => (
        <Wrapper testID={testID}>
          {typeof menu !== 'undefined' ? menu : globalMenu}
          <LoaderWrapper>
            {loader}
            <AccessibilitySummary accessibilitySummary={accessibilitySummary}>
              <Content>{children}</Content>
            </AccessibilitySummary>
            <BottomPadding />
            {typeof footer !== 'undefined' ? footer : globalFooter}
            {hasNotifications && notifications}
          </LoaderWrapper>
        </Wrapper>
      )}
    </PageParts>
  );
};

BasePage.propTypes = {
  menu: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  hasNotifications: PropTypes.bool,
};

BasePage.defaultProps = {
  menu: undefined,
  footer: undefined,
  children: undefined,
  testID: undefined,
  accessibilitySummary: undefined,
  hasNotifications: true,
};

export default BasePage;
