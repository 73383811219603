import { lazy } from 'react';
import withSuspense from 'decorators/withSuspense';
import * as routeNames from 'constants/routeNames';

const LazyLinkChildrenScreen = lazy(
  () => import('areas/dashboard/LinkChildren/LinkChildrenNavigator')
);
const LinkChildrenScreen = withSuspense(LazyLinkChildrenScreen);

export default {
  [routeNames.LINK_CHILDREN]: {
    screen: LinkChildrenScreen,
  },
};
