import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import * as routeNames from 'constants/routeNames';
import { isWeb } from 'helpers/platform';
import AwardRulesDisability from 'areas/dashboard/AwardRules/AwardRulesDisability';
import AwardRulesDeath from 'areas/dashboard/AwardRules/AwardRulesDeath';
import Retirement from 'areas/dashboard/dashboards/Retirement';
import Disability from 'areas/dashboard/dashboards/Disability';
import Death from 'areas/dashboard/dashboards/Death';
import calculationsPension from 'areas/dashboard/calculations/routes';
import AdjustCalculation from 'areas/dashboard/AdjustCalculation';

import PayoutsRetirement from 'areas/dashboard/payouts/PayoutsRetirement';
import PayoutsDisability from 'areas/dashboard/payouts/PayoutsDisability';
import PayoutsDeath from 'areas/dashboard/payouts/PayoutsDeath';

const DashboardSectionsStack = createStackNavigator();

const DashboardSectionsNavigator = () => (
  <DashboardSectionsStack.Navigator
    initialRouteName={routeNames.RETIREMENT}
    screenOptions={{
      animationEnabled: false,
      headerShown: false,
    }}
  >
    <DashboardSectionsStack.Screen name={routeNames.RETIREMENT} component={Retirement} />
    <DashboardSectionsStack.Screen name={routeNames.DISABILITY} component={Disability} />
    <DashboardSectionsStack.Screen name={routeNames.DEATH} component={Death} />
  </DashboardSectionsStack.Navigator>
);

const DashboardStack = createStackNavigator();

const DashboardNavigator = () => (
  <DashboardStack.Navigator
    screenOptions={({ route }) => ({
      animationEnabled: isWeb || route?.params?.animationDisabled ? false : true,
      headerShown: false,
    })}
  >
    <DashboardStack.Screen
      name={routeNames.PENSION_DASHBOARD}
      component={DashboardSectionsNavigator}
    />
    <DashboardStack.Screen
      name={routeNames.AWARD_RULES_DISABILITY}
      component={AwardRulesDisability}
    />
    <DashboardStack.Screen name={routeNames.AWARD_RULES_DEATH} component={AwardRulesDeath} />
    <DashboardStack.Screen name={routeNames.ADJUST_CALCULATION} component={AdjustCalculation} />
    <DashboardStack.Screen
      name={routeNames.DASHBOARD_PAYOUTS_RETIREMENT}
      component={PayoutsRetirement}
    />
    {/* NOTE: We disable animations on some routes that behave like tabs */}
    <DashboardStack.Screen
      name={routeNames.DASHBOARD_PAYOUTS_DISABILITY}
      component={PayoutsDisability}
    />
    <DashboardStack.Screen name={routeNames.DASHBOARD_PAYOUTS_DEATH} component={PayoutsDeath} />
    {Object.keys(calculationsPension).map((route) => (
      <DashboardStack.Screen
        name={route}
        key={route}
        component={calculationsPension[route].screen}
        initialParams={calculationsPension[route]?.params}
        options={calculationsPension[route]?.options}
      />
    ))}
  </DashboardStack.Navigator>
);

export default DashboardNavigator;
