import React from 'react';
import { Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import Animated, { EasingNode } from 'react-native-reanimated';
import styled from 'styled-components/native';
import { getCurrentDeviceType } from '@sp/ui/helpers/device';

const DEFAULT_DURATION = 1500;
const OPACITY_INPUT_RANGE = 100;

const Wrapper = styled.View`
  overflow: hidden;
`;

const getAnimationType = (animationType) => {
  const isMobile = getCurrentDeviceType() === 'mobile';

  switch (animationType) {
    case 'OutExp':
      return EasingNode.out(EasingNode.exp);
    case 'OutLin':
      return EasingNode.bezier(isMobile ? 0.5 : 0.1, 1, 1, 0.95);
    case 'Ease':
      return EasingNode.ease;
    default:
      return EasingNode.linear;
  }
};

const {
  greaterThan,
  lessOrEq,
  and,
  add,
  set,
  cond,
  stopClock,
  interpolateNode,
  not,
  Value,
  Clock,
  block,
  startClock,
  clockRunning,
  timing,
  defined,
  greaterOrEq,
} = Animated;

class Appear extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };

    this.delayTimer = new Value(0);
    this.childHeight = new Value(0);
    this.animationClock = new Clock();
    this.isAnimationRunning = new Value(0);
    this.opacityTimer = new Value(OPACITY_INPUT_RANGE);

    this.height = Dimensions.get('window').height;

    this.transY = new Value(0);

    this.animationConfig = {
      duration: new Value(0),
      toValue: new Value(0),
      easing: getAnimationType(this.props.animationType),
    };

    this.animationState = {
      finished: new Value(0),
      time: new Value(0),
      position: new Value(0),
      frameTime: new Value(0),
    };
  }

  onLayout = (e) => {
    const { height } = e.nativeEvent.layout;

    if (height) {
      this.setState({ height });
      this.childHeight.setValue(new Value(height));
    }
  };

  runAnimationCode = () =>
    block([
      set(
        this.transY,
        cond(and(defined(this.transY), greaterThan(this.childHeight, 0)), [
          cond(
            not(clockRunning(this.animationClock)),
            [
              set(this.animationState.finished, 0),
              set(this.animationState.time, 0),
              set(this.animationState.frameTime, 0),
              set(this.animationConfig.duration, this.props.duration ?? DEFAULT_DURATION),
              set(this.animationState.position, this.height),
              set(this.animationConfig.toValue, 0),
              set(this.delayTimer, this.props.delay ?? 0),
              set(this.delayTimer, add(this.delayTimer, this.animationClock)),
              startClock(this.animationClock),
            ],
            [
              cond(greaterOrEq(this.animationClock, this.delayTimer), [
                timing(this.animationClock, this.animationState, this.animationConfig),
                cond(and(greaterThan(this.transY, 0), lessOrEq(this.transY, this.opacityTimer)), [
                  set(this.opacityTimer, this.transY),
                ]),
              ]),
              cond(this.animationState.finished, [
                stopClock(this.animationClock),
                set(this.animationState.finished, 0),
                set(this.delayTimer, 0),
              ]),
              this.animationState.position,
            ]
          ),
        ])
      ),
    ]);

  render() {
    const animatedStyle = {
      position: 'relative',
      flex: 1,
      opacity: this.props.hasOpacityInterpolation
        ? interpolateNode(this.opacityTimer, {
            inputRange: [0, OPACITY_INPUT_RANGE],
            outputRange: [1, 0],
          })
        : interpolateNode(this.childHeight, {
            inputRange: [0, this.childHeight],
            outputRange: [0, 1],
          }),
      transform: [{ translateY: this.transY }],
    };

    return (
      <Wrapper onLayout={this.onLayout}>
        <Animated.View style={animatedStyle}>
          <Animated.Code dependencies={[]}>{this.runAnimationCode}</Animated.Code>
          {this.props.children}
        </Animated.View>
      </Wrapper>
    );
  }
}

Appear.propTypes = {
  children: PropTypes.node.isRequired,
  animationType: PropTypes.string,
  hasOpacityInterpolation: PropTypes.bool,
  delay: PropTypes.number,
  duration: PropTypes.number,
};

Appear.defaultProps = {
  animationType: undefined,
  opacityTimer: undefined,
  delay: 0,
  duration: undefined,
  reRunAnimation: false,
  hasOpacityInterpolation: false,
};

export default Appear;
