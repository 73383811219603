import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import styledWeb from 'styled-components';
import { wrapperStyleFn, WrapperBaseProps } from './index.shared';
const { default: fontStyles } = require('@sp/ui/typography/fontStyles');

const DEFAULT_TOP_MARGIN = 24;

export interface DateTimePickerProps {
  mode: 'date' | 'datetime';
  placeholder: string;
  defaultValue?: Date;
  maximumDate?: Date;
  minimumDate?: Date;
  marginTop: number;
  onConfirm: (date: Date) => void;
  hasError: boolean;
  testID?: string;
  inactive: boolean;
}

const WrapperBase = styled.View<WrapperBaseProps>`
  ${wrapperStyleFn}
`;

const Wrapper = styled(WrapperBase)`
  width: 100%;
`;

const Input = styledWeb.input<{ hasError: boolean }>`
  flex: 1;
  border-width: 0px;
  border: none;
  background: white;
  font-size: ${(props) => props.theme.FONT_SIZES.V2_BODY2.DEFAULT}px;
  ${fontStyles('normal')};
  overflow: hidden;
  color: ${({ hasError, theme }) => {
    return hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_TEXT;
  }}};
`;

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  onConfirm,
  defaultValue,
  maximumDate,
  minimumDate,
  marginTop,
  hasError,
  mode,
  testID,
  placeholder,
  inactive,
}) => {
  const inferredType = mode === 'datetime' ? 'datetime-local' : 'date';

  // We support both time and date values using the same component
  const inferredDefaultValue = useMemo(() => {
    try {
      return inferredType === 'date'
        ? defaultValue?.toISOString().split('T')[0]
        : defaultValue?.toISOString().slice(0, 16);
    } catch {
      return '';
    }
  }, [defaultValue, inferredType]);

  const minDate = useMemo(() => minimumDate?.toISOString().split('T')[0], [minimumDate]);
  const maxDate = useMemo(() => maximumDate?.toISOString().split('T')[0], [maximumDate]);

  return (
    <Wrapper marginTop={marginTop} hasError={hasError} disabled={inactive}>
      <Input
        placeholder={placeholder}
        hasError={hasError}
        id={testID}
        onChange={(e) => {
          const dateValue = new Date(e.target.value);
          onConfirm(dateValue);
        }}
        type={inferredType}
        value={inferredDefaultValue}
        min={minDate}
        max={maxDate}
        disabled={inactive}
      />
    </Wrapper>
  );
};

DateTimePicker.defaultProps = {
  mode: 'date',
  maximumDate: undefined,
  minimumDate: undefined,
  defaultValue: undefined,
  hasError: false,
  marginTop: DEFAULT_TOP_MARGIN,
  testID: undefined,
  inactive: false,
};

export default DateTimePicker;
