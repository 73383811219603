import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import Loader from '@sp/ui/base/Loader';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import Margin from '@sp/ui/base/Margin';
import { isWeb } from 'helpers/platform';

const LoadingWrapper = styled.View`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Wrapper = styled.View`
  flex: 1;
`;

const ModalWrapper = ({ onClose, title, loading, children }) =>
  isWeb ? (
    <Wrapper>
      {loading && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {children}
    </Wrapper>
  ) : (
    <ModalProvider isModal onClose={onClose}>
      <ScrollPage title={title} hasBackButton={false}>
        <Margin all="md">{children}</Margin>
      </ScrollPage>
    </ModalProvider>
  );

ModalWrapper.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ModalWrapper.defaultProps = {
  title: undefined,
  loading: false,
};

export default ModalWrapper;
