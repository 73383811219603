import { withTheme } from 'styled-components/native';
import Tracking from 'services/tracking';
import { getAPIStatus } from 'actions/api';
import { selectBlockMessage as selectBlockMessageDiscovery } from 'selectors/discovery';
import { selectBlockMessage as selectBlockMessageApi } from 'selectors/apiStatus';
import { logout as logoutAction } from 'actions/security';

import withScreenContainer from 'decorators/withScreenContainer';

const mapStateToProps = (state) => ({
  blockMessage: selectBlockMessageDiscovery(state) || selectBlockMessageApi(state),
});

const init =
  ({ action, getProps }) =>
  async () => {
    const { dispatch, blockMessage } = getProps();

    // We need this on web in case the user refreshes the page
    if (!blockMessage) {
      action(
        async () => {
          await dispatch(getAPIStatus());
        },
        {
          loader: true,
        }
      );
    }

    return {
      logout: () => {
        Tracking.trackEvent({ area: 'CustomerBlock', action: 'Logout' });
        dispatch(logoutAction());
      },
    };
  };

export const getActions = (i18n, logout) => [
  {
    text: i18n.t('Log ud'),
    onPress: logout,
    mobilePosition: 'bottom-sticky',
    type: 'primary',
  },
];

export const enhanceCustomerBlock = (component) =>
  withScreenContainer({
    mapStateToProps,
    init,
  })(withTheme(component));
