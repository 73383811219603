import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Button } from '@sp/ui/base/buttons';
import Margin from '@sp/ui/base/Margin';
import { Subtitle } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import { ActionShape } from './index.shared';

const Wrapper = styled.View`
  margin-top: ${(props) =>
    props.marginTop !== undefined ? props.marginTop : props.theme.SPACINGS.sm}px;
  justify-content: center;
  align-items: center;
  padding: 0 ${(props) => props.theme.SPACINGS.xxl}px;
  flex-direction: column;
`;

const ButtonWrapper = styled.View`
  margin-top: ${(props) => (props.isFirst ? 0 : props.theme.SPACINGS.sm)}px;
`;

const Actions = ({ actions, actionInfo, theme, marginTop }) => (
  <Wrapper marginTop={marginTop}>
    {actionInfo && (
      <Margin marginBottom="md">
        <Subtitle>{actionInfo}</Subtitle>
      </Margin>
    )}
    {actions.map((action, i) => {
      if (isValidElement(action.element)) {
        return (
          <ButtonWrapper key={action.text} isFirst={i === 0}>
            {action.element}
          </ButtonWrapper>
        );
      }
      return (
        <ButtonWrapper key={action.text || action.icon} isFirst={i === 0}>
          <Button
            testID={action.testID}
            before={
              action.icon && (
                <Icon
                  name={action.icon}
                  fill={
                    action.type === 'secondary'
                      ? theme.COLORS.PRIMARY
                      : theme.COLORS.SECONDARY_BACKGROUND
                  }
                />
              )
            }
            {...action}
          />
        </ButtonWrapper>
      );
    })}
  </Wrapper>
);

Actions.propTypes = {
  marginTop: PropTypes.number,
  actions: PropTypes.arrayOf(ActionShape),
  theme: PropTypes.shape({
    COLORS: PropTypes.shape({
      PRIMARY: PropTypes.string.isRequired,
      SECONDARY_BACKGROUND: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  actionInfo: PropTypes.string,
};

Actions.defaultProps = {
  actions: [],
  actionInfo: undefined,
  marginTop: undefined,
};

export default withTheme(Actions);
