import React, { useCallback, Fragment, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { selectSalaryByIndex } from 'selectors/dashboard';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import InfoRow from './InfoRow';
import ChildrenList from './ChildrenList';
import { RootState } from 'store';

const { default: Margin } = require('@sp/ui/base/Margin');
const { H3, Body } = require('@sp/ui/v2/typography');
const { formatMoney } = require('helpers/string');

type Props = {
  index: number;
};

const selectAnnualStateInfo = (state: RootState, index: number) => {
  const selectedSalary = selectSalaryByIndex(state, index);

  const income = selectedSalary?.yearlyDetails?.personligIndkomstFoerAMBidrag?.amount || 0;
  const amBidrag = selectedSalary?.yearlyDetails?.samletAMBidrag?.amount || 0;
  const skat = selectedSalary?.yearlyDetails?.beregnetSkatWithoutSamletAMBidrag?.amount || 0;

  const {
    children: childrenData,
    totalChildrenPublicBenefit,
    childAndYouthBenefit,
  } = selectedSalary || {};

  const calculatedDispIncome = income - skat - amBidrag + (childAndYouthBenefit?.amount || 0);

  const monthlyIncomeBeforeTax = selectedSalary?.monthlyAmount?.grossAmount || 0;
  const monthlyIncomeAfterTax = selectedSalary?.monthlyAmount?.amount || 0;

  const year = selectedSalary?.period?.year;

  return {
    year,
    amBidrag,
    income,
    skat,
    childrenData,
    monthlyIncomeBeforeTax,
    monthlyIncomeAfterTax,
    totalChildrenPublicBenefit,
    calculatedDispIncome,
  };
};

const AnnualStateInfo: React.FC<Props> = ({ index }) => {
  const i18n = useI18n(['dashboard']);

  const {
    year,
    income,
    amBidrag,
    skat,
    childrenData,
    totalChildrenPublicBenefit,
    monthlyIncomeAfterTax,
    monthlyIncomeBeforeTax,
    calculatedDispIncome,
  } = useAppSelector((state) => selectAnnualStateInfo(state, index), shallowEqual);

  const amountPerYear = useCallback(
    (amount) =>
      i18n.t('dashboard|amountPrYear', {
        amount: formatMoney({ amount }, true),
      }),
    [i18n]
  );

  const amountPerMonth = useCallback(
    (amount) =>
      i18n.t('dashboard|amountPrMonth', {
        amount: formatMoney({ amount }, true),
      }),
    [i18n]
  );

  const annualStatementInfoRows = useMemo(() => {
    const rows: {
      textLeft?: string;
      componentLeft?: React.ReactNode;
      textRight?: string;
      content?: string;
      iconName?: string;
      fontWeight?: 'bold' | 'normal';
    }[] = [
      {
        textLeft: i18n.t('dashboard|incomeAndContribution'),
        textRight: amountPerYear(income),
        content: i18n.t('dashboard|incomeDescription'),
      },
      {
        textLeft: i18n.t('dashboard|amContribution'),
        textRight: amountPerYear(amBidrag),
        iconName: 'minus',
      },
      {
        textLeft: i18n.t('dashboard|calculatedTax'),
        textRight: amountPerYear(skat),
        content: i18n.t('dashboard|incomeDescriptionAfterContribution'),
        iconName: 'minus',
      },
      {
        textLeft: i18n.t('dashboard|yearlyIncome'),
        textRight: amountPerYear(calculatedDispIncome),
        content: i18n.t('dashboard|yearlyIncomeWithoutTax'),
        iconName: 'equalTo',
        fontWeight: 'bold',
      },
      {
        textLeft: i18n.t('dashboard|monthlyIncome'),
        fontWeight: 'bold',
      },
      {
        textLeft: i18n.t('dashboard|grossAmount'),
        textRight: amountPerMonth(monthlyIncomeBeforeTax),
        iconName: 'equalTo',
        fontWeight: 'bold',
      },
      {
        textLeft: i18n.t('dashboard|netAmount'),
        textRight: amountPerMonth(monthlyIncomeAfterTax),
        content: i18n.t('dashboard|payoutOverview'),
        fontWeight: 'bold',
        iconName: 'equalTo',
      },
    ];

    if (totalChildrenPublicBenefit) {
      rows.push(
        {
          textLeft: i18n.t('dashboard|childAndYouthBenefit'),
          content:
            childrenData?.length === 1
              ? i18n.t('dashboard|benefitCalculationForChild')
              : i18n.t('dashboard|benefitCalculationForChildren'),
          fontWeight: 'bold',
        },
        {
          componentLeft: <ChildrenList childrenData={childrenData} />,
          textRight: amountPerMonth(totalChildrenPublicBenefit.amount),
          fontWeight: 'bold',
          iconName: 'equalTo',
        }
      );
    }

    return rows;
  }, [
    income,
    amBidrag,
    skat,
    childrenData,
    totalChildrenPublicBenefit,
    calculatedDispIncome,
    monthlyIncomeBeforeTax,
    monthlyIncomeAfterTax,
    i18n,
    amountPerYear,
    amountPerMonth,
  ]);

  return (
    <>
      <Margin marginBottom="md">
        <H3 textAlign="center">{i18n.t('dashboard|annualStateInfoTitle', { year })}</H3>
      </Margin>
      <Margin marginBottom="lg" horizontal="md">
        <Body>{i18n.t('dashboard|annualStateInfo')}</Body>
      </Margin>
      {annualStatementInfoRows.map(
        ({ textLeft, textRight, componentLeft, iconName, fontWeight, content }, i) => (
          <Fragment key={`${textLeft}_${i}`}>
            <InfoRow
              textLeft={textLeft}
              textRight={textRight}
              componentLeft={componentLeft}
              fontWeight={fontWeight}
              content={content}
              iconName={iconName}
            />
          </Fragment>
        )
      )}
    </>
  );
};

export default AnnualStateInfo;
