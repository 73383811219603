import React, { Fragment, isValidElement } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Button } from '@sp/ui/base/buttons';
import Margin from '@sp/ui/base/Margin';
import { Subtitle } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import { ActionShape } from './index.shared';
import { shadow } from '@sp/ui/helpers/style';

const Wrapper = styled.View`
  justify-content: center;
  align-items: center;
  background: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.COLORS.SECONDARY_BACKGROUND};
  ${({ horizontalMode, theme, transparent }) => {
    if (horizontalMode) {
      return `
        padding: ${theme.SPACINGS.md}px ${theme.SPACINGS.xxl}px 0px;
        align-self: center;
        ${
          !transparent
            ? `
            border-top-left-radius: ${theme.SPACINGS.m}px;
            border-top-right-radius: ${theme.SPACINGS.m}px;
            ${shadow}
          `
            : ''
        }
      `;
    }
    return `
      padding: ${theme.SPACINGS.md}px ${theme.SPACINGS.md}px 0;
      border-radius: ${theme.SPACINGS.m}px;
    `;
  }}
`;

const ButtonWrapper = styled.View`
  flex-direction: ${({ horizontalMode }) => (horizontalMode ? 'row' : 'column')};
  z-index: -1;
`;

const ActionsContentWrapper = styled.View`
  width: 100%;
  margin-bottom: ${(props) => props.theme.SPACINGS.md}px;
`;

const Actions = ({ actions, actionInfo, theme, actionsContent, horizontalMode, transparent }) => (
  <Wrapper horizontalMode={horizontalMode} transparent={transparent}>
    {actionsContent && <ActionsContentWrapper>{actionsContent}</ActionsContentWrapper>}
    {actionInfo && (
      <Margin marginBottom="md">
        <Subtitle>{actionInfo}</Subtitle>
      </Margin>
    )}
    <ButtonWrapper horizontalMode={horizontalMode}>
      {actions.map((action, index) => {
        if (isValidElement(action.element)) {
          return <Fragment key={action.text}>{action.element}</Fragment>;
        }
        return (
          <Margin
            marginBottom="md"
            key={action.text || action.icon}
            marginLeft={horizontalMode && index === 1 && 'md'}
          >
            <Button
              key={action.text || action.icon}
              testID={action.testID}
              before={
                action.icon && (
                  <Icon
                    name={action.icon}
                    fill={
                      action.type === 'secondary'
                        ? theme.COLORS.PRIMARY
                        : theme.COLORS.SECONDARY_BACKGROUND
                    }
                  />
                )
              }
              {...action}
            />
          </Margin>
        );
      })}
    </ButtonWrapper>
  </Wrapper>
);

Actions.propTypes = {
  actions: PropTypes.arrayOf(ActionShape),
  theme: PropTypes.shape({
    COLORS: PropTypes.shape({
      PRIMARY: PropTypes.string.isRequired,
      SECONDARY_BACKGROUND: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  actionInfo: PropTypes.string,
  horizontalMode: PropTypes.bool,
  actionsContent: PropTypes.node,
  transparent: PropTypes.bool,
};

Actions.defaultProps = {
  actions: [],
  actionInfo: undefined,
  horizontalMode: false,
  actionsContent: undefined,
  transparent: false,
};

export default withTheme(Actions);
