import { useCallback } from 'react';
import { Keyboard } from 'react-native';
import type { RootState } from 'store';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { usePatchDisposableIncome, usePatchDisposableIncomeWithInputValidation } from '../hooks';
import {
  selectDisposableIncome,
  selectDisposableIncomeOptions,
  selectUserEnteredIncome,
  selectSelectedSalary,
} from 'selectors/dashboard';
import { selectFormAdjustIncome } from 'selectors/dashboardForms';

const { actions } = require('react-formed');
const { selectIsDeviceTypeMobile } = require('selectors/device');
const { setInitialIncomeValues } = require('areas/dashboard/calculations/helpers/income');

const selectAdjustDisposableIncome = (state: RootState) => ({
  selectedIncomeRowIndex: selectFormAdjustIncome(state)?.selectedIndex,
  userEnteredIncome: selectUserEnteredIncome(state),
  disposableIncome: selectDisposableIncome(state),
  disposableIncomeOptions: selectDisposableIncomeOptions(state),
  selectedSalary: selectSelectedSalary(state),
  isMobile: selectIsDeviceTypeMobile(state),
});

export const useUpdateHelpers = () => {
  const {
    selectedIncomeRowIndex,
    userEnteredIncome,
    disposableIncome,
    disposableIncomeOptions,
    selectedSalary,
    isMobile,
  } = useAppSelector((state) => selectAdjustDisposableIncome(state), shallowEqual);

  const dispatch = useAppDispatch();

  const [updateDisposableIncomeWithoutInputValidation] = usePatchDisposableIncome();
  const [updateDiposableIncomeWithInputValidation] = usePatchDisposableIncomeWithInputValidation();

  const updateDisposableIncome = useCallback(
    ({ goBack }) => {
      if (isMobile) {
        Keyboard.dismiss();
      }

      return selectedIncomeRowIndex === -1
        ? updateDiposableIncomeWithInputValidation(goBack)
        : updateDisposableIncomeWithoutInputValidation(goBack);
    },
    [
      isMobile,
      selectedIncomeRowIndex,
      updateDisposableIncomeWithoutInputValidation,
      updateDiposableIncomeWithInputValidation,
    ]
  );

  const updateInitialIncomeValues = useCallback(
    () =>
      setInitialIncomeValues({
        userEnteredIncome,
        disposableIncomeOptions,
        selectedSalary,
        defaultIdentifier: disposableIncome?.defaultIdentifier,
        dispatch,
      }),
    [
      userEnteredIncome,
      disposableIncomeOptions,
      disposableIncome?.defaultIdentifier,
      selectedSalary,
      dispatch,
    ]
  );

  const updateSelectedIncomeRow = useCallback(
    (index, callback) => {
      dispatch(
        actions.setValue(
          'adjustIncome',
          'selectedIndex',
          disposableIncomeOptions?.[index]?.source === 'UserEnteredDisposableIncome' ? -1 : index
        )
      );

      callback(false);
    },
    [disposableIncomeOptions, dispatch]
  );

  return {
    updateSelectedIncomeRow,
    updateDisposableIncome,
    updateInitialIncomeValues,
  };
};
