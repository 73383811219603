import React from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import useI18n from 'hooks/useI18n';

import Alert from '@sp/ui/base/inputs/Alert';

const DeleteDraftAlert = ({ setIsOpen, isOpen, onClose }) => {
  const { i18n } = useI18n();
  if (isOpen) {
    Keyboard.dismiss();
  }

  return (
    <Alert
      onRequestClose={() => setIsOpen(false)}
      visible={isOpen}
      title={i18n.t('Slet udkast')}
      description={i18n.t('Dit udkast gemmes ikke. Vil du slette dit udkast?')}
      actions={[
        {
          text: i18n.t('Ja'),
          onPress: () => {
            if (onClose) onClose();
          },
        },
        {
          text: i18n.t('Nej'),
          style: 'secondary',
          onPress: () => {
            setIsOpen(false);
          },
        },
      ]}
    />
  );
};

DeleteDraftAlert.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteDraftAlert;
