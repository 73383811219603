import React from 'react';
import withComponentContainer from 'decorators/withComponentContainer';
import styled, { withTheme } from 'styled-components/native';
import { H5 } from '@sp/ui/typography';
import PropTypes from 'prop-types';
import { selectIsDeviceTypeDesktop } from 'selectors/device';

const POLICE_WRAPPER_HEIGHT = 46;

const Wrapper = styled.View`
  height: ${POLICE_WRAPPER_HEIGHT}px;
  background-color: ${(props) => props.theme.COLORS.PRIMARY_VARIANT1};
  padding-left: ${(props) => props.paddingLeft}px;
  justify-content: center;
`;

const PoliceInformationRow = ({ theme, text, isDesktop }) => (
  <Wrapper paddingLeft={!isDesktop ? theme.SPACINGS.md : 0}>
    <H5 textAlign={!isDesktop ? 'left' : 'center'}>{text}</H5>
  </Wrapper>
);

PoliceInformationRow.propTypes = {
  theme: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default withTheme(
  withComponentContainer({
    mapStateToProps,
  })(PoliceInformationRow)
);
