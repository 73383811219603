import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { Body } from '@sp/ui/typography';
import { isIOS } from '@sp/ui/helpers/device';

const CONTROLS_HEIGHT = 44;

const ArrowNav = styled.View`
  flex-direction: row;
`;

const Control = styled.TouchableOpacity`
  padding-right: ${({ theme }) => theme.SPACINGS.md}px;
`;

const Controls = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.COLORS.PRIMARY};
  height: ${CONTROLS_HEIGHT}px;
  padding-left: ${({ theme }) => theme.SPACINGS.md}px;
`;

const PickerIOSControls = ({
  onPrevPress,
  onNextPress,
  onSubmitPress,
  theme,
  submitTitle,
  testID,
  accessibilityLabelClose,
}) =>
  isIOS && (
    <Controls>
      <ArrowNav accessibilityElementsHidden importantForAccessibility="no-hide-descendants">
        <Control onPress={onPrevPress}>
          {onPrevPress && <Icon name="arrowUpCentered" fill={theme.COLORS.SECONDARY_BACKGROUND} />}
        </Control>
        <Control onPress={onNextPress}>
          {onNextPress && (
            <Icon name="arrowDownCentered" fill={theme.COLORS.SECONDARY_BACKGROUND} />
          )}
        </Control>
      </ArrowNav>
      <Control
        onPress={onSubmitPress}
        testID={`${testID}SubmitButton`}
        accessibilityLabel={accessibilityLabelClose}
      >
        <Body color={theme.COLORS.SECONDARY_BACKGROUND}>{submitTitle}</Body>
      </Control>
    </Controls>
  );

PickerIOSControls.propTypes = {
  onPrevPress: PropTypes.func,
  onNextPress: PropTypes.func,
  onSubmitPress: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  submitTitle: PropTypes.string.isRequired,
  accessibilityLabelClose: PropTypes.string,
  testID: PropTypes.string,
};

PickerIOSControls.defaultProps = {
  onPrevPress: undefined,
  onNextPress: undefined,
  accessibilityLabelClose: undefined,
  testID: undefined,
};

export default withTheme(PickerIOSControls);
