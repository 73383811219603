import React from 'react';
import { ActivityIndicator } from 'react-native';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import Icon from '@sp/ui/base/Icon';

const BROWSER_CONTROL_BUTTON_HEIGHT = 100;
const ICON_SIZE = 25;

const ActivityWrapper = styled.View`
  justify-content: center;
  align-items: flex-end;
  margin-right: ${(props) => props.theme.SPACINGS.m}px;
`;

const TouchableOpacity = styled.TouchableOpacity`
  justify-content: center;
  align-items: flex-end;
  margin-right: ${(props) =>
    props.type === 'cancelled' ? props.theme.SPACINGS.sm : props.theme.SPACINGS.m}px;
  height: ${BROWSER_CONTROL_BUTTON_HEIGHT}%;
`;

const YearlyDepositOverviewButton = ({
  showAllYears,
  depositFetchingState,
  areAllYearsShown,
  resumeFetching,
  theme,
}) => {
  switch (depositFetchingState) {
    case 'finished': {
      return (
        <TouchableOpacity hitSlop={theme.HIT_SLOP} onPress={() => showAllYears()}>
          <Icon
            name={areAllYearsShown ? 'dotsVertical' : 'dots'}
            fill={theme.COLORS.SECONDARY_BACKGROUND}
          />
        </TouchableOpacity>
      );
    }
    case 'cancelled': {
      return (
        <TouchableOpacity hitSlop={theme.HIT_SLOP} type="cancelled" onPress={resumeFetching}>
          <Icon
            height={ICON_SIZE}
            width={ICON_SIZE}
            name="update"
            fill={theme.COLORS.SECONDARY_BACKGROUND}
          />
        </TouchableOpacity>
      );
    }
    default: {
      return (
        <ActivityWrapper>
          <ActivityIndicator size="small" color={theme.COLORS.SECONDARY_BACKGROUND} />
        </ActivityWrapper>
      );
    }
  }
};

YearlyDepositOverviewButton.propTypes = {
  depositFetchingState: PropTypes.oneOf(['finished', 'cancelled']).isRequired,
  resumeFetching: PropTypes.func.isRequired,
  areAllYearsShown: PropTypes.bool.isRequired,
  showAllYears: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(YearlyDepositOverviewButton);
