import { get } from 'actions/api';

import { PUSH_PREVIOUS_FOLDER, POP_PREVIOUS_FOLDER } from 'constants/actionTypes';

export const getFolders = () => get('DOCUMENT_FOLDERS', 'api', '/savings/v2/documents');

export const pushPreviousFolder = (folderId) => ({
  type: PUSH_PREVIOUS_FOLDER,
  payload: { folderId },
});

export const popPreviousFolder = () => ({ type: POP_PREVIOUS_FOLDER });

// NOTE: action to open a document from anywhere in the app
export const openPoliceDocument = (policyId, type, onBackPress) => ({
  type: '@@DOCUMENTS/OPEN_DOCUMENT',
  payload: {
    policyId,
    type,
    onBackPress,
  },
});
