export const MORE_AREA = 'More';
const NOTIFICATION_SETTINGS_AREA = 'NotificationSettings';
const TERMS_AREA = 'Terms';

export const NOTIFICATION_SETTINGS = {
  area: NOTIFICATION_SETTINGS_AREA,
  action: 'ToggleNotificationSettings',
};

export const TERMS = {
  INITIATE_REVOKE: {
    area: TERMS_AREA,
    action: 'InitiateRevoke',
  },
  MAIL_REVOKE: {
    area: TERMS_AREA,
    action: 'MailRevoke',
  },
  CANCEL_REVOKE: {
    area: TERMS_AREA,
    action: 'CancelRevoke',
  },
};

export const CLICK_SAVINGS_INSURANCE = {
  area: MORE_AREA,
  action: 'ClickSavingsInsurance',
};

export const CLICK_HEALTH_BENEFITS = {
  area: MORE_AREA,
  action: 'ClickHealthBenefits',
};
