import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';

const CARRET_LEFT_MARGIN = 9;
const SORT_ICON_SIZE = 10;

const TouchableWrapper = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled.View`
  margin-left: ${CARRET_LEFT_MARGIN}px;
`;

// sorting
const ASC = 'ASC';
const DESC = 'DESC';

const SortToggle = ({
  label,
  onPress,
  accessibilityLabel,
  accessibilityLabelASC,
  accessibilityLabelDESC,
}) => {
  const [order, setOrder] = useState(DESC);

  return (
    <TouchableWrapper
      focusable
      accessibilityRole="button"
      accessibilityLabel={
        order === DESC
          ? `${accessibilityLabel} ${label.toLowerCase()}. ${accessibilityLabelDESC}.`
          : `${accessibilityLabel} ${label.toLowerCase()}. ${accessibilityLabelASC}.`
      }
      // Prevents accessibilityLabel to be read twice after interaction
      accessibilityHint=""
      onPress={() => {
        setOrder(order === DESC ? ASC : DESC);
        onPress(order);
      }}
    >
      <Body>{label}</Body>
      <IconWrapper>
        <Icon
          name={order === DESC ? 'caretDown' : 'caret'}
          width={SORT_ICON_SIZE}
          height={SORT_ICON_SIZE}
        />
      </IconWrapper>
    </TouchableWrapper>
  );
};

SortToggle.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  accessibilityLabel: PropTypes.string.isRequired,
  accessibilityLabelASC: PropTypes.string.isRequired,
  accessibilityLabelDESC: PropTypes.string.isRequired,
};

export default SortToggle;
