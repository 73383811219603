export const pensionInfoHtml = (url: string, ssoToken: string) => `
<!DOCTYPE html>
<html lang="en"> 
  <head>
    <meta charset="UTF-8">
    <title>Sampension PensionsInfo Redirect Site</title>
  </head>
  <body>
    <form action="${url}" method="post" id="pensionInfoForm" name="pensionInfoForm" style="visibility: hidden"> 
      <input name="TICKET" id="TICKET" type="hidden" value="${ssoToken}" autocomplete="off">
      <input name="submitBtn" style="opacity: 0;" id="submitBtn" type="submit" value="Gå til PensionsInfo" autocomplete="off">    
    </form>
    <script>
      window.onload = function() {
      document.getElementById('pensionInfoForm').submit();
      };
    </script>
  </body>
</html>
`;
