import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import PageParts from '@sp/ui/PageParts';
import { isWeb } from '@sp/ui/helpers/device';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useModal from '@sp/ui/hooks/useModal';
import Actions from './Actions';
import { groupActions, ActionShape } from './Actions/index.shared';

const Wrapper = styled.View`
  background: ${({ theme }) => theme.COLORS.PRIMARY};
  flex: 1;
  height: 100%;
  ${isWeb ? 'user-select: text;' : ''}
`;

const Content = styled.View`
  flex: 1;
`;

const StatusBarOffset = styled.View`
  ${({ hasHeader, extraTopPadding }) => (hasHeader ? '' : `padding-top: ${extraTopPadding}px;`)}
  background: ${({ theme }) => theme.COLORS.PRIMARY};
`;

const BasePage = ({
  menu,
  title,
  children,
  actions,
  backButton,
  hasBackButton,
  hasHeader,
  hasNotifications,
  customHeader,
  testID,
  accessibilitySummary,
  actionsContent,
  actionInfo,
  titleAnimation,
  animateTitle,
}) => {
  const { isModal, leftContent, onClose, hasOnCloseButton, shouldDismissPopupFlow } = useModal();

  const insets = useSafeAreaInsets();

  const groupedActions = groupActions(actions);
  const hasCustomHeader = typeof customHeader !== 'undefined';
  const hasGlobalHeader = !hasCustomHeader && hasHeader && !isModal;
  const hasModalHeader = !hasCustomHeader && hasHeader && isModal;

  return (
    <PageParts>
      {({
        menu: globalMenu,
        Header: GlobalHeader,
        ModalHeader,
        BackButton: GlobalBackButton,
        notifications,
      }) => {
        const backButtonNode =
          hasBackButton && (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />);

        return (
          <Wrapper testID={testID}>
            <StatusBarOffset hasHeader={hasHeader} extraTopPadding={insets.top} />
            {isWeb && (typeof menu !== 'undefined' ? menu : globalMenu)}
            {hasCustomHeader && customHeader}
            {hasGlobalHeader && GlobalHeader && (
              <GlobalHeader
                title={title}
                animation={titleAnimation}
                actions={groupedActions.header}
                backButton={backButtonNode}
                accessibilitySummary={accessibilitySummary}
                animateTitle={animateTitle}
              />
            )}
            {hasModalHeader && ModalHeader && (
              <ModalHeader
                title={title}
                animation={titleAnimation}
                onClose={onClose}
                hasOnCloseButton={hasOnCloseButton}
                backButton={backButtonNode}
                leftContent={leftContent}
                animateTitle={animateTitle}
                shouldDismissPopupFlow={shouldDismissPopupFlow}
              />
            )}
            {hasNotifications && notifications}
            <Content>{children}</Content>
            {groupedActions.bottom.length > 0 && (
              <Actions
                actions={groupedActions.bottom}
                actionInfo={actionInfo}
                actionsContent={actionsContent}
              />
            )}
          </Wrapper>
        );
      }}
    </PageParts>
  );
};

BasePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  actions: PropTypes.arrayOf(ActionShape),
  customHeader: PropTypes.node,
  backButton: PropTypes.node,
  menu: PropTypes.node,
  actionsContent: PropTypes.node,
  title: PropTypes.string,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  actionInfo: PropTypes.string,
  hasNotifications: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  animateTitle: PropTypes.bool,
  hasHeader: PropTypes.bool,
  titleAnimation: PropTypes.object,
};

BasePage.defaultProps = {
  title: undefined,
  children: undefined,
  actions: undefined,
  backButton: undefined,
  customHeader: undefined,
  hasHeader: true,
  hasBackButton: true,
  hasNotifications: true,
  menu: undefined,
  titleAnimation: undefined,
  testID: undefined,
  accessibilitySummary: undefined,
  actionInfo: undefined,
  actionsContent: undefined,
  animateTitle: false,
};

export default BasePage;
