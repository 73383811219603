import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';

const H1 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.H1.MOBILE}px;
`;

H1.defaultProps = {
  fontWeight: 'bold',
};

export default H1;
