import { useRef, useCallback } from 'react';
import { useStore } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import { selectDeeplink } from 'selectors/deeplink';
import { createSessionAndUpdateStore } from './helpers';
import { DEEPLINK_SESSION } from 'constants/deeplink';
import type { RootState } from 'store';

const { default: sessionStorage } = require('services/stores/session');

export const useHandleDeeplink = () => {
  const dispatch = useAppDispatch();
  const previousScreen = useRef(global.location.pathname);
  const store = useStore();

  return useCallback(async () => {
    const isPreviousRouteEmpty = previousScreen.current === '/';
    const state = store.getState() as RootState;
    const { isDeeplink, deeplinkFlow } = selectDeeplink(state) ?? {};
    const { flow, isPriorToLogin } = (await sessionStorage.getItem(DEEPLINK_SESSION)) ?? {};
    const isDeeplinkFlowDefined = typeof deeplinkFlow === 'string';

    let deeplink = flow;

    /* NOTE: When trying to manipulate the URL to nothing */
    if (isPreviousRouteEmpty && isDeeplink) {
      createSessionAndUpdateStore({ deeplink, dispatch });
      return undefined;
    }

    if (flow) {
      if (isPriorToLogin || !deeplinkFlow) {
        /* NOTE: User is deeplinking prior to login */
        deeplink = flow;
      } else if (!isPriorToLogin && isDeeplinkFlowDefined && deeplinkFlow !== flow) {
        /* NOTE: SSO user is logged in and is deeplinking externally */
        deeplink = deeplinkFlow;
      }
    } else if (deeplinkFlow) {
      /* NOTE: There is no session storage. This can happend if you deeplink
      directly / internally by manipulating the URL on etc. login.sampension.dk
      instead of intializing an event */
      deeplink = deeplinkFlow;
    }

    if (deeplink) {
      createSessionAndUpdateStore({ deeplink, dispatch });
    }

    return undefined;
  }, [store, dispatch]);
};
