import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Notification from '@sp/ui/base/Notification';
import Conditional from 'decorators/conditional';
import AnimatedItems from './AnimatedItem';
import { isWeb } from '@sp/ui/helpers/device';

const PADDING = 10;
const MAX_WIDTH = 700;
const NOTIFICATION_MARGIN_TOP = 10;
const PADDING_TOP_WEB = 54;

const Wrapper = Conditional({
  desktop: styled.View`
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: ${PADDING}px;
    padding-top: ${({ theme }) => (isWeb ? 0 : theme.SPACINGS.xl)}px;
  `,
  default: styled.View`
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: ${PADDING}px;
    padding-top: ${({ theme }) => (isWeb ? PADDING_TOP_WEB : theme.SPACINGS.xl)}px;
  `,
});

const NotificationWrapper = Conditional({
  desktop: styled.View`
    width: ${MAX_WIDTH}px;
    margin: ${NOTIFICATION_MARGIN_TOP}px auto 0;
  `,
  default: styled.View`
    width: 100%;
    max-width: ${MAX_WIDTH}px;
    margin: ${NOTIFICATION_MARGIN_TOP}px auto 0;
  `,
});

const Notifications = ({ notifications, dismiss }) =>
  notifications.length > 0 && (
    <Wrapper>
      {notifications.map((notification) => {
        const onPressClose = dismiss || undefined;

        return (
          <AnimatedItems key={notification.id}>
            <NotificationWrapper>
              <Notification
                text={notification.text}
                type={notification.type}
                onPressClose={
                  notification.dismissable !== false
                    ? () => onPressClose(notification.id)
                    : undefined
                }
                buttonText={notification.buttonText || undefined}
                onPressButton={notification.onPressButton || undefined}
              />
            </NotificationWrapper>
          </AnimatedItems>
        );
      })}
    </Wrapper>
  );

const notificationProp = PropTypes.shape({
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning']),
  dismissable: PropTypes.bool,
});

Notifications.propTypes = {
  dismiss: PropTypes.func,
  notifications: PropTypes.arrayOf(notificationProp),
};

Notifications.defaultProps = {
  dismiss: undefined,
  notifications: [],
};

export default withTheme(Notifications);
