import React from 'react';
import PropTypes from 'prop-types';
import Svg, { LinearGradient, Defs, Stop, ClipPath, Rect } from 'react-native-svg';

import styled from 'styled-components/native';
import ConditionalSvgImage from 'containers/theme/ConditionalSvgImage';
import { isAndroid } from '@sp/ui/helpers/device';

const OUTER_RADIUS = 36;
const BORDER_GAP = 14;

const Wrapper = styled.View`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: ${OUTER_RADIUS}px;
  ${isAndroid ? 'elevation: 4;' : ''};
  max-width: ${({ width }) => width}px;
`;

const sizing = {
  mobile: {
    height: 2190 / 3.8,
    width: 1125 / 3.6,
  },
  tablet: {
    height: 1889 / 2.5,
    width: 1536 / 2.5,
  },
  desktop: {
    height: 1889 / 2.5,
    width: 1536 / 2.5,
  },
  landscape: {
    width: 2048 / 3.4,
    height: 1376 / 3.4,
  },
};

const DeviceGradient = ({
  deviceType,
  isLandscape,
  mobileImage,
  tabletImage,
  landscapeImage,
  children,
  backgroundFill,
}) => {
  let dimensions = sizing[deviceType];

  if (deviceType === 'tablet' && isLandscape) {
    dimensions = sizing.landscape;
  }

  return (
    <Wrapper width={dimensions.width}>
      <Svg height={dimensions.height} width={dimensions.width}>
        <Defs>
          <ClipPath id="clip">
            <Rect
              x="14"
              y="14"
              width={dimensions.width - BORDER_GAP * 2}
              height={dimensions.height - BORDER_GAP * 2}
              fill="transparent"
              rx="22"
            />
          </ClipPath>
          <LinearGradient id="linearGradient-1" x1="38%" y1="0%" x2="62%" y2="40%">
            <Stop offset="0%" stopColor="#5F5E5D" />
            <Stop offset="100%" stopColor="#464543" />
          </LinearGradient>
        </Defs>
        <Rect
          x="0"
          y="0"
          d="M46.1499336,-1.27465222e-14 L234.850066,-1.762824e-15 C250.897404,1.02425151e-15 256.716556,1.670861 262.583223,4.80838867 C268.449889,7.94591634 273.054084,12.5501107 276.191611,18.4167773 C279.329139,24.283444 281,30.1025963 281,46.1499336 L281,514.850066 C281,530.897404 279.329139,536.716556 276.191611,542.583223 C273.054084,548.449889 268.449889,553.054084 262.583223,556.191611 C256.716556,559.329139 250.897404,561 234.850066,561 L46.1499336,561 C30.1025963,561 24.283444,559.329139 18.4167773,556.191611 C12.5501107,553.054084 7.94591634,548.449889 4.80838867,542.583223 C1.670861,536.716556 4.93739766e-15,530.897404 -8.49768148e-15,514.850066 L1.24300837e-15,46.1499336 C-7.22223659e-16,30.1025963 1.670861,24.283444 4.80838867,18.4167773 C7.94591634,12.5501107 12.5501107,7.94591634 18.4167773,4.80838867 C24.283444,1.670861 30.1025963,7.40609649e-15 46.1499336,-1.27465222e-14 Z"
          width={dimensions.width}
          height={dimensions.height}
          fill="#464543"
          rx={OUTER_RADIUS}
        />
        <Rect
          d="M44.458278,5.5 C30.9438983,5.5 26.0502849,6.92480094 21.111515,9.56608396 C16.1342031,12.2279795 12.2279795,16.1342031 9.56608396,21.111515 C6.92480094,26.0502849 5.5,30.9438983 5.5,44.458278 L5.5,516.541722 C5.5,530.056102 6.92480094,534.949715 9.56608396,539.888485 C12.2279795,544.865797 16.1342031,548.77202 21.111515,551.433916 C26.0502849,554.075199 30.9438983,555.5 44.458278,555.5 L236.541722,555.5 C250.056102,555.5 254.949715,554.075199 259.888485,551.433916 C264.865797,548.77202 268.77202,544.865797 271.433916,539.888485 C274.075199,534.949715 275.5,530.056102 275.5,516.541722 L275.5,44.458278 C275.5,30.9438983 274.075199,26.0502849 271.433916,21.111515 C268.77202,16.1342031 264.865797,12.2279795 259.888485,9.56608396 C254.949715,6.92480094 250.056102,5.5 236.541722,5.5 L44.458278,5.5 Z"
          id="Glass"
          stroke="#3B3A39"
          x="7"
          y="7"
          width={dimensions.width - BORDER_GAP}
          height={dimensions.height - BORDER_GAP}
          fill={backgroundFill || 'url(#linearGradient-1)'}
          rx="30"
        />
        {children ? (
          children
        ) : (
          <ConditionalSvgImage
            x="14"
            y="14"
            width={dimensions.width - BORDER_GAP * 2}
            height={dimensions.height - BORDER_GAP * 2}
            preserveAspectRatio="xMinYMin slice"
            mobile={mobileImage}
            tablet={tabletImage}
            desktop={tabletImage}
            landscape={landscapeImage}
            clipPath="url(#clip)"
          />
        )}
      </Svg>
    </Wrapper>
  );
};

DeviceGradient.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  mobileImage: PropTypes.any,
  tabletImage: PropTypes.any,
  landscapeImage: PropTypes.any,
  backgroundFill: PropTypes.string,
};

DeviceGradient.defaultProps = {
  mobileImage: undefined,
  tabletImage: undefined,
  landscapeImage: undefined,
  children: undefined,
  backgroundFill: undefined,
};

export default DeviceGradient;
