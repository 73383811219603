const CHANGE_INVESTMENT_PROFILE_AREA = 'ChangeInvestmentProfile';

export const START_FLOW = {
  area: CHANGE_INVESTMENT_PROFILE_AREA,
  action: 'StartFlow',
};

export const SIGNING_SUCCESS = {
  area: CHANGE_INVESTMENT_PROFILE_AREA,
  action: 'SigningSuccess',
};
