import React from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import features from 'features';
import * as routeNames from 'constants/routeNames';
import { initFunc } from 'helpers/props';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import TwoLineRow from '@sp/ui/base/rows/TwoLineRow';
import { RowBorder } from '@sp/ui/base/rows';
import { H5, Subtitle } from '@sp/ui/typography';
import { fetchSavingsOverview } from 'actions/savings';
import { selectAllSavingsData } from 'selectors/savings';
import { numberWithSeperator } from 'helpers/string';
import Margin from '@sp/ui/base/Margin';
import { TopGradientContent, NarrowContent } from '@sp/ui/pages/BasePage';
import { getPolicyDetails } from './helpers/getPolicyDetails';
import SavingsReturnFooter from './components/policies/SavingsReturnFooter';
import { VIEW_SAVINGS_RETURN } from 'areas/savings/trackingIds';
import {
  getCurrentInvestmentProfiles,
  startChangeInvestmentProfileFlow,
} from 'actions/changeInvestmentProfile';
import { selectChangeablePolicies, selectIsPolicyChangeable } from 'selectors/investmentProfile';

const Return = ({
  i18n,
  savingsData,
  navigateToChangeInvestmentProfile,
  canChangeInvestmentProfile,
  route,
}) => {
  const { pID } = route.params;
  const { isLink, policyReturn, latestDepositStatementYear, latestDepositStatementDate } =
    getPolicyDetails(savingsData, pID);

  const { yearToDate, lastYear, last3Years, last5Years, riskGroup, riskRating } =
    policyReturn ?? {};

  const actions =
    features.isEnabled('changeInvestmentProfile') && canChangeInvestmentProfile
      ? [
          {
            text: i18n.t('changeInvestmentProfile|Skift investeringsprofil'),
            mobilePosition: 'bottom-sticky',
            testID: 'savingsGoToChangeProfile',
            onPress: navigateToChangeInvestmentProfile,
          },
        ]
      : undefined;

  return (
    <ScrollPage
      title={i18n.t('savings|Afkast')}
      disableTopPadding
      disableBottomPadding={!features.isEnabled('changeInvestmentProfile')}
      fullWidth
      actions={actions}
    >
      <TopGradientContent
        content={{
          title: i18n.t('savings|Afkast'),
          heading: riskGroup,
          subtitle: i18n.t('savings|Din investeringsprofil'),
        }}
        removeTitleMobile
        gradientHeader
      />
      {policyReturn && (
        <NarrowContent>
          {yearToDate && (
            <TwoLineRow
              textLeft={<H5 fontWeight="bold">{i18n.t('savings|Afkast')}</H5>}
              textRight={`${numberWithSeperator(yearToDate, 2)} %`}
              subtitleLeft={i18n.t('savings|År-til-dato pr. {{latestDepositStatementDate}}', {
                latestDepositStatementDate,
              })}
            />
          )}
          <RowBorder />
          {lastYear && (
            <>
              <TwoLineRow
                textLeft={<H5 fontWeight="bold">{i18n.t('savings|Afkast')}</H5>}
                textRight={`${numberWithSeperator(lastYear, 2)} %`}
                subtitleLeft={i18n.t('savings|I {{ lastYearDate }} ', {
                  lastYearDate: latestDepositStatementYear - 1,
                })}
              />
              <RowBorder />
            </>
          )}
          {last3Years && (
            <>
              <TwoLineRow
                textLeft={<H5 fontWeight="bold">{i18n.t('savings|Afkast')}</H5>}
                textRight={`${numberWithSeperator(last3Years, 2)} %`}
                subtitleLeft={i18n.t('savings|De sidste 3 år')}
              />
              <RowBorder />
            </>
          )}
          {last5Years && (
            <>
              <TwoLineRow
                textLeft={<H5 fontWeight="bold">{i18n.t('savings|Afkast')}</H5>}
                textRight={`${numberWithSeperator(last5Years, 2)} %`}
                subtitleLeft={i18n.t('savings|De sidste 5 år')}
              />
              <RowBorder />
            </>
          )}
          {riskRating && (
            <>
              <TwoLineRow
                textLeft={<H5 fontWeight="bold">{i18n.t('savings|Risikotal')}</H5>}
                textRight={riskRating.toString().replace('.', ',')}
                subtitleLeft={i18n.t('savings|På en skala fra 1,0 - 5,9')}
              />
              <RowBorder />
            </>
          )}
          {riskRating && <SavingsReturnFooter />}
          {isLink && (
            <Margin horizontal="md" marginBottom="md">
              <Subtitle>
                {i18n.t('savings|Ovenstående afkast inkluderer ikke afkast fra Linkpension.')}
              </Subtitle>
            </Margin>
          )}
        </NarrowContent>
      )}
    </ScrollPage>
  );
};

Return.propTypes = {
  savingsData: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  navigateToChangeInvestmentProfile: initFunc,
  route: PropTypes.object.isRequired,
  canChangeInvestmentProfile: PropTypes.bool.isRequired,
};

Return.defaultProps = {
  savingsData: undefined,
  navigateToChangeInvestmentProfile: undefined,
};

const mapStateToProps = (state, props) => ({
  savingsData: selectAllSavingsData(state),
  changeablePolicies: selectChangeablePolicies(state),
  canChangeInvestmentProfile: selectIsPolicyChangeable(state, props.route.params?.pID),
});

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { dispatch, savingsData, navigation, route } = getProps();
        const { pID } = route.params;

        if (pID) {
          if (!savingsData) {
            await dispatch([fetchSavingsOverview(), getCurrentInvestmentProfiles(true)]);
          }
        } else {
          navigation.replace(routeNames.SAVINGS_OVERVIEW);
        }

        return {
          navigateToChangeInvestmentProfile: () =>
            action(async () => dispatch(startChangeInvestmentProfileFlow()), { loader: true }),
        };
      },
      {
        loader: true,
      }
    ),
  trackAs: VIEW_SAVINGS_RETURN,
})(Return);
