import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components/native';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { useRoute, useNavigation } from '@react-navigation/native';
import ResultPage from '@sp/ui/pages/ResultPage';
import { BackButton } from '@sp/ui/pages/BasePage';
import useI18n from 'hooks/useI18n';
import { goBackToDashboardFromNestedStack } from 'helpers/navigation';
import * as routeNames from 'constants/routeNames';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import features from 'features';

const GenericError = ({ retryFn, titleKey, isDesktop, theme }) => {
  const route = useRoute();
  const navigation = useNavigation();
  const { i18n } = useI18n();

  const isModal = route.params?.isModal || false;

  const backButton = isDesktop ? (
    <BackButton
      label={i18n.t('back')}
      onPress={() => goBackToDashboardFromNestedStack(navigation, isModal)}
    />
  ) : undefined;

  const actions = retryFn
    ? [
        {
          text: i18n.t('tryAgain'),
          onPress: retryFn,
          mobilePosition: 'bottom',
        },
      ]
    : [];

  if (features.isEnabled('feedback')) {
    actions.push({
      text: i18n.t('reportError'),
      type: 'secondary',
      onPress: () =>
        navigation.navigate(routeNames.FEEDBACK, {
          screen: routeNames.FEEDBACK_CREATE,
          type: 'error',
          onBackPress: () => {
            navigation.popToTop();
            navigation.navigate(route.name);
          },
        }),
      mobilePosition: 'bottom',
    });
  }

  return (
    <ModalProvider isModal={isModal} onClose={() => navigation.navigate(routeNames.TABS)}>
      <ResultPage
        title={i18n.t(titleKey)}
        actions={actions}
        heading={i18n.t('genericErrorHeading')}
        description={i18n.t('genericErrorDescription')}
        iconColor={theme.COLORS.PRIMARY}
        iconName="warning"
        backButton={backButton}
      />
    </ModalProvider>
  );
};

GenericError.propTypes = {
  titleKey: PropTypes.string.isRequired,
  retryFn: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default connect(mapStateToProps)(withTheme(GenericError));
