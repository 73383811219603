import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import * as routeNames from 'constants/routeNames';
import { isWeb } from 'helpers/platform';
import { createOrRemoveDeeplinkPersistedStorage } from 'hooks/useHandleDeeplink/helpers';
import { useAppSelector } from 'hooks/redux';
import { selectDeeplink } from 'selectors/deeplink';
import { selectPartnerLinkGUID } from 'selectors/partner';
import { selectIsLoggedIn } from 'selectors/api';
import { navigate } from 'helpers/navigation';
import type { RootState } from 'store';

const selectUseStartAppAndHandleDeeplink = (state: RootState) => ({
  partnerGUID: selectPartnerLinkGUID(state),
  deeplink: selectDeeplink(state),
  loggedIn: selectIsLoggedIn(state),
  authCheckFinished: state.services.security.authCheckFinished,
});

export const useStartAppAndHandleDeeplink = (startApp: () => void) => {
  const { partnerGUID, deeplink, loggedIn, authCheckFinished } = useAppSelector(
    selectUseStartAppAndHandleDeeplink,
    shallowEqual
  );

  const { deeplinkFlow, isDeeplinkPriorToLogin, isDeeplinkComplete, isDeeplink } = deeplink ?? {};
  const isDeeplinkUnhandled = isWeb && isDeeplink && !isDeeplinkComplete;
  const isRouteNotFound = isWeb ? global.location.pathname === `/${routeNames.NOT_FOUND}` : false;
  const isAuthorizedAndLoggedIn = authCheckFinished && loggedIn;

  useEffect(() => {
    if (isWeb && isRouteNotFound) {
      return;
    } else if (isAuthorizedAndLoggedIn && startApp && !isDeeplink) {
      startApp();
    } else if (isDeeplinkPriorToLogin) {
      createOrRemoveDeeplinkPersistedStorage({
        flow: deeplinkFlow,
        guid: partnerGUID,
        isPriorToLogin: true,
      });

      navigate(routeNames.LOGIN, {});
    }
  }, [
    deeplinkFlow,
    isAuthorizedAndLoggedIn,
    isDeeplink,
    isDeeplinkPriorToLogin,
    isDeeplinkUnhandled,
    isRouteNotFound,
    partnerGUID,
    startApp,
  ]);
};
