import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styledWeb, { withTheme } from 'styled-components';
import Icon from '@sp/ui/base/Icon';
import { DropdownButton, SearchDropdownButton } from '@sp/ui/base/buttons';
import { Body } from '@sp/ui/typography';
import Conditional from 'decorators/conditional';
import { TextInput } from '@sp/ui/base/inputs';
import { getUpdatedOptionsAndSelected } from './index.shared';

const DROPDOWN_HORIZONTAL_PADDING = 8;
const DROPDOWN_ICON_SIZE = 10;
const DROPDOWN_MAX_HEIGHT = 300;
const DROPDOWN_BODY_WIDTH = 288;
const DROPDOWN_ITEM_VERTICAL_PADDING = 9;
const DROPDOWN_ITEM_HORIZONTAL_PADDING = 15;

const Wrapper = styledWeb.div`
  z-index: 1;
`;

const FakeOverlay = styledWeb.div`
  ${({ isVisible }) => (isVisible ? 'display: block;' : 'display: none;')}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: auto;
`;

const Dropdown = styledWeb.div`
  position: absolute;
  width: 100%;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  max-height: ${DROPDOWN_MAX_HEIGHT}px;
  overflow: auto;
  padding: ${DROPDOWN_HORIZONTAL_PADDING}px 0;
  border: 1px solid ${(props) => props.theme.COLORS.PRIMARY_BORDER};
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styledWeb.button`
  display: block;
  padding: ${DROPDOWN_ITEM_VERTICAL_PADDING}px ${DROPDOWN_ITEM_HORIZONTAL_PADDING}px;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  cursor: pointer;
  width: 100%;
  text-align: left;
  outline-style: none;
  border: none;
  &:focus, &:hover {
    background-color: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  }
`;

const DropdownContainerMobile = styledWeb.div`
  position: relative;
`;

const DropdownContainer = Conditional({
  mobile: DropdownContainerMobile,
  default: styledWeb(DropdownContainerMobile)`
    width: ${DROPDOWN_BODY_WIDTH}px;
  `,
});

const Selector = ({
  options,
  setValue,
  onSelect,
  placeholder,
  defaultValue,
  border,
  before,
  theme,
  withSearch,
  renderItem,
}) => {
  const { updatedOptions, selected } = getUpdatedOptionsAndSelected(options, defaultValue);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState(undefined);
  const [data, setData] = useState([]);

  const CustomDropdownButton = isSearching ? SearchDropdownButton : DropdownButton;

  const handleOnDropdownButtonPress = () => {
    setData(updatedOptions);
    setIsDropdownOpen(!isDropdownOpen);

    if (withSearch && !isSearching) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  };

  return (
    <Wrapper>
      <FakeOverlay
        onClick={() => {
          setIsSearching(false);
          setIsDropdownOpen(false);
        }}
        isVisible={isDropdownOpen}
      />
      <CustomDropdownButton onPress={handleOnDropdownButtonPress} border={border}>
        {isSearching ? (
          <TextInput
            marginTop={0}
            placeholder="Vælg"
            optional
            autoFocus
            onChangeText={(text) => {
              const filteredData = updatedOptions.filter(({ name }) =>
                name.toLowerCase().startsWith(text.toLowerCase())
              );

              setData(filteredData);
            }}
            after={
              <Icon
                name={isDropdownOpen ? 'arrowUpCentered' : 'arrowDownCentered'}
                width={DROPDOWN_ICON_SIZE}
                height={DROPDOWN_ICON_SIZE}
                fill={theme.COLORS.GRAPH_INCOME}
              />
            }
          />
        ) : (
          <>
            {before}
            <Body>
              {(currentlySelected && currentlySelected.name) || selected.name || placeholder}
            </Body>
            <Icon
              name={isDropdownOpen ? 'arrowUpCentered' : 'arrowDownCentered'}
              width={DROPDOWN_ICON_SIZE}
              height={DROPDOWN_ICON_SIZE}
              fill={theme.COLORS.GRAPH_INCOME}
            />
          </>
        )}
      </CustomDropdownButton>
      <DropdownContainer>
        {isDropdownOpen && (
          <Dropdown>
            {data &&
              data.map((option) =>
                typeof renderItem === 'function' ? (
                  renderItem(option.value, () => {
                    if (typeof onSelect === 'function') onSelect(option);
                    setCurrentlySelected(option);
                    setIsDropdownOpen(false);
                    setIsSearching(false);
                    if (typeof setValue === 'function') setValue(option.value);
                  })
                ) : (
                  <DropdownItem
                    key={option.name}
                    onClick={() => {
                      if (typeof onSelect === 'function') onSelect(option);
                      setIsDropdownOpen(false);
                      setCurrentlySelected(option);
                      setIsSearching(false);
                      if (typeof setValue === 'function') setValue(option.value);
                    }}
                  >
                    <Body>{option.name}</Body>
                  </DropdownItem>
                )
              )}
          </Dropdown>
        )}
      </DropdownContainer>
    </Wrapper>
  );
};

Selector.propTypes = {
  options: PropTypes.array.isRequired,
  setValue: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  border: PropTypes.bool,
  before: PropTypes.node,
  theme: PropTypes.object.isRequired,
  withSearch: PropTypes.bool,
  renderItem: PropTypes.func,
};

Selector.defaultProps = {
  onSelect: undefined,
  setValue: undefined,
  placeholder: undefined,
  border: true,
  defaultValue: '',
  before: undefined,
  withSearch: false,
  renderItem: undefined,
};

export default withTheme(Selector);
