import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { selectFormGrossAmountHasChanges, selectFormGrossAmount } from 'selectors/dashboardForms';
import { clickGrossAmount } from 'areas/dashboard/trackingIds';
import RadioButtonForm from 'containers/form/RadioButtonForm';
import useI18n from 'hooks/useI18n';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import Page from '../components/Page';
import { useSetShowGrossAmount, useInitCalculationProfile } from '../hooks';
import { selectShowGrossAmount } from 'selectors/dashboard';

const { Form } = require('react-formed');
const { default: Margin } = require('@sp/ui/base/Margin');
const { Body, Body2, H4 } = require('@sp/ui/v2/typography');
const { default: buttonPositions } = require('constants/buttonPositions');
const { default: Tracking } = require('services/tracking');
const { getShowGrossAmount } = require('areas/dashboard/AdjustCalculation/helpers');

const { default: withScreenContainer } = require('decorators/withScreenContainer');

const selectTaxInfo = (state: any) => ({
  hasChanges: selectFormGrossAmountHasChanges(state),
  showGrossAmount: selectShowGrossAmount(state),
  formGrossAmount: selectFormGrossAmount(state),
});

const TaxInfo: React.FC = () => {
  const { i18n } = useI18n();
  const { showGrossAmount, formGrossAmount, hasChanges } = useSelector(selectTaxInfo, shallowEqual);
  const [updateGrossAmount] = useSetShowGrossAmount(formGrossAmount.showGrossAmount);
  useInitCalculationProfile();

  const grossAmountValue = getShowGrossAmount(formGrossAmount, showGrossAmount);

  const radioProps = [
    {
      testID: testIds.TAX_INFO_SELECT_BEFORE_TAX,
      label: i18n.t('dashboard|grossAmount'),
      isSelected: grossAmountValue,
      value: true,
    },
    {
      label: i18n.t('dashboard|netAmount'),
      isSelected: !grossAmountValue,
      value: false,
    },
  ];

  return (
    <Page
      testID={testIds.ADJUST_CALCULATION_TAX_INFO_SCREEN}
      heading={i18n.t('dashboard|showWithGrossAmount')}
      actions={[
        {
          testID: testIds.TAX_INFO_SAVE_CHANGES_BUTTON,
          text: i18n.t('saveChange'),
          mobilePosition: buttonPositions.bottom,
          inactive: !hasChanges,
          onPress: updateGrossAmount,
        },
      ]}
      actionsContent={
        <Margin marginBottom="md" marginTop="sm">
          <Body2 secondaryColor>{i18n.t('dashboard|showWithGrossAmountTitle')}</Body2>
          <Margin marginTop="md">
            <Form name="adjustGrossAmount" resetOnMount>
              <RadioButtonForm
                name="showGrossAmount"
                radioProps={radioProps}
                onPress={(i: number) => Tracking.trackEvent(clickGrossAmount(radioProps[i].value))}
              />
            </Form>
          </Margin>
        </Margin>
      }
    >
      <>
        <Margin marginBottom="md">
          <H4>{i18n.t('dashboard|grossAmount')}</H4>
        </Margin>
        <Body>{i18n.t('dashboard|showWithGrossAmountModalDescription')}</Body>
        <Margin marginTop="lg" marginBottom="md">
          <H4>{i18n.t('dashboard|netAmount')}</H4>
        </Margin>
        <Body>{i18n.t('dashboard|showWithNetAmountModalDescription')}</Body>
      </>
    </Page>
  );
};

export default withScreenContainer({})(TaxInfo);
