import currencySymbol from 'helpers/currencySymbol';
import { formatMoney, formatMonth, capitalizeFirstLetter, formatYear } from 'helpers/string';

export const getLatestDepositDetails = (allDeposits) => {
  if (!allDeposits?.[0]?.deposits?.length) return {};

  const { deposits } = allDeposits[0];
  const { totalAmount, periodStart } = deposits[deposits.length - 1];

  return {
    latestDepositValue:
      totalAmount?.amount &&
      `${formatMoney({ amount: totalAmount.amount }, true)}${currencySymbol(
        totalAmount.currency.alphaCode
      )}`,
    latestDepositDate: `${capitalizeFirstLetter(formatMonth(periodStart))} ${formatYear(
      periodStart
    )}`,
  };
};
