import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from 'decorators/i18n';
import { DashboardRow, RowBorder } from '@sp/ui/base/rows';
import { changeLanguage } from 'services/i18n';

const ChangeLanguage = ({ i18n }) => {
  const { language } = i18n;
  const isDanish = language === 'da';
  const iconName = isDanish ? 'en_flag' : 'dk_flag';
  const textLeft = isDanish ? i18n.t('Oversæt til Engelsk') : i18n.t('Oversæt til Dansk');
  const setLanguageTo = isDanish ? 'en' : 'da';

  return (
    <>
      <DashboardRow
        arrow
        textLeft={textLeft}
        iconLeft={{ name: iconName }}
        onPress={() => changeLanguage(setLanguageTo)}
      />
      <RowBorder />
    </>
  );
};

ChangeLanguage.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(ChangeLanguage);
