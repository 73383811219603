import * as routeNames from 'constants/routeNames';
import { selectNotificationSettingsCompanyGroups } from 'selectors/settings';
import { fetchNotificationSettingsCompanyGroups } from 'actions/settings';

export default async (store, payload) => {
  const { dispatch } = store;
  const { isModal } = payload;
  let companyGroups = selectNotificationSettingsCompanyGroups(store.getState());

  if (companyGroups.length === 0) {
    await dispatch(fetchNotificationSettingsCompanyGroups());
  }

  companyGroups = selectNotificationSettingsCompanyGroups(store.getState());

  if (companyGroups.length === 1) {
    return {
      name: isModal
        ? routeNames.SETTINGS_NOTIFICATION_SETTINGS_MODAL
        : routeNames.SETTINGS_NOTIFICATION_SETTINGS,
      params: {
        companyName: companyGroups[0].name,
        companyGroup: companyGroups[0].companyGroup,
        isModal,
      },
    };
  }

  return {
    name: isModal
      ? routeNames.SETTINGS_NOTIFICATION_SETTINGS_MODAL
      : routeNames.SETTINGS_NOTIFICATION_SETTINGS_COMPANY_GROUPS,
    params: {
      isModal,
    },
  };
};
