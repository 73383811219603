import React from 'react';
import styled from 'styled-components/native';

const { default: RowWithRadioButton } = require('@sp/ui/v2/base/inputs/RowWithRadioButton');
const { default: Conditional } = require('decorators/conditional');
const { Body2 } = require('@sp/ui/v2/typography');

const RADIO_BUTTON_WRAPPER_LEFT_MARGIN_MOBILE = 20;
const RADIO_BUTTON_WRAPPER_LEFT_MARGIN = 24;

type IsVerticalType = boolean;

type RadioButtonWrapperTypes = {
  isVertical?: IsVerticalType;
  isFirst?: boolean;
  borders?: boolean;
};

type Selections = {
  label: string;
  isSelected: boolean;
  testID?: string;
  disabled?: boolean;
};

export type Props = RadioButtonWrapperTypes & {
  testID?: string;
  radioProps?: Selections[];
  leftAlignRadioButton?: boolean;
  onPress?: (i: number) => void;
};

const Wrapper = styled.View<Pick<Props, 'isVertical'>>`
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
`;

const RadioButtonWrapperMobile = styled.View<RadioButtonWrapperTypes>`
  ${({ isVertical }) => (isVertical ? '' : 'flex: 1;')};
  margin-left: ${({ isFirst, isVertical }) =>
    !isVertical && !isFirst ? RADIO_BUTTON_WRAPPER_LEFT_MARGIN_MOBILE : 0}px;
`;

const RadioButtonWrapperTablet = styled.View<RadioButtonWrapperTypes>`
  flex: 1;
  margin-left: ${({ isFirst, isVertical }) =>
    !isVertical && !isFirst ? RADIO_BUTTON_WRAPPER_LEFT_MARGIN : 0}px;
`;

const RadioButtonWrapper = Conditional({
  default: RadioButtonWrapperTablet,
  mobile: RadioButtonWrapperMobile,
});

const RadioButtonGroup: React.FC<Props> = ({
  isVertical,
  onPress,
  radioProps,
  leftAlignRadioButton,
}) => (
  <Wrapper isVertical={isVertical}>
    {radioProps?.map(({ testID, label, isSelected, disabled }, i: number) => (
      <RadioButtonWrapper key={i} isVertical={isVertical} isFirst={i === 0} borders={isVertical}>
        <RowWithRadioButton
          testID={testID}
          ignoreIsSelectedBackgroundColor
          leftAlignRadioButton={leftAlignRadioButton}
          titleRight={
            <Body2 fontWeight="regular" secondaryColor>
              {label}
            </Body2>
          }
          isSelected={isSelected}
          onPress={() => {
            if (disabled || !onPress) {
              return undefined;
            }

            return onPress(i);
          }}
          border={!isVertical}
        />
      </RadioButtonWrapper>
    ))}
  </Wrapper>
);

export default RadioButtonGroup;
