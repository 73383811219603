import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useStore } from 'react-redux';
import * as routeNames from 'constants/routeNames';
import * as notFoundConstants from 'constants/notFound';
import { selectDeeplink } from 'selectors/deeplink';
import { selectPartnerLinkGUID } from 'selectors/partner';
import { getValidDeeplinkRoute } from './';
import type { RootState } from 'store';

export const useNavigateToDeeplink = () => {
  const store = useStore();
  const navigation = useNavigation();

  return useCallback(() => {
    const state = store.getState() as RootState;
    const { deeplinkFlow } = selectDeeplink(state) as { deeplinkFlow: string };
    const partnerGUID = selectPartnerLinkGUID(state);
    const screen = getValidDeeplinkRoute(deeplinkFlow);

    const isScreenDeeplinkWarning = screen === routeNames.DEEPLINK_WARNING;
    const derivedParams = partnerGUID ? { guid: partnerGUID } : undefined;
    const params = isScreenDeeplinkWarning
      ? { type: notFoundConstants.DEEPLINK_DONT_EXIST }
      : derivedParams;

    if (screen === routeNames.CHANGE_INVESTMENT_PROFILE) {
      navigation.navigate(routeNames.CHANGE_INVESTMENT_PROFILE, {
        screen: routeNames.CHANGE_INVESTMENT_PROFILE_START_FLOW,
        params,
      });
      return;
    }
    navigation.navigate(routeNames.DEEPLINK, { screen: screen, params });
  }, [navigation, store]);
};
