import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { downloadDocumentAndShare } from 'helpers/share';
import log from 'services/log';
import { getAccessToken } from 'actions/api';
import FlexPage from '@sp/ui/pages/FlexPage';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import Pdf from '@sp/ui/base/Pdf';
import Button from '@sp/ui/layout/Header/Button';

const Viewer = ({ url, title, token, navigation, isDesktop, i18n }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <ModalProvider
      title={title}
      onClose={() => navigation.goBack()}
      leftContent={
        !isLoading && (
          <Button
            icon="dots"
            accessibilityLabel={i18n.t('Send, udskriv eller gem dokument.')}
            onPress={() => downloadDocumentAndShare(url, title)}
          />
        )
      }
      isModal
      iPhoneXOffset={false}
    >
      <FlexPage title={title} hasBackButton={isDesktop} testID="documentsViewerScreen" fullWidth>
        {token && (
          <Pdf
            url={url}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            onLoadComplete={() => setIsLoading(false)}
            onError={(error) => log.warn('Error loading pdf document', error)}
            errorMessage={i18n.t('common|Fejl ved indlæsning af pdf')}
          />
        )}
      </FlexPage>
    </ModalProvider>
  );
};

Viewer.propTypes = {
  url: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  title: PropTypes.string,
  navigation: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
};

Viewer.defaultProps = {
  title: undefined,
};

const mapStateToProps = (state, props) => ({
  ...props,
  url: decodeURIComponent(props.route.params?.url),
  isDesktop: selectIsDeviceTypeDesktop(state),
  title: props.route.params?.title ? decodeURIComponent(props.route.params?.title) : undefined,
});

const init = ({ action, getProps }) =>
  action(async () => {
    const { dispatch } = getProps();
    const token = await dispatch(getAccessToken());

    return {
      token,
    };
  });

export default withScreenContainer({
  hasLocalNotifications: false,
  mapStateToProps,
  init,
})(Viewer);
