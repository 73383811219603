import { shallowEqual } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useAsyncCallback from 'hooks/useAsyncCallback';
import { updateCalculationProfile } from 'actions/calculations';
import { selectCalculationSettings } from 'selectors/dashboard';

export const useSubmitIncomeInformation = () => {
  const calculationSettings = useAppSelector(selectCalculationSettings, shallowEqual);
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  return useAsyncCallback(async (incomeOptions) => {
    await dispatch(
      updateCalculationProfile({
        ...calculationSettings,
        income: {
          ...calculationSettings?.income,
          declaredAnnualIncomeSelf:
            +(incomeOptions.enteredIncome as string).split('.').join('') * 12,
        },
        publicBenefits: {
          ...calculationSettings?.publicBenefits,
          earlyRetirement: incomeOptions.selectedEarlyRetirement,
        },
        tax: {
          ...calculationSettings?.tax,
          isChurchContributor: incomeOptions.isChurchTaxSelected,
        },
      })
    );

    navigation.navigate(routeNames.PARTNER_RECEIPT);
  });
};
