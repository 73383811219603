import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { RowWithCheckbox } from '@sp/ui/v2/base/inputs';
import { Body2 } from '@sp/ui/v2/typography';

const Wrapper = styled.View`
  padding: ${({ theme, hasPadding }) => (hasPadding ? `${theme.SPACINGS.md}px 0` : '0px')};
  background-color: ${({ theme, hasBackgroundColor }) =>
    hasBackgroundColor
      ? theme.COLORS.V2_SECONDARY_BACKGROUND_LEVEL_1
      : theme.COLORS.V2_SECONDARY_BACKGROUND};
  ${({ hasOnlyBorderTopRadius, theme }) =>
    hasOnlyBorderTopRadius
      ? `
        border-top-left-radius: ${theme.DEFAULTS.BORDER_RADIUS}px;
        border-top-right-radius: ${theme.DEFAULTS.BORDER_RADIUS}px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        `
      : `border-radius: ${theme.DEFAULTS.BORDER_RADIUS}px;`};
`;

const TermsCheckbox = ({ text, ...props }) => {
  return (
    <Wrapper
      hasPadding={props.hasPadding}
      hasBackgroundColor={props.hasBackgroundColor}
      hasOnlyBorderTopRadius={props.hasOnlyBorderTopRadius}
    >
      <RowWithCheckbox
        titleLeft={typeof text === 'string' ? <Body2 secondaryColor>{text}</Body2> : text}
        align="left"
        hasCheckedBackground={false}
        {...props}
      />
    </Wrapper>
  );
};

TermsCheckbox.propTypes = {
  text: PropTypes.string.isRequired,
  onChangeChecked: PropTypes.func,
  hasBackgroundColor: PropTypes.bool,
  hasOnlyBorderTopRadius: PropTypes.bool,
  hasPadding: PropTypes.bool,
};

TermsCheckbox.defaultProps = {
  onChangeChecked: undefined,
  hasBackgroundColor: true,
  hasPadding: true,
  hasOnlyBorderTopRadius: false,
};

export default withTheme(TermsCheckbox);
