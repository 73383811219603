import { get, put } from 'actions/api';
import {
  NOTIFICATION_SETTINGS,
  NOTIFICATION_SETTINGS_COMPANY_GROUPS,
  UPDATE_NOTIFICATION_SETTINGS,
  CLEAR_NOTIFICATION_SETTINGS,
  NAVIGATE_TO_NOTIFICATION_SETTINGS,
} from 'constants/actionTypes';

export const navigateToNotificationSettings = (isModal) => ({
  type: NAVIGATE_TO_NOTIFICATION_SETTINGS,
  payload: {
    isModal,
  },
});

export const fetchNotificationSettingsCompanyGroups = () =>
  get(NOTIFICATION_SETTINGS_COMPANY_GROUPS, 'api', '/settings/v2/notifications');

export const fetchNotificationSettingsForCompanyGroup = (companyGroup) =>
  get(NOTIFICATION_SETTINGS, 'api', `/settings/v2/notifications/${companyGroup}`);

export const updateNotificationSettingsForCompanyGroup = (key, isEnabled, companyGroup) =>
  put(
    UPDATE_NOTIFICATION_SETTINGS,
    { key, isEnabled, companyGroup },
    'api',
    '/settings/v2/notifications'
  );

export const clearNotificationSettings = (type) =>
  get(CLEAR_NOTIFICATION_SETTINGS, 'api', `/settings/v2/notifications/clear/${type}`);
