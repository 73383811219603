import { create } from '../helpers/reducers';

interface State {
  data: {
    savings?: any;
  };
  isFetching: boolean;
  depositFetchingState: string;
}

const createDefaults = (): State => ({
  data: {
    savings: undefined,
  },
  isFetching: false,
  depositFetchingState: 'sleeping',
});

export default create(
  {
    REQUEST_GET_SAVINGS_OVERVIEW: (state) => ({
      ...state,
      isFetching: true,
    }),
    RESPONSE_GET_SAVINGS_OVERVIEW: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        savings: payload.response,
      },
      isFetching: false,
    }),
    REQUEST_GET_SAVINGS_DEPOSITS: (state) => ({
      ...state,
      depositFetchingState:
        state.depositFetchingState === 'sleeping' ? 'fetching' : state.depositFetchingState,
    }),
    RESPONSE_GET_SAVINGS_DEPOSITS: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        savings: {
          ...state.data.savings,
          internalSavings: state.data.savings.internalSavings.map((item: any) =>
            item.coverageNumber === payload.response.policyId
              ? {
                  ...item,
                  deposits: payload.response.deposits,
                }
              : item
          ),
        },
      },
      depositFetchingState:
        state.data.savings.internalSavings.find(
          (item: any) => item.coverageNumber === payload.response.policyId
        ).minimumYear === payload.response.year
          ? 'finished'
          : state.depositFetchingState,
    }),
    UPDATE_DEPOSIT_FETCHING_STATE: (state, payload) => ({
      ...state,
      depositFetchingState: payload,
    }),
    UPDATE_ADMIN_COSTS: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        yearlyAdminCosts: payload,
      },
    }),
  },
  createDefaults
);

export type { State };
