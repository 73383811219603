import { APP_LOCK } from 'constants/actionTypes';

export const storeSession = () => ({
  type: '@@SECURITY/STORE_SESSION',
});

export const unlockSession = () => ({
  type: '@@SECURITY/UNLOCK_SESSION',
});

export const removeBiometrics = () => ({
  type: '@@SECURITY/REMOVE_BIOMETRICS',
});

export const authCheckFinished = (value = true) => ({
  type: 'AUTH_CHECK_FINISHED',
  payload: value,
});

export const appLock = (reason, options) => ({
  type: APP_LOCK,
  payload: {
    reason,
    options,
  },
});

export const appUnlock = () => ({
  type: APP_LOCK,
  payload: undefined,
});

/**
 * @param {*} type - the type of logout to perform
 * possible options are:
 * - 'hard' - complete log out of the user removing all session information
 * - 'soft' - only supported on native, take user back to the intro screen
 *  but retain the login information making it possible to log back in with biometrics
 */

export const logout = (type = 'hard') => ({
  type: '@@SECURITY/LOGOUT',
  meta: {
    type,
  },
});
