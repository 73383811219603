import { create } from '../helpers/reducers';

interface State {
  notificationSettings: {
    settings: any[];
    companyGroups: any[];
  };
  contents: any[];
}

const createDefaults = (): State => ({
  notificationSettings: {
    settings: [],
    companyGroups: [],
  },
  contents: [],
});

export default create(
  {
    RESPONSE_NOTIFICATION_SETTINGS: (state, payload) => ({
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        settings: payload.response,
      },
    }),
    RESPONSE_CONTENT: (state, payload) => ({
      ...state,
      contents: payload.response.contents,
    }),
    REQUEST_UPDATE_NOTIFICATION_SETTINGS: (state, payload) => {
      const { key, isEnabled } = payload;

      const settings = state.notificationSettings.settings.map((s: any) => {
        if (s.key !== key) {
          return s;
        }

        return {
          ...s,
          isEnabled,
        };
      });

      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          settings,
        },
      };
    },
    RESPONSE_NOTIFICATION_SETTINGS_COMPANY_GROUPS: (state, payload) => ({
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        companyGroups: payload.response,
      },
    }),
  },
  createDefaults
);

export type { State };
