import { useContext } from 'react';
import LoadingContext from '@sp/ui/context/LoadingContext';
import Conditional from 'decorators/conditional';

const LoadingView = () => {
  const { loader } = useContext(LoadingContext);
  return loader || null;
};

export default Conditional({
  tablet: LoadingView,
  desktop: null,
  mobile: LoadingView,
});
