import log from 'services/log';

export const create = (area) => (next, action, actions) => {
  const [actionArea, actionType] = action.type.split('/');
  if (actionArea === area) {
    if (!actions[actionType]) {
      log.error(`middleware action ${actionType} is not valid for area ${actionArea}`);
    }
    return actions[actionType](action, next);
  }
  return next(action);
};
