import { isAndroid } from '@sp/ui/helpers/device';
import { COLORS } from '@sp/ui/settings';

export const shadow = `
  box-shadow: 0px 0px 3px ${COLORS.V2_SHADOW};
  ${isAndroid ? 'elevation: 4;' : ''}
`;

export const shadow2 = `
  box-shadow: 0px -3px 2px ${COLORS.V2_SHADOW};
  ${isAndroid ? 'elevation: 4;' : 'shadow-opacity: 0.1;'}
`;
