import {
  GET_TRANSFER_OVERVIEW,
  UPDATE_TRANSFER_FORM,
  UPDATE_TRANSFER_SOURCE_SELECTIONS,
  UPDATE_TRANSFER_QUESTION_ANSWERS,
  UPDATE_TRANSFER_CONTACT_DETAILS,
  POST_TRANSFER_CONSENTS,
  POST_TRANSFER_FORM,
  CREATE_TRANSFER_FORM,
  RESET_TRANSFER_FORM,
} from 'constants/actionTypes';
import type { GroupTransferQuestionAnswersByPolicyReturnResponse } from 'areas/transfer/types';
import type { TransferState } from 'reducers/transfer';

const { get, post } = require('actions/api');
const { getTransferPostRequestData } = require('./helpers/transfer/getTransferPostRequestData');

export const fetchTransferOverview = () =>
  get(GET_TRANSFER_OVERVIEW, 'api', '/savings/v3/transfer');

export const fetchTransferConsents = (transferForm: TransferState['transferForm']) => {
  const { targetPolicyId, sourcePolicies, consents } = getTransferPostRequestData(transferForm);

  return post(
    POST_TRANSFER_CONSENTS,
    {
      targetPolicyId,
      sourcePolicies,
      consents,
    },
    'api',
    '/savings/v3/transfer/consents'
  );
};

export const postTransferData = (
  transferForm: TransferState['transferForm'],
  contactDetails: TransferState['transferForm']['contactDetails']
) =>
  post(
    POST_TRANSFER_FORM,
    getTransferPostRequestData({ ...transferForm, contactDetails }),
    'api',
    '/savings/v3/transfer'
  );

type transferQuestionAnswers = {
  alternativeReference?: string;
  policyReference: string;
};

export const updateTransferForm = (values: {
  action:
    | typeof UPDATE_TRANSFER_SOURCE_SELECTIONS
    | typeof UPDATE_TRANSFER_QUESTION_ANSWERS
    | typeof UPDATE_TRANSFER_CONTACT_DETAILS;
  transferQuestionAnswers?: GroupTransferQuestionAnswersByPolicyReturnResponse[];
  availableIndexes?: number[];
  contactDetails?: TransferState['transferForm']['contactDetails'];
  selectedSourceIds?: string[];
}) => ({
  type: UPDATE_TRANSFER_FORM,
  payload: { ...values },
});

export const createTransferForm = (targetPolicyId?: string) => ({
  type: CREATE_TRANSFER_FORM,
  payload: targetPolicyId,
});

export const resetTransferForm = () => ({
  type: RESET_TRANSFER_FORM,
});
