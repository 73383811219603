import PropTypes from 'prop-types';
import { createElement } from './helpers';

const NodeGenerator = ({ goToPage, handleLink, nodeDefinition, featureCheck }) =>
  createElement(nodeDefinition, goToPage, handleLink, featureCheck);

const linkType = PropTypes.shape({
  type: PropTypes.oneOf(['Page', 'App', 'Url', 'Phone', 'Map']),
  target: PropTypes.string.isRequired,
});

export const elementType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string.isRequired]),
  type: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  data: PropTypes.object,
  content: PropTypes.any,
  link: linkType,
});

NodeGenerator.propTypes = {
  goToPage: PropTypes.func.isRequired,
  nodeDefinition: elementType,
  handleLink: PropTypes.func,
  featureCheck: PropTypes.func,
};

NodeGenerator.defaultProps = {
  nodeDefinition: {},
  handleLink: undefined,
  featureCheck: undefined,
};

export default NodeGenerator;
