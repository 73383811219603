import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose, Action } from 'redux';
import serviceReducer from 'services/reducers';
import areaReducer from './reducers';

const { default: serviceMiddleware } = require('services/middleware');
const { formReducer } = require('react-formed');
const { injectReduxMiddleware } = require('flipper');

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  services: serviceReducer,
  areas: areaReducer,
  forms: formReducer as (...args: any[]) => any,
});

type RootState = ReturnType<typeof rootReducer>;

injectReduxMiddleware(serviceMiddleware);

const configureStore = (initState?: RootState) =>
  createStore(rootReducer, initState, composeEnhancers(applyMiddleware(...serviceMiddleware)));

export type AppDispatch<T = Action> = (actions: Action | Action[]) => Promise<T>;

export type { RootState };
export default configureStore;
