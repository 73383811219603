import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StackActions } from '@react-navigation/native';
import features from 'features';
import styled, { withTheme } from 'styled-components/native';
import withTranslation from 'decorators/i18n';
import { initFunc, initProp } from 'helpers/props';
import Mail from 'areas/mailbox/Mail';
import Alert from '@sp/ui/base/inputs/Alert';
import { Form } from 'react-formed';
import { BackButton } from '@sp/ui/pages/BasePage';
import InputPage from '@sp/ui/pages/InputPage';
import Message from 'containers/form/Message';
import SendButtons from 'areas/mailbox/SendButtons';
import Margin from '@sp/ui/base/Margin';
import { Button } from '@sp/ui/base/buttons';
import { formatDate } from 'helpers/string';
import { hasDocumentWithStatus } from 'areas/mailbox/helpers/documents';
import { TwoLineRow, RowBorder } from '@sp/ui/base/rows';
import { H5 } from '@sp/ui/typography';
import { FlatList } from '@sp/ui/base/lists';
import { pickFileAndGetAttachment } from 'helpers/fileUpload';
import refCache from 'services/refCache';
import DeleteDraftAlert from 'areas/general/alerts/DeleteDraftAlert';
import { addAttachment, removeAttachmentById } from 'areas/mailbox/helpers/attachments';
import * as routeNames from 'constants/routeNames';

const MESSAGE_MIN_HEIGHT = 200;

const ButtonWrapper = styled.View`
  align-items: center;
`;

const Wrapper = styled.View`
  flex-direction: row;
`;

const FlexWrapper = styled.View`
  flex: 1;
`;

const getTitle = (isArchived, isReplying, i18n) => {
  if (isArchived) return i18n.t('mailbox|archive');
  if (isReplying) return '';

  return i18n.t('mailbox|inbox');
};

const mailKeyExtractor = (item) => item.id;

class Details extends Component {
  constructor() {
    super();
    this.messageInputRefId = refCache.create();
  }

  state = {
    attachments: [],
    isReplying: false,
    isArchiveModalOpen: false,
    isDraftModalOpen: false,
  };

  componentWillUnmount() {
    refCache.remove(this.messageInputRefId);
    this.handleInProgressDocuments();
  }

  onReplyPress = () => {
    const { showError, isAdvisorsBusy, i18n } = this.props;

    if (isAdvisorsBusy) {
      showError(i18n.t('mailbox|longResponseTimesMsg'), { type: 'warning' });
    }

    this.setState({ isReplying: true }, () => refCache.get(this.messageInputRefId).current.focus());
  };

  getToolbarIcons() {
    const { theme, isArchived, canReply } = this.props;

    const icons = [
      {
        name: isArchived ? 'putToInbox' : 'archive',
        fill: theme.COLORS.PRIMARY,
        onPress: () => this.setState({ isArchiveModalOpen: true }),
      },
    ];

    if (canReply) {
      icons.push({
        name: 'reply',
        fill: theme.COLORS.PRIMARY,
        onPress: this.onReplyPress,
      });
    }

    return icons;
  }

  getActions() {
    const actions = [];
    const { isArchived, form, send, canReply, i18n } = this.props;
    const { attachments, isReplying } = this.state;

    if (canReply) {
      if (isReplying && features.isEnabled('mailboxAddAttachments')) {
        actions.push({
          icon: 'attachment',
          onPress: () => {
            this.openFilePickerAndAttachFile();
          },
        });
      }

      if (isReplying) {
        actions.push({
          text: i18n.t('common|send'),
          inactive: !form.content,
          onPress: () => {
            this.setState({
              isReplying: false,
            });

            send(attachments);
          },
        });
      }

      if (!isReplying) {
        actions.push({
          text: i18n.t('common|reply'),
          mobilePosition: 'bottom',
          onPress: this.onReplyPress,
        });
      }
    }

    if (!isReplying && !isArchived) {
      actions.push({
        icon: 'archive',
        accessibilityLabel: i18n.t('mailbox|moveToArchive'),
        onPress: () => this.setState({ isArchiveModalOpen: true }),
      });
    }

    if (!isReplying && isArchived) {
      actions.push({
        icon: 'putToInbox',
        accessibilityLabel: i18n.t('mailbox|moveToInbox'),
        onPress: () => this.setState({ isArchiveModalOpen: true }),
      });
    }

    return actions;
  }

  handleInProgressDocuments = () => {
    const {
      data,
      cancelPollIndividualMail,
      isArchived,
      fetchAllMails,
      patchOverviewWithUpdatedMail,
    } = this.props;

    cancelPollIndividualMail();

    // NOTE: We need this to initiate polling in overview if needed
    // TODO: Handle all polling logic in middleware and make it screen independent
    if (hasDocumentWithStatus(data, 'inProgress')) {
      fetchAllMails(isArchived);
    }

    patchOverviewWithUpdatedMail(data);
  };

  removeAttachment = (id) => {
    this.setState((prevState) => ({
      attachments: removeAttachmentById(prevState.attachments, id),
    }));
  };

  openFilePickerAndAttachFile = () => {
    const { validateAttachments } = this.props;
    const { attachments } = this.state;

    pickFileAndGetAttachment(async (attachment) => {
      const updatedAttachments = (prevState) =>
        addAttachment(prevState, {
          id: attachment.filename,
          ...attachment,
        });
      const isValid = await validateAttachments(updatedAttachments(attachments));

      if (isValid) {
        this.setState((prevState) => ({
          attachments: updatedAttachments(prevState.attachments),
        }));
      }
    });
  };

  render() {
    const {
      data,
      subject,
      theme,
      isArchived,
      form,
      send,
      isDesktop,
      toggleArchived,
      sign,
      open,
      canReply,
      navigation,
      route,
      i18n,
    } = this.props;

    const { isReplying, isArchiveModalOpen, attachments } = this.state;

    const onClose = () => {
      const hasContent = !!form.content || attachments.length > 0;
      if (hasContent) {
        this.setState({ isDraftModalOpen: true });
      } else {
        this.handleInProgressDocuments();
        navigation.goBack();

        navigation.dispatch({
          ...StackActions.replace(routeNames.MAIL_OVERVIEW),
          source: route.key,
          target: navigation.getState().key,
        });
      }
    };

    return (
      <Form name="mailboxReply" resetOnMount>
        <InputPage
          backButton={
            <BackButton
              label={i18n.t('Tilbage')}
              onPress={onClose}
              accessibilityLabel={i18n.t('mailbox|backToPreviousScreen')}
            />
          }
          actions={isDesktop ? undefined : this.getActions(form, send)}
          title={getTitle(isArchived, isReplying, i18n)}
          enableResetScrollToCoords={false} // Prevents scrolling up/down when selecting files
          testID="mailboxDetailsScreen"
        >
          <Wrapper>
            <FlexWrapper>
              {data && (
                <FlatList
                  data={data}
                  hasSeparator={false}
                  keyExtractor={mailKeyExtractor}
                  renderItem={({ item, index }) => (
                    <Mail
                      {...item}
                      title={subject}
                      toolbarIcons={isDesktop ? this.getToolbarIcons() : []}
                      isFirst={parseInt(index, 10) === 0}
                      onDocumentPress={(document) => {
                        if (sign && document.status === 'toBeSigned') {
                          sign(item.id, document.id);
                        } else if (open) {
                          open(document);
                        }
                      }}
                    />
                  )}
                />
              )}
            </FlexWrapper>
          </Wrapper>

          {canReply && isDesktop && !isReplying && (
            <Margin marginTop="lg">
              <ButtonWrapper>
                <Button text={i18n.t('common|reply')} onPress={this.onReplyPress} />
              </ButtonWrapper>
            </Margin>
          )}

          {isReplying && (
            <Fragment>
              <RowBorder />
              <TwoLineRow
                textLeft={
                  <H5 numberOfLines={1}>{i18n.t('mailbox|subjectReplyLabel', { subject })}</H5>
                }
                subtitleLeft={formatDate(new Date())}
              />
              <RowBorder />

              <Margin horizontal="xl">
                <Message
                  name="content"
                  minHeight={MESSAGE_MIN_HEIGHT}
                  maxHeight={MESSAGE_MIN_HEIGHT}
                  marginTop={theme.SPACINGS.md}
                  attachments={attachments}
                  refId={this.messageInputRefId}
                  onAttachmentDeletePress={this.removeAttachment}
                  deleteImageText={i18n.t('mailbox|removeImage')}
                />
                {canReply && isDesktop && (
                  <Margin marginTop="md">
                    <SendButtons
                      form={form}
                      send={() => send(attachments)}
                      isButtonInactive={!form.content}
                      onAttachmentPress={this.openFilePickerAndAttachFile}
                    />
                  </Margin>
                )}
              </Margin>
            </Fragment>
          )}
          <Alert
            onRequestClose={() => this.setState({ isArchiveModalOpen: false })}
            visible={isArchiveModalOpen}
            title={isArchived ? i18n.t('mailbox|moveToInbox') : i18n.t('mailbox|moveToArchive')}
            description={
              isArchived ? i18n.t('mailbox|moveToInboxMsg') : i18n.t('mailbox|moveToArchiveMsg')
            }
            actions={[
              {
                text: i18n.t('common|yes'),
                onPress: toggleArchived,
              },
              {
                text: i18n.t('common|no'),
                style: 'secondary',
                onPress: () => this.setState({ isArchiveModalOpen: false }),
              },
            ]}
          />
          <DeleteDraftAlert
            isOpen={this.state.isDraftModalOpen}
            setIsOpen={(value) => this.setState({ isDraftModalOpen: value })}
            onClose={navigation.goBack}
          />
        </InputPage>
      </Form>
    );
  }
}

Details.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  sender: initProp(PropTypes.string),
  subject: initProp(PropTypes.string),
  theme: PropTypes.object.isRequired,
  isArchived: PropTypes.bool,
  form: PropTypes.object,
  send: initFunc,
  isDesktop: PropTypes.bool.isRequired,
  toggleArchived: PropTypes.func,
  sign: PropTypes.func,
  open: PropTypes.func,
  canReply: PropTypes.bool,
  validateAttachments: initFunc,
  navigation: PropTypes.object.isRequired,
  cancelPollIndividualMail: initFunc,
  fetchAllMails: initFunc,
  patchOverviewWithUpdatedMail: initFunc,
  route: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  showError: PropTypes.func.isRequired,
  isAdvisorsBusy: PropTypes.bool.isRequired,
};

Details.defaultProps = {
  isArchived: false,
  canReply: true,
  form: {},
  toggleArchived: undefined,
  subject: undefined,
  sign: undefined,
  open: undefined,
  validateAttachments: undefined,
  send: undefined,
  sender: undefined,
  cancelPollIndividualMail: undefined,
  fetchAllMails: undefined,
  patchOverviewWithUpdatedMail: undefined,
};

export default withTranslation(['mailbox'])(withTheme(Details));
