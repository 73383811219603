import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Label } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';
import Conditional from 'decorators/conditional';

const HEIGHT = 35;
const WIDTH = 320;
const BOTTOM_BORDER_WIDTH = 3;

const TabsConditional = Conditional({
  mobile: styled.View``,
  default: styled.View`
    padding-left: ${({ theme }) => theme.SPACINGS.md}px;
  `,
});

const Tabs = styled(TabsConditional)`
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
  flex-direction: row;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  justify-content: space-between;
`;

const Tab = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${({ theme }) => theme.SPACINGS.sm}px;
  ${({ backgroundColor, focused, theme }) =>
    `background-color: ${focused ? theme.COLORS.SECONDARY_BACKGROUND : backgroundColor}`};
  border-style: solid;
  border-bottom-color: ${({ focused, theme }) =>
    focused ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_BACKGROUND};
  border-bottom-width: ${({ focused }) => (focused ? BOTTOM_BORDER_WIDTH : 0)}px;
`;

const ViewSelector = ({ tabs, focusedTab, theme }) => (
  <Tabs>
    {tabs.map((tab) => {
      const isFocused = tab.id === focusedTab;
      return (
        <Tab
          testID={tab.testID}
          key={tab.id}
          focused={isFocused}
          onPress={tab.onPress}
          backgroundColor={tab.backgroundColor}
          disabled={tab.disabled}
        >
          {tab.iconName && (
            <Margin marginRight="xs">
              <Icon
                name={tab.iconName}
                fill={isFocused ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_INACTIVE}
              />
            </Margin>
          )}
          <Label
            color={isFocused ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_TEXT}
            fontWeight={isFocused ? 'bold' : 'regular'}
          >
            {tab.title}
          </Label>
        </Tab>
      );
    })}
  </Tabs>
);

ViewSelector.propTypes = {
  focusedTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
      iconName: PropTypes.string.isRequired,
    })
  ).isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ViewSelector);
