import React from 'react';
import { AsyncProvider } from '../context/AsyncContext';

const { default: Loader } = require('containers/base/Loader');
const { LoadingProvider } = require('@sp/ui/context/LoadingContext');

type CreateWithAsyncProps = {
  hasLoadingContext?: boolean;
};

type LoadingContextWrapperProps = {
  hasLoadingContext?: CreateWithAsyncProps['hasLoadingContext'];
  children: React.ReactNode;
};

const LoadingContextWrapper: React.FC<LoadingContextWrapperProps> = ({
  hasLoadingContext,
  children,
}: LoadingContextWrapperProps) =>
  hasLoadingContext ? <LoadingProvider>{children}</LoadingProvider> : <>{children}</>;

const createWithAsync =
  <P extends CreateWithAsyncProps>({ hasLoadingContext }: CreateWithAsyncProps) =>
  (Wrapped: React.FC<P>): React.FC => {
    const WithInit: React.FC = (props) => (
      <LoadingContextWrapper hasLoadingContext={hasLoadingContext}>
        <AsyncProvider overlayLoader={<Loader />}>
          <Wrapped {...(props as P)} />
        </AsyncProvider>
      </LoadingContextWrapper>
    );

    WithInit.displayName = Wrapped.displayName;

    return (props) => <WithInit {...props} />;
  };

LoadingContextWrapper.defaultProps = {
  hasLoadingContext: false,
};

export default createWithAsync;
