import React, { useContext } from 'react';
import Notifications from '@sp/ui/layout/Notifications';

import NotificationContext from 'context/NotificationContext';
import GlobalNotificationContext from 'context/GlobalNotificationContext';

const NotificationsContainer = () => {
  const { notifications, dismiss } = useContext(NotificationContext);
  const { notifications: globalNotifications, dismiss: globalDismiss } =
    useContext(GlobalNotificationContext);
  return (
    <>
      <Notifications notifications={notifications} dismiss={dismiss} />
      <Notifications notifications={globalNotifications} dismiss={globalDismiss} />
    </>
  );
};

export default <NotificationsContainer />;
