import Desktop from './index.desktop';

const { default: Conditional } = require('decorators/conditional');
const { default: Mobile } = require('@sp/ui/v2/pages/BasePage/Actions/index.mobile');
const { default: Tablet } = require('@sp/ui/v2/pages/BasePage/Actions/index.tablet');

export default Conditional({
  mobile: Mobile,
  tablet: Tablet,
  desktop: Desktop,
});
