import { create } from '../helpers/reducers';
import {
  SET_DEPOSITS_AMOUNT,
  SET_DEPOSITS_ANSWERS,
  SET_DEPOSITS_TARGET,
  SET_DEPOSITS_CONTACT_INFO,
  SET_DEPOSITS_IS_PLACEMENT_VALID,
  CLEAR_DEPOSITS,
} from 'constants/actionTypes';

const initialState = {
  receipt: undefined as any,
  receipts: undefined as any,
  status: undefined as any,
  amount: undefined as any,
  questions: undefined,
  answers: undefined as any,
  savings: undefined as any,
  paymentSources: undefined as any,
  terms: undefined as any,
  paymentUrl: undefined as any,
  target: undefined as any,
  contactInfo: undefined as any,
  placement: undefined as any,
  isPlacementValid: undefined as any,
  isRatepensionSelected: undefined as any,
  isAldersforsikringSelected: undefined as any,
};

const createDefaults = () => ({
  ...initialState,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [CLEAR_DEPOSITS]: (state) => ({
      ...initialState,
      savings: state.savings,
    }),
    [SET_DEPOSITS_AMOUNT]: (state, payload) => ({
      ...state,
      amount: payload,
    }),
    [SET_DEPOSITS_IS_PLACEMENT_VALID]: (state, payload) => ({
      ...state,
      isPlacementValid: payload,
    }),
    RESPONSE_DEPOSITS_PLACEMENT_TEXTS: (state, payload) => ({
      ...state,
      placement: {
        ...state.placement,
        texts: payload.response,
      },
    }),
    RESPONSE_DEPOSITS_PLACEMENT: (state, payload) => ({
      ...state,
      placement: {
        ...state.placement,
        ...payload.response,
      },
      isPlacementValid: true,
      isRatepensionSelected: payload.request.isRatepensionSelected,
      isAldersforsikringSelected: payload.request.isAldersforsikringSelected,
    }),
    [SET_DEPOSITS_ANSWERS]: (state, payload) => ({
      ...state,
      answers: payload,
    }),
    [SET_DEPOSITS_TARGET]: (state, payload) => ({
      ...state,
      target: payload,
    }),
    [SET_DEPOSITS_CONTACT_INFO]: (state, payload) => ({
      ...state,
      contactInfo: payload,
    }),
    RESPONSE_DEPOSITS_SAVINGS: (state, payload) => ({
      ...state,
      savings: payload?.response,
    }),
    RESPONSE_DEPOSITS_PAYMENT_RECEIPT: (state, payload) => ({
      ...state,
      receipt: payload.response,
    }),
    RESPONSE_DEPOSITS_PAYMENT_RECEIPTS: (state, payload) => ({
      ...state,
      receipts: payload.response?.receipts,
    }),
    RESPONSE_DEPOSITS_PAYMENT_STATUS: (state, payload) => ({
      ...state,
      status: payload.response?.deposits,
    }),
    RESPONSE_DEPOSITS_QUESTIONS: (state, payload) => ({
      ...state,
      questions: payload.response?.questions,
    }),
    RESPONSE_DEPOSITS_PAYMENT_SOURCES: (state, payload) => ({
      ...state,
      paymentSources: payload.response,
    }),
    RESPONSE_DEPOSITS_TERMS: (state, payload) => ({
      ...state,
      terms: payload.response,
    }),
    RESPONSE_DEPOSITS_NEXI: (state, payload) => ({
      ...state,
      nexi: payload.response,
    }),
  },
  createDefaults
);

export type { State };
