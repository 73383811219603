import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import withTranslation from 'decorators/i18n';
import withSuspense from 'decorators/withSuspense';
import log from 'services/log';
import { compose } from 'redux';

import { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import Button from '@sp/ui/base/buttons/Button';
import { Result } from '@sp/ui/pages/ResultPage';

const Wrapper = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

class ErrorHandler extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { i18n } = this.props;
    this.setState({
      hasError: true,
    });
    log.error(i18n.t('Der er sket en fejl'), error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, i18n, theme } = this.props;

    if (hasError) {
      return (
        <SafeAreaWrapper>
          <Wrapper>
            <Result
              heading={i18n.t('Der er sket en fejl')}
              iconName="warning"
              iconColor={theme.COLORS.PRIMARY}
            />
            <Button
              text={i18n.t('Prøv igen')}
              onPress={() => {
                this.setState({
                  hasError: false,
                });
              }}
            />
          </Wrapper>
        </SafeAreaWrapper>
      );
    }

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(withTheme, withSuspense, withTranslation())(ErrorHandler);
