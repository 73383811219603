import React from 'react';
import { ViewProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import Icon from '@sp/ui/v2/base/Icon';

const { default: Row } = require('@sp/ui/v2/base/rows');
const ICON_DIMENSIONS = 14;

interface AdjustCalculationRowProps extends ViewProps {
  onPress: () => void;
  textRight?: React.ReactNode;
  testID?: string;
  textLeft: string;
  componentLeft: React.ReactNode;
  hasRowBorder?: boolean;
}

const AdjustCalculationRow: React.FC<AdjustCalculationRowProps> = ({
  onPress,
  textRight,
  textLeft,
  componentLeft,
  testID,
  hasRowBorder,
}: AdjustCalculationRowProps) => {
  const { COLORS } = useTheme();

  return (
    <Row
      testID={testID}
      hasBorderRadius={false}
      componentRight={
        <Icon
          name="arrowRightSmall"
          fill={COLORS.V2_PRIMARY_BACKGROUND}
          height={ICON_DIMENSIONS}
          width={ICON_DIMENSIONS}
        />
      }
      hasRowBorder={hasRowBorder}
      componentLeft={componentLeft}
      textLeft={textLeft}
      textRight={textRight}
      onPress={onPress}
    />
  );
};

AdjustCalculationRow.defaultProps = {
  testID: undefined,
  textRight: undefined,
  hasRowBorder: true,
};

export default AdjustCalculationRow;
