import React from 'react';
import PropTypes from 'prop-types';
import { H3 } from '@sp/ui/v2/typography';
import Margin from '@sp/ui/base/Margin';
import Markdown from 'containers/base/v2/Markdown';

const Content = ({ title, body, disableMarginBottom }) => (
  <Margin marginBottom={!disableMarginBottom && 'md'}>
    <H3>{title}</H3>
    {body && (
      <Margin marginTop="md">
        <Markdown>{body}</Markdown>
      </Margin>
    )}
  </Margin>
);

Content.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  disableMarginBottom: PropTypes.bool,
};

Content.defaultProps = {
  title: undefined,
  body: undefined,
  disableMarginBottom: false,
};

export default Content;
