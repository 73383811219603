import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTheme } from 'styled-components/native';
import withTranslation from 'decorators/i18n';
import { formatMoney } from 'helpers/string';
import { TwoLineRow } from '@sp/ui/base/rows';
import { H5 } from '@sp/ui/typography';
import { getPoliceTimestamp } from '../../helpers/getPoliceTimestamp';
import { getSavingsAccessibility } from '../../helpers/getSavingsAccessibility';

const ExternalPolicy = ({ item, i18n, savingsData, theme }) => {
  const policyBalance = item.balance
    ? `${formatMoney(item.balance, true)}`
    : i18n.t('savings|Kunne ikke hentes');
  const policyTimestamp =
    item.policyId &&
    item.balance &&
    i18n.t('savings|pr. {{ timestamp }}', {
      timestamp: getPoliceTimestamp(item.policyId, savingsData),
    });

  const accessibility = getSavingsAccessibility({
    components: ['ExternalPolicy'],
    props: {
      policyName: item.title,
      policyId: item.policyId,
    },
  });

  return (
    <TwoLineRow
      arrow={item.balance && { fill: 'transparent' }}
      {...accessibility.ExternalPolicy}
      textLeft={
        item.title && (
          <H5 numberOfLines={1} fontWeight="bold">
            {item.title}
          </H5>
        )
      }
      textRight={policyBalance}
      subtitleLeft={
        item.policyId &&
        item.balance &&
        i18n.t('savings|Police: {{ policyId }}', { policyId: item.policyId })
      }
      subtitleRight={policyTimestamp}
      iconRight={
        item.balance
          ? null
          : {
              name: 'rowErrorFilled',
              fill: theme.COLORS.PRIMARY_ALERT,
            }
      }
    />
  );
};

ExternalPolicy.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  item: PropTypes.object,
  savingsData: PropTypes.object,
};

ExternalPolicy.defaultProps = {
  item: undefined,
  savingsData: undefined,
};

export default compose(withTheme, withTranslation())(ExternalPolicy);
