import React from 'react';
import { useAppSelector } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import { selectIsChurchContributor } from 'selectors/dashboardForms';

const { Form, Group } = require('react-formed');
const { Body2 } = require('@sp/ui/v2/typography');
const { default: Tracking } = require('services/tracking');
const { default: Margin } = require('@sp/ui/base/Margin');
import RadioButtonForm from 'containers/form/RadioButtonForm';

const ChurchTax: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const isChurchContributor = useAppSelector(selectIsChurchContributor);

  const radioProps = [
    {
      label: i18n.t('yes'),
      isSelected: isChurchContributor,
      value: true,
      tracking: 'ClickChurchNo',
    },
    {
      label: i18n.t('no'),
      isSelected: !isChurchContributor,
      value: false,
      tracking: 'ClickChurchYes',
    },
  ];

  return (
    <Margin vertical="md">
      <Body2 secondaryColor>{i18n.t('dashboard|churchTaxDescription')}</Body2>
      <Margin marginTop="md">
        <Form name="adjustCalculation" resetOnMount>
          <Group name="tax">
            <RadioButtonForm
              name="isChurchContributor"
              radioProps={radioProps}
              onChange={(i: number) =>
                Tracking.trackEvent({
                  area: 'TAXATION',
                  action: radioProps[i].tracking,
                })
              }
            />
          </Group>
        </Form>
      </Margin>
    </Margin>
  );
};

export default ChurchTax;
