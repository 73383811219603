import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import withScreenContainer from 'decorators/withScreenContainer';
import * as routeNames from 'constants/routeNames';
import { fetchSavingsOverview } from 'actions/savings';
import {
  selectAllSavingsCosts,
  selectAllSavingsData,
  selectIsSeniorAndISP,
} from 'selectors/savings';
import { formatYear, formatDate, formatMoney } from 'helpers/string';
import Browser from '@sp/ui/layout/Browser';
import CostBrowser from './components/browsers/CostsBrowser';
import { VIEW_SAVINGS_COSTS } from 'areas/savings/trackingIds';

const getDateInterval = (annualCosts) => {
  const { periodStart, periodSlut: periodEnd } = annualCosts;

  const year = formatYear(periodStart);
  const formatPeriodStart = formatDate(periodStart);
  const formatPeriodEnd = formatDate(periodEnd);
  const firstDateOfYear = `01.01.${year}`;
  const lastDateOfYear = `31.12.${year}`;
  const areCostsRepresentingWholeYear =
    firstDateOfYear === formatPeriodStart && lastDateOfYear === formatPeriodEnd;

  return areCostsRepresentingWholeYear ? year : `${formatPeriodStart} - ${formatPeriodEnd}`;
};

const Costs = ({ i18n, savingsCosts, isSeniorAndISP }) => {
  const [index, setIndex] = useState(0);
  const { annualCosts } = savingsCosts ?? {};

  return (
    <ScrollPage title={i18n.t('savings|Omkostninger')} disableTopPadding fullWidth>
      {annualCosts?.length > 0 && (
        <Browser
          title={i18n.t('savings|Omkostninger')}
          minBrowsing={
            annualCosts[index].periodStart === annualCosts[annualCosts.length - 1].periodStart
          }
          maxBrowsing={annualCosts[index].periodStart === annualCosts[0].periodStart}
          hasBrowsingArrows={annualCosts?.length > 1}
          browserTitle={getDateInterval(annualCosts[index])}
          heading={i18n.t('savings|{{adminCosts}}kr.', {
            adminCosts: formatMoney({ amount: annualCosts[index].summary.adminCosts }, true),
          })}
          subtitle={i18n.t('savings|I administrationsomkostninger')}
          content={<CostBrowser data={annualCosts[index]} isSeniorAndISP={isSeniorAndISP} />}
          goBack={() => setIndex(index + 1)}
          goForward={() => setIndex(index - 1)}
        />
      )}
    </ScrollPage>
  );
};

Costs.propTypes = {
  i18n: PropTypes.object.isRequired,
  savingsCosts: PropTypes.object,
  isSeniorAndISP: PropTypes.bool,
};

Costs.defaultProps = {
  savingsCosts: {},
  isSeniorAndISP: undefined,
};

const mapStateToProps = (state, props) => ({
  savingsData: selectAllSavingsData(state),
  savingsCosts: selectAllSavingsCosts(state, props.route.params.pID),
  isSeniorAndISP: selectIsSeniorAndISP(state, props.route.params.pID),
});

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { dispatch, savingsData, navigation, route } = getProps();
        const { pID } = route.params;

        if (!pID) navigation.replace(routeNames.SAVINGS_OVERVIEW);

        if (!savingsData) {
          await dispatch(fetchSavingsOverview());
        }
      },
      {
        loader: true,
      }
    ),
  trackAs: VIEW_SAVINGS_COSTS,
})(Costs);
