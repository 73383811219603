const selectBookingState = (state) => state.areas.booking;

export const selectAllAppointmentTypes = (state) =>
  state.areas.booking.options?.appointmentTypes || [];

export const selectSelectedAppointment = (state) => selectBookingState(state).selectedAppointment;

export const selectSelectedOrganizer = (state) => selectBookingState(state).selectedOrganizer;

export const selectSelectedLocation = (state) => selectBookingState(state).selectedLocation;

export const selectSelectedTimeSlot = (state) => selectBookingState(state).selectedTimeSlot;

export const selectSelectedAppointmentType = (state) =>
  selectBookingState(state).selectedAppointmentType;

export const selectBookedAppointments = (state) => selectBookingState(state).bookedAppointments;

export const selectSelectedTopicInfo = (state) =>
  selectBookingState(state).selectedBookingTopicInfo;

export const selectContactInfo = (state) => selectBookingState(state).contactInfo;

export const selectAvailableOrganizers = (state) =>
  selectBookingState(state)?.organizerVariations || [];

export const selectPersonalAdvisor = (state) => selectBookingState(state)?.options?.personalAdvisor;

export const selectVIPAdvisors = (state) => selectBookingState(state)?.options?.vipAdvisors;

export const selectCanProceedToBooking = (state, availableLocations) =>
  selectSelectedAppointmentType(state) !== 'Personal' ||
  !!selectSelectedLocation(state) ||
  availableLocations?.length === 1;

export const selectBookingConflict = (state) => selectBookingState(state).bookingConflict;

export const selectIsVIP = (state) => selectBookingState(state)?.options?.isVip;
