import * as routeNames from 'constants/routeNames';
import { getConsents } from 'actions/consents';
import { getAPIStatus } from 'actions/api';
import { getAppChangelog } from 'actions/changelog';
import { selectGDPRConsent } from 'selectors/consents';
import { selectBlockMessage as selectBlockMessageDiscovery } from 'selectors/discovery';
import { selectBlockMessage as selectBlockMessageApi } from 'selectors/apiStatus';
import { hasMissingConsents } from 'services/consents';
import features from 'features';
import doCustomerFlow from './doCustomerFlow';

const doConsentsAndApiStatusFlow = async (store) => {
  await store.dispatch([getConsents(), getAPIStatus()]);

  if (features.isEnabled('changelog')) {
    try {
      await store.dispatch(getAppChangelog());
    } catch {
      // NOTE: swallowing API errors for now as this is not a critical endpoint
    }
  }

  const state = store.getState();
  const blockMessage = selectBlockMessageDiscovery(state) || selectBlockMessageApi(state);

  if (blockMessage) {
    return { name: routeNames.CUSTOMER_BLOCK };
  }

  const { items } = selectGDPRConsent(state);
  const missingConsents = hasMissingConsents(items);

  if (missingConsents) {
    return { name: routeNames.CONSENTS };
  }

  return doCustomerFlow(store);
};

export default doConsentsAndApiStatusFlow;
