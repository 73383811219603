import Body from './Body';
import Body2 from './Body2';
import BodyInput from './BodyInput';
import H3 from './H3';
import H2 from './H2';
import H4 from './H4';
import H5 from './H5';
import Subtitle from './Subtitle';

export { H2, H3, H4, H5, Body, Body2, BodyInput, Subtitle };
