import { compose } from 'redux';
import { connect } from 'react-redux';
import withTranslation from 'decorators/i18n';
import withInit from './init';
import withLogin from './login';
import trackAsHoc from './trackAs';

export default ({
  i18n,
  i18ns,
  needsLogin = true,
  overlayScreenProps, // Used as props for the overlayer screens (GenericError)
  init,
  mapStateToProps,
  trackAs,
  hasLocalNotifications = true,
  usesParentNotifications = false,
} = {}) => {
  const decorators = [];

  if (i18n !== false) {
    decorators.push(withTranslation(i18ns));
  }

  if (needsLogin) {
    decorators.push(withLogin);
  }

  decorators.push(connect(mapStateToProps));

  if (trackAs) {
    decorators.push(trackAsHoc(trackAs));
  }
  if (init) {
    decorators.push(
      withInit(init, overlayScreenProps, hasLocalNotifications, usesParentNotifications, false)
    );
  }
  return compose(...decorators);
};
