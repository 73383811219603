import React, { Dispatch, SetStateAction } from 'react';
import { shallowEqual } from 'react-redux';
import * as routeNames from 'constants/routeNames';
import { useAppSelector } from 'hooks/redux';
import { useUpdateHelpers } from './hooks';
import { selectDisposableIncomeOptions } from 'selectors/dashboard';
import { selectFormAdjustIncome } from 'selectors/dashboardForms';
import type { RootState } from 'store';

const { Form } = require('react-formed');
const { getSalaryRows } = require('areas/dashboard/calculations/components/IncomeInput');

const RADIO_BUTTON_DIMENSIONS = 20;

type Props = {
  setIsSpecifyIncomeVisible: Dispatch<SetStateAction<boolean>>;
};

const selectAdjustDisposableIncome = (state: RootState) => ({
  disposableIncomeOptions: selectDisposableIncomeOptions(state),
  selectedIncomeRowIndex: selectFormAdjustIncome(state)?.selectedIndex,
});

const IncomeOverview: React.FC<Props> = ({ setIsSpecifyIncomeVisible }) => {
  const { updateSelectedIncomeRow } = useUpdateHelpers();
  const { disposableIncomeOptions, selectedIncomeRowIndex } = useAppSelector(
    (state) => selectAdjustDisposableIncome(state),
    shallowEqual
  );

  return (
    <Form name="adjustIncome">
      {getSalaryRows({
        disposableIncomeOptions,
        initSelectedIndex: selectedIncomeRowIndex,
        radioButtonDimensions: RADIO_BUTTON_DIMENSIONS,
        goBackRouteName: routeNames.ADJUST_DISPOSABLE_INCOME,
        setSelectedRadioButton: (index: number) =>
          updateSelectedIncomeRow(index, () => setIsSpecifyIncomeVisible(false)),
      })}
    </Form>
  );
};

export default IncomeOverview;
