import { getTransferQuestions } from 'areas/transfer/helpers/getTransferQuestions';
import {
  TRANSFER_SAVINGS_INSURANCE,
  TRANSFER_SCHEME_PAYMENT,
} from 'areas/transfer/constants/selectionReferences';
import type { QuestionStackParams, PrefilledValuesTypes } from 'areas/transfer/types';
import type { TransferState } from 'reducers/transfer';

interface StoreSlice {
  areas: { transfer: TransferState };
}

export const selectTransferQuestions = (state: StoreSlice) => {
  const { transferableSources, hasSelectedWithSavingsInsurance } =
    state.areas.transfer?.transferForm ?? {};

  const questions = transferableSources
    ?.filter(({ isSelected, areQuestionsSkippable }) => isSelected && !areQuestionsSkippable)
    ?.reduce((arr, transferableSource) => {
      const { policyId, mustSpecifyIssuer, mustSpecifyJobChange, hasBasisCoverage, selections } =
        transferableSource ?? {};

      const transferQuestions = getTransferQuestions({
        mustSpecifyIssuer,
        mustSpecifyJobChange,
        hasBasisCoverage,
      });

      transferQuestions.forEach((questionReference) => {
        const skippableConditions =
          questionReference === TRANSFER_SCHEME_PAYMENT
            ? {
                transferJobChange: mustSpecifyJobChange === 'IfEmployerPolicy',
              }
            : undefined;

        const { isCompleted, isSelected } =
          selections?.find((selection) => selection.questionReference === questionReference) ?? {};

        arr.push({
          questionReference,
          policyId,
          skippableConditions,
          prefilledValues: { isCompleted, isSelected },
          name: state.areas.transfer?.data?.policies?.find((policy) => policy.policyId === policyId)
            ?.name,
        });
      });

      return arr;
    }, [] as QuestionStackParams);

  const savingsInsuranceQuestion = transferableSources
    ?.filter(({ isSelected }) => isSelected)
    ?.some(({ savingsInsurance }) => savingsInsurance === 'Optional')
    ? [
        {
          questionReference: TRANSFER_SAVINGS_INSURANCE,
          prefilledValues: {
            isCompleted: hasSelectedWithSavingsInsurance !== undefined,
            isSelected: hasSelectedWithSavingsInsurance,
          },
        },
      ]
    : [];

  return questions?.concat(
    savingsInsuranceQuestion as {
      questionReference: typeof TRANSFER_SAVINGS_INSURANCE;
      prefilledValues: PrefilledValuesTypes;
    }[]
  );
};
