import styled from 'styled-components/native';
import { H2, H5 } from '@sp/ui/v2/typography';
import ConditionalImage from 'containers/theme/ConditionalImage';
import { isIphone5 } from '@sp/ui/helpers/device';
import Conditional from 'decorators/conditional';

export const BackgroundImage = styled(ConditionalImage)`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const Wrapper = styled.View`
  flex: 1;
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor}` : '')};
`;

export const ContentWrapper = styled.View`
  margin: ${({ theme }) =>
    isIphone5() ? `${theme.SPACINGS.md}px` : `${theme.SPACINGS.xxl}px ${theme.SPACINGS.xl}px`};
  flex: ${({ textOnly }) => (textOnly ? 3 : 4)};
  align-items: center;
  justify-content: ${({ textOnly }) => (textOnly ? 'flex-start' : 'space-around')};
`;

export const Heading = styled(H2)`
  color: ${({ theme }) => theme.COLORS.V2_PRIMARY_TEXT};
  max-width: 640px;
`;

export const Description = styled(H5)`
  color: ${({ theme }) => theme.COLORS.V2_PRIMARY_TEXT};
  max-width: 640px;
`;

export const Image = Conditional({
  tablet: styled.Image`
    width: 50%;
  `,
  desktop: styled.Image`
    width: 30%;
  `,
  landscape: styled.Image`
    width: 30%;
  `,
  mobile: styled.Image`
    width: 80%;
  `,
});
