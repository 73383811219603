import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Policy from './Policy';
import {
  SELECTION_TYPE_MULTIPLE,
  SELECTION_TYPE_SINGLE,
  isCompanyGroupSelected,
  isCompanyGroupPartiallySelected,
} from './helpers';
import Header from './Header';

const CompanyGroupWrapper = styled.View`
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  background: ${({ theme, hasBackground }) =>
    hasBackground ? theme.COLORS.PRIMARY_VARIANT1 : 'transparent'};
  overflow: hidden;
`;

const Border = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.COLORS.V2_SECONDARY_BACKGROUND};
`;

const CompanyGroupSelector = ({
  companyGroup,
  state,
  onCompanyGroupPress,
  onPolicyPress,
  isOneCompanyGroup,
  hasHeader,
  isFoldable,
  policySelectionType,
  texts,
  theme,
}) => {
  const isSelected = isCompanyGroupSelected(
    state,
    companyGroup.companyGroup,
    companyGroup.policies
  );
  const isPartiallySelected = isCompanyGroupPartiallySelected(
    state,
    companyGroup.companyGroup,
    companyGroup.policies
  );
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <CompanyGroupWrapper hasBackground={isSelected}>
      {hasHeader && (
        <Header
          companyGroup={companyGroup}
          state={state}
          isOneCompanyGroup={isOneCompanyGroup}
          onCompanyGroupPress={onCompanyGroupPress}
          isFoldable={isFoldable}
          isSelected={isSelected}
          isPartiallySelected={isPartiallySelected}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          texts={texts}
          theme={theme}
        />
      )}
      {isExpanded && (
        <>
          <Border />
          {companyGroup.policies?.map((policy) => (
            <Policy
              key={policy.policyId}
              policy={policy}
              state={state}
              companyGroup={companyGroup.companyGroup}
              onPolicyPress={onPolicyPress}
              selectionType={policySelectionType}
            />
          ))}
        </>
      )}
    </CompanyGroupWrapper>
  );
};

CompanyGroupSelector.propTypes = {
  companyGroup: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onCompanyGroupPress: PropTypes.func.isRequired,
  onPolicyPress: PropTypes.func.isRequired,
  isOneCompanyGroup: PropTypes.bool.isRequired,
  hasHeader: PropTypes.bool.isRequired,
  isFoldable: PropTypes.bool.isRequired,
  policySelectionType: PropTypes.oneOf([SELECTION_TYPE_SINGLE, SELECTION_TYPE_MULTIPLE]).isRequired,
  texts: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(CompanyGroupSelector);
