import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { connectPartnerLink } from 'actions/partner';
import { selectPartnerLinkGUID } from 'selectors/partner';
import { DEEPLINK_SESSION } from 'constants/deeplink';

const { default: sessionStorage } = require('services/stores/session');

export const useLinkPartner = () => {
  const dispatch = useAppDispatch();
  const guid = useAppSelector(selectPartnerLinkGUID);

  return useCallback(async () => {
    const deeplinkSessionStorage = (await sessionStorage.getItem(DEEPLINK_SESSION)) ?? {};
    const { guid: storagePartnerGUID } = deeplinkSessionStorage ?? {};
    const derivedPartnerGUID = guid ?? storagePartnerGUID;

    if (derivedPartnerGUID) {
      await dispatch(connectPartnerLink(derivedPartnerGUID));

      if (!storagePartnerGUID) {
        await sessionStorage.setItem(DEEPLINK_SESSION, {
          ...deeplinkSessionStorage,
          guid: derivedPartnerGUID,
        });
      }
    } else {
      const exception: { name: string; response?: { status: number } } = new Error();
      exception.response = { status: 404 };

      throw exception;
    }
  }, [guid, dispatch]);
};
