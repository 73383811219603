import { combineReducers } from 'redux';
import savings from './savings';
import mailbox from './mailbox';
import dashboard from './dashboard';
import profile from './profile';
import general from './general';
import feedback from './feedback';
import documents from './documents';
import booking from './booking';
import settings from './settings';
import investmentProfile from './investmentProfile';
import deposits from './deposits';
import transfer from './transfer';
import popupWebView from './popupWebView';
import healthBenefits from './healthBenefits';
import fetchTaxAndPensionsInfo from './fetchTaxAndPensionsInfo';
import partner from './partner';
import meetingPreparation from './meetingPreparation';

export default combineReducers({
  savings,
  mailbox,
  dashboard,
  profile,
  general,
  feedback,
  documents,
  booking,
  settings,
  investmentProfile,
  deposits,
  transfer,
  popupWebView,
  healthBenefits,
  fetchTaxAndPensionsInfo,
  partner,
  meetingPreparation,
});
