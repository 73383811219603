import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRoute, useNavigation } from '@react-navigation/native';
import { withTheme } from 'styled-components/native';
import { Trans } from 'react-i18next';
import * as routeNames from 'constants/routeNames';
import withComponentContainer from 'decorators/withComponentContainer';
import { fetchSavingsOverview } from 'actions/savings';
import { selectAllSavingsData } from 'selectors/savings';
import Margin from '@sp/ui/base/Margin';
import { Subtitle } from '@sp/ui/typography';
import { openLink } from 'helpers/linking';
import { getPolicyDetails } from '../../helpers/getPolicyDetails';

const SavingsReturnFooter = ({ savingsData, i18n, theme }) => {
  const route = useRoute();
  const navigation = useNavigation();
  const { pID } = route.params;

  useEffect(() => {
    if (!pID) navigation.replace(routeNames.SAVINGS_OVERVIEW);
  }, [navigation, pID]);

  const { type } = getPolicyDetails(savingsData, pID);
  const url = type === 'ISP' ? i18n.t('link|aboutRiskNumberISP') : i18n.t('link|aboutRiskNumberSP');

  return (
    <Margin vertical="md" horizontal="md">
      <Subtitle>
        <Trans i18nKey="savings|riskNumber">
          Risikotallet er et udtryk for, hvor meget din opsparing kan svinge på ét år. Jo større
          risikotal, jo større udsving kan der forekomme. Læs mere{' '}
          <Subtitle onPress={() => openLink(url, '_blank')} color={theme.COLORS.PRIMARY_LINK}>
            her
          </Subtitle>
          .
        </Trans>
      </Subtitle>
    </Margin>
  );
};

SavingsReturnFooter.propTypes = {
  savingsData: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

SavingsReturnFooter.defaultProps = {
  savingsData: undefined,
};

const mapStateToProps = (state) => ({
  savingsData: selectAllSavingsData(state),
});

export default withComponentContainer({
  mapStateToProps,
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { savingsData, dispatch } = getProps();

        if (!savingsData) await dispatch(fetchSavingsOverview());
      },
      {
        loader: true,
      }
    ),
})(withTheme(SavingsReturnFooter));
