import PropTypes from 'prop-types';

const segmentProp = PropTypes.shape({
  color: PropTypes.string,
  value: PropTypes.number,
});

export default PropTypes.shape({
  onSelect: PropTypes.func,
  total: PropTypes.number,
  barWidth: PropTypes.number,
  selected: PropTypes.bool,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  testID: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
  segments: PropTypes.arrayOf(segmentProp),
});
