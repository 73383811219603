import { useNavigation } from '@react-navigation/native';
import { shallowEqual } from 'react-redux';
import { ADJUST_CALCULATION } from 'constants/routeNames';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useAsyncCallback from 'hooks/useAsyncCallback';
import { RECALCULATE_DASHBOARD } from 'areas/dashboard/trackingIds';
import type { RootState } from 'store';
import {
  selectShowGrossAmount,
  selectDisposableIncome,
  selectDisposableIncomeOptions,
} from 'selectors/dashboard';
import {
  selectFormAdjustIncome,
  selectFormGrossAmountHasChanges,
  selectFormGrossAmount,
} from 'selectors/dashboardForms';

const { default: Tracking } = require('services/tracking');
const { actions } = require('react-formed');
const { updateIncome, setShowGrossAmount } = require('actions/dashboard');
const { fetchDashboardData } = require('helpers/dashboard');
const { getShowGrossAmount } = require('areas/dashboard/AdjustCalculation/helpers');
const { buildIncomeInfo } = require('areas/dashboard/calculations/helpers/income');

const clearEnteredUserValues = [
  actions.setValue('adjustIncome', 'enteredIncome', ''),
  actions.setValue('adjustIncome', 'enteredGrossIncome', ''),
];

const selectAdjustDisposableIncome = (state: RootState) => ({
  disposableIncome: selectDisposableIncome(state),
  disposableIncomeOptions: selectDisposableIncomeOptions(state),
  formAdjustIncome: selectFormAdjustIncome(state),
  formGrossAmountHasChanges: selectFormGrossAmountHasChanges(state),
  formGrossAmount: selectFormGrossAmount(state),
  showGrossAmount: selectShowGrossAmount(state),
  selectedIncomeRowIndex: selectFormAdjustIncome(state)?.selectedIndex,
});

export const usePatchDisposableIncome = () => {
  const dispatchArray: any[] = [];
  const trackingData: any[] = [];

  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const {
    disposableIncome,
    disposableIncomeOptions,
    formAdjustIncome,
    formGrossAmountHasChanges,
    formGrossAmount,
    showGrossAmount,
    selectedIncomeRowIndex,
  } = useAppSelector((state) => selectAdjustDisposableIncome(state), shallowEqual);

  return useAsyncCallback(
    async (goBack = false) => {
      if (formGrossAmountHasChanges) {
        dispatchArray.push(
          setShowGrossAmount(getShowGrossAmount(formGrossAmount, showGrossAmount))
        );
        trackingData.push(formGrossAmount);
      } else {
        const incomeInfo = buildIncomeInfo({
          disposableIncome: {
            ...disposableIncome,
            disposableIncomeOptions,
          },
          formAdjustIncome,
          showGrossAmount: getShowGrossAmount(formGrossAmount, showGrossAmount),
        });

        dispatchArray.push(updateIncome(incomeInfo));
        trackingData.push({
          ...incomeInfo,
          ...incomeInfo?.userEnteredCurrentDisposableIncome,
        });
      }

      if (dispatchArray.length > 0) {
        await dispatch(dispatchArray);
      }

      if (trackingData.length > 0) {
        Tracking.trackEvent(RECALCULATE_DASHBOARD, Object.assign({}, ...trackingData));
      }

      if (selectedIncomeRowIndex > -1) {
        dispatch(clearEnteredUserValues);
      }

      await fetchDashboardData(dispatch);

      if (goBack) {
        navigation.navigate(ADJUST_CALCULATION);
      }
    },
    [dispatch, dispatchArray, trackingData]
  );
};
