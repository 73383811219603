import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import type { MenuPageProps } from '../index.desktop';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Body2, Subtitle } = require('@sp/ui/v2/typography');

const CIRCLE_DIMENSIONS = 35;
const LINE_CONNECTOR_HEIGHT = 32;
const BORDER_WIDTH = 3;

type StepsProps = Pick<MenuPageProps, 'progress'>;

const RowWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const ColumnWrapper = styled.View`
  flex-direction: column;
  justify-content: center;
`;

const StepCircle = styled.View<{ isActive: boolean }>`
  width: ${CIRCLE_DIMENSIONS}px;
  height: ${CIRCLE_DIMENSIONS}px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  ${({ isActive }) => {
    if (isActive) {
      return 'background-color: white';
    }

    return `border: ${BORDER_WIDTH}px solid white`;
  }}
`;

const LineConnectorWrapper = styled.View`
  width: ${CIRCLE_DIMENSIONS}px;
  align-items: center;
`;

const LineConnector = styled.View`
  height: ${LINE_CONNECTOR_HEIGHT}px;
  width: ${BORDER_WIDTH}px;
  background-color: white;
`;

const TouchableOpacity = styled.TouchableOpacity``;

const Steps: React.FC<StepsProps> = ({ progress }) => {
  const { COLORS } = useTheme();

  return (
    <ColumnWrapper>
      {progress?.map(({ title, isActive, isSelected, onPress }, i) => (
        <TouchableOpacity key={`step-progress-${i}`} disabled={!isActive} onPress={onPress}>
          <RowWrapper>
            <StepCircle isActive={isActive ?? false}>
              <Subtitle
                color={isActive ? COLORS.V2_PRIMARY_BACKGROUND : COLORS.V2_SECONDARY_BACKGROUND}
                fontWeight="semibold"
              >
                {i + 1}
              </Subtitle>
            </StepCircle>
            <Margin marginLeft="md">
              <Body2 color="white" fontWeight={isSelected ? 'semibold' : 'normal'}>
                {title}
              </Body2>
            </Margin>
          </RowWrapper>
          {i !== progress.length - 1 && (
            <LineConnectorWrapper>
              <LineConnector />
            </LineConnectorWrapper>
          )}
        </TouchableOpacity>
      ))}
    </ColumnWrapper>
  );
};

export default Steps;
