import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import Conditional from 'decorators/conditional';
import Icon from '@sp/ui/base/Icon';
import { TABLET_MARGIN_HORIZONTAL, MOBILE_MARGIN_HORIZONTAL } from '../index.shared';

const ICON_TEXT_SPACING = 5;

const Touchable = styled.TouchableOpacity``;

const Spacer = styled.View`
  width: ${ICON_TEXT_SPACING}px;
`;

const Wrapper = Conditional({
  mobile: styled.View`
    padding: ${MOBILE_MARGIN_HORIZONTAL / 2}px;
    flex-direction: row;
    opacity: ${({ inactive }) => (inactive ? 0.5 : 1)};
    align-items: center;
  `,
  default: styled.View`
    padding: ${TABLET_MARGIN_HORIZONTAL / 2}px;
    flex-direction: row;
    align-items: center;
  `,
});

const getColor = (inactive, type, colors) => {
  if (inactive) return colors.PRIMARY_INACTIVE;

  switch (type) {
    case 'destructive':
      return colors.PRIMARY_ALERT;
    case 'primary':
    case 'secondary':
    default:
      return colors.SECONDARY_BACKGROUND;
  }
};

export const HeaderButton = ({
  text,
  icon,
  type,
  inactive,
  theme,
  onPress,
  textFirst,
  testID,
  viewBox,
  accessibilityLabel,
}) => {
  const renderIcon = () =>
    icon && <Icon name={icon} fill={getColor(inactive, type, theme.COLORS)} viewBox={viewBox} />;

  const renderText = () =>
    text && <Body color={getColor(inactive, type, theme.COLORS)}>{text}</Body>;

  return (
    <Touchable
      onPress={onPress}
      disabled={inactive}
      testID={testID}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
    >
      <Wrapper inactive={inactive}>
        {textFirst ? renderText() : renderIcon()}
        {icon && text && <Spacer />}
        {textFirst ? renderIcon() : renderText()}
      </Wrapper>
    </Touchable>
  );
};

HeaderButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'destructive']),
  inactive: PropTypes.bool,
  textFirst: PropTypes.bool,
  theme: PropTypes.shape({
    COLORS: PropTypes.shape({
      SECONDARY_BACKGROUND: PropTypes.string,
    }),
  }).isRequired,
  onPress: PropTypes.func.isRequired,
  testID: PropTypes.string,
  viewBox: PropTypes.string,
  accessibilityLabel: PropTypes.string,
};

HeaderButton.defaultProps = {
  text: undefined,
  icon: undefined,
  type: 'primary',
  inactive: false,
  textFirst: false,
  testID: undefined,
  viewBox: undefined,
  accessibilityLabel: undefined,
};

export default withTheme(HeaderButton);
