import React, { useContext, useEffect, useCallback, useState } from 'react';
import useI18n from 'hooks/useI18n';
import { shallowEqual } from 'react-redux';
import { isWeb } from 'helpers/platform';
import { useAppSelector } from 'hooks/redux';
import usePrerenderScreen from 'hooks/usePrerenderScreen';
import { selectDeeplink } from 'selectors/deeplink';
import {
  selectTaxFetchingStatus,
  selectPensionsInfoFetchingStatus,
  selectIsFlowTax,
} from 'selectors/fetchTaxAndPensionsInfo';
import {
  useInitFetchTaxOrPensionsInfo,
  useHandlePollingResponse,
  useHandlePollingResponseCallback,
  useOnFetchComplete,
  useOnFetchFail,
  useOnFetchCancel,
} from './hooks';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import Fetching from './Fetching';
import { getIsDevOrTest } from 'helpers/environment';
import fetchTaxAndPensionsInfoController from './fetchTaxAndPensionsInfoController';
import type { RootState } from 'store';
import { pensionInfoHtml } from './pensionInfoHtml';
const { openInAppBrowser, closeInAppBrowser } = require('./inAppBrowser');

const { default: FlexPage } = require('@sp/ui/pages/FlexPage');
const { default: WebView } = require('@sp/ui/base/WebView');
const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { ModalProvider } = require('@sp/ui/v2/context/ModalContext');

const selectFetchTaxOrPensionsInfo = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  taxFetchingStatus: selectTaxFetchingStatus(state),
  pensionsInfoFetchingStatus: selectPensionsInfoFetchingStatus(state),
  isFlowTax: selectIsFlowTax(state),
});

const FetchTaxOrPensionsInfo: React.FC = () => {
  const [failTypeState, setFailTypeState] = useState('');
  const { onClose, fetchingState } = useContext(FetchTaxAndPensionsInfoContext);

  const i18n = useI18n();

  const { deeplink, isFlowTax, taxFetchingStatus, pensionsInfoFetchingStatus } = useAppSelector(
    selectFetchTaxOrPensionsInfo,
    shallowEqual
  );
  const { updateIsFetching, getIsFetching, getBrowserService, updateAndOpenBrowserService } =
    fetchTaxAndPensionsInfoController;
  const { isDeeplink } = deeplink ?? {};
  const { cancel, url, fetchingController, ssoTicket } = fetchingState ?? {};
  const isFetching = getIsFetching();
  const browserService = getBrowserService();
  const currentFetchingStatus = taxFetchingStatus || pensionsInfoFetchingStatus;
  const title = isFlowTax ? 'skatImportTitle' : 'pensionCheckTitle';
  const isDevOrTest = getIsDevOrTest();

  const onFetchComplete = useOnFetchComplete();
  const onFetchFail = useOnFetchFail();
  const onFetchCancel = useOnFetchCancel();
  const handlePollingResponseCallback = useHandlePollingResponseCallback();

  const cancelPolling = useCallback(async () => {
    if (cancel) {
      await cancel();
    }
  }, [cancel]);

  const stopPollingAndCompleteFetchingProcess = useCallback(
    (failType?: string) => {
      if (isFetching) {
        cancelPolling();
        updateIsFetching(false);
        if (isWeb && browserService) {
          browserService.close();
        }

        if (isFlowTax && !isWeb) {
          closeInAppBrowser();
        }

        if (failType === 'inAppBrowserClosed') {
          setFailTypeState('inAppBrowserClosed');
          onFetchCancel(failType);
        } else if (failType) {
          onFetchFail(failType);
        } else {
          onFetchComplete();
        }
      }
    },
    [
      isFetching,
      taxFetchingStatus,
      fetchingController,
      cancelPolling,
      updateIsFetching,
      browserService,
      isFlowTax,
      onFetchCancel,
      onFetchFail,
      onFetchComplete,
    ]
  );

  useInitFetchTaxOrPensionsInfo();
  useHandlePollingResponse({
    currentFetchingStatus,
    fetchingController,
    callback: (fetchingStatus, failType) => {
      handlePollingResponseCallback({
        fetchingStatus,
        failType,
        stopPollingAndCompleteFetchingProcess,
      });
    },
  });

  // NOTE: Avoid iframe CORS errors
  useEffect(() => {
    if (isWeb && url && isFetching) {
      updateAndOpenBrowserService(url, isFlowTax, ssoTicket);
    }
  }, [url, isFetching, updateAndOpenBrowserService, isFlowTax, ssoTicket]);

  useEffect(() => {
    if (
      !isWeb &&
      url &&
      isFlowTax &&
      taxFetchingStatus !== 'DataReceived' &&
      failTypeState !== 'inAppBrowserClosed'
    ) {
      openInAppBrowser(url, stopPollingAndCompleteFetchingProcess);
    }
  }, [url, isFlowTax]);

  useEffect(
    () => () => {
      cancelPolling();
    },
    [cancelPolling]
  );

  const prerender = usePrerenderScreen();

  if (prerender) {
    return prerender;
  }

  return (
    <ModalProvider hasOnCloseButton={!isDeeplink} onClose={onClose} isModal secondaryColor>
      {(isDevOrTest || isWeb) && <Fetching />}
      {!isDevOrTest && !isWeb && url && !isFlowTax ? (
        <FlexPage hasBackButton={false} title={i18n.t(`onboarding|${title}`)} fullWidth>
          <WebView
            height="100%"
            width="100%"
            startInLoadingState
            source={isFlowTax ? { uri: url } : { html: pensionInfoHtml(url, ssoTicket) }}
            onContentProcessDidTerminate={() => {
              if (isFlowTax) {
                stopPollingAndCompleteFetchingProcess('LoginFail');
              }
            }}
          />
        </FlexPage>
      ) : null}
    </ModalProvider>
  );
};

export default withScreenContainer()(FetchTaxOrPensionsInfo);
