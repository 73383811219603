import { getFirstValue } from 'helpers/object';
import {
  currencyAmountValidator,
  currencyAmountWithZeroValidator,
} from '@sp/ui/helpers/validators';

export const patchProfileInfo = (currentProfileInfo, profileInfoForm) => {
  const patchedProfileInfo = profileInfoForm;

  if (patchedProfileInfo.userEnteredHasWealth !== undefined) {
    patchedProfileInfo.userEnteredIsEntitledForAeldrecheck = !profileInfoForm.userEnteredHasWealth;
  }

  if (patchedProfileInfo.userEnteredWealthAtPensionAge === '') {
    patchedProfileInfo.userEnteredWealthAtPensionAge = 0;
  }

  if (patchedProfileInfo.userEnteredNumberOfYearsLivingInDenmarkAtPensionAge === '') {
    patchedProfileInfo.userEnteredNumberOfYearsLivingInDenmarkAtPensionAge = 0;
  }

  return {
    ...currentProfileInfo,
    ...patchedProfileInfo,
  };
};

export const getSkatCommuneCode = (formProfile, profile, municipality) =>
  getFirstValue(
    formProfile?.userEnteredSkattekommuneKommunekode,
    profile.userEnteredSkattekommuneKommunekode,
    municipality && municipality.kommunekode
  );

export const getShowGrossAmount = (formGrossAmount, showGrossAmount) =>
  getFirstValue(formGrossAmount.showGrossAmount, showGrossAmount);

export const getChurchTaxValue = (formProfile, profile, churchTax) =>
  getFirstValue(
    formProfile?.userEnteredPaysKirkeskat,
    profile.userEnteredPaysKirkeskat,
    !!churchTax
  );

// TODO: move logic to separate functions
export const isRecalculateButtonInactive = ({
  hasChanges,
  showGrossAmount,
  formAdjustIncome,
  formProfileInfo,
}) => {
  if (!hasChanges) {
    return true;
  }

  if (!formAdjustIncome.enteredIncome && formAdjustIncome.selectedIndex === -1) {
    return true;
  }

  if (formAdjustIncome.enteredIncome && !currencyAmountValidator(formAdjustIncome.enteredIncome)) {
    return true;
  }

  // If gross amount should be shown, gross income is required.
  if (
    showGrossAmount &&
    !formAdjustIncome?.enteredGrossIncome &&
    formAdjustIncome.selectedIndex === -1
  ) {
    return true;
  }

  if (
    formAdjustIncome.enteredIncome &&
    formAdjustIncome?.enteredGrossIncome &&
    parseInt(formAdjustIncome?.enteredGrossIncome, 10) <
      parseInt(formAdjustIncome.enteredIncome, 10)
  ) {
    return true;
  }

  if (
    formProfileInfo.userEnteredWealthAtPensionAge &&
    !currencyAmountWithZeroValidator(formProfileInfo.userEnteredWealthAtPensionAge)
  ) {
    return true;
  }

  return !!(
    formProfileInfo.userEnteredNumberOfYearsLivingInDenmarkAtPensionAge &&
    !currencyAmountWithZeroValidator(
      formProfileInfo.userEnteredNumberOfYearsLivingInDenmarkAtPensionAge
    )
  );
};
