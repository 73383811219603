import React from 'react';
import PropTypes from 'prop-types';
import { Body, H2 } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import { renderStringOrComponent } from '@sp/ui/helpers/component';

const ModalContentWithList = ({ title, description }) => (
  <>
    {title && (
      <Margin marginBottom="lg">
        <H2 textAlign="center">{title}</H2>
      </Margin>
    )}
    {renderStringOrComponent(description, Body)}
  </>
);

ModalContentWithList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ModalContentWithList.defaultProps = {
  title: undefined,
  description: undefined,
};

export default ModalContentWithList;
