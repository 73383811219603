import PropTypes from 'prop-types';

export const ActionShape = PropTypes.shape({
  element: PropTypes.node,
  text: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'destructive-secondary']),
  mobilePosition: PropTypes.oneOf(['bottom']),
  inactive: PropTypes.bool,
  onPress: PropTypes.func,
  testID: PropTypes.string,
});

export const groupActions = (actions = []) => {
  const result = {
    bottom: [],
  };

  // At the moment it looks like the new design only supports bottom buttons,
  // so all other actions are removed, but mobilePosition switch is saved for now,
  // in case some new actions will show up again.
  actions.forEach((action) => {
    switch (action.mobilePosition) {
      default:
        result.bottom.push(action);
        break;
    }
  });
  return result;
};

export const extractBottomActions = (actions = []) =>
  actions.reduce(
    (output, action) => {
      output.bottomActions.push(action);
      return output;
    },
    { bottomActions: [], otherActions: [] }
  );
