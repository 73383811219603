import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Body2, Subtitle } from '@sp/ui/v2/typography';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import Margin from '@sp/ui/base/Margin';

const ROW_BORDER_HEIGHT = 1;
const ONE_LINE_HEIGHT = 44;
const DEFAULT_OPACITY = 0.2;
export const TWO_LINES_HEIGHT = 60;

const Wrapper = styled.View`
  min-height: ${({ twoLines }) => (twoLines ? TWO_LINES_HEIGHT : ONE_LINE_HEIGHT)}px;
  flex-direction: row;
  border-radius: ${({ theme, hasBorderRadius }) =>
    hasBorderRadius ? theme.DEFAULTS.BORDER_RADIUS : 0}px;
  padding: ${({ hasPadding, theme }) => (hasPadding ? `0px ${theme.SPACINGS.md}px` : '0px')};
  ${({ border, theme }) =>
    border
      ? `
  borderWidth: ${StyleSheet.hairlineWidth}px;
  borderColor: ${theme.COLORS.PRIMARY_BORDER};
  `
      : null}
  ${({ background }) => background && `background: ${background};`}
  align-items: center;
  overflow: hidden;
`;

const FlexWrapper = styled.View`
  flex: 1;
`;

const FlexWrapperComponentLeft = styled.View`
  ${({ hasTextOrSubtitles }) => (hasTextOrSubtitles ? '' : 'flex: 1')};
`;

const RowWrapper = styled.View`
  flex-direction: row;
`;

const LeftWrapper = styled.View`
  justify-content: center;
  margin-right: auto;
  flex: 1;
`;

const RightWrapper = styled.View`
  justify-content: center;
`;

export const RowBorder = styled.View`
  width: 100%;
  height: ${ROW_BORDER_HEIGHT}px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BACKGROUND};
`;

const Row = ({
  activeOpacity,
  textLeft,
  textRight,
  subtitleLeft,
  subtitleRight,
  componentRight,
  componentLeft,
  onPress,
  border,
  hasBorderRadius,
  accessibilityState,
  backgroundColor,
  accessibilityHide,
  hasRowBorder,
  hasPadding,
  testID,
}) => {
  // NOTE: If using just <TouchableOpacity disabled /> text is unselectable on web
  const TouchableOpacityOrView = typeof onPress === 'function' ? TouchableOpacity : View;
  const hasTextOrSubtitles = textLeft || textRight || subtitleLeft || subtitleRight;

  return (
    <TouchableOpacityOrView
      activeOpacity={activeOpacity}
      onPress={onPress}
      accessibilityState={accessibilityState}
      accessibilityElementsHidden={accessibilityHide}
    >
      <Wrapper
        border={border}
        hasPadding={hasPadding}
        hasBorderRadius={hasBorderRadius}
        twoLines={subtitleLeft || subtitleRight}
        importantForAccessibility={accessibilityHide ? 'no-hide-descendants' : undefined}
        background={backgroundColor}
        testID={testID}
      >
        {componentLeft && (
          <FlexWrapperComponentLeft hasTextOrSubtitles={hasTextOrSubtitles}>
            <Margin marginRight="m">{componentLeft}</Margin>
          </FlexWrapperComponentLeft>
        )}
        {hasTextOrSubtitles && (
          <FlexWrapper>
            {(textLeft || textRight) && (
              <RowWrapper>
                {textLeft && (
                  <LeftWrapper>
                    <Margin marginRight="md">
                      {renderStringOrComponent(textLeft, Body2, {
                        fontWeight: 'semibold',
                        numberOfLines: 1,
                        secondaryColor: true,
                      })}
                    </Margin>
                  </LeftWrapper>
                )}
                <RightWrapper>
                  {renderStringOrComponent(textRight, Body2, {
                    fontWeight: 'semibold',
                    textAlign: 'right',
                    secondaryColor: true,
                  })}
                </RightWrapper>
              </RowWrapper>
            )}
            {(subtitleLeft || subtitleRight) && (
              <RowWrapper>
                <LeftWrapper>
                  <Margin marginTop="xs" marginRight="md">
                    {renderStringOrComponent(subtitleLeft, Subtitle, {
                      fontWeight: 'regular',
                      numberOfLines: 1,
                      secondaryColor: true,
                    })}
                  </Margin>
                </LeftWrapper>
                <RightWrapper>
                  <Margin marginTop="xs">
                    {renderStringOrComponent(subtitleRight, Subtitle, {
                      fontWeight: 'regular',
                      textAlign: 'right',
                      secondaryColor: true,
                    })}
                  </Margin>
                </RightWrapper>
              </RowWrapper>
            )}
          </FlexWrapper>
        )}
        {componentRight && <Margin marginLeft="sm">{componentRight}</Margin>}
      </Wrapper>
      {hasRowBorder && <RowBorder />}
    </TouchableOpacityOrView>
  );
};

Row.propTypes = {
  textLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitleLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitleRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  componentLeft: PropTypes.node,
  componentRight: PropTypes.node,
  backgroundColor: PropTypes.string,
  accessibilityState: PropTypes.object,
  accessibilityHide: PropTypes.bool,
  onPress: PropTypes.func,
  border: PropTypes.bool,
  hasBorderRadius: PropTypes.bool,
  hasRowBorder: PropTypes.bool,
  hasPadding: PropTypes.bool,
  activeOpacity: PropTypes.number,
  testID: PropTypes.string,
};

Row.defaultProps = {
  activeOpacity: DEFAULT_OPACITY,
  onPress: undefined,
  border: false,
  hasPadding: true,
  hasRowBorder: false,
  hasBorderRadius: true,
  textLeft: undefined,
  textRight: undefined,
  subtitleLeft: undefined,
  subtitleRight: undefined,
  componentLeft: undefined,
  componentRight: undefined,
  backgroundColor: undefined,
  accessibilityState: undefined,
  accessibilityHide: undefined,
  testID: undefined,
};

export default Row;
