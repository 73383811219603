import { fetchSavingsOverview } from 'actions/savings';
import { fetchTransferOverview } from 'actions/transfer';
import { getProfileCompleteness } from 'actions/digital-advice';
import { getProfile } from 'actions/profile';
import { getPensionsInfo } from 'actions/pensionsInfo';
import { fetchDashboardData } from 'helpers/dashboard';

export default async (store, config) => {
  const { dispatch } = store;

  const dispatchArray = [];
  await fetchDashboardData(dispatch);

  if (config && config.profileUpdate) {
    dispatchArray.push(getProfile());
  }

  dispatchArray.push(getProfileCompleteness());
  dispatchArray.push(fetchSavingsOverview());
  dispatchArray.push(fetchTransferOverview());
  dispatchArray.push(getPensionsInfo());

  await dispatch(dispatchArray);
};
