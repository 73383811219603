import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import {
  reducer,
  initialState,
  TOGGLE_POLICY,
  TOGGLE_COMPANY_GROUP,
  SELECT_POLICY,
} from './reducer';
import {
  SELECTION_TYPE_SINGLE,
  SELECTION_TYPE_MULTIPLE,
  usePreselectWhenOnePolicy,
} from './helpers';

import CompanyGroup from './CompanyGroup';
import Policy from './Policy';

const PolicySelector = ({
  data,
  onUpdate,
  policySelectionType,
  hasHeader,
  texts,
  isFoldable,
  theme,
}) => {
  const isOneCompanyGroup = data?.length === 1;
  const isOnePolicy = isOneCompanyGroup && data[0]?.policies?.length === 1;
  const isOnePolicyInEachCompanyGroup =
    data?.length > 0 &&
    data?.length === data?.map((x) => x.policies?.length).reduce((acc, curr) => acc + curr);
  const [state, dispatch] = useReducer(reducer, initialState);

  const onPolicyToggle = useCallback((policyId, companyGroup) => {
    dispatch({
      type: TOGGLE_POLICY,
      payload: {
        policy: { policyId, companyGroup },
      },
    });
  }, []);
  const onCompanyGroupToggle = useCallback((companyGroup) => {
    dispatch({
      type: TOGGLE_COMPANY_GROUP,
      payload: {
        companyGroup: companyGroup.companyGroup,
        policies: companyGroup.policies.map((p) => ({
          ...p,
          companyGroup: companyGroup.companyGroup,
        })),
      },
    });
  }, []);
  const onPolicySelect = useCallback((policyId) => {
    dispatch({
      type: SELECT_POLICY,
      payload: {
        policy: { policyId },
      },
    });
  }, []);
  const onPolicyPress =
    policySelectionType === SELECTION_TYPE_SINGLE ? onPolicySelect : onPolicyToggle;

  useEffect(() => {
    onUpdate(state);
  }, [onUpdate, state]);

  usePreselectWhenOnePolicy(data, state, isOnePolicy, onPolicyPress);

  if (isOnePolicy) {
    const policy = data[0].policies[0];

    return (
      <Policy
        key={policy.policyId}
        policy={policy}
        state={state}
        companyGroup={data[0].companyGroup}
        onPolicyPress={onPolicyPress}
        selectionType={policySelectionType}
      />
    );
  }

  if (isOneCompanyGroup) {
    const companyGroup = data[0];

    return (
      <CompanyGroup
        companyGroup={companyGroup}
        state={state}
        onCompanyGroupPress={onCompanyGroupToggle}
        onPolicyPress={onPolicyPress}
        isOneCompanyGroup
        isFoldable={isFoldable}
        policySelectionType={policySelectionType}
        hasHeader={hasHeader}
        texts={texts}
        theme={theme}
      />
    );
  }

  if (isOnePolicyInEachCompanyGroup) {
    return data?.map((companyGroup, key) => (
      <Policy
        key={key}
        policy={companyGroup.policies[0]}
        state={state}
        companyGroup={companyGroup.companyGroup}
        onPolicyPress={onPolicySelect}
        selectionType={SELECTION_TYPE_SINGLE}
      />
    ));
  }

  return data?.map((companyGroup, key) => (
    <CompanyGroup
      key={key}
      companyGroup={companyGroup}
      state={state}
      onCompanyGroupPress={onCompanyGroupToggle}
      onPolicyPress={onPolicyPress}
      isOneCompanyGroup={false}
      isFoldable={isFoldable}
      policySelectionType={policySelectionType}
      hasHeader={hasHeader}
      texts={texts}
      theme={theme}
    />
  ));
};

PolicySelector.propTypes = {
  data: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  texts: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  policySelectionType: PropTypes.oneOf([SELECTION_TYPE_SINGLE, SELECTION_TYPE_MULTIPLE]),
  hasHeader: PropTypes.bool,
  isFoldable: PropTypes.bool,
};

PolicySelector.defaultProps = {
  policySelectionType: SELECTION_TYPE_SINGLE,
  hasHeader: true,
  isFoldable: true,
};

export default withTheme(PolicySelector);
