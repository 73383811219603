import { getIntermediateNavPath, navigationRef, dispatch } from 'helpers/navigation';
import { CommonActions } from '@react-navigation/native';
import * as linking from 'helpers/linking';
import persistedStorage from 'services/stores/persisted';
import features from 'features';
import * as routeNames from 'constants/routeNames';
import { selectFirstChangelogFeature } from 'selectors/changelog';
import { selectPushNotification } from 'selectors/pushNotification';
import { ONBOARDING_COMPLETED_ROUTE } from '../constants';

// NOTE: we return undefined when we don't want to navigate via the result of this function
const getCompletedNavParams = async (state) => {
  persistedStorage.setItem('hasCompletedOnboarding', true);

  if (features.isEnabled('pushNotifications')) {
    const notification = selectPushNotification(state);

    if (notification?.url) {
      await linking.openLink(notification.url, '_self');

      // NOTE: we need to do this to have proper back behaviour after onboarding
      dispatch((navigationState) =>
        CommonActions.reset({
          ...navigationState,
          routes: [{ name: ONBOARDING_COMPLETED_ROUTE }],
          index: 0,
        })
      );

      return undefined;
    }
  }
  const changelogFeature = selectFirstChangelogFeature(state);

  if (changelogFeature) {
    return { name: routeNames.CHANGELOG };
  }

  const intermediateNavPath = await getIntermediateNavPath();

  if (intermediateNavPath) {
    linking.replace(intermediateNavPath);
    return undefined;
  }

  const currentRoute = navigationRef.current.getRootState()?.routes?.[0]?.name;

  // NOTE: we only to go to ONBOARDING_COMPLETED_ROUTE if there is no current route
  // because startApp action is dispatched from login and from AppRouter
  if (!currentRoute || ![routeNames.MAIN, routeNames.DEVELOPMENT].includes(currentRoute)) {
    navigationRef.current.reset({
      index: 0,
      routes: [{ name: ONBOARDING_COMPLETED_ROUTE }],
    });
    return undefined;
  }
  return undefined;
};

export default getCompletedNavParams;
