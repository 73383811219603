import styled from 'styled-components/native';

export const DROPDOWN_ICON_SIZE = 10;
const DROPDOWN_BORDER_SIZE = 1;
const SELECTOR_INPUT_HEIGHT = 44;

export const Dropdown = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
  border-width: ${DROPDOWN_BORDER_SIZE}px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
  border-color: ${({ hasError, isInactive, theme }) => {
    if (isInactive) {
      return theme.COLORS.INACTIVE_PRIMARY_ALERT;
    }
    if (hasError) {
      return theme.COLORS.PRIMARY_ALERT;
    }
    return theme.COLORS.PRIMARY_BORDER;
  }};
`;

export const DropdownWrapper = styled(Dropdown)`
  border-color: ${({ theme }) => theme.COLORS.PRIMARY_BORDER};
`;

export const DropdownItem = styled.TouchableOpacity`
  padding: ${({ theme }) => theme.SPACINGS.md}px;
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
  text-align: left;
  width: 100%;
`;

export const LeftWrapper = styled.View`
  flex: 1;
`;

export const RowWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${({ theme }) => theme.SPACINGS.md}px;
  height: ${SELECTOR_INPUT_HEIGHT - 2 * DROPDOWN_BORDER_SIZE}px;
`;
