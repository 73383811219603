import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';

const H3 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.H3.MOBILE}px;
`;

H3.defaultProps = {
  fontWeight: 'bold',
};

export default H3;
