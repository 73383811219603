import React, { Fragment } from 'react';
import { TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import MenuItem from '@sp/ui/base/MenuItem';
import Icon from '@sp/ui/base/Icon';
import { Link } from '@sp/ui/typography';
import Conditional from 'decorators/conditional';
import { CONTAINER_WIDTHS, BORDERS } from '@sp/ui/settings';

const DIVIDER_HEIGHT = 24;
const CONTAINER_MAXWIDTH = CONTAINER_WIDTHS.DESKTOP_WIDE;
const TOPBAR_HEIGHT = 60;
const LOGO_WIDTH = 143;
const LOGO_HEIGHT = 24;
const BORDER_WIDTH = BORDERS.s;

const FlexWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const UserNameWrapper = Conditional({
  desktop: Fragment,
  tablet: Fragment,
});

const WrapperBase = styled(FlexWrapper)`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  height: ${({ showBottomBorder }) =>
    showBottomBorder ? TOPBAR_HEIGHT + BORDER_WIDTH : TOPBAR_HEIGHT}px;
  justify-content: center;
  border-bottom-color: ${({ theme }) => theme.COLORS.PRIMARY};
  border-bottom-style: solid;
  border-bottom-width: ${({ showBottomBorder }) => (showBottomBorder ? BORDER_WIDTH : 0)}px;
`;

const Wrapper = Conditional({
  mobile: styled(WrapperBase)`
    padding: 0px ${({ theme }) => theme.SPACINGS.md}px;
  `,
  default: styled(WrapperBase)`
    padding: 0px ${({ theme }) => theme.SPACINGS.xl}px;
  `,
});

const TopBarContent = styled(FlexWrapper)`
  max-width: ${CONTAINER_MAXWIDTH}px;
  justify-content: space-between;
  width: 100%;
`;

const Divider = styled.View`
  width: ${(props) => props.theme.DEFAULTS.BORDER_WIDTH}px;
  height: ${DIVIDER_HEIGHT}px;
  background-color: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  margin: 0px ${(props) => props.theme.SPACINGS.md}px;
`;

const TopBar = ({
  logOutLabel,
  onPressUserName,
  onPressLogout,
  showProfile,
  showLogoutButton,
  userName,
  theme,
  showBottomBorder,
  hideLogo,
}) => {
  const TouchableOpacityOrFragment = onPressUserName ? TouchableOpacity : Fragment;

  return (
    <Wrapper showBottomBorder={showBottomBorder}>
      <TopBarContent>
        {hideLogo ? (<div />) : (
          <Icon
            name="sampension"
            width={LOGO_WIDTH}
            height={LOGO_HEIGHT}
            fill={theme.COLORS.SECONDARY_BACKGROUND}
          />
        )}
        <FlexWrapper>
          {showProfile && userName && (
            <UserNameWrapper>
              <TouchableOpacityOrFragment
                {...(onPressUserName && { onPress: { onPressUserName } })}
              >
                <Link color={theme.COLORS.SECONDARY_BACKGROUND}>{userName}</Link>
              </TouchableOpacityOrFragment>
              <Divider />
            </UserNameWrapper>
          )}

          {showLogoutButton && (
            <MenuItem name={logOutLabel} icon="padlock" compact onPress={onPressLogout} />
          )}
        </FlexWrapper>
      </TopBarContent>
    </Wrapper>
  );
};

TopBar.propTypes = {
  onPressLogout: PropTypes.func,
  showProfile: PropTypes.bool,
  showLogoutButton: PropTypes.bool,
  userName: PropTypes.string,
  logOutLabel: PropTypes.string.isRequired,
  onPressUserName: PropTypes.func,
  theme: PropTypes.object.isRequired,
  showBottomBorder: PropTypes.bool,
};

TopBar.defaultProps = {
  showProfile: false,
  showLogoutButton: false,
  userName: undefined,
  onPressLogout: undefined,
  onPressUserName: undefined,
  showBottomBorder: false,
};

export default withTheme(TopBar);
