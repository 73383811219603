export const BOOKING_AREA = 'Booking';

export const BOOKING_CONFIRM = {
  area: BOOKING_AREA,
  action: 'ConfirmBooking',
};

export const BOOKING_COMPLETED = {
  area: BOOKING_AREA,
  action: 'BookingCompleted',
};

export const BOOKING_RECEIPT = {
  area: BOOKING_AREA,
  action: 'BookingReceipt',
};

export const START_BOOKING_FLOW = {
  area: BOOKING_AREA,
  action: 'StartFlow',
};

export const BOOKING_CANCELED = {
  area: BOOKING_AREA,
  action: 'CancelBooking',
};

export const BOOKING_CALENDAR_EVENT_ADDED = {
  area: BOOKING_AREA,
  action: 'BookingCalendarEventAdded',
};
