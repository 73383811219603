import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import Row from '@sp/ui/v2/base/rows';
import RadioButton from '../RadioButton';

const RowWithRadioButton = ({
  titleLeft,
  titleRight,
  subtitleLeft,
  subtitleRight,
  isSelected,
  leftAlignRadioButton,
  ignoreIsSelectedBackgroundColor,
  onPress,
  border,
  accessibilityHide,
  hasPadding,
  isPartiallySelected,
  testID,
  theme,
}) => {
  const isSelectedBackgroundColorSetup =
    !ignoreIsSelectedBackgroundColor && isSelected ? theme.COLORS.PRIMARY_VARIANT1 : undefined;

  return (
    <Row
      onPress={onPress}
      border={border}
      hasPadding={hasPadding}
      textLeft={titleLeft}
      textRight={titleRight}
      subtitleLeft={subtitleLeft}
      subtitleRight={subtitleRight}
      componentLeft={
        leftAlignRadioButton && (
          <RadioButton
            isSelected={isSelected}
            onPress={onPress}
            testID={testID}
            isPartiallySelected={isPartiallySelected}
          />
        )
      }
      componentRight={
        !leftAlignRadioButton && (
          <RadioButton
            isSelected={isSelected}
            onPress={onPress}
            testID={testID}
            isPartiallySelected={isPartiallySelected}
          />
        )
      }
      accessibilityState={{
        checked: isSelected,
      }}
      backgroundColor={isSelectedBackgroundColorSetup}
      accessibilityHide={accessibilityHide}
    />
  );
};

RowWithRadioButton.propTypes = {
  titleLeft: PropTypes.string,
  titleRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitleLeft: PropTypes.string,
  subtitleRight: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
  border: PropTypes.bool,
  accessibilityHide: PropTypes.bool,
  isPartiallySelected: PropTypes.bool,
  leftAlignRadioButton: PropTypes.bool,
  hasPadding: PropTypes.bool,
  ignoreIsSelectedBackgroundColor: PropTypes.bool,
  testID: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

RowWithRadioButton.defaultProps = {
  onPress: undefined,
  hasPadding: true,
  border: false,
  titleLeft: undefined,
  titleRight: undefined,
  subtitleLeft: undefined,
  subtitleRight: undefined,
  testID: undefined,
  isPartiallySelected: false,
  accessibilityHide: false,
  leftAlignRadioButton: false,
  ignoreIsSelectedBackgroundColor: false,
};

export default withTheme(RowWithRadioButton);
