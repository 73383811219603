export const selectAllSavingsData = (state) => state.areas.savings?.data?.savings;

export const selectDepositFetchingState = (state) => state.areas.savings?.depositFetchingState;

export const selectSavingsDeposits = (state, pID) =>
  pID &&
  state.areas.savings?.data?.savings &&
  state.areas.savings?.data?.savings?.internalSavings.find((item) => item.coverageNumber === pID)
    .deposits;

export const selectAllSavingsCosts = (state, pID) =>
  pID &&
  state.areas.savings?.data?.savings &&
  state.areas.savings?.data?.savings?.internalSavings.find((item) => item.coverageNumber === pID)
    ?.expenses;

export const selectIsSeniorAndISP = (state, pID) => {
  const internalSaving = state.areas.savings?.data?.savings?.internalSavings.find(
    (item) => item.coverageNumber === pID
  );

  return internalSaving?.isSeniorPolicy && internalSaving?.type === 'ISP';
};

export const selectIsFetchingSavingsDeposits = (state) =>
  state.areas.savings?.isFetchingSavingsDeposits;

export const selectMissingSavingsData = (state) => {
  let hasNoSavingsData;
  const savingsData = state.areas.savings?.data?.savings;
  const { internalSavings, externalSavings, blockedSavings, pensionInfoFetched } =
    savingsData || {};

  if (internalSavings && externalSavings && blockedSavings) {
    hasNoSavingsData =
      internalSavings.length === 0 && externalSavings.length === 0 && blockedSavings.length === 0;
  }

  const missingSavingsKeys = {
    customerHasNotFetchedPensionInfo: !pensionInfoFetched && hasNoSavingsData,
    customerHasNoSavingsData: pensionInfoFetched && hasNoSavingsData,
  };

  const hasMissingSavingsKeys = Object.values(missingSavingsKeys).find((item) => item);

  return hasMissingSavingsKeys && missingSavingsKeys;
};

export const selectTechnicalTransferError = (state) =>
  state.areas.savings?.data?.savingsTransfer?.userCanTransferCode === 5;
