import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';

const CHECKBOX_SIZE = 16;
const CHECKBOX_INNER_MARGIN = 3;
const BORDER_WIDTH = 1;
const CHECKMARK_SIZE = 11;
const PARTIAL_STATE_OPACITY = 0.4;

const Box = styled.TouchableOpacity`
  border-width: ${BORDER_WIDTH}px;
  border-radius: ${({ theme }) => theme.SPACINGS.xs}px;
  width: ${CHECKBOX_SIZE}px;
  height: ${CHECKBOX_SIZE}px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, checked, isPartiallyChecked }) =>
    checked && !isPartiallyChecked ? theme.COLORS.PRIMARY : theme.COLORS.SECONDARY_BACKGROUND};
  border-color: ${({ theme, checked }) =>
    checked ? theme.COLORS.PRIMARY : theme.COLORS.GRAPH_INCOME};
`;

const InnerBox = styled.View`
  border-width: ${BORDER_WIDTH}px;
  border-radius: ${({ theme }) => theme.SPACINGS.xs}px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY};
  border-color: ${({ theme }) => theme.COLORS.PRIMARY};
  width: ${CHECKBOX_SIZE - CHECKBOX_INNER_MARGIN * 2}px;
  height: ${CHECKBOX_SIZE - CHECKBOX_INNER_MARGIN * 2}px;
  opacity: ${PARTIAL_STATE_OPACITY};
  align-items: center;
  justify-content: center;
`;

const Checkbox = ({
  isChecked,
  accessibilityLabel,
  testID,
  theme,
  onPress,
  isPartiallyChecked,
}) => (
  <Box
    accessibilityLabel={accessibilityLabel}
    accessibilityRole="checkbox"
    isPartiallyChecked={isPartiallyChecked}
    onPress={onPress}
    accessibilityState={{
      isChecked,
    }}
    checked={isChecked}
    testID={isChecked ? `${testID}-Checked` : testID}
  >
    {isChecked && !isPartiallyChecked && (
      <Icon
        name="checkmark"
        width={CHECKMARK_SIZE}
        height={CHECKMARK_SIZE}
        fill={theme.COLORS.SECONDARY_BACKGROUND}
      />
    )}
    {isPartiallyChecked && <InnerBox />}
  </Box>
);

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  accessibilityLabel: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  isPartiallyChecked: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  testID: PropTypes.string,
};

Checkbox.defaultProps = {
  onChangeChecked: undefined,
  accessibilityLabel: undefined,
  isPartiallyChecked: false,
  testID: undefined,
};

export default withTheme(Checkbox);
