import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { SPACINGS, COLORS, DEFAULTS, HIT_SLOP } from '@sp/ui/settings';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import { H5, Subtitle } from '@sp/ui/typography';

const DEFAULT_ARROW_DIRECTION = 'arrowRight';

const TouchableOpacity = styled.TouchableOpacity`
  width: 100%;
`;

const View = styled.View`
  width: 100%;
`;

const Wrapper = styled.View`
  ${(props) => (props.minHeight ? `min-height: ${props.minHeight}px` : '')};
  align-items: center;
  flex-direction: row;
  background: ${(props) => props.background || COLORS.SECONDARY_BACKGROUND};
  padding: 0 ${SPACINGS.md}px;
`;

const LeftWrapper = styled.View`
  margin-right: auto;
  flex: 1;
`;

const RightWrapper = styled.View``;

const IconLeft = styled.TouchableOpacity`
  min-width: ${DEFAULTS.ICON_HEIGHT}px;
`;

const IconRight = styled.TouchableOpacity``;

const TextLeft = styled.View`
  margin-right: ${(props) => props.theme.SPACINGS.md}px;
`;
const SubtitleLeft = styled.View`
  margin-right: ${(props) => props.theme.SPACINGS.md}px;
`;

const TextRight = styled.View``;
const SubtitleRight = styled.View``;

const getArrowName = (arrow) => {
  if (typeof arrow === 'boolean') {
    return DEFAULT_ARROW_DIRECTION;
  }

  switch (arrow.direction) {
    case 'right':
      return 'arrowRight';
    case 'up':
      return 'arrowUp';
    case 'down':
      return 'arrowDown';
    case 'left':
      return 'arrowLeft';
    default:
      return DEFAULT_ARROW_DIRECTION;
  }
};

const getIconProps = (icon) => ({
  name: typeof icon === 'string' ? icon : icon.name,
  fill: typeof icon === 'string' ? COLORS.PRIMARY : icon.fill || COLORS.PRIMARY,
  height: typeof icon === 'string' ? DEFAULTS.ICON_HEIGHT : icon.height || DEFAULTS.ICON_HEIGHT,
  width: typeof icon === 'string' ? DEFAULTS.ICON_HEIGHT : icon.width || DEFAULTS.ICON_HEIGHT,
});

const WrapperComponent = ({
  arrow,
  iconLeft,
  iconRight,
  componentRight,
  componentLeft,
  minHeight,
  textLeft,
  textRight,
  subtitleLeft,
  subtitleRight,
  backgroundColor,
}) => (
  <Wrapper background={backgroundColor} minHeight={minHeight}>
    {componentLeft}

    {iconLeft && (
      <IconLeft disabled={!iconLeft?.onPress} onPress={iconLeft?.onPress} hitSlop={HIT_SLOP}>
        <Icon {...getIconProps(iconLeft)} />
      </IconLeft>
    )}

    <LeftWrapper>
      <Margin marginLeft={iconLeft || componentLeft ? 'sm' : 0}>
        <TextLeft>
          {renderStringOrComponent(textLeft, H5, { fontWeight: 'regular', numberOfLines: 1 })}
        </TextLeft>

        {subtitleLeft && (
          <SubtitleLeft>
            <Margin marginTop="xs">
              {renderStringOrComponent(subtitleLeft, Subtitle, {
                fontWeight: 'regular',
                numberOfLines: 1,
              })}
            </Margin>
          </SubtitleLeft>
        )}
      </Margin>
    </LeftWrapper>

    <RightWrapper>
      <TextRight>
        {renderStringOrComponent(textRight, H5, { fontWeight: 'regular', textAlign: 'right' })}
      </TextRight>

      {subtitleRight && (
        <SubtitleRight>
          <Margin marginTop="xs">
            {renderStringOrComponent(subtitleRight, Subtitle, {
              fontWeight: 'regular',
              textAlign: 'right',
            })}
          </Margin>
        </SubtitleRight>
      )}
    </RightWrapper>

    {iconRight && (
      <Margin marginLeft="sm">
        <IconRight disabled={!iconRight?.onPress} onPress={iconRight?.onPress} hitSlop={HIT_SLOP}>
          <Icon {...getIconProps(iconRight)} />
        </IconRight>
      </Margin>
    )}

    {componentRight && <Margin marginLeft="sm">{componentRight}</Margin>}

    {arrow && (
      <Margin marginLeft="sm">
        <Icon
          name={getArrowName(arrow)}
          fill={typeof arrow === 'string' ? COLORS.GRAPH_INCOME : arrow.fill || COLORS.GRAPH_INCOME}
        />
      </Margin>
    )}
  </Wrapper>
);

/** Button component description */
const Row = (props) => {
  const {
    onPress,
    testID,
    focusable,
    accessibilityLabel,
    hasPanGestureEvent,
    accessibilityActions,
    onAccessibilityAction,
    accessibilityHint,
    accessibilityRole,
  } = props;

  return typeof onPress === 'function' && !hasPanGestureEvent ? (
    <TouchableOpacity
      onPress={onPress}
      testID={testID}
      focusable={focusable}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      accessibilityActions={accessibilityActions}
      onAccessibilityAction={onAccessibilityAction}
    >
      <WrapperComponent {...props} />
    </TouchableOpacity>
  ) : (
    <View
      testID={testID}
      focusable={focusable}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityRole}
      accessibilityActions={accessibilityActions}
      accessibilityHint={accessibilityHint}
      onAccessibilityAction={onAccessibilityAction}
    >
      <WrapperComponent {...props} />
    </View>
  );
};

Row.propTypes = {
  onPress: PropTypes.func,
  testID: PropTypes.string,
  focusable: PropTypes.bool,
  accessibilityHint: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  accessibilityRole: PropTypes.string,
  hasPanGestureEvent: PropTypes.bool,
  onAccessibilityAction: PropTypes.func,
  accessibilityActions: PropTypes.array,
};

Row.defaultProps = {
  onPress: undefined,
  testID: undefined,
  focusable: undefined,
  accessibilityRole: undefined,
  accessibilityHint: undefined,
  accessibilityLabel: undefined,
  hasPanGestureEvent: false,
  onAccessibilityAction: undefined,
  accessibilityActions: undefined,
};

WrapperComponent.propTypes = {
  /** Arrow component, always goes last in the row, can be boolean or object */
  arrow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      direction: PropTypes.oneOf(['up', 'down', 'right', 'left']),
      fill: PropTypes.string,
    }),
  ]),
  /** Left icon component, always goes first unless custom component is specified */
  iconLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      fill: PropTypes.string,
      onPress: PropTypes.func,
    }),
  ]),
  /** Right icon component, always goes last unless custom component is specified */
  iconRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      fill: PropTypes.string,
      onPress: PropTypes.func,
    }),
  ]),
  /** Custom component, renders as last element in row (but before arrow) */
  componentRight: PropTypes.node,
  /** Custom component, renders as first element */
  componentLeft: PropTypes.node,
  /** Left text, defaults to <H5> */
  textLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Right text, defaults to <H5> */
  textRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Background color */
  backgroundColor: PropTypes.string,
  onPress: PropTypes.func,
  /** Subtitle left detaults to <Subtitle>  */
  subtitleLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Subtitle right detaults to <Subtitle>  */
  subtitleRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Custom height of row, OneLineRow defaults to 46, TwoLineRow defaults to 80 */
  minHeight: PropTypes.number,
  testID: PropTypes.string,
};

WrapperComponent.defaultProps = {
  arrow: false,
  iconLeft: undefined,
  iconRight: undefined,
  componentRight: undefined,
  componentLeft: undefined,
  textLeft: undefined,
  textRight: undefined,
  subtitleLeft: undefined,
  subtitleRight: undefined,
  backgroundColor: undefined,
  onPress: undefined,
  minHeight: undefined,
  testID: undefined,
};

export default Row;
