export class SessionStore {
  constructor(prefix) {
    this.prefix = prefix;
  }

  async setItem(name, value) {
    const key = `${this.prefix}${name}`;
    global.sessionStorage.setItem(key, JSON.stringify(value));
  }

  async getItem(name) {
    const key = `${this.prefix}${name}`;
    const raw = global.sessionStorage.getItem(key);
    if (raw) {
      return JSON.parse(raw);
    }
    return undefined;
  }

  async removeItem(name) {
    const key = `${this.prefix}${name}`;
    global.sessionStorage.removeItem(key);
  }

  async clear() {
    Object.keys(global.sessionStorage)
      .filter((key) => key.startsWith(this.prefix))
      .forEach((key) => {
        global.sessionStorage.removeItem(key);
      });
  }
}

export default new SessionStore('_session_');
