import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '@sp/ui/base/Loader';
import ScrollPage from '@sp/ui/pages/ScrollPage';

const styles = {
  iframe: {
    width: '100%',
    border: 'none',
    overflow: 'hidden',
  },
};

const ClassicPage = ({ url, cacheKey }) => {
  const iframeRef = useRef();
  // const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const [height, setHeight] = useState(100);
  const frame = useMemo(
    () => (
      <iframe
        key={cacheKey}
        src={url}
        title="classic embedded page"
        style={styles.iframe}
        ref={iframeRef}
      />
    ),
    [url, cacheKey]
  );
  const onMessage = useCallback(({ data }) => {
    if (data && data.height && typeof data.height === 'number') {
      setHeight(Math.ceil(data.height));
    }
  }, []);

  useEffect(() => {
    const run = async () => {
      // await login(dispatch);
    };

    run().finally(() => setReady(true));
  }, []);

  useEffect(() => {
    if (iframeRef.current) {
      global.addEventListener('message', onMessage);
    }
    return () => {
      global.removeEventListener('message', onMessage);
    };
  }, [onMessage, iframeRef, ready]);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.style.height = `${height + 50}px`;
    }
  }, [height, iframeRef, ready]);

  return <ScrollPage>{ready ? frame : <Loader />}</ScrollPage>;
};

ClassicPage.propTypes = {
  url: PropTypes.string.isRequired,
  cacheKey: PropTypes.string,
};

ClassicPage.defaultProps = {
  cacheKey: undefined,
};

export default ClassicPage;
