import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import withTranslation from 'decorators/i18n';
import * as routeNames from 'constants/routeNames';
import { formatMoney } from 'helpers/string';
import { TwoLineRow } from '@sp/ui/base/rows';
import Modal from 'containers/layout/Modal';
import { H5 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import TransferredPolicyModalContent from '../modal/TransferredPolicyModalContent';
import { getPoliceTimestamp } from '../../helpers/getPoliceTimestamp';
import { getSavingsAccessibility } from '../../helpers/getSavingsAccessibility';
import Tracking from 'services/tracking';
import { CLICK_TRANSFERRED_POLICY } from 'areas/savings/trackingIds';

const TransferredPolicy = ({
  item,
  showDetails,
  isSampensionEmployee,
  savingsData,
  i18n,
  theme,
}) => {
  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);

  const { title, policyId, value, transferState, isInternal } = item;

  const showPoliceDetails = () => {
    setModalVisible(false);
    showDetails(policyId);
  };

  const isPoliceBeingSuccessfullyTransferred = transferState === 'Blocked';

  const accessibility = getSavingsAccessibility({
    components: ['TransferredPolicy'],
    props: {
      policyName: item.title,
      policyId: item.policyId,
    },
  });

  return (
    <>
      <TwoLineRow
        {...accessibility.TransferredPolicy}
        textLeft={
          title && (
            <H5 numberOfLines={1} fontWeight="bold">
              {title}
            </H5>
          )
        }
        textRight={
          <H5
            textAlign={isPoliceBeingSuccessfullyTransferred ? 'right' : 'center'}
            fontWeight="regular"
          >
            {isPoliceBeingSuccessfullyTransferred
              ? value && `${formatMoney(value, true)}kr.`
              : i18n.t('savings|Klik for\nmere info')}
          </H5>
        }
        subtitleLeft={i18n.t('savings|Police: {{ policyId }}', { policyId })}
        subtitleRight={
          isPoliceBeingSuccessfullyTransferred &&
          i18n.t('savings|pr. {{ timestamp }}', {
            timestamp: getPoliceTimestamp(policyId, savingsData),
          })
        }
        onPress={() => {
          Tracking.trackEvent(CLICK_TRANSFERRED_POLICY);
          setModalVisible(true);
        }}
        componentRight={
          <Icon
            name={isPoliceBeingSuccessfullyTransferred ? 'awaiting' : 'exclamationMarkFilled'}
            fill={
              isPoliceBeingSuccessfullyTransferred
                ? theme.COLORS.GRAPH_INCOME
                : theme.COLORS.PRIMARY_WARNING
            }
          />
        }
      />
      <Modal
        visible={modalVisible}
        actions={
          transferState === 'Unknown' && !isSampensionEmployee
            ? [
                {
                  text: i18n.t('savings|Kontakt os'),
                  mobilePosition: 'bottom',
                  onPress: () => {
                    navigation.navigate(routeNames.MODAL_CMS_STACK, { id: 'contactoverview' });
                    setModalVisible(false);
                  },
                },
              ]
            : []
        }
        onRequestClose={() => setModalVisible(false)}
      >
        <TransferredPolicyModalContent
          item={item}
          transferState={transferState}
          showDetails={showPoliceDetails}
          isInternal={isInternal}
          isSampensionEmployee={isSampensionEmployee}
        />
      </Modal>
    </>
  );
};

TransferredPolicy.propTypes = {
  item: PropTypes.object,
  savingsData: PropTypes.object,
  showDetails: PropTypes.func,
  isSampensionEmployee: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

TransferredPolicy.defaultProps = {
  item: undefined,
  savingsData: undefined,
  showDetails: undefined,
  isSampensionEmployee: false,
};

export default compose(withTheme, withTranslation())(TransferredPolicy);
