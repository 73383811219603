import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

const NOTIFICATION_DISPLAY_TIME = 8000;

const NotificationProviderWrapper = ({ children, provider: Provider }) => {
  const [notifications, setNotifications] = useState([]);

  const dismiss = useCallback(
    (notificationId) =>
      setNotifications((oldNotifications) =>
        oldNotifications.filter(({ id }) => id !== notificationId)
      ),
    []
  );

  const dismissAll = useCallback(() => setNotifications([]), []);

  const dismissOnAutoHide = useCallback(
    (notification) => {
      if (notification.autoHideAfter) {
        setTimeout(() => {
          dismiss(notification.id);
        }, notification.autoHideAfter);
      }
    },
    [dismiss]
  );

  const showError = useCallback(
    (error, options = {}) => {
      setNotifications((oldNotifications) => {
        if (options.id && oldNotifications.find(({ id }) => options.id === id)) {
          return oldNotifications;
        }

        const errorNotification = {
          text: error,
          id: options.id || nanoid(),
          buttonText: options.buttonText,
          onPressButton: options.onPressButton,
          dismissable: options.dismissable !== 'undefined' ? options.dismissable : true,
          type: options.type || 'error',
          autoHideAfter: options.autoHideAfter,
        };

        dismissOnAutoHide(errorNotification);
        return [...oldNotifications, errorNotification];
      });
    },
    [dismissOnAutoHide]
  );

  const showNotification = useCallback(
    (text, options = {}) => {
      setNotifications((oldNotifications) => {
        if (options.id && oldNotifications.find(({ id }) => options.id === id)) {
          return oldNotifications;
        }

        const notification = {
          id: options.id || nanoid(),
          text,
          dismissable: true,
          type: 'success',
          ...options,
          autoHideAfter: options.autoHideAfter ?? NOTIFICATION_DISPLAY_TIME,
        };

        dismissOnAutoHide(notification);

        return [...oldNotifications, notification];
      });
    },
    [dismissOnAutoHide]
  );

  return (
    <Provider
      value={{
        notifications,
        showError,
        showNotification,
        dismiss,
        dismissAll,
      }}
    >
      {children}
    </Provider>
  );
};

NotificationProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  provider: PropTypes.object.isRequired,
};

export default NotificationProviderWrapper;
