import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Margin from '@sp/ui/base/Margin';

const TouchableOpacity = styled.TouchableOpacity``;

const ShowMoreOrLess = ({ moreButton, lessButton, content, accessibilityLabel }) => {
  const [expanded, setExpanded] = useState(false);

  return expanded ? (
    <>
      {content}
      <Margin marginTop="sm">
        <TouchableOpacity onPress={() => setExpanded(false)}>{lessButton}</TouchableOpacity>
      </Margin>
    </>
  ) : (
    <TouchableOpacity
      onPress={() => setExpanded(true)}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityLabel ? 'text' : 'button'}
    >
      {moreButton}
    </TouchableOpacity>
  );
};

ShowMoreOrLess.propTypes = {
  moreButton: PropTypes.node.isRequired,
  lessButton: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  accessibilityLabel: PropTypes.string,
};

ShowMoreOrLess.defaultProps = {
  accessibilityLabel: undefined,
};

export default withTheme(ShowMoreOrLess);
