import React, { useRef, useState } from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import { useAppSelector } from 'hooks/redux';
import { Actions } from '@sp/ui/v2/pages/BasePage';
import SwiperPage from '@sp/ui/pages/SwiperPage';
import Swiper from '@sp/ui/layout/Swiper';
import { Appear } from '@sp/ui/v2/animations';
import Alert from '@sp/ui/base/inputs/Alert';
import CurrentStepIndicator from '@sp/ui/v2/base/CurrentStepIndicator';
import { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import { initFunc } from 'helpers/props';
import Tracking from 'services/tracking';
import * as routeNames from 'constants/routeNames';
import { ONBOARDING_COMPLETED_ROUTE } from 'services/onboarding/constants';

import Menu from 'containers/navigation/Menu';
import withScreenContainer from 'decorators/withScreenContainer';
import Conditional from 'decorators/conditional';

import { selectChangelogFeatureInfo } from 'selectors/changelog';
import { selectUserFirstName } from 'selectors/profile';
import { finishFeatureIntro } from 'actions/changelog';

import Slide1 from './Slide1';
import Slide2 from './Slide2';

const ActionsWrapper = styled.View`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const AnimationWrapper = Conditional({
  mobile: styled.View`
    width: 100%;
  `,
  default: styled.View``,
});

const BookingIntro = ({ i18n, finishedIntro, userName, theme }) => {
  const info = useAppSelector((state) => selectChangelogFeatureInfo(state, 'booking'));
  const [slideIndex, setSlideIndex] = useState(0);
  const [isAlertVisible, setAlertVisible] = useState(false);
  const swiperRef = useRef();

  const firstAction =
    slideIndex === 0
      ? {
          text: i18n.t('common|Næste'),
          testID: 'bookingIntroNextBtn',
          type: 'primary',
          onPress: () => swiperRef.current.moveTo(1),
        }
      : {
          text: i18n.t('booking|bookAMeeting'),
          testID: 'bookingIntroBookBtn',
          type: 'primary',
          onPress: () => finishedIntro(info.id, true),
        };

  return (
    <SwiperPage customHeader={null} hasHeader={false} backButton={null} menu={<Menu />}>
      <Swiper
        testID="introSlider"
        ref={swiperRef}
        onIndexChanged={(newIndex) => setSlideIndex(newIndex)}
      >
        <Slide1
          description={info.pages?.[0]?.description}
          userName={userName}
          backgroundImage={info.pages?.[0]?.backgroundImage}
        />
        <Slide2
          title={info.pages?.[1]?.title}
          description={info.pages?.[1]?.description}
          startAnimation={slideIndex === 1}
        />
      </Swiper>
      <ActionsWrapper>
        <AnimationWrapper>
          <Appear animationType="OutLin" duration={600}>
            <Actions
              horizontalMode
              actions={[
                firstAction,
                {
                  text: i18n.t('booking|notNow'),
                  testID: 'bookingIntroCancelBtn',
                  type: 'secondary',
                  onPress: () => setAlertVisible(true),
                },
              ]}
              actionsContent={
                <CurrentStepIndicator
                  current={slideIndex}
                  ids={[0, 1]}
                  onPress={(i) => swiperRef.current.moveTo(i)}
                  color={theme.COLORS.PRIMARY}
                />
              }
            />
            <SafeAreaWrapper />
          </Appear>
        </AnimationWrapper>
      </ActionsWrapper>
      <Alert
        visible={isAlertVisible}
        onRequestClose={() => setAlertVisible(false)}
        title={i18n.t('common|Skjul denne besked fremover?')}
        actions={[
          {
            text: i18n.t('Ja'),
            onPress: () => {
              setAlertVisible(false);
              finishedIntro(info.id, false);
            },
          },
          {
            text: i18n.t('Nej'),
            style: 'secondary',
            onPress: () => {
              setAlertVisible(false);
              finishedIntro(info.id, false, true);
            },
          },
        ]}
      />
    </SwiperPage>
  );
};

BookingIntro.propTypes = {
  i18n: PropTypes.object.isRequired,
  finishedIntro: initFunc,
  theme: PropTypes.object.isRequired,
  userName: PropTypes.string,
};

BookingIntro.defaultProps = {
  finishedIntro: undefined,
  userName: undefined,
};

const mapStateToProps = (state, props) => ({
  ...props,
  userName: selectUserFirstName(state),
});

const init = ({ action, getProps }) =>
  action(async () => {
    const { dispatch, navigation } = getProps();

    return {
      finishedIntro: (id, wantsToBook, showAgain = false) =>
        action(async () => {
          Tracking.trackEvent(
            {
              area: routeNames.CHANGELOG,
              action: 'FinishedBookingIntro',
            },
            { wantsToBook, showAgain }
          );

          if (!showAgain) {
            dispatch(finishFeatureIntro(id));
          }

          if (wantsToBook) {
            navigation.replace(routeNames.MAIN, { screen: routeNames.BOOKING, initial: false });
          } else {
            navigation.replace(ONBOARDING_COMPLETED_ROUTE);
          }
        }),
    };
  });

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['booking'],
  init,
})(withTheme(BookingIntro));
