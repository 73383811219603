import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import { DEFAULTS } from '@sp/ui/settings';
import { Button } from '@sp/ui/base/buttons';

const ROW_PADDING = 16;
const MARGIN_HORIZONTAL = 10;
const NOTIFICATION_MAX_WIDTH = 700;
const BOX_SHADOW_VERTICAL = 2;
const BOX_SHADOW_BLUR = 4;

const Wrapper = styled.View`
  width: 100%;
  max-width: ${NOTIFICATION_MAX_WIDTH}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  padding: ${ROW_PADDING}px ${ROW_PADDING}px;
  border-radius: ${DEFAULTS.BORDER_RADIUS}px;
  box-shadow: 0 ${BOX_SHADOW_VERTICAL}px ${BOX_SHADOW_BLUR}px
    ${(props) => props.theme.COLORS.SHADOW};
`;

const TopWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Content = styled.View`
  margin-right: ${MARGIN_HORIZONTAL}px;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const ButtonWrapper = styled.View`
  padding-top: ${ROW_PADDING}px;
`;

const getTypes = (colors) => ({
  success: {
    background: colors.POSITIVE_STATUS_SHADOW,
    border: colors.PRIMARY_SUCCESS,
    icon: 'checkmark',
  },
  warning: {
    background: colors.NEGATIVE_STATUS_SHADOW,
    border: colors.PRIMARY_WARNING,
  },
  error: {
    background: colors.ERROR_BACKGROUND_SHADOW,
    border: colors.PRIMARY_ALERT,
  },
});

const TouchableOpacity = styled.TouchableOpacity``;

const Notification = ({ onPressClose, text, theme, type, onPressButton, inactive, buttonText }) => {
  const types = getTypes(theme.COLORS);
  const { background, border } = types[type];
  return (
    <Wrapper backgroundColor={background} borderColor={border}>
      <TopWrapper>
        <Content>
          <Body>{text}</Body>
        </Content>
        {onPressClose && (
          <TouchableOpacity
            onPress={onPressClose}
            hitSlop={theme.HIT_SLOP}
            testID="notificationCloseButton"
          >
            <Icon
              fill={theme.COLORS.PRIMARY_TEXT}
              name="close"
              width={theme.SPACINGS.md}
              height={theme.SPACINGS.md}
            />
          </TouchableOpacity>
        )}
      </TopWrapper>
      {onPressButton && buttonText && (
        <ButtonWrapper>
          <Button onPress={onPressButton} text={buttonText} inactive={inactive} />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

Notification.propTypes = {
  onPressClose: PropTypes.func,
  onPressButton: PropTypes.func,
  type: PropTypes.oneOf(['success', 'error', 'warning']),
  text: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  inactive: PropTypes.bool,
  buttonText: PropTypes.string,
};

Notification.defaultProps = {
  onPressClose: undefined,
  onPressButton: undefined,
  type: 'success',
  inactive: false,
  buttonText: undefined,
};

export default withTheme(Notification);
