type DefaultModalStateTypes = {
  isModalOpen: boolean;
  modalText?: {
    modalTitle: string;
    modalBody: string;
  };
};

type DefaultInputStateTypes = {
  selectedEarlyRetirement?: string;
  enteredIncome?: string;
  isIncomeValid?: boolean;
  isChurchTaxSelected: boolean;
};

type RadioButtonsTypes = {
  label: string;
  isSelected: boolean;
}[];

type ReturnResponseType = {
  DEFAULT_MODAL_STATE: DefaultModalStateTypes;
  DEFAULT_INPUT_STATE: DefaultInputStateTypes;
  SELECTION_OPTIONS: { [key: string]: string };
  RADIO_BUTTONS: RadioButtonsTypes;
};

export const getDefaultIncomeStates = (i18n: { t: (s: string) => string }): ReturnResponseType => ({
  DEFAULT_MODAL_STATE: { isModalOpen: false, modalText: undefined },
  DEFAULT_INPUT_STATE: {
    selectedEarlyRetirement: undefined,
    enteredIncome: undefined,
    isIncomeValid: undefined,
    isChurchTaxSelected: true,
  },
  SELECTION_OPTIONS: {
    no: i18n.t('common|no'),
    parttimeinsured: i18n.t('partner|partTimeInsured'),
    fulltimeinsured: i18n.t('partner|fullTimeInsured'),
  },
  RADIO_BUTTONS: [
    {
      label: i18n.t('common|no'),
      isSelected: true,
    },
    {
      label: i18n.t('common|yes'),
      isSelected: false,
    },
  ],
});
