const PROFILE_AREA = 'Profile';

const EMAIL_AREA = 'Email';
const PHONE_NUMBER_AREA = 'PhoneNumber';

const VERIFY_EMAIL_AREA = 'VerifyEmail';
const VERIFY_PHONE_NUMBER_AREA = 'VerifyPhoneNumber';

const DELETE_SKAT_OR_PI_AREA = 'DeleteSkatOrPI';
const DELETE_CHILDREN_DATA_AREA = 'DeleteChildren';

export const EMAIL = {
  DELETE: { area: EMAIL_AREA, action: 'Delete' },
  SAVE: { area: EMAIL_AREA, action: 'Save' },
  CLICK: { area: PROFILE_AREA, action: 'ClickEmail' },
};

export const PHONE_NUMBER = {
  DELETE: { area: PHONE_NUMBER_AREA, action: 'Delete' },
  SAVE: { area: PHONE_NUMBER_AREA, action: 'Save' },
  CLICK: { area: PROFILE_AREA, action: 'ClickPhoneNumber' },
};

export const VERIFY_EMAIL = {
  VERIFICATION_SUCCESS: { area: VERIFY_EMAIL_AREA, action: 'VerificationSuccess' },
  VERIFICATION_FAIL: { area: VERIFY_EMAIL_AREA, action: 'VerificationFail' },
  SEND_CODE_AGAIN: { area: VERIFY_EMAIL_AREA, action: 'SendCodeAgain' },
};

export const VERIFY_PHONE_NUMBER = {
  VERIFICATION_SUCCESS: { area: VERIFY_PHONE_NUMBER_AREA, action: 'VerificationSuccess' },
  VERIFICATION_FAIL: { area: VERIFY_PHONE_NUMBER_AREA, action: 'VerificationFail' },
  SEND_CODE_AGAIN: { area: VERIFY_PHONE_NUMBER_AREA, action: 'SendCodeAgain' },
};

export const DELETE_SKAT_OR_PI = {
  area: DELETE_SKAT_OR_PI_AREA,
  action: 'DeleteData',
};

export const HARD_DELETE_CHILDREN_DATA = {
  area: DELETE_CHILDREN_DATA_AREA,
  action: 'DeleteChildrenData',
};
