import {
  TRANSFER_SCHEME_PAYMENT,
  TRANSFER_JOB_CHANGE,
  TRANSFER_COVERAGE,
} from 'areas/transfer/constants/selectionReferences';

const getTransferSavingsInsuranceSelection = (
  savingsInsurance,
  hasSelectedWithSavingsInsurance
) => {
  switch (savingsInsurance) {
    case 'Mandatory':
      return true;
    case 'Unavailable':
      return false;
    default:
      return hasSelectedWithSavingsInsurance;
  }
};

const getSelection = (selections, reference) =>
  selections?.find(({ questionReference }) => questionReference === reference)?.isSelected;

export const getTransferPostRequestData = (transferForm) => {
  const {
    contactDetails,
    targetId,
    transferableSources,
    hasSelectedWithSavingsInsurance,
    consents,
  } = transferForm ?? {};
  const { email, phone } = contactDetails ?? {};
  const { number, countryCode } = phone ?? {};

  const sourcePolicies = transferableSources
    ?.filter(({ isSelected }) => isSelected)
    ?.map(({ policyId, savingsInsurance, selections }) => ({
      sourcePolicyId: policyId,
      employerPolicy: getSelection(selections, TRANSFER_SCHEME_PAYMENT),
      underThreeYears: getSelection(selections, TRANSFER_JOB_CHANGE),
      basisCoverage: getSelection(selections, TRANSFER_COVERAGE),
      savingsInsurance: getTransferSavingsInsuranceSelection(
        savingsInsurance,
        hasSelectedWithSavingsInsurance
      ),
      consents: consents?.map(({ key, version }) => ({ key, version })),
    }));

  return {
    email,
    phone: `${countryCode}${number}`,
    targetPolicyId: targetId,
    sourcePolicies,
    consents: consents?.map(({ key, version }) => ({ key, version })),
  };
};
