import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from 'decorators/i18n';
import { ListWithSeparator } from '@sp/ui/base/lists';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import { getDepositBrowserContent } from '../../helpers/getDepositBrowserContent';

const totalAmount = (year, savingsDeposits) => {
  const { deposits } = savingsDeposits.find((item) => item.year === year);
  const { accumulatedDepositAmount } = getDepositBrowserContent(deposits) ?? {};
  return accumulatedDepositAmount;
};

const AllPaymentYears = ({ savingsDeposits, goToSpecificPaymentYear, i18n }) => (
  <ListWithSeparator separator={RowBorder}>
    {savingsDeposits.map((item, i) => (
      <OneLineRow
        key={`${i}_${item.year}`}
        arrow
        onPress={() => goToSpecificPaymentYear(item.year, i)}
        textLeft={`${item.year}`}
        textRight={
          totalAmount(item.year, savingsDeposits) > 0
            ? i18n.t('savings|{{accumulatedDepositAmount}}kr.', {
                accumulatedDepositAmount: totalAmount(item.year, savingsDeposits),
              })
            : i18n.t('savings|Ingen indbetalinger')
        }
      />
    ))}
  </ListWithSeparator>
);

AllPaymentYears.propTypes = {
  goToSpecificPaymentYear: PropTypes.func.isRequired,
  savingsDeposits: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(AllPaymentYears);
