import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';
import { TopContent } from '@sp/ui/pages/BasePage';
import Icon from '@sp/ui/base/Icon';

const ICON_SIZE = 80;

const IconWrapper = Conditional({
  default: styled.View`
    padding-top: ${({ marginTop, theme }) => marginTop || theme.SPACINGS.xl}px;
    align-items: center;
  `,
  desktop: styled.View`
    padding-bottom: ${(props) => props.theme.SPACINGS.xl}px;
    align-items: center;
  `,
});

const Image = styled.Image`
  height: 100px;
  width: 100px;
`;

const Result = ({
  heading,
  iconName,
  iconColor,
  iconImage,
  description,
  disableBottomPadding,
  marginTop,
}) => (
  <>
    {(iconName || iconImage) && (
      <IconWrapper marginTop={marginTop}>
        {iconName && <Icon fill={iconColor} height={ICON_SIZE} width={ICON_SIZE} name={iconName} />}
        {iconImage && <Image source={iconImage} />}
      </IconWrapper>
    )}
    <TopContent
      disableBottomPadding={disableBottomPadding}
      heading={heading}
      description={description}
    />
  </>
);

Result.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  marginTop: PropTypes.number,
  iconImage: PropTypes.any,
  disableBottomPadding: PropTypes.bool,
};

Result.defaultProps = {
  heading: undefined,
  description: undefined,
  iconName: undefined,
  iconColor: undefined,
  iconImage: undefined,
  marginTop: undefined,
  disableBottomPadding: true,
};

export default Result;
