import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import Margin from '@sp/ui/base/Margin';
import {
  Heading,
  Description,
  ContentWrapper,
  Image,
  Wrapper,
  BackgroundImage,
} from './index.shared';

const ContentOffset = styled.View`
  flex: 3;
`;

const TextOnlyOffset = styled.View`
  flex: 1;
`;

const Slide = ({
  sources,
  children,
  description,
  heading,
  image,
  textOnly,
  backgroundColor,
  testID,
}) => {
  const renderContent = () => (
    <Wrapper backgroundColor={backgroundColor} testID={testID}>
      {textOnly && <TextOnlyOffset />}
      <ContentWrapper textOnly={textOnly}>
        {image && <Image source={image} resizeMode="contain" />}
        <Heading textAlign="center">{heading}</Heading>
        {textOnly && <Margin marginBottom="xl" />}
        <Description textAlign="center" fontWeight="semibold">
          {description}
        </Description>
      </ContentWrapper>
      {children || <ContentOffset />}
    </Wrapper>
  );

  return sources ? (
    <BackgroundImage {...sources}>{renderContent()}</BackgroundImage>
  ) : (
    <>{renderContent()}</>
  );
};

Slide.propTypes = {
  description: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  heading: PropTypes.string.isRequired,
  sources: PropTypes.shape({
    mobile: PropTypes.any,
    desktop: PropTypes.any,
    tablet: PropTypes.any,
    landscape: PropTypes.any,
  }),
  image: PropTypes.any,
  textOnly: PropTypes.bool,
  testID: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Slide.defaultProps = {
  children: undefined,
  sources: undefined,
  image: undefined,
  textOnly: false,
  testID: undefined,
  backgroundColor: undefined,
};

export default Slide;
