import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image as SvgImage } from 'react-native-svg';
import { selectIsDeviceLandscape, selectDeviceType } from 'selectors/device';

const ConditionalSvgImage = ({
  deviceType,
  isLandscape,
  mobile,
  tablet,
  landscape,
  desktop,
  ...props
}) => {
  if (deviceType === 'mobile' && mobile) {
    return <SvgImage href={mobile} {...props} />;
  }
  if (deviceType === 'tablet' && tablet) {
    return <SvgImage href={isLandscape && landscape ? landscape : tablet} {...props} />;
  }
  if (deviceType === 'desktop' && desktop) {
    return <SvgImage href={desktop} {...props} />;
  }

  return <SvgImage href={desktop} {...props} />;
};

ConditionalSvgImage.propTypes = {
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop', 'default']).isRequired,
  mobile: PropTypes.any.isRequired,
  desktop: PropTypes.any.isRequired,
  tablet: PropTypes.any,
  landscape: PropTypes.any,
  isLandscape: PropTypes.bool.isRequired,
};

ConditionalSvgImage.defaultProps = {
  tablet: undefined,
  landscape: undefined,
};

const mapStateToProps = (state) => ({
  deviceType: selectDeviceType(state),
  isLandscape: selectIsDeviceLandscape(state),
});

export default connect(mapStateToProps)(ConditionalSvgImage);
