import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import { openLink } from 'helpers/linking';

const BlockLinks = ({ items }) => {
  const openUrl = (link) => () => openLink(link, '_self');

  return items.map(({ label, link }) => (
    <Fragment key={label}>
      <Link onPress={openUrl(link)}>{label}</Link>
      <Margin marginBottom="md" />
    </Fragment>
  ));
};

BlockLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default BlockLinks;
