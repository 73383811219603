import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Conditional from 'decorators/conditional';
import Loader from '@sp/ui/base/Loader';
import { Actions, ActionShape } from '@sp/ui/pages/BasePage';
import Margin from '@sp/ui/base/Margin';
import CloseIcon from './CloseIcon';

const root = global.document.createElement('div');
global.document.body.appendChild(root);

const MODAL_Z_INDEX = 9;
const MODAL_MAX_WIDTH_DESKTOP = 700;
const MODAL_BOTTOM_OFFSET = 80;
const MODAL_TOP_PADDING = 72;
const BUTTONS_PADDING_BOTTOM_MOBILE = 6;

const ModalContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${MODAL_Z_INDEX};
`;

// TODO: use colors from constants once the missing ones are available ('@sp/ui/settings')
const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const WrapperDesktop = styled.div`
  position: relative;
  width: 100%;
  max-width: ${MODAL_MAX_WIDTH_DESKTOP}px;
  max-height: calc(100% - ${MODAL_BOTTOM_OFFSET}px);
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  overflow: auto;
`;

const WrapperMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
`;

const Wrapper = Conditional({
  mobile: WrapperMobile,
  default: WrapperDesktop,
});

const Content = Conditional({
  mobile: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    padding: ${MODAL_TOP_PADDING}px ${({ theme }) => theme.SPACINGS.md}px 0px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
  `,
  default: styled.div`
    padding: ${MODAL_TOP_PADDING}px ${({ theme }) => theme.SPACINGS.xxl}px 0px;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    display: flex;
  `,
});

const ChildrenWrapper = styled.div``;

const ButtonWrapper = Conditional({
  mobile: styled.div`
    margin-top: auto;
    padding-bottom: ${BUTTONS_PADDING_BOTTOM_MOBILE}px;
    justify-content: center;
  `,
  default: styled.div`
    padding-bottom: ${({ theme }) => theme.SPACINGS.lg}px;
  `,
});

const CloseButtonDesktop = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  margin: 0;
  padding: ${({ theme }) => theme.SPACINGS.lg}px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const CloseButton = Conditional({
  mobile: styled(CloseButtonDesktop)`
    padding: ${({ theme }) => theme.SPACINGS.md}px;
  `,
  default: CloseButtonDesktop,
});

const Modal = ({ children, visible, onRequestClose, actions, loading, theme }) =>
  ReactDOM.createPortal(
    <ModalContainer visible={visible} role="alert">
      <Overlay onClick={onRequestClose} />
      <Wrapper>
        <Content>
          <ChildrenWrapper>{children}</ChildrenWrapper>
          {actions && actions.length > 0 && (
            <ButtonWrapper>
              <Actions actions={actions} zIndex={1} />
            </ButtonWrapper>
          )}

          {!actions && <Margin marginBottom="xxl" />}
        </Content>

        <CloseButton type="button" onClick={onRequestClose}>
          <CloseIcon theme={theme} />
        </CloseButton>
        {loading && <Loader />}
      </Wrapper>
    </ModalContainer>,
    root
  );

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  transparent: PropTypes.bool,
  actions: PropTypes.arrayOf(ActionShape),
  loading: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  accessibilityRole: PropTypes.string,
};

Modal.defaultProps = {
  loading: false,
  accessibilityRole: undefined,
};

export default withTheme(Modal);
