import logoAP from 'assets/images/company-specific/AP/logo.png';
import logoPJD from 'assets/images/company-specific/PJD/logo.png';
import logoISP from 'assets/images/company-specific/ISP/logo.png';

const getSlideInfo = (companyGroup, iconChanged, showWelcome) => {
  let slide1, slide2;

  let companyImage;

  switch (companyGroup) {
    case 'AP':
      companyImage = logoAP;
      break;
    case 'PJD':
      companyImage = logoPJD;
      break;
    case 'ISP':
      companyImage = logoISP;
      break;
  }

  if (showWelcome) {
    slide1 = {
      title: 'onboarding|welcomeUser',
      description: `onboarding|slide1CompanySpecificDescription${companyGroup}`,
      companyImage,
    };
  }

  if (iconChanged) {
    slide2 = {
      title: 'onboarding|slide2CompanySpecificTitle',
      description: `onboarding|slide2CompanySpecificDescription${companyGroup}`,
      companyImage,
      iconUri: companyGroup,
    };
  }

  return {
    slide1,
    slide2,
  };
};

export default getSlideInfo;
