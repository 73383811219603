import React from 'react';
import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';
import Svg, { LinearGradient, Stop, Rect, Defs, G } from 'react-native-svg';
import PropTypes from 'prop-types';
import { CONTAINER_WIDTHS } from '@sp/ui/settings';

const WIDTH = '100%';
const MAX_WIDTH = CONTAINER_WIDTHS.DESKTOP_WIDE;

const Container = styled.View`
  width: ${WIDTH};
  height: ${(props) => props.height}px;
  max-width: ${MAX_WIDTH}px;
  background: ${(props) => props.bgColor};
  position: relative;
`;

const gradientContainer = (backgroundColor, borderRadius, gradient, colorStops, height, width) => {
  // apply "unique" id to gradient, fix gradient not showing when multiples instances are in DOM
  const UID = new Date().getTime();

  const ShowGradient = () => (
    <Svg height={height} width={width} style={{ borderRadius }}>
      <G>
        <Defs>
          <LinearGradient
            id={`gradient${UID}`}
            x1={gradient.x1}
            y1={gradient.y1}
            x2={gradient.x2}
            y2={gradient.y2}
          >
            {colorStops.map((item, i) => (
              <Stop key={i} offset={colorStops[i].offset} stopColor={colorStops[i].stopColor} />
            ))}
          </LinearGradient>
        </Defs>
        <Rect fill={`url(#gradient${UID})`} x="0" y="0" width="100%" height="100%" />
      </G>
    </Svg>
  );

  return (
    <Container width={width} height={height} bgColor={backgroundColor}>
      <ShowGradient name="gradient" height={height} width={width} />
    </Container>
  );
};

const GradientBox = ({ gradient, colorStops, height, width, backgroundColor, borderRadius }) => {
  const createGradient = () =>
    gradientContainer(backgroundColor, borderRadius, gradient, colorStops, height, width);
  const Gradient = Conditional({
    default: createGradient,
  });

  return <Gradient />;
};

GradientBox.propTypes = {
  gradient: PropTypes.object,
  colorStops: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
};

GradientBox.defaultProps = {
  gradient: {},
  colorStops: [],
  height: 0,
  width: 0,
  borderRadius: 0,
  backgroundColor: 'transparent',
};

export default GradientBox;
