import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/v2/typography/index.shared';

const Body2 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.V2_BODY2.DEFAULT}px;
  line-height: ${(props) => props.theme.FONT_SIZES.V2_BODY2.DEFAULT * 1.5}px;
`;

Body2.defaultProps = {
  fontWeight: 'semibold',
  fontStyle: 'normal',
};

export default Body2;
