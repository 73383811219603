import React from 'react';
import { AlertTypeProps, ActionProps } from '.';

const { default: Modal } = require('@sp/ui/v2/layout/Modal');
const { default: Margin } = require('@sp/ui/base/Margin');
const { H2, Body } = require('@sp/ui/v2/typography');

export type AlertActionProps = Omit<ActionProps, 'style'> & {
  style?: {
    style?: 'primary' | 'secondary';
    text: ActionProps['text'];
    onPress: ActionProps['onPress'];
  };
};

type AlertWebProps = Omit<AlertTypeProps, 'actions'> & {
  actions: AlertActionProps[];
};

const Alert: React.FC<AlertWebProps> = ({
  isVisible,
  isDesktop,
  title,
  description,
  actions,
  onRequestClose,
}) => (
  <Modal
    visible={isVisible}
    onRequestClose={onRequestClose}
    actions={actions?.map((props) => ({
      ...props,
      type: props.style,
      onPress: () => {
        props.onPress();

        if (onRequestClose) {
          onRequestClose();
        }
      },
    }))}
    isDesktop={isDesktop}
  >
    <Margin horizontal="md">
      <Margin marginBottom="lg">
        <H2 textAlign="center">{title}</H2>
      </Margin>
      <Margin marginBottom="lg">
        <Body>{description}</Body>
      </Margin>
    </Margin>
  </Modal>
);

export default Alert;
