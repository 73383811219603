import { DashboardTypes } from 'areas/dashboard/constants/dashboardTypes';
import { RootState } from 'store';
import { selectChildren, selectChildById } from './children';

export const selectHasChildrenPayoutPolicies = (state: RootState, policyType: DashboardTypes) => {
  return selectChildren(state)?.some((child) => !!child?.policies?.[policyType]?.length) || false;
};

export const selectChildrenWithPayoutPolicies = (state: RootState, policyType: DashboardTypes) => {
  return selectChildren(state)?.filter((child) => !!child?.policies?.[policyType]?.length);
};

export const selectPayoutPoliciesForChild = (
  state: RootState,
  policyType: DashboardTypes,
  childId: string
) => {
  return selectChildById(state, childId)?.policies?.[policyType];
};

export const selectAvailableChildrenPayoutPolicyGroups = (state: RootState, childId: string) => {
  const childPolicies = selectChildById(state, childId)?.policies;

  return {
    hasRetirement: !!childPolicies?.retirement?.length,
    hasDisability: !!childPolicies?.disability?.length,
    hasDeath: !!childPolicies?.death?.length,
  };
};

export const selectOneTimeChildPayoutPolicies = (
  state: RootState,
  policyType: DashboardTypes,
  childId: string
) =>
  selectPayoutPoliciesForChild(state, policyType, childId)?.filter(
    (item) => item.payoutType === 'Engangsudbetaling'
  );

export const selectOverTimeChildPayoutPolicies = (
  state: RootState,
  policyType: DashboardTypes,
  childId: string
) =>
  selectPayoutPoliciesForChild(state, policyType, childId)?.filter(
    (item) => item.payoutType === 'OphørendeUdbetaling' || item.payoutType === 'LivsvarigUdbetaling'
  );
