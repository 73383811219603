import type { RootState } from 'store';

const { getIsDateTooOld } = require('@sp/ui/helpers/date');

export const selectPensionsInfo = (state: RootState) => state.services?.pensionsInfo;

export const selectIsPensionsInfoUndefinedOrThreeOrMoreMonthsOld = (state: RootState) =>
  getIsDateTooOld({ date: state.services?.pensionsInfo?.pensionsInfoUpdateDate }) ?? true;

export const selectHasPensionsInfo = (state: RootState) =>
  Object.values(selectPensionsInfo(state)).some((value) => value);
