import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { initFunc } from 'helpers/props';
import { withTheme } from 'styled-components/native';
import { Linking } from 'react-native';
import features from 'features';
import { Alert } from '@sp/ui/base/inputs';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import withScreenContainer from 'decorators/withScreenContainer';
import { DashboardRow, RowBorder } from '@sp/ui/base/rows';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import * as routeNames from 'constants/routeNames';
import { selectContactInfo } from 'selectors/profile';
import { getContactInfo } from 'actions/profile';
import { H5 } from '@sp/ui/typography';
import {
  fetchNotificationSettingsForCompanyGroup,
  updateNotificationSettingsForCompanyGroup as updateNotificationSettingsForCompanyGroupAction,
} from 'actions/settings';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { dismiss } from 'helpers/navigation';
import { isWeb } from 'helpers/platform';
import NotificationSection from './NotificationSection';

const NotificationSettings = ({
  i18n,
  theme,
  updateNotificationSettingsForCompanyGroup,
  navigation,
  isDesktop,
  phoneNumber,
  email,
  companyName,
  isModal,
}) => {
  const [emailMissingDialog, setEmailMissingDialog] = useState({
    isOpen: false,
    onSave: undefined,
  });
  const [phoneMissingDialog, setPhoneMissingDialog] = useState({
    isOpen: false,
    onSave: undefined,
  });

  return (
    <ModalProvider isModal={isModal} onClose={dismiss(navigation)}>
      <ScrollPage
        title={i18n.t('Kontaktindstillinger')}
        heading={isDesktop ? i18n.t('Kontaktindstillinger') : undefined}
        hasBackButton={isDesktop || !isModal}
        disableBottomPadding
        testID="settingsContactSettingsScreen"
      >
        {!isWeb && features.isEnabled('pushNotifications') && (
          <>
            <DashboardRow
              textLeft={<H5>{i18n.t('Push-notifikationer')}</H5>}
              iconLeft="notification"
              arrow
              onPress={() => Linking.openSettings()}
            />
            <RowBorder />
          </>
        )}
        <NotificationSection
          type="mail"
          title={i18n.t('E-mail')}
          theme={theme}
          iconLeft="email"
          i18n={i18n}
          companyName={companyName}
          onPress={(key, isEnabled) => {
            if (!email) {
              setEmailMissingDialog({
                isOpen: true,
                onSave: () => updateNotificationSettingsForCompanyGroup(key, isEnabled),
              });
            } else {
              updateNotificationSettingsForCompanyGroup(key, isEnabled);
            }
          }}
        />
        <RowBorder />
        <NotificationSection
          type="phone"
          title={i18n.t('SMS og opkald')}
          theme={theme}
          iconLeft="rowPhone"
          i18n={i18n}
          companyName={companyName}
          onPress={(key, isEnabled) => {
            if (!phoneNumber) {
              setPhoneMissingDialog({
                isOpen: true,
                onSave: () => updateNotificationSettingsForCompanyGroup(key, isEnabled),
              });
            } else {
              updateNotificationSettingsForCompanyGroup(key, isEnabled);
            }
          }}
        />
        <RowBorder />
        <Alert
          onRequestClose={() => setEmailMissingDialog({ isOpen: false })}
          visible={emailMissingDialog.isOpen}
          title={i18n.t('E-mail mangler')}
          description={i18n.t(
            'For at kunne sende notifikationer til dig skal vi bruge din e-mail. Tilføj venligst din e-mail til din profil.'
          )}
          actions={[
            {
              text: i18n.t('Annuller'),
              onPress: () => setEmailMissingDialog({ isOpen: false }),
              style: 'destructive',
            },
            {
              text: i18n.t('Tilføj'),
              onPress: () => {
                navigation.navigate(routeNames.PROFILE_EMAIL_MODAL, {
                  isModal: true,
                  onSave: emailMissingDialog.onSave,
                });
                setEmailMissingDialog({ isOpen: false });
              },
              style: 'primary',
            },
          ]}
        />
        <Alert
          onRequestClose={() => setPhoneMissingDialog({ isOpen: false })}
          visible={phoneMissingDialog.isOpen}
          title={i18n.t('Mobiltelefonnummer mangler')}
          description={i18n.t(
            'For at kunne sende SMS-notifikationer til dig skal vi bruge dit mobiltelefonnummer. Tilføj venligst dit mobiltelefonnummer til din profil.'
          )}
          actions={[
            {
              text: i18n.t('Annuller'),
              onPress: () => setPhoneMissingDialog({ isOpen: false }),
              style: 'destructive',
            },
            {
              text: i18n.t('Tilføj'),
              onPress: () => {
                navigation.navigate(routeNames.PROFILE_TELEPHONE_MODAL, {
                  isModal: true,
                  onSave: phoneMissingDialog.onSave,
                });
                setPhoneMissingDialog({ isOpen: false });
              },
              style: 'primary',
            },
          ]}
        />
      </ScrollPage>
    </ModalProvider>
  );
};

NotificationSettings.propTypes = {
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  updateNotificationSettingsForCompanyGroup: initFunc,
  isDesktop: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  navigation: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired,
  isModal: PropTypes.bool.isRequired,
};

NotificationSettings.defaultProps = {
  updateNotificationSettingsForCompanyGroup: undefined,
  phoneNumber: undefined,
  email: undefined,
};

const mapStateToProps = (state, props) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
  email: selectContactInfo(state).email,
  phoneNumber: selectContactInfo(state).phoneNumber,
  companyGroup: props.route.params?.companyGroup,
  companyName: props.route.params?.companyName,
  isModal: props.route?.params?.isModal || false,
});

const init = ({ action, getProps }) =>
  action(
    async () => {
      const { dispatch, email, phoneNumber, companyGroup } = getProps();

      if (typeof email === 'undefined' || typeof phoneNumber === 'undefined') {
        await dispatch(getContactInfo());
      }

      await dispatch(fetchNotificationSettingsForCompanyGroup(companyGroup));

      return {
        updateNotificationSettingsForCompanyGroup: (key, isEnabled) =>
          dispatch(updateNotificationSettingsForCompanyGroupAction(key, isEnabled, companyGroup)),
      };
    },
    {
      loader: true,
    }
  );

export default withScreenContainer({
  mapStateToProps,
  init,
})(withTheme(NotificationSettings));
