const getYear = (url) => {
  const params = url.split('&');
  const paramYear = params.find((item) => item.match(/year=/g));
  const year = paramYear ? parseInt(paramYear.substr(-4), 10) : undefined;

  if (year) return year;

  /* NOTE: A minor percentage of customers don't have
  a depot account to access. It has been agreed
  with Christian Møller an error will be thrown (15.11.2019) */
  throw Error('Failed to fetch your depot account');
};

export const getLatestDepotAccount = (documents) =>
  documents
    .filter((item) => item.type === 'depot')
    .map((item) => ({
      ...item,
      year: getYear(item.downloadUrl),
    }))
    .sort((a, b) => b.year - a.year)[0];
