import { GET_PENSIONS_INFO, DELETE_USER_PENSIONS_INFO } from 'constants/actionTypes';

const { get, remove } = require('actions/api');

export const getPensionsInfo = () =>
  get(GET_PENSIONS_INFO, 'api', '/pensions/v1/users/me/pensionsinfo', {
    allowFailure: true,
  });

export const deleteUserPensionsInfo = () =>
  remove(DELETE_USER_PENSIONS_INFO, 'api', '/pensions/v1/users/me/pensionsinfo');
