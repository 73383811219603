import { shallowEqual } from 'react-redux';
import { CommonActions, useNavigation } from '@react-navigation/native';
import useAsyncCallback from 'hooks/useAsyncCallback';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  selectHasFetchedPensionsInfo,
  selectHasFetchedTax,
  selectIsOnboarding,
  selectOnDoneRouteConfig,
} from 'selectors/fetchTaxAndPensionsInfo';
import { selectDeeplink } from 'selectors/deeplink';
import fetchTaxAndPensionsInfoController from '../fetchTaxAndPensionsInfoController';
import { useOnFetchFail } from './useOnFetchFail';
import type { RootState } from 'store';
import * as routeNames from 'constants/routeNames';

const { completeInfoFlow } = require('actions/onboarding');

type UpdateFetchingState = {
  hasSkippedAllFetchingActions?: boolean;
  updateFetchingState: (state: any) => void;
};

const selectFinishFlow = (state: RootState) => ({
  isOnboarding: selectIsOnboarding(state),
  onDoneRouteConfig: selectOnDoneRouteConfig(state),
  hasFetchedPensionsInfo: selectHasFetchedPensionsInfo(state),
  hasFetchedTax: selectHasFetchedTax(state),
  deeplink: selectDeeplink(state),
});

export const useFinishFlow = ({ updateFetchingState }: UpdateFetchingState) => {
  const { hasFetchedTax, hasFetchedPensionsInfo, isOnboarding, onDoneRouteConfig, deeplink } =
    useAppSelector(selectFinishFlow, shallowEqual);

  const dispatch = useAppDispatch();
  const onFetchFail = useOnFetchFail();
  const navigation = useNavigation();

  const { deeplinkFlow } = deeplink ?? {};
  const avoidRefetching = deeplinkFlow === routeNames.PARTNER;

  return useAsyncCallback(async () => {
    updateFetchingState({});

    if (isOnboarding) {
      dispatch(completeInfoFlow());
      return null;
    }

    const { getCallbacks, waitForPendingRefetchingPromise } = fetchTaxAndPensionsInfoController;
    const { onDoneCallback, onDoneNavigation, onSkipNavigation } = getCallbacks() ?? {};

    if (hasFetchedTax || hasFetchedPensionsInfo) {
      if (!avoidRefetching) {
        await waitForPendingRefetchingPromise({
          dispatch,
          fetchFail: onFetchFail,
        });
      }

      if (onDoneCallback) {
        onDoneCallback();
      }

      if (onDoneNavigation) {
        onDoneNavigation();
      } else {
        navigation.dispatch(CommonActions.navigate(onDoneRouteConfig));
      }

      return null;
    }

    if (onSkipNavigation) {
      onSkipNavigation();
      return null;
    }

    navigation.dispatch(CommonActions.navigate(onDoneRouteConfig));
    return null;
  }, [
    updateFetchingState,
    dispatch,
    isOnboarding,
    hasFetchedTax,
    hasFetchedPensionsInfo,
    navigation,
    onDoneRouteConfig,
  ]);
};
