import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled, { withTheme } from 'styled-components/native';
import { Dimensions, Platform, TouchableOpacity } from 'react-native';
import { H5 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import Modal from '@sp/ui/layout/Modal';

const ATTACHMENT_PADDING_VERTICAL = 7;
const ATTACHMENT_WIDTH = 280;
const ATTACHMENT_PADDING_LEFT = 13;
const MODAL_HEADER_HEIGHT = 44;

const THUMBNAIL_HEIGHT = 50;
const HORIZONTAL_IMAGE_HEIGHT = 250;
const HORIZONTAL_IMAGE_HEIGHT_DESKTOP = 450;

const isImage = ({ title }) =>
  title.toLowerCase().endsWith('.png') ||
  title.toLowerCase().endsWith('.jpeg') ||
  title.toLowerCase().endsWith('.jpg');

const Wrapper = styled.View``;

const AttachmentWrapperMobileTablet = styled.View`
  flex: 1;
  flex-basis: auto;
`;

const DocumentAttachments = Conditional({
  desktop: styled.View`
    flex-direction: row;
    flex-wrap: wrap;
  `,
  default: Fragment,
});

const AttachmentWrapper = Conditional({
  desktop: styled.View`
    width: ${ATTACHMENT_WIDTH}px;
    margin-right: ${({ isOdd, theme }) => (isOdd ? 0 : theme.SPACINGS.md)}}px;
  `,
  default: AttachmentWrapperMobileTablet,
});

const Attachment = styled.View`
  background-color: ${(props) => props.theme.COLORS.PRIMARY_VARIANT1};
  padding-top: ${ATTACHMENT_PADDING_VERTICAL}px;
  padding-bottom: ${ATTACHMENT_PADDING_VERTICAL}px;
  padding-right: ${({ theme }) => theme.SPACINGS.md}px;
  padding-left: ${ATTACHMENT_PADDING_LEFT}px;
  border-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
  margin-bottom: ${({ theme }) => theme.SPACINGS.md}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.TouchableOpacity``;

const ImagesScrollView = styled.ScrollView``;

const ImagesWrapper = styled.View`
  flex-direction: row;
`;

const ImageThumbnail = styled.Image`
  margin-right: ${({ theme }) => theme.SPACINGS.m}px;
  margin-bottom: ${({ theme }) => theme.SPACINGS.m}px;
  border-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
  height: ${THUMBNAIL_HEIGHT}px;
  width: ${({ width, height }) =>
    width && height ? (width / height) * THUMBNAIL_HEIGHT : THUMBNAIL_HEIGHT}px;
`;

const ModalImage = Conditional({
  default: styled.Image`
    width: 100%;
    height: ${HORIZONTAL_IMAGE_HEIGHT_DESKTOP}px;
  `,
  mobile: styled.Image`
    width: 100%;
    height: ${({ width, height, theme }) =>
      width && height
        ? (height / width) * Math.round(Dimensions.get('window').width - theme.SPACINGS.md * 2)
        : HORIZONTAL_IMAGE_HEIGHT}px;
  `,
});

const ModalImageVertical = styled.Image`
  width: ${({ width, height, theme }) =>
    width && height ? Math.round(Dimensions.get('window').width - theme.SPACINGS.md * 2) : '100%'};
  height: 100%;
`;

const ModalImageWrapper = Conditional({
  default: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
  `,
  mobile: styled.View`
    ${Platform.OS !== 'web' ? `margin-top: -${MODAL_HEADER_HEIGHT}px` : ''};
    width: 100%;
    justify-content: center;
    align-items: center;
  `,
});

const Attachments = ({ attachments, onAttachmentDeletePress, theme, deleteImageText }) => {
  const [isModalOpened, setModalOpened] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(undefined);
  const imageAttachments = [...attachments].reverse().filter((attachment) => isImage(attachment));

  return (
    <Wrapper>
      <ImagesScrollView alwaysBounceVertical={false} horizontal>
        <ImagesWrapper>
          {imageAttachments.map((attachment) => (
            <TouchableOpacity
              key={attachment.id}
              onPress={() => {
                setModalOpened(true);
                setSelectedAttachment(attachment);
              }}
            >
              <ImageThumbnail
                width={attachment.width}
                height={attachment.height}
                source={{ uri: attachment.uri }}
              />
            </TouchableOpacity>
          ))}
        </ImagesWrapper>
      </ImagesScrollView>

      <DocumentAttachments>
        {attachments
          .filter((attachment) => !isImage(attachment))
          .map((attachment, i) => (
            <AttachmentWrapper isOdd={i % 2} key={attachment.id}>
              <Attachment>
                <H5 fontWeight="regular" numberOfLines={1}>
                  {attachment.title}
                </H5>
                <CloseButton onPress={() => onAttachmentDeletePress(attachment.id)}>
                  <Icon
                    name="close"
                    width={theme.SPACINGS.md}
                    height={theme.SPACINGS.md}
                    fill={theme.COLORS.PRIMARY_TEXT}
                  />
                </CloseButton>
              </Attachment>
            </AttachmentWrapper>
          ))}
      </DocumentAttachments>

      <Modal
        visible={isModalOpened}
        onRequestClose={() => {
          setModalOpened(false);
          setSelectedAttachment(undefined);
        }}
        actions={[
          {
            text: deleteImageText,
            type: 'destructive-secondary',
            onPress: () => {
              onAttachmentDeletePress(selectedAttachment && selectedAttachment.id);
              setModalOpened(false);
              setSelectedAttachment(undefined);
            },
            mobilePosition: 'bottom-sticky',
          },
        ]}
        justifyChildren="center"
      >
        <ModalImageWrapper>
          {selectedAttachment &&
            (selectedAttachment.isVertical ? (
              <ModalImageVertical
                source={{ uri: selectedAttachment.uri }}
                width={selectedAttachment.width}
                height={selectedAttachment.height}
                resizeMode="contain"
              />
            ) : (
              <ModalImage
                source={{ uri: selectedAttachment.uri }}
                width={selectedAttachment.width}
                height={selectedAttachment.height}
                resizeMode="contain"
              />
            ))}
        </ModalImageWrapper>
      </Modal>
    </Wrapper>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  onAttachmentDeletePress: PropTypes.func,
  theme: PropTypes.object.isRequired,
  deleteImageText: PropTypes.string,
};

Attachments.defaultProps = {
  onAttachmentDeletePress: undefined,
  deleteImageText: undefined,
};

export default withTheme(Attachments);
