import React from 'react';
import PropTypes from 'prop-types';
import ShowMore from '@sp/ui/layout/ShowMore';
import withTranslation from 'decorators/i18n';

const ShowMoreContainer = ({
  children,
  i18n,
  moreText,
  showMoreElement,
  lessText,
  showLessElement,
}) => (
  <ShowMore
    moreText={moreText || i18n.t('Læs mere')}
    lessText={lessText || i18n.t('Læs mindre')}
    showMoreElement={showMoreElement}
    showLessElement={showLessElement}
  >
    {children}
  </ShowMore>
);

ShowMoreContainer.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: PropTypes.object.isRequired,
  moreText: PropTypes.string,
  lessText: PropTypes.string,
  showMoreElement: PropTypes.node,
  showLessElement: PropTypes.node,
};

ShowMoreContainer.defaultProps = {
  showMoreElement: undefined,
  showLessElement: undefined,
  moreText: undefined,
  lessText: undefined,
};

export default withTranslation()(ShowMoreContainer);
