import * as actionTypes from 'constants/actionTypes';
import { navigate, reset } from 'helpers/navigation';
import startAppFlow from './startAppFlow';
import doLoginFlow from './doLoginFlow';
import doCustomerFlow from './doCustomerFlow';
import doConsentsAndApiStatusFlow from './doConsentsAndApiStatusFlow';
import doInfoFlow from './doInfoFlow';
import completeInfoFlow from './completeInfoFlow';
import prefetchInitialData from './prefetchInitialData';

const navigateOrNext = (result, next, action) => (result ? navigate(result) : next(action));

const resetOrNext = (result, next, action) =>
  result
    ? reset({
        index: 0,
        routes: [result],
      })
    : next(action);

const onboardingMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actionTypes.START_APP:
      return resetOrNext(await startAppFlow(store), next, action);
    case actionTypes.DO_LOGIN_FLOW:
      return navigateOrNext(doLoginFlow(), next, action);
    case actionTypes.DO_CONSENTS_AND_API_STATUS_FLOW:
      return resetOrNext(await doConsentsAndApiStatusFlow(store), next, action);
    case actionTypes.DO_CUSTOMER_FLOW:
      return navigateOrNext(await doCustomerFlow(store), next, action);
    case actionTypes.DO_INFO_FLOW:
      return navigateOrNext(await doInfoFlow(store), next, action);
    case actionTypes.COMPLETE_INFO_FLOW:
      const completeNavigationResult = await completeInfoFlow(store)
      return navigateOrNext(completeNavigationResult, next, action);
    case actionTypes.PREFETCH_INITIAL_DATA:
      prefetchInitialData(store);
      return next(action);
    default:
      return next(action);
  }
};

export default onboardingMiddleware;
