import { Linking, Platform } from 'react-native';
const { default: log } = require('services/log');
import linkingConfig, { APP_SCHEME } from 'linkingConfig';
import { isWeb } from 'helpers/platform';

const SAMPENSION_OFFICE_LAT = 55.72629;
const SAMPENSION_OFFICE_LNG = 12.58103;

export type Target = '_blank' | '_self';

export const openMaps = (lat = SAMPENSION_OFFICE_LAT, lng = SAMPENSION_OFFICE_LNG) => {
  const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
  const latLng = `${lat},${lng}`;
  const label = 'Sampension';
  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
  });

  if (url) {
    Linking.openURL(url);
  }
};

export const openAddressInMaps = (address: string) => {
  const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
  const addressQuery = address.replace(/\s/g, '+');
  const url = Platform.select({
    ios: `${scheme}${addressQuery}`,
    android: `${scheme}${addressQuery}`,
  });

  if (url) {
    Linking.openURL(url);
  }
};

export const writeEmail = (email: string, subject?: string, body?: string) =>
  Linking.openURL(`mailto:${email}?subject=${subject}&body=${body}`);

export const phoneCall = (number: string) => Linking.openURL(`tel:+45${number}`);

export const openLink = async (
  url: string,
  webTarget?: Target,
  popupContext?: { open: (url: string) => void }
) => {
  if (isWeb) {
    if (webTarget === '_self') {
      // NOTE: The reason we use href navigation instead of .open, is to preserve window.opener
      global.location.href = url;
    } else {
      if (popupContext) {
        popupContext?.open(url);
      } else {
        global.open(url, webTarget);
      }
    }
  } else {
    let correctedURL;

    try {
      if (url.startsWith('//')) {
        correctedURL = url;
      } else if (url.startsWith('/')) {
        correctedURL = url.replace('/', APP_SCHEME);
      } else {
        const rewritePart = linkingConfig.prefixes.find((prefix) => url.startsWith(prefix));
        correctedURL = url.replace(`${rewritePart}/`, APP_SCHEME);
      }

      await Linking.canOpenURL(correctedURL);
      Linking.openURL(correctedURL);
    } catch (err) {
      log.warn(`Could not open URI: ${correctedURL}`, err);
    }
  }
};

export const replace = (url: string) => {
  if (isWeb) {
    global.location.replace(url);
  } else {
    openLink(url);
  }
};
