import { selectPrefetchKeys } from 'selectors/discovery';
import { selectIsLoggedIn } from 'selectors/api';
import persistedStorage from 'services/stores/persisted';
import { fetchSavingsOverview } from 'actions/savings';
import { fetchTransferOverview } from 'actions/transfer';
import { fetch as fetchMailbox } from 'actions/mailbox';
import { fetchDashboardData } from 'helpers/dashboard';

const prefetchInitialData = async (store) => {
  const state = store.getState();
  const { dispatch } = store;
  const prefetchKeys = selectPrefetchKeys(state);

  const actions = [];

  const hasCompletedOnboarding = await persistedStorage.getItem('hasCompletedOnboarding');

  const isLoggedIn = selectIsLoggedIn(state);

  if (isLoggedIn && hasCompletedOnboarding) {
    // Dashboard
    if (prefetchKeys.includes('dashboard')) {
      fetchDashboardData(dispatch);
    }

    // Savings overview
    if (prefetchKeys.includes('savings')) {
      actions.push(fetchSavingsOverview());
      actions.push(fetchTransferOverview());
    }

    if (prefetchKeys.includes('mailbox')) {
      actions.push(fetchMailbox('inbox'));
    }
  }

  if (actions.length > 0) {
    dispatch(actions);
  }
};

export default prefetchInitialData;
