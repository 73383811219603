import * as actionTypes from 'constants/actionTypes';
import features from 'features';
import { navigate } from 'helpers/navigation';
import Tracking from 'services/tracking';
import refetchNakedDashboardErrors from './refetchNakedDashboardErrors';
import refetchDataAfterSkatPI from './refetchDataAfterSkatPI';
import navigateToNotificationSettings from './navigateToNotificationSettings';

const sideEffectsMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actionTypes.REFETCH_NAKED_DASHBOARD_ERRORS: {
      refetchNakedDashboardErrors(store, action.payload);
      return next(action);
    }
    case actionTypes.REFETCH_DATA_AFTER_PI_SKAT: {
      await refetchDataAfterSkatPI(store, action.payload);
      return next(action);
    }
    case actionTypes.NAVIGATE_TO_NOTIFICATION_SETTINGS: {
      return navigate(await navigateToNotificationSettings(store, action.payload));
    }
    case actionTypes.RESPONSE_GET_PROFILE: {
      const { customer } = action.payload.response;
      Tracking.setTrackingId(customer.id);
      return next(action);
    }
    case actionTypes.RESPONSE_GET_API_STATUS: {
      if (features.isEnabled('blockFeaturesFromAPI')) {
        const blockedFeatures = action.payload.response?.features?.blockedFeatures.reduce(
          (acc, { name, reason, title }) => {
            acc[name] = {
              reason,
              title,
            };
            return acc;
          },
          {}
        );
        features.setBlockedFeatures(blockedFeatures || {});
      }
      return next(action);
    }
    default:
      return next(action);
  }
};

export default sideEffectsMiddleware;
