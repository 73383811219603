import { useRef, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AppState } from 'react-native';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectIsPersisted } from 'selectors/api';
import useI18n from 'hooks/useI18n';
import GlobalNotificationContext from 'context/GlobalNotificationContext';
import { appLock, logout } from 'actions/security';
import { formatInactiveMessage, DEFAULT_TIMEOUT } from './helpers';

let backgroundTime = new Date().getTime();

const AutoLock = ({ timeout }) => {
  const { i18n } = useI18n();

  const appState = useRef(AppState.currentState);
  const { showNotification } = useContext(GlobalNotificationContext);
  const enrolled = useAppSelector(selectIsPersisted);
  const dispatch = useAppDispatch();

  const handleAppStateChange = useCallback(
    (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        const delta = new Date().getTime() - backgroundTime;
        if (delta > timeout) {
          if (enrolled) {
            dispatch(appLock('inactive', { time: timeout }));
          } else {
            showNotification(formatInactiveMessage(i18n, timeout), {
              type: 'warning',
              id: 'auto-logout',
            });
            dispatch(logout('hard'));
          }
        }
      }
      backgroundTime = new Date().getTime();
      appState.current = nextAppState;
    },
    [dispatch, enrolled, i18n, showNotification, timeout]
  );

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, [handleAppStateChange]);

  return null;
};

AutoLock.propTypes = {
  timeout: PropTypes.number,
};

AutoLock.defaultProps = {
  timeout: DEFAULT_TIMEOUT,
};

export default AutoLock;
