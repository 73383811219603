import React from 'react';
import PropTypes from 'prop-types';
import Tracking from 'services/tracking';
import * as routeNames from 'constants/routeNames';
import TopTabBar from '@sp/ui/layout/TopTabBar';
import useI18n from 'hooks/useI18n';
import { withTheme } from 'styled-components/native';
import { useRoute, useNavigation } from '@react-navigation/native';
import { RETIREMENT_TAB, DISABILITY_TAB, DEATH_TAB } from 'constants/testIds/dashboard';

const TopTabBarContainer = ({ focusedTab, theme }) => {
  const route = useRoute();
  const navigation = useNavigation();
  const { i18n } = useI18n();

  return (
    <TopTabBar
      tabs={[
        {
          testID: RETIREMENT_TAB,
          id: routeNames.RETIREMENT,
          title: i18n.t('common|retirement'),
          onPress: () => {
            Tracking.trackEvent({ area: route.name, action: 'ClickRetirement' });
            navigation.navigate(routeNames.RETIREMENT, { resetToLastTab: true });
          },
          iconName: 'pig',
          backgroundColor: theme.COLORS.PRIMARY_OVERLAY_SOLID,
        },
        {
          testID: DISABILITY_TAB,
          id: routeNames.DISABILITY,
          title: i18n.t('common|disability'),
          onPress: () => {
            Tracking.trackEvent({ area: route.name, action: 'ClickDisability' });
            navigation.navigate(routeNames.DISABILITY, { resetToLastTab: true });
          },
          iconName: 'asterisk',
          backgroundColor: theme.COLORS.PRIMARY_BACKGROUND,
        },
        {
          testID: DEATH_TAB,
          id: routeNames.DEATH,
          onPress: () => {
            Tracking.trackEvent({ area: route.name, action: 'ClickDeath' });
            navigation.navigate(routeNames.DEATH, { resetToLastTab: true });
          },
          title: i18n.t('common|death'),
          iconName: 'heartbeat',
          backgroundColor: theme.COLORS.INACTIVE_GRAPH_INCOME,
        },
      ]}
      focusedTab={focusedTab}
    />
  );
};

TopTabBarContainer.propTypes = {
  focusedTab: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(TopTabBarContainer);
