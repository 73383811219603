import React, { useMemo, useRef, useCallback } from 'react';
import { TextInput } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import Animated, {
  Easing,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import type { CurrentProgressType } from '../../../../../app/src/services/reducers/flowControlReducer';
import { MENU_WIDTH } from '@sp/ui/v2/pages/MenuPage/Menu';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Body2 } = require('@sp/ui/v2/typography');
const { isWeb } = require('@sp/ui/helpers/device');
const { CARD_WIDTH } = require('@sp/ui/v2/pages/BasePage/index.tablet');
const { getCurrentDeviceType } = require('@sp/ui/helpers/device');

const HEIGHT = 12;
const FONT_SIZE = 14;
const FONT_WIDTH_SMALL = 30;
const FONT_WIDTH_LARGE = 42;
const PERCENTAGE_TOP = -3;
const DEFAULT_BEEZIER_CONFIG = {
  duration: 400,
  easing: Easing.bezier(0.25, 0.1, 1, 1),
};
const DEFAULT_SPRING_CONFIG = {
  damping: 18,
  mass: 1,
  stiffness: 120,
  overshootClamping: true,
  restSpeedThreshold: 1,
  restDisplacementThreshold: 1,
};
const BOX_SHADOW = '0px 3px 7px rgba(0, 0, 0, 0.0125)';
const DEFAULT_BAR_BG = 'rgba(255, 255, 255, 0.5)';

interface BarProps {
  progress: CurrentProgressType[];
}

const Wrapper = styled.View<{ isMobileWeb: boolean }>`
  flex: 1;
  background-color: ${({ isMobileWeb, theme }) =>
    isMobileWeb ? theme.COLORS.PRIMARY_INACTIVE : DEFAULT_BAR_BG};
  height: ${HEIGHT}px;
  box-shadow: ${BOX_SHADOW};
  border-top-right-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
  border-bottom-right-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
`;

const Percentage = styled.View`
  top: ${PERCENTAGE_TOP}px;
`;

const RowWrapper = styled.View`
  flex-direction: row;
`;

const Bar: React.FC<BarProps> = ({ progress }) => {
  const inputRef = useRef<TextInput>();
  const borderBottomRightRadius = useSharedValue(0);
  const borderTopRightRadius = useSharedValue(0);
  const currentProgressWidth = useSharedValue(0);
  const animationClock = useSharedValue(0);
  const percentageText = useSharedValue('0 %');

  const { BORDERS, COLORS, SPACINGS, windowDimensions } = useTheme();
  const { width } = windowDimensions;

  const isMobile = getCurrentDeviceType() === 'mobile';
  const isTablet = getCurrentDeviceType() === 'tablet';

  const getBarWidth = useCallback(
    (percentage: number) => {
      const spacingOffset =
        (percentage === 0 ? FONT_WIDTH_SMALL : FONT_WIDTH_LARGE) + SPACINGS.xl * 2;

      if (isMobile) {
        return width - spacingOffset;
      } else if (isTablet) {
        return width - CARD_WIDTH - spacingOffset - SPACINGS.xl;
      }

      return MENU_WIDTH - spacingOffset;
    },
    [SPACINGS, isMobile, isTablet, width]
  );

  const getDerivedWidth = useCallback(() => {
    const currentIndex = Math.max(
      progress.findIndex(({ isSelected }) => isSelected),
      0
    );
    const previousIndex = progress.findIndex(({ isPrevious }) => isPrevious);
    const percentage = Math.floor((100 / (progress.length - 1)) * currentIndex);
    const barWidth = getBarWidth(percentage);
    const previousPercentage =
      previousIndex >= 0 ? Math.floor((100 / (progress.length - 1)) * previousIndex) : 0;

    return {
      currentProgressIndex: currentIndex,
      derivedPercentage: percentage,
      derivedPreviousPercentage: previousPercentage,
      derivedProgressWidth: (barWidth / 100) * percentage,
      derivedPreviousProgressWidth: (barWidth / 100) * previousPercentage,
    };
  }, [progress, getBarWidth]);

  const {
    currentProgressIndex,
    derivedPercentage,
    derivedPreviousPercentage,
    derivedProgressWidth,
    derivedPreviousProgressWidth,
  } = getDerivedWidth();

  const isMobileWeb = useMemo(() => isWeb && isMobile, [isMobile]);

  const fontStyle = useMemo(
    () => ({
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: FONT_SIZE,
      width: derivedPercentage === 0 ? FONT_WIDTH_SMALL : FONT_WIDTH_LARGE,
      color: isMobileWeb ? COLORS.PRIMARY : COLORS.V2_SECONDARY_BACKGROUND,
      borderColor: COLORS.V2_SECONDARY_BACKGROUND,
    }),
    [COLORS.V2_SECONDARY_BACKGROUND, COLORS.PRIMARY, derivedPercentage, isMobileWeb]
  );

  const animatedStyle = useAnimatedStyle(() => ({
    borderTopLeftRadius: BORDERS.sm,
    borderBottomLeftRadius: BORDERS.sm,
    borderBottomRightRadius: borderBottomRightRadius.value,
    borderTopRightRadius: borderTopRightRadius.value,
    maxWidth: currentProgressWidth.value,
    width: '100%',
    height: HEIGHT,
    backgroundColor: isWeb && isMobile ? COLORS.PRIMARY : COLORS.V2_SECONDARY_BACKGROUND,
    boxShadow: BOX_SHADOW,
  }));

  useAnimatedReaction(
    () => derivedProgressWidth,
    () => {
      if (typeof derivedProgressWidth === 'number') {
        animationClock.value = derivedPreviousPercentage;
        currentProgressWidth.value = derivedPreviousProgressWidth;

        const isProgressHundredPercent = currentProgressIndex === progress.length - 1;
        const borderRadiusParams = isProgressHundredPercent ? BORDERS.sm : 0;

        borderTopRightRadius.value = withTiming(borderRadiusParams, { duration: 0 });
        borderBottomRightRadius.value = withTiming(borderRadiusParams, { duration: 0 });
        animationClock.value = withTiming(derivedPercentage, DEFAULT_BEEZIER_CONFIG);
        currentProgressWidth.value = withSpring(derivedProgressWidth, DEFAULT_SPRING_CONFIG);
      }
    },
    [derivedProgressWidth]
  );

  useAnimatedReaction(
    () => animationClock,
    () => {
      if (isWeb) {
        inputRef?.current?.setNativeProps({ text: `${Math.floor(animationClock.value)} %` });
      }
    },
    [animationClock]
  );

  return (
    <RowWrapper>
      <Animated.View style={animatedStyle} />
      <Margin horizontal="m">
        <Percentage>
          {isWeb ? (
            <TextInput
              ref={inputRef as any}
              style={fontStyle as any}
              editable={false}
              defaultValue={percentageText.value}
            />
          ) : (
            <Body2 color={COLORS.V2_SECONDARY_BACKGROUND}>{`${derivedPercentage} %`}</Body2>
          )}
        </Percentage>
      </Margin>
      <Wrapper isMobileWeb={isMobileWeb} />
    </RowWrapper>
  );
};

export default Bar;
