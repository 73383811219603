import styled from 'styled-components/native';
import { SPACINGS } from '@sp/ui/settings';

const getSpacings = (a, b, c) => SPACINGS[a || b || c] || 0;

const Margin = styled.View`
  margin-top: ${({ marginTop, vertical, all }) => getSpacings(marginTop, vertical, all)}px;
  margin-bottom: ${({ marginBottom, vertical, all }) => getSpacings(marginBottom, vertical, all)}px;
  margin-left: ${({ marginLeft, horizontal, all }) => getSpacings(marginLeft, horizontal, all)}px;
  margin-right: ${({ marginRight, horizontal, all }) =>
    getSpacings(marginRight, horizontal, all)}px;
`;

export default Margin;
