import { selectSelectedAppointment } from './index';

const selectAppointmentTopics = (state, appointmentId) => {
  let topics;

  if (!appointmentId) {
    topics = selectSelectedAppointment(state)?.appointment?.topics || [];
  } else {
    const appointments = state.areas.booking.options?.appointments || [];
    const appointment = appointments.find((a) => a.appointmentId === appointmentId);
    topics = appointment?.topics || [];
  }

  return topics.sort((a, b) => (a.displayPriority || 0) - (b.displayPriority || 0));
};

export default selectAppointmentTopics;
