import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from 'react-formed';
import { CodeInput } from '@sp/ui/base/inputs';

const CodeInputContainer = ({ setValue, name, onChangeText, ...props }) => (
  <CodeInput
    {...props}
    name={name}
    onChangeText={(newValue) => {
      if (typeof onChangeText === 'function') {
        onChangeText(setValue, newValue);
      } else {
        setValue(newValue);
      }
    }}
  />
);

CodeInputContainer.propTypes = {
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string,
  onChangeText: PropTypes.func,
};

CodeInputContainer.defaultProps = {
  onChangeText: undefined,
  name: undefined,
};

export default withForm(CodeInputContainer);
