import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Appear } from '@sp/ui/v2/animations';
import { Slide } from '@sp/ui/layout/Swiper';

import DeviceGradient from 'containers/DeviceGradient';

import IconGrid from './IconGrid';

const DeviceWrapper = styled.View`
  flex: 3;
  align-items: center;
`;

const Slide2 = ({ title, description, companyImage, iconUri, startAnimation }) => (
  <Slide heading={title} description={description} image={companyImage}>
    <DeviceWrapper>
      {startAnimation && (
        <Appear animationType="OutExp" duration={2500} delay={100}>
          <DeviceGradient backgroundFill="#222">
            <IconGrid count={22} iconUri={iconUri} />
          </DeviceGradient>
        </Appear>
      )}
    </DeviceWrapper>
  </Slide>
);

Slide2.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startAnimation: PropTypes.bool,
  iconUri: PropTypes.string.isRequired,
  companyImage: PropTypes.any,
};

Slide2.defaultProps = {
  backgroundImage: undefined,
  startAnimation: false,
  companyImage: undefined,
};

export default Slide2;
