import i18n from 'services/i18n';
import Tracking from 'services/tracking';
import navigateToPayoutsScreen from './navigateToPayoutsScreen';
import * as routeNames from 'constants/routeNames';
import * as trackingIds from 'areas/dashboard/trackingIds';
import * as testIds from 'constants/testIds/dashboard';
import features from 'features';

export default ({
  navigation,
  payouts,
  area,
  payoutsRoute,
  awardRulesRoute,
  childrenPayoutsRoute,
  checkForLinkableChildren,
}) => {
  const menuItems = [];

  if (awardRulesRoute) {
    menuItems.push({
      arrow: true,
      iconLeft: 'info',
      textLeft: i18n.t('dashboard|payoutCriteria'),
      testID: testIds.DASHBOARD_PAYOUTS_CRITERIA_MENU,
      onPress: () => {
        Tracking.trackEvent(trackingIds.CLICK_AWARD_RULES, { screen: area });
        navigation.navigate(awardRulesRoute);
      },
    });
  }

  menuItems.push(
    {
      arrow: true,
      iconLeft: 'rowPencil',
      textLeft: i18n.t('dashboard|adjustCalculation'),
      testID: testIds.DASHBOARD_ADJUST_CALCULATION_MENU,
      onPress: () => {
        Tracking.trackEvent(trackingIds.ADJUST_CALCULATION, { screen: area });
        navigation.navigate(routeNames.ADJUST_CALCULATION);
      },
    },
    {
      arrow: true,
      iconLeft: 'rowLongGraph',
      textLeft: i18n.t('dashboard|seeYourPayouts'),
      testID: testIds.DASHBOARD_PAYOUTS_DETAILS_OVERVIEW_MENU,
      onPress: () => {
        Tracking.trackEvent(trackingIds.CLICK_PAYOUTS, { screen: area });
        navigateToPayoutsScreen({
          payouts,
          area,
          payoutsRoute,
          navigation,
        });
      },
    }
  );

  if (features.isEnabled('linkChildren') && childrenPayoutsRoute) {
    menuItems.push({
      arrow: true,
      iconLeft: 'children',
      textLeft: i18n.t('dashboard|payoutsChildren'),
      testID: testIds.DASHBOARD_PAYOUTS_CHILDREN_MENU,
      onPress: () => checkForLinkableChildren({ payoutType: area, recheck: true }),
    });
  }

  menuItems.push(
    {
      arrow: true,
      iconLeft: 'checkboxFilled',
      textLeft: i18n.t('dashboard|aboutCalculationPayout'),
      testID: testIds.DASHBOARD_PAYOUTS_ORIGIN_MENU,
      onPress: () => {
        Tracking.trackEvent(trackingIds.ABOUT_CALCULATIONS, { screen: area });
        navigation.navigate(routeNames.PAYOUTS_ORIGIN, {
          payoutType: area,
        });
      },
    },
    {
      arrow: true,
      iconLeft: 'paragraph',
      textLeft: i18n.t('dashboard|preconditions'),
      testID: testIds.DASHBOARD_PRECONDITIONS_MENU,
      onPress: () => {
        Tracking.trackEvent(trackingIds.CLICK_PRECONDITIONS, { screen: area });
        navigation.navigate(routeNames.PRECONDITIONS_INFO);
      },
    }
  );

  return menuItems;
};
