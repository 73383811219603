import React, { useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import Conditional from 'decorators/conditional';
import { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import Feature from 'containers/base/Feature';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { OneLineRow, TwoLineRow, RowBorder } from '@sp/ui/base/rows';
import Tracking from 'services/tracking';
import { initFunc } from 'helpers/props';
import { isWeb, isAndroid } from 'helpers/platform';
import { getProfileLabel } from 'areas/profile/helpers';
import { selectHasPendingVerifications, selectUserName } from 'selectors/profile';
import { selectHasHealthBenefits } from 'selectors/healthBenefits';
import { getProfile } from 'actions/profile';
import {
  getHasHealthBenefits,
  navigateToHealthBenefits as navigateToHealthBenefitsAction,
} from 'actions/healthBenefits';
import { logout as logoutAction } from 'actions/security';
import GlobalNotificationContext from 'context/GlobalNotificationContext';
import * as routeNames from 'constants/routeNames';
import { MORE_DEPOSITS_BUTTON } from 'constants/testIds/deposits';
import { openLink } from 'helpers/linking';
import { isEmpty } from 'helpers/object';
import { startChangeInvestmentProfileFlow } from 'actions/changeInvestmentProfile';
import { CLICK_SAVINGS_INSURANCE, CLICK_HEALTH_BENEFITS } from 'areas/more/trackingIds';
import featureEnvConfig from 'featureEnvConfig';

const Row = Conditional({
  tablet: TwoLineRow,
  default: OneLineRow,
});

const More = ({
  username,
  logout,
  hasPendingVerifications,
  hasHealthBenefits,
  navigateToChangeInvestmentProfile,
  navigateToHealthBenefits,
  theme,
  i18n,
  route,
}) => {
  const { dismissAll } = useContext(GlobalNotificationContext);
  const navigation = useNavigation();

  return (
    <ScrollPage
      hasBackButton={false}
      title={i18n.t('Mere')}
      testID="moreScreen"
      actions={[
        {
          text: i18n.t('Log ud'),
          type: 'destructive',
          mobilePosition: 'bottom',
          onPress: () => logout(dismissAll),
        },
      ]}
    >
      {featureEnvConfig?.name && (
        <>
          <OneLineRow
            textLeft={`Feature env: ${featureEnvConfig.name}`}
            iconLeft={{
              name: 'rowErrorFilled',
              fill: theme.COLORS.PRIMARY_WARNING,
            }}
          />
          <RowBorder />
        </>
      )}
      <Row
        arrow
        textLeft={getProfileLabel(username, i18n)}
        onPress={() => {
          Tracking.trackEvent({ area: route.name, action: 'ClickProfile' });
          navigation.navigate(routeNames.PROFILE);
        }}
        iconLeft="profile"
        iconRight={
          hasPendingVerifications
            ? {
                name: 'rowErrorFilled',
                fill: theme.COLORS.PRIMARY_ALERT,
              }
            : undefined
        }
        testID="moreProfileLink"
      />
      <RowBorder />
      <Feature name="booking">
        <Row
          arrow
          textLeft={i18n.t('bookingFeature')}
          onPress={() => {
            Tracking.trackEvent({ area: route.name, action: 'ClickBookMeeting' });
            navigation.navigate(routeNames.BOOKING);
          }}
          iconLeft="calendar"
        />
        <RowBorder />
      </Feature>
      <Feature name="meetingPreparation">
        <Row
          arrow
          textLeft={i18n.t('meetingPreparationFeature')}
          onPress={() => {
            Tracking.trackEvent({ area: route.name, action: 'ClickMeetingPreparation' });
            navigation.navigate(routeNames.MEETING_PREPARATION);
          }}
          iconLeft="calendar"
        />
        <RowBorder />
      </Feature>
      <Feature name="changeInvestmentProfile">
        <>
          <Row
            arrow
            textLeft={i18n.t('changeInvestmentProfileFeature')}
            onPress={() => {
              Tracking.trackEvent({ area: route.name, action: 'ClickChangeInvestmentProfile' });
              navigateToChangeInvestmentProfile();
            }}
            iconLeft="weight"
            testID="moreChangeInvestmentProfileButton"
          />
          <RowBorder />
        </>
      </Feature>
      <Feature name="deposits">
        <Row
          arrow
          textLeft={i18n.t('depositsFeature')}
          onPress={() => {
            Tracking.trackEvent({ area: route.name, action: 'ClickDeposits' });
            navigation.navigate(routeNames.DEPOSITS, {
              screen: routeNames.DEPOSITS_SAVINGS,
            });
          }}
          iconLeft="rowMoneyBag"
          testID={MORE_DEPOSITS_BUTTON}
        />
        <RowBorder />
      </Feature>
      <Feature name="savingsInsurance">
        <Row
          arrow
          textLeft={i18n.t('savingsInsuranceFeature')}
          onPress={() => {
            Tracking.trackEvent(CLICK_SAVINGS_INSURANCE);
            navigation.navigate(routeNames.MORE, {
              screen: routeNames.CLASSIC,
              params: {
                url: i18n.t('link|savingsInsurance'),
                key: new Date().getTime().toString(),
                title: i18n.t('savingsInsuranceFeature'),
              },
            });
          }}
          iconLeft="piggyBank"
          testID="moreSavingsInsuranceButton"
        />
        <RowBorder />
      </Feature>
      <Feature name="healthBenefits">
        {hasHealthBenefits && (
          <>
            <Row
              arrow
              textLeft={i18n.t('healthBenefitsFeature')}
              onPress={() => {
                Tracking.trackEvent(CLICK_HEALTH_BENEFITS);
                navigateToHealthBenefits();
              }}
              iconLeft="handheart"
              testID="moreHealthBenefitsButton"
            />
            <RowBorder />
          </>
        )}
      </Feature>
      <Row
        arrow
        textLeft={i18n.t('documentsFeature')}
        onPress={() => {
          Tracking.trackEvent({ area: route.name, action: 'ClickDocuments' });
          navigation.navigate(routeNames.DOCUMENTS);
        }}
        iconLeft="rowFolder"
        testID="documentsLink"
      />
      <RowBorder />
      <Feature name="classicLogin">
        <Row
          arrow
          onPress={() => {
            navigation.navigate(routeNames.MORE, {
              screen: routeNames.CLASSIC,
              params: {
                url: i18n.t('link|classicLogin'),
                key: new Date().getTime(),
                title: i18n.t('classicLoginFeature'),
              },
            });
          }}
          textLeft={i18n.t('classicLoginFeature')}
          iconLeft="rowFolder"
          testID="moreNextOfKinLink"
        />
        <RowBorder />
      </Feature>
      <Feature name="feedback">
        <Row
          arrow
          onPress={() => {
            Tracking.trackEvent({ area: route.name, action: 'ClickFeedback' });
            navigation.navigate(routeNames.FEEDBACK);
          }}
          textLeft={i18n.t('feedbackTitle')}
          iconLeft="rowChat"
          testID="moreFeedbackLink"
        />
      </Feature>
      <RowBorder />
      <Row
        arrow
        onPress={() => {
          Tracking.trackEvent({ area: route.name, action: 'ClickContact' });
          navigation.navigate(routeNames.MODAL_CMS_STACK, {
            id: 'contactoverview',
          });
        }}
        textLeft={i18n.t('contactTitle')}
        iconLeft="rowPhone"
      />
      <RowBorder />
      {isWeb && (
        <>
          <Row
            arrow
            iconLeft="rowLogin"
            onPress={() => {
              Tracking.trackEvent({ area: route.name, action: 'ClickOldLogin' });
              openLink(i18n.t('link|oldLogin'), '_self');
            }}
            textLeft={i18n.t('oldLogin')}
          />
          <RowBorder />
        </>
      )}
      <Row
        arrow
        textLeft={i18n.t('settingsFeature')}
        testID="profileSettingsLink"
        iconLeft="settings"
        onPress={() => {
          Tracking.trackEvent({ area: route.name, action: 'ClickSettings' });
          navigation.navigate(routeNames.SETTINGS);
        }}
      />
      <RowBorder />
    </ScrollPage>
  );
};

More.propTypes = {
  logout: initFunc,
  route: PropTypes.object.isRequired,
  username: PropTypes.string,
  hasPendingVerifications: PropTypes.bool.isRequired,
  hasHealthBenefits: PropTypes.bool,
  navigateToChangeInvestmentProfile: initFunc,
  navigateToHealthBenefits: initFunc,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

More.defaultProps = {
  username: undefined,
  logout: undefined,
  hasHealthBenefits: false,
  navigateToChangeInvestmentProfile: undefined,
  navigateToHealthBenefits: undefined,
};

const mapStateToProps = (state, props) => ({
  ...props,
  enrolled: state.services.security.enrolled,
  username: selectUserName(state),
  hasPendingVerifications: selectHasPendingVerifications(state),
  hasHealthBenefits: selectHasHealthBenefits(state),
});

const init =
  ({ action, props }) =>
  async () => {
    const { dispatch, route, username, hasHealthBenefits } = props;

    // TODO: make profile and health benefits call in parallel
    if (!hasHealthBenefits) {
      await action(async () => dispatch(getHasHealthBenefits()), { loader: true });
    }

    if (isEmpty(username)) {
      await action(async () => dispatch(getProfile()), {
        error: {
          type: 'overlay',
          retry: true,
        },
        loader: true,
      });
    }

    return {
      logout: (dismissAllNotifications) =>
        action(
          async () => {
            Tracking.trackEvent({ area: route.name, action: 'Logout' });
            dismissAllNotifications();
            await dispatch(logoutAction(isWeb || isAndroid ? 'hard' : 'soft'));
          },
          { loader: true }
        ),
      navigateToChangeInvestmentProfile: () =>
        action(async () => dispatch(startChangeInvestmentProfileFlow()), { loader: true }),
      navigateToHealthBenefits: () =>
        action(async () => dispatch(navigateToHealthBenefitsAction()), { loader: true }),
    };
  };

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['link'],
  init,
})(withTheme(More));
