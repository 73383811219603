import type { RootState } from 'store';

export const selectChildrenData = (state: RootState) => state.areas.dashboard?.childrenData;

export const selectChildren = (state: RootState) => selectChildrenData(state)?.children;

export const selectAllowResetChildren = (state: RootState) =>
  selectChildrenData(state)?.allowResetChildren;

export const selectHasChildren = (state: RootState) => {
  const children = selectChildren(state);

  return children && children?.length > 0;
};

export const selectHasLinkableChildren = (state: RootState) => {
  const linkableChildren = selectChildren(state)?.filter((child) => child.isLinkable) ?? [];
  const hasChildrenToLinkWith = selectChildren(state)?.filter((child) => !child.isLinkable) ?? [];

  return linkableChildren?.length > 0 && hasChildrenToLinkWith?.length > 0;
};

export const selectHasOneChild = (state: RootState) => selectChildren(state)?.length === 1;

export const selectChildById = (state: RootState, childId?: string) => {
  if (!childId) return undefined;
  return selectChildren(state)?.find(({ id }) => id === childId);
};
