import React, { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { shallowEqual } from 'react-redux';
import { useTheme } from 'styled-components/native';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import Icon from '@sp/ui/v2/base/Icon';
import { trackPublicServices } from 'areas/dashboard/trackingIds';
import DeselectorSwitch from './DeselectorSwitch';
import { selectAllPublicServiceBenefitsDisabled } from 'selectors/dashboardForms';
import { useUpdateCalculationProfile } from '../hooks';
import AdjustCalculationAlert from '../components/AdjustCalculationAlert';
import type { RootState } from 'store';

const { default: Row } = require('@sp/ui/v2/base/rows');
const { selectIsDeviceTypeDesktop } = require('selectors/device');
const { clearForms } = require('actions/digital-advice');

type Props = {
  hasSelectedPublicService: boolean;
  setShouldOpenOrCloseBottomSheet: Dispatch<SetStateAction<Record<string, boolean>>>;
};

const allPublicServicesDisabledExceptLivingAbroad = {
  hasElderCheque: false,
  hasFolkPension: false,
  hasFolkPensionSupplement: false,
  hasChildAndYouthBenefit: false,
};

const allPublicServicesEnabledExceptLivingAbroad = {
  hasElderCheque: true,
  hasFolkPension: true,
  hasFolkPensionSupplement: true,
  hasChildAndYouthBenefit: true,
};

const deviceAndProfileSelector = (state: RootState) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
  areAllPublicServiceBenefitsDisabled: selectAllPublicServiceBenefitsDisabled(state),
});

const DeselectorRow: React.FC<Props> = ({
  hasSelectedPublicService,
  setShouldOpenOrCloseBottomSheet,
}) => {
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { COLORS } = useTheme();
  const { i18n } = useI18n(['dashboard']);
  const { isDesktop, areAllPublicServiceBenefitsDisabled } = useAppSelector(
    (state) => deviceAndProfileSelector(state),
    shallowEqual
  );
  const [isSwitchEnabled, setIsSwitchEnabled] = useState<boolean>(
    areAllPublicServiceBenefitsDisabled
  );
  const toggleSwitch = () => setIsSwitchEnabled((previousState) => !previousState);
  // We derive the logic of the switch with useEffect for a better UX
  // with the switch de-selecting on change
  useEffect(() => {
    setIsSwitchEnabled(areAllPublicServiceBenefitsDisabled);
  }, [areAllPublicServiceBenefitsDisabled]);

  const [updateCalculationProfile] = useUpdateCalculationProfile(false);

  const updateProfile = useCallback(() => {
    if (areAllPublicServiceBenefitsDisabled) {
      updateCalculationProfile(allPublicServicesEnabledExceptLivingAbroad);
    } else {
      updateCalculationProfile(allPublicServicesDisabledExceptLivingAbroad);
    }
  }, [areAllPublicServiceBenefitsDisabled, updateCalculationProfile]);

  return (
    <>
      <Row
        activeOpacity={1}
        hasBorderRadius={false}
        hasRowBorder
        backgroundColor={isDesktop ? '' : COLORS.V2_SECONDARY_SELECTED}
        fill={COLORS.V2_PRIMARY_BACKGROUND}
        textLeft={i18n.t('dashboard|deselectAllPublicBenefits')}
        componentLeft={<Icon fill={COLORS.V2_PRIMARY_BACKGROUND} name="settings" />}
        componentRight={
          <DeselectorSwitch
            isSwitchEnabled={isSwitchEnabled}
            onPress={() => {
              setShouldOpenOrCloseBottomSheet({
                shouldCloseBottomSheetIfOpen: true,
                shouldOpenBottomSheetIfClosed: false,
              });
              trackPublicServices(
                areAllPublicServiceBenefitsDisabled
                  ? 'areAllPublicServiceBenefitsDisabledNo'
                  : 'areAllPublicServiceBenefitsDisabledYes'
              );
              toggleSwitch();
              setIsAlertModalVisible(true);
            }}
          />
        }
      />
      <AdjustCalculationAlert
        isVisible={isAlertModalVisible}
        title={i18n.t('dashboard|publicServicesAlertTitle')}
        description={
          isSwitchEnabled
            ? i18n.t('dashboard|publicServicesAlertDescriptionDisable')
            : i18n.t('dashboard|publicServicesAlertDescriptionEnable')
        }
        onRequestClose={() => setIsAlertModalVisible(false)}
        actions={[
          {
            text: i18n.t('yes'),
            style: 'primary',
            onPress: () => {
              updateProfile();
              dispatch(clearForms());
            },
          },
          {
            text: i18n.t('no'),
            style: 'secondary',
            onPress: () => {
              if (hasSelectedPublicService) {
                setShouldOpenOrCloseBottomSheet({
                  shouldCloseBottomSheetIfOpen: false,
                  shouldOpenBottomSheetIfClosed: true,
                });
              }
              toggleSwitch();
              dispatch(clearForms());
            },
          },
        ]}
      />
    </>
  );
};

export default DeselectorRow;
