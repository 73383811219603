import Conditional from 'decorators/conditional';
import Mobile from './index.mobile';
import Tablet from './index.tablet';
import Desktop from './index.desktop';

export default Conditional({
  desktop: Desktop,
  mobile: Mobile,
  tablet: Tablet,
});
