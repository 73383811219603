import React from 'react';
import MenuPage, { BackNavigationType } from 'containers/v2/pages/MenuPage/index';
import { useAppSelector } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import { useGetProgress } from 'areas/meetingPreparation/hooks/useGetProgress';
import { ActionProps } from 'typings/global';

import { useSelectBookingTimeslot } from 'areas/meetingPreparation/hooks/useSelectBookingTimeslot';
import { selectIsMeetingPreparationFlow } from 'selectors/flowControl';

// Menupage container V2 is curently used in Meeting Preparation flow
// TODO: Implement Menupage container V2 to partner flow and delete V1

type PageProps = {
  children: React.ReactNode;
  backButtonRoute?: BackNavigationType;
  title?: string;
  actions: ActionProps;
  actionsContent?: React.ReactNode;
  titleContent?: React.ReactNode;
  hasMenuAnimation?: boolean;
  hasBackButton?: boolean;
};

const MenuPageContainer: React.FC<PageProps> = ({
  children,
  actions,
  backButtonRoute,
  title,
  actionsContent,
  titleContent,
  hasMenuAnimation = false,
  hasBackButton = true,
}) => {
  const isMeetingPreparationFlow = useAppSelector(selectIsMeetingPreparationFlow);
  const { i18n } = useI18n(['meetingPreparation']);
  const booking = useSelectBookingTimeslot();
  const getProgress = useGetProgress();

  return (
    <MenuPage
      titleContent={titleContent}
      backButtonRoute={backButtonRoute}
      hasBackButton={hasBackButton}
      title={title}
      showMenuHeader
      menuTitle={i18n.t('meetingPreparation|progressbarTitle')}
      menuSubtitle={
        booking.date && booking.timeslot
          ? i18n.t('meetingPreparation|progressbarMeetingInfo', {
              date: booking.date,
              timeslot: booking.timeslot,
            })
          : ''
      }
      progress={isMeetingPreparationFlow ? getProgress() : undefined}
      actions={actions}
      hasMenuAnimation={hasMenuAnimation}
      actionsContent={actionsContent}
      hideLogo={isMeetingPreparationFlow}
    >
      {children}
    </MenuPage>
  );
};

export default MenuPageContainer;
