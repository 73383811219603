import { Middleware, AnyAction } from 'redux';

const routeNames = require('constants/routeNames');
const { navigate } = require('helpers/navigation');
const { NAVIGATE_TO_HEALTH_BENEFITS } = require('constants/actionTypes');
import { getHealthBenefits } from 'actions/healthBenefits';
import { selectHealthBenefits } from 'selectors/healthBenefits';

const middleware: Middleware = (store) => {
  return (next) => async (action: AnyAction) => {
    if (action.type === NAVIGATE_TO_HEALTH_BENEFITS) {
      const { dispatch } = store;
      await dispatch(getHealthBenefits());
      const state = store.getState();

      const benefits = selectHealthBenefits(state);

      if (!benefits || benefits?.length === 0) {
        throw new Error('No health benefits in response');
      } else if (benefits?.length === 1) {
        navigate(routeNames.HEALTH_BENEFITS_DETAILS, { name: benefits[0].name });
      } else {
        navigate(routeNames.HEALTH_BENEFITS);
      }
    }

    return next(action);
  };
};

export default middleware;
