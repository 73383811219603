import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppSelector } from 'hooks/redux';
import { selectFirstChangelogFeature } from 'selectors/changelog';
import { ONBOARDING_COMPLETED_ROUTE } from 'services/onboarding/constants';
import features from 'features';

import BookingIntro from './BookingIntro';

const Changelog = ({ navigation }) => {
  const feature = useAppSelector(selectFirstChangelogFeature);

  useEffect(() => {
    if (!feature || !features.isEnabled(feature)) {
      navigation.navigate(ONBOARDING_COMPLETED_ROUTE);
    }
  }, [feature, navigation]);

  switch (feature) {
    case 'booking':
      return <BookingIntro navigation={navigation} />;
    default:
      return null;
  }
};

Changelog.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default Changelog;
