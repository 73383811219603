import React from 'react';
import PropTypes from 'prop-types';
import Tracking from 'services/tracking';
import withScreenContainer from 'decorators/withScreenContainer';
import { getServiceUrl, getAccessToken } from 'actions/api';
import FlexPage from '@sp/ui/pages/FlexPage';
import { BackButton } from '@sp/ui/pages/BasePage';
import { EmptyFlatList } from '@sp/ui/base/lists';
import { getFolders, pushPreviousFolder, popPreviousFolder } from 'actions/documents';
import { selectContents, selectHasLoaded, selectFolderHistory } from 'selectors/documents';
import * as routeNames from 'constants/routeNames';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { downloadAndOpenOnWeb, downloadAndSaveOnWeb } from 'helpers/documents';
import PoliceInformationRow from 'areas/general/PoliceInformationRow';
import { isFirefox } from 'helpers/browser';
import { isWeb } from 'helpers/platform';
import { DOCUMENT_CLICK } from 'areas/documents/trackingIds';

import DocumentList from './DocumentList';

const Browser = ({
  title,
  folders,
  documents,
  police,
  companyName,
  showFolder,
  showDocument,
  showPreviousFolder,
  folderHistory,
  isRootFolder,
  isDesktop,
  navigation,
  loading,
  policeId,
  i18n,
}) => {
  const isEmpty = !loading && documents.length === 0 && folders.length === 0;

  const shouldShowFolderHeader = isRootFolder || documents.length > 0;
  // NOTE: we want to show the folder name as title when we are in a nested police folder
  const pageTitle = isRootFolder || !title ? i18n.t('documentsBrowserTitle') : title;

  // NOTE: we don't show the page title and description on mobile
  const heading = isDesktop ? i18n.t('documentsBrowserTitle') : undefined;
  const description = isDesktop ? i18n.t('documentsBrowserDescription') : undefined;

  return (
    <FlexPage
      title={pageTitle}
      heading={heading}
      description={description}
      disableBottomPadding
      hasBackButton={!isDesktop || folderHistory.length > 0}
      backButton={
        <BackButton
          label={i18n.t('back')}
          onPress={() => {
            if (folderHistory.length > 0) {
              showPreviousFolder(folderHistory);
            } else {
              navigation.popToTop();
            }
          }}
        />
      }
      testID="documentsBrowserScreen"
    >
      {shouldShowFolderHeader && <PoliceInformationRow text={`${companyName}: ${police}`} />}
      {!isEmpty ? (
        <DocumentList
          folders={folders}
          documents={documents}
          isInPoliceFolder={isRootFolder}
          showFolder={(pId) => {
            showFolder(pId, policeId);
          }}
          showDocument={showDocument}
        />
      ) : (
        <EmptyFlatList text={i18n.t('documentsEmptyBrowserMsg')} isFetching={false} />
      )}
    </FlexPage>
  );
};

Browser.propTypes = {
  title: PropTypes.string,
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      police: PropTypes.string.isRequired,
    })
  ),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      downloadUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  police: PropTypes.string,
  companyName: PropTypes.string,
  showFolder: PropTypes.func,
  showDocument: PropTypes.func,
  showPreviousFolder: PropTypes.func,
  folderHistory: PropTypes.arrayOf(PropTypes.string),
  navigation: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  policeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  isRootFolder: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
};

Browser.defaultProps = {
  folders: [],
  documents: [],
  police: undefined,
  companyName: undefined,
  showFolder: undefined,
  showDocument: undefined,
  showPreviousFolder: undefined,
  folderHistory: [],
  policeId: undefined,
  title: undefined,
  isRootFolder: false,
};

const mapStateToProps = (state, props) => {
  const policeId = props.route.params?.policeId;
  const { folders, documents, companyName, police, title, isRootFolder } = selectContents(
    state,
    policeId
  );

  return {
    title,
    folders,
    documents,
    companyName,
    police,
    hasLoaded: selectHasLoaded(state),
    loading: state.areas.documents.loading,
    isDesktop: selectIsDeviceTypeDesktop(state),
    folderHistory: selectFolderHistory(state),
    policeId,
    isRootFolder,
  };
};

const init = ({ action, getProps }) =>
  action(
    async () => {
      const { dispatch, navigation, hasLoaded } = getProps();

      const apiUrl = await dispatch(getServiceUrl('api'));

      if (!hasLoaded) {
        await dispatch(getFolders());
      }

      return {
        showFolder: (policeId, prevPoliceId) => {
          dispatch(pushPreviousFolder(prevPoliceId));
          navigation.push(routeNames.DOCUMENTS, { policeId });
        },
        showPreviousFolder: (folderHistory) => {
          dispatch(popPreviousFolder());
          navigation.setParams({ policyId: folderHistory[folderHistory.length - 1] });
          navigation.pop();
        },
        showDocument: (url, title) =>
          action(
            async () => {
              Tracking.trackEvent(DOCUMENT_CLICK, {
                title,
              });

              if (!url) {
                return;
              }

              const absoluteUrl = `${apiUrl}${url}`;

              if (isWeb) {
                const token = await dispatch(getAccessToken());
                if (isFirefox()) {
                  await downloadAndSaveOnWeb(absoluteUrl, token, title);
                } else {
                  const win = global.open(undefined, '_blank');
                  win.location.href = `${global.location.protocol}//${global.location.host}/${routeNames.LOADING}?preventAppStart=true`;
                  downloadAndOpenOnWeb(absoluteUrl, token, win);
                }
              } else {
                navigation.navigate(routeNames.DOCUMENT_VIEW, {
                  url: encodeURIComponent(absoluteUrl),
                  title: encodeURIComponent(title),
                });
              }
            },
            {
              loader: true,
            }
          ),
      };
    },
    {
      loader: true,
      error: {
        type: 'overlay',
        retry: true,
      },
    }
  );

export default withScreenContainer({
  overlayScreenProps: {
    titleKey: 'documentsBrowserTitle',
  },
  mapStateToProps,
  init,
})(Browser);
