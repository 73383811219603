import { LogBox } from 'react-native';
import tracking from 'services/tracking';
import config from 'config';
import setup from './setup';

const LEVELS = {
  error: 5,
  warn: 4,
  info: 3,
  debug: 2,
  verbose: 1,
  all: 0,
};

export class LogHandler {
  constructor({ disablePassThrough = false } = {}) {
    this.disablePassThrough = disablePassThrough;
  }

  subscribers = [];
  eventSubscribers = {};

  subscribe(level, fn) {
    this.subscribers.push({
      level: LEVELS[level],
      fn,
    });
  }

  unsubscribe(fn) {
    this.subscribers = this.subscribers.filter((l) => l.fn !== fn);
  }

  on(eventName, fn) {
    if (!this.eventSubscribers[eventName]) {
      this.eventSubscribers[eventName] = [];
    }
    this.eventSubscribers[eventName].push(fn);
  }

  off(eventName, fn) {
    if (this.eventSubscribers[eventName]) {
      this.eventSubscribers[eventName] = this.eventSubscribers[eventName].filter((e) => e !== fn);
    }
  }

  emit(eventName, options = {}) {
    if (this.eventSubscribers[eventName]) {
      this.eventSubscribers[eventName].forEach((fn) => fn(options));
    }
  }

  reset(options) {
    this.emit('reset', options);
  }

  close(message) {
    this.emit('close', message);
  }

  show(level, message, error, options) {
    this.subscribers.forEach((subscriber) => {
      if (LEVELS[level] >= subscriber.level) {
        subscriber.fn(message, error, {
          ...options,
          level,
        });
      }
    });
  }

  error(message, error, retry) {
    this.show('error', message, error, { retry });
    tracking.trackError(error);
    if (!this.disablePassThrough) {
      console.error(error); // eslint-disable-line no-console
    }
  }

  warn(message, error) {
    this.show('warn', message, error);
    if (!this.disablePassThrough) {
      console.warn(message, error); // eslint-disable-line no-console
    }
  }

  info(message, error) {
    this.show('info', message, error);
    if (!this.disablePassThrough) {
      console.log(message, error); // eslint-disable-line no-console
    }
  }

  debug(message, error) {
    this.show('debug', message, error);
    if (!this.disablePassThrough) {
      console.debug(message, error); // eslint-disable-line no-console
    }
  }

  verbose(message, error) {
    this.show('verbose', message, error);
    if (!this.disablePassThrough) {
      console.debug(message, error); // eslint-disable-line no-console
    }
  }

  showUnhandled(error) {
    this.error('Ups der er sket en fejl', error);
  }
}

const defaultInstance = new LogHandler();

if (!config.isDev) {
  LogBox.ignoreAllLogs(true);
}

setup(defaultInstance);

export default defaultInstance;
