import { numberWithSeperator, formatMoney, formatDate } from 'helpers/string';
import currencySymbol from 'helpers/currencySymbol';
import { getPolicyDetails } from './getPolicyDetails';
import { SAVINGS_RETURNS, SAVINGS_COSTS, SAVINGS_PAYMENTS } from 'constants/routeNames';
import { getSavingsAccessibility } from './getSavingsAccessibility';

export const getPolicyDetailsOverview = (savingsData, pID, navigation, i18n) => {
  const {
    policyId,
    policyReturn,
    policyInterestRate,
    policyValue,
    expenses,
    rates,
    latestAdminCosts,
    hasAnnualCosts,
    latestDepositStatementDate,
    allDeposits,
    latestDepositValue,
    latestDepositDate,
    title,
  } = getPolicyDetails(savingsData, pID);

  const latestAdminCostsContent =
    latestAdminCosts || hasAnnualCosts
      ? i18n.t('savings|administrationPerMonth')
      : i18n.t('savings|basisAdministrationIsPayedByAnother');

  const yearToDateReturn =
    policyReturn?.yearToDate && `${numberWithSeperator(policyReturn.yearToDate, 2)} %`;
  const formattedValue = policyValue?.amount
    ? `${formatMoney(policyValue, true)}${currencySymbol(policyValue?.currency?.alphaCode)}`
    : '-';

  const accessibility = getSavingsAccessibility({
    components: ['PolicyValue', 'PolicyReturn', 'PolicyCosts', 'PolicyPayments'],
    props: {
      policyName: title,
      policyId,
      policyValue: formattedValue,
      policyReturnAmount: yearToDateReturn,
      policyInterestRateAmount: policyInterestRate,
      policyLatestAdminCosts: latestAdminCosts,
      policyLatestDepositPayment: latestDepositValue,
      policyLatestDepositDate: latestDepositDate,
      policyStatementDate: latestDepositStatementDate,
      hasAnnualCosts,
    },
  });

  return [
    {
      isAvailable: true,
      accessibility: accessibility.PolicyValue,
      isIconTransparent: true,
      textLeft: i18n.t('savings|savings'),
      textRight: formattedValue,
      subtitleLeft: i18n.t('savings|dateStatement', { statement: latestDepositStatementDate }),
    },
    {
      isAvailable: policyReturn || rates,
      accessibility: accessibility.PolicyReturn,
      isIconTransparent: !yearToDateReturn,
      textLeft: yearToDateReturn ? i18n.t('savings|return') : i18n.t('savings|deposit'),
      textRight: yearToDateReturn || policyInterestRate,
      subtitleLeft: yearToDateReturn
        ? i18n.t('savings|yearToDate', {
            latestDepositStatementDate,
          })
        : i18n.t('savings|dateStatement', {
            statement: rates?.validFrom && formatDate(rates?.validFrom),
          }),
      onPress: yearToDateReturn ? () => navigation.navigate(SAVINGS_RETURNS, { pID }) : undefined,
    },
    {
      isAvailable: expenses,
      accessibility: accessibility.PolicyCosts,
      isIconTransparent: !hasAnnualCosts,
      textLeft: i18n.t('savings|costs'),
      textRight: latestAdminCosts,
      subtitleLeft: latestAdminCostsContent,
      onPress: hasAnnualCosts ? () => navigation.navigate(SAVINGS_COSTS, { pID }) : undefined,
    },
    {
      isAvailable: allDeposits?.[0]?.deposits?.length > 0,
      accessibility: accessibility.PolicyPayments,
      isIconTransparent: false,
      textLeft: i18n.t('savings|payments'),
      textRight: latestDepositValue || '-',
      subtitleLeft: latestDepositDate,
      onPress: () => navigation.navigate(SAVINGS_PAYMENTS, { pID }),
    },
  ].filter(({ isAvailable }) => isAvailable);
};
