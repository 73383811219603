import React from 'react';
import { Platform } from 'react-native';
import Svg from 'react-native-svg';

const FILL_DEFAULT = '#000';
const FILL_RULE_DEFAULT = 'evenodd';
const VIEW_BOX_DEFAULT = '0 0 100 100';

export type Props = {
  svgs: Record<string, any>;
  height: number | string;
  width: number | string;
  name?: string;
  fill?: string;
  fillRule?: string;
  stroke?: string;
  strokeWidth?: number | string;
  style?: any[] | Record<string, any>;
  viewBox?: string;
  testID?: string;
};

const SvgIcon: React.FC<Props> = ({
  name,
  svgs,
  viewBox,
  height,
  width,
  fill,
  strokeWidth,
  fillRule,
  stroke,
  style,
  testID,
}) => {
  const fillSetup = fill ?? FILL_DEFAULT;
  const fillRuleSetup = fillRule ?? FILL_RULE_DEFAULT;
  const viewBoxSetup = viewBox ?? VIEW_BOX_DEFAULT;

  if (!name) {
    return null;
  }

  const iconEl = svgs[`${name}.${Platform.OS}`] || svgs[name];

  if (!iconEl) {
    return null;
  }

  const heightStr = height && height.toString();
  const widthStr = width && width.toString();
  const strokeWidthStr = strokeWidth && strokeWidth.toString();

  const isSimple = React.isValidElement(iconEl);
  const svgEl = isSimple ? iconEl : iconEl.svg;
  return (
    <Svg
      height={heightStr}
      width={widthStr}
      viewBox={!isSimple && iconEl.viewBox ? iconEl.viewBox : viewBoxSetup}
      style={style}
      testID={testID}
    >
      {React.cloneElement(svgEl, {
        fill: fillSetup,
        fillRule: fillRuleSetup,
        stroke,
        strokeWidthStr,
      })}
    </Svg>
  );
};

export default SvgIcon;
