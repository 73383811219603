import useAsyncCallback from 'hooks/useAsyncCallback';
import { useStore } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import { selectCalculationSettings } from 'selectors/dashboard';
import { updateCalculationProfile } from 'actions/calculations';

export const useUpdateRetirementAge = () => {
  const dispatch = useAppDispatch();
  const store = useStore();

  return useAsyncCallback(
    async (retirementAge: number) => {
      const currentCalculationProfile = selectCalculationSettings(store.getState());

      if (currentCalculationProfile?.retirement) {
        await dispatch(
          updateCalculationProfile({
            ...currentCalculationProfile,
            retirement: {
              ...currentCalculationProfile.retirement,
              age: retirementAge,
            },
          })
        );
      }
    },
    [dispatch, store],
    {
      handleLoading: false,
    }
  );
};
