import { create } from '../../helpers/reducers';

const createDefaults = () => ({
  type: undefined,
  featuresLoaded: false,
  orientation: undefined,
});

export default create(
  {
    SET_CURRENT_DEVICE_TYPE: (state, { type, orientation }) => ({
      ...state,
      type,
      orientation,
    }),
    FEATURES_LOADED: (state) => ({
      ...state,
      featuresLoaded: true,
    }),
  },
  createDefaults,
  {
    disableResetOnLogout: true,
  }
);
