import React, { lazy } from 'react';
import withSuspense from 'decorators/withSuspense';
import * as routeNames from 'constants/routeNames';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import useI18n from 'hooks/useI18n';

import adjustCalculationRoutes from 'areas/dashboard/AdjustCalculation/routes';
import payoutsChildrenRoutes from 'areas/dashboard/PayoutsChildren/routes';
import payoutsOriginRoutes from 'areas/dashboard/PayoutsOrigin/routes';
import linkChildrenRoutes from 'areas/dashboard/LinkChildren/routes';
import { DASHBOARD_AREA } from 'areas/dashboard/trackingIds';

const { default: DashboardNavigator } = require('areas/dashboard/DashboardNavigator');
const { default: MailboxNavigator } = require('areas/mailbox/MailboxNavigator');
const { default: SavingsNavigator } = require('areas/savings/SavingsNavigator');
const { default: MoreNavigator } = require('areas/more/MoreNavigator');
const { default: MailCreate } = require('areas/mailbox/Create');
const { default: DocumentViewer } = require('areas/general/PdfViewer');
const { default: PhoneNumber } = require('areas/profile/PhoneNumber');
const { default: Email } = require('areas/profile/Email');
const { default: CMSNavigator } = require('areas/cms/CMSNavigator');
const { default: SavingsModalsNavigator } = require('areas/savings/SavingsModalsNavigator');
const { default: UpdateContactInfo } = require('areas/contact/UpdateContactInfo');
const { default: NotificationSettings } = require('areas/preferences/NotificationSettings');
const { default: VerifyPhoneNumber } = require('areas/profile/VerifyPhoneNumber');
const { default: VerifyEmail } = require('areas/profile/VerifyEmail');
const { default: YourProfile } = require('areas/profile/YourProfile');
const { default: FeedbackNavigator } = require('areas/feedback/FeedbackNavigator');
const { default: ModalStack } = require('navigation/ModalStack');
const { default: CustomBottomTabBar } = require('./CustomBottomTabBar');
const {
  default: NotificationSettingsCompanyGroups,
} = require('areas/preferences/NotificationSettingsCompanyGroups');
const {
  default: ExternalDataNavigator,
} = require('areas/profile/ExternalData/ExternalDataNavigator');

const { SAVINGS_AREA } = require('areas/savings/trackingIds');
const { MAILBOX_AREA } = require('areas/mailbox/trackingIds');
const { MORE_AREA } = require('areas/more/trackingIds');

// Lazy navigators

const LazyDepositsNavigator = lazy(() => import('areas/deposits/DepositsNavigator'));
const DepositsNavigator = withSuspense(LazyDepositsNavigator);

const LazyChangeInvestmentProfileNavigator = lazy(
  () => import('areas/changeInvestmentProfile/ChangeInvestmentProfileNavigator')
);
const ChangeInvestmentProfileNavigator = withSuspense(LazyChangeInvestmentProfileNavigator);

const LazyBookingNavigator = lazy(() => import('areas/booking/BookingNavigator'));
const BookingNavigator = withSuspense(LazyBookingNavigator);

const LazyTransferNavigator = lazy(() => import('areas/transfer/TransferNavigator'));
const TransferNavigator = withSuspense(LazyTransferNavigator);

const LazyFetchTaxAndPensionsInfoNavigator = lazy(
  () => import('areas/fetchTaxAndPensionsInfo/FetchTaxAndPensionsInfoNavigator')
);
const FetchTaxAndPensionsInfoNavigator = withSuspense(LazyFetchTaxAndPensionsInfoNavigator);

const LazyMeetingPreparationNavigator = lazy(
  () => import('areas/meetingPreparation/MeetingPreparationNavigator')
);
const MeetingPreparationNavigator = withSuspense(LazyMeetingPreparationNavigator);

// NOTE: these routes are statically included in order to allow deep linking
export const tabBarRoutes = {
  [routeNames.DASHBOARD]: {
    screen: DashboardNavigator,
  },
  [routeNames.SAVINGS]: {
    screen: SavingsNavigator,
  },
  [routeNames.MAILBOX]: {
    screen: MailboxNavigator,
  },
  [routeNames.MORE]: {
    screen: MoreNavigator,
  },
};

export const modalRoutes = {
  [routeNames.MAIL_CREATE]: {
    screen: MailCreate,
  },
  [routeNames.DOCUMENT_VIEW]: {
    screen: DocumentViewer,
  },
  [routeNames.FETCH_TAX_AND_PENSIONSINFO]: {
    screen: FetchTaxAndPensionsInfoNavigator,
  },
  [routeNames.BOOKING]: {
    screen: BookingNavigator,
  },
  [routeNames.CHANGE_INVESTMENT_PROFILE]: {
    screen: ChangeInvestmentProfileNavigator,
  },
  [routeNames.DEPOSITS]: {
    screen: DepositsNavigator,
  },
  [routeNames.MODAL_CMS_STACK]: {
    screen: CMSNavigator,
  },
  [routeNames.PROFILE_TELEPHONE_MODAL]: {
    screen: PhoneNumber,
  },
  [routeNames.PROFILE_EMAIL_MODAL]: {
    screen: Email,
  },
  [routeNames.PROFILE_EXTERNAL_DATA]: {
    screen: ExternalDataNavigator,
  },
  [routeNames.SAVINGS_MODALS]: {
    screen: SavingsModalsNavigator,
  },
  [routeNames.TRANSFER]: {
    screen: TransferNavigator,
  },
  [routeNames.FEEDBACK]: {
    screen: FeedbackNavigator,
  },
  [routeNames.CONTACT_INFO_UPDATE]: {
    screen: UpdateContactInfo,
  },
  [routeNames.SETTINGS_NOTIFICATION_SETTINGS_MODAL]: {
    screen: NotificationSettings,
  },
  [routeNames.SETTINGS_NOTIFICATION_SETTINGS_COMPANY_GROUPS_MODAL]: {
    screen: NotificationSettingsCompanyGroups,
  },
  [routeNames.PROFILE_VERIFY_PHONE_NUMBER_MODAL]: {
    screen: VerifyPhoneNumber,
  },
  [routeNames.PROFILE_VERIFY_EMAIL_MODAL]: {
    screen: VerifyEmail,
  },
  [routeNames.PROFILE_OVERVIEW_MODAL]: {
    screen: YourProfile,
  },
  [routeNames.MEETING_PREPARATION]: {
    screen: MeetingPreparationNavigator,
  },
  ...adjustCalculationRoutes,
  ...payoutsChildrenRoutes,
  ...payoutsOriginRoutes,
  ...linkChildrenRoutes,
};

const getTabBarInfo = (i18n: { t: (arg: string) => string }) => ({
  [routeNames.DASHBOARD]: {
    titleFn: () => i18n.t('Udbetaling'),
    icon: 'graph',
    testID: 'dashboardTab',
    trackingParams: {
      section: DASHBOARD_AREA,
    },
  },
  [routeNames.SAVINGS]: {
    titleFn: () => i18n.t('Opsparing'),
    icon: 'savings',
    testID: 'savingsTab',
    trackingParams: {
      section: SAVINGS_AREA,
    },
  },
  [routeNames.MAILBOX]: {
    titleFn: () => i18n.t('Postkasse'),
    icon: 'mail',
    testID: 'mailboxTab',
    trackingParams: {
      section: MAILBOX_AREA,
    },
  },
  [routeNames.MORE]: {
    titleFn: () => i18n.t('Mere'),
    icon: 'menu',
    testID: 'moreTab',
    trackingParams: {
      section: MORE_AREA,
    },
  },
});

const Tab = createBottomTabNavigator();

const BottomTabNavigator: React.FC = () => {
  const { i18n } = useI18n();
  const tabBarInfo = getTabBarInfo(i18n);

  return (
    <Tab.Navigator
      tabBar={(props) => <CustomBottomTabBar {...props} />}
      backBehavior="history"
      screenOptions={{
        headerShown: false,
        // @ts-expect-error
        tabBarInfo,
      }}
    >
      {Object.keys(tabBarRoutes).map((route) => (
        <Tab.Screen name={route} key={route} component={(tabBarRoutes as any)[route].screen} />
      ))}
    </Tab.Navigator>
  );
};

const mainRoutes = {
  [routeNames.TABS]: {
    screen: BottomTabNavigator,
  },
  ...modalRoutes,
};

const MainStack = () => <ModalStack routeConfig={mainRoutes} />;

export default MainStack;
