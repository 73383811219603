import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

const BORDER_RADIUS = 8;

const Wrapper = styled.View`
  justify-content: center;
  border-radius: ${BORDER_RADIUS}px;
  width: 100%;
  padding: ${({ theme }) => theme.SPACINGS.md}px;
  background: ${({ theme }) => theme.COLORS.BLOCKED_STATUS};
`;

const Warning = ({ children, testID }) => <Wrapper testID={testID}>{children}</Wrapper>;

Warning.propTypes = {
  children: PropTypes.node.isRequired,
  testID: PropTypes.string,
};

Warning.defaultProps = {
  testID: undefined,
};

export default Warning;
