// Screens
export const DEPOSITS_RECEIPT_SCREEN = 'deposits-receipt-screen';
export const DEPOSITS_SAVINGS_SCREEN = 'deposits-savings-screen';
export const DEPOSITS_AMOUNT_SCREEN = 'deposits-amount-screen';
export const DEPOSITS_PLACEMENT_SCREEN = 'deposits-placement-screen';
export const DEPOSITS_CONTACT_SCREEN = 'deposits-contact-screen';
export const DEPOSITS_SOURCES_SCREEN = 'deposits-sources-screen';

// Buttons
export const START_NEW_PAYMENT_BUTTON = 'start-new-payment-button';
export const EXIT_PAYMENT_BUTTON = 'exit-payment-button';
export const GO_TO_AMOUNT_BUTTON = 'go-to-amount-button';
export const GO_TO_PLACEMENT_BUTTON = 'go-to-placement-button';
export const GO_TO_CONTACT_OR_QUESTIONS_BUTTON = 'go-to-contact-or-questions-button';
export const ACCEPT_CONDITIONS_CHECKBOX = 'accept-conditions-checkbox';
export const CONTACT_SUBMIT_BUTTON = 'contact-submit-button';
export const GO_TO_PAYMENT_BUTTON = 'go-to-payment-button';
export const PAYMENT_SOURCE_RADIO_BUTTON_ACCOUNT_TRANSFER =
  'payment-source-radio-button-account-transfer';
export const PAYMENT_SOURCE_RADIO_BUTTON_CARD_PAYMENT = 'payment-source-radio-button-card-payment';
export const PAYMENT_SOURCE_RADIO_BUTTON_MOBILE_PAY = 'payment-source-radio-button-mobile-pay';
export const MORE_DEPOSITS_BUTTON = 'moreDepositsButton';

// Inputs
export const AMOUNT_INPUT = 'amount-input';
