import React, { useCallback, useContext, useMemo } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { shallowEqual } from 'react-redux';
import { FETCH_TAX_PENSIONSINFO_INTRODUCTION } from 'constants/routeNames';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import { useBackAndForwardButtonNavigation } from './hooks/useBackAndForwardButtonNavigation';
import { selectFetchFailType, selectOnDoneRouteConfig } from 'selectors/fetchTaxAndPensionsInfo';
import { selectIsDeviceTypeDesktop, selectIsDeviceTypeMobile } from 'selectors/device';
import { selectDeeplink } from 'selectors/deeplink';
import { selectFlowControl } from 'selectors/flowControl';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import MenuPageContainer from 'containers/v2/pages/MenuPage/MenuPageV2';
import fetchTaxAndPensionsInfoController from './fetchTaxAndPensionsInfoController';
import type { RootState } from 'store';
import { isWeb } from 'helpers/platform';
import { COLORS } from '@sp/ui/settings';

const { default: Margin } = require('@sp/ui/base/Margin');
const { ContentWithIcon } = require('@sp/ui/v2/pages/BasePage');
const { ModalProvider } = require('@sp/ui/v2/context/ModalContext');

const ICON_WIDTH = 72;
const ICON_HEIGHT = 65;

const selectFetchFail = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  fetchFailType: selectFetchFailType(state),
  isMobile: selectIsDeviceTypeMobile(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
  onDoneRouteConfig: selectOnDoneRouteConfig(state),
  flowControl: selectFlowControl(state),
});

const FetchFail: React.FC = () => {
  const { updateFetchingState, simulationClock } = useContext(FetchTaxAndPensionsInfoContext);

  const { removePendingRefetchingPromise } = fetchTaxAndPensionsInfoController;
  const navigation = useNavigation();
  const i18n = useI18n(['fetchTaxAndPensionsInfo']);
  const { backButtonNavigation } = useBackAndForwardButtonNavigation();

  const { deeplink, isMobile, isDesktop, fetchFailType, onDoneRouteConfig } = useAppSelector(
    selectFetchFail,
    shallowEqual
  );
  const { isDeeplink } = deeplink ?? {};

  const bodyI18nKey = useMemo(() => {
    switch (fetchFailType) {
      case 'ConsentFailed': {
        return 'fetchFailConsentFailed';
      }
      case 'NoCprInData': {
        return 'fetchFailNoCprInData';
      }
      case 'LoginFail': {
        return 'fetchFailLoginFail';
      }
      default: {
        return 'fetchFailSomethingWentWrong';
      }
    }
  }, [fetchFailType]);

  const tryAgainOrTerminateFlow = useCallback(
    (res: { tryAgain?: boolean }) => {
      simulationClock.value = 0;
      updateFetchingState({});
      removePendingRefetchingPromise();

      if (res?.tryAgain) {
        navigation.navigate(FETCH_TAX_PENSIONSINFO_INTRODUCTION);
      } else {
        navigation.dispatch(CommonActions.navigate(onDoneRouteConfig));
      }
    },
    [
      navigation,
      onDoneRouteConfig,
      simulationClock,
      updateFetchingState,
      removePendingRefetchingPromise,
    ]
  );

  return (
    <ModalProvider
      hasOnCloseButton={!isDeeplink}
      onClose={tryAgainOrTerminateFlow}
      isModal
      secondaryColor
    >
      <MenuPageContainer
        backButtonRoute={backButtonNavigation}
        title={isMobile ? undefined : i18n.t('common|somethingWentWrong')}
        actions={[
          {
            text: i18n.t('common|tryAgain'),
            onPress: () => tryAgainOrTerminateFlow({ tryAgain: true }),
            mobilePosition: 'bottom',
          },
        ]}
      >
        <Margin horizontal="xl" marginTop="md">
          <ContentWithIcon
            isMobile={isMobile}
            title={isDesktop ? undefined : i18n.t('fetchTaxAndPensionsInfo|fetchFailTitle')}
            body={i18n.t(`fetchTaxAndPensionsInfo|${bodyI18nKey}`)}
            icon={
              !isDesktop
                ? {
                    width: ICON_WIDTH,
                    height: ICON_HEIGHT,
                    name: 'exclamationMarkFilled',
                    // set fill color for deeplink menupage with white background
                    fill: isWeb && isMobile ? COLORS.V2_SECONDARY_TEXT : undefined,
                  }
                : undefined
            }
          />
        </Margin>
      </MenuPageContainer>
    </ModalProvider>
  );
};

export default FetchFail;
