import { create } from '../../helpers/reducers';
import { APP_LOCK } from 'constants/actionTypes';

const createDefaults = () => ({
  authCheckFinished: false,
  appLock: undefined,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    SECURITY_LOAD: (state, payload) => ({
      ...state,
      ...payload,
    }),
    ENROLLED: (state, payload) => ({
      ...state,
      enrolled: payload,
    }),
    AUTH_CHECK_FINISHED: (state, payload) => ({
      ...state,
      authCheckFinished: payload,
    }),
    [APP_LOCK]: (state, payload) => ({
      ...state,
      appLock: payload,
    }),
  },
  createDefaults,
  { disableResetOnLogout: true }
);

export type { State };
