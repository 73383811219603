import Desktop from './index.desktop';
import Mobile from './index.mobile';

const { default: Conditional } = require('decorators/conditional');
const { default: ScrollPage } = require('@sp/ui/v2/pages/ScrollPage');

export default Conditional({
  desktop: Desktop,
  tablet: ScrollPage,
  mobile: Mobile,
});
