import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import refCache from 'services/refCache';

import { selectIsDeviceLandscape, selectDeviceType } from 'selectors/device';

const Conditional = (conditions) => {
  const withConditions = ({
    deviceType,
    children,
    dispatch, // NOTE: Fixes warning where where Text component gets dispatch as a prop
    refId,
    isLandscape,
    ...otherProps
  }) => {
    const {
      default: Default,
      desktop: Desktop,
      tablet: Tablet,
      mobile: Mobile,
      landscape: Landscape,
    } = conditions;

    if (deviceType === 'mobile' && Mobile)
      return (
        <Mobile ref={refId ? refCache.get(refId) : undefined} {...otherProps}>
          {children}
        </Mobile>
      );
    if (deviceType === 'tablet') {
      if (isLandscape && Landscape) {
        return (
          <Landscape ref={refId ? refCache.get(refId) : undefined} {...otherProps}>
            {children}
          </Landscape>
        );
      }

      if (Tablet) {
        return (
          <Tablet ref={refId ? refCache.get(refId) : undefined} {...otherProps}>
            {children}
          </Tablet>
        );
      }
    }
    if (deviceType === 'desktop' && Desktop)
      return (
        <Desktop ref={refId ? refCache.get(refId) : undefined} {...otherProps}>
          {children}
        </Desktop>
      );

    return Default ? (
      <Default {...otherProps} ref={refId ? refCache.get(refId) : undefined}>
        {children}
      </Default>
    ) : null;
  };

  withConditions.propTypes = {
    deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop', 'default']).isRequired,
    isLandscape: PropTypes.bool.isRequired,
    children: PropTypes.any,
    dispatch: PropTypes.func.isRequired,
    refId: PropTypes.symbol,
  };

  withConditions.defaultProps = {
    children: undefined,
    refId: undefined,
  };

  return withConditions;
};

const mapStateToProps = (state) => ({
  deviceType: selectDeviceType(state),
  isLandscape: selectIsDeviceLandscape(state),
});

export default compose(connect(mapStateToProps), Conditional);
