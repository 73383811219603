import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import { Body, H5 } from '@sp/ui/typography';
import {
  selectRetirementAge,
  selectAwardRules,
  selectPayoutsOneTimeAwardRules,
  selectShowGrossAmount,
} from 'selectors/dashboard';
import IncomeTodayDetails from '../IncomeTodayDetails';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import ListAwardRules from 'areas/dashboard/AwardRules/ListAwardRules';
import { getDescriptionText } from 'areas/dashboard/payouts/payoutsOneTime/helpers';
import { PayoutsDescription } from 'areas/dashboard/styles';
import OneTimePayoutRow from 'areas/dashboard/payouts/OneTimePayoutRow';
import { DEATH, DISABILITY } from '../../constants/dashboardTypes';

const PayoutsOneTime = ({
  payouts,
  retirementAge,
  theme,
  i18n,
  awardRulesOneTime,
  showGrossAmount,
  payoutType,
}) => (
  <>
    {(payoutType === DISABILITY || payoutType === DEATH) && (
      <ListAwardRules
        textLeft={i18n.t('dashboard|payoutCriteria')}
        awardRules={awardRulesOneTime}
        payoutType={payoutType}
        footerText
        showDisclaimer
      />
    )}
    <PayoutsDescription>
      <Body textAlign="center">
        {getDescriptionText(
          retirementAge,
          payoutType,
          i18n,
          showGrossAmount,
          awardRulesOneTime,
          payouts?.length
        )}
      </Body>
    </PayoutsDescription>
    <RowBorder />
    <OneLineRow
      textLeft={
        <H5>{payoutType === DEATH ? i18n.t('dashboard|year') : i18n.t('dashboard|age')}</H5>
      }
      textRight={<H5>{i18n.t('dashboard|payout')}</H5>}
      backgroundColor={theme.COLORS.PRIMARY_VARIANT1}
    />
    <IncomeTodayDetails />
    {payouts?.map((item) => (
      <OneTimePayoutRow
        item={item}
        payoutType={payoutType}
        i18n={i18n}
        key={`OneTimePayoutRow-${item.age}`}
        showGrossAmount={showGrossAmount}
      />
    ))}
  </>
);

PayoutsOneTime.propTypes = {
  payouts: PropTypes.array,
  retirementAge: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  awardRulesOneTime: PropTypes.array,
  showGrossAmount: PropTypes.bool.isRequired,
  payoutType: PropTypes.string.isRequired,
};

PayoutsOneTime.defaultProps = {
  payouts: undefined,
  awardRulesOneTime: [],
};

const mapStateToProps = (state, props) => ({
  retirementAge: selectRetirementAge(state),
  awardRules: selectAwardRules(state),
  awardRulesOneTime: selectPayoutsOneTimeAwardRules(state, props.payoutType),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withScreenContainer({
  mapStateToProps,
})(withTheme(PayoutsOneTime));
