import React from 'react';
import { useRoute } from '@react-navigation/native';
import ClassicPage from 'containers/ClassicPage';

const Classic = () => {
  const { url, key, title } = useRoute().params ?? {};
  return <ClassicPage title={title} url={url} cacheKey={key} />;
};

export default Classic;
