import React from 'react';
import PropTypes from 'prop-types';
import { TwoLineRow, OneLineRow } from '@sp/ui/base/rows';
import { RadioButton } from '@sp/ui/v2/base/inputs';

const RowWithRadioButton = ({
  textLeft,
  textRight,
  subtitleLeft,
  subtitleRight,
  onPress,
  componentLeft,
  isSelected,
  isTwoLineRow,
}) =>
  isTwoLineRow ? (
    <TwoLineRow
      textLeft={textLeft}
      textRight={textRight}
      subtitleLeft={subtitleLeft}
      subtitleRight={subtitleRight}
      onPress={onPress}
      componentLeft={componentLeft || <RadioButton isSelected={isSelected} />}
    />
  ) : (
    <OneLineRow
      textLeft={textLeft}
      textRight={textRight}
      onPress={onPress}
      componentLeft={<RadioButton isSelected={isSelected} />}
    />
  );

RowWithRadioButton.propTypes = {
  componentLeft: PropTypes.node,
  textLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textRight: PropTypes.string,
  subtitleLeft: PropTypes.string,
  subtitleRight: PropTypes.string,
  onPress: PropTypes.func,
  isSelected: PropTypes.bool,
  isTwoLineRow: PropTypes.bool,
};

RowWithRadioButton.defaultProps = {
  componentLeft: undefined,
  textLeft: undefined,
  textRight: undefined,
  subtitleLeft: undefined,
  subtitleRight: undefined,
  onPress: undefined,
  isTwoLineRow: false,
  isSelected: false,
};

export default RowWithRadioButton;
