// Feature specific environment config
// Uncomment and modify the fields depending on your feature

//NOTE: we use the commonjs module format here because we also
//need this file imported in our webpack.config.js for the dev proxy config
module.exports = {
  // name: 'bop',
  // environment: {
  //   api: 'https://bop.api-test.sampension.app',
  // },
};
