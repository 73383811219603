import React from 'react';
import { useTheme } from 'styled-components/native';
import { ADJUST_TAXATION } from 'constants/routeNames';
import { ADJUST_CALCULATION_TAXATION_ROW } from 'constants/testIds/dashboard/adjustCalculation';
import useI18n from 'hooks/useI18n';
import { useNavigation } from '@react-navigation/native';
import AdjustCalculationRow from './AdjustCalculationRow';

const { default: Icon } = require('@sp/ui/base/Icon');

const TaxationRow: React.FC = () => {
  const { COLORS } = useTheme();
  const navigation = useNavigation();
  const { i18n } = useI18n(['dashboard']);

  return (
    <AdjustCalculationRow
      testID={ADJUST_CALCULATION_TAXATION_ROW}
      componentLeft={<Icon name="rowPercentage" fill={COLORS.V2_PRIMARY_BACKGROUND} />}
      textLeft={i18n.t('dashboard|taxTitle')}
      onPress={() => {
        navigation.navigate(ADJUST_TAXATION);
      }}
    />
  );
};

export default TaxationRow;
