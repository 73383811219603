import React from 'react';
import PropTypes from 'prop-types';
import DashboardModal from 'containers/dashboard/DashboardModal';
import withTranslation from 'decorators/i18n';

const MinAgeModal = ({ visible, onClose, i18n }) => (
  <DashboardModal
    visible={visible}
    onClose={onClose}
    title={i18n.t('dashboard|minAgeModalTitle')}
    body={i18n.t('dashboard|minAgeModalDescription')}
  />
);

MinAgeModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
};

MinAgeModal.defaultProps = {
  visible: false,
};

export default withTranslation()(MinAgeModal);
