import React, { useContext, useMemo, Fragment } from 'react';
import { Keyboard, Dimensions } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import Animated, { useSharedValue, useAnimatedScrollHandler } from 'react-native-reanimated';
import { KeyboardAwareScrollView } from '@sampension/react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Conditional from 'decorators/conditional';
import BasePage, { ActionShape, NarrowContent, animations } from '@sp/ui/pages/BasePage';
import { Link } from '@sp/ui/typography';
import { TAB_BAR_HEIGHT_MOBILE } from '@sp/ui/layout/BottomTabBar';
import { getCurrentDeviceType, isIphone5, isWeb, isIOS } from '@sp/ui/helpers/device';
import useModal from '@sp/ui/hooks/useModal';
import { KeyboardContext } from '@sp/ui/context/KeyboardContext';
import { Content } from '../Content';

const BUTTON_TOTAL_HEIGHT = 55;
const KEYBOARD_DONE_BAR_PADDING = 12;
const KEYBOARD_DONE_BAR_HEIGHT = 45;
const KEYBOARD_DONE_BAR_TOTAL_HEIGHT = KEYBOARD_DONE_BAR_HEIGHT + KEYBOARD_DONE_BAR_PADDING * 2;
const IOS_KEYBOARD_DONE_BAR_EXTRA_MARGIN = isIphone5() ? 10 : 24;

const isNative = !isWeb;

const AnimatedKeyboardAwareScrollView = Animated.createAnimatedComponent(KeyboardAwareScrollView);
const KeyboardHidingWrapper = styled.TouchableWithoutFeedback``;

const Wrapper = styled.View`
  flex: 1;
`;

const KeyboardAvoidingWrapper = styled.KeyboardAvoidingView`
  flex: 1;
`;

const NarrowWrapper = Conditional({
  tablet: NarrowContent,
  default: Fragment,
});

const KeyboardDoneBar = styled.View`
  position: absolute;
  background: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding: ${KEYBOARD_DONE_BAR_PADDING}px;
  height: ${KEYBOARD_DONE_BAR_HEIGHT}px;
  bottom: ${({ keyboardHeight, iOSExtraBottomSpace }) =>
    iOSExtraBottomSpace ? keyboardHeight - iOSExtraBottomSpace : keyboardHeight}px;
`;

const Page = ({
  title,
  heading,
  description,
  children,
  actions,
  actionInfo,
  backButton,
  hasBackButton,
  disableScroll,
  enableResetScrollToCoords,
  customHeader,
  keyboardDoneLabel,
  testID,
  accessibilitySummary,
  animateTitle,
}) => {
  const scrollY = useSharedValue(0);
  const { bottom: extraBottomSpace } = useSafeAreaInsets();

  const { isKeyboardVisible, keyboardHeight } = useContext(KeyboardContext);

  const isTablet = getCurrentDeviceType() === 'tablet';
  const { isModal } = useModal();

  const bottomStickyActions = actions.filter((action) => action.mobilePosition === 'bottom-sticky');
  const bottomOffsetHeight = BUTTON_TOTAL_HEIGHT * bottomStickyActions.length;
  const needsDoneAction =
    !isTablet && disableScroll && actions.some((action) => action.mobilePosition === 'bottom');

  const keyboardDoneBarVerticalOffset = isIOS
    ? KEYBOARD_DONE_BAR_TOTAL_HEIGHT + IOS_KEYBOARD_DONE_BAR_EXTRA_MARGIN
    : KEYBOARD_DONE_BAR_TOTAL_HEIGHT;

  const extraKeyboardVerticalOffset = needsDoneAction ? keyboardDoneBarVerticalOffset : 0;

  const headingAnimation = animations.useHeaderAnimation(scrollY);
  const titleAnimation = animations.useTitleAnimation(scrollY);

  const animatedScrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      scrollY.value = e.contentOffset.y;
    },
  });

  const onScrollHandler = useMemo(
    () => (animateTitle ? animatedScrollHandler : undefined),
    [animateTitle, animatedScrollHandler]
  );

  // HACK: we substract the header height so the keyboard can adjust accordingly
  const portraitHeightAdjustment = isNative && Dimensions.get('window').width >= 960 ? -64 : 0;

  return (
    <BasePage
      title={title}
      titleAnimation={titleAnimation}
      actions={actions}
      actionInfo={actionInfo}
      backButton={backButton}
      hasBackButton={hasBackButton}
      testID={testID}
      customHeader={customHeader}
      accessibilitySummary={accessibilitySummary}
      animateTitle={animateTitle}
    >
      {disableScroll ? (
        <KeyboardAvoidingWrapper
          behavior={!isIOS && !needsDoneAction ? undefined : 'padding'}
          keyboardVerticalOffset={
            isNative ? TAB_BAR_HEIGHT_MOBILE + extraBottomSpace + extraKeyboardVerticalOffset : 0
          }
        >
          <KeyboardHidingWrapper
            onPress={isNative ? Keyboard.dismiss : undefined}
            focusable={false}
          >
            <Wrapper>
              <NarrowWrapper>
                <Content
                  title={title}
                  heading={heading}
                  animateTitle={animateTitle}
                  headingAnimation={headingAnimation}
                  description={description}
                  children={children}
                />
              </NarrowWrapper>
            </Wrapper>
          </KeyboardHidingWrapper>
        </KeyboardAvoidingWrapper>
      ) : (
        <AnimatedKeyboardAwareScrollView
          extraScrollHeight={isNative ? extraBottomSpace - bottomOffsetHeight : 0}
          enableResetScrollToCoords={enableResetScrollToCoords}
          viewIsInsideTabBar
          extraHeight={portraitHeightAdjustment}
          onScroll={onScrollHandler}
        >
          <NarrowWrapper>
            <Content
              title={title}
              heading={heading}
              animateTitle={animateTitle}
              headingAnimation={headingAnimation}
              description={description}
              children={children}
            />
          </NarrowWrapper>
        </AnimatedKeyboardAwareScrollView>
      )}
      {needsDoneAction && isKeyboardVisible && (
        <KeyboardDoneBar
          isModal={isModal}
          keyboardHeight={keyboardHeight}
          iOSExtraBottomSpace={extraBottomSpace}
        >
          <Link testID={`${testID}HideKeyboard`} onPress={Keyboard.dismiss} fontWeight="semibold">
            {keyboardDoneLabel}
          </Link>
        </KeyboardDoneBar>
      )}
    </BasePage>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  keyboardDoneLabel: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  disableScroll: PropTypes.bool,
  enableResetScrollToCoords: PropTypes.bool,
  testID: PropTypes.string,
  customHeader: PropTypes.node,
  animateTitle: PropTypes.bool,
};

Page.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  children: undefined,
  actions: [],
  actionInfo: undefined,
  backButton: undefined,
  hasBackButton: true,
  disableScroll: false,
  enableResetScrollToCoords: true,
  keyboardDoneLabel: 'Done',
  testID: undefined,
  customHeader: undefined,
  accessibilitySummary: undefined,
  animateTitle: false,
};

export default Page;
