import React from 'react';
import PropTypes from 'prop-types';
import Margin from '@sp/ui/base/Margin';
import styled from 'styled-components/native';
import { RowWithRadioButton, RowWithCheckbox } from '@sp/ui/v2/base/inputs';
import { Subtitle } from '@sp/ui/v2/typography';
import Icon from '@sp/ui/base/Icon';
import { getSelectedPoliciesText } from './helpers';

const ExpandWrapper = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

const Header = ({
  companyGroup,
  state,
  isOneCompanyGroup,
  onCompanyGroupPress,
  isFoldable,
  isSelected,
  isPartiallySelected,
  isExpanded,
  setIsExpanded,
  texts,
  theme,
}) => {
  const Row = isOneCompanyGroup ? RowWithCheckbox : RowWithRadioButton;

  return (
    <Row
      titleLeft={isOneCompanyGroup ? texts.selectAll : companyGroup.textLeft}
      titleRight={companyGroup.textRight}
      testID={`companyGroupCheckmark-${companyGroup.companyGroup}`}
      subtitleLeft={
        <Subtitle color={theme.COLORS.V2_SECONDARY_LINK} fontWeight="semibold">
          {texts.selection(
            getSelectedPoliciesText(state, companyGroup.companyGroup, companyGroup?.policies)
          )}
        </Subtitle>
      }
      subtitleRight={
        isFoldable && (
          <ExpandWrapper onPress={() => setIsExpanded(!isExpanded)}>
            <Subtitle
              textAlign="right"
              color={theme.COLORS.V2_SECONDARY_LINK}
              fontWeight="semibold"
            >
              {isExpanded ? texts.folding.expanded : texts.folding.collapsed}
            </Subtitle>
            <Margin marginLeft="xs">
              <Icon
                name={isExpanded ? 'caret' : 'caretDown'}
                width={9}
                height={5}
                fill={theme.COLORS.V2_SECONDARY_LINK}
              />
            </Margin>
          </ExpandWrapper>
        )
      }
      checked={isSelected}
      isPartiallyChecked={isPartiallySelected}
      isSelected={isSelected}
      isPartiallySelected={isPartiallySelected}
      onPress={() => {
        if (!isExpanded) {
          setIsExpanded(true);
        }

        onCompanyGroupPress(companyGroup);
      }}
    />
  );
};

Header.propTypes = {
  companyGroup: PropTypes.object.isRequired,
  isOneCompanyGroup: PropTypes.bool.isRequired,
  isFoldable: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPartiallySelected: PropTypes.bool.isRequired,
  onCompanyGroupPress: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  texts: PropTypes.object.isRequired,
};

export default Header;
