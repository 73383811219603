import React from 'react';
import PropTypes from 'prop-types';
import { SortToggle } from '@sp/ui/base/buttons';
import withTranslation from 'decorators/i18n';

const SortToggleContainer = ({ i18n, ...props }) => (
  <SortToggle
    accessibilityLabel={i18n.t('Sortering per')}
    accessibilityLabelASC={i18n.t('Ældste først')}
    accessibilityLabelDESC={i18n.t('Nyeste først')}
    {...props}
  />
);

SortToggleContainer.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(SortToggleContainer);
