import React, { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import styled from 'styled-components/native';
import useI18n from 'hooks/useI18n';
import MenuPage from '@sp/ui/v2/pages/MenuPage';
import { selectPartner } from 'selectors/partner';
import useTrackingOnFocus from 'hooks/useTrackingOnFocus';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useGetContent, NotFoundErrorTypes } from './hooks/useGetContent';
import Lottie from './Lottie';
import LottieNotFoundJSON from './Lottie/NotFound.json';
import useAsyncCallback from 'hooks/useAsyncCallback';
import { createOrRemoveDeeplinkPersistedStorage } from 'hooks/useHandleDeeplink/helpers';

const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Menu } = require('containers/navigation/Menu');
const { logout: logoutAction } = require('actions/security');
const { H3 } = require('@sp/ui/v2/typography');
const { BackButton } = require('@sp/ui/v2/pages/BasePage');

const DEFAULT_TRACKING_ERROR = 'route-does-not-exist';
const TRACKING_AREA = 'NotFound';

const Wrapper = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const getLottieData = (): Record<string, any> => ({
  notFound: { data: LottieNotFoundJSON },
});

const NotFound = () => {
  const dispatch = useAppDispatch();
  const i18n = useI18n();
  const { type }: { type?: NotFoundErrorTypes } = useRoute().params ?? {};
  const { hasPartnerConnectionFailed } = useAppSelector(selectPartner, shallowEqual);

  const { notFound: lottieNotFound } = getLottieData();
  const { title, description } = useGetContent(type);

  const goBack = useCallback(() => global.location.replace('/'), []);

  const [logout] = useAsyncCallback(async () => {
    await createOrRemoveDeeplinkPersistedStorage({ remove: true });
    await dispatch(logoutAction());
  }, [dispatch]);

  useTrackingOnFocus({ area: TRACKING_AREA, action: type || DEFAULT_TRACKING_ERROR });

  return (
    <MenuPage
      disableTopPadding
      disableBottomPadding
      hasBackButton={!hasPartnerConnectionFailed}
      menu={<Menu showMenuItems={false} />}
      backButton={<BackButton label={i18n.t('common|back')} onPress={goBack} />}
      title={i18n.t(title)}
      actions={
        hasPartnerConnectionFailed
          ? [
              {
                text: i18n.t('common|endAndLogout'),
                type: 'primary',
                mobilePosition: 'bottom',
                onPress: logout,
              },
            ]
          : [
              {
                text: i18n.t('common|backToStartingPage'),
                type: 'primary',
                mobilePosition: 'bottom',
                onPress: goBack,
              },
            ]
      }
    >
      <Wrapper>
        <Lottie {...lottieNotFound} />
        <Margin all="md">
          <H3 textAlign="center">{i18n.t(description)}</H3>
        </Margin>
      </Wrapper>
    </MenuPage>
  );
};

export default withScreenContainer()(NotFound);
