import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import { useIsFocused } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import withScreenContainer from 'decorators/withScreenContainer';
import {
  fetchSavingsDeposits as fetchDepositAction,
  fetchSavingsOverview,
  updateDepositFetchingState,
  cancelSavingsDeposits,
} from 'actions/savings';
import {
  selectSavingsDeposits,
  selectAllSavingsData,
  selectDepositFetchingState,
} from 'selectors/savings';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import Browser from '@sp/ui/layout/Browser';
import { VIEW_SAVINGS_DEPOSITS } from 'areas/savings/trackingIds';
import { getPolicyDetails } from './helpers/getPolicyDetails';
import { getDepositBrowserContent } from './helpers/getDepositBrowserContent';
import YearlyDepositOverviewButton from './components/deposits/YearlyDepositOverviewButton';
import DepositContent from './components/deposits/DepositContent';

const Payments = ({
  savingsDeposits,
  savingsData,
  fetchDeposits,
  updateFetchingState,
  depositFetchingState,
  cancelPendingDepositRequests,
  i18n,
  route,
}) => {
  const [browserData, setBrowserData] = useState();
  const [showAllYears, setShowAllYears] = useState(false);

  const { pID } = route.params;
  const { maximumYear, minimumYear } = getPolicyDetails(savingsData, pID);

  const minBrowsing = browserData?.currentYear === minimumYear;
  const maxBrowsing = browserData?.currentYear === maximumYear;
  const isBrowsingBackBlocked = !savingsDeposits.some(
    (item) => item.year === browserData?.currentYear - 1
  );

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused && ['fetching', 'resumed'].includes(depositFetchingState)) {
      cancelPendingDepositRequests();
    }
  }, [isFocused, depositFetchingState, cancelPendingDepositRequests]);

  useEffect(() => {
    if (fetchDeposits && ['sleeping', 'resumed'].includes(depositFetchingState)) {
      const depositActions = [];

      for (let i = 0; i <= maximumYear - minimumYear; i += 1) {
        /* Logic that checks for loop holes: If a a year does not
        exist in the reducer then the action will be fetched.
        This enables the user to cancel and resume the fetching of deposits */
        if (!savingsDeposits.some((item) => item.year === maximumYear - i)) {
          depositActions.push(fetchDepositAction(pID, maximumYear - i, savingsDeposits));
        }
      }

      if (depositActions.length > 0) {
        fetchDeposits(depositActions);
      }
    }
  }, [
    fetchDeposits,
    updateFetchingState,
    depositFetchingState,
    maximumYear,
    minimumYear,
    savingsDeposits,
    pID,
  ]);

  useEffect(() => {
    if (savingsDeposits.length > 0 && !browserData) {
      const { deposits, year } = savingsDeposits.find((item) => item.year === maximumYear);

      setBrowserData({
        currentIndex: 0,
        currentYear: year,
        deposits,
      });
    }
  }, [browserData, maximumYear, savingsDeposits]);

  const goBack = () => {
    if (!minBrowsing && !isBrowsingBackBlocked) {
      const { currentYear, currentIndex } = browserData;
      const { deposits } = savingsDeposits.find((item) => item.year === currentYear - 1);

      setBrowserData({
        currentIndex: currentIndex + 1,
        currentYear: currentYear - 1,
        deposits,
      });
    }
  };

  const goForward = () => {
    if (!maxBrowsing) {
      const { currentYear, currentIndex } = browserData;
      const { deposits } = savingsDeposits.find((item) => item.year === currentYear + 1);

      setBrowserData({
        currentIndex: currentIndex - 1,
        currentYear: currentYear + 1,
        deposits,
      });
    }
  };

  const { accumulatedDepositAmount, monthlyInterval } =
    getDepositBrowserContent(browserData?.deposits, showAllYears, savingsDeposits) ?? {};

  const heading =
    accumulatedDepositAmount &&
    i18n.t('savings|{{accumulatedDepositAmount}}kr.', { accumulatedDepositAmount });

  return (
    <ScrollPage title={i18n.t('savings|Indbetalinger')} disableTopPadding fullWidth>
      <Browser
        title={i18n.t('savings|Indbetalinger')}
        hideBrowserControl={showAllYears}
        hasBrowsingArrows={savingsDeposits?.length > 1}
        minBrowsing={isBrowsingBackBlocked ?? minBrowsing}
        maxBrowsing={maxBrowsing}
        browserTitle={browserData?.currentYear}
        heading={heading}
        subtitle={showAllYears ? `${minimumYear} - ${maximumYear}` : monthlyInterval}
        content={
          <DepositContent
            showAllYears={showAllYears}
            savingsDeposits={savingsDeposits}
            deposits={browserData?.deposits}
            goToSpecificPaymentYear={(year, index) => {
              const { deposits } = savingsDeposits.find((item) => item.year === year);

              setBrowserData({
                currentIndex: index,
                currentYear: year,
                deposits,
              });

              setShowAllYears(false);
            }}
          />
        }
        goBack={goBack}
        goForward={goForward}
        yearlyDepositOverviewButton={
          <YearlyDepositOverviewButton
            showAllYears={() => setShowAllYears(!showAllYears)}
            areAllYearsShown={showAllYears}
            depositFetchingState={depositFetchingState}
            resumeFetching={() => updateFetchingState('resumed')}
          />
        }
      />
    </ScrollPage>
  );
};

Payments.propTypes = {
  i18n: PropTypes.object.isRequired,
  savingsDeposits: PropTypes.array,
  savingsData: PropTypes.object,
  fetchDeposits: PropTypes.func,
  updateFetchingState: PropTypes.func,
  cancelPendingDepositRequests: PropTypes.func,
  depositFetchingState: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};

Payments.defaultProps = {
  fetchDeposits: undefined,
  updateFetchingState: undefined,
  cancelPendingDepositRequests: undefined,
  savingsData: undefined,
  savingsDeposits: [],
};

const mapStateToProps = (state, props) => ({
  savingsData: selectAllSavingsData(state),
  savingsDeposits: selectSavingsDeposits(state, props.route.params.pID),
  depositFetchingState: selectDepositFetchingState(state),
});

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { dispatch, savingsData, navigation, route } = getProps();
        const { pID } = route.params;

        if (pID) {
          if (!savingsData) await dispatch(fetchSavingsOverview());
        } else {
          navigation.replace(routeNames.SAVINGS_OVERVIEW);
        }

        return {
          fetchDeposits: async (dispatchArray) => dispatch(dispatchArray),
          updateFetchingState: (state) => dispatch(updateDepositFetchingState(state)),
          cancelPendingDepositRequests: () =>
            dispatch([updateDepositFetchingState('cancelled'), cancelSavingsDeposits()]),
        };
      },
      {
        loader: true,
      }
    ),
  trackAs: VIEW_SAVINGS_DEPOSITS,
})(withTheme(Payments));
