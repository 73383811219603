export const MAILBOX_AREA = 'Mailbox';

export const SIGNING_SUCCESS = {
  area: MAILBOX_AREA,
  action: 'SigningSuccess',
};

export const VIEW_MAILBOX = {
  area: MAILBOX_AREA,
  action: 'ViewMailbox',
};
