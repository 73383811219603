import useAsyncCallback from 'hooks/useAsyncCallback';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
const { fetchDashboardData } = require('helpers/dashboard');

const { setShowGrossAmount } = require('actions/dashboard');
const { default: Tracking } = require('services/tracking');

import { RECALCULATE_DASHBOARD } from 'areas/dashboard/trackingIds';

export const useSetShowGrossAmount = (formGrossAmount: boolean) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  return useAsyncCallback(async () => {
    await dispatch(setShowGrossAmount(formGrossAmount));
    Tracking.trackEvent(RECALCULATE_DASHBOARD, { showGrossAmount: formGrossAmount });
    await fetchDashboardData(dispatch);
    navigation.goBack();
  }, [dispatch, formGrossAmount]);
};
