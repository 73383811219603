import { CONTENT } from 'constants/actionTypes';
import { post } from 'actions/api';

export const getContent = (ruleRequest) =>
  post(
    CONTENT,
    {
      type: 'Page',
      key: 'CompanyReservations',
      language: 'da',
      ruleRequest,
    },
    'api',
    '/rule-engine/v2/content'
  );
