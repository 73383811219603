import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import * as routeNames from 'constants/routeNames';
import AboutCosts from './components/modal/AboutCosts';

const Stack = createStackNavigator();

export default () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen name={routeNames.SAVINGS_ABOUT_COSTS} component={AboutCosts} />
  </Stack.Navigator>
);
