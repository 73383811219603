export const isIE = () =>
  global.navigator.userAgent.indexOf('MSIE') !== -1 ||
  global.navigator.appVersion.indexOf('Trident/') > -1;

export const isIOS = () =>
  global.navigator.userAgent.indexOf('iPhone') !== -1 ||
  global.navigator.userAgent.indexOf('iPad') !== -1;

export const isFirefox = () => global.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const isChrome = () => {
  const isChromium = global.chrome;
  const winNav = global.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof global.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;

  if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  }

  return false;
};
