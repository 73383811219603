import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Title } from '@sp/ui/typography';
import { Button } from '@sp/ui/base/buttons';

import { getColorByType } from './index.shared';

const CARD_PADDING = 24;
const TITLE_BOTTOM_PADDING = 16;
const WIDTH = 620;
const BUTTON_MARGIN_TOP = 10;

const Wrapper = styled.View`
  padding: ${CARD_PADDING}px;
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor}` : '')};
  ${({ borderColor }) => (borderColor ? `border: 1px solid ${borderColor}` : '')};
  width: ${WIDTH}px;
`;

const TitleWrapper = styled.View`
  padding-bottom: ${TITLE_BOTTOM_PADDING}px;
`;

const ButtonWrapper = styled.View`
  margin-top: ${BUTTON_MARGIN_TOP}px;
  align-items: center;
`;

const Card = ({ type, children, title, theme, buttons, buttonComponent }) => (
  <Wrapper
    backgroundColor={getColorByType(type, theme).backgroundColor}
    borderColor={getColorByType(type, theme).borderColor}
  >
    {title && (
      <TitleWrapper>
        <Title textAlign="center" fontWeight="bold">
          {title}
        </Title>
      </TitleWrapper>
    )}
    {children}
    {buttonComponent ||
      (buttons &&
        buttons.map((button) => (
          <ButtonWrapper key={button.text}>
            <Button {...button} />
          </ButtonWrapper>
        )))}
  </Wrapper>
);

Card.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'info']),
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      type: PropTypes.oneOf(['primary', 'secondary', 'destructive']),
      onPress: PropTypes.func,
      inactive: PropTypes.bool,
    })
  ),
  buttonComponent: PropTypes.node,
};

Card.defaultProps = {
  type: 'info',
  title: undefined,
  buttons: undefined,
  buttonComponent: undefined,
};

export default withTheme(Card);
