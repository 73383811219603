import React from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import styled from 'styled-components/native';
import BasePage from '@sp/ui/pages/BasePage';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import RawHTML from '@sp/ui/base/RawHTML';
import { isWeb } from 'helpers/platform';
import { getConsent } from 'actions/consents';
import * as routeNames from 'constants/routeNames';

const Wrapper = styled.View`
  flex: 1;
  flex-basis: 100%;
`;

const Page = isWeb ? ScrollPage : BasePage;

const PreconditionsInfo = ({ body, i18n }) => (
  <Page title={i18n.t('dashboard|preconditions')} testID={routeNames.PRECONDITIONS_INFO}>
    <Wrapper>
      <RawHTML content={body} />
    </Wrapper>
  </Page>
);

PreconditionsInfo.propTypes = {
  body: PropTypes.string,
  i18n: PropTypes.object.isRequired,
};

PreconditionsInfo.defaultProps = {
  body: '',
};

export default withScreenContainer({
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { dispatch } = getProps();
        const consent = await dispatch(getConsent('prerequisites'));

        return {
          body: consent.text,
        };
      },
      { loader: true }
    ),
})(PreconditionsInfo);
