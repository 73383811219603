import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled, { withTheme } from 'styled-components/native';
import { Keyboard } from 'react-native';
import { getAndroidAppSignature } from 'hooks/useAndroidSmsRetriever';
import withScreenContainer from 'decorators/withScreenContainer';
import GlobalNotificationContext from 'context/GlobalNotificationContext';
import Alert from '@sp/ui/base/inputs/Alert';
import InputPage from '@sp/ui/pages/InputPage';
import { PhoneInput } from '@sp/ui/base/inputs';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';
import { Subtitle } from '@sp/ui/typography';
import {
  getContactInfo,
  savePhoneNumber,
  getPendingVerifications as getPendingVerificationsAction,
  verifyContactInformation,
  validateContactInformationToken,
  deletePendingVerifications as deletePendingVerificationsAction,
} from 'actions/profile';
import {
  clearNotificationSettings,
  navigateToNotificationSettings as navigateToNotificationSettingsAction,
} from 'actions/settings';
import { selectContactInfo, selectPendingVerifications } from 'selectors/profile';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { initFunc } from 'helpers/props';
import { isWeb } from 'helpers/platform';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import * as routeNames from 'constants/routeNames';
import {
  getDeleteAction,
  getDisplayPhoneNumber,
  destructPhoneNumber,
  preparePhoneNumberForAPI,
  handleValidationErrors,
} from './helpers';
import { reducer } from './emailPhoneReducer';
import Tracking from 'services/tracking';
import { PHONE_NUMBER } from 'areas/profile/trackingIds';

const ContentDesktop = styled.View`
  margin: 0 ${({ theme }) => theme.SPACINGS.lg}px;
  align-items: center;
  ${isWeb ? 'z-index: auto' : ''};
`;

const Content = Conditional({
  default: ContentDesktop,
  mobile: Margin,
});

const PhoneNumber = ({
  phoneNumber,
  countryCode,
  removeUserPhoneNumber,
  isDesktop,
  onSave,
  isModal,
  navigation,
  getPendingVerificiations,
  pendingVerification,
  requestValidation,
  deletePendingVerifications,
  navigateToNotificationSettings,
  theme,
  i18n,
}) => {
  const { showNotification } = useContext(GlobalNotificationContext);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const isVerified = !pendingVerification;
  const pendingPhone = !isVerified && pendingVerification?.value;

  const [state, dispatch] = useReducer(reducer, {
    verifiedValue: getDisplayPhoneNumber({ countryCode, phoneNumber }),
    pendingValue: pendingPhone,
    formValue: pendingPhone || getDisplayPhoneNumber({ countryCode, phoneNumber }),
    isFormValueValid: true,
  });

  useEffect(() => {
    dispatch({
      type: 'SET_FORM_VALUE',
      value: pendingPhone || getDisplayPhoneNumber({ countryCode, phoneNumber }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'VERIFY_VALUE',
      pendingValue: pendingPhone,
      value: getDisplayPhoneNumber({ countryCode, phoneNumber }),
    });
  }, [pendingPhone, countryCode, phoneNumber]);

  useEffect(() => {
    if (deleteModalVisible) {
      Keyboard.dismiss();
    }
  }, [deleteModalVisible]);

  return (
    <ModalProvider isModal={isModal} onClose={navigation.goBack}>
      <InputPage
        heading={i18n.t('Mobiltelefon')}
        description={i18n.t(
          'Hvis vi kender dit telefonnummer, kan vi nemmere komme i kontakt med dig, hvis der er behov for ændringer i din pensionsordning.'
        )}
        hasBackButton={isDesktop || !isModal}
        enableOverflow
        actions={[
          ...getDeleteAction(
            i18n.t('Slet telefonnummer'),
            phoneNumber,
            pendingPhone,
            setDeleteModalVisible
          ),
          state.button === 'NavigateToNotificationSettings' && {
            text: i18n.t('Se dine kontaktindstillinger'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: navigateToNotificationSettings,
          },
          state.button === 'SaveRequestVerification' && {
            text: i18n.t('Gem'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: async () => {
              if (onSave) onSave();
              await requestValidation(preparePhoneNumberForAPI(state.formValue));
              await getPendingVerificiations();

              navigation.navigate(routeNames.PROFILE_VERIFY_PHONE_NUMBER_MODAL, {
                phoneNumber: state.formValue,
              });

              showNotification(
                i18n.t('Kode er sendt til {{phoneNumber}}. Den gælder i 20 min.', {
                  phoneNumber: state.formValue,
                })
              );
            },
          },
          state.button === 'SaveCancelVerification' && {
            text: i18n.t('Gem'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: async () => {
              if (onSave) onSave();
              await deletePendingVerifications();
              await getPendingVerificiations();

              navigation.goBack();
            },
          },
          state.button === 'Pending' && {
            text: i18n.t('Verificer telefonnummer'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: async () => {
              if (onSave) onSave();

              await requestValidation(preparePhoneNumberForAPI(state.formValue));
              await getPendingVerificiations();

              navigation.navigate(routeNames.PROFILE_VERIFY_PHONE_NUMBER_MODAL, {
                phoneNumber: state.formValue,
              });

              showNotification(
                i18n.t('Kode er sendt til {{phoneNumber}}. Den gælder i 20 min.', {
                  phoneNumber: state.formValue,
                })
              );
            },
          },
        ].filter(Boolean)}
      >
        <Content marginLeft="md" marginRight="md">
          <PhoneInput
            onChangeText={(value) =>
              dispatch({
                type: 'SET_FORM_VALUE',
                value: getDisplayPhoneNumber({
                  countryCode: value.countryCode,
                  phoneNumber: value.phoneNumber,
                }),
              })
            }
            defaultValue={destructPhoneNumber(state.formValue).phoneNumber}
            value={destructPhoneNumber(state.formValue).phoneNumber}
            onValidityChange={(value) =>
              dispatch({
                type: 'SET_FORM_VALIDITIY',
                value,
              })
            }
            marginTop={0}
            after={
              state.inputIcon && (
                <Icon
                  name={state.inputIcon === 'verified' ? 'rowCheckmarkFilled' : 'rowErrorFilled'}
                  fill={
                    state.inputIcon === 'verified'
                      ? theme.COLORS.PRIMARY_SUCCESS
                      : theme.COLORS.PRIMARY_ALERT
                  }
                />
              )
            }
          />
          <Margin marginTop="md" />
          {(!isVerified || !phoneNumber) && (
            <Subtitle>
              {i18n.t(
                'Vi sender en kode til det angivne telefonnummer.\n\nDu vil blive bedt om at indtaste denne kode for at verificere at dette telefonnummer tilhører dig.'
              )}
            </Subtitle>
          )}
          <Alert
            onRequestClose={() => setDeleteModalVisible(false)}
            visible={deleteModalVisible}
            title={i18n.t('Slet telefonnummer')}
            description={i18n.t(
              'Er du sikker på at du vil slette dit telefonnummer fra din profil?'
            )}
            actions={[
              {
                text: i18n.t('Ja'),
                style: 'destructive',
                onPress: removeUserPhoneNumber,
              },
              {
                text: i18n.t('Nej'),
                style: 'secondary',
                onPress: () => setDeleteModalVisible(false),
              },
            ]}
          />
        </Content>
      </InputPage>
    </ModalProvider>
  );
};

PhoneNumber.propTypes = {
  removeUserPhoneNumber: PropTypes.func,
  phoneNumber: PropTypes.string,
  countryCode: PropTypes.string,
  isDesktop: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  theme: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  pendingVerification: PropTypes.object,
  deletePendingVerifications: initFunc,
  getPendingVerificiations: initFunc,
  requestValidation: initFunc,
  navigateToNotificationSettings: initFunc,
  i18n: PropTypes.object.isRequired,
};

PhoneNumber.defaultProps = {
  phoneNumber: undefined,
  countryCode: undefined,
  removeUserPhoneNumber: undefined,
  requestValidation: undefined,
  pendingVerification: undefined,
  deletePendingVerifications: undefined,
  getPendingVerificiations: undefined,
  navigateToNotificationSettings: undefined,
  onSave: undefined,
};

const mapStateToProps = (state, props) => {
  const { phoneNumber, countryCode } = selectContactInfo(state);

  return {
    ...props,
    phoneNumber,
    countryCode,
    isDesktop: selectIsDeviceTypeDesktop(state),
    isModal: props.route.params?.isModal || false,
    onSave: props.route.params?.onSave,
    pendingVerification: selectPendingVerifications(state, 'phone'),
  };
};

const init = ({ action, getProps }) =>
  action(
    async () => {
      const { dispatch, navigation, showError, i18n, route } = getProps();
      const { token } = route?.params || {};
      let showPendingError = true;

      if (token) {
        try {
          await dispatch(validateContactInformationToken({ type: 'phone', token }));
        } catch (e) {
          showPendingError = false;
          handleValidationErrors(showError, i18n, e);
        }
      }

      await dispatch(getPendingVerificationsAction());
      await dispatch(getContactInfo());

      const { pendingVerification } = getProps();

      if (pendingVerification && showPendingError) {
        showError(i18n.t('Dit telefonnummer er ikke verificeret.'), { dismissable: false });
      }

      return {
        getPendingVerificiations: () =>
          action(async () => dispatch(getPendingVerificationsAction())),
        removeUserPhoneNumber: () =>
          action(
            async () => {
              await dispatch(savePhoneNumber(''));
              await dispatch(getContactInfo());

              await dispatch(deletePendingVerificationsAction('phone'));
              await dispatch(getPendingVerificationsAction());
              dispatch(clearNotificationSettings('phone'));
              Tracking.trackEvent(PHONE_NUMBER.DELETE);

              navigation.goBack();
            },
            { loader: true }
          ),
        requestValidation: ({ countryCode, number }) =>
          action(
            async () => {
              await dispatch(
                verifyContactInformation({
                  phone: {
                    countryCode,
                    number,
                  },
                  hash: await getAndroidAppSignature(),
                })
              );

              Tracking.trackEvent(PHONE_NUMBER.SAVE);
            },
            { loader: true }
          ),
        deletePendingVerifications: () =>
          action(async () => {
            await dispatch(deletePendingVerificationsAction('phone'));
          }),
        navigateToNotificationSettings: () =>
          action(async () => dispatch(navigateToNotificationSettingsAction(true)), {
            loader: true,
          }),
      };
    },
    {
      loader: true,
    }
  );

export default withScreenContainer({
  mapStateToProps,
  init,
})(withTheme(PhoneNumber));
