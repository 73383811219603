import { useNavigation, useRoute, CommonActions } from '@react-navigation/native';
import { useAppSelector } from 'hooks/redux';
import * as routeNames from 'constants/routeNames';
import { shallowEqual } from 'react-redux';
import { selectIsFlowTax } from 'selectors/fetchTaxAndPensionsInfo';
import { useCallback } from 'react';

const { default: Tracking } = require('services/tracking');

export const useNavigateToFetchingScreen = () => {
  const { name } = useRoute();
  const navigation = useNavigation();
  const isFlowTax = useAppSelector(selectIsFlowTax, shallowEqual);

  return useCallback(() => {
    const startTrackingActionName = isFlowTax
      ? 'ClickStartTaxImportButton'
      : 'ClickPensionImportStartButton';

    Tracking.trackEvent({
      area: name,
      action: startTrackingActionName,
    });

    navigation.dispatch(
      CommonActions.navigate({
        name: routeNames.FETCH_TAX_OR_PENSIONSINFO,
        key: isFlowTax ? 'fetch-tax' : 'fetch-pensionsinfo',
      })
    );
  }, [navigation, name, isFlowTax]);
};
