import React from 'react';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import useI18n from 'hooks/useI18n';
import { ADJUST_DISPOSABLE_INCOME } from 'constants/routeNames';
import { ADJUST_CALCULATION_DISPOSABLE_INCOME_ROW } from 'constants/testIds/dashboard/adjustCalculation';
import { selectManualEnteredUserValuesOrSelectedIncomeRow } from 'selectors/dashboard';
import { useAppSelector } from 'hooks/redux';
import AdjustCalculationRow from './AdjustCalculationRow';

const { default: Icon } = require('@sp/ui/base/Icon');
const { Body2, Subtitle } = require('@sp/ui/v2/typography');
const { default: Margin } = require('@sp/ui/base/Margin');

const DisposableIncomeRow: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const { COLORS } = useTheme();
  const navigation = useNavigation();
  const { amount, grossAmount } = useAppSelector((state) =>
    selectManualEnteredUserValuesOrSelectedIncomeRow(state)
  );

  return (
    <AdjustCalculationRow
      testID={ADJUST_CALCULATION_DISPOSABLE_INCOME_ROW}
      componentLeft={<Icon name="rowMoneyBag" fill={COLORS.V2_PRIMARY_BACKGROUND} />}
      textLeft={i18n.t('dashboard|yourIncomeToday')}
      textRight={
        <>
          <Body2 fontWeight="normal" textAlign="right" color={COLORS.V2_SECONDARY_TEXT}>
            {i18n.t('dashboard|netAmountTitle', { amount })}
          </Body2>
          {grossAmount ? (
            <Margin marginTop="xs">
              <Subtitle textAlign="right" fontWeight="normal" color={COLORS.V2_SECONDARY_TEXT}>
                {i18n.t('dashboard|grossAmountTitle', { amount: grossAmount })}
              </Subtitle>
            </Margin>
          ) : undefined}
        </>
      }
      accessibilityLabel={i18n.t('dashboard|incomeTodayA11y', {
        amount,
      })}
      onPress={() => navigation.navigate(ADJUST_DISPOSABLE_INCOME)}
    />
  );
};

export default DisposableIncomeRow;
