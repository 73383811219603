import currencySymbol from 'helpers/currencySymbol';

export const getMonth = (month, i18n) => {
  switch (month && month.toLowerCase()) {
    case 'january':
      return i18n.t('januar');
    case 'february':
      return i18n.t('februar');
    case 'march':
      return i18n.t('marts');
    case 'april':
      return i18n.t('april');
    case 'may':
      return i18n.t('maj');
    case 'june':
      return i18n.t('juni');
    case 'july':
      return i18n.t('juli');
    case 'august':
      return i18n.t('august');
    case 'september':
      return i18n.t('september');
    case 'october':
      return i18n.t('oktober');
    case 'november':
      return i18n.t('november');
    case 'december':
      return i18n.t('december');
    default:
      return month;
  }
};

const months = [
  'januar',
  'februar',
  'marts',
  'april',
  'maj',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'december',
];

const addThousandSeparator = (number, separator = '.') => {
  const elements = number.toString();
  const result = [];
  for (let i = elements.length - 1; i >= 0; i -= 1) {
    const pos = elements.length - 1 - i;
    result.push(elements[i]);
    if (pos > 0 && pos % 3 === 2 && i > 0) {
      result.push(separator);
    }
  }

  // NOTE: We mutate the array in separate statements
  // because array-mutating methods should not be used misleadingly (SonarCloud error)
  result.reverse();
  return result.join('');
};

export const numberWithSeperator = (input, decimalPoints) => {
  let x = input;
  if (typeof decimalPoints !== 'undefined') {
    x = x.toFixed(decimalPoints);
  }
  const parts = x.toString().split('.');
  const isNumberNegativeAndInTheHundreds = parts[0].startsWith('-') && parts[0].length === 4;

  if (!isNumberNegativeAndInTheHundreds) parts[0] = addThousandSeparator(parts[0], '.');

  return parts.join(',');
};

const getCurrencyCode = (value) =>
  currencySymbol(value?.currency) || currencySymbol(value?.currencyCode) || '';

export const getGrossAmountOrAmount = (val, showGrossAmount) => ({
  amount: showGrossAmount ? val?.grossAmount : val?.amount,
  currency: val?.currency || val?.currencyCode,
});

export const formatMoney = (
  value,
  round = false,
  showGrossAmount = false,
  withCurrencyCode = true
) => {
  if (!value || Object.keys(value ?? {}).length === 0) {
    return undefined;
  }

  const updatedValue = getGrossAmountOrAmount(value, showGrossAmount);
  return `${
    updatedValue && typeof updatedValue.amount !== 'undefined'
      ? numberWithSeperator(updatedValue.amount, round ? 0 : 2)
      : '?'
  }${withCurrencyCode ? ` ${getCurrencyCode(updatedValue)}` : ' '}`;
};

export const formatPercentage = (value, decimalPlaces = 2) =>
  `${(value * 100).toFixed(decimalPlaces)} %`.replace('.', ',');

export const formatMonth = (value) => {
  if (typeof value === 'string') {
    value = new Date(value);
  }
  return months[value.getMonth()];
};

export const formatYear = (value) => {
  if (typeof value === 'string') {
    value = new Date(value);
  }
  return value.getFullYear();
};

export const padNumber = (number, size) => {
  let s = String(number);
  while (s.length < (size || 2)) {
    s = `0${s}`;
  }
  return s;
};

// returns date in format DD.MM.YYYY
export const formatDate = (value) => {
  if (typeof value === 'undefined') {
    return undefined;
  }

  if (typeof value === 'string') {
    value = new Date(value);
  }

  const dd = String(value.getDate()).padStart(2, '0');
  const mm = String(value.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = value.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
};

export const formatTime = (value) => {
  if (typeof value === 'string') {
    value = new Date(value);
  }
  const hours = padNumber(value.getHours());
  const minutes = padNumber(value.getMinutes());
  return `${hours}.${minutes}`;
};

export const formatAppointmentSlot = (startsAt, endsAt) =>
  `${formatTime(startsAt)} - ${formatTime(endsAt)}`;

export const getAppointmentEndTime = (startsAt, duration) => {
  if (typeof startsAt === 'string') {
    startsAt = new Date(startsAt);
  }
  const durationParts = duration.split(':').map((t) => parseInt(t, 10));
  const durationTime = (durationParts[0] * 60 * 60 + durationParts[1] * 60) * 1000;
  return new Date(startsAt.getTime() + durationTime);
};

/* Formats a telephone number by adding a space every 2 characters. */
export const formatTelephone = (value) => value.toString().match(/.{2}/g).join(' ');

export const capitalizeFirstLetter = (string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1) || '';

export const withComma = (str) => str.toString().replace('.', ',');

export const accessibilityPrettifier = (text, i18n) =>
  text.replace(i18n.t('moneySymbol'), i18n.t('moneySymbolA11y'));

export const formatNumberWithThousandSeparator = (value) => {
  if (!value) {
    return value;
  }

  if (typeof value === 'number') {
    return numberWithSeperator(value, 0);
  }

  const cleanString = value.replace(/\./g, '');
  const withSeparatorString = isNaN(+cleanString) ? value : numberWithSeperator(+cleanString, 0);

  return withSeparatorString;
};
