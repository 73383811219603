import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';

const Wrapper = styled.View`
  top: ${({ focused }) => (focused ? 10 : 16)}px;
`;

const TabBarIcon = ({ name, focused, theme }) => (
  <Wrapper focused={focused}>
    <Icon
      name={name}
      fill={focused ? theme.COLORS.PRIMARY : theme.COLORS.SECONDARY_TEXT}
      width={24}
      height={24}
    />
  </Wrapper>
);

TabBarIcon.propTypes = {
  name: PropTypes.string.isRequired,
  focused: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

TabBarIcon.defaultProps = {
  focused: false,
};

export default withTheme(TabBarIcon);
