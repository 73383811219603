import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { SafeAreaWrapper } from '@sp/ui/pages/BasePage';

const ModalContext = createContext({
  isModal: false,
  onClose: undefined,
});

export const ModalProvider = ({
  isModal,
  onClose,
  shouldDismissPopupFlow,
  children,
  leftContent,
  iPhoneXOffset,
}) => (
  <ModalContext.Provider
    value={{
      isModal,
      onClose,
      shouldDismissPopupFlow,
      leftContent,
    }}
  >
    {isModal && iPhoneXOffset ? <SafeAreaWrapper>{children}</SafeAreaWrapper> : children}
  </ModalContext.Provider>
);

ModalProvider.propTypes = {
  isModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  shouldDismissPopupFlow: PropTypes.bool,
  leftContent: PropTypes.node,
  iPhoneXOffset: PropTypes.bool,
};

ModalProvider.defaultProps = {
  onClose: undefined,
  leftContent: undefined,
  iPhoneXOffset: true,
  shouldDismissPopupFlow: true,
};

export default ModalContext;
