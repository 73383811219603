import { combineReducers } from 'redux';

import api from './apiReducer';
import security from './securityReducer';
import consent from './consentsReducer';
import reference from './referenceReducer';
import device from './deviceReducer';
import connectivity from './connectivityReducer';
import fetching from './fetchingReducer';
import discovery from './discoveryReducer';
import appRating from './appRatingReducer';
import pushNotification from './pushNotificationReducer';
import pensionsInfo from './pensionsInfoReducer';
import deeplink from './deeplinkReducer';
import flowControl from './flowControlReducer';

export default combineReducers({
  api,
  security,
  consent,
  reference,
  device,
  connectivity,
  fetching,
  discovery,
  appRating,
  pushNotification,
  deeplink,
  flowControl,
  pensionsInfo,
});
