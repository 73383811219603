import { create } from '../helpers/reducers';
import { RESPONSE_FETCH_FEEDBACK_SUBJECTS } from 'constants/actionTypes';

const createDefaults = () => ({
  subjects: [],
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [RESPONSE_FETCH_FEEDBACK_SUBJECTS]: (state, payload) => ({
      ...state,
      subjects: payload.response.subjects.map((s: any) => s.title),
    }),
  },
  createDefaults
);

export type { State };
