import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { isWeb } from '@sp/ui/helpers/device';

const Wrapper = styled.View``;

const AccessibilitySummary = ({ accessibilitySummary, children }) =>
  accessibilitySummary ? (
    <Wrapper
      focusable
      accessibilityRole={isWeb ? 'summary' : 'header'}
      accessibilityLabel={accessibilitySummary}
    >
      {children}
    </Wrapper>
  ) : (
    <>{children}</>
  );

AccessibilitySummary.propTypes = {
  accessibilitySummary: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AccessibilitySummary.defaultProps = {
  accessibilitySummary: undefined,
};

export default AccessibilitySummary;
