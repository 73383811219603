import React from 'react';
import { ViewStyle, ImageStyle, TextStyle } from 'react-native';
import Animated, {
  useAnimatedStyle,
  AnimatedStyleProp,
  interpolate,
} from 'react-native-reanimated';
import styled, { useTheme } from 'styled-components/native';
import Bar from '../Bar';
import Steps from '../Steps';
import type { CurrentProgressType } from '../../../../../app/src/services/reducers/flowControlReducer';
import type { MenuPageProps } from '../index.desktop';

const { default: Margin } = require('@sp/ui/base/Margin');

export const MENU_WIDTH = 350;
const ANIMATION_CLOCK_TIMER = 20;
const TRANSLATE_Y_OFFSET = -20;
const WRAPPER_PADDING = 32;
const Z_INDEX = 9;

type AnimatedStyleUnionType = ViewStyle | ImageStyle | TextStyle | any;

type MenuProps = Pick<MenuPageProps, 'menuHeader'> & {
  menuAnimationClock: Animated.SharedValue<number>;
  progress: CurrentProgressType[];
  hasAnimation?: boolean;
};

const Wrapper = styled.View`
  background-color: ${({ theme }) => theme.COLORS.V2_PRIMARY_BACKGROUND};
  overflow-y: auto;
  flex: 1;
  border-radius: ${({ theme }) => theme.BORDERS.s}px;
  padding: ${WRAPPER_PADDING}px;
  margin-bottom: ${({ theme }) => theme.SPACINGS.xxxl}px;
`;

const Menu: React.FC<MenuProps> = ({ menuHeader, progress, menuAnimationClock, hasAnimation }) => {
  const { BORDERS } = useTheme();

  const animatedStyle = useAnimatedStyle(
    (): AnimatedStyleProp<AnimatedStyleUnionType> => ({
      ...(hasAnimation
        ? {
            opacity: interpolate(menuAnimationClock.value, [0, ANIMATION_CLOCK_TIMER], [0, 1]),
            transform: [
              {
                translateY: interpolate(
                  menuAnimationClock.value,
                  [0, ANIMATION_CLOCK_TIMER],
                  [TRANSLATE_Y_OFFSET, 0]
                ),
              },
            ],
          }
        : {}),
      position: 'relative',
      zIndex: Z_INDEX,
      width: MENU_WIDTH,
      borderRadius: BORDERS.sm,
    })
  );

  return (
    <Animated.View style={animatedStyle}>
      <Wrapper>
        {menuHeader}
        {progress && (
          <>
            <Margin vertical="md">
              <Bar progress={progress} />
            </Margin>
            <Steps progress={progress} />
          </>
        )}
      </Wrapper>
    </Animated.View>
  );
};

export default Menu;
