import React from 'react';
import { H2 } from '@sp/ui/typography';
import PropTypes from 'prop-types';
import Animated from 'react-native-reanimated';
import styled, { withTheme } from 'styled-components/native';
import Conditional from 'decorators/conditional';
import { getCurrentDeviceType } from '@sp/ui/helpers/device';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import Description from '../Description';

const WrapperMobile = styled.View`
  align-items: center;
  padding: ${(props) => props.theme.SPACINGS.md}px ${(props) => props.theme.SPACINGS.md}px 0;
`;

const Wrapper = Conditional({
  mobile: WrapperMobile,
});

const getStringProps = (theme) =>
  getCurrentDeviceType() === 'desktop' ? undefined : { color: theme.COLORS.SECONDARY_BACKGROUND };

const TopContent = ({ heading, description, disableBottomPadding, animation, theme }) => (
  <Wrapper
    disableBottomPadding={disableBottomPadding}
    bottomPadding={description ? theme.SPACINGS.lg : theme.SPACINGS.xxl}
  >
    <Animated.View
      style={
        animation ?? {
          ...animation,
        }
      }
    >
      {heading && renderStringOrComponent(heading, H2, getStringProps(theme))}
    </Animated.View>
    {description && renderStringOrComponent(description, Description, getStringProps(theme))}
  </Wrapper>
);

TopContent.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  animation: PropTypes.object,
  disableBottomPadding: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

TopContent.defaultProps = {
  heading: undefined,
  description: undefined,
  animation: undefined,
  disableBottomPadding: false,
};

export default withTheme(TopContent);
