import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import withComponentContainer from 'decorators/withComponentContainer';
import Margin from '@sp/ui/base/Margin';
import { ModalTitle, ModalRegularText } from './modalTextComponents';

const Wrapper = styled.View`
  flex: 1;
`;

const getModalContent = (type, i18n) => {
  let title;
  let content;

  switch (type) {
    case 'Insurance':
      title = i18n.t('savings|Forsikring');
      content = [
        i18n.t(
          'savings|Bemærk venligst at dækningen på denne police er en forsikring og ikke en opsparing.'
        ),
        i18n.t(
          'savings|For denne dækning er det ikke muligt at vise en opsparet værdi, afkast, omkostninger eller indbetalinger.'
        ),
      ];
      break;
    case 'transfer':
      title = i18n.t('savings|Din overførselssag behandles');
      content = [
        i18n.t(
          'savings|Vi har modtaget information om din ordning fra dit tidligere selskab og er ved at behandle din overførsel. Vi informerer dig, når vi har opdateret dit depot hos os. Beskeden lægges i din digitale postkasse.'
        ),
      ];
      break;
    default:
      title = i18n.t('savings|Intet at vise');
      content = [
        i18n.t(
          'savings|For denne dækning er det ikke muligt at vise en opsparet værdi, afkast, omkostninger eller indbetalinger.'
        ),
        i18n.t('savings|Du er velkommen til at kontakte os, såfremt du vil vide mere.'),
      ];
  }

  return {
    title,
    content,
  };
};

const BlockedPolicyModalContent = ({ i18n, type }) => {
  const { title, content } = getModalContent(type, i18n);

  return (
    <Wrapper>
      <ModalTitle textAlign="center" title={title} />
      {content.map((item, i) => (
        <Fragment key={i}>
          <Margin marginTop="md" />
          <ModalRegularText textAlign="center" text={item} />
        </Fragment>
      ))}
    </Wrapper>
  );
};

BlockedPolicyModalContent.propTypes = {
  i18n: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default withComponentContainer()(BlockedPolicyModalContent);
