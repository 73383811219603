import SetAppIcon from '@sampension/react-native-set-app-icon';
import * as routeNames from 'constants/routeNames';
import persistedStorage from 'services/stores/persisted';
import { isIOS } from 'helpers/platform';

const runCompanySpecificSetup = async (
  companyGroups,
  companyBrandingSetup,
  hasCompletedOnboarding
) => {
  if (!companyGroups) {
    return undefined;
  }
  const companiesWithSpecificSetup = ['AP', 'PJD', 'ISP'];
  const companyGroup = companyGroups?.[0];
  const needsCompanyBrandingSetup =
    companyGroups.length === 1 && companiesWithSpecificSetup.includes(companyGroup);
  const hasWrongCompanyBranding = companyBrandingSetup !== companyGroup;

  if (hasWrongCompanyBranding && needsCompanyBrandingSetup) {
    if (isIOS && (await SetAppIcon.supportsDynamicAppIcon())) {
      try {
        await SetAppIcon.changeIcon(companyGroup);
        await persistedStorage.setItem('companyBrandingSetup', companyGroup);

        return {
          name: routeNames.ONBOARDING,
          params: {
            screen: routeNames.COMPANY_SPECIFICS,
            params: {
              companyGroup,
              iconChanged: true,
              showWelcome: !hasCompletedOnboarding,
            },
          },
        };
      } catch (e) {
        // NOTE: Handling the case when the users icon is all-ready set up correctly,
        // but he does not have his local state aligned,
        // for example hard logout in case of auth token expiration
        if (e.message === 'Icon already in use') {
          await persistedStorage.setItem('companyBrandingSetup', companyGroup);
          return undefined;
        } else {
          throw e;
        }
      }
    } else {
      await persistedStorage.setItem('companyBrandingSetup', companyGroup);

      return hasCompletedOnboarding
        ? undefined
        : {
            name: routeNames.ONBOARDING,
            params: {
              screen: routeNames.COMPANY_SPECIFICS,
              params: {
                companyGroup,
                iconChanged: false,
                showWelcome: true,
              },
            },
          };
    }
  } else if (companyBrandingSetup && !needsCompanyBrandingSetup) {
    if (isIOS && (await SetAppIcon.supportsDynamicAppIcon())) {
      await SetAppIcon.changeIcon(null);
    }
    await persistedStorage.removeItem('companyBrandingSetup');
  }

  return undefined;
};

export default runCompanySpecificSetup;
