import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme, ThemeContext, ThemeProvider } from 'styled-components/native';
import Actions from './Actions';
import type { ActionProps } from 'typings/global';

const { default: LoadingContext } = require('@sp/ui/context/LoadingContext');
const { default: PageParts } = require('@sp/ui/PageParts');
const { default: AccessibilitySummary } = require('@sp/ui/v2/pages/BasePage/AccessibilitySummary');
const { groupActions } = require('@sp/ui/v2/pages/BasePage/Actions/index.shared');
const { default: BackButton } = require('@sp/ui/pages/BasePage/BackButton/index.desktop');

const FIXED_WRAPPER_Z_INDEX = 9;
const HEADER_AND_FOOTER_MENU_HEIGHT = 72;
const CONTENT_WRAPPER_TOP_OFFSET_WITHOUT_MENU = 30;
const WRAPPER_PADDING = 32;
const CONTENT_PADDING = 32;
const BACK_BUTTON_OFFSET_TOP = 77;
const BACK_BUTTON_WIDTH = 100;
const BACK_BUTTON_HEIGHT = 30;

export interface MenuPageProps {
  title?: string;
  disableContentTopPadding?: boolean;
  menu?: React.ReactNode;
  footer?: React.ReactNode;
  testID?: string;
  accessibilitySummary?: string;
  actions: ActionProps;
  actionInfo?: string;
  actionsContent?: React.ReactNode;
  hasMenuAnimation?: boolean;
  hasBackButton?: boolean;
  backButton?: React.ReactNode;
  menuHeader?: React.ReactNode;
  wizard?: React.ReactNode[];
}

const Wrapper = styled.View`
  background: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
  flex-direction: column;
`;

const FixedWrapper = styled.View<{ isLocatedTop?: boolean }>`
  position: fixed;
  overflow: hidden;
  width: 100%;
  z-index: ${FIXED_WRAPPER_Z_INDEX};
  ${({ isLocatedTop }) => (isLocatedTop ? 'top: 0px' : 'bottom: 0px')}
`;

const LoaderWrapper = styled.View`
  position: relative;
  flex: 1;
  align-items: center;
  padding: 0 ${WRAPPER_PADDING}px;
  margin: 0 -${WRAPPER_PADDING}px; // add negative margin so overlay fills to edges
`;

const ContentWrapper = styled.View<{ hasCustomMenu: boolean }>`
  overflow-y: auto;
  padding: ${CONTENT_PADDING}px 0px;
  width: 100%;
  min-height: 100%;
  margin-top: ${({ hasCustomMenu }) =>
    hasCustomMenu ? HEADER_AND_FOOTER_MENU_HEIGHT : CONTENT_WRAPPER_TOP_OFFSET_WITHOUT_MENU}px;
`;

const ActionsContent = styled.View`
  padding: 0 ${WRAPPER_PADDING}px;
`;

const BackButtonWrapper = styled.View`
  justify-content: center;
  position: absolute;
  top: ${BACK_BUTTON_OFFSET_TOP}px;
  left: ${CONTENT_PADDING}px;
  z-index: ${FIXED_WRAPPER_Z_INDEX};
  height: ${BACK_BUTTON_HEIGHT}px;
  width: ${BACK_BUTTON_WIDTH}px;
`;

const MenuPage: React.FC<MenuPageProps> = ({
  menu,
  footer,
  testID,
  accessibilitySummary,
  actions,
  actionInfo,
  actionsContent,
  hasBackButton,
  backButton,
  children,
}) => {
  const groupedActions = groupActions(actions);

  const { loader } = useContext(LoadingContext);

  const hasCustomMenu = typeof menu !== 'undefined';

  const themeContext = useContext(ThemeContext);

  // override default theme to use dark text color on white background,
  // not usual blue from V2 design
  const mobileTheme = {
    ...themeContext,
    COLORS: {
      ...themeContext.COLORS,
      V2_PRIMARY_TEXT: themeContext.COLORS.V2_SECONDARY_TEXT,
    },
  };

  const [containerHeight, setContainerHeight] = useState(0);
  const wrapperRef = useRef<any>();
  const htmlEl = document.querySelector('html');
  const initialContainerHeight = wrapperRef?.current?.clientHeight;

  useEffect(() => {
    if (containerHeight && containerHeight > 0) {
      htmlEl!.style.height = `${containerHeight}px`;
    }

    if (containerHeight && containerHeight !== initialContainerHeight) {
      window.scrollTo(0, 0);
    }
  }, [htmlEl, initialContainerHeight, containerHeight]);

  return (
    <PageParts>
      {({ menu: globalMenu, footer: globalFooter, notifications }: Record<string, any>) => (
        <ThemeProvider theme={mobileTheme as DefaultTheme}>
          <Wrapper
            testID={testID}
            ref={wrapperRef}
            onLayout={(e) => {
              setContainerHeight(e.nativeEvent.layout.height);
            }}
          >
            <AccessibilitySummary accessibilitySummary={accessibilitySummary}>
              <FixedWrapper isLocatedTop>{hasCustomMenu ? menu : globalMenu}</FixedWrapper>

              <BackButtonWrapper>
                {hasBackButton && backButton && (
                  <BackButton
                    onPress={(backButton as any).props.onPress}
                    label={(backButton as any).props.label}
                    theme={mobileTheme}
                  />
                )}
              </BackButtonWrapper>

              <ContentWrapper hasCustomMenu={hasCustomMenu}>
                {children}

                <ActionsContent>{actionsContent}</ActionsContent>

                <Actions
                  horizontalMode
                  transparent
                  actions={groupedActions.bottom}
                  actionInfo={actionInfo}
                />
              </ContentWrapper>

              <FixedWrapper>
                {typeof footer !== 'undefined' ? footer : globalFooter}
                {notifications}
              </FixedWrapper>
            </AccessibilitySummary>
            <LoaderWrapper>{loader}</LoaderWrapper>
          </Wrapper>
        </ThemeProvider>
      )}
    </PageParts>
  );
};

export default MenuPage;
