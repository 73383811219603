import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Body, H3 } from '@sp/ui/v2/typography';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';
import { renderStringOrComponent } from '@sp/ui/helpers/component';

const IconWrapper = styled.View`
  justify-content: center;
  flex-direction: row;
`;

const ContentWithIcon = ({
  isMobile,
  textAlignment,
  title,
  body,
  icon,
  disableMarginBottom,
  theme,
}) => {
  const alternativeTextAlignment = isMobile ? 'center' : 'left';
  const marginTop = isMobile || textAlignment === 'center' ? 'md' : '';
  const derivedTextAlignment = textAlignment ?? alternativeTextAlignment;

  return (
    <Margin marginBottom={!disableMarginBottom && 'md'}>
      {icon && (
        <>
          <IconWrapper>
            <Icon {...icon} fill={icon.fill || theme.COLORS.V2_PRIMARY_TEXT} />
          </IconWrapper>
          <Margin marginTop="md" />
        </>
      )}
      {title && <H3 textAlign={derivedTextAlignment}>{title}</H3>}
      <Margin marginTop={marginTop} />
      {body && renderStringOrComponent(body, Body, { textAlign: derivedTextAlignment })}
    </Margin>
  );
};

ContentWithIcon.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  isDeeplink: PropTypes.bool,
  isMobile: PropTypes.bool,
  textAlignment: PropTypes.string,
  disableMarginBottom: PropTypes.bool,
  icon: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

ContentWithIcon.defaultProps = {
  title: undefined,
  body: undefined,
  textAlignment: undefined,
  isDeeplink: false,
  isMobile: false,
  disableMarginBottom: false,
  icon: undefined,
};

export default withTheme(ContentWithIcon);
