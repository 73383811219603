import { create, Action } from 'helpers/reducers';
import {
  REQUEST_MAILS,
  RESPONSE_MAILS,
  RESPONSE_POLL_MAILS,
  REQUEST_MAIL,
  RESPONSE_MAIL,
  RESPONSE_POLL_MAIL,
  RESPONSE_THREAD_READ,
  REQUEST_THREAD_INBOX,
  REQUEST_THREAD_ARCHIVE,
  RESPONSE_FETCH_MAILBOX_SUBJECTS,
  TOGGLE_SORT_MAILBOX_CONVERSATIONS,
  SET_TYPE,
  PATCH_OVERVIEW_WITH_UPDATED_MAIL,
} from 'constants/actionTypes';
const { getThreadSigningStatus } = require('areas/mailbox/helpers/documents');

const ASC = 'ASC';
const DESC = 'DESC';

const createDefaults = (): any => ({
  conversations: {
    inbox: {
      threads: [],
      isFetching: false,
      hasFetched: false,
    },
    archive: {
      threads: [],
      isFetching: false,
      hasFetched: false,
    },
  },
  source: 'inbox',
  order: DESC,
  conversation: [],
  serviceMessages: {},
});

type State = ReturnType<typeof createDefaults>;

const handleResponseMails: Action = (state, payload, meta) => ({
  ...state,
  serviceMessages: payload.response.serviceMessages,
  conversations: {
    ...state.conversations,
    [meta.requestInfo.source]: {
      ...state.conversations[state.source],
      threads: payload.response.threads,
      hasFetched: true,
      isFetching: false,
    },
  },
});

const handleResponseMail: Action<State> = (state, payload) => ({
  ...state,
  conversation: payload.response.messages,
});

export default create(
  {
    [REQUEST_MAILS]: (state, payload) => ({
      ...state,
      source: payload.source,
      conversations: {
        ...state.conversations,
        [payload.source]: {
          ...state.conversations[payload.source],
          hasFetched: false,
          isFetching: true,
        },
      },
    }),
    [RESPONSE_POLL_MAILS]: (state, payload, meta) => handleResponseMails(state, payload, meta),
    [RESPONSE_MAILS]: (state, payload, meta) => handleResponseMails(state, payload, meta),
    [REQUEST_MAIL]: (state) => ({
      ...state,
      conversation: undefined,
    }),
    [RESPONSE_POLL_MAIL]: (state, payload) => handleResponseMail(state, payload),
    [RESPONSE_MAIL]: (state, payload) => handleResponseMail(state, payload),
    [SET_TYPE]: (state, payload) => ({
      ...state,
      source: payload.source,
    }),
    [RESPONSE_THREAD_READ]: (state, payload) => {
      const conversations = state.conversations[state.source].threads.map((c: any) => {
        if (c.id !== payload.request.id) {
          return c;
        }
        return {
          ...c,
          unread: false,
        };
      });

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [state.source]: {
            ...state.conversations[state.source],
            threads: conversations,
          },
        },
      };
    },
    [REQUEST_THREAD_ARCHIVE]: (state, payload) => {
      const { inbox, archive } = state.conversations;

      return {
        ...state,
        conversations: {
          ...state.conversations,
          archive: {
            ...archive,
            threads: [
              ...archive.threads,
              ...inbox.threads
                .filter((c: any) => c.id === payload.id)
                .map((c: any) => ({ ...c, isArchived: true })),
            ],
          },
          inbox: {
            ...inbox,
            threads: inbox.threads.filter((c: any) => c.id !== payload.id),
          },
        },
      };
    },
    [REQUEST_THREAD_INBOX]: (state, payload) => {
      const { inbox, archive } = state.conversations;

      return {
        ...state,
        conversations: {
          archive: {
            ...archive,
            threads: [...archive.threads.filter((c: any) => c.id !== payload.id)],
          },
          inbox: {
            ...inbox,
            threads: [
              ...inbox.threads,
              ...archive.threads
                .filter((c: any) => c.id === payload.id)
                .map((c: any) => ({ ...c, isArchived: false })),
            ],
          },
        },
      };
    },
    [RESPONSE_FETCH_MAILBOX_SUBJECTS]: (state, payload) => ({
      ...state,
      subjects: payload.response.requestTypes,
    }),
    [TOGGLE_SORT_MAILBOX_CONVERSATIONS]: (state) => ({
      ...state,
      order: state.order === ASC ? DESC : ASC,
    }),
    [PATCH_OVERVIEW_WITH_UPDATED_MAIL]: (state, payload) => {
      const messageIds = payload.messages.map((c: any) => c.id);
      const threadId = messageIds[messageIds.length - 1];

      const conversations = state.conversations[state.source].threads.map((c: any) => {
        if (threadId !== c.id) {
          return c;
        }

        return {
          ...c,
          signingStatus: getThreadSigningStatus(payload.messages),
        };
      });

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [state.source]: {
            ...state.conversations[state.source],
            threads: conversations,
          },
        },
      };
    },
  },
  createDefaults
);

export type { State };
