import React from 'react';
import PropTypes from 'prop-types';
import { Picker as BasePicker } from '@sp/ui/base/inputs';
import { withForm } from 'react-formed';

const Picker = ({ setValue, value, i18n, ...props }) => (
  <BasePicker selectedValue={value} onValueChange={(v) => setValue(v)} {...props} />
);

Picker.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withForm(Picker);
