import { sanFranciscoWeights, webWeights } from 'react-native-typography';
import { FONTS } from '@sp/ui/settings';
import { isIOS, isAndroid } from '@sp/ui/helpers/device';

const fontStyles = (weight) => {
  if (isIOS) {
    return {
      ...sanFranciscoWeights[weight],
      fontFamily: FONTS.IOS,
    };
  }

  if (isAndroid) {
    // NOTE: Android uses different font for each weight
    return {
      includeFontPadding: false,
      backgroundColor: 'transparent',
      fontFamily: FONTS.ANDROID[weight] || FONTS.ANDROID.regular,
    };
  }

  return {
    ...webWeights[weight],
    fontFamily: FONTS.WEB,
  };
};

export default fontStyles;
