import { create } from '../helpers/reducers';

const createDefaults = () => ({
  root: undefined,
  loading: false,
  hasLoaded: false,
  folderHistory: [] as any[],
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    REQUEST_DOCUMENT_FOLDERS: (state) => ({
      ...state,
      loading: true,
    }),
    RESPONSE_DOCUMENT_FOLDERS: (state, payload) => ({
      ...state,
      root: payload.response,
      loading: false,
      hasLoaded: true,
    }),
    PUSH_PREVIOUS_FOLDER: (state, payload) => ({
      ...state,
      folderHistory: [...state.folderHistory, payload.folderId],
    }),
    POP_PREVIOUS_FOLDER: (state) => ({
      ...state,
      folderHistory: state.folderHistory.slice(0, -1),
    }),
  },
  createDefaults
);

export type { State };
