import { DEATH, RETIREMENT } from '../../constants/dashboardTypes';

export const getDescriptionText = (
  retirementAge,
  payoutType,
  i18n,
  showGrossAmount,
  awardRules
) => {
  switch (payoutType) {
    case 'death':
      return showGrossAmount
        ? i18n.t('dashboard|payoutsOverTimeDeathWithGrossDescription')
        : i18n.t('dashboard|payoutsOverTimeDeathDescription');
    case 'disability':
      let disabilityGRADisclaimerText = awardRules?.descriptions?.disabilityGRADisclaimerText
        ? `${awardRules?.descriptions?.disabilityGRADisclaimerText}\r\n\r\n`
        : '';

      return showGrossAmount
        ? disabilityGRADisclaimerText +
            i18n.t('dashboard|payoutsOverTimeDisabilityWithGrossDescription')
        : disabilityGRADisclaimerText + i18n.t('dashboard|payoutsOverTimeDisabilityDescription');
    default:
      return showGrossAmount
        ? i18n.t('dashboard|payoutsOverTimeRetirementWithGrossDescription', { retirementAge })
        : i18n.t('dashboard|payoutsOverTimeRetirementDescription', { retirementAge });
  }
};

export const doesPaymentExist = (perYear, payoutType) =>
  perYear?.find((item) => item[payoutType]?.amount > 0);

export const getGraphLabels = (
  wealthAtPensionAge,
  COLORS,
  payoutType,
  i18n,
  payouts,
  showAverageBar,
  averageBarColor
) => {
  const labels = [];

  if (showAverageBar) {
    labels.push({ name: i18n.t('dashboard|graphLabelAveragePayout'), color: averageBarColor });
  }

  if (payouts?.filter((item) => item.oneTimePayout)?.length > 0) {
    labels.push({
      name: i18n.t('dashboard|graphLabelOnetimePayout'),
      color: COLORS.GRAPH_PAYOUTS_VARIANT5,
    });
  }

  if (doesPaymentExist(payouts, 'ophoerendeUdbetalingTotal')) {
    labels.push({
      name: i18n.t('dashboard|graphLabelYearlyPayout'),
      color: COLORS.GRAPH_PAYOUTS_VARIANT2,
    });
  }

  if (doesPaymentExist(payouts, 'livsvarigUdbetalingTotal')) {
    labels.push({
      name: i18n.t('dashboard|graphLabelLifelongPayout'),
      color: COLORS.GRAPH_PAYOUTS_VARIANT4,
    });
  }

  if (doesPaymentExist(payouts, 'offentligeYdelserTotal')) {
    labels.push({ name: i18n.t('dashboard|graphLabelPublicPayout'), color: COLORS.PRIMARY });
  }

  if (payoutType === RETIREMENT && wealthAtPensionAge) {
    labels.push({
      name: i18n.t('dashboard|graphLabelSavingsPayout'),
      color: COLORS.GRAPH_PAYOUTS_VARIANT1,
    });
  }

  return labels;
};

const addOneTimePayouts = (currentValue, labelName, mergedPayouts) => {
  if (currentValue.engangsudbetalingTotal.amount !== 0) {
    const oneTimePayout = { oneTimePayout: true };
    oneTimePayout.engangsudbetalingTotal = currentValue.engangsudbetalingTotal;
    oneTimePayout.engangsudbetalingDetails = currentValue.engangsudbetalingDetails;
    oneTimePayout[labelName] = currentValue[labelName];
    mergedPayouts.push(oneTimePayout);
  }
};

export const mergePayoutsOverTime = (payoutsPerYear, payoutType, showOneTimePayouts) => {
  // only one payout year exist, so we will return it.
  if (payoutsPerYear?.length === 1) {
    return payoutsPerYear;
  }

  let lastIndex = 0;
  let objectToCompare;
  const labelName = payoutType === DEATH ? 'year' : 'age';
  const mergedPayouts = [];
  let noOfMergedBars = 1;
  payoutsPerYear.forEach((currentValue, index, array) => {
    const roundedAverageToCompare = objectToCompare?.roundedAveragePayoutMonth;
    const currentAverageValues = currentValue?.roundedAveragePayoutMonth;

    if (index === 0) {
      if (showOneTimePayouts) {
        addOneTimePayouts(currentValue, labelName, mergedPayouts);
      }
      [objectToCompare] = payoutsPerYear;
    } else if (
      // objects are identical
      roundedAverageToCompare?.totalIncomeWithoutOneTimePayouts?.amount ===
        currentAverageValues?.totalIncomeWithoutOneTimePayouts?.amount &&
      roundedAverageToCompare?.wealthAtPensionTotal?.amount ===
        currentAverageValues?.wealthAtPensionTotal?.amount &&
      roundedAverageToCompare?.offentligeYdelserTotal?.amount ===
        currentAverageValues?.offentligeYdelserTotal?.amount &&
      roundedAverageToCompare?.livsvarigUdbetalingTotal?.amount ===
        currentAverageValues?.livsvarigUdbetalingTotal?.amount &&
      roundedAverageToCompare?.ophoerendeUdbetalingTotal?.amount ===
        currentAverageValues?.ophoerendeUdbetalingTotal?.amount
    ) {
      // all objects are identical, so we will add the last object
      if (index + 1 === array.length) {
        const newObject = JSON.parse(JSON.stringify(objectToCompare));
        noOfMergedBars += 1;
        newObject.noOfMergedBars = noOfMergedBars;
        // update year or age label
        if (array[lastIndex][labelName] !== array[index][labelName]) {
          newObject[labelName] = `${array[lastIndex][labelName]} - ${array[index][labelName]}`;
        }
        mergedPayouts.push(newObject);
      }
      noOfMergedBars += 1;
    } else {
      const newObject = JSON.parse(JSON.stringify(objectToCompare));
      newObject.noOfMergedBars = noOfMergedBars;
      // update year or age label
      if (array[lastIndex][labelName] !== array[index - 1][labelName]) {
        newObject[labelName] = `${array[lastIndex][labelName]} - ${array[index - 1][labelName]}`;
      }
      mergedPayouts.push(newObject);
      objectToCompare = currentValue;
      lastIndex = index;
      noOfMergedBars = 1;

      if (showOneTimePayouts) {
        addOneTimePayouts(currentValue, labelName, mergedPayouts);
      }

      // add last element
      if (index + 1 === array.length) {
        currentValue.noOfMergedBars = noOfMergedBars;
        mergedPayouts.push(currentValue);
      }
    }
  });
  return mergedPayouts;
};
