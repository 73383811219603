import React from 'react';
import PropTypes from 'prop-types';
import { TopContent } from '@sp/ui/pages/BasePage';
import Margin from '@sp/ui/base/Margin';

export const Content = ({
  title,
  heading,
  animateTitle,
  headingAnimation,
  description,
  children,
}) => (
  <>
    {heading || animateTitle || description ? (
      <TopContent
        heading={animateTitle ? title : heading}
        description={description}
        animation={animateTitle ? headingAnimation : undefined}
      />
    ) : (
      <Margin marginTop="md" />
    )}
    {children}
  </>
);

Content.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  animateTitle: PropTypes.bool,
  headingAnimation: PropTypes.object,
  children: PropTypes.node.isRequired,
};

Content.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  headingAnimation: undefined,
  animateTitle: false,
};

export default Content;
