import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import BasePage, { Card, NarrowContent, Actions, ActionShape } from '@sp/ui/pages/BasePage';
import PageParts from '@sp/ui/PageParts';
import Result from './Result';

const NO_BACK_BUTTON_OFFSET = 69;

const NoBackButtonOffset = styled.View`
  padding-top: ${NO_BACK_BUTTON_OFFSET}px;
`;

const ResultPage = ({
  title,
  heading,
  iconName,
  iconColor,
  iconImage,
  description,
  actions,
  backButton,
  children,
  hasBackButton,
  accessibilitySummary,
}) => (
  <BasePage
    title={title}
    backButton={backButton}
    actions={actions}
    accessibilitySummary={accessibilitySummary}
  >
    <PageParts>
      {({ BackButton: GlobalBackButton }) => (
        <NarrowContent>
          {!hasBackButton && <NoBackButtonOffset />}
          {hasBackButton && (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />)}
        </NarrowContent>
      )}
    </PageParts>
    <NarrowContent>
      <Card>
        <Result
          heading={heading}
          description={description}
          iconName={iconName}
          iconColor={iconColor}
          iconImage={iconImage}
          disableBottomPadding={false}
        />
        {children}
        {actions && actions.length > 0 && <Actions actions={actions} />}
      </Card>
    </NarrowContent>
  </BasePage>
);

ResultPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  actions: PropTypes.arrayOf(ActionShape),
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  iconImage: PropTypes.any,
  children: PropTypes.node,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
};

ResultPage.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  actions: undefined,
  iconImage: undefined,
  iconName: undefined,
  iconColor: undefined,
  children: undefined,
  backButton: undefined,
  accessibilitySummary: undefined,
  hasBackButton: true,
};

export default ResultPage;
