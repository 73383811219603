import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import { getSubjects } from 'actions/feedback';
import * as routeNames from 'constants/routeNames';
import PickerPage from 'containers/pages/PickerPage';
import DeleteDraftAlert from 'areas/general/alerts/DeleteDraftAlert';
import { BackButton } from '@sp/ui/pages/BasePage';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { initFunc } from 'helpers/props';
import { dismiss } from 'helpers/navigation';
import { getActions } from './index.shared';

const FeedbackCreate = ({ subjects, sendFeedback, form, type, navigation, i18n }) => {
  const [isDeleteDraftDialogOpen, setIsDeleteDraftDialogOpen] = useState(false);
  const [shouldGoBackInDialog, setShouldGoBackInDialog] = useState(false);

  const title = type === 'error' ? i18n.t('feedbackErrorTitle') : i18n.t('feedbackTitle');

  const heading = type === 'error' ? i18n.t('feedbackErrorTitle') : i18n.t('feedbackHeading');

  const messagePlaceholder =
    type === 'error'
      ? i18n.t(
          'Del din oplevelse med os. Hvad gik godt? Hvad gik mindre godt? Hvordan kunne det være gået bedre?'
        )
      : i18n.t('Hvad gik godt? Hvad gik mindre godt? Hvordan kunne det være gået bedre?');

  const customBackButton = (
    <BackButton
      onPress={() => {
        setShouldGoBackInDialog(true);

        if (form.content) {
          setIsDeleteDraftDialogOpen(true);
        } else {
          navigation.goBack();
        }
      }}
      label={i18n.t('back')}
    />
  );

  return (
    <ModalProvider
      isModal
      onClose={() => {
        setShouldGoBackInDialog(false);

        if (form.content) {
          setIsDeleteDraftDialogOpen(true);
        } else {
          dismiss(navigation)();
        }
      }}
    >
      <PickerPage
        formName="feedback"
        pickerOptions={subjects}
        resetFormOnMount
        title={title}
        heading={heading}
        description={i18n.t('feedbackPlaceholder')}
        actions={getActions(form, sendFeedback)}
        pickerFormValue={form.subject}
        pickerPlaceholder={i18n.t('feedbackTopicChoice')}
        backButton={customBackButton}
        messagePlaceholder={messagePlaceholder}
      />
      <DeleteDraftAlert
        isOpen={isDeleteDraftDialogOpen}
        setIsOpen={setIsDeleteDraftDialogOpen}
        onClose={shouldGoBackInDialog ? navigation.goBack : dismiss(navigation)}
      />
    </ModalProvider>
  );
};

FeedbackCreate.propTypes = {
  subjects: PropTypes.arrayOf(PropTypes.string),
  sendFeedback: initFunc,
  type: PropTypes.oneOf(['error', 'feedback']).isRequired,
  form: PropTypes.object,
  navigation: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

FeedbackCreate.defaultProps = {
  subjects: undefined,
  sendFeedback: undefined,
  form: {},
};

const mapStateToProps = (state, props) => ({
  form: state.forms.feedback,
  subjects: state.areas.feedback.subjects,
  type: props.route.params?.type,
});

const init = ({ action, props }) =>
  action(
    async () => {
      const { dispatch, navigation, route } = props;
      await dispatch(getSubjects());

      return {
        sendFeedback: () => {
          const type = route.params?.type;
          navigation.navigate(routeNames.FEEDBACK_ADD_CONTACT_INFO, { type });
        },
      };
    },
    {
      loader: true,
    }
  );

export default withScreenContainer({
  mapStateToProps,
  init,
})(FeedbackCreate);
