import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import withTranslation from 'decorators/i18n';
import Margin from '@sp/ui/base/Margin';
import { TwoLineRow, RowBorder } from '@sp/ui/base/rows';
import { H5 } from '@sp/ui/typography';
import { ModalTitle, ModalRegularText } from './modalTextComponents';

const Wrapper = styled.View`
  flex: 1;
`;

const getModalContent = (transferState, isSampensionEmployee, i18n) => {
  let title;
  let description;

  switch (transferState) {
    case 'Blocked':
      title = i18n.t('Din anmodning behandles');
      description = i18n.t(
        'savings|En overførsel kan tage tid. Det skyldes, de tidsfrister brancheforeningen Forsikring & Pension giver pensionsselskaberne i forhold til at ekspedere en overførsel.\n\nNormalt tager det et par måneder, fra du beder om en overførsel til at vi modtager værdien fra den tidligere ordning.\n\nModtager vi værdien fra dit tidligere selskab senere end sjette bankdag i en måned, forhøjer vi først dine pensioner og giver afkast eller depotrente fra den første i den efterfølgende måned.\n\nVi giver dig besked via din digitale postkasse, når vi har modtaget beløbet. Du vil efterfølgende modtage en pensions- og forsikringsoversigt.'
      );
      break;
    case 'Unknown': {
      if (isSampensionEmployee) {
        title = i18n.t('savings|Kontakt os');
        description = i18n.t(`savings|Du har bedt om at få overført denne ordning til os.
        \nVi kan desværre ikke oplyse dig om status på overførslen i app'en - du bedes kontakte en af de rådgivere der er tilknyttet medarbejdere i {{COMPANY_NAME}}`);
      } else {
        title = i18n.t('savings|Kontakt os');
        description = i18n.t(`savings|Du har bedt om at få overført denne ordning til os.
        \nVi kan desværre ikke oplyse dig om status på overførslen i app'en - du bedes kontakte os i stedet.`);
      }
      break;
    }
    default:
      // 'Outdated'
      title = i18n.t('savings|Opdater venligst tal fra PensionsInfo');
      description = i18n.t(`savings|Du har bedt om at få overført denne ordning til os.
      \nFor at vi kan vise dig den nuværende status på overførslen, skal du opdatere dine oplysninger fra PensionsInfo.`);
  }

  return {
    title,
    description,
  };
};

const TransferredPolicyModalContent = ({
  item,
  transferState,
  showDetails,
  isInternal,
  isSampensionEmployee,
  i18n,
}) => {
  const { title, description } = getModalContent(transferState, isSampensionEmployee, i18n);

  return (
    <Wrapper>
      <ModalTitle textAlign="center" title={title} />
      <Margin marginTop="md" marginBottom="xl">
        <ModalRegularText textAlign="center" text={description} />
        {isInternal && (
          <Margin marginTop="xl" marginBottom="md">
            <RowBorder />
            <TwoLineRow
              arrow
              textLeft={<H5 numberOfLines={1}>{item.title}</H5>}
              textRight={
                <H5 fontWeight="regular" textAlign="center">
                  {i18n.t('savings|Uddybende\ninformation')}
                </H5>
              }
              subtitleLeft={i18n.t('savings|Police: {{ policyId }}', { policyId: item.policyId })}
              onPress={() => showDetails()}
            />
            <RowBorder />
          </Margin>
        )}
      </Margin>
    </Wrapper>
  );
};

TransferredPolicyModalContent.propTypes = {
  showDetails: PropTypes.func,
  isInternal: PropTypes.bool,
  item: PropTypes.object.isRequired,
  transferState: PropTypes.string.isRequired,
  isSampensionEmployee: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
};

TransferredPolicyModalContent.defaultProps = {
  showDetails: undefined,
  isInternal: undefined,
};

export default withTranslation()(TransferredPolicyModalContent);
