import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import { ADJUST_SAVINGS } from 'constants/routeNames';
import { ADJUST_CALCULATION_SAVINGS_ROW } from 'constants/testIds/dashboard/adjustCalculation';
import { selectRetirementWealth } from 'selectors/dashboard';
import AdjustCalculationRow from './AdjustCalculationRow';

const { default: Icon } = require('@sp/ui/base/Icon');
const { Body2 } = require('@sp/ui/v2/typography');
const { formatMoney } = require('helpers/string');

const SavingsRow: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const navigation = useNavigation();
  const { COLORS } = useTheme();

  const retirementWealth: any = useAppSelector(selectRetirementWealth);
  const wealthAtPensionAge = parseInt(retirementWealth || 0, 10);

  return (
    <AdjustCalculationRow
      testID={ADJUST_CALCULATION_SAVINGS_ROW}
      componentLeft={<Icon name="rowMoneyBag" fill={COLORS.V2_PRIMARY_BACKGROUND} />}
      textLeft={i18n.t('dashboard|savings')}
      textRight={
        <Body2 fontWeight="normal" color={COLORS.V2_SECONDARY_TEXT}>
          {formatMoney({ amount: wealthAtPensionAge, currency: 'DKK' }, true)}
        </Body2>
      }
      onPress={() => navigation.navigate(ADJUST_SAVINGS)}
    />
  );
};

export default SavingsRow;
