import React from 'react';
import PropTypes from 'prop-types';
import Card from '@sp/ui/base/Card';
import Icon from '@sp/ui/base/Icon';
import styled, { withTheme } from 'styled-components/native';
import Margin from '@sp/ui/base/Margin';
import { Subtitle } from '@sp/ui/typography';
import { Container, IconContainer, ItemHeader, BodyWrapper, SubtitleWrapper } from '../styles';
import { getPensionSubtitle } from './helpers';
import useI18n from 'hooks/useI18n';

const SUBTITLE_MARGIN_LEFT = 46;

const DotSubtitleWrapper = styled.View`
  flex-direction: row;
  margin-left: ${SUBTITLE_MARGIN_LEFT}px;
`;

const GenericError = ({
  theme,
  type,
  buttonComponent,
  title,
  subtitle,
  description,
  pensionStatus,
  errorId,
  listItems,
}) => {
  let pensionSubtitle;
  const { i18n } = useI18n(['dashboardErrors']);

  if (pensionStatus) {
    pensionSubtitle = getPensionSubtitle(errorId, pensionStatus, i18n);
  } else if (listItems.length > 0) {
    pensionSubtitle = listItems;
  }

  return (
    <Container>
      <Card title={title} type={type} buttonComponent={buttonComponent}>
        <ItemHeader>
          <IconContainer>
            <Icon name="exclamationMarkFilled" fill={theme.COLORS.PRIMARY_WARNING} />
          </IconContainer>
          <BodyWrapper>{description}</BodyWrapper>
        </ItemHeader>
        {pensionSubtitle && pensionSubtitle.length > 0 && (
          <Margin marginTop="xs">
            <DotSubtitleWrapper>
              <Subtitle>{pensionSubtitle.map((item) => `\n•\t ${item}`)}</Subtitle>
            </DotSubtitleWrapper>
          </Margin>
        )}
        {subtitle ? <SubtitleWrapper>{subtitle}</SubtitleWrapper> : <Margin marginBottom="sm" />}
      </Card>
    </Container>
  );
};

GenericError.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  type: PropTypes.string,
  subtitle: PropTypes.string,
  pensionStatus: PropTypes.object,
  buttonComponent: PropTypes.node,
  errorId: PropTypes.string,
  listItems: PropTypes.array,
};

GenericError.defaultProps = {
  type: 'warning',
  errorId: undefined,
  subtitle: undefined,
  pensionStatus: undefined,
  buttonComponent: undefined,
  listItems: [],
};

export default withTheme(GenericError);
