import { formatDate } from 'helpers/string';

export const getPoliceTimestamp = (policyId, savingsData) => {
  const { internalSavings, timestamp: externalPoliceTimestamp } = savingsData ?? {};
  const { timestamp: internalPoliceTimestamp } =
    internalSavings?.find((item) => item.coverageNumber === policyId) ?? {};
  const timestamp = internalPoliceTimestamp || externalPoliceTimestamp;

  return timestamp ? formatDate(timestamp) : '';
};
