import React from 'react';
import styled from 'styled-components/native';

const Wrapper = styled.View``;

type Props = {
  children: React.ReactNode;
};

const PointerEventsNoneWrapper: React.FC<Props> = ({ children }) => (
  <Wrapper pointerEvents="none">{children}</Wrapper>
);

export default PointerEventsNoneWrapper;
