import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTheme } from 'styled-components/native';
import withTranslation from 'decorators/i18n';
import { TwoLineRow } from '@sp/ui/base/rows';
import Modal from 'containers/layout/Modal';
import { H5 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import BlockedPolicyModalContent from '../modal/BlockedPolicyModalContent';
import { getSavingsAccessibility } from '../../helpers/getSavingsAccessibility';
import Tracking from 'services/tracking';
import { CLICK_BLOCKED_POLICY } from 'areas/savings/trackingIds';

const BlockedPolicy = ({ item, i18n, theme }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const accessibility = getSavingsAccessibility({
    components: ['BlockedPolicy'],
    props: {
      policyName: item.title,
      policyId: item.policyId,
    },
  });

  return (
    <>
      <TwoLineRow
        arrow={item.type !== 'transfer'}
        {...accessibility.BlockedPolicy}
        textLeft={
          item.title && (
            <H5 numberOfLines={1} fontWeight="bold">
              {item.title}
            </H5>
          )
        }
        subtitleLeft={
          item.policyId && i18n.t('savings|Police: {{ policyId }}', { policyId: item.policyId })
        }
        componentRight={
          item.type === 'transfer' && <Icon fill={theme.COLORS.GRAPH_INCOME} name="awaiting" />
        }
        onPress={() => {
          Tracking.trackEvent(CLICK_BLOCKED_POLICY);
          setModalVisible(true);
        }}
      />
      <Modal visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
        <BlockedPolicyModalContent type={item.type} />
      </Modal>
    </>
  );
};

BlockedPolicy.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  item: PropTypes.object,
};

BlockedPolicy.defaultProps = {
  item: undefined,
};

export default compose(withTranslation(), withTheme)(BlockedPolicy);
