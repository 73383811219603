import React from 'react';
import PropTypes from 'prop-types';
import { RowWithRadioButton, RowWithCheckbox } from '@sp/ui/v2/base/inputs';
import { isPolicySelected, SELECTION_TYPE_SINGLE, SELECTION_TYPE_MULTIPLE } from './helpers';

const Policy = ({ policy, state, companyGroup, onPolicyPress, selectionType }) => {
  const Row = selectionType === SELECTION_TYPE_SINGLE ? RowWithRadioButton : RowWithCheckbox;

  return (
    <Row
      testID={`policyRadioButton-${policy.policyId}`}
      titleLeft={policy.textLeft}
      titleRight={policy.textRight}
      subtitleLeft={policy.subtitleLeft}
      subtitleRight={policy.subtitleRight}
      checked={isPolicySelected(state, policy)}
      isSelected={isPolicySelected(state, policy)}
      onPress={() => onPolicyPress(policy.policyId, companyGroup)}
    />
  );
};

Policy.propTypes = {
  policy: PropTypes.object.isRequired,
  state: PropTypes.array.isRequired,
  companyGroup: PropTypes.string.isRequired,
  onPolicyPress: PropTypes.func.isRequired,
  selectionType: PropTypes.oneOf([SELECTION_TYPE_SINGLE, SELECTION_TYPE_MULTIPLE]),
};

Policy.defaultProps = {
  selectionType: SELECTION_TYPE_SINGLE,
};

export default Policy;
