import type { TransferState } from 'reducers/transfer';

const { default: features } = require('features');

export const getTransferDetails = (transferData: TransferState['data']) => {
  const { policies, targets, userCanTransfer, userCanTransferCode, consents } = transferData ?? {};

  const validTransferTargets = targets
    ?.filter((item) => item.validSources || item.blocked)
    ?.map((item) => ({
      ...item,
      ...policies.find((policy) => item.policyId === policy.policyId),
    }));

  return {
    transferTargets: validTransferTargets,
    userCanTransfer,
    isTransferButtonActive:
      features.isEnabled('savingsTransfer') &&
      validTransferTargets?.length > 0 &&
      validTransferTargets?.some((item) => !item.blocked) &&
      userCanTransfer,
    hasOneTransferableTarget: validTransferTargets?.length === 1,
    hasTechnicalTransferError: userCanTransferCode === 5,
    processAwarenessInformation: consents?.some((item) => item.key === 'TransferRateGuardInfo'),
  };
};
