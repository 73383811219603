import withScreenContainer from 'decorators/withScreenContainer';
import { createSigningUrl, fetchIndividual, completeSigning } from 'actions/mailbox';
import Signing from 'areas/signing/Signing';
import Tracking from 'services/tracking';
import { SIGNING_SUCCESS } from 'areas/mailbox/trackingIds';
import { hasMitIdApp } from 'helpers/handleAppSwitch';

export default withScreenContainer({
  init: ({ action, getProps, setProps }) =>
    action(
      async () => {
        const { dispatch, navigation, route } = getProps();
        const { id, threadId, documentId } = route.params || {};
        const hasMitId = await hasMitIdApp();
        const { signingUrl } = await dispatch(createSigningUrl(id, threadId, documentId, hasMitId));

        const onSuccess = () =>
          action(async () => {
            await dispatch(completeSigning(id, threadId, documentId));
            if (threadId) {
              await dispatch(fetchIndividual(threadId));
            }

            Tracking.trackEvent(SIGNING_SUCCESS);
            navigation.goBack();
          });
        const onFailure = () => navigation.goBack();
        setProps({
          url: signingUrl,
          onCompleted: onSuccess,
          onCancelled: onFailure,
        });
      },
      {
        loader: true,
      }
    ),
})(Signing);
