import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';

const H6 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.H6.DESKTOP}px;
  line-height: ${(props) => props.theme.FONT_SIZES.H6.DESKTOP * 1.5}px;
`;

H6.defaultProps = {
  fontWeight: 'bold',
};

export default H6;
