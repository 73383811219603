import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Image from 'containers/theme/ConditionalImage';
import { isIphone5 } from '@sp/ui/helpers/device';
import withTranslation from 'decorators/i18n';
import { Wrapper, Description } from './index.shared.js';

const TextWrapper = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 ${({ theme }) => theme.SPACINGS.xxl}px;
  position: absolute;
  top: ${isIphone5() ? '25%' : '36%'};
  width: 100%;
`;

const Slide1 = ({ theme, description, userName, backgroundImage }) => {
  const displayText = userName ? description.replace('kollega', userName) : description;

  return (
    <Wrapper>
      <Image {...backgroundImage}>
        <TextWrapper>
          <Description color={theme.COLORS.SECONDARY_BACKGROUND} textAlign="center">
            {displayText}
          </Description>
        </TextWrapper>
      </Image>
    </Wrapper>
  );
};

Slide1.propTypes = {
  description: PropTypes.string,
  backgroundImage: PropTypes.shape({
    mobile: PropTypes.any,
    desktop: PropTypes.any,
  }),
  theme: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
};

Slide1.defaultProps = {
  description: undefined,
  backgroundImage: undefined,
};

export default withTranslation()(withTheme(Slide1));
