import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import { updateCurrentProgress, updateFlowControl } from 'actions/flowControl';
import { selectFlowControl } from 'selectors/flowControl';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useGetUpdatedCurrentProgress } from './useGetUpdatedCurrentProgress';

export const useUpdateCurrentProgress = () => {
  const dispatch = useAppDispatch();
  const { name } = useRoute();
  const { activeProgressIndexes, currentProgress } =
    useAppSelector(selectFlowControl, shallowEqual) ?? {};

  const getUpdatedCurrentProgress = useGetUpdatedCurrentProgress();

  return useCallback(
    (res?: { initialize?: boolean; step?: string }) => {
      const { step, initialize } = res ?? {};
      const derivedStep = step ?? name;

      if ((initialize || step) && currentProgress) {
        const updatedCurrentProgress = getUpdatedCurrentProgress({
          step: derivedStep,
          activeProgressIndexes,
        });

        dispatch([
          updateCurrentProgress(updatedCurrentProgress),
          updateFlowControl({ activeProgress: derivedStep }),
        ]);
      }
    },
    [name, currentProgress, activeProgressIndexes, getUpdatedCurrentProgress, dispatch]
  );
};
