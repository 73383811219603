import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Margin from '@sp/ui/base/Margin';
import Icon from '@sp/ui/base/Icon';
import { shadow2 } from '@sp/ui/helpers/style';
import { Button } from '@sp/ui/v2/base/buttons';
import { Body2 } from '@sp/ui/v2/typography';
import { ActionShape } from './index.shared';

const getWrapperBottomPadding = (hasExtraBottomOffset, theme) => {
  return hasExtraBottomOffset ? 0 : `${theme.SPACINGS.md}px`;
};

// NOTE: "z-index: -1" is needed to have the ability to place absolute elements over buttons
const Wrapper = styled.View`
  z-index: ${({ zIndex }) => zIndex};
  ${({ theme, transparent, disableShadow, hasExtraBottomOffset }) => {
    return `
      background: ${transparent ? 'transparent' : theme.COLORS.SECONDARY_BACKGROUND};
      padding: 0 ${theme.SPACINGS.xl}px ${getWrapperBottomPadding(hasExtraBottomOffset, theme)} ${
      theme.SPACINGS.xl
    }px;
      border-top-right-radius: ${theme.BORDERS.m}px;
      border-top-left-radius: ${theme.BORDERS.m}px;
      ${!transparent && !disableShadow ? shadow2 : ''}
    `;
  }};
`;

const Actions = ({
  actions,
  actionInfo,
  actionsContent,
  disableShadow,
  disableMarginTop,
  hideActionsContent,
  theme,
  zIndex,
  transparent,
}) => {
  const singleAction = actions?.length === 1;
  const insets = useSafeAreaInsets();
  const iconFill = (action) => {
    switch (action.type) {
      case 'secondary':
        return theme.COLORS.PRIMARY;
      case 'accessory':
        return theme.COLORS.PRIMARY_TEXT;
      default:
        return theme.COLORS.SECONDARY_BACKGROUND;
    }
  };

  return (
    <Wrapper
      center={singleAction}
      zIndex={zIndex}
      disableShadow={disableShadow}
      hasExtraBottomOffset={insets.bottom > 0}
      transparent={transparent}
    >
      {!hideActionsContent && actionsContent && <Margin vertical="md">{actionsContent}</Margin>}
      {actionInfo && (
        <Margin vertical="md">
          {typeof actionInfo === 'string' ? (
            <Body2 secondaryColor fontWeight="normal">
              {actionInfo}
            </Body2>
          ) : (
            actionInfo
          )}
        </Margin>
      )}
      {actions.map((action, key) => {
        if (isValidElement(action.element)) return action.element;

        return (
          <Margin
            key={action.text || action.icon}
            marginTop={!disableMarginTop && !actionsContent && !actionInfo && key === 0 && 'lg'}
            marginBottom={actions.length - 1 !== key && 'md'}
          >
            <Button
              testID={action.testID}
              size="large"
              before={action.icon && <Icon name={action.icon} fill={iconFill(action)} />}
              {...action}
            />
          </Margin>
        );
      })}
    </Wrapper>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(ActionShape),
  theme: PropTypes.shape({
    COLORS: PropTypes.shape({
      PRIMARY: PropTypes.string.isRequired,
      SECONDARY_BACKGROUND: PropTypes.string.isRequired,
      PRIMARY_TEXT: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  zIndex: PropTypes.number,
  actionInfo: PropTypes.string,
  actionsContent: PropTypes.node,
  hideActionsContent: PropTypes.bool,
  disableShadow: PropTypes.bool,
  disableMarginTop: PropTypes.bool,
  hasMarginTop: PropTypes.bool,
  transparent: PropTypes.bool,
};

Actions.defaultProps = {
  actions: [],
  zIndex: -1,
  disableShadow: false,
  disableMarginTop: false,
  hideActionsContent: false,
  autoSlideToBottomGesture: undefined,
  autoSlideToTopGesture: undefined,
  actionInfo: undefined,
  actionsContent: undefined,
  hasMarginTop: false,
  transparent: false,
};

export default withTheme(Actions);
