import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import { MobileAndTabletTabs, MobileAndDesktopIconWrapper } from './index.shared';

const VERTICAL_TAB_PADDING = 24;

const Tab = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${VERTICAL_TAB_PADDING}px 0;
  ${({ backgroundColor, focused, theme }) =>
    `background-color: ${focused ? theme.COLORS.SECONDARY_BACKGROUND : backgroundColor}`};
`;

const TopTabBar = ({ tabs, focusedTab, theme }) => (
  <MobileAndTabletTabs>
    {tabs.map((tab) => {
      const isFocused = tab.id === focusedTab;
      return (
        <Tab
          testID={tab.testID}
          key={tab.id}
          focused={isFocused}
          onPress={tab.onPress}
          backgroundColor={tab.backgroundColor}
          accessibilityRole="tab"
          accessibilityState={{
            selected: isFocused,
          }}
        >
          {tab.iconName && (
            <MobileAndDesktopIconWrapper>
              <Icon
                name={tab.iconName}
                fill={isFocused ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_TEXT}
              />
            </MobileAndDesktopIconWrapper>
          )}
          <Body
            fontWeight={isFocused ? 'bold' : 'regular'}
            color={isFocused ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_TEXT}
          >
            {tab.title}
          </Body>
        </Tab>
      );
    })}
  </MobileAndTabletTabs>
);

TopTabBar.propTypes = {
  focusedTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
      iconName: PropTypes.string.isRequired,
    })
  ).isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(TopTabBar);
