import React, { Fragment } from 'react';
import { useAppSelector } from 'hooks/redux';

const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Markdown } = require('@sp/ui/v2/base/Markdown');
const { H3, H5, Body } = require('@sp/ui/v2/typography');
const { selectIsDeviceTypeDesktop } = require('selectors/device');

type Props = {
  texts: {
    h3?: string;
    h5?: string;
    body?: string;
    markdown?: string;
  }[];
};

const ModalContent: React.FC<Props> = ({ texts }) => {
  const isDesktop = useAppSelector((state) => selectIsDeviceTypeDesktop(state));

  return (
    <>
      {texts.map(({ h3, h5, body, markdown }, i) => {
        const isLast = i === texts.length - 1;

        return (
          <Fragment key={`${i + 1}`}>
            {h3 && (
              <>
                <H3 textAlign="center">{h3}</H3>
                <Margin marginBottom="lg" />
              </>
            )}
            {h5 && (
              <>
                <H5>{h5}</H5>
                <Margin marginBottom="lg" />
              </>
            )}
            {body && (
              <>
                <Body>{body}</Body>
                {!isLast && <Margin marginBottom="md" />}
              </>
            )}
            {markdown && (
              <>
                <Markdown isDesktop={isDesktop}>{markdown}</Markdown>
                {!isLast && <Margin marginBottom="md" />}
              </>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default ModalContent;
