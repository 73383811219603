import React from 'react';
import PropTypes from 'prop-types';
import { H5 } from '@sp/ui/typography';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';

const FOCUSED_LINE_POSITION = -26;
const FOCUSED_LINE_HEIGHT = 4;
const LINK_MARGIN_LEFT = 8;

const MenuItem = styled.TouchableOpacity``;

const Content = styled.View`
  position: relative;
  flex-direction: row;
  align-items: center;
`;

const Line = styled.View`
  background: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  position: absolute;
  width: 100%
  height: ${FOCUSED_LINE_HEIGHT}px;
  bottom: ${FOCUSED_LINE_POSITION}px;
  left: 0;
`;

const LinkWrapper = styled.View`
  margin-left: ${LINK_MARGIN_LEFT}px;
`;

const Item = ({ name, icon, onPress, focused, compact, theme }) => (
  <MenuItem
    key={name}
    onPress={onPress}
    focused={focused}
    compact={compact}
    accessibilityRole="button"
  >
    <Content>
      {icon && <Icon name={icon} fill={theme.COLORS.SECONDARY_BACKGROUND} />}
      <LinkWrapper>
        <H5 fontWeight={focused ? 'bold' : 'regular'} color={theme.COLORS.SECONDARY_BACKGROUND}>
          {name}
        </H5>
      </LinkWrapper>

      {focused && <Line />}
    </Content>
  </MenuItem>
);

Item.propTypes = {
  name: PropTypes.string.isRequired,
  focused: PropTypes.bool,
  compact: PropTypes.bool,
  icon: PropTypes.any,
  onPress: PropTypes.func,
  theme: PropTypes.object.isRequired,
};

Item.defaultProps = {
  icon: undefined,
  focused: false,
  compact: false,
  onPress: () => {},
};

export default withTheme(Item);
