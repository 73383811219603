import React from 'react';
import useAsync from './useAsync';
const { default: GenericError } = require('areas/system/GenericError');
const { default: BlockingLoader } = require('containers/base/BlockingLoader');

const usePrerenderScreen = () => {
  const { loading, errors } = useAsync();

  if (loading) {
    return <BlockingLoader />;
  }

  if (errors.length > 0) {
    return <GenericError retryFn={errors[0].retry} />;
  }

  return undefined;
};

export default usePrerenderScreen;
