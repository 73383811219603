import React from 'react';
import PropTypes from 'prop-types';
import TopBar from './TopBar';
import MenuBar from './MenuBar';
import Conditional from 'decorators/conditional';

const MenuBarWrapper = Conditional({
  desktop: MenuBar,
});

const Menu = ({
  visible,
  onPressLogout,
  menuItems,
  showProfile,
  showLogoutButton,
  userName,
  onPressUserName,
  logOutLabel,
  hideLogo,
}) => (
  <>
    {visible && (
      <TopBar
        showProfile={showProfile}
        showLogoutButton={showLogoutButton}
        onPressLogout={onPressLogout}
        userName={userName}
        logOutLabel={logOutLabel}
        onPressUserName={onPressUserName}
        showBottomBorder={!menuItems}
        hideLogo={hideLogo}
      />
    )}

    {menuItems && <MenuBarWrapper menuItems={menuItems} />}
  </>
);

Menu.propTypes = {
  visible: PropTypes.bool,
  showProfile: PropTypes.bool,
  showLogoutButton: PropTypes.bool,
  onPressLogout: PropTypes.func,
  menuItems: PropTypes.array,
  userName: PropTypes.string,
  onPressUserName: PropTypes.func,
  logOutLabel: PropTypes.string.isRequired,
};

Menu.defaultProps = {
  visible: true,
  showProfile: false,
  showLogoutButton: false,
  userName: undefined,
  onPressLogout: undefined,
  menuItems: undefined,
  onPressUserName: undefined,
};

export default Menu;
