import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import styled, { withTheme } from 'styled-components/native';
import Conditional from 'decorators/conditional';
import withScreenContainer from 'decorators/withScreenContainer';
import { doInfoFlow, completeInfoFlow } from 'actions/onboarding';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import sessionStorage from 'services/stores/session';
import Tracking from 'services/tracking';
import * as routeNames from 'constants/routeNames';
import { initFunc } from 'helpers/props';
import BasePage, { Actions, SafeAreaWrapper } from '@sp/ui/v2/pages/BasePage';
import SwiperPage from '@sp/ui/pages/SwiperPage';
import Menu from 'containers/navigation/Menu';
import Margin from '@sp/ui/base/Margin';
import { Appear } from '@sp/ui/v2/animations';
import { H3, Body, Body2 } from '@sp/ui/v2/typography';

import mobileImage from 'assets/images/info/mobile_portrait.png';
import tabletImage from 'assets/images/info/tablet_portrait.png';
import landscapeImage from 'assets/images/info/tablet_landscape.png';

import DeviceGradient from 'containers/DeviceGradient';

const Page = Conditional({
  default: BasePage,
  desktop: SwiperPage,
});

const TextWrapper = Conditional({
  landscape: styled.View`
    flex: 4;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: ${({ theme }) => theme.SPACINGS.xl}px;
  `,
  default: styled.View`
    align-items: center;
    justify-content: flex-end;
  `,
});

const Wrapper = Conditional({
  default: Fragment,
  desktop: styled.View`
    flex: 1;
    background-color: ${({ theme }) => theme.COLORS.V2_PRIMARY_BACKGROUND};
  `,
});

const ContentWrapper = Conditional({
  landscape: styled.View`
    flex: 6;
    align-items: center;
  `,
  default: styled.View`
    flex: 8;
    align-items: center;
  `,
});

const ActionsWrapper = styled.View`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const InfoTextWrapper = styled.Text``;

const Info = ({ i18n, theme, route, onNext, onSkip, isDesktop }) => {
  const hasBackButton = route.params?.hasBackButton;

  return (
    <Page menu={<Menu />} hasBackButton={hasBackButton}>
      <Wrapper>
        <TextWrapper>
          <Margin horizontal="xl" marginBottom="xl" marginTop={isDesktop && 'xl'}>
            <H3 textAlign="center" color={theme.COLORS.V2_PRIMARY_TEXT}>
              {i18n.t('onboarding|infoScreenHeading')}
            </H3>
            <Margin marginTop="md">
              <Body textAlign="center" color={theme.COLORS.V2_PRIMARY_TEXT}>
                {i18n.t('onboarding|infoScreenDescription')}
              </Body>
            </Margin>
          </Margin>
        </TextWrapper>
        <ContentWrapper>
          <Appear animationType="OutExp" duration={3000} delay={100}>
            <DeviceGradient
              mobileImage={mobileImage}
              tabletImage={tabletImage}
              landscapeImage={landscapeImage}
            />
          </Appear>
          <ActionsWrapper>
            <Appear animationType="OutLin" duration={600}>
              <Actions
                horizontalMode
                actionInfo={
                  <InfoTextWrapper>
                    <Trans i18nKey="onboarding|infoScreenActionInfo">
                      <Body2 fontWeight="regular" secondaryColor>
                        Vi anbefaler at du henter dine oplysninger fra{' '}
                      </Body2>
                      <Body2 fontWeight="semibold" secondaryColor>
                        PensionsInfo
                      </Body2>
                      <Body2 fontWeight="regular" secondaryColor>
                        {' '}
                        og{' '}
                      </Body2>
                      <Body2 fontWeight="semibold" secondaryColor>
                        Skatteforvaltningen
                      </Body2>
                      <Body2 fontWeight="regular" secondaryColor>
                        . Det tager kun et par minutter.
                      </Body2>
                    </Trans>
                  </InfoTextWrapper>
                }
                actions={[
                  {
                    text: i18n.t('onboarding|doItLater'),
                    mobilePosition: 'bottom-sticky',
                    type: 'secondary',
                    onPress: onSkip,
                    testID: 'onboardingInfoSkipButton',
                  },
                  {
                    text: i18n.t('onboarding|consentsNextButton'),
                    mobilePosition: 'bottom-sticky',
                    onPress: onNext,
                  },
                ]}
              />
              <SafeAreaWrapper secondaryColor />
            </Appear>
          </ActionsWrapper>
        </ContentWrapper>
      </Wrapper>
    </Page>
  );
};

Info.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  onNext: initFunc,
  onSkip: initFunc,
};

Info.defaultProps = {
  startAnimation: false,
  onNext: undefined,
  onSkip: undefined,
};

const mapStateToProps = (state) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['onboarding'],
  init: ({ getProps }) => ({
    onNext: () => {
      const { dispatch } = getProps();
      Tracking.trackEvent({ area: routeNames.INFO, action: 'ClickStartTaxPensionImport' });
      dispatch(doInfoFlow());
    },
    onSkip: () => {
      const { dispatch } = getProps();
      sessionStorage.setItem('skippedOnboardingInfo', true);
      Tracking.trackEvent({ area: routeNames.INFO, action: 'ClickSkipTaxPensionImport' });
      dispatch(completeInfoFlow());
    },
  }),
})(withTheme(Info));
