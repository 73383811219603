import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
// import tracingOrigins from './tracingOrigins';

export const routingInstrumentation = undefined;

const getEnv = () => {
  const host = global.location.hostname;
  switch (host) {
    case 'login.sampension.dk':
      return 'prod';
    case 'login-staging.sampension.dk':
      return 'staging';
    case 'login-test.sampension.dk':
      return 'test';
    case 'login-dev.sampension.dk':
      return 'dev';
    default:
      return 'local';
  }
};

if (!__DEV__) {
  Sentry.init({
    dsn: 'https://ec7626b148ed4a6b9a545d357f21ad80@sentry.io/1335848',
    integrations: [new TracingIntegrations.BrowserTracing({})],
    // To set a uniform sample rate
    tracesSampleRate: 0.2,
    release: `web-app@${process.env.VERSION}`,
    environment: getEnv(),
  });
}

export default Sentry;
