import { RootState } from 'store';

export const selectIsLoggedIn = (state: RootState) => state.services.api.isLoggedIn;
export const selectAuthCheckFinished = (state: RootState) => state.services.api.authCheckFinished;
export const selectShowLoginOnLogout = (state: RootState) => state.services.api.showLoginOnLogout;
export const selectRuleRequest = (state: RootState) => state.services.api.ruleRequest;
export const selectIsPersisted = (state: RootState) => state.services.api.isPersisted;
export const selectCanPersist = (state: RootState) => state.services.api.canPersist;
export const selectSupportedPersistenceMechanism = (state: RootState) =>
  state.services.api.supportedPersistenceMechanism;
