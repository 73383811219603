import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import { DEATH_SCREEN } from 'constants/testIds/dashboard';
import { fetchDeath, fetchRetirement, getAwardRules } from 'actions/digital-advice';
import GraphPage from '@sp/ui/pages/GraphPage';
import Margin from '@sp/ui/base/Margin';
import * as routeNames from 'constants/routeNames';
import {
  selectIncomeToday,
  selectTotalIncomeToday,
  selectChildAndYouthBenefitToday,
  selectHasDashboardData,
  selectPayoutDeath,
  selectIsNaked,
  selectDisposableIncomeOptions,
  selectError,
  selectNakedDashboardErrors,
  selectAwardRules,
  selectAwardRulesSucceeded,
  selectShowGrossAmount,
} from 'selectors/dashboard';
import { selectHasChildrenPayoutPolicies } from 'selectors/childrenPayouts';
import { refetchNakedDashboardErrors, getIncome } from 'actions/dashboard';
import { selectChildrenData } from 'selectors/children';
import { getChildren } from 'actions/children';
import TopTabBar from 'containers/dashboard/TopTabBar';
import Dashboard from 'areas/dashboard/Dashboard';

import { selectIsDeviceTypeDesktop } from 'selectors/device';
import Naked from 'areas/dashboard/dashboards/Naked';
import getGraphData from 'areas/dashboard/dashboards/Death/getGraphData';
import { DEATH } from '../../constants/dashboardTypes';

const FOCUSED_TAB = routeNames.DEATH;

const Death = ({
  isNaked,
  navigation,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  payoutDeath,
  theme,
  nakedDashboardErrors,
  isDesktop,
  awardRules,
  awardRulesSucceeded,
  hasChildrenPayouts,
  showGrossAmount,
}) => {
  if (isNaked) {
    return (
      <Naked
        focusedTab={FOCUSED_TAB}
        nakedDashboardErrors={nakedDashboardErrors}
        navigation={navigation}
      />
    );
  }

  const graphData = getGraphData({
    navigation,
    payoutDeath,
    incomeToday,
    totalIncomeToday,
    childAndYouthBenefitToday,
    theme,
    awardRules,
    awardRulesSucceeded,
    hasChildrenPayouts,
    showGrossAmount,
  });

  return (
    <GraphPage
      hasBackButton={false}
      hasHeader={false}
      tabBar={<TopTabBar focusedTab={FOCUSED_TAB} />}
      testID={DEATH_SCREEN}
      wideContent={<Dashboard graphData={graphData} />}
    >
      {isDesktop && <Margin marginTop="lg" />}
    </GraphPage>
  );
};

Death.propTypes = {
  isNaked: PropTypes.bool,
  payoutDeath: PropTypes.object,
  incomeToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  totalIncomeToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  childAndYouthBenefitToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }),
  navigation: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  nakedDashboardErrors: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  awardRules: PropTypes.object,
  awardRulesSucceeded: PropTypes.bool,
  hasChildrenPayouts: PropTypes.bool,
  showGrossAmount: PropTypes.bool.isRequired,
};

Death.defaultProps = {
  isNaked: false,
  payoutDeath: undefined,
  incomeToday: {
    amount: 0,
    grossAmount: 0,
    currency: 'DKK',
  },
  totalIncomeToday: undefined,
  childAndYouthBenefitToday: undefined,
  awardRules: undefined,
  hasChildrenPayouts: false,
  awardRulesSucceeded: false,
};

const mapStateToProps = (state) => ({
  isNaked: selectIsNaked(state, DEATH),
  incomeToday: selectIncomeToday(state),
  totalIncomeToday: selectTotalIncomeToday(state),
  childAndYouthBenefitToday: selectChildAndYouthBenefitToday(state),
  hasDashboardDataRetirement: selectHasDashboardData(state),
  hasDashboardData: selectHasDashboardData(state, DEATH),
  hasDisposableIncomeOptions: selectDisposableIncomeOptions(state),
  payoutDeath: selectPayoutDeath(state),
  error: selectError(state, DEATH),
  nakedDashboardErrors: selectNakedDashboardErrors(state, DEATH),
  isDesktop: selectIsDeviceTypeDesktop(state),
  awardRules: selectAwardRules(state, DEATH),
  awardRulesSucceeded: selectAwardRulesSucceeded(state),
  hasChildrenPayouts: selectHasChildrenPayoutPolicies(state, DEATH),
  hasChildrenData: selectChildrenData(state),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['dashboard'],
  init: ({ action, getProps }) =>
    action(
      async () => {
        const {
          dispatch,
          hasDashboardData,
          hasDisposableIncomeOptions,
          awardRules,
          hasDashboardDataRetirement,
          hasChildrenData,
        } = getProps();
        const dispatchArray = [];

        if (!hasDashboardDataRetirement) {
          dispatchArray.push(fetchRetirement());
        }

        if (!awardRules) {
          dispatchArray.push(getAwardRules());
        }

        if (!hasDisposableIncomeOptions) {
          dispatchArray.push(getIncome());
        }

        if (!hasDashboardData) {
          dispatchArray.push(fetchDeath());
        }

        if (!hasChildrenData) {
          dispatchArray.push(getChildren());
        }

        if (dispatchArray.length > 0) {
          await dispatch(dispatchArray);
        }

        dispatch(refetchNakedDashboardErrors(DEATH));
      },
      {
        loader: true,
        error: {
          type: 'overlay',
          retry: true,
        },
      }
    ),
})(withTheme(Death));
