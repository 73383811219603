import {
  GET_SAVINGS_OVERVIEW,
  GET_SAVINGS_DEPOSITS,
  CANCEL_SAVINGS_DEPOSITS,
  UPDATE_ADMIN_COSTS,
  UPDATE_DEPOSIT_FETCHING_STATE,
} from 'constants/actionTypes';
import { get, cancelRequestByReference } from 'actions/api';
import { groupSavingsDeposits } from 'areas/savings/helpers/groupSavingDeposits';

export const fetchSavingsOverview = () =>
  get(GET_SAVINGS_OVERVIEW, 'api', '/savings/v3/savings/all', {
    map: (response) => ({
      ...response,
      internalSavings: response.internalSavings.map((item) => ({
        ...item,
        deposits: [
          {
            year: item.maximumYear,
            deposits: groupSavingsDeposits(item.deposits),
          },
        ],
      })),
    }),
  });

export const getAdminCostsForSpecificYear = (costsForSpecificPolice, year) => {
  const policeCostsForSpecificYear = costsForSpecificPolice.find((item) => item.year === year);
  const { summary } = policeCostsForSpecificYear;
  const { adminCosts } = summary;

  return {
    type: UPDATE_ADMIN_COSTS,
    payload: adminCosts,
  };
};

export const fetchSavingsDeposits = (policyId, year, savingsDeposits) =>
  get(GET_SAVINGS_DEPOSITS, 'api', `/savings/v3/savings/${policyId}/payments/${year}`, {
    allowFailure: true,
    groupId: CANCEL_SAVINGS_DEPOSITS,
    map: (response) => {
      const deposits = groupSavingsDeposits(response.deposits);

      if (savingsDeposits && savingsDeposits.length > 0) {
        savingsDeposits.push({ year, deposits });
      }

      return {
        deposits: savingsDeposits?.sort((a, b) => b.year - a.year) || [{ year, deposits }],
        policyId,
        year,
      };
    },
  });

export const updateDepositFetchingState = (payload) => ({
  type: UPDATE_DEPOSIT_FETCHING_STATE,
  payload,
});

export const cancelSavingsDeposits = () => cancelRequestByReference(CANCEL_SAVINGS_DEPOSITS);
