import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useAppSelector } from 'hooks/redux';
import UnlockScreen from './UnlockScreen';
import AutoLock from './AutoLock';

const ContentWrapper = styled.View`
  flex: 1;
  ${(props) => (props.visible ? '' : 'display: none;')};
`;

const AppLock = ({ children }) => {
  const lockInfo = useAppSelector((state) => state.services.security.appLock);

  return (
    <>
      {!!lockInfo && <UnlockScreen {...lockInfo} />}
      <ContentWrapper visible={!lockInfo}>{children}</ContentWrapper>
    </>
  );
};

AppLock.propTypes = {
  children: PropTypes.any.isRequired,
};

export { AutoLock };

export default AppLock;
