import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import Markdown from '@sp/ui/base/Markdown';
import { getSelectedIncomeText } from 'areas/dashboard/calculations/helpers/income';
import { selectDisposableIncome, selectShowGrossAmount } from 'selectors/dashboard';
import withComponentContainer from 'decorators/withComponentContainer';
import Description from 'areas/dashboard/Description';

const FormSection = ({ i18n, disposableIncome, showGrossAmount }) => (
  <Description
    title={
      showGrossAmount
        ? i18n.t('dashboard|yourSalaryTitleWithGross')
        : i18n.t('dashboard|yourSalaryTitle')
    }
    infoAccessibilityLabel={i18n.t('dashboard|yourSalaryA11y')}
    description={i18n.t('dashboard|yourSalaryDescription', {
      selectedIncomeText: getSelectedIncomeText(i18n, disposableIncome),
    })}
    modalTitle={i18n.t('dashboard|incomeToday')}
    modalDescription={
      <>
        <Body>{i18n.t('dashboard|yourSalaryList')}</Body>
        <Margin marginTop="md" />
        <Markdown>{i18n.t('dashboard|yourSalaryListContent')}</Markdown>
      </>
    }
  />
);

FormSection.propTypes = {
  i18n: PropTypes.object.isRequired,
  disposableIncome: PropTypes.object,
  showGrossAmount: PropTypes.bool.isRequired,
};

FormSection.defaultProps = {
  disposableIncome: undefined,
};

const mapStateToProps = (state) => ({
  disposableIncome: selectDisposableIncome(state),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withComponentContainer({
  mapStateToProps,
})(FormSection);
