import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import log from 'services/log';
import { selectPendingVerifications } from 'selectors/profile';
import { navigate } from 'helpers/navigation';
import { getAndroidAppSignature } from 'hooks/useAndroidSmsRetriever';
import { preparePhoneNumberForAPI } from 'areas/profile/helpers';
import {
  verifyContactInformation,
  setHasSeenUnverifiedContactInfoNotification,
} from 'actions/profile';

import GlobalNotificationContext from 'context/GlobalNotificationContext';
import withTranslation from 'decorators/i18n';
import * as routeNames from 'constants/routeNames';

const UnverifiedContactInfoMonitor = ({
  phonePendingVerification,
  emailPendingVerification,
  isStartupCall,
  hasSeenNotification,
  setHasSeenNotification,
  verifyPhoneNumber,
  i18n,
}) => {
  const { showError, showNotification, dismissAll } = useContext(GlobalNotificationContext);

  const hasEmailPendingVerifications = !!emailPendingVerification;
  const hasPhonePendingVerifications = !!phonePendingVerification;

  useEffect(() => {
    if (hasSeenNotification || !isStartupCall) {
      return;
    }

    if (hasPhonePendingVerifications && isStartupCall) {
      showError(i18n.t('Din telefonnummer er ikke verificeret.'), {
        buttonText: i18n.t('Verificer telefonnummer'),
        onPressButton: async () => {
          dismissAll();
          try {
            verifyPhoneNumber({
              phone: preparePhoneNumberForAPI(phonePendingVerification?.value),
              hash: await getAndroidAppSignature(),
            });
            showNotification(
              i18n.t('Kode er sendt til {{phoneNumber}}. Den gælder i 20 min.', {
                phoneNumber: phonePendingVerification?.value,
              })
            );
          } catch (e) {
            log.warn('Could not request phone verification', e);
          }
          navigate(routeNames.PROFILE_VERIFY_PHONE_NUMBER_MODAL, {
            phoneNumber: phonePendingVerification?.value,
            standaloneFlow: true,
          });
        },
      });
    }

    if (hasEmailPendingVerifications && isStartupCall) {
      showError(i18n.t('Din e-mail er ikke verificeret.'), {
        buttonText: i18n.t('Verificer e-mail'),
        onPressButton: () => {
          dismissAll();
          navigate(routeNames.PROFILE_VERIFY_EMAIL_MODAL, {
            email: emailPendingVerification?.value,
            standaloneFlow: true,
          });
          showNotification(
            i18n.t(
              'Kode blev sendt til {{email}}, da du indtastede e-mailen. Hvis det er mere end 24 timer siden, skal du bede om en ny.',
              { email: emailPendingVerification?.value }
            ),
            { autoHideAfter: 12000 }
          );
        },
      });
    }

    setHasSeenNotification();
  }, [
    hasEmailPendingVerifications,
    hasPhonePendingVerifications,
    hasSeenNotification,
    setHasSeenNotification,
    isStartupCall,
    showError,
    i18n,
    dismissAll,
    phonePendingVerification?.value,
    verifyPhoneNumber,
    showNotification,
    emailPendingVerification?.value,
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  phonePendingVerification: selectPendingVerifications(state, 'phone'),
  emailPendingVerification: selectPendingVerifications(state, 'email'),
  isStartupCall: state.areas.profile?.verifications?.pending?.fetchedOnStartup,
  hasSeenNotification: state.areas.profile?.verifications?.hasSeenUnverifiedContactInfoNotification,
});

const mapDispatchToProps = (dispatch) => ({
  setHasSeenNotification: () => dispatch(setHasSeenUnverifiedContactInfoNotification()),
  verifyPhoneNumber: ({ phone, hash }) => dispatch(verifyContactInformation({ phone, hash })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UnverifiedContactInfoMonitor));
