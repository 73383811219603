import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { H1, Body, Subtitle } from '@sp/ui/typography';
import { Button } from '@sp/ui/base/buttons';
import PageParts from '@sp/ui/PageParts';
import { SafeAreaWrapper, ActionShape } from '@sp/ui/pages/BasePage';
import useModal from '@sp/ui/hooks/useModal';
import { isWeb } from '@sp/ui/helpers/device';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  Wrapper,
  MenuWrapper,
  Content,
  TitleWrapper,
  Image,
  BottomWrapper,
  ButtonWrapper,
  BottomContent,
} from './index.shared';

const IPhoneXOffsetWrapper = styled(SafeAreaWrapper)`
  background: transparent;
  width: 100%;
  align-items: center;
`;

const BackgroundPage = ({
  title,
  sources,
  children,
  heading,
  description,
  theme,
  backgroundColor,
  actions,
  actionInfo,
  bottom,
  menu,
  backButton,
  hasBackButton,
  dark,
}) => {
  const { isModal, onClose } = useModal();
  const insets = useSafeAreaInsets();

  const textColor = dark ? theme.COLORS.PRIMARY_TEXT : theme.COLORS.SECONDARY_BACKGROUND;
  return (
    <Wrapper backgroundColor={backgroundColor}>
      {sources && <Image mobile={sources.mobile} desktop={sources.desktop} />}
      {isWeb && menu && <MenuWrapper>{menu}</MenuWrapper>}
      <PageParts>
        {({ Header: GlobalHeader, ModalHeader, BackButton: GlobalBackButton, notifications }) => {
          const backButtonNode =
            hasBackButton &&
            (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />);

          return (
            <Fragment>
              {!isModal && <GlobalHeader title={title} backButton={backButtonNode} />}
              {isModal && (
                <ModalHeader title={title} onClose={onClose} backButton={backButtonNode} />
              )}
              <IPhoneXOffsetWrapper>
                {notifications}
                <Content>
                  {heading && (
                    <TitleWrapper>
                      <H1 color={textColor} textAlign="center">
                        {heading}
                      </H1>
                    </TitleWrapper>
                  )}
                  {description && (
                    <Body textAlign="center" color={textColor}>
                      {description}
                    </Body>
                  )}
                  {children}
                </Content>
                <BottomWrapper paddingBottom={insets.bottom}>
                  <BottomContent>{bottom}</BottomContent>
                  {actionInfo && <Subtitle>{actionInfo}</Subtitle>}
                  {actions &&
                    actions.map((button, index) => (
                      <ButtonWrapper key={`button-${index}`}>
                        <Button {...button} />
                      </ButtonWrapper>
                    ))}
                </BottomWrapper>
              </IPhoneXOffsetWrapper>
            </Fragment>
          );
        }}
      </PageParts>
    </Wrapper>
  );
};

BackgroundPage.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sources: PropTypes.shape({
    mobile: PropTypes.any,
    desktop: PropTypes.any,
  }),
  theme: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  dark: PropTypes.bool,
  bottom: PropTypes.node,
  menu: PropTypes.node,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
};

BackgroundPage.defaultProps = {
  heading: undefined,
  description: undefined,
  sources: undefined,
  backgroundColor: undefined,
  children: undefined,
  actions: undefined,
  actionInfo: undefined,
  dark: false,
  bottom: undefined,
  menu: undefined,
  backButton: undefined,
  hasBackButton: true,
};

export default withTheme(BackgroundPage);
