import { actionNames } from '@sampension/api-middleware';
import { create } from '../../helpers/reducers';

const { default: features } = require('features');

interface State {
  isLoggedIn: boolean;
  showLoginOnLogout: boolean;
  initialized: boolean;
  changelog?: {
    features: {
      name: string;
      [name: string]: any;
    }[];
  };
  authCheckFinished?: boolean;
  ruleRequest?: any;
  isPersisted?: boolean;
  canPersist?: boolean;
  supportedPersistenceMechanism?: any;
  blockMessage?: any;
  features?: {
    blockedFeatures?: {
      name: string;
      [name: string]: any;
    }[];
  };
}

const createDefaults = (showLoginOnLogout = true): State => ({
  isLoggedIn: false,
  showLoginOnLogout,
  initialized: false,
});

export default create(
  {
    [actionNames.API_AUTH_STATE_CHANGE]: (state, payload) => ({
      ...state,
      showLoginOnLogout: payload.showLoginOnLogout !== false,
      ...payload,
    }),
    RESPONSE_GET_API_STATUS: (state, payload) => {
      const { blockMessage, features: blockedFeatures, ...response } = payload.response;

      return {
        ...state,
        ...response,
        initialized: true,
        blockMessage: features.isEnabled('customerBlock') ? blockMessage : undefined,
        features: features.isEnabled('blockFeaturesFromAPI') ? blockedFeatures : undefined,
      };
    },
    RESPONSE_GET_APP_CHANGELOG: (state, payload) => ({
      ...state,
      changelog: payload.response,
    }),
    REQUEST_UPDATE_APP_CHANGELOG: (state, { id }) => {
      const filteredFeatures =
        state?.changelog?.features.filter((feature: any) => feature.id !== id) || [];

      return {
        ...state,
        changelog: { ...state.changelog, features: filteredFeatures },
      };
    },
  },
  createDefaults,
  { disableResetOnLogout: true }
);

export type { State };
