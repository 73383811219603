import React, { useState } from 'react';
import { Dimensions, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Title } from '@sp/ui/typography';
import MouseView from '@sp/ui/base/MouseView';
import { getCurrentDeviceType } from '@sp/ui/helpers/device';

const BUTTON_HEIGHT = 45;
const BUTTON_WIDTH = 288;
const BUTTON_WIDTH_MEDIUM = 256;
const BUTTON_WIDTH_SMALL = 150;
const BUTTON_BORDER_WIDTH = 1;
const BEFORE_AFTER_PADDING = 12;
const BUTTON_NARROW_HORIZONTAL_PADDING = 16;

const getStyle = (type, inactive, COLORS) => {
  switch (type) {
    case 'secondary':
      return {
        color: COLORS.SECONDARY_BACKGROUND,
        text: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        borderColor: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        hover: COLORS.PRIMARY_BACKGROUND,
      };
    case 'accessory':
      return {
        color: 'transparent',
        borderColor: 'transparent',
        text: COLORS.PRIMARY_TEXT,
        hover: 'transparent',
      };
    case 'destructive':
      return {
        color: COLORS.SECONDARY_BACKGROUND,
        text: COLORS.PRIMARY_ALERT,
        borderColor: COLORS.SECONDARY_BACKGROUND,
        hover: COLORS.SECONDARY_BACKGROUND,
      };
    case 'destructive-secondary':
      return {
        color: COLORS.SECONDARY_BACKGROUND,
        text: COLORS.PRIMARY_ALERT,
        borderColor: COLORS.PRIMARY_ALERT,
        hover: COLORS.SECONDARY_BACKGROUND,
      };
    case 'primary':
    default:
      return {
        color: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        text: COLORS.SECONDARY_BACKGROUND,
        borderColor: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        hover: COLORS.PRIMARY_HOVER,
      };
  }
};

const ButtonNative = styled.View`
  align-items: center;
  justify-content: center;
  width: ${({ narrow, size, theme }) => {
    const isMobile = getCurrentDeviceType() === 'mobile';
    const { width: BUTTON_WIDTH_LARGE } = Dimensions.get('window');

    if (narrow) {
      return 'auto';
    }

    switch (size) {
      case 'small':
        return `${BUTTON_WIDTH_SMALL}px`;
      case 'medium':
        return `${BUTTON_WIDTH_MEDIUM}px`;
      case 'large':
        return `${isMobile ? BUTTON_WIDTH_LARGE - 2 * theme.SPACINGS.xl : BUTTON_WIDTH}px`;
      default:
        return `${BUTTON_WIDTH}px`;
    }
  }};
  height: ${BUTTON_HEIGHT}px;
  border-width: ${BUTTON_BORDER_WIDTH}px;
  border-color: ${(props) => getStyle(props.type, props.inactive, props.theme.COLORS).borderColor};
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  padding: ${(props) => (props.narrow ? `0 ${BUTTON_NARROW_HORIZONTAL_PADDING}` : 0)}px;
  background: ${(props) => {
    if (props.hover && !props.inactive) {
      return getStyle(props.type, props.inactive, props.theme.COLORS).hover;
    }
    return getStyle(props.type, props.inactive, props.theme.COLORS).color;
  }};
`;

const ButtonContent = styled.View`
  display: flex;
  flex-direction: row;
`;

const AfterContent = styled.View`
  padding-left: ${BEFORE_AFTER_PADDING}px;
`;

const BeforeContent = styled.View`
  padding-right: ${BEFORE_AFTER_PADDING}px;
`;

const Button = ({ onPress, text, type, inactive, before, after, narrow, theme, size, testID }) => {
  const [hover, setHover] = useState(false);

  return (
    <MouseView onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <TouchableOpacity
        disabled={inactive}
        onPress={onPress}
        testID={`${testID}${inactive ? '-Inactive' : ''}`}
        accessibilityRole="button"
        accessibilityState={{
          disabled: inactive,
        }}
      >
        <ButtonNative hover={hover} type={type} inactive={inactive} narrow={narrow} size={size}>
          <ButtonContent>
            {before && <BeforeContent>{before}</BeforeContent>}
            <Title color={getStyle(type, inactive, theme.COLORS).text}>{text}</Title>
            {after && <AfterContent>{after}</AfterContent>}
          </ButtonContent>
        </ButtonNative>
      </TouchableOpacity>
    </MouseView>
  );
};

Button.propTypes = {
  onPress: PropTypes.func,
  type: PropTypes.oneOf(['destructive', 'primary', 'secondary', 'destructive-secondary']),
  text: PropTypes.string.isRequired,
  inactive: PropTypes.bool,
  after: PropTypes.node,
  before: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),
  theme: PropTypes.object.isRequired,
  narrow: PropTypes.bool,
  testID: PropTypes.string,
};

Button.defaultProps = {
  onPress: () => {},
  type: 'primary',
  inactive: false,
  after: null,
  before: null,
  size: 'normal',
  narrow: false,
  testID: undefined,
};

export default withTheme(Button);
