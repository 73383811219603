import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-native';
import { withTheme } from 'styled-components/native';
import { isWeb, isAndroid, isIOS } from '@sp/ui/helpers/device';

const getThumbColor = (theme, isEnabled) => {
  if (isWeb) {
    return isEnabled ? theme.COLORS.PRIMARY : theme.COLORS.SECONDARY_BACKGROUND;
  }

  if (isAndroid) {
    return isEnabled ? theme.COLORS.PRIMARY : theme.COLORS.GRAPH_INCOME;
  }

  return undefined;
};

// NOTE: `activeThumbColor` is needed for web
//
// Props are a little bit different from native version in RN web:
// https://github.com/necolas/react-native-web/blob/0.14.0/packages/react-native-web/src/exports/Switch/index.js
const SwitchComponent = ({ theme, ...props }) => (
  <Switch
    trackColor={{
      true: isIOS ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_VARIANT1,
      false: isIOS ? undefined : theme.COLORS.SHADOW,
    }}
    activeThumbColor={theme.COLORS.PRIMARY}
    thumbColor={getThumbColor(theme, props.value)}
    {...props}
  />
);

SwitchComponent.propTypes = {
  theme: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
};

export default withTheme(SwitchComponent);
