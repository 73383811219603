import { base64toBlob } from 'helpers/documents';

// NOTE: We push uploaders to array in order for them to not get garbage collected
const uploaders = [];

export const pickFileAndGetAttachment = (onFileRead) => {
  const uploader = global.document.createElement('input');
  uploaders.push(uploader);
  uploader.type = 'file';

  const reader = new global.FileReader();

  uploader.click();

  uploader.onchange = () => {
    const file = uploader.files[0];

    reader.onload = (event) => {
      const base64 = event.target.result.split(',')[1];
      const contentType = event.target.result.split(',')[0].split(':')[1].split(';')[0];
      const blob = base64toBlob(base64, contentType);

      const data = {
        filename: file.name,
        uri: URL.createObjectURL(blob),
        blob,
      };

      if (typeof onFileRead === 'function') onFileRead(data);
    };

    reader.readAsDataURL(file);
  };
};

export const getBase64FromSource = async (blob) => {
  const reader = new global.FileReader();
  reader.readAsDataURL(blob);

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const base64data = reader.result;

      resolve(base64data);
    };

    reader.onerror = () => {
      reject(new Error("Can't read blob"));
    };
  });
};
