import React from 'react';
import PropTypes from 'prop-types';
import { FlatList as List } from 'react-native';
import { RowBorder } from '@sp/ui/base/rows';

const getListFooterComponent = (props, Separator) => {
  const { ListFooterComponent, data } = props;

  if (ListFooterComponent) {
    return ListFooterComponent;
  }

  return data?.length > 0 && Separator;
};

const FlatList = (props) => {
  const { hasSeparator, ...otherProps } = props;
  const Separator = hasSeparator ? RowBorder : undefined;
  const ListFooterComponent = getListFooterComponent(props, Separator);

  return (
    <List
      {...otherProps}
      enableEmptySections
      ListHeaderComponent={Separator}
      ItemSeparatorComponent={Separator}
      ListFooterComponent={ListFooterComponent}
    />
  );
};

FlatList.propTypes = {
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  hasSeparator: PropTypes.bool,
  keyExtractor: PropTypes.func,
  onRefresh: PropTypes.func,
  refreshing: PropTypes.bool,
};

FlatList.defaultProps = {
  hasSeparator: true,
  onRefresh: undefined,
  keyExtractor: undefined,
  refreshing: false,
};

export default FlatList;
