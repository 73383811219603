const byName = (a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (b.name > a.name) {
    return -1;
  }
  return 0;
};

export const selectMunicipalities = (state) => {
  if (!state.services.reference.municipalities) return undefined;
  return state.services.reference.municipalities.sort(byName);
};
