import store from 'services/stores/persisted';
import config from 'config';
import featureEnvConfig from 'featureEnvConfig';

export class Features {
  listeners = [];
  storedFeatures = {};
  blockedFeatures = {};
  features = {
    development: false,
    customerBlock: true,
    blockFeaturesFromAPI: true,
    deposits: true,
    mailboxAddAttachments: false,
    savingsProfile: false,
    feedback: true,
    savingsTransfer: true,
    deleteProfile: false,
    healthBenefits: true,
    profileExternalData: true,
    pushNotifications: false,
    booking: true,
    meetingPreparation: false,
    linkChildren: false,
    changelog: !config?.isTest, // we don't want this while running e2e tests yet
    bookingDisablePICheck: true,
    changeInvestmentProfile: true,
    environment: 'default',
    devConsole: false,
    translate: false,
    trackingDebug: false,
    classicLogin: false,
    savingsInsurance: false,
  };

  constructor() {
    this.environments = ['default', 'dev', 'test', 'staging', 'production'];

    if (featureEnvConfig.name) {
      this.environments[0] = featureEnvConfig.name;
    }
  }

  get keys() {
    return Object.keys(this.features);
  }

  /**
   * @param {Object} blockedFeatures - object with the shape of { name: { reason, title }}
   */
  setBlockedFeatures(blockedFeatures) {
    this.blockedFeatures = blockedFeatures;
  }

  isEnabled(name, trueAnswer = true, falseAnswer = false) {
    let enabled = true;

    if (this.blockedFeatures[name]) {
      return false;
    }

    if (typeof this.storedFeatures[name] !== 'undefined') {
      enabled = this.storedFeatures[name];
    } else if (typeof this.features[name] !== 'undefined') {
      enabled = this.features[name];
    }

    return enabled ? trueAnswer : falseAnswer;
  }

  isBool(name) {
    return this.features[name] === true || this.features[name] === false;
  }

  isOverridden(name) {
    return this.storedFeatures[name];
  }

  set(name, value) {
    if (value !== this.features[name]) {
      this.storedFeatures[name] = value;
    } else {
      this.storedFeatures[name] = undefined;
    }
    store.setItem('_features', this.storedFeatures);
    const values = this.getAll();
    this.listeners.forEach((l) => l(values));
  }

  get(name) {
    if (typeof this.storedFeatures[name] !== 'undefined') {
      return this.storedFeatures[name];
    }

    return this.features[name] || false;
  }

  subscribe(fn) {
    this.listeners.push(fn);
  }

  unsubscribe(fn) {
    this.listeners = this.listeners.filter((l) => l !== fn);
  }

  toggle(name) {
    const currentValue = this.isEnabled(name);
    this.set(name, !currentValue);
  }

  getAll() {
    return this.keys.reduce(
      (output, key) => ({
        ...output,
        [key]: this.isEnabled(key),
      }),
      {}
    );
  }

  getAllValues() {
    return this.keys.reduce(
      (output, key) => ({
        ...output,
        [key]: this.get(key),
      }),
      {}
    );
  }

  async load() {
    const current = (await store.getItem('_features')) || {};
    this.storedFeatures = current;
  }

  expandArray(expansion, name) {
    if (this.isEnabled(name)) {
      return expansion;
    }
    return [];
  }
}

const feature = new Features();

export default feature;
