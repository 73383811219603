import { formatDate, formatYear, formatMoney, withComma } from 'helpers/string';
import { getLatestDepositDetails } from './getLatestDepositDetails';

export const getPolicyDetails = (savingsData, pID) => {
  if (!savingsData || !pID) return {};

  let policyInterestRate;
  const { internalSavings } = savingsData;
  const policyDetails = internalSavings.find((police) => police.coverageNumber === pID);
  const {
    minimumYear,
    maximumYear,
    return: returns,
    rates,
    value,
    expenses,
    deposits,
    timestamp,
    title,
    type,
    isLink,
    canChangeInvestmentProfile,
  } = policyDetails;
  const { annualCosts, currentCostsPerMonth } = expenses ?? {};
  const latestAdminCosts =
    currentCostsPerMonth >= 0
      ? formatMoney({ amount: currentCostsPerMonth, currency: 'DKK' }, true)
      : undefined;
  const hasAnnualCosts = annualCosts?.length > 0;
  const latestDepositStatementDate = timestamp && formatDate(timestamp);
  const latestDepositStatementYear = timestamp && formatYear(timestamp);

  if (rates) {
    const { highReturns, lowReturns } = rates;
    const areRatesEqual = highReturns === lowReturns;
    policyInterestRate = areRatesEqual
      ? `${withComma(lowReturns)} %`
      : `${withComma(lowReturns)} - ${withComma(highReturns)} %`;
  }

  const { latestDepositValue, latestDepositDate } = getLatestDepositDetails(deposits);

  return {
    minimumYear,
    maximumYear,
    policyId: pID,
    policyReturn: returns,
    policyInterestRate,
    policyValue: value,
    rates,
    expenses,
    latestAdminCosts,
    hasAnnualCosts,
    latestDepositStatementDate,
    latestDepositStatementYear,
    allDeposits: deposits,
    latestDepositValue,
    latestDepositDate,
    title,
    type,
    isLink,
    canChangeInvestmentProfile,
  };
};
