import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Tracking from 'services/tracking';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Swiper from '@sp/ui/layout/Swiper';
import SwiperPage from '@sp/ui/pages/SwiperPage';
import { Actions, SafeAreaWrapper } from '@sp/ui/v2/pages/BasePage';
import { Appear } from '@sp/ui/v2/animations';
import CurrentStepIndicator from '@sp/ui/v2/base/CurrentStepIndicator';
import Menu from 'containers/navigation/Menu';
import withScreenContainer from 'decorators/withScreenContainer';
import * as routeNames from 'constants/routeNames';
import config from 'config';
import { isAndroid } from 'helpers/platform';

import notifications from 'containers/globals/notifications';
import { GlobalNotificationConsumer } from 'context/GlobalNotificationContext';

import { unlockSession } from 'actions/security';
import { doLogin, startApp, prefetchInitialData } from 'actions/onboarding';
import { selectIsPersisted, selectSupportedPersistenceMechanism } from 'selectors/api';
import { selectPushNotification } from 'selectors/pushNotification';

import Splash1 from './Splash1';
import Splash2 from './Splash2';
import Splash3 from './Splash3';

const ActionsWrapper = styled.View`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const NotificationWrapper = styled.View`
  position: absolute;
  width: 100%;
  z-index: 1;
  align-items: center;
  top: ${({ topOffset }) => topOffset}px;
`;

const loginMechanismNames = {
  ['Face ID']: 'faceID',
  ['Touch ID']: isAndroid ? 'fingerprint' : 'touchID',
  PassCode: 'pinCode',
};

const getActions = ({
  loginMechanism,
  isPersisted,
  login,
  retryAppStart,
  isOnline,
  i18n,
  dismiss,
}) => {
  const actions =
    isPersisted && loginMechanism
      ? [
          {
            text: i18n.t('onboarding|logInWithMitID'),
            testID: 'introLoginBtn',
            type: 'secondary',
            onPress: () => {
              dismiss('auto-logout');
              login('NemID', { hadBiometrics: isPersisted });
            },
            inactive: !isOnline,
          },
          {
            text: i18n.t('onboarding|logInWith', {
              loginMechanism: i18n.t(`onboarding|${loginMechanismNames[loginMechanism]}`),
            }),
            testID: 'introPersistedLoginBtn',
            onPress: () => {
              dismiss('auto-logout');
              retryAppStart(loginMechanism);
            },
            inactive: !isOnline,
          },
        ]
      : [
          {
            text: i18n.t('onboarding|logIn'),
            testID: 'introLoginBtn',
            onPress: () => {
              dismiss('auto-logout');
              login('NemID', { hadBiometrics: isPersisted });
            },
            inactive: !isOnline,
          },
        ];

  return actions;
};

const getIndexes = (slideCount) => [...Array(slideCount).keys()];

const Intro = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const insets = useSafeAreaInsets();
  const slides = useMemo(() => [Splash1, Splash2, Splash3], []);
  const swiperRef = useRef(null);

  const { login, isPersisted, retryAppStart, i18n, isOnline, loginMechanism } = props;

  return (
    <SwiperPage customHeader={null} menu={<Menu showMenuItems={false} />} hasNotifications={false}>
      <NotificationWrapper topOffset={insets.top / 2}>{notifications}</NotificationWrapper>
      <GlobalNotificationConsumer>
        {({ notifications: notifs }) => (
          <Swiper
            testID="introSlider"
            ref={swiperRef}
            onIndexChanged={(newIndex) => setCurrentIndex(newIndex)}
          >
            {slides.map((Slide, key) => (
              <Slide key={key} hasNotificationMargin={notifs.length > 0} />
            ))}
          </Swiper>
        )}
      </GlobalNotificationConsumer>
      <ActionsWrapper>
        <Appear animationType="OutLin" duration={config.isTest ? 0 : 600}>
          <GlobalNotificationConsumer>
            {({ dismiss }) => (
              <Actions
                horizontalMode
                transparent
                zIndex={1}
                actionsContent={
                  <CurrentStepIndicator
                    current={currentIndex}
                    secondaryColor
                    ids={getIndexes(slides.length)}
                    onPress={(i) => swiperRef.current.moveTo(i)}
                  />
                }
                actions={getActions({
                  loginMechanism,
                  isPersisted,
                  login,
                  retryAppStart,
                  isOnline,
                  i18n,
                  dismiss,
                })}
              />
            )}
          </GlobalNotificationConsumer>
          <SafeAreaWrapper transparent />
        </Appear>
      </ActionsWrapper>
    </SwiperPage>
  );
};

const mapStateToProps = (state) => ({
  isOnline: state.services.connectivity.isOnline,
  isPersisted: selectIsPersisted(state),
  loginMechanism: selectSupportedPersistenceMechanism(state),
  pushNotification: selectPushNotification(state),
});

const init = ({ action, getProps }) =>
  action(async () => {
    const { dispatch, navigation, pushNotification, isPersisted } = getProps();

    const trackAuthentication = (type, params = {}) =>
      Tracking.trackEvent({
        area: routeNames.INTRO,
        action: 'Authentication',
        params: { type, ...params },
      });

    if (pushNotification && !isPersisted) {
      navigation.navigate(routeNames.LOGIN);
    }

    return {
      retryAppStart: (loginMechanism) =>
        action(
          async () => {
            const unlocked = await dispatch(unlockSession());
            // NOTE: if we can restore the persisted session
            // but can't start the app we fallback to the login flow
            if (unlocked) {
              try {
                await dispatch(startApp());
                dispatch(prefetchInitialData());
                trackAuthentication(loginMechanism);
              } catch (e) {
                dispatch(doLogin());
                trackAuthentication('NemID', { hadBiometrics: true, failedAfterBiometrics: true });
              }
            }
          },
          {
            loader: true,
          }
        ),
      login: (loginMechanism, params) => {
        dispatch(doLogin());
        trackAuthentication(loginMechanism, params);
      },
    };
  });

Intro.propTypes = {
  login: PropTypes.func,
  retryAppStart: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isPersisted: PropTypes.bool,
  loginMechanism: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Intro.defaultProps = {
  login: undefined,
  retryAppStart: undefined,
  isPersisted: false,
  loginMechanism: 'PassCode',
};

export default withScreenContainer({
  mapStateToProps,
  init,
  i18ns: ['onboarding'],
  hasLocalNotifications: false,
  needsLogin: false,
})(Intro);
