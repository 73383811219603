const selectAppointmentsByOrganizer = (state) => {
  const appointments = state.areas.booking.bookingVariations || [];
  const { selectedOrganizer } = state.areas.booking;

  if (selectedOrganizer) {
    return appointments.filter(
      (appointment) => appointment.organizerId === selectedOrganizer.organizerId
    );
  }

  return appointments;
};

export default selectAppointmentsByOrganizer;
