import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Button, ButtonGroup } from '@sp/ui/base/buttons';
import { SortToggle } from 'containers/base/buttons';
import { initFunc } from 'helpers/props';
import Tracking from 'services/tracking';
import useI18n from 'hooks/useI18n';
import Icon from '@sp/ui/base/Icon';
import * as routeNames from 'constants/routeNames';
import { useRoute, useNavigation } from '@react-navigation/native';

const SECTION_TOP_PADDING = 24;
const SECTION_HORIZONTAL_PADDING = 16;
const SECTION_BOTTOM_PADDING = 16;

const SourceSelectWrapper = styled.View`
  align-items: center;
`;

const SectionWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: ${SECTION_TOP_PADDING}px ${SECTION_HORIZONTAL_PADDING}px ${SECTION_BOTTOM_PADDING}px;
`;

const SortToggleWrapper = styled.View`
  margin-right: auto;
`;

const OverviewHeader = ({ theme, source, onSourceSelect, onSortPress }) => {
  const route = useRoute();
  const navigation = useNavigation();
  const { i18n } = useI18n(['mailbox']);

  return (
    <>
      <SourceSelectWrapper>
        <ButtonGroup
          buttons={[
            {
              text: i18n.t('mailbox|inbox'),
              isSelected: source === 'inbox',
              onPress: () => onSourceSelect('inbox'),
            },
            {
              text: i18n.t('mailbox|archive'),
              isSelected: source === 'archive',
              onPress: () => onSourceSelect('archive'),
            },
          ]}
        />
      </SourceSelectWrapper>
      <SectionWrapper>
        <SortToggleWrapper>
          <SortToggle label={i18n.t('Dato')} onPress={onSortPress} />
        </SortToggleWrapper>
        <Button
          text={i18n.t('mailbox|composeNewMail')}
          onPress={() => {
            Tracking.trackEvent({ area: route.name, action: 'ClickMailCompose' });
            navigation.navigate(routeNames.MAIL_CREATE);
          }}
          after={<Icon name="pencil" fill={theme.COLORS.SECONDARY_BACKGROUND} />}
          narrow
        />
      </SectionWrapper>
    </>
  );
};

OverviewHeader.propTypes = {
  theme: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  onSourceSelect: PropTypes.func.isRequired,
  onSortPress: initFunc,
};

OverviewHeader.defaultProps = {
  onSortPress: () => {},
};

export default withTheme(OverviewHeader);
