const { currencyAmountValidator } = require('@sp/ui/helpers/validators');
import useLinking from 'hooks/useLinking';
import { useCallback } from 'react';
const { default: config } = require('config');

export const amountValidator = (minAmount: number, maxAmount: number) => (amount: string) => {
  const val = parseInt(amount, 10);

  if (!maxAmount) {
    return currencyAmountValidator(amount) && val >= minAmount;
  }

  return currencyAmountValidator(amount) && val >= minAmount && val <= maxAmount;
};

export const compareDates = (a: { createdDate: Date }, b: { createdDate: Date }): -1 | 1 | 0 => {
  if (a.createdDate > b.createdDate) {
    return -1;
  }
  if (a.createdDate < b.createdDate) {
    return 1;
  }

  return 0;
};

export const getFilteredPaymentSources = (
  paymentSources: { minLimit: { amount: number }; maxLimit: { amount: number } }[],
  amount: number
) =>
  paymentSources?.filter(
    ({ minLimit, maxLimit }) => minLimit.amount <= amount && maxLimit.amount >= amount
  );

export const isSelectedSourceActive = (
  paymentSources: { type: string; isActive: boolean }[],
  selectedSource?: string
) => paymentSources?.find((item) => item.type === selectedSource)?.isActive;

export const getHostedUri = () => {
  const origin = global?.window?.location?.origin;

  if (origin) {
    return origin;
  }

  switch (config.env) {
    case 'test':
    case 'preview':
    case 'dev':
      return 'https://login-test.sampension.dk';
    case 'staging':
      return 'https://login-staging.sampension.dk';
    default:
      return 'https://login.sampension.dk';
  }
};

export const useMarkdownUrlHandler = () => {
  const { open } = useLinking();
  return useCallback(
    (url, openDocument, coverageNumber, startUpdatePensionFlow) => {
      if (startUpdatePensionFlow && url === 'deposits/update-pension') {
        startUpdatePensionFlow();
      } else if (url.includes('DOCUMENTS_pensionTerms')) {
        openDocument(coverageNumber);
      } else {
        open(url);
      }
    },
    [open]
  );
};
