import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from '@sp/ui/layout/Swiper';
import mobileImage from 'assets/images/intro/1/Splash1-mobile.jpg';
import desktopImage from 'assets/images/intro/1/Splash1-desktop.jpg';
import withTranslation from 'decorators/i18n';

const Splash1 = ({ i18n }) => (
  <Slide
    heading={i18n.t('onboarding|splash1Heading')}
    testID="introSlideLifeAfterJob"
    description={i18n.t('onboarding|splash1Description')}
    sources={{
      mobile: mobileImage,
      desktop: desktopImage,
    }}
    textOnly
  />
);

Splash1.propTypes = {
  i18n: PropTypes.object.isRequired,
};

Splash1.defaultProps = {
  backButton: undefined,
  hasNotificationMargin: false,
};

export default withTranslation(['onboarding'])(Splash1);
