// NOTE: we should uncomment files when they are converted to keys
// otherwise the translations are useless as keys and values are the sam
import dashboardDanish from '../../assets/i18n/da/dashboard.json';
import dashboardErrorsDanish from '../../assets/i18n/da/dashboardErrors.json';
import bookingDanish from '../../assets/i18n/da/booking.json';
import commonDanish from '../../assets/i18n/da/common.json';
import linkDanish from '../../assets/i18n/da/link.json';
import mailboxDanish from '../../assets/i18n/da/mailbox.json';
import onboardingDanish from '../../assets/i18n/da/onboarding.json';
// import phoneDanish from '../../assets/i18n/da/phone.json';
import savingsDanish from '../../assets/i18n/da/savings.json';
import transferDanish from '../../assets/i18n/da/transfer.json';
// NOTE: Dynamic translations are ones that come from backend
// And should be manually added to translation files
import dynamicDanish from '../../assets/i18n/da/dynamic.json';
// import appRatingEnglish from '../../assets/i18n/da/appRating.json';
import depositsDanish from '../../assets/i18n/da/deposits.json';
// import changeInvestmentProfileDanish from '../../assets/i18n/da/changeInvestmentProfile.json';
import accessibilityDanish from '../../assets/i18n/da/accessibility.json';
import profileDanish from '../../assets/i18n/da/profile.json';
import fetchTaxAndPensionsInfoDanish from '../../assets/i18n/da/fetchTaxAndPensionsInfo.json';
import partnerDanish from '../../assets/i18n/da/partner.json';
import meetingPreparationDanish from '../../assets/i18n/da/meetingPreparation.json';

import dashboardEnglish from '../../assets/i18n/en/dashboard.json';
import dashboardErrorsEnglish from '../../assets/i18n/en/dashboardErrors.json';
import bookingEnglish from '../../assets/i18n/en/booking.json';
// import commonEnglish from '../../assets/i18n/en/common.json';
import linkEnglish from '../../assets/i18n/en/link.json';
import mailboxEnglish from '../../assets/i18n/en/mailbox.json';
import onboardingEnglish from '../../assets/i18n/en/onboarding.json';
// import phoneEnglish from '../../assets/i18n/en/phone.json';
import savingsEnglish from '../../assets/i18n/en/savings.json';
import transferEnglish from '../../assets/i18n/en/transfer.json';
import dynamicEnglish from '../../assets/i18n/en/dynamic.json';
// import appRatingDanish from '../../assets/i18n/en/appRating.json';
import depositsEnglish from '../../assets/i18n/en/deposits.json';
// import changeInvestmentProfileEnglish from '../../assets/i18n/en/changeInvestmentProfile.json';
// import accessibilityEnglish from '../../assets/i18n/en/accessibility.json';
import fetchTaxAndPensionsInfoEnglish from '../../assets/i18n/en/fetchTaxAndPensionsInfo.json';

const resources = {
  da: {
    dashboard: dashboardDanish,
    dashboardErrors: dashboardErrorsDanish,
    booking: bookingDanish,
    common: commonDanish,
    link: linkDanish,
    fetchTaxAndPensionsInfo: fetchTaxAndPensionsInfoDanish,
    mailbox: mailboxDanish,
    onboarding: onboardingDanish,
    // phone: phoneDanish,
    savings: savingsDanish,
    transfer: transferDanish,
    dynamic: dynamicDanish,
    // appRating: appRatingEnglish,
    deposits: depositsDanish,
    // changeInvestmentProfile: changeInvestmentProfileDanish,
    accessibility: accessibilityDanish,
    profile: profileDanish,
    partner: partnerDanish,
    meetingPreparation: meetingPreparationDanish,
  },
  en: {
    dashboard: dashboardEnglish,
    dashboardErrors: dashboardErrorsEnglish,
    booking: bookingEnglish,
    // common: commonEnglish,
    link: linkEnglish,
    mailbox: mailboxEnglish,
    fetchTaxAndPensionsInfo: fetchTaxAndPensionsInfoEnglish,
    onboarding: onboardingEnglish,
    // phone: phoneEnglish,
    savings: savingsEnglish,
    transfer: transferEnglish,
    dynamic: dynamicEnglish,
    // appRating: appRatingDanish,
    deposits: depositsEnglish,
    // changeInvestmentProfile: changeInvestmentProfileEnglish,
    // accessibility: accessibilityEnglish,
  },
};

export default resources;
