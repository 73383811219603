export const getFirstValue = (...args) => {
  for (let i = 0; i < args.length; i += 1) {
    const val = args[i];
    if (typeof val !== 'undefined') {
      return val;
    }
  }
  return undefined;
};

export const isEmpty = (obj) => {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0;
};

export const getKeyValueObject = (obj) =>
  Object.keys(obj).reduce((filteredObj, key) => {
    if (typeof obj[key] !== 'object') {
      filteredObj[key] = obj[key];
    }
    return filteredObj;
  }, {});

export const filterOutObjectKeys = (dataObj, keys) => {
  return Object.keys(dataObj)
    .filter((key) => !keys.includes(key))
    .reduce((obj, key) => {
      obj[key] = dataObj[key];
      return obj;
    }, {});
};
