import React, { useCallback, Fragment, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import { selectSalaryByIndex } from 'selectors/dashboard';
import InfoRow from './InfoRow';
import ChildrenList from './ChildrenList';
import { RootState } from 'store';

const { default: getCompanyInfo } = require('areas/dashboard/calculations/helpers/getCompanyInfo');
const { default: Margin } = require('@sp/ui/base/Margin');
const { H3, Body } = require('@sp/ui/v2/typography');
const { formatMoney, capitalizeFirstLetter, getMonth } = require('helpers/string');

type Props = {
  index: number;
};

const selectSalaryInfo = (state: RootState, index: number) => {
  const selectedSalary = selectSalaryByIndex(state, index);

  const income = selectedSalary?.monthlyDetails?.samletBruttoindkomst?.amount || 0;
  const amBidrag = selectedSalary?.monthlyDetails?.amBidrag?.amount || 0;
  const skat = selectedSalary?.monthlyDetails?.aSkat?.amount || 0;

  const { companies, children: childrenData, totalChildrenPublicBenefit } = selectedSalary || {};
  const calculatedDispIncome = income - amBidrag - skat;

  const month = selectedSalary?.period?.month;
  const year = selectedSalary?.period?.year;

  return {
    income,
    amBidrag,
    skat,
    companies,
    childrenData,
    totalChildrenPublicBenefit,
    calculatedDispIncome,
    month,
    year,
  };
};

const SalaryInfo: React.FC<Props> = ({ index }) => {
  const i18n = useI18n(['dashboard']);

  const {
    income,
    amBidrag,
    skat,
    companies,
    childrenData,
    totalChildrenPublicBenefit,
    calculatedDispIncome,
    month,
    year,
  } = useAppSelector((state) => selectSalaryInfo(state, index), shallowEqual);

  const { companyInfo, companyNamesExist } = getCompanyInfo(companies, i18n);

  const amountPerMonth = useCallback(
    (amount) =>
      i18n.t('dashboard|amountPrMonth', {
        amount: formatMoney({ amount }, true),
      }),
    [i18n]
  );

  const salaryInfoRows = useMemo(() => {
    const rows: {
      textLeft?: string;
      componentLeft?: React.ReactNode;
      textRight?: string;
      content?: string;
      iconName?: string;
      fontWeight?: 'bold' | 'normal';
    }[] = [
      {
        textLeft: i18n.t('dashboard|salaryIncomeWithGross'),
        textRight: amountPerMonth(income),
        content: i18n.t('dashboard|salaryIncomeDescriptionWithGross'),
      },
      {
        textLeft: i18n.t('dashboard|amContribution'),
        textRight: amountPerMonth(amBidrag),
        content: i18n.t('dashboard|salaryAMContributionDescription'),
        iconName: 'minus',
      },
      {
        textLeft: i18n.t('dashboard|aTax'),
        textRight: amountPerMonth(skat),
        content: i18n.t('dashboard|salaryTaxDescription'),
        iconName: 'minus',
      },
      {
        textLeft: i18n.t('dashboard|salaryIncome'),
        textRight: amountPerMonth(calculatedDispIncome),
        content: i18n.t('dashboard|salaryIncomeDescription'),
        iconName: 'equalTo',
        fontWeight: 'bold',
      },
    ];

    if (totalChildrenPublicBenefit) {
      rows.push(
        {
          textLeft: i18n.t('dashboard|childAndYouthBenefit'),
          content:
            childrenData?.length === 1
              ? i18n.t('dashboard|benefitCalculationForChild')
              : i18n.t('dashboard|benefitCalculationForChildren'),
          fontWeight: 'bold',
          textRight: undefined,
        },
        {
          componentLeft: <ChildrenList childrenData={childrenData} />,
          textRight: amountPerMonth(totalChildrenPublicBenefit.amount),
          fontWeight: 'bold',
          iconName: 'equalTo',
        }
      );
    }

    return rows;
  }, [
    income,
    amBidrag,
    skat,
    calculatedDispIncome,
    childrenData,
    totalChildrenPublicBenefit,
    i18n,
    amountPerMonth,
  ]);

  return (
    <>
      <Margin all="md">
        <H3 textAlign="center">
          {i18n.t('dashboard|salaryInfoTitle', {
            period: `${capitalizeFirstLetter(getMonth(month, i18n))} ${year}`,
          })}
        </H3>
      </Margin>
      <Margin all="md">
        <Body>{i18n.t('dashboard|salaryInfoSubTitle')}</Body>
      </Margin>
      <Margin marginBottom="lg" horizontal="md">
        <Body>
          {i18n.t('dashboard|salaryInfoComesFrom', {
            companyInfo: companyInfo.join(', '),
          })}
          {!companyNamesExist && i18n.t('dashboard|salaryInfoComesFromMissingInfo')}
        </Body>
      </Margin>
      {salaryInfoRows.map(
        ({ textLeft, componentLeft, textRight, iconName, fontWeight, content }, i) => (
          <Fragment key={`${textLeft}_${i}`}>
            <InfoRow
              textLeft={textLeft}
              componentLeft={componentLeft}
              textRight={textRight}
              fontWeight={fontWeight}
              content={content}
              iconName={iconName}
            />
          </Fragment>
        )
      )}
    </>
  );
};

export default SalaryInfo;
