import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import { nanoid } from 'nanoid';
import PropTypes, { InferProps } from 'prop-types';
import useDeviceType from 'hooks/useDeviceType';
const { default: ChildLoader } = require('@sp/ui/context/ChildLoader');
import useI18n from 'hooks/useI18n';
const { default: LocalNotificationContext } = require('context/NotificationContext');

type Done = () => void;
type Action<T = any> = () => Promise<T> | T;
type Callback<T = any> = (result: T | undefined, error: ErrorObj | undefined, done: Done) => void;

interface ErrorObj {
  id: string;
  message: string;
  err: any;
  retry?: () => void;
  remove: () => void;
  type: 'blocking' | 'notification';
}

interface Options {
  handleLoading?: boolean;
  handleErrors?: boolean;
  error?: {
    type?: 'blocking' | 'notification';
    message?: string;
    retry?: boolean;
  };
  loading?: {
    type?: 'blocking' | 'overlay';
  };
}

interface AsyncContextValue<T = any> {
  loading: boolean;
  errors: ErrorObj[];
  run: (fn: Action<T>, callback: Callback, options: Options) => Promise<T>;
}

const asyncProviderPropTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  overlayLoader: PropTypes.node,
};

const AsyncContext = createContext<AsyncContextValue>(undefined as any);

const createErrorNotification = (errObj: ErrorObj, retryText?: string) => ({
  id: errObj.id,
  onPressButton: errObj.retry,
  buttonText: errObj.retry ? retryText : undefined,
});

const useLoading = () => {
  const [blockingLoading, setBlockingLoading] = useState<number>(1);
  const [overlayLoading, setOverlayLoading] = useState<number>(0);

  const createLoader = useCallback(
    (options: Options) => {
      const loadingOptions = options.loading || {};
      const setLoading =
        loadingOptions.type === 'blocking' ? setBlockingLoading : setOverlayLoading;
      const start = () => {
        if (options.handleLoading !== false) {
          setLoading((l) => l + 1);
        }
      };
      const end = () => {
        if (options.handleLoading !== false) {
          setLoading((l) => l - 1);
        }
      };
      return { start, end };
    },
    [setBlockingLoading, setOverlayLoading]
  );

  const setReady = useCallback(() => setBlockingLoading((l) => l - 1), []);

  return {
    setReady,
    blockingLoading,
    overlayLoading,
    createLoader,
  };
};

const useErrors = () => {
  const [errors, setErrors] = useState<any[]>([]);
  const { showError, dismiss } = useContext(LocalNotificationContext) as any;
  const { t } = useI18n();

  const createErrorObj = useCallback(
    function <T>(options: Options, err: Error, retry: () => Promise<T>): ErrorObj {
      const errorOptions = options.error || {};
      const id = nanoid();
      return {
        id,
        message: errorOptions.message ?? err.toString(),
        err,
        type: errorOptions.type || 'notification',
        remove: () => {
          dismiss(id);
          setErrors((current) => current.filter((c) => c.err !== err));
        },
        retry: errorOptions.retry
          ? () => {
              dismiss(id);
              setErrors((current) => current.filter((c) => c.err !== err));
              retry();
            }
          : undefined,
      };
    },
    [dismiss]
  );

  const fail = useCallback(
    (errObj: ErrorObj) => {
      if (errObj.type === 'blocking') {
        setErrors((current) => [...current, errObj]);
      } else {
        const genericErrorMessage =
          errObj.err?.response?.status === 403
            ? t('common|accessErrorMsg')
            : t('common|generalErrorMsg');
        showError(genericErrorMessage, createErrorNotification(errObj, t('common|tryAgain')));
      }
    },
    [showError, t]
  );

  return {
    errors,
    createErrorObj,
    fail,
  };
};

const AsyncProvider: React.FC<InferProps<typeof asyncProviderPropTypes>> = ({
  children,
  overlayLoader,
}) => {
  const { blockingLoading, overlayLoading, createLoader, setReady } = useLoading();
  const { errors, createErrorObj, fail } = useErrors();
  const { isDesktop } = useDeviceType();

  const run = useCallback(
    async (fn: Action, callback: Callback, options: Options) => {
      const loader = createLoader(options);
      loader.start();

      const done: Done = () => {
        loader.end();
      };

      try {
        const result = await fn();
        callback(result, undefined, done);
      } catch (err) {
        const errObj = createErrorObj(options, err, () => run(fn, callback, options));
        callback(undefined, errObj, done);
        if (options.handleErrors !== false) {
          fail(errObj);
        }
        throw err;
      }
    },
    [createErrorObj, createLoader, fail]
  );

  useEffect(() => {
    setReady();
  }, [setReady]);

  return (
    <AsyncContext.Provider
      value={{
        run,
        loading: blockingLoading > 0,
        errors,
      }}
    >
      {/*
        Note: On mobile we can render without ChildLoader, as it doesn't overlap the
        tab bar, but on web we need to use it to avoid overlapping the top menu
      */}
      {isDesktop && overlayLoading > 0 && <ChildLoader>{overlayLoader}</ChildLoader>}
      {!isDesktop && overlayLoading > 0 && <>{overlayLoader}</>}
      {children}
    </AsyncContext.Provider>
  );
};

AsyncProvider.propTypes = asyncProviderPropTypes;

AsyncProvider.defaultProps = {
  children: undefined,
  overlayLoader: undefined,
};

export { AsyncProvider, ErrorObj, Options };

export default AsyncContext;
