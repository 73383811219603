import { actionNames } from '@sampension/api-middleware';
import { create } from '../../helpers/reducers';

const createDefaults = () => ({
  services: undefined as any,
  prefetch: [],
  lockdown: true,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    DISCOVERY_UNLOCK_DEV_FEATURES: (state) => ({
      ...state,
      lockdown: false,
    }),
    [actionNames.API_DISCOVERY_RESULT]: (state, payload) => {
      const { blockMessage, ...response } = payload;

      return {
        ...state,
        ...response,
        blockMessage,
      };
    },
  },
  createDefaults,
  {
    disableResetOnLogout: true,
  }
);

export type { State };
