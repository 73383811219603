import withScreenContainer from 'decorators/withScreenContainer';
import { fetchIndividual, fetch, getSubjects, create } from 'actions/mailbox';
import { actions } from 'react-formed';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectServiceMessages } from 'selectors/mailbox';
import i18n from 'services/i18n';
import Tracking from 'services/tracking';
import { patchAttachmentsWithBase64, validateAttachments } from 'areas/mailbox/helpers/attachments';
import { FORMS_MAIL_CREATE } from 'constants/forms';

import Create from './Create';

const mapStateToProps = (state, props) => ({
  ...props,
  threadId: props.route.params?.threadId,
  subjects: state.areas.mailbox.subjects,
  form: state.forms[FORMS_MAIL_CREATE],
  isDesktop: selectIsDeviceTypeDesktop(state),
  isAdvisorsBusy: selectServiceMessages(state).isAdvisorsBusy,
});

const init = ({ action, props, getProps }) =>
  action(
    async () => {
      const { dispatch, showError, route } = props;
      await dispatch(getSubjects());
      const { subject, message } = route.params || {};

      if (subject && message) {
        dispatch(
          actions.setForm(FORMS_MAIL_CREATE, {
            subject: 'Andet',
            customSubject: subject,
            content: message,
          })
        );
      }

      return {
        validateAttachments: (attachments) =>
          action(async () => validateAttachments(attachments, showError, i18n)),
        send: (hasSubject, caseType, attachments, onSuccess) =>
          action(
            async () => {
              const { form, threadId } = getProps();

              const patchedAttachments = await patchAttachmentsWithBase64(attachments);

              Tracking.trackEvent({ area: route.name, action: 'ClickSend' });

              await dispatch(
                create({
                  content: form.content,
                  requestType: form.subject,
                  subject: hasSubject ? form.customSubject : undefined,
                  files: patchedAttachments,
                  caseType,
                  threadId,
                })
              );

              if (threadId) {
                await dispatch(fetchIndividual(threadId));
              } else {
                await dispatch(fetch());
              }

              if (onSuccess) onSuccess();
            },
            {
              loader: true,
              error: {
                message: i18n.t('mailbox|sendErrorMsg'),
              },
            }
          ),
      };
    },
    {
      loader: true,
    }
  );

export default withScreenContainer({
  mapStateToProps,
  init,
  i18ns: ['mailbox'],
})(Create);
