import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { Body } from '@sp/ui/typography';
import DropdownButton from './DropdownButton';

const DROPDOWN_ICON_SIZE = 10;

const PickerButton = ({
  placeholder,
  value,
  isPickerOpen,
  before,
  theme,
  onPress,
  testID,
  accessibilityLabel,
}) => (
  <DropdownButton accessibilityLabel={accessibilityLabel} onPress={onPress} border testID={testID}>
    <Body testID={`${testID}Text`}>
      {before}
      {value || placeholder}
    </Body>
    <Icon
      name={isPickerOpen ? 'arrowUpCentered' : 'arrowDownCentered'}
      width={DROPDOWN_ICON_SIZE}
      height={DROPDOWN_ICON_SIZE}
      fill={theme.COLORS.GRAPH_INCOME}
    />
  </DropdownButton>
);

PickerButton.propTypes = {
  placeholder: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  value: PropTypes.string,
  isPickerOpen: PropTypes.bool,
  before: PropTypes.node,
  theme: PropTypes.object.isRequired,
  testID: PropTypes.string,
  accessibilityLabel: PropTypes.string,
};

PickerButton.defaultProps = {
  placeholder: undefined,
  value: '',
  isPickerOpen: false,
  before: undefined,
  testID: undefined,
  accessibilityLabel: undefined,
};

export default withTheme(PickerButton);
