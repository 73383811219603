import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Loader from '@sp/ui/base/Loader';

const LOADER_HEIGHT = 100;

const Wrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  height: ${LOADER_HEIGHT}px;
`;

const Loading = ({ theme }) => (
  <Wrapper>
    <Loader color={theme.COLORS.PRIMARY} text={null} blur={false} fullScreen={false} />
  </Wrapper>
);

Loading.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(Loading);
