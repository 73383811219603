import React from 'react';
import PropTypes from 'prop-types';
import { initFunc } from 'helpers/props';
import { Form, actions } from 'react-formed';
import styled, { withTheme } from 'styled-components/native';
import withComponentContainer from 'decorators/withComponentContainer';
import { getIncome } from 'actions/dashboard';
import { formatDate, numberWithSeperator } from 'helpers/string';
import {
  selectDisposableIncome,
  selectDisposableIncomeOptions,
  selectUserEnteredIncome,
  selectSelectedSalary,
  selectShouldDefaultValueEnableSaveButton,
  selectTaxDataUpdateDate,
  selectShowGrossAmount,
} from 'selectors/dashboard';
import { selectIsDeviceTypeMobile } from 'selectors/device';
import { selectFormAdjustIncome } from 'selectors/dashboardForms';
import ShowMore from 'containers/dashboard/ShowMore';
import Input from 'containers/form/Input';
import SalaryRow from 'areas/dashboard/AdjustCalculation/DisposableIncome/SalaryRow';
import { setInitialIncomeValues } from 'areas/dashboard/calculations/helpers/income';
import Margin from '@sp/ui/base/Margin';
import { Footnote, Body } from '@sp/ui/typography';
import { RowBorder } from '@sp/ui/base/rows';
import { currencyAmountValidator } from '@sp/ui/helpers/validators';
import { RETIREMENT } from '../../constants/dashboardTypes';

const WRAPPER_TEXT_WIDTH = 288;

const Wrapper = styled.View`
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  ${({ isMobile }) => (isMobile ? 'width: 100%;' : undefined)};
`;

const Container = styled.View`
  align-items: ${(props) => (props.leftAlignItems ? 'flex-start' : 'center')};
`;

const TextWrapper = styled.View`
  ${(props) => (props.leftAlignItems ? '' : `max-width: ${WRAPPER_TEXT_WIDTH}px;`)}
`;

export const getSalaryRows = ({
  disposableIncomeOptions,
  initSelectedIndex,
  setSelectedRadioButton,
  radioButtonDimensions,
}) => {
  if (!disposableIncomeOptions) {
    return null;
  }

  return disposableIncomeOptions.map((item, index) => {
    const calculatedIndex = disposableIncomeOptions.indexOf(item);
    const userEnteredIncome = item.source === 'UserEnteredDisposableIncome';
    const isHighlighted = userEnteredIncome
      ? initSelectedIndex === -1
      : calculatedIndex === initSelectedIndex;

    return (
      <SalaryRow
        key={index}
        calculatedIndex={calculatedIndex}
        isHighlighted={isHighlighted}
        userEnteredIncome={userEnteredIncome}
        radioButtonDimensions={radioButtonDimensions}
        incomeOption={item}
        index={index}
        onPress={(clickedIndex) => setSelectedRadioButton(userEnteredIncome ? -1 : clickedIndex)}
        amount={`${numberWithSeperator(item.monthlyAmount?.amount || 0, 0)}`}
        grossAmount={`${numberWithSeperator(item.monthlyAmount?.grossAmount || 0, 0)}`}
      />
    );
  });
};

export const getWarningText = ({
  showGrossAmountWarning,
  showGrossAmount,
  i18n,
  formAdjustIncome,
}) => {
  if (showGrossAmountWarning && showGrossAmount) {
    return i18n.t('dashboard|showWithGrossInfo');
  }

  if (
    parseInt(formAdjustIncome?.enteredGrossIncome, 10) <
    parseInt(formAdjustIncome?.enteredIncome, 10)
  ) {
    return i18n.t('dashboard|enteredIncomeWithGrossLessThanIncome');
  }

  return undefined;
};

const IncomeInput = ({
  i18n,
  moneySymbol,
  disposableIncomeOptions,
  clearSelectedRadioButton,
  formAdjustIncome,
  setSelectedRadioButton,
  withShowMore,
  leftAlignItems,
  isMobile,
  taxDataUpdateDate,
  theme,
  showGrossAmount,
  showGrossAmountWarning,
}) => (
  <>
    {withShowMore && (
      <Margin marginBottom="lg" horizontal="md">
        <ShowMore>
          <Body>{i18n.t('dashboard|adjustIncome')}</Body>
        </ShowMore>
      </Margin>
    )}

    <Form name="adjustIncome">
      {getSalaryRows({
        disposableIncomeOptions,
        initSelectedIndex: formAdjustIncome?.selectedIndex,
        setSelectedRadioButton,
      })}
      <RowBorder />
      <Margin marginLeft="md" marginTop="md">
        <Footnote>
          {i18n.t('dashboard|salaryInfoFromTax', {
            date: taxDataUpdateDate && formatDate(taxDataUpdateDate),
          })}
        </Footnote>
      </Margin>
      <Margin horizontal="md" marginTop="md">
        <Container leftAlignItems={leftAlignItems}>
          <Margin marginBottom="sm">
            <Body>{i18n.t('dashboard|userEnteredIncomeInfo')}</Body>
          </Margin>
          <Wrapper isMobile={isMobile}>
            <Margin
              marginBottom={isMobile ? 'md' : undefined}
              marginRight={isMobile ? undefined : 's'}
            >
              <Input
                name="enteredIncome"
                keyboardType="number-pad"
                numeric
                customValidator={(val) => currencyAmountValidator(val) || val === ''}
                autoCorrect={false}
                placeholder={i18n.t('dashboard|userEnteredIncome')}
                after={
                  formAdjustIncome?.enteredIncome
                    ? i18n.t('dashboard|amountWithoutTax', { moneySymbol })
                    : ' '
                }
                marginTop={0}
                onFocus={clearSelectedRadioButton}
                defaultValue={formAdjustIncome?.enteredIncome}
                accessibilityLabel={i18n.t('dashboard|userEnteredIncome')}
              />
            </Margin>
            <Margin marginLeft={isMobile ? undefined : 's'}>
              <Input
                name="enteredGrossIncome"
                keyboardType="number-pad"
                numeric
                customValidator={(val) => currencyAmountValidator(val) || val === ''}
                autoCorrect={false}
                placeholder={i18n.t('dashboard|enterIncomeWithGross')}
                after={
                  formAdjustIncome?.enteredGrossIncome
                    ? i18n.t('dashboard|amountWithGross', { moneySymbol })
                    : ' '
                }
                marginTop={0}
                onFocus={clearSelectedRadioButton}
                defaultValue={formAdjustIncome?.enteredGrossIncome}
                accessibilityLabel={i18n.t('dashboard|enterIncomeWithGross')}
              />
            </Margin>
          </Wrapper>
          <Margin marginTop="md">
            <Footnote color={theme.COLORS.PRIMARY_ALERT}>
              {getWarningText({ showGrossAmountWarning, showGrossAmount, i18n, formAdjustIncome })}
            </Footnote>
          </Margin>

          <Margin marginTop="md">
            <TextWrapper leftAlignItems={leftAlignItems}>
              <Footnote textAlign="center">{i18n.t('dashboard|incomeInputDisclaimer')}</Footnote>
            </TextWrapper>
          </Margin>
        </Container>
      </Margin>
    </Form>
  </>
);
IncomeInput.propTypes = {
  clearSelectedRadioButton: initFunc,
  setSelectedRadioButton: initFunc,
  i18n: PropTypes.object.isRequired,
  moneySymbol: PropTypes.string.isRequired,
  disposableIncomeOptions: PropTypes.array,
  formAdjustIncome: PropTypes.object.isRequired,
  withShowMore: PropTypes.bool,
  leftAlignItems: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  taxDataUpdateDate: PropTypes.string,
  theme: PropTypes.object.isRequired,
  showGrossAmount: PropTypes.bool.isRequired,
  showGrossAmountWarning: PropTypes.bool,
};

IncomeInput.defaultProps = {
  clearSelectedRadioButton: undefined,
  setSelectedRadioButton: undefined,
  withShowMore: true,
  disposableIncomeOptions: undefined,
  leftAlignItems: false,
  taxDataUpdateDate: undefined,
  showGrossAmountWarning: false,
};

const mapStateToProps = (state) => ({
  disposableIncomeOptions: selectDisposableIncomeOptions(state),
  selectedSalary: selectSelectedSalary(state),
  formAdjustIncome: selectFormAdjustIncome(state),
  userEnteredIncome: selectUserEnteredIncome(state),
  disposableIncome: selectDisposableIncome(state),
  shouldDefaultValueEnableSaveButton: selectShouldDefaultValueEnableSaveButton(state, RETIREMENT),
  isMobile: selectIsDeviceTypeMobile(state),
  taxDataUpdateDate: selectTaxDataUpdateDate(state),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withTheme(
  withComponentContainer({
    mapStateToProps,
    init: ({ action, getProps }) =>
      action(async () => {
        const { dispatch, disposableIncomeOptions, formAdjustIncome } = getProps();

        if (!disposableIncomeOptions) {
          await dispatch(getIncome());
        }

        if (Object.keys(formAdjustIncome).length === 0) {
          // get props again, so we know they are updated
          const {
            userEnteredIncome,
            disposableIncome,
            shouldDefaultValueEnableSaveButton,
            disposableIncomeOptions: incomeOptionsUpdated,
            selectedSalary,
          } = getProps();

          setInitialIncomeValues({
            userEnteredIncome,
            disposableIncomeOptions: incomeOptionsUpdated,
            selectedSalary,
            dispatch,
            defaultIdentifier: disposableIncome.defaultIdentifier,
          });

          if (!userEnteredIncome?.amount && !selectedSalary) {
            // select default value if no value is selected and income errors are not present.
            if (incomeOptionsUpdated && shouldDefaultValueEnableSaveButton) {
              let salary = incomeOptionsUpdated.find(
                (item) => item.identifier === disposableIncome.defaultIdentifier
              );
              // no default was found, so first item is selected.
              if (!salary) [salary] = incomeOptionsUpdated;

              const selectedIndex = incomeOptionsUpdated.indexOf(salary);
              // set default value in form => save button is enabled.
              dispatch(actions.setValue('adjustIncome', 'selectedIndex', selectedIndex));
            }
          }
        }

        return {
          clearSelectedRadioButton: () => {
            dispatch(actions.setValue('adjustIncome', 'selectedIndex', -1));
          },
          setSelectedRadioButton: (clickedIndex) => {
            dispatch(actions.setValue('adjustIncome', 'selectedIndex', clickedIndex));
            dispatch(actions.setValue('adjustIncome', 'enteredIncome', ''));
            dispatch(actions.setValue('adjustIncome', 'enteredGrossIncome', ''));
          },
        };
      }),
  })(IncomeInput)
);
