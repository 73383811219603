import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import type { RootState } from 'store';
import useI18n from 'hooks/useI18n';
import { ADJUST_PUBLIC_SERVICES } from 'constants/routeNames';
import { ADJUST_CALCULATION_PUBLIC_SERVICES_ROW } from 'constants/testIds/dashboard/adjustCalculation';
import {
  selectHasPartlySelectedPublicBenefits,
  selectAllPublicServiceBenefitsDisabled,
} from 'selectors/dashboardForms';
import AdjustCalculationRow from './AdjustCalculationRow';

const { default: Icon } = require('@sp/ui/base/Icon');
const { Body2 } = require('@sp/ui/v2/typography');

const selectPublicServiceRow = (state: RootState) => ({
  hasPartlySelectedPublicBenefits: selectHasPartlySelectedPublicBenefits(state),
  areAllPublicServicesDisabled: selectAllPublicServiceBenefitsDisabled(state),
});

const PublicServicesRow: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const { COLORS } = useTheme();
  const navigation = useNavigation();
  const { hasPartlySelectedPublicBenefits, areAllPublicServicesDisabled } = useAppSelector(
    selectPublicServiceRow,
    shallowEqual
  );

  const areAllPublicServicesDisabledText = areAllPublicServicesDisabled
    ? i18n.t('no')
    : i18n.t('yes');

  return (
    <AdjustCalculationRow
      testID={ADJUST_CALCULATION_PUBLIC_SERVICES_ROW}
      componentLeft={<Icon name="rowMoneyBag" fill={COLORS.V2_PRIMARY_BACKGROUND} />}
      textLeft={i18n.t('dashboard|publicServices')}
      textRight={
        <Body2 fontWeight="normal" color={COLORS.V2_SECONDARY_TEXT}>
          {hasPartlySelectedPublicBenefits ? i18n.t('partly') : areAllPublicServicesDisabledText}
        </Body2>
      }
      onPress={() => navigation.navigate(ADJUST_PUBLIC_SERVICES)}
    />
  );
};

export default PublicServicesRow;
