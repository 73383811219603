import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from '@sp/ui/layout/Swiper';
import mobileImage from 'assets/images/intro/2/Splash2-mobile.jpg';
import desktopImage from 'assets/images/intro/2/Splash2-desktop.jpg';
import withTranslation from 'decorators/i18n';

const Splash2 = ({ i18n }) => (
  <Slide
    heading={i18n.t('onboarding|splash2Heading')}
    testID="introSlideInsurances"
    description={i18n.t('onboarding|splash2Description')}
    sources={{
      mobile: mobileImage,
      desktop: desktopImage,
    }}
    textOnly
  />
);

Splash2.propTypes = {
  i18n: PropTypes.object.isRequired,
};

Splash2.defaultProps = {
  backButton: undefined,
  hasNotificationMargin: false,
};

export default withTranslation(['onboarding'])(Splash2);
