import { Fragment } from 'react';
import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';

const TOP_CONTAINER_MAX_WIDTH = 700;
const TOP_CONTAINER_MAX_WIDTH_DESKTOP = 980;
const GRAPH_HEIGHT = 300;

export const TopContainer = Conditional({
  desktop: styled.View`
    max-width: ${TOP_CONTAINER_MAX_WIDTH_DESKTOP}px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  `,
  tablet: styled.View`
    max-width: ${TOP_CONTAINER_MAX_WIDTH}px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: ${({ theme }) => theme.SPACINGS.lg}px auto 0;
  `,
  default: styled.View`
    max-width: ${TOP_CONTAINER_MAX_WIDTH}px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: ${({ theme }) => theme.SPACINGS.sm}px auto 0;
  `,
});

export const layoutSpecs = {
  labelHeight: 35,
  labelWidth: 120,
  graphHeight: GRAPH_HEIGHT,
  pageMaxWidth: TOP_CONTAINER_MAX_WIDTH,
};

export const PayoutsDescription = styled.View`
  padding: ${({ theme }) => theme.SPACINGS.md}px;
`;

export const AgeSelectorContainer = Conditional({
  default: styled.View`
    width: 50%;
    margin: 0 auto;
    padding: ${({ theme }) => theme.SPACINGS.lg}px 0;
  `,
  mobile: Fragment,
});
