export const TOGGLE_POLICY = 'TOGGLE_POLICY';
export const TOGGLE_COMPANY_GROUP = 'TOGGLE_COMPANY_GROUP';
export const SELECT_POLICY = 'SELECT_POLICY';
import { isPolicySelected, isCompanyGroupSelected } from './helpers';

export const initialState = [];
export const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_POLICY: {
      if (isPolicySelected(state, action.payload.policy)) {
        return state.filter((p) => p.policyId !== action.payload.policy.policyId);
      }

      return [
        ...state.filter((p) => p.companyGroup === action.payload.policy.companyGroup),
        action.payload.policy,
      ];
    }
    case TOGGLE_COMPANY_GROUP: {
      if (isCompanyGroupSelected(state, action.payload.companyGroup, action.payload.policies)) {
        return state.filter((p) => p.companyGroup !== action.payload.companyGroup);
      }

      return action.payload.policies.filter((p) => p.companyGroup === action.payload.companyGroup);
    }
    case SELECT_POLICY: {
      return [action.payload.policy];
    }
    default:
      throw new Error(`Unkown action ${action.type} in PolicySelector reducer`);
  }
};
