import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Conditional from 'decorators/conditional';
import { H1, H2 } from '@sp/ui/typography';
import RoundButton from '@sp/ui/base/buttons/RoundButton';
import Slider from '@sp/ui/base/buttons/Slider';

const SELECTOR_WIDTH = 320;
const SELECTOR_MIN_WIDTH_TABLET = SELECTOR_WIDTH - 10;
const MOBILE_SELECTOR_HEIGHT = 74;
const SELECTOR_HEIGHT = 46;
const PADDING_TITLE_RIGHT = 5;

const MobileWrapper = styled.View`
  flex-direction: row;
  width: ${SELECTOR_WIDTH}px;
  height: ${MOBILE_SELECTOR_HEIGHT}px;
  padding: 0 ${({ theme }) => theme.SPACINGS.sm}px;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  align-items: center;
`;

const TabletAndDesktopWrapper = styled.View`
  flex-direction: row;
  min-width: ${SELECTOR_MIN_WIDTH_TABLET}px;
  max-width: ${SELECTOR_WIDTH}px;
  height: ${SELECTOR_HEIGHT}px;
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
  align-items: center;
`;

const Wrapper = Conditional({
  mobile: MobileWrapper,
  tablet: TabletAndDesktopWrapper,
  desktop: TabletAndDesktopWrapper,
});

const ButtonWrapper = styled.View``;

const TitleContent = styled.View`
  padding-right: ${PADDING_TITLE_RIGHT}px;
`;

const AgeWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Column = styled.View`
  flex-direction: column;
  flex: 1;
  margin: 0 ${({ theme }) => theme.SPACINGS.sm}px;
`;

const AgeSelector = ({
  testID,
  buttonTestIDS,
  onPressLeft,
  onPressRight,
  title,
  subTitle,
  inactiveLeft,
  inactiveRight,
  acceptInactiveOnPress,
  accessibilityLabel,
  accessibilityHint,
  accessibilityLabelLeft,
  accessibilityLabelRight,
  minAge,
  maxAge,
  onValueChange,
  value,
}) => {
  if (!isFinite(minAge) || !isFinite(maxAge)) {
    return null;
  }

  return (
    <Wrapper
      testID={testID}
      accessible
      accessibilityRole="adjustable"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      accessibilityActions={[
        { name: 'decrement', label: accessibilityLabelLeft },
        { name: 'increment', label: accessibilityLabelRight },
      ]}
      onAccessibilityAction={(event) => {
        switch (event.nativeEvent.actionName) {
          case 'decrement':
            onPressLeft();
            break;
          case 'increment':
            onPressRight();
            break;
          default:
            break;
        }
      }}
    >
      <ButtonWrapper>
        <RoundButton
          testID={buttonTestIDS?.decrement}
          onPress={onPressLeft}
          type="minus"
          inactive={inactiveLeft}
          acceptInactiveOnPress={acceptInactiveOnPress}
        />
      </ButtonWrapper>
      <Column>
        <AgeWrapper>
          <TitleContent>
            <H1>{title}</H1>
          </TitleContent>
          <H2>{subTitle}</H2>
        </AgeWrapper>

        <Slider minAge={minAge} maxAge={maxAge} value={value} onValueChange={onValueChange} />
      </Column>
      <ButtonWrapper>
        <RoundButton
          testID={buttonTestIDS?.increment}
          onPress={onPressRight}
          inactive={inactiveRight}
          acceptInactiveOnPress={acceptInactiveOnPress}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

AgeSelector.propTypes = {
  onPressLeft: PropTypes.func.isRequired,
  onPressRight: PropTypes.func.isRequired,
  testID: PropTypes.string,
  buttonTestIDS: PropTypes.object,
  acceptInactiveOnPress: PropTypes.bool,
  inactiveLeft: PropTypes.bool,
  inactiveRight: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subTitle: PropTypes.string.isRequired,
  accessibilityLabel: PropTypes.string,
  accessibilityHint: PropTypes.string,
  accessibilityLabelLeft: PropTypes.string,
  accessibilityLabelRight: PropTypes.string,
  minAge: PropTypes.number,
  maxAge: PropTypes.number,
  onValueChange: PropTypes.func,
  value: PropTypes.number,
};

AgeSelector.defaultProps = {
  acceptInactiveOnPress: true,
  inactiveLeft: false,
  inactiveRight: false,
  title: '',
  testID: undefined,
  buttonTestIDS: undefined,
  accessibilityLabel: undefined,
  accessibilityHint: undefined,
  accessibilityLabelLeft: undefined,
  accessibilityLabelRight: undefined,
  minAge: undefined,
  maxAge: undefined,
  value: undefined,
  onValueChange: undefined,
};

export default withTheme(AgeSelector);
