import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const LoadingContext = createContext({
  addLoader: () => {},
  removeLoader: () => {},
});

export const LoadingProvider = ({ children }) => {
  const [loaders, setLoaders] = useState([]);
  const addLoader = useCallback((loaderToAdd) => {
    if (!loaderToAdd) {
      return;
    }
    setLoaders((currentLoaders) => [...currentLoaders, loaderToAdd]);
  }, []);

  const removeLoader = useCallback((loaderToRemove) => {
    if (!loaderToRemove) {
      return;
    }
    setLoaders((currentLoaders) => currentLoaders.filter((l) => l !== loaderToRemove));
  }, []);

  const currentLoader = loaders.find((l) => !!l) || null;

  return (
    <LoadingContext.Provider
      value={{
        isLoading: !!currentLoader,
        loader: currentLoader,
        addLoader,
        removeLoader,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoadingContext;
