import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { selectAwardRulesSucceeded } from 'selectors/dashboard';
import withComponentContainer from 'decorators/withComponentContainer';
import { Body } from '@sp/ui/typography';
import Card from '@sp/ui/base/Card';
import ErrorButton from '../dashboards/naked/ErrorButton';

const Wrapper = styled.View`
  flex-direction: column;
  align-items: center;
`;

const AwardRulesNotFound = ({ i18n, disableMargin, awardRulesSucceeded }) => {
  const errorMessage = awardRulesSucceeded
    ? i18n.t('dashboard|pensionInfoOutOfDate')
    : i18n.t('dashboard|awardRulesError');

  return (
    <Wrapper>
      <Card
        type="warning"
        disableMargin={disableMargin}
        buttonComponent={awardRulesSucceeded && <ErrorButton name="UpdatePensionDataButton" />}
      >
        <Body>{errorMessage}</Body>
      </Card>
    </Wrapper>
  );
};

AwardRulesNotFound.propTypes = {
  i18n: PropTypes.object.isRequired,
  disableMargin: PropTypes.bool,
  awardRulesSucceeded: PropTypes.bool,
};

AwardRulesNotFound.defaultProps = {
  disableMargin: false,
  awardRulesSucceeded: false,
};

const mapStateToProps = (state) => ({
  awardRulesSucceeded: selectAwardRulesSucceeded(state),
});

export default withComponentContainer({ mapStateToProps })(AwardRulesNotFound);
