import React from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { ListWithSeparator } from '@sp/ui/base/lists';
import { DashboardRow, RowBorder } from '@sp/ui/base/rows';
import * as routeNames from 'constants/routeNames';
import { BackButton } from '@sp/ui/pages/BasePage';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { dismiss } from 'helpers/navigation';

const FeedbackType = ({ navigation, isDesktop, i18n }) => (
  <ModalProvider isModal onClose={dismiss(navigation)}>
    <ScrollPage
      hasBackButton={isDesktop}
      heading={i18n.t('feedbackTypeTitle')}
      description={i18n.t('feedbackTypeDescription')}
      title={i18n.t('feedbackTitle')}
      disableBottomPadding
      testID="feedbackScreen"
      backButton={<BackButton label={i18n.t('back')} onPress={dismiss(navigation)} />}
    >
      <ListWithSeparator separator={RowBorder}>
        <DashboardRow
          arrow
          iconLeft="rowChat"
          onPress={() =>
            navigation.navigate(routeNames.FEEDBACK_CREATE, {
              title: i18n.t('feedbackGiveTitle'),
              type: 'feedback',
            })
          }
          textLeft={i18n.t('feedbackGiveTitle')}
        />
        <DashboardRow
          arrow
          iconLeft="rowErrorFilled"
          onPress={() =>
            navigation.navigate(routeNames.FEEDBACK_CREATE, {
              type: 'error',
            })
          }
          textLeft={i18n.t('feedbackErrorTitle')}
        />
      </ListWithSeparator>
    </ScrollPage>
  </ModalProvider>
);

FeedbackType.propTypes = {
  navigation: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default withScreenContainer({
  mapStateToProps,
})(FeedbackType);
