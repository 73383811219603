import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { initFunc } from 'helpers/props';
import Url from 'pure-url';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NavigationContainer } from '@react-navigation/native';
import Loader from '@sp/ui/base/Loader';
import useFlipper from 'hooks/useFlipper';
import { setMenuPath, navigationRef, getPathFromState, getStateFromPath } from 'helpers/navigation';
import linkingConfig from 'linkingConfig';
import withInit from 'decorators/init';
import Tracking from 'services/tracking';
import { routingInstrumentation } from 'services/tracking/sentry';
import { startApp as startAppAction, prefetchInitialData } from 'actions/onboarding';
import { selectIsLoggedIn } from 'selectors/api';
import { selectLockdown } from 'selectors/discovery';
import { redirectToAppDeposits } from 'helpers/redirectToAppDeposits';
import { isWeb } from 'helpers/platform';
import { isImpersonationFlow } from 'helpers/impersonation';
import AreasNavigator from 'areas/AreasNavigator';
import AppLock, { AutoLock } from 'containers/AppLock';
import SecurityScreen from 'containers/SecurityScreen';
import { useStartAppAndHandleDeeplink } from 'hooks/useStartAppAndHandleDeeplink';

const linking = {
  ...linkingConfig,
  getPathFromState,
  getStateFromPath,
};

const AppRouter = ({ loggedIn, authCheckFinished, lockdown, startApp }) => {
  const currentLocation = useRef(isWeb ? global.location.href : null);
  const navPathRef = useRef(null);

  useFlipper(navigationRef);
  useStartAppAndHandleDeeplink(startApp);

  if (!authCheckFinished && !navPathRef.current) {
    return <Loader />;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      onReady={() => {
        if (!isWeb) {
          // NOTE: on web we rely on BrowserTracing for metrics
          // so we don't need a routing instrumentation
          routingInstrumentation.registerNavigationContainer(navigationRef);
        } else {
          redirectToAppDeposits({ currentLocation: currentLocation.current });
        }
      }}
      onStateChange={(state) => {
        const currentNavPath = getPathFromState(state);
        const previousNavPath = navPathRef.current;

        // NOTE: this is currently used to get the current path for the desktop menu
        setMenuPath(currentNavPath);

        if (previousNavPath !== currentNavPath) {
          Tracking.trackScreen(currentNavPath);
        }

        // Save the current route name for later comparison
        navPathRef.current = currentNavPath;
      }}
    >
      {loggedIn && !isWeb && <AutoLock />}
      <AppLock>
        <AreasNavigator lockdown={lockdown} />
        <SecurityScreen />
      </AppLock>
    </NavigationContainer>
  );
};

AppRouter.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  lockdown: PropTypes.bool.isRequired,
  authCheckFinished: PropTypes.bool.isRequired,
  startApp: initFunc,
};

AppRouter.defaultProps = {
  partnerGUID: undefined,
  deeplink: undefined,
  startApp: undefined,
  startPopupAppFlow: undefined,
};

const init = withInit(({ action, props }) => {
  const { dispatch } = props;

  return {
    startApp: () =>
      action(
        async () => {
          if (!isWeb) {
            await dispatch(startAppAction());
            // NOTE: we do not await here in order to allow the requests to fail silently
            dispatch(prefetchInitialData());
            // NOTE: on web we need to check if the customer should be blocked on page refresh
          } else {
            const location = new Url(global.location.href);

            /* NOTE: Ensures that popup does not re-enter the startAppAction and stays on the
            initiated URL and application does not start prematurely during impersonation */
            if (!location?.query?.preventAppStart && !isImpersonationFlow()) {
              await dispatch(startAppAction());
            }
          }
        },
        {
          error: {
            type: 'startApp',
            retry: true,
          },
        }
      ),
  };
});

const mapStateToProps = (state) => {
  const lockdown = __DEV__ || process.env.IS_DEV_BUILD ? false : selectLockdown(state);

  return {
    loggedIn: selectIsLoggedIn(state),
    authCheckFinished: state.services.security.authCheckFinished,
    lockdown,
  };
};

export default compose(connect(mapStateToProps), init)(AppRouter);
