import React from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

const { default: Row } = require('areas/dashboard/calculations/components/Row');
const { default: Margin } = require('@sp/ui/base/Margin');
const { Body2 } = require('@sp/ui/v2/typography');

type InfoRowProps = {
  textLeft?: string;
  componentLeft?: React.ReactNode;
  iconName?: string;
  textRight?: string;
  fontWeight?: string;
  content?: string;
};

const Separator = styled.View`
  background-color: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
  height: ${StyleSheet.hairlineWidth}px;
`;

const ContentWrapper = styled.View`
  background-color: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
`;

const InfoRow: React.FC<InfoRowProps> = ({
  textLeft,
  componentLeft,
  textRight,
  fontWeight,
  content,
  iconName,
}) => (
  <>
    <Row
      componentLeft={
        componentLeft ? (
          componentLeft
        ) : (
          <Body2 numberOfLines={1} fontWeight={fontWeight} secondaryColor>
            {textLeft}
          </Body2>
        )
      }
      componentRight={
        <Body2 fontWeight={fontWeight} secondaryColor>
          {textRight}
        </Body2>
      }
      iconName={iconName}
    />
    {content ? (
      <ContentWrapper>
        <Margin all="md">
          <Body2 fontWeight="regular" secondaryColor>
            {content}
          </Body2>
        </Margin>
      </ContentWrapper>
    ) : (
      <Separator />
    )}
  </>
);

InfoRow.defaultProps = {
  fontWeight: 'regular',
  textRight: undefined,
  componentLeft: undefined,
  textLeft: undefined,
  content: undefined,
  iconName: undefined,
};

export default InfoRow;
