import config from 'config';
import features from 'features';
import store from 'services/stores/persisted';
import featureEnvConfig from 'featureEnvConfig';
import { isWeb } from 'helpers/platform';

const environments = {
  dev: {
    identity: 'http://localhost:3000/iam',
    api: 'http://localhost:3000/api',
  },
  preview: {
    identity: 'https://login-dev.sampension.dk/iam',
    api: 'https://login-dev.sampension.dk/api',
  },
  test: {
    identity: 'https://identitet-test.sampension.dk',
    api: 'https://api-test.sampension.dk',
  },
  staging: {
    identity: 'https://identitet-staging.sampension.dk',
    api: 'https://api-staging.sampension.dk',
  },
  production: {
    identity: 'https://identitet.sampension.dk',
    api: 'https://api.sampension.dk',
  },
};

class SampensionServiceCache {
  cache = environments.production;

  getUrl = async (name) => this.cache[name];

  set = async (input) => {
    if (config.env === 'default') {
      this.cache = input;
    }

    await store.setItem('discovery_service_cache', input);
  };

  setupFeatureSpecificEnvConfig = async (envConfig) => {
    await features.set('environment', envConfig.name);
    //NOTE: for web we use the preview environment as a baseline
    //while for native builds we use test
    environments[envConfig.name] = {
      ...(isWeb ? environments.preview : environments.test),
      ...(isWeb ? {} : envConfig.environment),
    };

    this.cache = environments[envConfig.name];
  };

  setup = async () => {
    if (featureEnvConfig.name && config.env !== 'dev') {
      await this.setupFeatureSpecificEnvConfig(featureEnvConfig);
    } else if (config.env !== 'default') {
      this.cache = environments[config.env];
    } else {
      this.cache = environments.production;
      const persisted = await store.getItem('discovery_service_cache');
      if (persisted) {
        this.cache = persisted;
      }
    }
  };
}

export default SampensionServiceCache;
