import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import { IconShape } from '@sp/ui/base/Icon';

const CollapsibleRow = ({
  children,
  textLeft,
  textRight,
  iconLeft,
  isOpen,
  RowComponent,
  disableBorder,
  backgroundColor,
  componentLeft,
  hasArrow,
  componentRight,
  onPress,
  accessibilityLabel,
}) => {
  const Row = RowComponent || OneLineRow;
  const [visible, setVisible] = useState(isOpen);

  return (
    <Fragment>
      <Row
        accessibilityLabel={accessibilityLabel}
        backgroundColor={backgroundColor}
        arrow={
          children && hasArrow
            ? {
                direction: visible ? 'up' : 'down',
              }
            : null
        }
        onPress={() => {
          if (onPress) onPress();

          setVisible(!visible);
        }}
        textLeft={textLeft}
        iconLeft={iconLeft}
        textRight={textRight}
        componentLeft={componentLeft}
        componentRight={componentRight}
      />
      {visible && children}
      {!visible && !disableBorder && <RowBorder />}
    </Fragment>
  );
};

CollapsibleRow.propTypes = {
  children: PropTypes.any.isRequired,
  textLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconLeft: PropTypes.oneOfType([PropTypes.string, IconShape]),
  isOpen: PropTypes.bool,
  RowComponent: PropTypes.node,
  disableBorder: PropTypes.bool,
  componentLeft: PropTypes.node,
  backgroundColor: PropTypes.string,
  hasArrow: PropTypes.bool,
  componentRight: PropTypes.node,
  onPress: PropTypes.func,
  accessibilityLabel: PropTypes.string,
};

CollapsibleRow.defaultProps = {
  textLeft: undefined,
  textRight: undefined,
  iconLeft: undefined,
  isOpen: false,
  disableBorder: false,
  RowComponent: undefined,
  componentLeft: undefined,
  backgroundColor: undefined,
  hasArrow: true,
  componentRight: undefined,
  onPress: undefined,
  accessibilityLabel: undefined,
};

export default CollapsibleRow;
