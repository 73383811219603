import type { I18nParams } from 'typings/global';

type ModalContentType = {
  type?: string;
  i18n: I18nParams;
};

export const getModalIncomeContent = ({ type, i18n }: ModalContentType) => {
  let modalTitle: string;
  let modalBody: string;

  switch (type) {
    case 'earlyRetirement': {
      modalTitle = i18n.t('partner|earlyRetirementModalTitle');
      modalBody = i18n.t('partner|earlyRetirementModalBody');
      break;
    }
    case 'churchTax': {
      modalTitle = i18n.t('partner|churchTaxModalTitle');
      modalBody = i18n.t('partner|churchTaxModalBody');
      break;
    }
    case 'income': {
      modalTitle = i18n.t('partner|incomeTaxModalTitle');
      modalBody = i18n.t('partner|incomeTaxModalBody');
      break;
    }
    default: {
      return undefined;
    }
  }

  return {
    modalTitle,
    modalBody,
  };
};
