import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { SnapEffect as ReanimatedSnapEffect } from '@sampension/reanimated-animation-library';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SPACINGS } from '../../../settings';

const SECONDARY_MENU_HEIGHT = 60;
const MENU_HEIGHT = 50;
const EXTRA_STATIC_OFFSET = SPACINGS.md;
const EXTRA_SCROLLABILITY_OFFSET = 50;

const isStaticOffsets = {
  web: MENU_HEIGHT + SECONDARY_MENU_HEIGHT - EXTRA_STATIC_OFFSET,
  ios: MENU_HEIGHT + SECONDARY_MENU_HEIGHT,
  android: MENU_HEIGHT + EXTRA_STATIC_OFFSET,
};

const isScrollableOffsets = {
  web: MENU_HEIGHT + SECONDARY_MENU_HEIGHT + EXTRA_STATIC_OFFSET,
  ios: MENU_HEIGHT + SECONDARY_MENU_HEIGHT + EXTRA_SCROLLABILITY_OFFSET,
  android: MENU_HEIGHT + EXTRA_SCROLLABILITY_OFFSET,
};

const SnapEffect = ({ cardHeight, snapEffectDirection, children }) => {
  const { bottom: extraBottomSpace } = useSafeAreaInsets();

  const extraIsStaticOffset = useMemo(() => isStaticOffsets[Platform.OS], []);
  const extraIsScrollableOffset = useMemo(() => isScrollableOffsets[Platform.OS], []);

  const extraOffset = extraBottomSpace ? SPACINGS.md : 0;

  return (
    <ReanimatedSnapEffect
      isStaticOffset={extraIsStaticOffset + extraOffset}
      isScrollableOffset={extraIsScrollableOffset}
      cardHeight={cardHeight}
      snapEffectDirection={snapEffectDirection}
    >
      {children}
    </ReanimatedSnapEffect>
  );
};

SnapEffect.propTypes = {
  cardHeight: PropTypes.any.isRequired,
  snapEffectDirection: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

export default SnapEffect;
