import React from 'react';
import PropTypes from 'prop-types';
import { Animated, Easing } from 'react-native';
import styled from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { isWeb } from '@sp/ui/helpers/device';

const HEIGHT = 88;
const ANIMATED_COIN_HEIGHT = 11;
const ANIMATED_COIN_WIDTH = 12;
const ANIMATED_COIN_BORDER_RADIUS = 6;
const OVAL_HEIGHT = 11;
const PIGGY_BANK_HEIGHT = 35;
const DURATION = 350;
const DISTANCE = HEIGHT - PIGGY_BANK_HEIGHT + 1;
const OPACITY_INITIAL = 0.25;
const OPACITY_FINAL = 1;
const OPACITY_INTERPOLATOR = {
  inputRange: [0, DISTANCE - OVAL_HEIGHT],
  outputRange: [OPACITY_INITIAL, OPACITY_FINAL],
};
const isNative = !isWeb;

const TRANSFORM_ANIMATION = {
  toValue: DISTANCE,
  duration: DURATION,
  useNativeDriver: isNative,
};

const animatedStyle = (opacity, translateY) => ({
  opacity,
  transform: [{ translateY }],
});

const Container = styled.View`
  position: relative;
  height: ${HEIGHT}px;
  justify-content: flex-end;
  align-items: center;
`;

const AnimatedCoin = styled(Animated.View)`
  width: ${ANIMATED_COIN_WIDTH}px;
  height: ${ANIMATED_COIN_HEIGHT}px;
  border-radius: ${ANIMATED_COIN_BORDER_RADIUS}px;
  position: absolute;
  top: 0;
  background: ${({ background }) => background};
`;

const AnimatedPiggyBank = styled(Animated.View)``;

class Pig extends React.Component {
  state = {
    animatedCoins: [new Animated.Value(0), new Animated.Value(0), new Animated.Value(0)],
    animatedValuePig: new Animated.Value(1),
  };

  componentDidMount() {
    const { startAnimation } = this.props;
    if (startAnimation) {
      this.startAnimation();
    }
  }

  startAnimation() {
    Animated.loop(
      Animated.sequence(
        [
          Animated.stagger(
            DURATION * 0.5,
            [
              Animated.timing(this.state.animatedCoins[0], TRANSFORM_ANIMATION),
              Animated.timing(this.state.animatedCoins[1], TRANSFORM_ANIMATION),
              Animated.timing(this.state.animatedCoins[2], TRANSFORM_ANIMATION),
            ],
            {
              useNativeDriver: isNative,
            }
          ),
          Animated.sequence([
            Animated.timing(this.state.animatedValuePig, {
              toValue: 1.175,
              duration: DURATION * 0.5,
              easing: Easing.easeInEaseOut,
              useNativeDriver: isNative,
            }),
            Animated.timing(this.state.animatedValuePig, {
              toValue: 1,
              duration: DURATION * 0.5,
              easing: Easing.easeInEaseOut,
              useNativeDriver: isNative,
            }),
          ]),
        ],
        {
          useNativeDriver: isNative,
        }
      )
    ).start();
  }

  render() {
    const { color } = this.props;
    const { animatedCoins, animatedValuePig } = this.state;
    const interpolateOpacity1 = animatedCoins[0].interpolate(OPACITY_INTERPOLATOR);
    const interpolateOpacity2 = animatedCoins[1].interpolate(OPACITY_INTERPOLATOR);
    const interpolateOpacity3 = animatedCoins[2].interpolate(OPACITY_INTERPOLATOR);
    const animatedStylePig = {
      transform: [{ scale: animatedValuePig }],
    };

    return (
      <Container>
        <AnimatedCoin
          background={color}
          style={animatedStyle(interpolateOpacity1, animatedCoins[0])}
        />
        <AnimatedCoin
          background={color}
          style={animatedStyle(interpolateOpacity2, animatedCoins[1])}
        />
        <AnimatedCoin
          background={color}
          style={animatedStyle(interpolateOpacity3, animatedCoins[2])}
        />
        <AnimatedPiggyBank style={animatedStylePig}>
          <Icon name="piggyBank" fill={color} width="41" height={`${PIGGY_BANK_HEIGHT}`} />
        </AnimatedPiggyBank>
      </Container>
    );
  }
}

Pig.propTypes = {
  color: PropTypes.string,
  startAnimation: PropTypes.bool,
};

Pig.defaultProps = {
  color: '#FFF',
  startAnimation: true,
};

export default Pig;
