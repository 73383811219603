import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { Body } from '@sp/ui/v2/typography';
import { getColor } from '@sp/ui/v2/typography/index.shared';

const { isWeb } = require('@sp/ui/helpers/device');

// NOTE: on web we set text-align on Wrapper
// on native we set text-align on children
const Wrapper = styled.Text`
  ${({ textAlign }) => (isWeb && textAlign ? `text-align: ${textAlign};` : '')};
`;

const LinkIconWrapper = styled.TouchableOpacity``;

const StyledBody = styled(Body)`
  text-decoration-line: underline;
`;

const Link = ({
  testID,
  onPress,
  children,
  theme,
  color,
  secondaryColor,
  linkColor,
  textAlign,
  isDesktop,
}) => {
  const updatedColor = getColor(theme, color, secondaryColor, linkColor, isDesktop);

  return (
    <Wrapper textAlign={textAlign}>
      {typeof children === 'string' ? (
        <StyledBody onPress={onPress}>{children}</StyledBody>
      ) : (
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              style: {
                textDecorationLine: 'underline',
              },
              onPress,
              color: updatedColor,
              textAlign,
            });
          }
          return child;
        })
      )}
      <LinkIconWrapper testID={testID} onPress={onPress}>
        <Icon name="link" width={17} height={17} fill={updatedColor} />
      </LinkIconWrapper>
    </Wrapper>
  );
};

Link.propTypes = {
  children: PropTypes.any,
  testID: PropTypes.string,
  onPress: PropTypes.func,
  color: PropTypes.string,
  secondaryColor: PropTypes.bool,
  linkColor: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  isDesktop: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

Link.defaultProps = {
  secondaryColor: false,
  testID: undefined,
  linkColor: undefined,
  children: undefined,
  href: undefined,
  onPress: undefined,
  handleOpen: undefined,
  color: undefined,
  textAlign: undefined,
};

export default withTheme(Link);
