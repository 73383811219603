import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';
import { Label } from '@sp/ui/typography';
import Conditional from 'decorators/conditional';

const BULLET_ICON_SIZE = 10;

export const BulletLabel = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.SPACINGS.xs}px;
`;

export const ColorSymbolizations = Conditional({
  mobile: styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    ${(props) => !props.leftAlignLabels && 'justify-content: space-around;'}
    padding-bottom: ${({ theme }) => theme.SPACINGS.xs}px;
  `,
  default: styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: ${({ theme }) => `${theme.SPACINGS.m}px ${theme.SPACINGS.md}px`};
  `,
});

const GraphLabels = ({ labels, leftAlignLabels }) => (
  <ColorSymbolizations leftAlignLabels={leftAlignLabels}>
    {labels.map((label) => (
      <BulletLabel key={label.name}>
        <Icon name="bullet" width={BULLET_ICON_SIZE} height={BULLET_ICON_SIZE} fill={label.color} />
        <Margin marginLeft="sm">
          <Label>{label.name}</Label>
        </Margin>
      </BulletLabel>
    ))}
  </ColorSymbolizations>
);

GraphLabels.propTypes = {
  labels: PropTypes.array.isRequired,
  leftAlignLabels: PropTypes.bool,
};

GraphLabels.defaultProps = {
  leftAlignLabels: false,
};

export default GraphLabels;
