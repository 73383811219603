import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

const WIDTH = 1060;
const PADDING_TOP = 40;
const PADDING_BOTTOM = 0;
const PADDING_LEFT_RIGHT = 73;

const Wrapper = styled.View`
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  padding: ${PADDING_TOP}px ${PADDING_LEFT_RIGHT}px ${PADDING_BOTTOM}px;
  width: ${WIDTH}px;
`;

const WideContent = ({ children }) => <Wrapper>{children}</Wrapper>;

WideContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WideContent;
