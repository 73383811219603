export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const areArraysEqual = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

export const removeEquals = (arr) =>
  arr.filter((item, pos, self) => self.indexOf(item) === pos).sort((a, b) => a - b);
