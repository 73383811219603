import React from 'react';
import PropTypes from 'prop-types';
import { FlatList } from '@sp/ui/base/lists';

import { getFolderId } from 'helpers/documents';

import FolderRow from './FolderRow';
import DocumentRow from './DocumentRow';

const DocumentList = ({ folders, documents, showFolder, showDocument, isInPoliceFolder }) => {
  const data = [...documents, ...folders];

  return (
    <>
      {
        // NOTE: showFolder is not set on first render
        // because it is generated by the action wrapper
        showFolder && (
          <FlatList
            data={data}
            keyExtractor={(item) => `${item.title}${item.police || item.type}`}
            renderItem={({ item, index }) => {
              if (item.type) {
                return (
                  <DocumentRow
                    document={item}
                    index={index}
                    onPress={() => showDocument(item.downloadUrl, item.title)}
                  />
                );
              }

              return (
                <FolderRow
                  isInPoliceFolder={isInPoliceFolder}
                  folder={item}
                  index={index}
                  onPress={() => showFolder(getFolderId(item))}
                />
              );
            }}
          />
        )
      }
    </>
  );
};

DocumentList.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      police: PropTypes.string.isRequired,
    })
  ),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      downloadUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  showFolder: PropTypes.func,
  showDocument: PropTypes.func,
  isInPoliceFolder: PropTypes.bool.isRequired,
};

DocumentList.defaultProps = {
  folders: [],
  documents: [],
  showFolder: undefined,
  showDocument: undefined,
};

export default DocumentList;
