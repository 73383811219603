import React from 'react';
import PropTypes from 'prop-types';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import withComponentContainer from 'decorators/withComponentContainer';
import { openPoliceDocument as openPoliceDocumentAction } from 'actions/documents';
import { fetchSavingsOverview } from 'actions/savings';
import { selectAllSavingsData } from 'selectors/savings';
import AboutCostsSP from '../costs/AboutCostsSP';
import AboutCostsForOtherPensions from '../costs/AboutCostsForOtherPensions';
import { getPolicyDetails } from '../../helpers/getPolicyDetails';
import ModalWrapper from './ModalWrapper';

const getContent = (type, isLink, openLatestDepotAccount) =>
  type === 'SP' ? (
    <AboutCostsSP openLatestDepotAccount={openLatestDepotAccount} isLink={isLink} type={type} />
  ) : (
    <AboutCostsForOtherPensions openLatestDepotAccount={openLatestDepotAccount} type={type} />
  );

const AboutCosts = ({ openLatestDepotAccount, savingsData, documentsLoading }) => {
  const route = useRoute();
  const navigation = useNavigation();

  const { pID } = route.params;

  const { isLink, type } = getPolicyDetails(savingsData, pID);
  const Content = getContent(type, isLink, openLatestDepotAccount);

  return (
    <ModalWrapper
      onClose={() => navigation.navigate(routeNames.SAVINGS_COSTS, { pID })}
      loading={documentsLoading}
    >
      {Content}
    </ModalWrapper>
  );
};

AboutCosts.propTypes = {
  openLatestDepotAccount: PropTypes.func,
  documentsLoading: PropTypes.bool,
  savingsData: PropTypes.object.isRequired,
};

AboutCosts.defaultProps = {
  documentsLoading: false,
  openLatestDepotAccount: undefined,
};

const mapStateToProps = (state) => ({
  savingsData: selectAllSavingsData(state),
  documentsLoading: state.areas.documents.loading,
});

export default withComponentContainer({
  mapStateToProps,
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { dispatch, navigation, savingsData, route } = getProps();
        const pID = route?.params?.pID;

        if (pID && !savingsData) await dispatch(fetchSavingsOverview());

        if (!pID && navigation) navigation.replace(routeNames.SAVINGS_OVERVIEW);

        return {
          openLatestDepotAccount: () => {
            action(
              async () => {
                await dispatch(
                  openPoliceDocumentAction(pID, 'openLatestDepotAccount', () =>
                    navigation.navigate(routeNames.SAVINGS_COSTS)
                  )
                );
              },
              {
                loader: true,
              }
            );
          },
        };
      },
      {
        loader: true,
      }
    ),
})(AboutCosts);
