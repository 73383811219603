import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import styled, { withTheme } from 'styled-components/native';
import { useAppDispatch } from 'hooks/redux';
import withComponentContainer from 'decorators/withComponentContainer';
import { Button } from '@sp/ui/base/buttons';
import * as routeNames from 'constants/routeNames';
import {
  fetchTax,
  fetchPensionsInfo,
  fetchTaxAndPensionsInfo,
} from 'actions/fetchTaxAndPensionsInfo';
import { getProfileCompleteness } from 'actions/digital-advice';
import {
  refetchNakedDashboardErrors,
  ignoreNakedDashboardError as ignoreNakedDashboardErrorAction,
} from 'actions/dashboard';
import { selectProfileCompletenessInfo } from 'selectors/profile';
import { selectIsDeviceTypeMobile } from 'selectors/device';

const BUTTON_MARGIN_TOP = 10;

const ButtonWrapper = styled.View`
  margin-top: ${BUTTON_MARGIN_TOP}px;
  align-items: center;
`;

const ErrorButton = ({
  i18n,
  text,
  type,
  name,
  nakedDashboardErrors,
  ignoreAndRefetchErrors,
  isMobile,
  errorId,
}) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const updateTaxOrPensionsInfo = useCallback(
    () => dispatch(name === 'UpdatePensionDataButton' ? fetchPensionsInfo() : fetchTax()),
    [dispatch, name]
  );

  const updateTaxAndPensionsInfo = useCallback(
    () => dispatch(fetchTaxAndPensionsInfo()),
    [dispatch]
  );

  const getButtonProps = () => {
    switch (name) {
      case 'AdjustIncomeButton':
        return {
          onPress: () => navigation.navigate(routeNames.ADJUST_DISPOSABLE_INCOME),
          text: i18n.t('dashboardErrors|adjustDisposableIncome'),
          testID: name,
        };
      case 'ContactUsButton':
        return {
          onPress: () => navigation.navigate(routeNames.MODAL_CMS_STACK, { id: 'contactoverview' }),
          text: i18n.t('dashboardErrors|contactUs'),
          testID: name,
        };
      case 'UpdatePensionDataButton':
        return {
          onPress: updateTaxOrPensionsInfo,
          text: i18n.t('dashboardErrors|getPensionsInfo'),
          testID: name,
        };
      case 'UpdateTaxDataButton':
        return {
          onPress: updateTaxOrPensionsInfo,
          text: i18n.t('dashboardErrors|getTaxInfo'),
          testID: name,
        };
      case 'UpdatePensionAndTaxButton':
        return {
          onPress: updateTaxAndPensionsInfo,
          text: i18n.t('dashboardErrors|getInformation'),
          testID: name,
        };
      case 'UseExistingDataButton': {
        const { dashboardArea } = nakedDashboardErrors;

        return {
          onPress: () => ignoreAndRefetchErrors(errorId, dashboardArea),
          testID: name,
        };
      }
      case 'UpdateChildrenInformation':
        return {
          onPress: () => navigation.navigate(routeNames.CHILDREN),
          testID: name,
          text: i18n.t('dashboard|addInformation'),
        };
      case 'AdjustCalculation':
        return {
          onPress: () => navigation.navigate(routeNames.ADJUST_CALCULATION),
          testID: name,
          text: i18n.t('dashboard|adjustCalculation'),
        };
      default:
        return undefined;
    }
  };

  const buttonProps = getButtonProps();
  const button = {
    ...buttonProps,
    text: text || buttonProps.text,
    type,
    size: isMobile ? 'medium' : 'normal',
  };

  return (
    <ButtonWrapper key={button.text}>
      <Button {...button} />
    </ButtonWrapper>
  );
};

const mapStateToProps = (state) => ({
  profileCompletenessInfo: selectProfileCompletenessInfo(state),
  isMobile: selectIsDeviceTypeMobile(state),
});

ErrorButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  ignoreAndRefetchErrors: PropTypes.func,
  nakedDashboardErrors: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  errorId: PropTypes.string,
};

ErrorButton.defaultProps = {
  text: undefined,
  type: 'primary',
  name: undefined,
  ignoreAndRefetchErrors: undefined,
  nakedDashboardErrors: undefined,
  errorId: undefined,
};

export default withTheme(
  withComponentContainer({
    mapStateToProps,
    i18ns: ['dashboardErrors'],
    init: ({ action, getProps }) =>
      action(
        async () => {
          const { dispatch, profileCompletenessInfo } = getProps();

          if (!profileCompletenessInfo) {
            await dispatch(getProfileCompleteness());
          }

          return {
            ignoreAndRefetchErrors: (errorId, area) => {
              dispatch(ignoreNakedDashboardErrorAction(errorId));
              dispatch(refetchNakedDashboardErrors(area));
            },
          };
        },
        {
          loader: true,
        }
      ),
  })(ErrorButton)
);
