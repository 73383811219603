import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import currencySymbol from 'helpers/currencySymbol';
import { formatMoney, formatMonth, capitalizeFirstLetter } from 'helpers/string';
import { ListWithSeparator } from '@sp/ui/base/lists';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import DepositCard from '../deposits/DepositCard';

const DepositsBrowser = ({ data, theme }) => {
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    if (data.length > 0) {
      setIsOpen(data.map((item, i) => ({ i, status: false })));
    }
  }, [data]);

  const isDepositRowOpen = (i) => isOpen?.find((item) => item.i === i)?.status;

  return (
    <ListWithSeparator separator={RowBorder}>
      {data.map((item, i) => (
        <Fragment key={i}>
          <OneLineRow
            arrow={{ direction: isDepositRowOpen(i) ? 'up' : 'down' }}
            iconLeft={{
              name: isDepositRowOpen(i) ? 'pig' : 'piggyBank',
              fill: isDepositRowOpen(i) ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_INACTIVE,
            }}
            backgroundColor={
              isDepositRowOpen(i)
                ? theme.COLORS.PRIMARY_VARIANT1
                : theme.COLORS.SECONDARY_BACKGROUND
            }
            textLeft={capitalizeFirstLetter(formatMonth(item.periodStart))}
            textRight={`${formatMoney(item.totalAmount, true)}${currencySymbol(
              item.totalAmount.currency.alphaCode
            )}`}
            onPress={() =>
              setIsOpen(isOpen.map((e) => (e.i === i ? { ...e, status: !e.status } : e)))
            }
          />
          {isDepositRowOpen(i) && <DepositCard item={item} />}
        </Fragment>
      ))}
    </ListWithSeparator>
  );
};

DepositsBrowser.propTypes = {
  data: PropTypes.any,
  theme: PropTypes.object.isRequired,
};

DepositsBrowser.defaultProps = {
  data: undefined,
};

export default withTheme(DepositsBrowser);
