import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/native';
import lottie from 'lottie-web';
import Loading from './NotFound.json';

const HEIGHT = 400;

interface LottieProps {
  data: typeof Loading;
  autoplay?: boolean;
  height?: number;
  marginTop?: number;
}

const LottieWrapper = styled.View<{
  height?: number;
  marginTop?: number;
}>`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${HEIGHT}px;
`;

const Lottie: React.FC<LottieProps> = ({ data, autoplay, height, marginTop }) => {
  const lottieWrapperRefLoading = useRef<any>();

  useEffect(() => {
    if (lottieWrapperRefLoading?.current) {
      lottie.loadAnimation({
        container: lottieWrapperRefLoading.current,
        renderer: 'svg',
        loop: false,
        autoplay: autoplay ?? true,
        animationData: data,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return <LottieWrapper ref={lottieWrapperRefLoading} height={height} marginTop={marginTop} />;
};

export default Lottie;
