import axios from 'axios';
import { isChrome } from 'helpers/browser';
import * as routeNames from 'constants/routeNames';

export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = global.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new global.Blob(byteArrays, { type: contentType });
};

const createDocumentBlob = async (url, token) => {
  const { data, headers } = await axios.get(url, {
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const contentType = headers['content-type'];
  return new global.Blob([data], { type: contentType });
};

export const downloadAndSaveOnWeb = async (url, token, title) => {
  const blob = await createDocumentBlob(url, token);
  const blobUrl = URL.createObjectURL(blob);
  const link = global.document.createElement('a');
  link.href = blobUrl;
  link.target = '_target';
  link.download = `${title}.pdf`;
  global.document.body.appendChild(link);
  link.click();
  global.document.body.removeChild(link);
};

export const downloadAndOpenOnWeb = async (url, token, win) => {
  const blob = await createDocumentBlob(url, token);
  const blobUrl = URL.createObjectURL(blob);

  if (global.window.navigator && global.window.navigator.msSaveOrOpenBlob) {
    if (win) {
      win.close();
    }
    global.window.navigator.msSaveOrOpenBlob(blob, 'document.pdf');
    return;
  }

  win =
    win ||
    global.open(
      `${global.location.protocol}//${global.location.host}/${routeNames.LOADING}?preventAppStart=true`,
      '_blank'
    );

  if (isChrome() && win.document.readyState !== 'complete') {
    // NOTE: This is made specifically for Chrome with AdBlock enabled
    win.onload = () => {
      win.location = blobUrl;
    };
  } else {
    if (win) {
      win.location = blobUrl;
    } else {
      win = global.open(blobUrl, '_blank');
    }

    win.focus();
  }

  setTimeout(() => {
    URL.revokeObjectURL(blobUrl);
  }, 5000);
};

export const getFolderId = (folder) => `${folder.police}-${folder.title}`;

// NOTE: we need usePoliceAsId flag to support the contract in the middleware
export const findFolderContents = (folders, folderId, usePoliceAsId = false) => {
  if (!folders) {
    return undefined;
  }

  let result = folders.find(
    (folder) => (usePoliceAsId ? folder.police : getFolderId(folder)) === folderId
  );

  if (result) {
    return result;
  }

  folders.some((folder) => {
    result = findFolderContents(folder.folders, folderId, usePoliceAsId);
    return !!result;
  });

  return result;
};
