import { HealthBenefitsState } from 'reducers/healthBenefits';
interface StoreSlice {
  areas: { healthBenefits: HealthBenefitsState };
}

export const selectHealthBenefits = (state: StoreSlice) =>
  state.areas.healthBenefits.data?.benefits;

export const selectHasHealthBenefits = (state: StoreSlice) =>
  state.areas.healthBenefits?.hasHealthBenefits;

export const selectHealthBenefitDetails = (state: StoreSlice, coverageName: string) =>
  selectHealthBenefits(state)?.find(({ name }) => name === coverageName)?.pages[0];
