import React from 'react';
import PropTypes from 'prop-types';
import { createStackNavigator } from '@react-navigation/stack';

import * as routeNames from 'constants/routeNames';
import Page from './Page';

const Stack = createStackNavigator();

const CMSNavigator = (props) => (
  <Stack.Navigator
    initialRouteName={routeNames.CMS_PAGE}
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name={routeNames.CMS_PAGE}
      component={Page}
      initialParams={{
        isModal: true,
        // FIXME: https://github.com/react-navigation/rfcs/issues/43
        ...props.route.params,
      }}
    />
  </Stack.Navigator>
);

CMSNavigator.propTypes = {
  route: PropTypes.object.isRequired,
};

export default CMSNavigator;
