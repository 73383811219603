import styled from 'styled-components/native';

const BackgroundOverlay = styled.View`
  background: ${({ theme }) => theme.COLORS.V2_DARK};
  opacity: 0.68;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default BackgroundOverlay;
