import React from 'react';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import Description from 'areas/dashboard/Description';
import withComponentContainer from 'decorators/withComponentContainer';
import Markdown from '@sp/ui/base/Markdown';
import AwardRulesNotFound from 'areas/dashboard/AwardRules/AwardRulesNotFound';
import { withTheme } from 'styled-components/native';
import { selectShowGrossAmount } from 'selectors/dashboard';

const DeathDescription = ({ i18n, theme, awardRules, awardRulesSucceeded, showGrossAmount }) => {
  const navigation = useNavigation();

  return (
    <Description
      title={i18n.t('dashboard|noRecommendation')}
      description={
        showGrossAmount
          ? i18n.t('dashboard|payoutDeathNoRecommendationWithGross')
          : i18n.t('dashboard|payoutDeathNoRecommendation')
      }
      infoAccessibilityLabel={i18n.t('dashboard|deathPayoutModalA11y')}
      modalDescription={
        <>
          {awardRulesSucceeded ? (
            <Markdown
              styles={{
                heading2: {
                  paddingTop: theme.SPACINGS.sm,
                  paddingBottom: theme.SPACINGS.md,
                },
              }}
            >
              {awardRules?.descriptions?.deathDashboardInfoMarkdownText}
            </Markdown>
          ) : (
            <AwardRulesNotFound disableMargin />
          )}
        </>
      }
      navigation={navigation}
    />
  );
};

DeathDescription.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  awardRules: PropTypes.object,
  awardRulesSucceeded: PropTypes.bool,
  showGrossAmount: PropTypes.bool.isRequired,
};

DeathDescription.defaultProps = {
  awardRules: undefined,
  awardRulesSucceeded: false,
};

const mapStateToProps = (state) => ({
  showGrossAmount: selectShowGrossAmount(state),
});

export default withComponentContainer({
  mapStateToProps,
  hasLocalNotifications: false,
  awardRules: undefined,
  awardRulesSucceeded: false,
})(withTheme(DeathDescription));
