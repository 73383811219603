import { actions as formActions } from 'react-formed';
import { MAIL_SIGN, DOCUMENT_VIEW, LOADING } from 'constants/routeNames';
import withScreenContainer from 'decorators/withScreenContainer';
import { getAccessToken, getServiceUrl } from 'actions/api';
import {
  markRead,
  fetch,
  fetchIndividual,
  create,
  moveToArchive,
  moveToInbox,
  createSigningUrl,
  completeSigning,
  cancelPollIndividualMail,
  patchOverviewWithUpdatedMail,
} from 'actions/mailbox';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectConversationById, selectServiceMessages } from 'selectors/mailbox';
import { downloadAndOpenOnWeb, downloadAndSaveOnWeb } from 'helpers/documents';
import { isWeb } from 'helpers/platform';
import { isFirefox } from 'helpers/browser';
import { validateAttachments, patchAttachmentsWithBase64 } from 'areas/mailbox/helpers/attachments';
import Details from './Details';

const mapStateToProps = (state, props) => {
  const { route } = props;
  const { id } = route.params;
  const conversation = selectConversationById(state, id);

  return {
    ...props,
    id,
    signed: route.params.signed,
    signedMessageId: route.params.signedMessageId,
    documentId: route.params.documentId,
    form: state.forms.mailboxReply,
    sender: conversation.sender,
    subject: conversation.title,
    createdOn: conversation.createdOn,
    isArchived: conversation.isArchived,
    canReply: conversation.canReply,
    data: state.areas.mailbox.conversation || [],
    isDesktop: selectIsDeviceTypeDesktop(state),
    isAdvisorsBusy: selectServiceMessages(state).isAdvisorsBusy,
  };
};

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['mailbox'],
  init: ({ action, props, getProps }) => {
    const { dispatch, showError, showNotification, i18n } = props;

    const fetchAllMails = (isArchived) => dispatch(fetch(isArchived ? 'archive' : 'inbox'));

    action(
      async () => {
        if (props.signed) {
          await dispatch(completeSigning(props.signedMessageId, props.id, props.documentId));
        }

        if (!props.subject) {
          await fetchAllMails(props.isArchived);
        }

        await dispatch(fetchIndividual(props.id));
        dispatch(markRead(props.id));
      },
      {
        loader: true,
      }
    );

    return {
      fetchAllMails: (isArchived) => action(async () => fetchAllMails(isArchived)),
      validateAttachments: (attachments) =>
        action(async () => validateAttachments(attachments, showError, i18n)),
      toggleArchived: () =>
        action(
          async () => {
            const { id, isArchived, navigation } = getProps();

            if (isArchived) {
              await dispatch(moveToInbox(id));
            } else {
              await dispatch(moveToArchive(id));
            }
            navigation.goBack();
          },
          {
            loader: true,
            error: {
              type: 'notification',
              retry: true,
              onPressButton: () =>
                action(
                  async () => {
                    const { navigation } = getProps();
                    await dispatch([fetch('archive'), fetch('inbox')]);
                    navigation.goBack();
                  },
                  {
                    loader: true,
                  }
                ),
              buttonText: i18n.t('refresh'),
            },
          }
        ),
      send: (attachments) =>
        action(
          async () => {
            const { form } = getProps();

            const patchedAttachments = await patchAttachmentsWithBase64(attachments);

            await dispatch(
              create({
                ...form,
                files: patchedAttachments,
                threadId: props.id,
              })
            );
            await dispatch(fetchIndividual(props.id));
            await dispatch(formActions.clear('mailboxReply'));
            showNotification(i18n.t('mailbox|messageSentMsg'));
          },
          {
            loader: true,
            error: {
              message: i18n.t('mailbox|sendErrorMsg'),
            },
          }
        ),
      sign: (mailId, documentId) =>
        action(async () => {
          const { id: threadId, navigation } = getProps();
          if (isWeb) {
            action(
              async () => {
                const { signingUrl } = await dispatch(
                  createSigningUrl(mailId, threadId, documentId)
                );
                global.window.location = signingUrl;
              },
              {
                loader: true,
              }
            );
          } else {
            navigation.navigate(MAIL_SIGN, {
              id: mailId,
              threadId,
              documentId,
            });
          }
        }),
      open: async (document) => {
        const { navigation } = getProps();
        const apiUrl = await dispatch(getServiceUrl('api'));
        const absoluteUrl = `${apiUrl}${document.downloadUrl}`;

        if (isWeb) {
          // NOTE: this is outside of action to prevent pop-up blockers
          const win = isFirefox()
            ? undefined
            : global.open(
                `${global.location.protocol}//${global.location.host}/${LOADING}?preventAppStart=true`,
                '_blank'
              );

          action(async () => {
            const token = await dispatch(getAccessToken());
            if (isFirefox()) {
              await downloadAndSaveOnWeb(absoluteUrl, token, document.title);
            } else {
              await downloadAndOpenOnWeb(absoluteUrl, token, win);
            }
          });
        } else {
          navigation.navigate(DOCUMENT_VIEW, {
            url: encodeURIComponent(absoluteUrl),
            title: document.title,
          });
        }
      },
      cancelPollIndividualMail: () => dispatch(cancelPollIndividualMail()),
      patchOverviewWithUpdatedMail: (data) => dispatch(patchOverviewWithUpdatedMail(data)),
    };
  },
  trackAs: ({ id, route }) => ({
    area: route.name,
    action: 'ClickViewMail',
    params: { listIndex: id },
  }),
})(Details);
