import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import { Dimensions } from 'react-native';
import Graph from '@sp/ui/base/Graph';
import ExpandableArea from 'containers/base/ExpandableArea';
import Margin from '@sp/ui/base/Margin';
import { getGraphAccessibilityLabel } from 'areas/dashboard/helpers';
import withTranslation from 'decorators/i18n';
import { isWeb } from 'helpers/platform';

const GRAPH_PAGE_PADDING = 32;
const GRAPH_MAX_WIDTH_DESKTOP = 732;
const BAR_MAX_WIDTH_DESKTOP = 80;
const DEFAULT_HEIGHT = 250;
const EXPANDABLE_AREA_MIN_HEIGHT = 88;

const Container = styled.View`
  margin-left: auto;
  margin-right: auto;
`;

const ExpandableWrapper = ({ expandable, maxHeight, children }) =>
  expandable ? (
    <ExpandableArea minHeight={EXPANDABLE_AREA_MIN_HEIGHT} maxHeight={maxHeight}>
      {children}
    </ExpandableArea>
  ) : (
    <>{children}</>
  );

ExpandableWrapper.propTypes = {
  expandable: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const Graphs = ({
  graphs,
  selected,
  onSelect,
  withContent,
  height,
  expandable,
  maxWidth,
  barMaxWidth,
  barOuterWidth,
  i18n,
  graphPagePadding,
  withHorizontalPadding,
  maxValue,
}) => (
  <>
    {graphs.length > 0 && (
      <ExpandableWrapper expandable={expandable} maxHeight={height}>
        <Container>
          <Margin horizontal={withHorizontalPadding && 'md'}>
            <Graph
              maxValue={maxValue}
              withSelectedBorder
              selectedIndex={selected}
              maxWidth={Math.min(maxWidth, GRAPH_MAX_WIDTH_DESKTOP - graphPagePadding)}
              paddingLeftAndRight={graphPagePadding}
              centerContent
              enableScroll={false}
              height={height}
              barWidth={isWeb ? Math.min(barMaxWidth, Dimensions.get('window').width / 5) : 60}
              maxBarOuterWidth
              barOuterWidth={barOuterWidth}
              bars={graphs.map((g, index) => ({
                onSelect: onSelect && (() => onSelect(index, g.bar.trackId)),
                selected: selected === index,
                selectedIndex: 2,
                accessibilityLabel: getGraphAccessibilityLabel(
                  i18n,
                  g.bar.description,
                  g.bar.amount
                ),
                label: g.bar.amount,
                description: g.bar.description,
                segments: [
                  {
                    color: g.bar.color,
                    value: g.bar.value,
                  },
                ],
                testID: g.testID,
                barWidth: g.barWidth,
              }))}
            />
          </Margin>
        </Container>
      </ExpandableWrapper>
    )}
    {withContent && graphs[selected] && graphs[selected].content.component}
  </>
);

export const GraphShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    bar: PropTypes.shape({
      amount: PropTypes.string,
      height: PropTypes.number,
      description: PropTypes.string,
    }),
    content: PropTypes.object,
    card: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  })
);

Graphs.propTypes = {
  height: PropTypes.number,
  barOuterWidth: PropTypes.number,
  graphs: GraphShape.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.number,
  withContent: PropTypes.bool,
  expandable: PropTypes.bool,
  maxWidth: PropTypes.number,
  barMaxWidth: PropTypes.number,
  i18n: PropTypes.object.isRequired,
  graphPagePadding: PropTypes.number,
  maxValue: PropTypes.number,
  withHorizontalPadding: PropTypes.bool,
};

Graphs.defaultProps = {
  onSelect: undefined,
  barOuterWidth: undefined,
  selected: undefined,
  withContent: false,
  height: DEFAULT_HEIGHT,
  expandable: true,
  maxWidth: GRAPH_MAX_WIDTH_DESKTOP,
  barMaxWidth: BAR_MAX_WIDTH_DESKTOP,
  graphPagePadding: GRAPH_PAGE_PADDING,
  withHorizontalPadding: true,
  showTaxSwitch: true,
  maxValue: undefined,
};

export default withTranslation()(withTheme(Graphs));
