import { DEATH, RETIREMENT } from 'areas/dashboard/constants/dashboardTypes';
import { GET_AWARD_RULES, GET_SSO_TOKEN } from 'constants/actionTypes';

const { actions } = require('react-formed');
const { get, post, remove } = require('./api');
const { getMonthlyValue } = require('./helpers/numbers');

const calculationEndpointURI = '/digital-advice/v2/tax/calculation';

export const getAwardRules = () =>
  post(GET_AWARD_RULES, {}, 'api', `/digital-advice/v2/policies/awardrules`, {
    disableTimeout: true,
  });

export const getSsoToken = () => post(GET_SSO_TOKEN, {}, 'api', '/digital-advice/v2/sso-token');

// REFAC: the API of digital-advice needs to be reworked to avoid running this logic on the client
// this is causing issues with prop types due to the map call taking a long time to process
const mapRetirement = ({
  succeeded,
  inputStatus,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  recommendedPensionYear,
  allPensionYears,
  taxBasedKommune,
  perCompanyDistinctFriendlyErrors,
  taxBasedPaysKirkeskat,
  paysKirkeskat,
  errorReason,
  failedCoverageOverview,
  earliestFolkepensionAge,
  updatedPrimaryPensionAge,
}: any) => ({
  succeeded,
  status: inputStatus,
  perCompanyDistinctFriendlyErrors,
  error: errorReason,
  failedCoverageOverview,
  incomeToday: incomeToday && {
    currency: incomeToday.currencyCode,
    amount: getMonthlyValue(incomeToday.amount),
    grossAmount: getMonthlyValue(incomeToday?.grossAmount),
  },
  totalIncomeToday: totalIncomeToday && {
    currency: totalIncomeToday.currencyCode,
    amount: getMonthlyValue(totalIncomeToday.amount),
    grossAmount: getMonthlyValue(totalIncomeToday?.grossAmount),
  },
  childAndYouthBenefitToday: childAndYouthBenefitToday && {
    currency: childAndYouthBenefitToday.currencyCode,
    amount: getMonthlyValue(childAndYouthBenefitToday.amount),
    grossAmount: getMonthlyValue(childAndYouthBenefitToday?.grossAmount),
  },
  recommendedIncome:
    succeeded && recommendedPensionYear
      ? {
          currency: recommendedPensionYear.averageIncomePerYearWhileRetired.currencyCode,
          pensionVsTodayRatio: recommendedPensionYear.pensionVsTodayRatio,
          pensionAge: recommendedPensionYear.pensionAge,
          amount: recommendedPensionYear.averageRoundedIncomePerMonthWhileRetired?.amount,
          grossAmount: recommendedPensionYear.averageRoundedIncomePerMonthWhileRetired?.grossAmount,
        }
      : undefined,
  allPensionYears: (succeeded ? allPensionYears : [])
    .filter((p: any) => !p.apiExceptionMessage)
    .map((p: any) => ({
      currency: p.averageIncomePerYearWhileRetired.currencyCode,
      pensionVsTodayRatio: p.pensionVsTodayRatio,
      pensionAge: p.pensionAge,
      oneTimePayment: p.oneTimePayment,
      perYear: p.perYear,
      coverageOverview: p.coverageOverview,
      amount: p.averageRoundedIncomePerMonthWhileRetired?.amount,
      grossAmount: p.averageRoundedIncomePerMonthWhileRetired?.grossAmount,
      opsatFolkepensionAge: p.opsatFolkepensionAge,
    })),
  municipality: taxBasedKommune,
  taxBasedPaysKirkeskat,
  paysKirkeskat,
  earliestFolkepensionAge,
  updatedPrimaryPensionAge,
});

export const fetchRetirement = () =>
  post(
    'RETIREMENT_OVERVIEW',
    {
      scenarioEvent: RETIREMENT,
    },
    'api',
    calculationEndpointURI,
    {
      map: (response: any) => mapRetirement(response),
      disableTimeout: true,
      preventConcurrent: true,
    }
  );

const mapDisabilityAndDeath = ({ succeeded, inputStatus, allPensionYears, errorReason }: any) => ({
  succeeded,
  status: inputStatus,
  error: errorReason,
  allPensionYears: (allPensionYears || [])
    .filter((p: any) => !p.apiExceptionMessage)
    .map((p: any) => ({
      currency: p.averageIncomePerYearWhileRetired.currencyCode,
      pensionVsTodayRatio: p.pensionVsTodayRatio,
      pensionAge: p.pensionAge,
      oneTimePayment: p.oneTimePayment,
      coverageOverview: p.coverageOverview,
      perYear: p.perYear,
      amount: p.averageRoundedIncomePerMonthAfterInsuranceEvent?.amount,
      grossAmount: p.averageRoundedIncomePerMonthAfterInsuranceEvent?.grossAmount,
    })),
});

export const fetchDisability = () =>
  post(
    'DISABILITY_OVERVIEW',
    {
      scenarioEvent: 'disability',
    },
    'api',
    calculationEndpointURI,
    {
      map: (response: any) => mapDisabilityAndDeath(response),
      disableTimeout: true,
      preventConcurrent: true,
    }
  );

export const fetchDeath = () =>
  post(
    'DEATH_OVERVIEW',
    {
      scenarioEvent: DEATH,
      takeBestResultIfNoResultsExceedRecommendedRatio: true,
    },
    'api',
    calculationEndpointURI,
    {
      map: (response: any) => mapDisabilityAndDeath(response),
      disableTimeout: true,
      preventConcurrent: true,
    }
  );

export const clearIncomeForm = () => actions.clear('adjustIncome');

export const clearForms = () => [
  actions.clear('adjustCalculation'),
  actions.clear('adjustAge'),
  actions.clear('adjustIncome'),
  actions.clear('adjustGrossAmount'),
];

export const acceptIncompleteData = () => ({
  type: 'DASHBOARD_ACCEPT_INCOMPLETE_DATA',
});

export const getProfileCompleteness = () =>
  get('PROFILE_COMPLETENESS', 'api', '/digital-advice/v2/profile/completeness');

export const deleteProfile = () =>
  remove('PROFILE_REMOVE', 'api', '/digital-advice/v2/profile', {});
