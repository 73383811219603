import React from 'react';
import { useTheme } from 'styled-components/native';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Subtitle } = require('@sp/ui/v2/typography');

type Props = {
  text: string;
  hasMargin?: boolean;
  testID?: string;
};

const FooterWarning: React.FC<Props> = ({ text, hasMargin, testID }) => {
  const { COLORS } = useTheme();

  return (
    <Margin testID={testID} horizontal={hasMargin ? 'xl' : ''} vertical="md">
      <Subtitle color={COLORS.PRIMARY_ALERT}>{text}</Subtitle>
    </Margin>
  );
};

FooterWarning.defaultProps = {
  hasMargin: true,
  testID: undefined,
};

export default FooterWarning;
