import React from 'react';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { selectAllSavingsData } from 'selectors/savings';
import { Subtitle } from '@sp/ui/typography';
import { RowBorder } from '@sp/ui/base/rows';
import { Wrapper } from './styles';
import { getSavingsDetails } from '../../helpers/getSavingsDetails';

const getFooterText = (i18n, pensionInfoLastUpdate, hasInsurance) => {
  const generalPensionInfo = i18n.t(
    'savings|Hvis du har pensioner i andre selskaber, kan du nemt hente oplysninger om dem fra PensionsInfo.'
  );
  const pensionInfoWithDate = i18n.t(
    'savings|Data fra PensionsInfo er senest hentet d. {{ pensionInfoLastUpdate }}.',
    { pensionInfoLastUpdate }
  );
  const insurancePoliceInfo = i18n.t(
    'savings|*Bemærk venligst at dækningen på denne police er en forsikring og ikke en opsparing.'
  );

  switch (true) {
    case pensionInfoLastUpdate && hasInsurance:
      return `${generalPensionInfo} ${pensionInfoWithDate} \n\n${insurancePoliceInfo}`;
    case pensionInfoLastUpdate && !hasInsurance:
      return `${generalPensionInfo} ${pensionInfoWithDate}`;
    case !pensionInfoLastUpdate && hasInsurance:
      return `${generalPensionInfo} \n\n${insurancePoliceInfo}`;
    default:
      return generalPensionInfo;
  }
};

const SavingsOverviewFooter = ({ savingsData, i18n }) => {
  const { pensionInfoLastUpdate, hasInsurance } = getSavingsDetails(savingsData);

  return (
    <>
      <RowBorder />
      <Wrapper>
        <Subtitle>{getFooterText(i18n, pensionInfoLastUpdate, hasInsurance)}</Subtitle>
      </Wrapper>
    </>
  );
};

SavingsOverviewFooter.propTypes = {
  savingsData: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  savingsData: selectAllSavingsData(state),
});

export default withComponentContainer({ mapStateToProps })(SavingsOverviewFooter);
