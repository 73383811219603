const { formatNumberWithThousandSeparator } = require('helpers/string');

type PartnerIncomeOptions = {
  earlyRetirement?: string;
  monthlyIncome?: string;
  isChurchContributor?: boolean;
  hasElderCheque?: boolean;
  hasMissingIncome?: boolean;
};

type ReturnResponse = {
  enteredIncome: string;
  isIncomeValid: boolean;
  isChurchTaxSelected?: boolean;
  selectedEarlyRetirement?: string;
};

export const getInitialIncomeInformation = (
  partnerIncomeOptions: PartnerIncomeOptions
): ReturnResponse => {
  const { monthlyIncome, earlyRetirement, isChurchContributor, hasMissingIncome } =
    partnerIncomeOptions ?? {};

  return {
    enteredIncome: formatNumberWithThousandSeparator(monthlyIncome),
    isIncomeValid: !hasMissingIncome,
    isChurchTaxSelected: isChurchContributor,
    selectedEarlyRetirement: earlyRetirement?.toLowerCase(),
  };
};
