import { useCallback, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { isWeb } from 'helpers/platform';
import { useAppSelector } from 'hooks/redux';
import { selectShouldFetchPensionsInfo } from 'selectors/fetchTaxAndPensionsInfo';
import { FETCH_TAX_PENSIONSINFO_INTRODUCTION } from 'constants/routeNames';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import { useFinishFlow } from './useFinishFlow';
import fetchTaxAndPensionsInfoController from '../fetchTaxAndPensionsInfoController';

export const useNextAction = () => {
  const navigation = useNavigation();
  const { fetchingState, updateFetchingState, simulationClock } = useContext(
    FetchTaxAndPensionsInfoContext
  );

  const { removeBrowserService } = fetchTaxAndPensionsInfoController;
  const shouldFetchPensionsInfo = useAppSelector(selectShouldFetchPensionsInfo, shallowEqual);

  const [finishFlow] = useFinishFlow({ updateFetchingState });

  return useCallback(
    (skippedAreas?: Record<string, boolean>) => {
      const { isSkippingPensionsInfo } = skippedAreas ?? {};

      if (shouldFetchPensionsInfo && !isSkippingPensionsInfo) {
        if (Object.keys(fetchingState).length > 0) {
          if (isWeb) {
            removeBrowserService();
          }

          simulationClock.value = 0;
          updateFetchingState({});
        }

        navigation.dispatch(
          CommonActions.navigate({
            name: FETCH_TAX_PENSIONSINFO_INTRODUCTION,
            key: 'pensions-info',
          })
        );
      } else {
        finishFlow();
      }
    },
    [
      shouldFetchPensionsInfo,
      fetchingState,
      navigation,
      simulationClock,
      updateFetchingState,
      removeBrowserService,
      finishFlow,
    ]
  );
};
