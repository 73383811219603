import { create } from '../../helpers/reducers';

const createDefaults = () => ({
  countries: undefined as any,
  municipalities: undefined as any,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    RESPONSE_COUNTRIES: (state, payload) => ({
      ...state,
      countries: payload.response,
    }),
    RESPONSE_MUNICIPALITIES: (state, payload) => ({
      ...state,
      municipalities: payload.response,
    }),
  },
  createDefaults
);

export type { State };
