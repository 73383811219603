import { lazy } from 'react';
import withSuspense from 'decorators/withSuspense';
import * as routeNames from 'constants/routeNames';
import { DashboardTypes } from '../constants/dashboardTypes';

export type PayoutsOriginRouteType = {
  [routeNames.PAYOUTS_ORIGIN]: { payoutType: DashboardTypes };
};

const LazyPayoutsOriginScreen = lazy(() => import('areas/dashboard/PayoutsOrigin'));
const PayoutsOriginScreen = withSuspense(LazyPayoutsOriginScreen);

export default {
  [routeNames.PAYOUTS_ORIGIN]: {
    screen: PayoutsOriginScreen,
  },
};
