import React from 'react';
import Page from './Page';
import KeyboardProviderWrapper from '@sp/ui/context/KeyboardContext';

const InputPage = ({ ...props }) => (
  <KeyboardProviderWrapper>
    <Page {...props} />
  </KeyboardProviderWrapper>
);

export default InputPage;
