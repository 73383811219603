import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Label } from '@sp/ui/typography';
import { getCurrentDeviceType, isWeb } from '@sp/ui/helpers/device';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import { createMeasureElement, createViewElement } from './helpers';

// hack to disable accessibility.
const DisableAccessibility = styled.TouchableOpacity`
  border: 1px solid transparent;
`;

const ReadMore = styled(DisableAccessibility)`
  flex-shrink: 0;
`;

const ReadLess = styled(DisableAccessibility)`
  align-items: flex-end;
  width: 100%;
`;

const Content = styled.View`
  flex-shrink: 1;
`;

const Container = styled.View`
  flex-direction: row;
`;

const Outer = styled.View`
  height: 0;
  width: 0;
  overflow: hidden;
`;

const Inner = styled.View`
  position: absolute;
  opacity: 0;
`;

const View = styled.View`
  flex: 1;
`;

const ShowMoreWrapper = styled.View`
  padding-left: ${(props) => props.theme.SPACINGS.sm}px;
`;

const ShowMore = ({ children, theme, moreText, showMoreElement, lessText, showLessElement }) => {
  const [containerWidth, setContainerWidth] = useState();
  const [textWidth, setTextWidth] = useState();
  const [showMore, setShowMore] = useState();
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setShowMore(textWidth >= containerWidth);
  }, [containerWidth, textWidth, children]);

  if (getCurrentDeviceType() !== 'mobile') {
    if (typeof children === 'function') {
      return <View>{children(true)}</View>;
    }

    return renderStringOrComponent(children, Label);
  }

  const more = showMoreElement || (
    <ShowMoreWrapper>
      <Label color={theme.COLORS.PRIMARY_LINK}>{moreText}</Label>
    </ShowMoreWrapper>
  );

  const less = showLessElement || <Label color={theme.COLORS.PRIMARY_LINK}>{lessText}</Label>;

  const measureText = (evt) => {
    const { layout } = evt.nativeEvent;
    setTextWidth(layout.width);
  };

  return (
    <>
      <Container
        onLayout={(evt) => {
          const { layout } = evt.nativeEvent;
          setContainerWidth(layout.width);
        }}
      >
        <Outer>
          <Inner>{createMeasureElement(children, measureText)}</Inner>
        </Outer>

        <Content>{createViewElement(children, expanded)}</Content>

        {showMore && !expanded && (
          <ReadMore
            onPress={() => {
              setExpanded(true);
            }}
            accessibilityElementsHidden
            importantForAccessibility={isWeb ? 'yes' : 'no-hide-descendants'}
          >
            {more}
          </ReadMore>
        )}
      </Container>

      {expanded && (
        <Container>
          <ReadLess
            accessibilityElementsHidden
            importantForAccessibility={isWeb ? 'yes' : 'no-hide-descendants'}
            onPress={() => {
              setExpanded(false);
            }}
          >
            {less}
          </ReadLess>
        </Container>
      )}
    </>
  );
};

ShowMore.propTypes = {
  theme: PropTypes.object.isRequired,
  moreText: PropTypes.string.isRequired,
  lessText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showMoreElement: PropTypes.node,
  showLessElement: PropTypes.node,
};

ShowMore.defaultProps = {
  showMoreElement: undefined,
  showLessElement: undefined,
};

export default withTheme(ShowMore);
