import React, { Fragment } from 'react';
import ErrorButton from './ErrorButton';

export const getPensionTitle = (errorId, i18n) => {
  switch (errorId) {
    case 'Sc302':
    case 'Sc306':
      // received all info
      return i18n.t('dashboardErrors|pensionInfoAllReceived');
    case 'Sc300':
    case 'Sc304':
    case 'Sc308':
      // skipped pension
      return i18n.t('dashboardErrors|pensionInfoSkipped');
    case 'Sc200':
    case 'Sc303':
    case 'Sc307':
      // missing some data
      return i18n.t('dashboardErrors|pensionInfoMissingData');
    case 'Sc301':
    case 'Sc305':
    case 'Sc309':
      // error, didn't receive any data
      return i18n.t('dashboardErrors|pensionInfoNoDataReceived');
    default:
      return undefined;
  }
};

export const getTaxTitle = (errorId, i18n) => {
  switch (errorId) {
    case 'Sc200':
    case 'Sc300':
    case 'Sc301':
      // received all info
      return i18n.t('dashboardErrors|taxAllReceived');
    case 'Sc302':
    case 'Sc303':
    case 'Sc305':
      // skipped tax
      return i18n.t('dashboardErrors|taxSkipped');
    case 'Sc306':
    case 'Sc307':
    case 'Sc308':
      // error, didn't receive any data
      return i18n.t('dashboardErrors|taxNoDataReceived');
    default:
      return undefined;
  }
};

export const getFootNote = (taxDate, pensionDate, errorId, i18n) => {
  switch (errorId) {
    case 'Sc302':
    case 'Sc306':
      return i18n.t('dashboardErrors|footnoteAllPensionReceived', {
        date: pensionDate,
      });
    case 'Sc303':
    case 'Sc307':
      return i18n.t('dashboardErrors|footnoteMissingPension', {
        date: pensionDate,
      });
    case 'Sc406':
      return i18n.t('dashboardErrors|footnotePensionFetchedFrom', { date: pensionDate });
    case 'Sc300':
    case 'Sc301':
      return i18n.t('dashboardErrors|footnoteAllTaxReceived', {
        date: taxDate,
      });
    case 'Sc200':
      return i18n.t('dashboardErrors|footnoteTaxReceivedMissingPension', {
        pensionDate,
        taxDate,
      });
    default:
      return '';
  }
};

export const getButtons = (i18n, errorId, nakedDashboardErrors) => {
  switch (errorId) {
    case 'Sc200':
      return (
        <Fragment>
          <ErrorButton name="UpdatePensionDataButton" type="primary" />
          <ErrorButton
            name="UseExistingDataButton"
            text={i18n.t('dashboardErrors|showDashboardWithMissingData')}
            type="secondary"
            errorId={errorId}
            nakedDashboardErrors={nakedDashboardErrors}
          />
        </Fragment>
      );
    case 'Sc300':
    case 'Sc301':
      return <ErrorButton name="UpdatePensionDataButton" type="primary" />;
    case 'Sc302':
    case 'Sc303':
    case 'Sc306':
      return <ErrorButton name="UpdateTaxDataButton" type="primary" />;
    default:
      return <ErrorButton name="UpdatePensionAndTaxButton" type="primary" />;
  }
};

export const getIconType = (errorId, type) => {
  if (type === 'pension') {
    return [
      'Sc200',
      'Sc300',
      'Sc301',
      'Sc303',
      'Sc304',
      'Sc305',
      'Sc307',
      'Sc308',
      'Sc309',
    ].includes(errorId)
      ? 'warning'
      : 'success';
  }

  return ['Sc302', 'Sc303', 'Sc304', 'Sc305', 'Sc306', 'Sc307', 'Sc308', 'Sc309'].includes(errorId)
    ? 'warning'
    : 'success';
};

export const getDescription = (errorId, type, i18n) => {
  if (type === 'pension') {
    return ['Sc200', 'Sc302', 'Sc303', 'Sc305', 'Sc306', 'Sc307'].includes(errorId)
      ? i18n.t('dashboardErrors|pensionList')
      : i18n.t('dashboardErrors|pensionListWithExamples');
  }

  return ['Sc200', 'Sc300', 'Sc301'].includes(errorId)
    ? i18n.t('dashboardErrors|taxList')
    : i18n.t('dashboardErrors|taxListWithExamples');
};

export const getPensionSubtitle = (errorId, pensionStatus, i18n) => {
  let pensionSubtitle;
  const { companiesThatDidNotDeliverFullPensionsInfo, companiesThatDidDeliverFullPensionsInfo } =
    pensionStatus;

  if (['Sc302', 'Sc306'].includes(errorId)) {
    if (
      companiesThatDidDeliverFullPensionsInfo &&
      companiesThatDidDeliverFullPensionsInfo.length > 0
    ) {
      pensionSubtitle = companiesThatDidDeliverFullPensionsInfo;
    }
  } else if (
    companiesThatDidNotDeliverFullPensionsInfo &&
    companiesThatDidNotDeliverFullPensionsInfo.length > 0
  ) {
    pensionSubtitle = companiesThatDidNotDeliverFullPensionsInfo;
  } else {
    pensionSubtitle = [
      i18n.t('dashboardErrors|subItemAtp'),
      i18n.t('dashboardErrors|subItemOtherCompanies'),
    ];
  }
  return pensionSubtitle;
};
