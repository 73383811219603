import { create } from '../helpers/reducers';
import { TRANSFER_SAVINGS_INSURANCE } from 'areas/transfer/constants/selectionReferences';
import {
  UPDATE_TRANSFER_FORM,
  CREATE_TRANSFER_FORM,
  RESET_TRANSFER_FORM,
  UPDATE_TRANSFER_SOURCE_SELECTIONS,
  UPDATE_TRANSFER_QUESTION_ANSWERS,
  UPDATE_TRANSFER_CONTACT_DETAILS,
  RESPONSE_GET_TRANSFER_OVERVIEW,
  RESPONSE_POST_TRANSFER_CONSENTS,
} from 'constants/actionTypes';
import type { SelectionKeysTypes, QuestionReferencesTypes } from 'areas/transfer/types';

export type SelectionsTypes = {
  key: SelectionKeysTypes;
  skippableConditions: {
    transferJobChange: boolean;
  };
  questionReference: QuestionReferencesTypes;
  policyReference: string;
  isSelected?: boolean;
  isPrimary: boolean;
  isPreselected?: boolean;
  isCompleted?: boolean;
}[];

type ConsentsType = {
  key: string;
  text: string;
  version: number;
}[];

type TargetTypes = {
  policyId: string;
  blocked: boolean;
  consents: ConsentsType;
  validSources: boolean;
  sources?: {
    policyId: string;
    canBeTransferred: boolean;
    reasons: string[];
    mustSpecifyIssuer: boolean;
    mustSpecifyJobChange: string;
    hasBasisCoverage: boolean;
    canTransferToBasisDaekning: string;
    basisCoverageTexts: string[];
    savingsInsurance: string;
    mustSpecifySavingsInsurance: boolean;
    consents: ConsentsType;
  }[];
};

type PoliciesTypes = {
  policyId: string;
  name: string;
  balance: {
    amount: number;
    currency: string;
  };
  type: string;
  transferState: string;
};

type TransferDataTypes = {
  userCanTransfer: boolean;
  userCanTransferReason: string;
  userCanTransferCode: number;
  phoneRequired: boolean;
  emailRequired: boolean;
  consents: ConsentsType;
  targets: TargetTypes[];
  policies: PoliciesTypes[];
};

type TransferFormTypes = {
  targetId?: string;
  targetName?: string;
  targetType?: string;
  targetBalance?: {
    amount: number;
    currency: string;
  };
  transferableSources?: {
    policyId?: string;
    canBeTransferred?: boolean;
    reasons?: string[];
    mustSpecifyIssuer?: boolean;
    mustSpecifyJobChange?: string;
    hasBasisCoverage?: boolean;
    canTransferToBasisDaekning?: string;
    basisCoverageTexts?: string[];
    savingsInsurance?: string;
    mustSpecifySavingsInsurance?: boolean;
    consents?: ConsentsType;
    name?: string;
    balance?: {
      amount?: number;
      currency?: string;
    };
    type?: string;
    transferState?: string;
    areQuestionsSkippable?: boolean;
    isTransferable?: boolean;
    isSelected?: boolean;
    selections?: SelectionsTypes;
  }[];
  hasSelectedWithSavingsInsurance?: boolean;
  availableTransferQuestionIndexes?: number[];
  consents?: ConsentsType;
  contactDetails?: {
    email: string;
    phone: {
      number: string;
      countryCode: string;
    };
  };
};

export type TransferState = {
  data: TransferDataTypes;
  transferForm: TransferFormTypes;
};

const createDefaults = (): TransferState => ({
  data: undefined as any,
  transferForm: undefined as any,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [RESPONSE_GET_TRANSFER_OVERVIEW]: (state, payload) => ({
      ...state,
      data: payload.response,
    }),
    [RESPONSE_POST_TRANSFER_CONSENTS]: (state, payload) => {
      const { consents } = state.transferForm ?? {};
      const transferRateGuardConsent = consents?.find(({ key }) => key === 'TransferRateGuardInfo');
      const updatedConsents = payload.response.consents.concat(transferRateGuardConsent ?? []);

      return {
        ...state,
        transferForm: {
          ...state.transferForm,
          consents: updatedConsents,
        },
      };
    },
    [CREATE_TRANSFER_FORM]: (state, payload: { policyId: string }) => {
      const { policies, targets, consents } = state.data;
      const hasOneTransferableTarget = targets.length === 1;
      const { policyId: targetId } = hasOneTransferableTarget ? targets[0] : payload;
      const { name, balance, type } = policies.find(({ policyId }) => policyId === targetId) ?? {};
      const { sources } = targets.find(({ policyId }) => policyId === targetId) ?? {};

      return {
        ...state,
        transferForm: {
          ...state.transferForm,
          targetId,
          targetName: name,
          targetBalance: balance,
          targetType: type,
          transferableSources: sources
            ?.map((source) => {
              const {
                policyId,
                mustSpecifyIssuer,
                mustSpecifyJobChange,
                savingsInsurance,
                hasBasisCoverage,
                canBeTransferred,
              } = source;
              const { transferState } =
                policies?.find((policy) => policy.policyId === policyId) ?? {};

              return {
                ...source,
                ...policies.find((item) => item.policyId === source.policyId),
                areQuestionsSkippable:
                  !mustSpecifyIssuer &&
                  !hasBasisCoverage &&
                  mustSpecifyJobChange === 'No' &&
                  savingsInsurance !== 'Optional',
                isTransferable: canBeTransferred && transferState === 'Available',
                isSelected: false,
              };
            })
            ?.filter(({ isTransferable }) => isTransferable),
          availableTransferQuestionIndexes: [],
          hasSelectedWithSavingsInsurance: undefined,
          contactDetails: undefined,
          consents,
        },
      };
    },
    [UPDATE_TRANSFER_FORM]: (state, payload) => {
      switch (payload.action) {
        case UPDATE_TRANSFER_SOURCE_SELECTIONS: {
          return {
            ...state,
            transferForm: {
              ...state.transferForm,
              transferableSources: state.transferForm?.transferableSources?.map(
                (transferableSource: any) => ({
                  ...transferableSource,
                  selections: [],
                  isSelected: payload.selectedSourceIds.includes(transferableSource.policyId),
                })
              ),
              availableTransferQuestionIndexes: [],
              hasSelectedWithSavingsInsurance: undefined,
            },
          };
        }
        case UPDATE_TRANSFER_QUESTION_ANSWERS: {
          const { transferQuestionAnswers, availableIndexes } = payload;
          const { transferableSources, hasSelectedWithSavingsInsurance } = state.transferForm;

          return {
            ...state,
            transferForm: {
              ...state.transferForm,
              availableTransferQuestionIndexes: availableIndexes,
              transferableSources: transferableSources?.map((transferableSource) => ({
                ...transferableSource,
                selections:
                  transferQuestionAnswers.find(
                    ({ policyReference }: { policyReference: string }) =>
                      policyReference === transferableSource.policyId
                  )?.userSelections ?? transferableSource.selections,
              })),
              hasSelectedWithSavingsInsurance:
                transferQuestionAnswers.find(
                  ({ alternativeReference }: { alternativeReference: string }) =>
                    alternativeReference === TRANSFER_SAVINGS_INSURANCE
                )?.userSelections?.[0]?.isSelected ?? hasSelectedWithSavingsInsurance,
            },
          };
        }
        case UPDATE_TRANSFER_CONTACT_DETAILS: {
          return {
            ...state,
            transferForm: {
              ...state.transferForm,
              contactDetails: payload.contactDetails,
            },
          };
        }
        default:
          return state;
      }
    },
    [RESET_TRANSFER_FORM]: (state) => ({
      ...state,
      transferForm: undefined as any,
    }),
  },
  createDefaults
);

export type { State };
