import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import { Result } from '@sp/ui/pages/ResultPage';
import useI18n from 'hooks/useI18n';

const BlockingOverlay = ({ theme }) => {
  const { i18n } = useI18n();

  return (
    <Result
      iconName="warning"
      iconColor={theme.COLORS.PRIMARY}
      heading={i18n.t('dashboard|noAccessTitle')}
      description={i18n.t('dashboard|noAccessDescription')}
    />
  );
};

BlockingOverlay.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(BlockingOverlay);
