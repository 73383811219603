import { SET_FLOW, UPDATE_CURRENT_PROGRESS, UPDATE_FLOW_CONTROL } from 'constants/actionTypes';
import * as flowControl from 'constants/flowControl';
import { create } from 'helpers/reducers';

type StepUnionType = 'consents' | 'get-information' | 'other-information' | 'receipt';

export type CurrentProgressType = {
  step?: StepUnionType;
  title: string;
  isActive: boolean;
  isSelected: boolean;
  isPrevious: boolean;
  onPress: () => void;
};

interface State {
  selectedIndex?: number;
  activeProgressIndexes?: number[];
  currentProgress?: CurrentProgressType[];
  activeProgress?: string;
  status?:
    | typeof flowControl.FLOW_CONTROL_UNPREPARED
    | typeof flowControl.FLOW_CONTROL_PREPARED
    | typeof flowControl.FLOW_CONTROL_DONE;
  isFlowCompleted: boolean;
  showWarningModalWhenLogout?: boolean;
  onSkipPension?: string;
  onPensionComplete?: string;
  flow?: string;
}

const createDefaults = (): State => ({
  activeProgressIndexes: undefined,
  currentProgress: undefined,
  activeProgress: undefined,
  status: flowControl.FLOW_CONTROL_UNPREPARED,
  isFlowCompleted: false,
  showWarningModalWhenLogout: undefined,
  flow: undefined,
  onPensionComplete: undefined,
  onSkipPension: undefined,
  selectedIndex: undefined,
});

export default create(
  {
    [UPDATE_CURRENT_PROGRESS]: (
      state,
      {
        currentProgress,
        activeProgressIndexes,
        selectedIndex,
        showWarningModalWhenLogout,
        onSkipPension,
        onPensionComplete,
      }
    ) => ({
      ...state,
      currentProgress,
      activeProgressIndexes,
      selectedIndex,
      showWarningModalWhenLogout,
      onSkipPension,
      onPensionComplete,
    }),
    [UPDATE_FLOW_CONTROL]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [SET_FLOW]: (state, payload) => ({
      ...state,
      flow: payload.flow,
    }),
  },
  createDefaults
);

export type { State };
