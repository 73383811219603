import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import BasePage, { ActionShape } from '@sp/ui/pages/BasePage';
import Conditional from 'decorators/conditional';
import Result from './Result';

const Scroll = Conditional({
  mobile: styled.ScrollView``,
  default: styled.View``,
});

const CenterTabletContent = Conditional({
  tablet: styled.View`
    align-items: center;
  `,
  default: Fragment,
});

const Wrapper = styled.View`
  flex: 1;
  background: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
`;

const ResultPage = ({
  title,
  heading,
  iconName,
  iconColor,
  iconImage,
  description,
  actions,
  backButton,
  children,
  hasBackButton,
  customHeader,
  accessibilitySummary,
  testID,
}) => (
  <Wrapper>
    <BasePage
      title={title}
      backButton={backButton}
      actions={actions}
      hasBackButton={hasBackButton}
      customHeader={customHeader}
      accessibilitySummary={accessibilitySummary}
      testID={testID}
    >
      <Scroll alwaysBounceVertical={false}>
        <CenterTabletContent>
          <Result
            heading={heading}
            description={description}
            iconName={iconName}
            iconColor={iconColor}
            iconImage={iconImage}
          />
          {children}
        </CenterTabletContent>
      </Scroll>
    </BasePage>
  </Wrapper>
);

ResultPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.arrayOf(ActionShape),
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  iconImage: PropTypes.any,
  children: PropTypes.node,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  customHeader: PropTypes.node,
  testID: PropTypes.string,
};

ResultPage.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  actions: undefined,
  iconName: undefined,
  iconColor: undefined,
  iconImage: undefined,
  children: undefined,
  backButton: undefined,
  hasBackButton: true,
  customHeader: undefined,
  accessibilitySummary: undefined,
  testID: undefined,
};

export default ResultPage;
