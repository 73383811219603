import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import styled, { withTheme } from 'styled-components/native';
import { H6 } from '@sp/ui/typography';
import { isWeb } from '@sp/ui/helpers/device';

const ScrollView = styled.ScrollView`
  width: 100%;
`;

const styles = StyleSheet.create({
  wrapper: { flexGrow: 1, justifyContent: 'center' },
});

const ScrollContainer = isWeb
  ? Fragment
  : styled.View`
      flex: 1;
    `;

const Pill = styled.TouchableOpacity`
  border-radius: 20px;
  flex: 1;
  max-width: 180px;
  padding: ${(props) => props.theme.SPACINGS.sm}px ${(props) => props.theme.SPACINGS.md}px;
  ${({ isFirst, theme }) =>
    isFirst
      ? `
    margin-left: ${theme.SPACINGS.md}px;
  `
      : `
    margin-left: ${theme.SPACINGS.m}px;
  `};
  ${({ selected, disabled, theme }) =>
    selected
      ? `
      background-color: ${theme.COLORS.PRIMARY};
      border: 1px solid ${theme.COLORS.PRIMARY};
    `
      : `
      background-color: ${theme.COLORS.SECONDARY_BACKGROUND};
      border: 1px solid ${disabled ? theme.COLORS.PRIMARY_INACTIVE : theme.COLORS.PRIMARY};
    `}
  ${({ isLast, theme }) => (isLast ? `margin-right: ${theme.SPACINGS.md}px;` : '')}
`;

const getTextColor = (disabled, selected, theme) => {
  if (disabled) {
    return theme.COLORS.PRIMARY_INACTIVE;
  }

  return selected ? theme.COLORS.SECONDARY_BACKGROUND : theme.COLORS.PRIMARY;
};

const HorizontalPicker = ({ theme, options, onSelect }) => (
  <ScrollContainer>
    <ScrollView horizontal contentContainerStyle={styles.wrapper}>
      {options.map((option, i) => (
        <Pill
          isFirst={i === 0}
          isLast={i === options.length - 1}
          selected={option.selected}
          disabled={option.disabled || option.selected}
          onPress={() => onSelect(option)}
          key={option.text}
        >
          <H6
            fontWeight="regular"
            textAlign="center"
            color={getTextColor(option.disabled, option.selected, theme)}
          >
            {option.text}
          </H6>
        </Pill>
      ))}
    </ScrollView>
  </ScrollContainer>
);

HorizontalPicker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      disabled: PropTypes.bool,
      selected: PropTypes.bool,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(HorizontalPicker);
