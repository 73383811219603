import { selectSelectedLocation } from './index';

const selectSelectedDate = (state) => {
  const { selectedDate } = state.areas.booking;

  if (selectedDate) {
    return selectedDate;
  }

  let availableAppointments = state.areas.booking.bookingVariations;

  // NOTE: we default to the first available date
  if (availableAppointments) {
    // NOTE: should we combine selectors or extract filter by organizer to a function?
    const { selectedOrganizer } = state.areas.booking;

    if (selectedOrganizer) {
      availableAppointments = availableAppointments.filter(
        (appointment) => appointment.organizerId === selectedOrganizer.organizerId
      );
    }

    const selectedLocation = selectSelectedLocation(state);

    if (selectedLocation) {
      availableAppointments = availableAppointments.filter((a) => a.location === selectedLocation);
    }

    return availableAppointments.length === 0
      ? undefined
      : new Date(availableAppointments[0].startsAt);
  }

  return undefined;
};

export default selectSelectedDate;
