const TRANSFER_AREA = 'Transfer';

export const START_FLOW = {
  area: TRANSFER_AREA,
  action: 'StartFlow',
};

export const TECHNICAL_ERROR = {
  area: TRANSFER_AREA,
  action: 'TechnicalError',
};

export const TRANSFER_NOT_SUBMITTED = 'transferNotSubmitted';

export const TRANSFER_SUBMITTED = 'transferSubmitted';
