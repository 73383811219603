import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Subtitle } from '@sp/ui/typography';

const StyledSubtitle = styled(Subtitle)`
  margin-top: 11px;
  ${({ theme }) => `color: ${theme.COLORS.PRIMARY}`};
`;

const TabBarLabel = ({ text }) => (
  <StyledSubtitle textAlign="center" fontWeight="medium">
    {text}
  </StyledSubtitle>
);

TabBarLabel.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TabBarLabel;
