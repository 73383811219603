import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { updateFlowControl } from 'actions/flowControl';
import { FLOW_CONTROL_PREPARED } from 'constants/flowControl';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectPARTNERConsent } from 'selectors/consents';
import { selectDeeplink } from 'selectors/deeplink';
import { getPensionsInfo } from 'actions/pensionsInfo';
import { getCalculationProfile } from 'actions/calculations';
import type { RootState } from 'store';
import { getPartnerDataFromMeetingPreparation } from 'actions/partner';

const { getConsent } = require('actions/consents');

const PARTNER_CONSENT = 'PARTNER';

const selectFetchAllServices = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  consents: selectPARTNERConsent(state),
});

export const useFetchAllServices = () => {
  const dispatch = useAppDispatch();
  const { consents, deeplink } = useAppSelector(selectFetchAllServices, shallowEqual);
  const { isDeeplink } = deeplink ?? {};

  const fetchData = useCallback(async () => {
    if (isDeeplink && consents.status === 'ready') {
      await dispatch([
        getConsent(PARTNER_CONSENT),
        getPensionsInfo(),
        getCalculationProfile(),
        getPartnerDataFromMeetingPreparation(),
      ]);
    }

    await dispatch(updateFlowControl({ status: FLOW_CONTROL_PREPARED }));
  }, [consents.status, isDeeplink, dispatch]);

  return useCallback(async () => {
    await fetchData();
  }, [fetchData]);
};
