import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { formatMoney, formatDate } from 'helpers/string';
import currencySymbol from 'helpers/currencySymbol';
import withTranslation from 'decorators/i18n';
import { ListWithSeparator } from '@sp/ui/base/lists';
import { RowBorder } from '@sp/ui/base/rows';
import { H5, Subtitle } from '@sp/ui/typography';
import { ShadowBox } from '@sp/ui/base/Box';
import Margin from '@sp/ui/base/Margin';

const RowWrapper = styled.View`
  display: flex;
  flex-flow: row wrap;
`;

const RowFlexWrapper = styled.View`
  flex: 1;
`;

const ListOfCards = ({ payments, children }) =>
  payments.length > 1 ? (
    <ListWithSeparator separator={RowBorder}>{children}</ListWithSeparator>
  ) : (
    <>{children}</>
  );

const DepositCard = ({ item, i18n }) => (
  <ShadowBox
    customComponent={item.payments.map((payment, i) => (
      <ListOfCards key={i} payments={item.payments}>
        <Margin all="md">
          <H5>{item.depositer}</H5>
          <Margin marginBottom="m" />
          <RowWrapper>
            <RowFlexWrapper>
              <Subtitle>{i18n.t('savings|Indbetaling:')}</Subtitle>
            </RowFlexWrapper>
            <Subtitle>{`${formatMoney(payment.totalAmount, true)}${currencySymbol(
              payment.totalAmount.currency.alphaCode
            )}`}</Subtitle>
          </RowWrapper>
          <Margin marginBottom="xs" />

          {payment.ownContribution.amount > 0 && (
            <>
              <RowWrapper>
                <RowFlexWrapper>
                  <Subtitle>{i18n.t('savings|Heraf eget frivilligt bidrag:')}</Subtitle>
                </RowFlexWrapper>
                <Subtitle>{`${formatMoney(payment.ownContribution, true)}${currencySymbol(
                  payment.ownContribution.currency.alphaCode
                )}`}</Subtitle>
              </RowWrapper>
              <Margin marginBottom="xs" />
            </>
          )}

          <RowWrapper>
            <RowFlexWrapper>
              <Subtitle>{i18n.t('savings|Lønperiode:')}</Subtitle>
            </RowFlexWrapper>
            <Subtitle>{`${formatDate(item.periodStart)} - ${formatDate(item.periodEnd)}`}</Subtitle>
          </RowWrapper>
          <Margin marginBottom="xs" />
        </Margin>
      </ListOfCards>
    ))}
  />
);

DepositCard.propTypes = {
  item: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

ListOfCards.propTypes = {
  payments: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export default withTranslation()(DepositCard);
