export const getColorByType = (type, theme) => {
  switch (type) {
    case 'warning': {
      return {
        backgroundColor: theme.COLORS.NEGATIVE_STATUS,
        borderColor: theme.COLORS.PRIMARY_WARNING,
      };
    }
    case 'error': {
      return {
        backgroundColor: theme.COLORS.PRIMARY_ALERT_NOTIFICATION,
        borderColor: theme.COLORS.PRIMARY_ALERT,
      };
    }
    case 'info': {
      return {
        backgroundColor: theme.COLORS.SECONDARY_BACKGROUND,
        borderColor: theme.COLORS.PRIMARY_BORDER,
      };
    }
    default:
      return {};
  }
};
