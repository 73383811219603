import { Component } from 'react';
import PropTypes from 'prop-types';
import features from 'features';
import { connect } from 'react-redux';

import { selectBlockedFeature } from 'selectors/apiStatus';

class Feature extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      features: features.getAll(),
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    features.subscribe(this.onChange);
  }

  componentWillUnmount() {
    features.unsubscribe(this.onChange);
  }

  onChange(values) {
    this.setState({
      features: values,
    });
  }

  render() {
    const { name, children, enabled, disabled, blockedFeature } = this.props;

    let component = disabled;
    if (this.state.features[name] && !blockedFeature) {
      component = enabled || children;
    }
    if (typeof component === 'function') {
      return component(this.state.features[name]);
    }
    return component;
  }
}

Feature.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  enabled: PropTypes.bool,
  disabled: PropTypes.node,
  blockedFeature: PropTypes.object,
};

Feature.defaultProps = {
  disabled: null,
  enabled: false,
  blockedFeature: undefined,
};

const mapStateToProps = (state, { name }) => ({
  blockedFeature: selectBlockedFeature(state, name),
});

export default connect(mapStateToProps)(Feature);
