import { useEffect } from 'react';
import useAsyncCallback, { Options } from './useAsyncCallback';

type Response<T> = [
  T,
  {
    reload: () => Promise<void>;
    set: (result: T) => void;
    error?: any;
    loading: boolean;
  }
];

const useAsyncInit = <T>(
  fn: () => Promise<T>,
  dependencies: any[] = [],
  options: Options = {}
): Response<T> => {
  const [reload, { result, set, error, loading }] = useAsyncCallback(fn, dependencies, options);

  useEffect(() => {
    reload();
  }, [reload]);

  return [
    result as T,
    {
      reload,
      set,
      error,
      loading,
    },
  ];
};

export { Options };

export default useAsyncInit;
