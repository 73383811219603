import React, { Fragment } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { getHtmlStyleForWeb } from '@sp/ui/helpers/html';

const Content = styled.div``;

const GlobalStyle = createGlobalStyle`
  ${(props) => getHtmlStyleForWeb(props.theme)};
`;

const HTML = ({ content, className }) => (
  <Fragment>
    <GlobalStyle />
    <Content
      dangerouslySetInnerHTML={{ __html: content }}
      className={`HtmlRenderer ${className}`}
    />
  </Fragment>
);

HTML.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

HTML.defaultProps = {
  className: undefined,
};

export default HTML;
