import { UPDATE_DEEPLINK } from 'constants/actionTypes';
import { create } from 'helpers/reducers';

interface State {
  isDeeplinkFromClassic?: boolean;
  isExternalDeeplink?: boolean;
  isDeeplink?: boolean;
  isDeeplinkPriorToLogin?: boolean;
  isDeeplinkComplete?: boolean;
  isDeeplinkGenerated?: boolean;
  deeplinkFlow?: string;
  isDeeplinkModalOpen: boolean;
}

const createDefaults = (): State => ({
  isDeeplinkFromClassic: undefined,
  isExternalDeeplink: undefined,
  isDeeplink: undefined,
  isDeeplinkPriorToLogin: undefined,
  isDeeplinkComplete: undefined,
  isDeeplinkGenerated: undefined,
  deeplinkFlow: undefined,
  isDeeplinkModalOpen: false,
});

export default create(
  {
    [UPDATE_DEEPLINK]: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  createDefaults
);

export type { State };
