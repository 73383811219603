import React, { useMemo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/native';
import Icon from '@sp/ui/v2/base/Icon';
import styledWeb from 'styled-components';

const CLOSE_BUTTON_SIZE = 36;
const WRAPPER_TOP_OFFSET = 50;
const WRAPPER_BOTTOM_OFFSET = 10;
const WRAPPER_VERTICAL_OFFSET = 10;

const IFrame = styledWeb.iframe`
  flex: 1;
  border: none;
`;

const BG = styledWeb.div`
  position: fixed;
  background: rgba(0,0,0,0.5);
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Wrapper = styled.View`
  position: absolute;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  top: ${WRAPPER_TOP_OFFSET}px;
  bottom: ${WRAPPER_BOTTOM_OFFSET}px;
  left: ${WRAPPER_VERTICAL_OFFSET}px;
  right: ${WRAPPER_VERTICAL_OFFSET}px;
`;

const Header = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: ${(props) => props.theme.SPACINGS.lg}px;
`;

const Close = styled.TouchableOpacity`
  background: #ebedf0;
  width: ${CLOSE_BUTTON_SIZE}px;
  height: ${CLOSE_BUTTON_SIZE}px;
  border-radius: ${CLOSE_BUTTON_SIZE / 2}px;
  align-items: center;
  justify-content: center;
`;

type Props = {
  url: string;
  onClose: () => void;
};

const PopupWebModal = ({ url, onClose }: Props) => {
  const container = useMemo(() => document.createElement('div'), []);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src = url;
    }
  }, [url, iframeRef]);

  return ReactDOM.createPortal(
    <BG>
      <Wrapper>
        <Header>
          <Close onPress={onClose}>
            <Icon name="close" height={14} />
          </Close>
        </Header>
        <IFrame ref={iframeRef} />
      </Wrapper>
    </BG>,
    container
  );
};

export default PopupWebModal;
