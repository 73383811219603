import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';

const CIRCLE_SIZE = 16;
const INNER_CIRCLE_SIZE = 10;
const BORDER_WIDTH = 1;
const PARTIAL_STATE_OPACITY = 0.4;

const Wrapper = styled.View`
  align-items: center;
  justify-content: center;
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  border-width: ${BORDER_WIDTH}px;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.COLORS.PRIMARY : theme.COLORS.GRAPH_INCOME};
  border-radius: ${CIRCLE_SIZE / 2}px;
  background: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
`;

const Inner = styled.View`
  width: ${INNER_CIRCLE_SIZE}px;
  height: ${INNER_CIRCLE_SIZE}px;
  border-radius: ${INNER_CIRCLE_SIZE / 2}px;
  background: ${({ isSelected, isPartiallySelected, theme }) =>
    isSelected || isPartiallySelected ? theme.COLORS.PRIMARY : theme.COLORS.SECONDARY_BACKGROUND};
  opacity: ${({ isPartiallySelected }) => (isPartiallySelected ? PARTIAL_STATE_OPACITY : 1)};
`;

const getTestID = (isSelected, isPartiallySelected, testID) => {
  if (isSelected) {
    return `${testID}-Checked`;
  }

  if (isPartiallySelected) {
    return `${testID}-PartiallyChecked`;
  }

  return testID;
};

const RadioButton = ({ onPress, isSelected, isPartiallySelected, testID }) => (
  <Wrapper
    onPress={onPress}
    isSelected={isSelected}
    isPartiallySelected={isPartiallySelected}
    testID={getTestID(isSelected, isPartiallySelected, testID)}
  >
    <Inner isSelected={isSelected} isPartiallySelected={isPartiallySelected} />
  </Wrapper>
);

RadioButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPartiallySelected: PropTypes.bool,
  testID: PropTypes.string,
};

RadioButton.defaultProps = {
  isPartiallySelected: false,
  testID: undefined,
};

export default withTheme(RadioButton);
