import React from 'react';
import PropTypes from 'prop-types';
import Margin from '@sp/ui/base/Margin';
import withComponentContainer from 'decorators/withComponentContainer';
import { openLink } from 'helpers/linking';
import AdminCosts from './AdminCosts';
import AdminCostGroupLifeAndHealthInsurances from './AdminCostGroupLifeAndHealthInsurances';
import InvestmentCosts from './InvestmentCosts';
import {
  ModalLinkText,
  ModalTitle,
  ModalRegularText,
  ModalBulletList,
} from '../modal/modalTextComponents';

const AboutCostsSP = ({ openLatestDepotAccount, isLink, i18n, type }) => (
  <>
    <AdminCosts openLatestDepotAccount={openLatestDepotAccount} i18n={i18n} type={type} />
    <AdminCostGroupLifeAndHealthInsurances
      companyName={i18n.t('savings|{{COMPANY_NAME}}')}
      i18n={i18n}
    />
    <InvestmentCosts
      companyName={i18n.t('savings|{{COMPANY_NAME}}')}
      linkText={i18n.t('savings|www.sampension.dk')}
      onPress={() => {
        const url = i18n.t('link|Sampension');

        openLink(url, '_blank');
      }}
      i18n={i18n}
    />
    {isLink && (
      <>
        <Margin marginTop="md" />
        <ModalTitle title={i18n.t('savings|Omkostninger i Linkpension')} />
        <ModalRegularText
          text={i18n.t(
            'savings|I Linkpension er der både direkte og indirekte investeringsomkostninger.'
          )}
        />
        <ModalRegularText
          text={i18n.t(
            'savings|De direkte investeringsomkostninger kan inddeles i to typer omkostninger: en årlig omkostning og omkostninger, når du handler:'
          )}
        />
        <ModalBulletList
          title={i18n.t('savings|Årlige omkostninger')}
          text={i18n.t(
            'savings|Vi beregner en årlig omkostning for hver pension, du har oprettet i Linkpension. Omkostningerne beregnes som en procentdel af dit depot i Linkpension, og de bruges bl.a. til at dække {{COMPANY_NAME_GENITIVE}} udgifter til at vedligeholde IT-systemer og til administrationen af linkpolicerne.'
          )}
        />
        <ModalBulletList
          title={i18n.t('savings|Omkostninger ved køb og salg')}
          text={i18n.t(
            'savings|Du betaler handelsomkostninger og et kursspread hver gang, du køber og sælger andele i en fond. Kursspread er forskellen mellem købs- og salgskursen, og det beregnes i forhold til en fonds indre værdi. Indre værdi er et udtryk for den samlede værdi af de obligationer og aktier, der indgår i fonden. Omkostningerne bruges til at dække {{COMPANY_NAME_GENITIVE}} omkostninger ved køb og salg af andele i fonde.'
          )}
        >
          <Margin marginTop="md" />
          <ModalLinkText
            textBefore={i18n.t('savings|Læs mere om linkomkostninger på')}
            link={{ linkText: i18n.t('savings|www.sampension.dk') }}
            onPress={() => {
              const url = i18n.t('link|sampensionLinkCosts');

              openLink(url, '_blank');
            }}
          />
          <ModalLinkText
            textBefore={i18n.t('savings|Læs mere om dine specifikke linkomkostninger på dit')}
            link={{ linkText: i18n.t('savings|depotregnskab') }}
            onPress={openLatestDepotAccount}
          />
        </ModalBulletList>
        <ModalBulletList title={i18n.t('savings|Indirekte investeringsomkostninger i fondene')}>
          <ModalLinkText
            textBefore={i18n.t(
              'Når du investerer i Linkpension foregår det ved, at der købes andele i forskellige investeringsforeninger ex. Carnegie World Wide Aktier. I de forskellige investeringsforeninger handles der med forskellige værdipapirer, og der afholdes derfor en række investeringsomkostninger til handel og administration. {{COMPANY_NAME}} har beregnet din andel af disse investeringsomkostninger ud fra hver enkelt fonds officielle årlige omkostninger i procent (ÅOP). Du kan finde fondenes årlige omkostninger i procent i'
            )}
            onPress={() => {
              const url = i18n.t('link|sampensionLinkCostsOverview');
              openLink(url, '_blank');
            }}
            link={{
              linkText: i18n.t('savings|fondsoversigten'),
              linkPunctuation: '. ',
            }}
            textAfter={i18n.t(
              'savings|De indirekte investeringsomkostninger er modregnet i det afkast, de forskellige investeringsforeninger giver, og de bliver således ikke trukket direkte på dit depot, som det er tilfældet med handelsgebyr og kursspread.'
            )}
          />
        </ModalBulletList>
      </>
    )}
    <ModalTitle subtitle={i18n.t('savings|Tidligere års omkostninger')} />
    <Margin marginTop="md" />
    <ModalLinkText
      textBefore="På omkostningssiden kan du se dine samlede omkostninger inklusive investeringsomkostningerne for de seneste fem år. Du kan finde administrationsomkostninger og eventuelle omkostninger i Linkpension fra tidligere år på dit"
      link={{ linkText: i18n.t('savings|depotregnskab') }}
      onPress={openLatestDepotAccount}
    />
  </>
);

AboutCostsSP.propTypes = {
  openLatestDepotAccount: PropTypes.func,
  isLink: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

AboutCostsSP.defaultProps = {
  openLatestDepotAccount: undefined,
};

export default withComponentContainer()(AboutCostsSP);
