import React from 'react';
import PropTypes from 'prop-types';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import { H5, Subtitle, Body } from '@sp/ui/typography';
import useI18n from 'hooks/useI18n';
import { ListWithSeparator } from '@sp/ui/base/lists';

const DanishAddress = ({ isDesktop, danishAddress }) => {
  const { i18n } = useI18n();

  const { streetAddress, postalCode, postalDistrict } = danishAddress;
  const RightText = !isDesktop && streetAddress && streetAddress.length > 25 ? Subtitle : Body;

  return (
    <ListWithSeparator separator={RowBorder} disableTop>
      {streetAddress && (
        <OneLineRow
          textRight={<RightText>{streetAddress}</RightText>}
          textLeft={<H5>{i18n.t('Vej')}</H5>}
        />
      )}
      {postalCode && (
        <OneLineRow
          textRight={<RightText>{postalCode}</RightText>}
          textLeft={<H5>{i18n.t('Postnummer')}</H5>}
        />
      )}
      {postalDistrict && (
        <OneLineRow
          textRight={<RightText>{postalDistrict}</RightText>}
          textLeft={<H5>{i18n.t('By')}</H5>}
        />
      )}
    </ListWithSeparator>
  );
};

DanishAddress.propTypes = {
  danishAddress: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default DanishAddress;
