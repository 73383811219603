export const getDescriptionText = (
  retirementAge,
  payoutType,
  i18n,
  showGrossAmount,
  awardRules,
  noOfPayments = 1
) => {
  switch (payoutType) {
    case 'death': {
      return showGrossAmount
        ? i18n.t('dashboard|onetimePayoutsDeathDescriptionWithGross', { count: noOfPayments })
        : i18n.t('dashboard|onetimePayoutsDeathDescription', { count: noOfPayments });
    }
    case 'disability': {
      let disabilityGRADisclaimerText = awardRules?.descriptions?.disabilityGRADisclaimerText
        ? `${awardRules?.descriptions?.disabilityGRADisclaimerText}\r\n\r\n`
        : '';

      return showGrossAmount
        ? disabilityGRADisclaimerText +
            i18n.t('dashboard|onetimePayoutsDisabilityDescriptionWithGross', {
              count: noOfPayments,
            })
        : disabilityGRADisclaimerText +
            i18n.t('dashboard|onetimePayoutsDisabilityDescription', { count: noOfPayments });
    }
    default: {
      return showGrossAmount
        ? i18n.t('dashboard|onetimePayoutsDescriptionWithGross', {
            retirementAge,
            count: noOfPayments,
          })
        : i18n.t('dashboard|onetimePayoutsDescription', {
            retirementAge,
            count: noOfPayments,
          });
    }
  }
};
