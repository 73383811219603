import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import * as routeNames from 'constants/routeNames';

import Type from './Type';
import Create from './Create';
import AddContactInfo from './AddContactInfo';
import Completed from './Completed';

const Stack = createStackNavigator();

export default () => (
  <Stack.Navigator
    initialRouteName={routeNames.FEEDBACK_TYPE}
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen name={routeNames.FEEDBACK_TYPE} component={Type} />
    <Stack.Screen name={routeNames.FEEDBACK_CREATE} component={Create} />
    <Stack.Screen name={routeNames.FEEDBACK_COMPLETED} component={Completed} />
    <Stack.Screen name={routeNames.FEEDBACK_ADD_CONTACT_INFO} component={AddContactInfo} />
  </Stack.Navigator>
);
