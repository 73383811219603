import ReactDOM from 'react-dom';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Loader from '@sp/ui/base/Loader';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.85;
  z-index: 1;
`;

const root = global.document.createElement('div');
global.document.body.appendChild(root);

const BlockingLoader = (props) =>
  ReactDOM.createPortal(
    <Fragment>
      <Wrapper />
      <Loader {...props} fullscreen={false} blur={false} />
    </Fragment>,
    root
  );

export default BlockingLoader;
