import Conditional from 'decorators/conditional';
import Mobile from './index.mobile';
import Tablet from './index.tablet';
import Desktop from './index.desktop';

export { ActionShape } from './index.shared';

export default Conditional({
  mobile: Mobile,
  tablet: Tablet,
  desktop: Desktop,
});
