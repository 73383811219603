import React from 'react';
import PropTypes from 'prop-types';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { isWeb, isIOS } from 'helpers/platform';

const Stack = createStackNavigator();

const TransitionPreset = isIOS
  ? TransitionPresets.ModalPresentationIOS
  : TransitionPresets.FadeFromBottomAndroid;

const ModalStack = ({ routeConfig }) => (
  <Stack.Navigator
    screenOptions={() => ({
      presentation: 'modal',
      animationEnabled: !isWeb,
      headerShown: false,
      gestureEnabled: isIOS,
      cardOverlayEnabled: true,
      ...TransitionPreset,
    })}
  >
    {Object.keys(routeConfig).map((route) => (
      <Stack.Screen name={route} key={route} component={routeConfig[route]?.screen}>
        {routeConfig[route]?.renderFn}
      </Stack.Screen>
    ))}
  </Stack.Navigator>
);

ModalStack.propTypes = {
  routeConfig: PropTypes.objectOf(
    PropTypes.shape({
      screen: PropTypes.any,
      renderFn: PropTypes.func,
    })
  ).isRequired,
};

export default ModalStack;
