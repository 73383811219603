import React from 'react';
import PropTypes from 'prop-types';
import { H2, Body } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';

const ModalTitle = ({ title, textAlign, subtitle, withMarginBottom }) => (
  <>
    {title && <H2 textAlign={textAlign || 'left'}>{title}</H2>}
    {subtitle && (
      <Margin marginTop="md">
        <Body fontWeight="bold">{subtitle}</Body>
      </Margin>
    )}
    {withMarginBottom && <Margin marginBottom="md" />}
  </>
);

ModalTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  textAlign: PropTypes.string,
  withMarginBottom: PropTypes.bool,
};

ModalTitle.defaultProps = {
  title: undefined,
  textAlign: undefined,
  subtitle: undefined,
  withMarginBottom: false,
};

export default ModalTitle;
