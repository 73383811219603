import api from '@sampension/api-middleware';
import config from 'config';
import { Platform } from 'react-native';
import VersionNumber from 'react-native-version-number';
import { logout } from 'actions/security';
import security from 'services/security/middleware';
import array from 'services/array/middleware';
import onboarding from 'services/onboarding/middleware';
import documents from 'services/documents/middleware';
import sideEffectsMiddleware from 'services/datasideeffects/middleware';
import changeInvestmentProfileMiddleware from 'services/changeInvestmentProfile/middleware';
import healthBenefitsMiddleware from 'services/healthBenefits/middleware';
import trackingMiddleware from 'services/tracking/middleware';
import mailbox from 'services/mailbox/middleware';
import fetchTaxAndPensionsInfo from 'services/fetchTaxAndPensionsInfo/middleware';
import { getDeviceName } from 'helpers/device';
import { isWeb } from 'helpers/platform';
import ServiceCache from './api/ServiceCache';
import auth from './api/client';
import featureEnvConfig from 'featureEnvConfig';

const serviceCache = new ServiceCache();

const getWebEnvironment = () => {
  if (!isWeb) {
    return undefined;
  }
  switch (global.location.hostname) {
    case 'localhost':
    case 'login-dev.sampension.dk':
    case 'login-test.sampension.dk':
      return 'test';
    case 'login-staging.sampension.dk':
      return 'staging';
    case 'login.sampension.dk':
      return 'production';
    default:
      if (config.env === 'preview') {
        return featureEnvConfig.name || 'test';
      }
      throw new Error('Running on unknown hostname');
  }
};

const getDiscoveryEnvironment = () =>
  process.env.IS_DEV_BUILD
    ? 'https://app-discovery-staging.sampension.app/discover'
    : 'https://app-discovery.sampension.app/discover';

export default [
  array,
  documents,
  security,
  api({
    auth,
    serviceCache,
    discovery: getDiscoveryEnvironment(),
    environment: getWebEnvironment(),
    host: global?.location?.hostname,
    appVersion: VersionNumber.appVersion || process.env.VERSION,
    deviceName: getDeviceName(),
    platform: Platform.OS,
    onUnauthorized: (_err, dispatch) => dispatch(logout('hard')),
  }),
  onboarding,
  sideEffectsMiddleware,
  trackingMiddleware,
  changeInvestmentProfileMiddleware,
  healthBenefitsMiddleware,
  mailbox,
  fetchTaxAndPensionsInfo,
];
