// React popup view
import React from 'react';
import usePopupWebView from 'hooks/usePopupWebView';
import Modal from './Modal.web';

const PopupWebView = () => {
  const { url, close } = usePopupWebView();

  if (!url) {
    return null;
  }

  return <Modal url={url} onClose={close} />;
};

export default PopupWebView;
