import React from 'react';
import PropTypes from 'prop-types';
import InternalPolicy from './InternalPolicy';
import ExternalPolicy from './ExternalPolicy';
import BlockedPolicy from './BlockedPolicy';
import TransferredPolicy from './TransferredPolicy';

const PolicyListItem = ({ item, showDetails, savingsData, isSampensionEmployee }) => (
  <>
    {item.isInternal && !item.isBeingTransferred && (
      <InternalPolicy item={item} showDetails={showDetails} savingsData={savingsData} />
    )}
    {item.isExternal && !item.isBeingTransferred && (
      <ExternalPolicy item={item} savingsData={savingsData} />
    )}
    {item.isBeingTransferred && (
      <TransferredPolicy
        item={item}
        showDetails={showDetails}
        isSampensionEmployee={isSampensionEmployee}
        savingsData={savingsData}
      />
    )}
    {item.isBlocked && <BlockedPolicy item={item} />}
  </>
);

PolicyListItem.propTypes = {
  isSampensionEmployee: PropTypes.bool,
  showDetails: PropTypes.func,
  savingsData: PropTypes.object,
  item: PropTypes.object.isRequired,
};

PolicyListItem.defaultProps = {
  showDetails: undefined,
  savingsData: undefined,
  isSampensionEmployee: undefined,
};

export default PolicyListItem;
