import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import Modal from 'containers/layout/Modal';
import withScreenContainer from 'decorators/withScreenContainer';
import Conditional from 'decorators/conditional';
import { retractConsent, giveConsent, getConsents, getConsent } from 'actions/consents';
import Markdown from '@sp/ui/base/Markdown';
import Tracking from 'services/tracking';
import { H2, Body } from '@sp/ui/typography';
import { openLink } from 'helpers/linking';
import { TERMS } from 'areas/more/trackingIds';
import { isWeb } from 'helpers/platform';

const WrapperMobile = styled.View`
  margin: ${(props) => props.theme.SPACINGS.lg}px ${(props) => props.theme.SPACINGS.md}px 0;
`;

const Wrapper = Conditional({
  desktop: styled.View`
    margin: 0 ${(props) => props.theme.SPACINGS.xxl}px;
  `,
  default: WrapperMobile,
});

const ModalContent = styled.View`
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.View`
  padding-top: ${(props) => props.theme.SPACINGS.lg}px;
`;

const Terms = ({
  title,
  body,
  given,
  action,
  actionDescription,
  buttonText,
  allowEdit,
  route,
  i18n,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const key = route.params?.consentKey;

  return (
    <ScrollPage
      actions={
        buttonText && [
          {
            text: buttonText,
            mobilePosition: 'bottom',
            onPress: () => {
              if (given) {
                Tracking.trackEvent(TERMS.INITIATE_REVOKE, { type: key });
                setIsDialogOpen(true);
              } else {
                action();
              }
            },
          },
        ]
      }
      title={title}
    >
      <Wrapper>{body && <Markdown>{body}</Markdown>}</Wrapper>
      <Modal
        visible={isDialogOpen}
        onRequestClose={() => setIsDialogOpen(false)}
        actions={[
          {
            text: allowEdit ? buttonText : i18n.t('Skriv besked'),
            mobilePosition: 'bottom-sticky',
            onPress: allowEdit
              ? action
              : () => {
                  Tracking.trackEvent(TERMS.MAIL_REVOKE, { type: key });
                  const mailSubject = i18n.t('Træk samtykke tilbage');
                  const supportMail = i18n.t('supportMail');
                  const mailLink = `mailto:${supportMail}?subject=${mailSubject}`;

                  if (!isWeb) {
                    encodeURIComponent(mailLink);
                  }
                  openLink(mailLink, '_blank');
                },
          },
          {
            type: 'secondary',
            text: i18n.t('Annuller'),
            mobilePosition: 'bottom-sticky',
            onPress: () => {
              Tracking.trackEvent(TERMS.CANCEL_REVOKE, { type: key });
              setIsDialogOpen(false);
            },
          },
        ]}
      >
        <ModalContent>
          <H2 textAlign="center">{i18n.t('Vi gør opmærksom på')}</H2>
          <Content>
            <Body textAlign="left">{actionDescription}</Body>
          </Content>
        </ModalContent>
      </Modal>
    </ScrollPage>
  );
};

Terms.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node,
  given: PropTypes.bool,
  action: PropTypes.func.isRequired,
  actionDescription: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  allowEdit: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

Terms.defaultProps = {
  body: undefined,
  given: false,
  buttonText: undefined,
};

const mapStateToProps = (state, props) => ({
  consents: state.services.consent.items,
  allowEdit: props.route.params?.allowEdit === 'true',
});

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { dispatch, navigation, route, allowEdit, i18n } = getProps();
        let buttonAction = () => {};
        const back = () => navigation.goBack();
        const key = route.params?.consentKey;
        const consent = await dispatch(getConsent(key));
        const given = consent.consent === 'granted';
        const buttonText = given ? i18n.t('Tilbagetræk samtykke') : i18n.t('Giv samtykke');

        if (allowEdit) {
          if (given) {
            buttonAction = async (consentKey) => {
              await dispatch(retractConsent(consentKey));
              if (key !== 'GDPR') {
                await dispatch(getConsents());
              }
            };
          } else {
            buttonAction = async (consentKey) => {
              await dispatch(giveConsent(consentKey));
              await dispatch(getConsents());
            };
          }
        }

        return {
          body: consent.text,
          title: consent.alternativeTitle,
          given,
          buttonText,
          action: () =>
            action(async () => {
              if (!(key === 'GDPR' && given)) {
                Tracking.trackEvent(
                  {
                    area: route.name,
                    action: given ? `Click${key}Revoke` : `Click${key}Grant`,
                    description: `Consent for ${key} revoked or granted`,
                  },
                  {
                    key,
                  }
                );
                await buttonAction(key);
                await back();
              }
            }),
          actionDescription:
            key === 'GDPR' && given
              ? i18n.t(
                  'Skriv til feedback@sampension.dk, hvis du ønsker at trække dit samtykke til behandling af personoplysninger tilbage.'
                )
              : i18n.t(
                  'Hvis du trækker dit samtykke tilbage, vil det ikke have betydning for din anvendelse af dit digitale univers. Det betyder dog, at vi ikke længere vil anvende dine oplysninger til markedsføring.'
                ),
        };
      },
      {
        loader: true,
      }
    ),
})(withTheme(Terms));
