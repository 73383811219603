import log from 'services/log';

export const handleCriticalFail = (e) => {
  log.error('Error during app init', e);

  //NOTE: This is a handler for critical errors that prevent app startup
  //we are not using i18n here because there is no warranty of it initializing
  return {
    blockMessage: {
      title: 'Noget gik galt',
      reason:
        "Vi er stødt på en kritisk fejl, som gør app'en utilgængelig i øjeblikket. Venligst benyt vores hjemmeside indtil vi får løst problemet. Vi beklager ulejligheden",
    },
  };
};
