import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Conditional from 'decorators/conditional';
import { isIphone5 } from '@sp/ui/helpers/device';
import Margin from '@sp/ui/base/Margin';
import { Appear } from '@sp/ui/v2/animations';
import { H1 } from '@sp/ui/typography';

import mobileImage from 'assets/images/changelog/booking/mobile_portrait.jpg';
import tabletImage from 'assets/images/changelog/booking/tablet_portrait.jpg';
import landscapeImage from 'assets/images/changelog/booking/tablet_landscape.jpg';
import { Wrapper, Description } from './index.shared.js';

import DeviceGradient from 'containers/DeviceGradient';

const TextWrapper = Conditional({
  landscape: styled.View`
    flex: 4;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: ${({ theme }) => theme.SPACINGS.xl}px;
  `,
  default: styled.View`
    flex: 3;
    align-items: center;
    justify-content: flex-end;
  `,
});

const ContentWrapper = Conditional({
  landscape: styled.View`
    flex: 6;
    align-items: center;
  `,
  default: styled.View`
    flex: 8;
    align-items: center;
  `,
});

const Slide1 = ({ title, description, theme, startAnimation }) => (
  <Wrapper>
    <TextWrapper>
      <Margin all={isIphone5() ? 'md' : 'xl'}>
        <H1 textAlign="center" color={theme.COLORS.SECONDARY_BACKGROUND}>
          {title}
        </H1>
        <Margin marginTop="md">
          <Description
            textAlign="center"
            fontWeight="regular"
            color={theme.COLORS.SECONDARY_BACKGROUND}
          >
            {description}
          </Description>
        </Margin>
      </Margin>
    </TextWrapper>
    <ContentWrapper>
      {startAnimation && (
        <Appear animationType="OutExp" duration={3000} delay={100}>
          <DeviceGradient
            mobileImage={mobileImage}
            tabletImage={tabletImage}
            landscapeImage={landscapeImage}
          />
        </Appear>
      )}
    </ContentWrapper>
  </Wrapper>
);

Slide1.propTypes = {
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startAnimation: PropTypes.bool,
};

Slide1.defaultProps = {
  startAnimation: false,
};

export default withTheme(Slide1);
