/* eslint-disable react/prop-types */
import React from 'react';
import FormSection from 'areas/dashboard/calculations/components/FormSection';
import { formatMoney, getGrossAmountOrAmount } from 'helpers/string';
import Content from '../Content';

export default ({
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  i18n,
  theme,
  testIDPrefix,
  showGrossAmount,
  withAgeSelector = false,
  menuItems,
}) => {
  const hasExtraIncome = childAndYouthBenefitToday?.amount > 0;

  const income = hasExtraIncome
    ? getGrossAmountOrAmount(totalIncomeToday, showGrossAmount)
    : getGrossAmountOrAmount(incomeToday, showGrossAmount);

  const description = hasExtraIncome
    ? i18n.t('dashboard|incomeTotalBar')
    : i18n.t('dashboard|incomeBar');

  return {
    id: '3',
    testID: `${testIDPrefix}DisposableIncomeBar`,
    bar:
      income.amount > 0
        ? {
            amount: formatMoney(income, true),
            value: income.amount,
            description,
            color: theme.COLORS.GRAPH_INCOME,
            trackId: 'DisposableIncome',
          }
        : {
            centerLabel: '?',
            amount: '',
            value: 1200,
            description,
            color: theme.COLORS.GRAPH_INCOME,
          },
    content: {
      component: (
        <Content
          testID={`${testIDPrefix}DisposableIncomeContent`}
          withAgeSelector={withAgeSelector}
          description={<FormSection />}
          menuItems={menuItems}
        />
      ),
    },
  };
};
