import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import Conditional from 'decorators/conditional';
import Icon from '@sp/ui/base/Icon';
import { TABLET_MARGIN_HORIZONTAL, MOBILE_MARGIN_HORIZONTAL } from '../index.shared';

const ICON_TEXT_SPACING = 5;

const Spacer = styled.View`
  width: ${ICON_TEXT_SPACING}px;
`;

const Wrapper = Conditional({
  mobile: styled.View`
    padding: ${MOBILE_MARGIN_HORIZONTAL / 2}px;
    flex-direction: row;
    align-items: center;
  `,
  default: styled.View`
    padding: ${TABLET_MARGIN_HORIZONTAL / 2}px;
    flex-direction: row;
    align-items: center;
  `,
});

const Section = ({ text, icon, theme, textFirst }) => {
  const renderIcon = () => icon && <Icon name={icon} fill={theme.COLORS.SECONDARY_BACKGROUND} />;

  const renderText = () => text && <Body color={theme.COLORS.SECONDARY_BACKGROUND}>{text}</Body>;

  return (
    <Wrapper>
      {textFirst ? renderText() : renderIcon()}
      {icon && text && <Spacer />}
      {textFirst ? renderIcon() : renderText()}
    </Wrapper>
  );
};

Section.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  textFirst: PropTypes.bool,
  theme: PropTypes.shape({
    COLORS: PropTypes.shape({
      SECONDARY_BACKGROUND: PropTypes.string,
    }),
  }).isRequired,
};

Section.defaultProps = {
  text: undefined,
  icon: undefined,
  textFirst: false,
};

export default withTheme(Section);
