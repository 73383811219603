import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { selectPrefetchKeys } from 'selectors/discovery';
import { registerDevice } from 'actions/pushnotification';
import withComponentContainer from 'decorators/withComponentContainer';
import Tracking from 'services/tracking';
import Graphs from 'areas/dashboard/Graphs';
import { useRoute } from '@react-navigation/native';
import { setup as setupPushNotifications } from 'services/pushnotifications';
import features from 'features';
import { isWeb } from 'helpers/platform';

import Conditional from 'decorators/conditional';
import BlockingOverlay from './BlockingOverlay';
import { selectMaxIncomeWhenRetired } from 'selectors/dashboard';

const ScrollWrapper = styled.View`
  position: relative;
`;

const Content = Conditional({
  mobile: styled.View`
    padding-bottom: ${({ theme }) => theme.SPACINGS.md}px;
  `,
  default: styled.View``,
});

const InnerWrapper = isWeb ? Fragment : ScrollWrapper;

const Dashboard = ({ graphData, maxIncomeWhenRetired }) => {
  const [selectedBar, setSelectedBar] = useState(0);
  const route = useRoute();

  useEffect(() => {
    const lastBarIsSelected = selectedBar === graphData.length - 1;
    if (!lastBarIsSelected && graphData) {
      setSelectedBar(graphData.length - 1);
    }
    // last bar should only be set when route is changed. Not when graphData is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <InnerWrapper>
      <Content>
        <Graphs
          selected={selectedBar}
          onSelect={(index, trackId) => {
            setSelectedBar(index);
            Tracking.trackEvent({
              area: route.name,
              action: `ClickGraph${trackId}`,
            });
          }}
          withContent
          graphs={graphData}
          maxValue={maxIncomeWhenRetired}
        />
      </Content>
    </InnerWrapper>
  );
};

Dashboard.propTypes = {
  graphData: PropTypes.array.isRequired,
  maxIncomeWhenRetired: PropTypes.number,
};

Dashboard.defaultProps = {
  maxIncomeWhenRetired: undefined,
};

const mapStateToProps = (state) => ({
  isPushEnabled: selectPrefetchKeys(state).includes('pushNotifications'),
  maxIncomeWhenRetired: selectMaxIncomeWhenRetired(state),
});

const Wrapped = withComponentContainer({
  mapStateToProps,
  overlayScreenProps: {
    customOverlay: <BlockingOverlay />,
  },
  init: ({ action, getProps }) =>
    action(async () => {
      const { dispatch, isPushEnabled } = getProps();

      // TODO: remove the feature flag once push notification API is running on staging/prod
      if (features.isEnabled('pushNotifications') || isPushEnabled) {
        setupPushNotifications((token) => dispatch(registerDevice(token)));
      }
    }),
})(Dashboard);

export default Wrapped;
