import React from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import { withTheme } from 'styled-components/native';
import GraphPage from '@sp/ui/pages/GraphPage';
import Margin from '@sp/ui/base/Margin';
import { fetchDisability, fetchRetirement, getAwardRules } from 'actions/digital-advice';
import { DISABILITY_SCREEN } from 'constants/testIds/dashboard';
import * as routeNames from 'constants/routeNames';
import {
  selectIncomeToday,
  selectTotalIncomeToday,
  selectChildAndYouthBenefitToday,
  selectHasDashboardData,
  selectPayoutDisability,
  selectIsNaked,
  selectDisposableIncomeOptions,
  selectNakedDashboardErrors,
  selectError,
  selectAwardRules,
  selectAwardRulesSucceeded,
  selectShowGrossAmount,
  selectNoRecommendationType,
} from 'selectors/dashboard';
import TopTabBar from 'containers/dashboard/TopTabBar';
import Dashboard from 'areas/dashboard/Dashboard';
import { getChildren } from 'actions/children';
import { refetchNakedDashboardErrors, getIncome } from 'actions/dashboard';
import { selectHasChildrenPayoutPolicies } from 'selectors/childrenPayouts';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectChildrenData } from 'selectors/children';
import Naked from 'areas/dashboard/dashboards/Naked';
import getGraphData from 'areas/dashboard/dashboards/Disability/getGraphData';

const FOCUSED_TAB = routeNames.DISABILITY;

const Disability = ({
  isNaked,
  navigation,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  payoutDisability,
  theme,
  nakedDashboardErrors,
  isDesktop,
  awardRules,
  awardRulesSucceeded,
  hasChildrenPayouts,
  showGrossAmount,
  noRecommendationType,
}) => {
  if (isNaked) {
    return (
      <Naked
        focusedTab={FOCUSED_TAB}
        nakedDashboardErrors={nakedDashboardErrors}
        navigation={navigation}
      />
    );
  }

  const graphData = getGraphData({
    navigation,
    payoutDisability,
    incomeToday,
    totalIncomeToday,
    childAndYouthBenefitToday,
    theme,
    awardRules,
    awardRulesSucceeded,
    hasChildrenPayouts,
    noRecommendationType,
    showGrossAmount,
  });

  return (
    <GraphPage
      hasBackButton={false}
      hasHeader={false}
      tabBar={<TopTabBar focusedTab={FOCUSED_TAB} />}
      testID={DISABILITY_SCREEN}
      wideContent={<Dashboard graphData={graphData} />}
    >
      {isDesktop && <Margin marginTop="lg" />}
    </GraphPage>
  );
};

Disability.propTypes = {
  isNaked: PropTypes.bool,
  payoutDisability: PropTypes.object,
  incomeToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  totalIncomeToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  childAndYouthBenefitToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }),
  navigation: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  nakedDashboardErrors: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  awardRulesSucceeded: PropTypes.bool,
  awardRules: PropTypes.object,
  hasChildrenPayouts: PropTypes.bool,
  showGrossAmount: PropTypes.bool.isRequired,
  noRecommendationType: PropTypes.string,
};

Disability.defaultProps = {
  isNaked: false,
  payoutDisability: undefined,
  incomeToday: {
    amount: 0,
    grossAmount: 0,
    currency: 'DKK',
  },
  totalIncomeToday: undefined,
  childAndYouthBenefitToday: undefined,
  awardRulesSucceeded: false,
  awardRules: undefined,
  hasChildrenPayouts: false,
  noRecommendationType: undefined,
};

const mapStateToProps = (state) => ({
  incomeToday: selectIncomeToday(state),
  totalIncomeToday: selectTotalIncomeToday(state),
  childAndYouthBenefitToday: selectChildAndYouthBenefitToday(state),
  hasDashboardData: selectHasDashboardData(state, 'disability'),
  hasDashboardDataRetirement: selectHasDashboardData(state),
  payoutDisability: selectPayoutDisability(state),
  isNaked: selectIsNaked(state, 'disability'),
  hasDisposableIncomeOptions: selectDisposableIncomeOptions(state),
  error: selectError(state, 'disability'),
  nakedDashboardErrors: selectNakedDashboardErrors(state, 'disability'),
  isDesktop: selectIsDeviceTypeDesktop(state),
  awardRules: selectAwardRules(state),
  awardRulesSucceeded: selectAwardRulesSucceeded(state),
  hasChildrenPayouts: selectHasChildrenPayoutPolicies(state, 'disability'),
  noRecommendationType: selectNoRecommendationType(state, 'disability'),
  hasChildrenData: selectChildrenData(state),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['dashboard'],
  init: ({ action, getProps }) =>
    action(
      async () => {
        const {
          dispatch,
          hasDashboardData,
          hasDisposableIncomeOptions,
          awardRules,
          hasDashboardDataRetirement,
          hasChildrenData,
        } = getProps();
        const dispatchArray = [];

        if (!hasDashboardDataRetirement) {
          dispatchArray.push(fetchRetirement());
        }

        if (!awardRules) {
          dispatchArray.push(getAwardRules());
        }

        if (!hasDisposableIncomeOptions) {
          dispatchArray.push(getIncome());
        }

        if (!hasDashboardData) {
          dispatchArray.push(fetchDisability());
        }

        if (!hasChildrenData) {
          dispatchArray.push(getChildren());
        }

        if (dispatchArray.length > 0) {
          await dispatch(dispatchArray);
        }

        dispatch(refetchNakedDashboardErrors('disability'));
      },
      {
        loader: true,
        error: {
          type: 'overlay',
          retry: true,
        },
      }
    ),
})(withTheme(Disability));
