import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

const Scroll = styled.ScrollView`
  height: ${(props) => props.height}px;
  ${(props) => props.width && `width: ${props.width}px`};
`;

const NonScroll = styled.View`
  height: ${(props) => props.height}px;
  ${(props) => props.width && `width: ${props.width}px`};
`;

const Wrapper = styled.View`
  flex-direction: row;
  height: ${(props) => props.height}px;
  ${(props) => props.centerContent && 'justify-content: center'};
`;

const Root = ({ children, withScroll, height, width, centerContent, getRef, onScroll }) => {
  const OuterWrapper = withScroll ? Scroll : NonScroll;
  return (
    OuterWrapper && (
      <OuterWrapper
        height={height}
        width={width}
        horizontal
        ref={(ref) => getRef(ref)}
        onScroll={onScroll}
      >
        <Wrapper height={height} centerContent={centerContent}>
          {children}
        </Wrapper>
      </OuterWrapper>
    )
  );
};

Root.propTypes = {
  children: PropTypes.node.isRequired,
  withScroll: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number,
  centerContent: PropTypes.bool,
  getRef: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
};

Root.defaultProps = {
  withScroll: false,
  width: undefined,
  centerContent: true,
  onScroll: undefined,
};

export default Root;
