import React from 'react';
import Conditional from 'decorators/conditional';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { H2 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';

const Wrapper = styled(Margin)`
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-basis: auto;
`;

const Text = styled(H2)`
  text-align: center;
`;

const DesktopMargin = Conditional({
  desktop: Margin,
});

const EmptyFlatList = ({ text, icon, theme, isFetching }) =>
  !isFetching && (
    <Wrapper marginLeft="lg" marginRight="lg">
      <DesktopMargin marginTop="lg" />
      <Text>{text}</Text>
      <Margin marginTop="md" marginBottom="xl">
        {icon || <Icon name="folderBig" fill={theme.COLORS.PRIMARY} width={69} height={49} />}
      </Margin>
    </Wrapper>
  );

EmptyFlatList.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  icon: PropTypes.node,
};

EmptyFlatList.defaultProps = {
  icon: undefined,
};

export default withTheme(EmptyFlatList);
