import React from 'react';
import { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import { TopGradientContent, NarrowContent } from '@sp/ui/pages/BasePage';
import BrowserController from './BrowserController';

const Browser = ({
  title,
  hideBrowserControl,
  hasBrowsingArrows,
  minBrowsing,
  maxBrowsing,
  goBack,
  goForward,
  browserTitle,
  heading,
  subtitle,
  content,
  yearlyDepositOverviewButton,
  isDesktop,
}) => (
  <>
    <TopGradientContent
      content={{
        title,
        heading,
        subtitle,
      }}
      removeTitleMobile
      gradientHeader
      withBrowser
      isDesktop={isDesktop}
    />
    <BrowserController
      minBrowsing={minBrowsing}
      maxBrowsing={maxBrowsing}
      hasBrowsingArrows={hasBrowsingArrows}
      hideBrowserControl={hideBrowserControl}
      goBack={goBack}
      goForward={goForward}
      browserTitle={browserTitle}
      yearlyDepositOverviewButton={yearlyDepositOverviewButton}
    />
    {content && <NarrowContent>{content}</NarrowContent>}
  </>
);

Browser.propTypes = {
  title: PropTypes.string,
  browserTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  isDesktop: PropTypes.bool,
  hasBrowsingArrows: PropTypes.bool,
  hideBrowserControl: PropTypes.bool,
  content: PropTypes.node,
  yearlyDepositOverviewButton: PropTypes.node,
  minBrowsing: PropTypes.bool.isRequired,
  maxBrowsing: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
};

Browser.defaultProps = {
  hasBrowsingArrows: false,
  hideBrowserControl: false,
  title: undefined,
  browserTitle: undefined,
  heading: undefined,
  subtitle: undefined,
  isDesktop: undefined,
  content: undefined,
  yearlyDepositOverviewButton: null,
};

export default withTheme(Browser);
