import { Dimensions, Platform } from 'react-native';

export default Dimensions;

export const isWeb = Platform.OS === 'web';
export const isAndroid = Platform.OS === 'android';
export const isIOS = Platform.OS === 'ios';

type DeviceTypes = {
  mobile: number;
  tablet: number;
  desktop?: number;
};

/**
 * NOTE: on native we always want tablets ot be in either tablet or mobile mode
 * small tablets might still fall in the < 640 category in portrait mode
 * but for (16:10 we need the breakpoint ot be below 710pt)
 */
const deviceTypes: DeviceTypes = isWeb
  ? {
      mobile: 0,
      tablet: 767,
      desktop: 1024,
    }
  : {
      mobile: 0,
      tablet: 640,
    };

export const getCurrentDeviceType = () => {
  let currentDevice;

  const { width } = Dimensions.get('window');

  for (const [key, value] of Object.entries(deviceTypes)) {
    if (width > value) {
      currentDevice = key;
    }
  }

  return currentDevice;
};

export const getCurrentDeviceOrientation = () => {
  const { width, height } = Dimensions.get('window');
  return height >= width ? 'portrait' : 'landscape';
};

export const isIphone5 = () => {
  const iphone5Size = 320;
  return Dimensions.get('window').width <= iphone5Size;
};
