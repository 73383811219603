import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Transitioning, Transition } from 'react-native-reanimated';
import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';
import TabBarIcon from '@sp/ui/base/TabBarIcon';
import TabBarLabel from '@sp/ui/base/TabBarLabel';

const transition = (
  <Transition.Together>
    <Transition.Change interpolation="easeInOut" durationMs={300} />
    <Transition.In type="fade" durationMs={300} />
    <Transition.Out type="fade" durationMs={400} />
  </Transition.Together>
);

export const TAB_BAR_HEIGHT_MOBILE = 56;
export const TAB_BAR_HEIGHT_TABLET = 70;

// NOTE: copied from https://github.com/react-navigation/tabs/blob/master/src/views/BottomTabBar.tsx
export const TouchableWithoutFeedbackWrapper = ({
  onPress,
  onLongPress,
  testID,
  accessibilityLabel,
  ...props
}) => (
  <TouchableWithoutFeedback
    onPress={onPress}
    onLongPress={onLongPress}
    testID={testID}
    hitSlop={{
      left: 15,
      right: 15,
      top: 5,
      bottom: 5,
    }}
    accessibilityLabel={accessibilityLabel}
  >
    <View {...props} />
  </TouchableWithoutFeedback>
);

TouchableWithoutFeedbackWrapper.propTypes = {
  onPress: PropTypes.func.isRequired,
  onLongPress: PropTypes.func,
  testID: PropTypes.string,
  accessibilityLabel: PropTypes.string,
};

TouchableWithoutFeedbackWrapper.defaultProps = {
  onLongPress: () => {},
  testID: undefined,
  accessibilityLabel: undefined,
};

const TabBar = styled.View`
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
`;

const TabBarWrapper = Conditional({
  mobile: styled.View`
    flex-direction: row;
    height: ${TAB_BAR_HEIGHT_MOBILE}px;
    justify-content: space-around;
    border-top-width: ${StyleSheet.hairlineWidth}px;
    border-top-color: ${(props) => props.theme.COLORS.PRIMARY_BORDER};
    background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  `,
  default: styled.View`
    flex-direction: row;
    height: ${TAB_BAR_HEIGHT_TABLET}px;
    justify-content: space-around;
    border-top-width: ${StyleSheet.hairlineWidth}px;
    border-top-color: ${(props) => props.theme.COLORS.PRIMARY_BORDER};
    background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  `,
});

const TabWrapper = styled.View`
  justify-content: center;
  align-items: center;
  min-width: 80px;
`;

const Tab = ({ focused, onPress, onLongPress, label, icon, testID }) => (
  <TouchableWithoutFeedbackWrapper
    onPress={onPress}
    onLongPress={onLongPress}
    testID={testID}
    accessibilityRole="tab"
    accessibilityLabel={label}
    accessibilityState={{
      selected: focused,
    }}
  >
    <TabWrapper>
      <TabBarIcon name={icon} focused={focused} />
      {focused && <TabBarLabel text={label} />}
    </TabWrapper>
  </TouchableWithoutFeedbackWrapper>
);

Tab.propTypes = {
  focused: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  onLongPress: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  testID: PropTypes.string.isRequired,
};

const BottomTabBar = ({ style, state, navigation, onTabPress, descriptors }) => {
  const { routes } = state;
  const ref = useRef(null);
  const instets = useSafeAreaInsets();

  const onPress = (route, isFocused, trackingParams) => {
    onTabPress(trackingParams);

    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
      canPreventDefault: true,
    });

    if (!isFocused && !event.defaultPrevented) {
      navigation.navigate(route.name);
    }
  };

  const onLongPress = (route) => {
    navigation.emit({
      type: 'tabLongPress',
      target: route.key,
    });
  };

  return (
    <TabBar accessibilityRole="tablist" style={style} paddingBottom={instets.bottom}>
      <Transitioning.View {...{ transition, ref }}>
        <TabBarWrapper>
          {routes.map((route, index) => {
            const { options } = descriptors[route.key];
            const focused = index === state.index;

            return (
              <Tab
                onPress={() => {
                  if (ref.current) {
                    ref.current.animateNextTransition();
                  }

                  onPress(route, focused, options.tabBarInfo[route.name].trackingParams);
                }}
                onLongPress={() => onLongPress(route)}
                key={route.key}
                focused={focused}
                icon={options.tabBarInfo[route.name].icon}
                testID={options.tabBarInfo[route.name].testID}
                label={options.tabBarInfo[route.name].titleFn()}
              />
            );
          })}
        </TabBarWrapper>
      </Transitioning.View>
    </TabBar>
  );
};

BottomTabBar.propTypes = {
  descriptors: PropTypes.object.isRequired,
  style: PropTypes.object,
  state: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  onTabPress: PropTypes.func.isRequired,
};

BottomTabBar.defaultProps = {
  style: undefined,
};

export default Conditional({
  default: BottomTabBar,
  desktop: null,
});
