import React from 'react';

const { SafeAreaWrapper } = require('@sp/ui/v2/pages/BasePage');
const { default: ModalContext } = require('@sp/ui/context/ModalContext');

type ModalProviderProps = {
  isModal: boolean;
  onClose?: () => void;
  hasOnCloseButton?: boolean;
  shouldDismissPopupFlow?: boolean;
  children: React.ReactNode;
  leftContent?: React.ReactNode;
  iPhoneXOffset?: boolean;
  transparentOffset?: boolean;
  secondaryColor?: boolean;
};

export const ModalProvider = ({
  isModal,
  onClose,
  hasOnCloseButton,
  children,
  leftContent,
  iPhoneXOffset,
  transparentOffset,
  shouldDismissPopupFlow,
  secondaryColor,
}: ModalProviderProps) => (
  <ModalContext.Provider
    value={{
      isModal,
      onClose,
      hasOnCloseButton,
      leftContent,
      shouldDismissPopupFlow,
    }}
  >
    {isModal && iPhoneXOffset ? (
      <SafeAreaWrapper transparent={transparentOffset} secondaryColor={secondaryColor}>
        {children}
      </SafeAreaWrapper>
    ) : (
      children
    )}
  </ModalContext.Provider>
);

ModalProvider.defaultProps = {
  onClose: undefined,
  leftContent: undefined,
  hasOnCloseButton: true,
  iPhoneXOffset: true,
  shouldDismissPopupFlow: true,
  secondaryColor: false,
  transparentOffset: false,
};

export default ModalContext;
