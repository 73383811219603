export const isFormEmpty = (state, name) => Object.keys(state.forms[name] || {}).length === 0;
export const shouldUpdateContactInfo = (state, name) => {
  const form = state.forms[name] || {};
  const contactInfo = state.areas?.profile?.contactInfo || {};

  const hasChangedOnlyEmail = form.email && !(form.phone?.phoneNumber && form.phone?.countryCode);
  const hasChangedOnlyPhone = form.phone?.phoneNumber && form.phone?.countryCode && !form.email;

  const hasChangedBoth = form.email && form.phone?.phoneNumber && form.phone?.countryCode;
  const isPhoneEmpty = form.phone?.countryCode && !form.phone?.phoneNumber;
  const isEmailEmpty = !form.email;

  const isPhoneSameAsInProfile =
    contactInfo.countryCode === form.phone?.countryCode &&
    contactInfo.phoneNumber === form.phone?.phoneNumber;
  const isEmailSameAsInProfile = contactInfo.email === form.email;

  // Handle empty phone number
  if (isPhoneEmpty && Object.keys(form).length === 1) {
    return false;
  }

  // Handle empty phone + email
  if (isPhoneEmpty && isEmailEmpty) {
    return false;
  }

  // Handle same contact info as in profile
  if (hasChangedBoth && isEmailSameAsInProfile && isPhoneSameAsInProfile) {
    return false;
  }

  if (hasChangedOnlyPhone && isPhoneSameAsInProfile) {
    return false;
  }

  if (hasChangedOnlyEmail && isEmailSameAsInProfile) {
    return false;
  }

  // Handle empty values
  return Object.values(form).filter(Boolean).length > 0;
};
