import AuthHandler from '@sampension/oidc-client';

const client = new AuthHandler({
  url: 'https://identitet.sampension.dk',
  scopes: [
    'openid',
    'profile',
    'email',
    'phone',
    'address',
    'urn:sp:coverages',
    'urn:sp:api',
    'urn:sp:api:scope:internal',
    'urn:sp:personal',
  ],
  clientId: 'Sampension.Web.Pkce',
  contextName: 'login_token',
  redirectUrl: `${global.location.protocol}//${global.location.host}/login`,
});

export default client;
