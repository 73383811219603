import { create } from '../helpers/reducers';
import {
  RESPONSE_GET_HEALTH_BENEFITS,
  RESPONSE_GET_HAS_HEALTH_BENEFITS,
} from 'constants/actionTypes';

export type Page = {
  title: string;
  description: string;
  pages: Array<Page>;
};

export type Section = {
  name: string;
  pages: Array<Page>;
};

type HealthBenefitsResponse = {
  benefits: Array<Section>;
};

export type HealthBenefitsState = {
  data?: HealthBenefitsResponse;
  hasHealthBenefits?: boolean;
};

const createDefaults: () => HealthBenefitsState = () => ({
  data: undefined,
  hasHealthBenefits: undefined,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [RESPONSE_GET_HEALTH_BENEFITS]: (
      state: any,
      payload: { response: HealthBenefitsResponse }
    ) => ({
      ...state,
      data: payload.response,
    }),
    [RESPONSE_GET_HAS_HEALTH_BENEFITS]: (
      state: Record<string, any>,
      payload: { response: any }
    ) => ({
      ...state,
      ...payload.response,
    }),
  },
  createDefaults
);

export type { State };
