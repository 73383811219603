import React from 'react';
import styled from 'styled-components/native';
import Tracking from 'services/tracking';
import { useNavigationState, useNavigation, useRoute } from '@react-navigation/native';
import useI18n from 'hooks/useI18n';
import Conditional from 'decorators/conditional';
import { MARGIN_VERTICAL, ICON_HEIGHT } from '@sp/ui/pages/BasePage/BackButton/index.desktop.js';

import { BackButton } from '@sp/ui/pages/BasePage';

const Spacer = Conditional({
  default: null,
  desktop: styled.View`
    height: ${MARGIN_VERTICAL * 2 + ICON_HEIGHT}px;
  `,
});

function useIsFirstRouteInParent() {
  return useNavigationState((state) => state.index !== 0);
}

const BackButtonContainer = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { i18n } = useI18n();

  const hasBack = useIsFirstRouteInParent();

  return hasBack ? (
    <BackButton
      onPress={() => {
        Tracking.trackEvent({
          area: route.name,
          action: 'ClickBack',
          description: 'Back button is pressed',
        });
        navigation.goBack();
      }}
      label={i18n.t('Tilbage')}
      testID="backButton"
      accessibilityLabel={i18n.t('Tilbage til forrige skærm.')}
    />
  ) : (
    <Spacer />
  );
};

export default BackButtonContainer;
