import { create } from '../helpers/reducers';
import {
  CHANGE_INVESTMENT_CLEAR_PENDING_CHANGES,
  SET_CHANGE_INVESTMENT_PROFILE_CONTACT_INFO,
  SET_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES,
  SET_CHANGE_INVESTMENT_PROFILE_POLICIES,
  CLEAR_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES,
  SET_CHANGE_INVESTMENT_PROFILE_ANSWERS,
} from 'constants/actionTypes';

const createDefaults = () => ({
  questions: undefined,
  pendingChanges: undefined,
  recommendation: undefined,
  answers: undefined,
  contactInfo: undefined,
  consents: undefined,
  signingUrl: undefined,
  newRiskProfiles: undefined,
  receipt: undefined,
  current: undefined,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    RESPONSE_CHANGE_INVESTMENT_PENDING_CHANGES: (state, payload) => ({
      ...state,
      pendingChanges: payload.response,
    }),
    [CHANGE_INVESTMENT_CLEAR_PENDING_CHANGES]: (state) => ({
      ...state,
      pendingChanges: undefined,
    }),
    RESPONSE_CHANGE_INVESTMENT_CURRENT_PROFILES: (state, payload) => ({
      ...state,
      current: payload.response,
    }),
    RESPONSE_CHANGE_INVESTMENT_PROFILE_CONSENTS: (state, payload) => ({
      ...state,
      consents: payload.response,
    }),
    RESPONSE_CHANGE_INVESTMENT_QUESTIONS: (state, payload) => ({
      ...state,
      questions: payload.response,
    }),
    RESPONSE_CHANGE_INVESTMENT_RECOMMENDED_PROFILE: (state, payload) => ({
      ...state,
      recommendation: payload.response,
    }),
    [SET_CHANGE_INVESTMENT_PROFILE_ANSWERS]: (state, payload) => ({
      ...state,
      answers: payload,
    }),
    [SET_CHANGE_INVESTMENT_PROFILE_CONTACT_INFO]: (state, payload) => ({
      ...state,
      contactInfo: payload,
    }),
    [SET_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES]: (state, payload) => ({
      ...state,
      newRiskProfiles: payload,
    }),
    [CLEAR_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES]: (state) => ({
      ...state,
      newRiskProfiles: undefined,
    }),
    [SET_CHANGE_INVESTMENT_PROFILE_POLICIES]: (state, payload) => ({
      ...state,
      policies: payload,
    }),
    RESPONSE_CHANGE_INVESTMENT_COMPLETE_SIGNING: (state, payload) => ({
      ...state,
      receipt: payload.response,
    }),
  },
  createDefaults
);

export type { State };
