import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';
import Svg, { LinearGradient, Stop, Rect, Defs, G } from 'react-native-svg';
import { H1, H2, Body } from '@sp/ui/typography';
import { SPACINGS, COLORS, CONTAINER_WIDTHS } from '@sp/ui/settings';

const WIDTH = '100%';
const MAX_WIDTH = CONTAINER_WIDTHS.DESKTOP_WIDE;

const DESKTOP_PADDING_TOP = SPACINGS.xs;
const DESKTOP_PADDING_BOTTOM = SPACINGS.xs;
const DEFAULT_PADDING_BOTTOM = SPACINGS.sm;

const DESKTOP_TOP_POSITION = SPACINGS.xxl;
const TABLET_TOP_POSITION = 0;
const MOBILE_TOP_POSITION = SPACINGS.md;

const HEIGHT_DESKTOP_WEB = 237;
const HEIGHT_DESKTOP_NO_BROWSER = 192;
const HEIGHT_TABLET_MOBILE = 142;
const HEIGHT_TABLET_MOBILE_NO_BROWSER = 98;

const TEXT_COLOR = COLORS.SECONDARY_BACKGROUND;

const Wrapper = styled.View`
  width: ${WIDTH};
  max-width: ${MAX_WIDTH}px;
  position: relative;
`;

const TextWrapper = styled.View`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  align-items: center;
`;

const SvgWrapper = Conditional({
  desktop: styled.View`
    height: ${(props) => (props.withBrowser ? HEIGHT_DESKTOP_WEB : HEIGHT_DESKTOP_NO_BROWSER)}px;
  `,
  default: styled.View`
    height: ${(props) =>
      props.withBrowser ? HEIGHT_TABLET_MOBILE : HEIGHT_TABLET_MOBILE_NO_BROWSER}px;
  `,
});

const ContentWrapper = Conditional({
  desktop: styled(TextWrapper)`
    top: ${DESKTOP_TOP_POSITION}px;
  `,
  tablet: styled(TextWrapper)`
    top: ${TABLET_TOP_POSITION}px;
  `,
  mobile: styled(TextWrapper)`
    top: ${MOBILE_TOP_POSITION}px;
  `,
});

const ContentDesktopWrapper = Conditional({
  desktop: styled.View`
    padding-top: 0px;
    padding-bottom: ${DESKTOP_PADDING_BOTTOM}px;
    display: visible;
  `,
  default: styled.View`
    display: none;
  `,
});

const ContentHeadingWrapper = Conditional({
  desktop: styled.View`
    padding: 0px;
  `,
  default: styled.View`
    padding-top: 0px;
    padding-bottom: ${DEFAULT_PADDING_BOTTOM}px;
  `,
});

const ContentSubtitleWrapper = Conditional({
  desktop: styled.View`
    padding-top: ${DESKTOP_PADDING_TOP}px;
    padding-bottom: 0px;
  `,
  default: styled.View`
    padding: 0px;
  `,
});

const GradientPrimary = ({ withBrowser, gradient, colorStops, width, content }) => {
  const [fullWidth, setFullWidth] = useState(width);

  return (
    <Wrapper
      width={fullWidth}
      onLayout={(event) => {
        // NOTE: in case we change orientation we have to re-render the gradient
        // for it to fill the container
        if (width === '100%') {
          const { width: layoutWidth } = event.nativeEvent.layout;
          setFullWidth(layoutWidth);
        }
      }}
    >
      <SvgWrapper withBrowser={withBrowser}>
        <Svg height="100%" width="100%">
          <G>
            <Defs>
              <LinearGradient
                id="gradient"
                x1={gradient.x1}
                y1={gradient.y1}
                x2={gradient.x2}
                y2={gradient.y2}
              >
                {colorStops.map((item, i) => (
                  <Stop
                    key={`${item}_${i}`}
                    offset={colorStops[i].offset}
                    stopColor={colorStops[i].stopColor}
                  />
                ))}
              </LinearGradient>
            </Defs>
            <Rect fill="url(#gradient)" x="0" y="0" width="100%" height="100%" />
          </G>
        </Svg>
      </SvgWrapper>
      <ContentWrapper>
        <ContentDesktopWrapper>
          <H2 color={TEXT_COLOR}>{content.title}</H2>
        </ContentDesktopWrapper>
        <ContentHeadingWrapper>
          <H1 color={TEXT_COLOR}>{content.heading}</H1>
        </ContentHeadingWrapper>
        <ContentSubtitleWrapper>
          <Body color={TEXT_COLOR}>{content.subtitle}</Body>
        </ContentSubtitleWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

GradientPrimary.propTypes = {
  withBrowser: PropTypes.bool,
  gradient: PropTypes.object,
  content: PropTypes.object,
  colorStops: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GradientPrimary.defaultProps = {
  withBrowser: false,
  gradient: {},
  colorStops: [],
  width: 0,
  content: undefined,
};

export default GradientPrimary;
