import { Middleware } from 'redux';
import { FETCH_TAX_PENSIONSINFO } from 'constants/actionTypes';
import * as routeNames from 'constants/routeNames';
import {
  initFetchTaxAndPensionsInfo,
  deleteFetchTaxAndPensionsInfoStore,
} from 'actions/fetchTaxAndPensionsInfo';
import { selectDeeplink } from 'selectors/deeplink';
import { selectHasFetchTaxAndPensionsInfoData } from 'selectors/fetchTaxAndPensionsInfo';
import { getFetchTaxOrPensionsInfo } from './getFetchTaxOrPensionsInfo';
import { getOnDoneRouteConfig } from './getOnDoneRouteConfig';
import fetchTaxAndPensionsInfoController from 'areas/fetchTaxAndPensionsInfo/fetchTaxAndPensionsInfoController';
import type { RootState } from 'store';

const { navigate } = require('helpers/navigation');

const fetchTaxAndPensionsInfoMiddleware: Middleware<Record<string, unknown>, RootState> =
  (store) =>
  (next) =>
  (action: any): any => {
    if (action.type === FETCH_TAX_PENSIONSINFO) {
      const { isOnboarding, onDoneCallback, onDoneNavigation, onSkipNavigation } =
        action.payload ?? {};
      const {
        resetController,
        updateOnDoneCallback,
        updateOnDoneNavigation,
        updateOnSkipNavigation,
      } = fetchTaxAndPensionsInfoController;
      const { dispatch, getState } = store;
      const state = getState();

      const { isDeeplink } = selectDeeplink(state);
      const hasFetchTaxAndPensionsInfoData = selectHasFetchTaxAndPensionsInfoData(state);
      const onDoneRouteConfig = getOnDoneRouteConfig();
      const fetchTaxOrPensionsInfo = getFetchTaxOrPensionsInfo(action.payload, state);

      if (hasFetchTaxAndPensionsInfoData) {
        dispatch(deleteFetchTaxAndPensionsInfoStore());
      }

      dispatch(
        initFetchTaxAndPensionsInfo({
          onDoneRouteConfig,
          fetchTaxOrPensionsInfo,
          isOnboarding,
        })
      );

      resetController();
      updateOnSkipNavigation(onSkipNavigation);
      updateOnDoneCallback(onDoneCallback);
      updateOnDoneNavigation(onDoneNavigation);

      /* NOTE: Onboarding middleware and deeplink hook handles the navigation */
      if (!isOnboarding || !isDeeplink) {
        navigate(routeNames.MAIN, {
          screen: routeNames.FETCH_TAX_AND_PENSIONSINFO,
        });
      }
    }

    next(action);
  };

export default fetchTaxAndPensionsInfoMiddleware;
