import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import * as routeNames from 'constants/routeNames';

import ExternalDataOverview from './ExternalDataOverview';
import InformationAboutDeletion from './InformationAboutDeletion';

const Stack = createStackNavigator();

const ExternalDataRouter = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen
      name={routeNames.PROFILE_EXTERNAL_DATA_OVERVIEW}
      component={ExternalDataOverview}
    />
    <Stack.Screen
      name={routeNames.PROFILE_INFORMATION_ABOUT_EXTERNAL_DATA_DELETION}
      component={InformationAboutDeletion}
    />
  </Stack.Navigator>
);

export default ExternalDataRouter;
