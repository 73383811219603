const addMaxValues = (bars) =>
  bars.map((bar) => {
    const totalValue = bar.segments.reduce((a, b) => a + b.value, 0);
    return {
      ...bar,
      totalValue,
    };
  });

const setMinimumValue = (bars) =>
  bars.map((bar) => ({
    ...bar,
    segments: bar.segments.map((segment) => ({
      ...segment,
      value: segment.value > 0 && segment.value <= 3 ? 3 : segment.value,
    })),
  }));

const getRatio = (barsWithMax, graphHeight, maxValue) =>
  graphHeight /
  (maxValue
    ? Math.max(...barsWithMax.map((bar) => bar.totalValue), maxValue)
    : Math.max(...barsWithMax.map((bar) => bar.totalValue)));

export const recalculateBars = (bars, graphHeight, addEmptyBar, emptyBarWidth, maxValue) => {
  const barsWithMinimumValue = setMinimumValue(bars);
  const barsWithMax = addMaxValues(barsWithMinimumValue);
  const ratio = getRatio(barsWithMax, graphHeight, maxValue);
  const recalculatedBars = barsWithMax.map((bar) => ({
    ...bar,
    segments: bar.segments
      .filter((el) => el?.value !== 0)
      .map((segment) => ({
        ...segment,
        value: Math.round(segment.value * ratio),
      })),
  }));

  if (addEmptyBar) {
    recalculatedBars.push({
      segments: [{ value: 0 }],
      outerWidth: emptyBarWidth,
      totalValue: 0,
    });
  }

  return {
    recalculatedBars,
    ratio,
  };
};

export const graphColors = [
  '#15B5C0F3',
  '#15B5C0E6',
  '#15B5C0D9',
  '#15B5C0CC',
  '#15B5C0C0',
  '#15B5C0B3',
  '#15B5C0A6',
  '#15B5C099',
  '#15B5C08C',
  '#15B5C080',
  '#15B5C073',
  '#15B5C066',
  '#15B5C059',
  '#15B5C04C',
  '#15B5C040',
  '#15B5C033',
  '#15B5C026',
  '#15B5C019',
];
