import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import HTMLRenderer from 'react-native-render-html';
import { FONT_SIZES } from '@sp/ui/settings';
import fontStyles from '@sp/ui/typography/fontStyles';
import {
  IGNORED_TAGS,
  alterNode,
  makeTableRenderer,
  makeCustomTableRenderer,
  defaultTableStylesSpecs,
  cssRulesFromSpecs,
} from '@native-html/table-plugin';
import WebView from 'react-native-webview';
import Dimensions, { isWeb } from '@sp/ui/helpers/device';
import RawHTML from '@sp/ui/base/RawHTML';

const ListItem = styled.View`
  margin-top: -${FONT_SIZES.BODY.DESKTOP}px;
`;

export const fixSpaceInHTML = (html = '') => {
  html = html.replace(/\s\s+/g, ' ');
  html = html.replace(/>\s/g, '>');
  html = html.replace(/\&emsp;/g, '<span style="padding-right: 1em;"></span>');
  return html;
};

const textToHtml = (input) => {
  if (!input.match(/(<.+>.*<\/.+>)|(<[a-zA-Z0-9-_]+\/>)/g)) {
    return input.replace(/\n/g, '<br />');
  }
  return input;
};

const cssRules = `
  ${cssRulesFromSpecs(defaultTableStylesSpecs)}
  td {
    text-align: left;
    padding: ${defaultTableStylesSpecs.cellPaddingEm}em;
  }
`;

const renderers = {
  li: (_, children) => <ListItem>{children}</ListItem>,
  table: isWeb
    ? makeCustomTableRenderer((hTMLTableBaseProps) => (
        <RawHTML content={hTMLTableBaseProps?.html} />
      ))
    : makeTableRenderer({ WebView, cssRules }),
};

const htmlConfig = {
  alterNode,
  renderers,
  ignoredTags: IGNORED_TAGS,
};

const HTML = ({ content, theme, onLinkPress, textSelectable }) => (
  <HTMLRenderer
    html={fixSpaceInHTML(textToHtml(content))}
    baseFontStyle={fontStyles('regular')}
    imagesMaxWidth={Dimensions.get('window').width}
    tagsStyles={{ a: { color: theme.COLORS.PRIMARY_LINK } }}
    onLinkPress={onLinkPress}
    textSelectable={textSelectable}
    {...htmlConfig}
  />
);

HTML.propTypes = {
  content: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  onLinkPress: PropTypes.func.isRequired,
  textSelectable: PropTypes.bool,
};

HTML.defaultProps = {
  textSelectable: true,
};

export default withTheme(HTML);
