import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import Animated from 'react-native-reanimated';
import { isIOS } from '@sp/ui/helpers/device';

export const KeyboardContext = React.createContext({});
export const { Provider } = KeyboardContext;

const KeyboardProviderWrapper = ({ children }) => {
  const isKeyboardVisibleAnimatedValue = useRef(new Animated.Value(0)).current;
  const keyboardHeightAnimatedValue = useRef(new Animated.Value(0)).current;
  const keyboardDurationAnimatedValue = useRef(new Animated.Value(0)).current;

  const [keyboardMeasures, setKeyboardMeasures] = useState({
    isKeyboardVisible: false,
    keyboardHeight: 0,
    keyboardDuration: 0,
  });

  const { isKeyboardVisible, keyboardHeight, keyboardDuration } = keyboardMeasures;

  const show = isIOS ? 'keyboardWillShow' : 'keyboardDidShow';
  const hide = isIOS ? 'keyboardWillHide' : 'keyboardDidHide';

  const handleShow = useCallback((e) => {
    setKeyboardMeasures({
      isKeyboardVisible: true,
      keyboardHeight: e.endCoordinates.height,
      keyboardDuration: e.duration,
    });

    isKeyboardVisibleAnimatedValue.setValue(new Animated.Value(1));
    keyboardHeightAnimatedValue.setValue(new Animated.Value(e.endCoordinates.height));
    keyboardDurationAnimatedValue.setValue(new Animated.Value(e.duration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHide = useCallback((e) => {
    setKeyboardMeasures({
      isKeyboardVisible: false,
      keyboardHeight: 0,
      keyboardDuration: e.duration,
    });

    isKeyboardVisibleAnimatedValue.setValue(new Animated.Value(0));
    keyboardHeightAnimatedValue.setValue(new Animated.Value(0));
    keyboardDurationAnimatedValue.setValue(new Animated.Value(e.duration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const showSubscription = Keyboard.addListener(show, handleShow);
    const hideSubscription = Keyboard.addListener(hide, handleHide);
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider
      value={{
        isKeyboardVisibleAnimatedValue,
        keyboardHeightAnimatedValue,
        keyboardDurationAnimatedValue,
        isKeyboardVisible,
        keyboardHeight,
        keyboardDuration,
      }}
    >
      {children}
    </Provider>
  );
};

KeyboardProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KeyboardProviderWrapper;
