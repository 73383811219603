import React from 'react';
import styled from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type WrapperProps = {
  transparent: boolean;
  secondaryColor: string;
};

const Wrapper = styled.View<{ bottomPadding: number } & WrapperProps>`
  padding-bottom: ${(props) => props.bottomPadding || 0}px;
  flex: 1;
  background: ${({ theme, transparent, secondaryColor }) => {
    if (secondaryColor) {
      return theme.COLORS.V2_SECONDARY_BACKGROUND;
    }
    return transparent ? 'transparent' : theme.COLORS.V2_PRIMARY_BACKGROUND;
  }};
`;

export const SafeAreaWrapper = ({
  children,
  transparent,
  secondaryColor,
}: {
  children: React.ReactNode;
} & WrapperProps) => {
  const insets = useSafeAreaInsets();

  return (
    <Wrapper
      bottomPadding={insets.bottom}
      transparent={transparent}
      secondaryColor={secondaryColor}
    >
      {children}
    </Wrapper>
  );
};

export default SafeAreaWrapper;
