import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';
import fontStyles from '@sp/ui/typography/fontStyles';

const DESCRIPTION_FONT_SIZE = 9;

const Description = styled.TouchableOpacity`
  position: absolute;
  border-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
  padding: ${({ theme }) => theme.SPACINGS.xs}px ${({ theme }) => theme.SPACINGS.sm}px;
  bottom: ${(props) => props.bottomOffset}px;
  right: 0;
  background: ${({ theme }) => theme.COLORS.PRIMARY_OVERLAY_SOLID};
  margin-bottom: ${({ theme }) => theme.SPACINGS.xs}px;
`;

const DescriptionText = styled(withDefaultTypographyProps)`
  font-size: ${DESCRIPTION_FONT_SIZE}px;
  ${fontStyles('bold')};
`;

const AverageLabel = ({ bottomOffset, label, showAverageModal, theme }) => (
  <Description bottomOffset={bottomOffset} onPress={showAverageModal} hitSlop={theme.HIT_SLOP}>
    <DescriptionText>{label}</DescriptionText>
  </Description>
);

AverageLabel.propTypes = {
  bottomOffset: PropTypes.number,
  label: PropTypes.string,
  showAverageModal: PropTypes.func,
  theme: PropTypes.object.isRequired,
};

AverageLabel.defaultProps = {
  bottomOffset: 0,
  label: undefined,
  showAverageModal: undefined,
};

export default withTheme(AverageLabel);
