import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { Body2 } from '@sp/ui/v2/typography';

const MARGIN_LEFT = 16;
const CHECKBOX_SIZE = 24;
const BORDER_WIDTH = 1;
const CHECKMARK_SIZE = 13;

const Wrapper = styled.View`
  flex: 1;
  align-items: center;
  flex-direction: row;
`;

const Box = styled.TouchableOpacity`
  border-width: ${BORDER_WIDTH}px;
  border-color: ${(props) => props.theme.COLORS.PRIMARY};
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
  border-radius: ${({ theme }) => theme.SPACINGS.xs};
  width: ${CHECKBOX_SIZE}px;
  height: ${CHECKBOX_SIZE}px;
  margin-right: ${MARGIN_LEFT}px;
  ${(props) => (props.checked ? `background: ${props.theme.COLORS.PRIMARY}` : '')}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.View`
  flex: 1;
`;

const Checkbox = ({
  text,
  checked,
  onChangeChecked,
  theme,
  testID,
  accessibilityLabel,
  ...rootProps
}) => (
  <Wrapper {...rootProps}>
    <Box
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="checkbox"
      accessibilityState={{
        checked,
      }}
      onPress={() => {
        if (onChangeChecked) {
          onChangeChecked(!checked);
        }
      }}
      checked={checked}
      testID={testID}
    >
      {checked && (
        <Icon
          name="checkmark"
          testID={`${testID}Checked`}
          width={CHECKMARK_SIZE}
          height={CHECKMARK_SIZE}
          fill={theme.COLORS.SECONDARY_BACKGROUND}
        />
      )}
    </Box>
    <Content>
      {typeof text === 'string' ? (
        <Body2 fontWeight="semi" secondaryColor>
          {text}
        </Body2>
      ) : (
        text
      )}
    </Content>
  </Wrapper>
);

Checkbox.propTypes = {
  text: PropTypes.any.isRequired,
  checked: PropTypes.bool,
  onChangeChecked: PropTypes.func,
  theme: PropTypes.object.isRequired,
  testID: PropTypes.string,
  accessibilityLabel: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  onChangeChecked: null,
  testID: undefined,
  accessibilityLabel: undefined,
};

export default withTheme(Checkbox);
