import React from 'react';
import { useTheme } from 'styled-components/native';
import { useAppSelector } from 'hooks/redux';
import { PUBLIC_SERVICES_SWITCH } from 'constants/testIds/dashboard/adjustCalculation';

const { default: Switch } = require('@sp/ui/base/inputs/Switch');
const { selectIsDeviceTypeDesktop } = require('selectors/device');

type Props = {
  onPress: () => void;
  isSwitchEnabled: boolean;
};

const DeselectorSwitch: React.FC<Props> = ({ onPress, isSwitchEnabled }) => {
  const isDesktop: boolean = useAppSelector(selectIsDeviceTypeDesktop);
  const { COLORS } = useTheme();
  const switchThumbAndTrackColor = isDesktop
    ? {}
    : {
        activeThumbColor: COLORS.V2_SECONDARY_BACKGROUND,
        thumbColor: COLORS.V2_SECONDARY_BACKGROUND,
        trackColor: {
          true: COLORS.V2_PRIMARY_BACKGROUND,
          false: COLORS.V2_SWITCH_DESELECTED_TRACK,
        },
      };

  return (
    <Switch
      testID={PUBLIC_SERVICES_SWITCH}
      onValueChange={onPress}
      value={isSwitchEnabled}
      {...switchThumbAndTrackColor}
    />
  );
};

export default DeselectorSwitch;
