import {
  CREATE_PARTNER_LINK,
  CONNECT_PARTNER,
  UPDATE_PARTNER_CONNECTION_FAILED,
  GET_PARTNER,
  RESEND_PARTNER_LINK,
  DELETE_PARTNER,
  GET_PARTNER_DATA_FROM_MEETINGPREPARATION,
} from 'constants/actionTypes';

const { put, post, get, remove } = require('actions/api');

export const updatePartnerConnectionFailed = (statusCode: number) => ({
  type: UPDATE_PARTNER_CONNECTION_FAILED,
  payload: { hasPartnerConnectionFailed: true, statusCode },
});

export const createPartnerLink = (contactInformation: object) =>
  post(CREATE_PARTNER_LINK, contactInformation, 'api', '/partners/v1/partnerlink/me');

export const connectPartnerLink = (guid: string) =>
  put(CONNECT_PARTNER, {}, 'api', `/partners/v1/partnerlink/${guid}`);

export const getPartnerCustomerData = () => get(GET_PARTNER, 'api', '/partners/v1/customers/me');

export const getPartnerDataFromMeetingPreparation = () =>
  get(GET_PARTNER_DATA_FROM_MEETINGPREPARATION, 'api', '/partners/v1/partners/me');

export const resendPartnerLink = () =>
  put(RESEND_PARTNER_LINK, {}, 'api', '/partners/v1/partnerlink/resend/me');

export const deletePartner = () => remove(DELETE_PARTNER, 'api', `/partners/v1/partnerlink/me`);
