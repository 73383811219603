import Conditional from 'decorators/conditional';
import Mobile from './index.shared';
import Desktop from './index.desktop';

import Result from './Result';

export { Result };

export default Conditional({
  desktop: Desktop,
  default: Mobile,
});
