import React from 'react';
import PropTypes from 'prop-types';
import UIBlockingLoader from '@sp/ui/base/BlockingLoader';
import withTranslation from 'decorators/i18n';

const BlockingLoader = ({ i18n, ...props }) => (
  <UIBlockingLoader {...props} accessibilityLabel={i18n.t('Vent venligst. Indhold hentes.')} />
);

BlockingLoader.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(BlockingLoader);
