import { actionNames } from '@sampension/api-middleware';
import { RECEIVED_PUSH_NOTIFICATION } from 'constants/actionTypes';
import config from 'config';
import tracker from './index';

const middleware = () => (next) => (action) => {
  if (action.type === actionNames.API_DISCOVERY_RESULT) {
    const { targetEnv } = action.payload;
    const configEnv = config.env;
    // NOTE: if environment was changed via dev settings
    // we use the selected environment and ignore discovery
    const env = configEnv === 'default' ? targetEnv : configEnv;
    action.payload.targetEnv = env;
    tracker.setTargetEnv(env);
  }
  if (action.type === RECEIVED_PUSH_NOTIFICATION) {
    tracker.trackEvent({
      area: 'General',
      action: 'PushNotificationReceived',
      params: {
        id: action.payload?.identifier,
        title: action.payload?.title,
      },
    });
  }
  return next(action);
};

export default middleware;
