import { create } from '../helpers/reducers';
import {
  RESPONSE_GET_ASSETS,
  RESPONSE_ADD_ASSET,
  RESPONSE_DELETE_ASSETS,
} from 'constants/actionTypes';
import {
  ASSET_CATEGORY_LOANS,
  ASSET_CATEGORY_HOUSINGS,
  ASSET_CATEGORY_SAVINGS,
  ASSET_CATEGORY_INVESTMENTS,
  ASSET_TYPE_OWNER_HOUSE,
  ASSET_TYPE_OWNER_APPARTMENT,
  ASSET_TYPE_HOUSING_COOPERATIVE,
  ASSET_TYPE_SUMMERHOUSE,
  ASSET_TYPE_STOCKS,
  ASSET_TYPE_SAVING_SAVINGS_ACOUNT,
  ASSET_TYPE_BONDS,
} from 'constants/meetingPreparation';

export type assetNames =
  | typeof ASSET_CATEGORY_LOANS
  | typeof ASSET_CATEGORY_HOUSINGS
  | typeof ASSET_CATEGORY_SAVINGS
  | typeof ASSET_CATEGORY_INVESTMENTS;

export type assetsDataType = {
  [ASSET_CATEGORY_HOUSINGS]: assetsType[];
  [ASSET_CATEGORY_LOANS]: loanType[];
  [ASSET_CATEGORY_SAVINGS]: assetsType[];
  [ASSET_CATEGORY_INVESTMENTS]: assetsType[];
};

type assetsState = {
  assets: assetsDataType;
};

const defaultAssets = {
  [ASSET_CATEGORY_HOUSINGS]: [],
  [ASSET_CATEGORY_LOANS]: [],
  [ASSET_CATEGORY_SAVINGS]: [],
  [ASSET_CATEGORY_INVESTMENTS]: [],
};

const createDefaults = (): assetsState => ({
  assets: defaultAssets,
});

type State = ReturnType<typeof createDefaults>;

export type loanType = {
  id: string;
  remainingDebt: number;
  interestPerYear: number;
  remainingTimeInYears: number;
  gracePeriodEndYear: number;
  hasGracePeriod: boolean;
  parentId?: string;
};

export type assetsType = {
  id: string;
  value: number;
  type: string;
  ownershipRatio: number;
  loans?: loanType[];
};

const currentYear = new Date().getFullYear();
const convertYearToNumberOfYears = (year: number) => year - currentYear;

export default create(
  {
    [RESPONSE_GET_ASSETS]: (state, { response }) => {
      const housings: assetsType[] = [];
      const savings: assetsType[] = [];
      const investments: assetsType[] = [];
      const loans: loanType[] = [];

      response.assets.forEach((asset: assetsType) => {
        switch (asset.type) {
          case ASSET_TYPE_OWNER_HOUSE:
          case ASSET_TYPE_OWNER_APPARTMENT:
          case ASSET_TYPE_HOUSING_COOPERATIVE:
          case ASSET_TYPE_SUMMERHOUSE:
            const { loans: housingLoans, ...housingObj } = asset;

            housings.push(housingObj);

            housingLoans?.forEach((loan) => {
              loans.push({
                ...loan,
                gracePeriodEndYear: convertYearToNumberOfYears(loan.gracePeriodEndYear),
                parentId: asset.id,
              });
            });
            break;
          case ASSET_TYPE_STOCKS:
          case ASSET_TYPE_SAVING_SAVINGS_ACOUNT:
          case ASSET_TYPE_BONDS:
            investments.push(asset);
            break;
          default:
            savings.push(asset);
        }
      });

      return {
        ...state,
        assets: {
          housings,
          loans,
          savings,
          investments,
        },
      };
    },
    [RESPONSE_ADD_ASSET]: (state, { request, response }) => ({
      ...state,
      assets: {
        ...state.assets,
        [request.category]: [
          ...state.assets[request.category as assetNames],
          {
            id: response,
            ...request,
          },
        ],
      },
    }),
    [RESPONSE_DELETE_ASSETS]: (state) => ({
      ...state,
      assets: defaultAssets,
    }),
  },
  createDefaults
);

export type { State };
