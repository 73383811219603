import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Body, H5 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import Conditional from 'decorators/conditional';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import { isWeb } from '@sp/ui/helpers/device';

const ICON_SIZE_MIN_WIDTH = 20;
const HIT_SLOP_RANGE = 12;
const HIT_SLOP_RANGE_LEFT = 4;

// NOTE: static prevents z-index magic react native web adds unnecessary "position: relative;"
// NOTE: z-index auto makes sure FormSection is not overlapping previous form section
// with i.e. Selector
const WrapperMobile = styled.View`
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  ${isWeb ? 'position: static' : ''};
  ${(props) =>
    props.disablePadding
      ? ''
      : `padding: ${props.theme.SPACINGS.lg}px ${props.theme.SPACINGS.md}px 0`};
  ${isWeb ? 'z-index: auto;' : ''}
`;

const TitleWrapper = styled.View`
  flex-direction: row;
  align-items: ${({ centerInfoBox }) => (centerInfoBox ? 'center' : 'flex-start')};
`;

const LeftContent = styled.View`
  margin-right: auto;
  flex: 1;
`;

const WrapperTablet = styled.View`
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  ${isWeb ? 'position: static' : ''};
  ${(props) =>
    props.disablePadding
      ? ''
      : `padding: ${props.theme.SPACINGS.lg}px ${props.theme.SPACINGS.md}px 0`};
  ${isWeb ? 'z-index: auto;' : ''}
`;

const Wrapper = Conditional({
  mobile: WrapperMobile,
  default: WrapperTablet,
});

export const DescriptionWrapperMobile = styled.View`
  padding-top: ${(props) => props.theme.SPACINGS.sm}px;
  padding-bottom: ${(props) => (props.disablePadding ? 0 : props.theme.SPACINGS.lg)}px;
`;

export const DescriptionWrapperTablet = styled.View`
  padding-top: ${(props) => props.theme.SPACINGS.md}px;
  padding-bottom: ${(props) => (props.disablePadding ? 0 : props.theme.SPACINGS.lg)}px;
`;

const DescriptionWrapper = Conditional({
  mobile: DescriptionWrapperMobile,
  default: DescriptionWrapperTablet,
});

const IconTouchable = styled.TouchableOpacity`
  min-width: ${ICON_SIZE_MIN_WIDTH}px;
  margin-left: ${(props) => props.theme.SPACINGS.sm}px;
  align-items: flex-end;
`;

const FormSection = ({
  title,
  description,
  onInfoPress,
  children,
  theme,
  disablePadding,
  accessibilityLabel,
  infoAccessibilityLabel,
  disableModalContent,
  centerInfoBox,
}) => (
  <Wrapper disablePadding={disablePadding} accessibilityLabel={accessibilityLabel}>
    <TitleWrapper centerInfoBox={centerInfoBox}>
      <LeftContent>{renderStringOrComponent(title, H5)}</LeftContent>
      {onInfoPress && (
        <IconTouchable
          disabled={disableModalContent}
          onPress={onInfoPress}
          hitSlop={{
            top: HIT_SLOP_RANGE,
            left: HIT_SLOP_RANGE_LEFT,
            bottom: HIT_SLOP_RANGE,
            right: HIT_SLOP_RANGE,
          }}
          accessibilityRole="button"
          accessibilityLabel={infoAccessibilityLabel}
        >
          <Icon name="info" fill={theme.COLORS.PRIMARY} />
        </IconTouchable>
      )}
    </TitleWrapper>
    {description && (
      <DescriptionWrapper disablePadding={disablePadding}>
        {renderStringOrComponent(description, Body)}
      </DescriptionWrapper>
    )}
    {children}
  </Wrapper>
);

FormSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.node,
  onInfoPress: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  theme: PropTypes.object.isRequired,
  disablePadding: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  infoAccessibilityLabel: PropTypes.string,
  disableModalContent: PropTypes.bool,
  centerInfoBox: PropTypes.bool,
};

FormSection.defaultProps = {
  children: undefined,
  description: undefined,
  onInfoPress: undefined,
  disablePadding: false,
  accessibilityLabel: undefined,
  infoAccessibilityLabel: undefined,
  disableModalContent: false,
  centerInfoBox: false,
};

export default withTheme(FormSection);
