import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';

const Wrapper = styled.TouchableOpacity``;

const Link = ({ children, onPress }) => (
  <Wrapper onPress={onPress} accessibilityRole="button">
    {children}
  </Wrapper>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default Link;
