import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withForm } from 'react-formed';
import { PhoneInput as BasePhoneInput } from '@sp/ui/base/inputs';

import { getObjectValue } from './index.shared';

const PhoneInput = ({
  setValue,
  value,
  defaultValue,
  defaultCountryCode,
  placeholder,
  onChangeText,
  getIsDirty,
  ...props
}) => {
  const [isDirty, setIsDirty] = useState(false);
  if (typeof getIsDirty === 'function') getIsDirty(isDirty);

  const defaultValueObj = { phoneNumber: defaultValue, countryCode: defaultCountryCode };
  const currentValue = getObjectValue(value, defaultValueObj, isDirty);

  return (
    <BasePhoneInput
      value={currentValue}
      defaultValue={currentValue.phoneNumber}
      defaultCountryCode={currentValue.countryCode}
      placeholder={placeholder}
      onChangeText={(newValue) => {
        setIsDirty(true);

        if (typeof onChangeText === 'function') {
          onChangeText(setValue, newValue);
        } else {
          setValue(newValue);
        }
      }}
      {...props}
    />
  );
};

PhoneInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultCountryCode: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
  getIsDirty: PropTypes.func,
};

PhoneInput.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  defaultCountryCode: undefined,
  placeholder: '',
  getIsDirty: undefined,
  onChangeText: undefined,
};

export default withForm(PhoneInput);
