import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { isWeb } from '@sp/ui/helpers/device';

const Border = styled.View`
  position: absolute;
  width: 100%;
  bottom: ${(props) => props.bottom}px;
  left: ${(props) => props.left || 0}px;
  height: ${isWeb ? '1' : StyleSheet.hairlineWidth}px;
  background: ${({ theme }) => theme.COLORS.PRIMARY_BACKGROUND};
`;

const getLines = (lines, lineStep) => {
  let bottom = 0;
  return lines.map((_, i) => {
    const offset = bottom;
    bottom += lineStep;
    return <Border key={i} bottom={offset} />;
  });
};

const Grid = ({ lineStep, maxValue }) => {
  // Always min. 2 lines if lineStep is set, because the first is used for 0 value.
  const lines = lineStep
    ? new Array(Math.max(2, Math.floor(maxValue / lineStep))).fill(undefined)
    : [{}];

  return getLines(lines, lineStep);
};

Grid.defaultProps = {
  lineStep: undefined,
};

Grid.propTypes = {
  lineStep: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
};

export default withTheme(Grid);
