import { useCallback } from 'react';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as routeNames from 'constants/routeNames';
import {
  updateHasFetchedTax,
  updateHasFetchedPensionsInfo,
  resetFetchingStatuses,
} from 'actions/fetchTaxAndPensionsInfo';
import { selectIsFlowTax, selectShouldFetchPensionsInfo } from 'selectors/fetchTaxAndPensionsInfo';
import { selectDeeplink } from 'selectors/deeplink';
import fetchTaxAndPensionsInfoController from '../fetchTaxAndPensionsInfoController';
import type { RootState } from 'store';

const selectOnFetchComplete = (state: RootState) => ({
  isFlowTax: selectIsFlowTax(state),
  isUserOnlyFetchingTax: selectIsFlowTax(state) && !selectShouldFetchPensionsInfo(state),
  deeplink: selectDeeplink(state),
});

export const useOnFetchComplete = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { isFlowTax, isUserOnlyFetchingTax, deeplink } = useAppSelector(
    selectOnFetchComplete,
    shallowEqual
  );

  const { deeplinkFlow } = deeplink ?? {};
  const avoidRefetching = deeplinkFlow === routeNames.PARTNER;

  return useCallback(() => {
    dispatch([
      isFlowTax ? updateHasFetchedTax(true) : updateHasFetchedPensionsInfo(true),
      resetFetchingStatuses(),
    ]);

    if ((isUserOnlyFetchingTax || !isFlowTax) && !avoidRefetching) {
      const { initiatePendingRefetchingPromise } = fetchTaxAndPensionsInfoController;
      initiatePendingRefetchingPromise({ dispatch });
    }

    navigation.dispatch(
      CommonActions.navigate({
        name: routeNames.FETCH_TAX_PENSIONSINFO_DONE,
        key: isFlowTax ? 'tax-done' : 'pensionsinfo-done',
      })
    );
  }, [isFlowTax, isUserOnlyFetchingTax, navigation, dispatch, avoidRefetching]);
};
