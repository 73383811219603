import React, { useCallback } from 'react';
import { useNavigation, useRoute, useFocusEffect } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { isWeb } from 'helpers/platform';
import { getQueryParamByName } from 'helpers/getQueryParamByName';

const { default: Loader } = require('@sp/ui/base/Loader');

const QUERY_FLOW = 'flow';
const QUERY_ORDERID = 'orderid';
const QUERY_SCREEN = 'screen';

const Redirect: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const isRedirectUrl = route?.path?.startsWith('/redirect');

  const navigateToDepositFlow = useCallback(() => {
    const flow = getQueryParamByName(QUERY_FLOW, route.path);
    const orderId = getQueryParamByName(QUERY_ORDERID, route.path);
    const screen = getQueryParamByName(QUERY_SCREEN, route.path);

    if (flow === routeNames.DEPOSITS.toLowerCase()) {
      navigation.navigate(routeNames.MAIN, {
        screen: routeNames.DEPOSITS,
        params: screen
          ? {
              screen: routeNames.DEPOSITS_PAYMENT_SOURCES,
            }
          : {
              screen: routeNames.DEPOSITS_PAYMENT_COMPLETED,
              params: { orderid: orderId },
            },
      });
    }
  }, [navigation, route]);

  useFocusEffect(() => {
    if (!isWeb && isRedirectUrl) {
      navigateToDepositFlow();
    }
  });

  return <Loader text="Håndterer omdirigering" />;
};

export default Redirect;
