import React, { useState } from 'react';
import type { RootState } from 'store';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import Page from '../components/Page';
import { useInitCalculationProfile, useUpdateCalculationProfile } from '../hooks';
import {
  selectFormAdjustCalculationHasChanges,
  selectRetirementWealth,
} from 'selectors/dashboardForms';
import { RETIREMENT } from '../../constants/dashboardTypes';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Form, Group } = require('react-formed');
const { Body2, Subtitle } = require('@sp/ui/v2/typography');
const { default: buttonPositions } = require('constants/buttonPositions');
const { default: Input } = require('containers/form/Input');
const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { default: Markdown } = require('containers/base/v2/Markdown');

export const selectSavings = (state: RootState) => ({
  hasChanges: selectFormAdjustCalculationHasChanges(state),
  retirementWealth: selectRetirementWealth(state),
});

const Savings: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const { hasChanges, retirementWealth } = useAppSelector(selectSavings, shallowEqual);
  const [isInputValid, setIsInputValid] = useState(true);
  const [updateCalculationProfile] = useUpdateCalculationProfile();

  useInitCalculationProfile();

  const wealthAtPensionAge = parseInt(retirementWealth || 0, 10);

  return (
    <Page
      testID={testIds.ADJUST_CALCULATION_SAVINGS_SCREEN}
      heading={i18n.t('dashboard|savings')}
      actions={[
        {
          testID: testIds.SAVINGS_SAVE_CHANGES_BUTTON,
          text: i18n.t('saveChange'),
          mobilePosition: buttonPositions.bottom,
          inactive: !hasChanges || !isInputValid,
          onPress: () => updateCalculationProfile(),
        },
      ]}
      actionsContent={
        <>
          <Margin marginBottom="md">
            <Body2 secondaryColor fontWeight="regular">
              {i18n.t('dashboard|savingsTitle')}
            </Body2>
          </Margin>
          <Form name="adjustCalculation" resetOnMount>
            <Group name={RETIREMENT}>
              <Input
                testID={testIds.SAVINGS_INPUT}
                useV2
                name="wealth"
                onValidityChange={setIsInputValid}
                keyboardType="numeric"
                validator="currencyAmountWithZero"
                autoCorrect={false}
                placeholder={i18n.t('dashboard|savingsInputPlaceholder')}
                defaultValue={wealthAtPensionAge.toString()}
                after={wealthAtPensionAge ? i18n.t('currency') : ' '}
                marginTop={0}
                optional
              />
            </Group>
          </Form>
          <Margin marginTop="sm">
            <Subtitle secondaryColor>{i18n.t('dashboard|savingsDescription')}</Subtitle>
          </Margin>
        </>
      }
    >
      <Markdown>{i18n.t('dashboard|savingsModalDescription')}</Markdown>
    </Page>
  );
};

export default withScreenContainer({})(Savings);
