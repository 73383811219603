import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import svgs from '@sp/ui/assets/svgs';
import SvgIcon from './SvgIcon';

const Icon = (props) => (
  <SvgIcon
    {...props}
    fill={props.fill || props.theme.COLORS.PRIMARY_TEXT}
    width={props.width || props.theme.DEFAULTS.ICON_HEIGHT}
    height={props.height || props.theme.DEFAULTS.ICON_HEIGHT}
    svgs={svgs}
  />
);

const IconPropTypes = {
  name: PropTypes.string.isRequired,
  testID: PropTypes.string,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const IconShape = PropTypes.shape(IconPropTypes);

Icon.propTypes = {
  ...IconPropTypes,
  ...{
    theme: PropTypes.object.isRequired,
  },
};

Icon.defaultProps = {
  fill: undefined,
  testID: undefined,
  viewBox: '0 0 100 100',
  width: undefined,
  height: undefined,
  style: undefined,
};

export default withTheme(Icon);
