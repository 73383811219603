import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Login from 'areas/auth/Login';
import { selectIsLoggedIn, selectShowLoginOnLogout } from 'selectors/api';

const withLogin = (WrappedComponent) => {
  const WithLogin = (props) => {
    const { loggedIn, showLoginOnLogout } = props;

    if (!loggedIn && showLoginOnLogout) {
      return <Login hasBackButton={false} />;
    }

    if (!loggedIn) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  const mapStateToPros = (state, props) => ({
    ...props,
    loggedIn: selectIsLoggedIn(state),
    showLoginOnLogout: selectShowLoginOnLogout(state),
  });

  WithLogin.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    showLoginOnLogout: PropTypes.bool.isRequired,
  };

  return connect(mapStateToPros)(WithLogin);
};

export default withLogin;
