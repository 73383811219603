import styled from 'styled-components/native';
const { withDefaultTypographyInputProps } = require('@sp/ui/typography/index.shared');

const BodyInput = styled(withDefaultTypographyInputProps)`
  font-size: ${({ theme }) => theme.FONT_SIZES.V2_BODY2.DEFAULT}px;
  overflow: hidden;
`;

BodyInput.defaultProps = {
  fontWeight: 'regular',
};

export default BodyInput;
