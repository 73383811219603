import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const isBottomSeparatorVisible = (disableBottom, items, currentIndex) => {
  const lastItem = items.length === currentIndex + 1;
  const onlyOneItem = items.length === 1;

  if (onlyOneItem && disableBottom) return false;
  if (lastItem && disableBottom) return false;

  return true;
};

const ListWithSeparator = ({ separator: Separator, children, disableTop, disableBottom }) => {
  const items = Array.isArray(children) ? children : [children];
  if (!children) return null;

  return (
    <Fragment>
      {!disableTop && <Separator />}

      {
        // NOTE: [].concat(...items).map is the same as items.flat().map
        [].concat(...items).map((item, index) => (
          <Fragment key={index}>
            {item}
            {isBottomSeparatorVisible(disableBottom, items, index) && <Separator />}
          </Fragment>
        ))
      }
    </Fragment>
  );
};

ListWithSeparator.propTypes = {
  separator: PropTypes.object.isRequired,
  children: PropTypes.node,
  disableTop: PropTypes.bool,
  disableBottom: PropTypes.bool,
};

ListWithSeparator.defaultProps = {
  children: undefined,
  disableTop: false,
  disableBottom: false,
};

export default ListWithSeparator;
