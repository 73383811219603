import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingContext from './LoadingContext';

const ChildLoader = ({ children }) => {
  const { addLoader, removeLoader } = useContext(LoadingContext);

  useEffect(() => {
    const childrenRef = children;
    addLoader(childrenRef);

    return () => {
      removeLoader(childrenRef);
    };
  }, [addLoader, children, removeLoader]);

  return null;
};

ChildLoader.propTypes = {
  children: PropTypes.node,
};

ChildLoader.defaultProps = {
  children: undefined,
};

export default ChildLoader;
