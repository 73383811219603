import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { ModalHeader as UIModalHeader } from '@sp/ui/layout/Header';
import Button from '@sp/ui/layout/Header/Button';
import withTranslation from 'decorators/i18n';
import useLaunchContext from 'hooks/useLaunchContext';

const ModalHeader = ({
  title,
  onClose,
  backButton,
  hasOnCloseButton,
  leftContent,
  i18n,
  animation,
  animateTitle,
  shouldDismissPopupFlow,
}) => {
  const navigation = useNavigation();
  const launchContext = useLaunchContext();
  const handleClose = useCallback(() => {
    if (shouldDismissPopupFlow) {
      launchContext.dismissesFlow();
    }
    if (onClose) {
      onClose();
    } else {
      navigation.goBack();
    }
  }, [navigation, onClose, shouldDismissPopupFlow, launchContext]);

  return (
    <UIModalHeader
      title={title}
      leftContent={leftContent || backButton}
      hasOnCloseButton={hasOnCloseButton}
      rightContent={
        <Button
          icon="close"
          onPress={handleClose}
          testID="modalClose"
          accessibilityLabel={i18n.t('Luk')}
        />
      }
      animation={animation}
      animateTitle={animateTitle}
    />
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  backButton: PropTypes.node,
  leftContent: PropTypes.node,
  hasOnCloseButton: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  animation: PropTypes.object,
  animateTitle: PropTypes.bool,
  shouldDismissPopupFlow: PropTypes.bool,
};

ModalHeader.defaultProps = {
  title: undefined,
  onClose: undefined,
  backButton: undefined,
  leftContent: undefined,
  animation: undefined,
  animateTitle: false,
  hasOnCloseButton: true,
  shouldDismissPopupFlow: true,
};

export default withTranslation()(ModalHeader);
