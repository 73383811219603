import { lazy } from 'react';
import withSuspense from 'decorators/withSuspense';
import * as routeNames from 'constants/routeNames';

import AdjustRetirementAge from 'areas/dashboard/AdjustCalculation/RetirementAge';
import AdjustPublicServices from 'areas/dashboard/AdjustCalculation/PublicServices';
import AdjustSavings from 'areas/dashboard/AdjustCalculation/Savings';
import AdjustTaxation from 'areas/dashboard/AdjustCalculation/Taxation';
import AdjustTaxInfo from 'areas/dashboard/AdjustCalculation/TaxInfo';
import AdjustDisposableIncome from 'areas/dashboard/AdjustCalculation/DisposableIncome';

const LazyChildrenNavigator = lazy(
  () => import('areas/dashboard/AdjustCalculation/Children/ChildrenNavigator')
);
const ChildrenNavigator = withSuspense(LazyChildrenNavigator);

export default {
  [routeNames.ADJUST_RETIREMENT_AGE]: {
    screen: AdjustRetirementAge,
  },
  [routeNames.CHILDREN]: {
    screen: ChildrenNavigator,
  },
  [routeNames.ADJUST_PUBLIC_SERVICES]: {
    screen: AdjustPublicServices,
  },
  [routeNames.ADJUST_DISPOSABLE_INCOME]: {
    screen: AdjustDisposableIncome,
  },
  [routeNames.ADJUST_SAVINGS]: {
    screen: AdjustSavings,
  },
  [routeNames.ADJUST_TAXATION]: {
    screen: AdjustTaxation,
  },
  [routeNames.ADJUST_TAX_INFO]: {
    screen: AdjustTaxInfo,
  },
};
