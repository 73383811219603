import React, { useContext, useEffect } from 'react';
import { cancelAnimation, withSpring, useSharedValue } from 'react-native-reanimated';
import styled, { useTheme } from 'styled-components/native';
import ProgressMenu from './Menu';
import Wizard from './Wizard';
import Actions from './Actions';
import type { ActionProps } from 'typings/global';

const { default: Margin } = require('@sp/ui/base/Margin');
const { default: LoadingContext } = require('@sp/ui/context/LoadingContext');
const { default: PageParts } = require('@sp/ui/PageParts');
const { default: AccessibilitySummary } = require('@sp/ui/v2/pages/BasePage/AccessibilitySummary');
const { groupActions } = require('@sp/ui/v2/pages/BasePage/Actions/index.shared');
const { H3 } = require('@sp/ui/v2/typography');
const { CONTAINER_WIDTHS } = require('@sp/ui/settings');

const FIXED_WRAPPER_Z_INDEX = 9;
const BUTTON_WIDTH = 286;
const HEADER_LOGOUT_HEIGHT = 60;
const HEADER_AND_FOOTER_MENU_HEIGHT = 72;
const CONTENT_WRAPPER_TOP_OFFSET_WITH_MENU = 100;
const CONTENT_WRAPPER_TOP_OFFSET_WITHOUT_MENU = 30;
const TITLE_BORDER_HEIGHT = 2;
const TOTAL_HEADER_HEIGHT = HEADER_LOGOUT_HEIGHT + HEADER_AND_FOOTER_MENU_HEIGHT;
const DESKTOP_WIDTH = CONTAINER_WIDTHS.DESKTOP_WIDE;
const CARD_WIDTH = BUTTON_WIDTH + 48;
const WRAPPER_PADDING = 32;
const CONTENT_PADDING = 32;
const BORDER_SIZE = 2;
const BACK_BUTTON_HEIGHT = 50;
const BACK_BUTTON_OFFSET = 30;
const BACK_BUTTON_MARGIN_TOP = 10;
const ANIMATION_CLOCK_TIMER = 20;
const DEFAULT_SPRING_CONFIG = {
  damping: 15,
  mass: 1,
  stiffness: 120,
  overshootClamping: false,
  restSpeedThreshold: 1,
  restDisplacementThreshold: 1,
};

type CurrentProgressType = {
  step?: any;
  title: string;
  isActive: boolean;
  isSelected: boolean;
  isPrevious: boolean;
  onPress: () => void;
};

export interface MenuPageProps {
  title?: string;
  disableContentTopPadding?: boolean;
  menu?: React.ReactNode;
  footer?: React.ReactNode;
  testID?: string;
  accessibilitySummary?: string;
  actions: ActionProps;
  actionInfo?: string;
  actionsContent?: React.ReactNode;
  hasMenuAnimation?: boolean;
  hasBackButton?: boolean;
  backButton?: React.ReactNode;
  menuHeader?: React.ReactNode;
  wizard?: React.ReactNode[];
  progress?: CurrentProgressType[];
}

const Wrapper = styled.View`
  flex: 1;
  min-height: 100%;
  padding: ${WRAPPER_PADDING}px;
  background: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
`;

const FixedWrapper = styled.View<{ isLocatedTop?: boolean }>`
  position: fixed;
  overflow: hidden;
  width: 100%;
  z-index: ${FIXED_WRAPPER_Z_INDEX};
  ${({ isLocatedTop }) => (isLocatedTop ? 'top: 0px' : 'bottom: 0px')}
`;

const TitleWrapper = styled.View`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
`;

const TitleBorder = styled.View`
  width: 100%;
  height: ${TITLE_BORDER_HEIGHT}px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BORDER};
`;

const LoaderWrapper = styled.View`
  position: relative;
  flex: 1;
  align-items: center;
  padding: 0 ${WRAPPER_PADDING}px;
  margin: 0 -${WRAPPER_PADDING}px; // add negative margin so overlay fills to edges
`;

const Content = styled.View<{ hasFullWidth?: boolean; disableContentTopPadding?: boolean }>`
  flex: 1;
  overflow-y: auto;
  padding: ${({ hasFullWidth }) =>
    hasFullWidth ? `${CONTENT_PADDING}px 0px` : `${CONTENT_PADDING}px`};
  ${({ disableContentTopPadding }) => disableContentTopPadding && `padding-top: 0`};
`;

const ActionCard = styled.View<{ hasFullWidth?: boolean }>`
  z-index: 1;
  background-color: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
  ${({ hasFullWidth, theme }) => {
    if (hasFullWidth) {
      return `
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
      `;
    } else {
      return `
        border-right-width: ${BORDER_SIZE}px;
        border-right-color: ${theme.COLORS.PRIMARY_BORDER};
        width: ${CARD_WIDTH}px;
      `;
    }
  }}
`;

const RowWrapper = styled.View`
  flex-direction: row;
  flex: 1;
`;

const MenuAndContentWrapper = styled.View<{ height: number; hasCustomMenu?: boolean }>`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  max-width: ${DESKTOP_WIDTH}px;
  height: ${({ height }) => height}px;
  top: ${({ hasCustomMenu }) =>
    hasCustomMenu
      ? CONTENT_WRAPPER_TOP_OFFSET_WITHOUT_MENU
      : CONTENT_WRAPPER_TOP_OFFSET_WITH_MENU}px;
`;

const ContentWrapper = styled.View`
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
  margin-bottom: ${({ theme }) => theme.SPACINGS.xxxl}px;
`;

const BackButtonWrapper = styled.View<{ hasBackButton?: boolean }>`
  justify-content: center;
  margin-top: ${BACK_BUTTON_MARGIN_TOP}px;
  height: ${({ hasBackButton }) => (hasBackButton ? BACK_BUTTON_HEIGHT : BACK_BUTTON_OFFSET)}px;
`;

const MenuPage: React.FC<MenuPageProps> = ({
  menu,
  footer,
  testID,
  accessibilitySummary,
  actions,
  actionInfo,
  actionsContent,
  hasBackButton,
  hasMenuAnimation,
  backButton,
  menuHeader,
  progress,
  title,
  wizard,
  children,
  disableContentTopPadding,
}) => {
  const groupedActions = groupActions(actions);

  const { windowDimensions } = useTheme();
  const { loader } = useContext(LoadingContext);

  const windowWidth = useSharedValue(0);
  const menuAnimationClock = useSharedValue(0);

  const { height } = windowDimensions;
  const hasCustomMenu = typeof menu !== 'undefined';
  const extraMenuAndContentWrapperHeightOffset = hasCustomMenu ? 0 : HEADER_AND_FOOTER_MENU_HEIGHT;
  const menuAndContentWrapperHeight =
    height - TOTAL_HEADER_HEIGHT - extraMenuAndContentWrapperHeightOffset;

  useEffect(() => {
    if (windowDimensions.width !== windowWidth.value) {
      cancelAnimation(menuAnimationClock);
      windowWidth.value = windowDimensions.width;
      menuAnimationClock.value = 0;
      menuAnimationClock.value = withSpring(ANIMATION_CLOCK_TIMER, DEFAULT_SPRING_CONFIG);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <PageParts>
      {({ menu: globalMenu, footer: globalFooter, notifications }: Record<string, any>) => (
        <Wrapper testID={testID}>
          <LoaderWrapper>
            {loader}
            <AccessibilitySummary accessibilitySummary={accessibilitySummary}>
              <FixedWrapper isLocatedTop>{hasCustomMenu ? menu : globalMenu}</FixedWrapper>
              <MenuAndContentWrapper
                height={menuAndContentWrapperHeight}
                hasCustomMenu={hasCustomMenu}
              >
                <BackButtonWrapper hasBackButton={hasBackButton}>
                  {hasBackButton ? backButton : null}
                </BackButtonWrapper>
                <RowWrapper>
                  {progress && (
                    <>
                      <ProgressMenu
                        menuAnimationClock={menuAnimationClock}
                        hasAnimation={hasMenuAnimation}
                        progress={progress}
                        menuHeader={menuHeader}
                      />
                      <Margin marginLeft="xxl" />
                    </>
                  )}
                  <ContentWrapper>
                    {title && (
                      <TitleWrapper>
                        <H3 fontWeight="semibold">{title}</H3>
                        <Margin marginTop="lg">
                          {wizard && <Wizard content={wizard} />}
                          <TitleBorder />
                        </Margin>
                      </TitleWrapper>
                    )}
                    <Content hasFullWidth disableContentTopPadding={disableContentTopPadding}>
                      {children}
                      {actionsContent}
                    </Content>
                    <ActionCard hasFullWidth>
                      <Actions
                        horizontalMode
                        transparent
                        actions={groupedActions.bottom}
                        actionInfo={actionInfo}
                      />
                    </ActionCard>
                  </ContentWrapper>
                </RowWrapper>
              </MenuAndContentWrapper>
              <FixedWrapper>
                {typeof footer !== 'undefined' ? footer : globalFooter}
                {notifications}
              </FixedWrapper>
            </AccessibilitySummary>
          </LoaderWrapper>
        </Wrapper>
      )}
    </PageParts>
  );
};

export default MenuPage;
