export const selectNotificationSettingsForType = (state, type) => {
  const { settings } = state.areas.settings.notificationSettings;

  return settings.filter((s) => s.key.startsWith(type));
};

export const selectNotificationSettingsCompanyGroups = (state) =>
  state.areas.settings.notificationSettings.companyGroups;

export const selectContents = (state) => state.areas.settings.contents;
