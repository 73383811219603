import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { CHILDREN } from 'constants/routeNames';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import { selectChildren } from 'selectors/children';
import { RootState } from 'store';
import AdjustCalculationRow from './AdjustCalculationRow';
import { ADJUST_CALCULATION_CHILDREN_ROW } from 'constants/testIds/dashboard/adjustCalculation';

const { default: Icon } = require('@sp/ui/base/Icon');
const { Body2 } = require('@sp/ui/v2/typography');

const ChildrenRow: React.FC = () => {
  const { COLORS } = useTheme();
  const navigation = useNavigation();
  const numberOfChildren = useAppSelector((state: RootState) => selectChildren(state)?.length || 0);
  const { i18n } = useI18n(['dashboard']);

  const getTextRight = useCallback(() => {
    switch (true) {
      case numberOfChildren === 1:
        return i18n.t('dashboard|childCount', { count: numberOfChildren });
      case numberOfChildren > 1:
        return i18n.t('dashboard|childrenCount', { count: numberOfChildren });
      default:
        return i18n.t('none');
    }
  }, [numberOfChildren, i18n]);

  const textRight = getTextRight();

  return (
    <AdjustCalculationRow
      testID={ADJUST_CALCULATION_CHILDREN_ROW}
      componentLeft={<Icon name="children" fill={COLORS.V2_PRIMARY_BACKGROUND} />}
      textLeft={i18n.t('dashboard|children')}
      textRight={
        <Body2 fontWeight="normal" color={COLORS.V2_SECONDARY_TEXT}>
          {textRight}
        </Body2>
      }
      onPress={() => navigation.navigate(CHILDREN)}
    />
  );
};

export default ChildrenRow;
