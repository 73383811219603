import { create } from '../../helpers/reducers';

const createDefaults = () => ({
  isOnline: true,
});

type State = ReturnType<typeof createDefaults>;

const notificationReducer = create(
  {
    CONNECTION_CHANGE: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  createDefaults
);

export default notificationReducer;

export type { State };
