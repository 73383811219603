import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import * as routeNames from 'constants/routeNames';

import YourProfile from './YourProfile';
import Email from './Email';
import PhoneNumber from './PhoneNumber';
import Address from './Address';

const Stack = createStackNavigator();

export default () => (
  <Stack.Navigator
    initialRouteName={routeNames.PROFILE_OVERVIEW}
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen name={routeNames.PROFILE_OVERVIEW} component={YourProfile} />
    <Stack.Screen name={routeNames.PROFILE_EMAIL} component={Email} />
    <Stack.Screen name={routeNames.PROFILE_TELEPHONE} component={PhoneNumber} />
    <Stack.Screen name={routeNames.PROFILE_ADDRESS} component={Address} />
  </Stack.Navigator>
);
