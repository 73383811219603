import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled, { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import GlobalNotificationContext from 'context/GlobalNotificationContext';
import InputPage from '@sp/ui/pages/InputPage';
import { TextInput } from '@sp/ui/base/inputs';
import Alert from '@sp/ui/base/inputs/Alert';
import Icon from '@sp/ui/base/Icon';
import {
  getContactInfo,
  saveEmail,
  getPendingVerifications as getPendingVerificationsAction,
  verifyContactInformation,
  validateContactInformationToken,
  deletePendingVerifications as deletePendingVerificationsAction,
} from 'actions/profile';
import {
  clearNotificationSettings,
  navigateToNotificationSettings as navigateToNotificationSettingsAction,
} from 'actions/settings';
import { selectContactInfo, selectPendingVerifications } from 'selectors/profile';
import Margin from '@sp/ui/base/Margin';
import { Subtitle } from '@sp/ui/typography';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { initFunc } from 'helpers/props';
import * as routeNames from 'constants/routeNames';
import { getDeleteAction, handleValidationErrors } from './helpers';
import { reducer } from './emailPhoneReducer';
import Tracking from 'services/tracking';
import { EMAIL } from 'areas/profile/trackingIds';

const ContentDesktop = styled.View`
  margin: 0 ${({ theme }) => theme.SPACINGS.lg}px;
  align-items: center;
`;

const Content = Conditional({
  mobile: Margin,
  default: ContentDesktop,
});

const Email = ({
  email,
  removeUserEmail,
  isDesktop,
  isModal,
  onSave,
  navigation,
  theme,
  getPendingVerifications,
  pendingVerification,
  requestValidation,
  deletePendingVerifications,
  navigateToNotificationSettings,
  i18n,
}) => {
  const { showNotification } = useContext(GlobalNotificationContext);
  const isVerified = !pendingVerification;
  const pendingEmail = !isVerified && pendingVerification?.value;

  const [state, dispatch] = useReducer(reducer, {
    verifiedValue: email,
    pendingValue: pendingEmail,
    formValue: pendingEmail || email,
    isFormValueValid: true,
  });

  useEffect(() => {
    dispatch({
      type: 'SET_FORM_VALUE',
      value: pendingEmail || email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'VERIFY_VALUE',
      pendingValue: pendingEmail,
      value: email,
    });
  }, [pendingEmail, email]);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  useEffect(() => {
    if (deleteModalVisible) {
      Keyboard.dismiss();
    }
  }, [deleteModalVisible]);

  return (
    <ModalProvider isModal={isModal} onClose={() => navigation.goBack()}>
      <InputPage
        heading={i18n.t('E-mail')}
        description={i18n.t(
          'Hvis vi kender din e-mail, kan vi nemmere komme i kontakt med dig, hvis der er behov for ændringer i din pensionsordning.'
        )}
        hasBackButton={isDesktop || !isModal}
        actions={[
          ...getDeleteAction(
            i18n.t('Slet e-mail fra min profil'),
            email,
            pendingEmail,
            setDeleteModalVisible
          ),
          state.button === 'NavigateToNotificationSettings' && {
            text: i18n.t('Se dine kontaktindstillinger'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: navigateToNotificationSettings,
          },
          state.button === 'SaveRequestVerification' && {
            text: i18n.t('Gem'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: async () => {
              if (onSave) onSave();
              await requestValidation(state.formValue);
              await getPendingVerifications();

              navigation.navigate(routeNames.PROFILE_VERIFY_EMAIL_MODAL, {
                email: state.formValue,
              });

              showNotification(
                i18n.t('Kode er sendt til {{email}}. Den gælder i 24 timer.', {
                  email: state.formValue,
                })
              );
            },
          },
          state.button === 'SaveCancelVerification' && {
            text: i18n.t('Gem'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: async () => {
              if (onSave) onSave();
              await deletePendingVerifications();
              await getPendingVerifications();
              navigation.goBack();
            },
          },
          state.button === 'Pending' && {
            text: i18n.t('Verificer e-mail'),
            mobilePosition: 'bottom-sticky',
            inactive: state.isFormEmpty || !state.isFormValueValid,
            onPress: async () => {
              navigation.navigate(routeNames.PROFILE_VERIFY_EMAIL_MODAL, {
                email: state.formValue,
              });
              showNotification(
                i18n.t(
                  'Kode blev sendt til {{email}}, da du indtastede e-mailen. Hvis det er mere end 24 timer siden, skal du bede om en ny.',
                  { email: state.formValue }
                ),
                { autoHideAfter: 12000 }
              );
            },
          },
        ].filter(Boolean)}
      >
        <Content marginLeft="md" marginRight="md">
          <TextInput
            after={
              state.inputIcon && (
                <Icon
                  name={state.inputIcon === 'verified' ? 'rowCheckmarkFilled' : 'rowErrorFilled'}
                  fill={
                    state.inputIcon === 'verified'
                      ? theme.COLORS.PRIMARY_SUCCESS
                      : theme.COLORS.PRIMARY_ALERT
                  }
                />
              )
            }
            autoCapitalize="none"
            before={<Icon name="email" fill={theme.COLORS.PRIMARY} />}
            autoCorrect={false}
            keyboardType="email-address"
            textContentType="emailAddress"
            placeholder={i18n.t('Email')}
            onChangeText={(value) =>
              dispatch({
                type: 'SET_FORM_VALUE',
                value,
              })
            }
            validator="email"
            onValidityChange={(value) =>
              dispatch({
                type: 'SET_FORM_VALIDITIY',
                value,
              })
            }
            value={state.formValue}
            marginTop={0}
            optional
          />
          <Margin marginTop="md" />
          {(!isVerified || !email) && (
            <Subtitle>
              {i18n.t(
                'Vi sender en kode til den angivne e-mailadresse.\n\nDu vil blive bedt om at indtaste koden for at verificere at denne e-mail tilhører dig.'
              )}
            </Subtitle>
          )}
          <Alert
            onRequestClose={() => setDeleteModalVisible(false)}
            visible={deleteModalVisible}
            title={i18n.t('Slet email')}
            description={i18n.t('Er du sikker på at du vil slette din email fra din profil?')}
            actions={[
              {
                text: i18n.t('Ja'),
                style: 'destructive',
                onPress: removeUserEmail,
              },
              {
                text: i18n.t('Nej'),
                style: 'secondary',
                onPress: () => setDeleteModalVisible(false),
              },
            ]}
          />
        </Content>
      </InputPage>
    </ModalProvider>
  );
};

Email.propTypes = {
  removeUserEmail: initFunc,
  email: PropTypes.string,
  isDesktop: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  onSave: initFunc,
  theme: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  pendingVerification: PropTypes.object,
  deletePendingVerifications: initFunc,
  getPendingVerifications: initFunc,
  requestValidation: initFunc,
  navigateToNotificationSettings: initFunc,
  i18n: PropTypes.object.isRequired,
};

Email.defaultProps = {
  email: undefined,
  removeUserEmail: undefined,
  onSave: undefined,
  requestValidation: undefined,
  pendingVerification: undefined,
  deletePendingVerifications: undefined,
  getPendingVerifications: undefined,
  navigateToNotificationSettings: undefined,
};

const mapStateToProps = (state, props) => {
  const { email } = selectContactInfo(state);

  return {
    ...props,
    email,
    isDesktop: selectIsDeviceTypeDesktop(state),
    isModal: props.route?.params?.isModal || false,
    onSave: props.route?.params?.onSave,
    pendingVerification: selectPendingVerifications(state, 'email'),
  };
};

const init = ({ action, getProps }) =>
  action(
    async () => {
      const { dispatch, navigation, showError, i18n, route } = getProps();
      const { token } = route?.params || {};
      let showPendingEmailError = true;

      if (token) {
        try {
          await dispatch(validateContactInformationToken({ type: 'email', token }));
        } catch (e) {
          showPendingEmailError = false;
          handleValidationErrors(showError, i18n, e);
        }
      }

      await dispatch(getPendingVerificationsAction());
      await dispatch(getContactInfo());

      const { pendingVerification } = getProps();

      if (pendingVerification && showPendingEmailError) {
        showError(i18n.t('Din email er ikke verificeret.'), { dismissable: false });
      }

      return {
        getPendingVerifications: () =>
          action(async () => dispatch(getPendingVerificationsAction())),
        removeUserEmail: () =>
          action(
            async () => {
              await dispatch(saveEmail(''));
              await dispatch(getContactInfo());

              await dispatch(deletePendingVerificationsAction('email'));
              await dispatch(getPendingVerificationsAction());

              dispatch(clearNotificationSettings('email'));

              Tracking.trackEvent(EMAIL.DELETE);
              navigation.goBack();
            },
            { loader: true }
          ),
        requestValidation: (email) =>
          action(
            async () => {
              await dispatch(verifyContactInformation({ email }));
              Tracking.trackEvent(EMAIL.SAVE);
            },
            { loader: true }
          ),
        deletePendingVerifications: () =>
          action(async () => {
            await dispatch(deletePendingVerificationsAction('email'));
          }),
        navigateToNotificationSettings: () =>
          action(async () => dispatch(navigateToNotificationSettingsAction(true)), {
            loader: true,
          }),
      };
    },
    {
      loader: true,
    }
  );

export default withScreenContainer({
  mapStateToProps,
  init,
})(withTheme(Email));
