import { hasOnetimePayouts, hasOverTimePayouts } from 'areas/dashboard/helpers';
import * as tabNames from '../payouts/constants/tabNames';

export default ({ payouts, area, payoutsRoute, navigation, animationDisabled = false }) => {
  const hasOneTimePayout = hasOnetimePayouts(payouts);
  const hasOverTimePayout = hasOverTimePayouts(payouts, area);

  if (hasOneTimePayout && hasOverTimePayout) {
    navigation.navigate(payoutsRoute, {
      tab: tabNames.PAYOUTS_ALL,
      animationDisabled,
    });
  } else if (hasOneTimePayout) {
    navigation.navigate(payoutsRoute, {
      tab: tabNames.PAYOUTS_ONE_TIME,
      animationDisabled,
    });
  } else {
    navigation.navigate(payoutsRoute, {
      tab: tabNames.PAYOUTS_OVER_TIME,
      animationDisabled,
    });
  }
};
