import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useAppSelector } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import { selectFlowControl } from 'selectors/flowControl';
import { createNavigationObject, getProgressData } from '../helpers';
import type { NavigationRouteTypes } from 'containers/v2/pages/MenuPage';

const { removeEquals } = require('helpers/array');

type UseGetUpdatedCurrentProgress = {
  step: string;
  activeProgressIndexes?: number[] | never[];
};

export const useGetUpdatedCurrentProgress = () => {
  const navigation = useNavigation();
  const i18n = useI18n(['partner']);
  const { currentProgress } = useAppSelector(selectFlowControl, shallowEqual) ?? {};

  const progressData = getProgressData(i18n);
  const currentSelectionIndex = currentProgress?.findIndex(({ isSelected }) => isSelected);

  return useCallback(
    ({ step, activeProgressIndexes }: UseGetUpdatedCurrentProgress) => {
      const currentProgressIndex = progressData.findIndex(
        ({ step: progressStep }) => progressStep === step
      );

      const updatedProgressIndexes = activeProgressIndexes
        ? [...activeProgressIndexes, currentProgressIndex]
        : [currentProgressIndex];

      /* NOTE: This scenario occours when flow control kicks in
      and navigates to an index that is higher than 0. This logic
      ensures that everything below the current progress becomes
      active */
      if (updatedProgressIndexes[0] !== 0) {
        for (let i = 0; i <= currentProgressIndex; i += 1) {
          updatedProgressIndexes.push(i);
        }
      }

      const progressIndexes = removeEquals(updatedProgressIndexes);

      return {
        activeProgressIndexes: progressIndexes,
        currentProgress: progressData.map((progress, i) => ({
          ...progress,
          isActive: progressIndexes.includes(i),
          isPrevious: currentSelectionIndex === i,
          isSelected: progress.step === step,
          onPress: () => {
            const { navigationName, navigationParams } = createNavigationObject(progress.step);
            navigation.navigate(navigationName as NavigationRouteTypes, navigationParams);
          },
        })),
      };
    },

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [currentSelectionIndex, navigation]
  );
};
