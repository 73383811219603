import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import styled, { withTheme } from 'styled-components/native';
import { Form, actions as formActions } from 'react-formed';
import InputPage from '@sp/ui/pages/InputPage';
import Icon from '@sp/ui/base/Icon';
import { Subtitle } from '@sp/ui/typography';
import withComponentContainer from 'decorators/withComponentContainer';
import { getContactInfo } from 'actions/profile';
import Conditional from 'decorators/conditional';
import Input from 'containers/form/Input';
import PhoneInput from 'containers/form/PhoneInput';
import { selectContactInfo } from 'selectors/profile';
import { openLink } from 'helpers/linking';
import { isWeb } from 'helpers/platform';
import { isFormValid, checkEmailValidity, checkPhoneValidity } from './helpers';

// NOTE: "z-index: auto" is needed for button to not overflow selector
const Content = Conditional({
  mobile: styled.View`
    padding: 0px ${({ theme }) => theme.SPACINGS.md}px;
  `,
  default: styled.View`
    align-items: center;
    padding: 0px ${({ theme }) => theme.SPACINGS.xxl}px ${(props) => props.theme.SPACINGS.xl}px;
    ${isWeb ? 'z-index: auto' : ''};
  `,
});

const FootnoteMargin = Conditional({
  desktop: styled.View`
    margin: ${({ theme }) => theme.SPACINGS.md}px;
  `,
  tablet: styled.View`
    margin: ${({ theme }) => theme.SPACINGS.lg}px;
  `,
  mobile: styled.View`
    margin-top: ${({ theme }) => theme.SPACINGS.sm}px;
  `,
});

export const defaultFootnote = (theme, i18n) => (
  <Subtitle>
    <Trans i18nKey="contact_footnote">
      Læs mere om behandling af personoplysninger{' '}
      <Subtitle
        onPress={() => {
          const url = i18n.t('link|processingPersonalDataSP');
          openLink(url, '_blank');
        }}
        color={theme.COLORS.PRIMARY_LINK}
      >
        her
      </Subtitle>
      .
    </Trans>
  </Subtitle>
);

// NOTE: we set country code to +45 for extra safety
const formatPhoneNumberForAPI = (phoneNumber, countryCode = '+45') =>
  phoneNumber && `${countryCode === '+45' ? '' : countryCode}${phoneNumber}`;

const ContactPage = ({
  email,
  phoneNumber,
  countryCode,
  formName,
  theme,
  actionsFn,
  title,
  form,
  heading,
  footnote,
  description,
  testID,
  onSubmit,
  areAllInputsMandatory,
  i18n,
  usesNewFormat,
}) => {
  const [isPhoneInputPristine, setIsPhoneInputPristine] = useState(true);
  const [isEmailInputPristine, setIsEmailInputPristine] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  useEffect(() => {
    setIsEmailValid(checkEmailValidity(areAllInputsMandatory, isEmailInputPristine, email, form));

    setIsPhoneValid(
      checkPhoneValidity(areAllInputsMandatory, isPhoneInputPristine, phoneNumber, form)
    );
  }, [
    form.email,
    form.phone,
    email,
    phoneNumber,
    isEmailInputPristine,
    isPhoneInputPristine,
    areAllInputsMandatory,
    form,
  ]);

  const isFormActive = isFormValid(
    phoneNumber,
    email,
    form,
    isPhoneInputPristine,
    isEmailInputPristine,
    isPhoneValid,
    isEmailValid,
    areAllInputsMandatory
  );

  const getFormValues = () => {
    const formPhoneNumber = formatPhoneNumberForAPI(
      form.phone?.phoneNumber,
      form.phone?.countryCode
    );
    const profilePhoneNumber =
      isPhoneInputPristine && formatPhoneNumberForAPI(phoneNumber, countryCode);

    if (usesNewFormat) {
      return {
        phone: phoneNumber
          ? {
              phone: phoneNumber,
              countryCode,
            }
          : undefined,
        email,
        ...form,
      };
    }

    return {
      email,
      phoneNumber: formPhoneNumber || profilePhoneNumber || undefined,
      ...form,
    };
  };

  const formValues = getFormValues();

  const submit = () => onSubmit(formValues);

  return (
    <InputPage
      textAlign={isWeb ? 'center' : 'left'}
      title={title}
      heading={heading}
      description={description}
      enableOverflow
      testID={testID}
      actions={
        actionsFn &&
        actionsFn({
          isFormInactive: !isFormActive,
          onSendPress: submit,
          formValues,
        })
      }
    >
      <Form name={formName}>
        <Content>
          <PhoneInput
            name="phone"
            defaultValue={phoneNumber}
            defaultCountryCode={countryCode}
            marginTop={0}
            onValidityChange={(phoneValidity) => {
              setIsPhoneValid(phoneValidity);
            }}
            optional={!areAllInputsMandatory}
            testID="contactPagePhoneInput"
            getIsDirty={(value) => {
              setIsPhoneInputPristine(!value);
            }}
          />
          <Input
            name="email"
            defaultValue={email}
            autoCapitalize="none"
            keyboardType="email-address"
            textContentType="emailAddress"
            validator="email"
            marginTop={theme.SPACINGS.md}
            placeholder={i18n.t('common|Email')}
            before={<Icon name="email" fill={theme.COLORS.PRIMARY} />}
            onValidityChange={(emailValidity) => {
              setIsEmailValid(emailValidity);
            }}
            optional={!areAllInputsMandatory}
            testID="contactPageEmailInput"
            getIsDirty={(value) => {
              setIsEmailInputPristine(!value);
            }}
          />
          <FootnoteMargin marginTop="sm">
            <Subtitle>{footnote || defaultFootnote(theme, i18n)}</Subtitle>
          </FootnoteMargin>
        </Content>
      </Form>
    </InputPage>
  );
};

ContactPage.propTypes = {
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  countryCode: PropTypes.string,
  formName: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  actionsFn: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  footnote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  form: PropTypes.object,
  testID: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  areAllInputsMandatory: PropTypes.bool,
  usesNewFormat: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
};

ContactPage.defaultProps = {
  email: undefined,
  phoneNumber: undefined,
  countryCode: undefined,
  description: undefined,
  footnote: undefined,
  form: {},
  testID: undefined,
  areAllInputsMandatory: false,
  usesNewFormat: false,
};

const mapStateToProps = (state, props) => {
  const { email, phoneNumber, countryCode } = selectContactInfo(state);

  return {
    form: state.forms[props.formName],
    email,
    phoneNumber,
    countryCode,
  };
};

const init = ({ action, getProps }) =>
  action(
    async () => {
      const { dispatch, formName } = getProps();
      await dispatch(getContactInfo());
      dispatch(formActions.clear(formName));
    },
    {
      loader: true,
    }
  );

export default withComponentContainer({
  mapStateToProps,
  init,
  usesParentNotifications: true,
})(withTheme(ContactPage));
