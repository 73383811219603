import { CLEAR_PUSH_NOTIFICATION, RECEIVED_PUSH_NOTIFICATION } from 'constants/actionTypes';
import { create } from '../../helpers/reducers';

const createDefaults = () => ({
  notification: undefined as any,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [RECEIVED_PUSH_NOTIFICATION]: (state, payload) => ({
      ...state,
      notification: payload,
    }),
    [CLEAR_PUSH_NOTIFICATION]: (state) => ({
      ...state,
      notification: undefined,
    }),
  },
  createDefaults,
  {
    disableResetOnLogout: true,
  }
);

export type { State };
