import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import { SPACINGS } from '@sp/ui/settings';
import Icon from '@sp/ui/base/Icon';

const ICON_HEIGHT = SPACINGS.m;
const ICON_WIDTH = SPACINGS.m;
const NORMAL_OPACITY = 1;
const REDUCED_OPACITY = 0.3;
const NEGATIVE_MARGIN = -45;
const BROWSER_CONTROL_HEIGHT = 45;
const BROWSER_CONTROL_WIDTH = 100;
const BROWSER_CONTROL_BUTTON_HEIGHT = 100;
const BROWSER_CONTROL_OPACITY = 0.3;
const BROWSER_CONTROL_POSITIONING_TOP = 0;
const BROWSER_CONTROL_CENTER_WIDTH = 180;

const TextWrapper = styled.Text`
  color: ${(props) => props.textColor};
`;

const Wrapper = styled.View`
  position: relative;
  top: ${(props) => props.negativeMargin}px;
`;

const BrowserControlWrapper = styled.View`
  position: absolute;
  top: ${BROWSER_CONTROL_POSITIONING_TOP}px;
  background-color: ${(props) => props.bgColor};
  height: ${BROWSER_CONTROL_HEIGHT}px;
  width: ${BROWSER_CONTROL_WIDTH}%;
  opacity: ${BROWSER_CONTROL_OPACITY};
`;

const BrowserControlContentWrapper = styled.View`
  position: absolute;
  display: flex;
  top: ${BROWSER_CONTROL_POSITIONING_TOP}px;
  flex-direction: row;
  height: ${BROWSER_CONTROL_HEIGHT}px;
  width: ${BROWSER_CONTROL_WIDTH}%;
  justify-content: ${({ hasBrowsingArrows }) => (hasBrowsingArrows ? 'space-between' : 'center')};
  align-items: center;
`;

const BrowserControlCenterWrapper = styled.View`
  width: ${BROWSER_CONTROL_CENTER_WIDTH}px;
  justify-content: center;
  align-items: center;
`;

const BrowserControlButtonWrapper = styled.View`
  display: flex;
  flex: 1;
  height: ${BROWSER_CONTROL_BUTTON_HEIGHT}%;
`;

const BrowserControlRightButtonWrapper = styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  height: ${BROWSER_CONTROL_BUTTON_HEIGHT}%;
`;

const BrowserControlLeftButton = styled.TouchableOpacity`
  justify-content: center;
  align-items: flex-end;
  height: ${BROWSER_CONTROL_BUTTON_HEIGHT}%;
`;

const BrowserControlRightButton = styled.TouchableOpacity`
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  height: ${BROWSER_CONTROL_BUTTON_HEIGHT}%;
`;

const BrowserController = ({
  minBrowsing,
  maxBrowsing,
  goBack,
  goForward,
  browserTitle,
  isFetchingDeposits,
  yearlyDepositOverviewButton,
  hasBrowsingArrows,
  hideBrowserControl,
  theme,
}) => (
  <Wrapper bgColor={theme.COLORS.PRIMARY} negativeMargin={NEGATIVE_MARGIN}>
    <BrowserControlWrapper bgColor={theme.COLORS.PRIMARY} />
    <BrowserControlContentWrapper>
      {hideBrowserControl ? (
        <BrowserControlButtonWrapper>{yearlyDepositOverviewButton}</BrowserControlButtonWrapper>
      ) : (
        <>
          {hasBrowsingArrows && (
            <BrowserControlButtonWrapper>
              <BrowserControlLeftButton
                onPress={goBack}
                disabled={minBrowsing || isFetchingDeposits}
              >
                <Icon
                  name="arrowLeftCentered"
                  height={ICON_HEIGHT}
                  width={ICON_WIDTH}
                  fill={theme.COLORS.SECONDARY_BACKGROUND}
                  style={{ opacity: minBrowsing ? REDUCED_OPACITY : NORMAL_OPACITY }}
                />
              </BrowserControlLeftButton>
            </BrowserControlButtonWrapper>
          )}
          <BrowserControlCenterWrapper hasBrowsingArrows={hasBrowsingArrows}>
            <TextWrapper textColor={theme.COLORS.SECONDARY_BACKGROUND}>{browserTitle}</TextWrapper>
          </BrowserControlCenterWrapper>
          {hasBrowsingArrows && (
            <BrowserControlRightButtonWrapper>
              <BrowserControlRightButton onPress={goForward} disabled={maxBrowsing}>
                <Icon
                  name="arrowRightCentered"
                  height={ICON_HEIGHT}
                  width={ICON_WIDTH}
                  fill={theme.COLORS.SECONDARY_BACKGROUND}
                  style={{ opacity: maxBrowsing ? REDUCED_OPACITY : NORMAL_OPACITY }}
                />
              </BrowserControlRightButton>
              {yearlyDepositOverviewButton}
            </BrowserControlRightButtonWrapper>
          )}
        </>
      )}
    </BrowserControlContentWrapper>
  </Wrapper>
);

BrowserController.propTypes = {
  isFetchingDeposits: PropTypes.bool,
  hasBrowsingArrows: PropTypes.bool,
  hideBrowserControl: PropTypes.bool,
  browserTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minBrowsing: PropTypes.bool.isRequired,
  maxBrowsing: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  yearlyDepositOverviewButton: PropTypes.node,
};

BrowserController.defaultProps = {
  hasBrowsingArrows: false,
  hideBrowserControl: false,
  isFetchingDeposits: false,
  browserTitle: undefined,
  yearlyDepositOverviewButton: null,
};

export default withTheme(BrowserController);
