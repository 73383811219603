import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sp/ui/layout/Modal';
import { FormSection } from '@sp/ui/layout/forms';

const FormSectionWithModal = ({
  children,
  modalContent,
  onInfoPress,
  tracking,
  modalAccessibilityLabel,
  ...props
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  return (
    <>
      <FormSection
        focusable
        {...props}
        onInfoPress={() => {
          if (typeof onInfoPress === 'function') onInfoPress();
          if (tracking) {
            tracking();
          }
          setModalVisible(true);
        }}
      >
        {children}
      </FormSection>
      <Modal
        visible={isModalVisible}
        onRequestClose={() => setModalVisible(false)}
        accessibilityLabel={modalAccessibilityLabel}
      >
        {modalContent}
      </Modal>
    </>
  );
};

FormSectionWithModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  modalContent: PropTypes.node.isRequired,
  onInfoPress: PropTypes.func,
  tracking: PropTypes.func,
  modalAccessibilityLabel: PropTypes.string.isRequired,
};

FormSectionWithModal.defaultProps = {
  children: undefined,
  onInfoPress: undefined,
  tracking: undefined,
};

export default FormSectionWithModal;
