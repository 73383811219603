import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import BasePage, { TopContent, ActionShape, NarrowContent } from '@sp/ui/pages/BasePage';

const Scroll = styled.ScrollView``;

const WideContentWrapper = styled.View`
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

const GraphPage = ({
  title,
  heading,
  description,
  children,
  actions,
  wideContent,
  backButton,
  tabBar,
  hasHeader,
  hasBackButton,
  testID,
  accessibilitySummary,
}) => (
  <BasePage
    title={title}
    actions={actions}
    backButton={backButton}
    hasHeader={hasHeader}
    hasBackButton={hasBackButton}
    testID={testID}
    accessibilitySummary={accessibilitySummary}
  >
    {tabBar}
    <Scroll alwaysBounceVertical={false} testID="graphScroll">
      {(heading || description) && <TopContent heading={heading} description={description} />}
      <WideContentWrapper>{wideContent}</WideContentWrapper>
      <NarrowContent>{children}</NarrowContent>
    </Scroll>
  </BasePage>
);

GraphPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tabBar: PropTypes.node,
  wideContent: PropTypes.node,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  backButton: PropTypes.node,
  hasHeader: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
};

GraphPage.defaultProps = {
  tabBar: undefined,
  title: undefined,
  heading: undefined,
  description: undefined,
  wideContent: undefined,
  children: undefined,
  actions: undefined,
  backButton: undefined,
  hasHeader: true,
  hasBackButton: true,
  testID: undefined,
  accessibilitySummary: undefined,
};

export default GraphPage;
