import React from 'react';
import { useWindowDimensions } from 'react-native';
import {
  DefaultTheme as DefaultThemeNative,
  ThemeProvider as ThemeProviderNative,
} from 'styled-components/native';
import {
  DefaultTheme as DefaultThemeWeb,
  ThemeProvider as ThemeProviderWeb,
} from 'styled-components';
import * as settings from '@sp/ui/settings';

/* NOTE: The reason for wrapping this container with two
styled providers is to enable theme usage for both platforms
as we use both approaches on web. */
const ThemeProviderWrapper: React.FC = ({ children }) => {
  const windowDimensions = useWindowDimensions();
  const themeSettings = { ...settings, windowDimensions };

  return (
    <ThemeProviderNative theme={themeSettings as DefaultThemeNative}>
      <ThemeProviderWeb theme={themeSettings as DefaultThemeWeb}>{children}</ThemeProviderWeb>
    </ThemeProviderNative>
  );
};

export default ThemeProviderWrapper;
