import { createRef } from 'react';
import { Linking } from 'react-native';
import { getPathFromState as origPFS, getStateFromPath as origSFP } from '@react-navigation/native';
import type {
  CommonActions,
  NavigationAction,
  NavigationState,
  ParamListBase,
  PartialState,
} from '@react-navigation/routers';
import type {
  NavigationHelpers,
  NavigationContainerRef,
} from '@react-navigation/core/lib/typescript/src';
import { isWeb } from 'helpers/platform';
import { tabBarRoutes, modalRoutes } from 'areas/main/MainNavigator';
import * as routeNames from 'constants/routeNames';

const { default: log } = require('services/log');
const { default: sessionStorage } = require('services/stores/session');

export const INTERMEDIATE_NAV_STATE = 'INTERMEDIATE_NAV_STATE';

type NavigationStateType = NavigationState | Omit<PartialState<NavigationState>, 'stale'>;
type DispatchActionType = NavigationAction | ((state: NavigationStateType) => NavigationAction);

export const navigationRef = createRef<NavigationContainerRef<ParamListBase>>();

export function navigate(name: string, params: object) {
  if (!navigationRef.current) {
    const msg = 'Navigator ref not set in navigate!';
    log.error(msg, new Error(msg));
  }
  return navigationRef.current?.navigate(name, params);
}

export function dispatch(action: DispatchActionType) {
  if (!navigationRef.current) {
    const msg = 'Navigator ref error in dispatch';
    log.error(msg, new Error(msg));
  }

  return navigationRef.current?.dispatch(action);
}

export function reset(newState: NavigationStateType) {
  if (!navigationRef.current) {
    const msg = 'Navigator ref not set in reset!';
    log.error(msg, new Error(msg));
  }

  return navigationRef.current?.reset(newState);
}

export function dismiss(
  navigation: typeof CommonActions & {
    canGoBack: () => boolean;
    getParent<T = NavigationHelpers<ParamListBase>>(id?: string): T & { pop: () => void };
  }
) {
  return () => {
    if (navigation.canGoBack()) {
      navigation.getParent().pop();
    } else {
      navigation.navigate(routeNames.TABS);
    }
  };
}

export const goBackToDashboardFromNestedStack = (
  navigation: typeof CommonActions & { popToTop: () => void; dismiss: () => void },
  shouldPopToTop = false
) => {
  if (shouldPopToTop) {
    navigation.popToTop();
  } else {
    navigation.dismiss();
  }
  // NOTE: we need to use setImmediate to navigate after a dismiss on web
  // (don't know why - if you figure it out let me know and I will buy you a beer)
  setImmediate(() => navigation.navigate(routeNames.DASHBOARD));
};

export const getInitialUrl = async () => Linking.getInitialURL();

// NOTE: we use this to set an intermediate path before login
export const setIntermediateNavPath = async () => {
  let path = await getInitialUrl();

  if (isWeb && path) {
    path = path.replace(global.location.origin, '');
  }

  if (!path || path.startsWith('/?') || path === '/') {
    return;
  }

  const passThroughRoutes = [
    routeNames.DEEPLINK,
    routeNames.ONBOARDING,
    routeNames.INTRO,
    routeNames.LOGIN,
    routeNames.BIOMETRIC,
  ];

  const cleanPath = path.replace('/', '');

  if (!passThroughRoutes.some((route) => cleanPath.startsWith(route))) {
    await sessionStorage.setItem(INTERMEDIATE_NAV_STATE, path);
  }
};

// NOTE: we use intermediate state to persist path before and after login
export const getIntermediateNavPath = async () => {
  const intermediatePath = await sessionStorage.getItem(INTERMEDIATE_NAV_STATE);
  await sessionStorage.removeItem(INTERMEDIATE_NAV_STATE);
  return intermediatePath;
};

// NOTE: On web the base url is prefixed with / but not on native
const platformPathAdjustment = (routeName: string) => (isWeb ? `/${routeName}` : `${routeName}`);

const shouldRewriteRoute = (routes: string[], path: string) =>
  routes.some((routeName) => path.startsWith(routeName) || path === routeName);

let menuPath = '';

export const setMenuPath = (path: string) => {
  [menuPath] = path.split('?');
};

export const getMenuPath = () => menuPath;

export const getPathFromState = (
  state: NavigationState | Omit<PartialState<NavigationState>, 'stale'>,
  config: any
) => {
  const path = origPFS(state, config);
  return path.replace(`/${routeNames.MAIN}/`, '/').replace(`/${routeNames.TABS}/`, '/');
};

export const getStateFromPathHelpers = (path: string) => {
  const adjustedPathWeb = path.startsWith('/')
    ? path.split('?')[0].split('#')[0].split('/')[1]
    : path.split('?')[0].split('#')[0].split('/')[0];
  const adjustedPath = isWeb ? adjustedPathWeb : path;
  const isLogin = isWeb ? adjustedPath?.startsWith(routeNames.LOGIN) : path === routeNames.LOGIN;
  const isExternalDeeplink = !!global.opener && global.window !== global.opener;
  const derivedPath = path.startsWith('/') ? path : `/${path}`;

  return {
    isLogin,
    isExternalDeeplink,
    derivedPath,
    adjustedPath,
  };
};

export const getStateFromPath = (path: string, config: any) => {
  setMenuPath(path);

  const { isLogin, isExternalDeeplink, derivedPath, adjustedPath } = getStateFromPathHelpers(path);

  if ((Object.values(routeNames) as string[]).includes(adjustedPath)) {
    if (shouldRewriteRoute(Object.keys(tabBarRoutes), adjustedPath)) {
      const pathPrefix = platformPathAdjustment(`${routeNames.MAIN}/${routeNames.TABS}`);
      return origSFP(`${pathPrefix}${derivedPath}`, config);
    }

    if (!isExternalDeeplink && shouldRewriteRoute(Object.keys(modalRoutes), adjustedPath)) {
      const pathPrefix = platformPathAdjustment(routeNames.MAIN);
      return origSFP(`${pathPrefix}${derivedPath}`, config);
    } else if (isExternalDeeplink) {
      /* NOTE: Rewriting URL to match deeplink routes: instead of login.sampension.dk/partner
      it will refer to login.sampension.dk/deeplink/partner when deeplinking from classic */
      if (adjustedPath !== routeNames.DEEPLINK) {
        const pathPrefix = platformPathAdjustment(routeNames.DEEPLINK);
        return origSFP(`${pathPrefix}${derivedPath}`, config);
      }
    }
  } else if (isWeb && adjustedPath && !isLogin) {
    return origSFP(`/${routeNames.NOT_FOUND}`, config);
  }

  return origSFP(path, config);
};
