import React from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { H5 } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import { getAwardRules } from 'actions/digital-advice';
import {
  selectAwardRules,
  selectPayoutsOneTimeAwardRules,
  selectPayoutsOverTimeAwardRules,
  selectAwardRulesDescriptions,
  selectHasDashboardData,
  selectHasOneTimePayout,
  selectAwardRulesDisclaimers,
} from 'selectors/dashboard';
import ListAwardRules from 'areas/dashboard/AwardRules/ListAwardRules';
import { RowBorder } from '@sp/ui/base/rows';
import Markdown from '@sp/ui/base/Markdown';
import Footer from 'areas/dashboard/AwardRules/Footer';
import { hasOnetimePayouts } from 'areas/dashboard/helpers';
import { DEATH, DISABILITY } from '../constants/dashboardTypes';

export const createPage = (payoutType, selectPayouts, getPayoutsAction) => {
  const hasOneTimePayoutAmount = (payouts) => hasOnetimePayouts(payouts);

  const isDisability = payoutType === DISABILITY;
  const isDeath = payoutType === DEATH;

  const AwardRules = ({
    i18n,
    awardRules,
    awardRulesOneTime,
    awardRulesOverTime,
    awardRulesDescriptions,
    payouts,
    hasOneTimePayout,
    disclaimer,
  }) => {
    const title = isDeath
      ? i18n.t('dashboard|awardRulesDeathTitle')
      : i18n.t('dashboard|awardRulesDisabilityTitle');
    const description =
      awardRules && isDisability && awardRulesDescriptions?.disabilityOverviewHeaderText;

    return (
      <ScrollPage title={title} animateTitle>
        {!!description && (
          <Margin horizontal="md">
            <Markdown>{description}</Markdown>
          </Margin>
        )}

        {disclaimer && (
          <Margin horizontal="md">
            <Markdown>{disclaimer}</Markdown>
          </Margin>
        )}
        <RowBorder />
        {(hasOneTimePayoutAmount(payouts) || hasOneTimePayout) && (
          <ListAwardRules
            textLeft={<H5>{i18n.t('dashboard|onetimePayouts')}</H5>}
            awardRules={awardRulesOneTime}
          />
        )}

        {awardRulesOverTime?.length > 0 && (
          <ListAwardRules
            textLeft={<H5>{i18n.t('dashboard|overtimePayouts')}</H5>}
            awardRules={awardRulesOverTime}
          />
        )}

        <Footer payoutType={payoutType} />
      </ScrollPage>
    );
  };

  AwardRules.propTypes = {
    i18n: PropTypes.object.isRequired,
    awardRules: PropTypes.object,
    awardRulesOneTime: PropTypes.array,
    awardRulesOverTime: PropTypes.array,
    hasOneTimePayout: PropTypes.bool,
    awardRulesDescriptions: PropTypes.object.isRequired,
    payouts: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      oneTimePayment: PropTypes.object,
      perYear: PropTypes.array,
    }),
    disclaimer: PropTypes.string,
  };

  AwardRules.defaultProps = {
    awardRules: undefined,
    awardRulesOneTime: [],
    awardRulesOverTime: [],
    hasOneTimePayout: undefined,
    payouts: undefined,
    disclaimer: undefined,
  };

  const mapStateToProps = (state) => ({
    awardRules: selectAwardRules(state),
    awardRulesOneTime: selectPayoutsOneTimeAwardRules(state, payoutType),
    awardRulesOverTime: selectPayoutsOverTimeAwardRules(state, payoutType),
    awardRulesDescriptions: selectAwardRulesDescriptions(state),
    hasDashboardData: selectHasDashboardData(state, payoutType),
    hasOneTimePayout: selectHasOneTimePayout(state, payoutType),
    disclaimer: selectAwardRulesDisclaimers(state, payoutType),
    payouts: selectPayouts(state),
  });

  return withScreenContainer({
    i18ns: ['dashboard'],
    mapStateToProps,
    init: ({ action, getProps }) =>
      action(
        async () => {
          const { dispatch, awardRules, hasDashboardData } = getProps();
          const dispatchArray = [];

          if (!hasDashboardData) dispatchArray.push(getPayoutsAction());

          if (!awardRules) {
            dispatchArray.push(getAwardRules());
          }

          if (dispatchArray.length > 0) {
            await dispatch(dispatchArray);
          }
        },
        {
          loader: true,
        }
      ),
  })(AwardRules);
};
