import { isWeb } from 'helpers/platform';

export const fileLimitValidator = (files, limit) => files.length <= limit;

export const sizeLimitValidator = (files, limit) => {
  const totalSize = files.reduce((acc, file) => acc + (isWeb ? file.blob.size : file.fileSize), 0);

  return totalSize <= limit;
};

export const supportedFileTypesValidator = (files, filetypes) =>
  files.some((file) => filetypes.some((format) => file.filename.toLowerCase().endsWith(format)));
