import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closePopupWebView, openPopupWebView } from 'actions/popup-webview';
import { RootState } from 'store';

const usePopupWebView = () => {
  const dispatch = useDispatch();
  const { url } = useSelector((state: RootState) => state.areas.popupWebView);
  const close = useCallback(() => {
    dispatch(closePopupWebView());
  }, [dispatch]);

  const open = useCallback(
    (newUrl) => {
      // HACK: currently sampension.dk has some issues if not prefixed with www.
      const fixedUrl = newUrl.replace('https://sampension.dk', 'https://www.sampension.dk');
      dispatch(openPopupWebView(fixedUrl));
    },
    [dispatch]
  );

  return {
    url,
    close,
    open,
  };
};

export default usePopupWebView;
