import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import styled from 'styled-components/native';
import Tracking from 'services/tracking';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { Subtitle } from '@sp/ui/typography';
import { DashboardRow, RowBorder } from '@sp/ui/base/rows';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectConsents } from 'selectors/consents';
import * as routeNames from 'constants/routeNames';
import { ListWithSeparator } from '@sp/ui/base/lists';
import { getConsents } from 'actions/consents';

const MOBILE_HORIZONTAL_MARGIN = 8;
const MOBILE_VERTICAL_MARGIN = 16;

const MobileWrapper = styled.View`
  margin: ${MOBILE_HORIZONTAL_MARGIN}px ${MOBILE_VERTICAL_MARGIN}px;
`;

const VisibilityWrapper = styled.View`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
`;

const SettingsConsents = ({ consents, navigation, route, isDesktop, i18n }) => {
  const description = i18n.t(
    'I oversigten kan du se hvilke samtykker du enten har accepteret eller afslået. Du kan altid ændre tilstanden for de enkelte.'
  );

  /* NOTE: Generic way of handling consents which has a
  business requirement to be hidden */
  const hiddenConsentIndexes = useMemo(
    () =>
      consents.reduce((arr, { key, currentConsent }, i) => {
        if (key.toLowerCase() === 'partner' && currentConsent !== 'granted') {
          arr.push(i);
        }

        return arr;
      }, []),
    [consents]
  );

  return (
    <ScrollPage
      title={i18n.t('Samtykker')}
      heading={isDesktop ? i18n.t('Samtykker') : null}
      description={isDesktop ? description : null}
      disableBottomPadding
      testID="settingsConsentsScreen"
    >
      <ListWithSeparator separator={RowBorder}>
        {consents?.map((consent, i) => (
          <VisibilityWrapper key={consent.key} isHidden={hiddenConsentIndexes.includes(i)}>
            <DashboardRow
              arrow
              textRight={consent.currentConsent === 'granted' ? i18n.t('Til') : i18n.t('Fra')}
              textLeft={consent.alternativeTitle}
              onPress={() => {
                Tracking.trackEvent({ area: route.name, action: `Click${consent.key}` });
                navigation.navigate(routeNames.SETTINGS_TERMS, {
                  consentKey: consent.key,
                  allowEdit: consent.key !== 'GDPR',
                });
              }}
            />
          </VisibilityWrapper>
        ))}
      </ListWithSeparator>

      {!isDesktop && (
        <MobileWrapper>
          <Subtitle>{description}</Subtitle>
        </MobileWrapper>
      )}
    </ScrollPage>
  );
};

SettingsConsents.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  consents: PropTypes.arrayOf(PropTypes.object),
  isDesktop: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
};

SettingsConsents.defaultProps = {
  consents: [],
};

const mapStateToProps = (state) => ({
  consentsStatus: state.services.consent.status,
  consents: selectConsents(state).items,
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { dispatch, consentsStatus } = getProps();

        if (consentsStatus === 'ready') {
          await dispatch(getConsents());
        }
      },
      {
        loader: true,
      }
    ),
})(SettingsConsents);
