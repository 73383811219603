import React from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled, { withTheme } from 'styled-components/native';
import { H6 } from '@sp/ui/typography';

const BUTTON_DESKTOP_WIDTH = 172;
const BORDER_WIDTH = 1;

const WrapperBase = styled.View`
  border: ${BORDER_WIDTH}px solid ${({ theme }) => theme.COLORS.PRIMARY};
  flex-direction: row;
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  overflow: hidden;
`;

const ButtonBase = styled.View`
  flex: 1;
  align-items: center;
  padding: ${(props) => props.theme.SPACINGS.s}px 0;
  ${({ isFirst, theme }) =>
    isFirst
      ? ''
      : `
    border-left-width: 1px;
    border-left-color: ${theme.COLORS.PRIMARY};
    `};
  ${({ isSelected, theme }) =>
    isSelected
      ? `background-color: ${theme.COLORS.PRIMARY};`
      : `background-color: ${theme.COLORS.SECONDARY_BACKGROUND};`};
`;

const View = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonTouchableOpacity = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Button = Conditional({
  mobile: ButtonBase,
  default: styled(ButtonBase)`
    width: ${BUTTON_DESKTOP_WIDTH}px;
    padding: ${(props) => props.theme.SPACINGS.ms}px 0;
  `,
});

const Wrapper = Conditional({
  mobile: WrapperBase,
  default: styled(WrapperBase)`
    flex-basis: auto;
    max-width: ${({ count }) => count * BUTTON_DESKTOP_WIDTH + BORDER_WIDTH * 2}px;
  `,
});

const ButtonGroup = ({ buttons, theme }) => (
  <Wrapper count={buttons.length} accessibilityRole="tablist">
    {buttons.map((button, i) => {
      const TouchableOpacityOrView = button.isSelected === true ? View : ButtonTouchableOpacity;

      return (
        <TouchableOpacityOrView
          focusable
          accessibilityRole="tab"
          key={button.text}
          onPress={button.onPress}
        >
          <Button isFirst={i === 0} isSelected={button.isSelected} testID={button.testID}>
            <H6
              fontWeight="regular"
              color={button.isSelected ? theme.COLORS.SECONDARY_BACKGROUND : theme.COLORS.PRIMARY}
            >
              {button.text}
            </H6>
          </Button>
        </TouchableOpacityOrView>
      );
    })}
  </Wrapper>
);

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      isSelected: PropTypes.bool,
      onPress: PropTypes.func,
      testID: PropTypes.string,
    })
  ).isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ButtonGroup);
