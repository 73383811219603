import sessionStorage from 'services/stores/session';
import persistedStorage from 'services/stores/persisted';
import * as routeNames from 'constants/routeNames';
import { getProfileCompleteness } from 'actions/digital-advice';
import { getProfile, getPendingVerifications } from 'actions/profile';
import Tracking from 'services/tracking';
import log from 'services/log';
import { selectCompanyGroups, selectProfileCompletenessInfo } from 'selectors/profile';
import getCustomerNavParams from './utils/getCustomerNavParams';
import getCompletedNavParams from './utils/getCompletedNavParams';
import runCompanySpecificSetup from './utils/runCompanySpecificSetup';

const doCustomerFlow = async (store) => {
  await store.dispatch([getProfileCompleteness(), getProfile()]);

  Tracking.trackEvent({ area: 'Auth', action: 'LoginSuccess' });

  const state = store.getState();

  const companyGroups = selectCompanyGroups(state);
  const info = selectProfileCompletenessInfo(state);

  const [skippedOnboardingInfo, companyBrandingSetup, hasCompletedOnboarding] = await Promise.all([
    sessionStorage.getItem('skippedOnboardingInfo'),
    persistedStorage.getItem('companyBrandingSetup'),
    persistedStorage.getItem('hasCompletedOnboarding'),
  ]);

  try {
    const companySpecificRoute = await runCompanySpecificSetup(
      companyGroups,
      companyBrandingSetup,
      hasCompletedOnboarding
    );
    if (companySpecificRoute) {
      return companySpecificRoute;
    }
  } catch (e) {
    log.error('Could not set up company branding', e);
  }

  const hasSeenInfoScreens =
    (info.taxStatement || info.taxSkipped) && (info.pensionInfo || info.pensionSkipped);

  if (skippedOnboardingInfo || hasCompletedOnboarding) {
    store.dispatch(getPendingVerifications(true));
    return getCompletedNavParams(state);
  }

  if (!hasSeenInfoScreens) {
    return {
      name: routeNames.ONBOARDING,
      screen: routeNames.INFO,
      params: { hasBackButton: false },
    };
  }

  return getCustomerNavParams(info, state);
};

export default doCustomerFlow;
