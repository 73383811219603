import Conditional from 'decorators/conditional';
import Mobile from './index.mobile';
import Tablet from './index.tablet';
import Desktop from './index.desktop';

import AccessibilitySummary from './AccessibilitySummary';
import Card from './Card';
import WideContent from './WideContent';
import Actions from './Actions';
import { groupActions, ActionShape, extractBottomActions } from './Actions/index.shared';
import Description from './Description';
import TopContent from './TopContent';
import Content from './Content';
import ContentWithIcon from './ContentWithIcon';
import TopGradientContent from './TopGradientContent';
import BackButton from './BackButton';
import SafeAreaWrapper from './SafeAreaWrapper';
import Warning from './Warning';
import * as animations from './animations';

export {
  AccessibilitySummary,
  Card,
  Actions,
  Description,
  TopContent,
  Content,
  ContentWithIcon,
  TopGradientContent,
  BackButton,
  WideContent,
  ActionShape,
  groupActions,
  SafeAreaWrapper,
  Warning,
  extractBottomActions,
  animations,
};

export default Conditional({
  mobile: Mobile,
  tablet: Tablet,
  desktop: Desktop,
});
