import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import { withTheme } from 'styled-components/native';
import FlexPage from '@sp/ui/pages/FlexPage';
import { EmptyFlatList, ListWithSeparator } from '@sp/ui/base/lists';
import Icon from '@sp/ui/base/Icon';
import { getContent as getContentAction } from 'actions/ruleEngine';
import { selectRuleRequest } from 'selectors/api';
import { selectContents } from 'selectors/settings';
import { DashboardRow, RowBorder } from '@sp/ui/base/rows';
import Tracking from 'services/tracking';
import { openLink } from 'helpers/linking';

const Reservations = ({ theme, ruleRequest, contents, i18n, getContent, route }) => {
  useEffect(() => {
    if (getContent && ruleRequest && contents.length === 0) {
      getContent(ruleRequest);
    }

    if (contents?.length === 1) {
      openLink(contents[0]?.text, '_self');
    }
  }, [ruleRequest, contents, getContent]);

  return (
    <FlexPage
      title={i18n.t('common|Forbehold og privatliv')}
      disableBottomPadding
      testID="reservationsScreen"
    >
      {contents?.length === 0 && (
        <EmptyFlatList
          text={i18n.t('Der er ikke fundet nogen forbehold…')}
          isFetching={false}
          icon={<Icon name="paragraph" fill={theme.COLORS.PRIMARY} />}
        />
      )}

      <ListWithSeparator separator={RowBorder}>
        {contents?.map((item, key) => (
          <DashboardRow
            key={item.key}
            textLeft={item.title}
            arrow
            testID={`reservation${key}Link`}
            onPress={() => {
              Tracking.trackEvent(
                {
                  area: route.name,
                  action: 'ClickReservations',
                },
                { link: item.text }
              );
              openLink(item.text);
            }}
          />
        ))}
      </ListWithSeparator>
    </FlexPage>
  );
};

Reservations.propTypes = {
  route: PropTypes.object.isRequired,
  ruleRequest: PropTypes.object,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  getContent: PropTypes.func,
  contents: PropTypes.array,
};

Reservations.defaultProps = {
  ruleRequest: undefined,
  getContent: undefined,
  contents: undefined,
};

const mapStateToProps = (state) => ({
  ruleRequest: selectRuleRequest(state),
  contents: selectContents(state),
  loading: state.areas.documents.loading,
});

const init = ({ action, getProps }) =>
  action(
    async () => {
      const { dispatch } = getProps();

      return {
        getContent: (ruleRequest) =>
          action(async () => {
            await dispatch(getContentAction(ruleRequest));
          }),
      };
    },
    {
      loader: true,
      error: {
        type: 'overlay',
        retry: true,
      },
    }
  );

export default withScreenContainer({
  overlayScreenProps: {
    titleKey: 'Forbehold og privatliv',
  },
  mapStateToProps,
  init,
})(withTheme(Reservations));
