import { get, post } from 'actions/api';
import * as actionTypes from 'constants/actionTypes';

export const updateIncome = (incomeInfo) =>
  post(
    actionTypes.UPDATE_INCOME,
    incomeInfo,
    'api',
    '/digital-advice/v2/profile/tax-statements/disposableincome-options'
  );

export const getIncome = () =>
  get(
    actionTypes.GET_INCOME,
    'api',
    '/digital-advice/v2/profile/tax-statements/disposableincome-options'
  );

export const setShowGrossAmount = (showGrossAmount) =>
  post(
    actionTypes.UPDATE_SHOW_GROSS_INCOME,
    { showGrossAmount },
    'api',
    '/digital-advice/v2/profile/tax-statements/show-gross-amount'
  );

export const createProfileInfo = (profileInfo) =>
  post(actionTypes.CREATE_PROFILE_INFO, profileInfo, 'api', '/digital-advice/v2/profile', {});

export const setNeedsRecalculation = (payload) => ({
  type: 'DASHBOARD_NEEDS_RECALCULATION',
  payload,
});

export const updateNakedDashboardErrors = (errors, area) => ({
  type: 'UPDATE_NAKED_DASHBOARD_ERRORS',
  payload: {
    errors,
    area,
  },
});

// NOTE: Interceptor for refetching naked dashboard errors
export const refetchNakedDashboardErrors = (area) => ({
  type: actionTypes.REFETCH_NAKED_DASHBOARD_ERRORS,
  payload: area,
});

export const refetchDataAfterSkatPI = (config = { profileUpdate: false }) => ({
  type: actionTypes.REFETCH_DATA_AFTER_PI_SKAT,
  payload: config,
});

export const ignoreNakedDashboardError = (errorId) => ({
  type: actionTypes.IGNORE_NAKED_DASHBOARD_ERROR,
  payload: errorId,
});
