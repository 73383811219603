import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { shallowEqual } from 'react-redux';
import * as routeNames from 'constants/routeNames';
import MenuPage from '@sp/ui/v2/pages/MenuPage';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectDeeplink } from 'selectors/deeplink';
import { selectFlowControl, selectShowWarningModalWhenLogout } from 'selectors/flowControl';
import { updateDeeplink } from 'actions/deeplink';
import useI18n from 'hooks/useI18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { isWeb, isAndroid } from 'helpers/platform';
import ContentWrapper from './components/ContentWrapper';
import ContentHeaderMobile from './components/ContentHeaderMobile';
import MenuHeader from './components/MenuHeader';
import { FLOW_EXIT } from 'areas/partner/trackingIds';
import type { ActionProps } from 'typings/global';
import type { RootState } from 'store';
import ThemeProviderWrapper from 'containers/theme/ThemeProviderWrapper';

import type { CurrentProgressType } from 'services/reducers/flowControlReducer';
import { selectUserName } from 'selectors/profile';
import { selectPartnerName } from 'selectors/partner';
const { default: Tracking } = require('services/tracking');
const { default: Menu } = require('containers/navigation/Menu');
const { default: Modal } = require('@sp/ui/v2/layout/Modal');
const { default: Margin } = require('@sp/ui/base/Margin');
const { Body, H3 } = require('@sp/ui/v2/typography');
const { BackButton } = require('@sp/ui/v2/pages/BasePage');
const { logout } = require('actions/security');

// Menupage container V1 is curently used in Partner flow
// TODO: Implement Menupage container V2 to partner flow and delete V1

const selectPage = (state: RootState) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
  deeplink: selectDeeplink(state),
  flowControl: selectFlowControl(state),
  showWarningModalWhenLogout: selectShowWarningModalWhenLogout(state),
  userName: selectUserName(state) || '',
  partnerEnteredName: selectPartnerName(state) || '',
});

export type NavigationRouteTypes =
  | typeof routeNames.PARTNER_CONSENTS
  | typeof routeNames.PARTNER_PENSIONS_INFORMATION
  | typeof routeNames.PARTNER_INCOME_INFORMATION
  | typeof routeNames.PARTNER_RECEIPT
  | typeof routeNames.DEPOSITS_PLACEMENT
  | typeof routeNames.MEETING_PREPARATION_INTRO;

type GoBackNavigationType = (() => void) | undefined;

export type BackNavigationType = NavigationRouteTypes | GoBackNavigationType;

type PageProps = {
  children: React.ReactNode;
  hasBackButton?: boolean;
  hasMenuAnimation?: boolean;
  backButtonRoute?: BackNavigationType;
  title?: string;
  titleContent?: React.ReactNode;
  actions: ActionProps;
  actionsContent?: React.ReactNode;
  showMenuHeader?: boolean;
  menuTitle?: string;
  menuSubtitle?: string;
  progress?: CurrentProgressType[];
  hideLogo?: boolean;
};

const Page: React.FC<PageProps> = ({
  children,
  hasMenuAnimation = false,
  hasBackButton = true,
  backButtonRoute,
  title,
  titleContent,
  actions,
  actionsContent,
  showMenuHeader,
  menuTitle = '',
  menuSubtitle = '',
  progress,
  hideLogo,
}) => {
  const { i18n } = useI18n(['partner']);
  const navigation = useNavigation();
  const {
    isDesktop,
    flowControl,
    deeplink,
    showWarningModalWhenLogout,
    userName,
    partnerEnteredName,
  } = useAppSelector(selectPage, shallowEqual);

  const dispatch = useAppDispatch();

  const { currentProgress } = flowControl ?? {};
  const { isDeeplinkModalOpen, isDeeplink, deeplinkFlow } = deeplink ?? {};
  const isDeeplinkFlowPartner = deeplinkFlow === routeNames.PARTNER;

  const mobileStepTitle = useMemo(
    () => flowControl?.currentProgress?.find(({ isSelected }) => isSelected)?.title,
    [flowControl]
  );

  const name = isDeeplinkFlowPartner ? partnerEnteredName : userName;

  return (
    <MenuPage
      disableTopPadding
      disableBottomPadding
      hasMenuAnimation={hasMenuAnimation}
      hasBackButton={hasBackButton}
      backButton={
        backButtonRoute && (
          <BackButton
            label={i18n.t('common|back')}
            onPress={
              typeof backButtonRoute === 'string'
                ? () => navigation.navigate(backButtonRoute)
                : backButtonRoute
            }
          />
        )
      }
      title={!isDesktop && mobileStepTitle ? mobileStepTitle : title}
      disableContentTopPadding={!!titleContent}
      progress={progress || currentProgress}
      actions={actions.length && actions}
      actionsContent={actionsContent}
      menu={isDeeplink ? <Menu showMenuItems={false} hideLogo={hideLogo} /> : undefined}
      menuHeader={
        showMenuHeader || (isDeeplink && isDeeplinkFlowPartner) ? (
          <MenuHeader name={name} menuTitle={menuTitle} menuSubtitle={menuSubtitle} />
        ) : undefined
      }
    >
      <ContentWrapper>
        {!isDesktop && (progress || currentProgress) && (
          <ContentHeaderMobile
            currentProgress={(progress || currentProgress) as CurrentProgressType[]}
            title={title}
          />
        )}
        {titleContent}
        {children}
      </ContentWrapper>
      {showWarningModalWhenLogout && (
        <ThemeProviderWrapper>
          <Modal
            visible={isDeeplinkModalOpen}
            isDesktop={isWeb}
            onRequestClose={() => dispatch(updateDeeplink({ isDeeplinkModalOpen: false }))}
            actions={[
              {
                text: i18n.t('common|no'),
                onPress: () => dispatch(updateDeeplink({ isDeeplinkModalOpen: false })),
                type: 'secondary',
              },
              {
                text: i18n.t('common|yes'),
                onPress: () => {
                  Tracking.trackEvent(FLOW_EXIT);
                  dispatch(logout(isWeb || isAndroid ? 'hard' : 'soft'));
                },
              },
            ]}
          >
            <Margin marginBottom="md">
              <H3 textAlign="center">{i18n.t('partner|logoutModalTitle')}</H3>
            </Margin>
            <Margin marginBottom="lg">
              <Body textAlign="center">{i18n.t('partner|logoutModalBody')}</Body>
            </Margin>
          </Modal>
        </ThemeProviderWrapper>
      )}
    </MenuPage>
  );
};

export default Page;
