import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import styledWeb from 'styled-components';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import { BodyInput, Body } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import { phoneNumberValidator } from '@sp/ui/helpers/validators';

import {
  DEFAULT_COUNTRY_CODE,
  InputWrapper,
  CountryCode,
  CountrySelect,
  PhoneIcon,
  CountryDialCode,
} from './index.shared';

import countryData from './data';

const DEFAULT_TOP_MARGIN = 24;
const CARET_ICON_SIZE = 7;
const TEXT_INPUT_VERTICAL_OFFSET = 18;

const DROPDOWN_MAX_HEIGHT = 300;
const DROPDOWN_HORIZONTAL_PADDING = 8;
const DROPDOWN_ITEM_VERTICAL_PADDING = 9;
const DROPDOWN_ITEM_HORIZONTAL_PADDING = 15;
const DROPDOWN_SHADOW_LEFT_OFSET = 2;
const DROPDOWN_SHADOW_BOTTOM_OFSET = 10;

const Wrapper = styledWeb.div`
  position: relative;
  z-index: 1;
`;

const FakeOverlay = styledWeb.div`
  ${({ isVisible }) => (isVisible ? 'display: block;' : 'display: none;')}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: auto;
`;

const DropdownContainer = styledWeb.div`
  position: relative;
`;

const Dropdown = styledWeb.div`
  position: absolute;
  width: 100%;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  max-height: ${DROPDOWN_MAX_HEIGHT}px;
  overflow: auto;
  padding: ${DROPDOWN_HORIZONTAL_PADDING}px 0;
  border: 1px solid ${(props) => props.theme.COLORS.PRIMARY_BORDER};
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  box-shadow: 0 ${DROPDOWN_SHADOW_LEFT_OFSET}px ${DROPDOWN_SHADOW_BOTTOM_OFSET}px 0 ${(props) =>
  props.theme.COLORS.SHADOW};
`;

const DropdownItem = styledWeb.button`
  display: block;
  padding: ${DROPDOWN_ITEM_VERTICAL_PADDING}px ${DROPDOWN_ITEM_HORIZONTAL_PADDING}px;
  cursor: pointer;
  width: 100%;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  text-align: left;
  outline-style: none;
  border: none;
  &:focus, &:hover {
    background-color: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  }
`;

const Input = styled(BodyInput)`
  flex: 1;
  color: ${({ hasError, theme }) =>
    hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_TEXT};
  margin: ${TEXT_INPUT_VERTICAL_OFFSET}px 0;
`;

const After = styled.View`
  padding-left: ${({ theme }) => theme.SPACINGS.md}px;
`;

const PhoneInput = ({
  flex,
  marginTop,
  onChangeValue,
  onChangeText,
  defaultValue,
  defaultCountryCode,
  hasError,
  theme,
  onValidityChange,
  optional,
  after,
}) => {
  const [currentlySelected, setCurrentlySelected] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [showError, setShowError] = useState(true);
  const [value, setValue] = useState(defaultValue);

  const selectedCountry =
    currentlySelected || countryData.find((c) => c.dialCode === defaultCountryCode.slice(1));
  const dialCode = `+${selectedCountry.dialCode}`;

  useEffect(() => {
    if (defaultValue) {
      setHasValidationError(!phoneNumberValidator(dialCode, defaultValue));
      onValidityChange(!hasValidationError);
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // TODO: legacy implementation - to be removed in the future
  const onChangeHandler = onChangeValue || onChangeText;

  return (
    <Wrapper>
      <FakeOverlay onClick={() => setIsDropdownOpen(false)} isVisible={isDropdownOpen} />
      <InputWrapper
        flex={flex}
        marginTop={marginTop}
        hasError={(showError && hasValidationError) || hasError}
      >
        <PhoneIcon />
        <CountrySelect onPress={() => setIsDropdownOpen(!isDropdownOpen)}>
          <CountryCode>{selectedCountry.iso2.toUpperCase()}</CountryCode>
          <Icon
            name={isDropdownOpen ? 'caret' : 'caretDown'}
            width={CARET_ICON_SIZE}
            height={CARET_ICON_SIZE}
            fill={theme.COLORS.PRIMARY}
          />
          <CountryDialCode>{dialCode}</CountryDialCode>
        </CountrySelect>
        <Input
          autoFocus={!!currentlySelected}
          key={selectedCountry.dialCode}
          keyboardType="numeric"
          placeholderTextColor={theme.COLORS.GRAPH_INCOME}
          value={value}
          onChangeText={(text) => {
            onChangeHandler({
              phoneNumber: text,
              countryCode: dialCode,
            });

            const isInputValid = phoneNumberValidator(dialCode, text);

            setHasValidationError(!isInputValid);
            setValue(text);

            if (onValidityChange) {
              onValidityChange(isInputValid);
            }

            setShowError(text !== '' || !optional);
          }}
          hasError={(showError && hasValidationError) || hasError}
        />
        {after && <After>{renderStringOrComponent(after, Body)}</After>}
      </InputWrapper>
      <DropdownContainer>
        {isDropdownOpen && (
          <Dropdown>
            {countryData.map((country) => (
              <DropdownItem
                key={country.name}
                onClick={() => {
                  onChangeHandler({
                    phoneNumber: value,
                    countryCode: `+${country.dialCode}`,
                  });

                  setCurrentlySelected(country);
                  setIsDropdownOpen(false);
                }}
              >
                <Body>{country.name}</Body>
                <CountryDialCode>+{country.dialCode}</CountryDialCode>
              </DropdownItem>
            ))}
          </Dropdown>
        )}
      </DropdownContainer>
    </Wrapper>
  );
};

PhoneInput.propTypes = {
  flex: PropTypes.string,
  marginTop: PropTypes.number,
  onChangeValue: PropTypes.func,
  onChangeText: PropTypes.func,
  hasError: PropTypes.bool,
  defaultCountryCode: PropTypes.string,
  theme: PropTypes.object.isRequired,
  onValidityChange: PropTypes.func,
  optional: PropTypes.bool,
  defaultValue: PropTypes.string,
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PhoneInput.defaultProps = {
  flex: undefined,
  marginTop: `${DEFAULT_TOP_MARGIN}px`,
  onChangeValue: undefined,
  onChangeText: undefined,
  hasError: false,
  defaultCountryCode: DEFAULT_COUNTRY_CODE,
  onValidityChange: undefined,
  optional: false,
  defaultValue: undefined,
  after: undefined,
};

export default withTheme(PhoneInput);
