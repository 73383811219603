import getCustomerNavParams from './utils/getCustomerNavParams';

const doInfoFlow = async (store) => {
  const { getState, dispatch } = store;
  const state = getState();

  const { info } = state.areas.profile.profileCompleteness;

  return getCustomerNavParams(info, state, dispatch);
};

export default doInfoFlow;
