/*
  Functions returning a boolean corresponding to the input validity,
  receiving input value as parameter.
*/

export const emailValidator = (text) => {
  const regEx =
    /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@\S+\.\S+[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return text && regEx.test(String(text).toLowerCase());
};

const phoneNumberRegex = /^[0-9-+s()]{6,}$/g;

export const phoneNumberValidator = (dialCode, baseNumber = '') => {
  // Basic check that can be aligned with backend
  const clearValue = baseNumber.replace(/ /g, '');
  if (clearValue.length >= 5 && clearValue.match(phoneNumberRegex)) {
    // NOTE: matches Denmark
    if (dialCode === '+45' && clearValue.length !== 8) {
      return false;
    }
    return true;
  }
  return false;
};

const amountValidatorRegex = /^[1-9][0-9]*$/;

export const currencyAmountValidator = (text) => amountValidatorRegex.test(text);

const amountValidatorWithZeroRegex = /^[0]$|^[1-9][0-9]*$/;

export const currencyAmountWithZeroValidator = (text) => amountValidatorWithZeroRegex.test(text);

export const thirtyTwoLengthValidator = (text) => text.length <= 32 && text.length > 0;

// positive number, excluding beginning with 0, with '.' as thousand separator and ',' for decimals
const numberValidatorWithDecimalsAndThousandsRegex =
  /^[1-9][0-9.]*?(?:\d{1,3}(?:.\d{3})*|\d+)?(?:,\d+?){0,1}$/;

export const numberWithDecimalsAndThousandsValidator = (text) =>
  numberValidatorWithDecimalsAndThousandsRegex.test(text);

const fourDigitsRegex = /^[0-9]{4}$/;

export const fourDigitsValidator = (text) => fourDigitsRegex.test(text);

const decimalNumbersRegex = /^[-]?[0-9]*[,]?[0-9]*$/;
export const decimalNumbersValidator = (text) => decimalNumbersRegex.test(text);
