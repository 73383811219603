import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { H5, Subtitle, Body } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import styled from 'styled-components/native';
import withTranslation from 'decorators/i18n';
import Markdown from '@sp/ui/base/Markdown';

const TitleWrapper = styled.View`
  flex-direction: column;
  justify-content: center;
`;

const Wrapper = styled.View`
  width: 100%;
`;

const BeneficiariesWrapper = styled.View`
  flex-direction: row;
`;

const PayoutCard = ({ awardRule, i18n }) => (
  <Wrapper>
    <Margin horizontal="md" marginTop="md">
      <TitleWrapper>
        <H5>{awardRule && (awardRule.pensionCompanyName || awardRule.name)}</H5>
        {awardRule && awardRule.referenceNumber && (
          <Fragment>
            <Margin marginTop="xs" />
            <Subtitle>
              {i18n.t('dashboard|awardRulesPolicy', {
                referenceNumber: awardRule.referenceNumber,
              })}
            </Subtitle>
            <Margin marginTop="xs" />
          </Fragment>
        )}
        <Subtitle>{awardRule && awardRule.pensionCompanyName && awardRule.name}</Subtitle>
      </TitleWrapper>
      <Margin marginTop="sm">
        <Markdown>{awardRule.awardRuleDescription}</Markdown>
        {awardRule.beneficiaries.length > 0 && (
          <BeneficiariesWrapper>
            <Body>{i18n.t('dashboard|to')}</Body>
            <Margin marginLeft="md">
              {awardRule.beneficiaries.map((beneficiary) => (
                <Body key={beneficiary}>{beneficiary}</Body>
              ))}
            </Margin>
          </BeneficiariesWrapper>
        )}
      </Margin>
    </Margin>
  </Wrapper>
);

PayoutCard.propTypes = {
  i18n: PropTypes.object.isRequired,
  awardRule: PropTypes.object,
};

PayoutCard.defaultProps = {
  awardRule: undefined,
};

export default withTranslation()(PayoutCard);
