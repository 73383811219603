import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import { MobileAndDesktopIconWrapper } from './index.shared';

const Tabs = styled.View`
  background-color: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 0;
`;

const VERTICAL_TAB_PADDING = 20;
const HORIZONTAL_TAB_PADDING = 72;

// NOTE: padding between tabs is not aligned with zeplin, but designers were ok whit that.
const Tab = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ horizontalTabPadding }) =>
      `${VERTICAL_TAB_PADDING}px ${horizontalTabPadding || HORIZONTAL_TAB_PADDING}px;`}
    ${({ backgroundColor, focused, theme }) =>
      `background-color: ${focused ? theme.COLORS.SECONDARY_BACKGROUND : backgroundColor}`};
`;

const TopTabBar = ({ tabs, focusedTab, theme, horizontalTabPadding }) => (
  <Tabs>
    {tabs.map((tab) => {
      const isFocused = tab.id === focusedTab;
      return (
        <Tab
          testID={tab.testID}
          horizontalTabPadding={horizontalTabPadding}
          key={tab.id}
          focused={isFocused}
          onPress={tab.onPress}
          backgroundColor={tab.backgroundColor}
          accessibilityRole="tab"
          accessibilityState={{
            selected: isFocused,
          }}
        >
          {tab.iconName && (
            <MobileAndDesktopIconWrapper>
              <Icon
                name={tab.iconName}
                fill={isFocused ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_TEXT}
              />
            </MobileAndDesktopIconWrapper>
          )}
          <Body color={isFocused ? theme.COLORS.PRIMARY : theme.COLORS.PRIMARY_TEXT}>
            {tab.title}
          </Body>
        </Tab>
      );
    })}
  </Tabs>
);

TopTabBar.defaultProps = {
  horizontalTabPadding: undefined,
};

TopTabBar.propTypes = {
  focusedTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
      iconName: PropTypes.string.isRequired,
    })
  ).isRequired,
  theme: PropTypes.object.isRequired,
  horizontalTabPadding: PropTypes.number,
};

export default withTheme(TopTabBar);
