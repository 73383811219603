import { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppState } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { isWeb } from 'helpers/platform';

import useI18n from 'hooks/useI18n';
import { connectionChange as connectionChangeAction } from 'actions/connectivity';
import GlobalNotificationContext from 'context/GlobalNotificationContext';

const ConnectivityMonitor = ({ isOnline, connectionChange }) => {
  const { i18n } = useI18n();

  let connectionInfo = {};

  const { showError, dismiss } = useContext(GlobalNotificationContext);

  const handleConnectivityChange = (newConnectionInfo) => {
    // NOTE: preventing extra dispatches via a local state
    if (connectionInfo.type !== newConnectionInfo.type) {
      connectionInfo = newConnectionInfo;
      connectionChange(newConnectionInfo);
    }
  };

  const checkConnectivityChange = (nextAppState) => {
    if (nextAppState === 'active') {
      NetInfo.fetch().then((newConnectionInfo) => handleConnectivityChange(newConnectionInfo));
    }
  };

  const handleGoingOfflineOnWeb = () => {
    connectionChange({ type: 'none' });
  };

  const handleGoingOnlineOnWeb = () => {
    connectionChange({ type: 'internet' });
  };

  useEffect(() => {
    let unsubscribe;
    let appStateChangeSubscription;

    if (isWeb) {
      global.window.addEventListener('offline', handleGoingOfflineOnWeb);
      global.window.addEventListener('online', handleGoingOnlineOnWeb);
    } else {
      unsubscribe = NetInfo.addEventListener(handleConnectivityChange);
      appStateChangeSubscription = AppState.addEventListener('change', checkConnectivityChange);
    }

    return () => {
      if (isWeb) {
        global.window.removeEventListener('offline', handleGoingOfflineOnWeb);
        global.window.removeEventListener('online', handleGoingOnlineOnWeb);
      } else {
        if (unsubscribe) unsubscribe();
        appStateChangeSubscription.remove();
      }
    };
  });

  useEffect(() => {
    if (!isOnline) {
      showError(i18n.t('Det ser desværre ud til, at du ikke har forbindelse til internettet.'), {
        id: 'offline-warning',
        autoHideAfter: false,
        dismissable: false,
      });
    } else {
      dismiss('offline-warning');
    }
  }, [dismiss, i18n, isOnline, showError]);

  return null;
};

ConnectivityMonitor.propTypes = {
  connectionChange: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isOnline: state.services.connectivity.isOnline,
});

const mapDispatchToProps = (dispatch) => ({
  connectionChange: (connectionInfo) => dispatch(connectionChangeAction(connectionInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectivityMonitor);
