import { useState } from 'react';
import useAsyncCallback from 'hooks/useAsyncCallback';
import { useAppDispatch } from 'hooks/redux';
import { updatePartnerConnectionFailed } from 'actions/partner';
import { updateFlowControl } from 'actions/flowControl';
import { useLinkPartner } from './useLinkPartner';
import { useFetchAllServices } from './useFetchAllServices';
import { FLOW_CONTROL_PREPARED } from 'constants/flowControl';

export const usePrepareFlow = () => {
  const [isPromiseRunning, setIsPromiseRunning] = useState(false);
  const dispatch = useAppDispatch();
  const linkPartner = useLinkPartner();
  const fetchAllServices = useFetchAllServices();

  return useAsyncCallback(async () => {
    if (!isPromiseRunning) {
      setIsPromiseRunning(true);

      const promise = new Promise(async (resolve, reject) => {
        try {
          await linkPartner();
          resolve(true);
        } catch (e: any) {
          reject(e);
        }
      });

      await promise
        .then(async (res) => {
          if (res) {
            await fetchAllServices();
          }
        })
        .catch((e) => {
          dispatch([
            updatePartnerConnectionFailed(e.response.status),
            updateFlowControl({ status: FLOW_CONTROL_PREPARED }),
          ]);
        });
    }
  }, [isPromiseRunning, dispatch, fetchAllServices, linkPartner]);
};
