import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import features from 'features';
import withStore from 'decorators/store';
import withInit from 'decorators/init';
import Tracking from 'services/tracking';
import log from 'services/log';
import { isImpersonationFlow, clearSessionData } from 'helpers/impersonation';

import { setCurrentScreenSize, featuresLoaded } from 'actions/device';
import { authCheckFinished as authCheckFinishedAction } from 'actions/security';
import { setup as setupApi } from 'actions/api';
import i18n from 'services/i18n';

import App from '../../App';
import { handleCriticalFail } from './index.shared';

const hideWebLoader = () => {
  global.document.getElementById('loader').style.display = 'none';
};

class AppContainer extends Component {
  componentDidMount() {
    Tracking.trackEvent({ area: 'General', action: 'Open' });
  }

  render() {
    const { initialized } = this.props;

    if (!initialized) {
      return null;
    }

    return <App />;
  }
}

AppContainer.propTypes = {
  initialized: PropTypes.bool,
};

AppContainer.defaultProps = {
  initialized: false,
};

const init = withInit(({ action, props }) =>
  action(async () => {
    const { dispatch } = props;

    try {
      await dispatch(setCurrentScreenSize());

      const actualResizeHandler = () => {
        dispatch(setCurrentScreenSize());
      };

      let resizeTimeout;
      function resizeThrottler() {
        // ignore resize events as long as an actualResizeHandler execution is in the queue
        if (!resizeTimeout) {
          resizeTimeout = setTimeout(() => {
            resizeTimeout = null;
            actualResizeHandler();
          }, 300);
        }
      }

      global.window.addEventListener('resize', resizeThrottler, false);

      // NOTE: enabling features
      await features.load().catch((error) => log.error('failed loading features', error));

      dispatch(featuresLoaded());

      await i18n.init();

      // NOTE: setupApi depends on features being loaded
      // we don't want to setup the api here in case a token is injected,
      // instead we do it in the InjectToken screen
      if (isImpersonationFlow()) {
        // in case of impersonation clear all previous data before mounting the app
        clearSessionData();
      } else {
        await dispatch(setupApi());
      }

      await dispatch(authCheckFinishedAction());

      hideWebLoader();

      return {
        initialized: true,
      };
    } catch (e) {
      return handleCriticalFail(e);
    }
  })
);

export default compose(withStore, connect(), init)(AppContainer);
