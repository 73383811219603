import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import fontStyles from '@sp/ui/typography/fontStyles';
import MouseView from '@sp/ui/base/MouseView';

const Text = styled.Text`
  font-size: ${(props) => props.theme.FONT_SIZES.V2_BODY.DEFAULT}px;
  ${fontStyles('normal')};
  ${(props) => `color: ${props.color}`};
`;

const TouchableOpacity = styled.TouchableOpacity`
  width: 100%;
`;

const getStyle = (type, inactive, COLORS) => {
  switch (type) {
    case 'secondary':
      return {
        color: COLORS.SECONDARY_BACKGROUND,
        text: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        borderColor: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        hover: COLORS.PRIMARY_BACKGROUND,
      };
    case 'accessory':
      return {
        color: 'transparent',
        borderColor: 'transparent',
        text: COLORS.PRIMARY_TEXT,
        hover: 'transparent',
      };
    case 'destructive':
      return {
        color: COLORS.SECONDARY_BACKGROUND,
        text: COLORS.PRIMARY_ALERT,
        borderColor: COLORS.SECONDARY_BACKGROUND,
        hover: COLORS.SECONDARY_BACKGROUND,
      };
    case 'destructive-secondary':
      return {
        color: COLORS.SECONDARY_BACKGROUND,
        text: COLORS.PRIMARY_ALERT,
        borderColor: COLORS.PRIMARY_ALERT,
        hover: COLORS.SECONDARY_BACKGROUND,
      };
    case 'primary':
    default:
      return {
        color: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        text: COLORS.SECONDARY_BACKGROUND,
        borderColor: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        hover: COLORS.PRIMARY_HOVER,
      };
  }
};

const ButtonNative = styled.View`
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.SPACINGS.m}px 0;
  background: ${(props) => {
    if (props.hover && !props.inactive) {
      return getStyle(props.type, props.inactive, props.theme.COLORS).hover;
    }
    return getStyle(props.type, props.inactive, props.theme.COLORS).color;
  }};
  border-color: ${(props) => getStyle(props.type, props.inactive, props.theme.COLORS).borderColor};
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  border-width: 1px;
`;

const ButtonContent = styled.View`
  display: flex;
  flex-direction: row;
`;

const Button = ({ onPress, text, type, inactive, narrow, theme, size, testID }) => {
  const [hover, setHover] = useState(false);

  return (
    <MouseView onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <TouchableOpacity
        disabled={inactive}
        onPress={onPress}
        testID={`${testID}${inactive ? '-Inactive' : ''}`}
        accessibilityRole="button"
        accessibilityState={{
          disabled: inactive,
        }}
      >
        <ButtonNative hover={hover} type={type} inactive={inactive} narrow={narrow} size={size}>
          <ButtonContent>
            <Text color={getStyle(type, inactive, theme.COLORS).text}>{text}</Text>
          </ButtonContent>
        </ButtonNative>
      </TouchableOpacity>
    </MouseView>
  );
};

Button.propTypes = {
  onPress: PropTypes.func,
  type: PropTypes.oneOf(['destructive', 'primary', 'secondary', 'destructive-secondary']),
  text: PropTypes.string.isRequired,
  inactive: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),
  theme: PropTypes.object.isRequired,
  narrow: PropTypes.bool,
  testID: PropTypes.string,
};

Button.defaultProps = {
  onPress: () => {},
  type: 'primary',
  inactive: false,
  size: 'normal',
  narrow: false,
  testID: undefined,
};

export default withTheme(Button);
