import { lazy } from 'react';
import withSuspense from 'decorators/withSuspense';
import * as routeNames from 'constants/routeNames';

const LazyPayoutsChildrenNavigator = lazy(
  () => import('areas/dashboard/PayoutsChildren/PayoutsChildrenNavigator')
);
const PayoutsChildrenNavigator = withSuspense(LazyPayoutsChildrenNavigator);

export default {
  [routeNames.PAYOUTS_CHILDREN]: {
    screen: PayoutsChildrenNavigator,
  },
};
