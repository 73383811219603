import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import MenuItem from '@sp/ui/base/MenuItem';
import Margin from '@sp/ui/base/Margin';

const HEIGHT = 72;

const FlexWrapper = styled.View`
  flex-direction: row;
`;

const Wrapper = styled(FlexWrapper)`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  height: ${HEIGHT}px;
  width: 100%;
  justify-content: center;
  padding: 0px ${({ theme }) => theme.SPACINGS.xl}px;
`;

const Content = styled(FlexWrapper)`
  max-width: ${({ theme }) => theme.CONTAINER_WIDTHS.DESKTOP_WIDE}px;
  flex: 1;
  align-items: center;
`;

const Footer = ({ menuItems }) => (
  <Wrapper>
    <Content>
      {menuItems?.map((item) => (
        <Margin marginRight="xxl" key={item.name}>
          <MenuItem
            focused={item.focused}
            name={item.name}
            icon={item.icon}
            onPress={item.onPress}
          />
        </Margin>
      ))}
    </Content>
  </Wrapper>
);

Footer.propTypes = {
  menuItems: PropTypes.any.isRequired,
};

Footer.defaultProps = {};

export default Footer;
