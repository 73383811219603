import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

const CONTENT_WIDTH = 700;

const Wrapper = styled.View`
  width: ${CONTENT_WIDTH}px;
`;

const NarrowContent = ({ children }) => <Wrapper>{children}</Wrapper>;

NarrowContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NarrowContent;
