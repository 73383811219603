import log from 'services/log';
import Sentry from './sentry';
import digTracker from './dig-tracker';
const { default: config } = require('config');
export class Tracker {
  trackingId = undefined;
  context = {};

  setTrackingId(id) {
    this.trackingId = id;
    if (id) {
      digTracker.setTrackingId(id);
      Sentry.configureScope((scope) => {
        scope.setUser({ id });
      });
    }
  }

  setTargetEnv(env) {
    digTracker.targetEnv = env;
    Sentry.configureScope((scope) => {
      scope.setTag('targetEnv', env);
    });

    switch (env) {
      case 'test':
      case 'dev':
      case 'preview':
        digTracker.url = 'https://api-test.sampension.dk/tracking/v1/track';
        break;
      case 'production':
      case 'staging':
        digTracker.url = 'https://api.sampension.dk/tracking/v1/track';
        break;
      default:
        digTracker.url = 'https://api.sampension.dk/tracking/v1/track';
        break;
    }
  }

  setContext(options = {}) {
    this.context = {
      ...this.context,
      ...options,
    };
  }

  trackScreen(name = '', options = {}) {
    const { category = 'screen' } = options;
    if (!__DEV__) {
      digTracker.trackScreenView(name);
      Sentry.addBreadcrumb({
        message: name,
        category,
        data: {
          name,
          ...options,
        },
      });
    }
  }

  trackEvent({ area, action, options = {}, sendInfo = false }, params) {
    if (!__DEV__) {
      const { category = 'general' } = options;

      digTracker.trackEventView(area, action, params);

      const name = `${area}.${action}`;

      Sentry.addBreadcrumb({
        message: name,
        category,
        data: {
          name,
          ...options,
        },
      });

      if (sendInfo) {
        Sentry.captureMessage(action, 'info');
      }
    }
  }

  trackApiFailure(method, url, status) {
    if (!__DEV__) {
      Sentry.addBreadcrumb({
        category: 'api',
        message: `API error: ${method},${url} responded with ${status}`,
        level: 'error',
      });
    }
  }

  trackError(error = new Error('Error not provided'), options = {}) {
    const { fingerprint, level } = options;

    if (error.alreadyLogged) {
      return;
    }

    error.alreadyLogged = true;

    if (!__DEV__) {
      try {
        digTracker.trackError(error.toString());
        Sentry.captureException(error, {
          fingerprint,
          level,
        });
      } catch (err) {
        log.info('Could not track error', err);
      }
    }
  }
}

export default new Tracker();
