import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import * as routeNames from 'constants/routeNames';

import Overview from './Overview';
import Details from './Details';
import Return from './Return';
import Costs from './Costs';
import Payments from './Payments';

const routerFactory = [
  {
    name: routeNames.SAVINGS_OVERVIEW,
    component: Overview,
  },
  {
    name: routeNames.SAVINGS_DETAILS,
    component: Details,
  },
  {
    name: routeNames.SAVINGS_RETURNS,
    component: Return,
  },
  {
    name: routeNames.SAVINGS_COSTS,
    component: Costs,
  },
  {
    name: routeNames.SAVINGS_PAYMENTS,
    component: Payments,
  },
];

const Stack = createStackNavigator();

export default () => (
  <Stack.Navigator
    initialRouteName={routeNames.SAVINGS_OVERVIEW}
    screenOptions={{
      headerShown: false,
    }}
  >
    {routerFactory.map(({ name, component }) => (
      <Stack.Screen key={name} name={name} component={component} />
    ))}
  </Stack.Navigator>
);
