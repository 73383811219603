import React from 'react';
import useI18n from 'hooks/useI18n';
import styled from 'styled-components/native';
import { Trans } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import FormSectionWithModal from '@sp/ui/v2/layout/Forms/FormSectionWithModal';
import { selectResidencyYears, selectResidencyYearsHack } from 'selectors/dashboardForms';
import PointerEventsNoneWrapper from '../components/PointerEventsNoneWrapper';
import { RootState } from 'store';
import { RETIREMENT } from 'areas/dashboard/constants/dashboardTypes';

const { default: Input } = require('containers/form/Input');
const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Link } = require('@sp/ui/v2/base/Link');
const { Body, Body2, H3 } = require('@sp/ui/v2/typography');
const { Form, Group } = require('react-formed');
const { selectIsDeviceTypeDesktop } = require('selectors/device');
const { openLink } = require('helpers/linking');

type Props = {
  title: string;
};

const RowWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const selectLivedAbroad = (state: RootState) => ({
  residencyYears: selectResidencyYears(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
  residencyYearsHack: selectResidencyYearsHack(state),
});

const LivedAbroad: React.FC<Props> = ({ title }) => {
  const { i18n } = useI18n(['dashboard', 'link']);
  const { residencyYears, residencyYearsHack, isDesktop } = useAppSelector(
    selectLivedAbroad,
    shallowEqual
  );

  return (
    <FormSectionWithModal
      disablePadding
      title={
        <Body2 secondaryColor textAlign="center">
          {title}
        </Body2>
      }
      description={
        <Body2 secondaryColor fontWeight="regular">
          {i18n.t('dashboard|broekPensionModalTitle')}
        </Body2>
      }
      infoAccessibilityLabel={i18n.t('dashboard|broekPensionModalInfoA11y')}
      modalContent={
        <>
          <H3 textAlign="center">{i18n.t('dashboard|broekPension')}</H3>
          <Margin marginTop="lg" />
          <RowWrapper>
            <Trans
              i18nKey="dashboard|broekPensionModalDescription"
              components={{
                text: <Body />,
                linkText: <Body />,
                linkButton: (
                  <Link isDesktop={isDesktop} onPress={() => openLink(i18n.t('link|borger'))} />
                ),
              }}
            />
          </RowWrapper>
        </>
      }
    >
      <Margin marginTop="md">
        <PointerEventsNoneWrapper>
          <Body2 secondaryColor fontWeight="regular">
            {i18n.t('dashboard|broekPensionDescription')}
          </Body2>
        </PointerEventsNoneWrapper>
        <Margin marginBottom="s">
          <Form name="adjustCalculation">
            <Group name={RETIREMENT}>
              <Input
                useV2
                key={residencyYearsHack}
                name="residencyYears"
                keyboardType="numeric"
                validator="currencyAmountWithZero"
                autoCorrect={false}
                placeholder={i18n.t('dashboard|numberOfYearPlaceholder')}
                defaultValue={residencyYears || ''}
              />
            </Group>
          </Form>
        </Margin>
      </Margin>
    </FormSectionWithModal>
  );
};

export default LivedAbroad;
