// NOTE: for now push notifications on web are not supported
export const setupTokenListenerAndroid = () => {};

export const setup = () => {};

export const setupListener = () => {};

export const tearDown = () => {};

export const setBadgeCount = () => {};

export const getToken = () => undefined;

export const getInitialNotification = () => {};
