import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dimensions } from 'react-native';
import { recalculateBars } from './helpers';
import Arrows from './Arrows';
import barProp from './shapes';

const window = Dimensions.get('window');
const AVERAGE_LABEL_WIDTH = 130;

const Graph = ({
  bars,
  barWidth,
  barOuterWidth,
  withScroll,
  height,
  lineStep,
  labelHeight,
  labelWidth,
  labelsAlignedInTop,
  descriptionHeight,
  centerContent,
  paddingLeftAndRight,
  maxWidth,
  withSelectedBorder,
  selectedIndex,
  maxBarOuterWidth,
  getRef,
  showControls,
  nextBarAccessibilityLabel,
  prevBarAccessibilityLabel,
  showAverageModal,
  maxValue,
  averageBorder,
}) => {
  const [dimensions, setDimensions] = useState({ window });

  const onChange = ({ window: windowArg }) => {
    setDimensions({ window: windowArg });
  };

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', onChange);
    return () => {
      subscription.remove();
    };
  }, []);

  const width = Math.min(maxWidth, dimensions.window.width) - paddingLeftAndRight;

  let calculatedBarOuterWidth = maxBarOuterWidth ? Math.round(width / bars.length) : barOuterWidth;

  if (calculatedBarOuterWidth < labelWidth) {
    calculatedBarOuterWidth = labelWidth;
  }

  const graphHeight = height - descriptionHeight - labelHeight;
  const averageLabelWidth = averageBorder?.value && averageBorder?.label ? AVERAGE_LABEL_WIDTH : 0;
  const { recalculatedBars, ratio } = recalculateBars(
    bars,
    graphHeight,
    averageBorder?.value && averageBorder?.label,
    averageLabelWidth,
    maxValue
  );

  let graphTotalWidth = 0;
  if (withScroll) {
    // we need to calculate total graph with to see if scroll is enabled.
    graphTotalWidth = averageLabelWidth;
    bars.forEach((bar) => {
      graphTotalWidth += Math.max(bar.outerWidth || 0, calculatedBarOuterWidth);
    });
  }

  return (
    <Arrows
      bars={recalculatedBars}
      barWidth={barWidth}
      barOuterWidth={calculatedBarOuterWidth}
      withScroll={withScroll && graphTotalWidth > width}
      height={height}
      lineStep={lineStep}
      labelHeight={labelHeight}
      labelWidth={labelWidth}
      labelsAlignedInTop={labelsAlignedInTop}
      descriptionHeight={descriptionHeight}
      centerContent={centerContent}
      withSelectedBorder={withSelectedBorder}
      selectedIndex={selectedIndex}
      getRef={getRef}
      width={width}
      graphTotalWidth={graphTotalWidth}
      ratio={ratio}
      showControls={showControls}
      nextBarAccessibilityLabel={nextBarAccessibilityLabel}
      prevBarAccessibilityLabel={prevBarAccessibilityLabel}
      showAverageModal={showAverageModal}
      averageBorder={averageBorder}
    />
  );
};

Graph.defaultProps = {
  bars: [],
  barWidth: 60,
  barOuterWidth: 0,
  maxBarOuterWidth: false,
  withScroll: false,
  height: 400,
  lineStep: undefined,
  labelWidth: undefined,
  labelHeight: 30,
  labelsAlignedInTop: false,
  descriptionHeight: 50,
  centerContent: false,
  paddingLeftAndRight: 0,
  maxWidth: 0,
  withSelectedBorder: false,
  selectedIndex: undefined,
  getRef: () => {},
  showControls: false,
  nextBarAccessibilityLabel: undefined,
  prevBarAccessibilityLabel: undefined,
  showAverageModal: undefined,
  maxValue: undefined,
  averageBorder: undefined,
};

Graph.propTypes = {
  bars: PropTypes.arrayOf(barProp),
  barWidth: PropTypes.number,
  barOuterWidth: PropTypes.number,
  maxBarOuterWidth: PropTypes.bool,
  withScroll: PropTypes.bool,
  height: PropTypes.number,
  lineStep: PropTypes.number,
  labelWidth: PropTypes.number,
  labelHeight: PropTypes.number,
  labelsAlignedInTop: PropTypes.bool,
  descriptionHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  paddingLeftAndRight: PropTypes.number,
  centerContent: PropTypes.bool,
  withSelectedBorder: PropTypes.bool,
  selectedIndex: PropTypes.number,
  getRef: PropTypes.func,
  showControls: PropTypes.bool,
  nextBarAccessibilityLabel: PropTypes.string,
  prevBarAccessibilityLabel: PropTypes.string,
  showAverageModal: PropTypes.func,
  maxValue: PropTypes.number,
  averageBorder: PropTypes.object,
};

export default Graph;
