import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Conditional from 'decorators/conditional';
import Loader from '@sp/ui/base/Loader';
import Margin from '@sp/ui/base/Margin';
import { Actions, ActionShape } from '@sp/ui/v2/pages/BasePage';
import { getCurrentDeviceType } from '@sp/ui/helpers/device';
import CloseIcon from './CloseIcon';
import FadeInOut from '@sp/ui/v2/animations/FadeInOut';

const root = global.document.createElement('div');
global.document.body.appendChild(root);

const MODAL_Z_INDEX = 9;
const MODAL_MAX_WIDTH_DESKTOP = 700;
const MODAL_BOTTOM_OFFSET = 80;
const MODAL_TOP_PADDING = 72;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${MODAL_Z_INDEX};
`;

const Overlay = styled.div`
  background-color: ${({ theme }) => theme.COLORS.V2_MODAL_OVERLAY};
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const WrapperDesktop = styled.div`
  position: relative;
  width: 100%;
  max-width: ${MODAL_MAX_WIDTH_DESKTOP}px;
  max-height: calc(100% - ${MODAL_BOTTOM_OFFSET}px);
  background-color: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
  border-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
  min-height: 300px;
  overflow: auto;
`;

const WrapperMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.COLORS.V2_PRIMARY_BACKGROUND};
`;

const Wrapper = Conditional({
  desktop: WrapperDesktop,
  default: WrapperMobile,
});

const Content = Conditional({
  mobile: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    padding-top: ${MODAL_TOP_PADDING}px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
  `,
  default: styled.div`
    padding: ${MODAL_TOP_PADDING}px ${({ theme }) => theme.SPACINGS.xxl}px 0px;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    display: flex;
  `,
});

const ChildContent = Conditional({
  mobile: styled.div`
    padding: 0px ${({ theme, ignorePadding }) => (ignorePadding ? 0 : theme.SPACINGS.md)}px;
  `,
  default: styled.div`
    flex: 1;
  `,
});

const ButtonWrapper = Conditional({
  mobile: styled.div`
    margin-top: auto;
    justify-content: center;
  `,
  default: styled.div`
    padding-bottom: ${({ theme }) => theme.SPACINGS.lg}px;
  `,
});

const CloseButtonDesktop = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  margin: 0;
  padding: ${({ theme }) => theme.SPACINGS.lg}px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const CloseButton = Conditional({
  mobile: styled(CloseButtonDesktop)`
    padding: ${({ theme }) => theme.SPACINGS.md}px;
  `,
  default: CloseButtonDesktop,
});

const Modal = ({
  children,
  visible,
  onRequestClose,
  actions,
  loading,
  theme,
  isDesktop,
  ignorePadding,
}) => {
  const isMobile = getCurrentDeviceType() === 'mobile';
  return ReactDOM.createPortal(
    <FadeInOut visible={visible}>
      <ModalContainer role="alert">
        <Overlay onClick={onRequestClose} />
        <Wrapper>
          <Content>
            <ChildContent ignorePadding={ignorePadding}>{children}</ChildContent>
            {actions && actions.length > 0 && (
              <ButtonWrapper>
                <Actions actions={actions} zIndex={1} transparent={!isMobile} horizontalMode />
              </ButtonWrapper>
            )}

            {!actions && <Margin marginBottom="xxl" />}
          </Content>
          <CloseButton type="button" onClick={onRequestClose}>
            <CloseIcon theme={theme} />
          </CloseButton>
          {loading && (
            <Loader
              blur={false}
              color={isDesktop ? theme.COLORS.V2_SECONDARY_TEXT : theme.COLORS.V2_PRIMARY_TEXT}
            />
          )}
        </Wrapper>
      </ModalContainer>
    </FadeInOut>,
    root
  );
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(ActionShape),
  ignorePadding: PropTypes.bool,
  loading: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

Modal.defaultProps = {
  actions: [],
  loading: false,
  ignorePadding: false,
};

export default withTheme(Modal);
