import React from 'react';
import { Label } from '@sp/ui/typography';
import styled from 'styled-components/native';

const View = styled.View``;

const Wrapper = styled.View`
  flex: 1;
`;

export const createMeasureElement = (element, measure) => {
  if (typeof element === 'string' || Array.isArray(element)) {
    return (
      <Label numberOfLines={1} onLayout={measure}>
        {element}
      </Label>
    );
  }

  if (React.isValidElement(element)) {
    return React.cloneElement(element, {
      numberOfLines: 1,
      onLayout: measure,
    });
  }

  if (typeof element === 'function') {
    return <View onLayout={measure}>{element(false)}</View>;
  }
  throw new Error('unsupported type');
};

export const createViewElement = (element, expanded) => {
  if (typeof element === 'string' || Array.isArray(element)) {
    return <Label numberOfLines={expanded ? 0 : 1}>{element}</Label>;
  }

  if (React.isValidElement(element)) {
    return React.cloneElement(element, {
      numberOfLines: expanded ? 0 : 1,
    });
  }

  if (typeof element === 'function') {
    return <Wrapper>{element(expanded)}</Wrapper>;
  }
  throw new Error('unsupported type');
};
