import type { RootState } from 'store';
import type { CurrentProgressType } from '../services/reducers/flowControlReducer';

export const selectFlowControl = (state: RootState) => state.services?.flowControl;

export const selectShowWarningModalWhenLogout = (state: RootState) => {
  const { currentProgress, showWarningModalWhenLogout } = state.services?.flowControl ?? {};

  if (showWarningModalWhenLogout !== undefined) {
    return showWarningModalWhenLogout;
  }

  // todo use showWarningModalWhenLogout in partner flow
  const activeIndex = currentProgress?.findIndex(
    ({ isSelected }: CurrentProgressType) => isSelected
  );

  if (!currentProgress) {
    return false;
  }

  return activeIndex !== 0 && activeIndex !== currentProgress.length - 1;
};

export const selectIsMeetingPreparationFlow = (state: RootState) =>
  state.services?.flowControl.flow === 'meeting-preparation';
