import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import VersionNumber from 'react-native-version-number';
import { deleteProfile } from 'actions/digital-advice';
import { removePersistence, setupPersistence } from '@sampension/api-middleware';
import { navigateToNotificationSettings as navigateToNotificationSettingsAction } from 'actions/settings';
import { logout as logoutAction } from 'actions/security';
import { getContent } from 'actions/ruleEngine';
import { openLink } from 'helpers/linking';

import { selectContents, selectNotificationSettingsCompanyGroups } from 'selectors/settings';
import {
  selectRuleRequest,
  selectSupportedPersistenceMechanism,
  selectCanPersist,
  selectIsPersisted,
} from 'selectors/api';
import features from 'features';
import Tracking from 'services/tracking';
import log from 'services/log';
import * as routeNames from 'constants/routeNames';
import { Alert } from '@sp/ui/base/inputs';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import Switch from '@sp/ui/base/inputs/Switch';
import { DashboardRow, RowBorder } from '@sp/ui/base/rows';
import { initFunc } from 'helpers/props';
import { isWeb, isAndroid } from 'helpers/platform';

import ChangeLanguage from './ChangeLanguage';

const biometryIcons = {
  ['Face ID']: {
    name: 'faceId',
  },
  ['Touch ID']: {
    name: 'fingerprint',
  },
  PassCode: {
    name: 'password',
  },
};

const Settings = ({
  i18n,
  navigation,
  route,
  removeUser,
  isPersisted,
  persistenceMechanism,
  canPersist,
  navigateToNotificationSettings,
  navigateToReservations,
  persistAuth,
  removeAuthPersistence,
}) => {
  const [isDeleteProfileDialogOpen, setIsDeleteProfileDialogOpen] = useState(false);
  const getBiometricLabel = useCallback(() => {
    switch (persistenceMechanism) {
      case 'Touch ID':
        return isAndroid ? i18n.t('Log på med fingeraftryk') : i18n.t('Log på med Touch ID');
      case 'Face ID':
        return i18n.t('Log på med Face ID');
      default:
        return i18n.t('Log på med adgangskode');
    }
  }, [i18n, persistenceMechanism]);

  return (
    <ScrollPage
      title={i18n.t('Indstillinger')}
      disableBottomPadding
      actions={
        !isWeb && features.isEnabled('deleteProfile')
          ? [
              {
                text: i18n.t('Slet profil'),
                type: 'destructive',
                mobilePosition: 'bottom',
                onPress: () => setIsDeleteProfileDialogOpen(true),
              },
            ]
          : undefined
      }
      testID="profileSettingsScreen"
      animateTitle
    >
      <RowBorder />
      {canPersist && (
        <>
          <DashboardRow
            textLeft={getBiometricLabel()}
            iconLeft={biometryIcons[persistenceMechanism]}
            componentRight={
              <Switch
                value={isPersisted}
                onValueChange={isPersisted ? removeAuthPersistence : persistAuth}
              />
            }
          />
          <RowBorder />
        </>
      )}
      <DashboardRow
        arrow
        textLeft={i18n.t('Kontaktindstillinger')}
        iconLeft="notification"
        onPress={async () => {
          Tracking.trackEvent({ area: route.name, action: 'NotificationsClick' });
          await navigateToNotificationSettings();
        }}
        testID="settingsContactSettingsLink"
      />
      <RowBorder />
      <DashboardRow
        arrow
        textLeft={i18n.t('Samtykker')}
        iconLeft="document"
        onPress={() => {
          Tracking.trackEvent({ area: route.name, action: 'ConsentClick' });
          navigation.navigate(routeNames.SETTINGS_CONSENTS);
        }}
        testID="settingsConsentsLink"
      />
      <RowBorder />
      <DashboardRow
        arrow
        onPress={() => {
          Tracking.trackEvent({ area: route.name, action: 'PrivacyClick' });
          navigateToReservations();
        }}
        textLeft={i18n.t('common|Forbehold og privatliv')}
        iconLeft="document"
      />
      <RowBorder />
      {isWeb && (
        <>
          <DashboardRow
            arrow
            onPress={() => {
              Tracking.trackEvent({ area: route.name, action: 'CookiesClick' });
              openLink(i18n.t('link|cookies'), '_self');
            }}
            textLeft={i18n.t('common|Cookies')}
            iconLeft="document"
          />
          <RowBorder />
        </>
      )}
      {features.isEnabled('translate') && <ChangeLanguage />}
      <>
        <DashboardRow
          textLeft={i18n.t('Versionsnummer')}
          textRight={VersionNumber.appVersion || process.env.VERSION}
        />
        <RowBorder />
      </>
      {!isWeb && (
        <Alert
          onRequestClose={() => setIsDeleteProfileDialogOpen(false)}
          visible={isDeleteProfileDialogOpen}
          title={i18n.t('Slet profil')}
          description={i18n.t('Er du sikker på du vil slette din profil?')}
          cancelable={false}
          actions={[
            {
              text: i18n.t('Annuller'),
              onPress: () => log.info('Annuller Pressed'),
              style: 'primary',
            },
            {
              text: i18n.t('Slet min profil'),
              onPress: () => removeUser(),
              style: 'destructive',
            },
          ]}
        />
      )}
    </ScrollPage>
  );
};

Settings.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  removeUser: initFunc,
  isPersisted: PropTypes.bool.isRequired,
  persistenceMechanism: PropTypes.string,
  canPersist: PropTypes.bool.isRequired,
  persistAuth: initFunc,
  removeAuthPersistence: initFunc,
  navigateToNotificationSettings: initFunc,
  navigateToReservations: initFunc,
  i18n: PropTypes.object.isRequired,
};

Settings.defaultProps = {
  removeUser: undefined,
  persistAuth: undefined,
  removeAuthPersistence: undefined,
  navigateToNotificationSettings: undefined,
  navigateToReservations: undefined,
  persistenceMechanism: undefined,
};

const mapStateToProps = (state) => ({
  isPersisted: selectIsPersisted(state),
  persistenceMechanism: selectSupportedPersistenceMechanism(state),
  canPersist: selectCanPersist(state),
  companyGroups: selectNotificationSettingsCompanyGroups(state),
  contents: selectContents(state),
  ruleRequest: selectRuleRequest(state),
});

const init = ({ action, getProps }) => {
  const { dispatch, navigation, route } = getProps();

  return {
    removeUser: () =>
      action(
        async () => {
          await dispatch(deleteProfile());
          await dispatch(logoutAction());
        },
        { loader: true }
      ),
    persistAuth: () => {
      Tracking.trackEvent(
        {
          area: route.name,
          action: 'BiometricLoginToggle',
        },
        { value: true }
      );
      dispatch(setupPersistence());
    },
    removeAuthPersistence: () => {
      Tracking.trackEvent(
        {
          area: route.name,
          action: 'BiometricLoginToggle',
        },
        { value: false }
      );
      action(async () => {
        await dispatch(removePersistence());
      });
    },
    navigateToNotificationSettings: () =>
      action(async () => dispatch(navigateToNotificationSettingsAction()), { loader: true }),
    navigateToReservations: async () => {
      const { ruleRequest } = getProps();
      let { contents } = getProps();

      if (ruleRequest && contents.length === 0) {
        await dispatch(getContent(ruleRequest));
      }

      ({ contents } = getProps());

      if (contents.length === 1) {
        openLink(contents[0]?.text, '_self');
      } else {
        navigation.navigate(routeNames.RESERVATIONS);
      }
    },
  };
};

export default withScreenContainer({
  mapStateToProps,
  init,
})(Settings);
