import React from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import useI18n from 'hooks/useI18n';
import Alert from '@sp/ui/base/inputs/Alert';

const CancelDataImportAlert = ({ setIsOpen, isOpen, onConfirm, onCancel, onClose }) => {
  const { i18n } = useI18n(['onboarding']);
  if (isOpen) {
    Keyboard.dismiss();
  }

  return (
    <Alert
      onRequestClose={() => {
        setIsOpen(false);
      }}
      visible={isOpen}
      title={i18n.t('onboarding|cancelDataImportTitle')}
      description={i18n.t('onboarding|cancelDataImportDescription')}
      onClose={onClose}
      actions={[
        {
          text: i18n.t('confirm'),
          onPress: () => {
            onConfirm();
          },
        },
        {
          text: i18n.t('cancel'),
          style: 'secondary',
          onPress: () => {
            if (onCancel) {
              onCancel();
            }

            setIsOpen(false);
          },
        },
      ]}
    />
  );
};

CancelDataImportAlert.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

CancelDataImportAlert.defaultProps = {
  onCancel: undefined,
  onClose: undefined,
};

export default CancelDataImportAlert;
