import getCompletedNavParams from './utils/getCompletedNavParams';

const completeInfoFlow = async (store) => {
  const { getState } = store;
  const state = getState();

  return getCompletedNavParams(state);
};

export default completeInfoFlow;
