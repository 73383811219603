// Screens
export const TRANSFER_SOURCES_SCREEN = 'transfer-sources-screen';
export const TRANSFER_QUESTION_SCREEN = 'transfer-question-screen';
export const TRANSFER_CONDITIONS_SCREEN = 'transfer-conditions-screen';
export const TRANSFER_CONFIRM_SCREEN = 'transfer-confirm-screen';
export const TRANSFER_CONTACT_INFORMATION_SCREEN = 'transfer-contact-information-screen';

// Buttons
export const SELECT_QUESTION_ANSWER_BUTTON = 'select-question-answer-button';
export const GO_TO_CONFIRM_TRANSFER_BUTTON = 'go-to-confirm-transfer-button';
export const GO_TO_QUESTIONS_BUTTON = 'go-to-question-button';
export const GO_TO_NEXT_QUESTION_BUTTON = 'go-to-next-question-button';
export const GO_BACK_TO_PREVIOUS_QUESTION_BUTTON = 'go-back-to-previous-question-button';
export const GO_TO_CONTACT_INFORMATION_BUTTON = 'go-to-contact-information-button';
export const OPEN_EXCHANGE_INFORMATION_CONSENT_BUTTON = 'open-exchange-information-consent-button';
export const CLOSE_EXCHANGE_INFORMATION_MODAL_BUTTON = 'close-exchange-information-modal-button';

// Components
export const WIZARD_STEP_INDICATOR = 'wizard-step-indicator';
export const CONSENTS = 'consents';
export const EXCHANGE_INFORMATION_MODAL = 'exchange-information-modal';
