import axios from 'axios';
import log from 'services/log';
import { nanoid } from 'nanoid';
import features from 'features';
import { getKeyValueObject } from 'helpers/object';
import { getPath } from 'helpers/url';

const usesDebug = global.localStorage.getItem('debug_tracking');

const sessionId = nanoid();
const MAX_PATH_LENGTH = 100;

/* To not require cookie consent, we disable the share GA cookie
 * for not, and instead use an in memory session ID as well as the
 * user ID for now for tracking
 *
const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = global.document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
*/

const getCookie = () => sessionId;

const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${d.toUTCString()};`;
  }
  global.document.cookie = `${name}=${value || ''};${expires}domain=.sampension.dk;path=/;`;
};

class DigTrackingClient {
  constructor() {
    this.url = '';
    this.debug = !!(__DEV__ || !!usesDebug);
    this.user = {};
    this.targetEnv = 'unknown';
    this.dr = global.document.referrer
      ? new URL(global.document.referrer).hostname
      : global.document.location.hostname;
  }

  setTrackingId(id) {
    this.user.id = id;
  }

  getDefaultData() {
    let id = getCookie();
    if (!id) {
      id = `GA1.2.${nanoid()}`;
      setCookie('_ga', id, 365 * 2);
      setCookie('_gid', id);
    }

    return {
      cid: id,
      uid: this.user.id,
      ua: global.navigator.userAgent,
      sr: `${global.screen.width}x${global.screen.height}`,
      vp: `${global.innerWidth}x${global.innerHeight}`,
      targetEnv: this.targetEnv,
      ul: global.navigator.language || global.navigator.userLanguage,
      dh: global.location.hostname,
      dr: this.dr,
      timestamp: Math.floor((Date.now ? Date.now() : new Date().getTime()) / 1000),
      tzOffset: new Date().getTimezoneOffset(),
      an: 'Sampension',
      aid: 'Sampension.WebApp',
      av: process.env.VERSION,
      platform: 'web',
      debug: features.get('trackingDebug'),
    };
  }

  async sendRequest(trackingParams) {
    if (trackingParams.params) {
      trackingParams.params = JSON.stringify(getKeyValueObject(trackingParams.params));
    }

    try {
      await axios({
        method: 'get',
        url: this.url,
        params: {
          debug: this.debug,
          ...this.getDefaultData(),
          ...trackingParams,
        },
      });
    } catch (err) {
      log.info('Could not send log', err);
    }
  }

  trackScreenView(screenPath) {
    let path = getPath(screenPath);

    // NOTE: Limit path to MAX_PATH_LENGTH because of the limit in DB
    if (path?.length > MAX_PATH_LENGTH) {
      log.info(`Path ${path} length has exceeded ${MAX_PATH_LENGTH} character limit`);
      path = path?.substring(0, MAX_PATH_LENGTH);
    }

    return this.sendRequest({
      t: 'pageview',
      dp: path,
    });
  }

  trackEventView(area, action, params) {
    return this.sendRequest({
      t: 'event',
      ec: area,
      ea: action,
      params,
    });
  }

  trackError(description) {
    return this.sendRequest({
      t: 'exception',
      exd: description,
    });
  }
}

export default new DigTrackingClient();
