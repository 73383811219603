import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { isWeb } from '@sp/ui/helpers/device';

const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Icon } = require('@sp/ui/v2/base/Icon');
const { default: Conditional } = require('decorators/conditional');
const { Body2, H5 } = require('@sp/ui/v2/typography');
const { renderStringOrComponent } = require('@sp/ui/helpers/component');

const ICON_SIZE_MIN_WIDTH = 20;
const HIT_SLOP_RANGE = 12;
const HIT_SLOP_RANGE_LEFT = 4;

export type FormSectionProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  centerInfoIcon?: boolean;
  disablePadding?: boolean;
  disableModalContent?: boolean;
  accessibilityLabel?: string;
  infoAccessibilityLabel?: string;
  onInfoPress?: () => void;
};

type DisablePaddingType = Pick<FormSectionProps, 'disablePadding'>;

// NOTE: static prevents z-index magic react native web adds unnecessary "position: relative;"
// NOTE: z-index auto makes sure FormSection is not overlapping previous form section
// with i.e. Selector
const WrapperMobile = styled.View<DisablePaddingType>`
  background-color: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
  ${isWeb ? 'position: static' : ''};
  ${({ theme, disablePadding }) =>
    disablePadding ? '' : `padding: ${theme.SPACINGS.lg}px ${theme.SPACINGS.md}px 0`};
  ${isWeb ? 'z-index: auto;' : ''}
`;

const RowWrapper = styled.View`
  flex-direction: row;
`;

const TitleWrapper = styled.View<Pick<FormSectionProps, 'centerInfoIcon'>>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LeftContent = styled.View`
  flex: 1;
`;

const WrapperTablet = styled.View<DisablePaddingType>`
  ${isWeb ? 'position: static' : ''};
  ${({ theme, disablePadding }) =>
    disablePadding ? '' : `padding: ${theme.SPACINGS.lg}px ${theme.SPACINGS.md}px 0`};
  ${isWeb ? 'z-index: auto;' : ''}
`;

const Wrapper = Conditional({
  mobile: WrapperMobile,
  default: WrapperTablet,
});

const IconWrapper = styled.TouchableOpacity<Pick<FormSectionProps, 'centerInfoIcon'>>`
  min-width: ${ICON_SIZE_MIN_WIDTH}px;
  margin-left: ${({ theme }) => theme.SPACINGS.sm}px;
  ${({ centerInfoIcon }) =>
    centerInfoIcon
      ? `
          justify-content: center;
          align-items: center;
        `
      : ''}
`;

const FormSection: React.FC<FormSectionProps> = ({
  title,
  description,
  onInfoPress,
  disablePadding,
  accessibilityLabel,
  infoAccessibilityLabel,
  disableModalContent,
  centerInfoIcon,
  children,
}) => {
  const { COLORS } = useTheme();

  return (
    <Wrapper disablePadding={disablePadding} accessibilityLabel={accessibilityLabel}>
      <Margin marginBottom="md">
        <TitleWrapper centerInfoIcon={centerInfoIcon}>
          {renderStringOrComponent(title, H5, {
            fontWeight: 'semibold',
            numberOfLines: 1,
            secondaryColor: true,
          })}
        </TitleWrapper>
      </Margin>
      {description && (
        <RowWrapper>
          <LeftContent>
            {renderStringOrComponent(description, Body2, {
              fontWeight: 'regular',
              secondaryColor: true,
            })}
          </LeftContent>
          {onInfoPress && (
            <IconWrapper
              centerInfoIcon={centerInfoIcon}
              disabled={disableModalContent}
              onPress={onInfoPress}
              hitSlop={{
                top: HIT_SLOP_RANGE,
                left: HIT_SLOP_RANGE_LEFT,
                bottom: HIT_SLOP_RANGE,
                right: HIT_SLOP_RANGE,
              }}
              accessibilityRole="button"
              accessibilityLabel={infoAccessibilityLabel}
            >
              <Icon name="info" fill={COLORS.V2_PRIMARY_BACKGROUND} />
            </IconWrapper>
          )}
        </RowWrapper>
      )}
      {children}
    </Wrapper>
  );
};

FormSection.defaultProps = {
  title: undefined,
  description: undefined,
  centerInfoIcon: true,
  disablePadding: false,
  disableModalContent: false,
  accessibilityLabel: undefined,
  infoAccessibilityLabel: undefined,
  onInfoPress: undefined,
};

export default FormSection;
