import { useContext, useCallback, useState } from 'react';
import AsyncContext, { Options } from 'context/AsyncContext';

type Response<T, Args extends Array<any>> = [
  (...params: Args) => Promise<void>,
  {
    result?: T;
    set: (result: T) => void;
    loading: boolean;
    error?: any;
  }
];

const useAsyncCallback = <Args extends Array<any>, T = any>(
  fn: (...params: Args) => Promise<T>,
  dependencies: any[] = [],
  options: Options = {}
): Response<T, Args> => {
  const [result, setResult] = useState<T | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const { run } = useContext(AsyncContext);
  const invoke = useCallback(
    async (...params: Args) => {
      setLoading(true);
      await run(
        async () => {
          return fn(...params);
        },
        (currentResult, err, done) => {
          setResult(currentResult);
          setError(err);
          setLoading(false);
          done();
        },
        options
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies, run]
  );

  return [
    invoke,
    {
      result,
      set: setResult,
      loading,
      error,
    },
  ];
};

export { Options };

export default useAsyncCallback;
