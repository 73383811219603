import React from 'react';
import Page from '../components/Page';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import useI18n from 'hooks/useI18n';
import usePrerenderScreen from 'hooks/usePrerenderScreen';
import { useAppSelector } from 'hooks/redux';
import { selectFormAdjustCalculationHasChanges } from 'selectors/dashboardForms';
import { useInitCalculationProfile, useUpdateCalculationProfile } from '../hooks';
import ChurchTax from './ChurchTax';
import CommuneTax from './CommuneTax';

const { Body } = require('@sp/ui/v2/typography');
const { default: buttonPositions } = require('constants/buttonPositions');
const { RowBorder } = require('@sp/ui/base/rows');
const { default: Margin } = require('@sp/ui/base/Margin');

const { default: withScreenContainer } = require('decorators/withScreenContainer');

const Taxation: React.FC = () => {
  const hasChanges = useAppSelector(selectFormAdjustCalculationHasChanges);
  const { i18n } = useI18n(['dashboard']);

  const [updateCalculationProfile] = useUpdateCalculationProfile();
  useInitCalculationProfile();

  const prerender = usePrerenderScreen();

  if (prerender) {
    return prerender;
  }

  return (
    <Page
      testID={testIds.ADJUST_CALCULATION_TAXATION_SCREEN}
      heading={i18n.t('dashboard|taxTitle')}
      actions={[
        {
          testID: testIds.TAXATION_SAVE_CHANGES_BUTTON,
          text: i18n.t('saveChange'),
          mobilePosition: buttonPositions.bottom,
          inactive: !hasChanges,
          onPress: () => updateCalculationProfile(),
        },
      ]}
      actionsContent={
        <>
          <CommuneTax />
          <Margin marginTop="md">
            <RowBorder />
          </Margin>
          <ChurchTax />
        </>
      }
    >
      <Body textAlign="left">{i18n.t('dashboard|taxDescriptions')}</Body>
    </Page>
  );
};

export default withScreenContainer({})(Taxation);
