import React, { useRef, useState } from 'react';
import styled, { withTheme } from 'styled-components/native';
import { useRoute } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { initFunc } from 'helpers/props';
import { Actions } from '@sp/ui/v2/pages/BasePage';
import SwiperPage from '@sp/ui/pages/SwiperPage';
import Swiper from '@sp/ui/layout/Swiper';
import { Appear } from '@sp/ui/v2/animations';
import CurrentStepIndicator from '@sp/ui/v2/base/CurrentStepIndicator';
import { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import Conditional from 'decorators/conditional';

import { doCustomerFlow as doCustomerFlowAction } from 'actions/onboarding';
import Menu from 'containers/navigation/Menu';
import withScreenContainer from 'decorators/withScreenContainer';
import { selectUserFirstName } from 'selectors/profile';

import getSlideInfo from './utils/getSlideInfo';
import Slide1 from './Slide1';
import Slide2 from './Slide2';

import mobileImage from 'assets/images/company-specific/bg-mobile.jpg';
import tabletImage from 'assets/images/company-specific/bg-tablet.jpg';
import desktopImage from 'assets/images/company-specific/bg-desktop.jpg';

const ActionsWrapper = Conditional({
  default: styled.View`
    position: absolute;
    bottom: 0;
    width: auto;
    align-self: center;
  `,
  mobile: styled.View`
    position: absolute;
    bottom: 0;
    width: 100%;
    align-self: center;
  `,
});

const backgroundImage = {
  mobile: mobileImage,
  tablet: tabletImage,
  landscape: desktopImage,
  desktop: desktopImage,
};

const CompanySpecifics = ({ i18n, userName, theme, continueCustomerFlow }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const swiperRef = useRef();
  const { params } = useRoute();
  const { slide1, slide2 } = getSlideInfo(
    params.companyGroup,
    params.iconChanged,
    params.showWelcome
  );

  const lastFlowStep = (slide1 && slide2 && slideIndex === 1) || !slide2 || !slide1;

  const actions = [
    {
      text: lastFlowStep ? i18n.t('common|continue') : i18n.t('common|next'),
      testID: lastFlowStep ? 'companySpecificsDoneBtn' : 'companySpecificsNextBtn',
      type: 'primary',
      onPress: () => (lastFlowStep ? continueCustomerFlow() : swiperRef.current.moveTo(1)),
    },
  ];

  return (
    <SwiperPage customHeader={null} hasHeader={false} backButton={null} menu={<Menu />}>
      <Swiper
        testID="companySpecificsSlider"
        ref={swiperRef}
        onIndexChanged={(newIndex) => setSlideIndex(newIndex)}
        backgroundImage={backgroundImage}
        overlay={true}
      >
        {slide1 && (
          <Slide1
            title={i18n.t(slide1.title, { userName })}
            description={i18n.t(slide1.description)}
            companyImage={slide1.companyImage}
          />
        )}
        {slide2 && (
          <Slide2
            title={i18n.t(slide2.title)}
            description={i18n.t(slide2.description)}
            companyImage={slide2.companyImage}
            iconUri={slide2.iconUri}
            startAnimation={lastFlowStep}
          />
        )}
      </Swiper>

      <ActionsWrapper>
        <Appear animationType="OutLin" duration={600}>
          <Actions
            horizontalMode
            actions={actions}
            actionsContent={
              slide1 &&
              slide2 && (
                <CurrentStepIndicator
                  current={slideIndex}
                  ids={[0, 1]}
                  onPress={(i) => swiperRef.current.moveTo(i)}
                  color={theme.COLORS.PRIMARY}
                />
              )
            }
          />
          <SafeAreaWrapper />
        </Appear>
      </ActionsWrapper>
    </SwiperPage>
  );
};

CompanySpecifics.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  userName: PropTypes.string,
  continueCustomerFlow: initFunc,
};

CompanySpecifics.defaultProps = {
  userName: undefined,
  continueCustomerFlow: undefined,
};

const mapStateToProps = (state, props) => ({
  ...props,
  userName: selectUserFirstName(state),
});

const init =
  ({ action, getProps }) =>
  async () => {
    const { dispatch } = getProps();

    return {
      continueCustomerFlow: () =>
        action(async () => dispatch(doCustomerFlowAction()), {
          loader: true,
        }),
    };
  };

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['onboarding'],
  init,
  trackAs: ({ route }) => ({
    area: route.name,
    action: 'CompanySpecificWelcome',
    params: { iconChanged: route.params?.iconChanged },
  }),
})(withTheme(CompanySpecifics));
