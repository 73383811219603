import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';

const BORDER_WIDTH = 1;

const Border = styled.View`
  position: absolute;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  bottom: ${(props) => props.bottomOffset}px;
  left: ${(props) => props.left}px;
  border: ${BORDER_WIDTH}px dashed
    ${({ theme, color }) => color || theme.COLORS.INACTIVE_GRAPH_SUCCESS};
`;

const AverageBorder = ({ left, bottomOffset, width, color }) => (
  <Border bottomOffset={bottomOffset} left={left} width={width} color={color} />
);

AverageBorder.propTypes = {
  left: PropTypes.number,
  bottomOffset: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
};

AverageBorder.defaultProps = {
  left: 0,
  bottomOffset: 0,
  width: undefined,
  color: undefined,
};

export default withTheme(AverageBorder);
