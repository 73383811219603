import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import { getChildren } from 'actions/children';
import withScreenContainer from 'decorators/withScreenContainer';
import Tracking from 'services/tracking';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { TwoLineRow, RowBorder } from '@sp/ui/base/rows';
import { BackButton } from '@sp/ui/pages/BasePage';
import Feature from 'containers/base/Feature';
import features from 'features';
import { getUserInfo, getContactInfo, getPendingVerifications } from 'actions/profile';
import * as routeNames from 'constants/routeNames';
import { selectContactInfo, selectPendingVerifications } from 'selectors/profile';
import { selectChildren } from 'selectors/children';
import { H5 } from '@sp/ui/typography';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { dismiss } from 'helpers/navigation';
import { getDisplayAddress, getDisplayPhoneNumber, isPhoneNumberEmpty } from '../helpers';
import { EMAIL, PHONE_NUMBER } from 'areas/profile/trackingIds';

const getVerificationIcon = (isVerified, value, theme) => {
  if (!value || isPhoneNumberEmpty(value)) {
    return undefined;
  }

  return isVerified
    ? {
        name: 'rowCheckmarkFilled',
        fill: theme.COLORS.PRIMARY_SUCCESS,
      }
    : {
        name: 'rowErrorFilled',
        fill: theme.COLORS.PRIMARY_ALERT,
      };
};

const YourProfile = ({
  navigation,
  route,
  email,
  phoneNumber,
  countryCode,
  danishAddress,
  foreignAddress,
  contactAddress,
  isAddressProtected,
  isDesktop,
  theme,
  phonePendingVerifications,
  emailPendingVerifications,
  i18n,
}) => {
  const displayAddress = getDisplayAddress(
    danishAddress,
    foreignAddress,
    contactAddress,
    isAddressProtected,
    i18n
  );

  const isModal = route.params?.isModal || false;

  const displayPhoneNumber = getDisplayPhoneNumber({ countryCode, phoneNumber });
  const isEmailVerified = !emailPendingVerifications;
  const isPhoneVerified = !phonePendingVerifications;

  const pendingEmail = emailPendingVerifications?.value;
  const pendingPhone = phonePendingVerifications?.value;

  return (
    <ModalProvider isModal={isModal} onClose={dismiss(navigation)}>
      <ScrollPage
        hasBackButton={!isDesktop && !isModal}
        heading={i18n.t('common|Profil')}
        disableBottomPadding
        backButton={
          isDesktop ? undefined : (
            // NOTE: default back does not work here because we are in a nested stack
            <BackButton onPress={() => navigation.popToTop()} />
          )
        }
        testID="profileScreen"
      >
        <RowBorder />
        <TwoLineRow
          arrow
          textLeft={<H5>{i18n.t('E-mail')}</H5>}
          subtitleLeft={<H5 fontWeight="regular">{pendingEmail || email}</H5>}
          iconRight={getVerificationIcon(isEmailVerified, pendingEmail || email, theme)}
          onPress={() => {
            Tracking.trackEvent(EMAIL.CLICK);
            navigation.navigate(routeNames.PROFILE_EMAIL_MODAL, { isModal: true });
          }}
        />
        <RowBorder />
        <TwoLineRow
          arrow
          textLeft={<H5>{i18n.t('Mobiltelefon')}</H5>}
          subtitleLeft={<H5 fontWeight="regular">{pendingPhone || displayPhoneNumber}</H5>}
          onPress={() => {
            Tracking.trackEvent(PHONE_NUMBER.CLICK);
            navigation.navigate(routeNames.PROFILE_TELEPHONE_MODAL, {
              isModal: true,
            });
          }}
          iconRight={getVerificationIcon(isPhoneVerified, pendingPhone || phoneNumber, theme)}
        />
        <RowBorder />
        <TwoLineRow
          arrow
          textLeft={<H5>{i18n.t('Adresse')}</H5>}
          subtitleLeft={<H5 fontWeight="regular">{displayAddress}</H5>}
          testID="profileAddressLink"
          onPress={
            displayAddress
              ? () => {
                  Tracking.trackEvent({ area: route.name, action: 'ClickAddress' });
                  navigation.navigate(routeNames.PROFILE_ADDRESS);
                }
              : undefined
          }
        />
        {features.isEnabled('profileExternalData') && (
          <>
            <RowBorder />
            <TwoLineRow
              arrow
              textLeft={<H5>{i18n.t('deleteData')}</H5>}
              testID="profileDataFromExternalSources"
              onPress={() => {
                Tracking.trackEvent({
                  area: route.name,
                  action: 'ClickProfileDataExternalSources',
                });

                navigation.navigate(routeNames.PROFILE_EXTERNAL_DATA, {
                  screen: routeNames.PROFILE_EXTERNAL_DATA_OVERVIEW,
                });
              }}
            />
          </>
        )}
        <RowBorder />
        <Feature name="manualPayments">
          <TwoLineRow
            arrow
            textLeft={i18n.t('Indbetalinger')}
            onPress={() => {
              Tracking.trackEvent({ area: route.name, action: 'ClickAddress' });
              navigation.navigate(routeNames.PROFILE_PAYMENT_AGREEMENTS);
            }}
          />
          <RowBorder />
        </Feature>
        <Feature name="payouts">
          <TwoLineRow
            arrow
            textLeft={i18n.t('Betalingskort')}
            onPress={() => navigation.navigate(routeNames.PROFILE_EDIT_PAYMENT_CARDS)}
          />
          <RowBorder />
        </Feature>
        {isDesktop && (
          <>
            <TwoLineRow
              arrow
              textLeft={i18n.t('Indstillinger')}
              testID="profileSettingsLink"
              onPress={() => {
                Tracking.trackEvent({ area: route.name, action: 'ClickSettings' });
                navigation.navigate(routeNames.SETTINGS);
              }}
            />
            <RowBorder />
          </>
        )}
      </ScrollPage>
    </ModalProvider>
  );
};

YourProfile.propTypes = {
  navigation: PropTypes.any.isRequired,
  route: PropTypes.object.isRequired,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  countryCode: PropTypes.string,
  danishAddress: PropTypes.object,
  foreignAddress: PropTypes.array,
  contactAddress: PropTypes.array,
  isDesktop: PropTypes.bool.isRequired,
  isAddressProtected: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  emailPendingVerifications: PropTypes.array,
  phonePendingVerifications: PropTypes.array,
  i18n: PropTypes.object.isRequired,
};

YourProfile.defaultProps = {
  email: '',
  phoneNumber: '',
  countryCode: undefined,
  danishAddress: undefined,
  foreignAddress: undefined,
  contactAddress: undefined,
  isAddressProtected: undefined,
  emailPendingVerifications: undefined,
  phonePendingVerifications: undefined,
};

const mapStateToProps = (state, props) => {
  const {
    email,
    phoneNumber,
    countryCode,
    danishAddress,
    foreignAddress,
    contactAddress,
    isAddressProtected,
  } = selectContactInfo(state);

  return {
    ...props,
    isDesktop: selectIsDeviceTypeDesktop(state),
    childrenData: selectChildren(state),
    email,
    phoneNumber,
    countryCode,
    danishAddress,
    foreignAddress,
    contactAddress,
    isAddressProtected,
    emailPendingVerifications: selectPendingVerifications(state, 'email'),
    phonePendingVerifications: selectPendingVerifications(state, 'phone'),
  };
};

export default withScreenContainer({
  mapStateToProps,
  init: ({ action, props }) =>
    action(
      async () => {
        const dispatchActions = [getContactInfo(), getPendingVerifications()];

        if (!props.childrenData) {
          dispatchActions.push(getChildren());
        }

        await props.dispatch(getUserInfo());
        await props.dispatch(dispatchActions);
      },
      {
        loader: true,
      }
    ),
})(withTheme(YourProfile));
