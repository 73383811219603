import { useCallback } from 'react';
import fetchTaxAndPensionsInfoController from '../fetchTaxAndPensionsInfoController';

type UseOnCloseParams = {
  updateIsAlertOpen: () => void;
  finishFlow: () => void;
};

export const useOnClose = ({ updateIsAlertOpen, finishFlow }: UseOnCloseParams) =>
  useCallback(() => {
    const { getIsFetching, updateTerminationSituation } = fetchTaxAndPensionsInfoController;
    const isFetching = getIsFetching();
    if (isFetching) {
      updateIsAlertOpen();
      updateTerminationSituation({ isTryingToTerminateFetchingProcess: true });
    } else {
      finishFlow();
    }
  }, [finishFlow, updateIsAlertOpen]);
