import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import BasePage, { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import { Result } from '@sp/ui/pages/ResultPage';
import useI18n from 'hooks/useI18n';

const Wrapper = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StartAppError = ({ retryFn, theme }) => {
  const { i18n } = useI18n();

  const actions = retryFn && [
    {
      text: i18n.t('tryAgain'),
      onPress: retryFn,
      mobilePosition: 'bottom',
    },
  ];

  return (
    <SafeAreaWrapper>
      <BasePage actions={actions} hasHeader={false}>
        <Wrapper>
          <Result
            iconName="warning"
            iconColor={theme.COLORS.PRIMARY}
            heading={i18n.t('genericErrorHeading')}
            description={i18n.t('genericErrorDescription')}
          />
        </Wrapper>
      </BasePage>
    </SafeAreaWrapper>
  );
};

StartAppError.propTypes = {
  retryFn: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(StartAppError);
