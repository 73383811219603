import React, { useMemo } from 'react';
import { ReduxForm } from 'react-formed';
import { Provider as PagePartsProvider } from '@sp/ui/PageParts';
import AppRouter from 'AppRouter';
import ThemeProviderWrapper from 'containers/theme/ThemeProviderWrapper';
import PopupWebView from 'containers/PopupWebView';
import menu from 'containers/globals/menu';
import footer from 'containers/globals/footer';
import Header from 'containers/globals/Header';
import ModalHeader from 'containers/globals/ModalHeader';
import BackButton from 'containers/globals/BackButton';
import notifications from 'containers/globals/notifications';
import ConnectivityMonitor from 'containers/connectivity/ConnectivityMonitor';
import UnverifiedContactInfoMonitor from 'containers/profile/UnverifiedContactInfoMonitor';
import NotificationProviderWrapper from 'containers/NotificationProviderWrapper';
import AppRateModal from 'containers/apprating/AppRateModal';
import { GlobalNotificationProvider } from 'context/GlobalNotificationContext';
import ErrorHandler from 'containers/ErrorHandler';
import useLaunchContext from 'hooks/useLaunchContext';

const defaultPageParts = {
  menu,
  footer,
  Header,
  ModalHeader,
  BackButton,
  notifications,
};

const App = () => {
  const { isPopup } = useLaunchContext();
  const pageParts = useMemo(() => {
    if (!isPopup) {
      return defaultPageParts;
    }

    return { ...defaultPageParts, menu: () => null };
  }, [isPopup]);

  return (
    <PagePartsProvider value={pageParts}>
      <ReduxForm getState={(state) => state.forms}>
        <ThemeProviderWrapper>
          <ErrorHandler>
            <NotificationProviderWrapper provider={GlobalNotificationProvider}>
              <PopupWebView />
              <AppRouter />
              <ConnectivityMonitor />
              <UnverifiedContactInfoMonitor />
              <AppRateModal />
            </NotificationProviderWrapper>
          </ErrorHandler>
        </ThemeProviderWrapper>
      </ReduxForm>
    </PagePartsProvider>
  );
};

export default App;
