import { fetchRetirement, fetchDisability, fetchDeath } from 'actions/digital-advice';
import { refetchNakedDashboardErrors, getIncome } from 'actions/dashboard';
import { getChildren } from 'actions/children';
import { DEATH, DISABILITY, RETIREMENT } from '../areas/dashboard/constants/dashboardTypes';

// NOTE: we use this helper to orchestrate and
// sync refetchNakedDashboardErrors and the response action
export const fetchDashboardData = async (dispatch) => {
  await dispatch([fetchRetirement(), fetchDisability(), fetchDeath(), getIncome(), getChildren()]);

  dispatch(refetchNakedDashboardErrors(RETIREMENT));
  dispatch(refetchNakedDashboardErrors(DEATH));
  dispatch(refetchNakedDashboardErrors(DISABILITY));
};
