import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';

const BUTTON_LEFT_PADDING = 4;

const Wrapper = styled.TouchableOpacity`
  padding-left: ${BUTTON_LEFT_PADDING}px;
`;

const ICON_WIDTH = 24;
const ICON_HEIGHT = 24;

const BackButton = ({ onPress, theme, testID, label }) => (
  <Wrapper
    onPress={onPress}
    hitSlop={theme.HIT_SLOP}
    testID={testID}
    accessibilityLabel={label}
    accessibilityRole="button"
  >
    <Icon
      name="menuBack"
      width={ICON_WIDTH}
      height={ICON_HEIGHT}
      fill={theme.COLORS.SECONDARY_BACKGROUND}
    />
  </Wrapper>
);

BackButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  testID: PropTypes.string,
  label: PropTypes.string,
};

BackButton.defaultProps = {
  testID: 'backButton',
  label: undefined,
};

export default withTheme(BackButton);
