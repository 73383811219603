import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { initFunc } from 'helpers/props';
import Tracking from 'services/tracking';
import features from 'features';
import PickerPage from 'containers/pages/PickerPage';
import GlobalNotificationContext from 'context/GlobalNotificationContext';
import Margin from '@sp/ui/base/Margin';
import SendButtons from 'areas/mailbox/SendButtons';
import DeleteDraftAlert from 'areas/general/alerts/DeleteDraftAlert';
import { pickFileAndGetAttachment } from 'helpers/fileUpload';
import { addAttachment, removeAttachmentById } from 'areas/mailbox/helpers/attachments';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import * as routeNames from 'constants/routeNames';
import { FORMS_MAIL_CREATE } from 'constants/forms';

const MailCreate = ({
  navigation,
  subjects,
  form,
  send,
  isDesktop,
  validateAttachments,
  isAdvisorsBusy,
  showError,
  i18n,
}) => {
  const { showNotification } = useContext(GlobalNotificationContext);

  const selectedSubject = (subjects || []).find(({ subject }) => subject === form.subject);
  const hasCustomSubject = selectedSubject && selectedSubject.hasSubject;
  const caseType = selectedSubject && selectedSubject.caseType;
  const onSuccess = () => {
    showNotification(i18n.t('mailbox|inquerySentMsg'));

    navigation.navigate(routeNames.TABS, { screen: routeNames.MAIL_OVERVIEW });
  };

  const [attachments, setAttachments] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (isAdvisorsBusy) {
      showError(i18n.t('mailbox|longResponseTimesMsg'), {
        type: 'warning',
      });
    }
  }, [i18n, isAdvisorsBusy, showError]);

  const openFilePickerAndAttachFile = () => {
    pickFileAndGetAttachment(async (attachment) => {
      const updatedAttachments = addAttachment(attachments, {
        id: attachment.filename,
        ...attachment,
      });

      if (await validateAttachments(updatedAttachments)) {
        setAttachments(updatedAttachments);
      }
    });
  };

  const getActions = () => {
    const actions = [];

    if (features.isEnabled('mailboxAddAttachments')) {
      actions.push({
        icon: 'attachment',
        type: 'accessory',
        text: i18n.t('common|send'),
        onPress: openFilePickerAndAttachFile,
        mobilePosition: 'bottom',
      });
    }

    actions.push({
      text: i18n.t('common|send'),
      inactive: !form.subject || !form.content || (hasCustomSubject && !form.customSubject),
      onPress: () => send(hasCustomSubject, caseType, attachments, onSuccess),
      mobilePosition: 'bottom',
    });

    return actions;
  };

  const onClose = () => {
    const hasContent = !!form.content || attachments.length > 0;
    if (hasContent) {
      setIsDialogOpen(true);
    } else {
      navigation.goBack();
    }
  };

  return (
    <ModalProvider onClose={onClose} isModal>
      <PickerPage
        title={i18n.t('mailbox|newMessage')}
        formName={FORMS_MAIL_CREATE}
        resetFormOnMount
        hasBackButton={isDesktop}
        actions={isDesktop ? undefined : getActions()}
        pickerOptions={subjects ? subjects.map(({ subject }) => subject) : undefined}
        pickerPlaceholder={i18n.t('mailbox|chooseSubject')}
        pickerFormValue={form.subject}
        attachments={attachments}
        hasCustomSubject={hasCustomSubject}
        customSubject={form.customSubject}
        onSelect={(option) =>
          Tracking.trackEvent(
            {
              area: routeNames.MAIL_CREATE,
              action: 'ClickSubject',
            },
            { subject: option.name }
          )
        }
        onAttachmentDeletePress={(id) => setAttachments(removeAttachmentById(attachments, id))}
        testID="mailboxCreateScreen"
      >
        {isDesktop && (
          <Margin marginTop="md">
            <SendButtons
              form={form}
              send={() => send(hasCustomSubject, caseType, attachments, onSuccess)}
              isButtonInactive={!form.subject || !form.content}
              onAttachmentPress={openFilePickerAndAttachFile}
            />
          </Margin>
        )}
        <DeleteDraftAlert
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          onClose={navigation.goBack}
        />
      </PickerPage>
    </ModalProvider>
  );
};

MailCreate.propTypes = {
  navigation: PropTypes.object.isRequired,
  send: initFunc,
  subjects: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object,
  isDesktop: PropTypes.bool.isRequired,
  validateAttachments: initFunc,
  showError: PropTypes.func.isRequired,
  isAdvisorsBusy: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
};

MailCreate.defaultProps = {
  form: {},
  send: undefined,
  subjects: undefined,
  validateAttachments: undefined,
};

export default MailCreate;
