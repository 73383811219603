import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import * as routeNames from 'constants/routeNames';
import FetchTaxAndPensionsInfoNavigator from 'areas/fetchTaxAndPensionsInfo/FetchTaxAndPensionsInfoNavigator';

const { default: CompanySpecifics } = require('./CompanySpecifics');
const { default: ChangeLog } = require('../changelog');
const { default: Info } = require('./Info');

export const routes = [
  {
    name: routeNames.INFO,
    key: routeNames.INFO,
    component: Info,
  },
  {
    name: routeNames.CHANGELOG,
    key: routeNames.CHANGELOG,
    component: ChangeLog,
  },
  {
    name: routeNames.COMPANY_SPECIFICS,
    key: routeNames.COMPANY_SPECIFICS,
    component: CompanySpecifics,
  },
  {
    name: routeNames.FETCH_TAX_AND_PENSIONSINFO,
    key: routeNames.FETCH_TAX_AND_PENSIONSINFO,
    component: FetchTaxAndPensionsInfoNavigator,
  },
];

const Stack = createStackNavigator();

export default () => (
  <Stack.Navigator
    screenOptions={{
      headerShown: false,
    }}
  >
    {routes.map(({ name, component }) => (
      <Stack.Screen key={name} name={name} component={component} />
    ))}
  </Stack.Navigator>
);
