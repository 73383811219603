import { useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { selectIsFlowTax } from 'selectors/fetchTaxAndPensionsInfo';

const { default: Tracking } = require('services/tracking');

enum TrackStatusTypes {
  'started',
  'failed',
  'success',
}

export const useTrackFetchingStatus = () => {
  const isFlowTax = useAppSelector(selectIsFlowTax, shallowEqual);

  return useCallback(
    (trackingStatus: TrackStatusTypes) => {
      const trackingStatuses: Record<string, string> = {
        started: 'Started',
        failed: 'Failed',
        success: 'Success',
      };

      Tracking.trackEvent({
        area: isFlowTax ? 'TaxImport' : 'PensionInfoImport',
        action: trackingStatuses[trackingStatus],
      });
    },
    [isFlowTax]
  );
};
