import { post, remove } from 'actions/api';
import { Platform } from 'react-native';
import {
  REGISTER_PUSH_DEVICE,
  UNREGISTER_PUSH_DEVICE,
  RECEIVED_PUSH_NOTIFICATION,
  CLEAR_PUSH_NOTIFICATION,
} from 'constants/actionTypes';

const platformNameMap = {
  ios: 'Apple_iOS',
  android: 'Android',
};

export const registerDevice = (deviceId) =>
  post(
    REGISTER_PUSH_DEVICE,
    {
      deviceId,
      platform: platformNameMap[Platform.OS],
    },
    'api',
    '/devices/v2/registrations',
    { preventConcurrent: true }
  );

export const unregisterDevice = (pushToken) =>
  remove(UNREGISTER_PUSH_DEVICE, 'api', `/devices/v2/registrations/${pushToken}`, {
    preventConcurrent: true,
    includeToken: false,
  });

export const openedNotification = (notification) => ({
  type: RECEIVED_PUSH_NOTIFICATION,
  payload: notification,
});

export const clearNotification = () => ({
  type: CLEAR_PUSH_NOTIFICATION,
});
