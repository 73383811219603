import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';

const Body = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.BODY.DESKTOP}px;
  line-height: ${(props) => props.theme.FONT_SIZES.BODY.DESKTOP * 1.5}px;
  font-style: ${(props) => props.fontStyle};
`;

Body.defaultProps = {
  fontWeight: 'regular',
  fontStyle: 'normal',
};

export default Body;
