import * as routeNames from 'constants/routeNames';
import { selectIsLoggedIn } from 'selectors/api';
import { isWeb } from 'helpers/platform';
import type { RootState } from 'store';

export const selectDeeplink = (state: RootState) => {
  const deeplinkState = state.services?.deeplink;

  if (!isWeb) {
    return deeplinkState;
  }

  let deeplinkFlow;
  const isLoggedIn = selectIsLoggedIn(state);
  const hasDeeplinkStore = Object.values(state.services?.deeplink).some((res) => res);

  if (!isLoggedIn || !hasDeeplinkStore) {
    const isDeeplinkFromClassic = !!global.opener && global.window !== global.opener;
    const isExternalDeeplink = global.location.pathname.startsWith(`/${routeNames.DEEPLINK}`);
    const isDeeplink = [isDeeplinkFromClassic, isExternalDeeplink].some((res) => res);
    const isDeeplinkInvalid = global.location.pathname.split('/').length >= 4;
    const isDeeplinkPriorToLogin = isDeeplink && !isDeeplinkInvalid && !isLoggedIn;

    if (isExternalDeeplink) {
      deeplinkFlow = global.location.pathname.split('/')[2];
    } else if (isDeeplinkFromClassic) {
      deeplinkFlow = global.location.pathname.split('/')[1];
    }

    return {
      ...state.services?.deeplink,
      isDeeplinkFromClassic,
      isExternalDeeplink,
      isDeeplink,
      isDeeplinkInvalid,
      isDeeplinkPriorToLogin,
      deeplinkFlow,
    };
  }

  return deeplinkState;
};
