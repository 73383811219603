import { compose } from 'redux';
import { connect } from 'react-redux';
import withTranslation from 'decorators/i18n';
import withSuspense from 'decorators/withSuspense';
import withInit from './init';
import withLogin from './login';
import withAsync from './withAsync';
import trackAsHoc from './trackAs';

export default ({
  i18n,
  i18ns,
  needsLogin = true,
  overlayScreenProps, // Used as props for the overlayer screens (GenericError)
  init,
  mapStateToProps,
  trackAs,
  hasLocalNotifications = true,
} = {}) => {
  const decorators = [];

  if (i18n !== false) {
    decorators.push(withTranslation(i18ns));
    decorators.push(withSuspense);
  }

  if (needsLogin) {
    decorators.push(withLogin);
  }

  decorators.push(connect(mapStateToProps));

  if (trackAs) {
    decorators.push(trackAsHoc(trackAs));
  }

  if (init) {
    decorators.push(withInit(init, overlayScreenProps, hasLocalNotifications, false, true));
  }

  decorators.push(withAsync({ hasLoadingContext: typeof init !== 'function' }));

  return compose(...decorators);
};
