import React from 'react';
import styled from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const Wrapper = styled.View<{ bottomPadding: number }>`
  padding-bottom: ${(props) => props.bottomPadding || 0}px;
  flex: 1;
  background: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
`;

export const SafeAreaWrapper = ({ children }: { children: React.ReactNode }) => {
  const insets = useSafeAreaInsets();

  return <Wrapper bottomPadding={insets.bottom}>{children}</Wrapper>;
};

export default SafeAreaWrapper;
