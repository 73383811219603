export const PARTNER_AREA = 'Partner';

export const FLOW_STARTED = {
  area: PARTNER_AREA,
  action: 'FlowStarted',
};

export const FLOW_COMPLETED = {
  area: PARTNER_AREA,
  action: 'FlowCompleted',
};

export const FLOW_EXIT = {
  area: PARTNER_AREA,
  action: 'FlowExit',
};

export const FLOW_UPDATED = {
  area: PARTNER_AREA,
  action: 'FlowUpdated',
};
