import { actions } from 'react-formed';
import { getMonth, capitalizeFirstLetter } from 'helpers/string';

export const getSalaryPeriod = (salary, i18n, useMonthAbbreviation) => {
  switch (salary?.type) {
    case 'AnnualStatement':
      return salary?.period?.year;
    case 'MonthlySalary': {
      if (salary.period) {
        let month;
        if (useMonthAbbreviation) {
          month = getMonth(salary.period?.month, i18n)?.substr(0, 3);
          return `${month}. ${salary?.period?.year}`;
        }

        month = capitalizeFirstLetter(getMonth(salary.period?.month, i18n));
        return `${month} ${salary?.period?.year}`;
      }
      return null;
    }
    default:
      return null;
  }
};

export const getSelectedIncomeText = (i18n, disposableIncome) => {
  if (!disposableIncome) {
    return null;
  }
  const disposableIncomeOptions = disposableIncome?.disposableIncomeOptions;
  const selectedItem = disposableIncomeOptions?.find((item) => item.selected);

  let type;
  let period;
  // salary is selected
  if (selectedItem) {
    // manually entered income
    if (selectedItem.source === 'UserEnteredDisposableIncome') {
      type = selectedItem.source;
    } else {
      ({ type } = selectedItem);
    }
    period = getSalaryPeriod(selectedItem, i18n);
  } else {
    // default value is used
    const defaultIncome = disposableIncomeOptions?.find(
      (item) => item.identifier === disposableIncome.defaultIdentifier
    );
    type = defaultIncome?.type;
    if (!type) {
      // no default value exist.
      const option = disposableIncomeOptions?.[0];
      type = option?.type;
      period = getSalaryPeriod(option, i18n);
    } else {
      period = getSalaryPeriod(defaultIncome, i18n);
    }
  }

  switch (type) {
    case 'MonthlySalary':
      return i18n.t('dashboard|selectedIncomeSalary', { period });
    case 'UserEnteredDisposableIncome':
      return i18n.t('dashboard|selectedIncomeUserEntered');
    case 'AnnualStatement':
      return i18n.t('dashboard|selectedIncomeAnnualStatementWithPeriod', { period });
    default:
      return i18n.t('dashboard|selectedIncomeAnnualStatement');
  }
};

let lastIncome;

export const validateInput = ({
  formAdjustIncome,
  showErrorNotification,
  disposableIncomeOptions,
  moneySymbol,
  saveButtonOnPress,
}) => {
  const { enteredIncome, enteredGrossIncome, selectedIndex } = formAdjustIncome;

  if (!enteredIncome && selectedIndex === -1) {
    return false;
  }

  if (
    enteredIncome &&
    enteredGrossIncome &&
    parseInt(enteredGrossIncome, 10) < parseInt(enteredIncome, 10)
  ) {
    return false;
  }

  // enteredIncome is mandatory if user wants to enter salary manually.
  if (enteredIncome && lastIncome !== enteredIncome) {
    lastIncome = enteredIncome;
    const annualStatement =
      disposableIncomeOptions &&
      disposableIncomeOptions.find((item) => item.type === 'AnnualStatement');

    const monthlyIncome = annualStatement?.monthlyAmount?.amount || 0;

    const deviation = Math.abs((parseInt(enteredIncome, 10) - monthlyIncome) / monthlyIncome) * 100;

    if (deviation > 25) {
      showErrorNotification(enteredIncome, moneySymbol, saveButtonOnPress);
      return false;
    }
  }

  return true;
};

export const buildIncomeInfo = ({ disposableIncome, formAdjustIncome, showGrossAmount }) => {
  const { selectedIndex } = formAdjustIncome;
  const incomeInfo = {};

  incomeInfo.taxStatementId = disposableIncome?.taxStatementId;
  incomeInfo.showGrossAmount =
    showGrossAmount === undefined ? disposableIncome.showGrossAmount : showGrossAmount;

  if (selectedIndex > -1) {
    const { disposableIncomeOptions } = disposableIncome;
    incomeInfo.selectedOptionIdentifier =
      disposableIncomeOptions && disposableIncomeOptions[selectedIndex].identifier;
  } else {
    const { enteredIncome, enteredGrossIncome } = formAdjustIncome;
    incomeInfo.userEnteredCurrentDisposableIncome = {
      amount: enteredIncome,
      grossAmount: enteredGrossIncome,
      currencyCode: 'DKK',
    };
    incomeInfo.selectedOptionIdentifier = 'UserEnteredDisposableIncome_MonthlySalary';

    if (!enteredGrossIncome) {
      incomeInfo.showGrossAmount = false;
    }
  }
  return incomeInfo;
};

export const setInitialIncomeValues = ({
  userEnteredIncome,
  disposableIncomeOptions,
  selectedSalary,
  dispatch,
}) => {
  let initEnteredIncome = '';
  let initEnteredGrossIncome = '';
  let initSelectedIndex = -1;

  if (userEnteredIncome) {
    initEnteredIncome = userEnteredIncome.amount;
    initEnteredGrossIncome = userEnteredIncome.grossAmount;
  } else if (selectedSalary) {
    const selectedIncomeIndex = disposableIncomeOptions?.indexOf(selectedSalary);
    initSelectedIndex = selectedIncomeIndex > -1 ? selectedIncomeIndex : 0;
  }

  dispatch(
    actions.setForm('adjustIncome', {
      selectedIndex: initSelectedIndex,
      enteredIncome: initEnteredIncome,
      enteredGrossIncome: initEnteredGrossIncome,
    })
  );
};
