import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import { connect } from 'react-redux';
import { ContentWithIcon } from '@sp/ui/v2/pages/BasePage';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import Margin from '@sp/ui/base/Margin';

const Content = ({ title, body, icon, theme, isDesktop }) => (
  <Margin horizontal="xl" marginTop="md">
    <ContentWithIcon
      title={title}
      body={body}
      icon={{
        fill: isDesktop ? theme.COLORS.V2_SECONDARY_TEXT : undefined,
        ...icon,
      }}
    />
  </Margin>
);

Content.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  icon: PropTypes.object,
  theme: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

Content.defaultProps = {
  title: undefined,
  body: undefined,
  icon: undefined,
};

const mapStateToProps = (state) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default connect(mapStateToProps)(withTheme(Content));
