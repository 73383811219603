import React, { lazy } from 'react';
import * as routeNames from 'constants/routeNames';
import withSuspense from 'decorators/withSuspense';
import notFound from 'areas/notFound';

const { default: ModalStack } = require('navigation/ModalStack');

const LazyDepositsNavigator = lazy(() => import('areas/deposits/DepositsNavigator'));
const LazyTransferNavigator = lazy(() => import('areas/transfer/TransferNavigator'));
const LazyPartnerNavigator = lazy(() => import('areas/partner/PartnerNavigator'));
const LazyChangeInvestmentNavigator = lazy(
  () => import('areas/changeInvestmentProfile/ChangeInvestmentProfileNavigator')
);
const LazyMeetingPreparationNavigator = lazy(
  () => import('areas/meetingPreparation/MeetingPreparationNavigator')
);
const { default: LazyFeedback } = require('areas/feedback/FeedbackNavigator');

const DepositsNavigator = withSuspense(LazyDepositsNavigator);
const TransferNavigator = withSuspense(LazyTransferNavigator);
const PartnerNavigator = withSuspense(LazyPartnerNavigator);
const ChangeInvestmentNavigator = withSuspense(LazyChangeInvestmentNavigator);
const MeetingPreparationNavigator = withSuspense(LazyMeetingPreparationNavigator);
const Feedback = withSuspense(LazyFeedback);

export const deeplinkRoutes = {
  [routeNames.PARTNER]: {
    screen: PartnerNavigator,
  },
  [routeNames.MEETING_PREPARATION]: {
    screen: MeetingPreparationNavigator,
  },
  [routeNames.DEPOSITS]: {
    screen: DepositsNavigator,
  },
  [routeNames.TRANSFER]: {
    screen: TransferNavigator,
  },
  [routeNames.CHANGE_INVESTMENT_PROFILE]: {
    screen: ChangeInvestmentNavigator,
  },
  [routeNames.DEEPLINK_WARNING]: {
    screen: notFound,
  },
  [routeNames.FEEDBACK]: {
    screen: Feedback,
  },
};

const DeeplinkNavigator = () => <ModalStack routeConfig={deeplinkRoutes} />;

export default DeeplinkNavigator;
