import React from 'react';
import PropTypes from 'prop-types';
import { Body, H2 } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import withComponentContainer from 'decorators/withComponentContainer';
import { Wrapper } from './styles';

const getTitle = (missingSavingsDataKey, i18n) => {
  switch (missingSavingsDataKey) {
    case 'customerHasNotFetchedPensionInfo':
    case 'customerHasNoSavingsData':
      return i18n.t('savings|Ingen ordninger at vise');
    default:
      return '';
  }
};

const getContent = (missingSavingsDataKey, pensionInfoLastUpdate, i18n) => {
  const latestPensionDateText = i18n.t(
    'savings|Data er sidst hentet fra PensionsInfo d. {{pensionInfoLastUpdate}}',
    { pensionInfoLastUpdate }
  );

  switch (missingSavingsDataKey) {
    case 'customerHasNotFetchedPensionInfo':
      return [
        i18n.t('savings|Det ser ikke ud til, at du har nogle pensionsordninger, som vi kan vise.'),
        i18n.t(
          'savings|Henter du tal fra PensionsInfo, vil du få vist dine øvrige pensionsordninger her.'
        ),
      ];
    case 'customerHasNoSavingsData': {
      const content = [
        i18n.t('savings|Det ser ikke ud til, at du har nogle pensionsordninger, som vi kan vise.'),
        i18n.t(
          'savings|Hvis der er nye oplysninger hos PensionsInfo, kan du nemt hente dem ved at trykke på knappen nedenfor.'
        ),
      ];

      if (pensionInfoLastUpdate) {
        content.push(latestPensionDateText);
      }

      return content;
    }
    default:
      return [];
  }
};

const NoSavingsData = ({ missingSavingsDataKey, pensionInfoLastUpdate, i18n }) => {
  const title = getTitle(missingSavingsDataKey, i18n);
  const content = getContent(missingSavingsDataKey, pensionInfoLastUpdate, i18n);

  return (
    <>
      <Margin marginBottom="md" />
      <Wrapper>
        <H2 textAlign="center">{title}</H2>
        <Margin marginBottom="lg" />
        {content &&
          content.length > 0 &&
          content.map((item, i) => (
            <Margin key={i} marginBottom="md">
              <Body textAlign="center" key={i}>
                {item}
              </Body>
            </Margin>
          ))}
      </Wrapper>
    </>
  );
};

NoSavingsData.propTypes = {
  i18n: PropTypes.object.isRequired,
  missingSavingsDataKey: PropTypes.string.isRequired,
  pensionInfoLastUpdate: PropTypes.string,
};

NoSavingsData.defaultProps = {
  pensionInfoLastUpdate: undefined,
};

export default withComponentContainer()(NoSavingsData);
