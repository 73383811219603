import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import MouseView from '@sp/ui/base/MouseView';
import Icon from '@sp/ui/base/Icon';
import { isWeb } from '@sp/ui/helpers/device';

const PLUS_MINUS_BUTTON_CIRCLE_SIZE = 46;
const NEXT_BACK_BUTTON_CIRCLE_SIZE = 40;

const getStyle = (type, inactive, COLORS) => {
  switch (type) {
    case 'next':
      return {
        color: inactive ? COLORS.INACTIVE_GRAPH_INCOME : COLORS.PRIMARY_TEXT,
        hover: COLORS.PRIMARY_TEXT,
        size: NEXT_BACK_BUTTON_CIRCLE_SIZE,
        content: <Icon fill={COLORS.SECONDARY_BACKGROUND} name="arrowRightCentered" />,
      };
    case 'back':
      return {
        color: inactive ? COLORS.INACTIVE_GRAPH_INCOME : COLORS.PRIMARY_TEXT,
        hover: COLORS.PRIMARY_TEXT,
        size: NEXT_BACK_BUTTON_CIRCLE_SIZE,
        content: <Icon fill={COLORS.SECONDARY_BACKGROUND} name="arrowLeftCentered" />,
      };
    case 'minus':
      return {
        color: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        hover: COLORS.PRIMARY_HOVER,
        size: PLUS_MINUS_BUTTON_CIRCLE_SIZE,
        content: <Minus />,
      };
    case 'plus':
    default:
      return {
        color: inactive ? COLORS.PRIMARY_INACTIVE : COLORS.PRIMARY,
        hover: COLORS.PRIMARY_HOVER,
        size: PLUS_MINUS_BUTTON_CIRCLE_SIZE,
        content: (
          <Fragment>
            <Minus />
            <MinusRotated />
          </Fragment>
        ),
      };
  }
};

const RoundButtonWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const RoundButtonCircle = styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => getStyle(props.type, props.inactive, props.theme.COLORS).size}px;
  height: ${(props) => getStyle(props.type, props.inactive, props.theme.COLORS).size}px;
  border-radius: ${(props) => getStyle(props.type, props.inactive, props.theme.COLORS).size / 2}px;
  background: ${(props) => {
    if (props.hover && !props.inactive) {
      return getStyle(props.type, props.inactive, props.theme.COLORS).hover;
    }
    return getStyle(props.type, props.inactive, props.theme.COLORS).color;
  }};
  ${({ inactive }) => isWeb && (inactive ? 'cursor: default;' : ' cursor: pointer;')}
`;

const Minus = styled.View`
  height: 2px;
  background: white;
  width: 49%;
`;

const MinusRotated = styled(Minus)`
  height: 2px;
  background: white;
  width: 49%;
  top: -1px;
  position: relative;
  transform: rotate(90deg);
`;

const RoundButton = ({
  testID,
  onPress,
  type,
  inactive,
  acceptInactiveOnPress,
  theme,
  accessibilityLabel,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <MouseView onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <RoundButtonWrapper>
        <TouchableOpacity
          testID={testID}
          accessibilityLabel={accessibilityLabel}
          accessibilityRole="button"
          onPress={onPress}
          disabled={inactive && !acceptInactiveOnPress}
          accessibilityState={{
            disabled: inactive && !acceptInactiveOnPress,
          }}
        >
          <RoundButtonCircle type={type} inactive={inactive} hover={hover}>
            {getStyle(type, inactive, theme.COLORS).content}
          </RoundButtonCircle>
        </TouchableOpacity>
      </RoundButtonWrapper>
    </MouseView>
  );
};

RoundButton.defaultProps = {
  onPress: undefined,
};

RoundButton.propTypes = {
  onPress: PropTypes.func,
  testID: PropTypes.string,
  type: PropTypes.oneOf(['next', 'back', 'minus', 'plus']),
  inactive: PropTypes.bool,
  acceptInactiveOnPress: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  accessibilityLabel: PropTypes.string,
};

RoundButton.defaultProps = {
  type: 'plus',
  inactive: false,
  acceptInactiveOnPress: false,
  testID: undefined,
  accessibilityLabel: undefined,
};

export default withTheme(RoundButton);
