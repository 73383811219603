const { default: config } = require('config');

/* NOTE: For real-life test and simulation purposes we have to exclude
staging environment exclusively when determing if we are in a test/dev environment.
This is only relevant for fetching tax and pensionsInfo */
export const getIsDevOrTest = () => {
  const environment = config.env;
  const isDevBuild = process.env.IS_DEV_BUILD || __DEV__;

  if (
    ['test', 'preview', 'dev'].includes(environment) ||
    (isDevBuild && environment === 'default')
  ) {
    return true;
  }

  return false;
};
