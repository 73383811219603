import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import { initFunc } from 'helpers/props';
import VerifyCode from 'areas/profile/VerifyCode';
import { actions as formActions } from 'react-formed';
import {
  getContactInfo,
  validateContactInformationToken,
  verifyContactInformation,
  getPendingVerifications,
} from 'actions/profile';
import { dismiss } from 'helpers/navigation';
import { handleValidationErrors, navigateToProfile, navigateToProfileModal } from './helpers';
import { VERIFY_EMAIL } from 'areas/profile/trackingIds';
import Tracking from 'services/tracking';

const VerifyEmail = ({ i18n, onVerify, onResendCode, hasError, route, navigation }) => {
  const email = route?.params?.email;
  const hasBackButton = route?.params?.hasBackButton;
  const onVerifyCallback = useCallback(
    (code) => {
      if (onVerify) {
        onVerify(code);
      }
    },
    [onVerify]
  );

  return (
    <VerifyCode
      heading={i18n.t('Bekræft e-mail')}
      description={i18n.t('Indtast den 6-cifrede kode, som du har modtaget fra os.')}
      subtitle={i18n.t('Vi har sendt koden til {{email}}', { email })}
      formName="verifyEmail"
      value={email}
      hasError={hasError}
      navigation={navigation}
      hasBackButton={hasBackButton}
      isModal
      onVerify={onVerifyCallback}
      onResendCode={() => {
        if (onResendCode) {
          onResendCode(email);
        }
      }}
      i18n={i18n}
    />
  );
};

VerifyEmail.propTypes = {
  i18n: PropTypes.object.isRequired,
  onVerify: initFunc.isRequired,
  onResendCode: initFunc.isRequired,
  hasError: PropTypes.bool,
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

VerifyEmail.defaultProps = {
  hasError: false,
};

const init = ({ action, getProps, setProps }) =>
  action(
    async () => {
      const { showError, i18n, dispatch, navigation, route, showNotification } = getProps();

      const standaloneFlow = route.params?.standaloneFlow;

      dispatch(formActions.clear('verifyEmail'));

      const onVerify = (token) =>
        action(
          async () => {
            try {
              setProps({
                hasError: false,
              });

              await dispatch(validateContactInformationToken({ type: 'email', token }));
              await dispatch([getContactInfo(), getPendingVerifications()]);

              Tracking.trackEvent(VERIFY_EMAIL.VERIFICATION_SUCCESS);

              if (standaloneFlow) {
                dismiss(navigation)();
                navigateToProfileModal(navigation);
              } else {
                navigateToProfile(navigation);
              }
            } catch (e) {
              Tracking.trackEvent(VERIFY_EMAIL.VERIFICATION_FAIL);

              setProps({
                hasError: true,
              });
              handleValidationErrors(showError, i18n, e);
            }
          },
          { loader: true }
        );
      const onResendCode = async (email) => {
        await dispatch(verifyContactInformation({ email }));

        Tracking.trackEvent(VERIFY_EMAIL.SEND_CODE_AGAIN);
        showNotification(i18n.t('En ny kode er blevet afsendt. Den gælder i 24 timer.'));
      };
      return {
        onVerify,
        onResendCode,
      };
    },
    { loader: true }
  );

export default withScreenContainer({
  init,
})(VerifyEmail);
