import { get, post, remove } from 'actions/api';
import * as actionTypes from 'constants/actionTypes';

/**
 * NOTE: the html content of preconditions is stored here for testing modifications
 * the html needs to be minified before testing on web and updating it in the consents API
 * since web rendering treats all line breaks as <br/>
 */
// import mockPreconditions from '__mocks__/endpoints/consents/preconditions';

export const getConsents = () => get(actionTypes.CONSENTS, 'api', '/consent/v2/consents');

export const getConsent = (key) => get(actionTypes.CONSENT, 'api', `/consent/v2/consents/${key}`);

export const giveConsent = (id) =>
  post(actionTypes.GIVE_CONSENT, { id }, 'api', `/consent/v2/consents/${id}`);

export const retractConsent = (id) =>
  remove(actionTypes.RETRACT_CONSENT, 'api', `/consent/v2/consents/${id}`, {
    requestInfo: {
      id,
    },
  });
