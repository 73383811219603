import React from 'react';
import PropTypes from 'prop-types';
import BasePage, {
  TopContent,
  Actions,
  ActionShape,
  extractBottomActions,
} from '@sp/ui/pages/BasePage';

const FlexPage = ({
  title,
  heading,
  description,
  children,
  actions,
  backButton,
  customHeader,
  testID,
  hasBackButton,
  hasNotifications,
  actionInfo,
  accessibilitySummary,
}) => {
  const { bottomActions, otherActions } = extractBottomActions(actions);

  return (
    <BasePage
      title={title}
      actions={otherActions}
      customHeader={customHeader}
      backButton={hasBackButton ? backButton : null}
      actionInfo={actionInfo}
      testID={testID}
      hasNotifications={hasNotifications}
      accessibilitySummary={accessibilitySummary}
    >
      {(heading || description) && <TopContent heading={heading} description={description} />}
      {children}
      {bottomActions.length > 0 && <Actions actions={bottomActions} actionInfo={actionInfo} />}
    </BasePage>
  );
};

FlexPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  customHeader: PropTypes.node,
  backButton: PropTypes.node,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  hasBackButton: PropTypes.bool,
  hasNotifications: PropTypes.bool,
  actionInfo: PropTypes.string,
};

FlexPage.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  children: undefined,
  actions: undefined,
  backButton: undefined,
  customHeader: undefined,
  testID: undefined,
  hasBackButton: true,
  hasNotifications: true,
  actionInfo: undefined,
  accessibilitySummary: undefined,
};

export default FlexPage;
