import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';

const H2 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.H2.MOBILE}px;
`;

H2.defaultProps = {
  fontWeight: 'bold',
};

export default H2;
