import type { TransferState } from 'reducers/transfer';

interface StoreSlice {
  areas: { transfer: TransferState };
}

export const selectTransferData = (state: StoreSlice) => state.areas.transfer?.data;

export const selectTransferForm = (state: StoreSlice) => state.areas.transfer?.transferForm;

export const selectHasOneTransferableTarget = (state: StoreSlice) =>
  state.areas.transfer?.data?.targets?.length === 1;

export const selectSelectedTransferableSources = (state: StoreSlice) =>
  selectTransferForm(state)?.transferableSources?.filter(({ isSelected }) => isSelected);

export const selectGeneralTransferTerms = (state: StoreSlice) =>
  state.areas.transfer?.transferForm?.consents?.find(({ key }) => key === 'GeneralTransferTerms')
    ?.text;
