import React, { useEffect, useState, useRef } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';
import Icon from '@sp/ui/base/Icon';
import { Body2 } from '@sp/ui/v2/typography';
import { getUpdatedOptionsAndSelected } from '@sp/ui/base/inputs/Selector/index.shared';
import FadeInOut from '@sp/ui/v2/animations/FadeInOut';
import {
  DROPDOWN_ICON_SIZE,
  Dropdown,
  LeftWrapper,
  RowWrapper,
  DropdownItem,
  DropdownWrapper as DropdownWrapperBorderStyle,
} from './shared.js';

const DROPDOWN_LIST_OFFSET = 4;

const DropdownWrapper = Conditional({
  desktop: styled(DropdownWrapperBorderStyle)`
    position: absolute;
    width: 100%;
    bottom: 0;
    transform: translateY(${({ height }) => height + DROPDOWN_LIST_OFFSET}px);
    overflow: hidden;
  `,
  default: styled(DropdownWrapperBorderStyle)`
    margin-top: ${DROPDOWN_LIST_OFFSET}px;
    overflow: hidden;
  `,
});

const SelectorWithoutPicker = ({
  options,
  onSelect,
  placeholder,
  defaultValue,
  before,
  theme,
  overwriteDefaultButtonStyle,
  overwriteCurrentSelection,
  onPressDropdownRequest,
  isInactive,
  renderItem,
  hasError,
}) => {
  const { updatedOptions, selected } = getUpdatedOptionsAndSelected(options, defaultValue);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selection, setSelection] = useState();
  const [data, setData] = useState([]);
  const [height, setHeight] = useState(0);
  const [hover, setHover] = useState();

  const dropdownElement = useRef();

  useEffect(() => {
    if (overwriteCurrentSelection) {
      setSelection(overwriteCurrentSelection);
    }
  }, [overwriteCurrentSelection]);

  const handleClickOutside = (e) => {
    if (!dropdownElement.current.contains(e.target) && isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleOnDropdownButtonPress = () => {
    if (!isInactive) {
      setData(updatedOptions);
      setIsDropdownOpen(!isDropdownOpen);

      if (onPressDropdownRequest) onPressDropdownRequest(!isDropdownOpen);
    }
  };

  return (
    <>
      {selection && overwriteDefaultButtonStyle ? (
        <Dropdown
          isInactive={isInactive}
          onPress={handleOnDropdownButtonPress}
          ref={dropdownElement}
          hasError={hasError}
        >
          {renderItem(
            selection.value,
            handleOnDropdownButtonPress,
            <Icon
              name={isDropdownOpen ? 'arrowUpCentered' : 'arrowDownCentered'}
              width={DROPDOWN_ICON_SIZE}
              height={DROPDOWN_ICON_SIZE}
              fill={theme.COLORS.GRAPH_INCOME}
            />
          )}
        </Dropdown>
      ) : (
        <Dropdown
          isInactive={isInactive}
          onPress={handleOnDropdownButtonPress}
          ref={dropdownElement}
          hasError={hasError}
        >
          <RowWrapper>
            <LeftWrapper>
              <Body2 fontWeight="normal" secondaryColor>
                {before}
                {(selection && selection.name) || selected.name || placeholder}
              </Body2>
            </LeftWrapper>
            <Icon
              name={isDropdownOpen ? 'arrowUpCentered' : 'arrowDownCentered'}
              width={DROPDOWN_ICON_SIZE}
              height={DROPDOWN_ICON_SIZE}
              fill={theme.COLORS.GRAPH_INCOME}
            />
          </RowWrapper>
        </Dropdown>
      )}
      <FadeInOut visible={isDropdownOpen} zIndex={1}>
        <DropdownWrapper
          isInactive={isInactive}
          onLayout={(event) => setHeight(event.nativeEvent.layout.height)}
          height={height}
          hasError={hasError}
        >
          {data?.map((option, i) =>
            typeof renderItem === 'function' ? (
              <View
                key={option.name}
                onMouseEnter={() => setHover(i)}
                onMouseLeave={() => setHover(undefined)}
                style={hover === i ? { backgroundColor: theme.COLORS.GRAPH_NEUTRAL } : {}}
              >
                {renderItem(option.name, () => {
                  setSelection(option);
                  setIsDropdownOpen(false);
                  if (typeof onSelect === 'function') onSelect(option);
                })}
              </View>
            ) : (
              <DropdownItem
                key={option.name}
                onMouseEnter={() => setHover(i)}
                onMouseLeave={() => setHover(undefined)}
                style={hover === i ? { backgroundColor: theme.COLORS.GRAPH_NEUTRAL } : {}}
                onPress={() => {
                  setIsDropdownOpen(false);
                  setSelection(option);
                  if (typeof onSelect === 'function') onSelect(option);
                }}
              >
                <Body2 fontWeight="normal" secondaryColor>
                  {option.name}
                </Body2>
              </DropdownItem>
            )
          )}
        </DropdownWrapper>
      </FadeInOut>
    </>
  );
};

SelectorWithoutPicker.propTypes = {
  isInactive: PropTypes.bool,
  overwriteDefaultButtonStyle: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  onSelect: PropTypes.func,
  renderItem: PropTypes.func,
  onPressDropdownRequest: PropTypes.func,
  before: PropTypes.node,
  overwriteCurrentSelection: PropTypes.object,
  options: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  hasError: PropTypes.bool,
};

SelectorWithoutPicker.defaultProps = {
  onSelect: undefined,
  setValue: undefined,
  placeholder: undefined,
  overwriteCurrentSelection: undefined,
  onPressDropdownRequest: undefined,
  isInactive: false,
  overwriteDefaultButtonStyle: false,
  border: true,
  withSearch: false,
  defaultValue: '',
  before: undefined,
  renderItem: undefined,
  hasError: false,
};

export default withTheme(SelectorWithoutPicker);
