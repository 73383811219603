import Conditional from 'decorators/conditional';
import Mobile from './index.mobile';
import Desktop from './index.desktop';
import Tablet from './index.tablet';

export default Conditional({
  desktop: Desktop,
  tablet: Tablet,
  mobile: Mobile,
});
