import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import { H6 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';

const RIGHT_CONTENT_MIN_HEIGHT = 122;

const RowContent = styled.View`
  padding: ${(props) => props.theme.SPACINGS.m}px ${(props) => props.theme.SPACINGS.md}px;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.COLORS.PRIMARY_VARIANT1};
`;

const LeftContent = styled.View`
  margin-right: auto;
  flex: 1;
`;

const IconWrapper = styled.View`
  padding-right: ${(props) => props.theme.SPACINGS.md}px;
`;

const RightContent = styled.View`
  min-width: ${RIGHT_CONTENT_MIN_HEIGHT}px;
  align-items: flex-end;
`;

const Row = ({
  componentLeft,
  textLeft,
  textRight,
  componentRight,
  iconName,
  theme,
  fontWeight,
}) => (
  <RowContent>
    <LeftContent>{componentLeft || <H6 fontWeight={fontWeight}>{textLeft}</H6>}</LeftContent>
    {iconName && (
      <IconWrapper>
        <Icon fill={theme.COLORS.PRIMARY} name={iconName} />
      </IconWrapper>
    )}
    <RightContent>{componentRight || <H6 fontWeight={fontWeight}>{textRight}</H6>}</RightContent>
  </RowContent>
);

Row.propTypes = {
  textLeft: PropTypes.string,
  componentLeft: PropTypes.node,
  textRight: PropTypes.string,
  componentRight: PropTypes.node,
  iconName: PropTypes.string,
  theme: PropTypes.object.isRequired,
  fontWeight: PropTypes.string,
};

Row.defaultProps = {
  fontWeight: 'regular',
  textLeft: undefined,
  componentLeft: undefined,
  textRight: undefined,
  componentRight: undefined,
  iconName: undefined,
};

export default withTheme(Row);
