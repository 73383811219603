import React from 'react';
import PropTypes from 'prop-types';
import BasePage, { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import { Result } from '@sp/ui/pages/ResultPage';
import styled from 'styled-components/native';
import { enhanceCustomerBlock, getActions } from './index.shared';
import BlockLinks from './BlockLinks';

const Wrapper = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CustomerBlock = ({ blockMessage, i18n, theme, logout }) => {
  // NOTE: this is needed because the blockMessage gets cleared
  // in the store by logout while this screen is still mounted
  if (!blockMessage) {
    return null;
  }

  const { title, reason, actions: actionData } = blockMessage;

  const actions = getActions(i18n, logout);

  return (
    <SafeAreaWrapper>
      <BasePage actions={actions} hasHeader={false}>
        <Wrapper>
          <Result
            iconName="warning"
            iconColor={theme.COLORS.PRIMARY}
            heading={title}
            description={reason}
          />
          {actionData && <BlockLinks items={actionData} />}
        </Wrapper>
      </BasePage>
    </SafeAreaWrapper>
  );
};

CustomerBlock.propTypes = {
  blockMessage: PropTypes.shape({
    title: PropTypes.string,
    reason: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  logout: PropTypes.func,
};

CustomerBlock.defaultProps = {
  blockMessage: undefined,
  logout: undefined,
};

export default enhanceCustomerBlock(CustomerBlock);
