import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { BodyInput } from '@sp/ui/typography';
import { isAndroid } from '@sp/ui/helpers/device';

const SEARCH_WRAPPER_MARGIN = 16;
const SEARCH_WRAPPER_VERTICAL_PADDING = isAndroid ? 0 : 8;
const SEARCH_WRAPPER_HORIZONTAL_PADDING = 14;
const SEARCH_INPUT_LEFT_PADDING = 7;
const ICON_SIZE = 20;

const SearchWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ background }) => `background: ${background};`}
  padding: ${SEARCH_WRAPPER_VERTICAL_PADDING}px ${SEARCH_WRAPPER_HORIZONTAL_PADDING}px;
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  margin: ${SEARCH_WRAPPER_MARGIN}px;
`;

const Input = styled(BodyInput)`
  flex: 1;
  padding-left: ${SEARCH_INPUT_LEFT_PADDING}px;
`;

// TODO: change the alpha color to a standard one after Sissel adapts it in zeplin
const getStyle = (type, theme) => {
  switch (type) {
    case 'secondary':
      return {
        color: theme.COLORS.SECONDARY_BACKGROUND,
        background: 'rgba(255, 255, 255, 0.1)',
      };
    default:
      return {
        color: theme.COLORS.PRIMARY_TEXT,
        background: theme.COLORS.PRIMARY_BACKGROUND,
      };
  }
};

const SearchInput = ({ placeholder, onChangeText, type, theme }) => (
  <SearchWrapper background={getStyle(type, theme).background}>
    <Icon height={ICON_SIZE} width={ICON_SIZE} name="search" fill={getStyle(type, theme).color} />
    <Input
      autoCorrect={false}
      underlineColorAndroid="transparent"
      selectionColor={theme.COLORS.PRIMARY}
      placeholder={placeholder}
      placeholderTextColor={getStyle(type, theme).color}
      onChangeText={onChangeText}
      color={getStyle(type, theme).color}
    />
  </SearchWrapper>
);

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary']),
  theme: PropTypes.object.isRequired,
};

SearchInput.defaultProps = {
  type: 'primary',
};

export default withTheme(SearchInput);
