import { createRef } from 'react';

class RefCache {
  refs = {};

  create() {
    const id = Symbol('refCache');

    this.refs[id] = createRef();
    return id;
  }

  get(id) {
    return this.refs[id];
  }

  remove(id) {
    delete this.refs[id];
  }
}

export default new RefCache();
