import * as routeNames from 'constants/routeNames';
import { fetchTax, fetchPensionsInfo } from 'actions/fetchTaxAndPensionsInfo';

import getCompletedNavParams from './getCompletedNavParams';

const getCustomerNavParams = async (profileCompletenessInfo, state, dispatch) => {
  const navigateToFetchTaxPensionsInfoIntroduction = routeNames.FETCH_TAX_AND_PENSIONSINFO;

  if (!profileCompletenessInfo.taxStatement) {
    dispatch(fetchTax({ isOnboarding: true }));
    return navigateToFetchTaxPensionsInfoIntroduction;
  }

  if (!profileCompletenessInfo.pensionInfo) {
    dispatch(fetchPensionsInfo({ isOnboarding: true }));
    return navigateToFetchTaxPensionsInfoIntroduction;
  }

  return getCompletedNavParams(state);
};

export default getCustomerNavParams;
