import React from 'react';
import { H2 } from '@sp/ui/typography';
import PropTypes from 'prop-types';
import Animated from 'react-native-reanimated';
import styled, { withTheme } from 'styled-components/native';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import Conditional from 'decorators/conditional';

import Description from '../Description';

const WrapperMobile = styled.View`
  align-items: center;
  padding: ${(props) => props.theme.SPACINGS.lg}px ${(props) => props.theme.SPACINGS.md}px
    ${(props) => props.theme.SPACINGS.lg}px;
`;

const WrapperTablet = styled.View`
  align-items: center;
  padding: ${(props) =>
    `${props.theme.SPACINGS.xxl}px ${props.theme.SPACINGS.xxl}px ${props.theme.SPACINGS.lg}px`};
`;

const WrapperDesktop = styled.View`
  align-items: center;
  padding: 0px
    ${(props) =>
      `${props.theme.SPACINGS.xxl}px ${props.disableBottomPadding ? 0 : props.bottomPadding}px`};
`;

const Wrapper = Conditional({
  mobile: WrapperMobile,
  tablet: WrapperTablet,
  desktop: WrapperDesktop,
});

export const getHeading = (heading) =>
  renderStringOrComponent(heading, H2, { textAlign: 'center' });

const getDescription = (description) =>
  renderStringOrComponent(description, Description, { textAlign: 'center' });

const TopContent = ({ heading, description, disableBottomPadding, animation, theme }) => (
  <Wrapper
    disableBottomPadding={disableBottomPadding}
    bottomPadding={description ? theme.SPACINGS.lg : theme.SPACINGS.xxl}
  >
    <Animated.View
      style={
        animation ?? {
          ...animation,
        }
      }
    >
      {heading && getHeading(heading)}
    </Animated.View>
    {description && getDescription(description)}
  </Wrapper>
);

TopContent.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  animation: PropTypes.object,
  disableBottomPadding: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

TopContent.defaultProps = {
  heading: null,
  description: null,
  animation: undefined,
  disableBottomPadding: false,
};

export default withTheme(TopContent);
