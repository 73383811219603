import React, { useState, useEffect, useMemo } from 'react';
import { StyleSheet, TextInputProps as DefaultTextInputProps } from 'react-native';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components/native';
import { isAndroid, isWeb } from '@sp/ui/helpers/device';

const { default: Conditional } = require('decorators/conditional');
const { renderStringOrComponent } = require('@sp/ui/helpers/component');
const { BodyInput, Body } = require('@sp/ui/typography');
const {
  emailValidator,
  currencyAmountValidator,
  currencyAmountWithZeroValidator,
  thirtyTwoLengthValidator,
} = require('@sp/ui/helpers/validators');

const TEXT_INPUT_VERTICAL_OFFSET = 18;
const TEXT_INPUT_VERTICAL_ANDROID_OFFSET = 12;

const DEFAULT_TOP_MARGIN = 24;
const DESKTOP_TEXT_INPUT_WIDTH = 288;
const TEXT_INPUT_HEIGHT = 56;

const WrapperBase = styled.View<{
  flex: number | undefined;
  marginTop: number;
  hasError: boolean;
}>`
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
  ${({ hasError, theme }) => `
    padding: 0px ${theme.SPACINGS.md}px;
    border-color: ${hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_BORDER};
    border-radius: ${theme.DEFAULTS.BORDER_RADIUS}px;
  `}
  margin-top: ${({ marginTop }) => marginTop}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: ${TEXT_INPUT_HEIGHT}px;
  border-width: ${StyleSheet.hairlineWidth}px;
`;

const WrapperMobile = styled(WrapperBase)`
  width: 100%;
`;

const WrapperDesktop = styled(WrapperBase)`
  width: ${DESKTOP_TEXT_INPUT_WIDTH}px;
`;

const Wrapper = Conditional({
  mobile: WrapperMobile,
  default: WrapperDesktop,
});

const Input = styled(BodyInput)`
  padding: ${isAndroid ? TEXT_INPUT_VERTICAL_ANDROID_OFFSET : TEXT_INPUT_VERTICAL_OFFSET}px 0px;
  flex: 1;
  color: ${({ hasError, theme }) =>
    hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_TEXT};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px;` : '')}
  ${({ multiline }) => (isWeb && multiline ? 'resize: vertical;' : '')}
`;

const Before = styled.View`
  ${({ theme }) => `
    margin-right: ${theme.SPACINGS.sm}px;
    width: ${theme.SPACINGS.lg}px;
  `}
  align-items: center;
  justify-content: center;
`;

const After = styled.View`
  padding-left: ${({ theme }) => (isWeb ? theme.SPACINGS.md : 0)}px;
`;

type Validator = 'email' | 'currencyAmount' | 'currencyAmountWithZero' | 'thirtyTwoLength';

interface TextInputProps extends DefaultTextInputProps {
  onValidityChange: (isValid: boolean) => {};
  before?: React.ReactNode;
  after?: React.ReactNode;
  flex?: number;
  marginTop: number;
  hasError?: boolean;
  customValidator?: (text: string) => boolean;
  validator?: Validator;
  optional?: boolean;
}

const TextInput = ({
  before,
  after,
  flex,
  marginTop,
  hasError,
  validator,
  customValidator,
  onChangeText,
  onValidityChange,
  optional,
  testID,
  ...props
}: TextInputProps) => {
  const [hasValidationError, setHasValidationError] = useState(false);
  const [showError, setShowError] = useState(true);
  const [hasValidated, setHasValidated] = useState(false);
  const [value, setValue] = useState('');

  const { COLORS } = useTheme();

  const validatorFn = useMemo(() => {
    let result;
    switch (validator) {
      case 'email':
        result = emailValidator;
        break;
      case 'currencyAmount':
        result = currencyAmountValidator;
        break;
      case 'currencyAmountWithZero':
        result = currencyAmountWithZeroValidator;
        break;
      case 'thirtyTwoLength':
        result = thirtyTwoLengthValidator;
        break;
      default:
        break;
    }

    return result || customValidator;
  }, [validator, customValidator]);

  useEffect(() => {
    if (onValidityChange && validatorFn && props.defaultValue && !hasValidated) {
      onValidityChange(validatorFn(props.defaultValue));
    }
  }, [hasValidated, onValidityChange, props.defaultValue, validatorFn]);

  const changeTextHandler = useMemo(() => {
    return validatorFn || onChangeText
      ? (text) => {
          setValue(text);

          if (validatorFn) {
            const isInputValid = validatorFn(text);
            setHasValidationError(!isInputValid);
            if (onValidityChange) {
              // NOTE: we might need to debouce this as it will cause re-renders on the parent
              onValidityChange(isInputValid);
            }
          }

          if (onChangeText) {
            onChangeText(text);
          }

          setHasValidated(true);
          setShowError(!(optional && text === ''));
        }
      : undefined;
  }, [onChangeText, onValidityChange, optional, validatorFn]);

  return (
    <Wrapper
      flex={flex}
      marginTop={marginTop}
      hasError={(showError && hasValidationError) || hasError}
    >
      {before && <Before>{before}</Before>}
      <Input
        underlineColorAndroid="transparent"
        selectionColor={COLORS.PRIMARY}
        placeholderTextColor={COLORS.GRAPH_INCOME}
        hasError={(showError && hasValidationError) || hasError}
        testID={testID}
        value={value}
        returnKeyType="done"
        {...props}
        onChangeText={changeTextHandler}
      />
      {after && <After>{renderStringOrComponent(after, Body)}</After>}
    </Wrapper>
  );
};

TextInput.propTypes = {
  before: PropTypes.node,
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  flex: PropTypes.string,
  marginTop: PropTypes.number,
  minHeight: PropTypes.number,
  hasError: PropTypes.bool,
  onChangeText: PropTypes.func,
  validator: PropTypes.oneOf([
    'email',
    'currencyAmount',
    'currencyAmountWithZero',
    'thirtyTwoLength',
  ]),
  customValidator: PropTypes.func,
  onValidityChange: PropTypes.func,
  optional: PropTypes.bool,
  defaultValue: PropTypes.string,
  testID: PropTypes.string,
};

TextInput.defaultProps = {
  before: undefined,
  after: undefined,
  flex: undefined,
  marginTop: DEFAULT_TOP_MARGIN,
  minHeight: undefined,
  hasError: false,
  onChangeText: undefined,
  validator: undefined,
  customValidator: undefined,
  onValidityChange: undefined,
  optional: false,
  defaultValue: undefined,
  testID: undefined,
};

export default TextInput;
