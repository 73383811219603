/*
 *
 * Function that maps currency codes to currency symbols.
 *
 */

const currencySymbolMap = {
  DKK: 'kr.',
};

export default (currencyCode) => {
  if (typeof currencyCode !== 'string') {
    return undefined;
  }

  const code = currencyCode.toUpperCase();
  const hasCodeProperty = Object.prototype.hasOwnProperty.call(currencySymbolMap, code);

  if (!hasCodeProperty) {
    return undefined;
  }

  return currencySymbolMap[code];
};
