import { selectAppointmentsByOrganizer } from './index';

const selectAvailableLocations = (state) => {
  const appointments = selectAppointmentsByOrganizer(state);

  const resultSet = appointments.reduce((acc, value) => {
    if (value.location) {
      acc.add(value.location);
    }
    return acc;
  }, new Set());

  return Array.from(resultSet);
};

export default selectAvailableLocations;
