import Conditional from 'decorators/conditional';
import Mobile from './index.mobile';
import Desktop from './index.desktop';

import AccessibilitySummary from './AccessibilitySummary';
import Card from './Card';
import NarrowContent from './NarrowContent';
import WideContent from './WideContent';
import Actions from './Actions';
import { groupActions, ActionShape, extractBottomActions } from './Actions/index.shared';
import Description from './Description';
import TopContent from './TopContent';
import TopGradientContent from './TopGradientContent';
import BackButton from './BackButton';
import SafeAreaWrapper from './SafeAreaWrapper';
import * as animations from './animations';

export {
  AccessibilitySummary,
  Card,
  NarrowContent,
  Actions,
  Description,
  TopContent,
  TopGradientContent,
  BackButton,
  WideContent,
  ActionShape,
  groupActions,
  SafeAreaWrapper,
  extractBottomActions,
  animations,
};

export default Conditional({
  default: Mobile,
  desktop: Desktop,
  mobile: Mobile,
});
