import { create } from 'helpers/middleware';
import { downloadAndOpenOnWeb, downloadAndSaveOnWeb } from 'helpers/documents';
import * as routeNames from 'constants/routeNames';
import { getServiceUrl, getAccessToken } from 'actions/api';
import { getFolders } from 'actions/documents';
import { selectContents } from 'selectors/documents';
import { navigate } from 'helpers/navigation';
import { isFirefox } from 'helpers/browser';
import { getLatestDepotAccount } from './getLatestDepotAccount';
import { isWeb } from 'helpers/platform';

const middleware = create('@@DOCUMENTS');

const mergePoliceFolders = (documents, folders, policyId) => {
  if (folders.length > 0) {
    const nestedDocuments = folders.concat
      .apply(
        [],
        folders.map((item) => (item.police === policyId ? item.documents : undefined))
      )
      .filter(Boolean);

    return documents.concat(nestedDocuments);
  }
  return documents;
};

const openDocument = async (action, next, store) => {
  let fileToOpen;
  const { policyId, type, onBackPress } = action.payload;

  const state = store.getState();
  const hasFolders = !!state.areas.documents.root;
  if (!hasFolders) {
    await next(getFolders());
  }

  const { documents, folders } = selectContents(store.getState(), policyId, true);

  // We need to merge folders in case a police has nested folders
  const documentsList = mergePoliceFolders(documents, folders, policyId);

  if (type === 'openLatestDepotAccount') {
    fileToOpen = getLatestDepotAccount(documentsList);
  } else {
    fileToOpen = documentsList.find((file) => file.type === type);
  }

  const token = await store.dispatch(getAccessToken());
  const apiUrl = await store.dispatch(getServiceUrl('api'));
  const absoluteUrl = `${apiUrl}${fileToOpen.downloadUrl}`;

  if (isWeb) {
    // FIXME: on Firefox documents can't be opened in a new window: https://bugzilla.mozilla.org/show_bug.cgi?id=1582115
    if (isFirefox()) {
      await downloadAndSaveOnWeb(absoluteUrl, token, fileToOpen.title);
    } else {
      const win = global.window.open(undefined, '_blank');
      win.location.href = `${global.location.protocol}//${global.location.host}?screen=${routeNames.LOADING}&preventAppStart=true`;
      downloadAndOpenOnWeb(absoluteUrl, token, win);
    }
    next({
      type: 'DOCUMENT_OPENED',
    });
  } else {
    // TODO RN5: Test this
    navigate({
      name: routeNames.DOCUMENT_VIEW,
      params: {
        url: encodeURIComponent(absoluteUrl),
        title: encodeURIComponent(fileToOpen.title),
        onBackPress,
      },
    });
  }
};

const actions = (store) => ({
  OPEN_DOCUMENT: (action, next) => openDocument(action, next, store),
});

export default (store) => (next) => (action) => middleware(next, action, actions(store));
