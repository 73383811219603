import React, { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { Trans } from 'react-i18next';
import {
  selectDisposableIncomeOptions,
  selectCalculationSettings,
  selectPensionsInfoDataUpdateDate,
  selectTaxDataUpdateDate,
} from 'selectors/dashboard';
import { selectChildrenData } from 'selectors/children';
import { fetchTaxAndPensionsInfo } from 'actions/fetchTaxAndPensionsInfo';
import { getCalculationProfile } from 'actions/calculations';
import { getChildren } from 'actions/children';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import useAsyncInit from 'hooks/useAsyncInit';
import usePrerenderScreen from 'hooks/usePrerenderScreen';
import RetirementAgeRow from './components/RetirementAgeRow';
import DisposableIncomeRow from './components/DisposableIncomeRow';
import PublicServicesRow from './components/PublicServicesRow';
import TaxInfoRow from './components/TaxInfoRow';
import SavingsRow from './components/SavingsRow';
import TaxationRow from './components/TaxationRow';
import ChildrenRow from './components/ChildrenRow';
import RowsWrapper from './components/RowsWrapper';
import PageContentWrapper from './components/PageContentWrapper';
import { ADJUST_CALCULATION_SCREEN } from 'constants/testIds/dashboard/adjustCalculation';
import type { RootState } from 'store';

const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { default: Link } = require('@sp/ui/v2/base/Link');
const { default: ScrollPage } = require('@sp/ui/v2/pages/ScrollPage');
const { default: Margin } = require('@sp/ui/base/Margin');
const { getIncome } = require('actions/dashboard');
const { formatDate } = require('helpers/string');
const { selectIsDeviceTypeDesktop } = require('selectors/device');
const { H3, Body } = require('@sp/ui/v2/typography');
const { isEmpty } = require('helpers/object');

export const selectAdjustCalculation = (state: RootState) => ({
  disposableIncomeOptions: selectDisposableIncomeOptions(state),
  calculationSettings: selectCalculationSettings(state),
  childrenData: selectChildrenData(state),
  taxDataUpdateDate: selectTaxDataUpdateDate(state),
  pensionsInfoDataUpdateDate: selectPensionsInfoDataUpdateDate(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
});

const AdjustCalculation: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const dispatch = useAppDispatch();

  const {
    isDesktop,
    taxDataUpdateDate,
    pensionsInfoDataUpdateDate,
    disposableIncomeOptions,
    calculationSettings,
    childrenData,
  } = useAppSelector(selectAdjustCalculation, shallowEqual);

  const updateTaxAndPensionsInfo = useCallback(
    () => dispatch(fetchTaxAndPensionsInfo()),
    [dispatch]
  );

  useAsyncInit(
    async () => {
      const actions = [];

      if (!disposableIncomeOptions) {
        actions.push(getIncome());
      }

      if (!childrenData) {
        actions.push(getChildren());
      }

      if (isEmpty(calculationSettings)) {
        actions.push(getCalculationProfile());
      }

      if (actions.length) {
        await dispatch(actions);
      }
    },
    [],
    {
      error: {
        type: 'blocking',
        retry: true,
      },
      loading: {
        type: 'blocking',
      },
    }
  );

  const prerender = usePrerenderScreen();

  if (prerender) {
    return prerender;
  }

  return (
    <ScrollPage animateTitle testID={ADJUST_CALCULATION_SCREEN}>
      <PageContentWrapper ignoreDefaultVerticalPadding>
        <Margin marginBottom="md">
          <H3 textAlign="center">{i18n.t('dashboard|adjustCalculationTitle')}</H3>
        </Margin>
        <Margin horizontal="md" marginBottom="md">
          <Body>{i18n.t('dashboard|adjustCalculationDescription')}</Body>
        </Margin>
        <RowsWrapper>
          <RetirementAgeRow />
          <ChildrenRow />
          <DisposableIncomeRow />
          <PublicServicesRow />
          <TaxationRow />
          <SavingsRow />
          <TaxInfoRow />
        </RowsWrapper>
        <Margin horizontal="md" marginTop="md">
          <Trans
            i18nKey="dashboard|linkUpdateInformation"
            components={{
              text: <Body />,
              linkButton: <Link isDesktop={isDesktop} onPress={updateTaxAndPensionsInfo} />,
            }}
            values={{
              taxDataUpdateDate: taxDataUpdateDate && formatDate(taxDataUpdateDate),
              pensionsInfoDataUpdateDate:
                pensionsInfoDataUpdateDate && formatDate(pensionsInfoDataUpdateDate),
            }}
          />
        </Margin>
      </PageContentWrapper>
    </ScrollPage>
  );
};

export default withScreenContainer({
  i18ns: ['dashboard'],
})(AdjustCalculation);
