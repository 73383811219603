import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Picker } from '@react-native-picker/picker';
import { Button } from '@sp/ui/base/buttons';
import PickerIOSControls from './PickerIOSControls';
import { isIOS, isAndroid, isWeb } from '@sp/ui/helpers/device';

const Wrapper = styled.View`
  ${isIOS
    ? `
  position: absolute;
  bottom: 0;
  width: 100%;
`
    : ''}
  ${isAndroid
    ? `
  flex: 1;
  justify-content: space-between;
  padding: 0px ${({ theme }) => theme.SPACINGS.md}px;
`
    : ''}
`;

const PickerWrapper = styled.View`
  background: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  width: 100%;
`;

const Bottom = styled.View`
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.SPACINGS.lg}px ${({ theme }) => theme.SPACINGS.md}px;
`;

// NOTE: Not using styled components, because it complains about web styles i.e.:
// border, background etc. even when Platform.OS === 'web'
const picker = {
  width: '100%',
  ...(isWeb
    ? {
        border: 'none',
        background: 'transparent',
      }
    : {}),
};

const SelectPicker = ({
  options,
  selectedValue,
  onValueChange,
  onSubmit,
  withConfirmButton,
  submitTitle,
  testID,
  accessibilityLabelClose,
}) => {
  const updatedOptions =
    options && options.map((o) => (typeof o === 'object' ? o : { label: o, value: o }));

  const getNextValue = () => {
    const currentIndex = updatedOptions.findIndex(({ value }) => value === selectedValue);
    // return last value if last value is selected.
    if (currentIndex + 1 === updatedOptions.length) {
      return updatedOptions[updatedOptions.length - 1].value;
    }
    return updatedOptions[currentIndex + 1].value || selectedValue;
  };

  const getPreviousValue = () => {
    const currentIndex = updatedOptions.findIndex(({ value }) => value === selectedValue);
    // return first value if first value is selected or value is not found
    if (currentIndex === -1 || currentIndex === 0) {
      return updatedOptions[0].value;
    }
    return updatedOptions[currentIndex - 1].value || selectedValue;
  };

  const submit = () => {
    if (!selectedValue) {
      onValueChange(options[0]);
    }
    onSubmit(selectedValue);
  };

  return (
    <Wrapper testID={testID}>
      <PickerIOSControls
        onPrevPress={() => onValueChange(getPreviousValue())}
        onNextPress={() => onValueChange(getNextValue())}
        onSubmitPress={submit}
        submitTitle={submitTitle}
        testID={testID}
        accessibilityLabelClose={accessibilityLabelClose}
      />
      <PickerWrapper>
        <Picker
          style={picker}
          testID={`${testID}Select`}
          selectedValue={selectedValue}
          onValueChange={onValueChange}
        >
          {updatedOptions &&
            updatedOptions.map(({ label, value }) => (
              <Picker.Item key={label} label={label} value={value} />
            ))}
        </Picker>
      </PickerWrapper>
      {withConfirmButton && !isIOS && (
        <Bottom>
          <Button text={submitTitle} onPress={submit} />
        </Bottom>
      )}
    </Wrapper>
  );
};

SelectPicker.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]).isRequired,
  selectedValue: PropTypes.string,
  onValueChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  withConfirmButton: PropTypes.bool,
  submitTitle: PropTypes.string.isRequired,
  testID: PropTypes.string,
  accessibilityLabelClose: PropTypes.string,
};

SelectPicker.defaultProps = {
  selectedValue: undefined,
  onValueChange: undefined,
  withConfirmButton: true,
  testID: undefined,
  accessibilityLabelClose: undefined,
};

export { PickerIOSControls };

export default withTheme(SelectPicker);
