import { getBase64FromSource } from 'helpers/fileUpload';
import {
  fileLimitValidator,
  sizeLimitValidator,
  supportedFileTypesValidator,
} from 'helpers/fileValidators';
import features from 'features';
import { isWeb } from 'helpers/platform';

export const addAttachment = (attachments, attachment) => {
  const isDuplicate = attachments.find((a) => a.id === attachment.id);

  if (isDuplicate) return attachments;

  return [
    ...attachments,
    {
      title: attachment.filename,
      ...attachment,
    },
  ];
};

export const removeAttachmentById = (attachments, id) =>
  attachments.filter((attachment) => attachment.id !== id);

export const patchAttachmentsWithBase64 = async (attachments) => {
  if (attachments && attachments.length > 0 && features.isEnabled('mailboxAddAttachments')) {
    return Promise.all(
      await attachments.map(async (attachment) => ({
        name: attachment.filename,
        content: await getBase64FromSource(isWeb ? attachment.blob : attachment.uri),
      }))
    );
  }

  return attachments;
};

export const validateAttachments = (attachments, showError, i18n) => {
  const FILE_LIMIT = 4;
  const SIZE_LIMIT = 60 * 1024 * 1024; // 60 MB
  const SUPPORTED_FILE_TYPES = [
    '.html',
    '.htm',
    '.tiff',
    '.tif',
    '.txt',
    '.docx',
    '.doc',
    '.dot',
    '.dotx',
    '.xls',
    '.xlsx',
    '.jpg',
    '.jpeg',
    '.pdf',
    '.png',
    '.bmp',
    '.gif',
    '.ods',
    '.odt',
    '.ppt',
    '.pot',
    '.pptx',
    '.potx',
    '.rtf',
  ];

  if (!fileLimitValidator(attachments, FILE_LIMIT)) {
    showError(
      i18n.t('mailbox|fileLimitMsg', {
        fileLimit: FILE_LIMIT,
      })
    );

    return false;
  }

  if (!sizeLimitValidator(attachments, SIZE_LIMIT)) {
    showError(
      i18n.t('mailbox|fileSizeLimitMsg', {
        fileSize: SIZE_LIMIT / 1024 / 1024,
      })
    );

    return false;
  }

  if (!supportedFileTypesValidator(attachments, SUPPORTED_FILE_TYPES)) {
    showError(i18n.t('mailbox|unsupportedFileTypeMsg'));

    return false;
  }

  return true;
};
