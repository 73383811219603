import * as actionTypes from 'constants/actionTypes';

export const startApp = () => ({
  type: actionTypes.START_APP,
});

export const doLogin = () => ({
  type: actionTypes.DO_LOGIN_FLOW,
});

export const doConsentsAndApiStatusFlow = () => ({
  type: actionTypes.DO_CONSENTS_AND_API_STATUS_FLOW,
});

export const doCustomerFlow = () => ({
  type: actionTypes.DO_CUSTOMER_FLOW,
});

export const doInfoFlow = () => ({
  type: actionTypes.DO_INFO_FLOW,
});

export const completeInfoFlow = () => ({
  type: actionTypes.COMPLETE_INFO_FLOW,
});

export const prefetchInitialData = () => ({
  type: actionTypes.PREFETCH_INITIAL_DATA,
});
