import React from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled, { withTheme } from 'styled-components/native';
import { Body } from '@sp/ui/v2/typography';

const BUTTON_DESKTOP_WIDTH = 172;
const BORDER_WIDTH = 1;

const WrapperBase = styled.View`
  flex-direction: row;
  overflow: hidden;
  border: ${BORDER_WIDTH}px solid ${({ theme }) => theme.COLORS.PRIMARY};
  border-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
`;

const ButtonBase = styled.View`
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.SPACINGS.s}px 0px;
  border-left-width: ${({ isFirst }) => (isFirst ? 0 : 1)}px;
  border-left-color: ${({ isFirst, theme }) =>
    isFirst ? theme.COLORS.SECONDARY_BACKGROUND : theme.COLORS.PRIMARY}
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.COLORS.PRIMARY : theme.COLORS.SECONDARY_BACKGROUND};
`;

const View = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonTouchableOpacity = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Button = Conditional({
  mobile: ButtonBase,
  default: styled(ButtonBase)`
    width: ${BUTTON_DESKTOP_WIDTH}px;
    padding: ${(props) => props.theme.SPACINGS.ms}px 0;
  `,
});

const Wrapper = Conditional({
  mobile: WrapperBase,
  default: styled(WrapperBase)`
    flex-basis: auto;
    max-width: ${({ count }) => count * BUTTON_DESKTOP_WIDTH + BORDER_WIDTH * 2}px;
  `,
});

const ButtonGroup = ({ buttons, theme }) => (
  <Wrapper count={buttons.length} accessibilityRole="tablist">
    {buttons.map(({ isSelected, text, onPress, testID }, i) => {
      const TouchableOpacityOrView = isSelected === true ? View : ButtonTouchableOpacity;

      return (
        <TouchableOpacityOrView accessible accessibilityRole="tab" key={text} onPress={onPress}>
          <Button isFirst={i === 0} isSelected={isSelected} testID={testID}>
            <Body
              fontWeight="regular"
              color={isSelected ? theme.COLORS.SECONDARY_BACKGROUND : theme.COLORS.PRIMARY}
            >
              {text}
            </Body>
          </Button>
        </TouchableOpacityOrView>
      );
    })}
  </Wrapper>
);

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      isSelected: PropTypes.bool,
      onPress: PropTypes.func,
      testID: PropTypes.string,
    })
  ).isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ButtonGroup);
