import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Tracking from 'services/tracking';
import withTranslation from 'decorators/i18n';
import { Body2 } from '@sp/ui/v2/typography';
import { TermsCheckbox } from '@sp/ui/v2/base/inputs';

const View = styled.View``;

const ConsentCheckbox = ({
  consentKey,
  title,
  proposed,
  getConsent,
  proposedConsents,
  setTermsModalVisible,
  setProposedConsents,
  i18n,
}) => {
  const onLinkPress = () => {
    Tracking.trackEvent({ area: 'OnboardingConsent', action: `ClickRead${consentKey}` });
    setTermsModalVisible();
    getConsent(consentKey);
  };

  return (
    <TermsCheckbox
      testID={`${consentKey}ConsentCheckbox`}
      accessibilityLabel={title}
      text={
        <View
          focusable
          accessibilityRole="button"
          accessibilityActions={[{ name: 'openConsent', label: i18n.t('Se samtykke') }]}
          onAccessibilityAction={(event) => {
            if (event.nativeEvent.actionName === 'openConsent') {
              onLinkPress();
            }
          }}
        >
          <Body2 secondaryColor>{title}</Body2>
        </View>
      }
      checked={proposed}
      onChangeChecked={(value) => {
        Tracking.trackEvent({
          area: 'OnboardingConsent',
          action: `Click${consentKey}${value ? 'Accept' : 'Reject'}`,
        });
        setProposedConsents({
          ...proposedConsents,
          [consentKey]: value,
        });
      }}
    />
  );
};

ConsentCheckbox.propTypes = {
  consentKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  proposed: PropTypes.bool.isRequired,
  getConsent: PropTypes.func.isRequired,
  proposedConsents: PropTypes.object.isRequired,
  setTermsModalVisible: PropTypes.func.isRequired,
  setProposedConsents: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(withTheme(ConsentCheckbox));
