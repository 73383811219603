import { useCallback } from 'react';

type NotifyMsg = {
  type: 'flow-completed' | 'flow-dismissed';
  flow: string;
  path: string;
};

const useLaunchContext = () => {
  const notify = useCallback((msg: NotifyMsg) => {
    global.opener?.postMessage(
      JSON.stringify({
        app: 'login-universe',
        ...msg,
      }),
      '*'
    );

    return true;
  }, []);

  const completesLaunchFlow = useCallback(
    (flowName: string) =>
      notify({
        type: 'flow-completed',
        path: global.location.pathname,
        flow: flowName,
      }),
    [notify]
  );

  const dismissesFlow = useCallback(
    (flowName: string) => {
      notify({
        type: 'flow-dismissed',
        path: global.location.pathname,
        flow: flowName,
      });
    },
    [notify]
  );

  return {
    completesLaunchFlow,
    dismissesFlow,
  };
};

export default useLaunchContext;
