import React, { useContext, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components/native';
import useI18n from 'hooks/useI18n';
import { useNavigation } from '@react-navigation/native';
import { useAppSelector } from 'hooks/redux';
import {
  selectIsFlowTax,
  selectOnDoneRouteConfig,
  selectIsOnboarding,
} from 'selectors/fetchTaxAndPensionsInfo';
import { selectIsDeviceTypeDesktop, selectIsDeviceTypeMobile } from 'selectors/device';
import { selectFlowControl } from 'selectors/flowControl';
import { selectDeeplink } from 'selectors/deeplink';
import {
  selectPensionsInfo,
  selectIsPensionsInfoUndefinedOrThreeOrMoreMonthsOld,
} from 'selectors/pensionsInfo';
import { useInitCurrentProgress } from 'areas/partner/hooks';
import MenuPageContainer from 'containers/v2/pages/MenuPage/MenuPageV2';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import { useNavigateToFetchingScreen, useSkipAction } from './hooks';
import { useBackAndForwardButtonNavigation } from './hooks/useBackAndForwardButtonNavigation';
import type { RootState } from 'store';
import type { ActionProps } from 'typings/global';
import { isWeb, isAndroid } from 'helpers/platform';
import { COLORS } from '@sp/ui/settings';

const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { formatDate } = require('helpers/string');
const { ContentWithIcon } = require('@sp/ui/v2/pages/BasePage');
const { ModalProvider } = require('@sp/ui/v2/context/ModalContext');
const { Body, H5 } = require('@sp/ui/v2/typography');
const { default: Margin } = require('@sp/ui/base/Margin');

const ICON_WIDTH = 72;
const ICON_HEIGHT = 65;

const FlexWrapper = styled.View`
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  flex-basis: auto;
`;

const selectFetchTaxAndPensionsInfoIntroduction = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  flowControl: selectFlowControl(state),
  isFlowTax: selectIsFlowTax(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
  isMobile: selectIsDeviceTypeMobile(state),
  onDoneRouteConfig: selectOnDoneRouteConfig(state),
  isPensionsInfoUndefinedOrThreeOrMoreMonthsOld:
    selectIsPensionsInfoUndefinedOrThreeOrMoreMonthsOld(state),
  pensionsInfo: selectPensionsInfo(state),
  isOnboarding: selectIsOnboarding(state),
});

export type Nav = {
  navigate: (value: string) => void;
};

const getDescription = (isFlowTax: string) => {
  if (isFlowTax) {
    return isAndroid ? 'taxSplashDescriptionAndroid' : 'taxSplashDescription';
  }
  return 'pensionSplashDescription';
};

const FetchTaxAndPensionsInfoIntroduction: React.FC = () => {
  const i18n = useI18n(['fetchTaxAndPensionsInfo']);
  const navigation = useNavigation<Nav>();
  const { onClose } = useContext(FetchTaxAndPensionsInfoContext);
  const { backButtonNavigation } = useBackAndForwardButtonNavigation();

  const {
    isMobile,
    isDesktop,
    deeplink,
    flowControl,
    isFlowTax,
    pensionsInfo,
    isPensionsInfoUndefinedOrThreeOrMoreMonthsOld,
    isOnboarding,
  } = useAppSelector(selectFetchTaxAndPensionsInfoIntroduction, shallowEqual);
  const { isDeeplink } = deeplink ?? {};

  const { currentProgress, onSkipPension } = flowControl ?? {};
  const { pensionsInfoUpdateDate } = pensionsInfo ?? {};
  const isSkippable = isDeeplink || currentProgress || onSkipPension;
  const textAlignment = isMobile ? 'center' : 'left';

  const shared = useMemo(
    () => ({
      title: isFlowTax ? 'taxSplashTitle' : 'pensionSplashTitle',
      heading: isFlowTax ? 'taxSplashHeading' : 'pensionSplashHeading',
      description: getDescription(isFlowTax),
      iconName: isFlowTax ? 'income' : 'chair',
      actionText: isFlowTax ? 'getFiguresFromSkat' : 'getFiguresFromPensionsInfo',
    }),
    [isFlowTax]
  );

  const skipAction = useSkipAction();
  const navigateToFetchingScreen = useNavigateToFetchingScreen();

  const actions: ActionProps = [
    {
      text: i18n.t(`fetchTaxAndPensionsInfo|${shared.actionText}`),
      onPress: navigateToFetchingScreen,
    },
  ];

  if (isSkippable && !isPensionsInfoUndefinedOrThreeOrMoreMonthsOld) {
    actions.push({
      text: i18n.t('common|skip'),
      type: 'secondary',
      onPress: onSkipPension ? () => navigation.navigate(onSkipPension) : skipAction,
    });
  }

  // todo remove this one when partner flow is updated
  useInitCurrentProgress();

  return (
    <ModalProvider
      hasOnCloseButton={!isDeeplink}
      onClose={isOnboarding ? null : onClose}
      isModal
      secondaryColor
    >
      <MenuPageContainer
        title={isMobile ? undefined : i18n.t(`fetchTaxAndPensionsInfo|${shared.title}`)}
        backButtonRoute={backButtonNavigation}
        actions={actions}
      >
        <FlexWrapper>
          <ContentWithIcon
            isMobile={isMobile}
            title={isDesktop ? undefined : i18n.t(`fetchTaxAndPensionsInfo|${shared.heading}`)}
            body={i18n.t(`fetchTaxAndPensionsInfo|${shared.description}`)}
            icon={
              !isDesktop
                ? {
                    width: ICON_WIDTH,
                    height: ICON_HEIGHT,
                    name: shared.iconName,
                    // set fill color for deeplink menupage with white background
                    fill: isWeb && isMobile ? COLORS.V2_PRIMARY_BACKGROUND : undefined,
                  }
                : undefined
            }
          />

          {isSkippable && pensionsInfoUpdateDate && (
            <Margin marginTop="lg">
              <Margin marginBottom="xs">
                <H5 textAlign={textAlignment}>
                  {i18n.t('dashboard|pensionInfoUpdateDate', {
                    date: formatDate(pensionsInfoUpdateDate?.split('T')[0]), // remove time from date time string
                  })}
                </H5>
              </Margin>
              <Body textAlign={textAlignment}>
                {isPensionsInfoUndefinedOrThreeOrMoreMonthsOld
                  ? i18n.t('fetchTaxAndPensionsInfo|pensionsInfoIsOutdated')
                  : i18n.t('fetchTaxAndPensionsInfo|skipStepBody')}
              </Body>
            </Margin>
          )}
        </FlexWrapper>
      </MenuPageContainer>
    </ModalProvider>
  );
};

export default withScreenContainer()(FetchTaxAndPensionsInfoIntroduction);
