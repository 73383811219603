import React from 'react';
import { useAppSelector } from 'hooks/redux';
import Alert, { AlertTypeProps, ActionProps } from '@sp/ui/v2/base/inputs/Alert';

const { selectIsDeviceTypeDesktop } = require('selectors/device');

type AlertStyleProps = 'primary' | 'secondary' | 'destructive' | 'default' | 'cancel';

type AdjustCalculationAlertProps = Omit<
  AlertTypeProps,
  'actions' | 'isDesktop' | 'isCancelable'
> & {
  actions: {
    style?: AlertStyleProps;
    text: ActionProps['text'];
    onPress: ActionProps['onPress'];
  }[];
};

const AdjustCalculationAlert: React.FC<AdjustCalculationAlertProps> = ({
  title,
  description,
  isVisible,
  actions,
  onRequestClose,
}) => {
  const isDesktop = useAppSelector((state) => selectIsDeviceTypeDesktop(state));

  return (
    <Alert
      title={title}
      description={description}
      onRequestClose={onRequestClose}
      isVisible={isVisible}
      isDesktop={isDesktop}
      isCancelable={false}
      actions={actions}
    />
  );
};

export default AdjustCalculationAlert;
