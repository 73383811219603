import React from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled from 'styled-components';
import Modal from '@sp/ui/layout/Modal';
import { Button } from '@sp/ui/base/buttons';
import { H2, Body } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';

const ICON_SIZE = 40;

const IconWrapper = styled.div`
  padding-bottom: ${(props) => props.theme.SPACINGS.md}px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HorizontalWrapper = styled.div`
  padding-bottom: ${(props) => props.theme.SPACINGS.lg}px;
`;

const ButtonWrapper = styled.div`
  padding-bottom: ${(props) => props.theme.SPACINGS.sm}px;

  &:last-child {
    padding-bottom: 0px;
  }
`;

const ButtonContainer = Conditional({
  mobile: styled.div`
    position: absolute;
    bottom: ${(props) => props.theme.SPACINGS.md}px;
  `,
  default: styled.div``,
});

const Alert = ({
  visible,
  onRequestClose,
  onClose,
  title,
  iconName,
  iconColor,
  description,
  actions,
}) => (
  <Modal visible={visible} onRequestClose={onClose ?? onRequestClose}>
    <Wrapper>
      {iconName && iconColor && (
        <IconWrapper>
          <Icon fill={iconColor} height={ICON_SIZE} width={ICON_SIZE} name={iconName} />
        </IconWrapper>
      )}
      <HorizontalWrapper>
        <H2>{title}</H2>
      </HorizontalWrapper>
      <HorizontalWrapper>
        <Body>{description}</Body>
      </HorizontalWrapper>
      <ButtonContainer>
        {actions.map((action) => (
          <ButtonWrapper key={action.text}>
            <Button
              text={action.text}
              type={action.style}
              onPress={() => {
                action.onPress();
                onRequestClose();
              }}
            />
          </ButtonWrapper>
        ))}
      </ButtonContainer>
    </Wrapper>
  </Modal>
);

Alert.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onPress: PropTypes.func,
      style: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'destructive-secondary']),
    })
  ).isRequired,
};

Alert.defaultProps = {
  onClose: undefined,
  description: undefined,
  iconName: undefined,
  iconColor: undefined,
};

export default Alert;
