import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BottomTabBar, { TouchableWithoutFeedbackWrapper } from '@sp/ui/layout/BottomTabBar';
import Tracking from 'services/tracking';
import { CLICK_BOTTOM_TAB_BAR_ITEM } from 'areas/main/trackingIds';
import { selectIsDeviceTypeDesktop } from 'selectors/device';

import features from 'features';

const CustomBottomTabBar = (props) => {
  if (props.isDesktop) {
    return null;
  }

  if (props.featuresLoaded) {
    return (
      <BottomTabBar
        {...props}
        getButtonComponent={({ route }) => {
          // Filtering tabs based on enabled feature toggles
          if (features.isEnabled(route.key)) {
            return TouchableWithoutFeedbackWrapper;
          }
          return () => null;
        }}
        onTabPress={(params) => Tracking.trackEvent(CLICK_BOTTOM_TAB_BAR_ITEM, params)}
      />
    );
  }

  return <BottomTabBar {...props} />;
};

CustomBottomTabBar.propTypes = {
  featuresLoaded: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  featuresLoaded: state.services.device.featuresLoaded,
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default connect(mapStateToProps)(CustomBottomTabBar);
