import React, { useState } from 'react';
import FormSection, { FormSectionProps } from '../FormSection';

const { default: Modal } = require('@sp/ui/v2/layout/Modal');

interface FormSectionWithModalProps {
  infoAccessibilityLabel: string;
  disablePadding?: boolean;
  modalAccessibilityLabel?: string;
  description: string | React.ReactNode;
  title: string | React.ReactNode;
  modalContent: React.ReactNode;
  children: React.ReactNode;
  onInfoPress?: () => void;
  tracking?: () => void;
}

const FormSectionWithModal: React.FC<FormSectionWithModalProps> = ({
  modalContent,
  onInfoPress,
  tracking,
  modalAccessibilityLabel,
  children,
  ...props
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  return (
    <>
      <FormSection
        {...(props as FormSectionProps)}
        onInfoPress={() => {
          if (typeof onInfoPress === 'function') onInfoPress();
          if (tracking) {
            tracking();
          }
          setModalVisible(true);
        }}
      >
        {children}
      </FormSection>
      <Modal
        visible={isModalVisible}
        onRequestClose={() => setModalVisible(false)}
        accessibilityLabel={modalAccessibilityLabel}
      >
        {modalContent}
      </Modal>
    </>
  );
};

FormSectionWithModal.defaultProps = {
  disablePadding: false,
  modalAccessibilityLabel: undefined,
  tracking: undefined,
  onInfoPress: undefined,
};

export default FormSectionWithModal;
