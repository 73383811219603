import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';

const Wrapper = styled.View`
  flex-direction: row;
`;

const ActionButtons = ({ icons }) => (
  <Wrapper>
    {icons.map((item) => (
      <TouchableOpacity key={item.name} onPress={item.onPress}>
        <Margin marginLeft="md">
          <Icon {...item} />
        </Margin>
      </TouchableOpacity>
    ))}
  </Wrapper>
);

ActionButtons.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default ActionButtons;
