import { get, post, put } from 'actions/api';
import {
  DEPOSITS_SAVINGS,
  DEPOSITS_PLACEMENT,
  DEPOSITS_PLACEMENT_TEXTS,
  DEPOSITS_QUESTIONS,
  DEPOSITS_PAYMENT_SOURCES,
  DEPOSITS_PAYMENT_RECEIPT,
  DEPOSITS_PAYMENT_STATUS,
  DEPOSITS_TERMS,
  SET_DEPOSITS_AMOUNT,
  SET_DEPOSITS_ANSWERS,
  SET_DEPOSITS_TARGET,
  SET_DEPOSITS_CONTACT_INFO,
  CLEAR_DEPOSITS,
  DEPOSITS_NEXI,
  DEPOSITS_PAYMENT_RECEIPTS,
  SET_DEPOSITS_IS_PLACEMENT_VALID,
} from 'constants/actionTypes';
import * as routeNames from 'constants/routeNames';
import { getHostedUri } from 'areas/deposits/helpers';

export const setAmount = (amount) => ({ type: SET_DEPOSITS_AMOUNT, payload: amount });

export const setAnswers = (answers) => ({ type: SET_DEPOSITS_ANSWERS, payload: answers });

export const setTarget = (coverage) => ({ type: SET_DEPOSITS_TARGET, payload: coverage });

export const setContactInfo = (contactInfo) => ({
  type: SET_DEPOSITS_CONTACT_INFO,
  payload: contactInfo,
});

export const setIsPlacementValid = (isValid) => ({
  type: SET_DEPOSITS_IS_PLACEMENT_VALID,
  payload: isValid,
});

export const getSavings = (allowFailure) =>
  get(DEPOSITS_SAVINGS, 'api', '/deposits/v2/targets', {
    allowFailure,
  });

export const getPlacementTexts = (amount) =>
  get(
    DEPOSITS_PLACEMENT_TEXTS,
    'api',
    `/deposits/v2/placements/getplacementtexts?amount=${amount}`
  );

export const getPlacements = ({
  coverageNumber,
  amount,
  isRatepensionSelected,
  isAldersforsikringSelected,
  isLivsvarigAlderspensionSelected,
}) =>
  post(
    DEPOSITS_PLACEMENT,
    {
      coverageNumber,
      amount,
      isRatepensionSelected,
      isAldersforsikringSelected,
      isLivsvarigAlderspensionSelected,
    },
    'api',
    '/deposits/v2/placements/calculateplacements'
  );

export const getQuestions = ({ coverageNumber, amount }) =>
  post(
    DEPOSITS_QUESTIONS,
    {
      coverageNumber,
      amount,
      currency: 'DKK',
    },
    'api',
    '/deposits/v2/questions'
  );

export const getPaymentSources = (coverageNumber) =>
  post(
    DEPOSITS_PAYMENT_SOURCES,
    {
      coverageNumber,
    },
    'api',
    '/deposits/v2/sources'
  );

export const getReceipt = (receiptId) =>
  get(DEPOSITS_PAYMENT_RECEIPT, 'api', `/deposits/v2/receipts/${receiptId}`);

export const getReceipts = () => get(DEPOSITS_PAYMENT_RECEIPTS, 'api', '/deposits/v2/receipts');

export const getStatus = () => get(DEPOSITS_PAYMENT_STATUS, 'api', '/deposits/v2/status');

export const getTerms = (coverageNumber) =>
  get(DEPOSITS_TERMS, 'api', `/deposits/v2/terms/${coverageNumber}`);

export const addInfo = ({ ...data }) =>
  put('ADD_DEPOSITS_CONTACT_INFO', data, 'api', '/deposits/v2/contact-info', {});

export const clearDeposits = () => ({ type: CLEAR_DEPOSITS });

const embeddedCheckoutUrl = `${getHostedUri()}/${routeNames.DEPOSITS}`;

export const payWithNexi = ({
  amount,
  coverageNumber,
  currency,
  sourceType,
  contactInfo,
  termsId,
}) =>
  post(
    DEPOSITS_NEXI,
    {
      embeddedCheckoutUrl,
      amount,
      coverageNumber,
      currency,
      sourceType,
      termsId,
      ...contactInfo,
    },
    'api',
    '/deposits/v2/nexi/payments'
  );
