import features from 'features';
import { hasDocumentWithStatus } from 'areas/mailbox/helpers/documents';
import {
  MAILS,
  MAIL,
  POLL_MAIL,
  POLL_MAILS,
  CANCEL_POLL_MAILS,
  CANCEL_POLL_MAIL,
  SET_TYPE,
  THREAD_READ,
  THREAD_ARCHIVE,
  THREAD_INBOX,
  FETCH_MAILBOX_SUBJECTS,
  CREATE_MAIL,
  CREATE_MAILBOX_SIGNING_URL,
  COMPLETE_MAILBOX_SIGNING,
  TOGGLE_SORT_MAILBOX_CONVERSATIONS,
  PATCH_OVERVIEW_WITH_UPDATED_MAIL,
} from 'constants/actionTypes';
import { isWeb, isAndroid } from 'helpers/platform';
import * as routeNames from 'constants/routeNames';
import { get, post, put, poll } from './api';
import { getHostedUri } from '../areas/deposits/helpers';

const POLL_INTERVAL = 10000;

export const setSource = (source) => ({ type: SET_TYPE, payload: { source } });

export const fetch = (source = 'inbox') =>
  get(MAILS, 'api', `/mailbox/v2/threads?source=${source}`, {
    requestInfo: {
      source,
    },
  });

const mailsForSignatureStatusChange = (source = 'inbox') =>
  get(POLL_MAILS, 'api', `/mailbox/v2/threads?source=${source}`, {
    requestInfo: {
      source,
    },
  });

export const pollMailsForSignatureStatusChange = (source = 'inbox') =>
  poll(mailsForSignatureStatusChange(source), {
    maxRetries: 5,
    delay: POLL_INTERVAL,
    abortOnFail: true,
    status: (response) => {
      const success = response.threads.some(
        (conversation) => conversation.signingStatus === 'inProgress'
      );
      return success ? 'success' : 'pending';
    },
  });

export const fetchIndividual = (id) =>
  get(MAIL, 'api', `/mailbox/v2/messages?threadId=${id}`, {
    requestInfo: {
      id,
    },
  });

export const individualMailForSignatureStatusChange = (id) =>
  get(POLL_MAIL, 'api', `/mailbox/v2/messages?threadId=${id}`, {
    requestInfo: {
      id,
    },
  });

export const pollIndividualMailForSignatureStatusChange = (id) =>
  poll(individualMailForSignatureStatusChange(id), {
    maxRetries: 5,
    delay: POLL_INTERVAL,
    abortOnFail: true,
    status: (response) => {
      const success = !hasDocumentWithStatus(response.messages, 'inProgress');
      return success ? 'success' : 'pending';
    },
  });

export const cancelPollMails = () => ({ type: CANCEL_POLL_MAILS });
export const cancelPollIndividualMail = () => ({ type: CANCEL_POLL_MAIL });

export const markRead = (id) =>
  put(THREAD_READ, { id, read: true }, 'api', `/mailbox/v2/threads/${id}`, { allowFailure: true });

export const moveToArchive = (id) =>
  put(THREAD_ARCHIVE, { id, archived: true }, 'api', `/mailbox/v2/threads/${id}`);

export const moveToInbox = (id) =>
  put(THREAD_INBOX, { id, archived: false }, 'api', `/mailbox/v2/threads/${id}`);

export const getSubjects = () => get(FETCH_MAILBOX_SUBJECTS, 'api', '/mailbox/v2/request-types');

export const create = ({ threadId, content, subject, files, caseType }) =>
  post(
    CREATE_MAIL,
    {
      threadId,
      content,
      files: features.isEnabled('mailboxAddAttachments') ? files : undefined,
      title: threadId ? undefined : subject,
      requestType: caseType,
    },
    'api',
    '/mailbox/v2/messages'
  );

const addSignedParam = (origin, pathname, messageId, threadId, documentId) => {
  return `${origin}${pathname}?id=${threadId}&signed=true&signedMessageId=${messageId}&documentId=${documentId}`;
};

const getCancelUrlForNative = (threadId, documentId, hasMitId) => {
  // if device is android, redirect from mitid after signing is not working.
  // A workaround is to redirect to a page that will redirect to the app
  if (isAndroid && hasMitId) {
    const origin = getHostedUri();
    return `${origin}/redirect?flow=signing&screen=${routeNames.MAILBOX}&threadId=${threadId}&cancelled=true&documentId=${documentId}`;
  }

  return `sampension://main/tabs/mailbox/${routeNames.MAIL_SIGN}?threadId=${threadId}&documentId=${documentId}&cancelled=true`;
};
const getCompleteUrlForNative = (messageId, threadId, documentId, hasMitId) => {
  // if device is android, redirect from mitid after signing is not working.
  // A workaround is to redirect to a page that will redirect to the app
  if (isAndroid && hasMitId) {
    const origin = getHostedUri();
    return `${origin}/redirect?flow=signing&screen=${routeNames.MAILBOX}&threadId=${threadId}&completed=true&documentId=${documentId}`;
  }

  return `sampension://main/tabs/mailbox/${routeNames.MAIL_SIGN}?threadId=${threadId}&documentId=${documentId}&completed=true`;
};

export const createSigningUrl = (
  messageId,
  threadId,
  documentId,
  hasMitId,
  {
    cancelUrl = isWeb
      ? global.window.location.href
      : getCancelUrlForNative(threadId, documentId, hasMitId),
    completeUrl = isWeb
      ? addSignedParam(
          global.window.location.origin,
          global.window.location.pathname,
          messageId,
          threadId,
          documentId
        )
      : getCompleteUrlForNative(messageId, threadId, documentId, hasMitId),
  } = {}
) =>
  post(
    CREATE_MAILBOX_SIGNING_URL,
    {
      messageId,
      threadId,
      documentId,
      cancelUrl,
      completeUrl,
    },
    'api',
    '/mailbox/v2/signing-requests',
    {
      disableTimeout: true,
    }
  );

export const completeSigning = (messageId, threadId, documentId) =>
  put(
    COMPLETE_MAILBOX_SIGNING,
    {
      messageId,
      threadId,
      documentId,
    },
    'api',
    '/mailbox/v2/signing-requests'
  );

export const toggleSort = () => ({
  type: TOGGLE_SORT_MAILBOX_CONVERSATIONS,
});

export const patchOverviewWithUpdatedMail = (messages) => ({
  type: PATCH_OVERVIEW_WITH_UPDATED_MAIL,
  payload: {
    messages,
  },
});
