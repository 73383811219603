import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'react-redux';
import Footer from '@sp/ui/layout/Footer';
import { useNavigation } from '@react-navigation/native';
import { selectDeeplink } from 'selectors/deeplink';
import { useAppSelector } from 'hooks/redux';

const FooterContainer = ({ menuItemsFactory }) => {
  const navigation = useNavigation();
  const { isDeeplink } = useAppSelector(selectDeeplink, shallowEqual);
  const menuItems = menuItemsFactory(navigation, isDeeplink);

  return (
    <Footer
      menuItems={menuItems.filter((items) =>
        isDeeplink ? items.featureName === 'feedback' : items
      )}
    />
  );
};

FooterContainer.propTypes = {
  menuItemsFactory: PropTypes.func.isRequired,
};

export default FooterContainer;
