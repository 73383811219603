import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import { withTheme } from 'styled-components/native';
import GraphPage from '@sp/ui/pages/GraphPage';
import withScreenContainer from 'decorators/withScreenContainer';
import {
  selectHasDashboardData,
  selectAwardRules,
  selectDisposableIncomeOptions,
} from 'selectors/dashboard';
import { getAwardRules } from 'actions/digital-advice';
import { BackButton, TopContent } from '@sp/ui/pages/BasePage';
import { getIncome } from 'actions/dashboard';
import GraphSelector from 'areas/dashboard/payouts/GraphSelector';
import { hasOnetimePayouts } from '../helpers';
import { goBackToDashboardFromNestedStack } from 'helpers/navigation';
import TopTabBar from 'containers/dashboard/TopTabBar/DetailedPayouts';
import { mergePayoutsOverTime } from 'areas/dashboard/payouts/payoutsOverTime/helpers';
import Graph from 'areas/dashboard/payouts/Graph';
import GraphOneTime from 'areas/dashboard/payouts/payoutsOneTime/Graph';
import ContentAll from 'areas/dashboard/payouts/payoutsAll';
import ContentOneTime from 'areas/dashboard/payouts/payoutsOneTime';
import ContentOverTime from 'areas/dashboard/payouts/payoutsOverTime';
import { RowBorder } from '@sp/ui/base/rows';
import Margin from '@sp/ui/base/Margin';
import { AgeSelectorContainer } from 'areas/dashboard/styles';
import AgeSelector from 'containers/dashboard/AgeSelector';
import { PAYOUTS_ALL, PAYOUTS_ONE_TIME, PAYOUTS_OVER_TIME } from 'areas/dashboard/trackingIds';
import * as tabNames from './constants/tabNames';
import { PAYOUTS_DETAILS_OVERVIEW_SCREEN } from 'constants/testIds/dashboard';
import { DEATH, DISABILITY, RETIREMENT } from '../constants/dashboardTypes';

const PageTopContent = Conditional({
  desktop: TopContent,
});

const getTrackingId = (focusedTab) => {
  switch (focusedTab) {
    case tabNames.PAYOUTS_ALL:
      return PAYOUTS_ALL;
    case tabNames.PAYOUTS_ONE_TIME:
      return PAYOUTS_ONE_TIME;
    default:
      return PAYOUTS_OVER_TIME;
  }
};

const calculatePayouts = (
  payouts,
  payoutType,
  isAllPayoutsTab,
  isPayoutsOverTimeTab,
  isPayoutsOneTimeTab
) => {
  let payoutsAll, payoutsOneTime, payoutsOverTime;

  if (isAllPayoutsTab) {
    payoutsAll = payouts?.perYear && mergePayoutsOverTime(payouts?.perYear, payoutType, true);
  } else if (isPayoutsOverTimeTab) {
    payoutsOverTime = payouts?.perYear && mergePayoutsOverTime(payouts?.perYear, payoutType);
  } else if (isPayoutsOneTimeTab) {
    payoutsOneTime = payouts?.perYear.filter((item) => item?.engangsudbetalingDetails?.length > 0);
  }

  return { payoutsAll, payoutsOneTime, payoutsOverTime };
};

export const createPage = ({ getPayoutsAction, selectPayouts, payoutType }) => {
  const Payouts = ({ payouts, navigation, i18n, initFocusedTab }) => {
    const [focusedTab, setFocusedTab] = useState(initFocusedTab);

    const isAllPayoutsTab = focusedTab === tabNames.PAYOUTS_ALL;
    const isPayoutsOverTimeTab = focusedTab === tabNames.PAYOUTS_OVER_TIME;
    const isPayoutsOneTimeTab = focusedTab === tabNames.PAYOUTS_ONE_TIME;

    const { payoutsAll, payoutsOneTime, payoutsOverTime } = useMemo(
      () =>
        calculatePayouts(
          payouts,
          payoutType,
          isAllPayoutsTab,
          isPayoutsOverTimeTab,
          isPayoutsOneTimeTab
        ),
      [isAllPayoutsTab, isPayoutsOneTimeTab, isPayoutsOverTimeTab, payouts]
    );

    const hasOneTimePayout = hasOnetimePayouts(payouts);

    return (
      <GraphPage
        testID={PAYOUTS_DETAILS_OVERVIEW_SCREEN}
        title={i18n.t('dashboard|graphTitle')}
        tabBar={<TopTabBar navigation={navigation} focusedTab={payoutType} />}
        backButton={
          <BackButton
            label={i18n.t('common|back')}
            onPress={() => goBackToDashboardFromNestedStack(navigation, true)}
            testID={`${payoutType}PayoutsBackButton`}
          />
        }
        wideContent={
          <>
            <PageTopContent heading={i18n.t('dashboard|graphTitle')} />
            <GraphSelector
              focusedTab={focusedTab}
              payoutType={payoutType}
              payouts={payouts}
              setFocusedTab={setFocusedTab}
            />

            {isAllPayoutsTab && payoutsAll && (
              <Graph
                payouts={payoutsAll}
                payoutType={payoutType}
                incomeWhenRetired={payouts}
                showAverageBar={payoutType !== DEATH}
              />
            )}
            {isPayoutsOneTimeTab && payoutsOneTime && (
              <GraphOneTime payouts={payoutsOneTime} payoutType={payoutType} />
            )}
            {isPayoutsOverTimeTab && payoutsOverTime && (
              <Graph
                payouts={payoutsOverTime}
                payoutType={payoutType}
                incomeWhenRetired={payouts}
                showAverageBar={!hasOneTimePayout && payoutType !== DEATH}
              />
            )}
          </>
        }
      >
        {payoutType === RETIREMENT && (
          <>
            <RowBorder />
            <AgeSelectorContainer>
              <AgeSelector
                trackingParams={{
                  screen: getTrackingId(focusedTab),
                }}
                withDebounce
              />
            </AgeSelectorContainer>
          </>
        )}

        <RowBorder />

        {isAllPayoutsTab && <ContentAll payoutType={payoutType} payouts={payoutsAll} />}
        {isPayoutsOverTimeTab && (
          <ContentOverTime payoutType={payoutType} payouts={payoutsOverTime} />
        )}
        {isPayoutsOneTimeTab && <ContentOneTime payoutType={payoutType} payouts={payoutsOneTime} />}
        <Margin marginBottom="md" />
      </GraphPage>
    );
  };

  Payouts.propTypes = {
    payouts: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
      perYear: PropTypes.arrayOf(PropTypes.object),
    }),
    navigation: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    initFocusedTab: PropTypes.string,
  };

  Payouts.defaultProps = {
    payouts: undefined,
    initFocusedTab: 'payoutsAll',
  };

  const mapStateToProps = (state, props) => ({
    payouts: selectPayouts(state),
    hasDashboardData: selectHasDashboardData(state, payoutType),
    awardRules: selectAwardRules(state),
    hasDisposableIncomeOptions: selectDisposableIncomeOptions(state),
    initFocusedTab: props.route.params?.tab,
  });

  return withScreenContainer({
    mapStateToProps,
    i18ns: ['dashboard'],
    init: ({ action, getProps }) =>
      action(
        async () => {
          const dispatchArray = [];
          const { dispatch, hasDashboardData, hasDisposableIncomeOptions, awardRules } = getProps();

          if ((payoutType === DISABILITY || payoutType === DEATH) && !awardRules) {
            dispatchArray.push(getAwardRules());
          }

          if (!hasDashboardData) {
            dispatchArray.push(getPayoutsAction());
          }

          if (!hasDisposableIncomeOptions) {
            dispatchArray.push(getIncome());
          }

          await dispatch(dispatchArray);
        },
        {
          loader: true,
        }
      ),
  })(withTheme(Payouts));
};
