import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { selectIsDeviceLandscape, selectDeviceType } from 'selectors/device';

const Image = styled.Image``;

const ImageBackground = styled.ImageBackground`
  width: 100%;
  height: 100%;
`;

const ConditionalImage = ({
  deviceType,
  isLandscape,
  mobile,
  tablet,
  landscape,
  desktop,
  isBackground,
  ...props
}) => {
  const ImageComponent = isBackground ? ImageBackground : Image;

  if (deviceType === 'mobile' && mobile) {
    return <ImageComponent source={mobile} {...props} />;
  }
  if (deviceType === 'tablet' && tablet) {
    return <ImageComponent source={isLandscape && landscape ? landscape : tablet} {...props} />;
  }
  if (deviceType === 'desktop' && desktop) {
    return <ImageComponent source={desktop} {...props} />;
  }

  return <ImageComponent source={desktop} {...props} />;
};

ConditionalImage.propTypes = {
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop', 'default']).isRequired,
  mobile: PropTypes.any.isRequired,
  desktop: PropTypes.any.isRequired,
  tablet: PropTypes.any,
  landscape: PropTypes.any,
  isLandscape: PropTypes.bool.isRequired,
  isBackground: PropTypes.bool,
};

ConditionalImage.defaultProps = {
  tablet: undefined,
  landscape: undefined,
  isBackground: true,
};

const mapStateToProps = (state) => ({
  deviceType: selectDeviceType(state),
  isLandscape: selectIsDeviceLandscape(state),
});

export default connect(mapStateToProps)(ConditionalImage);
