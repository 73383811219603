import React from 'react';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { ModalTitle, ModalRegularText } from '../modal/modalTextComponents';

const AdminCostGroupLifeAndHealthInsurances = ({ companyName, i18n }) => (
  <>
    <ModalTitle subtitle={i18n.t('savings|Administration af gruppelivsforsikringerne')} />
    <ModalRegularText
      text={i18n.t(
        'savings|Gruppelivsforsikringerne administreres i Forenede Gruppeliv. Administrationsomkostninger til Forenede Gruppeliv er indeholdt i de administrationsomkostninger du betaler til {{ companyName }} - se ovenfor.',
        { companyName }
      )}
    />
    <ModalTitle subtitle={i18n.t('savings|Administration af sundhedsforsikring')} />
    <ModalRegularText
      text={i18n.t(
        'savings|Har du en sundhedsforsikring, som du betaler via din pensionsordning, går en vis del af præmien til at dække de administrationsomkostninger, der er forbundet med denne forsikring. Du kan se både præmie og omkostninger på dit depotregnskab.'
      )}
    />
  </>
);

AdminCostGroupLifeAndHealthInsurances.propTypes = {
  i18n: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default withComponentContainer()(AdminCostGroupLifeAndHealthInsurances);
