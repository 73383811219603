import * as routeNames from 'constants/routeNames';
import { isWeb } from 'helpers/platform';
import { selectCalculationSettings } from './dashboard';
import { selectDeeplink } from './deeplink';
import type { RootState } from 'store';

export const selectPartnerLinkGUID = (state: RootState) => {
  if (!isWeb) {
    return undefined;
  }

  const { deeplinkFlow } = selectDeeplink(state);
  const isPartnerFlow = deeplinkFlow === routeNames.PARTNER;
  const url = new URL(global.location.href);
  const searchParams = url.searchParams;
  const guid = searchParams.get('guid');

  return isPartnerFlow ? guid : undefined;
};

export const selectPartnerIncomeOptions = (state: RootState) => {
  const calculationSettings = selectCalculationSettings(state);
  const partnerEnteredIncome = selectPartner(state)?.partnerIncomeMonthly;
  const { income, tax, publicBenefits } = calculationSettings ?? {};
  const { declaredAnnualIncomePartner, declaredAnnualIncomeSelf } = income ?? {};
  const { isChurchContributor } = tax ?? {};
  const { hasElderCheque, earlyRetirement } = publicBenefits ?? {};
  const yearlyIncome = declaredAnnualIncomeSelf || declaredAnnualIncomePartner;
  const monthlyIncome = yearlyIncome ? (+yearlyIncome / 12).toString(10) : '';

  return {
    earlyRetirement,
    monthlyIncome: monthlyIncome || partnerEnteredIncome,
    isChurchContributor,
    hasElderCheque,
    hasMissingIncome: !monthlyIncome && !partnerEnteredIncome,
  };
};

export const selectPartner = (state: RootState) => state.areas.partner;
export const selectPartnerName = (state: RootState) => state.areas.partner?.partnerName;
export const selectHasPartner = (state: RootState) => selectPartnerName(state);
export const selectPartnerSubjectId = (state: RootState) => selectPartner(state)?.partnerSubjectId;
