import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { useHandleDeeplink } from 'hooks/useHandleDeeplink';
import { useNavigateToDeeplink } from 'hooks/useHandleDeeplink/helpers';
import { selectDeeplink } from 'selectors/deeplink';
import { selectIsLoggedIn } from 'selectors/api';
import { isWeb, isIOS } from 'helpers/platform';

import NotFound from 'areas/notFound';
import DeeplinkNavigator from 'areas/deeplink/DeeplinkNavigator';
import MainNavigator from 'areas/main/MainNavigator';
import * as routeNames from 'constants/routeNames';
import withSuspense from 'decorators/withSuspense';

import Login from 'areas/auth/Login';
import InjectToken from 'areas/auth/InjectToken';
import Biometric from 'areas/auth/Biometric';
import Consents from 'areas/auth/Consents';
import CustomerBlock from 'areas/general/CustomerBlock';
import OnboardingNavigator from 'areas/onboarding/OnboardingNavigator';
import Loader from '@sp/ui/base/Loader';
import Intro from 'areas/onboarding/intro/Intro';
import Redirect from 'areas/redirect';

const Stack = createStackNavigator();

const LazyDevelopmentNavigator = lazy(() => import('areas/development/DevelopmentNavigator'));
const DevelopmentNavigator = withSuspense(LazyDevelopmentNavigator);

const areasStackSelector = (state) => ({
  deeplink: selectDeeplink(state),
  loggedIn: selectIsLoggedIn(state),
  authCheckFinished: state.services.security.authCheckFinished,
});

const AreasStack = ({ lockdown }) => {
  const handleDeeplink = useHandleDeeplink();
  const navigateToDeeplink = useNavigateToDeeplink();
  const { deeplink, loggedIn, authCheckFinished } = useAppSelector(
    areasStackSelector,
    shallowEqual
  );

  const { isDeeplinkComplete, isDeeplink } = deeplink ?? {};
  const isAuthorizedAndLoggedIn = authCheckFinished && loggedIn;
  const isDeeplinkUnhandled = isDeeplink && !isDeeplinkComplete;

  useEffect(() => {
    if (isWeb && isAuthorizedAndLoggedIn) {
      if (isDeeplinkUnhandled) {
        handleDeeplink();
      } else if (isDeeplink) {
        navigateToDeeplink();
      }
    }
  }, [
    handleDeeplink,
    isAuthorizedAndLoggedIn,
    isDeeplink,
    isDeeplinkUnhandled,
    navigateToDeeplink,
  ]);

  return (
    <Stack.Navigator
      initialRouteName={routeNames.INTRO}
      screenOptions={{
        cardStyleInterpolator: isIOS
          ? CardStyleInterpolators.forVerticalIOS
          : CardStyleInterpolators.forFadeFromBottomAndroid,
        gestureEnabled: false,
        headerShown: false,
        animationEnabled: !isWeb,
      }}
    >
      {loggedIn ? (
        <>
          {isDeeplink ? (
            <Stack.Screen name={routeNames.DEEPLINK} component={DeeplinkNavigator} />
          ) : (
            <>
              <Stack.Screen name={routeNames.MAIN} component={MainNavigator} />
              <Stack.Screen name={routeNames.BIOMETRIC} component={Biometric} />
              <Stack.Screen name={routeNames.CONSENTS} component={Consents} />
              <Stack.Screen name={routeNames.CUSTOMER_BLOCK} component={CustomerBlock} />
              <Stack.Screen name={routeNames.ONBOARDING} component={OnboardingNavigator} />
            </>
          )}
        </>
      ) : (
        <>
          <Stack.Screen name={routeNames.INTRO} component={Intro} />
          <Stack.Screen name={routeNames.LOGIN} component={Login} />
          <Stack.Screen name={routeNames.INJECT_TOKEN} component={InjectToken} />
        </>
      )}
      {!lockdown && <Stack.Screen name={routeNames.DEVELOPMENT} component={DevelopmentNavigator} />}
      <Stack.Screen name={routeNames.NOT_FOUND} component={NotFound} />
      <Stack.Screen name={routeNames.LOADING} component={Loader} />
      <Stack.Screen name={routeNames.REDIRECT} component={Redirect} />
    </Stack.Navigator>
  );
};

AreasStack.propTypes = {
  lockdown: PropTypes.bool.isRequired,
};

export default AreasStack;
