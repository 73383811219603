import { create } from '../helpers/reducers';
import {
  RESPONSE_CREATE_PARTNER_LINK,
  UPDATE_PARTNER_CONNECTION_FAILED,
  RESPONSE_GET_PARTNER,
  RESPONSE_DELETE_PARTNER,
  RESPONSE_GET_PARTNER_DATA_FROM_MEETINGPREPARATION,
} from 'constants/actionTypes';

export type PartnerState = {
  guid?: string;
  hasPartnerConnectionFailed?: boolean;
  statusCode?: number;
  partnerName?: string;
  partnerIncomeMonthly?: string;
  partnerBirthDate?: string;
  email?: string;
  phone?: string;
  partnerSubjectId?: string;
};

const createDefaults = (): PartnerState => ({
  guid: undefined,
  hasPartnerConnectionFailed: undefined,
});

export default create(
  {
    [RESPONSE_CREATE_PARTNER_LINK]: (state, payload) => ({
      ...state,
      guid: payload.response.link,
      ...payload.request,
    }),
    [UPDATE_PARTNER_CONNECTION_FAILED]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [RESPONSE_GET_PARTNER_DATA_FROM_MEETINGPREPARATION]: (state, payload) => ({
      ...state,
      ...payload.response,
    }),
    [RESPONSE_GET_PARTNER]: (state, payload) => ({
      ...state,
      ...payload.response,
    }),
    [RESPONSE_DELETE_PARTNER]: () => createDefaults(),
  },
  createDefaults
);
