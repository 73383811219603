import styled from 'styled-components/native';
import { withDefaultTypographyInputProps } from '@sp/ui/typography/index.shared';

const BodyInput = styled(withDefaultTypographyInputProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.BODY.DESKTOP}px;
  overflow: hidden;
`;

BodyInput.defaultProps = {
  fontWeight: 'regular',
};

export default BodyInput;
