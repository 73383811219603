import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { DashboardRow, OneLineRow } from '@sp/ui/base/rows';
import { Switch } from '@sp/ui/base/inputs';
import { selectNotificationSettingsForType } from 'selectors/settings';
import ShowMoreOrLess from '@sp/ui/layout/ShowMoreOrLess';
import { Link, H5, Subtitle } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import Tracking from 'services/tracking';
import { NOTIFICATION_SETTINGS } from 'areas/more/trackingIds';

const NotificationSection = ({ title, iconLeft, i18n, settings, onPress, companyName }) => (
  <>
    <DashboardRow iconLeft={iconLeft} textLeft={<H5>{title}</H5>} />
    {settings.map((setting) => {
      const description = i18n.t(`dynamic|${setting.key}_description`, { companyName });

      return (
        <Fragment key={setting.key}>
          <OneLineRow
            textLeft={i18n.t(`dynamic|${setting.key}`)}
            componentRight={
              <Switch
                value={setting.isEnabled}
                onValueChange={() => {
                  onPress(setting.key, !setting.isEnabled);
                  Tracking.trackEvent(NOTIFICATION_SETTINGS, {
                    key: setting.key,
                    isEnabled: !setting.isEnabled,
                  });
                }}
              />
            }
          />
          <Margin horizontal="md" marginBottom="m">
            <ShowMoreOrLess
              moreButton={<Link>{i18n.t('common|læs mere')}</Link>}
              lessButton={<Link>{i18n.t('common|læs mindre')}</Link>}
              accessibilityLabel={description}
              content={<Subtitle>{description}</Subtitle>}
            />
          </Margin>
        </Fragment>
      );
    })}
  </>
);

NotificationSection.propTypes = {
  title: PropTypes.string.isRequired,
  iconLeft: PropTypes.string.isRequired,
  settings: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  settings: selectNotificationSettingsForType(state, props.type),
});

export default withComponentContainer({
  i18ns: ['dynamic'],
  mapStateToProps,
})(NotificationSection);
