import React, { useMemo, Fragment } from 'react';
import { ImageBackground } from 'react-native';
import PropTypes from 'prop-types';
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated';
import styled, { withTheme } from 'styled-components/native';
import Conditional from 'decorators/conditional';
import BasePage, {
  TopContent,
  extractBottomActions,
  animations,
  ActionShape,
} from '@sp/ui/v2/pages/BasePage';
import Margin from '@sp/ui/base/Margin';

const CenterTabletContent = Conditional({
  tablet: styled.View`
    align-items: center;
  `,
  default: Fragment,
});

const BgWrapper = styled.View`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY};
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.8;
`;

const ScrollPage = ({
  title,
  heading,
  description,
  children,
  tabBar,
  actions,
  actionInfo,
  actionsContent,
  backButton,
  customHeader,
  hasBackButton,
  contentContainerStyle,
  testID,
  fullWidth,
  onScroll,
  scrollEventThrottle,
  scrollViewVertical,
  scrollViewHorizontal,
  scrollViewRef,
  accessibilitySummary,
  backgroundImage,
  animateTitle,
  bottomSheetOuterScrollY,
}) => {
  const scrollY = useSharedValue(0);

  const headingAnimation = animations.useHeaderAnimation(scrollY);
  const titleAnimation = animations.useTitleAnimation(scrollY);

  const { bottomActions } = extractBottomActions(actions);

  const animatedScrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      if (bottomSheetOuterScrollY) {
        bottomSheetOuterScrollY.value = e.contentOffset.y;
      }

      scrollY.value = e.contentOffset.y;
    },
  });

  const onScrollHandler = useMemo(
    () => (typeof onScroll === 'function' ? onScroll : animatedScrollHandler),
    [onScroll, animatedScrollHandler]
  );

  const renderBody = () => (
    <>
      {(heading || animateTitle || description) && (
        <TopContent
          heading={animateTitle ? title : heading}
          description={description}
          animation={animateTitle ? headingAnimation : undefined}
        />
      )}
      {children}
    </>
  );

  return (
    <BasePage
      actions={bottomActions}
      actionInfo={actionInfo}
      accessibilitySummary={accessibilitySummary}
      actionsContent={actionsContent}
      animateTitle={animateTitle}
      backButton={hasBackButton ? backButton : null}
      backgroundImage={backgroundImage}
      customHeader={customHeader}
      testID={testID}
      title={title}
      titleAnimation={titleAnimation}
      scrollY={scrollY}
    >
      <ImageBackground
        resizeMode="cover"
        source={backgroundImage}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          width: '100%',
          height: '100%',
          flex: 1,
        }}
      >
        {backgroundImage && <BgWrapper />}
        {tabBar && tabBar}
        <Animated.ScrollView
          ref={scrollViewRef}
          alwaysBounceHorizontal={false}
          alwaysBounceVertical={false}
          bounces={false}
          contentContainerStyle={contentContainerStyle}
          vertical={scrollViewVertical}
          horizontal={scrollViewHorizontal}
          onScroll={onScrollHandler}
          scrollEventThrottle={scrollEventThrottle}
          testID={`${testID}scrollView`}
        >
          {fullWidth ? renderBody() : <CenterTabletContent>{renderBody()}</CenterTabletContent>}
          <Margin marginBottom="xl" />
        </Animated.ScrollView>
      </ImageBackground>
    </BasePage>
  );
};

ScrollPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bottomSheetOuterScrollY: PropTypes.any,
  children: PropTypes.node,
  tabBar: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  customHeader: PropTypes.node,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  testID: PropTypes.string,
  contentContainerStyle: PropTypes.object,
  fullWidth: PropTypes.bool,
  onScroll: PropTypes.func,
  scrollEventThrottle: PropTypes.number,
  scrollViewVertical: PropTypes.bool,
  scrollViewHorizontal: PropTypes.bool,
  scrollViewRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  actionsContent: PropTypes.node,
  backgroundImage: PropTypes.node,
  animateTitle: PropTypes.bool,
};

ScrollPage.defaultProps = {
  bottomSheetOuterScrollY: undefined,
  title: undefined,
  heading: undefined,
  description: undefined,
  isFocused: false,
  children: undefined,
  tabBar: undefined,
  actions: undefined,
  actionInfo: undefined,
  backButton: undefined,
  customHeader: undefined,
  hasBackButton: true,
  testID: undefined,
  contentContainerStyle: undefined,
  fullWidth: false,
  onScroll: undefined,
  scrollEventThrottle: 16,
  scrollViewVertical: false,
  scrollViewHorizontal: false,
  scrollViewRef: undefined,
  accessibilitySummary: undefined,
  actionsContent: undefined,
  backgroundImage: undefined,
  animateTitle: false,
};

export default withTheme(ScrollPage);
