import React from 'react';
import Bar from '@sp/ui/v2/pages/MenuPage/Bar';
import type { CurrentProgressType } from 'services/reducers/flowControlReducer';

const { default: Margin } = require('@sp/ui/base/Margin');
const { H3 } = require('@sp/ui/v2/typography');

const ContentHeaderMobile: React.FC<{
  currentProgress: CurrentProgressType[];
  title?: string;
}> = ({ currentProgress, title }) => (
  <>
    <Margin marginBottom="lg">
      <Bar progress={currentProgress} />
    </Margin>
    {title && (
      <Margin marginBottom="md">
        <H3>{title}</H3>
      </Margin>
    )}
  </>
);

export default ContentHeaderMobile;
