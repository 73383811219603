import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Vibration } from 'react-native';
import styled from 'styled-components/native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import fontStyles from '@sp/ui/typography/fontStyles';
import { FONT_SIZES } from '@sp/ui/settings';
import { getCurrentDeviceType, isWeb } from '@sp/ui/helpers/device';

const CODE_BOX_HEIGHT = 60;
const CODE_BOX_WIDTH = 40;
const CODE_BOX_BORDER_RADIUS = 5;
const CODE_BOX_MARGIN_HORIZONTAL = 4;
const ERROR_CODE_BOX_BORDER_WIDTH = 2;

const Cell = styled.Text`
  padding: 0;
  text-align: center;
  width: ${CODE_BOX_WIDTH}px;
  height: ${CODE_BOX_HEIGHT}px;
  font-size: ${getCurrentDeviceType() === 'desktop'
    ? `${FONT_SIZES.H2.DESKTOP}px`
    : `${FONT_SIZES.H2.MOBILE}px`};
  ${fontStyles('bold')};
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  border-radius: ${CODE_BOX_BORDER_RADIUS}px;
  border-width: ${(props) =>
    props.invalid ? ERROR_CODE_BOX_BORDER_WIDTH : props.theme.DEFAULTS.BORDER_WIDTH}px;
  border-color: ${(props) =>
    props.invalid ? props.theme.COLORS.PRIMARY_ALERT : props.theme.COLORS.PRIMARY_BORDER};
  color: ${(props) => props.theme.COLORS.PRIMARY_TEXT};
  margin-left: ${CODE_BOX_MARGIN_HORIZONTAL}px;
  margin-right: ${CODE_BOX_MARGIN_HORIZONTAL}px;
  line-height: ${CODE_BOX_HEIGHT - ERROR_CODE_BOX_BORDER_WIDTH}px;
  ${isWeb ? 'outline-style: none;' : null}
  box-shadow: none;
`;

const CodeInput = ({ length, autoFocus, invalid, onChangeText, value, onComplete }) => {
  const ref = useBlurOnFulfill({ value, cellCount: length });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: onChangeText,
  });

  useEffect(() => {
    if (value && value.length === length) {
      if (onComplete) onComplete(value);
    }
  }, [length, onComplete, value]);

  useEffect(() => {
    if (invalid && value && value.length === length) {
      Vibration.vibrate();
      onChangeText('');
    }
  }, [invalid, length, onChangeText, value]);

  return (
    <CodeField
      ref={ref}
      {...props}
      value={value}
      onChangeText={onChangeText}
      cellCount={length}
      keyboardType="number-pad"
      textContentType="oneTimeCode"
      autoFocus={autoFocus}
      renderCell={({ index, symbol, isFocused }) => (
        <Cell key={index} onLayout={getCellOnLayoutHandler(index)} invalid={invalid}>
          {symbol || (isFocused ? <Cursor /> : null)}
        </Cell>
      )}
    />
  );
};

CodeInput.propTypes = {
  length: PropTypes.number,
  autoFocus: PropTypes.bool,
  invalid: PropTypes.bool,
  onChangeText: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
};

CodeInput.defaultProps = {
  length: 6,
  autoFocus: false,
  invalid: false,
  onComplete: undefined,
};

export default CodeInput;
