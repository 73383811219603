import React from 'react';
import PropTypes from 'prop-types';
import ViewSelector from '@sp/ui/layout/ViewSelector';
import { TopContainer } from 'areas/dashboard/styles';
import { useRoute } from '@react-navigation/native';
import useI18n from 'hooks/useI18n';
import {
  PAYOUTS_ACCUMULATED_TAB,
  PAYOUTS_ONE_TIME_TAB,
  PAYOUTS_MONTHLY_TAB,
} from 'constants/testIds/dashboard';
import { hasOnetimePayouts, hasOverTimePayouts } from 'areas/dashboard/helpers';
import Tracking from 'services/tracking';
import { payoutTypes } from 'areas/dashboard/props';
import * as tabNames from './constants/tabNames';

const GraphSelector = ({ focusedTab, payoutType, payouts, setFocusedTab }) => {
  const route = useRoute();
  const { i18n } = useI18n();

  const hasOneTimePayout = hasOnetimePayouts(payouts);
  const hasOverTimePayout = hasOverTimePayouts(payouts, payoutType);

  return (
    <TopContainer>
      <ViewSelector
        tabs={[
          {
            testID: PAYOUTS_ACCUMULATED_TAB,
            id: tabNames.PAYOUTS_ALL,
            onPress: () => {
              Tracking.trackEvent({ area: route.name, action: 'ClickPayoutsAll' });
              setFocusedTab(tabNames.PAYOUTS_ALL);
            },
            title: i18n.t('Samlet'),
            iconName: 'rowGraph',
            disabled: !(hasOneTimePayout && hasOverTimePayout),
          },
          {
            testID: PAYOUTS_ONE_TIME_TAB,
            id: tabNames.PAYOUTS_ONE_TIME,
            onPress: () => {
              Tracking.trackEvent({ area: route.name, action: 'ClickPayoutsOneTime' });
              setFocusedTab(tabNames.PAYOUTS_ONE_TIME);
            },
            title: i18n.t('Engangs'),
            iconName: 'rowShortGraph',
            disabled: !hasOneTimePayout,
          },
          {
            testID: PAYOUTS_MONTHLY_TAB,
            id: tabNames.PAYOUTS_OVER_TIME,
            onPress: () => {
              Tracking.trackEvent({ area: route.name, action: 'ClickPayoutsOverTime' });
              setFocusedTab(tabNames.PAYOUTS_OVER_TIME);
            },
            title: i18n.t('Månedlig'),
            iconName: 'rowLongGraph',
            disabled: !hasOverTimePayout,
          },
        ]}
        focusedTab={focusedTab}
      />
    </TopContainer>
  );
};

GraphSelector.propTypes = {
  focusedTab: PropTypes.string.isRequired,
  payoutType: PropTypes.oneOf(payoutTypes).isRequired,
  payouts: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
    perYear: PropTypes.arrayOf(PropTypes.object),
  }),
  setFocusedTab: PropTypes.func.isRequired,
};

GraphSelector.defaultProps = {
  payouts: undefined,
};

export default GraphSelector;
