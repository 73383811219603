import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
const deviceSelectors = require('selectors/device');

const useDeviceType = () => {
  return useAppSelector(
    (state) => ({
      isDesktop: deviceSelectors.selectIsDeviceTypeDesktop(state),
    }),
    shallowEqual
  );
};

export default useDeviceType;
