import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import FlexPage from '@sp/ui/pages/FlexPage';
import Loader from '@sp/ui/base/Loader';
import { injectImpersoToken } from '@sampension/api-middleware';
import withInit from 'decorators/init';
import { startApp } from 'actions/onboarding';

import { getInjectAccessToken, setup as setupApi } from 'actions/api';
import useI18n from 'hooks/useI18n';

const LOADER_HEIGHT = 100;

const Wrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  height: ${LOADER_HEIGHT}px;
`;

const InjectToken = ({ theme }) => {
  const { i18n } = useI18n();

  return (
    <SafeAreaWrapper>
      <FlexPage heading={i18n.t('Exchanging token')} hasBackButton={false}>
        <Wrapper>
          <Loader color={theme.COLORS.PRIMARY} text={null} blur={false} fullScreen={false} />
        </Wrapper>
      </FlexPage>
    </SafeAreaWrapper>
  );
};

InjectToken.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default compose(
  connect(),
  withInit(({ action, getProps }) =>
    action(
      async () => {
        const { route, dispatch } = getProps();

        const { code } = route.params || {};

        await dispatch(setupApi());
        const impersoToken = await dispatch(getInjectAccessToken(code));

        await dispatch(injectImpersoToken(impersoToken));
        await dispatch(startApp());
      },
      {
        loader: true,
      }
    )
  )
)(withTheme(InjectToken));
