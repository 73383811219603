import {
  exchangeAuthToken,
  setup,
  get,
  post as newPost,
  remove,
  put as newPut,
  poll,
  extractToken as getAccessToken,
  getServiceUrl,
  cancelRequestsByGroup,
  cancelRequestTimeouts,
} from '@sampension/api-middleware';
import { INJECT_ACCESS_TOKEN, GET_API_STATUS } from 'constants/actionTypes';
import { getQueryData } from 'helpers/url';

const post = (name, data, service, url, options) => newPost(name, service, url, data, options);
const put = (name, data, service, url, options) => newPut(name, service, url, data, options);

const exchangeToken = (url) => {
  const query = getQueryData(url);
  const action = exchangeAuthToken(query);
  return action;
};

export const getInjectAccessToken = (code) =>
  post(INJECT_ACCESS_TOKEN, {}, 'api', `/impersonation/v1/tokens?code=${code}`);

export const getAPIStatus = () => get(GET_API_STATUS, 'api', '/app-service/v2/status');

const cancelRequestByReference = cancelRequestsByGroup;

export {
  get,
  post,
  remove,
  put,
  poll,
  setup,
  exchangeToken,
  getAccessToken,
  getServiceUrl,
  cancelRequestsByGroup,
  cancelRequestByReference,
  cancelRequestTimeouts,
};
