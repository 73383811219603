export default (companies, i18n) => {
  const companyInfo = [];
  let companyNamesExist = false;
  if (companies) {
    companies.forEach((item) => {
      if (item?.name) {
        companyNamesExist = true;
        companyInfo.push(
          i18n.t('dashboard|companyInfo', { name: item.name, cvr: item?.cvrNumber?.number })
        );
      } else {
        companyInfo.push(
          i18n.t('dashboard|companyInfoSeOnly', { seNumber: item?.seNumber?.number })
        );
      }
    });
  }

  return {
    companyInfo,
    companyNamesExist,
  };
};
