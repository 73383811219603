import { useAppSelector } from 'hooks/redux';
const { selectBookedAppointments } = require('selectors/booking');
const { formatDate, getAppointmentEndTime, formatAppointmentSlot } = require('helpers/string');

export const useSelectBookingTimeslot = () => {
  const bookedAppointments =
    useAppSelector<[{ startsAt: string; duration: string }]>(selectBookedAppointments);

  const { startsAt = undefined, duration = undefined } = bookedAppointments?.[0] || {};

  const endsAt = duration ? getAppointmentEndTime(startsAt, duration) : undefined;
  const timeslot = startsAt ? formatAppointmentSlot(startsAt, endsAt) : undefined;

  return {
    date: formatDate(startsAt),
    timeslot,
    endsAt,
  };
};
