import React, { useState } from 'react';
import withTranslation from 'decorators/i18n';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form, actions as formActions } from 'react-formed';
import styled from 'styled-components/native';
import { PickerButton } from '@sp/ui/base/buttons';
import InputPage from '@sp/ui/pages/InputPage';
import { ActionShape } from '@sp/ui/pages/BasePage';
import Selector from 'containers/form/Selector';
import Message from 'containers/form/Message';
import Input from 'containers/form/Input';
import Picker from 'containers/form/Picker';
import { isIOS } from 'helpers/platform';

const TEXT_MESSAGE_TOP_PADDING = 16;

const Content = styled.View`
  padding: 0px ${(props) => props.theme.SPACINGS.md}px ${(props) => props.theme.SPACINGS.md}px;
  flex: 1;
`;

const PickerPage = ({
  formName,
  formInitValues,
  resetFormOnMount,
  pickerOptions,
  actions,
  title,
  heading,
  description,
  pickerPlaceholder,
  messagePlaceholder,
  pickerFormValue,
  backButton,
  hasBackButton,
  children,
  attachments,
  onSelect,
  onAttachmentDeletePress,
  hasCustomSubject,
  hasContentError,
  customSubject,
  customHeader,
  i18n,
  testID,
}) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [hasInteractedWithPicker, setHasInteractedWithPicker] = useState(false);
  const dispatch = useDispatch();

  return (
    <Form name={formName} initValues={formInitValues} resetOnMount={resetFormOnMount}>
      <InputPage
        title={title}
        heading={heading}
        description={description}
        actions={actions}
        disableScroll
        backButton={backButton}
        hasBackButton={hasBackButton}
        customHeader={customHeader}
        keyboardDoneLabel={i18n.t('Skjul keyboard')}
        testID={testID}
      >
        <Content>
          {isIOS ? (
            <PickerButton
              name="subject"
              placeholder={pickerPlaceholder}
              value={
                pickerFormValue ||
                (hasInteractedWithPicker ? pickerOptions && pickerOptions[0] : undefined)
              }
              onPress={() => {
                setIsPickerOpen(true);
                setHasInteractedWithPicker(true);
                Keyboard.dismiss();
              }}
              isPickerOpen={isPickerOpen}
              testID={`${testID}PickerButton`}
            />
          ) : (
            <Selector
              name="subject"
              placeholder={pickerPlaceholder}
              options={pickerOptions}
              onSelect={(option) => (typeof onSelect === 'function' ? onSelect(option) : undefined)}
            />
          )}
          {hasCustomSubject && (
            <Input
              name="customSubject"
              marginTop={TEXT_MESSAGE_TOP_PADDING}
              placeholder={i18n.t('Emne')}
              defaultValue={customSubject}
            />
          )}
          <Message
            name="content"
            flex={1}
            hasError={hasContentError}
            onFocus={() => {
              setIsPickerOpen(false);
              if (hasInteractedWithPicker && !pickerFormValue) {
                dispatch(
                  formActions.setValue(formName, 'subject', pickerOptions && pickerOptions[0])
                );
              }
            }}
            placeholder={messagePlaceholder}
            marginTop={TEXT_MESSAGE_TOP_PADDING}
            onAttachmentDeletePress={onAttachmentDeletePress}
            attachments={attachments}
            deleteImageText={i18n.t('Fjern billede')}
            testID={`${testID}Message`}
          />
          {children}
        </Content>
      </InputPage>
      {isIOS && isPickerOpen && (
        <Picker
          options={pickerOptions}
          testID={`${testID}Picker`}
          name="subject"
          accessibilityLabelClose={i18n.t('Luk vælger og vend tilbage til besked')}
          submitTitle={i18n.t('OK')}
          onSubmit={(option) => {
            setIsPickerOpen(false);

            if (typeof onSelect === 'function') {
              onSelect(option || (hasInteractedWithPicker && pickerOptions && pickerOptions[0]));
            }
          }}
        />
      )}
    </Form>
  );
};

PickerPage.propTypes = {
  formName: PropTypes.string.isRequired,
  formInitValues: PropTypes.object,
  resetFormOnMount: PropTypes.bool,
  pickerOptions: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.arrayOf(ActionShape),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  pickerPlaceholder: PropTypes.string,
  pickerFormValue: PropTypes.string,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func,
  onAttachmentDeletePress: PropTypes.func,
  customSubject: PropTypes.string,
  hasCustomSubject: PropTypes.bool,
  hasContentError: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  customHeader: PropTypes.node,
  testID: PropTypes.string,
};

PickerPage.defaultProps = {
  formInitValues: undefined,
  pickerOptions: undefined,
  resetFormOnMount: false,
  actions: undefined,
  title: undefined,
  heading: undefined,
  description: undefined,
  messagePlaceholder: undefined,
  pickerPlaceholder: undefined,
  pickerFormValue: undefined,
  backButton: undefined,
  hasBackButton: true,
  children: undefined,
  attachments: undefined,
  onSelect: undefined,
  onAttachmentDeletePress: undefined,
  customSubject: undefined,
  hasCustomSubject: false,
  hasContentError: false,
  customHeader: undefined,
  testID: undefined,
};

export default withTranslation()(PickerPage);
