import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { selectDeeplink } from 'selectors/deeplink';
import { useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import type { NavigationRouteTypes, BackNavigationType } from 'containers/v2/pages/MenuPage';

export const useBackAndForwardButtonNavigation = () => {
  const { isDeeplink, deeplinkFlow } = useAppSelector(selectDeeplink, shallowEqual) ?? {};
  const navigation = useNavigation();

  let backButtonNavigation: BackNavigationType;
  let forwardButtonNavigation: NavigationRouteTypes | undefined;

  if (isDeeplink) {
    if (deeplinkFlow === routeNames.PARTNER) {
      backButtonNavigation = routeNames.PARTNER_CONSENTS;
      forwardButtonNavigation = routeNames.PARTNER_INCOME_INFORMATION;
    } else if (deeplinkFlow === routeNames.DEPOSITS) {
      backButtonNavigation = routeNames.DEPOSITS_PLACEMENT;
      forwardButtonNavigation = routeNames.DEPOSITS_PLACEMENT;
    } else if (deeplinkFlow === routeNames.MEETING_PREPARATION) {
      backButtonNavigation = routeNames.MEETING_PREPARATION_INTRO;
    }
  } else {
    backButtonNavigation = navigation.canGoBack() ? navigation.goBack : undefined;
  }

  return {
    backButtonNavigation,
    forwardButtonNavigation,
  };
};
