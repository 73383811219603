import React from 'react';
import { View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
});

const BlurViewWeb = () => <View style={styles.absolute} />;

export default BlurViewWeb;
