import styled from 'styled-components/native';
import Conditional from 'decorators/conditional';
import ConditionalImage from 'containers/theme/ConditionalImage';
import { isWeb } from '@sp/ui/helpers/device';

const CONTENT_PADDING_LEFT_RIGHT = 16;
const CONTENT_WIDTH = 620;
const CONTENT_PADDING_TOP = 16;
const TITLE_PADDING_TOP = 24;
const TITLE_PADDING_BOTTOM = 24;
const MOBILE_BUTTON_BOTTOM_POSITION = 8;
const BUTTON_BOTTOM_POSITION = 32;
const BUTTON_BUTTON_PADDING_BOTTOM = 8;
const BACK_PADDING_TOP = 24;
const BACK_PADDING_LEFT = 10;
const PADDING_BOTTOM = 16;

export const Wrapper = styled.View`
  flex: 1;
  height: 100%;
  align-items: center;
  background: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  ${isWeb ? 'overflow: auto;' : ''}
`;

export const BackButton = Conditional({
  desktop: styled.View`
    width: 100%;
    padding-left: ${BACK_PADDING_LEFT}px;
  `,
  default: styled.View`
    width: 100%;
    padding-top: ${BACK_PADDING_TOP}px;
    padding-left: ${BACK_PADDING_LEFT}px;
  `,
});

export const MenuWrapper = styled.View`
  width: 100%;
`;

const ContentMobile = styled.View`
  padding-left: ${CONTENT_PADDING_LEFT_RIGHT}px;
  padding-right: ${CONTENT_PADDING_LEFT_RIGHT}px;
  align-items: center;
  max-width: ${CONTENT_WIDTH}px;
`;

// NOTE: "z-index: auto" is needed for button to not overflow selector dropdown
export const Content = Conditional({
  default: ContentMobile,
  desktop: styled.View`
    align-items: center;
    padding-top: ${CONTENT_PADDING_TOP}px;
    max-width: ${CONTENT_WIDTH}px;
    ${isWeb ? 'z-index: auto' : ''};
  `,
});

export const TitleWrapper = styled.View`
  padding-top: ${TITLE_PADDING_TOP}px;
  padding-bottom: ${TITLE_PADDING_BOTTOM}px;
`;

export const Image = styled(ConditionalImage)`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const BottomWrapper = Conditional({
  default: styled.View`
    bottom: ${MOBILE_BUTTON_BOTTOM_POSITION}px;
    align-items: center;
    position: absolute;
    width: 100%;
    padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  `,
  desktop: styled.View`
    align-items: center;
    position: absolute;
    bottom: ${BUTTON_BOTTOM_POSITION};
  `,
});

export const ButtonWrapper = styled.View`
  padding-bottom: ${BUTTON_BUTTON_PADDING_BOTTOM}px;
`;

export const BottomContent = styled.View`
  padding-bottom: ${PADDING_BOTTOM}px;
`;
