import React, { lazy } from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import withSuspense from 'decorators/withSuspense';
import * as routeNames from 'constants/routeNames';
import profile from 'areas/profile/router';
import Settings from 'areas/preferences/Settings';
import Consents from 'areas/preferences/Consents';
import NotificationSettings from 'areas/preferences/NotificationSettings';
import NotificationSettingsCompanyGroups from 'areas/preferences/NotificationSettingsCompanyGroups';
import Terms from 'areas/general/Terms';
import Classic from 'areas/general/Classic';
import DocumentsBrowser from 'areas/documents/Browser';
import Reservations from 'areas/reservations';
import More from './More';

const LazyHealthBenefitsList = lazy(() => import('areas/healthBenefits/List'));
const LazyHealthBenefitsDetails = lazy(() => import('areas/healthBenefits/Details'));
const HealthBenefitsList = withSuspense(LazyHealthBenefitsList);
const HealthBenefitsDetails = withSuspense(LazyHealthBenefitsDetails);

const Stack = createStackNavigator();

const MoreStack = () => (
  <Stack.Navigator
    initialRouteName={routeNames.MORE_OVERVIEW}
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen name={routeNames.MORE_OVERVIEW} component={More} />
    <Stack.Screen name={routeNames.PROFILE} component={profile} />
    <Stack.Screen name={routeNames.SETTINGS} component={Settings} />
    <Stack.Screen name={routeNames.SETTINGS_CONSENTS} component={Consents} />
    <Stack.Screen name={routeNames.SETTINGS_TERMS} component={Terms} />
    <Stack.Screen
      name={routeNames.SETTINGS_NOTIFICATION_SETTINGS_COMPANY_GROUPS}
      component={NotificationSettingsCompanyGroups}
    />
    <Stack.Screen
      name={routeNames.SETTINGS_NOTIFICATION_SETTINGS}
      component={NotificationSettings}
    />
    <Stack.Screen name={routeNames.CLASSIC} component={Classic} />
    <Stack.Screen name={routeNames.DOCUMENTS} component={DocumentsBrowser} />
    <Stack.Screen name={routeNames.RESERVATIONS} component={Reservations} />
    <Stack.Screen name={routeNames.HEALTH_BENEFITS} component={HealthBenefitsList} />
    <Stack.Screen name={routeNames.HEALTH_BENEFITS_DETAILS} component={HealthBenefitsDetails} />
  </Stack.Navigator>
);

export default MoreStack;
