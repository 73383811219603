import React, { useEffect } from 'react';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
} from 'react-native-reanimated';

interface AppearOpacityAnimationProps {
  index: number;
  delay?: number;
  isReadyToRunAnimation?: Animated.SharedValue<boolean>;
  children: React.ReactNode;
}

const DEFAULT_SPRING_CONFIG = {
  damping: 15,
  mass: 1,
  stiffness: 120,
  overshootClamping: false,
  restSpeedThreshold: 1,
  restDisplacementThreshold: 1,
};

const AppearOpacityAnimation: React.FC<AppearOpacityAnimationProps> = ({
  index,
  delay,
  isReadyToRunAnimation,
  children,
}) => {
  const translationY = useSharedValue(50);
  const animatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(translationY.value, [0, 50 / 2, 50], [1, 0.5, 0]),
    transform: [
      {
        translateX: translationY.value,
      },
    ],
  }));

  const derivedIsReadyToRunAnimation =
    typeof isReadyToRunAnimation === 'boolean' ? isReadyToRunAnimation : true;

  useEffect(() => {
    if (derivedIsReadyToRunAnimation) {
      translationY.value = withDelay(
        150 * index + (delay ?? 0),
        withSpring(0, DEFAULT_SPRING_CONFIG)
      );
    }
  }, [index, delay, translationY, derivedIsReadyToRunAnimation]);

  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};

export default AppearOpacityAnimation;
