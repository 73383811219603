import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Animated } from 'react-native';
import styled from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { COLORS, HIT_SLOP } from '@sp/ui/settings';
import { isIphone5 } from '@sp/ui/helpers/device';
import withTranslation from 'decorators/i18n';
import { Body } from '@sp/ui/typography';

const Overlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ height, controlsHeight, expanded }) => (expanded ? controlsHeight : height)}px;
  background: ${({ expanded }) => (expanded ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0,0,0,0.7)')};
`;

const Content = styled.View``;

const Controls = styled.View`
  width: 100%;
  height: ${({ height }) => height}px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.View`
  padding: ${(props) => props.theme.SPACINGS.sm}px;
  transform: ${({ expanded }) => (expanded ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

class ExpandableArea extends Component {
  constructor(props) {
    super(props);

    const { expanded, minHeight, maxHeight, controlsHeight } = props;
    const height = expanded ? maxHeight + controlsHeight : minHeight;
    this.state = {
      expanded,
      animation: new Animated.Value(height),
    };
    this.toggleHeight = this.toggleHeight.bind(this);
  }

  toggleHeight() {
    const { minHeight, maxHeight, controlsHeight } = this.props;
    const { expanded, animation } = this.state;
    const initialHeight = expanded ? maxHeight + controlsHeight : minHeight;
    const finalHeight = expanded ? minHeight : maxHeight + controlsHeight;

    this.setState({
      expanded: !expanded,
    });

    animation.setValue(initialHeight);
    Animated.timing(animation, {
      toValue: finalHeight,
      duration: 250,
    }).start();
  }

  render() {
    const { minHeight, controlsHeight, children, i18n } = this.props;
    const { expanded, animation } = this.state;
    const labelColor = expanded ? COLORS.PRIMARY : '#fff';

    const animatedStyles = {
      height: animation,
      justifyContent: 'flex-end',
      overflow: 'hidden',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    };

    return isIphone5() ? (
      <Animated.View style={animatedStyles}>
        <Content>{children}</Content>
        <Overlay
          onPress={() => this.toggleHeight()}
          expanded={expanded}
          height={minHeight}
          controlsHeight={controlsHeight}
          hitSlop={HIT_SLOP}
        >
          <Controls height={expanded ? controlsHeight : minHeight}>
            <Body color={labelColor}>{expanded ? i18n.t('Skjul graf') : i18n.t('Se graf')}</Body>
            <IconWrapper expanded={expanded}>
              <Icon name="caret" width={12} height={6} fill={labelColor} />
            </IconWrapper>
          </Controls>
        </Overlay>
      </Animated.View>
    ) : (
      children
    );
  }
}

ExpandableArea.propTypes = {
  expanded: PropTypes.bool,
  minHeight: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  controlsHeight: PropTypes.number,
  children: PropTypes.node.isRequired,
  i18n: PropTypes.object.isRequired,
};

ExpandableArea.defaultProps = {
  expanded: false,
  controlsHeight: 20,
};

export default withTranslation()(ExpandableArea);
