import React from 'react';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { withTheme } from 'styled-components/native';
import Margin from '@sp/ui/base/Margin';
import { ModalTitle, ModalRegularText, ModalLinkText } from '../modal/modalTextComponents';

const InvestmentCosts = ({ companyName, linkText, onPress, i18n, theme, type }) => (
  <>
    <ModalTitle subtitle={i18n.t('savings|Investeringsomkostninger')} />
    <ModalRegularText
      text={i18n.t(
        'savings|Dit depot bliver investeret i fx aktier, obligationer og alternative investeringer (ex. ejendomme), for at kunne finansiere udbetalinger til din alderspension eller forsikringsudbetalinger i fremtiden.'
      )}
    />
    <ModalRegularText
      text={i18n.t(
        'savings|Der er omkostninger forbundet med at investere, uanset om {{ companyName }} selv investerer eller overlader det til en ekstern investeringsforvalter.',
        { companyName }
      )}
    />
    <ModalRegularText
      fontStyle="italic"
      removeMarginTop
      text={
        type === 'PJD' || type === 'AP'
          ? i18n.t(
              'savings|Investeringsomkostningerne er modregnet i den depotrente (eller afkast), du har fået tilskrevet, og de bliver således ikke trukket direkte på dit depot.'
            )
          : i18n.t(
              'savings|Investeringsomkostningerne er modregnet i det afkast (eller depotrente), du har fået tilskrevet, og de bliver således ikke trukket direkte på dit depot.'
            )
      }
    />
    <ModalRegularText
      text={i18n.t(
        'savings|Investeringsomkostningerne dækker både omkostninger afholdt i {{ companyName }} og eksterne handels- og administrationsomkostninger - herunder de beregnede investeringsomkostninger knyttet til de investeringsforeninger og unoterede fonde, {{ companyName }} har investeret i. {{ companyName }} investerer fx i skove og ejendomme, og i håndteringen af disse typer af investeringer kræves en helt særlig ekspertise, og derfor har {{ companyName }} udgifter til forskellige konsulenter og forvaltere.',
        { companyName }
      )}
    />
    <Margin marginTop="md" />
    <ModalLinkText
      textBefore={i18n.t(
        'savings|{{ companyName }} har opgjort investeringsomkostningerne ud fra retningslinjer, der er defineret af vores brancheforening, Forsikring og Pension. Du kan læse mere om {{ companyNameSpecific }} investeringer på',
        {
          companyName,
          companyNameSpecific: `${companyName}s`,
        }
      )}
      link={{ linkText }}
      margin={`${theme.SPACINGS.md}px 0px`}
      onPress={onPress}
    />
  </>
);

InvestmentCosts.propTypes = {
  linkText: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  type: PropTypes.func,
};

InvestmentCosts.defaultProps = {
  linkText: undefined,
  type: undefined,
};

export default withTheme(withComponentContainer()(InvestmentCosts));
