import { isPhoneNumberEmpty } from './helpers';

const SCREEN_STATES = {
  EMPTY: {
    button: 'SaveRequestVerification',
    inputIcon: null,
  },
  PENDING: {
    button: 'Pending',
    inputIcon: 'unverified',
  },
  // NOTE:
  // User already has verified phone number
  // And started verification flow by adding new pending number
  // But then typed in verified number into form
  PENDING_BUT_VERIFIED_VALUE_IN_THE_FORM: {
    button: 'SaveCancelVerification',
    inputIcon: 'verified',
  },
  PENDING_FORM_IS_DIRTY: {
    button: 'SaveRequestVerification',
    inputIcon: null,
  },
  VERIFIED: {
    button: 'NavigateToNotificationSettings',
    inputIcon: 'verified',
  },
};

export const reducer = (state, action) => {
  let button;
  let inputIcon;

  switch (action.type) {
    case 'SET_FORM_VALUE': {
      const { value } = action;
      const { verifiedValue, pendingValue } = state;
      const form = {
        isFormEmpty: !value || isPhoneNumberEmpty(value),
        formValue: value,
      };

      const isPendingButVerifiedValueInTheForm =
        pendingValue && verifiedValue && value === verifiedValue;
      const isPending = value === pendingValue;
      const isEmpty = !value || isPhoneNumberEmpty(value);
      const isVerified = value === verifiedValue;
      const isPendingDirty = value !== action.formValue;

      if (isPendingButVerifiedValueInTheForm) {
        return {
          ...state,
          ...form,
          ...SCREEN_STATES.PENDING_BUT_VERIFIED_VALUE_IN_THE_FORM,
        };
      }

      if (isEmpty) {
        return {
          ...state,
          ...form,
          ...SCREEN_STATES.EMPTY,
        };
      }

      if (isVerified) {
        return {
          ...state,
          ...form,
          ...SCREEN_STATES.VERIFIED,
        };
      }

      if (isPending) {
        return {
          ...state,
          ...form,
          ...SCREEN_STATES.PENDING,
        };
      }

      if (isPendingDirty) {
        return {
          ...state,
          ...form,
          ...SCREEN_STATES.PENDING_FORM_IS_DIRTY,
        };
      }

      return {
        ...state,
        ...form,
        button,
        inputIcon,
      };
    }
    case 'VERIFY_VALUE': {
      const hasNoPending = action.value && !action.pendingValue;

      if (hasNoPending) {
        return {
          ...state,
          ...SCREEN_STATES.VERIFIED,
        };
      }

      return state;
    }
    case 'SET_FORM_VALIDITIY': {
      return {
        ...state,
        isFormValueValid: action.value,
      };
    }
    default:
      throw new Error();
  }
};
