import React from 'react';
import PropTypes from 'prop-types';
import { Body, Link } from '@sp/ui/typography';
import ModalBulletList from './ModalBulletList';

const Wrapper = ({ listType, children }) =>
  listType === 'bulletList' ? <ModalBulletList>{children}</ModalBulletList> : <>{children}</>;

const ModalLinkText = ({ textBefore, link, listType, textAfter, onPress }) => {
  const { linkText, linkPunctuation } = link;

  return (
    <Wrapper listType={listType}>
      <Body>
        {textBefore && `${textBefore} `}
        <Link onPress={onPress}>{linkText}</Link>
        {linkPunctuation || '.'}
        {textAfter}
      </Body>
    </Wrapper>
  );
};

ModalLinkText.propTypes = {
  textBefore: PropTypes.string,
  textAfter: PropTypes.string,
  listType: PropTypes.string,
  link: PropTypes.object,
  onPress: PropTypes.func,
};

ModalLinkText.defaultProps = {
  textBefore: undefined,
  textAfter: undefined,
  listType: undefined,
  onPress: undefined,
  link: {},
};

Wrapper.propTypes = {
  listType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Wrapper.defaultProps = {
  listType: undefined,
};

export default ModalLinkText;
