import React from 'react';
import { useTheme } from 'styled-components';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Body } = require('@sp/ui/v2/typography');
const { Label } = require('@sp/ui/typography');

const MenuHeader: React.FC<{ name: string; menuTitle: string; menuSubtitle: string }> = ({
  name,
  menuTitle,
  menuSubtitle,
}) => {
  const { V2_PRIMARY_TEXT } = useTheme().COLORS;

  return (
    <Margin marginBottom="md">
      <Body color={V2_PRIMARY_TEXT} fontWeight="semibold">
        {name}
      </Body>
      <Margin marginTop="m">
        <Label color={V2_PRIMARY_TEXT} fontWeight="normal">
          {menuTitle}
        </Label>
        <Margin marginTop="s">
          <Label color={V2_PRIMARY_TEXT} fontWeight="normal">
            {menuSubtitle}
          </Label>
        </Margin>
      </Margin>
    </Margin>
  );
};

export default MenuHeader;
