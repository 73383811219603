import { useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { nanoid } from 'nanoid';
import type { RootState } from 'store';
import { useAppSelector } from 'hooks/redux';
import useAsyncCallback from 'hooks/useAsyncCallback';
import useI18n from 'hooks/useI18n';
import { CLICK_UPDATE_CALCULATION } from 'areas/dashboard/trackingIds';
import { usePatchDisposableIncome } from '../hooks';
import { selectDisposableIncomeOptions } from 'selectors/dashboard';
import { selectFormAdjustIncome } from 'selectors/dashboardForms';

const { default: Tracking } = require('services/tracking');
const { default: LocalNotificationContext } = require('context/NotificationContext');
const { validateInput } = require('areas/dashboard/calculations/helpers/income');

const errorNotificationId = nanoid();

const selectAdjustDisposableIncome = (state: RootState) => ({
  formAdjustIncome: selectFormAdjustIncome(state),
  disposableIncomeOptions: selectDisposableIncomeOptions(state),
});

export const usePatchDisposableIncomeWithInputValidation = () => {
  const i18n = useI18n(['dashboard']);
  const { showError, dismiss } = useContext(LocalNotificationContext);
  const [updateDisposableIncome] = usePatchDisposableIncome();

  const { formAdjustIncome, disposableIncomeOptions } = useAppSelector(
    (state) => selectAdjustDisposableIncome(state),
    shallowEqual
  );

  return useAsyncCallback(
    async (goBack = false) => {
      const valid = validateInput({
        formAdjustIncome,
        showErrorNotification: () =>
          showError(
            i18n.t('dashboard|adjustCalculationDeviation', {
              amount: formAdjustIncome?.enteredIncome,
              moneySymbol: i18n.t('moneySymbol'),
            }),
            {
              buttonText: i18n.t('dashboard|approveAmount'),
              onPressButton: async () => {
                dismiss(errorNotificationId);
                await updateDisposableIncome(goBack);
              },
              id: errorNotificationId,
              type: 'warning',
            }
          ),
        disposableIncomeOptions,
      });

      if (valid) {
        Tracking.trackEvent(CLICK_UPDATE_CALCULATION);
        await updateDisposableIncome(goBack);
      }
    },
    [formAdjustIncome, disposableIncomeOptions]
  );
};
