import { useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import useAsyncInit from 'hooks/useAsyncInit';
import { selectIsFlowTax } from 'selectors/fetchTaxAndPensionsInfo';
import { createSession, waitForStatus } from 'actions/fetchTaxAndPensionsInfo';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import fetchTaxAndPensionsInfoController from '../fetchTaxAndPensionsInfoController';
import { getIsDevOrTest } from 'helpers/environment';

const { default: Tracking } = require('services/tracking');

const MAINTENANCE_INFORMATION = 'MaintenanceInformation';

export const useInitFetchTaxOrPensionsInfo = () => {
  const { updateFetchingState } = useContext(FetchTaxAndPensionsInfoContext);
  const isDevOrTest = getIsDevOrTest();
  const i18n = useI18n(['fetchTaxAndPensionsInfo']);
  const dispatch = useAppDispatch();
  const { updateIsFetching } = fetchTaxAndPensionsInfoController;

  const isFlowTax = useAppSelector(selectIsFlowTax, shallowEqual);

  return useAsyncInit(
    async () => {
      let pollingStatus: Record<string, any> = {};

      const fetchingArea = isFlowTax ? 'tax' : 'pension';

      const {
        pensionsInfoUrl,
        eSkatDataRedirectUri,
        sessionId,
        integrationFlowType,
        ssoTicket,
      }: any = await dispatch(createSession(fetchingArea));
      const url = isFlowTax ? eSkatDataRedirectUri : pensionsInfoUrl;
      if (!isDevOrTest && integrationFlowType === MAINTENANCE_INFORMATION) {
        Tracking.trackEvent({
          area: isFlowTax ? 'Taximport' : 'PensionInfoImport',
          action: 'InMaintenanceMode',
        });
      } else {
        pollingStatus = await dispatch(waitForStatus({ sessionId, area: fetchingArea }));
      }

      updateIsFetching(true);
      updateFetchingState({
        url,
        ssoTicket,
        cancel: pollingStatus?.cancel,
        fetchingController: undefined,
      });
    },
    [],
    {
      error: {
        type: 'blocking',
        retry: true,
        message: i18n.t(
          `fetchTaxAndPensionsInfo|${isFlowTax ? 'skatFetchingError' : 'pensionsInfoFetchingError'}`
        ),
      },
    }
  );
};
