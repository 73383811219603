export const groupSavingsDeposits = (savingsDeposits) => {
  if (!savingsDeposits?.length) return [];

  return Object.values(
    savingsDeposits.reduce((obj, { periodStart, totalAmount, ownContribution, ...rest }) => {
      obj[periodStart] = obj[periodStart] || {
        periodStart,
        payments: [],
        totalAmount: 0,
        ...rest,
      };

      obj[periodStart].payments = obj[periodStart].payments.concat([
        { totalAmount, ownContribution },
      ]);

      if (obj[periodStart].payments.length > 1) {
        obj[periodStart].totalAmount = {
          amount: obj[periodStart].payments.reduce((total, item) => {
            total += item.totalAmount.amount;
            return total;
          }, 0),
          currency: totalAmount.currency,
        };
      } else {
        obj[periodStart].totalAmount = totalAmount;
      }

      return obj;
    }, {})
  );
};
