import React, { Fragment } from 'react';
import styled from 'styled-components/native';

const { default: Conditional } = require('decorators/conditional');

const Content = Conditional({
  default: styled.View`
    padding: ${({ theme }): string => `${theme.SPACINGS.md}px ${theme.SPACINGS.xl}px`};
  `,
  desktop: Fragment,
});

const ContentWrapper: React.FC = ({ children }) => <Content>{children}</Content>;

export default ContentWrapper;
