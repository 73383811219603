// not found
export const NOT_FOUND = 'not-found';

// deeplink
export const DEEPLINK = 'deeplink';
export const DEEPLINK_WARNING = 'deeplink-warning';

// redirect
export const REDIRECT = 'redirect';

// onboarding
export const ONBOARDING = 'onboarding';
export const INTRO = 'intro';
export const INFO = 'info';
export const INCOME_SPLASH = 'income-splash';
export const CHANGELOG = 'changelog';
export const COMPANY_SPECIFICS = 'company-specifics';

// auth
export const LOGIN = 'login';
export const BIOMETRIC = 'biometric';
export const CONSENTS = 'consents';

// main stack
export const MAIN = 'main';
export const TABS = 'tabs';
export const MODAL_CMS_STACK = 'modal-cms';

// skat
export const SKAT = 'skat';
export const SKAT_DONE = 'skat-done';
export const TAX_STATEMENT_INVALID = 'tax-statement-invalid';

// Partner
export const PARTNER = 'giv-oplysninger';
export const PARTNER_CONSENTS = 'consents';
export const PARTNER_OTHER_INFORMATION = 'other-information';
export const PARTNER_PENSIONS_INFORMATION = 'get-pensions-information';
export const PARTNER_INCOME_INFORMATION = 'other-information';
export const PARTNER_RECEIPT = 'receipt';

// Meeting preparation
export const MEETING_PREPARATION = 'bliv-klar-til-moedet';
export const MEETING_PREPARATION_INTRO = 'intro';
export const MEETING_PREPARATION_ASSETS = 'vaerdier';
export const MEETING_PREPARATION_ASSETS_HOUSING = 'bolig';
export const MEETING_PREPARATION_ASSETS_LOANS = 'boliglaan';
export const MEETING_PREPARATION_ASSETS_INVESTMENTS = 'investering';
export const MEETING_PREPARATION_ASSETS_SAVINGS = 'opsparing';
export const MEETING_PREPARATION_PENSIONS_INFORMATION = 'get-pensions-information';
export const MEETING_PREPARATION_ABOUT_YOU = 'om-dig';
export const MEETING_PREPARATION_CHILDREN = 'boern';
export const MEETING_PREPARATION_CHILD = 'barn';
export const MEETING_PREPARATION_PARTNER = 'partner';
export const MEETING_PREPARATION_RECEIPT = 'kvittering';

// PensionsInfo
export const PENSION_SPLASH = 'pension-splash';
export const PENSION_CHECK = 'pension-check';

// SKAT + PI for main stack
export const SKAT_PI_STACK = 'pension-skat-stack';
export const FETCH_TAX_OR_PENSIONSINFO = 'fetch';
export const FETCH_TAX_AND_PENSIONSINFO = 'fetch-tax-and-pensionsinfo';
export const FETCH_TAX_PENSIONSINFO_INTRODUCTION = 'introduction';
export const FETCH_TAX_PENSIONSINFO_DONE = 'done';
export const FETCH_TAX_PENSIONSINFO_FAIL = 'fail';
export const FETCH_TAX_PENSIONSINFO_CANCELLED = 'cancel';
export const FETCH_PENSIONSINFO = 'fetch-pensionsinfo';
export const FETCH_TAX = 'fetch-tax';
export const SIMULATE_FETCH_TAX_OR_PENSIONSINFO = 'fetch-simulation';
export const TAX_STATEMENT_INVALID_CONSENT = 'tax-statement-invalid-consent';

// main tabs
export const DASHBOARD = 'dashboard';
export const SAVINGS = 'savings';
export const MAILBOX = 'mailbox';
export const MORE = 'more';

// dashboard
export const RETIREMENT = 'retirement';
export const DISABILITY = 'disability';
export const DEATH = 'death';
export const PENSION_DASHBOARD = 'pension-dashboard';

// dashboard adjust-calculation
export const ADJUST_CALCULATION = 'adjust-calculation';
export const ADJUST_RETIREMENT_AGE = 'adjust-retirement-age';
export const ADJUST_DISPOSABLE_INCOME = 'adjust-disposable-income';
export const ADJUST_PUBLIC_SERVICES = 'adjust-public-services';
export const ADJUST_TAXATION = 'adjust-taxation';
export const ADJUST_SAVINGS = 'adjust-savings';
export const ADJUST_TAX_INFO = 'adjust-tax-info';

// children
export const CHILDREN = 'children';
export const CHILDREN_OVERVIEW = 'overview';
export const EDIT_CHILDREN = 'edit';
export const ADD_CHILD = 'add';

export const DASHBOARD_PAYOUTS_RETIREMENT = 'payouts-retirement';
export const DASHBOARD_PAYOUTS_DEATH = 'payouts-death';
export const DASHBOARD_PAYOUTS_DISABILITY = 'payouts-disability';

export const AWARD_RULES_DISABILITY = 'award-rules-disability';
export const AWARD_RULES_DEATH = 'award-rules-death';

// payouts come from
export const PAYOUTS_ORIGIN = 'payouts-origin';

// link children
export const LINK_CHILDREN = 'link-children';
export const LINK_CHILDREN_OVERVIEW = 'overview';
export const LINK_CHILDREN_EDIT_CHILD = 'edit-child';

// children payouts
export const PAYOUTS_CHILDREN = 'policy-award-rules-children';
export const PAYOUTS_CHILDREN_OVERVIEW = 'overview';
export const PAYOUTS_CHILDREN_DETAILS = 'details';

// dashboard calculations pension
export const PRECONDITIONS_INFO = 'preconditions-info';

export const RETIREMENT_PAYOUTS = 'retirement-payouts';
export const DISABILITY_PAYOUTS = 'disability-payouts';
export const DEATH_PAYOUTS = 'death-payouts';

// more
export const MORE_OVERVIEW = 'overview';
export const PROFILE = 'profile';
export const FEEDBACK = 'feedback';
export const FEEDBACK_TYPE = 'type';
export const CMS = 'cms';
export const RECOMMENDATION = 'recommendation';
export const DEVELOPMENT = 'dev';
export const SETTINGS = 'settings';
export const HEALTH_BENEFITS = 'health-benefits';
export const HEALTH_BENEFITS_DETAILS = 'health-benefits-details';

// booking
export const BOOKING = 'booking';
export const BOOKING_MAIN = 'main';
export const BOOKING_REFETCH_PENSION_INFO = 'refetch-pension-info';
export const BOOKING_CONTACT_INFORMATION = 'contact-information';
export const BOOKING_ADD_DETAILS = 'add-details';
export const BOOK_MEETING = 'book-meeting';
export const BOOKING_CONFIRM = 'confirm-booking';
export const BOOKING_RECEIPT = 'receipt';
export const BOOKING_ADVISOR_CONTACT = 'advisor-contact';
export const BOOKING_VIP_CONTACT_INFORMATION = 'vip-contact-information';
export const BOOKING_VIP_CONTACT_ME_CONFIRM = 'vip-contact-me-confirm';

// feedback
export const FEEDBACK_CREATE = 'create';
export const FEEDBACK_COMPLETED = 'completed';
export const FEEDBACK_ADD_CONTACT_INFO = 'add-contact-info';
export const FEEDBACK_ADD_CONTACT_INFO_COMPLETED = 'add-contact-info-completed';

// mailbox
export const MAIL_OVERVIEW = 'overview';
export const MAIL_DETAILS = 'details';
export const MAIL_CREATE = 'mail-create';
export const MAIL_SIGN = 'sign';
export const MAIL_DOCUMENT = 'document';

// cms
export const CMS_CONTAINER = 'cms-container';
export const CMS_PAGE = 'cms-page';

// preferences
export const SETTINGS_CONSENTS = 'consents';
export const SETTINGS_TERMS = 'terms';
export const SETTINGS_NOTIFICATION_SETTINGS = 'notification-settings';
export const SETTINGS_NOTIFICATION_SETTINGS_COMPANY_GROUPS = 'notification-settings-company-groups';
export const SETTINGS_NOTIFICATION_SETTINGS_MODAL = 'notification-settings-modal';
export const SETTINGS_NOTIFICATION_SETTINGS_COMPANY_GROUPS_MODAL =
  'notification-settings-company-groups-modal';

// profile
export const PROFILE_OVERVIEW = 'overview';
export const PROFILE_OVERVIEW_MODAL = 'profile-overview-modal';
export const PROFILE_PAYMENT_AGREEMENTS = 'payment-agreements';
export const PROFILE_EDIT_RECURRING_PAYMENT = 'edit-recurring-payment';
export const PROFILE_SELECT_CARD = 'select-card';
export const PROFILE_EDIT_PAYMENT_CARDS = 'edit-payment-cards';
export const PROFILE_ADD_PAYMENT_CARD = 'add-payment-card';
export const PROFILE_EMAIL = 'email';
export const PROFILE_TELEPHONE = 'telephone';
export const PROFILE_EMAIL_MODAL = 'email-modal';
export const PROFILE_TELEPHONE_MODAL = 'telephone-modal';
export const PROFILE_VERIFY_PHONE_NUMBER_MODAL = 'profile-verify-phonenumber-modal';
export const PROFILE_VERIFY_EMAIL_MODAL = 'profile-verify-email-modal';

export const PROFILE_ADDRESS = 'address';
export const PROFILE_EDIT_PAYMENT_AMOUNT = 'edit-payment-amount';
export const PROFILE_EDIT_PAYMENT_AMOUNT_RECEIPT = 'edit-payment-amount-receipt';
export const PROFILE_EXTERNAL_DATA = 'external-data';
export const PROFILE_EXTERNAL_DATA_OVERVIEW = 'external-data-overview';
export const PROFILE_INFORMATION_ABOUT_EXTERNAL_DATA_DELETION =
  'information-about-external-data-deletion';

// recommendation
export const RECOMMENDATION_OTHER_AMOUNT = 'other-amount';
export const RECOMMENDATION_CREATE_PAYMENT_AGREEMENT = 'create-payment-agreement';
export const RECOMMENDATION_SUBSCRIPTION_WINDOW = 'recommendation-subscription-window';
export const RECOMMENDATION_RECEIPT = 'recommendation-receipt';

// share
export const SHARE_WELCOME = 'share-welcome';
export const SHARE_NAME = 'share-name';
export const SHARE_CODE = 'share-code';
export const SHARE_SEND = 'share-send';
export const SHARE_BIRTHDAY = 'share-birthday';
export const SHARE_MESSAGE = 'share-message';
export const SHARE_SUCCESS = 'share-success';
export const SHARE_ACCEPT = 'share-accept';

// Transfer
export const TRANSFER = 'transfer';
// Deeplink access via classic
export const START_TRANSFER_FLOW = 'start-transfer-flow';
export const TRANSFER_PROCESS_AWARENESS = 'process-awareness';
export const TRANSFER_TARGETS = 'targets';
export const TRANSFER_SOURCES = 'sources';
export const TRANSFER_QUESTIONS = 'questions';
export const TRANSFER_CONDITIONS = 'conditions';
export const TRANSFER_CONFIRM = 'confirm';
export const TRANSFER_CONTACT = 'contact';
export const TRANSFER_CMS_PAGE = 'cms-page';
export const TRANSFER_RECEIPT = 'receipt';
export const TRANSFER_FAILURE = 'failure';

// Savings
export const SAVINGS_TRANSFER = 'transfer';
export const SAVINGS_MODALS = 'savings-modals';
export const SAVINGS_DETAILS = 'details';
export const SAVINGS_OVERVIEW = 'overview';
export const SAVINGS_ABOUT_COSTS = 'about-costs';
export const SAVINGS_STATEMENTS = 'statements';
export const SAVINGS_RETURNS = 'returns';
export const SAVINGS_COSTS = 'costs';
export const SAVINGS_PAYMENTS = 'payments';

// change-investment-profile
export const CHANGE_INVESTMENT_PROFILE = 'change-investment-profile';
export const CHANGE_INVESTMENT_PROFILE_RECEIPT = 'receipt';
export const CHANGE_INVESTMENT_PROFILE_START_FLOW = 'start-change-investment-profile-flow';
export const CHANGE_INVESTMENT_PROFILE_CHOOSE_POLICY = 'choose-policy';
export const CHANGE_INVESTMENT_PROFILE_CONTACT_US = 'contact-us';
export const CHANGE_INVESTMENT_PROFILE_RECEIPT_OVERVIEW = 'receipt-overview';
export const CHANGE_INVESTMENT_PROFILE_WARNING = 'profile-warning';
export const CHANGE_INVESTMENT_PROFILE_QUESTIONS_INTRO = 'questions-intro';
export const CHANGE_INVESTMENT_PROFILE_QUESTIONS = 'questions';
export const CHANGE_INVESTMENT_PROFILE_RECOMMENDATION = 'recommendation';
export const CHANGE_INVESTMENT_PROFILE_RECOMMENDATION_WARNING = 'recommendation-warning';
export const CHANGE_INVESTMENT_PROFILE_CONSENTS = 'consents';
export const CHANGE_INVESTMENT_PROFILE_CONTACT = 'contact';
export const CHANGE_INVESTMENT_PROFILE_SIGNING = 'signing';
export const CHANGE_INVESTMENT_PROFILE_SIGNING_SUCCESS = 'signing-success';

// development
export const FEATURES = 'features';

// documents
export const DOCUMENTS = 'documents';
export const DOCUMENT_BROWSER = 'document-browser';
export const DOCUMENT_FILES = 'document-files';
export const DOCUMENT_VIEW = 'document-viewer';

// reservations
export const RESERVATIONS = 'reservations';

// misc
export const LOADING = 'loading';
export const POPUP_LOADING = 'popup-loading';
export const INJECT_TOKEN = 'inject-token';
export const CUSTOMER_BLOCK = 'customer-block';
export const CLASSIC = 'classic';
export const CONTACT_INFO_UPDATE = 'contact-info-update';

// deposits
export const DEPOSITS = 'deposits';
export const DEPOSITS_STATUS = 'status';
export const DEPOSITS_SAVINGS = 'savings-overview';
export const DEPOSITS_PLACEMENT = 'placement';
export const DEPOSITS_AMOUNT = 'amount';
export const DEPOSITS_QUESTIONS = 'questions';
export const DEPOSITS_PAYMENT_SOURCES = 'payment-sources';
export const DEPOSITS_PAYMENT_NEXI = 'payment-nexi';
export const DEPOSITS_PAYMENT_RECEIPT = 'receipt';
export const DEPOSITS_PAYMENT_TERMS = 'terms';
export const DEPOSITS_PAYMENT_COMPLETED = 'payment-completed';
export const DEPOSITS_ADD_CONTACT_INFO = 'add-contact-info';
export const DEPOSITS_PAYMENT_NEXI_REDIRECT = 'payment-nexi-redirect';
export const DEPOSITS_PENSIONS_INFORMATION = 'get-pensions-information';
export const SIGNING = 'signing';
