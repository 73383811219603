import React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import configureStore from 'store';

const store = configureStore();

const withStore = (WrappedComponent) => {
  const WithStore = (props) => (
    <ReactReduxContext.Consumer>
      {(context) => {
        if (context && context.store) {
          return <WrappedComponent {...props} />;
        }

        return (
          <Provider store={store}>
            <WrappedComponent {...props} />
          </Provider>
        );
      }}
    </ReactReduxContext.Consumer>
  );

  return WithStore;
};

export default withStore;
