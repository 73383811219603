import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from 'decorators/i18n';
import { TwoLineRow } from '@sp/ui/base/rows';
import { getInternalPolicyTextProps } from './helpers';
import { getSavingsAccessibility } from '../../helpers/getSavingsAccessibility';

const InternalPolicy = ({ item, i18n, showDetails, savingsData }) => {
  const accessibility = getSavingsAccessibility({
    components: ['InternalPolicy'],
    props: {
      policyName: item.title,
      policyId: item.policyId,
    },
  });

  return (
    <TwoLineRow
      arrow
      {...accessibility.InternalPolicy}
      onPress={() => item.policyId && showDetails(item.policyId)}
      {...getInternalPolicyTextProps(item, savingsData, i18n)}
    />
  );
};

InternalPolicy.propTypes = {
  i18n: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  showDetails: PropTypes.func,
  savingsData: PropTypes.object,
};

InternalPolicy.defaultProps = {
  showDetails: undefined,
  savingsData: undefined,
};

export default withTranslation()(InternalPolicy);
