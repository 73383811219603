import {
  TRANSFER,
  TRANSFER_FAILURE,
  TRANSFER_PROCESS_AWARENESS,
  TRANSFER_TARGETS,
  TRANSFER_SOURCES,
} from 'constants/routeNames';
import { getTransferDetails } from './getTransferDetails';
import { START_FLOW, TECHNICAL_ERROR } from 'areas/transfer/trackingIds';
import type { TransferState } from 'reducers/transfer';
import type { NavigationParams } from 'typings/global';
import { isWeb } from 'helpers/platform';

const { default: Tracking } = require('services/tracking');

type NavigationToTransferParams = {
  transferData: TransferState['data'];
  navigation: NavigationParams<{
    [TRANSFER_FAILURE]: { type: 'technicalError' };
    [TRANSFER]: {};
    [TRANSFER_SOURCES]: { firstScreen: 'true' };
    [TRANSFER_TARGETS]: { firstScreen: 'true' };
  }>;
  createTransferForm: () => void;
  isProcessAwarenessInformationRead?: boolean;
};

export const navigateToTransfer = ({
  transferData,
  navigation,
  createTransferForm,
  isProcessAwarenessInformationRead,
}: NavigationToTransferParams) => {
  const { processAwarenessInformation, hasOneTransferableTarget, hasTechnicalTransferError } =
    getTransferDetails(transferData);

  Tracking.trackEvent(START_FLOW);

  switch (true) {
    case hasTechnicalTransferError: {
      Tracking.trackEvent(TECHNICAL_ERROR);

      navigation.navigate(TRANSFER, {
        screen: TRANSFER_FAILURE,
        params: { type: 'technicalError' },
      });

      break;
    }
    case !isWeb && processAwarenessInformation && !isProcessAwarenessInformationRead: {
      navigation.navigate(TRANSFER, {
        screen: TRANSFER_PROCESS_AWARENESS,
      });
      break;
    }
    case hasOneTransferableTarget: {
      createTransferForm();
      navigation.navigate(TRANSFER, {
        screen: TRANSFER_SOURCES,
        params: { firstScreen: 'true' },
      });
      break;
    }
    default: {
      navigation.navigate(TRANSFER, {
        screen: TRANSFER_TARGETS,
        params: { firstScreen: 'true' },
      });
      break;
    }
  }
};
