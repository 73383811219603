export const ASSET_TYPE_OWNER_HOUSE = 'Hus';
export const ASSET_TYPE_OWNER_APPARTMENT = 'Ejerlejlighed';
export const ASSET_TYPE_HOUSING_COOPERATIVE = 'Andelsbolig';
export const ASSET_TYPE_SUMMERHOUSE = 'Sommerhus';
export const ASSET_TYPE_SAVINGS = 'Kontantopsparing';
export const ASSET_TYPE_STOCKS = 'Aktier';
export const ASSET_TYPE_SAVING_SAVINGS_ACOUNT = 'Aktiesparekonto';
export const ASSET_TYPE_BONDS = 'Obligationer';

export const ASSET_CATEGORY_LOANS = 'loans';
export const ASSET_CATEGORY_HOUSINGS = 'housings';
export const ASSET_CATEGORY_SAVINGS = 'savings';
export const ASSET_CATEGORY_INVESTMENTS = 'investments';
