import React, { useEffect } from 'react';
import styled from 'styled-components/native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedReaction,
  interpolate,
  withSpring,
} from 'react-native-reanimated';
import { DEFAULT_SPRING_CONFIG } from '../constants';

const { default: CurrentStepIndicator } = require('@sp/ui/v2/base/CurrentStepIndicator');
const { default: Margin } = require('@sp/ui/base/Margin');

const ANIMATION_CLOCK_TIMER = 20;

interface WizardProps {
  testIdStepIndicator?: string;
  isStepIndicatorEnabled?: boolean;
  currentIndex: number;
  stepCallback: Function;
  availableSteps?: number[];
  content?: any[];
  getIsStepClickable?: (stepIndex: number) => boolean | undefined;
}

const WizardItem = styled.View<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

const Wizard: React.FC<WizardProps> = ({
  testIdStepIndicator,
  isStepIndicatorEnabled,
  currentIndex,
  stepCallback,
  availableSteps,
  content,
  getIsStepClickable,
}) => {
  const activeIndex = useSharedValue(0);
  const animationClock = useSharedValue(0);
  const isGoingBack = useSharedValue(false);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animationClock.value, [0, 20], [0, 1]),
    transform: [
      {
        translateX: interpolate(
          animationClock.value,
          [0, 20],
          isGoingBack.value ? [-20, 0] : [20, 0]
        ),
      },
    ],
  }));

  useEffect(() => {
    if (animationClock.value !== ANIMATION_CLOCK_TIMER) {
      animationClock.value = withSpring(ANIMATION_CLOCK_TIMER, DEFAULT_SPRING_CONFIG);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useAnimatedReaction(
    () => currentIndex,
    (res) => {
      if (res !== activeIndex.value) {
        isGoingBack.value = activeIndex.value > currentIndex;
        activeIndex.value = currentIndex;
        animationClock.value = 0;
        animationClock.value = withSpring(ANIMATION_CLOCK_TIMER, DEFAULT_SPRING_CONFIG);
      }
    },
    [currentIndex]
  );

  return (
    <>
      <Animated.View style={animatedStyle}>
        {content?.map((item, i) => (
          <WizardItem key={`${item}_${i}`} isVisible={i === currentIndex}>
            {item}
          </WizardItem>
        ))}
      </Animated.View>
      {isStepIndicatorEnabled && availableSteps && availableSteps.length > 1 && (
        <Margin marginTop="md">
          <CurrentStepIndicator
            testID={testIdStepIndicator}
            ids={availableSteps}
            current={currentIndex}
            onPress={(index: number) => {
              if (getIsStepClickable && getIsStepClickable(index)) {
                stepCallback(index);
              }
            }}
          />
        </Margin>
      )}
    </>
  );
};

export default Wizard;
