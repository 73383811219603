import React from 'react';
import Menu from 'containers/navigation/Menu';
import Tracking from 'services/tracking';
import features from 'features';
import * as routeNames from 'constants/routeNames';
import i18n from 'services/i18n';
import { SAVINGS_AREA } from 'areas/savings/trackingIds';
import { DASHBOARD_AREA } from 'areas/dashboard/trackingIds';
import { MAILBOX_AREA } from 'areas/mailbox/trackingIds';
import { BOOKING_AREA } from 'areas/booking/trackingIds';
import { DOCUMENTS_AREA } from 'areas/documents/trackingIds';
import { MORE_AREA } from 'areas/more/trackingIds';
import adjustCalculationRoutes from 'areas/dashboard/AdjustCalculation/routes';
import payoutsChildrenRoutes from 'areas/dashboard/PayoutsChildren/routes';
import payoutsOriginRoutes from 'areas/dashboard/PayoutsOrigin/routes';

import { getMenuPath } from 'helpers/navigation';

const includesOrEndsWith = (path, str) => path?.includes(`/${str}/`) || path?.endsWith(`/${str}`);

const menuItemsFactory = (navigation) => {
  // NOTE: this logic currently relies on the path structure built by
  // onStateChange callback of NavigationContainer
  // we should try to rewrite it by setting the active route in navigationOptions of the screen
  const menuPath = getMenuPath();

  const isRouteActive = (routeName, modalRoutes, ignoredRoutes) => {
    if (ignoredRoutes?.some((ignoredRoute) => includesOrEndsWith(menuPath, ignoredRoute))) {
      return false;
    }

    if (includesOrEndsWith(menuPath, routeName)) {
      return true;
    }

    if (modalRoutes?.some((modalRoute) => includesOrEndsWith(menuPath, modalRoute))) {
      return true;
    }

    return false;
  };

  const menuItems = [
    {
      focused: isRouteActive(routeNames.DASHBOARD, [
        Object.keys(adjustCalculationRoutes),
        ...Object.keys(payoutsChildrenRoutes),
        ...Object.keys(payoutsOriginRoutes),
      ]),
      name: i18n.t('dashboardFeature'),
      icon: 'graph',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Menu',
            action: 'Selected',
          },
          { section: DASHBOARD_AREA }
        );
        navigation.navigate(routeNames.TABS, { screen: routeNames.DASHBOARD });
      },
    },
    {
      focused: isRouteActive(routeNames.SAVINGS),
      name: i18n.t('savingsFeature'),
      icon: 'savings',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Menu',
            action: 'Selected',
          },
          { section: SAVINGS_AREA }
        );
        navigation.navigate(routeNames.TABS, { screen: routeNames.SAVINGS });
      },
    },
    {
      focused: isRouteActive(routeNames.MAILBOX, [routeNames.MAIL_CREATE]),
      name: i18n.t('mailboxFeature'),
      icon: 'mail',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Menu',
            action: 'Selected',
          },
          { section: MAILBOX_AREA }
        );
        navigation.navigate(routeNames.TABS, { screen: routeNames.MAILBOX });
      },
    },
    {
      featureName: 'booking',
      focused: isRouteActive(routeNames.BOOKING),
      name: i18n.t('bookingFeature'),
      icon: 'calendar',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Menu',
            action: 'Selected',
          },
          { section: BOOKING_AREA }
        );
        navigation.navigate(routeNames.MAIN, { screen: routeNames.BOOKING });
      },
    },
    {
      focused: isRouteActive(routeNames.DOCUMENTS, [routeNames.DOCUMENT_VIEW]),
      name: i18n.t('documentsFeature'),
      icon: 'documents',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Menu',
            action: 'Selected',
          },
          { section: DOCUMENTS_AREA }
        );
        navigation.navigate(routeNames.MORE, { screen: routeNames.DOCUMENTS });
      },
    },
    {
      featureName: 'more',
      focused: isRouteActive(
        routeNames.MORE,
        [routeNames.MODAL_CMS_STACK, routeNames.CHANGE_INVESTMENT_PROFILE, routeNames.DEPOSITS],
        [routeNames.DOCUMENTS]
      ),
      name: i18n.t('moreTitle'),
      icon: 'menu',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Menu',
            action: 'Selected',
          },
          { section: MORE_AREA }
        );
        navigation.navigate(routeNames.MORE, { screen: routeNames.MORE_OVERVIEW });
      },
    },
  ];

  return menuItems.filter((item) => features.isEnabled(item.featureName));
};

export default <Menu menuItemsFactory={menuItemsFactory} />;
