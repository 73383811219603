import React from 'react';
import PropTypes from 'prop-types';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import { H5 } from '@sp/ui/typography';
import { ListWithSeparator } from '@sp/ui/base/lists';
import Margin from '@sp/ui/base/Margin';

const OtherAddress = ({ address, title }) => (
  <ListWithSeparator separator={RowBorder} disableTop>
    <Margin vertical="md" marginLeft="md">
      <H5 fontWeight="bold">{title}</H5>
    </Margin>
    {address.filter(Boolean).map((part) => (
      <OneLineRow textLeft={part} />
    ))}
  </ListWithSeparator>
);

OtherAddress.propTypes = {
  address: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default OtherAddress;
