export const getValue = (value, defaultValue, isDirty) => {
  // NOTE: toString() is needed for integeres to render in native version
  if (!isDirty && !value) return defaultValue && defaultValue.toString();
  if (typeof value !== 'undefined') return value && value.toString();

  return '';
};

export const getObjectValue = (value, defaultValue, isDirty) => {
  if (!isDirty) return defaultValue;
  if (typeof value !== 'undefined') return value;

  return {};
};
