import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import useI18n from 'hooks/useI18n';
import { ADJUST_CALCULATION_GO_BACK } from 'constants/testIds/dashboard/adjustCalculation';
import BottomSheetPage from 'containers/v2/pages/BottomSheetPage';
import PageContentWrapper from './PageContentWrapper';

const { default: Margin } = require('@sp/ui/base/Margin');
const { BackButton } = require('@sp/ui/v2/pages/BasePage');
const { ModalProvider } = require('@sp/ui/context/ModalContext');
const { H3 } = require('@sp/ui/v2/typography');

type ActionType = 'primary' | 'secondary' | 'destructive' | 'destructive-secondary';

export type Action = {
  text: string;
  type?: ActionType;
  mobilePosition: string;
  onPress: () => void;
  inactive?: boolean;
  testID?: string;
};

type Props = {
  children: React.ReactNode;
  smoothAppearance?: {
    waitForContent: boolean;
    emptyContentHeight?: number;
  };
  hasAppearAnimation?: boolean;
  hasBackButton?: boolean;
  testID?: string;
  heading?: string;
  actions?: Action[];
  title?: string;
  actionsInfo?: string;
  footerWarning?: React.ReactNode;
  backButton?: React.ReactNode;
  actionsContent?: React.ReactNode;
  hasBottomSheet?: boolean;
  keyboardAdjustResize?: boolean;
  initializeBottomSheetAsClosed?: boolean;
  isBottomSheetInactive?: boolean;
  shouldCloseBottomSheetIfOpen?: boolean;
  shouldOpenBottomSheetIfClosed?: boolean;
  contentResizeHeightOnFocusedInputField?: number;
  takeUpAllAvailableSpaceWhenKeyboardIsVisible?: boolean;
  ignoreDefaultVerticalPadding?: boolean;
};

const Page: React.FC<Props> = ({
  testID,
  title,
  heading,
  children,
  actions,
  footerWarning,
  hasBackButton,
  smoothAppearance,
  backButton,
  actionsContent,
  actionsInfo,
  hasAppearAnimation,
  hasBottomSheet,
  isBottomSheetInactive,
  initializeBottomSheetAsClosed,
  keyboardAdjustResize,
  shouldCloseBottomSheetIfOpen,
  shouldOpenBottomSheetIfClosed,
  contentResizeHeightOnFocusedInputField,
  takeUpAllAvailableSpaceWhenKeyboardIsVisible,
  ignoreDefaultVerticalPadding,
}) => {
  const i18n = useI18n(['dashboard']);
  const navigation = useNavigation();
  const BackButtonComponent = useMemo(
    () =>
      backButton ?? (
        <BackButton
          testID={ADJUST_CALCULATION_GO_BACK}
          label={i18n.t('back')}
          onPress={() => navigation.goBack()}
        />
      ),
    [backButton, navigation, i18n]
  );

  return (
    <ModalProvider isModal>
      <BottomSheetPage
        testID={testID}
        smoothAppearance={smoothAppearance}
        hasBackButton={hasBackButton}
        hasBottomSheet={hasBottomSheet}
        keyboardAdjustResize={keyboardAdjustResize}
        isBottomSheetInactive={isBottomSheetInactive}
        takeUpAllAvailableSpaceWhenKeyboardIsVisible={takeUpAllAvailableSpaceWhenKeyboardIsVisible}
        initializeBottomSheetAsClosed={initializeBottomSheetAsClosed}
        shouldCloseBottomSheetIfOpen={shouldCloseBottomSheetIfOpen}
        shouldOpenBottomSheetIfClosed={shouldOpenBottomSheetIfClosed}
        contentResizeHeightOnFocusedInputField={contentResizeHeightOnFocusedInputField}
        title={title}
        footerWarning={footerWarning}
        actionsInfo={actionsInfo}
        actionsContent={actionsContent}
        actions={actions}
        backButton={BackButtonComponent}
      >
        <PageContentWrapper ignoreDefaultVerticalPadding={ignoreDefaultVerticalPadding}>
          {!hasAppearAnimation && (
            <Margin marginBottom="md">
              <H3 textAlign="center">{heading}</H3>
            </Margin>
          )}
          {children}
        </PageContentWrapper>
      </BottomSheetPage>
    </ModalProvider>
  );
};

Page.defaultProps = {
  hasBottomSheet: true,
  hasBackButton: true,
  initializeBottomSheetAsClosed: false,
  shouldOpenBottomSheetIfClosed: false,
  shouldCloseBottomSheetIfOpen: false,
  hasAppearAnimation: false,
  takeUpAllAvailableSpaceWhenKeyboardIsVisible: false,
  ignoreDefaultVerticalPadding: false,
  smoothAppearance: undefined,
  actions: undefined,
  testID: undefined,
  title: undefined,
  actionsInfo: undefined,
  footerWarning: undefined,
  backButton: undefined,
  actionsContent: undefined,
  keyboardAdjustResize: undefined,
  isBottomSheetInactive: undefined,
  heading: undefined,
  contentResizeHeightOnFocusedInputField: undefined,
};

export default Page;
