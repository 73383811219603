import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

const PADDING_TOP = 40;
const PADDING_BOTTOM = 40;

const Wrapper = styled.View`
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  background: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  overflow: ${(props) => (props.enableOverflow ? 'visible' : 'hidden')};
  padding: ${(props) =>
    `${props.disableTopPadding ? 0 : PADDING_TOP}px 0 ${
      props.disableBottomPadding ? 0 : PADDING_BOTTOM
    }px 0;`};
`;

const Card = ({ children, disableTopPadding, disableBottomPadding, enableOverflow }) => (
  <Wrapper
    disableTopPadding={disableTopPadding}
    disableBottomPadding={disableBottomPadding}
    enableOverflow={enableOverflow}
  >
    {children}
  </Wrapper>
);

Card.propTypes = {
  disableTopPadding: PropTypes.bool,
  disableBottomPadding: PropTypes.bool,
  enableOverflow: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  disableTopPadding: false,
  disableBottomPadding: false,
  enableOverflow: false,
};

export default Card;
