import { selectSelectedLocation, selectAppointmentsByOrganizer } from './index';

// Gets a unique list of appointment dates from the users booking options.
const selectAvailableAppointmentDates = (state) => {
  let appointments = selectAppointmentsByOrganizer(state);

  const selectedLocation = selectSelectedLocation(state);

  if (selectedLocation) {
    appointments = appointments.filter((a) => a.location === selectedLocation);
  }

  const availableAppointmentDates = appointments.map((appointment) => appointment.startsAt);

  const uniqueAvailableDates = availableAppointmentDates.reduce((availableDates, currentDate) => {
    const currentDateSlice = currentDate.slice(0, 10);
    const hasDate = availableDates.find((date) => date.startsWith(currentDateSlice));
    if (!hasDate) {
      availableDates.push(currentDate);
    }
    return availableDates;
  }, []);

  // Formatted as date objects
  return uniqueAvailableDates.map((isoDate) => new Date(isoDate));
};

export default selectAvailableAppointmentDates;
