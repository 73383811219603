import { POPUP_WEBVIEW_OPEN, POPUP_WEBVIEW_CLOSE } from 'constants/actionTypes';
import { create } from 'helpers/reducers';

const createDefaults = () => ({
  url: undefined,
});

export default create(
  {
    [POPUP_WEBVIEW_OPEN]: (state, payload) => ({
      ...state,
      url: payload,
    }),
    [POPUP_WEBVIEW_CLOSE]: (state) => ({
      ...state,
      url: undefined,
    }),
  },
  createDefaults
);
