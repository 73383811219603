// Deeplink
export const UPDATE_DEEPLINK = 'UPDATE_DEEPLINK';

// Partner
export const CREATE_PARTNER_LINK = 'CREATE_PARTNER_LINK';
export const CONNECT_PARTNER = 'CONNECT_PARTNER';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const RESPONSE_DELETE_PARTNER = 'RESPONSE_DELETE_PARTNER';
export const GET_PARTNER = 'GET_PARTNER';
export const RESPONSE_GET_PARTNER = 'RESPONSE_GET_PARTNER';
export const GET_PARTNER_DATA_FROM_MEETINGPREPARATION = 'GET_PARTNER_DATA_FROM_MEETINGPREPARATION';
export const RESPONSE_GET_PARTNER_DATA_FROM_MEETINGPREPARATION =
  'RESPONSE_GET_PARTNER_DATA_FROM_MEETINGPREPARATION';
export const UPDATE_PARTNER_CONNECTION_FAILED = 'UPDATE_PARTNER_CONNECTION_FAILED';
export const RESPONSE_CREATE_PARTNER_LINK = 'RESPONSE_CREATE_PARTNER_LINK';
export const RESEND_PARTNER_LINK = 'RESEND_PARTNER_LINK';

// Mødeforberedelse
export const GET_ASSET = 'GET_ASSET';
export const GET_ASSETS = 'GET_ASSETS';
export const RESPONSE_GET_ASSETS = 'RESPONSE_GET_ASSETS';
export const GET_ASSET_TYPES = 'GET_ASSETS_TYPES'; // remove when goiing live
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const ADD_ASSET = 'ADD_ASSET';
export const RESPONSE_ADD_ASSET = 'RESPONSE_ADD_ASSET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_ASSETS = 'DELETE_ASSETS';
export const RESPONSE_DELETE_ASSETS = 'RESPONSE_DELETE_ASSETS';
export const MEETING_PREPARATION_IS_DONE_EVENT = 'MEETING_PREPARATION_IS_DONE_EVENT';
export const PARTNER_FLOW_IS_DONE_EVENT = 'PARTNER_FLOW_IS_DONE_EVENT';

// Consents
export const CONSENTS = 'CONSENTS';
export const CONSENT = 'CONSENT';
export const GIVE_CONSENT = 'GIVE_CONSENT';
export const RETRACT_CONSENT = 'RETRACT_CONSENT';

// Flow control
export const UPDATE_CURRENT_PROGRESS = 'UPDATE_CURRENT_PROGRESS';
export const UPDATE_FLOW_CONTROL = 'UPDATE_FLOW_CONTROL';
export const SET_FLOW = 'SET_FLOW';

// Pensions info
export const GET_PENSIONS_INFO = 'GET_PENSIONS_INFO';
export const RESPONSE_GET_PENSIONS_INFO = 'RESPONSE_GET_PENSIONS_INFO';
export const FAILED_GET_PENSIONS_INFO = 'FAILED_GET_PENSIONS_INFO';

// Discovery
export const GET_DISCOVERY = 'GET_DISCOVERY';

// Used to check what is available to the user after login
export const GET_API_STATUS = 'GET_API_STATUS';
export const INJECT_ACCESS_TOKEN = 'INJECT_ACCESS_TOKEN';
export const RESPONSE_GET_API_STATUS = 'RESPONSE_GET_API_STATUS';
export const GET_SSO_TOKEN = 'GET_SSO_TOKEN';

// Dashboard
export const REFETCH_NAKED_DASHBOARD_ERRORS = 'REFETCH_NAKED_DASHBOARD_ERRORS';
export const IGNORE_NAKED_DASHBOARD_ERROR = 'IGNORE_NAKED_DASHBOARD_ERROR';
export const RESPONSE_RETIREMENT_OVERVIEW = 'RESPONSE_RETIREMENT_OVERVIEW';

// Calculations
export const GET_CALCULATION_PROFILE = 'GET_CALCULATION_PROFILE';
export const RESPONSE_GET_CALCULATION_PROFILE = 'RESPONSE_GET_CALCULATION_PROFILE';
export const PUT_CALCULATION_PROFILE = 'PUT_CALCULATION_PROFILE';
export const RESPONSE_PUT_CALCULATION_PROFILE = 'RESPONSE_PUT_CALCULATION_PROFILE';

// Fetch tax and pensionsInfo
export const SET_HAS_FETCHED_PENSIONSINFO = 'SET_HAS_FETCHED_PENSIONSINFO';
export const SET_HAS_FETCHED_TAX = 'SET_HAS_FETCHED_TAX';
export const SET_RESET_FETCHING_STATUSES = 'SET_RESET_FETCHING_STATUSES';
export const SET_SKIP_TAX_PENSIONSINFO = 'SET_SKIP_TAX_PENSIONSINFO';
export const PENSION_CREATE_SESSION = 'PENSION_CREATE_SESSION';
export const SKAT_CREATE_SESSION = 'SKAT_CREATE_SESSION';
export const AWAIT_PENSIONS_INFO_STATUS = 'AWAIT_PENSIONS_INFO_STATUS';
export const AWAIT_SKAT_STATUS = 'AWAIT_SKAT_STATUS';
export const FETCH_TAX_AND_PENSIONSINFO = 'FETCH_TAX_AND_PENSIONSINFO';
export const FETCH_TAX_PENSIONSINFO = 'FETCH_TAX_PENSIONSINFO';
export const UPDATE_FETCH_TAX_PENSIONSINFO = 'UPDATE_FETCH_TAX_PENSIONSINFO';
export const UPDATE_FETCH_FAIL_TAX_PENSIONSINFO = 'UPDATE_FETCH_FAIL_TAX_PENSIONSINFO';
export const DELETE_FETCH_TAX_PENSIONSINFO_STORE = 'DELETE_FETCH_TAX_PENSIONSINFO_STORE';

// Children
export const GET_CHILDREN = 'GET_CHILDREN';
export const ADD_CHILD = 'ADD_CHILD';
export const UPDATE_CHILD = 'UPDATE_CHILD';
export const DELETE_CHILD = 'DELETE_CHILD';
export const RESPONSE_GET_CHILDREN = 'RESPONSE_GET_CHILDREN';
export const UPDATE_LINKABLE_CHILD_TO_NOT_LINKABLE = 'UPDATE_LINKABLE_CHILD_TO_NOT_LINKABLE';
export const LINK_CHILDREN = 'LINK_CHILDREN';

// Linkable children
export const UPDATE_LINK_CHILDREN_FLOW = 'UPDATE_LINK_CHILDREN_FLOW';
export const REMOVE_LINK_CHILDREN_FLOW = 'REMOVE_LINK_CHILDREN_FLOW';
export const CHECK_FOR_LINKABLE_CHILDREN = 'CHECK_FOR_LINKABLE_CHILDREN';

// Onboarding
export const START_APP = 'START_APP';
export const START_POPUP_APP = 'START_POPUP_APP';
export const DO_LOGIN_FLOW = 'DO_LOGIN_FLOW';
export const DO_CONSENTS_AND_API_STATUS_FLOW = 'DO_CONSENTS_AND_API_STATUS_FLOW';
export const DO_CUSTOMER_FLOW = 'DO_CUSTOMER_FLOW';
export const DO_INFO_FLOW = 'DO_INFO_FLOW';
export const SKIP_INCOME_FLOW = 'SKIP_INCOME_FLOW';
export const SKIP_PENSION_FLOW = 'SKIP_PENSION_FLOW';
export const COMPLETE_INFO_FLOW = 'COMPLETE_INFO_FLOW';
export const GET_APP_CHANGELOG = 'GET_APP_CHANGELOG';
export const UPDATE_APP_CHANGELOG = 'UPDATE_APP_CHANGELOG';

// DATA_PREFETCHING
export const PREFETCH_INITIAL_DATA = 'PREFETCH_INITIAL_DATA';

// DATA REFETCHING
export const REFETCH_DATA_AFTER_PI_SKAT = 'REFETCH_DATA_AFTER_PI_SKAT';

export const GET_PROFILE = 'GET_PROFILE';

export const RESPONSE_PROFILE_COMPLETENESS = 'RESPONSE_PROFILE_COMPLETENESS';
export const TAX_STATEMENT_STATUS = 'TAX_STATEMENT_STATUS';
export const RESPONSE_STATEMENT_STATUS = 'RESPONSE_STATEMENT_STATUS';
export const RESPONSE_GET_PROFILE = 'RESPONSE_GET_PROFILE';

export const GET_USER_INFO = 'GET_USER_INFO';
export const RESPONSE_GET_USER_INFO = 'RESPONSE_GET_USER_INFO';

export const GET_CONTACT_INFO = 'GET_CONTACT_INFO';
export const RESPONSE_GET_CONTACT_INFO = 'RESPONSE_GET_CONTACT_INFO';

export const SAVE_PROFILE_EMAIL = 'SAVE_PROFILE_EMAIL';
export const SAVE_PROFILE_PHONE = 'SAVE_PROFILE_PHONE';
export const SAVE_FAMILY_SITUATION = 'SAVE_FAMILY_SITUATION';
export const RESPONSE_SAVE_FAMILY_SITUATION = 'RESPONSE_SAVE_FAMILY_SITUATION';

export const GET_PENDING_VERIFICATIONS = 'GET_PENDING_VERIFICATIONS';
export const RESPONSE_GET_PENDING_VERIFICATIONS = 'RESPONSE_GET_PENDING_VERIFICATIONS';
export const SET_HAS_SEEN_UNVERIFIED_CONTACT_INFO_NOTIFICATION =
  'SET_HAS_SEEN_UNVERIFIED_CONTACT_INFO_NOTIFICATION';
export const DELETE_PENDING_VERIFICATIONS = 'DELETE_PENDING_VERIFICATIONS';
export const VERIFY_CONTACT_INFORMATION = 'VERIFY_CONTACT_INFORMATION';
export const VALIDATE_CONTACT_INFORMATION_TOKEN = 'VALIDATE_CONTACT_INFORMATION_TOKEN';

// Calculations
export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const RESPONSE_UPDATE_PROFILE_INFO = 'RESPONSE_UPDATE_PROFILE_INFO';
export const CREATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const UPDATE_INCOME = 'UPDATE_INCOME';
export const UPDATE_INCOME_RESPONSE = 'RESPONSE_UPDATE_INCOME';
export const UPDATE_SHOW_GROSS_INCOME = 'UPDATE_SHOW_GROSS_INCOME';
export const GET_INCOME = 'GET_INCOME';
export const GET_INCOME_RESPONSE = 'RESPONSE_GET_INCOME';
// Award rules
export const GET_AWARD_RULES = 'GET_AWARD_RULES';
export const GET_AWARD_RULES_RESPONSE = 'RESPONSE_GET_AWARD_RULES';
export const GET_AWARD_RULES_REQUEST = 'REQUEST_GET_AWARD_RULES';

// Savings
export const GET_SAVINGS_OVERVIEW = 'GET_SAVINGS_OVERVIEW';
export const GET_SAVINGS_DEPOSITS = 'GET_SAVINGS_DEPOSITS';
export const CANCEL_SAVINGS_DEPOSITS = 'CANCEL_SAVINGS_DEPOSITS';
export const REQUEST_GET_SAVINGS_DEPOSITS = 'REQUEST_GET_SAVINGS_DEPOSITS';
export const UPDATE_DEPOSIT_FETCHING_STATE = 'UPDATE_DEPOSIT_FETCHING_STATE';
export const UPDATE_ADMIN_COSTS = 'UPDATE_ADMIN_COSTS';
export const RESPONSE_GET_SAVINGS_DEPOSITS = 'RESPONSE_GET_SAVINGS_DEPOSITS';
export const RESPONSE_GET_SAVINGS_OVERVIEW = 'RESPONSE_GET_SAVINGS_OVERVIEW';

// Transfer
export const GET_TRANSFER_OVERVIEW = 'GET_TRANSFER_OVERVIEW';
export const UPDATE_TRANSFER_FORM = 'UPDATE_TRANSFER_FORM';
export const POST_TRANSFER_FORM = 'POST_TRANSFER_FORM';
export const POST_TRANSFER_CONSENTS = 'POST_TRANSFER_CONSENTS';
export const CREATE_TRANSFER_FORM = 'CREATE_TRANSFER_FORM';
export const RESET_TRANSFER_FORM = 'RESET_TRANSFER_FORM';
export const UPDATE_TRANSFER_SOURCE_SELECTIONS = 'UPDATE_TRANSFER_SOURCE_SELECTIONS';
export const UPDATE_TRANSFER_QUESTION_ANSWERS = 'UPDATE_TRANSFER_QUESTION_ANSWERS';
export const UPDATE_TRANSFER_CONTACT_DETAILS = 'UPDATE_TRANSFER_CONTACT_DETAILS';
export const RESPONSE_GET_TRANSFER_OVERVIEW = 'RESPONSE_GET_TRANSFER_OVERVIEW';
export const RESPONSE_POST_TRANSFER_CONSENTS = 'RESPONSE_POST_TRANSFER_CONSENTS';

// documents
export const PUSH_PREVIOUS_FOLDER = 'PUSH_PREVIOUS_FOLDER';
export const POP_PREVIOUS_FOLDER = 'POP_PREVIOUS_FOLDER';

// Booking
export const GET_BOOKING_OPTIONS = 'GET_BOOKING_OPTIONS';
export const REQUEST_GET_BOOKING_OPTIONS = 'REQUEST_GET_BOOKING_OPTIONS';
export const POST_BOOKING_APPOINTMENT = 'POST_BOOKING_APPOINTMENT';
export const RESPONSE_POST_BOOKING_APPOINTMENT = 'RESPONSE_POST_BOOKING_APPOINTMENT';
export const RESPONSE_GET_BOOKING_OPTIONS = 'RESPONSE_GET_BOOKING_OPTIONS';
export const GET_BOOKING_APPOINTMENTS = 'GET_BOOKING_APPOINTMENTS';
export const POST_CONTACT_ME_LATER = 'POST_CONTACT_ME_LATER';

export const REQUEST_GET_BOOKING_APPOINTMENTS = 'REQUEST_GET_BOOKING_APPOINTMENTS';
export const RESPONSE_GET_BOOKING_APPOINTMENTS = 'RESPONSE_GET_BOOKING_APPOINTMENTS';
export const FAILED_POST_BOOKING_APPOINTMENT = 'FAILED_POST_BOOKING_APPOINTMENT';
export const SET_APPOINTMENT_TYPE = 'SET_APPOINTMENT_TYPE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_ORGANIZER = 'SET_SELECTED_ORGANIZER';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SELECTED_APPOINTMENT = 'SET_SELECTED_APPOINTMENT';
export const SET_SELECTED_TIME_SLOT = 'SET_SELECTED_TIME_SLOT';
export const SET_SELECTED_BOOKING_TOPIC_INFO = 'SET_SELECTED_BOOKING_TOPIC_INFO';
export const SET_BOOKING_CONTACT_INFO = 'SET_BOOKING_CONTACT_INFO';
export const DELETE_BOOKED_APPOINTMENT = 'DELETE_BOOKED_APPOINTMENT';
export const RESET_BOOKING_SELECTION = 'RESET_BOOKING_SELECTION';

// Push notifications
export const REGISTER_PUSH_DEVICE = 'REGISTER_PUSH_DEVICE';
export const UNREGISTER_PUSH_DEVICE = 'UNREGISTER_PUSH_DEVICE';
export const RECEIVED_PUSH_NOTIFICATION = 'RECEIVED_PUSH_NOTIFICATION';
export const CLEAR_PUSH_NOTIFICATION = 'CLEAR_PUSH_NOTIFICATION';

// Mailbox
export const REQUEST_MAILS = 'REQUEST_MAILS';
export const RESPONSE_MAILS = 'RESPONSE_MAILS';
export const MAILS = 'MAILS';
export const POLL_MAILS = 'POLL_MAILS';
export const RESPONSE_POLL_MAILS = 'POLL_SUCCESS_POLL_MAILS';
export const REQUEST_MAIL = 'REQUEST_MAIL';
export const RESPONSE_MAIL = 'RESPONSE_MAIL';
export const MAIL = 'MAIL';
export const POLL_MAIL = 'POLL_MAIL';
export const RESPONSE_POLL_MAIL = 'POLL_SUCCESS_POLL_MAIL';
export const CANCEL_POLL_MAILS = 'CANCEL_POLL_MAILS';
export const CANCEL_POLL_MAIL = 'CANCEL_POLL_MAIL';
export const SET_TYPE = 'SET_TYPE';
export const THREAD_READ = 'THREAD_READ';
export const RESPONSE_THREAD_READ = 'RESPONSE_THREAD_READ';
export const REQUEST_THREAD_ARCHIVE = 'REQUEST_THREAD_ARCHIVE';
export const THREAD_ARCHIVE = 'THREAD_ARCHIVE';
export const REQUEST_THREAD_INBOX = 'REQUEST_THREAD_INBOX';
export const THREAD_INBOX = 'THREAD_INBOX';
export const FETCH_MAILBOX_SUBJECTS = 'FETCH_MAILBOX_SUBJECTS';
export const CREATE_MAIL = 'CREATE_MAIL';
export const CREATE_MAILBOX_SIGNING_URL = 'CREATE_MAILBOX_SIGNING_URL';
export const COMPLETE_MAILBOX_SIGNING = 'COMPLETE_MAILBOX_SIGNING';
export const RESPONSE_FETCH_MAILBOX_SUBJECTS = 'RESPONSE_FETCH_MAILBOX_SUBJECTS';
export const TOGGLE_SORT_MAILBOX_CONVERSATIONS = 'TOGGLE_SORT_MAILBOX_CONVERSATIONS';
export const PATCH_OVERVIEW_WITH_UPDATED_MAIL = 'PATCH_OVERVIEW_WITH_UPDATED_MAIL';

// Settings
export const NAVIGATE_TO_NOTIFICATION_SETTINGS = 'NAVIGATE_TO_NOTIFICATION_SETTINGS';
export const NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS';
export const RESPONSE_NOTIFICATION_SETTINGS = 'RESPONSE_NOTIFICATION_SETTINGS';
export const NOTIFICATION_SETTINGS_COMPANY_GROUPS = 'NOTIFICATION_SETTINGS_COMPANY_GROUPS';
export const RESPONSE_NOTIFICATION_SETTINGS_COMPANY_GROUPS =
  'RESPONSE_NOTIFICATION_SETTINGS_COMPANY_GROUPS';
export const REQUEST_UPDATE_NOTIFICATION_SETTINGS = 'REQUEST_UPDATE_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';
export const CLEAR_NOTIFICATION_SETTINGS = 'CLEAR_NOTIFICATION_SETTINGS';

// Feedback
export const RESPONSE_FETCH_FEEDBACK_SUBJECTS = 'RESPONSE_FETCH_FEEDBACK_SUBJECTS';

// Consents
export const RESPONSE_CONSENT = 'RESPONSE_CONSENT';
export const TERMS_MODAL_VISIBLE = 'TERMS_MODAL_VISIBLE';

// Rule engine
export const CONTENT = 'CONTENT';

// Change investment profile
export const CHANGE_INVESTMENT_CURRENT_PROFILES = 'CHANGE_INVESTMENT_CURRENT_PROFILES';
export const CHANGE_INVESTMENT_PROFILE = 'CHANGE_INVESTMENT_PROFILE';
export const CHANGE_INVESTMENT_RECOMMENDED_PROFILE = 'CHANGE_INVESTMENT_RECOMMENDED_PROFILE';
export const CHANGE_INVESTMENT_QUESTIONS = 'CHANGE_INVESTMENT_QUESTIONS';
export const CHANGE_INVESTMENT_PENDING_CHANGES = 'CHANGE_INVESTMENT_PENDING_CHANGES';
export const CHANGE_INVESTMENT_PROFILE_CONSENTS = 'CHANGE_INVESTMENT_PROFILE_CONSENTS';
export const SET_CHANGE_INVESTMENT_PROFILE_CONTACT_INFO =
  'SET_CHANGE_INVESTMENT_PROFILE_CONTACT_INFO';
export const SET_CHANGE_INVESTMENT_PROFILE_ANSWERS = 'SET_CHANGE_INVESTMENT_PROFILE_ANSWERS';
export const SET_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES =
  'SET_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES';
export const SET_CHANGE_INVESTMENT_PROFILE_POLICIES = 'SET_CHANGE_INVESTMENT_PROFILE_POLICIES';
export const CHANGE_INVESTMENT_COMPLETE_SIGNING = 'CHANGE_INVESTMENT_COMPLETE_SIGNING';
export const CHANGE_INVESTMENT_CLEAR_PENDING_CHANGES = 'CHANGE_INVESTMENT_CLEAR_PENDING_CHANGES';
export const CLEAR_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES =
  'CLEAR_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES';
export const START_CHANGE_INVESTMENT_PROFILE_FLOW = 'START_CHANGE_INVESTMENT_PROFILE_FLOW';

// App rating
export const SET_IS_APP_RATING_OPEN = 'SET_IS_APP_RATING_OPEN';

// App lock
export const APP_LOCK = '@@SECURITY/APP_LOCK';

// Profile
export const DELETE_EXTERNAL_DATA = 'DELETE_EXTERNAL_DATA';
export const DELETE_USER_PENSIONS_INFO = 'DELETE_USER_PENSIONS_INFO';

// Deposits
export const DEPOSITS_SAVINGS = 'DEPOSITS_SAVINGS';
export const DEPOSITS_PLACEMENT = 'DEPOSITS_PLACEMENT';
export const DEPOSITS_PLACEMENT_TEXTS = 'DEPOSITS_PLACEMENT_TEXTS';
export const DEPOSITS_QUESTIONS = 'DEPOSITS_QUESTIONS';
export const DEPOSITS_PAYMENT_SOURCES = 'DEPOSITS_PAYMENT_SOURCES';
export const DEPOSITS_PAYMENT_RECEIPT = 'DEPOSITS_PAYMENT_RECEIPT';
export const DEPOSITS_PAYMENT_RECEIPTS = 'DEPOSITS_PAYMENT_RECEIPTS';
export const DEPOSITS_PAYMENT_STATUS = 'DEPOSITS_PAYMENT_STATUS';
export const DEPOSITS_TERMS = 'DEPOSITS_TERMS';
export const SET_DEPOSITS_AMOUNT = 'SET_DEPOSITS_AMOUNT';
export const SET_DEPOSITS_ANSWERS = 'SET_DEPOSITS_ANSWERS';
export const SET_DEPOSITS_TARGET = 'SET_DEPOSITS_TARGET';
export const SET_DEPOSITS_CONTACT_INFO = 'SET_DEPOSITS_CONTACT_INFO';
export const SET_DEPOSITS_IS_PLACEMENT_VALID = 'SET_DEPOSITS_IS_PLACEMENT_VALID';
export const CLEAR_DEPOSITS = 'CLEAR_DEPOSITS';
export const DEPOSITS_NEXI = 'DEPOSITS_NEXI';

// Popup web view
export const POPUP_WEBVIEW_OPEN = 'POPUP_WEBVIEW_OPEN';
export const POPUP_WEBVIEW_CLOSE = 'POPUP_WEBVIEW_CLOSE';

// Health benefits
export const NAVIGATE_TO_HEALTH_BENEFITS = 'NAVIGATE_TO_HEALTH_BENEFITS';
export const GET_HEALTH_BENEFITS = 'GET_HEALTH_BENEFITS';
export const GET_HAS_HEALTH_BENEFITS = 'GET_HAS_HEALTH_BENEFITS';
export const REQUEST_GET_HEALTH_BENEFITS = 'REQUEST_GET_HEALTH_BENEFITS';
export const RESPONSE_GET_HEALTH_BENEFITS = 'RESPONSE_GET_HEALTH_BENEFITS';
export const RESPONSE_GET_HAS_HEALTH_BENEFITS = 'RESPONSE_GET_HAS_HEALTH_BENEFITS';
