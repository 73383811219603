import React, { useMemo } from 'react';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store';
import { shallowEqual } from 'react-redux';
import {
  selectIncomeToday,
  selectTotalIncomeToday,
  selectChildAndYouthBenefitToday,
  selectShowGrossAmount,
} from 'selectors/dashboard';
import useI18n from 'hooks/useI18n';

const { formatMoney } = require('helpers/string');
const { default: Margin } = require('@sp/ui/base/Margin');
const { OneLineRow, RowBorder } = require('@sp/ui/base/rows');
const { H6, H5 } = require('@sp/ui/typography');

const selectIncomeDetails = (state: RootState) => ({
  incomeToday: selectIncomeToday(state),
  totalIncomeToday: selectTotalIncomeToday(state),
  childAndYouthBenefitToday: selectChildAndYouthBenefitToday(state),
  showGrossAmount: selectShowGrossAmount(state),
});

const IncomeTodayDetails = () => {
  const { incomeToday, totalIncomeToday, childAndYouthBenefitToday, showGrossAmount } =
    useAppSelector(selectIncomeDetails, shallowEqual);

  const { i18n } = useI18n(['dashboard']);

  const incomeTodayRows = useMemo(() => {
    const rows: {
      isBold: boolean;
      textLeft: string;
      textRight: string;
    }[] = [];

    if (!incomeToday) {
      return rows;
    }

    const hasExtraIncome = childAndYouthBenefitToday && childAndYouthBenefitToday?.amount > 0;

    if (hasExtraIncome && totalIncomeToday) {
      rows.push({
        isBold: true,
        textLeft: i18n.t('dashboard|incomeTodayTotal'),
        textRight: i18n.t('dashboard|incomePrMonth', {
          incomeToday: formatMoney(totalIncomeToday, true, showGrossAmount, false),
        }),
      });
    }

    rows.push({
      isBold: false,
      textLeft: i18n.t('dashboard|incomeToday'),
      textRight: i18n.t('dashboard|incomePrMonth', {
        incomeToday: formatMoney(incomeToday, true, showGrossAmount, false),
      }),
    });

    if (hasExtraIncome) {
      rows.push({
        isBold: false,
        textLeft: i18n.t('dashboard|childAndYouthBenefit'),
        textRight: i18n.t('dashboard|incomePrMonth', {
          incomeToday: formatMoney(childAndYouthBenefitToday, true, false, false),
        }),
      });
    }

    return rows;
  }, [childAndYouthBenefitToday, i18n, incomeToday, showGrossAmount, totalIncomeToday]);

  return (
    <>
      {incomeTodayRows.map((rowItem) => (
        <>
          <OneLineRow
            textLeft={rowItem.isBold ? <H5>{rowItem.textLeft}</H5> : rowItem.textLeft}
            textRight={
              <Margin marginRight="xs">
                <Margin marginRight="lg">
                  <H6 fontWeight={rowItem.isBold ? 'bold' : 'regular'}>{rowItem.textRight}</H6>
                </Margin>
              </Margin>
            }
          />
          <RowBorder />
        </>
      ))}
    </>
  );
};

export default IncomeTodayDetails;
