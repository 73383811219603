import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { Keyboard } from 'react-native';
import styled from 'styled-components/native';
import { initFunc } from 'helpers/props';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import CodeInput from 'containers/form/CodeInput';
import { Subtitle } from '@sp/ui/typography';
import { Form } from 'react-formed';
import Margin from '@sp/ui/base/Margin';
import { dismiss } from 'helpers/navigation';

const CODE_INPUT_LENGTH = 6;

const Wrapper = styled.View`
  align-items: center;
`;

const CodeInputWrapper = styled.View``;

const VerifyCode = ({
  title,
  heading,
  description,
  formName,
  onVerify,
  onResendCode,
  subtitle,
  hasError,
  form,
  isModal,
  navigation,
  i18n,
}) => {
  const [isNextButtonActive, setIsNextButtonActive] = useState(false);

  const onComplete = useCallback(
    (code) => {
      if (onVerify) {
        onVerify(code);
        Keyboard.dismiss();
        setIsNextButtonActive(true);
      }
    },
    [onVerify]
  );

  useEffect(() => {
    setIsNextButtonActive(!hasError);
  }, [hasError]);

  return (
    <ModalProvider isModal={isModal} onClose={dismiss(navigation)}>
      <ScrollPage
        title={title}
        heading={heading}
        description={description}
        hasBackButton={!isModal}
        actions={[
          {
            text: i18n.t('Send kode igen'),
            onPress: onResendCode,
            mobilePosition: 'bottom-sticky',
            type: 'secondary',
          },
          {
            text: i18n.t('Næste'),
            inactive: !form?.codeInput || hasError || !isNextButtonActive,
            onPress: () => onVerify(form.codeInput),
            mobilePosition: 'bottom-sticky',
          },
        ]}
      >
        <Wrapper>
          <CodeInputWrapper>
            <Form name={formName}>
              <CodeInput
                length={CODE_INPUT_LENGTH}
                name="codeInput"
                onComplete={onComplete}
                invalid={hasError}
                value={form.codeInput}
                autoFocus
              />
            </Form>
            <Margin marginTop="sm">
              <Subtitle>{subtitle}</Subtitle>
            </Margin>
          </CodeInputWrapper>
        </Wrapper>
      </ScrollPage>
    </ModalProvider>
  );
};

VerifyCode.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onVerify: initFunc.isRequired,
  onResendCode: initFunc.isRequired,
  i18n: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  form: PropTypes.object,
  navigation: PropTypes.object.isRequired,
  isModal: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
};

VerifyCode.defaultProps = {
  form: {},
  hasError: false,
};

const mapStateToProps = (state, props) => ({
  form: state.forms[props.formName],
});

export default withComponentContainer({
  mapStateToProps,
})(VerifyCode);
