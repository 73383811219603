import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/typography/index.shared';

const Link = styled(withDefaultTypographyProps)`
  color: ${(props) => props.color || props.theme.COLORS.PRIMARY};
  font-size: ${({ theme }) => theme.FONT_SIZES.LINK.DESKTOP}px;
`;

Link.defaultProps = {
  fontWeight: 'regular',
};

export default Link;
