import H1 from './H1';
import H2 from './H2';
import H3 from './H3';
import H5 from './H5';
import H6 from './H6';
import Body from './Body';
import BodyInput from './BodyInput';
import Link from './Link';
import Label from './Label';
import Title from './Title';
import Subtitle from './Subtitle';
import Footnote from './Footnote';

export { H1, H3, H2, H5, H6, Body, BodyInput, Link, Label, Title, Subtitle, Footnote };
