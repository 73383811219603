import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import { Body, Footnote, H5 } from '@sp/ui/typography';
import {
  selectRetirementAge,
  selectAwardRules,
  selectAllAwardRules,
  selectDisposableIncomeOptions,
  selectShowGrossAmount,
} from 'selectors/dashboard';
import IncomeTodayDetails from '../IncomeTodayDetails';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import Margin from '@sp/ui/base/Margin';
import ListAwardRules from 'areas/dashboard/AwardRules/ListAwardRules';
import YearlyPayoutDetailsRow from 'areas/dashboard/payouts/YearlyPayoutDetailsRow';
import OneTimePayoutRow from 'areas/dashboard/payouts/OneTimePayoutRow';
import { getDescriptionText } from 'areas/dashboard/payouts/payoutsAll/helpers';
import { PayoutsDescription } from 'areas/dashboard/styles';
import { DEATH, DISABILITY } from '../../constants/dashboardTypes';

const PayoutsAll = ({
  retirementAge,
  theme,
  i18n,
  awardRulesAll,
  showGrossAmount,
  payoutType,
  payouts,
}) => (
  <>
    {(payoutType === DISABILITY || payoutType === DEATH) && (
      <ListAwardRules
        textLeft={i18n.t('dashboard|payoutCriteria')}
        awardRules={awardRulesAll}
        payoutType={payoutType}
        footerText
        showDisclaimer
      />
    )}
    <PayoutsDescription>
      <Body textAlign="center">
        {getDescriptionText(retirementAge, payoutType, i18n, showGrossAmount, awardRulesAll)}
      </Body>
    </PayoutsDescription>

    <RowBorder />
    <OneLineRow
      textLeft={
        <H5>{payoutType === DEATH ? i18n.t('dashboard|year') : i18n.t('dashboard|age')}</H5>
      }
      textRight={<H5>{i18n.t('dashboard|payoutPrMonth')}</H5>}
      backgroundColor={theme.COLORS.PRIMARY_VARIANT1}
    />
    <IncomeTodayDetails />
    {payouts?.map((item) => {
      if (item.oneTimePayout) {
        return (
          <OneTimePayoutRow
            item={item}
            payoutType={payoutType}
            i18n={i18n}
            key={`OneTimePayoutRow-${item.age}`}
            showGrossAmount={showGrossAmount}
            showDescription
          />
        );
      }

      return (
        <YearlyPayoutDetailsRow
          item={item}
          payoutType={payoutType}
          i18n={i18n}
          key={`YearlyPayoutDetailsRow-${item.age}`}
          showGrossAmount={showGrossAmount}
        />
      );
    })}

    <Margin horizontal="md" vertical="sm">
      <Footnote>{i18n.t('dashboard|payoutsOverTimeDisclaimer')}</Footnote>
    </Margin>
  </>
);

PayoutsAll.propTypes = {
  payouts: PropTypes.array,
  retirementAge: PropTypes.number,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  awardRulesAll: PropTypes.array,
  showGrossAmount: PropTypes.bool.isRequired,
  payoutType: PropTypes.string.isRequired,
};

PayoutsAll.defaultProps = {
  payouts: undefined,
  awardRulesAll: [],
  retirementAge: undefined,
};

const mapStateToProps = (state, props) => ({
  retirementAge: selectRetirementAge(state),
  awardRules: selectAwardRules(state),
  awardRulesAll: selectAllAwardRules(state, props.payoutType),
  hasDisposableIncomeOptions: selectDisposableIncomeOptions(state),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withScreenContainer({
  mapStateToProps,
})(withTheme(PayoutsAll));
