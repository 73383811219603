import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Button } from '@sp/ui/base/buttons';
import Icon from '@sp/ui/base/Icon';
import { Subtitle } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import { ActionShape } from './index.shared';

const MARGIN_TOP = 10;

// NOTE: "z-index: -1" is needed to have the ability to place absolute elements over buttons
const Wrapper = styled.View`
  align-items: center;
  padding-bottom: ${(props) => props.theme.SPACINGS.md}px;
  z-index: ${(props) => props.zIndex};
`;

const ButtonWrapper = styled.View`
  margin-top: ${MARGIN_TOP}px;
`;

const Actions = ({ actions, actionInfo, theme, zIndex }) => {
  const singleAction = actions && actions.length === 1;

  const iconFill = (action) => {
    switch (action.type) {
      case 'secondary':
        return theme.COLORS.PRIMARY;
      case 'accessory':
        return theme.COLORS.PRIMARY_TEXT;
      default:
        return theme.COLORS.SECONDARY_BACKGROUND;
    }
  };

  return (
    <Wrapper center={singleAction} zIndex={zIndex}>
      {actionInfo && (
        <Margin horizontal="md">
          <Subtitle>{actionInfo}</Subtitle>
        </Margin>
      )}
      {actions.map((action) => {
        if (isValidElement(action.element)) return action.element;

        return (
          <ButtonWrapper key={action.text || action.icon}>
            <Button
              testID={action.testID}
              before={action.icon && <Icon name={action.icon} fill={iconFill(action)} />}
              {...action}
            />
          </ButtonWrapper>
        );
      })}
    </Wrapper>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(ActionShape),
  theme: PropTypes.shape({
    COLORS: PropTypes.shape({
      PRIMARY: PropTypes.string.isRequired,
      SECONDARY_BACKGROUND: PropTypes.string.isRequired,
      PRIMARY_TEXT: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  zIndex: PropTypes.number,
  actionInfo: PropTypes.string,
};

Actions.defaultProps = {
  actions: [],
  zIndex: -1,
  actionInfo: undefined,
};

export default withTheme(Actions);
