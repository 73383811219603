import React from 'react';
import { StyleSheet, View, Animated, PanResponder } from 'react-native';
import PropTypes from 'prop-types';
import { isWeb, isAndroid } from '@sp/ui/helpers/device';

import Slide from './Slide';
import BackgroundOverlay from './BackgroundOverlay';
import ConditionalImage from 'containers/theme/ConditionalImage';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  sliderContainer: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
    position: 'relative',
    flex: 1,
  },
  animatedWrapper: {
    position: 'relative',
    top: 0,
    left: 0,
  },
});

/*
NOTE: based on https://github.com/oxyii/react-native-web-swiper,
but adapted to our lint rules and needs
*/

export default class Swiper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      activeIndex: props.index,
      pan: new Animated.ValueXY(),
    };

    this.animatedValueX = 0;
    this.animatedValueY = 0;

    this.useNativeDriver = isAndroid;
    this.backfaceVisibility = isWeb
      ? {
          backfaceVisibility: 'hidden',
        }
      : {};

    this._panResponder = PanResponder.create({
      onPanResponderTerminationRequest: () => false,
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponderCapture: (e, gestureState) => {
        const allow =
          Math.abs(this.props.direction === 'row' ? gestureState.dx : gestureState.dy) > 5;
        if (allow) this.stopAutoplay();
        return allow;
      },
      onPanResponderGrant: () => this._fixState(),
      onPanResponderMove: Animated.event(
        [
          null,
          this.props.direction === 'row' ? { dx: this.state.pan.x } : { dy: this.state.pan.y },
        ],
        { useNativeDriver: false }
      ),
      onPanResponderRelease: (e, gesture) => {
        const correction =
          this.props.direction === 'row' ? gesture.moveX - gesture.x0 : gesture.moveY - gesture.y0;
        this.startAutoplay();
        if (
          Math.abs(correction) <
          (this.props.direction === 'row' ? this.state.width : this.state.height) *
            this.props.actionMinWidth
        ) {
          Animated.spring(this.state.pan, {
            toValue: { x: 0, y: 0 },
            useNativeDriver: this.useNativeDriver,
          }).start();
        } else {
          this._changeIndex(correction > 0 ? -1 : 1);
        }
      },
    });
  }

  componentDidMount() {
    this.state.pan.x.addListener((value) => {
      this._animatedValueX = value.value;
    });
    this.state.pan.y.addListener((value) => {
      this._animatedValueY = value.value;
    });
    this.startAutoplay();
  }

  componentWillUnmount() {
    this.stopAutoplay();
    this.state.pan.x.removeAllListeners();
    this.state.pan.y.removeAllListeners();
  }

  startAutoplay() {
    this.stopAutoplay();
    if (this.props.autoplayTimeout) {
      this.autoplay = setTimeout(() => {
        this.moveUpDown(this.props.autoplayTimeout < 0);
      }, Math.abs(this.props.autoplayTimeout) * 1000);
    }
  }

  stopAutoplay() {
    if (this.autoplay) {
      clearTimeout(this.autoplay);
    }
  }

  moveUpDown(down = false) {
    this._fixState();
    this._changeIndex(down ? -1 : 1);
  }

  moveTo(index) {
    this._fixState();
    const move = { x: 0, y: 0 };
    let calcDelta = 0;
    if (this.state.activeIndex < index) {
      calcDelta = (this.state.activeIndex - index) * -1;
    } else if (this.state.activeIndex > index) {
      calcDelta = index - this.state.activeIndex;
    }
    if (this.props.direction === 'row') {
      move.x = this.state.width * -1 * calcDelta;
    } else {
      move.y = this.state.height * -1 * calcDelta;
    }
    this.setState({ activeIndex: index });
    this._animateFullMove(move, index);
  }

  _fixState() {
    this._animatedValueX =
      this.props.direction === 'row' ? this.state.width * this.state.activeIndex * -1 : 0;
    this._animatedValueY =
      this.props.direction === 'row' ? 0 : this.state.height * this.state.activeIndex * -1;
    this.state.pan.setOffset({ x: this._animatedValueX, y: this._animatedValueY });
    this.state.pan.setValue({ x: 0, y: 0 });
  }

  _animateFullMove(move, index) {
    Animated.spring(this.state.pan, {
      toValue: move,
      useNativeDriver: this.useNativeDriver,
    }).start();
    this.startAutoplay();
    if (this.props.onIndexChanged) {
      this.props.onIndexChanged(index);
    }
  }

  _changeIndex(delta = 1) {
    const move = { x: 0, y: 0 };
    let skipChanges = !delta;
    let calcDelta = delta;
    if (this.state.activeIndex <= 0 && delta < 0) {
      skipChanges = !this.props.loop;
      calcDelta = this.count + delta;
    } else if (this.state.activeIndex + 1 >= this.count && delta > 0) {
      skipChanges = !this.props.loop;
      calcDelta = -1 * (this.state.activeIndex + delta) - 1;
    }
    if (skipChanges) {
      Animated.spring(this.state.pan, {
        toValue: move,
        useNativeDriver: this.useNativeDriver,
      }).start();
    } else {
      this.setState(
        (prevState) => ({
          activeIndex: prevState.activeIndex + calcDelta,
        }),
        () => {
          if (this.props.direction === 'row') {
            move.x = this.state.width * -1 * calcDelta;
          } else {
            move.y = this.state.height * -1 * calcDelta;
          }
          this._animateFullMove(move, this.state.activeIndex);
        }
      );
    }
  }

  _onLayout(event) {
    const { width, height } = event.nativeEvent.layout;
    this.setState({ width, height }, () => this._fixState());
  }

  _renderContent(children, pan, height, width, direction, swipeAreaStyle, swipeWrapperStyle) {
    const filteredChildren = children.filter(Boolean);

    return (
      <View style={[styles.sliderContainer, swipeAreaStyle]}>
        {filteredChildren.length > 1 ? (
          <Animated.View
            testID={this.props.testID}
            style={[
              styles.animatedWrapper,
              swipeWrapperStyle,
              {
                flexDirection: direction,
                width: direction === 'row' ? width * this.count : width,
                height: direction === 'row' ? height : height * this.count,
              },
              { transform: [{ translateX: pan.x }, { translateY: pan.y }] },
              this.backfaceVisibility,
            ]}
            {...this._panResponder.panHandlers}
          >
            {filteredChildren.map((el, i) => (
              <View key={i} style={{ width, height }}>
                {el}
              </View>
            ))}
          </Animated.View>
        ) : (
          filteredChildren
        )}
      </View>
    );
  }

  render() {
    const { pan, width, height } = this.state;
    const { direction, containerStyle, swipeAreaStyle, swipeWrapperStyle } = this.props;
    if (!width) {
      return (
        <View style={[styles.container, containerStyle]} onLayout={this._onLayout.bind(this)} />
      );
    }

    let { children, backgroundImage, overlay } = this.props;

    if (!Array.isArray(children)) children = [children];

    this.count = children.length;

    const contents = this._renderContent(
      children,
      pan,
      height,
      width,
      direction,
      swipeAreaStyle,
      swipeWrapperStyle
    );

    return (
      <View style={[styles.container, containerStyle]} onLayout={this._onLayout.bind(this)}>
        {backgroundImage ? (
          <ConditionalImage {...backgroundImage}>
            {overlay && <BackgroundOverlay />}
            {contents}
          </ConditionalImage>
        ) : (
          <>{contents}</>
        )}
      </View>
    );
  }
}

const ViewStyleProps = PropTypes.oneOf([PropTypes.array, PropTypes.object]);

Swiper.propTypes = {
  direction: PropTypes.oneOf(['row', 'column']),
  index: PropTypes.number,
  onIndexChanged: PropTypes.func,
  actionMinWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
  overRangeButtonsOpacity: PropTypes.number,
  loop: PropTypes.bool,
  autoplayTimeout: PropTypes.number,
  swipeAreaStyle: ViewStyleProps,
  swipeWrapperStyle: ViewStyleProps,
  containerStyle: ViewStyleProps,
  testID: PropTypes.string,
  backgroundImage: PropTypes.shape({
    mobile: PropTypes.any,
    desktop: PropTypes.any,
    tablet: PropTypes.any,
    landscape: PropTypes.any,
  }),
  overlay: PropTypes.bool,
};

Swiper.defaultProps = {
  onIndexChanged: undefined,
  direction: 'row',
  index: 0,
  actionMinWidth: 0.25,
  overRangeButtonsOpacity: 0,
  loop: false,
  autoplayTimeout: 0,
  swipeAreaStyle: undefined,
  swipeWrapperStyle: undefined,
  containerStyle: undefined,
  testID: undefined,
  backgroundImage: undefined,
  overlay: false,
};

export { Slide };
