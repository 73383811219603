import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CollapsibleRow, RowBorder } from '@sp/ui/base/rows';
import PayoutCard from 'areas/dashboard/AwardRules/PayoutCard';
import Margin from '@sp/ui/base/Margin';
import { H5 } from '@sp/ui/typography';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import AwardRulesNotFound from 'areas/dashboard/AwardRules/AwardRulesNotFound';
import Loading from 'areas/dashboard/AwardRules/Loading';
import { selectAwardRulesDisclaimers, selectAwardRulesIsFetching } from 'selectors/dashboard';
import withComponentContainer from 'decorators/withComponentContainer';
import Footer from 'areas/dashboard/AwardRules/Footer';
import { payoutTypeDeathAndDisability } from 'areas/dashboard/props';
import Markdown from '@sp/ui/base/Markdown';

const getContent = (awardRules, disclaimer) => {
  if (!awardRules || awardRules.length === 0) {
    return (
      <Margin marginBottom="md">
        <AwardRulesNotFound />
      </Margin>
    );
  }

  return (
    <Fragment>
      {disclaimer && (
        <Margin horizontal="md">
          <Markdown>{disclaimer}</Markdown>
        </Margin>
      )}
      <RowBorder />
      {awardRules.map((awardRule, index) => (
        <PayoutCard
          awardRule={awardRule}
          key={`police_no: ${index}. ${awardRule.referenceNumber}`}
        />
      ))}
      <Margin marginTop="sm" />
      <RowBorder />
    </Fragment>
  );
};

const ListAwardRules = ({
  payoutType,
  footerText,
  awardRules,
  textLeft,
  awardRulesIsFetching,
  disclaimer,
}) => (
  <CollapsibleRow
    iconLeft="info"
    textLeft={renderStringOrComponent(textLeft, H5, { fontWeight: 'regular', numberOfLines: 1 })}
  >
    {awardRulesIsFetching ? <Loading /> : getContent(awardRules, disclaimer)}
    {footerText && <Footer payoutType={payoutType} />}
  </CollapsibleRow>
);

ListAwardRules.propTypes = {
  awardRules: PropTypes.array,
  footerText: PropTypes.bool,
  payoutType: PropTypes.oneOf(payoutTypeDeathAndDisability),
  awardRulesIsFetching: PropTypes.bool,
  textLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disclaimer: PropTypes.string,
};

ListAwardRules.defaultProps = {
  awardRules: [],
  awardRulesIsFetching: false,
  footerText: false,
  payoutType: undefined,
  disclaimer: undefined,
};

const mapStateToProps = (state, props) => ({
  awardRulesIsFetching: selectAwardRulesIsFetching(state),
  disclaimer: props.showDisclaimer
    ? selectAwardRulesDisclaimers(state, props.payoutType)
    : undefined,
});

export default withComponentContainer({
  hasLocalNotifications: false,
  mapStateToProps,
})(ListAwardRules);
