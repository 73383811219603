import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { skipFetchingProcess } from 'actions/fetchTaxAndPensionsInfo';
import {
  selectHasFetchedTax,
  selectHasSkippedTax,
  selectShouldFetchTax,
} from 'selectors/fetchTaxAndPensionsInfo';
import { useNextAction } from './useNextAction';
import type { RootState } from 'store';

const selectSkipAction = (state: RootState) => ({
  shouldFetchTax: selectShouldFetchTax(state),
  hasFetchedTax: selectHasFetchedTax(state),
  hasSkippedTax: selectHasSkippedTax(state),
});

export const useSkipAction = () => {
  const { shouldFetchTax, hasFetchedTax, hasSkippedTax } = useAppSelector(
    selectSkipAction,
    shallowEqual
  );

  const dispatch = useAppDispatch();
  const nextAction = useNextAction();

  return useCallback(() => {
    const isUserLocatedAtPensionsInfoIntroductionScreen =
      !shouldFetchTax || hasSkippedTax || hasFetchedTax;

    const skippedAreas = {
      isSkippingTax: shouldFetchTax || hasSkippedTax,
      isSkippingPensionsInfo: isUserLocatedAtPensionsInfoIntroductionScreen ?? false,
    };

    dispatch(skipFetchingProcess(skippedAreas));
    nextAction(skippedAreas);
  }, [shouldFetchTax, hasSkippedTax, hasFetchedTax, dispatch, nextAction]);
};
