import { create } from '../helpers/reducers';

import {
  SET_HAS_FETCHED_PENSIONSINFO,
  SET_SKIP_TAX_PENSIONSINFO,
  SET_HAS_FETCHED_TAX,
  SET_RESET_FETCHING_STATUSES,
  UPDATE_FETCH_TAX_PENSIONSINFO,
  UPDATE_FETCH_FAIL_TAX_PENSIONSINFO,
  DELETE_FETCH_TAX_PENSIONSINFO_STORE,
} from 'constants/actionTypes';

const initialState = {
  fetchTax: undefined as any,
  fetchPensionsInfo: undefined as any,
  fetchFailType: undefined as any,
  hasSkippedPensionsInfo: undefined as any,
  hasSkippedTax: undefined as any,
  hasFetchedTax: undefined as any,
  hasFetchedPensionsInfo: undefined as any,
  taxFetchingStatus: undefined as any,
  pensionsInfoFetchingStatus: undefined as any,
  isSimulationFinish: undefined as any,
  isOnboarding: undefined as any,
  isTaxStatementValid: undefined as any,
  isTaxSkipped: undefined as any,
  isPensionsInfoSkipped: undefined as any,
  onDoneRouteConfig: undefined as any,
};

export type FetchTaxAndPensionsInfoState = typeof initialState;

const createDefaults = () => initialState;

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [SET_HAS_FETCHED_PENSIONSINFO]: (state, payload) => ({
      ...state,
      hasFetchedPensionsInfo: payload,
    }),
    [SET_HAS_FETCHED_TAX]: (state, payload) => ({
      ...state,
      hasFetchedTax: payload,
    }),
    [SET_RESET_FETCHING_STATUSES]: (state) => ({
      ...state,
      pensionsInfoFetchingStatus: undefined,
      taxFetchingStatus: undefined,
    }),
    [SET_SKIP_TAX_PENSIONSINFO]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [UPDATE_FETCH_TAX_PENSIONSINFO]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [UPDATE_FETCH_FAIL_TAX_PENSIONSINFO]: (state, payload) => ({
      ...state,
      fetchFailType: payload,
    }),
    [DELETE_FETCH_TAX_PENSIONSINFO_STORE]: () => initialState,
    RESPONSE_AWAIT_PENSIONS_INFO_STATUS: (state, payload) => ({
      ...state,
      pensionsInfoFetchingStatus: payload.response.status,
    }),
    RESPONSE_AWAIT_SKAT_STATUS: (state, payload) => ({
      ...state,
      taxFetchingStatus: payload.response,
    }),
  },
  createDefaults
);

export type { State };
