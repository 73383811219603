import { useCallback } from 'react';
import fetchTaxAndPensionsInfoController from '../fetchTaxAndPensionsInfoController';

type UseOnCancelParams = {
  url?: string;
  checkForClosedBrowserService?: boolean;
};

export const useOnCancel = () => {
  const { getBrowserService, updateAndOpenBrowserService, updateTerminationSituation } =
    fetchTaxAndPensionsInfoController;

  return useCallback(
    (params?: UseOnCancelParams) => {
      const { url, checkForClosedBrowserService } = params ?? {};

      if (checkForClosedBrowserService) {
        const browserService = getBrowserService();

        if (browserService?.closed && url) {
          updateAndOpenBrowserService(url);
        }
      }

      updateTerminationSituation({
        isTryingToTerminateFetchingProcess: false,
        hasTerminatedFetchingProcess: false,
      });
    },
    [getBrowserService, updateAndOpenBrowserService, updateTerminationSituation]
  );
};
