import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/native';
import { selectIsDeviceLandscape, selectDeviceType } from 'selectors/device';

const sizing = {
  mobile: {
    size: 50,
    borderRadius: 13,
    margin: 10,
    fontSize: 11,
    marginBottom: 7,
  },
  tablet: {
    size: 75,
    borderRadius: 15,
    margin: 20,
    marginBottom: 10,
    fontSize: 13,
  },
  desktop: {
    size: 75,
    borderRadius: 15,
    margin: 20,
    marginBottom: 10,
    fontSize: 13,
  },
  landscape: {
    size: 70,
    borderRadius: 15,
    margin: 22,
    marginBottom: 10,
    fontSize: 13,
  },
};

const Wrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  padding-top: 42px;
  height: 100%;
  width: 100%;
`;

const IconPlaceholder = styled.View`
  background: #333;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  margin-left: ${({ margin }) => margin}px;
  margin-right: ${({ margin }) => margin}px;
`;

const Image = styled.Image`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const IconText = styled.Text`
  color: ${({ theme }) => theme.COLORS.V2_PRIMARY_TEXT};
  font-size: ${({ fontSize }) => fontSize}px;
  text-align: center;
`;

const AppIconWrapper = styled.View`
  align-items: center;
`;

const AppIcon = ({ dimensions, placeholder, iconUri }) => {
  return (
    <AppIconWrapper>
      {placeholder ? (
        <IconPlaceholder {...dimensions} />
      ) : (
        <Image source={{ uri: iconUri }} {...dimensions} />
      )}
      <IconText fontSize={dimensions.fontSize}>{!placeholder && 'Sampension'}</IconText>
    </AppIconWrapper>
  );
};

AppIcon.propTypes = {
  dimensions: PropTypes.object.isRequired,
  placeholder: PropTypes.bool.isRequired,
  iconUri: PropTypes.string.isRequired,
};

const IconGrid = ({ count, deviceType, iconUri, isLandscape }) => {
  let dimensions = sizing[deviceType];

  if (deviceType === 'tablet' && isLandscape) {
    dimensions = sizing.landscape;
  }

  let items = [];

  for (let i = 1; i <= count; i += 1) {
    items.push(<AppIcon dimensions={dimensions} key={i} placeholder={i !== 1} iconUri={iconUri} />);
  }

  return <Wrapper>{items}</Wrapper>;
};

IconGrid.propTypes = {
  count: PropTypes.number.isRequired,
  deviceType: PropTypes.string.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  iconUri: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  deviceType: selectDeviceType(state),
  isLandscape: selectIsDeviceLandscape(state),
});

export default connect(mapStateToProps)(IconGrid);
