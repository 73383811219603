import React, { useState } from 'react';
import { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import Tracking from 'services/tracking';
import Link from 'containers/base/Link';
import { SafeAreaWrapper } from '@sp/ui/v2/pages/BasePage';
import Modal from 'containers/v2/layout/Modal';
import Menu from 'containers/navigation/Menu';
import Markdown from 'containers/base/v2/Markdown';
import BottomSheetPage from 'containers/v2/pages/BottomSheetPage';
import { Body2 } from '@sp/ui/v2/typography';
import { doCustomerFlow as doCustomerFlowAction } from 'actions/onboarding';
import {
  getConsents,
  getConsent as apiGetConsent,
  giveConsent as apiGiveConsent,
} from 'actions/consents';
import { selectGDPRConsent } from 'selectors/consents';
import Content from 'areas/onboarding/components/Content';
import Margin from '@sp/ui/base/Margin';
import ConsentCheckbox from './ConsentCheckbox';
import { selectIsMeetingPreparationFlow } from 'selectors/flowControl';
import * as routeNames from 'constants/routeNames';

const Consents = ({ consents, giveConsent, getConsent, i18n }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [proposedConsents, setProposedConsents] = useState({});

  const consentsNormalized = (consents.items || []).map((consent) => {
    const given = consent?.currentConsent?.toLowerCase() === 'granted';

    return {
      key: consent.key,
      title: consent.title,
      given,
      proposed: given || proposedConsents[consent.key],
      optional: consent.optional,
    };
  });

  const hasMissingConsents = consentsNormalized.find(
    (consent) => !consent.proposed && !consent.optional
  );

  return (
    <>
      <SafeAreaWrapper>
        <BottomSheetPage
          title={i18n.t('onboarding|consents')}
          hasBackButton={false}
          menu={<Menu />}
          actionsContent={
            <>
              {consentsNormalized.map(({ key, title, proposed }) => (
                <ConsentCheckbox
                  consentKey={key}
                  title={title}
                  proposed={proposed}
                  getConsent={getConsent}
                  proposedConsents={proposedConsents}
                  setTermsModalVisible={() => setIsModalVisible(false)}
                  setProposedConsents={setProposedConsents}
                  key={key}
                />
              ))}

              <Margin marginTop="md">
                <Link
                  linkColor
                  textAlign="center"
                  onPress={() => {
                    setIsModalVisible(true);
                    getConsent(consents?.items[0]?.key);
                  }}
                >
                  <Body2>{i18n.t('onboarding|consentsLink')}</Body2>
                </Link>
              </Margin>
            </>
          }
          actions={[
            {
              text: i18n.t('next'),
              inactive: !!hasMissingConsents,
              mobilePosition: 'bottom-sticky',
              onPress: () => {
                Tracking.trackEvent({ area: 'OnboardingConsent', action: 'ClickNext' });
                giveConsent(proposedConsents);
              },
            },
          ]}
        >
          <Content
            title={i18n.t('onboarding|consentsHeading')}
            body={i18n.t('onboarding|consentsDescription')}
            icon={{
              width: 72,
              height: 65,
              name: 'handheart',
            }}
          />
        </BottomSheetPage>
      </SafeAreaWrapper>
      <Modal visible={isModalVisible} onRequestClose={() => setIsModalVisible(false)} primaryColor>
        <Markdown>{consents.items[0]?.text}</Markdown>
      </Modal>
    </>
  );
};

Consents.propTypes = {
  consents: PropTypes.object.isRequired,
  giveConsent: PropTypes.func,
  getConsent: PropTypes.func,
  i18n: PropTypes.object.isRequired,
};

Consents.defaultProps = {
  modalIsVisible: false,
  modalTermsContent: null,
  giveConsent: null,
  getConsent: undefined,
};

const mapStateToProps = (state) => ({
  consents: selectGDPRConsent(state),
  isMeetingPreparationFlow: selectIsMeetingPreparationFlow(state),
});

const init =
  ({ action, getProps }) =>
  async () => {
    // We fetch consents only if they are in ready status
    const { consents, dispatch, isMeetingPreparationFlow, navigation } = getProps();

    if (consents.status === 'ready') {
      await action(async () => dispatch(getConsents()), {
        error: {
          type: 'overlay',
          retry: true,
        },
        loader: true,
      });
    }

    const giveConsent = (state) =>
      action(
        async () => {
          const keys = Object.keys(state).filter((key) => state[key]);
          for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            await dispatch(apiGiveConsent(key));
          }
          if (isMeetingPreparationFlow) {
            navigation.navigate(routeNames.MEETING_PREPARATION, {
              screen: routeNames.MEETING_PREPARATION_INTRO,
            });
          } else {
            await dispatch(doCustomerFlowAction());
          }
        },
        {
          loader: true,
        }
      );

    const getConsent = (key) => action(async () => dispatch(apiGetConsent(key)));

    return {
      giveConsent,
      getConsent,
    };
  };

export default withScreenContainer({
  mapStateToProps,
  i18ns: ['onboarding'],
  init,
  overlayScreenProps: {
    titleKey: 'Samtykker',
    hasBackButton: false,
  },
})(withTheme(Consents));
