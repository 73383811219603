import React from 'react';
import i18n from 'services/i18n';
import Footer from 'containers/navigation/Footer';
import Tracking from 'services/tracking';
import features from 'features';

import * as routeNames from 'constants/routeNames';

const menuItemsFactory = (navigation) => {
  const menuItems = [
    {
      name: i18n.t('contactTitle'),
      icon: 'phone',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Footer',
            action: 'Selected',
          },
          { section: 'contactoverview' }
        );
        navigation.navigate(routeNames.MODAL_CMS_STACK, { id: 'contactoverview' });
      },
    },
    {
      name: i18n.t('feedbackTitle'),
      icon: 'chat',
      featureName: 'feedback',
      onPress: () => {
        Tracking.trackEvent(
          {
            area: 'Footer',
            action: 'Selected',
          },
          { section: routeNames.FEEDBACK_TYPE }
        );
        navigation.navigate(routeNames.FEEDBACK);
      },
    },
  ];

  return menuItems.filter((item) => features.isEnabled(item.featureName));
};

export default <Footer menuItemsFactory={menuItemsFactory} />;
