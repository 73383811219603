import { useEffect } from 'react';

export const SELECTION_TYPE_SINGLE = 'SELECTION_TYPE_SINGLE';
export const SELECTION_TYPE_MULTIPLE = 'SELECTION_TYPE_MULTIPLE';

export const isPolicySelected = (state, policy) =>
  state.some((p) => p.policyId === policy.policyId);

export const isCompanyGroupSelected = (state, companyGroup, companyGroupPolicies) => {
  const selectedPolicies = state.filter((p) => p.companyGroup === companyGroup);

  return selectedPolicies.length >= companyGroupPolicies?.length;
};

export const isCompanyGroupPartiallySelected = (state, companyGroup, companyGroupPolicies) =>
  state.some((p) => p.companyGroup === companyGroup) &&
  !isCompanyGroupSelected(state, companyGroup, companyGroupPolicies);

export const getSelectedPoliciesText = (state, companyGroup, companyGroupPolicies) => {
  const selectedNumber = state?.filter((p) => p.companyGroup === companyGroup)?.length;
  const totalNumber = companyGroupPolicies?.length;

  return selectedNumber
    ? {
        count: selectedNumber,
        type: 'selected',
      }
    : {
        count: totalNumber,
        type: 'total',
      };
};

export const usePreselectWhenOnePolicy = (data, state, isOnePolicy, onPolicyPress) =>
  useEffect(() => {
    if (state.length === 1) {
      return;
    }

    if (isOnePolicy) {
      const policy = data[0]?.policies[0];

      onPolicyPress(policy.policyId);
    }
    // NOTE: This is only suppose to be run on mount or when number of polices changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnePolicy]);
