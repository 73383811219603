import { Keyboard } from 'react-native';
import i18n from 'services/i18n';
import { isWeb } from 'helpers/platform';

export const getActions = (form, sendFeedback) => [
  {
    text: i18n.t('common|send'),
    inactive: !form.subject || !form.content,
    mobilePosition: 'bottom',
    onPress: () => {
      if (!isWeb) {
        Keyboard.dismiss();
      }

      sendFeedback();
    },
  },
];
