import { GO_TO_CONFIRM_TRANSFER_BUTTON } from 'constants/testIds/transfer';
import { UPDATE_PENSIONSINFO_BUTTON, GET_PENSIONSINFO_BUTTON } from 'constants/testIds';

export const getSavingsOverviewActions = ({
  isTransferButtonActive,
  isDepositsButtonActive,
  goToTransferFlow,
  goToDepositsFlow,
  missingSavingsDataKey,
  updatePensionInfo,
  i18n,
}) => {
  const actions = [];
  const hasCustomerNotFetchedPensionsInfo =
    missingSavingsDataKey === 'customerHasNotFetchedPensionInfo';

  if (isTransferButtonActive) {
    actions.push({
      testID: GO_TO_CONFIRM_TRANSFER_BUTTON,
      text: i18n.t('savings|transferPensions'),
      type: 'primary',
      mobilePosition: 'bottom-sticky',
      onPress: goToTransferFlow,
    });
  }

  if (actions.length === 0 && isDepositsButtonActive) {
    actions.push({
      text: i18n.t('deposits|Indbetal ekstra og udnyt fradrag'),
      type: 'primary',
      mobilePosition: 'bottom-sticky',
      onPress: goToDepositsFlow,
    });
  }

  actions.push({
    testID: hasCustomerNotFetchedPensionsInfo
      ? GET_PENSIONSINFO_BUTTON
      : UPDATE_PENSIONSINFO_BUTTON,
    text: hasCustomerNotFetchedPensionsInfo
      ? i18n.t('savings|getNumbersFromPensionsInfo')
      : i18n.t('updatePensionInfo'),
    type: actions.length > 0 ? 'secondary' : 'primary',
    mobilePosition: 'bottom-sticky',
    onPress: updatePensionInfo,
  });

  return actions;
};
