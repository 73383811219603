import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import PageParts from '@sp/ui/PageParts';
import { isWeb } from '@sp/ui/helpers/device';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useModal from '@sp/ui/hooks/useModal';

import Actions from './Actions';
import { groupActions, ActionShape } from './Actions/index.shared';

export const CARD_HORIZONTAL_PADDING = 'xl';
export const CARD_WIDTH = 375;

const Wrapper = styled.View`
  background: ${(props) => props.theme.COLORS.PRIMARY};
  flex: 1;
  ${isWeb ? 'user-select: text;' : ''}
`;

const ContentWrapper = styled.View`
  flex-direction: row;
  flex: 1;
`;

const Content = styled.View`
  flex: 1;
  margin-right: auto;
`;

const ActionCard = styled.View`
  margin-bottom: ${({ theme }) => theme.SPACINGS.xl}px;
  margin-right: ${({ theme }) => theme.SPACINGS.xl}px;
  width: ${CARD_WIDTH}px;
`;

const StatusBarOffset = styled.View`
  ${({ hasHeader, extraTopPadding }) => (hasHeader ? '' : `padding-top: ${extraTopPadding}px;`)}
  background: ${({ theme }) => theme.COLORS.PRIMARY};
`;

const BasePage = ({
  menu,
  title,
  children,
  actions,
  actionInfo,
  backButton,
  hasBackButton,
  hasHeader,
  hasNotifications,
  customHeader,
  testID,
  accessibilitySummary,
  actionsContent,
  backgroundImage,
}) => {
  const { isModal, leftContent, onClose, hasOnCloseButton } = useModal();
  const insets = useSafeAreaInsets();

  const groupedActions = groupActions(actions);
  const hasCustomHeader = typeof customHeader !== 'undefined';
  const hasGlobalHeader = !hasCustomHeader && hasHeader && !isModal;
  const hasModalHeader = !hasCustomHeader && hasHeader && isModal;

  return (
    <PageParts>
      {({
        menu: globalMenu,
        Header: GlobalHeader,
        ModalHeader,
        BackButton: GlobalBackButton,
        notifications,
      }) => {
        const backButtonNode =
          hasBackButton && (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />);

        return (
          <Wrapper testID={testID}>
            <StatusBarOffset hasHeader={hasHeader} extraTopPadding={insets.top} />
            {isWeb && (typeof menu !== 'undefined' ? menu : globalMenu)}
            {hasCustomHeader && customHeader}
            {hasGlobalHeader && GlobalHeader && (
              <GlobalHeader
                title={title}
                backButton={backButtonNode}
                accessibilitySummary={accessibilitySummary}
              />
            )}
            {hasModalHeader && ModalHeader && (
              <ModalHeader
                title={title}
                onClose={onClose}
                hasOnCloseButton={hasOnCloseButton}
                backButton={backButtonNode}
                leftContent={leftContent}
              />
            )}
            {hasNotifications && notifications}
            <ContentWrapper>
              <Content>{children}</Content>
              {(actionsContent || groupedActions.bottom.length > 0) && (
                <ActionCard>
                  <Actions
                    backgroundImage={backgroundImage}
                    actions={groupedActions.bottom}
                    actionInfo={actionInfo}
                    actionsContent={actionsContent}
                  />
                </ActionCard>
              )}
            </ContentWrapper>
          </Wrapper>
        );
      }}
    </PageParts>
  );
};

BasePage.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  hasHeader: PropTypes.bool,
  customHeader: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  menu: PropTypes.node,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  hasNotifications: PropTypes.bool,
  actionsContent: PropTypes.node,
  backgroundImage: PropTypes.node,
};

BasePage.defaultProps = {
  title: undefined,
  children: undefined,
  actions: undefined,
  actionInfo: undefined,
  backButton: undefined,
  customHeader: undefined,
  hasHeader: true,
  hasBackButton: true,
  hasNotifications: true,
  menu: undefined,
  testID: undefined,
  accessibilitySummary: undefined,
  actionsContent: undefined,
  backgroundImage: undefined,
};

export default BasePage;
