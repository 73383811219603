import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { shallowEqual } from 'react-redux';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import { selectFormAdjustIncome } from 'selectors/dashboardForms';
import { selectUserEnteredIncome } from 'selectors/dashboard';
import PointerEventsNoneWrapper from '../components/PointerEventsNoneWrapper';
import type { RootState } from 'store';

const { default: Input } = require('containers/form/Input');
const { Body2 } = require('@sp/ui/v2/typography');
const { Form } = require('react-formed');
const { currencyAmountValidator } = require('@sp/ui/helpers/validators');

const InputWrapper = styled.View`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const selectAdjustDisposableIncome = (state: RootState) => ({
  userEnteredIncome: selectUserEnteredIncome(state),
  formAdjustIncome: selectFormAdjustIncome(state),
});

const ManualIncome: React.FC = () => {
  const { SPACINGS } = useTheme();
  const i18n = useI18n(['dashboard']);

  const { userEnteredIncome, formAdjustIncome } = useAppSelector(
    (state) => selectAdjustDisposableIncome(state),
    shallowEqual
  );

  const moneySymbol = i18n.t('moneySymbol');

  const { enteredIncome, enteredGrossIncome } = formAdjustIncome ?? {};
  const { amount, grossAmount } = userEnteredIncome ?? {};

  const currencyValidator = useCallback(
    (val: number | string) => currencyAmountValidator(val) || val === '',
    []
  );

  return (
    <Form name="adjustIncome">
      <PointerEventsNoneWrapper>
        <Body2 fontWeight="regular" secondaryColor>
          {i18n.t('dashboard|incomeInputDisclaimer')}
        </Body2>
      </PointerEventsNoneWrapper>
      <InputWrapper>
        <Input
          testID={testIds.DISPOSABLE_INCOME_AFTER_TAX_INPUT}
          useV2
          numeric
          name="enteredIncome"
          keyboardType="number-pad"
          customValidator={currencyValidator}
          autoCorrect={false}
          placeholder={i18n.t('dashboard|userEnteredIncome')}
          after={
            enteredIncome || amount ? i18n.t('dashboard|amountWithoutTax', { moneySymbol }) : ' '
          }
          defaultValue={amount}
          accessibilityLabel={i18n.t('dashboard|userEnteredIncome')}
        />
        <Input
          testID={testIds.DISPOSABLE_INCOME_BEFORE_TAX_INPUT}
          useV2
          numeric
          name="enteredGrossIncome"
          keyboardType="number-pad"
          customValidator={currencyValidator}
          autoCorrect={false}
          placeholder={i18n.t('dashboard|enterIncomeWithGross')}
          after={
            enteredGrossIncome || grossAmount
              ? i18n.t('dashboard|amountWithGross', { moneySymbol })
              : ' '
          }
          marginTop={SPACINGS.md}
          defaultValue={grossAmount}
          accessibilityLabel={i18n.t('dashboard|enterIncomeWithGross')}
        />
      </InputWrapper>
    </Form>
  );
};

export default ManualIncome;
