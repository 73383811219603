import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import styled, { withTheme } from 'styled-components/native';
import { RadioButton } from '@sp/ui/base/inputs';
import Conditional from 'decorators/conditional';

const RADIO_BUTTON_WRAPPER_LEFT_MARGIN_MOBILE = 20;
const RADIO_BUTTON_WRAPPER_LEFT_MARGIN = 24;

const Wrapper = styled.View`
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  height: 56px;
`;

const RadioButtonWrapperMobile = styled.View`
  ${({ vertical }) => (vertical ? '' : 'flex: 1;')};
  margin-left: ${({ isFirst, vertical }) =>
    !vertical && !isFirst ? RADIO_BUTTON_WRAPPER_LEFT_MARGIN_MOBILE : 0}px;
  border-color: ${(props) => props.theme.COLORS.PRIMARY_BORDER};
  ${({ borders }) => (borders ? `border-bottom-width: ${StyleSheet.hairlineWidth}px;` : '')}
  ${({ isFirst, borders }) =>
    borders && isFirst ? `border-top-width: ${StyleSheet.hairlineWidth}px;` : ''}
`;

const RadioButtonWrapperTablet = styled.View`
  margin-left: ${({ isFirst, vertical }) =>
    !vertical && !isFirst ? RADIO_BUTTON_WRAPPER_LEFT_MARGIN : 0}px;
  border-color: ${(props) => props.theme.COLORS.PRIMARY_BORDER};
  ${({ borders }) => (borders ? `border-bottom-width: ${StyleSheet.hairlineWidth}px;` : '')}
  ${({ isFirst, borders }) =>
    borders && isFirst ? `border-top-width: ${StyleSheet.hairlineWidth}px;` : ''}
`;

const RadioButtonWrapper = Conditional({
  default: RadioButtonWrapperTablet,
  mobile: RadioButtonWrapperMobile,
});

const RadioButtonGroup = ({ vertical, onChange, radioProps, initial, value }) => {
  const getSelectedIndex = () => {
    const index = radioProps.findIndex((button) => value === button.value);
    return index > -1 ? index : initial;
  };

  const selectedIndex = getSelectedIndex();

  return (
    <Wrapper vertical={vertical}>
      {radioProps.map((button, i) => (
        <RadioButtonWrapper key={i} vertical={vertical} isFirst={i === 0} borders={vertical}>
          <RadioButton
            onPress={
              button.disabled
                ? undefined
                : () => {
                    if (typeof onChange === 'function') onChange(button.value);
                  }
            }
            label={button.label}
            isSelected={selectedIndex === i}
            border={!vertical}
          />
        </RadioButtonWrapper>
      ))}
    </Wrapper>
  );
};

RadioButtonGroup.propTypes = {
  radioProps: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  initial: PropTypes.number,
  onChange: PropTypes.func,
  vertical: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  value: undefined,
  initial: 0,
  onChange: undefined,
  vertical: false,
};

export default withTheme(RadioButtonGroup);
