import React from 'react';
import styled from 'styled-components/native';
import { useAppSelector } from 'hooks/redux';
import { shallowEqual } from 'react-redux';
import { selectIsDeviceTypeDesktop, selectIsDeviceTypeTablet } from 'selectors/device';
import { RootState } from 'store';

const Wrapper = styled.View<{ isDesktop: boolean; isTablet: boolean }>`
  padding: ${({ theme }) => theme.SPACINGS.s}px 0px;
  background-color: ${({ theme, isDesktop }) =>
    isDesktop ? '' : theme.COLORS.V2_SECONDARY_SELECTED};
  border-radius: ${({ theme, isTablet }) => (isTablet ? theme.DEFAULTS.BORDER_RADIUS : 0)}px;
`;

const selectRowsWrapper = (state: RootState) => ({
  isTablet: selectIsDeviceTypeTablet(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
});

type RowsWrapperProps = {
  children: React.ReactNode;
};

const RowsWrapper = ({ children }: RowsWrapperProps) => {
  const { isTablet, isDesktop } = useAppSelector(selectRowsWrapper, shallowEqual);
  return (
    <Wrapper isTablet={isTablet} isDesktop={isDesktop}>
      {children}
    </Wrapper>
  );
};

export default RowsWrapper;
