import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import * as routeNames from 'constants/routeNames';
import FetchTaxAndPensionsInfoIntroduction from '.';
import TaxStatementInvalidConsent from './TaxStatementInvalidConsent';
import FetchTaxOrPensionsInfo from './FetchTaxOrPensionsInfo';
import FetchTaxAndPensionsInfoProvider from './FetchTaxAndPensionsInfoProvider';
import FetchDone from './FetchDone';
import FetchFail from './FetchFail';
import FetchCancelled from './FetchCancelled';
import { isWeb } from 'helpers/platform';
import useI18n from 'hooks/useI18n';

const routes = [
  {
    name: routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION,
    component: FetchTaxAndPensionsInfoIntroduction,
  },
  {
    name: routeNames.FETCH_TAX_OR_PENSIONSINFO,
    component: FetchTaxOrPensionsInfo,
  },
  {
    name: routeNames.FETCH_TAX_PENSIONSINFO_FAIL,
    component: FetchFail,
  },
  {
    name: routeNames.FETCH_TAX_PENSIONSINFO_DONE,
    component: FetchDone,
  },
  {
    name: routeNames.TAX_STATEMENT_INVALID_CONSENT,
    component: TaxStatementInvalidConsent,
  },
  {
    name: routeNames.FETCH_TAX_PENSIONSINFO_CANCELLED,
    component: FetchCancelled,
  },
];

const Stack = createStackNavigator();

export default () => {
  const i18n = useI18n(['fetchTaxAndPensionsInfo']);

  return (
    <FetchTaxAndPensionsInfoProvider>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        {routes.map(({ name, component }) => (
          <Stack.Screen
            key={name}
            name={name}
            component={component}
            options={{
              gestureEnabled: false,
              title: isWeb
                ? `${i18n.t('fetchTaxAndPensionsInfo|retrieveInformation')} | Sampension`
                : undefined,
            }}
            // initialParams={{ onSkip, onPensionComplete }}
          />
        ))}
      </Stack.Navigator>
    </FetchTaxAndPensionsInfoProvider>
  );
};
