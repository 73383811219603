import React, { useState, useEffect, useRef } from 'react';
import withComponentContainer from 'decorators/withComponentContainer';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Tracking from 'services/tracking';
import {
  selectRetirementAge,
  selectMinimumRetirementAge,
  selectMaximumRetirementAge,
} from 'selectors/dashboard';
import { AgeSelector as UIAgeSelector } from '@sp/ui/base/buttons';
import MinAgeModal from 'containers/dashboard/AgeSelector/MinAgeModal';
import MaxAgeModal from 'containers/dashboard/AgeSelector/MaxAgeModal';
import { initFunc } from 'helpers/props';
import { INCREASE_RETIREMENT_AGE, DECREASE_RETIREMENT_AGE } from 'areas/dashboard/trackingIds';
import { useUpdateRetirementAge } from './hooks/useUpdateRetirementAge';
import { useDebounce } from 'use-debounce';

const SelectPensionAgeContainer = styled.View`
  flex-direction: row;
  justify-content: space-around;
`;

const AgeSelector = ({
  retirementAge,
  testID,
  buttonTestIDS,
  minimumRetirementAge,
  maximumRetirementAge,
  trackingParams,
  i18n,
  withDebounce,
}) => {
  const [isMinAgeModalOpen, setIsMinAgeModalOpen] = useState(false);
  const [isMaxAgeModalOpen, setIsMaxAgeModalOpen] = useState(false);
  const [updateRetirementAge] = useUpdateRetirementAge();
  const [age, setAge] = useState(retirementAge);
  const delay = withDebounce ? 250 : 0;

  const [debouncedAge] = useDebounce(age, delay);
  const prevAge = useRef(retirementAge);
  const prevStateAge = useRef(age);

  useEffect(() => {
    if (!age) {
      setAge(minimumRetirementAge || 0);
    }
  }, [age, minimumRetirementAge]);

  useEffect(() => {
    // value was updated
    if (
      prevStateAge.current !== debouncedAge &&
      prevAge.current === retirementAge &&
      debouncedAge
    ) {
      updateRetirementAge(debouncedAge);
      prevStateAge.current = debouncedAge;
      prevAge.current = debouncedAge;
      // value was updated outside, so references and state is updated
    } else if (prevAge.current !== retirementAge && prevStateAge.current === debouncedAge) {
      setAge(retirementAge);
      prevStateAge.current = retirementAge;
      prevAge.current = retirementAge;
    }
  }, [retirementAge, updateRetirementAge, debouncedAge, withDebounce]);

  return (
    <SelectPensionAgeContainer>
      <UIAgeSelector
        testID={testID}
        buttonTestIDS={buttonTestIDS}
        accessibilityLabelLeft={i18n.t('Hæver din ønskede pensionsalder')}
        accessibilityLabelRight={i18n.t('Sænker din ønskede pensionsalder')}
        accessibilityHint={i18n.t(
          'Her kan du angive hvornår du ønsker at gå på pension. Hver gang du ændrer den ønskede pensionsalder, justeres grafen ovenfor.'
        )}
        minAge={minimumRetirementAge}
        maxAge={maximumRetirementAge}
        onValueChange={setAge}
        value={age}
        title={age}
        subTitle={i18n.t('år')}
        onPressLeft={() => {
          Tracking.trackEvent(DECREASE_RETIREMENT_AGE, trackingParams);
          if (minimumRetirementAge !== age) {
            // no reason to debounce button - so action is called
            updateRetirementAge(age - 1);
          } else {
            setIsMinAgeModalOpen(true);
          }
        }}
        onPressRight={() => {
          Tracking.trackEvent(INCREASE_RETIREMENT_AGE, trackingParams);
          if (maximumRetirementAge !== age) {
            // no reason to debounce button - so action is called
            updateRetirementAge(age + 1);
          } else {
            setIsMaxAgeModalOpen(true);
          }
        }}
        inactiveLeft={minimumRetirementAge === age}
        inactiveRight={maximumRetirementAge === age}
      />
      <MinAgeModal visible={isMinAgeModalOpen} onClose={() => setIsMinAgeModalOpen(false)} />
      <MaxAgeModal visible={isMaxAgeModalOpen} onClose={() => setIsMaxAgeModalOpen(false)} />
    </SelectPensionAgeContainer>
  );
};

AgeSelector.propTypes = {
  testID: PropTypes.string,
  buttonTestIDS: PropTypes.object,
  retirementAge: PropTypes.number,
  minimumRetirementAge: PropTypes.number,
  maximumRetirementAge: PropTypes.number,
  i18n: PropTypes.object.isRequired,
  trackingParams: PropTypes.object.isRequired,
  withDebounce: PropTypes.bool,
};

AgeSelector.defaultProps = {
  testID: undefined,
  buttonTestIDS: undefined,
  retirementAge: undefined,
  updateRetirementAge: initFunc,
  minimumRetirementAge: undefined,
  maximumRetirementAge: undefined,
  withDebounce: false,
};

const mapStateToProps = (state) => ({
  retirementAge: selectRetirementAge(state),
  minimumRetirementAge: selectMinimumRetirementAge(state),
  maximumRetirementAge: selectMaximumRetirementAge(state),
});

export default withComponentContainer({
  mapStateToProps,
})(AgeSelector);
