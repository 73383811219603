import {
  RESPONSE_GET_PENSIONS_INFO,
  FAILED_GET_PENSIONS_INFO,
  DELETE_USER_PENSIONS_INFO,
} from 'constants/actionTypes';
import { create } from 'helpers/reducers';

interface State {
  pensionsInfoUpdateDate?: string;
  companiesThatDidNotDeliverFullPensionsInfo?: string[];
}

const DEFAULT_STATE = {
  pensionsInfoUpdateDate: undefined,
  companiesThatDidNotDeliverFullPensionsInfo: undefined,
  hasNoPensionsInfoFile: undefined,
  isPensionsInfoFileDeleted: undefined,
};

const createDefaults = (): State => DEFAULT_STATE;

export default create(
  {
    [RESPONSE_GET_PENSIONS_INFO]: (state, payload) => ({
      ...state,
      ...payload.response,
      hasNoPensionsInfoFile: false,
      isPensionsInfoFileDeleted: false,
    }),
    [FAILED_GET_PENSIONS_INFO]: () => ({
      ...DEFAULT_STATE,
      hasNoPensionsInfoFile: true,
    }),
    [DELETE_USER_PENSIONS_INFO]: () => ({
      ...DEFAULT_STATE,
      isPensionsInfoFileDeleted: true,
    }),
  },
  createDefaults
);

export type { State };
