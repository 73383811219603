import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { ImageBackground } from 'react-native';
import { useAnimatedRef } from 'react-native-reanimated';
import styled, { withTheme } from 'styled-components/native';
import { ScrollViewKeyboardAvoid } from '@sampension/reanimated-animation-library';
import { Button } from '@sp/ui/base/buttons';
import Icon from '@sp/ui/base/Icon';
import { Body2 } from '@sp/ui/v2/typography';
import Margin from '@sp/ui/base/Margin';
import { shadow } from '@sp/ui/helpers/style';
import { ActionShape } from './index.shared';

// NOTE: "z-index: -1" is needed to have the ability to place absolute elements over buttons
const Wrapper = styled.View`
  z-index: ${(props) => props.zIndex};
  ${({ horizontalMode, theme, transparent, actionsContent }) => {
    return `
      background: ${transparent ? 'transparent' : theme.COLORS.SECONDARY_BACKGROUND};
      padding-top: ${horizontalMode && actionsContent ? 0 : theme.SPACINGS.md}px;
      padding-bottom: ${horizontalMode ? theme.SPACINGS.xl : theme.SPACINGS.md}px;
      padding-right: ${theme.SPACINGS.xl}px;
      padding-left: ${theme.SPACINGS.xl}px;
      border-top-left-radius: ${theme.SPACINGS.m}px;
      border-top-right-radius: ${theme.SPACINGS.m}px;
      border-bottom-left-radius: ${horizontalMode ? 0 : theme.SPACINGS.m}px;
      border-bottom-right-radius: ${horizontalMode ? 0 : theme.SPACINGS.m}px;
      height: ${horizontalMode ? 'auto' : '100%'};
      ${!transparent ? shadow : ''}
    `;
  }}
`;

const ScrollViewWrapper = styled.View`
  flex: 1;
`;

const BgWrapper = styled.View`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY};
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.8;
`;

const ActionsContentWrapper = styled.View`
  padding-top: ${({ theme, horizontalMode }) =>
    horizontalMode ? theme.SPACINGS.md : theme.SPACINGS.xl}px;
  padding-bottom: ${({ theme, horizontalMode }) => (horizontalMode ? theme.SPACINGS.sm : 0)}px;
  width: 100%;
`;

const ButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme, horizontalMode }) =>
    horizontalMode ? `${theme.SPACINGS.sm}px` : 'auto'};
  flex-direction: ${({ horizontalMode }) => (horizontalMode ? 'row' : 'column')};
  margin-bottom: ${({ theme, horizontalMode }) => (horizontalMode ? 0 : theme.SPACINGS.md)}px;
`;

const Actions = ({
  actions,
  actionInfo,
  theme,
  zIndex,
  actionsContent,
  horizontalMode,
  backgroundImage,
  transparent,
}) => {
  const scrollViewRef = useAnimatedRef();
  const singleAction = actions && actions.length === 1;

  const iconFill = (action) => {
    switch (action.type) {
      case 'secondary':
        return theme.COLORS.PRIMARY;
      case 'accessory':
        return theme.COLORS.PRIMARY_TEXT;
      default:
        return theme.COLORS.SECONDARY_BACKGROUND;
    }
  };

  return (
    <>
      {backgroundImage && (
        <ImageBackground
          resizeMode="cover"
          source={backgroundImage}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            // NOTE: This fixes minor UI bug where Content has background
            // Mirroring background for Actions makes background look not cropped
            transform: [{ scaleX: -1 }],
            flex: 1,
          }}
        />
      )}
      {backgroundImage && <BgWrapper />}
      <Wrapper
        center={singleAction}
        zIndex={zIndex}
        horizontalMode={horizontalMode}
        actionsContent={actionsContent}
        transparent={transparent}
      >
        <ScrollViewWrapper>
          <ScrollViewKeyboardAvoid
            isKeyboardAvoidDisabled
            ref={scrollViewRef}
            bounces={false}
            scrollEventThrottle={theme.SCROLL_CONFIG.SCROLL_EVENT_THROTTLE}
            fadingScrollEdges={theme.SCROLL_CONFIG.FADING_SCROLL_EDGES}
            scrollArrows={theme.SCROLL_CONFIG.SCROLL_ARROWS}
          >
            {actionsContent && (
              <ActionsContentWrapper horizontalMode={horizontalMode}>
                {actionsContent}
              </ActionsContentWrapper>
            )}
            {actionInfo && (
              <Margin vertical="md">
                {typeof actionInfo === 'string' ? (
                  <Body2 secondaryColor fontWeight="normal">
                    {actionInfo}
                  </Body2>
                ) : (
                  actionInfo
                )}
              </Margin>
            )}
          </ScrollViewKeyboardAvoid>
        </ScrollViewWrapper>
        <ButtonWrapper horizontalMode={horizontalMode}>
          {actions.map((action, index) => {
            if (isValidElement(action.element)) return action.element;

            return (
              <Margin
                key={action.text || action.icon}
                marginTop={!horizontalMode && 'md'}
                marginLeft={horizontalMode && index === 1 && 'md'}
              >
                <Button
                  testID={action.testID}
                  before={action.icon && <Icon name={action.icon} fill={iconFill(action)} />}
                  {...action}
                />
              </Margin>
            );
          })}
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(ActionShape),
  theme: PropTypes.shape({
    SCROLL_CONFIG: PropTypes.shape({
      SCROLL_EVENT_THROTTLE: PropTypes.number.isRequired,
      FADING_SCROLL_EDGES: PropTypes.shape({
        iOSandWebFadingEdgeHeight: PropTypes.number.isRequired,
        nativeBackgroundColor: PropTypes.string.isRequired,
        webBackgroundColorTop: PropTypes.object.isRequired,
        webBackgroundColorBottom: PropTypes.object.isRequired,
      }),
      SCROLL_ARROWS: PropTypes.shape({
        dimensions: PropTypes.number.isRequired,
        fill: PropTypes.string.isRequired,
      }),
    }),
    COLORS: PropTypes.shape({
      PRIMARY: PropTypes.string.isRequired,
      SECONDARY_BACKGROUND: PropTypes.string.isRequired,
      PRIMARY_TEXT: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  zIndex: PropTypes.number,
  actionInfo: PropTypes.string,
  actionsContent: PropTypes.node,
  horizontalMode: PropTypes.bool,
  backgroundImage: PropTypes.node,
  transparent: PropTypes.bool,
};

Actions.defaultProps = {
  actions: [],
  zIndex: 1,
  actionInfo: undefined,
  actionsContent: undefined,
  horizontalMode: false,
  backgroundImage: undefined,
  transparent: false,
};

export default withTheme(Actions);
