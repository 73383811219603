import React, { useContext } from 'react';
import { shallowEqual } from 'react-redux';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import MenuPageContainer from 'containers/v2/pages/MenuPage/MenuPageV2';
import type { RootState } from 'store';
import { selectDeeplink } from 'selectors/deeplink';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import { CommonActions, useNavigation } from '@react-navigation/native';
import fetchTaxAndPensionsInfoController from 'areas/fetchTaxAndPensionsInfo/fetchTaxAndPensionsInfoController';
import { selectOnDoneRouteConfig } from 'selectors/fetchTaxAndPensionsInfo';

const { default: Margin } = require('@sp/ui/base/Margin');
const { ContentWithIcon } = require('@sp/ui/v2/pages/BasePage');
const { ModalProvider } = require('@sp/ui/v2/context/ModalContext');

const ICON_WIDTH = 72;
const ICON_HEIGHT = 65;

const selectFetchFail = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
  onDoneRouteConfig: selectOnDoneRouteConfig(state),
});

const FetchFail: React.FC = () => {
  const i18n = useI18n(['fetchTaxAndPensionsInfo']);
  const { updateFetchingState } = useContext(FetchTaxAndPensionsInfoContext);
  const { removePendingRefetchingPromise } = fetchTaxAndPensionsInfoController;
  const navigation = useNavigation();
  const { deeplink, isDesktop, onDoneRouteConfig } = useAppSelector(selectFetchFail, shallowEqual);
  const { isDeeplink } = deeplink ?? {};

  const onClose = () => {
    updateFetchingState({});
    removePendingRefetchingPromise();
    navigation.dispatch(CommonActions.navigate(onDoneRouteConfig));
  };
  return (
    <ModalProvider hasOnCloseButton={!isDeeplink} onClose={onClose} isModal secondaryColor>
      <MenuPageContainer
        hasBackButton={false}
        actions={[
          {
            text: i18n.t('common|close'),
            onPress: onClose,
            mobilePosition: 'bottom',
          },
        ]}
      >
        <Margin horizontal="xl" marginTop="md">
          <ContentWithIcon
            isMobile={true}
            title={
              isDesktop ? undefined : i18n.t('fetchTaxAndPensionsInfo|inAppBrowserClosedTitle')
            }
            body={i18n.t('fetchTaxAndPensionsInfo|inAppBrowserClosed')}
            icon={{
              width: ICON_WIDTH,
              height: ICON_HEIGHT,
              name: 'exclamationMarkFilled',
            }}
          />
        </Margin>
      </MenuPageContainer>
    </ModalProvider>
  );
};

export default FetchFail;
