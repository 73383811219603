import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OneLineRow, CollapsibleRow } from '@sp/ui/base/rows';
import { H5, Footnote } from '@sp/ui/typography';
import { formatMoney } from 'helpers/string';
import Margin from '@sp/ui/base/Margin';
import styled, { withTheme } from 'styled-components/native';
import PayoutsModal from 'areas/dashboard/PayoutsModal';
import PaymentDetails from 'areas/dashboard/payouts/PaymentDetails';
import { payoutTypes } from 'areas/dashboard/props';
import { DEATH, RETIREMENT } from '../constants/dashboardTypes';

const TouchableOpacity = styled.TouchableOpacity``;

const YearlyPayoutDetailsRow = ({ item, theme, payoutType, i18n, showGrossAmount }) => {
  const [showModal, setShowModal] = useState(undefined);

  const {
    totalIncomeWithoutOneTimePayouts,
    ophoerendeUdbetalingTotal,
    ophoerendeUdbetalingDetails,
    livsvarigUdbetalingTotal,
    livsvarigUdbetalingDetails,
    offentligeYdelserTotal,
    offentligeYdelserDetails,
    wealthAtPensionTotal,
  } = item.roundedAveragePayoutMonth;

  return (
    <CollapsibleRow
      textLeft={
        payoutType === DEATH ? (
          <H5>{item.year}</H5>
        ) : (
          <H5>{i18n.t('dashboard|payoutRowTitle', { pensionAge: item.age })}</H5>
        )
      }
      textRight={
        <H5>
          {i18n.t('dashboard|amountPrMonth', {
            amount: formatMoney(totalIncomeWithoutOneTimePayouts, true, showGrossAmount, false),
          })}
        </H5>
      }
    >
      {ophoerendeUdbetalingTotal.amount > 0 && (
        <PaymentDetails
          payments={ophoerendeUdbetalingDetails}
          descriptionColor={theme.COLORS.GRAPH_PAYOUTS_VARIANT2}
          description={i18n.t('dashboard|aNumberOfYearsTotal')}
          total={ophoerendeUdbetalingTotal}
          showDescriptionModal={() => setShowModal('periodOfYears')}
        />
      )}

      {livsvarigUdbetalingTotal.amount > 0 && (
        <PaymentDetails
          payments={livsvarigUdbetalingDetails}
          descriptionColor={theme.COLORS.GRAPH_PAYOUTS_VARIANT4}
          description={i18n.t('dashboard|lifeLongTotal')}
          total={livsvarigUdbetalingTotal}
          showDescriptionModal={() => setShowModal('lifeTime')}
        />
      )}

      {offentligeYdelserTotal.amount > 0 && (
        // only show subgroups if amount is greater than 0.
        <PaymentDetails
          payments={offentligeYdelserDetails.filter((payout) =>
            showGrossAmount ? payout.amount?.grossAmount > 0 : payout.amount.amount > 0
          )}
          descriptionColor={theme.COLORS.PRIMARY}
          description={i18n.t('dashboard|publicTotal')}
          total={offentligeYdelserTotal}
          showDescriptionModal={() => setShowModal('publicServices')}
          infoColor={theme.COLORS.PRIMARY}
          showGreenCheckModal={() => setShowModal('greenCheck')}
        />
      )}

      {payoutType === RETIREMENT && wealthAtPensionTotal.amount > 0 && (
        <OneLineRow
          textLeft={
            <TouchableOpacity onPress={() => setShowModal('wealth')}>
              <H5 color={theme.COLORS.GRAPH_PAYOUTS_VARIANT1} fontWeight="bold">
                {i18n.t('dashboard|savingsTotal')}
              </H5>
            </TouchableOpacity>
          }
          textRight={
            <Margin marginRight="xs">
              <Margin marginRight="lg">
                <Footnote fontWeight="bold">
                  {i18n.t('dashboard|amountPrMonth', {
                    amount: formatMoney({ amount: wealthAtPensionTotal.amount }, true),
                  })}
                </Footnote>
              </Margin>
            </Margin>
          }
        />
      )}
      <PayoutsModal
        showModal={showModal === 'greenCheck'}
        onClose={() => setShowModal(undefined)}
        title={i18n.t('dashboard|greenCheckModalTitle')}
        description={i18n.t('dashboard|greenCheckModalDescription')}
      />
      <PayoutsModal
        showModal={showModal === 'periodOfYears'}
        onClose={() => setShowModal(undefined)}
        description={i18n.t('dashboard|periodOfYearsModalDescription')}
        title={i18n.t('dashboard|periodOfYearsModalTitle')}
      />
      <PayoutsModal
        showModal={showModal === 'publicServices'}
        onClose={() => setShowModal(undefined)}
        title={i18n.t('dashboard|publicServicesModalTitle')}
        description={i18n.t('dashboard|publicServicesModalDescription')}
      />
      <PayoutsModal
        showModal={showModal === 'lifeTime'}
        onClose={() => setShowModal(undefined)}
        title={i18n.t('dashboard|lifeTimeModalTitle')}
        description={i18n.t('dashboard|lifeTimeModalDescription')}
      />
      <PayoutsModal
        showModal={showModal === 'wealth'}
        onClose={() => setShowModal(undefined)}
        title={i18n.t('dashboard|wealthModalTitle')}
        description={i18n.t('dashboard|wealthModalDescription')}
      />
      <PayoutsModal
        showModal={showModal === 'oneTimePayment'}
        onClose={() => setShowModal(undefined)}
        title={i18n.t('dashboard|oneTimePaymentModalTitle')}
        description={i18n.t('dashboard|oneTimePaymentModalDescription')}
      />
    </CollapsibleRow>
  );
};

YearlyPayoutDetailsRow.propTypes = {
  item: PropTypes.object.isRequired,
  payoutType: PropTypes.oneOf(payoutTypes).isRequired,
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  showGrossAmount: PropTypes.bool.isRequired,
};

export default withTheme(YearlyPayoutDetailsRow);
