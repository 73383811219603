import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useAppDispatch } from 'hooks/redux';
import { FETCH_TAX_PENSIONSINFO_FAIL } from 'constants/routeNames';
import { updateFetchFailTaxAndPensionsInfo } from 'actions/fetchTaxAndPensionsInfo';

export const useOnFetchFail = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  return useCallback(
    (failType: string) => {
      dispatch(updateFetchFailTaxAndPensionsInfo(failType));
      navigation.navigate(FETCH_TAX_PENSIONSINFO_FAIL);
    },
    [navigation, dispatch]
  );
};
