import React from 'react';
import PropTypes from 'prop-types';
import Button from '@sp/ui/layout/Header/Button';
import Header from './index';

const ModalHeader = ({
  onClose,
  hasOnCloseButton,
  title,
  leftContent,
  rightContent,
  animation,
  animateTitle,
}) => (
  <Header
    title={title}
    hasOnCloseButton={hasOnCloseButton}
    rightContent={rightContent || <Button icon="close" onPress={onClose} testID="modalClose" />}
    leftContent={leftContent || null}
    animation={animation}
    animateTitle={animateTitle}
  />
);

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  animation: PropTypes.object,
  animateTitle: PropTypes.bool,
  hasOnCloseButton: PropTypes.bool,
};

ModalHeader.defaultProps = {
  onClose: undefined,
  leftContent: undefined,
  rightContent: undefined,
  title: undefined,
  animation: undefined,
  animateTitle: false,
  hasOnCloseButton: false,
};

export default ModalHeader;
