import React from 'react';
import Description from 'areas/dashboard/Description';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { nanoid } from 'nanoid';
import { getPensionVsTodayRatioAdvice } from 'areas/dashboard/helpers';
import { formatMoney } from 'helpers/string';
import { Body } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import AwardRulesNotFound from 'areas/dashboard/AwardRules/AwardRulesNotFound';
import Markdown from '@sp/ui/base/Markdown';
import { selectShowGrossAmount } from 'selectors/dashboard';

const DisabilityDescription = ({
  i18n,
  navigation,
  payoutDisability,
  awardRules,
  awardRulesSucceeded,
  noRecommendationType,
  showGrossAmount,
}) => {
  let title;
  let description;
  // need to create a id, used for key to description in order to collapse it on every mount.
  const notificationId = nanoid();

  const pensionVsTodayRatioAdvice =
    payoutDisability &&
    getPensionVsTodayRatioAdvice(payoutDisability.pensionVsTodayRatio, 'disability');

  switch (true) {
    case noRecommendationType === 'hasActiveTemporaryDisabilityCoverage':
      title = i18n.t('dashboard|hasActiveTemporaryDisabilityCoverageTitle');
      description = i18n.t('dashboard|hasActiveTemporaryDisabilityCoverageDescription');
      break;
    case noRecommendationType === 'hasActiveDisabilityCoverage':
      title = i18n.t('dashboard|hasActiveDisabilityCoverageTitle');
      description = i18n.t('dashboard|hasActiveDisabilityCoverageDescription');
      break;
    case noRecommendationType === 'disposableIncomeBelowRecommendationLimit':
      title = i18n.t('dashboard|disposableIncomeBelowRecommendationLimitDisabilityTitle');
      description = i18n.t(
        'dashboard|disposableIncomeBelowRecommendationLimitDisabilityDescription'
      );
      break;
    case noRecommendationType === 'hasGrafiskPensionVurdererAwardRule':
      title = i18n.t('dashboard|hasGrafiskPensionVurdererAwardRuleTitle');
      description = i18n.t('dashboard|hasGrafiskPensionVurdererAwardRuleDescription');
      break;
    case pensionVsTodayRatioAdvice === 'alert':
      title = i18n.t('dashboard|payoutTooLowTitle');
      description = i18n.t('dashboard|disabilityPayoutTooLowDescription', {
        payoutDisability: payoutDisability ? formatMoney(payoutDisability, true) : i18n.t('? kr.'),
      });
      break;
    case pensionVsTodayRatioAdvice === 'warning':
      title = i18n.t('dashboard|payoutALittleTooLowTitle');
      description = i18n.t('dashboard|disabilityPayoutALittleTooLowDescription', {
        payoutDisability: payoutDisability ? formatMoney(payoutDisability, true) : i18n.t('? kr.'),
      });
      break;
    default:
      title = i18n.t('dashboard|payoutIsFineTitle');
      description = i18n.t('dashboard|disabilityPayoutIsFineDescription', {
        payoutDisability: payoutDisability ? formatMoney(payoutDisability, true) : i18n.t('? kr.'),
      });
      break;
  }

  description += i18n.t('dashboard|noPayoutForKids');

  const preDescription = showGrossAmount
    ? i18n.t('dashboard|disabilityVsTodayRatioAdvicePreDescription')
    : undefined;

  return (
    <Description
      key={notificationId}
      title={title}
      description={preDescription ? `${preDescription}\n\n${description}` : description}
      modalTitle={i18n.t('dashboard|disabilityPayoutModalTitle')}
      infoAccessibilityLabel={i18n.t('dashboard|disabilityPayoutModalA11y')}
      modalDescription={
        <>
          {awardRules && (
            <>
              {awardRulesSucceeded ? (
                <Markdown>{awardRules?.descriptions?.disabilityDashboardInfoText || ''}</Markdown>
              ) : (
                <AwardRulesNotFound disableMargin />
              )}
              <Margin vertical="md">
                <Body fontWeight="bold">{i18n.t('dashboard|recommendedPayoutModalTitle')}</Body>
              </Margin>
            </>
          )}
          <Body>{i18n.t('dashboard|disabilityPayoutModalFooter')}</Body>
        </>
      }
      navigation={navigation}
    />
  );
};

DisabilityDescription.propTypes = {
  i18n: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  payoutDisability: PropTypes.object,
  awardRules: PropTypes.object,
  awardRulesSucceeded: PropTypes.bool,
  noRecommendationType: PropTypes.string,
  showGrossAmount: PropTypes.bool.isRequired,
};

DisabilityDescription.defaultProps = {
  payoutDisability: undefined,
  awardRules: undefined,
  awardRulesSucceeded: false,
  noRecommendationType: undefined,
};

const mapStateToProps = (state) => ({
  showGrossAmount: selectShowGrossAmount(state),
});

export default withComponentContainer({
  mapStateToProps,
  hasLocalNotifications: false,
})(DisabilityDescription);
