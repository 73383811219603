"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ActiveRequests {
    constructor() {
        this._requests = {};
        this.addRequest = (id, info) => {
            this._requests[id] = info;
        };
        this.removeRequest = (id) => {
            delete this._requests[id];
        };
        this.getBySignature = (signature) => Object.values(this._requests).find(r => r.signature === signature);
        this.getById = (id) => this._requests[id];
        this.getByGroup = (groupId) => Object.values(this._requests).filter(i => i.groupId === groupId);
        this.cancel = (id) => {
            const request = this.getById(id);
            if (request) {
                request.cancel();
            }
        };
        this.preventCancel = () => {
            Object.values(this._requests).forEach((r) => {
                r.cancel = () => { };
            });
        };
    }
    getAll() {
        return Object.values(this._requests);
    }
}
exports.default = ActiveRequests;
