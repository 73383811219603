import { COLORS } from '@sp/ui/settings';
import { DASHBOARD_GRAPH_AVERAGE_PAYOUT_BAR } from 'constants/testIds/dashboard';
import {
  fetchTaxAndPensionsInfo,
  fetchPensionsInfo,
  fetchTax,
} from 'actions/fetchTaxAndPensionsInfo';
import { layoutSpecs } from 'areas/dashboard/styles';
import { formatMoney, accessibilityPrettifier, getGrossAmountOrAmount } from 'helpers/string';
import { DEATH, RETIREMENT } from './constants/dashboardTypes';

const PAGE_MAX_WIDTH_DESKTOP = 914;

export const getPensionVsTodayRatioAdvice = (ratio, payoutType) => {
  switch (payoutType) {
    case 'retirement':
      if (ratio >= 0.66) return 'success';
      if (ratio >= 0.5) return 'warning';
      return 'alert';
    case 'disability':
      if (ratio >= 0.8) return 'success';
      if (ratio >= 0.66) return 'warning';
      return 'alert';
    case 'death':
      if (ratio >= 0.8) return 'success';
      if (ratio >= 0.5) return 'warning';
      return 'alert';
    default:
      return 'alert';
  }
};

export const getBarColor = (ratio, payoutType, noRecommendation) => {
  if (noRecommendation) {
    return COLORS.GRAPH_NEUTRAL;
  }
  const advice = getPensionVsTodayRatioAdvice(ratio, payoutType);

  switch (advice) {
    case 'success':
      return COLORS.PRIMARY_SUCCESS;
    case 'warning':
      return COLORS.PRIMARY_WARNING;
    default:
      return COLORS.PRIMARY_ALERT;
  }
};

export const updatePension = ({ dispatch }) => dispatch(fetchPensionsInfo());

export const updateTax = ({ dispatch }) => dispatch(fetchTax());

export const updatePensionAndTax = ({ dispatch }) => dispatch(fetchTaxAndPensionsInfo());

export const getGraphAccessibilityLabel = (i18n, description, amount) => {
  const formatted = description.replace('\n', '').replace('-', '');

  return i18n.t('dashboard|graphLabelA11y', { formatted, amount });
};

export const getMaxGraphWidth = (isDesktop, payouts, labelWidth, graphPadding, windowWidth) => {
  if (isDesktop) {
    return Math.min(PAGE_MAX_WIDTH_DESKTOP, windowWidth);
  }

  return (
    Math.max(
      layoutSpecs.pageMaxWidth,
      Math.min(layoutSpecs.pageMaxWidth, (payouts?.length + 1) * labelWidth)
    ) + graphPadding
  );
};

export const getGraphValue = (amount) => {
  const GRAPH_MIN_HEIGHT = 300;
  // return a minimum value, so the amount will be visible in the bar.
  if (amount > GRAPH_MIN_HEIGHT || amount === 0) {
    return amount;
  }
  return GRAPH_MIN_HEIGHT;
};

export const getBarDescription = (payoutType, item, i18n) =>
  payoutType === DEATH
    ? item.year?.toString()
    : i18n.t('dashboard|barDescription', { pensionAge: item.age.toString() });

const getGraphLabel = (amount, i18n, showGrossAmount) =>
  i18n.t('dashboard|amountPrMonth', {
    amount: formatMoney(amount, true, showGrossAmount, false),
  });

export const getYearlyPayoutBar = ({ i, theme, payoutType, i18n, showGrossAmount }) => {
  const roundedAmounts = i.roundedAveragePayoutMonth;
  return {
    barWidth: 60,
    labelColor: theme.COLORS.DARK,
    descriptionColor: theme.COLORS.DARK,
    label: getGraphLabel(roundedAmounts.totalIncomeWithoutOneTimePayouts, i18n, showGrossAmount),
    description: getBarDescription(payoutType, i, i18n),
    accessibilityLabel: `${getBarDescription(payoutType, i, i18n).replace(
      '-',
      ' til '
    )} ${accessibilityPrettifier(getGraphLabel(i, i18n, showGrossAmount), i18n)}`,
    segments: [
      {
        color: theme.COLORS.GRAPH_PAYOUTS_VARIANT1,
        value: getGraphValue(
          getGrossAmountOrAmount(roundedAmounts.wealthAtPensionTotal, showGrossAmount)?.amount
        ),
      },
      {
        color: theme.COLORS.PRIMARY,
        value: getGraphValue(
          getGrossAmountOrAmount(roundedAmounts.offentligeYdelserTotal, showGrossAmount)?.amount
        ),
      },
      {
        color: theme.COLORS.GRAPH_PAYOUTS_VARIANT4,
        value: getGraphValue(
          getGrossAmountOrAmount(roundedAmounts.livsvarigUdbetalingTotal, showGrossAmount)?.amount
        ),
      },
      {
        color: theme.COLORS.GRAPH_PAYOUTS_VARIANT2,
        value: getGraphValue(
          getGrossAmountOrAmount(roundedAmounts.ophoerendeUdbetalingTotal, showGrossAmount)?.amount
        ),
      },
    ],
  };
};

export const getAveragePayoutBar = ({ theme, i18n, income, onSelect, averageBarColor }) => {
  const formattedIncome = income && formatMoney({ amount: income.amount }, true);
  return {
    testID: DASHBOARD_GRAPH_AVERAGE_PAYOUT_BAR,
    labelColor: theme.COLORS.DARK,
    descriptionColor: theme.COLORS.DARK,
    label: i18n.t('dashboard|amountPrMonth', {
      amount: formattedIncome,
    }),
    onSelect,
    description: i18n.t('dashboard|graphLabelAveragePayout'),
    segments: [
      {
        color: averageBarColor,
        value: getGraphValue(income?.amount || 0),
      },
    ],
    outerWidth: layoutSpecs.labelWidth,
    accessibilityLabel: i18n.t('dashboard|averagePayout', {
      formattedIncome,
    }),
  };
};

export const getIncomeBar = ({
  theme,
  i18n,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  showGrossAmount,
}) => {
  const hasExtraIncome = childAndYouthBenefitToday?.amount > 0;
  const income = hasExtraIncome
    ? getGrossAmountOrAmount(totalIncomeToday, showGrossAmount)
    : getGrossAmountOrAmount(incomeToday, showGrossAmount);
  const formattedIncome = income && formatMoney(income, true, false, false);

  return {
    labelColor: theme.COLORS.DARK,
    descriptionColor: theme.COLORS.DARK,
    label: i18n.t('dashboard|amountPrMonth', {
      amount: formattedIncome,
    }),
    description: hasExtraIncome
      ? i18n.t('dashboard|incomeTotalBar')
      : i18n.t('dashboard|incomeBar'),
    segments: [
      {
        color: theme.COLORS.GRAPH_INCOME,
        value: income?.amount || 0,
      },
    ],
    outerWidth: layoutSpecs.labelWidth,
    accessibilityLabel: i18n.t('dashboard|incomeBarA11y', {
      formattedIncome,
    }),
  };
};

export const getCenterContent = (payouts, labelWidth, pageWidth, maxGraphWidth, graphPadding) =>
  maxGraphWidth === layoutSpecs.pageMaxWidth + graphPadding;

export const hasOnetimePayouts = (payouts) => !!payouts?.oneTimePayment?.amount;

const hasOverTimePayoutsAmount = (year) =>
  year?.livsvarigUdbetalingTotal.amount > 0 ||
  year?.ophoerendeUdbetalingTotal.amount > 0 ||
  year?.offentligeYdelserTotal.amount > 0;

export const hasOverTimePayouts = (payouts, payoutType) => {
  if (payoutType === RETIREMENT) {
    return payouts?.perYear?.find(
      (year) => hasOverTimePayoutsAmount(year) || year?.wealthAtPensionTotal.amount > 0
    );
  }

  return payouts?.perYear?.find((year) => hasOverTimePayoutsAmount(year));
};
