"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_SET_STATE = exports.API_CANCEL_REQUEST_TIMEOUTS = exports.API_CANCEL_REQUESTS_BY_GROUP = exports.API_GET_SERVICE_URL = exports.API_EXTRACT_TOKEN = exports.API_LOCK_PERSISTENCE = exports.API_INJECT_IMPERSO_TOKEN = exports.API_EXCHANGED_AUTH_TOKEN = exports.API_DISCOVERY_RESULT = exports.API_AUTH_STATE_CHANGE = exports.API_SETUP_DISCOVERY = exports.API_EXCHANGE_AUTH_TOKEN = exports.API_LOGGED_OUT = exports.API_LOGOUT = exports.API_STORE_SESSION = exports.API_REMOVE_PERSISTENCE = exports.API_UNLOCK_PERSISTENCE = exports.API_POLL = exports.API_REQUEST = exports.API_SETUP = void 0;
exports.API_SETUP = '@@API/SETUP';
exports.API_REQUEST = '@@API/REQUEST';
exports.API_POLL = '@@API/POLL';
exports.API_UNLOCK_PERSISTENCE = '@@API/UNLOCK-PERSISTENCE';
exports.API_REMOVE_PERSISTENCE = '@@API/REMOVE-PERSISTENCE';
exports.API_STORE_SESSION = '@@API/STORE-SESSION';
exports.API_LOGOUT = '@@API/LOGOUT';
exports.API_LOGGED_OUT = '@@API/LOGGED_OUT';
exports.API_EXCHANGE_AUTH_TOKEN = '@@API/EXCHANGE-AUTH-TOKEN';
exports.API_SETUP_DISCOVERY = '@@API/SETUP-DISCOVERY';
exports.API_AUTH_STATE_CHANGE = '@@API/AUTH_STATE_CHANGE';
exports.API_DISCOVERY_RESULT = '@@API/DISCOVERY_RESULT';
exports.API_EXCHANGED_AUTH_TOKEN = '@@API/EXCHANGED_AUTH_TOKEN';
exports.API_INJECT_IMPERSO_TOKEN = '@@API/API_INJECT_IMPERSO_TOKEN';
exports.API_LOCK_PERSISTENCE = '@@API/LOCK_PERSISTENCE';
exports.API_EXTRACT_TOKEN = '@@API/EXTRACT_TOKEN';
exports.API_GET_SERVICE_URL = '@@API/GET_SERVICE_URL';
exports.API_CANCEL_REQUESTS_BY_GROUP = '@@API/CANCEL_REQUESTS_BY_GROUP';
exports.API_CANCEL_REQUEST_TIMEOUTS = '@@API/CANCEL_REQUEST_TIMEOUTS';
exports.API_SET_STATE = '@@API/SET_STATE';
