import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { H1, Body, Subtitle } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import { Button } from '@sp/ui/base/buttons';
import PageParts from '@sp/ui/PageParts';
import { ActionShape, Actions } from '@sp/ui/pages/BasePage';
import { isWeb } from '@sp/ui/helpers/device';
import LoadingContext from '@sp/ui/context/LoadingContext';

import {
  Wrapper,
  BackButton,
  MenuWrapper,
  Content,
  TitleWrapper,
  Image,
  BottomWrapper,
  ButtonWrapper,
  BottomContent,
} from './index.shared';

const WRAPPER_MAX_WIDTH = 1024;
const WRAPPER_MIN_HEIGHT = 550;
const BACK_BUTTON_HEIGHT = 69;

const ContentWrapper = styled.View`
  width: 100%;
  align-items: center;
  background: ${(props) => props.backgroundColor || props.theme.COLORS.PRIMARY_BACKGROUND};
  max-width: ${WRAPPER_MAX_WIDTH}px;
  ${(props) => (props.hasMinHeight ? `min-height: ${WRAPPER_MIN_HEIGHT}px;` : '')}
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  ${(props) => (props.overflow ? 'overflow-y: hidden' : '')};
`;

const BackButtonWrapper = styled.View`
  max-width: ${WRAPPER_MAX_WIDTH}px;
  width: 100%;
  height: ${BACK_BUTTON_HEIGHT}px;
`;

const BackgroundPage = ({
  sources,
  children,
  heading,
  description,
  theme,
  backgroundColor,
  actions,
  actionInfo,
  bottom,
  menu,
  backButton,
  hasBackButton,
  dark,
  hasMinHeight,
}) => {
  const textColor = dark ? theme.COLORS.PRIMARY_TEXT : theme.COLORS.SECONDARY_BACKGROUND;
  const { loader } = useContext(LoadingContext);

  return (
    <>
      {isWeb && menu && <MenuWrapper>{menu}</MenuWrapper>}

      <Wrapper>
        {loader}
        <PageParts>
          {({ BackButton: GlobalBackButton, notifications }) => (
            <>
              {notifications}
              <BackButtonWrapper>
                {hasBackButton && (
                  <BackButton>
                    {typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />}
                  </BackButton>
                )}
              </BackButtonWrapper>
            </>
          )}
        </PageParts>

        <ContentWrapper
          backgroundColor={backgroundColor}
          hasMinHeight={hasMinHeight}
          overflow={!!sources}
        >
          {sources && <Image mobile={sources.mobile} desktop={sources.desktop} />}

          <Content>
            {heading && (
              <TitleWrapper>
                <H1 color={textColor}>{heading}</H1>
              </TitleWrapper>
            )}
            {description && (
              <Body textAlign="center" color={textColor}>
                {description}
              </Body>
            )}
            {children}
          </Content>

          {hasMinHeight ? (
            <BottomWrapper>
              <BottomContent>{bottom}</BottomContent>
              {actionInfo && (
                <Margin marginBottom="md">
                  <Subtitle>{actionInfo}</Subtitle>
                </Margin>
              )}
              {actions &&
                actions.map((button, index) => (
                  <ButtonWrapper key={`button-${index}`}>
                    <Button {...button} />
                  </ButtonWrapper>
                ))}
            </BottomWrapper>
          ) : (
            <Fragment>
              <Actions actions={actions} actionInfo={actionInfo} />
              <Margin marginBottom="xl" />
            </Fragment>
          )}
        </ContentWrapper>
      </Wrapper>
    </>
  );
};

BackgroundPage.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sources: PropTypes.shape({
    mobile: PropTypes.any,
    desktop: PropTypes.any,
  }),
  theme: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  bottom: PropTypes.node,
  dark: PropTypes.bool,
  menu: PropTypes.node,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  hasMinHeight: PropTypes.bool,
};

BackgroundPage.defaultProps = {
  heading: undefined,
  description: undefined,
  sources: undefined,
  backgroundColor: undefined,
  children: undefined,
  actions: undefined,
  actionInfo: undefined,
  bottom: undefined,
  menu: undefined,
  backButton: undefined,
  hasBackButton: true,
  dark: false,
  hasMinHeight: true,
};

export default withTheme(BackgroundPage);
