import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Conditional from 'decorators/conditional';
import { RoundButton } from '../buttons';
import Graph from './Graph';
import { isWeb } from '@sp/ui/helpers/device';

const ARROW_POSITION = -46;
const EXTRA_WIDTH_WITH_NO_SCROLL = 10;
const ARROW_HEIGHT = 40;

const Arrow = styled.View`
  position: absolute;
  height: ${ARROW_HEIGHT}px;
  ${({ height }) => `top: ${height / 2}px;`}
  ${({ disabled }) => (disabled ? 'opacity: 0.2;' : 'opacity: 1;')}
  ${({ hidden }) => (hidden ? 'display: none;' : 'display: flex;')}
  z-index: 1;
`;

const LeftArrow = Conditional({
  desktop: styled(Arrow)`
    left: ${ARROW_POSITION}px;
  `,
  default: styled(Arrow)`
    left: ${({ theme }) => theme.SPACINGS.md}px;
  `,
});

const RightArrow = Conditional({
  desktop: styled(Arrow)`
    right: ${(props) => (props.showInGraph ? 0 : ARROW_POSITION)}px;}
  `,
  default: styled(Arrow)`
    right: ${({ theme }) => theme.SPACINGS.md}px;
  `,
});

const hasReachedEnd = (graphWidth, barOuterWidth, noOfBars, graphTotalWidth) => {
  if (graphTotalWidth) {
    return graphWidth + EXTRA_WIDTH_WITH_NO_SCROLL >= graphTotalWidth;
  }
  return graphWidth + EXTRA_WIDTH_WITH_NO_SCROLL >= barOuterWidth * noOfBars;
};

class Arrows extends Component {
  state = {
    curX: 0,
    barsLength: this.props.bars.length,
    hasReachedEnd: hasReachedEnd(
      this.props.width,
      this.props.barOuterWidth,
      this.props.bars.length,
      this.props.graphTotalWidth
    ),
  };

  static getDerivedStateFromProps(props, state) {
    if (props.bars.length !== state.barsLength) {
      return {
        barsLength: props.bars.length,
        hasReachedEnd: hasReachedEnd(
          props.width,
          props.barOuterWidth,
          props.bars.length,
          props.graphTotalWidth
        ),
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  handlePrevPress = () => {
    this.scrollView.scrollTo({ x: this.state.curX - this.props.width / 2, y: 0, animated: true });
  };

  handleNextPress = () => {
    this.scrollView.scrollTo({ x: this.state.curX + this.props.width / 2, y: 0, animated: true });
  };

  handleScroll(event) {
    const offSetX = event.nativeEvent.contentOffset.x;
    this.setState({
      curX: offSetX < 1 ? 0 : offSetX,
      hasReachedEnd:
        offSetX + event.nativeEvent.layoutMeasurement.width >= event.nativeEvent.contentSize.width,
    });
  }

  render() {
    const { props } = this;

    return (
      <>
        {isWeb && props.showControls && (
          <LeftArrow height={props.height}>
            <RoundButton
              accessibilityLabel={props.prevBarAccessibilityLabel}
              type="back"
              onPress={this.state.curX ? this.handlePrevPress : undefined}
              inactive={this.state.curX === 0}
            />
          </LeftArrow>
        )}

        <Graph
          {...props}
          getRef={(ref) => {
            this.scrollView = ref;
            return ref;
          }}
          onScroll={(e) => this.handleScroll(e)}
        />

        {isWeb && props.showControls && (
          <RightArrow height={props.height}>
            <RoundButton
              accessibilityLabel={props.nextBarAccessibilityLabel}
              type="next"
              onPress={this.state.hasReachedEnd ? undefined : this.handleNextPress}
              inactive={this.state.hasReachedEnd}
            />
          </RightArrow>
        )}
      </>
    );
  }
}

Arrows.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  graphTotalWidth: PropTypes.number,
  bars: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  barOuterWidth: PropTypes.number.isRequired,
  showControls: PropTypes.bool,
  nextBarAccessibilityLabel: PropTypes.string,
  prevBarAccessibilityLabel: PropTypes.string,
};

Arrows.defaultProps = {
  showControls: false,
  graphTotalWidth: undefined,
  nextBarAccessibilityLabel: undefined,
  prevBarAccessibilityLabel: undefined,
};

export default withTheme(Arrows);
