import { create } from '../helpers/reducers';
import { RESPONSE_CONSENT, TERMS_MODAL_VISIBLE } from 'constants/actionTypes';

const createDefaults = () => ({
  termsModalIsVisible: false,
  consentContent: undefined as any,
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    [RESPONSE_CONSENT]: (state, payload) => ({
      ...state,
      consentContent: payload.response.text,
    }),
    [TERMS_MODAL_VISIBLE]: (state, payload) => ({
      ...state,
      termsModalIsVisible: payload.isVisible,
      termsModalActiveKey: payload.key,
      consentContent: null,
    }),
  },
  createDefaults
);

export type { State };
