import {
  GET_CHILDREN,
  ADD_CHILD,
  DELETE_CHILD,
  UPDATE_CHILD,
  LINK_CHILDREN,
  UPDATE_LINKABLE_CHILD_TO_NOT_LINKABLE,
} from 'constants/actionTypes';
import type { ChildrenInformation } from 'reducers/dashboard';

const { get, put, post, remove } = require('actions/api');

type AddOrUpdateChildDataParams = {
  birth?: { date?: Pick<ChildrenInformation, 'birth'>['birth']['date'] };
  benefits?: Partial<Pick<ChildrenInformation, 'benefits'>['benefits']>;
  name?: Partial<Pick<ChildrenInformation, 'name'>['name']>;
  parentLegalRelationship?: Partial<
    Pick<ChildrenInformation, 'parentLegalRelationship'>['parentLegalRelationship']
  >;
};

export const getChildren = () => get(GET_CHILDREN, 'api', '/children/v1/children/me');

export const addChild = (childData: AddOrUpdateChildDataParams) =>
  post(ADD_CHILD, childData, 'api', '/children/v1/children');

export const editChild = (childData: AddOrUpdateChildDataParams, id?: string) =>
  put(UPDATE_CHILD, childData, 'api', `/children/v1/children/${id}`);

export const deleteChild = (id: string) =>
  remove(DELETE_CHILD, 'api', `/children/v1/children/${id}`);

export const hardDeleteAllChildren = () => remove({}, 'api', '/children/v1/children/me');

export const linkChildren = (childIds: string[]) =>
  put(LINK_CHILDREN, { childIds }, 'api', '/children/v1/children/links');

export const updateLinkableChildToNotLinkable = (childId: string) =>
  put(
    UPDATE_LINKABLE_CHILD_TO_NOT_LINKABLE,
    { id: childId },
    'api',
    `/children/v1/children/${childId}/links/lock`
  );
