import { useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useIsFocused, useNavigation, useNavigationState } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectFlowControl, selectIsMeetingPreparationFlow } from 'selectors/flowControl';
import useI18n from 'hooks/useI18n';
import * as routeNames from 'constants/routeNames';
import { RootState } from 'store';
import { updateCurrentProgress } from 'actions/flowControl';
import { selectGDPRConsent } from 'selectors/consents';
const { hasMissingConsents } = require('services/consents');

const FETCH_DATA_INDEX = 0;
const HOUSEHOLD_INDEX = 1;
const ASSETS_INDEX = 2;
const RECEIPT_INDEX = 3;

const getCurrentIndex = (currentRouteName: string) => {
  switch (currentRouteName) {
    case routeNames.MEETING_PREPARATION_INTRO:
      return -1;
    case routeNames.MEETING_PREPARATION_PENSIONS_INFORMATION:
    case routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION:
    case routeNames.FETCH_TAX_OR_PENSIONSINFO:
    case routeNames.FETCH_TAX_PENSIONSINFO_FAIL:
    case routeNames.FETCH_TAX_PENSIONSINFO_DONE:
      return FETCH_DATA_INDEX;
    case routeNames.MEETING_PREPARATION_ABOUT_YOU:
    case routeNames.MEETING_PREPARATION_CHILDREN:
    case routeNames.MEETING_PREPARATION_CHILD:
    case routeNames.MEETING_PREPARATION_PARTNER:
      return HOUSEHOLD_INDEX;
    case routeNames.MEETING_PREPARATION_ASSETS:
    case routeNames.MEETING_PREPARATION_ASSETS_HOUSING:
    case routeNames.MEETING_PREPARATION_ASSETS_LOANS:
    case routeNames.MEETING_PREPARATION_ASSETS_INVESTMENTS:
    case routeNames.MEETING_PREPARATION_ASSETS_SAVINGS:
      return ASSETS_INDEX;
    case routeNames.MEETING_PREPARATION_RECEIPT:
      return RECEIPT_INDEX;
    default:
      return FETCH_DATA_INDEX;
  }
};

const selectUseInitCurrentProgress = (state: RootState) => ({
  flowControl: selectFlowControl(state),
  isMeetingPreparationFlow: selectIsMeetingPreparationFlow(state),
  GDPRConsent: selectGDPRConsent(state),
});

const isRouteActive = (index: number, activeProgressIndexes?: number[]) => {
  return activeProgressIndexes?.some((el: number) => el === index) || activeProgressIndexes
    ? index <= Math.max(...activeProgressIndexes)
    : false;
};

export const useGetProgress = () => {
  const dispatch = useAppDispatch();
  const { flowControl, isMeetingPreparationFlow, GDPRConsent } =
    useAppSelector(selectUseInitCurrentProgress, shallowEqual) ?? {};
  const { activeProgressIndexes } = flowControl;
  const navigation = useNavigation();
  const i18n = useI18n(['meetingPreparation']);

  const routes = useNavigationState((state) => state.routes);
  const currentRoute = routes[routes.length - 1];
  const activeRouteIndex = getCurrentIndex(currentRoute.name);

  const isFocused = useIsFocused();

  const { items } = GDPRConsent;
  const missingConsents = hasMissingConsents(items) && GDPRConsent?.status === 'loaded';

  useEffect(() => {
    if (missingConsents) {
      navigation.navigate(routeNames.MEETING_PREPARATION, {
        screen: routeNames.CONSENTS,
      });
    }
  }, [missingConsents, navigation]);

  useEffect(() => {
    if (!missingConsents && isMeetingPreparationFlow && !flowControl.activeProgressIndexes) {
      dispatch(
        updateCurrentProgress({
          activeProgressIndexes: [FETCH_DATA_INDEX],
          showWarningModalWhenLogout: false,
          onSkipPension: routeNames.MEETING_PREPARATION_ABOUT_YOU,
          onPensionComplete: routeNames.MEETING_PREPARATION_ABOUT_YOU,
        })
      );
    }
  }, [dispatch, flowControl, isMeetingPreparationFlow, missingConsents]);

  useEffect(() => {
    if (
      !missingConsents &&
      isMeetingPreparationFlow &&
      isFocused &&
      !isRouteActive(activeRouteIndex, activeProgressIndexes)
    ) {
      if (!isRouteActive(activeRouteIndex, activeProgressIndexes)) {
        activeProgressIndexes?.push(activeRouteIndex);
      }
      dispatch(
        updateCurrentProgress({
          activeProgressIndexes: activeProgressIndexes,
          showWarningModalWhenLogout:
            activeRouteIndex !== FETCH_DATA_INDEX && activeRouteIndex !== RECEIPT_INDEX,
          onSkipPension: routeNames.MEETING_PREPARATION_ABOUT_YOU,
          onPensionComplete: routeNames.MEETING_PREPARATION_ABOUT_YOU,
        })
      );
    }
  }, [
    activeProgressIndexes,
    activeRouteIndex,
    dispatch,
    isFocused,
    isMeetingPreparationFlow,
    missingConsents,
  ]);

  return useCallback(
    () => [
      {
        title: i18n.t('meetingPreparation|getPensionsData'),
        isActive: isRouteActive(FETCH_DATA_INDEX, activeProgressIndexes),
        isSelected: activeRouteIndex === FETCH_DATA_INDEX,
        isPrevious: false,
        onPress: () => {
          navigation.navigate(routeNames.MEETING_PREPARATION, {
            screen: routeNames.MEETING_PREPARATION_PENSIONS_INFORMATION,
          });
        },
      },
      {
        title: i18n.t('meetingPreparation|progressAboutHousehold'),
        isActive: isRouteActive(HOUSEHOLD_INDEX, activeProgressIndexes),
        isSelected: activeRouteIndex === HOUSEHOLD_INDEX,
        isPrevious: false,
        onPress: () => {
          navigation.navigate(routeNames.MEETING_PREPARATION, {
            screen: routeNames.MEETING_PREPARATION_ABOUT_YOU,
          });
        },
      },
      {
        title: i18n.t('meetingPreparation|progressValues'),
        isActive: isRouteActive(ASSETS_INDEX, activeProgressIndexes),
        isSelected: activeRouteIndex === ASSETS_INDEX,
        isPrevious: false,
        onPress: () => {
          navigation.navigate(routeNames.MEETING_PREPARATION, {
            screen: routeNames.MEETING_PREPARATION_ASSETS,
          });
        },
      },
      {
        title: i18n.t('meetingPreparation|progressReceipt'),
        isActive: isRouteActive(RECEIPT_INDEX, activeProgressIndexes),
        isSelected: activeRouteIndex === RECEIPT_INDEX,
        isPrevious: false,
        onPress: () => {
          navigation.navigate(routeNames.MEETING_PREPARATION, {
            screen: routeNames.MEETING_PREPARATION_RECEIPT,
          });
        },
      },
    ],
    [i18n, activeProgressIndexes, activeRouteIndex, navigation]
  );
};
