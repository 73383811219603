import styled from 'styled-components/native';
import { Body } from '@sp/ui/typography';

export const Wrapper = styled.View`
  flex: 1;
  background: ${(props) => props.theme.COLORS.PRIMARY};
`;

export const Description = styled(Body)`
  font-size: 18px;
  line-height: 24px;
`;
