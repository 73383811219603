import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  BottomSheet as ReanimatedBottomSheet,
  scrollToPosition,
} from '@sampension/reanimated-animation-library';
import { CLOSE_BOTTOM_SHEET as CLOSE_BOTTOM_SHEET_TEST_ID } from '../../../../app/src/constants/testIds/dashboard/adjustCalculation';
import { isWeb, isIOS } from '@sp/ui/helpers/device';
import type { BottomSheetConfiguration } from '@sampension/reanimated-animation-library/src/types/index.d';

const { default: Icon } = require('@sp/ui/v2/base/Icon');
const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Actions } = require('@sp/ui/v2/pages/BasePage/Actions');
const { groupActions } = require('@sp/ui/v2/pages/BasePage/Actions/index.shared');
const { SPACINGS, COLORS, SCROLL_CONFIG } = require('@sp/ui/settings');

const CLOSE_WRAPPER_TOP_OFFSET = 24;
const CONTENT_RESIZE_HEIGHT_TRIGGER_ON_FOCUSED_INPUT_FIELD = 150;
const CONTENT_RESIZE_HEIGHT_ON_FOCUSED_INPUT_FIELD = 150;
const PRESSABLE_SAFE_AREA_TO_CONTENT = 16;
const MORPHING_ARROW_OFFSET_WEB = 25;
const BOTTOM_SHEET_HEADER_HEIGHT = 40;
const MAX_HEIGHT_RATIO_OF_WINDOWS_HEIGHT = 0.6;
const OPEN_BOTTOM_SHEET = false;
const IS_BOTTOM_SHEET_INACTIVE = false;
const INITIALIZE_BOTTOM_SHEET_AS_CLOSED = false;
const TAKE_UP_ALL_AVAILABLE_SPACE_WHEN_KEYBOARD_IS_VISIBLE = false;
const CLOSE_BOTTOM_SHEET = false;
const SNAP_POINT_BOTTOM = BOTTOM_SHEET_HEADER_HEIGHT;
const SNAP_POINT_BOTTOM_IOS = BOTTOM_SHEET_HEADER_HEIGHT + SPACINGS.md;
const AUTO_SCROLL_TRIGGER_LENGTH = SPACINGS.s;
const BACKGROUND_COLOR = COLORS.V2_SECONDARY_BACKGROUND;
const MORPHING_ARROW_OFFSET = isWeb ? MORPHING_ARROW_OFFSET_WEB : SPACINGS.lg;
const ACTIONS_WRAPPER_PADDING_IOS = `${SPACINGS.md}px 0px ${SPACINGS.xl}px 0px`;
const ACTIONS_WRAPPER_PADDING_IOS_NO_CONTENT = `0px 0px ${SPACINGS.xl}px 0px`;
const ACTIONS_WRAPPER_PADDING = `${SPACINGS.md}px 0px 0px 0px`;
const DEFAULT_SNAP_EFFECT_DIRECTION = { value: '' };
const HIDE_CONTENT_OR_FOOTER_SETTINGS = { isEnabled: !isWeb, offset: 5 };
const WEB_BOX_SHADOW = { offset: 10, opacity: 0.65 };
const MORPHING_ARROW_SETTINGS = {
  isEnabled: true,
  fill: COLORS.V2_SECONDARY_BACKGROUND_LEVEL_1,
  offset: MORPHING_ARROW_OFFSET,
};
const MORPHING_ARROW_DISABLED = {
  isEnabled: false,
};
const OUTER_SCROLL_EVENT = {
  scrollY: { value: 0 },
  autoScrollTriggerLength: AUTO_SCROLL_TRIGGER_LENGTH,
};

const ActionsWrapper = styled.View<{
  iPhoneBottomSpace: number;
  actionsContent: any[];
  hasFooterWarning?: boolean;
}>`
  background: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
  padding: ${({ iPhoneBottomSpace, actionsContent, hasFooterWarning }) => {
    const hasIPhoneBottomSpace = iPhoneBottomSpace > 0;

    if (!actionsContent || hasFooterWarning) {
      return isIOS && hasIPhoneBottomSpace ? ACTIONS_WRAPPER_PADDING_IOS_NO_CONTENT : '0px';
    }

    return isIOS && hasIPhoneBottomSpace ? ACTIONS_WRAPPER_PADDING_IOS : ACTIONS_WRAPPER_PADDING;
  }};
`;

const CloseIconWrapper = styled.View`
  position: absolute;
  right: 0px;
  top: ${CLOSE_WRAPPER_TOP_OFFSET}px;
`;

const FooterWarningWrapper = styled.View`
  background: ${({ theme }) => theme.COLORS.V2_SECONDARY_BACKGROUND};
`;
interface Props extends BottomSheetConfiguration {
  actions: any[];
  actionsContent: any;
  actionsInfo: any;
  scrollViewRef: React.RefObject<Animated.ScrollView>;
  maxHeightRatioOfWindowHeight: number;
  footerWarning?: React.ReactNode;
  openBottomSheet: boolean;
  closeBottomSheet: boolean;
  smoothAppearance?: {
    waitForContent: boolean;
    emptyContentHeight?: number;
  };
  testID?: string;
  contentResizeHeightOnFocusedInputField: number;
  takeUpAllAvailableSpaceWhenKeyboardIsVisible: boolean;
  headerHeight: number;
}

const BottomSheet: React.FC<Props> = ({
  actions,
  actionsContent,
  actionsInfo,
  scrollViewRef,
  testID,
  footerWarning,
  snapPointBottom,
  smoothAppearance,
  onLayoutRequest,
  isBottomSheetInactive = IS_BOTTOM_SHEET_INACTIVE,
  initializeBottomSheetAsClosed = INITIALIZE_BOTTOM_SHEET_AS_CLOSED,
  contentResizeHeightOnFocusedInputField = CONTENT_RESIZE_HEIGHT_ON_FOCUSED_INPUT_FIELD,
  takeUpAllAvailableSpaceWhenKeyboardIsVisible = TAKE_UP_ALL_AVAILABLE_SPACE_WHEN_KEYBOARD_IS_VISIBLE, // eslint-disable-line max-len
  hideContentOnCardCollapse = HIDE_CONTENT_OR_FOOTER_SETTINGS,
  hideFooterOnCardCollapse = HIDE_CONTENT_OR_FOOTER_SETTINGS,
  headerHeight = BOTTOM_SHEET_HEADER_HEIGHT,
  backgroundColor = BACKGROUND_COLOR,
  fadingScrollEdges = SCROLL_CONFIG.FADING_SCROLL_EDGES,
  maxHeightRatioOfWindowHeight = MAX_HEIGHT_RATIO_OF_WINDOWS_HEIGHT,
  morphingArrow = MORPHING_ARROW_SETTINGS,
  outerScrollEvent = OUTER_SCROLL_EVENT,
  scrollArrows = SCROLL_CONFIG.SCROLL_ARROWS,
  snapEffectDirection = DEFAULT_SNAP_EFFECT_DIRECTION,
  openBottomSheet = OPEN_BOTTOM_SHEET,
  closeBottomSheet = CLOSE_BOTTOM_SHEET,
}) => {
  const { bottom: bottomActions } = groupActions(actions);
  const { bottom: extraBottomSpace } = useSafeAreaInsets();
  const isIOSandHasBottomSpace = isIOS && extraBottomSpace !== 0;

  const snapPointBasedOnDevice = isIOSandHasBottomSpace ? SNAP_POINT_BOTTOM_IOS : SNAP_POINT_BOTTOM;
  const bottomSheetSnapPointBottom = snapPointBottom ?? snapPointBasedOnDevice;

  const openOrCloseBottomSheetCallback = useCallback(
    (cb) => {
      const { value } = outerScrollEvent?.scrollY ?? {};

      if (scrollViewRef && value && value > 0) {
        scrollToPosition({ ref: scrollViewRef, to: 'top' });
      }

      return cb();
    },
    [scrollViewRef, outerScrollEvent]
  );

  return (
    <ReanimatedBottomSheet
      testID={testID}
      pressableSafeAreaToContent={PRESSABLE_SAFE_AREA_TO_CONTENT}
      webBoxShadow={WEB_BOX_SHADOW}
      smoothAppearance={smoothAppearance}
      contentHeightWhenKeyboardIsVisible={{
        takeUpAllAvailableSpace: takeUpAllAvailableSpaceWhenKeyboardIsVisible,
        resizeHeightTrigger: CONTENT_RESIZE_HEIGHT_TRIGGER_ON_FOCUSED_INPUT_FIELD,
        resizeHeight: contentResizeHeightOnFocusedInputField,
        offset: isIOSandHasBottomSpace ? extraBottomSpace : 0,
        closeIcon: {
          topOffset: extraBottomSpace ? SPACINGS.md : SPACINGS.lg,
          rightOffset: SPACINGS.md,
          icon: () => (
            <CloseIconWrapper testID={CLOSE_BOTTOM_SHEET_TEST_ID}>
              <Icon name="close" fill={COLORS.V2_SECONDARY_TEXT} />
            </CloseIconWrapper>
          ),
        },
      }}
      hideContentOnCardCollapse={hideContentOnCardCollapse}
      hideFooterOnCardCollapse={hideFooterOnCardCollapse}
      isBottomSheetInactive={isBottomSheetInactive}
      initializeBottomSheetAsClosed={initializeBottomSheetAsClosed}
      snapPointBottom={bottomSheetSnapPointBottom}
      maxHeightRatio={maxHeightRatioOfWindowHeight}
      header={{ height: headerHeight }}
      fadingScrollEdges={fadingScrollEdges}
      scrollArrows={scrollArrows}
      backgroundColor={backgroundColor}
      morphingArrow={isBottomSheetInactive ? MORPHING_ARROW_DISABLED : morphingArrow}
      outerScrollEvent={{ ...OUTER_SCROLL_EVENT, ...outerScrollEvent }}
      snapEffectDirection={snapEffectDirection}
      contentComponent={actionsContent && <Margin horizontal="xl">{actionsContent}</Margin>}
      footerComponent={
        (bottomActions || footerWarning) && (
          <>
            {footerWarning && <FooterWarningWrapper>{footerWarning}</FooterWarningWrapper>}
            {bottomActions && (
              <ActionsWrapper
                iPhoneBottomSpace={extraBottomSpace}
                actionsContent={actionsContent}
                hasFooterWarning={!!footerWarning}
              >
                <Actions
                  transparent
                  disableShadow
                  disableMarginTop
                  hideActionsContent
                  actions={bottomActions}
                  actionsInfo={actionsInfo}
                />
              </ActionsWrapper>
            )}
          </>
        )
      }
      openBottomSheetRequest={{
        isEnabled: openBottomSheet,
        callback: openOrCloseBottomSheetCallback,
      }}
      closeBottomSheetRequest={{
        isEnabled: closeBottomSheet,
        callback: openOrCloseBottomSheetCallback,
      }}
      onLayoutRequest={(height): void => {
        if (typeof onLayoutRequest === 'function') {
          onLayoutRequest(height);
        }
      }}
    />
  );
};

BottomSheet.defaultProps = {
  footerWarning: undefined,
  smoothAppearance: undefined,
  testID: undefined,
};

export default BottomSheet;
