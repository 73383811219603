import { graphColors } from '@sp/ui/base/Graph/helpers';
import { formatMoney } from 'helpers/string';
import { getBarDescription, getGraphValue } from 'areas/dashboard/helpers';

const FADEOUT_VALUE = 250;

export const getDescriptionText = (
  retirementAge,
  payoutType,
  i18n,
  showGrossAmount,
  awardRules
) => {
  switch (payoutType) {
    case 'death':
      return showGrossAmount
        ? i18n.t('dashboard|payoutsAllDeathWithGrossDescription')
        : i18n.t('dashboard|payoutsAllDeathDescription');
    case 'disability':
      let disabilityGRADisclaimerText = awardRules?.descriptions?.disabilityGRADisclaimerText
        ? `${awardRules?.descriptions?.disabilityGRADisclaimerText}\r\n\r\n`
        : '';

      return showGrossAmount
        ? disabilityGRADisclaimerText + i18n.t('dashboard|payoutsAllDisabilityWithGrossDescription')
        : disabilityGRADisclaimerText + i18n.t('dashboard|payoutsAllDisabilityDescription');
    default:
      return showGrossAmount
        ? i18n.t('dashboard|payoutsAllRetirementWithGrossDescription', { retirementAge })
        : i18n.t('dashboard|payoutsAllRetirementDescription', { retirementAge });
  }
};

export const recalculateOneTimePayout = (oneTimePayout, maxValue, COLORS, showGrossAmount) => {
  const newPayout = {};
  newPayout.engangsudbetalingTotal = oneTimePayout.engangsudbetalingTotal;
  newPayout.age = oneTimePayout.age;
  newPayout.year = oneTimePayout.year;

  const amount = showGrossAmount
    ? newPayout.engangsudbetalingTotal.grossAmount
    : newPayout.engangsudbetalingTotal.amount;
  if (amount > maxValue) {
    newPayout.segments = [
      {
        value: maxValue - FADEOUT_VALUE * graphColors.length,
        color: COLORS.GRAPH_PAYOUTS_VARIANT5,
      },
    ];

    graphColors.forEach((color) =>
      newPayout.segments.push({
        value: FADEOUT_VALUE,
        color,
      })
    );
  }

  return newPayout;
};

export const getOneTimePayoutBar = (payout, maxValue, theme, payoutType, i18n, showGrossAmount) => {
  const calculatedBar = recalculateOneTimePayout(payout, maxValue, theme.COLORS, showGrossAmount);
  return {
    label: formatMoney(calculatedBar.engangsudbetalingTotal, true, showGrossAmount),
    labelColor: theme.COLORS.DARK,
    description: getBarDescription(payoutType, calculatedBar, i18n),
    descriptionColor: theme.COLORS.DARK,
    segments: calculatedBar.segments
      ? [...calculatedBar.segments]
      : [
          {
            value: showGrossAmount
              ? calculatedBar.engangsudbetalingTotal.grossAmount
              : calculatedBar.engangsudbetalingTotal.amount,
            color: theme.COLORS.GRAPH_PAYOUTS_VARIANT5,
          },
        ],
  };
};

export const reCalculateAverageValue = (incomeWhenRetired, maxValue) => {
  if (incomeWhenRetired < maxValue + FADEOUT_VALUE * graphColors.length) {
    return getGraphValue(incomeWhenRetired);
  }

  return getGraphValue(maxValue + (FADEOUT_VALUE * graphColors.length) / 2);
};
