import React, { Fragment, useMemo } from 'react';
import { Trans } from 'react-i18next';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import { useRoute, useNavigation } from '@react-navigation/native';
import withScreenContainer from 'decorators/withScreenContainer';
import * as routeNames from 'constants/routeNames';
import { openPoliceDocument as openPoliceDocumentAction } from 'actions/documents';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectAllSavingsData } from 'selectors/savings';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { H5, Subtitle } from '@sp/ui/typography';
import { TwoLineRow, RowBorder } from '@sp/ui/base/rows';
import { BackButton } from '@sp/ui/pages/BasePage';
import Link from '@sp/ui/v2/base/Link';
import Margin from '@sp/ui/base/Margin';
import PoliceInformationRow from 'areas/general/PoliceInformationRow';
import { getPolicyDetailsOverview } from './helpers/getPolicyDetailsOverview';
import { getSavingsAccessibility } from './helpers/getSavingsAccessibility';
import { VIEW_SAVINGS_DETAILS } from 'areas/savings/trackingIds';

const RowWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const Details = ({ savingsData, openLatestDepotAccount, isDesktop, i18n, theme }) => {
  const route = useRoute();
  const navigation = useNavigation();

  const { internalSavings } = savingsData ?? {};
  const { pID } = route?.params ?? {};

  const policyDetailsOverview = useMemo(
    () => getPolicyDetailsOverview(savingsData, pID, navigation, i18n),
    [savingsData, pID, navigation, i18n]
  );

  const linkToDepotAccountAccessibility = useMemo(
    () => getSavingsAccessibility({ components: ['LinkToDepotAccount'] }).LinkToDepotAccount,
    []
  );

  return (
    <ScrollPage
      title={i18n.t('savings|savings')}
      heading={isDesktop ? i18n.t('savings|savings') : undefined}
      backButton={
        <BackButton
          label={i18n.t('common|back')}
          onPress={() => navigation.navigate(routeNames.SAVINGS_OVERVIEW)}
        />
      }
    >
      <PoliceInformationRow
        text={`${
          internalSavings?.find(({ coverageNumber }) => coverageNumber === pID).title
        }: ${pID}`}
      />
      {policyDetailsOverview.map(({ accessibility, isIconTransparent, textLeft, ...rest }, i) => (
        <Fragment key={`${textLeft}_${i}`}>
          <TwoLineRow
            arrow={{ fill: isIconTransparent ? 'transparent' : theme.COLORS.GRAPH_INCOME }}
            textLeft={<H5>{textLeft}</H5>}
            {...accessibility}
            {...rest}
          />
          <RowBorder />
        </Fragment>
      ))}
      <Margin all="md">
        <RowWrapper>
          <Trans
            i18nKey="savings|depotAccountLink"
            components={{
              text: <Subtitle {...linkToDepotAccountAccessibility} />,
              linkText: <Subtitle fontWeight="bold" color={theme.COLORS.V2_SECONDARY_LINK} />,
              linkButton: (
                <Link
                  isDesktop={isDesktop}
                  color={theme.COLORS.V2_SECONDARY_LINK}
                  onPress={() => openLatestDepotAccount(pID)}
                />
              ),
            }}
          />
        </RowWrapper>
      </Margin>
    </ScrollPage>
  );
};

Details.propTypes = {
  savingsData: PropTypes.object,
  openLatestDepotAccount: PropTypes.func,
  navigateTo: PropTypes.func,
  route: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

Details.defaultProps = {
  openLatestDepotAccount: undefined,
  savingsData: undefined,
  navigateTo: undefined,
};

const mapStateToProps = (state) => ({
  savingsData: selectAllSavingsData(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default withTheme(
  withScreenContainer({
    mapStateToProps,
    i18ns: ['savings'],
    init: ({ action, getProps }) => {
      const { savingsData, dispatch, navigation, route } = getProps();
      const { pID } = route?.params ?? {};

      if (!pID || !savingsData) {
        navigation.replace(routeNames.SAVINGS_OVERVIEW);
      }

      return {
        openLatestDepotAccount: (policyId) => {
          action(
            async () => {
              await dispatch(
                openPoliceDocumentAction(policyId, 'openLatestDepotAccount', () =>
                  navigation.navigate(routeNames.SAVINGS_DETAILS)
                )
              );
            },
            {
              loader: true,
            }
          );
        },
      };
    },
    trackAs: VIEW_SAVINGS_DETAILS,
  })(Details)
);
