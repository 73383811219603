import { useCallback, useContext } from 'react';
import { withTiming, runOnJS, cancelAnimation } from 'react-native-reanimated';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import fetchTaxAndPensionsInfoController from '../fetchTaxAndPensionsInfoController';
import { useTrackFetchingStatus } from './useTrackFetchingStatus';

const SIMULATION_DURATION = 5000;
const SIMULATION_TIMER = 20;

export const useHandlePollingResponseCallback = () => {
  const { updateFetchingState, fetchingState, simulationClock } = useContext(
    FetchTaxAndPensionsInfoContext
  );

  const { getTerminationSituation } = fetchTaxAndPensionsInfoController;
  const trackFetchingStatus = useTrackFetchingStatus();

  return useCallback(
    ({ fetchingStatus, failType, stopPollingAndCompleteFetchingProcess }) => {
      const { isTryingToTerminateFetchingProcess, hasTerminatedFetchingProcess } =
        getTerminationSituation() ?? {};

      const stopCallbackFromHappening =
        isTryingToTerminateFetchingProcess || hasTerminatedFetchingProcess;

      if (fetchingStatus !== 'simulation') {
        trackFetchingStatus(fetchingStatus);

        updateFetchingState({
          ...fetchingState,
          fetchingController: fetchingStatus,
        });
      } else if (simulationClock.value !== SIMULATION_TIMER) {
        if (stopCallbackFromHappening) {
          cancelAnimation(simulationClock);
        } else if (simulationClock.value === 0) {
          simulationClock.value = withTiming(
            SIMULATION_TIMER,
            { duration: SIMULATION_DURATION },
            (isSimulationDone) => {
              if (isSimulationDone) {
                runOnJS(stopPollingAndCompleteFetchingProcess)();
              }
            }
          );
        }
      }

      /* NOTE: If the user has terminated the process then we ensure
      that no callback is being made. Otherwise a navigation would occour */
      if (!stopCallbackFromHappening && ['failed', 'success'].includes(fetchingStatus)) {
        stopPollingAndCompleteFetchingProcess(fetchingStatus === 'failed' ? failType : undefined);
      }
    },
    [
      fetchingState,
      simulationClock,
      updateFetchingState,
      getTerminationSituation,
      trackFetchingStatus,
    ]
  );
};
