import React, { useState, useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import usePrerenderScreen from 'hooks/usePrerenderScreen';
import Icon from '@sp/ui/v2/base/Icon';
import DeselectorRow from './DeselectorRow';
import {
  useGetPublicServiceBenefitRows,
  useUpdateFormBasedOnFolkPensionOrFolkSupplementChange,
} from './hooks';
import Page from '../components/Page';
import { useInitCalculationProfile, useUpdateCalculationProfile } from '../hooks';
import AdjustCalculationAlert from '../components/AdjustCalculationAlert';
import FooterWarning from '../components/FooterWarning';
import RowsWrapper from '../components/RowsWrapper';
import type { RootState } from 'store';
import {
  selectHasFolkPensionSupplementSideEffect,
  selectFormAdjustCalculationHasChanges,
  selectPublicServicesFormIsValid,
} from 'selectors/dashboardForms';
import {
  selectIsDeviceTypeDesktop,
  selectIsDeviceTypeTablet,
  selectIsDeviceTypeMobile,
} from 'selectors/device';

const { default: buttonPositions } = require('constants/buttonPositions');
const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Row } = require('@sp/ui/v2/base/rows');
const { BackButton } = require('@sp/ui/v2/pages/BasePage');
const { Body, Body2 } = require('@sp/ui/v2/typography');
const { clearForms: clearFormsAction } = require('actions/digital-advice');

const ICON_DIMENSIONS = 14;

const shouldOpenOrCloseBottomSheetInitialState = {
  shouldCloseBottomSheetIfOpen: false,
  shouldOpenBottomSheetIfClosed: false,
};

const selectPublicServices = (state: RootState) => ({
  isMobile: selectIsDeviceTypeMobile(state),
  isTablet: selectIsDeviceTypeTablet(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
  publicServicesFormHasChanges: selectFormAdjustCalculationHasChanges(state),
  publicServicesFormIsValid: selectPublicServicesFormIsValid(state),
  hasFolkPensionSupplementSideEffect: selectHasFolkPensionSupplementSideEffect(state),
});

const PublicServices: React.FC = () => {
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false);
  const [selectedPublicService, setSelectedPublicService] = useState<number>(-1);
  const [shouldOpenOrCloseBottomSheet, setShouldOpenOrCloseBottomSheet] = useState<
    Record<string, boolean>
  >(shouldOpenOrCloseBottomSheetInitialState);

  const { COLORS } = useTheme();
  const publicServiceBenefitRows = useGetPublicServiceBenefitRows();

  const { shouldCloseBottomSheetIfOpen, shouldOpenBottomSheetIfClosed } =
    shouldOpenOrCloseBottomSheet;
  const hasSelectedPublicService = selectedPublicService > -1;
  const { actionsContent } = publicServiceBenefitRows[selectedPublicService] ?? {};

  const { i18n } = useI18n(['dashboard']);

  const {
    isDesktop,
    isMobile,
    publicServicesFormHasChanges,
    publicServicesFormIsValid,
    hasFolkPensionSupplementSideEffect,
  } = useAppSelector((state) => selectPublicServices(state), shallowEqual);

  const navigation = useNavigation();
  const prerender = usePrerenderScreen();
  const dispatch = useAppDispatch();
  const [updateCalculationProfile] = useUpdateCalculationProfile(false);

  useInitCalculationProfile();

  const updateFormBasedOnFolkPensionOrFolkSupplementChange =
    useUpdateFormBasedOnFolkPensionOrFolkSupplementChange();

  const clearForms = useCallback(() => {
    dispatch(clearFormsAction());
  }, [dispatch]);

  const updateSelectedPublicService = useCallback(
    (service) => {
      setShouldOpenOrCloseBottomSheet({
        shouldCloseBottomSheetIfOpen: false,
        shouldOpenBottomSheetIfClosed: true,
      });

      clearForms();
      setSelectedPublicService(service);
    },
    [clearForms]
  );

  if (prerender) {
    return prerender;
  }

  return (
    <Page
      testID={testIds.ADJUST_CALCULATION_PUBLIC_SERVICES_SCREEN}
      keyboardAdjustResize={publicServiceBenefitRows[selectedPublicService]?.name === 'abroad'}
      isBottomSheetInactive={selectedPublicService === -1}
      initializeBottomSheetAsClosed={selectedPublicService === -1}
      shouldCloseBottomSheetIfOpen={shouldCloseBottomSheetIfOpen}
      shouldOpenBottomSheetIfClosed={shouldOpenBottomSheetIfClosed}
      heading={i18n.t('dashboard|publicServices')}
      ignoreDefaultVerticalPadding
      footerWarning={
        isMobile &&
        hasFolkPensionSupplementSideEffect && (
          <FooterWarning text={i18n.t('dashboard|hasFolkPensionSupplementSideEffect')} />
        )
      }
      actionsContent={
        actionsContent && (
          <>
            {actionsContent}
            {!isMobile && hasFolkPensionSupplementSideEffect && (
              <FooterWarning
                hasMargin={false}
                text={i18n.t('dashboard|hasFolkPensionSupplementSideEffect')}
              />
            )}
          </>
        )
      }
      backButton={
        <BackButton
          testID={testIds.ADJUST_CALCULATION_GO_BACK}
          label={i18n.t('back')}
          onPress={() =>
            publicServicesFormHasChanges ? setIsAlertModalVisible(true) : navigation.goBack()
          }
        />
      }
      actions={
        hasSelectedPublicService
          ? [
              {
                text: i18n.t('saveChange'),
                mobilePosition: buttonPositions.bottom,
                onPress: () => {
                  if (hasFolkPensionSupplementSideEffect) {
                    updateFormBasedOnFolkPensionOrFolkSupplementChange();
                  }

                  updateCalculationProfile();
                  clearForms();
                },
                inactive: !publicServicesFormHasChanges || !publicServicesFormIsValid,
              },
            ]
          : []
      }
    >
      <Margin marginBottom="sm" horizontal="md">
        <Body textAlign="center">{i18n.t('dashboard|whichPublicServicesToInclude')}</Body>
      </Margin>
      <Margin vertical="md">
        <RowsWrapper>
          <DeselectorRow
            hasSelectedPublicService={hasSelectedPublicService}
            setShouldOpenOrCloseBottomSheet={setShouldOpenOrCloseBottomSheet}
          />
          {publicServiceBenefitRows.map(({ testID, name, iconLeft, textLeft, textRight }, i) => (
            <Row
              testID={testID}
              key={`${name}_${i}`}
              onPress={() => updateSelectedPublicService(i)}
              hasBorderRadius={false}
              hasRowBorder={i !== publicServiceBenefitRows.length - 1}
              backgroundColor={isDesktop ? '' : COLORS.V2_SECONDARY_SELECTED}
              fill={COLORS.V2_PRIMARY_BACKGROUND}
              textLeft={textLeft}
              textRight={
                <Body2 fontWeight="normal" color={COLORS.V2_SECONDARY_TEXT}>
                  {textRight}
                </Body2>
              }
              componentLeft={<Icon fill={COLORS.V2_PRIMARY_BACKGROUND} name={iconLeft} />}
              componentRight={
                <Icon
                  name="arrowRightSmall"
                  fill={COLORS.V2_PRIMARY_BACKGROUND}
                  height={ICON_DIMENSIONS}
                  width={ICON_DIMENSIONS}
                />
              }
            />
          ))}
        </RowsWrapper>
      </Margin>
      <AdjustCalculationAlert
        isVisible={isAlertModalVisible}
        title={i18n.t('dashboard|keepChanges')}
        onRequestClose={() => setIsAlertModalVisible(false)}
        actions={[
          {
            text: i18n.t('yes'),
            style: 'primary',
            onPress: () => {
              clearForms();
              navigation.goBack();
            },
          },
          {
            text: i18n.t('no'),
            style: 'secondary',
            onPress: () => {
              if (hasSelectedPublicService) {
                setShouldOpenOrCloseBottomSheet({
                  shouldCloseBottomSheetIfOpen: false,
                  shouldOpenBottomSheetIfClosed: true,
                });
              }
            },
          },
        ]}
      />
    </Page>
  );
};

export default withScreenContainer({})(PublicServices);
