import React from 'react';
import { shallowEqual } from 'react-redux';
import useI18n from 'hooks/useI18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { selectHasDashboardData } from 'selectors/dashboard';
import useAsyncInit from 'hooks/useAsyncInit';
import usePrerenderScreen from 'hooks/usePrerenderScreen';
import { ADJUST_CALCULATION } from 'areas/dashboard/trackingIds';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import { useInitCalculationProfile } from '../hooks';
import Page from '../components/Page';
import type { RootState } from 'store';

const { default: Margin } = require('@sp/ui/base/Margin');
const { default: AgeSelector } = require('containers/dashboard/AgeSelector');
const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { Body } = require('@sp/ui/v2/typography');
const { selectIsDeviceTypeMobile } = require('selectors/device');
const { fetchRetirement } = require('actions/digital-advice');

export const selectAdjustCalculation = (state: RootState) => ({
  isMobile: selectIsDeviceTypeMobile(state),
  hasDashboardData: selectHasDashboardData(state),
});

const RetirementAge: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const dispatch = useAppDispatch();
  const { isMobile, hasDashboardData } = useAppSelector(selectAdjustCalculation, shallowEqual);
  useInitCalculationProfile();

  useAsyncInit(
    async () => {
      if (!hasDashboardData) {
        await dispatch(fetchRetirement());
      }
    },
    [],
    {
      error: {
        type: 'blocking',
        retry: true,
      },
    }
  );

  const prerender = usePrerenderScreen();

  if (prerender) {
    return prerender;
  }

  return (
    <Page
      testID={testIds.ADJUST_CALCULATION_RETIREMENT_AGE_SCREEN}
      heading={i18n.t('dashboard|retirementAgeTitle')}
      actionsContent={
        <>
          <Body secondaryColor textAlign="center">
            {i18n.t('dashboard|whenToRetire')}
          </Body>
          <Margin vertical={isMobile ? 'sm' : 'md'}>
            <AgeSelector
              testID={testIds.ADJUST_CALCULATION_RETIREMENT_AGE_SELECTOR}
              buttonTestIDS={{
                increment: testIds.ADJUST_CALCULATION_RETIREMENT_AGE_INCREMENT_BUTTON,
                decrement: testIds.ADJUST_CALCULATION_RETIREMENT_AGE_DECREMENT_BUTTON,
              }}
              withDebounce
              trackingParams={{ screen: ADJUST_CALCULATION }}
            />
          </Margin>
        </>
      }
    >
      <Body textAlign="left">{i18n.t('dashboard|retirementAgesDescription')}</Body>
    </Page>
  );
};

export default withScreenContainer({})(RetirementAge);
