import { create } from '../../helpers/reducers';

const createDefaults = () => ({
  status: 'ready',
  items: [] as any[],
});

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    REQUEST_CONSENTS: (state) => ({
      ...state,
      status: 'loading',
    }),
    RESPONSE_CONSENTS: (state, payload) => ({
      ...state,
      status: 'loaded',
      items: payload.response,
    }),
    RESPONSE_CONSENT: (state, payload) => ({
      ...state,
      items:
        state.items?.length > 0
          ? state.items.map((item) =>
              item.key === payload.response.key
                ? { ...payload.response, currentConsent: payload.response.consent }
                : item
            )
          : [payload.response],
    }),
    RESPONSE_GIVE_CONSENT: (state, payload) => ({
      ...state,
      items: state.items.map((c) => {
        if (c.key === payload.request.id) {
          c.currentConsent = 'granted';
        }
        return c;
      }),
    }),
    RESPONSE_RETRACT_CONSENT: (state, payload) => ({
      ...state,
      items: state.items.map((c) => {
        if (c.key === payload.request.id) {
          c.currentConsent = 'rejected';
        }
        return c;
      }),
    }),
  },
  createDefaults
);

export type { State };
