import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { useAppSelector } from 'hooks/redux';
import { selectFlowControl } from 'selectors/flowControl';
import { selectDeeplink } from 'selectors/deeplink';
import { FLOW_CONTROL_UNPREPARED, FLOW_CONTROL_PREPARED } from 'constants/flowControl';
import { usePrepareFlow } from './usePrepareFlow';
import { useNavigateToActionableScreen } from './useNavigateToActionableScreen';
import type { RootState } from 'store';

const selectUseInitFlowControl = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  flowControl: selectFlowControl(state),
});

export const useInitFlowControl = () => {
  const { deeplink, flowControl } = useAppSelector(selectUseInitFlowControl, shallowEqual) ?? {};
  const { name } = useRoute();

  const navigateToActionableScreen = useNavigateToActionableScreen();
  const [prepareFlow] = usePrepareFlow();

  const { isDeeplink, deeplinkFlow } = deeplink ?? {};
  const { status: flowControlStatus } = flowControl;
  const isRouteValid = isDeeplink
    ? deeplinkFlow === routeNames.PARTNER
    : name === routeNames.PARTNER;

  useEffect(() => {
    if (isRouteValid) {
      if (flowControlStatus === FLOW_CONTROL_UNPREPARED) {
        prepareFlow();
      } else if (flowControlStatus === FLOW_CONTROL_PREPARED) {
        navigateToActionableScreen();
      }
    }
  }, [flowControlStatus, isRouteValid, navigateToActionableScreen, prepareFlow]);
};
