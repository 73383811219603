import React from 'react';
import { formatMoney } from 'helpers/string';
import { H5 } from '@sp/ui/typography';
import { getPoliceTimestamp } from '../../helpers/getPoliceTimestamp';

export const getInternalPolicyTextProps = (item, savingsData, i18n) => {
  const { title, value, policyId, isInsurance } = item;

  const timestamp = getPoliceTimestamp(policyId, savingsData);

  const depositValueText = value && `${formatMoney(value, true)}kr.`;
  const hasInsuranceText = isInsurance && (
    <H5 textAlign="center" fontWeight="regular">
      {i18n.t('savings|Dette er en\nforsikring*')}
    </H5>
  );

  return {
    textLeft: title && <H5 numberOfLines={1}>{title}</H5>,
    textRight: hasInsuranceText || depositValueText,
    subtitleLeft: policyId && i18n.t('savings|Police: {{ policyId }}', { policyId }),
    subtitleRight:
      !isInsurance && value && timestamp && i18n.t('savings|pr. {{ timestamp }}', { timestamp }),
  };
};
