import React from 'react';
import PropTypes from 'prop-types';
import { initFunc } from 'helpers/props';
import Tracking from 'services/tracking';
import * as routeNames from 'constants/routeNames';
import TopTabBar from '@sp/ui/layout/TopTabBar';
import { withTheme } from 'styled-components/native';
import { useRoute } from '@react-navigation/native';
import withComponentContainer from 'decorators/withComponentContainer';
import navigateToPayoutsScreen from 'areas/dashboard/dashboards/navigateToPayoutsScreen';
import {
  selectHasDashboardData,
  selectIncomeWhenRetired,
  selectPayoutDisability,
  selectPayoutDeath,
} from 'selectors/dashboard';
import { fetchDisability, fetchRetirement } from 'actions/digital-advice';
import { RETIREMENT_TAB, DISABILITY_TAB, DEATH_TAB } from 'constants/testIds/dashboard';
import { DEATH, DISABILITY, RETIREMENT } from '../../../areas/dashboard/constants/dashboardTypes';

const TopTabBarContainer = ({
  focusedTab,
  theme,
  onPensionPress,
  onDisabilityPress,
  onDeathPress,
  i18n,
}) => {
  const route = useRoute();

  return (
    <TopTabBar
      tabs={[
        {
          testID: RETIREMENT_TAB,
          id: RETIREMENT,
          title: i18n.t('common|retirement'),
          onPress: () => onPensionPress(route),
          iconName: 'pig',
          backgroundColor: theme.COLORS.PRIMARY_OVERLAY_SOLID,
        },
        {
          testID: DISABILITY_TAB,
          id: DISABILITY,
          title: i18n.t('common|disability'),
          onPress: () => onDisabilityPress(route),
          iconName: 'asterisk',
          backgroundColor: theme.COLORS.PRIMARY_BACKGROUND,
        },
        {
          testID: DEATH_TAB,
          id: DEATH,
          onPress: () => onDeathPress(route),
          title: i18n.t('common|death'),
          iconName: 'heartbeat',
          backgroundColor: theme.COLORS.INACTIVE_GRAPH_INCOME,
        },
      ]}
      focusedTab={focusedTab}
    />
  );
};

TopTabBarContainer.defaultProps = {
  onPensionPress: initFunc,
  onDisabilityPress: initFunc,
  onDeathPress: initFunc,
};

TopTabBarContainer.propTypes = {
  focusedTab: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  onPensionPress: PropTypes.func,
  onDisabilityPress: PropTypes.func,
  onDeathPress: PropTypes.func,
  i18n: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  incomeWhenRetired: selectIncomeWhenRetired(state),
  hasDashboardDataRetirement: selectHasDashboardData(state, RETIREMENT),
  hasDashboardDataDisability: selectHasDashboardData(state, DISABILITY),
  hasDashboardDataDeath: selectHasDashboardData(state, DEATH),
  payoutDisability: selectPayoutDisability(state),
  payoutDeath: selectPayoutDeath(state),
});

export default withComponentContainer({
  mapStateToProps,
  i18ns: ['dashboard'],
  init: ({ action, getProps }) => ({
    onPensionPress: (route) =>
      action(
        async () => {
          const { hasDashboardDataRetirement, dispatch, navigation } = getProps();

          if (!hasDashboardDataRetirement) {
            await dispatch(fetchRetirement());
          }

          const { incomeWhenRetired } = getProps();

          Tracking.trackEvent({ area: route.name, action: 'ClickDetailedPayoutsTabPension' });
          navigateToPayoutsScreen({
            payouts: incomeWhenRetired,
            area: RETIREMENT,
            payoutsRoute: routeNames.DASHBOARD_PAYOUTS_RETIREMENT,
            navigation,
            animationDisabled: true,
          });
        },
        {
          loader: true,
        }
      ),
    onDisabilityPress: (route) =>
      action(
        async () => {
          const { hasDashboardDataDisability, dispatch, navigation } = getProps();

          if (!hasDashboardDataDisability) {
            await dispatch(fetchDisability());
          }

          const { payoutDisability } = getProps();

          Tracking.trackEvent({ area: route?.name, action: 'ClickDetailedPayoutsTabPension' });
          navigateToPayoutsScreen({
            payouts: payoutDisability,
            area: DISABILITY,
            payoutsRoute: routeNames.DASHBOARD_PAYOUTS_DISABILITY,
            navigation,
            animationDisabled: true,
          });
        },
        {
          loader: true,
        }
      ),
    onDeathPress: (route) =>
      action(
        async () => {
          const { hasDashboardDataDeath, dispatch, navigation } = getProps();

          if (!hasDashboardDataDeath) {
            await dispatch(fetchDisability());
          }
          const { payoutDeath } = getProps();

          Tracking.trackEvent({ area: route?.name, action: 'ClickDetailedPayoutsTabPension' });
          navigateToPayoutsScreen({
            payouts: payoutDeath,
            area: DEATH,
            payoutsRoute: routeNames.DASHBOARD_PAYOUTS_DEATH,
            navigation,
            animationDisabled: true,
          });
        },
        {
          loader: true,
        }
      ),
  }),
})(withTheme(TopTabBarContainer));
