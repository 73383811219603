import React from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import Animated from 'react-native-reanimated';
import styled, { withTheme } from 'styled-components/native';
import { isWeb } from '@sp/ui/helpers/device';
import { Body } from '@sp/ui/typography';
import Button from '@sp/ui/layout/Header/Button';
import Section from '@sp/ui/layout/Header/Section';
import ModalHeader from '@sp/ui/layout/Header/ModalHeader';
import { AccessibilitySummary } from '@sp/ui/pages/BasePage';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  TABLET_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  TABLET_MARGIN_HORIZONTAL,
  MOBILE_MARGIN_HORIZONTAL,
} from './index.shared';

const Content = Conditional({
  tablet: styled.View`
    align-items: center;
    justify-content: center;
    height: ${TABLET_HEADER_HEIGHT}px;
  `,
  mobile: styled.View`
    align-items: center;
    justify-content: center;
    height: ${MOBILE_HEADER_HEIGHT}px;
  `,
});

const LeftIcon = Conditional({
  tablet: styled.View`
    position: absolute;
    left: ${TABLET_MARGIN_HORIZONTAL / 2}px;
  `,
  mobile: styled.View`
    position: absolute;
    left: ${MOBILE_MARGIN_HORIZONTAL / 2}px;
  `,
});

const RightIcon = Conditional({
  tablet: styled.View`
    position: absolute;
    right: ${TABLET_MARGIN_HORIZONTAL / 2}px;
    flex-direction: row;
  `,
  mobile: styled.View`
    position: absolute;
    right: ${MOBILE_MARGIN_HORIZONTAL / 2}px;
    flex-direction: row;
  `,
});

const Wrapper = styled.View`
  background-color: ${(props) => props.theme.COLORS.PRIMARY};
  padding-top: ${({ extraTopOffset, theme }) => extraTopOffset - theme.SPACINGS.s}px;
  width: 100%;
`;

// NOTE: we hide the title on web with visibility so we still get the
// accessibility value but avoid rendering and animation
const Title = Animated.createAnimatedComponent(styled.View`
  width: ${(props) => (props.useLongTitle ? '70%' : '50%')};
  align-items: center;
  ${({ animateTitle }) => animateTitle && isWeb && 'visibility: hidden;'}
`);

const Header = ({
  leftContent,
  onPressBack,
  hasOnCloseButton,
  rightContent,
  title,
  animation,
  theme,
  accessibilitySummary,
  animateTitle,
}) => {
  const insets = useSafeAreaInsets();

  return (
    <Wrapper extraTopOffset={insets.top}>
      <Content>
        {leftContent && <LeftIcon>{leftContent}</LeftIcon>}

        {typeof leftContent === 'undefined' && (
          <LeftIcon>
            <Button icon="menuBack" onPress={onPressBack} />
          </LeftIcon>
        )}
        <AccessibilitySummary accessibilitySummary={accessibilitySummary}>
          <Title
            useLongTitle={!rightContent || rightContent.length === 0}
            style={animateTitle && animation ? animation : undefined}
            animateTitle={animateTitle}
          >
            <Body color={theme.COLORS.SECONDARY_BACKGROUND} numberOfLines={1}>
              {title}
            </Body>
          </Title>
        </AccessibilitySummary>

        {(rightContent || (rightContent && hasOnCloseButton)) && (
          <RightIcon>{rightContent}</RightIcon>
        )}
      </Content>
    </Wrapper>
  );
};

Header.propTypes = {
  leftContent: PropTypes.node,
  onPressBack: PropTypes.func,
  rightContent: PropTypes.node,
  accessibilitySummary: PropTypes.string,
  animation: PropTypes.object,
  title: PropTypes.string,
  hasOnCloseButton: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  animateTitle: PropTypes.bool,
};

Header.defaultProps = {
  leftContent: undefined,
  accessibilitySummary: undefined,
  onPressBack: null,
  rightContent: null,
  animation: undefined,
  animateTitle: false,
  hasOnCloseButton: false,
  title: undefined,
};

export { Button, Section, ModalHeader };

export default withTheme(
  Conditional({
    tablet: Header,
    mobile: Header,
  })
);
