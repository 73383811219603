const { get } = require('actions/api');
const {
  NAVIGATE_TO_HEALTH_BENEFITS,
  GET_HEALTH_BENEFITS,
  GET_HAS_HEALTH_BENEFITS,
} = require('constants/actionTypes');

export const getHealthBenefits = () => get(GET_HEALTH_BENEFITS, 'api', '/health-benefits/v2/plans');
export const getHasHealthBenefits = () =>
  get(GET_HAS_HEALTH_BENEFITS, 'api', '/health-benefits/v2/plans/check');

export const navigateToHealthBenefits = () => ({
  type: NAVIGATE_TO_HEALTH_BENEFITS,
});
