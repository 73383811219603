import {
  TRANSFER_SCHEME_PAYMENT,
  TRANSFER_JOB_CHANGE,
  TRANSFER_COVERAGE,
} from '../constants/selectionReferences';
import type { QuestionReferencesTypes } from 'areas/transfer/types';

type GetTransferQuestionsParams = {
  mustSpecifyIssuer?: boolean;
  mustSpecifyJobChange?: string;
  hasBasisCoverage?: boolean;
};

export const getTransferQuestions = ({
  mustSpecifyIssuer,
  mustSpecifyJobChange,
  hasBasisCoverage,
}: GetTransferQuestionsParams) => {
  const questions = [
    {
      questionReference: TRANSFER_SCHEME_PAYMENT,
      ignoreQuestion: !mustSpecifyIssuer,
    },
    {
      questionReference: TRANSFER_JOB_CHANGE,
      ignoreQuestion: mustSpecifyJobChange === 'No',
    },
    {
      questionReference: TRANSFER_COVERAGE,
      ignoreQuestion: !hasBasisCoverage,
    },
  ] as { ignoreQuestion: boolean; questionReference: QuestionReferencesTypes }[];

  return questions
    .filter(({ ignoreQuestion }) => !ignoreQuestion)
    .map(({ questionReference }) => questionReference);
};
