import { actionNames } from '@sampension/api-middleware';

interface Params {
  type: string;
  payload?: any;
  meta?: any;
  [name: string]: any;
}

interface Options {
  disableResetOnLogout?: boolean;
}

type Action<TState = any> = (state: TState, payload?: any, meta?: any, others?: any) => TState;

interface Actions<TState> {
  [name: string]: Action<TState>;
}

export const create =
  <TState = any>(
    actions: Actions<TState>,
    createDefaults: (...args: any[]) => TState,
    options: Options = {}
  ) =>
  (state = createDefaults(), params: Params = { type: 'unknown' }) => {
    const { type, payload, meta, ...others } = params;

    if (
      type === actionNames.API_AUTH_STATE_CHANGE &&
      payload.isLoggedIn === false &&
      !options.disableResetOnLogout
    ) {
      return createDefaults(payload.showLoginOnLogout);
    }
    if (actions[type]) {
      return actions[type](state, payload, meta, others);
    }
    return state;
  };

export type { Params, Options, Actions, Action };
