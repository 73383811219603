import { create } from 'helpers/reducers';
import {
  RESPONSE_UPDATE_PROFILE_INFO,
  RESPONSE_GET_PROFILE,
  RESPONSE_GET_USER_INFO,
  RESPONSE_GET_CONTACT_INFO,
  RESPONSE_PROFILE_COMPLETENESS,
  RESPONSE_GET_PENDING_VERIFICATIONS,
  SET_HAS_SEEN_UNVERIFIED_CONTACT_INFO_NOTIFICATION,
  RESPONSE_SAVE_FAMILY_SITUATION,
} from 'constants/actionTypes';

type ProfileMe = {
  customer: {
    companyGroups: string[];
    id: string;
    type: string;
  };
  person: {
    age?: number;
    birth: {
      date?: string;
      day?: number;
      month?: number;
      year?: number;
    };
    gender?: string;
    name: {
      first: string;
      formal: string;
      full: string;
      last: string;
      middle: string;
      title: string;
    };
    familySituation?: string;
  };
};

export type ContactInfo = {
  danishAddress?: {
    careOfName?: string;
    postalCode?: string;
    streetAddress?: string;
    postalDistrict?: string;
  };
  foreignAddress?: string;
  contactAddress?: string;
  isAddressProtected?: boolean;
  email?: string;
  phoneNumber?: string;
  countryCode?: string;
};

export type ProfileCompleteness = {
  info: {
    taxStatement: boolean;
    taxStatementUpdateDate: string;
    lastTaxStatementUpdateFailed: true;
    lastTaxStatementUpdateFailedDate: string;
    pensionInfo: boolean;
    pensionInfoUpdateDate: string;
    lastPensionInfoUpdateFailed: boolean;
    lastPensionInfoUpdateFailedDate: string;
    companiesThatDidNotDeliverFullPensionsInfo: string[];
    isTaxStatementOutdated: boolean;
    isPensionInfoOutdated: boolean;
    sampension: boolean;
    hasActiveDisabilityCoverage: boolean;
    hasActiveTemporaryDisabilityCoverage: boolean;
  };
};

interface State {
  profileData?: ProfileMe;
  contactInfo?: ContactInfo;
  verifications?: any;
  profileCompleteness?: ProfileCompleteness;
}

const createDefaults = (): State => ({
  profileData: undefined,
  contactInfo: {},
  verifications: {
    hasSeenUnverifiedContactInfoNotification: false,
  },
});

export default create(
  {
    [RESPONSE_GET_PROFILE]: (state, payload) => ({
      ...state,
      profileData: {
        ...state.profileData,
        ...payload.response,
      },
    }),
    [RESPONSE_PROFILE_COMPLETENESS]: (state, payload) => ({
      ...state,
      profileCompleteness: payload.response,
    }),
    [RESPONSE_UPDATE_PROFILE_INFO]: (state, payload) => ({
      ...state,
      profileData: {
        ...state.profileData,
        ...payload.response,
      },
    }),
    [RESPONSE_GET_USER_INFO]: (state, { response }) => {
      const { address } = response;

      const danishAddress = address ? JSON.parse(address) : {};
      const foreignAddress =
        response['urn:sp:address:foreign'] &&
        response['urn:sp:address:foreign'].split(/\n/).filter(Boolean);
      const contactAddress =
        response['urn:sp:address:contact'] &&
        response['urn:sp:address:contact'].split(/\n/).filter(Boolean);

      return {
        ...state,
        contactInfo: {
          danishAddress: {
            careOfName: danishAddress.careof_name,
            postalCode: danishAddress.postal_code,
            streetAddress: danishAddress.street_address,
            postalDistrict: danishAddress.postal_district,
          },
          foreignAddress,
          contactAddress,
          isAddressProtected: response['urn:sp:address:protection'] === 'Full',
        },
      };
    },
    [RESPONSE_GET_CONTACT_INFO]: (state, { response }) => {
      const { email, phoneNumber, countryCode } = response;

      return {
        ...state,
        contactInfo: {
          ...state?.contactInfo,
          email,
          phoneNumber,
          countryCode,
        },
      };
    },
    [RESPONSE_GET_PENDING_VERIFICATIONS]: (state, { response, request }) => {
      const phonePendingVerifications = response.data?.filter(
        (v: any) => v.type.toLowerCase() === 'phone'
      )[0];
      const emailPendingVerifications = response.data?.filter(
        (v: any) => v.type.toLowerCase() === 'email'
      )[0];

      return {
        ...state,
        verifications: {
          ...state.verifications,
          pending: {
            data: {
              phone: phonePendingVerifications,
              email: emailPendingVerifications,
            },
            fetchedOnStartup: request?.isStartupCall,
          },
        },
      };
    },
    [SET_HAS_SEEN_UNVERIFIED_CONTACT_INFO_NOTIFICATION]: (state) => ({
      ...state,
      verifications: {
        ...state.verifications,
        hasSeenUnverifiedContactInfoNotification: true,
      },
    }),
    [RESPONSE_SAVE_FAMILY_SITUATION]: (state, { request }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        person: {
          ...state.profileData.person,
          familySituation: request.familySituation,
        },
      },
    }),
  },
  createDefaults
);

export type { State, ProfileMe };
