import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { withTheme } from 'styled-components/native';
import Markdown, { getUniqueID, openUrl } from 'react-native-markdown-display';
import { H1, H2, H3, H5, H6, Link, Label, Body } from '@sp/ui/typography';

const rules = (markdownUrlHandler) => ({
  heading1: (node, children, parent, styles) => (
    <H1 key={getUniqueID()} style={[styles.heading, styles.heading1]}>
      {children}
    </H1>
  ),
  heading2: (node, children, parent, styles) => (
    <H2 key={getUniqueID()} style={[styles.heading, styles.heading2]}>
      {children}
    </H2>
  ),
  heading3: (node, children, parent, styles) => (
    <H3 key={getUniqueID()} style={[styles.heading, styles.heading3]}>
      {children}
    </H3>
  ),
  heading5: (node, children, parent, styles) => (
    <H5 key={getUniqueID()} style={[styles.heading, styles.heading5]}>
      {children}
    </H5>
  ),
  heading6: (node, children, parent, styles) => (
    <H6 key={getUniqueID()} style={[styles.heading, styles.heading6]}>
      {children}
    </H6>
  ),
  link: (node, children, parent, styles) => (
    <Link
      key={node.key}
      style={styles.link}
      onPress={() => {
        const url = node.attributes.href;

        if (markdownUrlHandler) {
          markdownUrlHandler(url, openUrl);
        } else {
          openUrl(url);
        }
      }}
    >
      {children}
    </Link>
  ),
  span: (node, children, parent, styles) => (
    <Label key={getUniqueID()} style={[styles.span, styles.span]}>
      {children}
    </Label>
  ),
  paragraph: (node, children, parent, styles) => (
    <Body key={node.key} style={styles.paragraph}>
      {children}
    </Body>
  ),
});

// NOTE: There is no merge of the style properties, if you specify a style property,
// it will completely overwrite existing styles for that property.
// We need to add custom styling for extra spacings and offests defined for markdown pages
const createStyles = (theme, customStyles) =>
  StyleSheet.create({
    heading1: {
      textAlign: 'center',
      marginBottom: theme.SPACINGS.lg,
    },
    heading2: {
      textAlign: 'center',
      marginBottom: theme.SPACINGS.lg,
    },
    heading3: {
      textAlign: 'center',
      marginBottom: theme.SPACINGS.lg,
    },
    heading5: {
      textAlign: 'center',
      fontSize: theme.FONT_SIZES.H5.MOBILE,
      marginBottom: theme.SPACINGS.md,
    },
    heading6: {
      textAlign: 'center',
      fontSize: theme.FONT_SIZES.H6.MOBILE,
      marginBottom: theme.SPACINGS.md,
    },
    link: {
      textDecorationLine: 'none',
    },
    paragraph: {
      marginBottom: theme.SPACINGS.md,
    },
    bullet_list: {
      marginBottom: theme.SPACINGS.md,
    },
    ordered_list: {
      marginBottom: theme.SPACINGS.md,
    },
    bullet_list_icon: {
      marginLeft: theme.SPACINGS.sm,
      marginRight: theme.SPACINGS.md,
      color: theme.COLORS.PRIMARY_TEXT,
    },
    ordered_list_icon: {
      marginLeft: theme.SPACINGS.sm,
      marginRight: theme.SPACINGS.md,
      color: theme.COLORS.PRIMARY_TEXT,
    },
    list_item: {
      flexDirection: 'row',
      marginBottom: -theme.SPACINGS.sm,
    },
    bullet_list_content: {
      flex: 1,
    },
    ordered_list_content: {
      flex: 1,
    },
    ...customStyles,
  });

const MarkdownComponent = ({ children, theme, styles, markdownUrlHandler }) => {
  const style = createStyles(theme, styles);

  // NOTE: defensive programming to prevent rendering with null
  if (!children) {
    return null;
  }

  return (
    <Markdown rules={rules(markdownUrlHandler)} style={style} mergeStyle={false}>
      {children}
    </Markdown>
  );
};

MarkdownComponent.propTypes = {
  children: PropTypes.string,
  theme: PropTypes.object.isRequired,
  markdownUrlHandler: PropTypes.func,
  styles: PropTypes.object,
};

MarkdownComponent.defaultProps = {
  children: undefined,
  markdownUrlHandler: undefined,
  styles: {},
};

export default withTheme(MarkdownComponent);
