import type { RootState } from 'store';
import {
  selectUserEnteredIncome,
  selectDisposableIncomeOptions,
  selectDisposableIncome,
  selectCalculationSettings,
  selectSelectedSalary,
} from 'selectors/dashboard';
const {
  currencyAmountValidator,
  currencyAmountWithZeroValidator,
} = require('@sp/ui/helpers/validators');

export const selectFormAdjustCalculation = (state: any) => state.forms.adjustCalculation;

export const selectFormAdjustCalculationHasChanges = (state: any) =>
  Object.keys(state.forms.adjustCalculation || {}).length > 0;

export const selectFormGrossAmount = (state: any) => state.forms.adjustGrossAmount || {};

export const selectFormGrossAmountHasChanges = (state: any) =>
  Object.keys(selectFormGrossAmount(state)).length > 0 &&
  selectFormGrossAmount(state).showGrossAmount !== selectDisposableIncome(state)?.showGrossAmount;

export const selectFormAdjustIncome = (state: any) => state.forms.adjustIncome || {};

export const selectFormAdjustIncomeHasChanges = (state: any) => {
  const incomeForm = selectFormAdjustIncome(state);
  const { enteredGrossIncome, enteredIncome, selectedIndex } = incomeForm;
  const userEnteredIncome = selectUserEnteredIncome(state);

  if (!enteredIncome && !enteredGrossIncome && selectedIndex === -1) {
    return false;
  }

  const isValueDifferent = (enteredValue?: string, previousValue?: number) =>
    enteredValue && parseInt(enteredValue, 10) !== previousValue;

  if (isValueDifferent(enteredIncome, userEnteredIncome?.amount)) {
    return true;
  }

  if (isValueDifferent(enteredGrossIncome, userEnteredIncome?.grossAmount)) {
    return true;
  }

  if (enteredGrossIncome === '' && userEnteredIncome?.grossAmount !== '' && selectedIndex === -1) {
    return true;
  }

  const isEmptyOrUndefined = (val?: string | number) => val === '' || val === undefined;

  if (
    isEmptyOrUndefined(enteredIncome) &&
    isEmptyOrUndefined(enteredGrossIncome) &&
    selectedIndex === -1
  ) {
    return true;
  }

  if (selectedIndex !== undefined && selectedIndex !== -1) {
    const incomeOptions = selectDisposableIncomeOptions(state);
    const selectedSalary = incomeOptions?.find(({ selected }) => selected);
    const initSelectedIndex = selectedSalary ? incomeOptions?.indexOf(selectedSalary) : -1;

    return selectedIndex !== initSelectedIndex;
  }

  return false;
};

export const selectIsIncomeChangedOrValid = (state: any) => {
  const { enteredIncome, enteredGrossIncome, selectedIndex } = selectFormAdjustIncome(state);
  const selectedSalary = selectSelectedSalary(state);
  const hasSelectedIndex = selectedIndex > -1;

  if (!selectedSalary && hasSelectedIndex) {
    return true;
  }

  const hasChanges = selectFormAdjustIncomeHasChanges(state);

  if (!hasChanges) {
    return false;
  }

  if (hasSelectedIndex) {
    return true;
  }

  if (
    (!enteredIncome && !currencyAmountValidator(enteredIncome)) ||
    (!enteredGrossIncome && !currencyAmountValidator(enteredGrossIncome))
  ) {
    return false;
  }

  if (enteredGrossIncome && enteredIncome) {
    return parseInt(enteredGrossIncome, 10) >= parseInt(enteredIncome, 10);
  }

  if (!enteredIncome && !enteredGrossIncome && !hasSelectedIndex) {
    return false;
  }
  return true;
};

const selectAdjustCalculationFormRetirement = (state: RootState) => ({
  settingsRetirementSection: selectCalculationSettings(state)?.retirement,
  formRetirementSection: selectFormAdjustCalculation(state)?.retirement,
});

const selectAdjustCalculationFormPublicBenefits = (state: RootState) => ({
  settingsPublicBenefitsSection: selectCalculationSettings(state)?.publicBenefits,
  formPublicBenefitsSection: selectFormAdjustCalculation(state)?.publicBenefits,
});

const selectAdjustCalculationFormTax = (state: RootState) => ({
  settingsTaxSection: selectCalculationSettings(state)?.tax,
  formTaxSection: selectFormAdjustCalculation(state)?.tax,
});

export const selectIsChurchContributor = (state: RootState) => {
  const { settingsTaxSection, formTaxSection } = selectAdjustCalculationFormTax(state);

  return formTaxSection?.isChurchContributor ?? settingsTaxSection?.isChurchContributor ?? false;
};

export const selectMunicipalCode = (state: RootState) => {
  const { settingsTaxSection, formTaxSection } = selectAdjustCalculationFormTax(state);

  return formTaxSection?.municipalCode ?? settingsTaxSection?.municipalCode;
};

export const selectRetirementWealth = (state: RootState) => {
  const { settingsRetirementSection, formRetirementSection } =
    selectAdjustCalculationFormRetirement(state);

  return formRetirementSection?.wealth ?? settingsRetirementSection?.wealth;
};

export const selectHasFolkPension = (state: RootState) => {
  const { settingsPublicBenefitsSection, formPublicBenefitsSection } =
    selectAdjustCalculationFormPublicBenefits(state);

  return (
    formPublicBenefitsSection?.hasFolkPension ??
    settingsPublicBenefitsSection?.hasFolkPension ??
    false
  );
};

export const selectHasFolkPensionSupplement = (state: RootState) => {
  const { settingsPublicBenefitsSection, formPublicBenefitsSection } =
    selectAdjustCalculationFormPublicBenefits(state);

  return (
    formPublicBenefitsSection?.hasFolkPensionSupplement ??
    settingsPublicBenefitsSection?.hasFolkPensionSupplement ??
    false
  );
};

export const selectResidencyYears = (state: RootState) => {
  const { settingsRetirementSection, formRetirementSection } =
    selectAdjustCalculationFormRetirement(state);

  return formRetirementSection?.residencyYears ?? settingsRetirementSection?.residencyYears;
};

// This can be removed once we figure out proper form state handling
export const selectResidencyYearsHack = (state: RootState) =>
  selectCalculationSettings(state)?.retirement.residencyYears;

export const selectHasElderCheque = (state: RootState) => {
  const { settingsPublicBenefitsSection, formPublicBenefitsSection } =
    selectAdjustCalculationFormPublicBenefits(state);

  return (
    formPublicBenefitsSection?.hasElderCheque ??
    settingsPublicBenefitsSection?.hasElderCheque ??
    false
  );
};

export const selectHasChildAndYouthBenefit = (state: RootState) => {
  const { settingsPublicBenefitsSection, formPublicBenefitsSection } =
    selectAdjustCalculationFormPublicBenefits(state);

  return (
    formPublicBenefitsSection?.hasChildAndYouthBenefit ??
    settingsPublicBenefitsSection?.hasChildAndYouthBenefit ??
    false
  );
};

// We need to check if the form is valid before allowing the user to save
export const selectPublicServicesFormIsValid = (state: RootState) => {
  const residencyYears = selectResidencyYears(state);
  return currencyAmountWithZeroValidator(residencyYears);
};

const selectAllPublicServiceBenefitsExceptLivingAbroad = (state: RootState) => {
  /* NOTE: Ensures that the values are based on settings instead
  of form values to avoid direct visualization of changes */
  const stateWithoutFormValues = { ...state, forms: {} };

  return {
    hasFolkPension: selectHasFolkPension(stateWithoutFormValues),
    hasFolkPensionSupplement: selectHasFolkPensionSupplement(stateWithoutFormValues),
    hasElderCheque: selectHasElderCheque(stateWithoutFormValues),
    hasChildAndYouthBenefit: selectHasChildAndYouthBenefit(stateWithoutFormValues),
  };
};

export const selectHasPartlySelectedPublicBenefits = (state: RootState) => {
  const allPublicBenefits = Object.values(selectAllPublicServiceBenefitsExceptLivingAbroad(state));

  return (
    allPublicBenefits.some((selected) => selected) &&
    allPublicBenefits.some((selected) => !selected)
  );
};

export const selectAllPublicServiceBenefitsDisabled = (state: RootState) => {
  const allPublicBenefits = selectAllPublicServiceBenefitsExceptLivingAbroad(state);
  return !Object.values(allPublicBenefits).some((val) => val);
};

export const selectHasGrossAmountWarning = (state: RootState) => {
  const { enteredIncome, enteredGrossIncome } = selectFormAdjustIncome(state) ?? {};

  return enteredIncome && !enteredGrossIncome;
};

export const selectHasFolkPensionSupplementSideEffect = (state: RootState) => {
  const hasFolkPension = selectHasFolkPension(state);
  const hasFolkPensionSupplement = selectHasFolkPensionSupplement(state);

  return !hasFolkPension && hasFolkPensionSupplement;
};
