import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { BodyInput } from '@sp/ui/typography';
import { isWeb } from '@sp/ui/helpers/device';
import Attachments from './Attachments';

const DEFAULT_TOP_MARGIN = 24;

const TEXT_HORIZONTAL_PADDING = 16;
const TEXT_INPUT_VERTICAL_OFFSET = 16;
const TEXT_INPUT_VERTICAL_MOBILE_OFFSET = 0;

const Wrapper = styled.View`
  margin-top: ${({ marginTop }) => marginTop}px;
  flex: ${({ flex }) => flex || 'none'};
  padding: 0 ${TEXT_HORIZONTAL_PADDING}px;
  ${({ border, hasError, theme }) =>
    border
      ? `
  border-color: ${hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_BORDER};
  border-width: ${StyleSheet.hairlineWidth}px;
  border-radius: ${theme.DEFAULTS.BORDER_RADIUS}px;
  `
      : ''}
  }
`;

// NOTE: using margin on web to make the textarea sizer render in the corner
const Input = styled(BodyInput)`
  ${isWeb
    ? `margin: ${TEXT_INPUT_VERTICAL_OFFSET}px 0px`
    : `padding: ${TEXT_INPUT_VERTICAL_MOBILE_OFFSET} 0px`};
  color: ${({ hasError, theme }) =>
    hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_TEXT};
  flex: ${({ flex }) => flex || 'none'};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px;` : '')};
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px;` : '')};
  ${({ multiline }) => (isWeb && multiline ? 'resize: vertical;' : '')}
`;

const TextMessage = (props) => {
  const {
    theme,
    marginTop,
    hasError,
    border,
    flex,
    onAttachmentDeletePress,
    deleteImageText,
    testID,
  } = props;

  const renderAttachments = (attachments) => (
    <Wrapper hasError={hasError} marginTop={marginTop} border={border} flex={flex}>
      <Input
        underlineColorAndroid="transparent"
        selectionColor={theme.COLORS.PRIMARY}
        placeholderTextColor={theme.COLORS.GRAPH_INCOME}
        {...props}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ textAlignVertical: 'top' }} // NOTE: Android only
        multiline
        testID={testID}
      />
      {attachments && (
        <Attachments
          deleteImageText={deleteImageText}
          attachments={attachments}
          onAttachmentDeletePress={onAttachmentDeletePress}
        />
      )}
    </Wrapper>
  );

  return renderAttachments(props.attachments);
};

TextMessage.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  marginTop: PropTypes.number,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  hasError: PropTypes.bool,
  border: PropTypes.bool,
  flex: PropTypes.number,
  theme: PropTypes.object.isRequired,
  onAttachmentDeletePress: PropTypes.func,
  deleteImageText: PropTypes.string,
  testID: PropTypes.string,
};

TextMessage.defaultProps = {
  marginTop: DEFAULT_TOP_MARGIN,
  minHeight: undefined,
  maxHeight: undefined,
  hasError: false,
  attachments: undefined,
  border: true,
  flex: undefined,
  onAttachmentDeletePress: undefined,
  deleteImageText: undefined,
};

export default withTheme(TextMessage);
