import React, { useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { compose } from 'redux';
import withTranslation from 'decorators/i18n';
import Modal from 'containers/layout/Modal';
import { SAVINGS_MODALS, SAVINGS_ABOUT_COSTS } from 'constants/routeNames';
import { formatMoney, numberWithSeperator } from 'helpers/string';
import { isWeb } from 'helpers/platform';
import Row from '@sp/ui/v2/base/rows';
import CollapsibleRow from '@sp/ui/v2/base/CollapsibleRow';
import { Body2, Subtitle } from '@sp/ui/v2/typography';
import Margin from '@sp/ui/base/Margin';
import OneLineRowWithContent from './OneLineRowWithContent';
import AboutCosts from '../modal/AboutCosts';

const RegularBody2 = ({ children }) => (
  <Body2 fontWeight="regular" secondaryColor>
    {children}
  </Body2>
);

const Wrapper = styled.View`
  flex: 1;
  flex-basis: 100%;
`;

const RowWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const TouchableOpacity = styled.TouchableOpacity``;

const CostsBrowser = ({ i18n, data, theme, isSeniorAndISP }) => {
  const [isAboutCostsModalOpen, setIsAboutCostsModalOpen] = useState(false);

  const navigation = useNavigation();
  const route = useRoute();

  const { annualPercentageRate, summary, directCosts, investmentCosts } = data;

  const DESCRIPTIONS = {
    WHAT_IS_AOP: i18n.t(
      'savings|Tallet (ÅOP) viser dine samlede omkostninger for et år som en procentdel af din ordnings værdi.'
    ),
    WHAT_IS_AOK: i18n.t(
      'savings|Tallet (ÅOK) viser dine årlige omkostninger i kroner. Administrationsomkostningerne betaler du direkte af din pensionsopsparing. Investeringsomkostningerne er indirekte omkostninger, der ikke trækkes på din pensionsordning, men istedet trækkes i dit afkast.'
    ),
    WHAT_IS_ADMINCOSTS_GENERAL: i18n.t(
      'savings|De omkostninger der betales for oprettelse og drift af din pensionsordning.'
    ),
    WHAT_IS_ADMINCOSTS_GROUP: i18n.t(
      'savings|De omkostninger der betales til Forenede Gruppeliv for dine gruppelivsforsikringer.'
    ),
    WHAT_IS_ADMINCOSTS_HEALTH: i18n.t(
      'savings|De omkostninger der betales for drift af din sunhedsordning.'
    ),
    WHAT_IS_ADMINCOSTS_LINK_BASE_FEE: i18n.t(
      'savings|De omkostninger der betales for administration af din Linkpension.'
    ),
    WHAT_IS_ADMINCOSTS_LINK_TRADING_FEE: i18n.t(
      'savings|De omkostninger der betales for hver handel i fondene i Linkpension.'
    ),
    WHAT_IS_ADMINCOSTS_LINK_SPREAD: i18n.t(
      'savings|Kursspread er et tillæg/fradrag i købs- og salgskursen, når der handles i Linkpension.'
    ),
    WHAT_IS_AVERAGERATE: i18n.t(
      'savings|De omkostninger, der er forbundet med at investere din opsparing i gennemsnitsrente. Omkostningerne er fratrukket i afkastet, inden depotrenten tilskrives din opsparing.'
    ),
    WHAT_IS_RISKRATE: i18n.t(
      'savings|Din depotrente reduceres med en risikoforrentning, som er betaling for, at basiskapitalen kan komme til at dække tab for at kunne opretholde din garanterede ydelse.'
    ),
    WHAT_IS_RISKRATE_ISP_SENIOR: i18n.t('savings|Omkostningen for rentegaranti i Seniorordningen.'),
    WHAT_IS_BONUSCAPITAL: i18n.t(
      'savings|De omkostninger, der er forbundet med at investere din Bonuskapital. Omkostningerne er fratrukket i afkastet, inden de tilskrives din Bonuskapital.'
    ),
    WHAT_IS_MARKETRATE: i18n.t(
      'savings|De omkostninger, der er forbundet med at investere din opsparing i markedsrente. Omkostningerne er fratrukket i afkastet, inden det tilskrives din opsparing.'
    ),
    WHAT_IS_LINK_COSTS_IN_FUNDS: i18n.t(
      'savings|De investeringsomkostninger der er afholdt af investeringsforeningerne i de forskellige fonde i din Linkpension. Omkostningerne er fratrukket i afkastet.'
    ),
    WHAT_IS_COSTS_RESULT: i18n.t(
      'savings|Over- og underskud på administrationsomkostninger bliver tilført kundernes fælleskasse. Her er beregnet din andel af et over- eller underskud i omkostningsregnskabet.'
    ),
    WHAT_IS_INSURANCE_RESULT: i18n.t(
      'savings|Over- og underskud på forsikringsregnskabet bliver tilført kundernes fælleskasse. Her er beregnet din andel af et over- eller underskud i risikoregnskabet.'
    ),
  };

  const {
    adminCosts,
    adminCostsGruppeliv,
    adminCostsHealth,
    linkBaseFee,
    linkTradingFee,
    linkSpread,
  } = directCosts;

  const {
    averageRate,
    riskPremium,
    bonusCapital,
    marketRate,
    contributionToEquity,
    linkCostsInFunds,
    costsResult,
    insuranceResult,
  } = investmentCosts;

  const { V2_SECONDARY_BACKGROUND } = theme.COLORS;

  return (
    <Wrapper>
      {annualPercentageRate > 0 && (
        <CollapsibleRow
          hasRowBorder
          backgroundColor={V2_SECONDARY_BACKGROUND}
          textLeft={i18n.t('savings|ÅOP')}
          textRight={`${numberWithSeperator(annualPercentageRate, 2)} %`}
        >
          <Margin all="md">
            <RegularBody2>{DESCRIPTIONS.WHAT_IS_AOP}</RegularBody2>
          </Margin>
        </CollapsibleRow>
      )}
      {summary.adminCosts > 0 && (
        <CollapsibleRow
          hasRowBorder
          backgroundColor={V2_SECONDARY_BACKGROUND}
          textLeft={i18n.t('savings|ÅOK')}
          textRight={`${formatMoney({ amount: summary.total }, true)}kr.`}
        >
          {summary.adminCosts > 0 && (
            <Row
              componentRight={<Margin marginRight="md" />}
              textLeft={<RegularBody2>{i18n.t('savings|Administration:')}</RegularBody2>}
              textRight={
                <RegularBody2>{`${formatMoney(
                  { amount: summary.adminCosts },
                  true
                )}kr.`}</RegularBody2>
              }
            />
          )}
          {summary.investmentCosts > 0 && (
            <Row
              componentRight={<Margin marginRight="md" />}
              textLeft={<RegularBody2>{i18n.t('savings|Investering m.m.:')}</RegularBody2>}
              textRight={
                <RegularBody2>{`${formatMoney(
                  { amount: summary.investmentCosts },
                  true
                )}kr.`}</RegularBody2>
              }
            />
          )}

          <Margin all="md">
            <RegularBody2>{DESCRIPTIONS.WHAT_IS_AOK}</RegularBody2>
          </Margin>
        </CollapsibleRow>
      )}
      <CollapsibleRow
        hasRowBorder
        backgroundColor={V2_SECONDARY_BACKGROUND}
        textLeft={i18n.t('savings|Se dine beregninger:')}
      >
        {directCosts.total > 0 && (
          <CollapsibleRow
            hasRowBorder
            backgroundColor={theme.COLORS.PRIMARY_VARIANT1}
            textLeft={<RegularBody2>{i18n.t('savings|Direkte omkostninger:')}</RegularBody2>}
            textRight={
              <RegularBody2>{`${formatMoney(
                { amount: directCosts.total },
                true
              )}kr.`}</RegularBody2>
            }
          >
            {adminCosts > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Administration:')}
                textRight={`${formatMoney({ amount: adminCosts }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_ADMINCOSTS_GENERAL}
              />
            )}
            {adminCostsGruppeliv > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Administration - gruppeliv:')}
                textRight={`${formatMoney({ amount: adminCostsGruppeliv }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_ADMINCOSTS_GROUP}
              />
            )}
            {adminCostsHealth > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Administration - sunhed:')}
                textRight={`${formatMoney({ amount: adminCostsHealth }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_ADMINCOSTS_HEALTH}
              />
            )}
            {linkBaseFee > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Grundgebyr (Linkpension):')}
                textRight={`${formatMoney({ amount: linkBaseFee }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_ADMINCOSTS_LINK_BASE_FEE}
              />
            )}
            {linkTradingFee > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Handelsomkostninger (Linkpension):')}
                textRight={`${formatMoney({ amount: linkTradingFee }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_ADMINCOSTS_LINK_TRADING_FEE}
              />
            )}
            {linkSpread > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Kursspread (Linkpension):')}
                textRight={`${formatMoney({ amount: linkSpread }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_ADMINCOSTS_LINK_SPREAD}
              />
            )}
          </CollapsibleRow>
        )}
        {investmentCosts.total > 0 && (
          <CollapsibleRow
            hasRowBorder
            backgroundColor={theme.COLORS.PRIMARY_VARIANT1}
            textLeft={<RegularBody2>{i18n.t('savings|Investeringsomkostninger:')}</RegularBody2>}
            textRight={
              <RegularBody2>{`${formatMoney(
                { amount: investmentCosts.total },
                true
              )}kr.`}</RegularBody2>
            }
          >
            {averageRate > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Gennemsnitsrente:')}
                textRight={`${formatMoney({ amount: averageRate }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_AVERAGERATE}
              />
            )}
            {riskPremium > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Risikoforrentning:')}
                textRight={`${formatMoney({ amount: riskPremium }, true)}kr.`}
                description={
                  isSeniorAndISP
                    ? DESCRIPTIONS.WHAT_IS_RISKRATE_ISP_SENIOR
                    : DESCRIPTIONS.WHAT_IS_RISKRATE
                }
              />
            )}
            {bonusCapital > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Bonuskapital:')}
                textRight={`${formatMoney({ amount: bonusCapital }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_BONUSCAPITAL}
              />
            )}
            {marketRate > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|I markedsrente:')}
                textRight={`${formatMoney({ amount: marketRate }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_MARKETRATE}
              />
            )}
            {contributionToEquity > 0 && (
              <Row
                arrow={{ fill: 'transparent' }}
                textLeft={i18n.t('savings|Bidrag til egenkapital:')}
                textRight={`${formatMoney({ amount: contributionToEquity }, true)}kr.`}
              />
            )}
            {linkCostsInFunds > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Investeringsomkostninger i fonde (Linkpension):')}
                textRight={`${formatMoney({ amount: linkCostsInFunds }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_LINK_COSTS_IN_FUNDS}
              />
            )}
            {costsResult > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Omkostningsresultat:')}
                textRight={`${formatMoney({ amount: costsResult }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_COSTS_RESULT}
              />
            )}
            {insuranceResult > 0 && (
              <OneLineRowWithContent
                textLeft={i18n.t('savings|Risikoresultat:')}
                textRight={`${formatMoney({ amount: insuranceResult }, true)}kr.`}
                description={DESCRIPTIONS.WHAT_IS_INSURANCE_RESULT}
              />
            )}
          </CollapsibleRow>
        )}
      </CollapsibleRow>
      <Margin all="md">
        <RowWrapper>
          <Trans
            i18nKey="savings|AboutCosts"
            defaults="<text>Læs mere om dine omkostninger </text><linkButton><linkText>her</linkText></linkButton><text>.</text>"
            components={{
              text: <Subtitle fontWeight="regular" secondaryColor />,
              linkText: <Subtitle fontWeight="regular" color={theme.COLORS.PRIMARY_LINK} />,
              linkButton: (
                <TouchableOpacity
                  hitSlop={theme.HIT_SLOP}
                  onPress={() =>
                    isWeb
                      ? setIsAboutCostsModalOpen(true)
                      : navigation.navigate(SAVINGS_MODALS, {
                          screen: SAVINGS_ABOUT_COSTS,
                          params: { pID: route?.params?.pID },
                        })
                  }
                />
              ),
            }}
          />
        </RowWrapper>
        <Modal
          visible={isAboutCostsModalOpen}
          onRequestClose={() => setIsAboutCostsModalOpen(false)}
        >
          <AboutCosts />
        </Modal>
        {isSeniorAndISP && (
          <Margin all="md">
            <Subtitle>
              {i18n.t(
                'Som medlem af seniorordningen betaler du 0,2% af dit depot årligt for din afkastgaranti. Disse indgår i dine direkte omkostninger.'
              )}
            </Subtitle>
          </Margin>
        )}
      </Margin>
    </Wrapper>
  );
};

RegularBody2.propTypes = {
  children: PropTypes.node.isRequired,
};

CostsBrowser.propTypes = {
  i18n: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isSeniorAndISP: PropTypes.bool.isRequired,
};

export default compose(withTranslation(), withTheme)(CostsBrowser);
