import React from 'react';
import PropTypes from 'prop-types';
import Card from '@sp/ui/base/Card';
import { Subtitle, Body } from '@sp/ui/typography';
import withTranslation from 'decorators/i18n';
import styled from 'styled-components/native';
import Margin from '@sp/ui/base/Margin';
import CardItem from './CardItem';
import {
  getPensionTitle,
  getTaxTitle,
  getFootNote,
  getButtons,
  getIconType,
  getDescription,
  getPensionSubtitle,
} from './helpers';
import { Container } from '../styles';

const WRAPPER_TEXT_WIDTH = 288;

const CenterText = styled.View`
  align-items: center;
`;

const TextWrapper = styled.View`
  max-width: ${WRAPPER_TEXT_WIDTH}px;
`;

const TaxStatementOrPensionStatementIsNotComplete = ({
  pensionStatus,
  i18n,
  pensionDate,
  taxDate,
  errorId,
  nakedDashboardErrors,
}) => {
  const footNote = getFootNote(taxDate, pensionDate, errorId, i18n);
  const infoTypeScenarios = errorId === 'Sc304';
  const showIntroText = errorId === 'Sc302';

  return (
    <Container>
      <Card
        type={infoTypeScenarios ? 'info' : 'warning'}
        buttonComponent={getButtons(i18n, errorId, nakedDashboardErrors)}
      >
        {showIntroText && (
          <Margin marginBottom="md">
            <Body>{i18n.t('dashboardErrors|errorScenarioSc302')}</Body>
          </Margin>
        )}
        <CardItem
          title={getPensionTitle(errorId, i18n)}
          description={getDescription(errorId, 'pension', i18n)}
          iconType={getIconType(errorId, 'pension')}
          subtitles={pensionStatus && getPensionSubtitle(errorId, pensionStatus, i18n)}
        />

        <CardItem
          title={getTaxTitle(errorId, i18n)}
          description={getDescription(errorId, 'tax', i18n)}
          iconType={getIconType(errorId, 'tax')}
          subtitles={[
            i18n.t('dashboardErrors|yourDisposableIncomeToday'),
            i18n.t('dashboardErrors|yourTaxMunicipality'),
            i18n.t('dashboardErrors|yourChurchTax'),
          ]}
          withoutMarginBottom={!footNote}
        />

        <CenterText>
          <TextWrapper>
            <Subtitle textAlign="center">{footNote}</Subtitle>
          </TextWrapper>
        </CenterText>
      </Card>
    </Container>
  );
};

TaxStatementOrPensionStatementIsNotComplete.propTypes = {
  pensionStatus: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  pensionDate: PropTypes.string,
  taxDate: PropTypes.string,
  errorId: PropTypes.string,
  nakedDashboardErrors: PropTypes.object,
};

TaxStatementOrPensionStatementIsNotComplete.defaultProps = {
  errorId: undefined,
  pensionDate: undefined,
  taxDate: undefined,
  nakedDashboardErrors: undefined,
};

export default withTranslation()(TaxStatementOrPensionStatementIsNotComplete);
