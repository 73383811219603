import React, { Component } from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import MobileLoader from 'containers/MobileLoader';
import GenericError from 'areas/system/GenericError';
import StartAppError from 'areas/system/StartAppError';
import Loader from 'containers/base/Loader';
import BlockingLoader from 'containers/base/BlockingLoader';
import log from 'services/log';
import i18n from 'services/i18n';

import { NotificationConsumer, NotificationProvider } from 'context/NotificationContext';
import { GlobalNotificationConsumer } from 'context/GlobalNotificationContext';

import NotificationProviderWrapper from 'containers/NotificationProviderWrapper';
import { LoadingProvider } from '@sp/ui/context/LoadingContext';
import ChildLoader from '@sp/ui/context/ChildLoader';

const loaders = {
  default: (props) => <Loader {...props} />,
  blocking: (props) => <BlockingLoader {...props} />,
};

const createWithInit =
  (
    load,
    overlayScreenProps,
    hasLocalNotifications = true,
    usesParentNotifications = false,
    isScreen = false
  ) =>
  (WrappedComponent) => {
    class WithInit extends Component {
      constructor(...props) {
        super(...props);
        this.action = this.action.bind(this); // TODO: add support for array of actions
        this.updateProps = this.updateProps.bind(this);
        this.getProps = this.getProps.bind(this);
        this.update = this.update.bind(this);
        this.setProps = this.setProps.bind(this);
      }

      state = {
        loading: false,
        loader: undefined,
        stateProps: {},
        error: undefined,
      };

      componentDidMount() {
        this.update();
      }

      async componentWillUnmount() {
        const { unload } = this.state.stateProps;
        const run = async () => {
          if (unload) {
            await unload({
              props: this.props,
              getProps: this.getProps,
            });
          }
        };
        await run();
      }

      getProps() {
        return {
          ...this.props,
          ...this.stateProps,
        };
      }

      setProps(props) {
        this.setState((prevState) => ({
          stateProps: {
            ...prevState.stateProps,
            ...props,
          },
        }));
      }

      handleError(err, errorConfig, fn, options) {
        if (err?.response?.status === 403) {
          errorConfig.message = i18n.t('common|accessErrorMsg');
        } else {
          log.error(errorConfig.message, err, () => this.action(fn, options));
        }

        const makeRetry = (callback) =>
          errorConfig.retry
            ? async () => {
                await this.setState(
                  {
                    error: undefined,
                  },
                  callback
                );
              }
            : undefined;

        if (this.props.showError && errorConfig.type === 'notification') {
          const notificationId = nanoid();

          let onErrorButtonPress;

          if (errorConfig.onPressButton) {
            onErrorButtonPress = () => this.action(errorConfig.onPressButton, options);
          } else if (errorConfig.retry) {
            onErrorButtonPress = makeRetry(() => {
              this.props.dismissNotification(notificationId);
              this.action(fn, options);
            });
          }

          const buttonText =
            errorConfig.buttonText || (errorConfig.retry ? i18n.t('common|tryAgain') : undefined);

          this.props.showError(errorConfig.message, {
            onPressButton: onErrorButtonPress,
            buttonText,
            id: notificationId,
            screenId: this.id,
          });
        } else {
          const retry = makeRetry(() => this.action(fn, options));

          this.setState({
            error: {
              ...errorConfig,
              retry,
            },
          });
        }
      }

      async action(fn, options = {}) {
        let output;

        const { loader = false, loaderProps = {}, error } = options;

        const errorConfig = {
          type: 'notification', // NOTE: can be 'notification' or 'overlay'
          retry: false,
          message: i18n.t('common|generalErrorMsg'),
          ...error,
          overlayScreenProps,
        };

        if (loader) {
          this.setState({
            loading: true,
            loader: loader === true ? 'default' : loader,
            loaderProps,
          });
        }

        try {
          output = await fn();
        } catch (err) {
          this.handleError(err, errorConfig, fn, options);
        }

        if (loader) {
          this.setState({
            loading: false,
            loader: undefined,
            loaderProps: undefined,
          });
        }

        if (errorConfig.retry && typeof output === 'object') {
          this.setProps(output);
        }

        return output;
      }

      updateProps(props) {
        const { stateProps } = this.stateProps;
        this.setState({
          stateProps: {
            ...stateProps,
            ...props,
          },
        });
      }

      async update() {
        const run = async () => {
          if (load) {
            const outerProps = await load({
              props: this.props,
              getProps: this.getProps,
              setProps: this.setProps,
              action: this.action,
            });

            const stateProps = typeof outerProps === 'function' ? await outerProps() : outerProps;
            this.setState((prevState) => ({
              stateProps: {
                ...prevState.stateProps,
                ...stateProps,
              },
            }));
          }
        };
        await run();
      }

      render() {
        const { loading, loader, stateProps, loaderProps, error } = this.state;
        if (error && error.type === 'overlay') {
          return <GenericError {...error.overlayScreenProps} retryFn={error.retry} />;
        } else if (error && error.type === 'startApp') {
          return <StartAppError {...error.overlayScreenProps} retryFn={error.retry} />;
        }

        const loadingElement = loader in loaders && loaders[loader](loaderProps);

        const element = (
          <>
            <ChildLoader>{loadingElement}</ChildLoader>
            <WrappedComponent {...this.props} {...stateProps} initLoading={loading} />
          </>
        );

        if (isScreen) {
          return (
            <LoadingProvider>
              <MobileLoader />
              {element}
            </LoadingProvider>
          );
        }
        return element;
      }
    }

    WithInit.propTypes = {
      navigation: PropTypes.object,
      showError: PropTypes.func,
      dismissNotification: PropTypes.func,
    };

    WithInit.defaultProps = {
      navigation: undefined,
      dismissNotification: undefined,
      showError: undefined,
    };

    return (props) => {
      const withInitWithNotifications = (
        <GlobalNotificationConsumer>
          {({ showError: showGlobalError, dismiss: dismissGlobalError }) => (
            <NotificationConsumer>
              {({ showError, dismiss, showNotification }) => (
                <WithInit
                  {...props}
                  showError={showError}
                  showNotification={showNotification}
                  dismissNotification={dismiss}
                  showGlobalError={showGlobalError}
                  dismissGlobalError={dismissGlobalError}
                />
              )}
            </NotificationConsumer>
          )}
        </GlobalNotificationConsumer>
      );

      if (usesParentNotifications) {
        return withInitWithNotifications;
      }

      return hasLocalNotifications ? (
        <NotificationProviderWrapper provider={NotificationProvider}>
          {withInitWithNotifications}
        </NotificationProviderWrapper>
      ) : (
        <WithInit {...props} />
      );
    };
  };

export default createWithInit;
