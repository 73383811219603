import React from 'react';
import styled from 'styled-components/native';
import AppearOpacityAnimation from '@sp/ui/v2/animations/AppearOpacityAnimation';

const ROW_WRAPPER_HEIGHT = 50;
const WRAPPER_PADDING = 12;

interface WizardProps {
  content: React.ReactNode[];
}

const RowWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${ROW_WRAPPER_HEIGHT}px;
`;

const Wrapper = styled.View`
  flex-direction: row;
  padding: ${WRAPPER_PADDING}px;
`;

const FlexWrapper = styled.View`
  flex: 1;
`;

/* NOTE: First item no padding */
const Wizard: React.FC<WizardProps> = ({ content }) => {
  return (
    <RowWrapper>
      <Wrapper>
        {content.map((question, i) => (
          <AppearOpacityAnimation index={i}>{question}</AppearOpacityAnimation>
        ))}
      </Wrapper>
      <FlexWrapper />
    </RowWrapper>
  );
};

export default Wizard;
