import { SET_FLOW, UPDATE_CURRENT_PROGRESS, UPDATE_FLOW_CONTROL } from 'constants/actionTypes';

export const updateCurrentProgress = (payload: object) => ({
  type: UPDATE_CURRENT_PROGRESS,
  payload,
});

export const updateFlowControl = (payload: object) => ({
  type: UPDATE_FLOW_CONTROL,
  payload,
});

export const setFlow = (payload: object) => ({
  type: SET_FLOW,
  payload,
});
