import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { COLORS } from '@sp/ui/settings';

const BOTTOM_BORDER_WIDTH = 1;
const BOTTOM_BORDER_WIDTH_SELECTED = 2;

const Border = styled.View`
  position: absolute;
  width: ${(props) => props.width}%;
  left: ${(props) => props.left || 0}px;
  bottom: 0px;
  border-style: solid;
  border-bottom-color: ${({ isSelected }) =>
    isSelected ? COLORS.PRIMARY : COLORS.PRIMARY_BACKGROUND};
  border-bottom-width: ${({ isSelected }) =>
    isSelected ? BOTTOM_BORDER_WIDTH_SELECTED : BOTTOM_BORDER_WIDTH}px;
`;

const getBorders = (width, selectedIndex, noOfBars) => {
  const bordersArray = new Array(noOfBars).fill(undefined);
  const borderWidth = width / noOfBars;
  const widthInPercent = (1 * 100) / noOfBars;

  let left = 0;
  return bordersArray.map((_, i) => {
    const offset = left;
    left += borderWidth;
    return <Border key={i} isSelected={selectedIndex === i} left={offset} width={widthInPercent} />;
  });
};

const SelectedBorder = ({ width, selectedIndex, noOfBars }) => (
  <>{getBorders(width, selectedIndex, noOfBars)}</>
);

SelectedBorder.propTypes = {
  width: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  noOfBars: PropTypes.number.isRequired,
};

export default SelectedBorder;
