import React, { useMemo } from 'react';
import useI18n from 'hooks/useI18n';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import PensionBasicAmountOrSupplement from '../PensionBasicAmountOrSupplement';
import LivedAbroad from '../LivedAbroad';
import ElderlyCheck from '../ElderlyCheck';
import ChildAndYouthBenefit from '../ChildAndYouthBenefit';
import {
  selectHasElderCheque,
  selectHasFolkPension,
  selectHasFolkPensionSupplement,
  selectResidencyYears,
  selectHasChildAndYouthBenefit,
} from 'selectors/dashboardForms';
import { RootState } from 'store';

export type PublicServiceBenefitRows = {
  testID: string;
  name: string;
  iconLeft: string;
  textLeft: string;
  actionsContent: React.ReactNode;
  textRight?: string;
  isDisabled?: boolean;
}[];

const selectPublicServiceRowsContent = (state: RootState) => {
  /* NOTE: Ensures that the values are based on settings instead
  of form values to avoid direct visualization of changes */
  const stateWithoutFormValues = { ...state, forms: {} };

  return {
    hasFolkPension: selectHasFolkPension(stateWithoutFormValues),
    hasFolkPensionSupplement: selectHasFolkPensionSupplement(stateWithoutFormValues),
    hasElderCheque: selectHasElderCheque(stateWithoutFormValues),
    residencyYears: selectResidencyYears(stateWithoutFormValues),
    hasChildAndYouthBenefit: selectHasChildAndYouthBenefit(stateWithoutFormValues),
  };
};

export const useGetPublicServiceBenefitRows = () => {
  const { i18n } = useI18n(['dashboard']);
  const {
    hasFolkPension,
    hasFolkPensionSupplement,
    hasElderCheque,
    residencyYears,
    hasChildAndYouthBenefit,
  } = useAppSelector(selectPublicServiceRowsContent, shallowEqual);

  return useMemo(
    () => [
      {
        testID: `${testIds.PUBLIC_SERVICES_PENSION_BASIC_AMOUNT}-${
          hasFolkPension ? 'enabled' : 'disabled'
        }`,
        name: 'pensionBasicAmount',
        iconLeft: 'chair',
        textLeft: i18n.t('dashboard|pensionBasicAmount'),
        textRight: hasFolkPension ? i18n.t('yes') : i18n.t('no'),
        actionsContent: (
          <PensionBasicAmountOrSupplement
            title={i18n.t('dashboard|pensionBasicAmount')}
            type="pensionBasicAmount"
          />
        ),
      },
      {
        testID: `${testIds.PUBLIC_SERVICES_PENSION_SUPPLEMENT}-${
          hasFolkPensionSupplement ? 'enabled' : 'disabled'
        }`,
        name: 'pensionSupplement',
        iconLeft: 'chair',
        textLeft: i18n.t('dashboard|pensionSupplement'),
        textRight: hasFolkPensionSupplement ? i18n.t('yes') : i18n.t('no'),
        actionsContent: (
          <PensionBasicAmountOrSupplement
            title={i18n.t('dashboard|pensionSupplement')}
            type="pensionSupplement"
          />
        ),
      },
      {
        testID: `${testIds.PUBLIC_SERVICES_ABROAD}-${residencyYears > 0 ? 'enabled' : 'disabled'}`,
        name: 'abroad',
        iconLeft: 'location',
        textLeft: i18n.t('dashboard|livingAbroad'),
        textRight: residencyYears > 0 ? i18n.t('yes') : i18n.t('no'),
        actionsContent: <LivedAbroad title={i18n.t('dashboard|livingAbroad')} />,
      },
      {
        testID: `${testIds.PUBLIC_SERVICES_EDERLY_CHECK}-${
          hasElderCheque ? 'enabled' : 'disabled'
        }`,
        name: 'elderlyCheck',
        iconLeft: 'description',
        textLeft: i18n.t('dashboard|seniorityCheck'),
        textRight: hasElderCheque ? i18n.t('yes') : i18n.t('no'),
        actionsContent: <ElderlyCheck title={i18n.t('dashboard|seniorityCheck')} />,
      },
      {
        testID: `${testIds.PUBLIC_SERVICES_CHILDREN_AND_YOUTH_SERVICES}-${
          hasChildAndYouthBenefit ? 'enabled' : 'disabled'
        }`,
        name: 'childrenAndYouthServices',
        iconLeft: 'children',
        textLeft: i18n.t('dashboard|childAndYouthBenefit'),
        textRight: hasChildAndYouthBenefit ? i18n.t('yes') : i18n.t('no'),
        actionsContent: <ChildAndYouthBenefit title={i18n.t('dashboard|childAndYouthBenefit')} />,
      },
    ],
    [
      hasFolkPension,
      hasFolkPensionSupplement,
      hasElderCheque,
      residencyYears,
      hasChildAndYouthBenefit,
      i18n,
    ]
  ) as PublicServiceBenefitRows;
};
