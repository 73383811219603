import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import BasePage, {
  Card,
  NarrowContent,
  Actions,
  ActionShape,
  TopContent,
} from '@sp/ui/pages/BasePage';
import PageParts from '@sp/ui/PageParts';
import { isWeb } from '@sp/ui/helpers/device';

const NO_BACK_BUTTON_OFFSET = 69;

const NoBackButtonOffset = styled.View`
  padding-top: ${NO_BACK_BUTTON_OFFSET}px;
`;

// FIX ME. Rounded border makes the background incorrect when a tabbar is added.
// remove rounded border in payouts
const View = styled.View`
  margin-bottom: -4px;
`;

const ScrollPage = ({
  title,
  heading,
  description,
  children,
  actions,
  actionInfo,
  backButton,
  hasBackButton,
  menu,
  disableTopPadding,
  disableBottomPadding,
  enableOverflow,
  testID,
  accessibilitySummary,
  animateTitle,
  tabBar,
}) => (
  <BasePage menu={isWeb && menu} testID={testID} accessibilitySummary={accessibilitySummary}>
    <PageParts>
      {({ BackButton: GlobalBackButton }) => (
        <NarrowContent>
          {!hasBackButton && <NoBackButtonOffset />}
          {hasBackButton && (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />)}
        </NarrowContent>
      )}
    </PageParts>
    <NarrowContent>
      {tabBar ? <View>{tabBar}</View> : null}
      <Card
        disableTopPadding={disableTopPadding}
        disableBottomPadding={disableBottomPadding}
        enableOverflow={enableOverflow}
      >
        {(heading || animateTitle || description) && (
          <TopContent heading={animateTitle ? title : heading} description={description} />
        )}
        {children}
        {actions && actions.length > 0 && <Actions actions={actions} actionInfo={actionInfo} />}
      </Card>
    </NarrowContent>
  </BasePage>
);

ScrollPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  backButton: PropTypes.node,
  menu: PropTypes.node,
  hasBackButton: PropTypes.bool,
  disableTopPadding: PropTypes.bool,
  disableBottomPadding: PropTypes.bool,
  enableOverflow: PropTypes.bool,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  animateTitle: PropTypes.bool,
  tabBar: PropTypes.node,
};

ScrollPage.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  children: undefined,
  actions: undefined,
  actionInfo: undefined,
  menu: undefined,
  backButton: undefined,
  hasBackButton: true,
  disableTopPadding: false,
  disableBottomPadding: false,
  enableOverflow: false,
  testID: undefined,
  accessibilitySummary: undefined,
  animateTitle: false,
  tabBar: undefined,
};

export default ScrollPage;
