import React from 'react';
import PropTypes from 'prop-types';
import Conditional from 'decorators/conditional';
import styled from 'styled-components/native';
import { Button } from '@sp/ui/base/buttons';
import { H5 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import Margin from '@sp/ui/base/Margin';
import Feature from 'containers/base/Feature';
import useI18n from 'hooks/useI18n';

const ActionsWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const AttachmentWrapper = styled.TouchableOpacity`
  flex-direction: row;
`;

const SendButtons = ({ send, isButtonInactive, onAttachmentPress }) => {
  const { i18n } = useI18n();

  return (
    <ActionsWrapper>
      <Feature name="mailboxAddAttachments">
        <AttachmentWrapper onPress={onAttachmentPress}>
          <H5 fontWeight="regular">{i18n.t('common|addAttachment')}</H5>
          <Margin marginRight="sm" />
          <Icon name="attachment" />
        </AttachmentWrapper>
      </Feature>
      <Margin marginRight="xxl" />
      <Button text={i18n.t('common|send')} narrow inactive={isButtonInactive} onPress={send} />
    </ActionsWrapper>
  );
};

SendButtons.propTypes = {
  send: PropTypes.func.isRequired,
  isButtonInactive: PropTypes.bool.isRequired,
  onAttachmentPress: PropTypes.func.isRequired,
};

export default Conditional({
  desktop: SendButtons,
});
