/* eslint-disable react/prop-types */
import React from 'react';
import { formatMoney, getGrossAmountOrAmount } from 'helpers/string';
import { getBarColor } from 'areas/dashboard/helpers';
import i18n from 'services/i18n';
import * as routeNames from 'constants/routeNames';
import Content from 'areas/dashboard/Content';
import getIncomeBar from '../getIncomeBar';
import getMenuItems from '../getMenuItems';
import Description from './DisabilityDescription';

const BAR_PLACEHOLDER_HEIGHT = 0.66;

export default ({
  navigation,
  payoutDisability,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  theme,
  awardRules,
  hasChildrenPayouts,
  awardRulesSucceeded,
  noRecommendationType,
  showGrossAmount,
}) => {
  /* TODO: Reintroduce logic when link children is available
  const checkForLinkableChildren = useCheckForLinkableChildren();
  */

  const menuItems = getMenuItems({
    navigation,
    payouts: payoutDisability,
    area: 'disability',
    payoutsRoute: routeNames.DASHBOARD_PAYOUTS_DISABILITY,
    payoutsOriginRoute: routeNames.PAYOUTS_ORIGIN,
    awardRulesRoute: routeNames.AWARD_RULES_DISABILITY,
    checkForLinkableChildren: false, // TODO: Insert checkForLinkableChildren here
    childrenPayoutsRoute: hasChildrenPayouts ? routeNames.PAYOUTS_CHILDREN : undefined,
  });

  const payout = getGrossAmountOrAmount(payoutDisability, showGrossAmount);

  return [
    getIncomeBar({
      incomeToday,
      totalIncomeToday,
      childAndYouthBenefitToday,
      i18n,
      theme,
      testIDPrefix: 'disability',
      showGrossAmount,
      menuItems,
    }),
    {
      id: '1',
      testID: 'disabilityBar',
      bar: payoutDisability
        ? {
            amount: formatMoney(payout, true),
            value: payout.amount,
            description: i18n.t('dashboard|payoutsDisability'),
            color: getBarColor(
              payoutDisability.pensionVsTodayRatio,
              'disability',
              noRecommendationType
            ),
            trackId: 'PayoutDisability',
          }
        : {
            centerLabel: '?',
            amount: '',
            value: incomeToday && incomeToday.amount * BAR_PLACEHOLDER_HEIGHT,
            description: i18n.t('dashboard|payoutsDisability'),
            color: theme.COLORS.GRAPH_NEUTRAL,
          },
      content: {
        component: (
          <Content
            testID="disabilityContent"
            description={
              <Description
                navigation={navigation}
                payoutDisability={payoutDisability}
                awardRules={awardRules}
                awardRulesSucceeded={awardRulesSucceeded}
                incomeToday={incomeToday}
                noRecommendationType={noRecommendationType}
              />
            }
            menuItems={menuItems}
          />
        ),
      },
    },
  ];
};
