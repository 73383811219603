import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Animated, Platform } from 'react-native';

const ANIMATION_DURATION = 300;

const isNative = Platform.OS !== 'web';

class AnimatedItem extends Component {
  state = {
    opacityValue: new Animated.Value(0),
    translateYValue: new Animated.Value(-100),
  };

  componentDidMount = () => {
    this.show();
  };

  show = () => {
    const { opacityValue, translateYValue } = this.state;
    Animated.parallel([
      Animated.timing(opacityValue, {
        toValue: 1,
        duration: ANIMATION_DURATION,
        useNativeDriver: isNative,
      }),
      Animated.timing(translateYValue, {
        toValue: 0,
        duration: ANIMATION_DURATION,
        useNativeDriver: isNative,
      }),
    ]).start();
  };

  render() {
    const { opacityValue, translateYValue } = this.state;
    const animatedStyle = {
      opacity: opacityValue,
      transform: [{ translateY: translateYValue }],
    };

    return <Animated.View style={animatedStyle}>{this.props.children}</Animated.View>;
  }
}

AnimatedItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnimatedItem;
