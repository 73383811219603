import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';
import { H5, Subtitle } from '@sp/ui/typography';

const ICON_SIZE = 64;
const CONTENT_PADDING = 12;
const BORDER_COLOR = '#e3e3e3';

const Wrapper = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
`;

const IconContainer = styled.View`
  width: ${ICON_SIZE}px;
  min-height: ${ICON_SIZE}px;
  background-color: ${(props) => props.color};
  border-top-left-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  border-bottom-left-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
`;

const IconLeft = styled.View`
  margin: auto;
`;

const Content = styled.View`
  flex: 1;
  margin-right: auto;
  padding: ${CONTENT_PADDING}px;
  border-color: ${BORDER_COLOR};
  border-width: 1px;
  border-top-right-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  border-bottom-right-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
`;

const getIcon = (type) => {
  switch (type) {
    case 'attachment':
      return 'attachment';
    case 'unsigned':
      return 'signature';
    case 'inProgress':
      return 'awaiting';
    default:
      return 'checkmark';
  }
};

const getBackgroundColor = (type, COLORS) => {
  switch (type) {
    case 'attachment':
      return COLORS.PRIMARY_LINK;
    case 'unsigned':
      return COLORS.PRIMARY_ALERT;
    case 'inProgress':
      return COLORS.GRAPH_INCOME;
    default:
      return COLORS.PRIMARY_SUCCESS;
  }
};

const DocumentButton = ({ date, name, onPress, type, theme, accessibilityLabel }) => {
  // NOTE: If using just <TouchableOpacity disabled /> text is unselectable on web
  const TouchableOpacityOrView = typeof onPress === 'function' ? TouchableOpacity : View;
  return (
    <TouchableOpacityOrView onPress={onPress} accessibilityLabel={accessibilityLabel}>
      <Wrapper>
        <IconContainer color={getBackgroundColor(type, theme.COLORS)}>
          <IconLeft>
            <Icon fill={theme.COLORS.SECONDARY_BACKGROUND} name={getIcon(type)} />
          </IconLeft>
        </IconContainer>
        <Content>
          <H5 numberOfLines={1}>{name}</H5>
          {date && <Subtitle>{date}</Subtitle>}
        </Content>
      </Wrapper>
    </TouchableOpacityOrView>
  );
};

DocumentButton.propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  theme: PropTypes.object.isRequired,
  accessibilityLabel: PropTypes.string,
};

DocumentButton.defaultProps = {
  onPress: undefined,
  accessibilityLabel: undefined,
};

export default withTheme(DocumentButton);
