import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'react-redux';
import * as routeNames from 'constants/routeNames';
import Menu from '@sp/ui/layout/Menu';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { createOrRemoveDeeplinkPersistedStorage } from 'hooks/useHandleDeeplink/helpers';
import Tracking from 'services/tracking';
import { useNavigation } from '@react-navigation/native';
import { logout as logoutAction } from 'actions/security';
import withComponentContainer from 'decorators/withComponentContainer';
import { getProfileLabel } from 'areas/profile/helpers';
import { selectUserName, selectCustomerType } from 'selectors/profile';
import { selectBlockMessage, selectInitialized } from 'selectors/apiStatus';
import { selectIsLoggedIn } from 'selectors/api';
import { selectDeeplink } from 'selectors/deeplink';
import { selectShowWarningModalWhenLogout } from 'selectors/flowControl';
import { isWeb, isAndroid } from 'helpers/platform';
import { updateDeeplink } from 'actions/deeplink';

const selectMenuContainer = (state) => ({
  deeplink: selectDeeplink(state),
  showWarningModalWhenLogout: selectShowWarningModalWhenLogout(state),
  isLoggedIn: selectIsLoggedIn(state),
  initialized: selectInitialized(state),
  userName: selectUserName(state),
  customerType: selectCustomerType(state),
  blockMessage: selectBlockMessage(state),
});

const MenuContainer = ({ menuItemsFactory, logout, i18n, showMenuItems, hideLogo }) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const {
    deeplink,
    showWarningModalWhenLogout,
    isLoggedIn,
    initialized,
    userName,
    customerType,
    blockMessage,
  } = useAppSelector(selectMenuContainer, shallowEqual);

  const menuItems = initialized ? menuItemsFactory(navigation) : [];
  const onboarding = customerType !== 'Customer';

  /* NOTE: If the flow is deeplink we disallow clicking on the username
  as there is no navigation support for entering the user profile area */
  const { isDeeplink } = deeplink ?? {};
  const hasUserProfile = !isDeeplink;

  const onPressLogout = useCallback(async () => {
    if (isDeeplink) {
      await createOrRemoveDeeplinkPersistedStorage({ remove: true });

      if (showWarningModalWhenLogout) {
        dispatch(updateDeeplink({ isDeeplinkModalOpen: true }));
        return;
      }
    }

    Tracking.trackEvent({ area: 'Header', action: 'ClickLogout' });
    logout();
  }, [isDeeplink, logout, dispatch, showWarningModalWhenLogout]);

  const onPressUserName = useCallback(() => {
    Tracking.trackEvent({ area: 'Header', action: 'ClickProfile' });
    navigation.navigate(routeNames.MORE, { screen: routeNames.PROFILE });
  }, [navigation]);

  return (
    <Menu
      menuItems={showMenuItems ? menuItems : undefined}
      showProfile={(isLoggedIn && !onboarding && !blockMessage) || isDeeplink}
      showLogoutButton={isLoggedIn}
      onPressLogout={onPressLogout}
      userName={getProfileLabel(userName, i18n)}
      onPressUserName={hasUserProfile ? onPressUserName : undefined}
      logOutLabel={i18n.t('logOut')}
      hideLogo={hideLogo}
    />
  );
};
MenuContainer.propTypes = {
  showMenuItems: PropTypes.bool,
  menuItemsFactory: PropTypes.func,
  logout: PropTypes.func,
  i18n: PropTypes.object.isRequired,
};

MenuContainer.defaultProps = {
  menuItemsFactory: () => {},
  logout: undefined,
  showMenuItems: true,
};

const init = ({ action, props }) =>
  action(async () => {
    const { dispatch } = props;

    return {
      logout: async () => {
        await dispatch(logoutAction(isWeb || isAndroid ? 'hard' : 'soft'));
      },
    };
  });

export default withComponentContainer({
  needsLogin: false,
  init,
})(MenuContainer);
