import * as routeNames from 'constants/routeNames';
import { dismiss } from 'helpers/navigation';

export const getDisplayAddress = (
  danishAddress,
  foreignAddress,
  contactAddress,
  isAddressProtected,
  i18n
) => {
  if (isAddressProtected) return i18n.t('Beskyttet');
  if (danishAddress && danishAddress.streetAddress) return danishAddress.streetAddress;
  if (Array.isArray(foreignAddress)) return foreignAddress[0];
  if (Array.isArray(contactAddress)) return contactAddress[0];
  return '';
};

export const getDeleteAction = (text, value, pendingValue, setModal) =>
  value || pendingValue
    ? [
        {
          text,
          type: 'destructive',
          mobilePosition: 'bottom-sticky',
          onPress: () => setModal(true),
        },
      ]
    : [];

export const getProfileLabel = (username, i18n) =>
  !username || username === 'Pseudonym' ? i18n.t('Profil') : username;

export const getDisplayPhoneNumber = ({ phoneNumber, countryCode }) =>
  countryCode && phoneNumber ? `${countryCode} ${phoneNumber}` : phoneNumber;

export const isPhoneNumberEmpty = (fullPhoneNumber) => {
  if (!fullPhoneNumber) return true;
  if (!fullPhoneNumber.startsWith('+')) return false;

  return fullPhoneNumber.split(' ').filter(Boolean).length === 1;
};

export const handleValidationErrors = (showError, i18n, e) => {
  if (e?.response?.status === 400) {
    showError(i18n.t('Den indtastede kode kunne ikke genkendes. Prøv venligst igen.'));
  } else if (e?.response?.status === 404) {
    throw Error('No token was found for the user for that type');
  } else if (e?.response?.status === 409) {
    showError(
      i18n.t(
        'Den indtastede kode er udløbet. Få tilsendt en ny kode ved at trykke på knappen ‘Send kode igen’.'
      )
    );
  }
};

export const destructPhoneNumber = (fullPhoneNumber) => {
  if (!fullPhoneNumber) return {};
  const [countryCode, phoneNumber] = fullPhoneNumber.split(' ');

  if (!phoneNumber && !countryCode.startsWith('+')) {
    return {
      countryCode: '+45',
      phoneNumber: countryCode,
    };
  }

  if (!phoneNumber && countryCode.startsWith('+')) {
    return {};
  }

  return {
    countryCode,
    phoneNumber,
  };
};

export const preparePhoneNumberForAPI = (fullPhoneNumber) => {
  const { countryCode, phoneNumber } = destructPhoneNumber(fullPhoneNumber);

  return {
    countryCode,
    number: phoneNumber,
  };
};

export const navigateToProfileModal = (navigation) =>
  navigation.navigate(routeNames.PROFILE_OVERVIEW_MODAL, {
    isModal: true,
  });

export const navigateToProfile = (navigation) =>
  navigation.navigate(routeNames.MORE, {
    screen: routeNames.PROFILE,
  });

export const navigateToContactInfoIfNecessary = (
  isContactInfoUpdated,
  navigation,
  formName,
  shouldDismiss = true
) => {
  if (shouldDismiss) {
    dismiss(navigation)();
  }

  if (isContactInfoUpdated) {
    navigation.navigate(routeNames.CONTACT_INFO_UPDATE, {
      isModal: true,
      formName,
    });
  }
};
