import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { NAKED_DASHBOARD_ERROR } from 'constants/testIds/dashboard';
import GraphPage from '@sp/ui/pages/GraphPage';
import Margin from '@sp/ui/base/Margin';
import {
  selectDataStatus,
  selectError,
  selectPensionsInfoDataUpdateDate,
  selectTaxDataUpdateDate,
  selectFailedCoverageOverview,
} from 'selectors/dashboard';
import { selectProfileCompletenessInfo } from 'selectors/profile';
import { getProfileCompleteness } from 'actions/digital-advice';
import Graphs from 'areas/dashboard/Graphs';
import TopTabBar from 'containers/dashboard/TopTabBar';
import * as routeNames from 'constants/routeNames';
import { isWeb } from 'helpers/platform';
import { formatDate } from 'helpers/string';
import ErrorCard from './naked/ErrorCard';
import { RETIREMENT } from '../constants/dashboardTypes';
// fix datetimepicker for children, must import module here
import DateTimePickerModal from 'react-native-modal-datetime-picker';

const getDummyBarIncome = (theme, i18n) => ({
  id: 2,
  bar: {
    value: 55,
    description: i18n.t('dashboard|incomeBar'),
    color: theme.COLORS.INACTIVE_GRAPH_INCOME,
  },
});

const dummyGraphDataRetirement = (theme, i18n) => [
  getDummyBarIncome(theme, i18n),
  {
    id: 3,
    bar: {
      value: 45,
      description: i18n.t('dashboardErrors|recommendAgeBarDescription'),
      color: theme.COLORS.INACTIVE_GRAPH_SUCCESS,
    },
  },
  {
    id: 1,
    bar: {
      value: 32,
      description: i18n.t('dashboardErrors|wantedAgeBarDescription'),
      color: theme.COLORS.INACTIVE_PRIMARY_ALERT,
    },
  },
];

const dummyGraphDataDisability = (theme, i18n) => [
  getDummyBarIncome(theme, i18n),
  {
    id: 1,
    bar: {
      value: 30,
      description: i18n.t('dashboardErrors|payoutDisabilityBarDescription'),
      color: theme.COLORS.GRAPH_NEUTRAL,
    },
  },
];

const dummyGraphDataDeath = (theme, i18n) => [
  getDummyBarIncome(theme, i18n),
  {
    id: 1,
    bar: {
      value: 30,
      description: i18n.t('dashboardErrors|payoutDeathBarDescription'),
      color: theme.COLORS.GRAPH_NEUTRAL,
    },
  },
];

const Wrapper = styled.View`
  flex: 1;
  background: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
`;

const NakedDashboard = ({
  focusedTab,
  pensionStatus,
  theme,
  i18n,
  pensionsInfoDataUpdateDate,
  taxDataUpdateDate,
  nakedDashboardErrors,
  failedCoverageOverview,
}) => {
  // NOTE: Needs to be in render for translations
  const graphByType = {
    [routeNames.RETIREMENT]: dummyGraphDataRetirement(theme, i18n),
    [routeNames.DISABILITY]: dummyGraphDataDisability(theme, i18n),
    [routeNames.DEATH]: dummyGraphDataDeath(theme, i18n),
  };

  return (
    <Wrapper testID={NAKED_DASHBOARD_ERROR}>
      <GraphPage
        hasBackButton={false}
        hasHeader={false}
        tabBar={<TopTabBar focusedTab={focusedTab} />}
        wideContent={
          <Graphs
            expandable={false}
            graphs={graphByType[focusedTab]}
            height={200}
            showTaxSwitch={false}
          />
        }
      >
        {isWeb && <Margin marginTop="lg" />}
        {nakedDashboardErrors?.errors && (
          <ErrorCard
            i18n={i18n}
            pensionStatus={pensionStatus}
            pensionDate={pensionsInfoDataUpdateDate && formatDate(pensionsInfoDataUpdateDate)}
            taxDate={taxDataUpdateDate && formatDate(taxDataUpdateDate)}
            nakedDashboardErrors={nakedDashboardErrors}
            failedCoverageOverview={failedCoverageOverview}
          />
        )}

        {/* Margin fixes card shadow cropping */}
        <Margin marginTop="lg" />
        <Margin marginTop="lg" />
      </GraphPage>
    </Wrapper>
  );
};

NakedDashboard.propTypes = {
  theme: PropTypes.object.isRequired,
  focusedTab: PropTypes.string.isRequired,
  pensionStatus: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  taxDataUpdateDate: PropTypes.string,
  pensionsInfoDataUpdateDate: PropTypes.string,
  nakedDashboardErrors: PropTypes.object,
  failedCoverageOverview: PropTypes.array,
};

NakedDashboard.defaultProps = {
  taxDataUpdateDate: undefined,
  pensionsInfoDataUpdateDate: undefined,
  nakedDashboardErrors: undefined,
  failedCoverageOverview: undefined,
};

const mapStateToProps = (state, props) => ({
  profileCompletenessInfo: selectProfileCompletenessInfo(state),
  pensionStatus: selectDataStatus(state, RETIREMENT, {}),
  error: selectError(state, props.focusedTab),
  failedCoverageOverview: selectFailedCoverageOverview(state, props.focusedTab),
  taxDataUpdateDate: selectTaxDataUpdateDate(state),
  pensionsInfoDataUpdateDate: selectPensionsInfoDataUpdateDate(state),
});

export default withTheme(
  withComponentContainer({
    consents: [],
    mapStateToProps,
    i18ns: ['dashboard', 'dashboardErrors'],
    init: ({ action, getProps }) =>
      action(
        async () => {
          const { dispatch, profileCompletenessInfo } = getProps();

          if (!profileCompletenessInfo) {
            await dispatch(getProfileCompleteness());
          }
        },
        {
          loader: true,
        }
      ),
    trackAs: { area: 'NakedDashboard', action: 'Viewed', params: { view: 'list' } },
  })(NakedDashboard)
);
