const ASC = 'ASC';

/*
 * NOTE: We are handling welcome mail on frontend
 * so it can play nicely with moving mails between inbox/archive
 */
export const selectConversations = (state) => {
  const { conversations, order, source } = state.areas.mailbox;

  const welcomeMail = conversations[source].threads
    .filter((c) => c.id === 'welcome')
    .map((c) => ({
      ...c,
      createdOn: null,
    }));

  const sortedConversations = [
    ...conversations[source].threads.filter((c) => c.id !== 'welcome'),
  ].sort((a, b) => {
    if (order === ASC) {
      return new Date(a.createdOn) - new Date(b.createdOn);
    }

    return new Date(b.createdOn) - new Date(a.createdOn);
  });

  return order === ASC
    ? [...welcomeMail, ...sortedConversations]
    : [...sortedConversations, ...welcomeMail];
};

export const selectConversationById = (state, id) => {
  const { conversations, source } = state.areas.mailbox;

  return conversations[source].threads.find((c) => c.id === id) || {};
};

export const selectServiceMessages = (state) => state.areas.mailbox.serviceMessages;
