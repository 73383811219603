export const APP_SCHEME = 'sampension://';

export default {
  prefixes: [
    'https://login-test.sampension.dk',
    'https://login-staging.sampension.dk',
    'https://login.sampension.dk',
    'http://localhost:3000',
    APP_SCHEME,
  ],
};
