import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/v2/typography/index.shared';

const H2 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.V2_H2.DEFAULT}px;
  line-height: ${(props) => props.theme.FONT_SIZES.V2_H2.DEFAULT * 1.5}px;
`;

H2.defaultProps = {
  fontWeight: 'bold',
  fontStyle: 'normal',
};

export default H2;
