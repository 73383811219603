import i18n from 'services/i18n';
import { formatDate, formatMoney } from 'helpers/string';

export const selectReceipt = (state) => state.areas.deposits?.receipt;
export const selectReceiptFromOwnData = (state) => ({
  coverageNumber: selectTarget(state)?.coverageNumber,
  lines: [
    {
      leftColumn: 'Engangsbetaling',
      rightColumn: `${selectAmount(state)} kr.`,
    },
    {
      leftColumn: 'Oprettet',
      rightColumn: selectNexiData(state)?.paymentDate,
    },
    {
      leftColumn: 'Betalingsid',
      rightColumn: selectNexiData(state)?.internalPaymentId,
    },
  ],
  remarks: [
    {
      leftColumn: 'Police',
      rightColumn: selectTarget(state)?.coverageNumber,
    },
  ],
  title: 'Ekstra engangsbetaling',
});

export const selectReceipts = (state) => state.areas.deposits?.receipts;

export const selectPlacement = (state) => state.areas.deposits?.placement;

export const selectStatus = (state) => state.areas.deposits?.status;

export const selectSavings = (state) => state.areas.deposits?.savings?.targets;

export const selectClearance = (state) => state.areas.deposits?.savings?.clearance;

export const selectQuestions = (state) => state.areas.deposits?.questions;

export const selectPaymentSources = (state) => state.areas.deposits?.paymentSources;

export const selectPaymentSourcesData = (state) => state.areas.deposits?.paymentSources?.sources;

export const selectTerms = (state) => state.areas.deposits?.terms;

export const selectAmount = (state) => state.areas.deposits?.amount;

export const selectNexiData = (state) => state.areas.deposits?.nexi;

export const selectTarget = (state) => state.areas.deposits?.target;

export const selectCompanyGroup = (state) => state.areas.deposits?.target?.companyGroup;

export const selectSavingsWithDepositsEnabled = (state) =>
  state.areas.deposits?.savings?.targets?.filter((el) => el.isDepositEnabled);

export const selectContactInfo = (state) => state.areas.deposits?.contactInfo;

export const selectTermsId = (state) => state.areas.deposits?.terms?.id;

export const selectIsPlacementValid = (state) => state.areas.deposits?.isPlacementValid;

export const selectSkipRatePension = (state) => state.areas.deposits?.skipRatePension;

export const selectPlacementText = (state) => state.areas.deposits?.placement?.texts;

export const selectDepositPolicies = (state) => {
  const savings = selectSavings(state);

  if (!savings) {
    return [];
  }

  return [
    {
      policies: savings.map(({ coverageNumber, name, amount, currency, timestamp }) => ({
        policyId: coverageNumber,
        textLeft: name,
        subtitleLeft: coverageNumber,
        textRight: formatMoney({ amount: amount, currency: currency }, true),
        subtitleRight: timestamp && i18n.t('deposits|prDate', { date: formatDate(timestamp) }),
      })),
    },
  ];
};
