import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import Row from '@sp/ui/v2/base/rows';
import { Checkbox } from '@sp/ui/v2/base/inputs';

const RowWithCheckbox = ({
  titleLeft,
  titleRight,
  subtitleLeft,
  subtitleRight,
  checked,
  onPress,
  border,
  theme,
  align,
  onChangeChecked,
  hasCheckedBackground,
  accessibilityLabel,
  isPartiallyChecked,
  testID,
}) => {
  const onPressCallback = useCallback(() => {
    if (onPress) {
      onPress();
    }

    if (onChangeChecked) {
      onChangeChecked(!checked);
    }
  }, [onPress, checked, onChangeChecked]);

  const checkbox = (
    <Checkbox
      onPress={onPressCallback}
      isChecked={checked}
      accessibilityLabel={accessibilityLabel}
      hasCheckedBackground={hasCheckedBackground}
      isPartiallyChecked={isPartiallyChecked}
      testID={testID}
    />
  );

  return (
    <Row
      onPress={onPressCallback}
      border={border}
      textLeft={titleLeft}
      textRight={titleRight}
      subtitleLeft={subtitleLeft}
      subtitleRight={subtitleRight}
      componentRight={align === 'right' && checkbox}
      componentLeft={align === 'left' && checkbox}
      backgroundColor={checked && hasCheckedBackground ? theme.COLORS.PRIMARY_VARIANT1 : undefined}
      accessibilityRole="RowWithCheckbox"
      accessibilityState={{
        checked,
      }}
    />
  );
};

RowWithCheckbox.propTypes = {
  titleLeft: PropTypes.string,
  titleRight: PropTypes.string,
  subtitleLeft: PropTypes.string,
  subtitleRight: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
  border: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  align: PropTypes.oneOf(['left', 'right']),
  onChangeChecked: PropTypes.func,
  hasCheckedBackground: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  isPartiallyChecked: PropTypes.bool,
  testID: PropTypes.string,
};

RowWithCheckbox.defaultProps = {
  onPress: undefined,
  border: false,
  titleLeft: undefined,
  titleRight: undefined,
  subtitleLeft: undefined,
  subtitleRight: undefined,
  align: 'right',
  onChangeChecked: undefined,
  hasCheckedBackground: true,
  accessibilityLabel: undefined,
  isPartiallyChecked: false,
  testID: undefined,
};

export default withTheme(RowWithCheckbox);
