import styled from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import Conditional from 'decorators/conditional';

const DESCRIPTION_MOBILE_TOP_PADDING = 8;
const DESCRIPTION_DESKTOP_TOP_PADDING = 24;

const DescriptionMobile = styled(Body)`
  padding-top: ${DESCRIPTION_MOBILE_TOP_PADDING}px;
`;

const Description = styled(Body)`
  padding-top: ${DESCRIPTION_DESKTOP_TOP_PADDING}px;
`;

export default Conditional({
  mobile: DescriptionMobile,
  tablet: Description,
  desktop: Description,
});
