import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import useI18n from 'hooks/useI18n';
import { useAppSelector } from 'hooks/redux';
import { ADJUST_RETIREMENT_AGE } from 'constants/routeNames';
import { ADJUST_CALCULATION_RETIREMENT_AGE_ROW } from 'constants/testIds/dashboard/adjustCalculation';
import { selectRetirementAge } from 'selectors/dashboard';
import AdjustCalculationRow from './AdjustCalculationRow';

const { default: Icon } = require('@sp/ui/base/Icon');
const { Body2 } = require('@sp/ui/v2/typography');

const RetirementAgeRow: React.FC = () => {
  const { i18n } = useI18n(['dashboard']);
  const { COLORS } = useTheme();
  const navigation = useNavigation();
  const retirementAge = useAppSelector(selectRetirementAge);

  return (
    <AdjustCalculationRow
      testID={ADJUST_CALCULATION_RETIREMENT_AGE_ROW}
      componentLeft={<Icon name="rockingChair" fill={COLORS.V2_PRIMARY_BACKGROUND} />}
      textLeft={i18n.t('dashboard|retirementAgeTitle')}
      textRight={
        <Body2 fontWeight="normal" color={COLORS.V2_SECONDARY_TEXT}>
          {i18n.t('dashboard|retirementAge', { retirementAge })}
        </Body2>
      }
      onPress={() => navigation.navigate(ADJUST_RETIREMENT_AGE)}
    />
  );
};

export default RetirementAgeRow;
