import React from 'react';
import useI18n from 'hooks/useI18n';
import FormSectionWithModal from '@sp/ui/v2/layout/Forms/FormSectionWithModal';
import RadioButtonForm from 'containers/form/RadioButtonForm';
import ModalContent from './ModalContent';
import { useAppSelector } from 'hooks/redux';
import { selectHasElderCheque } from 'selectors/dashboardForms';

const { default: Margin } = require('@sp/ui/base/Margin');
const { Body2 } = require('@sp/ui/v2/typography');
const { Form, Group } = require('react-formed');

type Props = {
  title: string;
};

const ElderlyCheck: React.FC<Props> = ({ title }) => {
  const { i18n } = useI18n(['dashboard']);
  const hasElderCheque: boolean = useAppSelector(selectHasElderCheque);

  const radioProps = [
    {
      label: i18n.t('yes'),
      isSelected: hasElderCheque,
      value: true,
    },
    {
      label: i18n.t('no'),
      isSelected: !hasElderCheque,
      value: false,
    },
  ];

  return (
    <FormSectionWithModal
      disablePadding
      title={
        <Body2 secondaryColor textAlign="center">
          {title}
        </Body2>
      }
      description={
        <Body2 secondaryColor fontWeight="regular">
          {i18n.t('dashboard|seniorityCheckModalInfo')}
        </Body2>
      }
      infoAccessibilityLabel={i18n.t('dashboard|publicBenefitsModalA11y')}
      modalContent={
        <ModalContent
          texts={[
            {
              h3: i18n.t('dashboard|seniorityCheck'),
            },
            {
              h5: i18n.t('dashboard|seniorityCheckConditions'),
            },
            {
              body: i18n.t('dashboard|seniorityCheckModal'),
            },
            {
              markdown: i18n.t('dashboard|seniorityCheckListContent'),
            },
          ]}
        />
      }
    >
      <Margin marginTop="md" marginBottom="s">
        <Form name="adjustCalculation">
          <Group name="publicBenefits">
            <RadioButtonForm name="hasElderCheque" leftAlignRadioButton radioProps={radioProps} />
          </Group>
        </Form>
      </Margin>
    </FormSectionWithModal>
  );
};

export default ElderlyCheck;
