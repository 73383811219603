import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withTranslation from 'decorators/i18n';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import UIModal from '@sp/ui/v2/layout/Modal';

const Modal = ({ children, i18n, isDesktop, ...props }) => (
  <UIModal {...props} accessibilityLabel={i18n.t('Luk info')} isDesktop={isDesktop}>
    {children}
  </UIModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
});

export default compose(connect(mapStateToProps), withTranslation())(Modal);
