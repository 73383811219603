import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { DEFAULTS } from '@sp/ui/settings';
import Conditional from 'decorators/conditional';

const DROPDOWN_BODY_PADDING_VERTICAL = 15;
const DROPDOWN_BODY_PADDING_HORIZONTAL = 15;
export const DROPDOWN_BODY_WIDTH = 288;

export const DropdownBodyMobile = styled.TouchableOpacity`
  width: 100%;
  margin: 0;
  padding: ${DROPDOWN_BODY_PADDING_VERTICAL}px ${DROPDOWN_BODY_PADDING_HORIZONTAL}px;
  background-color: ${(props) => props.theme.COLORS.SECONDARY_BACKGROUND};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ border, theme }) =>
    border
      ? `
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${theme.COLORS.PRIMARY_BORDER};
  border-radius: ${DEFAULTS.BORDER_RADIUS}px;
  `
      : `
  border-width: 0;
  border-color: ${theme.COLORS.SECONDARY_BACKGROUND};
  `}
`;

const DropdownBody = Conditional({
  mobile: DropdownBodyMobile,
  default: styled(DropdownBodyMobile)`
    width: ${DROPDOWN_BODY_WIDTH}px;
  `,
});

export default DropdownBody;
