import { connect } from 'react-redux';
import { selectIsDeviceLandscape, selectDeviceType } from 'selectors/device';
import DeviceGradient from '@sp/ui/base/DeviceGradient';

const mapStateToProps = (state) => ({
  deviceType: selectDeviceType(state),
  isLandscape: selectIsDeviceLandscape(state),
});

export default connect(mapStateToProps)(DeviceGradient);
