import {
  CANCEL_POLL_MAILS,
  CANCEL_POLL_MAIL,
  RESPONSE_MAILS,
  RESPONSE_MAIL,
} from 'constants/actionTypes';
import {
  pollMailsForSignatureStatusChange,
  pollIndividualMailForSignatureStatusChange,
} from 'actions/mailbox';
import { hasDocumentWithStatus } from 'areas/mailbox/helpers/documents';

const mailboxMiddleware = (store) => {
  let awaitingMails;
  let awaitingMail;

  return (next) => async (action) => {
    if (action.type === RESPONSE_MAILS) {
      const { threads } = action.payload.response;
      const hasSigningInProgress = threads.some(
        (conversation) => conversation.signingStatus === 'inProgress'
      );

      if (hasSigningInProgress) {
        awaitingMails = await store.dispatch(pollMailsForSignatureStatusChange());
      }
    }

    if (action.type === RESPONSE_MAIL) {
      const { messages } = action.payload.response;
      const { id } = action.payload.request;
      const hasSigningInProgress = hasDocumentWithStatus(messages, 'inProgress');

      if (hasSigningInProgress) {
        awaitingMail = await store.dispatch(pollIndividualMailForSignatureStatusChange(id));
      }
    }

    if (action.type === CANCEL_POLL_MAILS && awaitingMails) {
      awaitingMails.cancel();
    }

    if (action.type === CANCEL_POLL_MAIL && awaitingMail) {
      awaitingMail.cancel();
    }

    return next(action);
  };
};

export default mailboxMiddleware;
