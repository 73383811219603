import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import BlurView from '@sp/ui/misc/BlurView';
import { isWeb } from '@sp/ui/helpers/device';
import { Body } from '@sp/ui/typography';
import Pig from './Pig';

const Wrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  ${({ fullScreen }) =>
    fullScreen
      ? `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  `
      : ''}
  ${isWeb ? 'backface-visibility: hidden;' : ''}
`;

const TextWrapper = styled.View`
  margin-top: ${(props) => props.theme.SPACINGS.md}px;
  position: relative;
  justify-content: flex-end;
  align-items: center;
`;

const Loader = ({ color, text, fullScreen, blur, accessibilityLabel, startAnimation }) => (
  <Wrapper
    fullScreen={fullScreen}
    testID="Loader"
    focusable
    accessibilityLabel={accessibilityLabel}
  >
    {blur && <BlurView />}
    <Pig color={color} startAnimation={startAnimation} />
    {text && (
      <TextWrapper>
        <Body color={color || '#fff'} textAlign="center">
          {text}
        </Body>
      </TextWrapper>
    )}
  </Wrapper>
);

Loader.propTypes = {
  color: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  text: PropTypes.string,
  blur: PropTypes.bool,
  startAnimation: PropTypes.bool,
  fullScreen: PropTypes.bool,
};

Loader.defaultProps = {
  color: '#fff',
  accessibilityLabel: undefined,
  text: 'Henter...',
  blur: true,
  fullScreen: true,
  startAnimation: true,
};

export default Loader;
