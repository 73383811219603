import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Bar from './Bar';
import Grid from './Grid';
import Root from './Root';
import AverageLabel from './AverageLabel';
import SelectedBorder from './SelectedBorder';
import barProp from './shapes';

const GraphWrapper = styled.View`
  position: absolute;
  width: 100%;
  bottom: ${(props) => props.bottom}px;
`;

const Graph = ({
  bars,
  barWidth,
  barOuterWidth,
  withScroll,
  height,
  width,
  lineStep,
  labelHeight,
  labelWidth,
  labelsAlignedInTop,
  descriptionHeight,
  centerContent,
  withSelectedBorder,
  selectedIndex,
  getRef,
  ratio,
  onScroll,
  showAverageModal,
  averageBorder,
}) => {
  const bottomOffset = Math.round(averageBorder?.value * ratio) - 1 || 0;

  return (
    <Root
      height={height}
      width={width}
      withScroll={withScroll}
      centerContent={centerContent}
      getRef={getRef}
      onScroll={onScroll}
    >
      <GraphWrapper bottom={descriptionHeight}>
        <Grid lineStep={Math.round(lineStep * ratio)} maxValue={height} />
      </GraphWrapper>
      {bars.map((bar, i) => {
        // if barOuterWidth or bar.barWidth is set higher manually we will use that value
        const outerWidth = Math.max(barOuterWidth, bar.barWidth || 0, barWidth);

        return (
          <Bar
            onSelect={bar.onSelect}
            selected={bar.selected}
            key={i}
            icon={bar.icon}
            total={Math.round(bar.totalValue * ratio)}
            width={bar.outerWidth || outerWidth}
            segments={bar.segments}
            barWidth={bar.barWidth || barWidth}
            description={bar.description}
            descriptionHeight={descriptionHeight}
            descriptionColor={bar.descriptionColor}
            label={bar.label}
            labelHeight={labelHeight}
            labelWidth={labelWidth || outerWidth}
            labelColor={bar.labelColor}
            labelsAlignedInTop={labelsAlignedInTop}
            testID={bar.testID}
            accessibilityLabel={bar.accessibilityLabel}
            averageValue={
              i >= averageBorder?.startFromBarNo ? descriptionHeight + bottomOffset - 1 : undefined
            }
            averageValueColor={averageBorder?.color}
            averageValueLeftOffset={
              averageBorder?.value && i === averageBorder?.startFromBarNo
                ? outerWidth / 2
                : undefined
            }
          />
        );
      })}
      {averageBorder?.value && averageBorder?.label && (
        <GraphWrapper bottom={descriptionHeight}>
          <AverageLabel
            bottomOffset={bottomOffset}
            label={averageBorder.label}
            showAverageModal={showAverageModal}
          />
        </GraphWrapper>
      )}
      {withSelectedBorder && selectedIndex !== undefined && (
        <SelectedBorder width={width} selectedIndex={selectedIndex} noOfBars={bars.length} />
      )}
    </Root>
  );
};

Graph.propTypes = {
  bars: PropTypes.arrayOf(barProp).isRequired,
  barWidth: PropTypes.number.isRequired,
  barOuterWidth: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  withScroll: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  lineStep: PropTypes.number,
  labelWidth: PropTypes.number,
  labelHeight: PropTypes.number.isRequired,
  labelsAlignedInTop: PropTypes.bool,
  descriptionHeight: PropTypes.number.isRequired,
  centerContent: PropTypes.bool,
  withSelectedBorder: PropTypes.bool,
  selectedIndex: PropTypes.number,
  getRef: PropTypes.func,
  onScroll: PropTypes.func,
  showAverageModal: PropTypes.func,
  averageBorder: PropTypes.object,
};

Graph.defaultProps = {
  withScroll: false,
  lineStep: 0,
  labelWidth: undefined,
  labelsAlignedInTop: false,
  centerContent: false,
  withSelectedBorder: false,
  selectedIndex: undefined,
  getRef: () => {},
  onScroll: undefined,
  showAverageModal: undefined,
  averageBorder: undefined,
};

export default Graph;
