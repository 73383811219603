import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from 'decorators/i18n';
import UIModal from '@sp/ui/layout/Modal';

const Modal = ({ children, i18n, ...props }) => (
  <UIModal {...props} accessibilityLabel={i18n.t('Luk info')}>
    {children}
  </UIModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Modal);
