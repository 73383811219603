import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated';
import styled from 'styled-components/native';
import BasePage, {
  TopContent,
  Actions,
  ActionShape,
  NarrowContent,
  extractBottomActions,
  animations,
} from '@sp/ui/pages/BasePage';
import Conditional from 'decorators/conditional';

const CenterTabletContent = Conditional({
  tablet: styled.View`
    align-items: center;
  `,
  default: Fragment,
});

const NarrowWrapper = Conditional({
  tablet: NarrowContent,
  default: Fragment,
});

const ScrollPage = ({
  title,
  heading,
  description,
  children,
  actions,
  actionInfo,
  backButton,
  customHeader,
  hasBackButton,
  contentContainerStyle,
  testID,
  fullWidth,
  onScroll,
  scrollEventThrottle,
  scrollViewVertical,
  scrollViewHorizontal,
  scrollViewRef,
  accessibilitySummary,
  animateTitle,
  tabBar,
}) => {
  const scrollY = useSharedValue(0);
  const headingAnimation = animations.useHeaderAnimation(scrollY);
  const titleAnimation = animations.useHeaderAnimation(scrollY);

  const { bottomActions, otherActions } = extractBottomActions(actions);

  const renderBody = () => (
    <>
      {(heading || animateTitle || description) && (
        <TopContent
          heading={animateTitle ? title : heading}
          description={description}
          animation={animateTitle ? headingAnimation : undefined}
        />
      )}
      {children}
      {bottomActions.length > 0 && <Actions actions={bottomActions} actionInfo={actionInfo} />}
    </>
  );

  const animatedScrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      scrollY.value = e.contentOffset.y;
    },
  });

  const onScrollHandler = useMemo(
    () => (typeof onScroll === 'function' ? onScroll : animatedScrollHandler),
    [onScroll, animatedScrollHandler]
  );

  return (
    <BasePage
      title={title}
      titleAnimation={titleAnimation}
      actions={otherActions}
      customHeader={customHeader}
      backButton={hasBackButton ? backButton : null}
      testID={testID}
      actionInfo={actionInfo}
      accessibilitySummary={accessibilitySummary}
      animateTitle={animateTitle}
    >
      <Animated.ScrollView
        alwaysBounceVertical={false}
        contentContainerStyle={contentContainerStyle}
        vertical={scrollViewVertical}
        horizontal={scrollViewHorizontal}
        onScroll={onScrollHandler}
        ref={scrollViewRef}
        scrollEventThrottle={scrollEventThrottle}
        testID={`${testID}scrollView`}
      >
        {tabBar}
        {fullWidth ? (
          renderBody()
        ) : (
          <CenterTabletContent>
            <NarrowWrapper>{renderBody()}</NarrowWrapper>
          </CenterTabletContent>
        )}
      </Animated.ScrollView>
    </BasePage>
  );
};

ScrollPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  customHeader: PropTypes.node,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  testID: PropTypes.string,
  contentContainerStyle: PropTypes.object,
  fullWidth: PropTypes.bool,
  onScroll: PropTypes.func,
  scrollEventThrottle: PropTypes.number,
  scrollViewVertical: PropTypes.bool,
  scrollViewHorizontal: PropTypes.bool,
  scrollViewRef: PropTypes.object,
  animateTitle: PropTypes.bool,
  tabBar: PropTypes.node,
};

ScrollPage.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  children: undefined,
  actions: undefined,
  actionInfo: undefined,
  backButton: undefined,
  customHeader: undefined,
  hasBackButton: true,
  testID: undefined,
  contentContainerStyle: undefined,
  fullWidth: false,
  onScroll: undefined,
  scrollEventThrottle: 16,
  scrollViewVertical: false,
  scrollViewHorizontal: false,
  scrollViewRef: undefined,
  accessibilitySummary: undefined,
  animateTitle: false,
  tabBar: undefined,
};

export default ScrollPage;
