import React, { Suspense } from 'react';
const { default: Loader } = require('@sp/ui/base/Loader');

const withSuspense =
  <P extends {}>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <Suspense fallback={<Loader />}>
        <WrappedComponent {...props} />
      </Suspense>
    );

export default withSuspense;
