import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import * as routeNames from 'constants/routeNames';

import MailOverview from './Overview';
import MailDetails from './Details';
import MailSign from './Signing';

const Stack = createStackNavigator();

export default () => (
  <Stack.Navigator
    initialRouteName={routeNames.MAIL_OVERVIEW}
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen name={routeNames.MAIL_OVERVIEW} component={MailOverview} />
    <Stack.Screen name={routeNames.MAIL_DETAILS} component={MailDetails} />
    <Stack.Screen name={routeNames.MAIL_SIGN} component={MailSign} />
  </Stack.Navigator>
);
