import { useMemo } from 'react';

import * as notFoundConstants from 'constants/notFound';

type NotFoundContentType = {
  [key: string]: {
    title: string;
    description: string;
  };
};

export type NotFoundErrorTypes =
  | typeof notFoundConstants.DEFAULT_NOT_FOUND
  | typeof notFoundConstants.PARTNER_CONNECTION_FAILED
  | typeof notFoundConstants.PARTNER_LINKING_ERROR
  | typeof notFoundConstants.DEEPLINK_DONT_EXIST;

const NOT_FOUND_CONTENT: NotFoundContentType = {
  [notFoundConstants.DEFAULT_NOT_FOUND]: {
    title: 'couldNotFindPage',
    description: 'sorryCouldNotFindPage',
  },
  [notFoundConstants.PARTNER_CONNECTION_FAILED]: {
    title: 'couldNotFindDeeplink',
    description: 'sorryYourLinkDoesNotWork',
  },
  [notFoundConstants.PARTNER_LINKING_ERROR]: {
    title: 'couldNotFindDeeplink',
    description: 'itIsNotPossibleToUseYourselfAsPartner',
  },
  [notFoundConstants.DEEPLINK_DONT_EXIST]: {
    title: 'couldNotFindDeeplink',
    description: 'sorryCouldNotFindDeeplink',
  },
};

export const useGetContent = (type?: NotFoundErrorTypes) =>
  useMemo(() => NOT_FOUND_CONTENT[type ?? notFoundConstants.DEFAULT_NOT_FOUND], [type]);
