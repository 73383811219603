import type { TransferState } from 'reducers/transfer';

interface StoreSlice {
  areas: { transfer: TransferState };
}

export const selectTransferTargets = (state: StoreSlice) => {
  const { targets, policies } = state.areas.transfer?.data ?? {};

  return targets?.map(({ policyId: targetPolicyId, blocked, validSources }) => {
    const { name, balance, type } =
      policies?.find(({ policyId }) => policyId === targetPolicyId) ?? {};

    return {
      policyId: targetPolicyId,
      isBlocked: blocked,
      validSources,
      name,
      balance,
      type,
    };
  });
};
