import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useAsyncInit from 'hooks/useAsyncInit';
import useI18n from 'hooks/useI18n';
import usePrerenderScreen from 'hooks/usePrerenderScreen';
import * as testIds from 'constants/testIds/dashboard/adjustCalculation';
import {
  selectDefaultIdentifierOrSelectedIncomeIndex,
  selectDisposableIncomeOptions,
  selectShowGrossAmount,
  selectTaxDataUpdateDate,
} from 'selectors/dashboard';
import {
  selectFormAdjustIncome,
  selectFormAdjustIncomeHasChanges,
  selectHasGrossAmountWarning,
  selectIsIncomeChangedOrValid,
} from 'selectors/dashboardForms';
import { selectIsDeviceTypeDesktop, selectIsDeviceTypeMobile } from 'selectors/device';
import { fetchTax } from 'actions/fetchTaxAndPensionsInfo';
import AdjustCalculationAlert from '../components/AdjustCalculationAlert';
import FooterWarning from '../components/FooterWarning';
import Page from '../components/Page';
import PointerEventsNoneWrapper from '../components/PointerEventsNoneWrapper';
import { useUpdateHelpers } from './hooks';
import IncomeOverview from './IncomeOverview';
import ManualIncome from './ManualIncome';
import type { RootState } from 'store';

const { default: Link } = require('@sp/ui/v2/base/Link');
const { default: Margin } = require('@sp/ui/base/Margin');
const { default: buttonPositions } = require('constants/buttonPositions');
const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { actions } = require('react-formed');
const { getIncome } = require('actions/dashboard');
const { clearIncomeForm } = require('actions/digital-advice');
const { BackButton } = require('@sp/ui/v2/pages/BasePage');
const { Body } = require('@sp/ui/v2/typography');
const { formatDate } = require('helpers/string');
const { getWarningText } = require('areas/dashboard/calculations/components/IncomeInput');

const selectAdjustDisposableIncome = (state: RootState) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
  isMobile: selectIsDeviceTypeMobile(state),
  taxDataUpdateDate: selectTaxDataUpdateDate(state),
  disposableIncomeOptions: selectDisposableIncomeOptions(state),
  formAdjustIncomeHasChanges: selectFormAdjustIncomeHasChanges(state),
  formAdjustIncome: selectFormAdjustIncome(state),
  hasGrossAmountWarning: selectHasGrossAmountWarning(state),
  showGrossAmount: selectShowGrossAmount(state),
  isIncomeChangedOrValid: selectIsIncomeChangedOrValid(state),
  defaultIdentifierOrSelectedIncomeIndex: selectDefaultIdentifierOrSelectedIncomeIndex(state),
});

const DisposableIncome: React.FC = () => {
  const [isSpecifyIncomeVisible, setIsSpecifyIncomeVisible] = useState(false);
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);

  const navigation = useNavigation();
  const i18n = useI18n(['dashboard']);
  const prerender = usePrerenderScreen();
  const dispatch = useAppDispatch();

  const {
    isMobile,
    isDesktop,
    taxDataUpdateDate,
    disposableIncomeOptions,
    formAdjustIncomeHasChanges,
    formAdjustIncome,
    hasGrossAmountWarning,
    showGrossAmount,
    isIncomeChangedOrValid,
    defaultIdentifierOrSelectedIncomeIndex,
  } = useAppSelector((state) => selectAdjustDisposableIncome(state), shallowEqual);

  const { updateDisposableIncome, updateInitialIncomeValues } = useUpdateHelpers();

  const updateTax = useCallback(
    () =>
      dispatch(
        fetchTax({
          onDoneCallback: () => {
            dispatch([
              clearIncomeForm(),
              actions.setValue(
                'adjustIncome',
                'selectedIndex',
                defaultIdentifierOrSelectedIncomeIndex
              ),
            ]);

            updateInitialIncomeValues();
          },
        })
      ),
    [defaultIdentifierOrSelectedIncomeIndex, dispatch, updateInitialIncomeValues]
  );

  const warningText = getWarningText({
    showGrossAmountWarning: hasGrossAmountWarning,
    showGrossAmount,
    formAdjustIncome,
    i18n,
  });

  const isFooterWarningVisible = isSpecifyIncomeVisible && warningText;
  const isUpdateButtonInactive = !isIncomeChangedOrValid;

  useAsyncInit(
    async () => {
      if (!disposableIncomeOptions) {
        await dispatch(getIncome());
      }

      updateInitialIncomeValues();
    },
    [],
    {
      error: {
        type: 'blocking',
        retry: true,
      },
    }
  );

  if (prerender) {
    return prerender;
  }

  return (
    <Page
      testID={testIds.ADJUST_CALCULATION_DISPOSABLE_INCOME_SCREEN}
      takeUpAllAvailableSpaceWhenKeyboardIsVisible
      keyboardAdjustResize
      shouldOpenBottomSheetIfClosed={true}
      ignoreDefaultVerticalPadding
      heading={i18n.t('dashboard|updateIncome')}
      backButton={
        <BackButton
          testID={testIds.ADJUST_CALCULATION_GO_BACK}
          label={i18n.t('back')}
          onPress={() =>
            formAdjustIncomeHasChanges && !warningText
              ? setIsAlertModalVisible(true)
              : navigation.goBack()
          }
        />
      }
      footerWarning={
        isMobile &&
        isFooterWarningVisible && (
          <PointerEventsNoneWrapper>
            <FooterWarning testID={testIds.DISPOSABLE_INCOME_FOOTER_WARNING} text={warningText} />
          </PointerEventsNoneWrapper>
        )
      }
      actionsContent={
        isSpecifyIncomeVisible && (
          <>
            <ManualIncome />
            {!isMobile && isFooterWarningVisible && (
              <FooterWarning
                testID={testIds.DISPOSABLE_INCOME_FOOTER_WARNING}
                hasMargin={false}
                text={warningText}
              />
            )}
          </>
        )
      }
      actions={[
        {
          testID: testIds.DISPOSABLE_INCOME_SAVE_CHANGES_BUTTON,
          text: i18n.t('saveChange'),
          mobilePosition: buttonPositions.bottom,
          inactive: isUpdateButtonInactive,
          onPress: () => updateDisposableIncome({ goBack: false }),
        },
      ].concat(
        isSpecifyIncomeVisible
          ? []
          : ([
              {
                testID: testIds.DISPOSABLE_INCOME_ENTER_OWN_INCOME_BUTTON,
                text: i18n.t('dashboard|enterYourself'),
                type: 'secondary',
                mobilePosition: buttonPositions.bottom,
                onPress: () => {
                  setIsSpecifyIncomeVisible(true);
                  dispatch(actions.setValue('adjustIncome', 'selectedIndex', -1));
                },
              },
            ] as any[])
      )}
    >
      <Margin horizontal="md">
        <Trans
          i18nKey="dashboard|salaryInfoFromTax"
          components={{
            text: <Body />,
            linkButton: <Link isDesktop={isDesktop} onPress={updateTax} />,
          }}
          values={{
            date: taxDataUpdateDate && formatDate(taxDataUpdateDate),
          }}
        />
      </Margin>
      <Margin vertical="lg">
        <IncomeOverview setIsSpecifyIncomeVisible={setIsSpecifyIncomeVisible} />
      </Margin>
      <AdjustCalculationAlert
        isVisible={isAlertModalVisible}
        title={i18n.t('dashboard|saveChanges')}
        onRequestClose={() => setIsAlertModalVisible(false)}
        actions={[
          {
            text: i18n.t('yes'),
            style: 'primary',
            onPress: () => updateDisposableIncome({ goBack: true }),
          },
          {
            text: i18n.t('no'),
            style: 'secondary',
            onPress: () => navigation.goBack(),
          },
        ]}
      />
    </Page>
  );
};

export default withScreenContainer({})(DisposableIncome);
