import React from 'react';
import PropTypes from 'prop-types';
import BasePage, { Card, ActionShape, TopContent } from '@sp/ui/v2/pages/BasePage';
import { isWeb } from '@sp/ui/helpers/device';

const ScrollPage = ({
  title,
  heading,
  description,
  children,
  tabBar,
  actions,
  actionInfo,
  backButton,
  hasBackButton,
  menu,
  disableTopPadding,
  disableBottomPadding,
  enableOverflow,
  testID,
  accessibilitySummary,
  animateTitle,
  actionsContent,
}) => (
  <BasePage
    menu={isWeb && menu}
    testID={testID}
    accessibilitySummary={accessibilitySummary}
    title={title}
    actions={actions}
    backButton={backButton}
    hasBackButton={hasBackButton}
    actionInfo={actionInfo}
    actionsContent={actionsContent}
  >
    <Card
      disableTopPadding={disableTopPadding}
      disableBottomPadding={disableBottomPadding}
      enableOverflow={enableOverflow}
    >
      {tabBar && tabBar}
      {(heading || animateTitle || description) && (
        <TopContent heading={animateTitle ? title : heading} description={description} />
      )}
      {children}
    </Card>
  </BasePage>
);

ScrollPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  tabBar: PropTypes.node,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  backButton: PropTypes.node,
  menu: PropTypes.node,
  hasBackButton: PropTypes.bool,
  disableTopPadding: PropTypes.bool,
  disableBottomPadding: PropTypes.bool,
  enableOverflow: PropTypes.bool,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  animateTitle: PropTypes.bool,
  actionsContent: PropTypes.node,
};

ScrollPage.defaultProps = {
  title: undefined,
  heading: undefined,
  description: undefined,
  children: undefined,
  tabBar: undefined,
  actions: undefined,
  actionInfo: undefined,
  menu: undefined,
  backButton: undefined,
  hasBackButton: true,
  disableTopPadding: false,
  disableBottomPadding: false,
  enableOverflow: false,
  testID: undefined,
  accessibilitySummary: undefined,
  animateTitle: false,
  actionsContent: undefined,
};

export default ScrollPage;
