import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { Footnote, H6 } from '@sp/ui/typography';
import AverageBorder from './AverageBorder';
import { isAndroid } from '@sp/ui/helpers/device';

const Wrapper = styled.TouchableOpacity`
  align-items: center;
  ${(props) => (props.width ? `width: ${props.width}px` : 'flex: 1')};
`;

const BarWrapper = styled.View`
  position: absolute;
  bottom: ${(props) => props.bottom}px;
`;

const CenterWrapper = styled.View`
  align-items: center;
  height: ${(props) => props.height}px;
`;

const Segment = styled.View`
  position: absolute;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  bottom: ${(props) => props.bottom}px;
  ${(props) => props.color && `background: ${props.color};`}
  ${(props) =>
    props.roundedCorners &&
    `border-top-left-radius: ${props.theme.DEFAULTS.BORDER_RADIUS}px;
    border-top-right-radius: ${props.theme.DEFAULTS.BORDER_RADIUS}px;`}
`;

const LabelWrapper = styled.View`
  ${(props) =>
    !props.labelsAlignedInTop &&
    `
    position: absolute;
    bottom: ${props.bottom}px;
  `};
  height: ${(props) => props.labelHeight || 0}px;
  justify-content: center;
  ${(props) => props.labelWidth && `width: ${props.labelWidth}px;`}
`;

const TextWrapper = styled.Text`
  text-align: center;
  color: ${(props) =>
    props.selected ? props.theme.COLORS.PRIMARY : props.color || props.theme.COLORS.SECONDARY_TEXT};
`;

const DescriptionWrapper = styled.View`
  position: absolute;
  bottom: 0;
  height: ${(props) => props.descriptionHeight}px;
  justify-content: center;
`;

const IconWrapper = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const getSegments = (segments, barWidth) => {
  let bottom = 0;
  return segments.map(({ value: segmentValue, color }, i) => {
    const bottomOffset = bottom;
    bottom += segmentValue;
    const height = isAndroid ? segmentValue + 1 : segmentValue;
    return (
      <Segment
        key={i}
        width={barWidth}
        height={height}
        bottom={bottomOffset}
        color={color}
        roundedCorners={i === segments.length - 1}
      />
    );
  });
};

const Bar = ({
  segments,
  barWidth,
  width,
  label,
  labelWidth,
  description,
  descriptionColor,
  icon,
  labelHeight,
  labelColor,
  descriptionHeight,
  total,
  labelsAlignedInTop,
  onSelect,
  selected,
  testID,
  accessibilityLabel,
  averageValue,
  averageValueLeftOffset,
  averageValueColor,
}) => (
  <Wrapper
    accessibilityRole={onSelect && 'button'}
    accessibilityLabel={accessibilityLabel}
    width={width}
    onPress={onSelect}
    testID={testID}
    disabled={!onSelect}
  >
    <BarWrapper bottom={descriptionHeight}>
      <CenterWrapper height={total}>
        {getSegments(segments, barWidth)}
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </CenterWrapper>
    </BarWrapper>
    {description && (
      <DescriptionWrapper descriptionHeight={descriptionHeight}>
        <Footnote textAlign="center">
          <TextWrapper selected={selected} color={descriptionColor}>
            {description}
          </TextWrapper>
        </Footnote>
      </DescriptionWrapper>
    )}
    {averageValue ? (
      <AverageBorder
        bottomOffset={averageValue}
        left={averageValueLeftOffset}
        color={averageValueColor}
      />
    ) : undefined}
    {label && label !== '' ? (
      <LabelWrapper
        labelHeight={labelHeight}
        labelWidth={labelWidth}
        bottom={descriptionHeight + total}
        labelsAlignedInTop={labelsAlignedInTop}
      >
        <H6 textAlign="center">
          <TextWrapper fontWeight="bold" selected={selected} color={labelColor}>
            {label}
          </TextWrapper>
        </H6>
      </LabelWrapper>
    ) : undefined}
  </Wrapper>
);

Bar.propTypes = {
  barWidth: PropTypes.number,
  width: PropTypes.number,
  label: PropTypes.string,
  labelWidth: PropTypes.number,
  labelHeight: PropTypes.number.isRequired,
  labelColor: PropTypes.string,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ).isRequired,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  icon: PropTypes.node,
  descriptionHeight: PropTypes.number,
  total: PropTypes.number,
  labelsAlignedInTop: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  testID: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  averageValue: PropTypes.number,
  averageValueLeftOffset: PropTypes.number,
  averageValueColor: PropTypes.string,
};

Bar.defaultProps = {
  barWidth: undefined,
  width: undefined,
  label: undefined,
  labelWidth: undefined,
  labelColor: undefined,
  description: undefined,
  descriptionColor: undefined,
  icon: undefined,
  descriptionHeight: 0,
  total: 0,
  labelsAlignedInTop: undefined,
  onSelect: undefined,
  selected: undefined,
  testID: undefined,
  accessibilityLabel: undefined,
  averageValue: undefined,
  averageValueLeftOffset: undefined,
  averageValueColor: undefined,
};

export default withTheme(Bar);
