import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import ResultPage from '@sp/ui/pages/ResultPage';
import withScreenContainer from 'decorators/withScreenContainer';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { shouldUpdateContactInfo } from 'selectors/forms';
import { navigateToContactInfoIfNecessary } from 'areas/profile/helpers';
import { dismiss } from 'helpers/navigation';
import { FORM_NAME } from 'areas/feedback/AddContactInfo';

const Completed = ({ navigation, theme, isContactInfoUpdated, i18n }) => {
  useEffect(
    () => () => {
      navigateToContactInfoIfNecessary(isContactInfoUpdated, navigation, FORM_NAME, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ModalProvider isModal onClose={dismiss(navigation)}>
      <ResultPage
        title={i18n.t('feedbackTitle')}
        heading={i18n.t('feedbackAddContactInfoHeading')}
        description={i18n.t('feedbackAddContactInfoDescription')}
        iconColor={theme.COLORS.PRIMARY_SUCCESS}
        iconName="checkmarkSuccess"
        hasBackButton={false}
        actions={[
          {
            text: i18n.t('done'),
            mobilePosition: 'bottom-sticky',
            onPress: dismiss(navigation),
          },
        ]}
      />
    </ModalProvider>
  );
};

Completed.propTypes = {
  navigation: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isContactInfoUpdated: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withScreenContainer({
  hasLocalNotifications: false,
  mapStateToProps: (state) => ({
    isContactInfoUpdated: shouldUpdateContactInfo(state, FORM_NAME),
  }),
})(withTheme(Completed));
