import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useRoute, useFocusEffect, useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectFlowControl } from 'selectors/flowControl';
import { selectDeeplink } from 'selectors/deeplink';
import { fetchPensionsInfo } from 'actions/fetchTaxAndPensionsInfo';
import { FLOW_CONTROL_DONE } from 'constants/flowControl';
import { useUpdateCurrentProgress } from './useUpdateCurrentProgress';
import { selectOnDoneRouteConfig } from 'selectors/fetchTaxAndPensionsInfo';
import type { RootState } from 'store';

const PENSIONS_INFO_ROUTES = [
  routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION,
  routeNames.FETCH_TAX_PENSIONSINFO_DONE,
  routeNames.FETCH_TAX_PENSIONSINFO_FAIL,
];

const selectUseInitCurrentProgress = (state: RootState) => ({
  flowControl: selectFlowControl(state),
  deeplink: selectDeeplink(state),
  onDoneRouteConfig: selectOnDoneRouteConfig(state),
});

export const useInitCurrentProgress = () => {
  const { flowControl, onDoneRouteConfig } =
    useAppSelector(selectUseInitCurrentProgress, shallowEqual) ?? {};

  const dispatch = useAppDispatch();
  const updateCurrentProgress = useUpdateCurrentProgress();
  const navigation = useNavigation();
  const { name } = useRoute();

  const { status, currentProgress, activeProgress } = flowControl ?? {};
  const isCurrentRoutePensionsInfo = PENSIONS_INFO_ROUTES.includes(name);
  const isActiveProgressPensionsInfo =
    activeProgress === routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION;
  const isActiveProgressWrong = isCurrentRoutePensionsInfo && !isActiveProgressPensionsInfo;
  const { isSelected }: { isSelected?: boolean } =
    currentProgress?.find(({ step }) => step === name) ?? {};

  const onSkipAndOnDoneNavigation = useCallback(() => {
    navigation.navigate(routeNames.PARTNER, {
      screen: routeNames.PARTNER_INCOME_INFORMATION,
    });
  }, [navigation]);

  useFocusEffect(() => {
    if (status === FLOW_CONTROL_DONE && !isSelected) {
      if (isCurrentRoutePensionsInfo && isActiveProgressWrong) {
        /* NOTE: Ensuring that PensionsInfo is correctly integrated and initiated */
        updateCurrentProgress({ step: routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION });

        if (!onDoneRouteConfig) {
          dispatch(
            fetchPensionsInfo({
              onSkipNavigation: onSkipAndOnDoneNavigation,
              onDoneNavigation: onSkipAndOnDoneNavigation,
            })
          );
        }
      } else if (!isCurrentRoutePensionsInfo) {
        updateCurrentProgress({ initialize: true });
      }
    }
  });
};
