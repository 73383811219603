import { create } from '../../helpers/reducers';

const createDefaults = () => [] as any[];

type State = ReturnType<typeof createDefaults>;

export default create(
  {
    FETCHING: (state, payload) => {
      const newIsFetching = state;
      newIsFetching.push(payload);

      return newIsFetching;
    },
    FETCHING_IS_DONE: (state, payload) =>
      state.filter(
        (serviceRequest) =>
          serviceRequest.url !== payload.url ||
          serviceRequest.method !== payload.method ||
          serviceRequest.name !== payload.name
      ),
  },
  createDefaults
);

export type { State };
