import { selectProfileCompletenessInfo } from 'selectors/profile';
import type { RootState } from 'store';

type FetchTaxOrPensionsInfoParams = {
  payload: {
    fetchTax?: boolean;
    fetchPensionsInfo?: boolean;
    isOnboarding?: boolean;
  };
  state: RootState;
};

export const getFetchTaxOrPensionsInfo = (
  payload: FetchTaxOrPensionsInfoParams['payload'],
  state: FetchTaxOrPensionsInfoParams['state']
): FetchTaxOrPensionsInfoParams['payload'] => {
  const { isOnboarding } = payload ?? {};
  let fetchTax = payload?.fetchTax;
  let fetchPensionsInfo = payload?.fetchPensionsInfo;

  if (isOnboarding) {
    const { taxStatement, pensionInfo } = selectProfileCompletenessInfo(state) ?? {};

    fetchTax = !taxStatement;
    fetchPensionsInfo = !pensionInfo;
  }

  return { fetchTax, fetchPensionsInfo };
};
