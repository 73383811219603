import { getCurrentDeviceType, getCurrentDeviceOrientation } from '@sp/ui/helpers/device';

export const setCurrentScreenSize = () => ({
  type: 'SET_CURRENT_DEVICE_TYPE',
  payload: {
    type: getCurrentDeviceType(),
    orientation: getCurrentDeviceOrientation(),
  },
});

export const featuresLoaded = () => ({ type: 'FEATURES_LOADED' });
