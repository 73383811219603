import React, { Fragment } from 'react';
import Conditional from 'decorators/conditional';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import withTranslation from 'decorators/i18n';
import { formatDate } from 'helpers/string';
import HTML from 'containers/base/HTML';
import { DocumentButton } from '@sp/ui/base/buttons';

import { H5 } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import { TwoLineRow, RowBorder } from '@sp/ui/base/rows';

import ActionButtons from './ActionButtons';

const MAIL_CONTENT_VERTICAL_MARGIN_DESKTOP = 16;
const MAIL_CONTENT_HORIZONTAL_MARGIN_DESKTOP = 20;

const MAIL_CONTENT_VERTICAL_MARGIN_MOBILE = 24;
const MAIL_CONTENT_HORIZONTAL_MARGIN_MOBILE = 24;

const DOCUMENT_MARGIN = 16;
const ATTACHMENT_ICON_HEIGHT = 10;

const DOCUMENT_WIDTH_DESKTOP = 288;

const MailContentMobile = styled.View`
  margin: ${MAIL_CONTENT_VERTICAL_MARGIN_MOBILE}px ${MAIL_CONTENT_HORIZONTAL_MARGIN_MOBILE}px;
`;

const MailContentDesktop = styled.View`
  margin: ${MAIL_CONTENT_VERTICAL_MARGIN_DESKTOP}px ${MAIL_CONTENT_HORIZONTAL_MARGIN_DESKTOP}px;
`;

const MailHeader = styled.View`
  flex-direction: row;
  justify-content: flex-start;
`;

const IconWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const MailContent = Conditional({
  mobile: MailContentMobile,
  tablet: MailContentMobile,
  desktop: MailContentDesktop,
});

const getDocumentType = (doc) => {
  const statusToType = {
    normal: 'attachment',
    signed: 'signed',
    toBeSigned: 'unsigned',
    inProgress: 'inProgress',
  };

  return statusToType[doc.status];
};

const getDocumentAccessibilityLabel = (doc, i18n) => {
  const statusToLabel = {
    normal: i18n.t('mailbox|attachDocument:'),
    signed: i18n.t('mailbox|signDocument:'),
    toBeSigned: i18n.t('mailbox|documentForSigning:'),
    inProgress: i18n.t('mailbox|documentBeeingSigned:'),
  };

  return statusToLabel[doc.status];
};

const DocumentWrapperDesktop = styled.View`
  margin: 0 ${MAIL_CONTENT_HORIZONTAL_MARGIN_DESKTOP}px;
  flex-direction: row;
  flex-flow: wrap;
`;

const DocumentWrapper = Conditional({
  mobile: Fragment,
  tablet: Fragment,
  desktop: DocumentWrapperDesktop,
});

const DocumentButtonWrapperMobile = styled.View`
  margin-top: ${DOCUMENT_MARGIN}px;
`;

const DocumentButtonWrapperDesktop = styled.View`
  width: ${DOCUMENT_WIDTH_DESKTOP}px;
  margin-top: ${DOCUMENT_MARGIN}px;
  margin-right: ${DOCUMENT_MARGIN}px;
`;

const DocumentButtonWrapper = Conditional({
  mobile: DocumentButtonWrapperMobile,
  tablet: DocumentButtonWrapperMobile,
  desktop: DocumentButtonWrapperDesktop,
});

const Mail = ({
  content,
  documents,
  onDocumentPress,
  createdOn,
  isFirst,
  theme,
  title,
  toolbarIcons,
  i18n,
}) => (
  <>
    {!isFirst && <RowBorder />}
    <>
      <TwoLineRow
        textLeft={<H5>{isFirst ? title : i18n.t('mailbox|titleReplyLabel', { title })}</H5>}
        subtitleLeft={
          <MailHeader>
            <H5
              fontWeight="regular"
              accessibilityLabel={i18n.t(
                createdOn && i18n.t('mailbox|messageReceivedOn', { date: formatDate(createdOn) })
              )}
            >
              {createdOn && formatDate(createdOn)}
              {createdOn && documents.length > 0 && ' |'}
            </H5>
            {documents.length > 0 && (
              <>
                <IconWrapper>
                  <Icon
                    height={ATTACHMENT_ICON_HEIGHT}
                    fill={theme.COLORS.PRIMARY_TEXT}
                    name="attachment"
                  />
                </IconWrapper>
                <H5 fontWeight="regular">
                  {documents.length > 0 && documents.length} {i18n.t('mailbox|attachedFiles')}
                </H5>
              </>
            )}
          </MailHeader>
        }
        componentRight={
          toolbarIcons.length > 0 ? <ActionButtons icons={toolbarIcons} /> : undefined
        }
      />
    </>

    <RowBorder />

    <MailContent>
      <HTML content={content} />

      <DocumentWrapper>
        {documents.map((doc) => (
          <DocumentButtonWrapper key={doc.id}>
            <DocumentButton
              name={doc.title}
              type={getDocumentType(doc)}
              date={createdOn && formatDate(createdOn)}
              onPress={
                onDocumentPress
                  ? () => {
                      onDocumentPress(doc);
                    }
                  : undefined
              }
              accessibilityLabel={`${getDocumentAccessibilityLabel(doc, i18n)} ${doc.title}`}
            />
          </DocumentButtonWrapper>
        ))}
      </DocumentWrapper>
    </MailContent>
  </>
);

Mail.propTypes = {
  content: PropTypes.string.isRequired,
  documents: PropTypes.array,
  onDocumentPress: PropTypes.func.isRequired,
  createdOn: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  toolbarIcons: PropTypes.array,
  i18n: PropTypes.object.isRequired,
};

Mail.defaultProps = {
  documents: [],
  toolbarIcons: [],
};

export default withTheme(withTranslation(['mailbox'])(Mail));
