import React from 'react';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { withTheme } from 'styled-components/native';
import Margin from '@sp/ui/base/Margin';
import { Trans } from 'react-i18next';
import { Body, Link } from '@sp/ui/typography';
import { openLink } from 'helpers/linking';
import { ModalTitle, ModalRegularText, ModalBulletList } from '../modal/modalTextComponents';

const getLinkUrl = (targetType, i18n) => {
  switch (targetType) {
    case 'ISP':
      return i18n.t('link|adminCostsISP');
    case 'AP':
      return i18n.t('link|adminCostsAP');
    case 'PJD':
      return i18n.t('link|adminCostsPJD');
    case 'SP':
    default:
      return i18n.t('link|adminCostsSP');
  }
};

const AdminCosts = ({ openLatestDepotAccount, i18n, theme, type, companyName }) => {
  const adminCostOverview = [
    i18n.t('savings|Udsendelse af forsikringsoversigter'),
    i18n.t('savings|Rådgivning'),
    i18n.t('savings|Drift og vedligeholdelse af it-systemer'),
    i18n.t('savings|Administration af præmieindbetalinger'),
    i18n.t('savings|Indhentning og vurdering af helbredsoplysninger'),
    i18n.t('savings|Udbetaling af pension og forsikringsdækning'),
  ];

  return (
    <>
      <ModalTitle
        title={i18n.t('savings|Omkostninger')}
        subtitle={i18n.t('savings|Administrationsomkostninger')}
      />
      <ModalRegularText
        text={
          type === 'PJD' || type === 'AP'
            ? i18n.t(
                'savings|Der er udgifter for {{companyName}} i forbindelse med at du får oprettet en pensionsordning. Ligeledes er der løbende omkostninger til bl.a.:',
                { companyName }
              )
            : i18n.t(
                'savings|Der er udgifter i forbindelse med at du får oprettet en pensionsordning. Ligeledes er der løbende omkostninger til bl.a.:'
              )
        }
      />
      <Margin vertical="md">
        {adminCostOverview.map((item, i) => (
          <ModalBulletList removeMarginTop key={i} text={item} />
        ))}
      </Margin>
      <Body>
        <Trans i18nKey="savings|adminCosts">
          Du kan se din betaling til administration på dit{' '}
          <Link onPress={openLatestDepotAccount} color={theme.COLORS.PRIMARY_LINK}>
            depotregnskab
          </Link>
          , og du kan se{' '}
          <Link onPress={() => openLink(getLinkUrl(type, i18n))} color={theme.COLORS.PRIMARY_LINK}>
            omkostningssatserne
          </Link>{' '}
          på denne side.
        </Trans>
      </Body>
    </>
  );
};

AdminCosts.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  openLatestDepotAccount: PropTypes.func,
  type: PropTypes.string.isRequired,
  companyName: PropTypes.string,
};

AdminCosts.defaultProps = {
  openLatestDepotAccount: undefined,
  companyName: undefined,
};

export default withTheme(withComponentContainer()(AdminCosts));
