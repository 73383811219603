import React, { useCallback } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import withScreenContainer from 'decorators/withScreenContainer';
import * as routeNames from 'constants/routeNames';
import useI18n from 'hooks/useI18n';
import { useAppDispatch } from 'hooks/redux';
import { hardDeleteAllChildren } from 'actions/children';
import { deleteExternalData } from 'actions/profile';
import { deleteUserPensionsInfo } from 'actions/pensionsInfo';
import { refetchDataAfterSkatPI } from 'actions/dashboard';
import useAsyncCallback from 'hooks/useAsyncCallback';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import Tracking from 'services/tracking';
import { DELETE_SKAT_OR_PI, HARD_DELETE_CHILDREN_DATA } from 'areas/profile/trackingIds';

const InformationAboutDeletion = () => {
  const { i18n } = useI18n(['profile']);
  const navigation = useNavigation();
  const route = useRoute();
  const dispatch = useAppDispatch();

  const { deletionType } = route.params;

  const [deleteData] = useAsyncCallback(async () => {
    if (['pension', 'tax'].includes(deletionType)) {
      Tracking.trackEvent(DELETE_SKAT_OR_PI, { type: deletionType });

      const deleteActions = [deleteExternalData(deletionType)];

      if (deletionType === 'pension') {
        deleteActions.push(deleteUserPensionsInfo());
      }

      await dispatch(deleteActions);
    } else {
      Tracking.trackEvent(HARD_DELETE_CHILDREN_DATA);
      await dispatch(hardDeleteAllChildren());
    }

    await dispatch(refetchDataAfterSkatPI());
    navigation.goBack();
  }, [dispatch, deletionType]);

  const getContent = useCallback(() => {
    switch (deletionType) {
      case 'pension':
        return {
          heading: i18n.t('profile|deletePensionInformation'),
          description: i18n.t('profile|deletePensionInformationAreYouSure'),
        };
      case 'tax':
        return {
          heading: i18n.t('profile|deleteIncomeAndTaxInformation'),
          description: i18n.t('profile|deleteTaxInformationAreYouSure'),
        };
      default:
        return {
          heading: i18n.t('profile|hardDeleteChildren'),
          description: i18n.t('profile|deleteChildrenInformationAreYouSure'),
        };
    }
  }, [deletionType, i18n]);

  const { heading, description } = getContent();

  return (
    <ModalProvider isModal onClose={() => navigation.navigate(routeNames.PROFILE)}>
      <ScrollPage
        heading={heading}
        description={description}
        actions={[
          {
            text: i18n.t('cancel'),
            type: 'secondary',
            mobilePosition: 'bottom-sticky',
            onPress: () => navigation.goBack(),
          },
          {
            text: i18n.t('deleteData'),
            type: 'primary',
            mobilePosition: 'bottom-sticky',
            onPress: deleteData,
          },
        ]}
      />
    </ModalProvider>
  );
};

export default withScreenContainer()(InformationAboutDeletion);
