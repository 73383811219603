import React from 'react';
import PropTypes from 'prop-types';
import styledWeb from 'styled-components';
import styled from 'styled-components/native';

const Wrapper = styled.View`
  width: 100%;
  height: 100%;
`;

const IFrame = styledWeb.iframe`
  width: 100%;
  height: 100%;
`;

const Pdf = ({ url, styles }) => (
  <Wrapper styles={styles}>
    <IFrame title={url} src={url} />
  </Wrapper>
);

Pdf.propTypes = {
  url: PropTypes.isRequired,
  styles: PropTypes.object,
};

Pdf.defaultProps = {
  styles: undefined,
};

export default Pdf;
