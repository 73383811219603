import { RootState } from 'store';
import { DashboardTypes, RETIREMENT } from 'areas/dashboard/constants/dashboardTypes';

const { formatMoney } = require('helpers/string');

//NOTE: This is a separate type since we currently don't support showing pension payouts
export type PayoutDashboardTypes = 'disability' | 'death';

export const selectDataStatus = (
  state: RootState,
  type: DashboardTypes,
  fallback: any = undefined
) => (state.areas.dashboard.data[type] && state.areas.dashboard.data[type].status) || fallback;

export const selectError = (state: RootState, area: DashboardTypes) =>
  state.areas.dashboard.data[area] && state.areas.dashboard.data[area].error;

export const selectNoSatisfyingRecommendedPension = (state: RootState) => {
  const error = selectError(state, RETIREMENT);
  const dataStatus = selectDataStatus(state, RETIREMENT);
  return (
    error === undefined &&
    dataStatus &&
    dataStatus.noPensionAgeFoundWithPayoutSatisfyingRecommendedPensionVsTodayRatio
  );
};

export const selectDisposableIncomeBelowRecommendationLimit = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.status?.disposableIncomeBelowRecommendationLimit;

export const selectHasActiveDisabilityCoverage = (state: RootState) =>
  state.areas.dashboard?.data?.disability?.status?.sampensionDataHasBeenFetched &&
  state.areas.dashboard?.data?.disability?.status?.hasActiveDisabilityCoverage;

export const selectHasActiveTemporaryDisabilityCoverage = (state: RootState) =>
  state.areas.dashboard?.data?.disability?.status?.sampensionDataHasBeenFetched &&
  state.areas.dashboard?.data?.disability?.status?.hasActiveTemporaryDisabilityCoverage;

export const selectHasGrafiskPensionVurdererAwardRule = (state: RootState) =>
  state.areas.dashboard?.awardRules?.descriptions?.hasGrafiskPensionVurdererAwardRule;

export const selectNoRecommendationType = (state: RootState, area: DashboardTypes) => {
  if (area === RETIREMENT) {
    if (selectDisposableIncomeBelowRecommendationLimit(state)) {
      return 'disposableIncomeBelowRecommendationLimit';
    }
    if (selectNoSatisfyingRecommendedPension(state)) {
      return 'noSatisfyingRecommendedPension';
    }
  }

  if (area === 'disability') {
    if (selectHasActiveDisabilityCoverage(state)) {
      return 'hasActiveDisabilityCoverage';
    }
    if (selectHasActiveTemporaryDisabilityCoverage(state)) {
      return 'hasActiveTemporaryDisabilityCoverage';
    }
    if (selectHasActiveTemporaryDisabilityCoverage(state)) {
      return 'hasActiveTemporaryDisabilityCoverage';
    }
    if (selectHasGrafiskPensionVurdererAwardRule(state)) {
      return 'hasGrafiskPensionVurdererAwardRule';
    }

    if (selectDisposableIncomeBelowRecommendationLimit(state)) {
      return 'disposableIncomeBelowRecommendationLimit';
    }
  }

  return undefined;
};

export const selectCalculationSettings = (state: RootState) =>
  state.areas.dashboard.calculationSettings;

export const selectRetirementAge = (state: RootState) =>
  selectCalculationSettings(state)?.retirement?.age;

export const selectRetirementWealth = (state: RootState) =>
  selectCalculationSettings(state)?.retirement?.wealth;

export const selectNakedDashboardErrors = (state: RootState, area: DashboardTypes) => {
  const nakedDashboardErrorsToIgnore =
    state.areas.dashboard.data?.nakedDashboardErrorsToIgnore || [];
  const { dashboardErrors } = state.areas.dashboard.data[area];

  return {
    errors: dashboardErrors?.errors?.filter(
      (item) => !nakedDashboardErrorsToIgnore.includes(item.errorId)
    ),
    dashboardArea: area,
  };
};

export const selectIsNaked = (state: RootState, area: DashboardTypes) => {
  const nakedDashBoardErrors = selectNakedDashboardErrors(state, area)?.errors;
  return !nakedDashBoardErrors || nakedDashBoardErrors?.length > 0;
};

export const selectIgnoredDashboardErrors = (state: RootState) => {
  const { nakedDashboardErrorsToIgnore } = state.areas.dashboard.data;
  return nakedDashboardErrorsToIgnore;
};

export const selectMinimumRetirementAge = (state: RootState) =>
  state.areas.dashboard.data.retirement.allPensionYears &&
  Math.min(...state.areas.dashboard.data.retirement.allPensionYears.map((p) => p.pensionAge));

export const selectMaximumRetirementAge = (state: RootState) =>
  state.areas.dashboard.data.retirement.allPensionYears &&
  Math.max(...state.areas.dashboard.data.retirement.allPensionYears.map((p) => p.pensionAge));

export const selectRecommendedIncome = (state: RootState) =>
  state.areas.dashboard.data.retirement.recommendedIncome;

export const selectIncomeWhenRetired = (state: RootState) => {
  const retirementAge = selectRetirementAge(state);
  return retirementAge && state.areas.dashboard.data.retirement.allPensionYears
    ? state.areas.dashboard.data.retirement.allPensionYears.find(
        (p) => p.pensionAge === retirementAge
      )
    : undefined;
};

export const selectMaxPayout = (state: RootState, area: DashboardTypes) => {
  const showGrossAmount = selectShowGrossAmount(state);

  const incomeToday = selectIncomeToday(state);
  const amount = showGrossAmount ? incomeToday?.grossAmount : incomeToday?.amount;

  const maxTotalIncomePerYear = state.areas.dashboard?.data?.[area]?.allPensionYears?.map(
    (pensionYear) =>
      Math.max(
        ...pensionYear.perYear.map((payout) =>
          showGrossAmount
            ? payout?.roundedAveragePayoutMonth?.totalIncomeWithoutOneTimePayouts?.grossAmount || 0
            : payout?.roundedAveragePayoutMonth?.totalIncomeWithoutOneTimePayouts?.amount || 0
        )
      ) || 0
  );

  return maxTotalIncomePerYear ? Math.max(...maxTotalIncomePerYear, amount || 0) : undefined;
};

export const selectMaxIncomeWhenRetired = (state: RootState) => {
  const showGrossAmount = selectShowGrossAmount(state);
  const amounts = selectPayoutRetirement(state)?.map((el) =>
    showGrossAmount ? el?.grossAmount : el?.amount
  );

  return amounts ? Math.max(...amounts) : undefined;
};

export const selectHasDashboardData = (state: RootState, type: DashboardTypes = RETIREMENT) =>
  !!Object.keys(state.areas.dashboard.data[type]).length;

export const selectHasOneTimePayout = (state: RootState, type: PayoutDashboardTypes) => {
  const awardRules = state.areas.dashboard?.awardRules?.awardRules;

  return (
    awardRules &&
    awardRules.some(
      (item) =>
        item.scenarioEvent.toLowerCase() === type.toLowerCase() &&
        item.payoutType === 'Engangsudbetaling'
    )
  );
};

export const selectPayoutRetirement = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.allPensionYears;

export const selectPayoutDisability = (state: RootState) =>
  state.areas.dashboard?.data?.disability?.allPensionYears?.[0];

export const selectPayoutDeath = (state: RootState) =>
  state.areas.dashboard?.data?.death?.allPensionYears?.[0];

export const selectShouldDefaultValueEnableSaveButton = (
  state: RootState,
  area: DashboardTypes
) => {
  const error = selectError(state, area);
  return [
    'DisposableIncomeBelowLimitAndOptionHasNotBeenExplicitlySelected',
    'MedianOfSalarySlipsDifferFromAnnualStatementAndOptionHasNotBeenExplicitlySelected',
  ].includes(error);
};

export const selectPensionsInfoDataHasBeenFetched = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.status?.pensionsInfoDataHasBeenFetched;

export const selectTaxDataUpdateDate = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.status?.taxDataUpdateDate;

export const selectPensionsInfoDataUpdateDate = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.status?.pensionsInfoDataUpdateDate;

export const selectDisposableIncome = (state: RootState) => state.areas.dashboard?.income;

export const selectDisposableIncomeOptions = (state: RootState) => {
  const { disposableIncomeOptions } = selectDisposableIncome(state) ?? {};

  return disposableIncomeOptions
    ?.filter(({ source }) => source !== 'UserEnteredDisposableIncome')
    ?.concat(
      disposableIncomeOptions.filter(
        ({ source, monthlyAmount }) => source === 'UserEnteredDisposableIncome' && monthlyAmount
      )
    );
};

export const selectSalaryByIndex = (state: RootState, index: number) =>
  selectDisposableIncomeOptions(state)?.[index];

export const selectIncomeToday = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.incomeToday;

export const selectTotalIncomeToday = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.totalIncomeToday;

export const selectChildAndYouthBenefitToday = (state: RootState) =>
  state.areas.dashboard?.data?.retirement?.childAndYouthBenefitToday;

export const selectDefaultIdentifierOrSelectedIncomeIndex = (state: RootState) => {
  const { defaultIdentifier } = selectDisposableIncome(state) ?? {};
  const disposableIncomeOptions = selectDisposableIncomeOptions(state) ?? [];

  const selectedIncomeIndex = disposableIncomeOptions
    ?.map(({ selected }) => selected)
    ?.indexOf(true);
  const defaultIdentifierIndex = disposableIncomeOptions
    ?.map(({ identifier }) => identifier)
    ?.indexOf(defaultIdentifier);

  return selectedIncomeIndex > -1 ? selectedIncomeIndex : defaultIdentifierIndex;
};

export const selectShowGrossAmount = (state: RootState) =>
  state.areas.dashboard.income?.showGrossAmount || false;

export const selectSelectedSalary = (state: RootState) => {
  const disposableIncomeOptions = selectDisposableIncomeOptions(state) || [];
  return disposableIncomeOptions && disposableIncomeOptions.find(({ selected }) => selected);
};

export const selectSelectedMonthlySalary = (state: RootState) =>
  selectSelectedSalary(state)?.monthlyAmount;

export const selectUserEnteredIncome = (state: RootState) => {
  const selectedSalary = selectSelectedSalary(state);
  return selectedSalary && selectedSalary.source === 'UserEnteredDisposableIncome'
    ? selectedSalary.monthlyAmount
    : undefined;
};

export const selectAwardRules = (state: RootState) => state.areas.dashboard.awardRules;

export const selectAwardRulesDescriptions = (state: RootState) =>
  state.areas.dashboard?.awardRules?.descriptions || {};

export const selectPayoutsOneTimeAwardRules = (
  state: RootState,
  paymentType: PayoutDashboardTypes
) =>
  state.areas.dashboard?.awardRules?.awardRules?.filter(
    (item) =>
      item.payoutType === 'Engangsudbetaling' && paymentType === item.scenarioEvent.toLowerCase()
  );

export const selectPayoutsOverTimeAwardRules = (
  state: RootState,
  paymentType: PayoutDashboardTypes
) =>
  state.areas.dashboard?.awardRules?.awardRules?.filter(
    (item) =>
      (item.payoutType === 'OphørendeUdbetaling' || item.payoutType === 'LivsvarigUdbetaling') &&
      paymentType === item.scenarioEvent.toLowerCase()
  );

export const selectAllAwardRules = (state: RootState, paymentType: PayoutDashboardTypes) =>
  state.areas.dashboard?.awardRules?.awardRules?.filter(
    (item) => paymentType === item.scenarioEvent.toLowerCase()
  );

export const selectAwardRulesIsFetching = (state: RootState) =>
  state.areas.dashboard?.awardRulesIsFetching;

export const selectAwardRulesSucceeded = (state: RootState) =>
  state.areas.dashboard?.awardRules?.succeeded;

export const selectAwardRulesDisclaimers = (
  state: RootState,
  paymentType: PayoutDashboardTypes
) => {
  const descriptions = state.areas.dashboard?.awardRules?.descriptions;

  switch (paymentType) {
    case 'disability':
      return descriptions?.disabilityAwardRuleDisclaimerText;
    case 'death':
      return descriptions?.deathAwardRuleDisclaimerText;
    default:
      return undefined;
  }
};

export const selectFailedCoverageOverview = (state: RootState, area: DashboardTypes) =>
  state.areas.dashboard.data[area]?.failedCoverageOverview;

export const selectUserEnteredDisposableIncome = (state: RootState) =>
  selectDisposableIncomeOptions(state)?.filter(
    ({ source }) => source === 'UserEnteredDisposableIncome'
  )[0];

export const selectManualEnteredUserValuesOrSelectedIncomeRow = (state: RootState) => {
  const disposableIncomeOptions = selectDisposableIncomeOptions(state);
  const userEnteredDisposableIncome = selectUserEnteredDisposableIncome(state);
  const defaultIdentifierOrSelectedIncomeIndex =
    selectDefaultIdentifierOrSelectedIncomeIndex(state);

  const { amount, grossAmount, currencyCode } = userEnteredDisposableIncome?.monthlyAmount ?? {};

  const hasUserEnteredValues = amount || grossAmount;
  const hasUserSelectedIncomeRow = defaultIdentifierOrSelectedIncomeIndex > -1;

  if (hasUserEnteredValues) {
    return {
      amount: formatMoney({ amount, currencyCode }, true),
      grossAmount: formatMoney({ amount: grossAmount, currencyCode }, true),
    };
  } else if (hasUserSelectedIncomeRow) {
    const {
      amount: selectedAmount,
      grossAmount: selectedGrossAmount,
      currencyCode: selectedCurrenyCode,
    } = disposableIncomeOptions?.[defaultIdentifierOrSelectedIncomeIndex]?.monthlyAmount;

    return {
      amount: formatMoney({ amount: selectedAmount, currencyCode: selectedCurrenyCode }, true),
      grossAmount: formatMoney(
        { amount: selectedGrossAmount, currencyCode: selectedCurrenyCode },
        true
      ),
    };
  }

  return {};
};
