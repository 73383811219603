import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import useI18n from 'hooks/useI18n';
import { initFunc } from 'helpers/props';
import { ButtonGroup } from '@sp/ui/base/buttons';
import { SortToggle } from 'containers/base/buttons';
import Margin from '@sp/ui/base/Margin';

const SectionWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ButtonGroupWrapper = styled.View`
  flex: 1;
`;

const OverviewHeader = ({ source, onSourceSelect, onSortPress }) => {
  const { i18n } = useI18n(['mailbox']);

  return (
    <Margin marginTop="lg" marginBottom="md" horizontal="md">
      <SectionWrapper>
        <Margin marginRight="md">
          <SortToggle label={i18n.t('common|date')} onPress={onSortPress} />
        </Margin>
        <ButtonGroupWrapper>
          <ButtonGroup
            buttons={[
              {
                text: i18n.t('mailbox|inbox'),
                isSelected: source === 'inbox',
                onPress: () => onSourceSelect('inbox'),
                testID: 'mailboxInboxFilterLink',
              },
              {
                text: i18n.t('mailbox|archive'),
                isSelected: source === 'archive',
                onPress: () => onSourceSelect('archive'),
                testID: 'mailboxArchiveFilterLink',
              },
            ]}
          />
        </ButtonGroupWrapper>
      </SectionWrapper>
    </Margin>
  );
};

OverviewHeader.propTypes = {
  source: PropTypes.string.isRequired,
  onSourceSelect: PropTypes.func.isRequired,
  onSortPress: initFunc,
};

OverviewHeader.defaultProps = {
  onSortPress: () => {},
};

export default withTheme(OverviewHeader);
