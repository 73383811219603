import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'services/i18n';
import { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import GraphPage from '@sp/ui/pages/GraphPage';
import { fetchRetirement, getAwardRules } from 'actions/digital-advice';
import { getCalculationProfile } from 'actions/calculations';
import { PENSION_SCREEN } from 'constants/testIds/dashboard';
import * as routeNames from 'constants/routeNames';
import {
  selectIsNaked,
  selectIncomeToday,
  selectTotalIncomeToday,
  selectChildAndYouthBenefitToday,
  selectRecommendedIncome,
  selectRetirementAge,
  selectIncomeWhenRetired,
  selectHasDashboardData,
  selectDisposableIncomeOptions,
  selectError,
  selectNakedDashboardErrors,
  selectShowGrossAmount,
  selectNoRecommendationType,
  selectAwardRules,
} from 'selectors/dashboard';
import { getIncome, refetchNakedDashboardErrors } from 'actions/dashboard';
import { getChildren } from 'actions/children';
import TopTabBar from 'containers/dashboard/TopTabBar';
import Dashboard from 'areas/dashboard/Dashboard';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectHasChildrenPayoutPolicies } from 'selectors/childrenPayouts';
import { selectChildrenData } from 'selectors/children';
import Margin from '@sp/ui/base/Margin';
import Naked from 'areas/dashboard/dashboards/Naked';
import getGraphData from 'areas/dashboard/dashboards/Retirement/getGraphData';
import { RETIREMENT } from '../../constants/dashboardTypes';

const FOCUSED_TAB = routeNames.RETIREMENT;

const Retirement = ({
  isNaked,
  retirementAge,
  navigation,
  incomeWhenRetired,
  recommendedIncome,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  theme,
  isDesktop,
  nakedDashboardErrors,
  showGrossAmount,
  noRecommendationType,
  hasChildrenPayouts,
}) => {
  if (isNaked) {
    return (
      <Naked
        focusedTab={FOCUSED_TAB}
        nakedDashboardErrors={nakedDashboardErrors}
        navigation={navigation}
      />
    );
  }

  const graphData = getGraphData({
    navigation,
    incomeWhenRetired,
    retirementAge,
    recommendedIncome,
    incomeToday,
    totalIncomeToday,
    childAndYouthBenefitToday,
    theme,
    noRecommendationType,
    hasChildrenPayouts,
    showGrossAmount,
  });

  return (
    <GraphPage
      hasBackButton={false}
      hasHeader={false}
      tabBar={<TopTabBar navigation={navigation} focusedTab={FOCUSED_TAB} />}
      header={null}
      testID={PENSION_SCREEN}
      wideContent={<Dashboard graphData={graphData} />}
    >
      {isDesktop && <Margin marginTop="lg" />}
    </GraphPage>
  );
};

Retirement.propTypes = {
  isNaked: PropTypes.bool,
  incomeWhenRetired: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
  }),
  incomeToday: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  totalIncomeToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    grossAmount: PropTypes.number.isRequired,
  }),
  childAndYouthBenefitToday: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }),
  retirementAge: PropTypes.number,
  recommendedIncome: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    pensionVsTodayRatio: PropTypes.number,
    pensionAge: PropTypes.number.isRequired,
  }),
  navigation: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  nakedDashboardErrors: PropTypes.object.isRequired,
  showGrossAmount: PropTypes.bool.isRequired,
  hasChildrenPayouts: PropTypes.bool,
  noRecommendationType: PropTypes.string,
};

Retirement.defaultProps = {
  isNaked: false,
  retirementAge: undefined,
  incomeWhenRetired: undefined,
  incomeToday: {
    amount: 0,
    grossAmount: 0,
    currency: 'DKK',
  },
  totalIncomeToday: undefined,
  childAndYouthBenefitToday: undefined,
  recommendedIncome: {
    amount: 0,
    currency: 'DKK',
    pensionAge: 0,
    pensionVsTodayRatio: undefined,
  },
  hasChildrenPayouts: false,
  noRecommendationType: undefined,
};

const mapStateToProps = (state) => {
  const payoutType = RETIREMENT;

  return {
    isNaked: selectIsNaked(state, payoutType),
    retirementAge: selectRetirementAge(state),
    incomeToday: selectIncomeToday(state),
    totalIncomeToday: selectTotalIncomeToday(state),
    childAndYouthBenefitToday: selectChildAndYouthBenefitToday(state),
    recommendedIncome: selectRecommendedIncome(state),
    incomeWhenRetired: selectIncomeWhenRetired(state),
    hasDashboardDataRetirement: selectHasDashboardData(state),
    hasDisposableIncomeOptions: selectDisposableIncomeOptions(state),
    isDesktop: selectIsDeviceTypeDesktop(state),
    error: selectError(state, payoutType),
    nakedDashboardErrors: selectNakedDashboardErrors(state, payoutType),
    noRecommendationType: selectNoRecommendationType(state, payoutType),
    awardRules: selectAwardRules(state),
    hasChildrenPayouts: selectHasChildrenPayoutPolicies(state, payoutType),
    hasChildrenData: selectChildrenData(state),
    showGrossAmount: selectShowGrossAmount(state),
  };
};

export default withTheme(
  withScreenContainer({
    mapStateToProps,
    i18ns: ['dashboard'],
    init: ({ action, getProps }) =>
      action(
        async () => {
          const {
            dispatch,
            retirementAge,
            hasDashboardDataRetirement,
            hasDisposableIncomeOptions,
            hasChildrenData,
            awardRules,
          } = getProps();

          const dispatchArray = [];

          if (!retirementAge) {
            dispatchArray.push(getCalculationProfile());
          }

          if (!hasDisposableIncomeOptions) {
            dispatchArray.push(getIncome());
          }

          if (!hasDashboardDataRetirement) {
            dispatchArray.push(fetchRetirement());
          }

          if (!awardRules) {
            dispatchArray.push(getAwardRules());
          }

          if (!hasChildrenData) {
            dispatchArray.push(getChildren());
          }

          if (dispatchArray.length > 0) {
            await dispatch(dispatchArray);
          }

          dispatch(refetchNakedDashboardErrors(RETIREMENT));
        },
        {
          loader: true,
          loaderProps: {
            text: i18n.t('dashboard|waitForCalculation'),
            color: getProps().theme.COLORS.SECONDARY_BACKGROUND,
          },
          error: {
            type: 'overlay',
            retry: true,
          },
        }
      ),
  })(Retirement)
);
