import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from 'decorators/i18n';
import { FormSectionWithModal as UIFormSectionWithModal } from '@sp/ui/layout/forms';

const FormSectionWithModal = ({ children, i18n, ...props }) => (
  <UIFormSectionWithModal {...props} modalAccessibilityLabel={i18n.t('accessibility|Luk info')}>
    {children}
  </UIFormSectionWithModal>
);

FormSectionWithModal.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.object.isRequired,
};

FormSectionWithModal.defaultProps = {
  children: undefined,
};

export default withTranslation()(FormSectionWithModal);
