import React, { useState } from 'react';
import withTranslation from 'decorators/i18n';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { PickerButton } from '@sp/ui/base/buttons';
import InputPage from '@sp/ui/pages/InputPage';
import Input from 'containers/form/Input';
import { Form } from 'react-formed';
import Selector from 'containers/form/Selector';
import Message from 'containers/form/Message';
import Picker from 'containers/form/Picker';
import { ActionShape } from '@sp/ui/pages/BasePage';
import { isIOS } from 'helpers/platform';

const TEXT_MESSAGE_TOP_MARGIN = 40;
const MESSAGE_MIN_HEIGHT = 316;

const Content = styled.View`
  padding: 0px ${(props) => props.theme.SPACINGS.xl}px ${(props) => props.theme.SPACINGS.xl}px;
`;

const PickerPage = ({
  formName,
  formInitValues,
  resetFormOnMount,
  pickerOptions,
  actions,
  title,
  heading,
  description,
  pickerPlaceholder,
  messagePlaceholder,
  pickerFormValue,
  backButton,
  hasBackButton,
  children,
  attachments,
  onSelect,
  onAttachmentDeletePress,
  customSubject,
  hasCustomSubject,
  hasContentError,
  i18n,
}) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [hasInteractedWithPicker, setHasInteractedWithPicker] = useState(false);

  return (
    <Form name={formName} initValues={formInitValues} resetOnMount={resetFormOnMount}>
      <InputPage
        title={title}
        heading={heading}
        description={description}
        actions={actions}
        backButton={backButton}
        hasBackButton={hasBackButton}
      >
        <Content>
          {isIOS ? (
            <PickerButton
              name="subject"
              placeholder={pickerPlaceholder}
              value={
                pickerFormValue || (hasInteractedWithPicker && pickerOptions && pickerOptions[0])
              }
              onPress={() => {
                setIsPickerOpen(true);
                Keyboard.dismiss();
                setHasInteractedWithPicker(true);
              }}
              isPickerOpen={isPickerOpen}
            />
          ) : (
            <Selector
              name="subject"
              placeholder={pickerPlaceholder}
              options={pickerOptions}
              onSelect={(option) => (typeof onSelect === 'function' ? onSelect(option) : undefined)}
            />
          )}
          {hasCustomSubject && (
            <Input name="customSubject" placeholder={i18n.t('Emne')} defaultValue={customSubject} />
          )}
          <Message
            name="content"
            onFocus={() => setIsPickerOpen(false)}
            hasError={hasContentError}
            placeholder={messagePlaceholder}
            marginTop={TEXT_MESSAGE_TOP_MARGIN}
            minHeight={MESSAGE_MIN_HEIGHT}
            attachments={attachments}
            onAttachmentDeletePress={onAttachmentDeletePress}
            deleteImageText={i18n.t('Fjern billede')}
          />
          {children}
        </Content>
      </InputPage>
      {isIOS && isPickerOpen && (
        <Picker
          options={pickerOptions}
          name="subject"
          submitTitle={i18n.t('OK')}
          onSubmit={(option) => {
            setIsPickerOpen(false);
            if (typeof onSelect === 'function') {
              onSelect(option || (hasInteractedWithPicker && pickerOptions && pickerOptions[0]));
            }
          }}
        />
      )}
    </Form>
  );
};

PickerPage.propTypes = {
  formName: PropTypes.string.isRequired,
  formInitValues: PropTypes.object,
  resetFormOnMount: PropTypes.bool,
  pickerOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  actions: PropTypes.arrayOf(ActionShape),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  pickerPlaceholder: PropTypes.string,
  pickerFormValue: PropTypes.string,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func,
  onAttachmentDeletePress: PropTypes.func,
  hasCustomSubject: PropTypes.bool,
  hasContentError: PropTypes.bool,
  customSubject: PropTypes.string,
  i18n: PropTypes.object.isRequired,
};

PickerPage.defaultProps = {
  formInitValues: undefined,
  resetFormOnMount: false,
  actions: undefined,
  title: undefined,
  heading: undefined,
  description: undefined,
  messagePlaceholder: undefined,
  pickerPlaceholder: undefined,
  pickerFormValue: undefined,
  backButton: undefined,
  hasBackButton: true,
  children: undefined,
  attachments: undefined,
  onSelect: undefined,
  onAttachmentDeletePress: undefined,
  hasCustomSubject: false,
  hasContentError: false,
  customSubject: undefined,
};

export default withTranslation()(PickerPage);
