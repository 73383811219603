import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { StyleSheet, View, Text } from 'react-native';
import Markdown, { getUniqueID, openUrl, hasParents } from 'react-native-markdown-display';
import { H1, H2, H5, H6, Label } from '@sp/ui/typography';
import { H3, H4, Body, Body2 } from '@sp/ui/v2/typography';
import Icon from '@sp/ui/base/Icon';
import fontStyles from '@sp/ui/typography/fontStyles';
import { isWeb } from '@sp/ui/helpers/device';

const LINK_ICON_PADDING = 2;

const LinkIconWrapper = styled.TouchableOpacity`
  padding-left: ${LINK_ICON_PADDING}px;
`;

const rules = (markdownUrlHandler, theme, isDesktop, useBody2) => ({
  heading1: (node, children, parent, styles) => (
    <H1 key={getUniqueID()} style={[styles.heading, styles.heading1]}>
      {children}
    </H1>
  ),
  heading2: (node, children, parent, styles) => (
    <H2 key={getUniqueID()} style={[styles.heading, styles.heading2]}>
      {children}
    </H2>
  ),
  heading3: (node, children, parent, styles) => (
    <H3 key={getUniqueID()} style={[styles.heading, styles.heading3]}>
      {children}
    </H3>
  ),
  heading4: (node, children, parent, styles) => (
    <H4 key={getUniqueID()} style={[styles.heading, styles.heading4]}>
      {children}
    </H4>
  ),
  heading5: (node, children, parent, styles) => (
    <H5 key={getUniqueID()} style={[styles.heading, styles.heading5]}>
      {children}
    </H5>
  ),
  heading6: (node, children, parent, styles) => (
    <H6 key={getUniqueID()} style={[styles.heading, styles.heading6]}>
      {children}
    </H6>
  ),
  link: (node, children) => {
    const onPress = () => {
      const url = node.attributes.href;

      if (markdownUrlHandler) {
        markdownUrlHandler(url, openUrl);
      } else {
        openUrl(url);
      }
    };

    return (
      <>
        {
          // NOTE: Manually going through children and adding onPress for each element
          // Reason for that is that android does not support custom styling on nested <Text>
          // https://github.com/facebook/react-native/issues/20398
          React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                style: {
                  textDecorationLine: 'underline',
                },
                onPress,
              });
            }
            return child;
          })
        }
        <LinkIconWrapper onPress={onPress}>
          <Icon
            name="link"
            width={17}
            height={17}
            fill={isDesktop ? theme.COLORS.V2_SECONDARY_TEXT : theme.COLORS.V2_PRIMARY_TEXT}
          />
        </LinkIconWrapper>
      </>
    );
  },
  span: (node, children, parent, styles) => (
    <Label key={getUniqueID()} style={[styles.span, styles.span]}>
      {children}
    </Label>
  ),
  paragraph: (node, children, parent, styles) => {
    const Paragraph = useBody2 ? Body2 : Body;

    return (
      <Paragraph
        key={node.key}
        style={styles.paragraph}
        fontWeight={isDesktop || isWeb ? 'regular' : 'semibold'}
      >
        {children}
      </Paragraph>
    );
  },
  // NOTE: this is copied from https://github.com/iamacup/react-native-markdown-display/blob/master/src/lib/renderRules.js#L211
  // we need to override this to support styling on web platform which was missing
  list_item: (node, children, parent, styles) => {
    if (hasParents(parent, 'bullet_list')) {
      return (
        <View key={node.key} style={styles._VIEW_SAFE_list_item}>
          <View style={[styles.text, styles.bullet_list_icon]} />
          <View style={styles._VIEW_SAFE_bullet_list_content}>{children}</View>
        </View>
      );
    }

    if (hasParents(parent, 'ordered_list')) {
      const orderedListIndex = parent.findIndex((el) => el.type === 'ordered_list');

      const orderedList = parent[orderedListIndex];
      let listItemNumber;

      if (orderedList.attributes && orderedList.attributes.start) {
        listItemNumber = orderedList.attributes.start + node.index;
      } else {
        listItemNumber = node.index + 1;
      }

      return (
        <View key={node.key} style={styles._VIEW_SAFE_list_item}>
          <Text style={[styles.text, styles.ordered_list_icon]}>
            {listItemNumber}
            {node.markup}
          </Text>
          <View style={styles._VIEW_SAFE_ordered_list_content}>{children}</View>
        </View>
      );
    }

    // we should not need this, but just in case
    return (
      <View key={node.key} style={styles._VIEW_SAFE_list_item}>
        {children}
      </View>
    );
  },
});

// NOTE: There is no merge of the style properties, if you specify a style property,
// it will completely overwrite existing styles for that property.
// We need to add custom styling for extra spacings and offsets defined for markdown pages
const createStyles = (theme, customStyles, isDesktop, secondaryColor) => {
  const textColor = secondaryColor ? theme.COLORS.V2_SECONDARY_TEXT : theme.COLORS.V2_PRIMARY_TEXT;

  return StyleSheet.create({
    heading1: isDesktop
      ? {
          marginBottom: theme.SPACINGS.lg,
        }
      : {
          color: textColor,
          marginBottom: theme.SPACINGS.lg,
        },
    heading2: isDesktop
      ? {
          marginBottom: theme.SPACINGS.lg,
        }
      : {
          color: textColor,
          marginTop: theme.SPACINGS.lg,
          marginBottom: theme.SPACINGS.lg,
        },
    heading3: isDesktop
      ? {
          marginBottom: theme.SPACINGS.lg,
        }
      : {
          color: textColor,
          marginTop: theme.SPACINGS.lg,
          marginBottom: theme.SPACINGS.lg,
        },
    heading4: {
      marginBottom: theme.SPACINGS.md,
    },
    heading5: {
      fontSize: theme.FONT_SIZES.H5.MOBILE,
      marginBottom: theme.SPACINGS.md,
    },
    heading6: {
      fontSize: theme.FONT_SIZES.H6.MOBILE,
      marginBottom: theme.SPACINGS.md,
    },
    paragraph: isDesktop
      ? {
          marginBottom: theme.SPACINGS.md,
          ...(isWeb ? fontStyles('normal') : {}),
        }
      : {
          color: textColor,
          marginBottom: theme.SPACINGS.md,
          ...(isWeb ? fontStyles('normal') : {}),
        },
    bullet_list_icon: {
      marginLeft: theme.SPACINGS.sm,
      marginRight: theme.SPACINGS.md,
      marginTop: 9,
      height: 6,
      width: 6,
      borderRadius: 6,
      backgroundColor:
        isDesktop || secondaryColor ? theme.COLORS.V2_SECONDARY_TEXT : theme.COLORS.V2_PRIMARY_TEXT,
    },
    ordered_list_icon: {
      marginLeft: theme.SPACINGS.sm,
      marginRight: theme.SPACINGS.md,
      fontSize: theme.FONT_SIZES.V2_BODY.DEFAULT,
      lineHeight: theme.FONT_SIZES.V2_BODY.DEFAULT * 1.5,
      color:
        isDesktop || secondaryColor ? theme.COLORS.V2_SECONDARY_TEXT : theme.COLORS.V2_PRIMARY_TEXT,
    },
    list_item: {
      flexDirection: 'row',
    },
    bullet_list_content: {
      flex: 1,
      marginBottom: theme.SPACINGS.s,
    },
    ordered_list_content: {
      flex: 1,
      marginBottom: theme.SPACINGS.s,
    },
    ...customStyles,
  });
};

const MarkdownComponent = ({
  children,
  theme,
  styles,
  markdownUrlHandler,
  secondaryColor,
  useBody2,
  isDesktop,
}) => {
  const style = createStyles(theme, styles, isDesktop, secondaryColor);

  // NOTE: defensive programming to prevent rendering with null
  if (!children) {
    return null;
  }

  return (
    <Markdown
      rules={rules(markdownUrlHandler, theme, isDesktop, useBody2)}
      style={style}
      mergeStyle={false}
    >
      {children}
    </Markdown>
  );
};

MarkdownComponent.propTypes = {
  children: PropTypes.string,
  theme: PropTypes.object.isRequired,
  markdownUrlHandler: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
  secondaryColor: PropTypes.bool,
  useBody2: PropTypes.bool,
  styles: PropTypes.object,
};

MarkdownComponent.defaultProps = {
  children: undefined,
  markdownUrlHandler: undefined,
  secondaryColor: false,
  useBody2: false,
  styles: {},
};

export default withTheme(MarkdownComponent);
