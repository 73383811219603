import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/v2/typography/index.shared';

const H3 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.V2_H3.DEFAULT}px;
  line-height: ${(props) => props.theme.FONT_SIZES.V2_H3.DEFAULT * 1.5}px;
  font-style: ${(props) => props.fontStyle};
`;

H3.defaultProps = {
  fontWeight: 'bold',
  fontStyle: 'normal',
};

export default H3;
