// Adjust calculation
export const ADJUST_CALCULATION_SCREEN = 'adjust-calculation-screen';
export const ADJUST_CALCULATION_RETIREMENT_AGE_SCREEN = 'adjust-calculation-retirement-age-screen';
export const ADJUST_CALCULATION_CHILDREN_SCREEN = 'adjust-calculation-children-screen';
export const ADJUST_CALCULATION_DISPOSABLE_INCOME_SCREEN =
  'adjust-calculation-disposable-income-screen';
export const ADJUST_CALCULATION_PUBLIC_SERVICES_SCREEN =
  'adjust-calculation-public-services-screen';
export const ADJUST_CALCULATION_TAXATION_SCREEN = 'adjust-calculation-taxation-screen';
export const ADJUST_CALCULATION_SAVINGS_SCREEN = 'adjust-calculation-savings-screen';
export const ADJUST_CALCULATION_TAX_INFO_SCREEN = 'adjust-calculation-tax-info-screen';

// Adjust calculation rows
export const ADJUST_CALCULATION_RETIREMENT_AGE_ROW = 'adjust-calculation-retirement-age-row';
export const ADJUST_CALCULATION_CHILDREN_ROW = 'adjust-calculation-children-row';
export const ADJUST_CALCULATION_DISPOSABLE_INCOME_ROW = 'adjust-calculation-disposable-income-row';
export const ADJUST_CALCULATION_PUBLIC_SERVICES_ROW = 'adjust-calculation-public-services-row';
export const ADJUST_CALCULATION_TAXATION_ROW = 'adjust-calculation-taxation-row';
export const ADJUST_CALCULATION_TAX_INFO_ROW = 'adjust-calculation-tax-information-row';
export const ADJUST_CALCULATION_SAVINGS_ROW = 'adjust-calculation-savings-row';

// Adjust calculation retirement age
export const ADJUST_CALCULATION_RETIREMENT_AGE_SELECTOR =
  'adjust-calculation-retirement-age-selector';
export const ADJUST_CALCULATION_RETIREMENT_AGE_INCREMENT_BUTTON =
  'adjust-calculation-retirement-age-increment-button';
export const ADJUST_CALCULATION_RETIREMENT_AGE_DECREMENT_BUTTON =
  'adjust-calculation-retirement-age-decrement-button';

// Adjust calculation children
export const ADJUST_CALCULATION_CHILDREN_MODAL = 'adjust-calculation-children-modal';
export const ADJUST_CALCULATION_CHILDREN_OPEN_MODAL = 'adjust-calculation-children-open-modal';
export const ADJUST_CALCULATION_CHILDREN_CLOSE_MODAL = 'adjust-calculation-children-close-modal';
export const ADJUST_CALCULATION_CHILDREN_ADD_CHILDREN_BUTTON =
  'adjust-calculation-children-add-children-button';
export const ADJUST_CALCULATION_CHILDREN_ADD_SCREEN = 'adjust-calculation-children-add-screen';

// Adjust calculation children add or edit child
export const ADD_CHILD_BUTTON = 'add-child-button';
export const ADD_OR_EDIT_CHILD_NAME_INPUT = 'add-or-edit-child-name-input';
export const ADD_OR_EDIT_CHILD_DATE_PICKER = 'add-or-edit-child-date-picker';

// Adjust calculation diposable income
export const DISPOSABLE_INCOME_SAVE_CHANGES_BUTTON = 'disposable-income-save-changes-button';
export const DISPOSABLE_INCOME_ENTER_OWN_INCOME_BUTTON =
  'disposable-income-enter-own-income-button';
export const DISPOSABLE_INCOME_ANNUAL_STATEMENT_INFO_BUTTON =
  'disposable-income-annual-statement-button';
export const DISPOSABLE_INCOME_MONTHLY_STATEMENT_INFO_BUTTON =
  'disposable-income-monthly-statement-button';
export const DISPOSABLE_INCOME_MONTHLY_INCOME_MODAL = 'disposable-income-annual-income-modal';
export const DISPOSABLE_INCOME_ANNUAL_INCOME_MODAL = 'disposable-income-monthly-income-modal';
export const DISPOSABLE_INCOME_CLOSE_MODAL = 'disposable-income-close-modal';
export const DISPOSABLE_INCOME_AFTER_TAX_INPUT = 'disposable-income-after-tax-input';
export const DISPOSABLE_INCOME_BEFORE_TAX_INPUT = 'disposable-income-before-tax-input';
export const DISPOSABLE_INCOME_FOOTER_WARNING = 'disposable-income-footer-warning';

// Adjust calculation public services
export const PUBLIC_SERVICES_SWITCH = 'public-services-switch';
export const PUBLIC_SERVICES_PENSION_BASIC_AMOUNT = 'public-services-pension-basic-amount';
export const PUBLIC_SERVICES_PENSION_SUPPLEMENT = 'public-services-pension-supplement';
export const PUBLIC_SERVICES_ABROAD = 'public-services-abroad';
export const PUBLIC_SERVICES_EDERLY_CHECK = 'public-services-elderly-check';
export const PUBLIC_SERVICES_CHILDREN_AND_YOUTH_SERVICES =
  'public-services-children-and-youth-services';

// Adjust calculation taxation
export const TAXATION_SAVE_CHANGES_BUTTON = 'taxation-save-changes-button';
export const TAXATION_MUNICIPALITY_SELECTOR_BUTTON = 'taxation-municipality-selector-button';
export const TAXATION_MUNICIPALITY_SELECTOR_ITEM = 'taxation-municipality-selector-item';

// Adjust calculation savings
export const SAVINGS_SAVE_CHANGES_BUTTON = 'savings-save-changes-button';
export const SAVINGS_INPUT = 'savings-input';

// Adjust calculation tax info
export const TAX_INFO_SELECT_BEFORE_TAX = 'tax-info-select-before-tax';
export const TAX_INFO_SAVE_CHANGES_BUTTON = 'tax-info-save-changes-button';

// General
export const ADJUST_CALCULATION_GO_BACK = 'adjust-calculation-go-back';

// Bottom sheet
export const CLOSE_BOTTOM_SHEET = 'close-bottom-sheet';
