import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useAnimatedRef, useSharedValue } from 'react-native-reanimated';
import RNAndroidKeyboardAdjust from 'rn-android-keyboard-adjust';
import { useIsFocused } from '@react-navigation/native';

const { default: ScrollPage } = require('@sp/ui/v2/pages/ScrollPage');
const { BottomSheet, SnapEffect } = require('@sp/ui/v2/animations');

const isAndroid = Platform.OS === 'android';

type Props = {
  actions?: any[];
  title?: string;
  heading?: string;
  testID?: string;
  actionsInfo?: string;
  actionsContent?: React.ReactNode;
  backButton?: React.ReactNode;
  backgroundImage?: React.ReactNode;
  menu?: React.ReactNode;
  hasBackButton?: boolean;
  hasBottomSheet?: boolean;
  smoothAppearance?: {
    waitForContent: boolean;
    emptyContentHeight?: number;
  };
  keyboardAdjustResize?: boolean;
  shouldOpenBottomSheetIfClosed?: boolean;
  shouldCloseBottomSheetIfOpen?: boolean;
  initializeBottomSheetAsClosed?: boolean;
  contentResizeHeightOnFocusedInputField?: number;
  takeUpAllAvailableSpaceWhenKeyboardIsVisible?: boolean;
  footerWarning?: React.ReactNode;
  isBottomSheetInactive?: boolean;
  onScroll?: () => void;
  tabBar?: React.ReactNode;
  children: React.ReactNode;
};

const BottomSheetPage: React.FC<Props> = ({
  testID,
  title,
  heading,
  actions,
  actionsContent,
  actionsInfo,
  contentResizeHeightOnFocusedInputField,
  takeUpAllAvailableSpaceWhenKeyboardIsVisible,
  backgroundImage,
  footerWarning,
  hasBackButton,
  hasBottomSheet,
  smoothAppearance,
  onScroll,
  initializeBottomSheetAsClosed,
  isBottomSheetInactive,
  shouldOpenBottomSheetIfClosed,
  shouldCloseBottomSheetIfOpen,
  backButton,
  keyboardAdjustResize,
  menu,
  children,
  tabBar,
}) => {
  const scrollViewRef = useAnimatedRef();
  const scrollY = useSharedValue(0);
  const bottomSheetHeight = useSharedValue(0);
  const snapEffectDirection = useSharedValue('');

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isAndroid && keyboardAdjustResize) {
      if (isFocused) {
        RNAndroidKeyboardAdjust.setAdjustResize();
      } else {
        RNAndroidKeyboardAdjust.setAdjustPan();
      }
    }
  }, [keyboardAdjustResize, isFocused]);

  return (
    <>
      <ScrollPage
        title={title}
        heading={heading}
        tabBar={tabBar}
        hasBackButton={hasBackButton}
        backButton={backButton}
        onScroll={onScroll}
        bottomSheetOuterScrollY={scrollY}
        scrollViewRef={scrollViewRef}
        backgroundImage={backgroundImage}
        menu={menu}
      >
        <SnapEffect cardHeight={bottomSheetHeight} snapEffectDirection={snapEffectDirection}>
          {children}
        </SnapEffect>
      </ScrollPage>
      {hasBottomSheet && (
        <BottomSheet
          testID={testID}
          smoothAppearance={smoothAppearance}
          actions={actions}
          actionsContent={actionsContent}
          actionsInfo={actionsInfo}
          takeUpAllAvailableSpaceWhenKeyboardIsVisible={
            takeUpAllAvailableSpaceWhenKeyboardIsVisible
          }
          contentResizeHeightOnFocusedInputField={contentResizeHeightOnFocusedInputField}
          isBottomSheetInactive={isBottomSheetInactive}
          initializeBottomSheetAsClosed={initializeBottomSheetAsClosed}
          outerScrollEvent={{ scrollY }}
          openBottomSheet={shouldOpenBottomSheetIfClosed && isFocused}
          closeBottomSheet={shouldCloseBottomSheetIfOpen && isFocused}
          snapEffectDirection={snapEffectDirection}
          scrollViewRef={scrollViewRef}
          footerWarning={footerWarning}
        />
      )}
    </>
  );
};

BottomSheetPage.defaultProps = {
  hasBottomSheet: true,
  isBottomSheetInactive: false,
  initializeBottomSheetAsClosed: false,
  shouldOpenBottomSheetIfClosed: false,
  shouldCloseBottomSheetIfOpen: false,
  takeUpAllAvailableSpaceWhenKeyboardIsVisible: false,
  actions: undefined,
  title: undefined,
  heading: undefined,
  testID: undefined,
  actionsInfo: undefined,
  actionsContent: undefined,
  backButton: undefined,
  backgroundImage: undefined,
  menu: undefined,
  hasBackButton: undefined,
  keyboardAdjustResize: undefined,
  contentResizeHeightOnFocusedInputField: undefined,
  footerWarning: undefined,
  onScroll: undefined,
  smoothAppearance: undefined,
  tabBar: undefined,
};

export default BottomSheetPage;
