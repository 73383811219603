import * as routeNames from 'constants/routeNames';

export const createNavigationObject = (step: string) => {
  const isStepFetchPensionsInfo = step === routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION;
  const navigationName = isStepFetchPensionsInfo ? routeNames.PARTNER_PENSIONS_INFORMATION : step;
  const navigationParams = isStepFetchPensionsInfo
    ? { screen: routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION }
    : undefined;

  return {
    navigationName,
    navigationParams,
  };
};
