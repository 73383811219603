import { POPUP_WEBVIEW_OPEN, POPUP_WEBVIEW_CLOSE } from 'constants/actionTypes';

export const openPopupWebView = (url: string) => ({
  type: POPUP_WEBVIEW_OPEN,
  payload: url,
});

export const closePopupWebView = () => ({
  type: POPUP_WEBVIEW_CLOSE,
});
