import React, { useCallback, useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import useI18n from 'hooks/useI18n';
import { useNextAction } from './hooks';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';

const { default: FlexPage } = require('@sp/ui/pages/FlexPage');
const { default: Conditional } = require('decorators/conditional');
const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Tracking } = require('services/tracking');
const { Checkbox } = require('@sp/ui/base/inputs');
const { Link } = require('@sp/ui/typography');
const { ModalProvider } = require('@sp/ui/v2/context/ModalContext');

const CheckboxWrapper = Conditional({
  mobile: styled.View`
    margin-top: auto;
  `,
  default: styled.View`
    margin: ${({ theme }) => theme.SPACINGS.md}px 0;
    padding: 0px ${({ theme }) => theme.SPACINGS.xxl}px;
  `,
});

const TaxStatementInvalidConsent: React.FC = () => {
  const [hasAcceptedConsent, setHasAcceptedConsent] = useState(false);
  const { onClose } = useContext(FetchTaxAndPensionsInfoContext);

  const i18n = useI18n(['fetchTaxAndPensionsInfo']);
  const { DEFAULTS, SPACINGS } = useTheme();

  const nextAction = useNextAction();

  const onChangeChecked = useCallback((value: boolean) => {
    Tracking.trackEvent({
      area: 'TaxStatementInvalidConsent',
      action: value ? 'ClickAccept' : 'ClickReject',
    });

    setHasAcceptedConsent(value);
  }, []);

  const onPress = useCallback(() => {
    Tracking.trackEvent({ area: 'TaxStatementInvalidConsent', action: 'ClickNext' });
    nextAction();
  }, [nextAction]);

  return (
    <ModalProvider onClose={onClose} isModal secondaryColor>
      <FlexPage
        title={i18n.t('fetchTaxAndPensionsInfo|taxStatementTitle')}
        heading={i18n.t('fetchTaxAndPensionsInfo|taxStatementConsentHeading')}
        description={i18n.t('fetchTaxAndPensionsInfo|taxStatementDescription')}
        hasBackButton={false}
        actions={[
          {
            text: i18n.t('next'),
            type: 'primary',
            mobilePosition: 'bottom-sticky',
            inactive: !hasAcceptedConsent,
            onPress,
          },
        ]}
      >
        <CheckboxWrapper>
          <Margin horizontal="md">
            <Checkbox
              // eslint-disable-next-line react-native/no-inline-styles
              style={{ flexBasis: 'auto', marginBottom: SPACINGS.md }}
              checked={hasAcceptedConsent}
              onChangeChecked={onChangeChecked}
              text={
                <Link color={DEFAULTS.LINK_COLOR}>
                  {i18n.t('fetchTaxAndPensionsInfo|agreeToTaxStatementTerms')}
                </Link>
              }
            />
          </Margin>
        </CheckboxWrapper>
      </FlexPage>
    </ModalProvider>
  );
};

export default TaxStatementInvalidConsent;
