import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import { actions as formActions } from 'react-formed';
import { initFunc } from 'helpers/props';
import VerifyCode from 'areas/profile/VerifyCode';
import useAndroidSmsRetriever, { getAndroidAppSignature } from 'hooks/useAndroidSmsRetriever';
import {
  getContactInfo,
  validateContactInformationToken,
  verifyContactInformation,
  getPendingVerifications,
} from 'actions/profile';
import * as routeNames from 'constants/routeNames';
import { dismiss } from 'helpers/navigation';
import {
  handleValidationErrors,
  preparePhoneNumberForAPI,
  navigateToProfileModal,
  navigateToProfile,
} from './helpers';
import { VERIFY_PHONE_NUMBER } from 'areas/profile/trackingIds';
import Tracking from 'services/tracking';

const VerifyPhoneNumber = ({ i18n, onVerify, onResendCode, hasError, route, navigation }) => {
  const phoneNumber = route?.params?.phoneNumber;
  const hasBackButton = route?.params?.hasBackButton;
  const onVerifyCallback = useCallback(
    (code) => {
      if (onVerify) {
        onVerify(code);
      }
    },
    [onVerify]
  );

  useAndroidSmsRetriever(onVerify);

  return (
    <VerifyCode
      heading={i18n.t('Bekræft telefonnummer')}
      description={i18n.t('Indtast den 6-cifrede kode, som du har modtaget fra os.')}
      subtitle={i18n.t('Vi har sendt koden til nr. {{phoneNumber}}', { phoneNumber })}
      formName="verifyPhoneNumber"
      value={phoneNumber}
      hasError={hasError}
      navigation={navigation}
      hasBackButton={hasBackButton}
      isModal
      onVerify={onVerifyCallback}
      onResendCode={() => {
        if (onResendCode) {
          onResendCode(preparePhoneNumberForAPI(phoneNumber));
        }
      }}
      i18n={i18n}
    />
  );
};

VerifyPhoneNumber.propTypes = {
  i18n: PropTypes.object.isRequired,
  onVerify: initFunc.isRequired,
  onResendCode: initFunc.isRequired,
  hasError: PropTypes.bool,
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

VerifyPhoneNumber.defaultProps = {
  hasError: false,
};

const init = ({ action, getProps, setProps }) =>
  action(
    async () => {
      const { showError, i18n, dispatch, navigation, route, showNotification } = getProps();

      const email = route.params?.email;
      const standaloneFlow = route.params?.standaloneFlow;

      dispatch(formActions.clear('verifyPhoneNumber'));

      const onVerify = (token, hash) =>
        action(
          async () => {
            try {
              setProps({
                hasError: false,
              });

              await dispatch(validateContactInformationToken({ type: 'phone', token, hash }));
              await dispatch([getContactInfo(), getPendingVerifications()]);

              Tracking.trackEvent(VERIFY_PHONE_NUMBER.VERIFICATION_SUCCESS);

              if (email) {
                dismiss(navigation)();
                navigation.navigate(routeNames.PROFILE_VERIFY_EMAIL_MODAL, {
                  email,
                  hasBackButton: false,
                  standaloneFlow,
                });

                return;
              }

              if (standaloneFlow) {
                dismiss(navigation)();
                navigateToProfileModal(navigation);
              } else {
                navigateToProfile(navigation);
              }
            } catch (e) {
              Tracking.trackEvent(VERIFY_PHONE_NUMBER.VERIFICATION_FAIL);

              setProps({
                hasError: true,
              });
              handleValidationErrors(showError, i18n, e);
            }
          },
          { loader: true }
        );
      const onResendCode = ({ countryCode, number }) =>
        action(async () => {
          await dispatch(
            verifyContactInformation({
              phone: {
                countryCode,
                number,
              },
              hash: await getAndroidAppSignature(),
            })
          );

          Tracking.trackEvent(VERIFY_PHONE_NUMBER.SEND_CODE_AGAIN);
          showNotification(i18n.t('En ny kode er blevet afsendt. Den gælder i 20 min.'));
        });
      return {
        onVerify,
        onResendCode,
      };
    },
    { loader: true }
  );

export default withScreenContainer({
  init,
})(VerifyPhoneNumber);
