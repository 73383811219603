import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Body, Subtitle } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import Conditional from 'decorators/conditional';

const WRAPPER_TEXT_WIDTH = 288;

const CenterText = styled.View`
  align-items: center;
`;

const TextWrapper = styled.View`
  max-width: ${WRAPPER_TEXT_WIDTH}px;
`;

export const Item = styled.View`
  margin-bottom: ${({ theme }) => theme.SPACINGS.sm}px;
`;

export const IconContainer = styled.View`
  margin-right: ${({ theme }) => theme.SPACINGS.sm}px;
`;

export const ItemHeader = styled.View`
  flex-direction: row;
`;

export const BodyWrapper = styled(Body)`
  flex: 1;
  flex-basis: 100%;
`;

export const Container = Conditional({
  mobile: styled.View`
    flex: 1;
    margin: 0px ${({ theme }) => theme.SPACINGS.md}px;
    width: 100%;
    align-self: center;
  `,
  default: styled.View`
    margin: 0px ${({ theme }) => theme.SPACINGS.md}px;
    align-self: center;
  `,
});

export const RightContent = styled.View`
  margin-left: auto;
`;

export const SubtitleWrapper = ({ children }) => (
  <Margin marginTop="md">
    <CenterText>
      <TextWrapper>
        <Subtitle textAlign="center">{children}</Subtitle>
      </TextWrapper>
    </CenterText>
  </Margin>
);

SubtitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
