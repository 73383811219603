import useAsyncInit from 'hooks/useAsyncInit';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectCalculationSettings } from 'selectors/dashboard';
import { getCalculationProfile } from 'actions/calculations';

const { isEmpty } = require('helpers/object');

export const useInitCalculationProfile = () => {
  const dispatch = useAppDispatch();
  const calculationSettings = useAppSelector(selectCalculationSettings);

  useAsyncInit(
    async () => {
      if (isEmpty(calculationSettings)) {
        await dispatch(getCalculationProfile());
      }
    },
    [],
    {
      error: {
        type: 'blocking',
        retry: true,
      },
      loading: {
        type: 'blocking',
      },
    }
  );
};
