class Url {
  path = [];
  constructor() {
    if (global.location && global.location.hash) {
      const queryPlacement = global.location.hash.indexOf('?');
      const querySeperator = queryPlacement >= 0 ? queryPlacement : undefined;
      const url = global.location.hash.substring(1, querySeperator);
      const parts = url.split('/').filter((p) => p);
      this.path = parts;
    }
  }

  getPath(depth) {
    return this.path[depth];
  }
}

export const encodeQueryData = (data = {}) => {
  const ret = Object.keys(data)
    .filter((key) => key)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
  return ret.join('&');
};

export const getQueryData = (url) => {
  const [, search] = url.split('?');
  const query = search.split('&').reduce((output, part) => {
    const [name, value] = part.split('=');
    return {
      ...output,
      [name]: decodeURIComponent(value),
    };
  }, {});
  return query;
};

export const getPath = (url) => url?.split('?')[0];

export default new Url();
