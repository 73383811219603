import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Body } from '@sp/ui/typography';
import AgeSelector from 'containers/dashboard/AgeSelector';
import { AgeSelectorContainer } from 'areas/dashboard/styles';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import { ListWithSeparator } from '@sp/ui/base/lists';
import Conditional from 'decorators/conditional';
import { RETIREMENT } from 'areas/dashboard/trackingIds';

const DESCRIPTION_MARGIN_TOP_MOBILE = 10;
const CONTENT_MAX_WIDTH = 700;

const Wrapper = styled.View`
  justify-content: space-between;
`;

const DescriptionPadding = Conditional({
  default: styled.View`
    margin: ${({ theme }) => theme.SPACINGS.md}px 0;
  `,
  mobile: styled.View`
    margin-top: ${DESCRIPTION_MARGIN_TOP_MOBILE}px;
    margin-bottom: ${({ theme }) => theme.SPACINGS.md}px;
  `,
});

const MaxWidthWrapper = styled.View`
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const PaddingHorizontal = Conditional({
  mobile: styled.View`
    padding: 0 ${({ theme }) => theme.SPACINGS.md}px;
  `,
  tablet: styled.View``,
  desktop: styled.View``,
});

const RowWrapper = styled.View`
  max-width: ${CONTENT_MAX_WIDTH}px;
  width: 100%;
  margin: 0 auto;
`;

const Content = ({ withAgeSelector, menuItems, description, testID }) => (
  <Wrapper testID={testID}>
    {withAgeSelector && (
      <PaddingHorizontal>
        <RowWrapper>
          <AgeSelectorContainer>
            <AgeSelector
              trackingParams={{
                screen: RETIREMENT,
              }}
            />
          </AgeSelectorContainer>
          <RowBorder />
        </RowWrapper>
      </PaddingHorizontal>
    )}
    {description && (
      <DescriptionPadding>
        <MaxWidthWrapper>
          {description && (
            <PaddingHorizontal>{renderStringOrComponent(description, Body)}</PaddingHorizontal>
          )}
        </MaxWidthWrapper>
      </DescriptionPadding>
    )}
    <RowWrapper>
      {menuItems && menuItems.length > 0 && (
        <ListWithSeparator separator={RowBorder}>
          {menuItems.map((props, i) => (
            <OneLineRow key={i} {...props} />
          ))}
        </ListWithSeparator>
      )}
    </RowWrapper>
  </Wrapper>
);

Content.propTypes = {
  withAgeSelector: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      iconRight: PropTypes.object,
      onPress: PropTypes.func,
      textLeft: PropTypes.string,
    })
  ),
  testID: PropTypes.string,
};

Content.defaultProps = {
  withAgeSelector: false,
  menuItems: [],
  description: undefined,
  testID: undefined,
};

export default Content;
