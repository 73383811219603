import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IFrame = styled.iframe`
  flex: 1;
  border: none;
`;

const WebView = ({ source, height, width, srcDoc }) => {
  return (
    <IFrame srcDoc={srcDoc} key={source?.uri} src={source?.uri} height={height} width={width} />
  );
};

WebView.defaultProps = {
  height: undefined,
  width: undefined,
  source: undefined,
  srcDoc: undefined,
};

WebView.propTypes = {
  source: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }),
  srcDoc: PropTypes.string,
  height: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WebView;
