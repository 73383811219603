import React, { Fragment, isValidElement } from 'react';
import styled, { useTheme } from 'styled-components/native';
import type { MenuPageProps } from '../index.desktop';

const { default: Margin } = require('@sp/ui/base/Margin');
const { default: Icon } = require('@sp/ui/base/Icon');
const { Subtitle } = require('@sp/ui/v2/typography');
const { shadow } = require('@sp/ui/helpers/style');
const { Button } = require('@sp/ui/base/buttons');

const WRAPPER_PADDING_TOP = 32;
const ACTIONS_CONTENT_WRAPPER_PADDING_RIGHT = 32;

type ActionsProps = Pick<MenuPageProps, 'actions' | 'actionInfo' | 'actionsContent'> & {
  horizontalMode?: boolean;
  transparent?: boolean;
};

const Wrapper = styled.View<{ transparent?: boolean; horizontalMode?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.COLORS.SECONDARY_BACKGROUND};
  ${({ theme, horizontalMode, transparent }) => {
    if (horizontalMode) {
      return `
        padding-top: ${theme.SPACINGS.md}px;
        align-self: center;
        ${
          transparent
            ? ''
            : `
              border-top-left-radius: ${theme.SPACINGS.m}px;
              border-top-right-radius: ${theme.SPACINGS.m}px;
              ${shadow}
            `
        }
      `;
    }
    return `
      padding-top: ${WRAPPER_PADDING_TOP}px;
      border-radius: ${theme.SPACINGS.m}px;
    `;
  }}
`;

const ButtonWrapper = styled.View<{ horizontalMode?: boolean }>`
  width: 100%;
  z-index: -1;
  flex-direction: ${({ horizontalMode }) => (horizontalMode ? 'row' : 'column')};
`;

const FlexWrapper = styled.View`
  flex: 1;
`;

const ActionsContentWrapper = styled.View`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: ${ACTIONS_CONTENT_WRAPPER_PADDING_RIGHT}px;
  margin-bottom: ${({ theme }) => theme.SPACINGS.md}px;
`;

const Actions: React.FC<ActionsProps> = ({
  actions,
  actionInfo,
  actionsContent,
  horizontalMode,
  transparent,
}) => {
  const theme = useTheme();

  return (
    <Wrapper horizontalMode={horizontalMode} transparent={transparent}>
      {actionsContent && <ActionsContentWrapper>{actionsContent}</ActionsContentWrapper>}
      {actionInfo && (
        <Margin marginBottom="md">
          <Subtitle>{actionInfo}</Subtitle>
        </Margin>
      )}
      <ButtonWrapper horizontalMode={horizontalMode}>
        {horizontalMode && <FlexWrapper />}
        {actions.map((action: Record<string, any>, index) => {
          if (isValidElement(action.element)) {
            return <Fragment key={action.text}>{action.element}</Fragment>;
          }

          return (
            <Margin
              key={action.text || action.icon}
              marginBottom={horizontalMode || index === actions.length - 1 ? '' : 'md'}
              marginLeft={horizontalMode && index === 1 && 'md'}
            >
              <Button
                key={action.text || action.icon}
                testID={action.testID}
                size="medium"
                before={
                  action.icon && (
                    <Icon
                      name={action.icon}
                      fill={
                        action.type === 'secondary'
                          ? theme.COLORS.PRIMARY
                          : theme.COLORS.SECONDARY_BACKGROUND
                      }
                    />
                  )
                }
                {...action}
              />
            </Margin>
          );
        })}
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Actions;
