import React, { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { isWeb } from 'helpers/platform';
import { useSharedValue } from 'react-native-reanimated';
import { selectDeeplink } from 'selectors/deeplink';
import { FetchTaxAndPensionsInfoProviderWrapper } from 'context/FetchTaxAndPensionsInfoContext';
import fetchTaxAndPensionsInfoController from './fetchTaxAndPensionsInfoController';
import { useFinishFlow, useOnClose, useOnCancel } from './hooks';
import { selectFlowControl } from 'selectors/flowControl';
import type { RootState } from 'store';

const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { default: CancelDataImportAlert } = require('areas/general/alerts/CancelDataImportAlert');

import type { Nav } from './index';

type FetchingStateType = {
  fetchingController?: string;
  url?: string;
  ssoTicket?: string;
  cancel?: Promise<() => void>;
};

const selectFetchTaxAndPensionsInfoProvider = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  flowControl: selectFlowControl(state),
});

const FetchTaxAndPensionsInfoProvider: React.FC = ({ children }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [fetchingState, setFetchingState] = useState<FetchingStateType>({});
  const navigation = useNavigation<Nav>();
  const { deeplink, flowControl } = useAppSelector(
    selectFetchTaxAndPensionsInfoProvider,
    shallowEqual
  );
  const simulationClock = useSharedValue(0);

  const { isDeeplink } = deeplink ?? {};
  const { activeProgress, onSkipPension } = flowControl ?? {};
  const { updateTerminationSituation } = fetchTaxAndPensionsInfoController;

  const updateFetchingState = (state: FetchingStateType) => setFetchingState(state);
  const updateIsAlertOpen = () => setIsAlertOpen(!isAlertOpen);

  const [finishFlow] = useFinishFlow({ updateFetchingState });
  const onCancel = useOnCancel();
  const onClose = useOnClose({ updateIsAlertOpen, finishFlow });

  const onConfirm = useCallback(() => {
    updateTerminationSituation({
      isTryingToTerminateFetchingProcess: false,
      hasTerminatedFetchingProcess: true,
    });

    if (onSkipPension) {
      navigation.navigate(onSkipPension);
    } else if (isDeeplink) {
      navigation.navigate(activeProgress || '');
    } else {
      finishFlow();
    }
  }, [
    updateTerminationSituation,
    onSkipPension,
    isDeeplink,
    navigation,
    activeProgress,
    finishFlow,
  ]);

  const onCloseOrCancel = useCallback(
    ({ closeAlert }: { closeAlert?: boolean }) => {
      if (closeAlert) {
        setIsAlertOpen(false);
      }

      onCancel({ url: fetchingState?.url, checkForClosedBrowserService: isWeb });
      setFetchingState({
        ...fetchingState,
        fetchingController: undefined,
      });
    },
    [fetchingState, onCancel]
  );

  return (
    <FetchTaxAndPensionsInfoProviderWrapper
      value={{
        fetchingState,
        simulationClock,
        isAlertOpen,
        onClose,
        updateFetchingState,
      }}
    >
      {children}
      <CancelDataImportAlert
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        /* NOTE: It is needed to manually close the alert for the
        onClose function as the default closing behaviour is not
        applicable for this prop on Web */
        onClose={() => onCloseOrCancel({ closeAlert: true })}
        onCancel={() => onCloseOrCancel({ closeAlert: false })}
        onConfirm={onConfirm}
      />
    </FetchTaxAndPensionsInfoProviderWrapper>
  );
};

export default withScreenContainer()(FetchTaxAndPensionsInfoProvider);
