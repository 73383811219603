import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'containers/layout/Modal';
import withComponentContainer from 'decorators/withComponentContainer';
import Margin from '@sp/ui/base/Margin';
import { Body, H2 } from '@sp/ui/typography';
import styled from 'styled-components/native';

const ModalContent = styled.View`
  justify-content: center;
  padding: 0 ${({ theme }) => theme.SPACINGS.md}px;
`;

const PayoutsModal = ({ showModal, onClose, title, description }) => (
  <Modal visible={showModal} onRequestClose={() => onClose()}>
    <ModalContent>
      <H2 textAlign="center">{title}</H2>
      <Margin marginTop="md" />
    </ModalContent>
    <ModalContent>
      <Body textAlign="left">{description}</Body>
    </ModalContent>
  </Modal>
);

PayoutsModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

PayoutsModal.defaultProps = {
  showModal: false,
};

export default withComponentContainer({
  needsLogin: false,
})(PayoutsModal);
