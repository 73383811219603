import React from 'react';
import PropTypes from 'prop-types';
import { useRoute } from '@react-navigation/native';
import { navigate } from 'helpers/navigation';
import { Trans } from 'react-i18next';
import { Body, Link } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import withComponentContainer from 'decorators/withComponentContainer';
import Tracking from 'services/tracking';
import * as routeNames from 'constants/routeNames';
import { openPoliceDocument as openPoliceDocumentAction } from 'actions/documents';
import { selectAwardRules } from 'selectors/dashboard';
import { payoutTypeDeathAndDisability } from 'areas/dashboard/props';
import { DEATH } from '../constants/dashboardTypes';

const Footer = ({ i18n, openTermsOrDocumentFolder, payoutType, awardRules }) => {
  const route = useRoute();
  const descriptions =
    payoutType === DEATH
      ? awardRules?.descriptions?.deathAwardRulePolicyNumbers
      : awardRules?.descriptions?.disabilityAwardRulePolicyNumbers;

  return (
    <Margin all="md">
      <Body>{i18n.t('dashboard|awardRulesFooter')}</Body>
      <Margin marginTop="md" />
      <Body>
        {descriptions?.length > 0 && (
          <Trans
            i18nKey="dashboard|awardRulesDisabilityLink"
            components={{
              text: <Body />,
              linkButton: (
                <Link onPress={() => openTermsOrDocumentFolder(descriptions, route.name)} />
              ),
            }}
          />
        )}
      </Body>
    </Margin>
  );
};

Footer.defaultProps = {
  openTermsOrDocumentFolder: undefined,
};

Footer.propTypes = {
  i18n: PropTypes.object.isRequired,
  openTermsOrDocumentFolder: PropTypes.func,
  payoutType: PropTypes.oneOf(payoutTypeDeathAndDisability).isRequired,
  awardRules: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  awardRules: selectAwardRules(state),
});

export default withComponentContainer({
  mapStateToProps,
  init: ({ action, getProps }) => ({
    openTermsOrDocumentFolder: (descriptions, routeName) => {
      action(async () => {
        const { dispatch } = getProps();

        Tracking.trackEvent({ area: routeName, action: 'ClickTerms' });
        // There are more than one policy - so we open document folder
        if (descriptions.length > 1) {
          navigate(routeNames.MORE, { screen: routeNames.DOCUMENTS });
        } else {
          // open terms documents, if there is only one policy
          await dispatch(openPoliceDocumentAction(descriptions[0], 'terms'));
        }
      });
    },
  }),
})(Footer);
