import { VALID_DEEPLINK_ROUTES, DEEPLINK_SESSION } from 'constants/deeplink';

const { default: sessionStorage } = require('services/stores/session');

type CreateOrRemoveDeeplinkPersistedStorageParams = {
  flow?: string;
  guid?: string | null;
  isPriorToLogin?: boolean;
  remove?: boolean;
};

export const createOrRemoveDeeplinkPersistedStorage = async ({
  flow,
  guid,
  isPriorToLogin = false,
  remove = false,
}: CreateOrRemoveDeeplinkPersistedStorageParams) => {
  const storageDeeplink = await sessionStorage.getItem(DEEPLINK_SESSION);
  const isDeeplinkValid = VALID_DEEPLINK_ROUTES.includes(flow ?? '');

  if (remove) {
    await sessionStorage.removeItem(DEEPLINK_SESSION);
  } else if (isDeeplinkValid) {
    await sessionStorage.setItem(DEEPLINK_SESSION, {
      ...storageDeeplink,
      guid: guid ?? storageDeeplink?.guid,
      flow,
      isPriorToLogin,
    });
  }
};
