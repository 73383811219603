import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

const Container = styled.View`
  flex-direction: row;
  align-self: center;
`;

const STEP_INDICATOR_SIZE = 8;

const Circle = styled.View`
  width: ${STEP_INDICATOR_SIZE}px;
  height: ${STEP_INDICATOR_SIZE}px;
  border-radius: ${STEP_INDICATOR_SIZE}px;
  background-color: ${({ theme, secondaryColor }) =>
    secondaryColor ? theme.COLORS.V2_SECONDARY_BACKGROUND : theme.COLORS.PRIMARY}};
  margin-right: ${(props) => props.theme.SPACINGS.sm}px;
  margin-left: ${(props) => props.theme.SPACINGS.sm}px;
  opacity: ${(props) => (props.isActive ? '1' : '0.5')};
`;

const CurrentStepIndicator = ({ testID, ids, current, onPress, secondaryColor }) => (
  <Container testID={testID}>
    {ids.map((index) => (
      <TouchableOpacity
        key={index}
        onPress={() => {
          if (onPress) {
            onPress(index);
          }
        }}
      >
        <Circle isActive={index === current} secondaryColor={secondaryColor} />
      </TouchableOpacity>
    ))}
  </Container>
);

CurrentStepIndicator.propTypes = {
  testID: PropTypes.string,
  ids: PropTypes.array.isRequired,
  current: PropTypes.any.isRequired,
  onPress: PropTypes.func,
  secondaryColor: PropTypes.bool,
};

CurrentStepIndicator.defaultProps = {
  testID: undefined,
  onPress: undefined,
  secondaryColor: false,
};

export default CurrentStepIndicator;
