import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { Body } from '@sp/ui/typography';
import Icon from '@sp/ui/base/Icon';
import Conditional from 'decorators/conditional';

import countryData from './data';

const TEXT_INPUT_HORIZONTAL_PADDING = 18;
const ITEM_RIGHT_MARGIN = 9;
const PHONE_ICON_SIZE = 20;
const COUNTRY_CODE_RIGHT_MARGIN = 3;
const COUNTRY_DIAL_CODE_MARGIN = 9;
const TEXT_INPUT_HEIGHT = 56;
// NOTE: we need this because flex items get mis-aligned on desktop/tablet size
const COUNTRY_SELECT_TOP_OFFSET = 2;
export const DEFAULT_COUNTRY_CODE = '+45';

const INPUT_WRAPPER_TEXT_WIDTH = 288;

export const getISO2CountryCode = (dialCode, defaultCountryCode) => {
  if (!dialCode) return defaultCountryCode;
  return countryData.find((c) => c.dialCode === dialCode).iso2;
};

const InputWrapperMobile = styled.View`
  ${(props) => (props.flex ? `flex: ${props.flex};` : '')}
  margin-top: ${({ marginTop }) => marginTop}px;
  flex-direction: row;
  align-items: center;
  height: ${TEXT_INPUT_HEIGHT}px;
  padding: 0px ${TEXT_INPUT_HORIZONTAL_PADDING}px;
  border-color: ${({ hasError, theme }) =>
    hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_BORDER};
  border-width: ${StyleSheet.hairlineWidth}px;
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
`;

export const InputWrapper = Conditional({
  mobile: InputWrapperMobile,
  default: styled(InputWrapperMobile)`
    width: ${INPUT_WRAPPER_TEXT_WIDTH}px;
  `,
});

export const CountryCode = styled(Body)`
  color: ${(props) => props.theme.COLORS.PRIMARY};
  margin-right: ${COUNTRY_CODE_RIGHT_MARGIN}px;
`;

export const CountrySelectMobile = styled.TouchableOpacity`
  margin-right: ${ITEM_RIGHT_MARGIN}px;
  flex-direction: row;
  align-items: center;
`;

export const CountrySelect = Conditional({
  mobile: CountrySelectMobile,
  default: styled(CountrySelectMobile)`
    margin-top: ${COUNTRY_SELECT_TOP_OFFSET}px;
  `,
});

const IconWrapper = styled.View`
  margin-right: ${ITEM_RIGHT_MARGIN}px;
`;

export const CountryDialCode = styled(Body)`
  color: ${(props) => props.theme.COLORS.GRAPH_INCOME};
  margin-left: ${COUNTRY_DIAL_CODE_MARGIN}px;
`;

export const PhoneIcon = withTheme(({ theme }) => (
  <IconWrapper>
    <Icon
      name="mobile"
      width={PHONE_ICON_SIZE}
      height={PHONE_ICON_SIZE}
      fill={theme.COLORS.PRIMARY}
    />
  </IconWrapper>
));
