export const SAVINGS_AREA = 'Savings';
export const SAVINGS_OVERVIEW = 'SavingsOverview';

export const VIEW_SAVINGS = {
  area: SAVINGS_AREA,
  action: 'ViewSavings',
};

export const VIEW_SAVINGS_RETURN = {
  area: SAVINGS_AREA,
  action: 'ViewReturn',
};

export const VIEW_SAVINGS_COSTS = {
  area: SAVINGS_AREA,
  action: 'ViewCosts',
};

export const VIEW_SAVINGS_DETAILS = {
  area: SAVINGS_AREA,
  action: 'ViewDetails',
};

export const VIEW_SAVINGS_DEPOSITS = {
  area: SAVINGS_AREA,
  action: 'ViewDeposits',
};

export const CLICK_TRANSFERRED_POLICY = {
  area: SAVINGS_AREA,
  action: 'ClickTransferredPolicy',
};

export const CLICK_BLOCKED_POLICY = {
  area: SAVINGS_AREA,
  action: 'ClickPolicyBlockedByTransfer',
};

export const CLICK_DEPOSITS = {
  area: SAVINGS_OVERVIEW,
  action: 'ClickDeposits',
};

export const CLICK_UPDATE_TAX_AND_PENSION = {
  area: SAVINGS_OVERVIEW,
  action: 'ClickUpdateTaxAndPension',
};
