import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import usePopupWebView from 'hooks/usePopupWebView';
import { useAppSelector } from 'hooks/redux';
import { selectDeeplink } from 'selectors/deeplink';
import { Target, openLink } from 'helpers/linking';

const useLinking = () => {
  const popupWebView = usePopupWebView();
  const { isDeeplink } = useAppSelector(selectDeeplink, shallowEqual);

  const open = useCallback(
    async (url: string, webTarget?: Target) => {
      if (isDeeplink) {
        return openLink(url, webTarget, popupWebView);
      }

      return openLink(url, webTarget);
    },
    [popupWebView, isDeeplink]
  );

  return {
    open,
  };
};

export default useLinking;
