import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { Subtitle } from '@sp/ui/typography';
import withScreenContainer from 'decorators/withScreenContainer';
import { selectContactInfo } from 'selectors/profile';
import { RowBorder } from '@sp/ui/base/rows';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import DanishAddress from './DanishAddress';
import OtherAddress from './OtherAddress';

const BOTTOM_CONTAINER_PADDING = 16;

const BottomContainer = styled.View`
  padding: ${BOTTOM_CONTAINER_PADDING}px ${BOTTOM_CONTAINER_PADDING}px 0px;
`;

const Address = ({
  danishAddress,
  foreignAddress,
  contactAddress,
  i18n,
  isDesktop,
  isAddressProtected,
}) => {
  const hasOnlyDanishAddressWithoutCO =
    !foreignAddress &&
    !contactAddress &&
    danishAddress &&
    danishAddress.streetAddress &&
    // NOTE: Based on design, when user has C/O we should show different UI
    !danishAddress.careOfName;

  const careOfName = danishAddress && danishAddress.careOfName;

  return (
    <ScrollPage title={i18n.t('Adresse')} testID="profileAddressScreen" animateTitle>
      {isAddressProtected ? (
        <DanishAddress
          isDesktop={isDesktop}
          danishAddress={{
            streetAddress: i18n.t('Beskyttet'),
            postalCode: i18n.t('Beskyttet'),
            postalDistrict: i18n.t('Beskyttet'),
          }}
        />
      ) : (
        <>
          <RowBorder />
          {hasOnlyDanishAddressWithoutCO && <DanishAddress danishAddress={danishAddress} />}
          {!hasOnlyDanishAddressWithoutCO && careOfName && (
            <OtherAddress address={[careOfName]} title={i18n.t('C/O adresse')} />
          )}
          {!hasOnlyDanishAddressWithoutCO && danishAddress && danishAddress.streetAddress && (
            <OtherAddress
              address={[
                danishAddress.streetAddress,
                danishAddress.postalCode,
                danishAddress.postalDistrict,
              ]}
              title={i18n.t('Dansk adresse')}
            />
          )}
          {foreignAddress && (
            <OtherAddress address={foreignAddress} title={i18n.t('Udlandsadresse')} />
          )}
          {contactAddress && (
            <OtherAddress address={contactAddress} title={i18n.t('Kontaktadresse')} />
          )}
        </>
      )}

      <BottomContainer>
        <Subtitle>
          {i18n.t(
            'Dine oplysninger er hentet fra CPR-registret, og bliver automatisk opdateret ved flytning.\nHvis der er fejl, skal du ændre det via CPR-registeret i din kommune.'
          )}
        </Subtitle>
      </BottomContainer>
    </ScrollPage>
  );
};

const mapStateToProps = (state, props) => {
  const { danishAddress, foreignAddress, contactAddress, isAddressProtected } =
    selectContactInfo(state);

  return {
    ...props,
    danishAddress,
    foreignAddress,
    contactAddress,
    isAddressProtected,
    isDesktop: selectIsDeviceTypeDesktop(state),
  };
};

Address.propTypes = {
  danishAddress: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  foreignAddress: PropTypes.array,
  contactAddress: PropTypes.array,
  isAddressProtected: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
};

Address.defaultProps = {
  foreignAddress: undefined,
  contactAddress: undefined,
  isAddressProtected: undefined,
};

export default withScreenContainer({
  hasLocalNotifications: false,
  mapStateToProps,
})(Address);
