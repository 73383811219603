import useAsyncCallback from 'hooks/useAsyncCallback';
import { useNavigation } from '@react-navigation/native';
import { useStore } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import { updateCalculationProfile } from 'actions/calculations';
import { RECALCULATE_DASHBOARD } from 'areas/dashboard/trackingIds';
import { selectCalculationSettings } from 'selectors/dashboard';
import { selectFormAdjustCalculation } from 'selectors/dashboardForms';

const { default: Tracking } = require('services/tracking');
const { fetchDashboardData } = require('helpers/dashboard');

export const useUpdateCalculationProfile = (goBack = true) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const store = useStore();

  return useAsyncCallback(
    async (customPublicBenefits: any = {}) => {
      const state = store.getState();
      const currentCalculationSettings = selectCalculationSettings(state);
      const formAdjustCalculation = selectFormAdjustCalculation(state);

      //NOTE: Values are always stored as string in the form so we need to serialize them
      // before sending to the API
      const serializedRetirement = formAdjustCalculation?.retirement
        ? { ...formAdjustCalculation.retirement }
        : {};

      if (serializedRetirement?.age) {
        serializedRetirement.age = parseInt(formAdjustCalculation.retirement.age, 10);
      }
      if (serializedRetirement?.residencyYears) {
        serializedRetirement.residencyYears = parseInt(serializedRetirement.residencyYears, 10);
      }
      if (serializedRetirement?.wealth) {
        serializedRetirement.wealth = parseFloat(formAdjustCalculation.retirement.wealth);
      }

      const updatePayload = {
        publicBenefits: {
          ...currentCalculationSettings?.publicBenefits,
          ...formAdjustCalculation?.publicBenefits,
          ...customPublicBenefits,
        },
        retirement: {
          ...currentCalculationSettings?.retirement,
          ...serializedRetirement,
        },
        tax: {
          ...currentCalculationSettings?.tax,
          ...formAdjustCalculation?.tax,
        },
      };

      await dispatch(updateCalculationProfile(updatePayload));
      await fetchDashboardData(dispatch);

      Tracking.trackEvent(RECALCULATE_DASHBOARD, {
        ...formAdjustCalculation?.tax,
        ...formAdjustCalculation?.retirement,
        ...formAdjustCalculation?.publicBenefits,
      });

      if (goBack) {
        navigation.goBack();
      }
    },
    [dispatch, store]
  );
};
