const TABLE_MARGIN = 25;
const H3_MARGIN = 15;
const H4_MARGIN = 10;
const H4_MARGIN_DESKTOP = 15;
const IMG_MARGIN = 15;
const LIST_MARGIN = 15;

const getHtmlCommonStyle = (theme) => `
  a {
    color: ${theme.DEFAULTS.LINK_COLOR};
  }

  ul {
    margin-top: 0;
  }

  li p {
    margin-top: -${theme.FONT_SIZES.BODY.MOBILE}px;
    color: ${theme.COLORS.PRIMARY_TEXT};
  }

  table {
    border-collapse: collapse;
    margin-top: ${TABLE_MARGIN}px;
    margin-bottom: 30px;
    word-wrap: break-word;
  }

  table tr, table td {
    border: 1px solid ${theme.COLORS.PRIMARY};
    color: ${theme.COLORS.PRIMARY_TEXT};
    padding: 5px;
    vertical-align: middle;
  }

  table tr p, table td p {
    font-size: ${theme.FONT_SIZES.FOOTNOTE.MOBILE}px;
    margin: 0;
    color: ${theme.COLORS.PRIMARY_TEXT};
  }

  p, h5 {
    font-size: ${theme.FONT_SIZES.BODY.MOBILE}px;
    line-height: 1.3;
    color: ${theme.COLORS.PRIMARY_TEXT};
  }

  h5 {
    font-weight: bold;
    margin: 5px 0;
  }

  h2 {
    font-size: ${theme.FONT_SIZES.H2.MOBILE}px;
    color: ${theme.COLORS.PRIMARY_TEXT};
  }

  h3 {
    font-size: ${theme.FONT_SIZES.H3.MOBILE}px;
    font-weight: bold;
    margin-top: ${H3_MARGIN}px;
    margin-bottom: ${H3_MARGIN}px;
    color: ${theme.COLORS.PRIMARY_TEXT};
  }


  h4 {
    font-size: ${theme.FONT_SIZES.TITLE.DEFAULT}px;
    font-weight: normal;
    margin-top: ${H3_MARGIN}px;
    margin-bottom: ${H4_MARGIN}px;
    color: ${theme.COLORS.PRIMARY_TEXT};
  }

  img {
    max-width: 100%;
    display: block;
    margin: ${IMG_MARGIN}px auto;
  }

  ul {
    margin: ${LIST_MARGIN}px 0;
  }

  ul li {
    font-size: ${theme.FONT_SIZES.BODY.MOBILE}px;
    color: ${theme.COLORS.PRIMARY_TEXT};
    margin-bottom: 5px;
  }

  @media (min-width: 768px) {
    p, h5 {
      font-size: ${theme.FONT_SIZES.H5.DESKTOP}px;
    }

    h2 {
      font-size: ${theme.FONT_SIZES.H2.DESKTOP}px;
    }

    h3 {
      font-size: ${theme.FONT_SIZES.H3.DESKTOP}px;
    }

    h4 {
      font-size: ${theme.FONT_SIZES.H2.DESKTOP}px;
      margin-bottom: ${H4_MARGIN_DESKTOP}px;
    }

    ul li {
      font-size: ${theme.FONT_SIZES.BODY.DESKTOP}px;
    }

    table tr p, table td p {
      font-size: ${theme.FONT_SIZES.FOOTNOTE.DESKTOP}px;
    }
  }
`;

export const getHtmlStyleForNative = (theme) => `
  <style>
    body {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
    }

    ${getHtmlCommonStyle(theme)}
  </style>
`;

export const getHtmlStyleForWeb = (theme) => `
  .HtmlRenderer {
    padding: 20px;
    overflow-y: auto;
    line-height: 1.5;
    white-space: pre-line;

    ${getHtmlCommonStyle(theme)};
  }
`;
