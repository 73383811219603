import React from 'react';
import PropTypes from 'prop-types';
import { OneLineRow } from '@sp/ui/base/rows';
import { Footnote, H5, Subtitle } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import { formatMoney, getGrossAmountOrAmount } from 'helpers/string';
import Icon from '@sp/ui/base/Icon';
import styled from 'styled-components/native';
import withComponentContainer from 'decorators/withComponentContainer';
import { selectShowGrossAmount } from 'selectors/dashboard';

const RowLine = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TouchableOpacity = styled.TouchableOpacity``;

const PaymentDetails = ({
  payments,
  descriptionColor,
  description,
  total,
  i18n,
  infoColor,
  showGreenCheckModal,
  showDescriptionModal,
  showGrossAmount,
}) => (
  <>
    <OneLineRow
      textLeft={
        <TouchableOpacity onPress={() => showDescriptionModal()}>
          <H5 color={descriptionColor} fontWeight="bold">
            {description}
          </H5>
        </TouchableOpacity>
      }
      textRight={
        <Margin marginRight="xs">
          <Margin marginRight="lg">
            <Footnote fontWeight="bold">
              {i18n.t('dashboard|amountPrMonth', {
                amount: formatMoney(
                  { amount: (total && getGrossAmountOrAmount(total, showGrossAmount).amount) || 0 },
                  true
                ),
              })}
            </Footnote>
          </Margin>
        </Margin>
      }
    />
    {payments &&
      payments.map((item, index) => (
        <OneLineRow
          key={`${item.name}-${index}`}
          textLeft={<Subtitle>{item.name}</Subtitle>}
          componentRight={
            item.name === 'Grøn check' ? (
              <RowLine>
                <Subtitle>
                  {i18n.t('dashboard|amountPrMonth', {
                    amount: formatMoney(
                      { amount: (item && item.amount && item.amount.amount) || 0 },
                      true
                    ),
                  })}
                </Subtitle>
                <Margin marginLeft="sm">
                  <TouchableOpacity onPress={() => showGreenCheckModal()}>
                    <Icon name="info" fill={infoColor} />
                  </TouchableOpacity>
                </Margin>
              </RowLine>
            ) : (
              <Margin marginRight="xs">
                <Margin marginRight="lg">
                  <Subtitle>
                    {i18n.t('dashboard|amountPrMonth', {
                      amount: formatMoney(
                        {
                          amount:
                            getGrossAmountOrAmount(item?.amount, showGrossAmount)?.amount || 0,
                        },
                        true
                      ),
                    })}
                  </Subtitle>
                </Margin>
              </Margin>
            )
          }
        />
      ))}
  </>
);

PaymentDetails.defaultProps = {
  infoColor: undefined,
  showGreenCheckModal: undefined,
};

PaymentDetails.propTypes = {
  payments: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  descriptionColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  total: PropTypes.object.isRequired,
  infoColor: PropTypes.string,
  showGreenCheckModal: PropTypes.func,
  showDescriptionModal: PropTypes.func.isRequired,
  showGrossAmount: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showGrossAmount: selectShowGrossAmount(state),
});

export default withComponentContainer({ mapStateToProps })(PaymentDetails);
