// Screens
export const DISABILITY_SCREEN = 'disability-screen';
export const PENSION_SCREEN = 'pension-screen';
export const DEATH_SCREEN = 'death-screen';
export const PAYOUTS_DETAILS_OVERVIEW_SCREEN = 'payouts-details-overview-screen';
export const PAYOUTS_DETAILS_ACCUMULATED_GRAPH_SCREEN = 'payouts-details-graph-accumulated-screen';
export const PAYOUTS_DETAILS_ONE_TIME_GRAPH_SCREEN = 'payouts-details-one-time-graph-screen';
export const PAYOUTS_DETAILS_OVER_TIME_GRAPH_SCREEN = 'payouts-details-over-time-graph-screen';

// Tabs
export const RETIREMENT_TAB = 'retirement-tab';
export const DISABILITY_TAB = 'disability-tab';
export const DEATH_TAB = 'death-tab';
export const PAYOUTS_ACCUMULATED_TAB = 'payouts-accumulated-tab';
export const PAYOUTS_ONE_TIME_TAB = 'payouts-one-time-tab';
export const PAYOUTS_MONTHLY_TAB = 'payouts-monthly-tab';
export const PAYOUTS_ORIGIN_RETIREMENT_TAB = 'payouts-origin-retirement-tab';
export const PAYOUTS_ORIGIN_DISABILITY_TAB = 'payouts-origin-disability-tab';
export const PAYOUTS_ORIGIN_DEATH_TAB = 'payouts-origin-death-tab';

// Menus
export const DASHBOARD_ADJUST_CALCULATION_MENU = 'dashboard-adjust-calculation-menu';
export const DASHBOARD_PAYOUTS_DETAILS_OVERVIEW_MENU = 'dashboard-payouts-details-overview-menu';
export const DASHBOARD_PAYOUTS_ORIGIN_MENU = 'dashboard-payouts-origin-menu';
export const DASHBOARD_PAYOUTS_CHILDREN_MENU = 'dashboard-payouts-children-menu';
export const DASHBOARD_PAYOUTS_CRITERIA_MENU = 'dashboard-payouts-criteria-menu';
export const DASHBOARD_PRECONDITIONS_MENU = 'dashboard-preconditions-menu';

// Content
export const DASHBOARD_GRAPH_DESIRED_PENSION_AGE_CONTENT =
  'dashboard-graph-desired-pension-age-content';
export const DASHBOARD_SUGGESTED_PENSION_AGE_CONTENT = 'dashboard-suggested-pension-age-content';

// Graphs
export const DASHBOARD_GRAPH_DESIRED_PENSION_AGE_BAR = 'dashboard-graph-desired-pension-age-bar';
export const DASHBOARD_GRAPH_SUGGESTED_PENSION_AGE_BAR =
  'dashboard-graph-suggested-pension-age-bar';
export const DASHBOARD_GRAPH_DISPOSABLE_INCOME = 'dashboard-graph-disposable-income';
export const DASHBOARD_GRAPH_AVERAGE_PAYOUT_BAR = 'dashboard-graph-average-payout-bar';

// Naked dashboard
export const NAKED_DASHBOARD_ERROR = 'naked-dashboard-error';
export const AWARD_RULES_ERROR = 'award-rules-error';
