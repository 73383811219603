import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import PageParts from '@sp/ui/PageParts';
import { isWeb } from '@sp/ui/helpers/device';

const WRAPPER_MAX_WIDTH = 1024;
const WRAPPER_MIN_HEIGHT = 550;
const BACK_BUTTON_HEIGHT = 69;

const ContentWrapper = styled.View`
  width: 100%;
  max-width: ${WRAPPER_MAX_WIDTH}px;
  min-height: ${WRAPPER_MIN_HEIGHT}px;
  border-radius: ${(props) => props.theme.DEFAULTS.BORDER_RADIUS}px;
  overflow: hidden;
`;

const BackButtonWrapper = styled.View`
  max-width: ${WRAPPER_MAX_WIDTH}px;
  width: 100%;
  height: ${BACK_BUTTON_HEIGHT}px;
`;

export const Wrapper = styled.View`
  flex: 1;
  height: 100%;
  align-items: center;
  background: ${(props) => props.theme.COLORS.PRIMARY_BACKGROUND};
  ${isWeb ? 'overflow: auto;' : ''}
`;

export const MenuWrapper = styled.View`
  width: 100%;
`;

export const BackButton = styled.View`
  width: 100%;
`;

const SwiperPage = ({ children, menu, backButton, hasBackButton, hasNotifications }) => (
  <Wrapper>
    {isWeb && menu && <MenuWrapper>{menu}</MenuWrapper>}
    <PageParts>
      {({ BackButton: GlobalBackButton, notifications }) => (
        <Fragment>
          <BackButtonWrapper>
            {hasBackButton && (
              <BackButton>
                {typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />}
              </BackButton>
            )}
          </BackButtonWrapper>
          {hasNotifications && notifications}
        </Fragment>
      )}
    </PageParts>
    <ContentWrapper>{children}</ContentWrapper>
  </Wrapper>
);

SwiperPage.propTypes = {
  children: PropTypes.node,
  menu: PropTypes.node,
  backButton: PropTypes.node,
  hasBackButton: PropTypes.bool,
  hasNotifications: PropTypes.bool,
};

SwiperPage.defaultProps = {
  children: undefined,
  menu: undefined,
  backButton: undefined,
  hasBackButton: true,
  hasNotifications: false,
};

export default withTheme(SwiperPage);
