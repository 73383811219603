import { Alert } from 'react-native';
import features from 'features';
import config from 'config';
import { unregisterDevice, clearNotification } from 'actions/pushnotification';
import { selectTargetEnv, selectPrefetchKeys } from 'selectors/discovery';
import { create } from 'helpers/middleware';
import {
  tearDown as tearDownPushNotifications,
  getToken as getPushToken,
} from 'services/pushnotifications';
import i18n from 'services/i18n';
import {
  logout as logoutAction,
  removePersistence,
  unlockPersistence,
  lockPersistence,
  setupPersistence,
} from '@sampension/api-middleware';
import log from 'services/log';
import Tracking from 'services/tracking';
const { openInAppAuthBrowser } = require('areas/fetchTaxAndPensionsInfo/inAppBrowser');

import { isAuthCanceledError, isAuthFailedOnClientError, isBiometricsInvalidated } from './helpers';
import { isWeb, isAndroid } from 'helpers/platform';

import { logoutClassicWeb } from './classic-web';

const middleware = create('@@SECURITY');

const unlockSession = (store) => async (_action, next) => {
  try {
    const targetEnv = selectTargetEnv(store.getState());

    await next(unlockPersistence(targetEnv));
    return true;
  } catch (e) {
    const track = (params) =>
      Tracking.trackEvent(
        {
          area: 'Auth',
          action: 'Authentication',
        },
        params
      );

    if (isAuthCanceledError(e)) {
      track({ authCanceled: true });
    } else if (isAuthFailedOnClientError(e)) {
      track({ failedOnBiometrics: true });
    } else if (isBiometricsInvalidated(e)) {
      Alert.alert(undefined, i18n.t('onboarding|changesInBiometricsMsg'));
      track({ invalidatedBiometrics: true });
    } else {
      Alert.alert(undefined, i18n.t('onboarding|biometricsFailureErrorMsg'));
      log.error('Error unlocking session', e);
    }
    return false;
  }
};

const storeSession = (store) => async (_action, next) => {
  const targetEnv = selectTargetEnv(store.getState());
  try {
    await next(setupPersistence(targetEnv));
  } catch (e) {
    // FIXME: due to the way we handle invalidation of biometrics
    // this method can throw an error if biometrics were changed
    // after a hard logout, but we don't want to treat it as an error in this case
    if (!isBiometricsInvalidated(e)) {
      throw e;
    }
  }
};

const removeBiometrics = async (action, next) => {
  await next(removePersistence());
};

const appLock = async (action, next) => {
  if (action.payload) {
    await next(lockPersistence());
  }
  await next(action);
};

const logout = (store) => async (action, next) => {
  const pushToken = getPushToken();

  const isPushEnabled = selectPrefetchKeys(store.getState()).includes('pushNotifications');

  const { type: logoutType } = action.meta;

  await next(clearNotification());
  await next(lockPersistence());

  if (logoutType === 'hard') {
    // TODO: remove this feature flag once push notifications are on staging
    if (features.isEnabled('pushNotifications') || isPushEnabled) {
      if (pushToken) {
        await next(unregisterDevice(pushToken));
        tearDownPushNotifications();
      }
    }
    await removeBiometrics(action, next);
  }

  // redirect when logout is done
  const logoutUrl = await next(logoutAction(isAndroid ? 'sampension://intro' : config.baseUrl));

  if (isAndroid && logoutType === 'hard') {
    openInAppAuthBrowser(logoutUrl, () => console.log('cancel called'));
  }

  if (isWeb) {
    logoutClassicWeb();
    global.location.href = logoutUrl;
  }
};

const actions = (store) => ({
  STORE_SESSION: storeSession(store),
  REMOVE_BIOMETRICS: removeBiometrics,
  UNLOCK_SESSION: unlockSession(store),
  LOGOUT: logout(store),
  APP_LOCK: appLock,
});

export default (store) => (next) => (action) => middleware(next, action, actions(store));
