export const hasDocumentWithStatus = (messages, status) =>
  messages.some((message) => message.documents.some((doc) => doc.status === status));

export const getThreadSigningStatus = (messages) => {
  if (hasDocumentWithStatus(messages, 'inProgress')) {
    return 'inProgress';
  }

  if (hasDocumentWithStatus(messages, 'toBeSigned')) {
    return 'toBeSigned';
  }

  if (hasDocumentWithStatus(messages, 'signed')) {
    return 'signed';
  }

  if (hasDocumentWithStatus(messages, 'normal')) {
    return 'normal';
  }

  return 'none';
};
