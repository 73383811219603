import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import * as routeNames from 'constants/routeNames';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { DashboardRow, RowBorder } from '@sp/ui/base/rows';
import { ListWithSeparator } from '@sp/ui/base/lists';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { selectNotificationSettingsCompanyGroups } from 'selectors/settings';
import { dismiss } from 'helpers/navigation';
import { selectIsDeviceTypeDesktop } from 'selectors/device';

const NotificationSettings = ({ i18n, companyGroups, isDesktop, navigation, isModal }) => (
  <ModalProvider isModal={isModal} onClose={dismiss(navigation)}>
    <ScrollPage
      title={i18n.t('Kontaktindstillinger')}
      heading={isDesktop && i18n.t('Kontaktindstillinger')}
      disableBottomPadding
      testID="profileNotificationSettingsCompanyGroupsScreen"
    >
      <ListWithSeparator separator={RowBorder}>
        {companyGroups.map((group, key) => (
          <DashboardRow
            key={group.companyGroup}
            textLeft={group.name}
            arrow
            testID={`companyGroup${key}Link`}
            onPress={() => {
              navigation.navigate(routeNames.SETTINGS_NOTIFICATION_SETTINGS, {
                companyName: group.name,
                companyGroup: group.companyGroup,
                isModal,
              });
            }}
          />
        ))}
      </ListWithSeparator>
    </ScrollPage>
  </ModalProvider>
);

NotificationSettings.propTypes = {
  i18n: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  companyGroups: PropTypes.array.isRequired,
  isModal: PropTypes.bool.isRequired,
};

NotificationSettings.defaultProps = {};

const mapStateToProps = (state, props) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
  companyGroups: selectNotificationSettingsCompanyGroups(state),
  isModal: props.route?.params?.isModal || false,
});

export default withScreenContainer({
  mapStateToProps,
})(withTheme(NotificationSettings));
