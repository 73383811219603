import React, { useState } from 'react';
import {
  fetch,
  toggleSort as toggleSortAction,
  setSource as setSourceAction,
  cancelPollMails as cancelPollMailsAction,
} from 'actions/mailbox';
import { withTheme } from 'styled-components/native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import withScreenContainer from 'decorators/withScreenContainer';
import Tracking from 'services/tracking';
import * as routeNames from 'constants/routeNames';
import FlexPage from '@sp/ui/pages/FlexPage';
import { H5 } from '@sp/ui/typography';
import { TwoLineRow } from '@sp/ui/base/rows';
import { FlatList, EmptyFlatList } from '@sp/ui/base/lists';
import useTrackingOnFocus from 'hooks/useTrackingOnFocus';
import { formatDate } from 'helpers/string';
import { selectConversations } from 'selectors/mailbox';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import OverviewHeader from 'areas/mailbox/OverviewHeader';
import { VIEW_MAILBOX } from 'areas/mailbox/trackingIds';
import useAsyncInit from 'hooks/useAsyncInit';
import useAsyncCallback from 'hooks/useAsyncCallback';
import usePrerenderScreen from 'hooks/usePrerenderScreen';

const BULLET_ICON_WIDTH = 12;
const BULLET_ICON_HEIGHT = 12;

const bulletIcon = {
  name: 'bullet',
  width: BULLET_ICON_WIDTH,
  height: BULLET_ICON_HEIGHT,
};

const actionsForDesktop = [];
const getActionsForMobileTablet = (navigation, route, i18n) => [
  {
    icon: 'pencil',
    onPress: () => {
      Tracking.trackEvent({ area: route.name, action: 'ClickMailCompose' });
      navigation.navigate(routeNames.MAIL_CREATE);
    },
    accessibilityLabel: i18n.t('Skriv ny besked til rådgiver.'),
    testID: 'mailboxCreateBtn',
  },
];

export const selectOverview = (state) => ({
  conversations: state.areas.mailbox.conversations,
  currentConversations: selectConversations(state),
  source: state.areas.mailbox.source,
  isFetching: state.areas.mailbox.conversations[state.areas.mailbox.source].isFetching,
  order: state.areas.mailbox.order,
  isDesktop: selectIsDeviceTypeDesktop(state),
});

const getRightIcon = (item, theme) => {
  const statusToIconName = {
    normal: 'rowAttachment',
    toBeSigned: 'signature',
    signed: 'checkmark',
    inProgress: 'awaiting',
    none: 'none',
  };

  return {
    name: statusToIconName[item.signingStatus],
    fill: theme.COLORS.PRIMARY_TEXT,
  };
};

const MailOverview = ({ i18n, theme }) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const route = useRoute();
  const { currentConversations, source, isFetching, isDesktop, conversations, order } =
    useAppSelector(selectOverview, shallowEqual);

  const [refreshing, setRefreshing] = useState(false);
  useAsyncInit(
    async () => {
      if (!conversations[source].hasFetched) {
        await dispatch(fetch(source));
      }
    },
    [],
    {
      error: {
        type: 'notification',
        retry: true,
      },
    }
  );

  const [onSourceSelect] = useAsyncCallback(
    async (c, newSource) => {
      if (!c[newSource].hasFetched) {
        await dispatch(fetch(newSource));
      }

      await dispatch(setSourceAction(newSource));
    },
    [dispatch],
    {
      handleLoading: false,
    }
  );

  const [onRefresh] = useAsyncCallback((s) => dispatch(fetch(s)), [dispatch], {
    handleLoading: false,
  });

  const [toggleSort] = useAsyncCallback(
    async () => {
      Tracking.trackEvent({ area: route.name, action: `ClickDate${order}` });
      await dispatch(toggleSortAction());
    },
    [order, dispatch],
    { handleLoading: false }
  );

  const [cancelPollMails] = useAsyncCallback(() => dispatch(cancelPollMailsAction()), [dispatch]);

  const prerender = usePrerenderScreen();

  useTrackingOnFocus(VIEW_MAILBOX);

  if (prerender) {
    return prerender;
  }

  return (
    <FlexPage
      heading={isDesktop ? i18n.t('common|mailboxFeature') : undefined}
      title={i18n.t('common|mailboxFeature')}
      testID={`mailbox${source}Screen`}
      actions={isDesktop ? actionsForDesktop : getActionsForMobileTablet(navigation, route, i18n)}
      hasBackButton={false}
      disableBottomPadding
    >
      <OverviewHeader
        source={source}
        onSourceSelect={(src) => {
          if (onSourceSelect) {
            Tracking.trackEvent({ area: route.name, action: `ClickSourceSelect${source}` });
            onSourceSelect(conversations, src);
            setRefreshing(true);
          }
        }}
        onSortPress={toggleSort}
      />
      <FlatList
        data={currentConversations}
        onRefresh={() => {
          if (onRefresh) {
            onRefresh(source);
            setRefreshing(true);
          }
        }}
        // NOTE: contentContainerStyle is needed for ListEmptyComponent to take fullscreen
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={currentConversations.length === 0 ? { flex: 1 } : {}}
        refreshing={refreshing && isFetching}
        ListEmptyComponent={
          <EmptyFlatList
            isFetching={isFetching}
            text={
              source === 'inbox'
                ? i18n.t('mailbox|noMessagesInInbox')
                : i18n.t('mailbox|noMessagesInArchive')
            }
          />
        }
        renderItem={({ item, index }) => (
          <TwoLineRow
            arrow
            key={item.id}
            textLeft={
              <H5
                fontWeight={item.signingStatus === 'toBeSigned' ? 'bold' : 'semibold'}
                numberOfLines={1}
              >
                {item.title}
              </H5>
            }
            subtitleLeft={item.createdOn && formatDate(item.createdOn)}
            iconLeft={item.unread ? bulletIcon : { ...bulletIcon, fill: 'transparent' }}
            backgroundColor={
              item.signingStatus === 'toBeSigned' ? theme.COLORS.PRIMARY_VARIANT1 : undefined
            }
            iconRight={getRightIcon(item, theme)}
            onPress={() => {
              if (cancelPollMails) {
                cancelPollMails();
              }

              navigation.navigate(routeNames.MAIL_DETAILS, {
                id: item.id,
              });
            }}
            testID={`mailboxDetails${index}Link`}
          />
        )}
      />
    </FlexPage>
  );
};

MailOverview.propTypes = {
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withTheme(
  withScreenContainer({
    i18ns: ['mailbox'],
    overlayScreenProps: {
      titleKey: 'Postkasse',
    },
  })(MailOverview)
);
