import React, { useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { shallowEqual } from 'react-redux';
import withScreenContainer from 'decorators/withScreenContainer';
import * as routeNames from 'constants/routeNames';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import { selectAllowResetChildren } from 'selectors/children';
import { selectDataFromExternalSources } from 'selectors/profile';
import { formatDate } from 'helpers/string';
import Margin from '@sp/ui/base/Margin';
import ScrollPage from '@sp/ui/pages/ScrollPage';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import { TwoLineRow, RowBorder } from '@sp/ui/base/rows';
import { Subtitle, H5 } from '@sp/ui/typography';
import { fetchTaxAndPensionsInfo } from 'actions/fetchTaxAndPensionsInfo';

const selectExternalData = (state) => ({
  dataFromExternalSources: selectDataFromExternalSources(state),
  allowResetChildren: selectAllowResetChildren(state),
});

const ExternalDataOverview = () => {
  const { i18n } = useI18n(['profile']);
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { allowResetChildren, dataFromExternalSources } = useAppSelector(
    selectExternalData,
    shallowEqual
  );

  const updatePensionAndTaxaData = useCallback(
    () => dispatch(fetchTaxAndPensionsInfo()),
    [dispatch]
  );

  const { pensionInfo, pensionInfoUpdateDate } = dataFromExternalSources?.pensionInfo ?? {};
  const { taxStatement, taxStatementUpdateDate } = dataFromExternalSources?.taxStatementInfo ?? {};

  const hasPIData = pensionInfoUpdateDate && pensionInfo;
  const hasSkatData = taxStatementUpdateDate && taxStatement;
  const hasExternalDataSources = hasPIData || hasSkatData || allowResetChildren;

  const heading = useMemo(() => {
    return hasExternalDataSources
      ? i18n.t('deleteData')
      : i18n.t('profile|dataFromExternalSources');
  }, [hasExternalDataSources, i18n]);

  const description = useMemo(() => {
    return hasExternalDataSources
      ? i18n.t('profile|deleteDataFromExternalSources')
      : i18n.t('profile|noDataFromExternalSourcesDescription');
  }, [hasExternalDataSources, i18n]);

  return (
    <ModalProvider isModal>
      <ScrollPage
        heading={heading}
        description={description}
        actions={[
          {
            text: i18n.t('refresh'),
            type: 'primary',
            mobilePosition: 'bottom-sticky',
            onPress: updatePensionAndTaxaData,
          },
        ]}
      >
        {allowResetChildren && (
          <>
            <RowBorder />
            <TwoLineRow
              textLeft={<H5 fontWeight="bold">{i18n.t('profile|hardDeleteChildren')}</H5>}
              iconRight={{
                name: 'delete',
                onPress: () =>
                  navigation.navigate(routeNames.PROFILE_INFORMATION_ABOUT_EXTERNAL_DATA_DELETION, {
                    deletionType: 'children',
                  }),
              }}
            />
          </>
        )}
        {hasPIData && (
          <>
            <RowBorder />
            <TwoLineRow
              textLeft={<H5 fontWeight="bold">{i18n.t('profile|pensionInformation')}</H5>}
              subtitleLeft={formatDate(pensionInfoUpdateDate)}
              iconRight={{
                name: 'delete',
                onPress: () => {
                  navigation.navigate(routeNames.PROFILE_INFORMATION_ABOUT_EXTERNAL_DATA_DELETION, {
                    deletionType: 'pension',
                  });
                },
              }}
            />
          </>
        )}
        {hasSkatData && (
          <>
            <RowBorder />
            <TwoLineRow
              textLeft={<H5 fontWeight="bold">{i18n.t('profile|incomeAndTaxInformation')}</H5>}
              subtitleLeft={formatDate(taxStatementUpdateDate)}
              iconRight={{
                name: 'delete',
                onPress: () => {
                  navigation.navigate(routeNames.PROFILE_INFORMATION_ABOUT_EXTERNAL_DATA_DELETION, {
                    deletionType: 'tax',
                  });
                },
              }}
            />
          </>
        )}
        {hasExternalDataSources && <RowBorder />}
        <Margin all="md">
          {pensionInfoUpdateDate && (
            <Margin marginBottom="md">
              <Subtitle>
                {i18n.t('profile|dataPensionInfoRecieveDate', {
                  date: formatDate(pensionInfoUpdateDate),
                })}
              </Subtitle>
            </Margin>
          )}
          {taxStatementUpdateDate && (
            <Subtitle>
              {i18n.t('profile|dataTaxInfoRecieveDate', {
                date: formatDate(taxStatementUpdateDate),
              })}
            </Subtitle>
          )}
        </Margin>
      </ScrollPage>
    </ModalProvider>
  );
};

export default withScreenContainer()(ExternalDataOverview);
