import { StyleSheet } from 'react-native';

const COLORS_V2 = {
  V2_PRIMARY_BACKGROUND: '#54A8D1',
  V2_PRIMARY_TEXT: '#FFFFFF',
  V2_SECONDARY_BACKGROUND: '#FFFFFF',
  V2_SECONDARY_BACKGROUND_LEVEL_1: '#F0F0EB',
  V2_SECONDARY_TEXT: '#464543',
  V2_SECONDARY_SELECTED: '#E5F1F8',
  V2_SECONDARY_LINK: '#54A8D1',
  V2_SHADOW: 'rgba(0, 0, 0, 0.15)',
  V2_MODAL_OVERLAY: 'rgba(0, 0, 0, 0.3)',
  V2_DARK: '#000000',
  V2_GRAPH_CIP_TOP: '#3c5c7b',
  V2_GRAPH_CIP_1: '#10385e',
  V2_GRAPH_CIP_2: '#e6e8e4',
  V2_RADIO_BORDER: '#A2A2A1',
  V2_SWITCH_DESELECTED_TRACK: '#d4dee5',
};

const COLORS = {
  PRIMARY: '#54a8d1',
  PRIMARY_HOVER: '#4291b8',
  PRIMARY_LINK: '#2aa7e3',
  PRIMARY_INACTIVE: '#bbdced',
  PRIMARY_VARIANT1: '#e5f1f8',
  PRIMARY_BACKGROUND: '#ececec',
  PRIMARY_BACKGROUND_V2: '#e6e8e4',
  PRIMARY_TEXT: 'rgb(70, 69, 67)',
  SECONDARY_TEXT: 'rgba(70, 69, 67, 0.8)',
  GRAPH_INCOME: '#a2a2a1',
  PRIMARY_BORDER: '#d0d0d0',
  GRAPH_NEUTRAL: '#ececec',
  PRIMARY_SUCCESS: '#66c72a',
  PRIMARY_ALERT: '#ca3a3a',
  PRIMARY_ALERT_NOTIFICATION: '#faeeee',
  PRIMARY_WARNING: '#f1ad3d',
  PRIMARY_OVERLAY_SOLID: '#b5b5b4',
  ERROR_BACKGROUND: '#faeeee',
  ERROR_BACKGROUND_SHADOW: 'rgba(250, 238, 238, 0.97)',
  GRAPH_PAYOUTS_VARIANT1: '#9d1b3a',
  GRAPH_PAYOUTS_VARIANT2: '#ff94a1',
  GRAPH_PAYOUTS_VARIANT3: '#fe4d77',
  GRAPH_PAYOUTS_VARIANT4: '#4c155b',
  GRAPH_PAYOUTS_VARIANT5: '#15b5c0',
  SECONDARY_BACKGROUND: '#fff',
  SECONDARY_BACKGROUND_V2: '#f0f0ea',
  POSITIVE_STATUS: '#f0f9eb',
  POSITIVE_STATUS_SHADOW: 'rgba(240, 249, 235, 0.97)',
  NEGATIVE_STATUS: '#fcefd8',
  NEGATIVE_STATUS_SHADOW: 'rgba(252, 239, 216, 0.97)',
  BLOCKED_STATUS: '#f0f0eb',
  SHADOW: 'rgba(0, 0, 0, 0.15)',
  DARK: '#000',
  INACTIVE_GRAPH_SUCCESS: '#c2e9aa',
  INACTIVE_GRAPH_INCOME: '#dadad9',
  INACTIVE_PRIMARY_ALERT: '#eab0b0',
  ...COLORS_V2,
};

const HIT_SLOP_RANGE = 24;

const HIT_SLOP = {
  top: HIT_SLOP_RANGE,
  left: HIT_SLOP_RANGE,
  bottom: HIT_SLOP_RANGE,
  right: HIT_SLOP_RANGE,
};

const FONTS = {
  WEB: 'Montserrat',
  ANDROID: {
    regular: 'Montserrat-Regular',
    medium: 'Montserrat-Medium',
    bold: 'Montserrat-Bold',
    semibold: 'Montserrat-SemiBold',
    italic: 'Montserrat-Italic',
  },
  IOS: 'Montserrat-Regular',
};

const FONT_SIZES_V2 = {
  V2_H2: {
    DEFAULT: 30,
  },
  V2_H3: {
    DEFAULT: 24,
  },
  V2_H4: {
    DEFAULT: 20,
  },
  V2_H5: {
    DEFAULT: 18,
  },
  V2_BODY: {
    DEFAULT: 16,
  },
  V2_BODY2: {
    DEFAULT: 14,
  },
  V2_SUBTITLE: {
    DEFAULT: 12,
  },
};

const FONT_SIZES = {
  H1: {
    DESKTOP: 40,
    MOBILE: 30,
  },

  H3: {
    DESKTOP: 32,
    MOBILE: 25,
  },

  H2: {
    DESKTOP: 25,
    MOBILE: 20,
  },

  H5: {
    DESKTOP: 16,
    MOBILE: 14,
  },

  H6: {
    DESKTOP: 14,
    MOBILE: 13,
  },

  BODY: {
    DESKTOP: 16,
    MOBILE: 14,
  },

  LINK: {
    DESKTOP: 16,
    MOBILE: 14,
  },

  LABEL: {
    DEFAULT: 13,
  },

  TITLE: {
    DEFAULT: 16,
  },

  SUBTITLE: {
    DESKTOP: 15,
    MOBILE: 11,
  },

  FOOTNOTE: {
    DESKTOP: 13,
    MOBILE: 11,
  },

  ...FONT_SIZES_V2,
};

const SCROLL_CONFIG = {
  SCROLL_EVENT_THROTTLE: 16,
  FADING_SCROLL_EDGES: {
    isEnabled: true,
    iOSandWebFadingEdgeHeight: 16,
    nativeBackgroundColor: COLORS_V2.V2_SECONDARY_BACKGROUND,
    webBackgroundColorTop: { from: 'rgba(0, 0, 0, 0.05)', to: 'rgba(255,255,255, 0.05)' },
    webBackgroundColorBottom: { to: 'rgba(0, 0, 0, 0.05)', from: 'rgba(255,255,255, 0.05)' },
  },
  SCROLL_ARROWS: {
    isEnabled: true,
    dimensions: 40,
    fill: COLORS_V2.V2_PRIMARY_BACKGROUND,
    bottomArrowOffset: 8,
    topArrowOffset: 8,
  },
};

const DEFAULTS = {
  BORDER_RADIUS: 7,
  BORDER_WIDTH: StyleSheet.hairlineWidth,
  LINK_COLOR: COLORS.PRIMARY_LINK,
  TEXT_COLOR: COLORS.PRIMARY_TEXT,
  ICON_HEIGHT: 20,
};

const SPACINGS = {
  xxxl: 50,
  xxl: 40,
  xl: 32,
  lg: 24,
  md: 16,
  m: 12,
  ms: 10,
  sm: 8,
  s: 6,
  xs: 4,
};

const BORDERS = {
  xxl: 40,
  xl: 32,
  lg: 24,
  md: 16,
  m: 12,
  ms: 10,
  sm: 8,
  s: 6,
  xs: 4,
};

const CONTAINER_WIDTHS = {
  DESKTOP_WIDE: 1440,
};

const BROWSER_FEATURES =
  'width=1000,height=900,top=20,left=20,scrollbars=yes,menubar=no,toolbar=no,status=no,resizable=yes,addressbar=yes';

const windowDimensions = { height: 0, width: 0 };

export {
  COLORS,
  DEFAULTS,
  HIT_SLOP,
  FONT_SIZES,
  SPACINGS,
  BORDERS,
  FONTS,
  BROWSER_FEATURES,
  SCROLL_CONFIG,
  windowDimensions,
  CONTAINER_WIDTHS,
};
