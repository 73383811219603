import * as routeNames from 'constants/routeNames';
import type { I18nParams } from 'typings/global';

export const getProgressData = (i18n: I18nParams) => [
  {
    step: routeNames.PARTNER_CONSENTS,
    title: i18n.t('partner|submitConsent'),
  },
  {
    step: routeNames.FETCH_TAX_PENSIONSINFO_INTRODUCTION,
    title: i18n.t('partner|getPensionsData'),
  },
  {
    step: routeNames.PARTNER_INCOME_INFORMATION,
    title: i18n.t('partner|incomeTitle'),
  },
  {
    step: routeNames.PARTNER_RECEIPT,
    title: i18n.t('partner|receiptAndExit'),
  },
];
