import React from 'react';
import { formatMoney, getGrossAmountOrAmount } from 'helpers/string';
import { getBarColor } from 'areas/dashboard/helpers';
import i18n from 'services/i18n';
import Content from 'areas/dashboard/Content';
import getIncomeBar from '../getIncomeBar';
import getMenuItems from '../getMenuItems';
import Description from './RetirementDescription';
import { Body, H2 } from '@sp/ui/typography';
import Margin from '@sp/ui/base/Margin';
import * as routeNames from 'constants/routeNames';
import * as testIds from 'constants/testIds/dashboard';
import { RETIREMENT } from '../../constants/dashboardTypes';

export default ({
  navigation,
  incomeWhenRetired,
  retirementAge,
  recommendedIncome,
  incomeToday,
  totalIncomeToday,
  childAndYouthBenefitToday,
  theme,
  hasChildrenPayouts,
  noRecommendationType,
  showGrossAmount,
}) => {
  /* TODO: Reintroduce logic when link children is available
  const checkForLinkableChildren = useCheckForLinkableChildren();
  */

  const menuItems = getMenuItems({
    navigation,
    payouts: incomeWhenRetired,
    area: RETIREMENT,
    payoutsRoute: routeNames.DASHBOARD_PAYOUTS_RETIREMENT,
    payoutsOriginRoute: routeNames.PAYOUTS_ORIGIN,
    checkForLinkableChildren: false, // TODO: Insert checkForLinkableChildren here
    childrenPayoutsRoute: hasChildrenPayouts ? routeNames.PAYOUTS_CHILDREN : undefined,
  });

  const bars = [
    getIncomeBar({
      incomeToday,
      totalIncomeToday,
      childAndYouthBenefitToday,
      i18n,
      theme,
      testIDPrefix: RETIREMENT,
      showGrossAmount,
      withAgeSelector: true,
      menuItems,
    }),
  ];
  const recommended = getGrossAmountOrAmount(recommendedIncome, showGrossAmount);

  if (!noRecommendationType) {
    bars.push({
      id: '2',
      testID: testIds.DASHBOARD_GRAPH_SUGGESTED_PENSION_AGE_BAR,
      bar: {
        centerLabel: recommended.amount > 0 ? '' : '?',
        amount: recommended.amount > 0 ? formatMoney(recommended, true) : '',
        value: recommended.amount || 1000,
        description:
          recommendedIncome.pensionAge > 0
            ? i18n.t('dashboard|recommendedPensionAge', {
                pensionAge: recommendedIncome.pensionAge,
              })
            : i18n.t('dashboard|recommendedPensionAgeUnknown'),
        color: recommended.amount > 0 ? theme.COLORS.PRIMARY_SUCCESS : theme.COLORS.GRAPH_NEUTRAL,
        trackId: 'SuggestedPensionAge',
      },
      content: {
        component: (
          <Content
            testID={testIds.DASHBOARD_SUGGESTED_PENSION_AGE_CONTENT}
            withAgeSelector
            description={
              <Description
                key="suggestedPensionAge"
                status="success"
                recommendedAge={recommendedIncome.pensionAge}
                noRecommendationType={noRecommendationType}
                infoAccessibilityLabel={i18n.t('dashboard|suggestedRetirementAgeModalA11y')}
                modalTitle={i18n.t('dashboard|aboutSuggestedRetirementAgeTitle')}
                modalDescription={<Body>{i18n.t('dashboard|aboutSuggestedRetirementAge')}</Body>}
              />
            }
            menuItems={menuItems}
          />
        ),
      },
    });
  }

  const whenRetired = getGrossAmountOrAmount(incomeWhenRetired, showGrossAmount);

  bars.push({
    id: '1',
    testID: testIds.DASHBOARD_GRAPH_DESIRED_PENSION_AGE_BAR,
    bar: whenRetired?.amount
      ? {
          amount: formatMoney(whenRetired, true),
          value: whenRetired.amount,
          description: i18n.t('dashboard|wantedPensionAge', { retirementAge }),
          color: getBarColor(
            incomeWhenRetired?.pensionVsTodayRatio,
            RETIREMENT,
            noRecommendationType
          ),
          trackId: 'WantedPensionAge',
        }
      : {
          centerLabel: '?',
          amount: '',
          value: 800,
          color: theme.COLORS.GRAPH_NEUTRAL,
          description: i18n.t('dashboard|wantedPensionAgeUnknown'),
          trackId: 'WantedPensionAge',
        },
    content: {
      component: (
        <Content
          navigation={navigation}
          testID={testIds.DASHBOARD_GRAPH_DESIRED_PENSION_AGE_CONTENT}
          withAgeSelector
          description={
            <Description
              key="wantedPensionAge"
              noRecommendationType={noRecommendationType}
              infoAccessibilityLabel={i18n.t('dashboard|recommendedPayoutModalA11y')}
              modalTitle={i18n.t('dashboard|recommendedPayoutModalTitle')}
              modalDescription={
                <>
                  <Body>{i18n.t('dashboard|recommendedPayoutModalDescription')}</Body>
                  <Margin marginTop="md" />
                  <H2>{i18n.t('dashboard|recommendedPayoutModalTitle2')}</H2>
                  <Margin marginTop="md" />
                  <Body>{i18n.t('dashboard|recommendedPayoutModalDescription2')}</Body>
                </>
              }
            />
          }
          menuItems={menuItems}
        />
      ),
    },
  });

  return bars;
};
