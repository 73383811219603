import PropTypes from 'prop-types';

export const ActionShape = PropTypes.shape({
  element: PropTypes.node,
  text: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'destructive-secondary']),
  mobilePosition: PropTypes.oneOf(['header', 'bottom-sticky', 'bottom', 'none']),
  inactive: PropTypes.bool,
  onPress: PropTypes.func,
  testID: PropTypes.string,
});

export const groupActions = (actions = []) => {
  const result = {
    header: [],
    bottomSticky: [],
    bottom: [],
  };

  actions.forEach((action) => {
    switch (action.mobilePosition) {
      case 'bottom':
        result.bottom.push(action);
        break;
      case 'bottom-sticky':
        result.bottomSticky.push(action);
        break;
      case 'none':
        break;
      case 'header':
      default:
        result.header.push(action);
        break;
    }
  });
  return result;
};

export const extractBottomActions = (actions = []) =>
  actions.reduce(
    (output, action) => {
      if (action.mobilePosition === 'bottom') {
        output.bottomActions.push(action);
      } else {
        output.otherActions.push(action);
      }
      return output;
    },
    { bottomActions: [], otherActions: [] }
  );
