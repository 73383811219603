import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import { create } from 'actions/feedback';
import withScreenContainer from 'decorators/withScreenContainer';
import * as routeNames from 'constants/routeNames';
import { ModalProvider } from '@sp/ui/context/ModalContext';
import ContactPage, { defaultFootnote } from 'containers/pages/ContactPage';
import { Subtitle } from '@sp/ui/typography';
import { dismiss } from 'helpers/navigation';
import { initFunc } from 'helpers/props';

export const FORM_NAME = 'feedbackContactInfo';

const FeedBackAddContactInfo = ({ sendFeedbackContactInfo, theme, navigation, i18n }) => (
  <ModalProvider isModal onClose={dismiss(navigation)}>
    <ContactPage
      title={i18n.t('feedbackTitle')}
      heading={i18n.t('feedbackContactInfo')}
      description={i18n.t('feedbackContactInfoDescription')}
      formName={FORM_NAME}
      onSubmit={sendFeedbackContactInfo}
      footnote={
        <>
          <Subtitle>{i18n.t('feedbackFootnote')}</Subtitle>
          {'\n\n'}
          {defaultFootnote(theme, i18n)}
        </>
      }
      actionsFn={({ isFormInactive, onSendPress }) => [
        {
          text: i18n.t('send'),
          inactive: isFormInactive,
          testID: 'feedbackAddContactInfoSubmitButton',
          onPress: onSendPress,
          mobilePosition: 'bottom',
        },
      ]}
      testID="feedbackAddContactInfoScreen"
    />
  </ModalProvider>
);

FeedBackAddContactInfo.propTypes = {
  sendFeedbackContactInfo: initFunc,
  theme: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

FeedBackAddContactInfo.defaultProps = {
  sendFeedbackContactInfo: undefined,
};

const mapStateToProps = (state, props) => ({
  type: props.route.params?.type,
  form: state.forms.feedback,
});

const init = ({ action, getProps }) =>
  action(
    async () => {
      const { navigation, dispatch, form, type } = getProps();

      return {
        sendFeedbackContactInfo: ({ email, phoneNumber, countryCode }) => {
          const phone = {
            number: phoneNumber,
            countryCode,
          };

          action(
            async () => {
              const payload = {
                ...form,
                type: type || 'feedback',
                email,
                phone,
              };
              await dispatch(create(payload));

              navigation.navigate(routeNames.FEEDBACK_COMPLETED);
            },
            {
              loader: true,
            }
          );
        },
      };
    },
    {
      loader: true,
    }
  );

export default withScreenContainer({
  mapStateToProps,
  init,
})(withTheme(FeedBackAddContactInfo));
