import React from 'react';
import PropTypes from 'prop-types';
import Margin from '@sp/ui/base/Margin';
import { Body } from '@sp/ui/typography';
import { TextBoxWrapperWithPadding, Column } from './styles.js';

const ModalBulletList = ({ text, title, removeMarginTop, children }) => (
  <>
    {!removeMarginTop && <Margin marginTop="md" />}
    <TextBoxWrapperWithPadding>
      <Body>{'•\t'}</Body>
      <Column>
        {title && <Body fontWeight="bold">{title}</Body>}
        {text && <Body>{text}</Body>}
        {children}
      </Column>
    </TextBoxWrapperWithPadding>
  </>
);

ModalBulletList.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  removeMarginTop: PropTypes.bool,
};

ModalBulletList.defaultProps = {
  text: undefined,
  title: undefined,
  children: undefined,
  removeMarginTop: false,
};

export default ModalBulletList;
