import { create } from '../helpers/reducers';
import * as actionTypes from '../constants/actionTypes';

export type AmountValue = {
  amount: number;
  currencyCode: string;
};

export type AmountValueWithGross = AmountValue & {
  grossAmount: number;
};

type PayoutType = 'Engangsudbetaling' | 'OphørendeUdbetaling' | 'LivsvarigUdbetaling';

export type AwardRule = {
  awardRuleCoverageType: string;
  scenarioEvent: string;
  payoutType: PayoutType;
  pensionCompanyName?: string;
  name?: string;
  referenceNumber: string;
  awardRuleDescription: string;
  beneficiaries: any[];
};

export type ChildAwardRule = Omit<AwardRule, 'scenarioEvent'> & {
  amount: AmountValue;
};

export type ChildrenInformation = {
  benefits: {
    hasBenefits: boolean;
    percentage: number;
  };
  birth: {
    age: number;
    date: Date;
    isLocked: boolean;
  };
  id: string;
  missingProperties: string[];
  name: string;
  isLinkable: boolean;
  isSelected?: boolean;
  parentLegalRelationship?: string;
  policies?: {
    retirement?: ChildAwardRule[];
    disability?: ChildAwardRule[];
    death?: ChildAwardRule[];
  };
};

export type CalculationSettings = {
  income?: {
    declaredAnnualIncomePartner?: number;
    declaredAnnualIncomeSelf?: number;
    hasSelectedDeclaredAnnualIncome?: number;
  };
  publicBenefits?: {
    hasElderCheque?: boolean;
    hasFolkPension?: boolean;
    hasFolkPensionSupplement?: boolean;
    hasChildAndYouthBenefit?: boolean;
    earlyRetirement?: string;
  };
  retirement?: {
    age?: number;
    isSingle?: boolean;
    residencyYears?: number;
    wealth?: number;
  };
  tax?: {
    isChurchContributor?: boolean;
    municipalCode?: number;
  };
};

export type AwardRules = {
  succeeded: boolean;
  awardRules?: AwardRule[];
  descriptions?: {
    retirementOverviewHeaderText?: string;
    retirementDashboardInfoText?: string;
    retirementAwardRuleDisclaimerText?: string;
    retirementAwardRulePolicyNumbers: string[];
    disabilityOverviewHeaderText?: string;
    disabilityDashboardInfoText?: string;
    disabilityAwardRuleDisclaimerText?: string;
    disabilityAwardRulePolicyNumbers: string[];
    deathDashboardInfoText?: string;
    deathDashboardInfoMarkdownText?: string;
    deathAwardRuleDisclaimerText?: string;
    deathAwardRulePolicyNumbers: string[];
    hasSupplementaryAgeSum?: boolean;
    hasGrafiskPensionVurdererAwardRule?: boolean;
  };
};

interface DataItem {
  status?: {
    pensionsInfoDataHasBeenFetched?: boolean;
    taxDataUpdateDate?: any;
    pensionsInfoDataUpdateDate?: any;
    disposableIncomeBelowRecommendationLimit?: any;
    sampensionDataHasBeenFetched?: any;
    hasActiveDisabilityCoverage?: any;
    hasActiveTemporaryDisabilityCoverage?: any;
  };
  failedCoverageOverview?: any;
  error?: any;
  incomeToday?: AmountValueWithGross;
  totalIncomeToday?: AmountValueWithGross;
  childAndYouthBenefitToday?: AmountValue;
  taxBasedPaysKirkeskat?: any;
  paysKirkeskat?: any;
  municipality?: any;
  earliestFolkepensionAge?: any;
  recommendedIncome?: any;
  allPensionYears?: {
    opsatFolkepensionAge: any;
    grossAmount?: any;
    amount?: any;
    pensionAge: number;
    coverageOverview: any[];
    perYear: {
      offentligeYdelserTotal: AmountValueWithGross;
      offentligeYdelserDetails: {
        name: string;
        amount: AmountValueWithGross;
      }[];
      roundedAveragePayoutMonth?: {
        offentligeYdelserTotal: AmountValueWithGross;
        offentligeYdelserDetails: {
          name: string;
          amount: AmountValueWithGross;
        }[];
        totalIncomeWithoutOneTimePayouts?: AmountValueWithGross;
      };
    }[];
  }[];
  dashboardErrors?: {
    errors: {
      errorId: any;
      [name: string]: any;
    }[];
  };
}

interface State {
  awardRulesIsFetching: boolean;
  data: {
    retirement: DataItem;
    disability: DataItem;
    death: DataItem;
    nakedDashboardErrorsToIgnore?: any[];
  };
  income?: {
    defaultIdentifier?: string;
    disposableIncomeOptions: {
      yearlyDetails?: any;
      period?: any;
      identifier?: string;
      selected?: any;
      monthlyAmount?: any;
      monthlyDetails?: any;
      companies?: any;
      source?: any;
      children?: {
        age: number;
        name: string;
      }[];
      totalChildrenPublicBenefit: AmountValueWithGross;
      childAndYouthBenefit: AmountValue;
    }[];
    showGrossAmount?: any;
  };
  awardRules?: AwardRules;
  retirementAge?: any;
  pickerValue?: any;
  retirementAgeModalVisible: boolean;
  calculationSettings?: CalculationSettings;
  childrenData?: {
    allowResetChildren: boolean;
    children: ChildrenInformation[];
  };
  linkChildrenFlow?: {
    linkedChildIds: string[];
    linkableChildInFocusIndex?: number;
    hasBeenReviewed?: boolean;
    linkableChildren?: ChildrenInformation[];
    linkableSelections?: ChildrenInformation[];
  };
}

const createDefaults = (): State => ({
  data: {
    retirement: {},
    disability: {},
    death: {},
    nakedDashboardErrorsToIgnore: undefined,
  },
  income: undefined,
  awardRulesIsFetching: false,
  awardRules: undefined,
  pickerValue: undefined,
  retirementAgeModalVisible: false,
  calculationSettings: undefined as any,
  childrenData: undefined as any,
});

export default create(
  {
    RESPONSE_RETIREMENT_OVERVIEW: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        retirement: {
          ...payload.response,
          // NOTE: We read the previous error from the state to prevent flickering of dashboard
          // and to sync refetchNakedDashboardErrors and the response action
          dashboardErrors: state.data.retirement.dashboardErrors,
        },
      },
    }),
    RESPONSE_DISABILITY_OVERVIEW: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        disability: {
          ...payload.response,
          // NOTE: We read the previous error from the state to prevent flickering of dashboard
          // and to sync refetchNakedDashboardErrors and the response action
          dashboardErrors: state.data.disability.dashboardErrors,
        },
      },
    }),
    RESPONSE_DEATH_OVERVIEW: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        death: {
          ...payload.response,
          // NOTE: We read the previous error from the state to prevent flickering of dashboard
          // and to sync refetchNakedDashboardErrors and the response action
          dashboardErrors: state.data.death.dashboardErrors,
        },
      },
    }),
    [actionTypes.RESPONSE_GET_CALCULATION_PROFILE]: (state, payload) => ({
      ...state,
      calculationSettings: payload.response,
    }),
    [actionTypes.RESPONSE_PUT_CALCULATION_PROFILE]: (state, payload) => ({
      ...state,
      calculationSettings: payload.response,
    }),
    [actionTypes.RESPONSE_GET_CHILDREN]: (state, payload) => ({
      ...state,
      childrenData: payload.response,
    }),
    [actionTypes.UPDATE_LINK_CHILDREN_FLOW]: (state, payload) => ({
      ...state,
      linkChildrenFlow: {
        ...state.linkChildrenFlow,
        ...payload,
      },
    }),
    [actionTypes.REMOVE_LINK_CHILDREN_FLOW]: (state) => ({
      ...state,
      linkChildrenFlow: undefined,
    }),
    [actionTypes.GET_INCOME_RESPONSE]: (state, payload) => ({
      ...state,
      income: payload.response,
    }),
    [actionTypes.UPDATE_INCOME_RESPONSE]: (state, payload) => ({
      ...state,
      income: payload.response.options,
    }),
    REQUEST_UPDATE_SHOW_GROSS_INCOME: (state, payload) => ({
      ...state,
      income: {
        ...state.income!,
        showGrossAmount: payload.showGrossAmount,
      },
    }),
    [actionTypes.GET_AWARD_RULES_REQUEST]: (state) => ({
      ...state,
      awardRulesIsFetching: true,
    }),
    [actionTypes.GET_AWARD_RULES_RESPONSE]: (state, payload) => ({
      ...state,
      awardRulesIsFetching: false,
      awardRules: payload.response,
    }),
    REQUEST_SET_EXPECTED_PENSION_AGE: (state, payload) => ({
      ...state,
      retirementAge: payload.Retirement.Expectations.Age,
    }),
    DASHBOARD_ACCEPT_INCOMPLETE_DATA: (state) => ({
      ...state,
      acceptsIncompleteData: true,
    }),
    DASHBOARD_NEEDS_RECALCULATION: (state, payload) => ({
      ...state,
      needsRecalculation: payload,
    }),
    UPDATE_NAKED_DASHBOARD_ERRORS: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        [payload.area]: {
          ...state.data[payload.area as keyof State['data']],
          dashboardErrors: {
            errors: payload.errors,
            dashboardArea: payload.area,
          },
        },
      },
    }),
    IGNORE_NAKED_DASHBOARD_ERROR: (state, payload) => {
      const errorsToIgnore = state.data.nakedDashboardErrorsToIgnore || [];
      errorsToIgnore.push(payload);
      return {
        ...state,
        data: {
          ...state.data,
          nakedDashboardErrorsToIgnore: errorsToIgnore,
        },
      };
    },
  },
  createDefaults
);

export type { State };
