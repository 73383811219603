import { useEffect } from 'react';
import { getIsDevOrTest } from 'helpers/environment';

const TAX_INIT_STATUSES = ['started', 'created'];
const TAX_FAILED_STATUSES = ['failed'];
const TAX_SUCCESS_STATUSES = ['completed'];
const PENSIONSINFO_INIT_STATUS = 'Pending';
const PENSIONSINFO_SUCCESS_STATUS = 'Received';
const INIT = [...TAX_INIT_STATUSES, PENSIONSINFO_INIT_STATUS];
const SUCCESS = [...TAX_SUCCESS_STATUSES, PENSIONSINFO_SUCCESS_STATUS];
const FAILED = TAX_FAILED_STATUSES;

export const useHandlePollingResponse = ({
  currentFetchingStatus,
  fetchingController,
  callback,
}: {
  currentFetchingStatus?: string;
  fetchingController?: string;
  callback: (fetchingStatus: string, failType: string) => void;
}) => {
  /* NOTE: Placed here to eliminate hoisting problem for unit test */
  const isDevOrTest = getIsDevOrTest();

  useEffect(() => {
    if (!currentFetchingStatus) {
      return;
    }

    // if (isDevOrTest) {
    //   callback('simulation', currentFetchingStatus);
    //   return;
    // }

    if (INIT.includes(currentFetchingStatus) && fetchingController !== 'started') {
      callback('started', currentFetchingStatus);
    }

    if (FAILED.includes(currentFetchingStatus) && fetchingController !== 'failed') {
      callback('failed', currentFetchingStatus);
    }

    if (SUCCESS.includes(currentFetchingStatus) && fetchingController !== 'success') {
      callback('success', currentFetchingStatus);
    }
  }, [fetchingController, currentFetchingStatus, isDevOrTest, callback]);
};
