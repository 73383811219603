import React, { useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import * as routeNames from 'constants/routeNames';
import { getProfile, getTaxStatementStatus } from 'actions/profile';
import useAsyncInit from 'hooks/useAsyncInit';
import useI18n from 'hooks/useI18n';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNextAction } from './hooks';
import {
  selectHasFetchedPensionsInfo,
  selectHasFetchedTax,
  selectIsTaxStatementValid,
  selectIsOnboarding,
} from 'selectors/fetchTaxAndPensionsInfo';
import { selectDeeplink } from 'selectors/deeplink';
import { selectFlowControl } from 'selectors/flowControl';
import { selectIsDeviceTypeDesktop, selectIsDeviceTypeMobile } from 'selectors/device';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import MenuPageContainer from 'containers/v2/pages/MenuPage/MenuPageV2';
import type { RootState } from 'store';
import { isWeb } from 'helpers/platform';

const { default: Margin } = require('@sp/ui/base/Margin');
const { default: withScreenContainer } = require('decorators/withScreenContainer');
const { default: Tracking } = require('services/tracking');
const { ContentWithIcon } = require('@sp/ui/v2/pages/BasePage');
const { ModalProvider } = require('@sp/ui/v2/context/ModalContext');

const ICON_WIDTH = 72;
const ICON_HEIGHT = 65;

const selectFetchDone = (state: RootState) => ({
  deeplink: selectDeeplink(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
  isMobile: selectIsDeviceTypeMobile(state),
  isTaxStatementValid: selectIsTaxStatementValid(state),
  flowControl: selectFlowControl(state),
  hasFetchedTax: selectHasFetchedTax(state),
  hasFetchedPensionsInfo: selectHasFetchedPensionsInfo(state),
  isOnboarding: selectIsOnboarding(state),
});

const FetchDone: React.FC = () => {
  const i18n = useI18n(['fetchTaxAndPensionsInfo']);
  const navigation = useNavigation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const route = useRoute();
  const { onClose } = useContext(FetchTaxAndPensionsInfoContext);

  const {
    deeplink,
    flowControl,
    isDesktop,
    isMobile,
    isTaxStatementValid,
    hasFetchedTax,
    hasFetchedPensionsInfo,
    isOnboarding,
  } = useAppSelector(selectFetchDone, shallowEqual);

  const nextAction = useNextAction();

  const { isDeeplink } = deeplink ?? {};
  const { onPensionComplete } = flowControl ?? {};
  const onDoneTax = hasFetchedTax && !hasFetchedPensionsInfo;
  const body = i18n.t(
    `fetchTaxAndPensionsInfo|${
      onDoneTax ? 'informationRecievedTax' : 'informationRecievedPensionsInfo'
    }`
  );

  /* NOTE: This is required to observe whether tax statement
  is valid or not */
  useAsyncInit(
    async () => {
      if (onDoneTax) {
        await dispatch([getProfile(), getTaxStatementStatus()]);
      }
    },
    [],
    {
      error: {
        type: 'blocking',
        retry: true,
      },
      loading: {
        type: 'blocking',
      },
    }
  );

  return (
    <ModalProvider
      hasOnCloseButton={!isDeeplink}
      onClose={() => {
        isOnboarding
          ? navigation.reset({
              index: 0,
              routes: [{ name: 'main' }],
            })
          : onClose();
      }}
      isModal
      secondaryColor
    >
      <MenuPageContainer
        title={isMobile ? '' : i18n.t('fetchTaxAndPensionsInfo|pensionsInfoFetched')}
        hasBackButton={false}
        actions={[
          {
            text: i18n.t('common|next'),
            onPress: () => {
              Tracking.trackEvent({ area: route.name, action: 'ClickNextButtonSuccess' });

              if (onPensionComplete) {
                navigation.navigate(onPensionComplete);
              } else if (isTaxStatementValid) {
                nextAction();
              } else {
                Tracking.trackEvent({ area: 'TaxStatementInvalidConsent', action: 'ClickStart' });
                navigation.navigate(routeNames.TAX_STATEMENT_INVALID_CONSENT);
              }
            },
            mobilePosition: 'bottom-sticky',
          },
        ]}
      >
        <Margin horizontal="xl" marginTop="md">
          <ContentWithIcon
            isMobile={isMobile}
            title={isDesktop ? undefined : i18n.t('fetchTaxAndPensionsInfo|informationRecieved')}
            textAlignment="center"
            body={body}
            icon={{
              fill:
                // special condition for mobile web menupage template with white background color
                isDesktop || (isWeb && isMobile) ? theme.COLORS.V2_PRIMARY_BACKGROUND : undefined,
              width: ICON_WIDTH,
              height: ICON_HEIGHT,
              name: 'done',
            }}
          />
        </Margin>
      </MenuPageContainer>
    </ModalProvider>
  );
};

export default withScreenContainer()(FetchDone);
