import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import Row from '@sp/ui/v2/base/rows';
import Margin from '@sp/ui/base/Margin';
import { Body2 } from '@sp/ui/v2/typography';

const ContentWrapper = styled.View`
  margin-bottom: ${(props) => props.marginBottom || props.theme.SPACINGS.md}px;
  margin-right: ${(props) => props.marginRight || props.theme.SPACINGS.md}px;
  margin-left: ${(props) => props.marginLeft || props.theme.SPACINGS.md}px;
`;

const OneLineRowWithContent = ({ textLeft, textRight, description }) => (
  <Fragment>
    <Row
      componentRight={<Margin marginRight="md" />}
      textLeft={
        <Body2 fontWeight="regular" secondaryColor>
          {textLeft}
        </Body2>
      }
      textRight={
        <Body2 fontWeight="regular" secondaryColor>
          {textRight}
        </Body2>
      }
    />
    <ContentWrapper>
      <Body2 fontWeight="regular" secondaryColor>
        {description}
      </Body2>
    </ContentWrapper>
  </Fragment>
);

OneLineRowWithContent.propTypes = {
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
  description: PropTypes.string,
};

OneLineRowWithContent.defaultProps = {
  textLeft: undefined,
  textRight: undefined,
  description: undefined,
};

export default OneLineRowWithContent;
