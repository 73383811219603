import features from 'features';
import { isWeb } from 'helpers/platform';

export class Configuration {
  get baseUrl() {
    if (!isWeb) {
      return 'https://login.sampension.dk';
    }
    return `${global.location?.protocol}//${global.location?.host}`;
  }
  get isDev() {
    return features.isEnabled('development');
  }

  get env() {
    if (isWeb) {
      if (__DEV__) {
        return 'dev';
      }

      const [, ...prDomain] = global.location.hostname.split('.');
      if (prDomain.join('.') === 'login-preview.sampension.app') {
        return 'preview';
      }
    }

    return features.get('environment') || 'default';
  }

  get isTest() {
    return process.env.BUILD_ENV === 'test';
  }
}

export default new Configuration();
