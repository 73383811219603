import { getAppointmentEndTime } from 'helpers/string';
import { createMemoSelect } from '../utils';

import selectSelectedDate from './selectSelectedDate';

import {
  selectSelectedLocation,
  selectSelectedOrganizer,
  selectSelectedAppointmentType,
} from './simple';

/**
 * NOTE: iOS hermes runtime does not support toLocaleString and Intl
 * so we need to wait for that to happen before we can
 * use new date.toLocaleString to implement this
 * https://github.com/facebook/hermes/issues/23
 */

const isDST = (date) => {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== date.getTimezoneOffset();
};

const getDateInDanishTime = (date) => {
  const gmtOffset = isDST(date) ? 2 : 1;
  const denmarkTimezoneOffset = gmtOffset * 60 * 60000;
  const userOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + denmarkTimezoneOffset + userOffset);
};

// TODO: unit test this
const groupAppointmentsForTimeFrames = (appointments) => {
  // NOTE: first item is the start hour, second the end hour, third is the group key (auto-sorted)
  const timeFrames = [
    [8, 12, 1],
    [12, 16, 2],
    [16, 24, 3],
  ];

  const result = {};
  const timeOverlaps = [];

  appointments.forEach((appointment) => {
    const startsAt = new Date(appointment.startsAt);
    const startsAtDanishTime = getDateInDanishTime(startsAt);

    const endsAt = getAppointmentEndTime(startsAt, appointment.duration);
    const endsAtDanishTime = getDateInDanishTime(endsAt);

    const startHour = startsAtDanishTime.getHours();

    const timeFrame = timeFrames.find((t) => startHour >= t[0] && startHour < t[1]);

    if (!timeFrame) {
      return;
    }

    const resultKey = timeFrame[2];
    const resultItem = {
      startsAt: startsAtDanishTime,
      endsAt: endsAtDanishTime,
      appointment,
    };

    // We need to make sure we don't have 2 appointments for the same time
    const timeOverlap = startsAtDanishTime.getTime() + endsAtDanishTime.getTime() / 1000;

    if (result[resultKey]) {
      if (!timeOverlaps.includes(timeOverlap)) {
        result[resultKey].push(resultItem);
        timeOverlaps.push(timeOverlap);
      } else {
        // We randomize overlapping appointments
        const overlapIndex = result[resultKey].findIndex(
          (app) =>
            app.startsAt.getTime() === resultItem.startsAt.getTime() &&
            app.endsAt.getTime() === resultItem.endsAt.getTime()
        );

        if (Math.floor(Math.random() * 2) > 0) {
          result[resultKey][overlapIndex] = resultItem;
        }
      }
    } else {
      result[resultKey] = [resultItem];
      timeOverlaps.push(timeOverlap);
    }
  });

  // Sorting the result by start time
  Object.keys(result).forEach((key) => {
    result[key] = result[key].sort((a, b) => a.startsAt - b.startsAt);
  });

  return result;
};

const selectAvailableTimeSlots = (
  selectedDate,
  selectedAppointmentType,
  selectedOrganizer,
  selectedLocation,
  availableAppointments
) => {
  if (!selectedDate || !selectedAppointmentType) {
    return undefined;
  }

  const selectedDateSlice = selectedDate.toISOString().slice(0, 10);

  const availableAppointmentsOnSelectedDate = availableAppointments.filter((appointment) => {
    if (selectedOrganizer && appointment.organizerId !== selectedOrganizer.organizerId) {
      return false;
    }

    if (selectedLocation && appointment.location !== selectedLocation) {
      return false;
    }
    return appointment.startsAt.startsWith(selectedDateSlice);
  });

  return groupAppointmentsForTimeFrames(availableAppointmentsOnSelectedDate);
};

const customMemorize = (prev, next) => {
  const [prevDate, prevAppointmentType, prevOrganizer, prevLocation] = prev;
  const [nextDate, nextAppointmentType, nextOrganizer, nextLocation] = next;

  if (
    prevDate?.getTime() === nextDate?.getTime() &&
    prevAppointmentType === nextAppointmentType &&
    prevOrganizer === nextOrganizer &&
    prevLocation === nextLocation
  ) {
    return true;
  }

  return false;
};

export default createMemoSelect(
  selectSelectedDate,
  selectSelectedAppointmentType,
  selectSelectedOrganizer,
  selectSelectedLocation,
  (state) => state.areas.booking.bookingVariations || [],
  selectAvailableTimeSlots,
  customMemorize
);
