import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchPensionsInfo } from 'actions/fetchTaxAndPensionsInfo';
import * as routeNames from 'constants/routeNames';
import features from 'features';
import withScreenContainer from 'decorators/withScreenContainer';
import useTrackingOnFocus from 'hooks/useTrackingOnFocus';
import { useAppDispatch } from 'hooks/redux';
import { fetchSavingsOverview } from 'actions/savings';
import { fetchTransferOverview, createTransferForm, resetTransferForm } from 'actions/transfer';
import { selectIsDeviceTypeDesktop } from 'selectors/device';
import { selectAllSavingsData, selectMissingSavingsData } from 'selectors/savings';
import { selectTransferData, selectTransferForm } from 'selectors/transfer';
import {
  selectSavings as selectInternalSavings,
  selectSavingsWithDepositsEnabled,
} from 'selectors/deposits';
import { getCurrentInvestmentProfiles } from 'actions/changeInvestmentProfile';
import { initFunc } from 'helpers/props';
import { getSavings } from 'actions/deposits';
import Tracking from 'services/tracking';
import FlexPage from '@sp/ui/pages/FlexPage';
import { FlatList } from '@sp/ui/base/lists';
import { getTransferDetails } from 'areas/transfer/helpers/getTransferDetails';
import { navigateToTransfer } from 'areas/transfer/helpers/navigateToTransfer';
import PolicyListItem from './components/policies/PolicyListItem';
import NoSavingsData from './components/policies/NoSavingsData';
import SavingsOverviewFooter from './components/policies/SavingsOverviewFooter';
import { getSavingsDetails } from './helpers/getSavingsDetails';
import { getSavingsOverviewActions } from './helpers/getSavingsOverviewActions';
import { VIEW_SAVINGS, CLICK_DEPOSITS } from 'areas/savings/trackingIds';

const Overview = ({
  savingsData,
  transferData,
  savingsWithDepositsEnabled,
  refreshSavingsOverview,
  missingSavingsData,
  goToPoliceDetails,
  goToTransferFlow,
  goToDepositsFlow,
  isFetching,
  isDesktop,
  i18n,
}) => {
  const [refreshing, setRefreshing] = useState(false);

  useTrackingOnFocus(VIEW_SAVINGS);

  const dispatch = useAppDispatch();

  const updatePensionsInfo = useCallback(() => dispatch(fetchPensionsInfo()), [dispatch]);

  const { policies, pensionInfoLastUpdate, isSampensionEmployee } = getSavingsDetails(savingsData);
  const { isTransferButtonActive } = getTransferDetails(transferData);

  const missingSavingsDataKey =
    missingSavingsData &&
    Object.keys(missingSavingsData)[Object.values(missingSavingsData).findIndex((item) => item)];

  return (
    <FlexPage
      title={i18n.t('savings|Opsparinger')}
      heading={isDesktop && i18n.t('savings|Opsparinger')}
      actions={
        savingsData &&
        getSavingsOverviewActions({
          isTransferButtonActive,
          isDepositsButtonActive: savingsWithDepositsEnabled,
          goToTransferFlow,
          goToDepositsFlow,
          updatePensionInfo: updatePensionsInfo,
          missingSavingsDataKey,
          i18n,
        })
      }
      hasBackButton={false}
      testID="savingsScreen"
    >
      {savingsData && missingSavingsDataKey && (
        <NoSavingsData
          missingSavingsDataKey={missingSavingsDataKey}
          pensionInfoLastUpdate={pensionInfoLastUpdate}
        />
      )}
      {!missingSavingsDataKey && policies && (
        <FlatList
          keyExtractor={(item) => `${item.key}`}
          refreshing={refreshing && isFetching}
          onRefresh={() => {
            refreshSavingsOverview();
            setRefreshing(true);
          }}
          data={policies}
          renderItem={({ item }) => (
            <PolicyListItem
              key={item.key}
              item={item}
              showDetails={goToPoliceDetails}
              savingsData={savingsData}
              isSampensionEmployee={isSampensionEmployee}
            />
          )}
          ListFooterComponent={<SavingsOverviewFooter />}
        />
      )}
    </FlexPage>
  );
};

Overview.propTypes = {
  savingsData: PropTypes.object,
  transferData: PropTypes.object,
  missingSavingsData: PropTypes.object,
  goToPoliceDetails: initFunc,
  refreshSavingsOverview: initFunc,
  goToTransferFlow: initFunc,
  isFetching: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
  savingsWithDepositsEnabled: PropTypes.bool,
  goToDepositsFlow: initFunc,
};

Overview.defaultProps = {
  savingsData: undefined,
  goToPoliceDetails: undefined,
  goToTransferFlow: undefined,
  transferData: undefined,
  refreshSavingsOverview: undefined,
  missingSavingsData: undefined,
  savingsWithDepositsEnabled: undefined,
  goToDepositsFlow: undefined,
};

const mapStateToProps = (state) => ({
  transferData: selectTransferData(state),
  savingsData: selectAllSavingsData(state),
  missingSavingsData: selectMissingSavingsData(state),
  isDesktop: selectIsDeviceTypeDesktop(state),
  updated: state.areas.savings.updated,
  isFetching: state.areas.savings.isFetching,
  internalSavings: selectInternalSavings(state),
  transferForm: selectTransferForm(state),
  savingsWithDepositsEnabled:
    features.isEnabled('deposits') && selectSavingsWithDepositsEnabled(state)?.length > 0,
});

export default withScreenContainer({
  mapStateToProps,
  overlayScreenProps: {
    titleKey: 'savings|Opsparinger',
  },
  init: ({ action, getProps }) =>
    action(
      async () => {
        const { savingsData, transferData, internalSavings, dispatch, navigation } = getProps();

        const dispatchArray = [getCurrentInvestmentProfiles(true)];

        if (!savingsData) dispatchArray.push(fetchSavingsOverview());
        if (!transferData) dispatchArray.push(fetchTransferOverview());

        if (features.isEnabled('deposits') && !internalSavings) {
          dispatchArray.push(getSavings(true));
        }

        await dispatch(dispatchArray);

        return {
          refreshSavingsOverview: () =>
            action(async () => {
              await dispatch(fetchSavingsOverview());
            }),
          goToPoliceDetails: (pID) => navigation.navigate(routeNames.SAVINGS_DETAILS, { pID }),
          goToTransferFlow: () => {
            const { transferData: fetchedTransferData, transferForm } = getProps();

            if (transferForm) {
              dispatch(resetTransferForm());
            }

            navigateToTransfer({
              transferData: fetchedTransferData,
              createTransferForm: () => dispatch(createTransferForm()),
              navigation,
            });
          },
          goToDepositsFlow: () =>
            action(() => {
              Tracking.trackEvent(CLICK_DEPOSITS);
              navigation.navigate(routeNames.DEPOSITS, {
                screen: routeNames.DEPOSITS_SAVINGS,
              });
            }),
        };
      },
      {
        loader: true,
        error: {
          type: 'overlay',
          retry: true,
        },
      }
    ),
})(Overview);
