import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import useI18n from 'hooks/useI18n';

const { default: Conditional } = require('decorators/conditional');
const { default: Margin } = require('@sp/ui/base/Margin');
const { Body2 } = require('@sp/ui/v2/typography');

type Props = {
  childrenData?: {
    name: string;
    age: number;
  }[];
};

const Wrapper = Conditional({
  mobile: styled.View`
    flex: 1;
  `,
  default: Fragment,
});

const RowWrapper = styled.View`
  flex-direction: row;
`;

const ChildrenList: React.FC<Props> = ({ childrenData }) => {
  const i18n = useI18n(['dashboard']);

  return (
    <>
      {childrenData?.map(({ name, age }, i) => (
        <RowWrapper key={`${name}_${i}`}>
          <Wrapper>
            <Body2 fontWeight="bold" numberOfLines={1} secondaryColor>
              {name}
            </Body2>
          </Wrapper>
          <Margin marginRight="m" marginLeft="s">
            <Body2 fontWeight="bold" secondaryColor>
              {`(${i18n.t('dashboard|childRowAge', { age })})`}
            </Body2>
          </Margin>
        </RowWrapper>
      ))}
    </>
  );
};

ChildrenList.defaultProps = {
  childrenData: undefined,
};

export default ChildrenList;
