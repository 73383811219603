import React from 'react';
import { Linking } from 'react-native';
import library from '@sp/ui/library';
import Link from './Link';
import ErrorText from './ErrorText';
import { isWeb } from '@sp/ui/helpers/device';

const linkToAction = (link, goToPage, handleLink) => () => {
  switch (link.type) {
    case 'Url': {
      return isWeb ? global.open(link.target, '_self') : Linking.openURL(link.target);
    }
    case 'Page': {
      return goToPage(link.target);
    }
    default: {
      return handleLink ? handleLink(link.type, link.target) : undefined;
    }
  }
};

const getComponentFromPath = (name) =>
  name.split('/').reduce((output, key) => (output ? output[key] : undefined), library);

const expandProps = (props, goToPage, handleLink, featureCheck) =>
  Object.keys(props || {}).reduce(
    (output, key) => ({
      ...output,
      [key]: createElement(props[key], goToPage, handleLink, featureCheck),
    }),
    {}
  );

const addLink = (node, item, goToPage, handleLink) => {
  const { link } = item;
  if (!link) {
    return node;
  }
  const onPress = linkToAction(link, goToPage, handleLink);
  return (
    <Link key={item.id} {...link} onPress={onPress}>
      {node}
    </Link>
  );
};

const createElements = (sectionItems, goToPage, handleLink, featureCheck) => {
  const result = sectionItems.map((item) => {
    const { id, component, data = {}, content, feature } = item;
    if (!component) {
      return item;
    }
    if (feature && featureCheck && !featureCheck(feature)) {
      return [];
    }
    const Component = getComponentFromPath(component);
    if (!Component) {
      return <ErrorText>Missing component {component}</ErrorText>;
    }
    const expandedProps = expandProps(data, goToPage, handleLink, featureCheck);
    const children = createElement(content, goToPage, handleLink, featureCheck);
    return addLink(
      <Component key={id} goToPage={goToPage} linkToAction={linkToAction} {...expandedProps}>
        {children}
      </Component>,
      item,
      goToPage,
      handleLink
    );
  });
  return result;
};

export const createElement = (item, goToPage, handleLink, featureCheck) => {
  if (!item) {
    return item;
  }
  if (Array.isArray(item)) {
    return createElements(item, goToPage, handleLink, featureCheck);
  }
  if (typeof item === 'object' && item.type === 'Component' && item.component) {
    return getComponentFromPath(item.component);
  }
  if (typeof item === 'object' && item.type === 'Node' && item.component) {
    return createElements([item], goToPage, handleLink, featureCheck);
  }
  return item;
};
