import Conditional from 'decorators/conditional';
import Desktop from './index.desktop';
import Tablet from './index.tablet';
import Mobile from './index.mobile';

export default Conditional({
  tablet: Tablet,
  mobile: Mobile,
  default: Desktop,
});
