import styled from 'styled-components/native';
import { withDefaultTypographyProps } from '@sp/ui/v2/typography/index.shared';

const H4 = styled(withDefaultTypographyProps)`
  font-size: ${(props) => props.theme.FONT_SIZES.V2_H4.DEFAULT}px;
  line-height: ${(props) => props.theme.FONT_SIZES.V2_H4.DEFAULT * 1.5}px;
`;

H4.defaultProps = {
  fontWeight: 'bold',
  fontStyle: 'normal',
};

export default H4;
