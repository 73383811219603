import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import withScreenContainer from 'decorators/withScreenContainer';
import { Body, H5, Footnote } from '@sp/ui/typography';
import {
  selectRetirementAge,
  selectDisposableIncomeOptions,
  selectAwardRules,
  selectPayoutsOverTimeAwardRules,
  selectShowGrossAmount,
} from 'selectors/dashboard';
import IncomeTodayDetails from '../IncomeTodayDetails';
import { OneLineRow, RowBorder } from '@sp/ui/base/rows';
import Margin from '@sp/ui/base/Margin';
import ListAwardRules from 'areas/dashboard/AwardRules/ListAwardRules';
import { getDescriptionText } from 'areas/dashboard/payouts/payoutsOverTime/helpers';
import { PayoutsDescription } from 'areas/dashboard/styles';
import YearlyPayoutDetailsRow from 'areas/dashboard/payouts/YearlyPayoutDetailsRow';
import { DEATH, DISABILITY, RETIREMENT } from '../../constants/dashboardTypes';

const PayoutsOverTime = ({
  retirementAge,
  theme,
  i18n,
  awardRulesOverTime,
  awardRules,
  showGrossAmount,
  payouts,
  payoutType,
}) => {
  const { hasSupplementaryAgeSum, supplementaryAgeSumDescription } = awardRules?.descriptions ?? {};

  return (
    <>
      {(payoutType === DISABILITY || payoutType === DEATH) && (
        <ListAwardRules
          textLeft={i18n.t('dashboard|payoutCriteria')}
          awardRules={awardRulesOverTime}
          payoutType={payoutType}
          footerText
          showDisclaimer
        />
      )}

      <PayoutsDescription>
        <Body textAlign="center">
          {getDescriptionText(retirementAge, payoutType, i18n, showGrossAmount, awardRules)}
        </Body>
      </PayoutsDescription>

      <RowBorder />

      <OneLineRow
        textLeft={
          <H5>{payoutType === DEATH ? i18n.t('dashboard|year') : i18n.t('dashboard|age')}</H5>
        }
        textRight={<H5>{i18n.t('dashboard|payoutPrMonth')}</H5>}
        backgroundColor={theme.COLORS.PRIMARY_VARIANT1}
      />

      <IncomeTodayDetails />

      {payouts?.map((item) => (
        <YearlyPayoutDetailsRow
          item={item}
          payoutType={payoutType}
          i18n={i18n}
          key={`YearlyPayoutDetailsRow-${item.age}`}
          showGrossAmount={showGrossAmount}
        />
      ))}

      <Margin horizontal="md" vertical="sm">
        <Footnote>{i18n.t('dashboard|payoutsOverTimeDisclaimer')}</Footnote>
        {hasSupplementaryAgeSum && supplementaryAgeSumDescription && payoutType === RETIREMENT && (
          <Margin marginTop="sm">
            <Footnote>{supplementaryAgeSumDescription}</Footnote>
          </Margin>
        )}
      </Margin>
    </>
  );
};

PayoutsOverTime.propTypes = {
  payouts: PropTypes.array,
  retirementAge: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  awardRules: PropTypes.object,
  awardRulesOverTime: PropTypes.array,
  showGrossAmount: PropTypes.bool.isRequired,
  payoutType: PropTypes.string.isRequired,
};

PayoutsOverTime.defaultProps = {
  payouts: undefined,
  awardRules: undefined,
  awardRulesOverTime: [],
};

const mapStateToProps = (state, props) => ({
  retirementAge: selectRetirementAge(state),
  hasDisposableIncomeOptions: selectDisposableIncomeOptions(state),
  awardRules: selectAwardRules(state),
  awardRulesOverTime: selectPayoutsOverTimeAwardRules(state, props.payoutType),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withScreenContainer({
  mapStateToProps,
})(withTheme(PayoutsOverTime));
