export const getUpdatedOptionsAndSelected = (options, defaultValue) => {
  const updatedOptions =
    options && options.map((o) => (typeof o === 'object' ? o : { name: o, value: o }));

  const selected =
    (updatedOptions &&
      updatedOptions.find((o) => o?.key === defaultValue || o.value === defaultValue)) ||
    {};
  return {
    updatedOptions,
    selected,
  };
};
