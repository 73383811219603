import { SET_IS_APP_RATING_OPEN } from 'constants/actionTypes';
import { create } from 'helpers/reducers';

interface State {
  isOpen: boolean;
  area?: any;
}

const createDefaults = (): State => ({
  isOpen: false,
});

export default create(
  {
    [SET_IS_APP_RATING_OPEN]: (state, { isOpen, area }) => ({
      ...state,
      isOpen,
      area,
    }),
  },
  createDefaults
);

export type { State };
