import { StyleSheet } from 'react-native';
import { css } from 'styled-components';

export type WrapperBaseProps = {
  hasError: boolean;
  marginTop: number;
  disabled: boolean;
};

const TEXT_INPUT_HEIGHT = 44;

export const wrapperStyleFn = css<WrapperBaseProps>`
  margin-top: ${({ marginTop }) => marginTop}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: ${TEXT_INPUT_HEIGHT}px;
  padding: 0px ${({ theme }) => theme.SPACINGS.md}px;
  border-color: ${({ hasError, theme }) =>
    hasError ? theme.COLORS.PRIMARY_ALERT : theme.COLORS.PRIMARY_BORDER};
  border-width: ${StyleSheet.hairlineWidth}px;
  border-radius: ${({ theme }) => theme.DEFAULTS.BORDER_RADIUS}px;
  ${({ disabled, theme }) => (disabled ? `background: ${theme.COLORS.V2_SECONDARY_SELECTED}` : '')};
`;
