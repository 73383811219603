import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Margin from '@sp/ui/base/Margin';
import BackgroundPage from '@sp/ui/pages/BackgroundPage';
import { Result } from '@sp/ui/pages/ResultPage';
import Menu from 'containers/navigation/Menu';
import BlockLinks from './BlockLinks';

import { enhanceCustomerBlock, getActions } from './index.shared';

const WIDTH = 620;

const Wrapper = styled.View`
  flex-direction: column;
  align-items: center;
  width: ${WIDTH}px;
  padding-top: ${(props) => props.theme.SPACINGS.xl}px;
`;

const CustomerBlock = ({ blockMessage, theme, i18n, logout }) => {
  // NOTE: this is needed because the blockMessage gets cleared
  // in the store by logout while this screen is still mounted
  if (!blockMessage) {
    return null;
  }

  const { title, reason, actions: actionData } = blockMessage;

  const actions = getActions(i18n, logout);

  return (
    <BackgroundPage
      backgroundColor={theme.COLORS.SECONDARY_BACKGROUND}
      actions={actions}
      hasHeader={false}
      backButton={null}
      menu={<Menu />}
    >
      <Wrapper>
        <Result
          iconName="warning"
          iconColor={theme.COLORS.PRIMARY}
          heading={title}
          description={reason}
        />
        <Margin marginBottom="md" />
        {actionData && <BlockLinks items={actionData} />}
      </Wrapper>
    </BackgroundPage>
  );
};

CustomerBlock.propTypes = {
  blockMessage: PropTypes.shape({
    title: PropTypes.string,
    reason: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  logout: PropTypes.func,
};

CustomerBlock.defaultProps = {
  blockMessage: undefined,
  logout: undefined,
};

export default enhanceCustomerBlock(CustomerBlock);
