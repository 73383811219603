import React from 'react';
import styled, { withTheme } from 'styled-components/native';
import { H5, Subtitle, Footnote, Body } from '@sp/ui/typography';
import PropTypes from 'prop-types';
import Dimensions, { isAndroid } from '@sp/ui/helpers/device';

const INNER_PADDING = 16;
const MARGIN_BOTTOM_MOBILE_TABLET = 16;
const MARGIN_BOTTOM_DESKTOP = 24;
const BREAK_POINT = 1200;
const WINDOW_WIDTH = Dimensions.get('window').width;
const BOX_SHADOW = '0px 5px 10px #d0d0d0';
const JustifyContent = 'center';
const IconPaddingRight = 7;
const PADDING_LARGE = 16;
const PADDING_MEDIUM = 8;
const PADDING_NONE = 0;

const View = styled.View``;

const BoxShadow = styled.View`
  box-shadow: ${(props) => props.boxShadow};
  border-radius: ${(props) => props.borderRadius}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  padding: ${(props) => props.innerPadding}px;
  background: ${(props) => props.bgColor};
  width: 100%;
  ${isAndroid ? 'elevation: 3;' : ''}
`;

const WrapperRow = styled.View`
  flex-direction: row;
  width: 100%;
  padding-top: ${(props) => props.paddingTop}px;
`;

const LiHalfLeft = styled.View`
  justify-content: ${JustifyContent};
  padding-top: ${(props) => props.paddingTop}px;
`;

const LiHalfRight = styled.View`
  flex: 1;
  padding-right: ${IconPaddingRight}px;
  justify-content: ${JustifyContent};
  text-align: right;
`;

const getMarginBottom = () => {
  if (WINDOW_WIDTH <= BREAK_POINT) {
    return MARGIN_BOTTOM_MOBILE_TABLET;
  }
  return MARGIN_BOTTOM_DESKTOP;
};

const ShadowBox = ({ theme, title, subtitle, rowTextLeft, rowTextRight, customComponent }) => (
  <BoxShadow
    boxShadow={BOX_SHADOW}
    innerPadding={customComponent ? 0 : INNER_PADDING}
    marginBottom={getMarginBottom()}
    borderRadius={theme.DEFAULTS.BORDER_RADIUS}
    bgColor={theme.COLORS.SECONDARY_BACKGROUND}
  >
    {customComponent || (
      <View>
        <LiHalfLeft paddingTop={PADDING_NONE}>
          <H5 fontWeight="bold">{title}</H5>
        </LiHalfLeft>
        <LiHalfLeft paddingTop={PADDING_MEDIUM}>
          <Subtitle fontWeight="regular">{subtitle}</Subtitle>
        </LiHalfLeft>
        <WrapperRow paddingTop={PADDING_LARGE}>
          <LiHalfLeft paddingTop={PADDING_NONE}>
            <Footnote>{rowTextLeft}</Footnote>
          </LiHalfLeft>
          <LiHalfRight>
            <Body textAlign="right">{rowTextRight}</Body>
          </LiHalfRight>
        </WrapperRow>
      </View>
    )}
  </BoxShadow>
);

ShadowBox.propTypes = {
  customComponent: PropTypes.node,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rowTextLeft: PropTypes.string,
  rowTextRight: PropTypes.string,
};

ShadowBox.defaultProps = {
  title: '',
  subtitle: '',
  rowTextLeft: '',
  rowTextRight: '',
};

ShadowBox.defaultProps = {
  customComponent: undefined,
};

export default withTheme(ShadowBox);
