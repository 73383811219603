// NOTE: ordered according to date indexes in JavaScript
export const localeConfig = {
  dk: {
    monthNames: [
      'Januar',
      'Februar',
      'Marts',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Avr',
      'Mai',
      'Juin',
      'Juli',
      'Aug',
      'Sept',
      'Okt',
      'Nov',
      'Dec',
    ],
    dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    dayNamesShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
    dayNamesShortest: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
    today: 'I dag',
  },
  en: {
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesShortest: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    today: 'Today',
  },
};

const capitalize = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : string);

/**
 * Returns the day string for display
 * @param {number} -  index of day according to JS 'getDay'
 * @param {string} -  locale to show the value in
 * @param {string} - available types are 'short', 'shortest' and empty resulting in a full name
 */
export const getDayString = (index, locale, type = '') =>
  localeConfig[locale][`dayNames${capitalize(type)}`][index];

/**
 * Returns the month string for display
 * @param {number} -  index of day according to JS 'getDay'
 * @param {string} -  locale to show the value in
 * @param {string} - available types are 'short', 'shortest' and empty resulting in a full name
 */
export const getMonthString = (index, locale, type = '') =>
  localeConfig[locale][`monthNames${capitalize(type)}`][index];

export const getDayStringByDate = (date, locale, type = '') => {
  const index = date.getDay();
  return getDayString(index, locale, type);
};

export const getMonthStringByDate = (date, locale, type = '') => {
  const index = date.getMonth();
  return getMonthString(index, locale, type);
};

export const getIsDateTooOld = ({ date, numberOfDaysValidator = 90 }) => {
  if (!date) {
    return undefined;
  }

  const today = new Date();
  const formattedDate = new Date(date);
  const numberOfDaysInMs = numberOfDaysValidator * 24 * 60 * 60 * 1000;
  const timeDiffInMs = today.getTime() - formattedDate.getTime();

  return timeDiffInMs >= numberOfDaysInMs;
};
