import { findFolderContents } from 'helpers/documents';

export const selectFolderHistory = (state) => state.areas.documents.folderHistory;

export const selectHasLoaded = (state) => state.areas.documents.hasLoaded;

export const selectRoot = (state) => state.areas.documents.root;

export const selectContents = (state, folderId, usePoliceAsId = false) => {
  const rootDir = selectRoot(state);

  if (!rootDir) {
    return {
      documents: [],
      folders: [],
    };
  }

  const { documents = [], folders = [], police, companyName, isRootFolder } = rootDir;

  let contents = folderId ? findFolderContents(folders, folderId, usePoliceAsId) : undefined;

  if (!contents) {
    contents = {
      documents,
      folders,
      police,
      companyName,
      isRootFolder,
    };
  }

  // NOTE: in case we have only one folder with no documents or files
  // we want to expand it automatically
  if (contents?.folders?.length === 1 && contents?.documents?.length === 0) {
    return {
      ...contents.folders[0],
    };
  }

  return {
    ...contents,
  };
};
