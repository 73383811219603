import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { layoutSpecs } from 'areas/dashboard/styles';
import { PAYOUTS_DETAILS_ONE_TIME_GRAPH_SCREEN } from 'constants/testIds/dashboard';
import GraphComp from '@sp/ui/base/Graph';
import { formatMoney } from 'helpers/string';
import Margin from '@sp/ui/base/Margin';
import withComponentContainer from 'decorators/withComponentContainer';
import { payoutTypes } from 'areas/dashboard/props';
import GraphLabels from 'areas/dashboard/GraphLabels';
import { getBarDescription } from 'areas/dashboard/helpers';
import { selectShowGrossAmount } from 'selectors/dashboard';

const Container = styled.View`
  align-items: center;
`;

const Graph = ({ i18n, theme, payouts, payoutType, showGrossAmount }) => (
  <Container testID={PAYOUTS_DETAILS_ONE_TIME_GRAPH_SCREEN}>
    <Margin marginTop="md" />
    <GraphComp
      bars={payouts?.map((item) => ({
        label: formatMoney(item.engangsudbetalingTotal, true, showGrossAmount),
        labelColor: theme.COLORS.DARK,
        description: getBarDescription(payoutType, item, i18n),
        descriptionColor: theme.COLORS.DARK,
        segments: [
          {
            value: item.engangsudbetalingTotal.amount,
            color: theme.COLORS.GRAPH_PAYOUTS_VARIANT5,
          },
        ],
      }))}
      maxWidth={layoutSpecs.pageMaxWidth}
      height={layoutSpecs.graphHeight}
      barWidth={60}
      barOuterWidth={150}
      centerContent
      paddingLeftAndRight={theme.SPACINGS.xl}
    />
    <GraphLabels
      labels={[
        {
          name: i18n.t('dashboard|graphLabelOnetimePayout'),
          color: theme.COLORS.GRAPH_PAYOUTS_VARIANT5,
        },
      ]}
    />
  </Container>
);

Graph.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  payouts: PropTypes.array,
  payoutType: PropTypes.oneOf(payoutTypes).isRequired,
  showGrossAmount: PropTypes.bool.isRequired,
};

Graph.defaultProps = {
  payouts: undefined,
};

const mapStateToProps = (state) => ({
  showGrossAmount: selectShowGrossAmount(state),
});

export default withComponentContainer({
  mapStateToProps,
  hasLocalNotifications: false,
})(withTheme(Graph));
