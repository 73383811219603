import React from 'react';
import PropTypes from 'prop-types';
import UISelector from '@sp/ui/base/inputs/Selector';
import withTranslation from 'decorators/i18n';

const Selector = ({ noResultsMessage, searchPlaceholder, i18n, ...props }) => (
  <UISelector
    {...props}
    noResultsMessage={noResultsMessage || i18n.t('Ingen resultater fundet...')}
    searchPlaceholder={searchPlaceholder || i18n.t('Søg')}
  />
);

Selector.propTypes = {
  searchPlaceholder: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  i18n: PropTypes.object.isRequired,
};

Selector.defaultProps = {
  searchPlaceholder: undefined,
  noResultsMessage: undefined,
};

export default withTranslation()(Selector);
