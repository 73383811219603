import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  selectFormAdjustCalculation,
  selectHasFolkPension,
  selectHasFolkPensionSupplement,
} from 'selectors/dashboardForms';
import type { RootState } from 'store';

const { actions } = require('react-formed');

const selectPublicServices = (state: RootState) => ({
  formAdjustCalculation: selectFormAdjustCalculation(state)?.publicBenefits,
  hasFolkPensionSupplement: selectHasFolkPensionSupplement(state),
  hasFolkPension: selectHasFolkPension(state),
});

export const useUpdateFormBasedOnFolkPensionOrFolkSupplementChange = () => {
  const dispatch = useAppDispatch();

  const { formAdjustCalculation, hasFolkPensionSupplement, hasFolkPension } = useAppSelector(
    selectPublicServices,
    shallowEqual
  );

  return useCallback(() => {
    let updateValueBasedOnValidationError;

    const {
      hasFolkPensionSupplement: formValueHasFolkPensionSupplement,
      hasFolkPension: formValueHasFolkPension,
    } = formAdjustCalculation ?? {};

    if (typeof formValueHasFolkPension === 'boolean') {
      if (!formValueHasFolkPension && hasFolkPensionSupplement) {
        updateValueBasedOnValidationError = false;
      }
    }

    if (typeof formValueHasFolkPensionSupplement === 'boolean') {
      if (formValueHasFolkPensionSupplement && !hasFolkPension) {
        updateValueBasedOnValidationError = true;
      }
    }

    dispatch(
      actions.setValue('adjustCalculation', 'publicBenefits', {
        hasFolkPension: updateValueBasedOnValidationError,
        hasFolkPensionSupplement: updateValueBasedOnValidationError,
      })
    );
  }, [formAdjustCalculation, hasFolkPension, hasFolkPensionSupplement, dispatch]);
};
