import React, { useState, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/native';
import Icon from '../Icon';
import { getCurrentDeviceType } from '@sp/ui/helpers/device';

const { default: Row } = require('@sp/ui/v2/base/rows');

const ICON_DIMENSIONS = 14;
const ROTATION_COLLAPSED_DEG = 90;
const ROTATION_UNCOLLAPSED_DEG = 270;

const IconWrapper = styled.View<{ isRowCollapsed?: boolean }>`
  transform: rotate(
    ${({ isRowCollapsed }) =>
      isRowCollapsed ? ROTATION_COLLAPSED_DEG : ROTATION_UNCOLLAPSED_DEG}deg
  );
`;

type CollapsibleRowProps = {
  isCollapsed?: boolean;
  hasRowBorder?: boolean;
  hasArrow?: boolean;
  hasBorderRadius?: boolean;
  componentRight?: React.ReactNode;
  componentLeft?: React.ReactNode;
  backgroundColor?: string;
  textLeft?: string | React.ReactNode;
  children: React.ReactNode;
  onPress?: () => void;
};

const CollapsibleRow: React.FC<CollapsibleRowProps> = ({
  isCollapsed,
  backgroundColor,
  componentRight,
  hasArrow,
  children,
  onPress,
  ...rest
}) => {
  const [isRowCollapsed, setIsRowCollapsed] = useState(isCollapsed);

  const { COLORS } = useTheme();

  const derivedBackgroundColor = useMemo(() => {
    const isMobile = getCurrentDeviceType() === 'mobile';

    if (backgroundColor) {
      return backgroundColor;
    }

    return isMobile ? COLORS.V2_SECONDARY_SELECTED : COLORS.V2_SECONDARY_BACKGROUND;
  }, [backgroundColor, COLORS]);

  return (
    <>
      <Row
        backgroundColor={derivedBackgroundColor}
        componentRight={
          componentRight || hasArrow ? (
            <>
              {componentRight}
              {hasArrow && (
                <IconWrapper isRowCollapsed={isRowCollapsed}>
                  <Icon
                    name="arrowRightSmall"
                    width={ICON_DIMENSIONS}
                    height={ICON_DIMENSIONS}
                    fill={COLORS.V2_PRIMARY_BACKGROUND}
                  />
                </IconWrapper>
              )}
            </>
          ) : null
        }
        onPress={() => {
          if (onPress) {
            onPress();
          }

          setIsRowCollapsed(!isRowCollapsed);
        }}
        {...rest}
      />
      {!isRowCollapsed && children}
    </>
  );
};

CollapsibleRow.defaultProps = {
  hasArrow: true,
  isCollapsed: true,
  hasBorderRadius: false,
  hasRowBorder: false,
  componentRight: undefined,
  componentLeft: undefined,
  backgroundColor: undefined,
  textLeft: undefined,
  onPress: undefined,
};

export default CollapsibleRow;
