import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import MenuItem from '@sp/ui/base/MenuItem';
import { CONTAINER_WIDTHS } from '@sp/ui/settings';

const PADDING_MENU_RIGHT_LEFT = 32;
const MARGIN_RIGHT_MENU_ITEM = 40;
const CONTAINER_MAXWIDTH = CONTAINER_WIDTHS.DESKTOP_WIDE;
const MENUBAR_HEIGHT = 72;

const Wrapper = styled.View`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY};
  height: ${MENUBAR_HEIGHT}px;
  padding: 0px ${PADDING_MENU_RIGHT_LEFT}px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Content = styled.View`
  max-width: ${CONTAINER_MAXWIDTH}px;
  flex: 1;
  flex-direction: row;
`;

const MenuItemWrapper = styled.View`
  margin-right: ${MARGIN_RIGHT_MENU_ITEM}px;
`;

const MenuBar = ({ menuItems }) => (
  <Wrapper>
    <Content>
      {menuItems?.map((item) => (
        <MenuItemWrapper key={item.name}>
          <MenuItem
            focused={item.focused}
            name={item.name}
            icon={item.icon}
            onPress={item.onPress}
          />
        </MenuItemWrapper>
      ))}
    </Content>
  </Wrapper>
);

MenuBar.propTypes = {
  menuItems: PropTypes.any.isRequired,
};

MenuBar.defaultProps = {};

export default MenuBar;
