import React from 'react';
import PropTypes from 'prop-types';
import { OneLineRow, CollapsibleRow } from '@sp/ui/base/rows';
import { H5, Subtitle } from '@sp/ui/typography';
import { formatMoney } from 'helpers/string';
import Margin from '@sp/ui/base/Margin';
import { withTheme } from 'styled-components/native';
import { payoutTypes } from 'areas/dashboard/props';
import { DEATH } from '../constants/dashboardTypes';

const OneTimePayoutRow = ({ item, theme, payoutType, i18n, showGrossAmount, showDescription }) => (
  <CollapsibleRow
    key={`collapsibleRow-${item.age}`}
    textLeft={
      payoutType === DEATH ? (
        <H5>
          {showDescription
            ? i18n.t('dashboard|payoutRowOneTimeDeathTitle', { year: item.year })
            : item.year}
        </H5>
      ) : (
        <H5>
          {showDescription
            ? i18n.t('dashboard|payoutRowOneTimeTitleWithDescription', { year: item.age })
            : i18n.t('dashboard|payoutRowTitle', { pensionAge: item.age })}
        </H5>
      )
    }
    textRight={<H5>{formatMoney(item.engangsudbetalingTotal, true, showGrossAmount)}</H5>}
    backgroundColor={theme.COLORS.SECONDARY_BACKGROUND}
  >
    {item.engangsudbetalingDetails.map((details) => (
      <OneLineRow
        key={`onelineRow-${details.name}`}
        textLeft={<Subtitle>{details.name}</Subtitle>}
        textRight={
          <Margin marginRight="md">
            <Subtitle>{formatMoney(details.amount, true, showGrossAmount)}</Subtitle>
          </Margin>
        }
      />
    ))}
  </CollapsibleRow>
);

OneTimePayoutRow.propTypes = {
  item: PropTypes.object.isRequired,
  payoutType: PropTypes.oneOf(payoutTypes).isRequired,
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  showGrossAmount: PropTypes.bool.isRequired,
  showDescription: PropTypes.bool,
};

OneTimePayoutRow.defaultProps = {
  showDescription: false,
};

export default withTheme(OneTimePayoutRow);
