import * as routeNames from 'constants/routeNames';
import { selectIsLoggedIn, selectIsPersisted, selectCanPersist } from 'selectors/api';
import { getAPIStatus } from 'actions/api';
import { selectBlockMessage } from 'selectors/apiStatus';
import persistedStorage from 'services/stores/persisted';
import sessionStorage from 'services/stores/session';
import { createSessionAndUpdateStore } from 'hooks/useHandleDeeplink/helpers';
import { DEEPLINK_SESSION } from 'constants/deeplink';
import doConsentsAndApiStatusFlow from './doConsentsAndApiStatusFlow';
import { isWeb } from 'helpers/platform';

const { replace } = require('helpers/linking');

const startAppFlow = async (store) => {
  const state = store.getState();
  const isLoggedIn = selectIsLoggedIn(state);

  if (!isLoggedIn) {
    return { name: routeNames.INTRO };
  }

  const { flow: storageDeeplink } = (await sessionStorage.getItem(DEEPLINK_SESSION)) ?? {};
  const hasCompletedOnboarding = await persistedStorage.getItem('hasCompletedOnboarding');

  if (isWeb && typeof storageDeeplink === 'string') {
    /* NOTE: You enter this scope when manipulating the URL to
    a deeplink URL after normal login */
    await createSessionAndUpdateStore({
      deeplink: storageDeeplink,
      shouldRerender: false,
      dispatch: store.dispatch,
    });

    /* NOTE: None elegant way of replacing current URL as there is a chance of
    navigationRef not being initialized yet */
    replace(`/${routeNames.DEEPLINK}/${storageDeeplink}`);

    return undefined;
  }

  // NOTE: we need this check to block the customer before showing the biometric screen
  if (!hasCompletedOnboarding) {
    await store.dispatch(getAPIStatus());

    const blockMessage = selectBlockMessage(store.getState());

    if (blockMessage) {
      return { name: routeNames.CUSTOMER_BLOCK };
    }
  }

  const isPersisted = selectIsPersisted(state);
  const canPersist = selectCanPersist(state);

  if (canPersist && !isPersisted) {
    return { name: routeNames.BIOMETRIC };
  }

  return doConsentsAndApiStatusFlow(store);
};

export default startAppFlow;
