import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Icon from '@sp/ui/base/Icon';

const TEXT_MARGIN_LEFT = 9;
export const MARGIN_VERTICAL = 26;
export const ICON_HEIGHT = 13;

const Wrapper = styled.TouchableOpacity`
  align-items: center;
  flex-direction: row;
  margin: ${MARGIN_VERTICAL}px 0;
`;

const Text = styled.Text`
  margin-left: ${TEXT_MARGIN_LEFT}px;
  color: ${(props) => props.theme.COLORS.PRIMARY_TEXT};
`;

const BackButton = ({ onPress, theme, label, testID }) => (
  <Wrapper onPress={onPress} testID={testID} accessibilityLabel={label} accessibilityRole="button">
    <Icon name="thinArrowLeft" width="21" height={ICON_HEIGHT} fill={theme.COLORS.PRIMARY} />
    {label && <Text>{label}</Text>}
  </Wrapper>
);

BackButton.propTypes = {
  onPress: PropTypes.func,
  theme: PropTypes.object.isRequired,
  label: PropTypes.string,
  testID: PropTypes.string,
};

BackButton.defaultProps = {
  onPress: undefined,
  label: undefined,
  testID: undefined,
};

export default withTheme(BackButton);
