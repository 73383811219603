import React from 'react';
import { GradientPrimary } from '@sp/ui/layout/Gradient';
import { H2 } from '@sp/ui/typography';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import Conditional from 'decorators/conditional';
import { SPACINGS, CONTAINER_WIDTHS } from '@sp/ui/settings';

const HEIGHT_DESKTOP_WEB = 237;
const HEIGHT_TABLET_MOBILE = 142;
const HEIGHT_DESKTOP_NO_BROWSER = 192;
const HEIGHT_TABLET_MOBILE_NO_BROWSER = 98;
const MAX_WIDTH = CONTAINER_WIDTHS.DESKTOP_WIDE;

const Wrapper = Conditional({
  desktop: styled.View`
    position: relative;
    max-width: ${MAX_WIDTH}px;
    padding: ${(props) =>
      props.gradientHeader && props.removeTitleMobile
        ? '0px'
        : `${SPACINGS.xxl}px 0px ${SPACINGS.xxl}px 0px`};
  `,
  default: styled.View`
    align-items: center;
    padding: ${(props) =>
      props.gradientHeader || props.removeTitleMobile
        ? '0px'
        : `${SPACINGS.xxl}px 0px ${SPACINGS.xxl}px 0px`};
  `,
});

const Text = styled(H2)`
  color: ${(props) =>
    props.gradientHeader
      ? props.theme.COLORS.SECONDARY_BACKGROUND
      : props.theme.COLORS.PRIMARY_TEXT};
  text-align: center;
`;

const GradientWrapper = Conditional({
  desktop: styled(GradientPrimary)`
    height: ${(props) => (props.withBrowser ? HEIGHT_DESKTOP_WEB : HEIGHT_DESKTOP_NO_BROWSER)}px;
  `,
  default: styled(GradientPrimary)`
    height: ${(props) =>
      props.withBrowser ? HEIGHT_TABLET_MOBILE : HEIGHT_TABLET_MOBILE_NO_BROWSER}px;
  `,
});

const TopGradientContent = ({
  withBrowser,
  heading,
  gradientHeader,
  removeTitleMobile,
  content,
  theme,
}) => (
  <Wrapper gradientHeader={gradientHeader} removeTitleMobile={removeTitleMobile}>
    {heading && typeof heading === 'string' ? (
      <Text gradientHeader={gradientHeader}>{heading}</Text>
    ) : (
      heading
    )}
    {gradientHeader && (
      <GradientWrapper
        withBrowser={withBrowser}
        content={content}
        width="100%"
        gradient={{
          x1: '0%',
          y1: '100%',
          x2: '0%',
          y2: '0%',
        }}
        colorStops={[
          {
            offset: '0%',
            stopColor: '#9dcbe2',
          },
          {
            offset: '15%',
            stopColor: '#94c7e0',
          },
          {
            offset: '28%',
            stopColor: '#89c2de',
          },
          {
            offset: '50%',
            stopColor: '#78bada',
          },
          {
            offset: '72%',
            stopColor: '#69b2d6',
          },
          {
            offset: '84%',
            stopColor: '#5faed4',
          },
          {
            offset: '100%',
            stopColor: theme.COLORS.PRIMARY,
          },
        ]}
      />
    )}
  </Wrapper>
);

TopGradientContent.propTypes = {
  gradientHeader: PropTypes.bool,
  removeTitleMobile: PropTypes.bool,
  withBrowser: PropTypes.bool,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

TopGradientContent.defaultProps = {
  withBrowser: false,
  gradientHeader: false,
  removeTitleMobile: false,
  heading: undefined,
  content: undefined,
};

export default withTheme(TopGradientContent);
