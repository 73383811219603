import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PageParts from '@sp/ui/PageParts';
import styled from 'styled-components/native';
import LoadingContext from '@sp/ui/context/LoadingContext';
import { Title } from '@sp/ui/typography';
import AccessibilitySummary from './AccessibilitySummary';
import Actions, { ActionShape } from './Actions';
import { isWeb } from '@sp/ui/helpers/device';

const NO_BACK_BUTTON_OFFSET = 69;
const CONTENT_BOTTOM_MARGIN = 60;
const CONTENT_MIN_WIDTH = 600;
export const CONTENT_MAX_WIDTH = 700;
export const CARD_WIDTH = 375;
export const CARD_HORIZONTAL_PADDING = 'xl';

const NoBackButtonOffset = styled.View`
  padding-top: ${NO_BACK_BUTTON_OFFSET}px;
`;

const Wrapper = styled.View`
  background: ${({ theme }) => theme.COLORS.PRIMARY_BACKGROUND};
  flex: 1;
  min-height: 100%;
  ${isWeb ? 'overflow-y: auto;' : ''}
  ${isWeb ? 'user-select: text;' : ''}
`;

const TitleWrapper = styled.View`
  justify-content: center;
`;

const TitleAndBackButtonWrapper = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.SPACINGS.xl}px;
`;

const LoaderWrapper = styled.View`
  position: relative;
  flex: 1;
  align-items: center;
`;

const ContentWrapper = styled.View`
  flex-direction: row;
  padding: 0 ${({ theme }) => `${theme.SPACINGS[CARD_HORIZONTAL_PADDING]}px ${theme.SPACINGS.xl}px`};
`;

const Empty = styled.View``;

const Content = styled.View`
  border-radius: ${({ theme }) => theme.SPACINGS.m}px;
  background: ${({ theme }) => theme.COLORS.SECONDARY_BACKGROUND};
  flex: 1;
  margin-left: auto;
  margin-right: ${({ theme }) => theme.SPACINGS.sm}px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  min-width: ${CONTENT_MIN_WIDTH}px;
`;

const ActionCard = styled.View`
  width: ${CARD_WIDTH}px;
  margin-right: auto;
  margin-left: ${({ theme }) => theme.SPACINGS.sm}px;
`;

const BottomPadding = styled.View`
  flex: 1;
  min-height: ${CONTENT_BOTTOM_MARGIN}px;
  z-index: -1;
`;

const BasePage = ({
  menu,
  footer,
  children,
  testID,
  accessibilitySummary,
  actions,
  actionInfo,
  actionsContent,
  hasBackButton,
  backButton,
  title,
}) => {
  const { loader } = useContext(LoadingContext);
  return (
    <PageParts>
      {({
        menu: globalMenu,
        footer: globalFooter,
        BackButton: GlobalBackButton,
        notifications,
      }) => (
        <Wrapper testID={testID}>
          {typeof menu !== 'undefined' ? menu : globalMenu}
          <LoaderWrapper>
            {loader}
            <AccessibilitySummary accessibilitySummary={accessibilitySummary}>
              <TitleAndBackButtonWrapper>
                {!hasBackButton && <NoBackButtonOffset />}
                {hasBackButton &&
                  (typeof backButton !== 'undefined' ? backButton : <GlobalBackButton />)}
                {title && (
                  <TitleWrapper>
                    <Title fontWeight="bold">{title}</Title>
                  </TitleWrapper>
                )}
                <Empty />
              </TitleAndBackButtonWrapper>
              <ContentWrapper>
                <Content>{children}</Content>
                {((actions && actions.length > 0) || actionsContent) && (
                  <ActionCard>
                    <Actions
                      actions={actions}
                      actionInfo={actionInfo}
                      actionsContent={actionsContent}
                    />
                  </ActionCard>
                )}
              </ContentWrapper>
            </AccessibilitySummary>
            <BottomPadding />
            {typeof footer !== 'undefined' ? footer : globalFooter}
            {notifications}
          </LoaderWrapper>
        </Wrapper>
      )}
    </PageParts>
  );
};

BasePage.propTypes = {
  menu: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  testID: PropTypes.string,
  accessibilitySummary: PropTypes.string,
  actions: PropTypes.arrayOf(ActionShape),
  actionInfo: PropTypes.string,
  actionsContent: PropTypes.node,
  hasBackButton: PropTypes.bool,
  backButton: PropTypes.node,
  title: PropTypes.string,
};

BasePage.defaultProps = {
  menu: undefined,
  footer: undefined,
  children: undefined,
  testID: undefined,
  accessibilitySummary: undefined,
  actions: undefined,
  actionInfo: undefined,
  actionsContent: undefined,
  hasBackButton: undefined,
  backButton: undefined,
  title: undefined,
};

export default BasePage;
