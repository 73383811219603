import { useCallback } from 'react';
import { useRoute, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { shallowEqual } from 'react-redux';
import * as routeNames from 'constants/routeNames';
import * as notFoundConstants from 'constants/notFound';
import { updateFlowControl } from 'actions/flowControl';
import { FLOW_CONTROL_DONE } from 'constants/flowControl';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useGetUpdatedCurrentProgress } from './useGetUpdatedCurrentProgress';
import { selectPARTNERConsent } from 'selectors/consents';
import { selectPartner, selectPartnerIncomeOptions } from 'selectors/partner';
import { selectFlowControl } from 'selectors/flowControl';
import { selectIsPensionsInfoUndefinedOrThreeOrMoreMonthsOld } from 'selectors/pensionsInfo';
import { FLOW_STARTED } from '../trackingIds';
import type { RootState } from 'store';

const { default: Tracking } = require('services/tracking');

type NavigationRouteTypes =
  | typeof routeNames.PARTNER_CONSENTS
  | typeof routeNames.PARTNER_PENSIONS_INFORMATION
  | typeof routeNames.PARTNER_INCOME_INFORMATION
  | typeof routeNames.PARTNER_RECEIPT
  | typeof routeNames.DEEPLINK
  | typeof routeNames.DEEPLINK_WARNING;

type ScreenAndParamsType =
  | {
      screen: NavigationRouteTypes;
      params?: {
        payoutType?: string;
        type?: any;
      };
    }
  | undefined;

type NavigationTypeParams = {
  [routeNames.DEEPLINK]: ScreenAndParamsType;
  [routeNames.PARTNER]: ScreenAndParamsType;
};

const selectUseNavigateToActionableScreen = (state: RootState) => ({
  flowControl: selectFlowControl(state),
  partner: selectPartner(state),
  partnerConsent: selectPARTNERConsent(state),
  hasMissingIncome: selectPartnerIncomeOptions(state)?.hasMissingIncome,
  isPensionsInfoUndefinedOrThreeOrMoreMonthsOld:
    selectIsPensionsInfoUndefinedOrThreeOrMoreMonthsOld(state),
});

/* NOTE: When backend is deployed, then below approach should
be specified to include correct services */
export const useNavigateToActionableScreen = () => {
  const dispatch = useAppDispatch();
  const getUpdatedCurrentProgress = useGetUpdatedCurrentProgress();
  const {
    flowControl,
    partner,
    partnerConsent,
    isPensionsInfoUndefinedOrThreeOrMoreMonthsOld,
    hasMissingIncome,
  } = useAppSelector(selectUseNavigateToActionableScreen, shallowEqual) ?? {};

  const navigation = useNavigation<StackNavigationProp<NavigationTypeParams>>();
  const { name } = useRoute();
  const { consent } = partnerConsent?.items[0] ?? {};
  const { statusCode, hasPartnerConnectionFailed } = partner ?? {};
  const { activeProgressIndexes, currentProgress } = flowControl ?? {};

  return useCallback(async () => {
    const missingConsents = consent !== 'granted';
    const missingPensionsInfo = isPensionsInfoUndefinedOrThreeOrMoreMonthsOld;
    const missingIncome = hasMissingIncome;

    let navigationRoute: NavigationRouteTypes = routeNames.PARTNER_RECEIPT;

    if (hasPartnerConnectionFailed) {
      navigation.navigate(routeNames.DEEPLINK, {
        screen: routeNames.DEEPLINK_WARNING,
        params: {
          type: [400, 404].includes(statusCode as number)
            ? notFoundConstants.PARTNER_CONNECTION_FAILED
            : notFoundConstants.PARTNER_LINKING_ERROR,
        },
      });

      return;
    } else if (missingConsents) {
      navigationRoute = routeNames.PARTNER_CONSENTS;
    } else if (missingPensionsInfo) {
      navigationRoute = routeNames.PARTNER_PENSIONS_INFORMATION;
    } else if (missingIncome) {
      navigationRoute = routeNames.PARTNER_INCOME_INFORMATION;
    }

    if (navigationRoute !== name && !currentProgress) {
      const updatedCurrentProgress = getUpdatedCurrentProgress({
        step: navigationRoute,
        activeProgressIndexes,
      });

      dispatch(
        updateFlowControl({
          status: FLOW_CONTROL_DONE,
          activeProgress: navigationRoute,
          isFlowCompleted: navigationRoute === routeNames.PARTNER_RECEIPT,
          ...updatedCurrentProgress,
        })
      );

      Tracking.trackEvent(FLOW_STARTED);
      navigation.navigate(routeNames.PARTNER, { screen: navigationRoute });
    }
  }, [
    consent,
    isPensionsInfoUndefinedOrThreeOrMoreMonthsOld,
    hasMissingIncome,
    hasPartnerConnectionFailed,
    name,
    currentProgress,
    navigation,
    statusCode,
    activeProgressIndexes,
    getUpdatedCurrentProgress,
    dispatch,
  ]);
};
